import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/train-reserve-loading',
            name: 'train-reserve-loading',
            component: () => import(/* webpackChunkName: "page-travel-book-train-train-reserve-loading" */ `./1.0.0/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '火车票占座',
                    need_head_nav: false,
                    need_footer_bar: false,
                    secondary_menu: 0,
                    need_alive: true,
                },

                data_for_all_routes_processor: {
                    order_index: '013-022',
                },

            },
        }
    ],
};


