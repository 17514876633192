import NavBar from '@/component/nav-bar/import_latest_version_module.js'
import consumer_hotel_search_getHotelImage from '@/lib/data-service/haolv-default/consumer_hotel_search_getHotelImage'
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
import { ImagePreview } from 'vant';
export default {
    data() {
        return {
            active: '',
            hotelId: '',
            query: {},
            tabList: [],
            loading: false,
            hotelImageList: [], // 总的酒店图片列表
            hotelImageListResults: [], // 酒店图片输出结果
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
        const __this = this
        /*wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback:(result) => {
                    __this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            },200)
        });*/
        this.init();
    },
    deactivated() {
        /*wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });*/
    },
    methods: {
        init() {
            this.query = this.$route.query;
            this.hotelId = this.query.hotelId;
            this.active = '';
            this.getPicList();
        },
        getPicList() {
            this.$showLoading.show();
            consumer_hotel_search_getHotelImage({hotelId: this.hotelId}).then(res=>{
                let hotelImageListResults = res.data.hotelImageListResults;
                let tabList = [];
                let total = 0;
                hotelImageListResults.forEach((value, index)=>{
                    total+= value.count;
                    value.name = `${value.typeName}(${value.count})`;
                    tabList.push(value);
                });
                tabList.unshift({name: `全部(${total})`, type: '',});
                this.tabList = tabList;
                this.hotelImageList = hotelImageListResults;
                this.hotelImageListResults = hotelImageListResults;
            }).finally(()=>{
                this.$showLoading.hide();
            })
        },
        back() {
            this.$router.go(-1);
        },
        toLocateRoomList() {
            this.$set(this.query, 'isLocateRoomList', 1)
            this.$router.push({
                name: "h5-hotel-detail",
                query: this.query,
            });
        },
        changeTabs(name, title) {
            if (name === '') {
                this.hotelImageListResults = JSON.parse(JSON.stringify(this.hotelImageList));
            } else {
                let result = [];
                this.hotelImageList.forEach(value => {
                    if (value.type === name) {
                        result.push(value)
                    }
                })
                this.hotelImageListResults = result;
            }
        },
        showImgPreview(hIndex, index) {
            let picList = [];
            let i = 0;
            this.hotelImageListResults.forEach((value,vIndex) => {
                picList = [...picList, ...value.urls];
                if (vIndex < hIndex) {
                    i+=value.count;
                }
            });
            i+=index;
            ImagePreview({
                images: picList,
                startPosition: i,
                closeable: true,
            });
        }
    }
}
