import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import NavBar from "@/component/nav-bar/import_latest_version_module";
export default {
    name: 'transfer',
    data () {
        return {
            activeUserIndex: 0,
            paddingTop: 0,
        }
    },
    props: {
        popupShow: {
            type: Boolean,
            default: false
        },
        allUserItems: {
            type: Array,
            default: () => []
        },
        activeUserIds: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: '选择出行人'
        },
    },
    components: {NavBar},
    created () {},
    mounted () {
        const __this = this;
        fixStatusBar({
            handler({api}) {
                __this.paddingTop = api.safeArea.top;
            }
        });
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {
        currentPopupShow: {
            get () {
                return this.popupShow
            },
            set (val) {
                this.$emit('update:popupShow', val)
            }
        },
        currentActiveUserIds: {
            get () {
                return this.activeUserIds
            },
            set (val) {
                this.$emit('update:activeUserIds', val)
            }
        }
    },
    filters: {},
    methods: {
        cancelChooseUser() {
            this.currentPopupShow = false
            this.$emit('cancelChoose')
        },
        submitChooseUser() {
            this.currentPopupShow = false
            this.$emit('submitChoose')
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/