import req_detail_data from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_queryHotelOrderDetails";
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";

export default {
    data() {
        return {
            detail: null,
        };
    },
    methods: {
        onBack() {
            if (this.$route.query.jumpType) {
                if (this.$route.query.jumpType == 'go(-1)') {
                    this.$router.go(-1)
                } else {
                    this.$router.replace({
                        path: '/h5/hotel-order-list',
                        query: {
                            active: 3,
                        },
                    });
                }
            } else {
                this.$router.replace({
                    path: '/h5/hotel-order-list',
                    query: {
                        active: 3,
                    },
                });
            }
        },
        toApplyDetail() {
            if(this.detail.applyId) {
                this.$router.push({
                    name: 'admin-my-apply-manage-apply-approve-exceed',
                    query: {
                        applyId: this.detail.applyId,
                        type: 'applyDetail',
                        lastMenu: 'adminMyApply'
                    }
                })
            }
        },
        // 获取订单详情
        getOrderDetail() {
            const orderNo = this.$route.query.orderNo || '';
            req_detail_data({ orderNo }).then(res => {
                this.detail = {
                    applyId: res.applyId,
                    applyNo: res.applyNo,
                    ifAgreement: res.ifAgreement === 1 ? true : false,
                    hotelGroup: res.hotelGroup,
                    cardNumber: res.cardNumber,
                    agreementHotelText: res.agreementHotelText,
                    hostingServiceFee: res.hotelCostInfoResponse.hostingServiceFee,
                    order: res.orderInterimResult,
                    personList: res.hotelOrderPersonList ? res.hotelOrderPersonList : [],
                    approvalReason: res.approvalReason,
                    applicationId: res.applicationid,
                    approveType: res.approveType,
                };
                this.detail.isExceed = res.showOrNot;
                if(this.detail.isExceed) {
                    this.detail.hoteltrainsOrderTipsResponses = res.hoteltrainsOrderTipsResponses ? res.hoteltrainsOrderTipsResponses : [];
                    this.detail.recordList = res.recordList ? res.recordList : []
                }
            });
            this.timeHandler();
        },
        timeHandler() {
            let test =setInterval(() => {
                if (this.$refs.navBox != undefined) {
                    const __this = this;
                    fixStatusBar({
                        type: `000`,
                        handler({default_handler, api}) {
                            default_handler({
                                element: __this.$refs.navBox,
                            });
                        }
                    });
                    clearInterval(test);
                }
            },100)
        },
        to_refund(row) { // 申请退订
            this.$router.push({
                name: "h5-hotel-refund",
                query: {
                    orderNo: row.orderNo
                }
            });
        },
        toApprovalDetail() {
            this.$router.push({
                name: 'h5-approve-detail',
                query: {
                    id: this.detail.applicationId,
                    approveType: this.detail.approveType,
                },
            });
        }
    },
    created() {
        this.getOrderDetail();
    },
    components: {
        CollapseCard: () => import('./components/collapse-card/index.vue'),
    },
    filters: {
        format_create(val) {
            switch (Number(val)) {
                case 1:
                    return "个人创建";
                    break;
                case 2:
                    return "出差单创建";
                    break;
            }
        },
    },
}
