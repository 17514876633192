import moment from "moment";
import PageHead from '@/page/evection/components/page-head/index.vue'
// 列表为空展示组件
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";
import {Dialog, Toast} from 'vant';
// 出差单列表
import consumer_evection_evectionList from "@/lib/data-service/haolv-default/consumer_evection_evectionList";
// 取消行程
import consumer_evection_cancelEvection from "@/lib/data-service/haolv-default/consumer_evection_cancelEvection";
// 删除行程
import consumer_evection_deleteEvection from "@/lib/data-service/haolv-default/consumer_evection_deleteEvection";

export default {
    components: {
        PageHead,
        ListEmpty,
        Dialog
    },
    data() {
        return {
            error: false,
            loading: false,
            refreshing: false,
            finished: false,
            emptyType: '',
            formData: { // 订单搜索
                selectType: 2, //1.我的出差，2.我的预订,
                currentPage: 1,
                pageSize: 10,
                total: 0,
                evectionNo: "",
                evectionReason: "",
                evectionStatus: "",
                evectionType: ""
            },
            reserveList: [], // 出差单列表
        };
    },
    methods: {
        get_reserve_data() {
            this.loading = true;
            this.refreshing = true;
            this.finished = false;
            consumer_evection_evectionList(this.formData).then((res) => {
                const datas = res.datas;
                const totalCount = datas.totalCount;
                for (let item of datas.list) {
                    item.isDevelop= false;
                }
                if (totalCount > this.formData.pageSize) {
                    this.finished = false;
                } else {
                    this.formData.pageSize = totalCount;
                    this.finished = true;
                }

                this.reserveList = datas.list;
                this.refreshing = false;
                this.loading = false;
            }).catch((err) => {
                this.reserveList = [];
                this.emptyType = "network";
                this.finished = true;
                this.refreshing = false;
                this.loading = false;
            });
        },
        onLoad() {
            if (this.refreshing) return;
            this.loading = true;
            this.formData.pageSize += 10;
            this.get_reserve_data();
        },
        onRefresh() {
            this.formData = { // 订单搜索
                selectType: 2, //1.我的出差，2.我的预订,
                currentPage: 1,
                pageSize: 10,
                total: 0,
                evectionNo: "",
                evectionReason: "",
                evectionStatus: "",
                evectionType: ""
            };
            this.refreshing = false;
            this.onLoad();
        },
        to_detail(row) { // 出差单详情
            this.$router.push({
                name: 'h5-reserve-order-detail',
                query: {
                    pageType: 1,
                    evectionNo: row.evectionNo
                },
            });
        },
        to_flight_order_detail(row) { // 行程单机票详情
            this.$router.push({
                name: 'flight-order-detail',
                query: {
                    orderNo: row.orderNo
                },
            });
        },
        to_train_order_detail(row) { // 行程火车票详情
            this.$router.push({
                name: 'h5-train-order-detail',
                query: {
                    orderNo: row.orderNo
                },
            });
        },
        to_hotel_order_detail(row) { // 行程酒店情
            console.log(row)
            this.$router.push({
                name: 'h5-hotel-order-detail',
                query: {
                    orderNo: row.orderNo
                },
            });
        },
        go_pay(row) { // 去支付
            this.$router.push({
                name: "h5-travel-payment",
                query: {
                    evectionNo: row.evectionNo
                }
            });
        },
        cancelTrip(row) { // 取消行程
            Dialog.confirm({
                title: '提示',
                message: '是否要取消该行程?？'
            }).then(() => {
                consumer_evection_cancelEvection({evectionNo: row.evectionNo }).then(res => {
                    Toast.success('取消订单成功');
                    this.formData.currentPage = 1;
                    this.reserveList = [];
                    this.onRefresh();
                });
            }).catch(() => {});
        },
        deteleEvect(row) { // 删除出差单
            Dialog.confirm({
                title: '提示',
                message: '是否要删除该行程?？'
            }).then(() => {
                consumer_evection_deleteEvection({evectionNo: row.evectionNo }).then(res => {
                    Toast.success('删除成功');
                    this.formData.currentPage = 1;
                    this.reserveList = [];
                    this.onRefresh();
                });
            }).catch(() => {});
        },
        flight_change(row, index) { // 机票改签
            this.$router.push({
                name: "flight-change",
                query: {
                    evectionNo: row.flightDetail.evectionNo,
                    orderNo: row.flightDetail.orderList[index].orderNo
                }
            });
        },
        flight_refund(row, index) { // 机票退订
            this.$router.push({
                name: "flight-refund",
                query: {
                    orderStatus: 1,
                    orderNo: row.flightDetail.orderList[index].orderNo
                }
            });
        },
        train_change(row) { // 火车票改签
            this.$router.push({
                name: "h5-train-change",
                query: {
                    orderNo: row.orderNo
                }
            });
        },
        train_refund(row) { // 火车票退票
            this.$router.push({
                name: "h5-train-refund",
                query: {
                    orderNo: row.orderNo
                }
            });
        },
        hotel_refund(row) { // 酒店退订
            this.$router.push({
                name: "h5-hotel-refund",
                query: {
                    orderNo: row.orderNo
                }
            });
        }
    },
    created() {

    },
    activated() {

    },
    deactivated() {

    },
    filters: {
        format_date(val) {
            if (val) {
                return moment(val).format("YYYY-MM-DD");
            } else {
                return val
            }

        },
        format_week(val) {
            switch (moment(val).day()) {
                case 1:
                    return "周一";
                    break;
                case 2:
                    return "周二";
                    break;
                case 3:
                    return "周三";
                    break;
                case 4:
                    return "周四";
                    break;
                case 5:
                    return "周五";
                    break;
                case 6:
                    return "周六";
                    break;
                case 0:
                    return "周日";
                    break;
            }
        },
        format_person(val) {
            if (val) {
                return JSON.parse(val)
                    .map((item) => item.lastName)
                    .join("/");
            }
        },
        format_status(val) {
            switch (Number(val)) {
                case 0:
                    return "待支付";
                    break;
                case 1:
                    return "支付中";
                    break;
                case 2:
                    return "已支付，待出房";
                    break;
                case 4:
                    return "已支付，待入住";
                    break;
                case 6:
                    return "已取消";
                    break;
                case 7:
                    return "取消中";
                    break;
                case 8:
                    return "取消失败";
                    break;
                case 9:
                    return "预下单";
                    break;
                case 10:
                    return "创建订单失败";
                    break;
                case 11:
                    return "已出房";
                    break;
                case 12:
                    return "已退款";
                    break;
                case 13:
                    return "退款失败";
                    break;
                case 14:
                    return "出房失败";
                    break;
                case 15:
                    return "退款中";
                    break;
                // case 16:
                //   return "已退房";
                //   break;
                // case 17:
                //   return "已退房";
                //   break;
                case -1:
                    return "已删除";
                    break;
            }
        },
    },
}
