import NavBar from "@/component/nav-bar/import_latest_version_module";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
export default {
    data() {
        return {
            title: '',
            changeNo: '',
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
        this.changeNo = this.$route.query.changeNo || '';
        keyback_processor.addEventListener(() => {
            this.back()
        })
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        goDetail() {
            if (this.changeNo) {
                this.$router.replace({
                    name: 'h5-train-change-order-detail',
                    query: {
                        orderNo: this.changeNo,
                        status: 3
                    }
                });
            }
        },
        back() {
            this.$router.replace({
                name: 'h5-train-order-list',
                query: {
                    type: 2
                }
            })
        },
    }
}