import NavBar from "@/component/nav-bar/import_latest_version_module";
import DomesticTicketAirportSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-city-selector/1.2.1/index.vue';
import consumer_trains_order_changeRule from '@/lib/data-service/haolv-default/consumer_trains_order_changeRule'
import consumer_trains_order_changeServiceTime  from '@/lib/data-service/haolv-default/consumer_trains_order_changeServiceTime'
import moment from "moment";
import {Toast, Dialog} from "vant";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
export default {
    data() {
        return {
            orderNo: '',
            selectedStartTrainId: '', // 出发站ID
            startTrainParams: {}, // 出发站信息
            selectedArriveTrainId: '', // 到达站ID
            arriveTrainParams: {}, // 到达站信息
            trainDate: '',
            isShowCalendar: false,
            minDate: new Date(),   //时间范围
            maxDate: new Date(new Date().getFullYear() + 10, 1, 1),   //时间范围
            cityChange: true,
            dateChange: true,
            startLoading: false,
            errorMsg: '',
            evectionType: 2,
            evectionNo: '',
            userList: '',
        }
    },
    components: {NavBar, DomesticTicketAirportSelector},
    created() {
    },
    mounted() {
        this.$refs.arriveAirportSelector.init();
    },
    activated() {
        this.errorMsg = '';
        this.orderNo = this.$route.query.orderNo;
        this.selectedStartTrainId = this.$route.query.fromStationCode;
        this.startTrainParams = {
            stationName: this.$route.query.fromStationName,
            stationCode: this.$route.query.fromStationCode,
        };
        this.selectedArriveTrainId = this.$route.query.toStationCode;
        this.arriveTrainParams = {
            stationName: this.$route.query.toStationName,
            stationCode: this.$route.query.toStationCode,
        };
        this.evectionNo = this.$route.query.evectionNo;
        this.evectionType = parseInt(this.$route.query.evectionType);
        this.userList = this.$route.query.userList;
        this.getChangeRule();
        keyback_processor.addEventListener(() => {
            if (this.isShowCalendar) {
                this.isShowCalendar = false;
                return
            }
            const arriveAirportSelector = this.$refs.arriveAirportSelector;
            const show_arriveAirportSelector = arriveAirportSelector.popup.show;
            if (show_arriveAirportSelector) {
                arriveAirportSelector.popup.show = false;
                return
            }
            this.back()
        })
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {
        selectedArriveTrainId(newVal) {
            this.$nextTick(() => {
                this.arriveTrainParams = this.$refs.arriveAirportSelector.get_selected_entity_promise({
                    id: newVal,
                }).then((entity) => {
                    console.log(entity);
                    this.arriveTrainParams = entity;
                }).catch((rej) => {
                    console.log(rej);
                })
            })
        },
    },
    computed: {},
    filters: {
        filterFlightDaY(val) {
            return val === '' ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        }
    },
    methods: {
        toReadDetail(val) {
            console.log('1', val);
            this.$router.push({
                name: 'h5-train-instructions',
                query: {
                    active: 2,
                    anchor: val
                }
            });
        },
        getChangeRule() {
            consumer_trains_order_changeRule({orderNo: this.orderNo}).then(res => {
                const info = res.datas;
                this.cityChange = info.cityChange;
                this.dateChange = info.dateChange;
                this.minDate = new Date(info.startTime);
                this.maxDate = new Date(info.endTime);
                this.errorMsg = info.msg;
                this.trainDate = this.$route.query.startDate;
                if (this.$route.query.startDate) {
                    let oneBox = moment(this.$route.query.startDate);
                    let twoBox = moment(info.startTime.split(' ')[0]);
                    if (twoBox.diff(oneBox,'day') > 0) {
                        this.trainDate = info.startTime.split(' ')[0];
                    }
                }
            })
        },
        openArriveTrainPopup() {
            if (!this.cityChange) {
                return
            }
            this.$refs.arriveAirportSelector.show();
        },
        showCalendar() {
            if (!this.dateChange) {
                return
            }
            this.isShowCalendar = true;
        },
        // 确定选择出行日期
        onCalendarConfirm(date) {
            this.isShowCalendar = false;
            this.trainDate = this.$moment(date).format('YYYY-MM-DD');
        },
        startChange() {
            // if (this.errorMsg) {
            //     Dialog({ message: this.errorMsg }).then(() => {
            //         this.toChange();
            //     });
            //     return
            // }
            this.toChange();
        },
        toChange() {
            if (this.startLoading) {
                return
            }
            this.startLoading = true;
            consumer_trains_order_changeServiceTime().then(res => {
                if (!res.datas.status) {
                    Toast(res.datas.msg);
                    return
                }
                const startTrainParams = {
                    stationCode: this.startTrainParams.stationCode,
                    stationName: this.startTrainParams.stationName
                };
                const arriveTrainParams = {
                    stationCode: this.arriveTrainParams.stationCode,
                    stationName: this.arriveTrainParams.stationName
                };
                const params = {
                    startTrainParams: JSON.stringify(startTrainParams), // 出发站信息
                    arriveTrainParams: JSON.stringify(arriveTrainParams), // 到达站信息
                    trainDate: this.trainDate,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType,
                    orderNo: this.orderNo,
                    userList: this.userList,
                    minDate: moment(this.minDate).format('YYYY-MM-DD'),
                    maxDate: moment(this.maxDate).format('YYYY-MM-DD'),
                };
                // this.$refs.aList.run(params);
                this.$router.push({
                    name: 'h5-train-change-search-list',
                    query: params
                })
            }).finally(() => {
                this.startLoading = false
            })
        },
        back() {
            this.$router.push({
                name: 'h5-train-order-detail',
                query: {
                    orderNo: this.orderNo,
                    jumpType: 'list'
                }
            })
        },
    }
}
