import ColorIcon from "@/component/color-icon/index.vue";
import applyButton from "@/lib/data-service/haolv-default/consumer_menu_applyButton";
import req_approveList_datas from "@/lib/data-service/haolv-default/consumer_apply_approveList";
import consumer_apply_approveNumber from '@/lib/data-service/haolv-default/consumer_apply_approveNumber'
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    ColorIcon
  },
  data() {
    return {
      dataOne:0,
      dataTwo:0,
      dataThree:0,
      jurisdiction: false   // 按钮权限范围
    };
  },

  watch: {
  },
  computed: {
    ...mapGetters(['applyListNum', 'applyListResult'])
  },
  mounted(){
    const api = global.api;
    keybackProcessor.addEventListener(() => {
      api.closeWidget()
      /*api.confirm({
          title: '确定要关闭程序吗？',
          buttons: ['确定', '关闭'],
      }, function (ret, err) {
          if (ret.buttonIndex === 1) {
              api.closeWidget()
          }
      })*/
    })
  },
  destroyed() {
    keybackProcessor.removeEventListener()
  },
  activated(){
    this.getApplyButton();
    // consumer_apply_approveNumber().then(res => {
    //   this.dataTwo = res.datas.approvedNumber
    // });
    // this.$store.dispatch('updateApplyListNum')
    this.updateApplyListNum()
  },
  methods: {
    // 出差申请按钮状态
    async getApplyButton() {
      try {
        const res = await applyButton();
        this.jurisdiction = res.datas.jurisdiction
      } catch (error) {

      }
    },
    ...mapActions(['updateApplyListNum'])
  }
};
