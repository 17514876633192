import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList' // 获取部门list
import consumer_web_costCenter_allList from "@/lib/data-service/haolv-default/consumer_web_costCenter_allList";
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
export default {
    name: 'costAttributionList',
    data () {
        return {
            // show: false,
            tabActive: 0,
            radio: '',

            cascadeList: [],
            treeDataSource: [],
            allCostList: [], // 成本列表
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        feeType: { // 费用归属类型：1.组织架构，2.成本中心
            type: Number,
            default: 1
        },
        feeName: {
            type: String,
            default: ''
        },
        feeId: {
            // type: Number,
            default: ''
        },
    },
    components: {
        radioItem: () => import('./radioItem/index.vue')
    },
    created () {},
    async mounted () {
        this.tabActive = this.feeType === 1 ? 0 : 1
        this.radio = this.feeId
        await this._getDeptList()
        this.treeDataSource = this._initTreeData(this.cascadeList)
        await this._allList()
    },
    activated () {},
    deactivated() {},
    destroyed () {},
    watch: {
        feeType(newVal) {
            this.tabActive = newVal === 1 ? 0 : 1
        },
    },
    computed: {
        popupShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        },
        currentFeeType: {
            get () {
                return this.feeType
            },
            set (val) {
                this.$emit('update:feeType', val)
            }
        },
        currentFeeName: {
            get () {
                return this.feeName
            },
            set (val) {
                this.$emit('update:feeName', val)
            }
        },
        currentFeeId: {
            get () {
                return this.feeId
            },
            set (val) {
                this.$emit('update:feeId', val)
            }
        }
    },
    filters: {},
    methods: {
        async _getDeptList() {
            let [err, res] = await awaitWrap(consumer_department_getDepartmentList())
            if (err) {
                this.cascadeList = []
                return false
            }
            this.cascadeList = res.datas.departmentListResults ? res.datas.departmentListResults : []
            let item = {
                childrenList: null,
                deptId: -1,
                deptLevel: 1,
                deptName: "各自所在部门",
                deptStatus: 1,
                parentId: 0,
                strength: 1,
                userId: null,
                userName: ""
            }
            this.cascadeList.unshift(item)
            return true
        },
        _initTreeData(list) {
            // 这里一定要转化，要不然他们的值监听不到变化
            let tempData = JSON.parse(JSON.stringify(list))
            let reduceDataFunc = (data, level) => {
                data.map((m, i) => {
                    m.isExpand = false // 是否展开
                    m.check = false
                    m.indeterminate = false
                    // m.children = m.children || []
                    m.level = level
                    m.bLeft = level * 20
                    m.label = m.deptName
                    m.value = m.deptId
                    m.children = m.childrenList
                    delete m.childrenList
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children, level + 1)
                    }
                })
            }
            reduceDataFunc(tempData, 1)
            return tempData
        },
        async _allList() {
            let res = await consumer_web_costCenter_allList()
            let allCostList = res.datas
            allCostList.forEach(value => {
                value.check = false
                value.label = value.centerName
                value.value = value.id
            })
            this.allCostList = allCostList;
        },
        _handlerExpand(val) {
            val.isExpand = !val.isExpand
        },
        _toSelectRadio(val) {
            console.log('val', val)
            this.radio = val.value
            this.currentFeeName = val.label
            this.currentFeeId = val.value
            this.currentFeeType = this.tabActive === 0 ? 1 : 2
            this.popupShow = false
        },
        _getActive(val) {
            this.radio = val.value
            this.currentFeeName = val.label
            this.currentFeeId = val.value
            this.currentFeeType = this.tabActive === 0 ? 1 : 2
            this.popupShow = false
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/