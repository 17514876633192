// consumer_web_policyConfig_myTravelPolicy 我的差标
// 接口文档地址：http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/t-bd-policy-config-controller/myTravelPolicyUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_policyConfig_myTravelPolicy = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/myTravelPolicy',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_policyConfig_myTravelPolicy;