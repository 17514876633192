//查询退改政策
import refund_change_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get'
import consumer_apply_checkLoginUserPolicy from '@/lib/data-service/haolv-default/consumer_apply_checkLoginUserPolicy'
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import CommonTravelerSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/common-traveler-selector/1.0.1';
import getEvectionAllType
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.1.1/index'
import {Toast} from "vant";
// 彩色icon组件
import ColorIcon from "@/component/color-icon/index.vue";
import ShareRoomUserSelectPopup from "@/component/share-room-user-select-popup/index.vue";

export default {
    data() {
        return {
            isSelectedOrder: false,//是否已选出差单
            isSelectedMyself: false,//是否只能选当前登陆用户为出行人
            isShowAllStaffPopup: false,//是否显示全体人员弹窗
            isShowEvectionStaffPopup: false,//是否显示出差单出行人弹窗
            evectionRequired: false,//出差单是否必填
            selectedStaffMaxNo: 6,//预定人数上限
            isFreeApprove: 0, // 是否免审：1.是，0.否
            userInfo: {},
            allUserItems: [],//全体员工弹窗数据
            allStaffList: [],//全体员工列表
            selectedStaffIdList: [],//已选的员工id列表
            selectedStaffList: [],//已选的员工列表
            allSelectedStaffList: [], // 可选员工列表

            otherUserList: [], // 合住人列表
            otherUserIdList: [], // 合住人id列表
            allSelectOtherUserLit: [], // 因公选出差单情况下可选的合住人合集列表
            shareTravelCurrent: null,

            enableAutoInit: false,
            selectMode: '100',
            typeOff: true,

            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差

            // sharingUserIdList: [22881],
        }
    },
    props: {
        // 传入的出差单数据
        travelCurrent: {
            type: Object,
            default: null
        },
        useTravel: {
            type: [String, Number],
            default: ''
        },
        businessType: {
            type: [String, Number],
            default: ''
        },
        evectionDetail: {
            type: Object,
            default: null,
        }
    },
    components: {
        CommonTravelerSelector,
        ColorIcon,
        ShareRoomUserSelectPopup,
    },
    computed: {
        selectedStaffListToShow() {
            const selectedStaffList = this.selectedStaffList;
            let selectedStaffNameList = [];
            let selectedStaffListText = '' ;
            selectedStaffList.forEach(item => {
                selectedStaffNameList.push(item.staffName || item.name)
            })
            const otherUserList = this.otherUserList;
            otherUserList.forEach(item => {
                selectedStaffNameList.push(`${(item.staffName || item.name)}(合住)`)
            })
            selectedStaffListText = selectedStaffNameList.join('、');
            return selectedStaffListText
        },
    },
    mounted() {
        if (this.$route.name === 'h5-train-home') {
            this.typeOff = false;
        }
        const __this = this;

        // this.checkOrder();
        __this.$refs.aCommonTravelerSelector.init({
            __this: __this,
            situation: '100',
            businessType: __this.businessType,
            select_mode: __this.businessType === 2 ? `single` : `multiple`,
            dialog: {
                title: `请选择出行人员`,
            },
            get_params() {
                const p = {
                    applyJourneyId: '',
                };
                return p;
            },
        });
        __this.$refs.aCommonTravelerSelector.$refs.aAvailableTravellerSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log(`aAvailableTravellerSelector.btn_ok_click_event_handler`);
            console.log(args);
            // if (args.selected_entity_list.length >= 2 && !this.typeOff) {
            //     this.$Toast('只能添加一位出行人');
            //     return
            // }

            this.selectedStaffList = args.selected_entity_list;
            this.selectedStaffIdList = args.selected_id_list;
            console.log('1');
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        };
        __this.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log('aTravelerMultipleSelector.btn_ok_click_event_handler');
            console.log(args);
            // if (args.selected_entity_list.length >= 2 && !this.typeOff) {
            //     this.$Toast('只能添加一位出行人');
            //     return
            // }
            let staffList = [];
            let staffIdList = [];
            args.selected_entity_list.forEach((value, index) => {
                if (!value.userId) {
                    value.userId = value.id
                }
                let maxNo = 6;
                if (this.selectedStaffMaxNo === 1 && this.isFreeApprove === 1) {
                    maxNo = 6
                } else {
                    maxNo = this.selectedStaffMaxNo
                }
                if (index < maxNo) {
                    staffList.push(value);
                    staffIdList.push(value.userId)
                }
            });
            this.selectedStaffList = staffList;
            this.selectedStaffIdList = staffIdList;

            this.otherUserList = args.otherSelectedList;
            this.otherUserIdList = args.otherSelectedIdList;
            console.log('2')
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList);
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        };
    },
    activated() {
        console.log('dengliu232323213');
        this.refundChangePolicyGet();
        this.checkOrder();
    },
    watch: {
        travelCurrent(newVal) {
            console.log('触发选人组件', newVal);
            this.checkOrder();
            this.otherUserList = []; // 合住人列表
            this.otherUserIdList = []; // 合住人id列表
            this.allSelectOtherUserLit = [];
            this.shareTravelCurrent = null;
            if (newVal === null) {
                this.selectedStaffList = [];
                this.selectedStaffIdList = [];
                this.travelUserList = [];
                //this.isChangeFactor = true
                this.applyJourneyId = 0;
                if (this.useTravel === 1 && this.selectedStaffMaxNo === 1 && this.isFreeApprove === 0) {
                    this.selectedStaffList = [{
                        userId: this.userInfo.id,
                        staffName: this.userInfo.realName
                    }];
                    this.selectedStaffIdList = [this.userInfo.id]
                }
                console.log('3')
                this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
                this.$emit('getSelectedStaffIdListForHotel', {
                    'selectedStaffIdList': this.selectedStaffIdList,
                    'otherUserIdList': this.otherUserIdList,
                    'shareRoomEvection': this.shareTravelCurrent,
                })
            } else {
                let resultStaffList = [];
                this.selectedStaffList = [];
                this.selectedStaffIdList = [];
                this.travelUserList = [];
                this.allSelectedStaffList = newVal.active.staffList;
                //如果有出差单回显  且 不是从出差单那边跳转过来的
                let type = this.$route.query.type
                if (type !== 'new') {
                    type = 'old'
                }
                // 如果是从出差单跳转过来的，地址栏带type = new； 否则则是预定过程中返回首页的
                if (this.evectionDetail && type === 'old') {
                    // 获取它带回来的出行人
                    let evectionActiveUsser = this.evectionDetail.psgList;
                    console.log('evectionActiveUsser', evectionActiveUsser);
                    let selectedStaffList = [];
                    evectionActiveUsser.forEach(value=>{
                        if (value && value.shareWith === 2) {
                            selectedStaffList.push(value)
                        }
                    })
                    this.selectedStaffList = selectedStaffList;
                } else {
                    let [...staffList] = newVal.active.staffList;
                    // 因公选出差单的情况，不受帮订人数限制
                    console.log('1', staffList);
                    resultStaffList = staffList.filter(value => value.bookStatus === 1);
                    this.selectedStaffList = resultStaffList;
                }

                this.selectedStaffIdList = [...new Set(this.selectedStaffList.map(item => item.userId))];
                console.log('4')
                this.$emit('getSelectedStaffIdList', this.selectedStaffIdList);
                this.$emit('getSelectedStaffIdListForHotel', {
                    'selectedStaffIdList': this.selectedStaffIdList,
                    'otherUserIdList': this.otherUserIdList,
                    'shareRoomEvection': this.shareTravelCurrent,
                })
                this.$refs.aCommonTravelerSelector.init({
                    __this: this,
                    situation: '100',
                    businessType: this.businessType,
                    dialog: {
                        title: `请选择出行人员`,
                    },
                    get_params() {
                        const p = {
                            applyJourneyId: newVal.active.id,
                        };
                        return p;
                    },
                });
            }
        },
    },
    methods: {
        async check() {
            let evectionParams = {
                vm: this,
                situation: '001',
            };
            const evectionQuery = await getEvectionAllType(evectionParams);
            this.evectionDetailType = evectionQuery.evectionDetailType;
            return new Promise(((resolve, reject) => {

                // 如果必传出差单，缺选了出差单
                if (evectionQuery.evectionRequired === 1 && this.travelCurrent !== null && this.selectedStaffIdList.length === 0) {
                    reject({type: '001', msg: '未选择出行人'});
                    return
                }
                if (this.travelCurrent !== null && this.selectedStaffIdList.length === 0) {
                    reject({type: '001', msg: '未选择出行人'});
                    return
                }
                if (this.evectionDetailType === '002') {
                    let staffList = [];
                    this.selectedStaffList.forEach((value) => {
                        staffList.push(value.userId);
                    });
                    let checkParams = {
                        businessType: this.businessType,
                        userIds: staffList
                    };
                    if (staffList.length === 0) {
                        resolve({type: '001'})
                        return;
                    }
                    consumer_apply_checkLoginUserPolicy(checkParams).then(res => {
                        resolve({type: '001'})
                    }, error => {
                        reject({type: '002', msg: error.datas.msg})
                    }).catch(err => {
                        reject({type: '002', msg: err.datas.msg})
                    })
                } else {
                    resolve('001')
                }
            }))
        },

        // 获取出差规则
        refundChangePolicyGet() {
            refund_change_policy_get().then(res => {
                this.evectionRequired = res.datas.evectionRequired;
                this.selectedStaffMaxNo = res.datas.bookUpperBound;
                this.isFreeApprove = res.datas.isFreeApprove || 0;
                this.checkMyself()
            })
        },
        //判断是否已选出差单
        checkOrder() {
            if (this.travelCurrent === null || this.travelCurrent === []) {
                this.isSelectedOrder = false
            } else {
                this.isSelectedOrder = true
            }
        },
        setShareRoomUser(params) {
            this.shareTravelCurrent = params.shareTravelCurrent;
            this.allSelectOtherUserLit = params.allSelectOtherUserLit;
            this.otherUserList = params.otherUserList;
            this.otherUserIdList = params.otherUserIdList;
        },
        //检查是否默认选中自己
        async checkMyself() {
            if (this.selectedStaffMaxNo === 1 || this.isFreeApprove === 1) {
                this.isSelectedMyself = true;
                await this.getUserInfo();
                this.selectedStaffList = [{
                    userId: this.userInfo.id,
                    staffName: this.userInfo.realName
                }];
                this.selectedStaffIdList = [this.userInfo.id];
                this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
                this.$emit('getSelectedStaffIdListForHotel', {
                    'selectedStaffIdList': this.selectedStaffIdList,
                    'otherUserIdList': this.otherUserIdList,
                    'shareRoomEvection': this.shareTravelCurrent,
                })
            } else {
                this.isSelectedMyself = false;
                this.selectedStaffList = [];
                this.selectedStaffIdList = [];
                this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
                this.$emit('getSelectedStaffIdListForHotel', {
                    'selectedStaffIdList': this.selectedStaffIdList,
                    'otherUserIdList': this.otherUserIdList,
                    'shareRoomEvection': this.shareTravelCurrent,
                })
            }
        },
        async getUserInfo() {
            let res = await get_user_info();
            this.userInfo = res.datas
        },
        async showStaffPopup() {
            if (!this.isSelectedOrder) {
                if (this.evectionRequired === 0) {
                    if (this.selectedStaffMaxNo === 1 && this.isFreeApprove === 0) {
                        this.$Toast('只能选自己为出行人');
                        return
                    }
                }
                this.$refs.aCommonTravelerSelector.show()

            } else {
                this.$refs.aCommonTravelerSelector.show()
            }
        },
        toggle(item) {
            if (item.bookStatus === 2) {
                return
            }
            let indexNum = this.selectedStaffIdList.indexOf(item.userId);
            if (indexNum > -1) {
                // 已有，取消
                this.selectedStaffIdList.splice(indexNum, 1)
            } else {
                // 没有，添加
                if (this.otherUserIdList.length > 0 && this.selectedStaffIdList.length > 0) {
                    return;
                }
                this.selectedStaffIdList.push(item.userId)
            }
            console.log('6')
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        },

        hide() {
            const __this = this;
            return __this.$refs.aCommonTravelerSelector.hide();
        },

        get_show() {
            const __this = this;
            return __this.$refs.aCommonTravelerSelector.get_show();
        },
        setActiveUserList(params) {
            // 合住修改
            let selectedStaffIdList = [];
            let selectedOtherStaffIdList = [];
            let paramsTravelUserList = [];
            let paramsOtherTravelUserList = [];
            if (params.travelUserList) {
                params.travelUserList.forEach(value=>{
                    // 判断是合住人还是正常的出行人
                    if (value.shareWith == 1) {
                        paramsOtherTravelUserList.push(value);
                        selectedOtherStaffIdList.push(value.userId);
                    } else {
                        paramsTravelUserList.push(value);
                        selectedStaffIdList.push(value.userId)
                    }
                })
            }
            this.selectedStaffIdList = selectedStaffIdList
            this.otherUserIdList = selectedOtherStaffIdList;
            this.otherUserList = paramsOtherTravelUserList;
            if (!this.travelCurrent) {
                if (this.otherUserList) {
                    this.selectedStaffList = JSON.parse(JSON.stringify(paramsTravelUserList))
                } else {
                    this.selectedStaffList = JSON.parse(JSON.stringify(params.travelUserList))
                }
            }
            console.log('7')
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        },
        getActiveUserIdList() {
            return this.selectedStaffIdList;
        },

        setHotelJourneyToShareRoomAndShowPopup(params) {
            this.$refs.aShareRoomUserSelectPopup.init(params);
            this.$refs.aShareRoomUserSelectPopup.show();
        },

        _showShareRoomUserSelectPopup() {
            if (this.selectedStaffIdList.length > 1) {
                return;
            }
            console.log(this.travelCurrent);
            // 往父组件去获取相关信息
            this.$emit('getJourneyInfo');
        },

        _getShareRoomInfo(item) {
            this.shareTravelCurrent = item;
            let sList = item.active.staffList;
            let otherUserList = [];
            let otherUserIdList = [];
            sList.forEach((value, index) => {
                if (otherUserIdList.length === 0 && value.bookStatus !== 2) {
                    otherUserList.push(value);
                    otherUserIdList.push(value.userId)
                }
            });
            this.allSelectOtherUserLit = sList;
            this.otherUserList = otherUserList;
            this.otherUserIdList = otherUserIdList;
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
                'shareRoomEvection': this.shareTravelCurrent,
            })
        },

        _toggleOther(item) {
            if (item.bookStatus === 2) {
                return;
            }
            let indexNum = this.otherUserIdList.indexOf(item.userId);
            if (indexNum > -1) {
                // 已有，取消
                this.otherUserIdList.splice(indexNum, 1)
            } else {
                // 没有，添加
                if (this.selectedStaffIdList.length > 1) {
                    return;
                }
                if (this.otherUserIdList.length > 0) {
                    return;
                }
                this.otherUserIdList.push(item.userId)
            }
            this.$emit('getSelectedStaffIdList', this.selectedStaffIdList)
            this.$emit('getSelectedStaffIdListForHotel', {
                'selectedStaffIdList': this.selectedStaffIdList,
                'otherUserIdList': this.otherUserIdList,
            })
        },
    }
}
