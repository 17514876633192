// consumer_trains_order_getTrainsOrderList 获取火车票订单列表
const __request = require(`./__request/__request_contentType_json`);

const consumer_trains_order_getTrainsOrderList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/order/getTrainsOrderList',
        data: pParameter
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p));
    })
};
module.exports = consumer_trains_order_getTrainsOrderList;