import EvectionList from "../../components/evection-list/3.0.3/index.vue";
import NavBar from "@/component/nav-bar/import_latest_version_module";
export default {
  components: {
    EvectionList,
    NavBar
  },
  data() {
    return {

    };
  },
  methods: {
    back(){
      // 判断是否在flutter app里
      if (window.getFlutter == true) {
        // 如果在，则向flutter app发送信息
        try {
          window.flutter_inappwebview
              //可以传递你所需要的任意类型数据，数组、对象等
              .callHandler("backHandler", {'type': 'close'})
              .then((res)=>{
                console.log("res==flutter给html的数据", res);
              })
        } catch (e) {}
        return
      }
      this.$router.go(-1)
    }
  },
  created() {},
  activated() {

  },
  deactivated() {},
};
