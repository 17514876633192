import wangyiServerOnline from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import slider_left_back from "@/lib/common-service/slider_left_back/index";// 滑屏返回
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
import consumer_sys_ad_updateAirVipInfo
  from "@/lib/data-service/haolv-default/consumer_sys_ad_updateAirVipInfo";
import {Toast} from "vant";
//保存南航会员号

export default {
  data() {
    return {
      airVipNo: ''
    }
  },
  activated() {
    this.flutterSwitch = window.getFlutter;
    this.keyback();
    wangyiServerOnline.config().then(() => {
      wangyiServerOnline.getUnReadMsgNum({
        callback:(result) => {
          this.readMsgNum = result;
        }
      });
      setTimeout(() => {
        wangyiServerOnline.onlyHide();
      },1)
    });

    fixStatusBar({
      type: `100`,
      selector: `.back-btn`,
    });

    let _this = this;
    fixStatusBar({
      type: `000`,
      handler({default_handler, api}) {
        _this.$refs['bookBtnWrapper'].style.paddingBottom = `${api.safeArea.bottom - 10}px`;
      }
    });

    this.airVipNo = this.$route.query.airVipNo || '';
  },
  destroyed() {
    wangyiServerOnline.config().then(() => {
      wangyiServerOnline.show();
      wangyiServerOnline.destructionGetUnReadMsgNum();
    });
    keyback_processor.removeEventListener();
  },
  methods: {
    //提交
    async submit() {
      if (!this.airVipNo) {
        this.onBack();
        return;
      }
      let {
        userId,
        userType
      } = this.$route.query;
      let params = {
        airVipNo: this.airVipNo,
        userId,
        userType
      }
      let res = await consumer_sys_ad_updateAirVipInfo(params);
      if (res.code === '000000') {
        Toast({
          message: '保存成功'
        });
      }
      this.onBack();
    },

    //前往注册
    toRegister() {
      this.$router.push({
        name: 'h5-advert-detail'
      });
    },

    //返回
    onBack(){
      this.$router.go(-1);
    },

    //滑动返回
    keyback() {
      const __this = this;

      keyback_processor.addEventListener(function () {
        __this.onBack()
      });
    }
  }
}
