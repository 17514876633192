import consumer_trains_common_queryOverweightBecause from '@/lib/data-service/haolv-default/consumer_trains_common_queryOverweightBecause'
import consumer_t_od_hotel_order_interim_checkHotelPolicy from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_checkHotelPolicy";// 酒店差标检测
import consumer_flight_search_flightVerification from '@/lib/data-service/haolv-default/consumer_flight_search_flightVerification'
import consumer_trains_product_checkTrainsPolicy from '@/lib/data-service/haolv-default/consumer_trains_product_checkTrainsPolicy'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Toast} from "vant";

export default {
    name: 'CheckTravel',
    data () {
        return {
            dialogVisible: false,
            businessType: '',
            initParams: {
                businessType: '',
                businessParams: {}
            },
            flightResultList: [],
            hotelResultList: [],
            trainsResultList:[],
            flightExceedingList: [],
            controlRules: '',
            controlParams: {},
            warningList: [],
            promiseResolve: '',
            promiseReject: '',
            reasonParams: {
                reason: '', // 原因
                otherReason: '',
            },
            reasonRule: {
                reason: [
                    { required: true, message: '请选择超标原因', trigger: 'change' }
                ]
            },
            checkFun: null, // 检查的方法
            submitFun: null, // 提交的方法
            cancelFun: null, // 取消选择而关闭窗口触发的方法
            // resultFun: null, // 结果返回的方法
            errorList: [], // 不允许预定的人员列表
            successList: [], // 只做提醒的人员列表
            reason: '', // 原因
            name: '',

        }
    },
    components: {NavBar},
    created () {},
    mounted () {
        this._getReasonList()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {
        getReason() {
            const reasonParams = this.reasonParams;
            if (reasonParams.reason === '') {
                return false
            }
            if (reasonParams.reason.split('-')[0] === '7') {
                return true
            } else {
                return false
            }
        },
    },
    filters: {},
    methods: {
        // 获取各业务的原因
        _getReasonList() {
            consumer_trains_common_queryOverweightBecause().then(res => {
                let flightResultList = res.datas.flightResult;
                flightResultList.forEach(value => {
                    value.value = `${value.code}-${value.msg}`
                });
                this.flightResultList = flightResultList;
                let hotelResultList = res.datas.hotelResult;
                hotelResultList.forEach(value => {
                    value.value = `${value.code}-${value.msg}`
                });
                this.hotelResultList = hotelResultList;
                let trainsResultList = res.datas.trainsResult;
                trainsResultList.forEach(value => {
                    value.value = `${value.code}-${value.msg}`
                });
                this.trainsResultList = trainsResultList;
                let flightExceedingList = res.datas.flightExceedingStandard;
                flightExceedingList.forEach(value => {
                    value.value = `${value.code}-${value.msg}`
                });
                this.flightExceedingList = flightExceedingList;
            })
        },
        check(params) {
            const __this = this
            let p = new Promise(((resolve, reject) => {
                __this.promiseResolve = resolve
                __this.promiseReject = reject
                if (!params) params = {}
                // 判断各入参是否符合标准
                if (!params.businessType) {
                    params.businessType = ''
                }
                if (!params.businessParams) {
                    params.businessParams = {}
                }
                this.businessType = params.businessType
                this.initParams = params
                Toast.loading({
                    duration: 0,
                    message: "",
                    forbidClick: true,
                })
                if (this.businessType === 1) {
                    // 机票
                    consumer_flight_search_flightVerification(this.initParams.businessParams).then(res => {
                        Toast.clear()
                        // 返回null就是全员通过
                        if (!res.datas) {
                            resolve({})
                            return
                        }
                        __this.controlParams = res.datas
                        __this.controlRules = res.datas.controlRules
                        __this.warningList = res.datas.reserveVerificationList
                        // 机票： 1:允许预定,只做提示, 3:不允许预定 10:超标需审批 11:超额自付
                        __this._checkResult()
                    }).finally(() => {
                        Toast.clear()
                    })
                } else if (this.businessType === 2) {
                    // 火车票
                    consumer_trains_product_checkTrainsPolicy(this.initParams.businessParams).then(res => {
                        // 返回null就是全员通过
                        if (!res.datas) {
                            resolve({})
                            return
                        }
                        __this.controlParams = res.datas
                        __this.controlRules = res.datas.controlRules
                        __this.warningList = res.datas.policies
                        // 火车票： 1:允许预定,只做提示, 3:不允许预定 10:超标需审批 11:超额自付
                        __this._checkResult()
                    }).finally(() => {
                        Toast.clear()
                    })
                } else {
                    // 酒店
                    consumer_t_od_hotel_order_interim_checkHotelPolicy(this.initParams.businessParams).then(res => {
                        // 返回null就是全员通过
                        if (!res.datas) {
                            resolve({})
                            return
                        }
                        __this.controlParams = res.datas
                        __this.controlRules = res.datas.controlRules
                        __this.warningList = res.datas.policyInfos
                        // 酒店： 1:允许预定,只做提示, 3:不允许预定 10:超标需审批 11:超额自付
                        __this._checkResult()
                    }).finally(() => {
                        Toast.clear()
                    })
                }
            }))
            return p
        },
        _checkResult() {
            const __this = this
            if (this.controlRules === 3) {
                // 不允许预定
                __this.dialogVisible = true
            } else {
                // 需要填原因
                __this.reasonParams = {reason: '', otherReason: ''};
                __this.warningList.forEach(value => {
                    value.reason = ''
                })
                __this.dialogVisible = true
            }
        },
        _checkForm() {
            const __this = this;
            return new Promise(resolve => {
                this.$refs['policyForm'].validate().then(() => {
                    if (__this.reasonParams.reason.split('-')[0] === '7' && __this.reasonParams.otherReason === '') {
                        Toast.fail('请输入超标原因');
                        resolve(false);
                        return
                    }
                    resolve(true)
                }).catch(() => {
                    resolve(false)
                })
            })
        },
        async _checkErrorTableForm() {
            const __this = this;
            let valid = await __this._checkForm();
            if (valid) {
                let reason = '';
                const reasonList = __this.reasonParams.reason.split('-');
                let reasonNameList = [];
                reasonList.forEach((value, index) => {
                    if (index > 0) {
                        reasonNameList.push(value)
                    }
                })
                if (reasonList[0] === '7') {
                    reason = __this.reasonParams.otherReason
                } else {
                    reason = reasonNameList.join('-')
                }
                if (__this.businessType === 1) {
                    __this.warningList.forEach(value => {
                        value.reason = reason;
                        value.businessType = 1;
                        value.controlRules = __this.controlParams.controlRules;
                        value.userRealName = value.username;
                        value.violationOfReason = __this.controlParams.controlRules;
                        value.seatName = value.standardContent
                    })
                } else if (__this.businessType === 2) {
                    __this.warningList.forEach(value => {
                        value.reason = reason;
                        value.businessType = 2;
                        value.controlRule = __this.controlParams.controlRules;
                        value.overproofType = 1;
                        // value.reserveContent = __this.controlParams.controlRules
                        value.reserveContent = reason
                    })
                } else {
                    __this.warningList.forEach(value => {
                        value.reason = reason;
                        value.businessType = 3;
                        value.controlRule = __this.controlParams.controlRules;
                        value.overproofType = 1;
                        // value.reserveContent = __this.controlParams.controlRules
                        value.reserveContent = reason
                    })
                }

                __this.controlParams.policies = __this.warningList
                __this.dialogVisible = false
                __this.promiseResolve(__this.controlParams)
                // __this.$refs['refuse'].removeEventListener()
            }
        },
        //_checkPass() {},
        _checkErrorTableRefuse() {
            const __this = this;
            __this.dialogVisible = false;
            __this.promiseReject(__this.controlParams)
        },
        _checkErrorTableCancel() {
            const __this = this;
            __this.dialogVisible = false;
            __this.promiseReject()
        },
        getShowStatus() {
            return this.dialogVisible;
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
