import Q from 'q';
import _ from 'underscore';
import moment from "moment";
import get_query_string_object from 'nllib/src/uri/get_query_string_object';
import set_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/set_token";
import check_token from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/check_token';
// import push_helper from '@/lib/common-service/apicloud/ajpush_helper';
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
import app_loading from "@/lib/common-service/app-loading/1.0.1";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import consumer_apply_applyJourneyList from "@/lib/data-service/haolv-default/consumer_apply_applyJourneyList";
import consumer_evection_getEvectionNoByPartnerId
    from "@/lib/data-service/haolv-default/consumer_evection_getEvectionNoByPartnerId";
import consumer_hotel_common_cityList from '@/lib/data-service/haolv-default/consumer_hotel_common_cityList';
import consumer_evection_addOrUpdate from '@/lib/data-service/haolv-default/consumer_evection_addOrUpdate';
import cache_set from "nllib/src/cache/set";
import {Toast} from "vant";
/**
 *
 *
 * redirectType:
 *
 * 301:
 * query需要的值： cityName，checkInDate, checkOutDate
 * eg: /redirect/?redirectType=301&token=bff217fc-391a-46fc-adeb-9766d77eaec8&query={"cityName":"北京","checkInDate":"2024-09-27","checkOutDate":"2024-09-28"}
 * */
export default {
    components: {},
    data() {
        return {}
    },
    methods: {},
    async created() {
        const __this = this;
        let tokenCancelNeedJumpToLogin = 1;

        const aQueryStringObject = get_query_string_object({
            isLowerCase: true,
        });
        console.log(aQueryStringObject);
        if (aQueryStringObject.query) {
            // 前海需求：token过期后不跳转登录页
            // tokenCancelNeedJumpToLogin: 1:默认，token失效就去登录页， 2:不去登录页，留在原地提示
            tokenCancelNeedJumpToLogin = 2;
            cache_set({key: 'tokenCancelNeedJumpToLogin', value: 2});
            // this.$store.commit('setTokenCancelNeedJumpToLogin', 2)
        }

        const process_redirect_type_000 = function () {
            return Q.when()
                .then(function () {
                    return check_token();
                })
                .then(function (data) {
                    const flag = data.flag;
                    if (flag) {
                        // push_helper.bind();
                        __this.$router.replace({
                            name: `h5-home`,
                        });
                        return;
                    }

                    // push_helper.bind_init_user();
                    wangyiServerOnline.hide();
                    if (tokenCancelNeedJumpToLogin === 2) {
                        Toast('登录失败');
                        return;
                    }
                    __this.$router.replace({
                        name: `login`,
                    });
                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })
                ;
        };

        const process_redirect_type_100 = function () {
            const token = aQueryStringObject.token;
            const backUrl = aQueryStringObject.backurl;
            const query = aQueryStringObject.query ? JSON.parse(decodeURIComponent(aQueryStringObject.query)) : {};
            console.log('query:', query);
            if (!token) {
                const msg = `process_redirect_type_100 parameter error, code=001`;
                console.error(msg);
                console.trace();
                alert(msg);
                return Q.reject({msg: msg});
            }

            set_token({token: token});

            return Q.when()
                .then(function () {
                    return check_token();
                })
                .then(function (data) {
                    const flag = data.flag;
                    if (flag) {
                        if (backUrl) {
                            __this.$router.replace({
                                name: backUrl,
                                query: query
                            });
                        } else {
                            __this.$router.replace({
                                name: `h5-home`,
                            });
                        }
                        return;
                    }

                    // push_helper.bind_init_user();
                    wangyiServerOnline.hide()

                    console.log('fa111111');
                    // 判断是否在flutter app里
                    if (window.getFlutter == true) {
                        // 如果在，则向flutter app发送信息
                        try {
                            window.flutter_inappwebview
                                //可以传递你所需要的任意类型数据，数组、对象等
                                .callHandler("backHandler", {'type': 'err'})
                                .then((res)=>{
                                    console.log("res==flutter给html的数据", res);
                                })
                        } catch (e) {}
                        return
                    }
                    if (tokenCancelNeedJumpToLogin === 2) {
                        Toast('登录失败');
                        return;
                    }
                    __this.$router.replace({
                        name: `login`,
                    });
                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })
                ;
        };

        const process_redirect_type_101 = function () {
            // 跳转去无需token的页面
            app_loading.hide();
            const backUrl = aQueryStringObject.backurl;
            const query = aQueryStringObject.query ? JSON.parse(decodeURIComponent(aQueryStringObject.query)) : {};
            console.log('query:', query);
            __this.$router.replace({
                name: backUrl,
                query: query
            });
        };

        const process_redirect_type_200 = async (redirectType) => {
            const token = aQueryStringObject.token;
            console.log(token);
            console.log(aQueryStringObject.query)
            console.log(decodeURIComponent(aQueryStringObject.query))
            let query = aQueryStringObject.query ? JSON.parse(decodeURIComponent(aQueryStringObject.query)) : {};
            console.log('query:', query);
            if (!token) {
                const msg = `process_redirect_type_100 parameter error, code=001`;
                console.error(msg);
                console.trace();
                alert(msg);
                return {msg: msg};
            }

            set_token({token: token});
            let [err, res] = await awaitWrap(check_token());
            console.log(err, res);
            if (err) {
                return;
            }
            const flag = res.flag;
            if (flag) {
                let backUrl = '';
                this.$store.commit('setEvectionBackType', '001');
                switch (redirectType) {
                    case `200`:
                        console.log('200');
                        // 去机票首页
                        localStorage.setItem('fromStationName', query.fromStationName);
                        localStorage.setItem('toStationName', query.toStationName);
                        backUrl = 'h5-flight-home';
                        let [err200, res200] = await awaitWrap(consumer_evection_getEvectionNoByPartnerId({partnerEctionId: query.evectionNo}));
                        if (err200) {
                            return;
                        }
                        query.evectionNo = res200.datas.evectionNo;
                        query.journeyId = res200.datas.applyJourneyId;
                        query.evectionBackType = '001';
                        break;
                    case `201`:
                        // 去机票搜索页
                        localStorage.setItem('fromStationName', query.fromStationName);
                        localStorage.setItem('toStationName', query.toStationName);
                        backUrl = 'flight-search';
                        // 调出差单接口
                        let params = {
                            pageSize: 100,
                            currentPage: 1,
                            status: 2,
                            approveType: 1,
                        };
                        let [jErr, jRes] = await awaitWrap(consumer_apply_applyJourneyList(params));
                        if (jErr) {
                            return;
                        }
                        let businessTravelList = jRes.datas;
                        businessTravelList.forEach(value=>{
                            if (value.evectionNo === query.evectionNo) {
                                query.flightType = 1;
                                query.evectionType = 2;
                                query.workTravelType = 1;
                                query.startRangeDate = value.startDate;
                                query.endRangeDate = value.endDate;
                                query.journeyType = 1;
                                query.journeyId = value.journeys[0]['id'];
                                // 如果没有startDate
                                if (!query.startDate) {
                                    query.startDate = query.startRangeDate;
                                }
                            }
                        })
                        break;
                    case `202`:
                        // 去机票详情页
                        backUrl = 'flight-order-detail';
                        query.jumpType = 'list';
                        break;
                    case `203`:
                        // 去机票改签详情页
                        backUrl = 'flight-change-order-detail';
                        query.evectionType = 2;
                        break;
                    case `204`:
                        // 去机票退票详情页
                        backUrl = 'flight-refund-order-detail';
                        break;
                    case `205`:
                        //  去机票订单列表
                        backUrl = 'h5-flight-order-list';
                        query = {
                            type: '0',
                        };
                        break;
                    case `206`:
                        //  去机票改签订单列表
                        backUrl = 'h5-flight-order-list';
                        query = {
                            type: '2',
                        };
                        break;
                    case `207`:
                        //  去机票改签订单列表
                        backUrl = 'h5-flight-order-list';
                        query = {
                            type: '1',
                        };
                        break;
                    case `301`:
                        // 通过城市名称换城市id
                        let [hErr, hRes] = await awaitWrap(consumer_hotel_common_cityList({countryType: 1}));
                        if (hErr) {
                            return;
                        }
                        let hotelCityList = hRes.citys;
                        let cityId = '';
                        for (let i = 0; i < hotelCityList.length; i++) {
                            if (query.cityName === hotelCityList[i].cityName) {
                                cityId = hotelCityList[i].cityId;
                                break;
                            }
                        }
                        localStorage.setItem('hotelCityName', query.cityName);
                        // 创建出差单
                        if (!query.checkInDate) {
                            query.checkInDate = moment().format('yyyy-MM-DD');
                        }
                        if (!query.checkInDate) {
                            query.checkInDate = moment().add(1, 'days').format('yyyy-MM-DD');
                        }
                        let _params ={
                            applyId: "",
                            endDate: query.checkOutDate,
                            evectionNo: "",
                            evectionType: 2,
                            filterType: 0,
                            location: "",
                            personType: 2,
                            sharedList: [],
                            sharedStatus: 2,
                            startDate: query.checkInDate,
                            travellerList: [],
                        }
                        let [eErr, eRes] = await awaitWrap(consumer_evection_addOrUpdate(_params));
                        if (eErr) {
                            return ;
                        }
                        let evectionNo = eRes.datas;
                        // 拼合跳转query
                        // cityId=70078&cityName=福州&cityCn=福州&isPOIName=false&checkInDate=2024-09-14&checkOutDate=2024-09-15&keyword=&evectionStartDate=&evectionEndDate=&evectionNo=126303618769002&evectionType=2&evectionRequired=0&allowChangeJourneyTime=1&workTravelType=2&journeyType=0&journeyId=&applyId=&pt=&filterType=0
                        query.cityId = cityId;
                        query.cityCn = query.cityName;
                        query.isPOIName = false;
                        query.keyword = '';
                        query.evectionStartDate = '';
                        query.evectionEndDate = '';
                        query.evectionNo = evectionNo;
                        query.evectionType = 2;
                        query.evectionRequired = 0;
                        query.allowChangeJourneyTime = '';
                        query.workTravelType = 2;
                        query.journeyType = 0;
                        query.journeyId = '';
                        query.applyId = '';
                        query.pt = '';
                        query.filterType = '';
                        backUrl = 'h5-hotel-search';
                        break;
                }
                __this.$router.replace({
                    name: backUrl,
                    query: query
                });
                return;
            }

            wangyiServerOnline.hide()
            // 判断是否在flutter app里
            if (window.getFlutter === true) {
                // 如果在，则向flutter app发送信息
                try {
                    window.flutter_inappwebview
                        //可以传递你所需要的任意类型数据，数组、对象等
                        .callHandler("backHandler", {'type': 'err'})
                        .then((res)=>{
                            console.log("res==flutter给html的数据", res);
                        })
                } catch (e) {}
                return
            }
            if (tokenCancelNeedJumpToLogin === 2) {
                Toast('登录失败');
                return;
            }
            __this.$router.replace({
                name: `login`,
            });
        };

        this.$store.commit('setEvectionBackType', '000');
        const redirectType = aQueryStringObject.redirecttype;
        switch (redirectType) {
            case `000`:
                process_redirect_type_000();
                break
            case `100`:
                process_redirect_type_100();
                break;
            case `101`:
                process_redirect_type_101();
                break;
            case `200`:
                // 去机票首页
                await process_redirect_type_200(redirectType);
                break;
            case `201`:
                // 去机票搜索页
                await process_redirect_type_200(redirectType);
                break;
            case `202`:
                // 去机票详情页
                await process_redirect_type_200(redirectType);
                break;
            case `203`:
                // 去机票改签详情页
                await process_redirect_type_200(redirectType);
                break;
            case `204`:
                // 去机票退票详情页
                await process_redirect_type_200(redirectType);
                break;
            case `205`:
                // 去机票订单列表
                await process_redirect_type_200(redirectType);
                break;
            case `206`:
                // 去机票改签订单列表
                await process_redirect_type_200(redirectType);
                break;
            case `207`:
                // 去机票退票订单列表
                await process_redirect_type_200(redirectType);
                break;
            case `300`:
                // 去酒店首页
                await process_redirect_type_200(redirectType);
                break;
            case `301`:
                // 去酒店搜索页
                await process_redirect_type_200(redirectType);
                break;
            default:
                process_redirect_type_000();
                break;


        }
    },
    mounted() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
