import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import moment from "moment/moment";
import TrainTimeTable
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
import NavBar from "@/component/nav-bar/import_latest_version_module";
export default {
    data() {
        return {
            orderNo: '',
            failMsg: '',
            priceInfo: {},
        }
    },
    props: {},
    components: {NavBar, TrainTimeTable, OrangeNavBar},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo || '';
        this.failMsg = this.$route.query.failMsg || '';
        this.getAllPrice();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "星期日";
            } else if (day === 1) {
                return "星期一";
            } else if (day === 2) {
                return "星期二";
            } else if (day === 3) {
                return "星期三";
            } else if (day === 4) {
                return "星期四";
            } else if (day === 5) {
                return "星期五";
            } else if (day === 6) {
                return "星期六";
            } else {
                return "";
            }
        },
    },
    methods: {
        getAllPrice() {
            const params = {
                orderNo: this.orderNo
            };
            consumer_trains_order_getTrainsOrderDetail(params).then(res => {
                let priceInfo = res.datas;
                this.priceInfo = priceInfo;
                this.$refs['aTrainTimeTable'].init({
                    //出发站三字码
                    fromStationCode: this.priceInfo.fromStationCode,
                    //到达站三字码
                    toStationCode: this.priceInfo.toStationCode,
                    //车次号
                    trainCode: this.priceInfo.trainNo,
                    //日期(2020-05-18)
                    trainDate: this.priceInfo.startDate,
                })
            })
        },
        closeCurrentPage() {
            // 去火车票订单详情页
            this.$router.replace({
                name: 'h5-train-order-detail',
                query: {
                    orderNo: this.orderNo,
                    jumpType: 'list'
                }
            });
        },
        clickSiteNum(){
            this.$refs['aTrainTimeTable'].show();
        },
        reactOrder() {
            this.$router.replace({
                name: 'train-search',
                query: {
                    fromStationCode: this.priceInfo.fromStationCode, //初始站ID
                    fromStationName: this.priceInfo.fromStationName, //初始站
                    toStationCode: this.priceInfo.toStationCode, //终点站ID
                    toStationName: this.priceInfo.toStationName, //终点站
                    trainDate: this.priceInfo.startDate, //日期
                    evectionType: this.priceInfo.evectionType, // 1是因私出差，2是因公出差
                    checked: false,
                    allowChangeJourneyTime: this.$store.state.policy.allowChangeJourneyTime,
                    maxDateText: null,
                },
            });
        },
    }
}