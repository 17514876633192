import NavBar from "@/component/nav-bar/import_latest_version_module";
import consumer_web_policyConfig_hotelTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_hotelTravelPolicy'
import moment from "moment";
// 获取差标新
export default {
    data() {
        return {

        }
    },
    props:['show','theFlightInformationList'],
    computed: {
        flightShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        }
    },
    components: {NavBar},
    activated() {
    },
    methods: {
    },
    filters: {
        filterFlightDate(val) {
            return val === '' ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterFlightDaY(val) {
            return val === '' ? '' : moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day()
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '公务舱'
            } else if (val === 3) {
                return '经济舱'
            } else {
                return '不限'
            }
        },
        filterPlaneSize(val) {
            if (val === 1) {
                return '大型机'
            } else if (val === 2) {
                return '中型机'
            } else if (val === 3) {
                return '小型机'
            } else {
                return ''
            }
        },
        convertTime(time) {
            if(time === null || time === '') {
                return;
            }
            // 提取小时数和分钟数
            const hours = parseInt(time.match(/(\d+)小时/)[0]);
            let minutesList = time.match(/(\d+)分钟/);
            if (minutesList == null) {
                minutesList = time.match(/(\d+)分/);
            }
            let minutes = 0;
            if (minutesList.length > 1) {
                minutes = parseInt(minutesList[1]);
            }
            return `${hours}h${minutes}m`;
        }
    },
}
