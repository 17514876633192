// consumer_car_order_checkCreditUsableAmount
const __request = require(`./__request/__request_contentType_json`)
const consumer_car_order_checkCreditUsableAmount = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/car/order/checkCreditUsableAmount',
        data: data
    }
    return __request(pParameter)
}
export default consumer_car_order_checkCreditUsableAmount