import PageHead from "@/page/evection/components/page-head/index.vue";

// 列表为空展示组件
import HaolvEmpty from "@/component/haolv-empty/index.vue";

import admin_api from "@/lib/data-service/yinzhilv-temporary-storage-system/admin_api";

// 获取保险产品列表
import get_insurance_product_list from "@/lib/data-service/yinzhilv-temporary-storage-system/get_insurance_product_list";
// 获取保险产品类型枚举
import get_insurance_enumeration from "@/lib/data-service/yinzhilv-temporary-storage-system/get_insurance_enumeration";

export default {
  components: {
    PageHead,
    HaolvEmpty,
  },
  data() {
    return {
      bgdImg:
        "https://yinzhilv-front-admin-bucket.oss-cn-beijing.aliyuncs.com/2020/11/25/haolv_app_insurance/insurance.png?x-oss-process=style/yinzhilv-default",

      emptyType: "search",

      loading: false,
      typeCode: "",
      tabList: [],

      list: [],
    };
  },
  watch: {
    typeCode(typeCode) {
      this.loading = true;
      this.req_list();
    },
    loading (val) {
      const Toast = this.$Toast;
      if (val) {
        Toast.loading({
          duration: 0,
          forbidClick: true,
          message: '请求中...',
        })
      } else {
        Toast.clear();
      }
    }
  },
  methods: {
    init() {
      this.loading = true;
      get_insurance_enumeration()
        .then((res) => {
          const list = res.data.allInsuranceProductTypes;
          this.tabList = list;
          this.typeCode = list[0].code;
        })
        .catch(() => {
          this.emptyType = "network";
          this.loading = false;
        });
    },
    req_list() {
      this.list = [];
      this.emptyType = "";
      get_insurance_product_list({ typeCode: this.typeCode })
        .then((result) => {
          this.list = result.data.allInsuranceProducts;
          if (!this.list.list) this.emptyType = "search";
          this.loading = false;
        })
        .catch((err) => {
          this.emptyType = "network";
          this.loading = false;
        });
    },

    onRefresh () {
      this.req_list();
    },

    change_tab(val) {
      this.typeCode = val;
    },
    to_insurance(url) {
      this.$browser_open_url(url);
    },
  },
  mounted() {
    this.init();
  },
};
