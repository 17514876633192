import moment from "moment";
import {Dialog} from "vant";

import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
import consumer_flight_search_flightPrice from '@/lib/data-service/haolv-default/consumer_flight_search_flightPrice'
import consumer_flight_order_offers from '@/lib/common-service/consumer_flight_order_offers'
import PolicyTypeLabel from '@/page/travel-book/flight/component/policyTypeLabel/1.0.0/index.vue'
import {hidePolicyLabelCompanyIdList} from "@/lib/ohter/index.js";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
export default {
    name: 'flight-item-component',
    props: {
        index: { // index of current item
            type: Number
        },
        source: { // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default () {
                return {}
            }
        },
        backRule: {
            type: Function,
        },
        submit: {
            type: Function
        },
        psgType: {
            type: [Number, String],
        },
        reactSearch: {
            type: Function
        },
        cabinParams: {
            type: String,
        },
        evectionNo: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            errorText: '',
            returnSecond: 3,
            returnSetInterval: '',
            show: false,

            policyShow:  false,
            teenOldInfo: {},

            companyId: '',
            hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
        }
    },
    components: {
        EvectionStandardExceed,
        PolicyTypeLabel
    },
    created() {
    },
    mounted() {
        this.getUserInfo();
    },
    activated() {
    },
    deactivated() {
        clearInterval(this.returnSetInterval)
    },
    destroyed() {
        clearInterval(this.returnSetInterval)
    },
    watch: {},
    computed: {},
    filters: {
        filterFlightDate(val) {
            return val === '' ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterFlightDaY(val) {
            return val === '' ? '' : moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day()
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '公务舱'
            } else if (val === 3) {
                return '经济舱'
            } else {
                return '不限'
            }
        },
        filterPlaneSize(val) {
            if (val === 1) {
                return '大型机'
            } else if (val === 2) {
                return '中型机'
            } else if (val === 3) {
                return '小型机'
            } else {
                return ''
            }
        },
    },
    methods: {
        getUserInfo() {
            get_user_info().then(res=>{
                console.log(res)
                this.companyId = res.datas.companyId;
            })
        },
        returnFlightParams(item) {
            if (item) {
                return {
                    deptDate: this.source.depDate,
                    evectionNo: this.evectionNo,
                    policyList: [item]
                }
            } else {
                return ''
            }
        },
        clickShowMore(val) {
            val.show === val.productList.length || val.productList.length === 0 ? val.show = 0 : val.show++
        },
        showMore(val, index) {
            val.show > 0 ? val.show = 0 : val.show++
            //this.flightList[index] = val
            if (this.source.policys && this.source.policys.length > 0) {
                return;
            }
            val.loading = true
            let params = {
                "rqData": {
                    "arrAirportCode": this.source.arrAirportCode,
                    "businessExt": this.source.businessExt,
                    "deptAirportCode": this.source.depAirportCode,
                    "deptDate": this.source.depDate,
                    "deptTime": this.source.depTime,
                    "flightNo": this.source.flightNo,
                    "psgType": this.psgType,
                    "shareFlightNo": this.source.sharedFlightNo,
                },
                depCityPortCode: this.source.depCityCode,
                arrCityPortCode: this.source.arrCityCode,
                basePrice: this.source.basePrice,
                ignoreKeyAccountCompany: this.evectionType === 1 ? 1 : '',
                applyId: this.$route.query.applyId ? parseInt(this.$route.query.applyId) : '',
                evectionNo: this.evectionNo,
                accord: this.source.accord
            }
            consumer_flight_search_flightPrice(params).then(res => {
                //let productTypeTit = [] // 收入tit
                let productList = []
                let data = res.rsData
                /*let list = res.rsData.policys
                if (res.rsData.settlement !== null) {
                    val.basePrice = res.rsData.settlement
                }
                if (res.rsData.accord !== null) {
                    val.accord = res.rsData.accord
                }
                list.forEach((value1, index1) => {
                    if (value1.productType === 'QCYX') {
                        value1.sortNum = 0
                    } else {
                        value1.sortNum = 1
                    }
                })
                list.sort((val1, val2) => {
                    return val1.sortNum - val2.sortNum
                })*/
                if (data.flightPolicyList && data.flightPolicyList.length > 0) {
                    if (this.cabinParams !== '') {
                        let flightPolicyList = []
                        let cabinParamsArr = this.cabinParams.split(',')
                        data.flightPolicyList.forEach(value => {
                            if (cabinParamsArr.indexOf(value.cabin[0].cabinRank.toString()) >= 0) {
                                flightPolicyList.push(value)
                            }
                        })
                        if (flightPolicyList.length > 0) {
                            productList.push(flightPolicyList)
                        }
                    } else {
                        productList.push(data.flightPolicyList)
                    }

                }
                if (data.policys && data.policys.length > 0) {
                    if (this.cabinParams !== '') {
                        let policys = []
                        let cabinParamsArr = this.cabinParams.split(',')
                        data.policys.forEach(value => {
                            if (cabinParamsArr.indexOf(value.cabin[0].cabinRank.toString()) >= 0) {
                                policys.push(value)
                            }
                        })
                        if (policys.length > 0) {
                            productList.push(policys)
                        }
                    } else {
                        productList.push(data.policys)
                    }
                }
                val.loading = false
                val.policys = [...data.flightPolicyList, ...data.policys]
                val.productList = productList
            }, error => {
                val.loading = false
                let errorCode = error.data.rsCode;
                this.errorText = error.data.rsMessage || error.data.msg;
                if (errorCode === '090002') {
                    this.show = true
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.$refs.tips.close()
                            this.reactSearch()
                            clearInterval(this.returnSetInterval)
                        }
                    }, 1000)
                } else {
                    Dialog.alert({
                        title: '温馨提示',
                        message: this.errorText,
                    }).then(() => {
                        this.reactSearch()
                    })
                }
            }).catch(() => {
                val.loading = false
            })
        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        closeDialog(action, done) {
            done()
            clearInterval(this.returnSetInterval)
            this.reactSearch()
        },
        showYoungOldLabel(policyItem, policyIndex, productListIndex, item, index) {
            console.log(policyItem, item)
            let flightsList = JSON.parse(JSON.stringify(this.flightsList))
            let params = {
                "businessExt": policyItem.businessExt,
                "depTime": item.depTime,
                "deptCode": item.depAirportCode,
                "deptDate": item.depDate,
                "destCode": item.arrCityCode,
                "flightNo": item.flightNo,
                "policyId": item.policyId,
                "supplierType": policyItem.productType
            }
            consumer_flight_order_offers(params).then(res => {
                const socpAges = res.socpAges
                let msg = '适用年龄：'
                const l = socpAges.length
                for (let i = 0; i < l; i++) {
                    msg+= `${socpAges.minAge} - ${socpAges.maxAge}`
                    if (i + 1 < l) {
                        msg+= `,`
                    }
                }
                Dialog.alert({
                    message: msg,
                    confirmButtonText: '知道了',
                    confirmButtonColor: '#FF9C00'
                }).then(() => {
                    // on close
                });
            })
        },
        showPolicyLabel(policyItem, policyIndex, productListIndex, item, index) {
            this.teenOldInfo = policyItem.teenOldInfo
            this.policyShow = true
        },
        setTypeLabelColor(val) {
            //"supplier": "1,2,3,4,12,13,9,OPL9,SGL1,OPL6,OPL11,OPL18,OPL17,OPL20,OPL16,OPL26,OPL23,OPL30,60,FD,NFD,VARI",
            //"supplierText": "其他渠道(供应商)【平台优选】",
            // "bigCustomers": "QCYX",
            //"bigCustomersText": "企业协议(大客户)",
            //"lowPrice": "41,42,43,44,45,26,YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL1,NSTD,62",
            //"lowPriceText": "低价推荐",
            // "website": "21,22,23,24,25,6,CXF1,WS",
            //"websiteText": "航司官网(官网)",
            //"platformProprietary": "61",
            //"platformProprietaryText": "商旅优选(自营)【商旅推荐】"
            const btoList = ['1','2','3','4','12','13', '9', 'OPL9', 'SGL1','OPL6','OPL11','OPL18','OPL17','OPL20','OPL16','OPL26','OPL23','OPL30','60','FD','NFD','VARI']
            const lprList = ['41','42','43','44','45','26','YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL1','NSTD','62']
            const owList = ['21','22','23','24','25','6','CXF1','WS']
            const qcList = ['QCYX']
            const ptList = ['61']
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type4'
            } else if (owList.indexOf(val) > -1) {
                return 'type3'
            } else if (qcList.indexOf(val) > -1) {
                return 'type7'
            } else if (ptList.indexOf(val) > -1) {
                return 'type2'
            } else {
                return 'type6'
            }
        },
    }
}
