import NavBar from "@/component/nav-bar/import_latest_version_module";
import consumer_hotel_search_getRoomPackageInfo from '@/lib/data-service/haolv-default/consumer_hotel_search_getRoomPackageInfo'
export default {
    data() {
        return {
            active: 0,

            evectionNo: '',
            checkInDate: '',
            checkOutDate: '',
            hotelId: '',
            cityId: '',
            applyId: '',

            hotelStaticInfo: {},
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
        this.active = 0;
        this.evectionNo = this.$route.query.evectionNo;
        this.checkInDate = this.$route.query.checkInDate;
        this.checkOutDate = this.$route.query.checkOutDate;
        this.hotelId = this.$route.query.hotelId;
        this.cityId = this.$route.query.cityId;
        this.applyId = this.$route.query.applyId;
        this.getHotelDetail();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        tabBeforeChange(name){
            if (name === 2) {
                return false
            } else {
                return true
            }
        },
        getHotelDetail() {
            let params = {
                evectionNo: this.evectionNo,
                checkInDate: this.checkInDate,
                checkOutDate: this.checkOutDate,
                hotelId: this.hotelId,
                cityId: this.cityId,
                applyId: this.applyId
            };
            this.$showLoading.show();
            consumer_hotel_search_getRoomPackageInfo(params).then(res=>{
                const data = res.datas;
                this.hotelStaticInfo = data.hotelStaticInfo;
            }).finally(()=>{
                this.$showLoading.hide();
            })
        },
        returnIcon(val) {
            let typeName = val.typeName;
            let iconName = '';
            switch (typeName) {
                case '运动':
                    iconName = 'haolv-enterprise-app-iconsport';
                    break
                case '公共区':
                    iconName = 'haolv-enterprise-app-icontotal';
                    break
                case '停车场':
                    iconName = 'haolv-enterprise-app-iconpark';
                    break
                case '娱乐活动设施':
                    iconName = 'haolv-enterprise-app-iconentertainment';
                    break
                case '餐饮服务':
                    iconName = 'haolv-enterprise-app-iconfoot';
                    break
                case '交通服务':
                    iconName = 'haolv-enterprise-app-iconpark';
                    break
                case '前台服务':
                    iconName = 'haolv-enterprise-app-iconfront-servce';
                    break
                case '清洁服务':
                    iconName = 'haolv-enterprise-app-iconclean';
                    break
                case '无障碍设施服务':
                    iconName = 'haolv-enterprise-app-iconsport';
                    break
                case '商务服务':
                    iconName = 'haolv-enterprise-app-iconbox-edit';
                    break
                default:
                    iconName = 'haolv-enterprise-app-iconsport'
            }
            return iconName;
        }
    }
}
