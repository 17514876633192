import consumer_notice_queryById from "@/lib/data-service/haolv-default/consumer_notice_queryById.js";
import {Dialog} from "vant";

export default {
  data() {
    return {
      id: 0,
      detail: {}
    };
  },
  watch: {},
  methods: {
    //初始化
    init (){
      this.detail = {
        id: 0,
        noticeTitle: '',
        noticeContent: '',
        createTime: ''
      }
    },
    // 获取详情
    async getDetail () {
      try {
        if (this.loading) return
        this.loading = true
        const res = await consumer_notice_queryById({
          id: this.id
        })
        this.detail = res;
      } catch (error) {
        Dialog.alert({
          message: '获取详情失败',
        })
      } finally {
        this.loading = false
      }
    }
  },
  created (){
    this.init()
  },
  activated () {
    this.id = Number(this.$route.query.id)
    this.getDetail()
  }
};