import Q from 'q';
import _ from 'underscore';

export default {
    components: {},
    data() {
        return {
            username: '',
            password: '',
        }
    },
    methods: {
        onSubmit(values) {
            const __this = this;
            __this.$router.push({
                name: `demo-home`,

                //这里transition_name参数不会生效，
                //因为login页面没有使用base-layout
                transition_name: `van-slide-left`,
                // transition_name: `van-fade`,
            });
        },
    },
    created() {
        // const __this = this;
    },
    activated() {
        const __this = this;

        __this.username = ``;
        __this.password = ``;
    },
    deactivated() {
        // const __this = this;
    },
}
