import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/my-reserve',
            name: 'h5-my-reserve',
            component: () => import(/* webpackChunkName: "page-reserve" */ `./1.0.1/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '我的行程',
                    need_head_nav: false,
                    need_head_nav1: false,
                    need_footer_bar: true,
                    secondary_menu: 1,
                    evectionMeta: '1',
                }
            },
        }
    ],
};
