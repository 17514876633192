/*
*改签申请
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/flight/order/orderChange',
        data: data,
        enable_error_alert: false // 临时隐藏
    };
    return __request(pParameter)
}
export default request