// consumer_trains_order_getOrderType 获取火车票订单类型--411
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// https://www.apifox.cn/apidoc/shared-c91c112c-ac85-4f14-8607-3d32d61d8a80/api-55788058
const consumer_trains_order_getOrderType = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/order/getOrderType',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_order_getOrderType;