// 组件
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import EpidemicPolicy from '@/page/travel-book/train/component/epidemicPolicy/1.0.0/index.vue'
// 时刻表
import TrainTimeTable
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
// 12306核验
import AccountProcessStep
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process/1.0.0/index.vue";
// 超标展示组件
import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
// 超标检测组件
import CheckTravel from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/check-travel/1.0.1/index.vue'
// 出差信息选择组件
import TravelPopup from '@/component/travel-popup/1.0.2/index.vue'

//方法
// 滑屏返回
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import {hidePolicyLabelCompanyIdList} from "@/lib/ohter/index.js";

// 接口
// 余票查询
import consumer_trains_product_checkTheRemainingTrainTicketsDetail
    from "@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTicketsDetail";
import consumer_trains_order_addJourneyPC from '@/lib/data-service/haolv-default/consumer_trains_order_addJourneyPC';
// 因私预订
import consumer_trains_order_addJourneyForPersonalPC
    from '@/lib/data-service/haolv-default/consumer_trains_order_addJourneyForPersonalPC';
// 出差规则查询
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
// 获取登录人信息
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
// 我的出差
import web_policyConfig_userTravelPolicy from "@/lib/data-service/haolv-default/web_policyConfig_userTravelPolicy";
// 乘车人列表
import consumer_trains_passenger_passengerList
    from "@/lib/data-service/haolv-default/consumer_trains_passenger_passengerList";
// 【576】获取出行人差标
import consumer_web_policyConfig_getUserTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getUserTravelPolicy";


// vant
import {Toast, Dialog} from "vant";
// moment
import moment from 'moment';
// Q
import Q from 'q'

export default {
    data() {
        return {
            policyLoading: false,
            netErr: false,
            // 根据ID判断是否显示差标信息的ID集合
            hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
            companyId: null,
            showBiao: false,  //差标提示组件
            showBiaoData: {},  //差标提示信息
            workTravelType: null,
            evectionType: null,
            activeChooseUserList: null,
            checked: null,
            allowChangeJourneyTime: null,
            redirectType: null,
            trainOrderNo: null,
            evectionRequired: null,
            journeyId: null,
            journeyType: null,
            businessRuleControl: null,
            applyId: null,
            evectionNo: '',
            trainParams: [],       //传入超标信息
            failDialogVisible: false,  // 余票查询为空数组--提醒dialog
            // failDialogVisible: true,  // 余票查询为空数组--提醒dialog
            resultList: [{
                fromStationName: null,
                fromTime: null,
                trainCode: null,
                spanTime: null,
                toStationName: null,
                toTime: null,
                arriveDate: null,
                trainSeatVoList: [],
            }],
            title: '车次详情',
            maxDate: '2044-12-12',            // 日历组件--可选最大日期
            minDate: null,            // 日历组件--可选最小日期
            fromStationCode: null,
            toStationCode: null,
            trainDate: null,
            trainNo: null,
            calendarShow: false,  // 日历组件Show
            currentPolicyIndex: null,
            // 抢票授权
            robTicketControl: 0,
        }
    },
    components: {
        OrangeNavBar,
        NavBar,
        EpidemicPolicy,
        TrainTimeTable,
        AccountProcessStep,
        EvectionStandardExceed,
        CheckTravel,
        TravelPopup
    },
    methods: {
        //卧铺提示
        clickLieText(item){
            if (item.seatName.indexOf('卧') == -1) {
                return;
            }
            Dialog.alert({
                title: '温馨提示',
                message: '展示的票价为上铺成人票价，实际以占座成功的价格为准',
                confirmButtonText: '我知道了',
                confirmButtonColor: '#FF7002',
                className: 'reserve-back-warning-dialog',
            })
                .then(() => {
                    // on confirm
                })
                .catch(() => {
                    // on cancel
                });
        },
        //差标提示
        clickBiao() {
            this.showBiao = true;
            this.policyLoading = true;

            let dataBox = {
                applyJourneyId: this.journeyId ? this.journeyId : '',
                businessType: 2,
                evectionNo: this.evectionNo ? this.evectionNo : '',
                userIds: this.$route.query.activeChooseUserList ? this.$route.query.activeChooseUserList.split(',') : [],
            };
            consumer_web_policyConfig_getUserTravelPolicy(dataBox).then(res =>{
                this.showBiaoData.trainsPolicy = res.datas.trainsMsg.trainsPolicy;
                this.showBiaoData.trainsPolicyText = res.datas.trainsMsg.trainsPolicyText;
                this.showBiaoData.cGSeatName = res.datas.trainsMsg.cGSeatName;
                this.showBiaoData.dSeatName = res.datas.trainsMsg.dSeatName;
                this.showBiaoData.seatName = res.datas.trainsMsg.seatName;
                this.showBiaoData.trainsControlRulesText = res.datas.trainsMsg.trainsControlRulesText;
                this.showBiaoData.travelerNames = res.datas.travelerNames;
            }).finally(()=>{
                this.policyLoading = false;
            });
            // if (this.activeChooseUserList) {
            //
            // } else if (this.showBiaoData.trainsPolicyText == undefined) {
            //     web_policyConfig_userTravelPolicy().then(res => {
            //         this.showBiaoData = res.datas;
            //     })
            // }
        },
        // 出差规则查询
        refundChangePolicyGet() {
            refund_change_policy_get().then(res => {
                this.robTicketControl = res.datas.robTicketControl ? res.datas.robTicketControl : 0;
                this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime ? res.datas.allowChangeJourneyTime : 0;
            });
        },
        // 计算时间差
        timeDifference(data) {
            let dateStart = moment(data.arriveDate).format('YYYY-MM-DD ') + data.fromTime + ':00';
            let dateEnd = moment().format('YYYY-MM-DD HH:mm' + ':00');

            let dateResult = moment(dateStart).diff(moment(dateEnd), 'minutes');
            if (dateResult <= 30) {
                // 灰色预订
                return 'book-grey'
            } else {
                // 抢票
                return 'snatch-tickets'
            }
        },
        // 组装当前页面url
        locationHref(timeDate) {
            let href = location.href.split('?')[0];
            let newUrl = `` +
                `${href}?` +
                `fromStationCode=${this.fromStationCode}&` +
                `toStationCode=${this.toStationCode}&` +
                `trainDate=${timeDate}&` +
                `trainNo=${this.trainNo}&` +
                `maxDate=${this.maxDate}&` +
                `minDate=${this.minDate}&` +
                `evectionNo=${this.evectionNo}&` +
                `evectionRequired=${this.evectionRequired}&` +
                `journeyId=${this.journeyId}&` +
                `businessRuleControl=${this.businessRuleControl}&` +
                `applyId=${this.applyId}&` +
                `workTravelType=${this.workTravelType}&` +
                `evectionType=${this.evectionType}&` +
                `redirectType=${this.redirectType}`;

            history.replaceState(null, null, newUrl);
        },
        // 日历组件选中
        selectCalendar(day) {
            this.$showLoading.show();
            let timeDate = moment(day).format('YYYY-MM-DD');
            // 组装当前页面url
            this.locationHref(timeDate);

            // 更改时间
            this.trainDate = timeDate;
            let dataPost = null;
            if (this.evectionType == 2) {
                dataPost = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                    applyId: this.applyId,
                    evectionNo: this.evectionNo,
                };
            } else {
                dataPost = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                };
            }
            this.netErr = false;
            consumer_trains_product_checkTheRemainingTrainTicketsDetail(dataPost).then((res) => {
                this.$showLoading.hide();
                if (res.resultList.length == 0) {
                    this.failDialogVisible = true;
                    return
                }
                this.resultList = res.resultList;
                this.initEvectionStandardExceed();
            }).catch(() => {
                this.netErr = true;
                this.$showLoading.hide();
            }).finally(() => {
                this.$showLoading.hide();
            });
            // this.initEpidemicPolicy();
            this.initTrainTimeTable();
            this.calendarShow = false;
        },
        // 今天、明天
        DateDiff(date) {
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(date).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == this.trainDate) {
                text = '今天';
            }

            if (tomorrow == todayEnd) {
                text = '明天';
            }

            if (afterTomorrow == todayEnd) {
                text = '后天';
            }

            return text

        },
        // 星期
        format_week(val) {
            switch (moment(val).day()) {
                case 0:
                    return '星期天';
                    break;
                case 1:
                    return '星期一';
                    break;
                case 2:
                    return '星期二';
                    break;
                case 3:
                    return '星期三';
                    break;
                case 4:
                    return '星期四';
                    break;
                case 5:
                    return '星期五';
                    break;
                case 6:
                    return '星期六';
                    break;
            }
        },
        // 时间拼接
        timeTexSplicing(date) {
            if (date != null) {
                // 月日
                let monthDay = moment(date).format('MM-DD');
                // 星期
                let weekText = this.format_week(date);
                // 今天、明天、后天
                let timeName = this.DateDiff(date);

                return `${monthDay} ${weekText} ${timeName}`
            }

        },
        //前一天(true)后一天(false)--前置判断(不可单击时置灰)
        ifDateSide(val) {
            let switchBox = null;
            if (val) {
                // 前一天
                // newDate--当前日期
                // trainDate--所选日期
                // minDate--出差单最小日期
                // 所选日期 >= 当前日期
                // 所选日期 >= 出差单最小日期
                // 当前日期 >= 出差单最小日期
                let newDate = moment(new Date()).format('YYYY-MM-DD');
                if (this.minDate <= newDate) {
                    //出差单最小日期在当前日期之前--当前日期为最小值
                    if (newDate == this.trainDate) {
                        switchBox = true;
                    } else {
                        switchBox = false;
                    }
                }

                if (this.minDate > newDate) {
                    //出差单最小日期在当前日期之后--出差单最小日期为最小值
                    if (this.trainDate == this.minDate) {
                        switchBox = true;
                    } else {
                        switchBox = false;
                    }
                }
            } else {
                // 后一天
                let newDate = moment(new Date(this.trainDate)).format('YYYY-MM-DD');
                if (newDate == this.maxDate) {
                    switchBox = true;
                } else {
                    switchBox = false;
                }
            }
            // 已经选择出差单，并且出差规则行程时间限制为禁止修改
            if (this.journeyId != null && this.journeyId != 0 && this.allowChangeJourneyTime == 0) {
                switchBox = true;
            }

            return switchBox
        },
        //前一天(true)后一天(false)--click事件
        clickDate(val) {
            let timeDate = null;
            if (val) {
                // 前一天
                timeDate = moment(new Date(this.trainDate)).subtract(1, 'day').format('YYYY-MM-DD');
            } else {
                // 后一天
                timeDate = moment(new Date(this.trainDate)).add(1, 'day').format('YYYY-MM-DD');
            }
            // 组装当前页面url
            this.locationHref(timeDate);

            // 更改时间
            this.trainDate = timeDate;
            this.initRequest();
        },
        initRequest() {
            this.$showLoading.show();
            let dataPost = null;
            if (this.evectionType == 2) {
                dataPost = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                    applyId: this.applyId,
                    evectionNo: this.evectionNo,
                };
            } else {
                dataPost = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                };
            }
            this.netErr = false;
            consumer_trains_product_checkTheRemainingTrainTicketsDetail(dataPost).then((res) => {
                this.$showLoading.hide();
                if (res.resultList.length == 0) {
                    this.failDialogVisible = true;
                    return
                }
                this.resultList = res.resultList;
                this.initEvectionStandardExceed();
                this.autoClick();
            }).catch(() => {
                this.netErr = true;
                this.$showLoading.hide();
            }).finally(() => {
                this.$showLoading.hide();
            });
            this.initTrainTimeTable();
        },
        // 日历组件
        timeCalenderClick() {
            this.calendarShow = true;
        },
        clickSiteNum() {
            this.$refs['aTrainTimeTable'].show();
        },
        //整合超标信息
        initEvectionStandardExceed() {
            this.resultList[0].trainSeatVoList.forEach((TItem, TIndex) => {
                this.trainParams.push({
                    evectionNo: this.evectionNo,
                    motorcycleType: this.resultList[0].motorcycleType,
                    seatName: TItem.seatName
                })
            });
        },
        // 防疫政策组件
        initEpidemicPolicy() {
            this.$refs['epidemicPolicy'].init({
                startCityCode: this.fromStationCode,
                endCityCode: this.toStationCode
            });
        },
        // 火车票时刻表组件
        initTrainTimeTable() {
            this.$refs['aTrainTimeTable'].init({
                //出发站三字码
                fromStationCode: this.fromStationCode,
                //到达站三字码
                toStationCode: this.toStationCode,
                //车次号
                trainCode: this.trainNo,
                //日期(2020-05-18)
                trainDate: this.trainDate,
            })
        },
        //maxDate为空，制造最大值
        dateMax() {
            let dateBox = moment(new Date()).format('YYYY-MM-DD').split('-');
            dateBox[0] = Number(dateBox[0]) + 10;

            return `${dateBox[0]}-${dateBox[1]}-${dateBox[2]}`
        },
        //因公出行弹框--选择后点击确认按钮返回事件
        updateEvectionInfo(val) {
            // editType: string, 编辑类型，
            // '000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单),
            // '001': 变更出差单（有出差单->无出差单）
            // '002' 修改原本出差单内容
            // 原地刷新、返回列表页面
            let __this = this;
            if (val.editType === '002') {
                if (val.difference == '002' || val.difference == '001' || val.difference == '003') {
                    this.evectionNo = val.evectionNo;
                    this.journeyId = val.journeyId;
                    this.initRequest();
                }
            } else if (val.editType === '000') {
                if (val.difference == '002') {
                    this.evectionNo = val.evectionNo;
                    this.journeyId = val.journeyId;
                    this.journeyType = val.journeyType;
                    this.workTravelType = val.workTravelType;
                    this.maxDate = val.travelCurrent.endDate;
                    this.minDate = val.travelCurrent.startDate;
                    this.initPageNoninductive();
                    return
                }

                // 验证是否获取到了上页的url
                this.$nextTick(() => {
                    this.$router.push({
                        name: "train-search",
                        query: {
                            // 注意不限行程
                            fromStationCode: val.travelCurrent.active.departCityId || this.fromStationCode,
                            fromStationName: val.travelCurrent.active.departCityName || this.resultList[0].fromStationName,
                            toStationCode: val.travelCurrent.active.toCityId || this.toStationCode,
                            toStationName: val.travelCurrent.active.toCityName || this.resultList[0].toStationName,
                            trainDate: val.travelCurrent.active.tripDate || val.travelCurrent.active.departTime,
                            activeChooseUserList: this.activeChooseUserList,
                            workTravelType: val.workTravelType,
                            evectionNo: val.evectionNo,
                            journeyId: val.journeyId,
                            journeyType: val.journeyType,
                            redirectType: this.redirectType,
                            evectionType: this.evectionType,
                            checked: this.checked,
                            evectionRequired: this.evectionRequired,
                            applyId: this.applyId,
                            maxDateText: val.travelCurrent.endDate,
                            minDateText: val.travelCurrent.startDate,
                            allowChangeJourneyTime: this.allowChangeJourneyTime,
                        }
                    });
                });
            } else if (val.editType === '001') {
                this.$router.push({
                    name: "train-search",
                    query: {
                        fromStationCode: this.fromStationCode,
                        fromStationName: this.resultList[0].fromStationName,
                        toStationCode: this.toStationCode,
                        toStationName: this.resultList[0].toStationName,
                        trainDate: this.trainDate,
                        activeChooseUserList: this.activeChooseUserList,
                        workTravelType: val.workTravelType,
                        evectionNo: val.evectionNo,
                        journeyId: val.journeyId,
                        journeyType: val.journeyType,
                        redirectType: this.redirectType,
                        evectionType: this.evectionType,
                        checked: this.checked,
                        evectionRequired: this.evectionRequired,
                        applyId: this.applyId,
                        maxDateText: '',
                        minDateText: '',
                        allowChangeJourneyTime: this.allowChangeJourneyTime,
                    }
                });
            }
        },
        clickDisabled(policys, index) {
            this.currentPolicyIndex = null;
            if (!policys.bookStatus) {
                let deptCityId = this.resultList[0].fromStationCode;
                let arrCityId = this.resultList[0].toStationCode;
                let deptTime = moment(this.resultList[0].arriveDate).format('YYYY-MM-DD');
                this.currentPolicyIndex = index;
                this.$refs['travelPopup'].init({
                    popupType: '001',   //000--右上角展示按钮，001--置灰展示按钮
                    evectionNo: this.evectionNo,
                    evectionRequired: Number(this.$route.query.evectionRequired),
                    journeyId: Number(this.journeyId),
                    businessRuleControl: Number(this.businessRuleControl),
                    trainInfo: {
                        deptCityId,
                        arrCityId,
                        deptTime
                    }
                });
            }
        },
        clickDisabledSnatch(policys, index) {
            if (this.robTicketControl == 0) {
                Dialog.alert({
                    title: '提示',
                    message: '当前企业未授权抢票，请联系管理员',
                }).then(() => {
                    this.$router.go(-1);
                });
            } else if (this.robTicketControl == 1) {
                this.currentPolicyIndex = null;
                if (!policys.bookStatus) {
                    this.currentPolicyIndex = index;
                    let deptCityId = this.resultList[0].fromStationCode;
                    let arrCityId = this.resultList[0].toStationCode;
                    let deptTime = moment(this.resultList[0].arriveDate).format('YYYY-MM-DD');
                    this.$refs['travelPopup'].init({
                        popupType: '001',   //000--右上角展示按钮，001--置灰展示按钮
                        evectionNo: this.evectionNo,
                        evectionRequired: Number(this.$route.query.evectionRequired),
                        journeyId: Number(this.journeyId),
                        businessRuleControl: Number(this.businessRuleControl),
                        trainInfo: {
                            deptCityId,
                            arrCityId,
                            deptTime
                        }
                    });
                }
            }
        },
        //因私预订click
        clickPersonal(item, index) {
            // let ToastBox = Toast.loading({
            //   message: '加载中...',
            //   forbidClick: true,
            // });
            this.$showLoading.show();
            this.$refs.aAccountProcessStep.run()
                .then(res => {
                    this.$showLoading.hide();
                    this.onPersonal(item, index);
                }).finally(() => {
                this.$showLoading.hide();
            })

        },
        // 因公抢票click
        snatchTickets(item, index) {
            console.log(item);
        },
        //因公预订click
        clickSubscribe(item, index, text) {
            let textBox = text ? text : '';

            if (textBox == 'snatch-tickets' && this.robTicketControl == 0) {
                Dialog.alert({
                    title: '提示',
                    message: '当前企业未授权抢票，请联系管理员',
                }).then(() => {
                    this.$router.go(-1);
                });
                this.$showLoading.hide();
                return
            }

            // 001:正常 '002':需检验， '003'：账号密码变更 '004': 未授权
            //12306账号检测组件
            this.$refs.aAccountProcessStep.run()
                .then(res => {
                    //超标检测
                    this.$showLoading.show();
                    this.$refs.checkTravelBox.check({
                        businessType: 2,
                        businessParams: {
                            evectionNo: this.evectionNo,
                            motorcycleType: this.resultList[0].motorcycleType,
                            seatName: this.trainParams[index].seatName,
                        }
                    }).then(res => {
                        //超标检测完成
                        this.$showLoading.hide();
                        this.onReserve(item, res.policies, index);
                    })
                })
                .finally(() => {
                    this.$showLoading.hide();
                });
        },
        //右上角按钮单击事件
        editEvection() {
            this.currentPolicyIndex = null;
            let deptCityId = this.resultList[0].fromStationCode;
            let arrCityId = this.resultList[0].toStationCode;
            let deptTime = moment(this.resultList[0].arriveDate).format('YYYY-MM-DD');
            this.$refs['travelPopup'].init({
                popupType: '000',   //000--右上角展示按钮，001--置灰展示按钮
                evectionNo: this.evectionNo,
                evectionRequired: Number(this.evectionRequired),
                journeyId: Number(this.journeyId),
                businessRuleControl: this.businessRuleControl,
                trainInfo: {
                    deptCityId,
                    arrCityId,
                    deptTime
                }
            })
        },
        // 预订--跳转--因私
        onPersonal(item, sindex) {
            let _trainParams = item;
            _trainParams.sIndex = sindex;
            const __this = this;
            let buyTicketsType = 0;
            if (_trainParams.isSale === '1' && parseInt(_trainParams.trainSeatVoList[_trainParams.sIndex].seatInventory) > 0) {
                buyTicketsType = 0;
            } else if (_trainParams.isSale === '1' && parseInt(_trainParams.trainSeatVoList[_trainParams.sIndex].seatInventory) <= 0) {
                buyTicketsType = 4;
            } else {
                buyTicketsType = 5;
            }
            let [...robTicketNote] = _trainParams.trainSeatVoList;
            let params = {
                acceptNoSeat: 1, // 是否接受无座: 1：接受 0：不接受
                fromStationCode: _trainParams.fromStationCode,
                fromStationName: _trainParams.fromStationName,
                fromTime: _trainParams.fromTime,
                seatCode: _trainParams.trainSeatVoList[_trainParams.sIndex].seatCode,
                seatName: _trainParams.trainSeatVoList[_trainParams.sIndex].seatName,
                seatPrice: _trainParams.trainSeatVoList[_trainParams.sIndex].seatPrice,
                canChooseSeat: _trainParams.trainSeatVoList[_trainParams.sIndex].canChooseSeat == true ? 1 : 2,
                numberOfSeats: _trainParams.trainSeatVoList[_trainParams.sIndex].numberOfSeats,
                startDate: __this.$moment(_trainParams.trainStartDate).format('YYYY-MM-DD'),
                toStationCode: _trainParams.toStationCode,
                toStationName: _trainParams.toStationName,
                trainCode: _trainParams.trainCode,
                arriveDate: __this.$moment(_trainParams.arriveDate).format('YYYY-MM-DD'),
                buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
                toTime: _trainParams.toTime,
                trainNo: _trainParams.trainNo,
                evectionNo: __this.evectionNo,
                robTicketNote: JSON.stringify(robTicketNote),
            };
            consumer_trains_order_addJourneyForPersonalPC(params).then((res) => {
                __this.$router.push({
                    name: 'train-reserve-private',
                    query: {
                        evectionNo: res.datas.evectionNo,
                        orderNo: res.datas.orderNo,
                    }
                });
            })

        },
        // 预订--跳转--因公
        onReserve(item, policies, sindex) {
            let reserveSubmitFun = (warningList) => {
                this.$showLoading.show();
                let passengerListData = {
                    applyId: this.applyId,
                    evectionNo: this.evectionNo,
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    fromStationName: this.resultList[0].fromStationName,
                    toStationName: this.resultList[0].toStationName,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                };
                consumer_trains_passenger_passengerList(passengerListData)
                    .then(res => {
                        let itemBox = item;
                        itemBox.sIndex = sindex;
                        let buyTicketsType = 0;
                        if (itemBox.isSale === '1' && parseInt(itemBox.trainSeatVoList[itemBox.sIndex].seatInventory) > 0) {
                            buyTicketsType = 0
                        } else if (itemBox.isSale === '1' && parseInt(itemBox.trainSeatVoList[itemBox.sIndex].seatInventory) <= 0) {
                            buyTicketsType = 4
                        } else {
                            buyTicketsType = 5
                        }

                        if (itemBox.trainSeatVoList == undefined) {
                            return
                        }

                        let [...robTicketNote] = itemBox.trainSeatVoList;

                        let params = {
                            controlRules: this.$refs.checkTravelBox.controlRules,
                            acceptNoSeat: 1, // 是否接受无座: 1：接受 0：不接受
                            fromStationCode: itemBox.fromStationCode,
                            fromStationName: itemBox.fromStationName,
                            fromTime: itemBox.fromTime,
                            seatCode: itemBox.trainSeatVoList[itemBox.sIndex].seatCode,
                            seatName: itemBox.trainSeatVoList[itemBox.sIndex].seatName,
                            seatPrice: itemBox.trainSeatVoList[itemBox.sIndex].seatPrice,
                            canChooseSeat: itemBox.trainSeatVoList[itemBox.sIndex].canChooseSeat === true ? 1 : 2,
                            numberOfSeats: itemBox.trainSeatVoList[itemBox.sIndex].numberOfSeats,
                            startDate: this.$moment(itemBox.trainStartDate).format('YYYY-MM-DD'),
                            toStationCode: itemBox.toStationCode,
                            toStationName: itemBox.toStationName,
                            trainCode: itemBox.trainCode,
                            arriveDate: this.$moment(itemBox.arriveDate).format('YYYY-MM-DD'),
                            buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
                            toTime: itemBox.toTime,
                            trainNo: itemBox.trainNo,
                            evectionNo: this.evectionNo,
                            overproofApplyList: warningList,
                            robTicketNote: JSON.stringify(robTicketNote),
                            orderNo: this.orderNo,
                            journeyId: this.$route.query.journeyId
                        };
                        consumer_trains_order_addJourneyPC(params).then(res => {
                            this.trainOrderNo = res.datas.orderNo;
                            this.evectionNo = res.datas.evectionNo;
                            this.$showLoading.hide();
                            //分离普通预订、抢票预订
                            if (itemBox.isSale === '1' && parseInt(itemBox.trainSeatVoList[itemBox.sIndex].seatInventory) > 0) {
                                //普通预订
                                this.$router.push({
                                    name: 'train-reserve',
                                    query: {
                                        trainOrderNo: this.trainOrderNo,
                                        workTravelType: this.workTravelType,
                                        evectionNo: this.evectionNo,
                                        evectionType: this.evectionType,
                                        redirectType: this.redirectType,
                                        motorcycleType: this.trainParams.motorcycleType,
                                    }
                                })
                            } else {
                                //抢票预订、预约抢
                                this.$router.push({
                                    name: 'train-gradding',
                                    query: {
                                        trainOrderNo: this.trainOrderNo,
                                        evectionNo: this.evectionNo,
                                        workTravelType: this.workTravelType,
                                        evectionType: this.evectionType,
                                        redirectType: this.redirectType,
                                        motorcycleType: this.trainParams.motorcycleType,
                                    }
                                })
                            }

                        }).catch(() => {
                            return null
                        });
                    })
                    .catch((rej) => {
                        this.$showLoading.hide();
                        if (rej.data.code == '300001') {
                            Dialog.confirm({
                                title: '温馨提示',
                                message: rej.data.msg,
                                cancelButtonText: '关闭',
                                confirmButtonText: '去核验',
                                confirmButtonColor: '#FF7002',
                                className: 'reserve-back-warning-dialog',
                            })
                                .then(() => {
                                    // on confirm
                                    this.$router.push({
                                        name: 'h5-train-account-check'
                                    });
                                })
                                .catch(() => {
                                    // on cancel
                                });


                        }
                    });
            };

            reserveSubmitFun(policies);

        },
        init() {
            // let ToastBox = Toast.loading({
            //   message: '加载中...',
            //   forbidClick: true,
            // });
            this.$showLoading.show();
            this.fromStationCode = this.$route.query.fromStationCode;
            this.toStationCode = this.$route.query.toStationCode;
            this.trainDate = this.$route.query.trainDate;
            this.trainNo = this.$route.query.trainNo;
            this.evectionNo = this.$route.query.evectionNo;
            this.applyId = this.$route.query.applyId;
            this.evectionRequired = this.$route.query.evectionRequired;
            this.journeyId = this.$route.query.journeyId;
            this.businessRuleControl = this.$route.query.businessRuleControl;

            this.workTravelType = this.$route.query.workTravelType;
            this.evectionType = this.$route.query.evectionType;
            this.redirectType = this.$route.query.redirectType;

            this.activeChooseUserList = this.$route.query.activeChooseUserList;
            this.checked = this.$route.query.checked;
            this.allowChangeJourneyTime = this.$route.query.allowChangeJourneyTime;

            this.maxDate = this.$route.query.maxDate ? this.$route.query.maxDate : this.dateMax();
            this.minDate = this.$route.query.minDate ? this.$route.query.minDate : moment(new Date()).format('YYYY-MM-DD');
            if (moment(this.$route.query.minDate).isBefore(moment(new Date()))) {
                this.minDate = moment(new Date()).format('YYYY-MM-DD');
            }

            get_user_info().then(res => {
                this.companyId = res.datas.companyId;
            });
            let data = null;
            if (this.evectionType == 2) {
                data = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                    applyId: this.applyId,
                    evectionNo: this.evectionNo,
                };
            } else {
                data = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                };
            }
            this.netErr = false;
            consumer_trains_product_checkTheRemainingTrainTicketsDetail(data).then((res) => {
                if (res.resultList.length == 0) {
                    this.$showLoading.hide();
                    this.failDialogVisible = true;
                    return
                }
                res.resultList.filter((item) => {
                    if (item.fromStationCode == this.fromStationCode && item.toStationCode == this.toStationCode) {
                        console.log(item);
                        this.resultList = [item];
                    }
                });
                this.$showLoading.hide();
                this.initEvectionStandardExceed();
                this.autoClick();
            }).catch(() => {
                this.netErr = true;
                this.$showLoading.hide();
            }).finally(() => {
                this.$showLoading.hide();
            });

            this.refundChangePolicyGet();
            // this.initEpidemicPolicy();
            this.initTrainTimeTable();
        },
        initPageNoninductive() {
            let data = null;
            if (this.evectionType == 2) {
                data = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                    applyId: this.applyId,
                    evectionNo: this.evectionNo,
                };
            } else {
                data = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                };
            }
            this.netErr = false;
            consumer_trains_product_checkTheRemainingTrainTicketsDetail(data).then((res) => {
                if (res.resultList.length == 0) {
                    // ToastBox.clear();
                    this.$showLoading.hide();
                    this.failDialogVisible = true;
                    return
                }
                res.resultList.filter((item) => {
                    if (item.fromStationCode == this.fromStationCode && item.toStationCode == this.toStationCode) {
                        console.log(item);
                        this.resultList = [item];
                    }
                });
                this.initEvectionStandardExceed();
                this.autoClick();
            }).catch(() => {
                this.netErr = true;
                this.$showLoading.hide();
            }).finally(() => {
                this.$showLoading.hide();
            });
            this.refundChangePolicyGet();
            this.initTrainTimeTable();
        },
        initInfo() {
            this.$showLoading.show();
            let data = null;
            if (this.evectionType == 2) {
                data = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                    applyId: this.applyId,
                    evectionNo: this.evectionNo,
                };
            } else {
                data = {
                    fromStationCode: this.fromStationCode,
                    toStationCode: this.toStationCode,
                    trainDate: this.trainDate,
                    trainNo: this.trainNo,
                };
            }
            this.netErr = false;
            consumer_trains_product_checkTheRemainingTrainTicketsDetail(data).then((res) => {
                if (res.resultList.length == 0) {
                    this.$showLoading.hide();
                    this.failDialogVisible = true;
                    return
                }
                res.resultList.filter((item) => {
                    if (item.fromStationCode == this.fromStationCode && item.toStationCode == this.toStationCode) {
                        this.resultList = [item];
                    }
                });
                this.$showLoading.hide();
                this.initEvectionStandardExceed();
                this.autoClick();
            }).catch(() => {
                this.netErr = true;
                this.$showLoading.hide();
            }).finally(() => {
                this.$showLoading.hide();
            });
            this.refundChangePolicyGet();
            // this.initEpidemicPolicy();
            this.initTrainTimeTable();
        },
        autoClick() {
            const index = this.currentPolicyIndex;
            if (index !== null) {
                let item = {};

                item = this.resultList[0];

                this.currentPolicyIndex = null;
                if (!this.resultList[0].trainSeatVoList[index].bookStatus) {
                    return
                }

                this.clickSubscribe(item, index);
            }
        },
        back() {
            // this.$router.go(-1);
            // return
            if (this.evectionType == 1) {
                this.$router.push({
                    name: "train-search",
                    query: {
                        fromStationCode: this.fromStationCode,
                        fromStationName: this.resultList[0].fromStationName ? this.resultList[0].fromStationName : this.$route.query.fromStationName,
                        toStationCode: this.toStationCode,
                        toStationName: this.resultList[0].toStationName ? this.resultList[0].toStationName : this.$route.query.toStationName,
                        trainDate: this.trainDate,
                        evectionType: 1,
                        checked: this.checked,
                        allowChangeJourneyTime: this.allowChangeJourneyTime,
                    }
                });
            } else {
                this.$router.push({
                    name: "train-search",
                    query: {
                        fromStationCode: this.fromStationCode,
                        fromStationName: this.resultList[0].fromStationName ? this.resultList[0].fromStationName : this.$route.query.fromStationName,
                        toStationCode: this.toStationCode,
                        toStationName: this.resultList[0].toStationName ? this.resultList[0].toStationName : this.$route.query.toStationName,
                        trainDate: this.trainDate,
                        activeChooseUserList: this.activeChooseUserList,
                        workTravelType: this.workTravelType,
                        evectionNo: this.evectionNo,
                        journeyId: this.journeyId,
                        journeyType: this.journeyType,
                        redirectType: this.redirectType,
                        evectionType: this.evectionType,
                        checked: this.checked,
                        evectionRequired: this.evectionRequired,
                        applyId: this.applyId,
                        maxDateText: this.maxDate,
                        minDateText: this.minDate,
                        allowChangeJourneyTime: this.allowChangeJourneyTime,
                    }
                });
            }

        },
    },
    activated() {
        this.currentPolicyIndex = null;
        this.init();
        let __this = this;
        keyback_processor.addEventListener(function () {

            //出差单弹框
            try {
                const show_travelPopup = __this.$refs.travelPopup.show;
                if (show_travelPopup) {
                    __this.$refs.travelPopup.hide();
                    return;
                }
            } catch (err) {

            }

            //超标提示弹框
            try {
                const checkTravelBox = __this.$refs.checkTravelBox;
                const show_checkTravelBox = checkTravelBox.dialogVisible;
                if (show_checkTravelBox) {
                    __this.$refs.checkTravelBox.dialogVisible = false;
                    return;
                }
            } catch (err) {

            }

            // 坐席超标提示框
            try {
                const aEvectionStandardExceed = __this.$refs.aEvectionStandardExceed;
                let show_aEvectionStandardExceed = null;
                aEvectionStandardExceed.forEach((item, index) => {
                    if (item.show) {
                        show_aEvectionStandardExceed = item;
                    }
                });

                if (show_aEvectionStandardExceed.show) {
                    show_aEvectionStandardExceed.show = false;
                    return;
                }
            } catch (err) {

            }

            // 12306异常组件
            try {
                const aAccountProcessStep = __this.$refs.aAccountProcessStep;
                let show_aAccountProcessStep = aAccountProcessStep.get_show();
                if (show_aAccountProcessStep) {
                    __this.$refs.aAccountProcessStep.hide();
                    return;
                }
            } catch (err) {

            }

            //时刻表
            try {
                const aTrainTimeTable = __this.$refs.aTrainTimeTable;
                let show_aTrainTimeTable = aTrainTimeTable.get_show();
                if (show_aTrainTimeTable) {
                    __this.$refs.aTrainTimeTable.hide();
                    return;
                }
            } catch (err) {

            }

            // //防疫提醒
            // try {
            //   const epidemicPolicy = __this.$refs.epidemicPolicy;
            //   let show_epidemicPolicy = epidemicPolicy.getShowStatus();
            //   if (show_epidemicPolicy) {
            //     __this.$refs.epidemicPolicy._close();
            //     return;
            //   }
            // } catch (err) {
            //
            // }

            //日期组件
            try {
                let show_calendarShow = __this.calendarShow;
                if (show_calendarShow) {
                    __this.calendarShow = false;
                    return;
                }
            } catch (err) {

            }

            __this.back()

        })
    },
    mounted() {

    },
    watch: {},
    computed: {},
    deactivated() {
        keyback_processor.removeEventListener();
    },
}
