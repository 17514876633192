import ColorIcon from "@/component/color-icon/index.vue";
// 撤销申请
import req_repealApply from "@/lib/data-service/haolv-default/consumer_apply_repealApply.js";

export default {
    components: {
        ColorIcon,
    },
    props: {
        index: { // index of current item
            type: Number,
        },

        source: {
            type: Object,
            default() {
                return {};
            }
        },
        to_detail: {
            type: Function,
        },
        req_list: {
            type: Function,
        },
        revocation: {
            type: Function,
        },
        __parent_this: {
            type: Object,
            default() {
                return {};
            }
        },
    },

    data() {
        return {
            revocationDialog: false,
            revocationApplyNo: null,
            revocationEvectionNo: null,
            image_url: ``,
        };
    },

    computed: {
        data_key() {
            const __this = this;
            const __parent_this = __this.__parent_this;
            const source = __this.source;

            const __panel_select_entity = __parent_this.panel_select_entity;
            const aVueVirtualScrollList = __panel_select_entity.aVueVirtualScrollList;
            const property_name_for_data_key = aVueVirtualScrollList.data_key;

            const v = source[property_name_for_data_key];
            return v;
        },

        entity_name() {
            const __this = this;
            const __parent_this = __this.__parent_this;
            const source = __this.source;

            const init_parameter = __parent_this.init_parameter;
            const panel_select_entity = init_parameter.panel_select_entity;
            const entity_define = panel_select_entity.entity_define;
            const property_name_for_name = entity_define.property_name_for_name;

            const v = source[property_name_for_name];
            return v;
        },
    },
    methods:{
        clickCancel(){
            this.revocationDialog = false;
        },
        revocationItem(){
            let loadingBox = this.$Toast.loading({
              message: '加载中...',
              forbidClick: true,
            });
            req_repealApply({applyNo: this.revocationApplyNo})
                .then((res) => {
                    // loadingBox.clone();
                    this.revocationDialog = false;
                    this.$Toast.success("撤销成功");
                    setTimeout(() => {
                        this.req_list(this.revocationEvectionNo);
                    }, 1000);
                })
                .catch((err) => {
                    this.revocationDialog = false;
                });
        },
        // 撤销按钮
        revocationItemDialogClick(item) {
            if (this.$store.state.workflow == '1') {
                this.revocation(item);
            } else {
                this.revocationDialog = true;
                this.revocationApplyNo = item.applyNo;
                this.revocationEvectionNo = item.evectionNo;
            }
        },
        getTimeDay(startDate,endDate){
            if (startDate != null && endDate != null) {
                let startDateBox = this.$moment(startDate + ' 00:00:00');
                let endDateBox = this.$moment(endDate + ' 00:00:00').add(1,"days");

                return this.$moment(endDateBox).diff(startDateBox, 'days')
            }

        },
    },
    mounted() {
        const __this = this;
        const index = __this.index;
        const source = __this.source;
        const image_url = source.image_url;

        //第一屏要显示图片的最大记录数
        const count = 5;
        if (index < count) {
            __this.image_url = image_url;
        } else {
            setTimeout(function () {
                __this.image_url = image_url;
            }, 1000);
        }
    },
}
