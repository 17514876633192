//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '标题',
    },
    showMore: {
      type: Boolean,
      default: true,
    },
    detail: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      show: true,
      maxHeight: '',
    };
  },
  mounted() {
    const overflowEle = this.$refs.overflow;
    const childEle = overflowEle.children[0];
    this.maxHeight = childEle.offsetHeight;
  },
  methods: {
    onToggle() {
      this.show = !this.show;
    },
    toDetail() {
      console.log('dwdwwdw', this.$parent)
      this.$router.push({
        name: "h5-approve-detail",
        query: {
          id: this.$parent.detail.evectionApplyId,
          approveType: 1,
          type: "detail",
        },
      });
      this.$emit('page')
    },
  },
}
