import Q from 'q';
import _ from 'underscore';
import {Toast} from 'vant';
import TravelApplicationCitySelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/travel-application-city-selector/1.0.0/index.vue';

export default {
    components: {
        TravelApplicationCitySelector,
    },
    data() {
        return {
            selected_id_list: [],

            //在这里赋值不能正常初始化，暂时解决不了
            // selected_id_list: [
            //     70008,//中山
            //     70011,//广州
            // ],
        };
    },
    methods: {
        __btn_get_value_click() {
            const __this = this;
            console.log(__this.selected_id_list);
        },

        __btn_set_value_click() {
            const __this = this;
            __this.selected_id_list = [
                70008,//中山
                70011,//广州
            ];
        },

        __btn_reset_value_click() {
            const __this = this;
            __this.selected_id_list = [];
        },

        __btn_get_selected_entity_list_click() {
            const __this = this;
            const selected_entity_list = __this.$refs.aTravelApplicationCitySelector.get_selected_entity_list();
            console.log(JSON.stringify(selected_entity_list));
        },
    },
    watch: {
        selected_id_list(pNewValue, pOldValue) {
            const __this = this;
            // console.log(`pNewValue=`);
            // console.log(pNewValue);
            // console.log(`pOldValue=`);
            // console.log(pOldValue);
        },
    },
    created() {
        const __this = this;
        //在这里赋值不能正常初始化，暂时解决不了
        // __this.selected_id_list = [
        //     70008,//中山
        //     70011,//广州
        // ];
    },
    mounted() {
        const __this = this;
        // __this.selected_id_list = [
        //     70008,//中山
        //     70011,//广州
        // ];
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
