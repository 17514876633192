import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import store from '@/lib/store';

const show = function (pParameter) {
    if (!pParameter) pParameter = {};
    const title = pParameter.title || `信息提示`;
    const msg = pParameter.msg || ``;
    const detail_msg = pParameter.detail_msg || ``;

    store.state.dialog_for_request.title = title;
    store.state.dialog_for_request.msg = msg;
    store.state.dialog_for_request.detail_msg = detail_msg;
    store.state.dialog_for_request.show = true;
};

const close = function () {
    store.state.dialog_for_request.show = false;
};

export default {
    show,
    close,
}
