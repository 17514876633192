import NavBar from "@/component/nav-bar/import_latest_version_module";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';

export default {
    data() {
        return {
            title: '火车票退票申请',
            orderNo: '',
            refundNo: ''
        }
    },
    components: {NavBar},
    mounted() {
        const _this = this;
        this.$nextTick(() => {
            fixStatusBar({
                handler({api}) {
                    const bodyWidth = document.body.clientWidth;
                    const headNav = _this.$refs.topElementNav.$el;
                    const addPaddingTop = (api.safeArea.top) / (bodyWidth / 100);
                    headNav.style.paddingTop = addPaddingTop + 'vw';
                }
            });
        })
    },
    activated() {
        this.refundNo = this.$route.query.refundNo || ''
    },
    methods: {
        toRefundDetail() {
            if (this.refundNo) {
                this.$router.replace({
                    name: 'h5-train-refund-form-detail',
                    query: {
                        refundNo: this.refundNo,
                        jumpType: 'list',
                    }
                })
            }
        }
    }
}