import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/train-account-check',
            name: 'h5-train-account-check',
            component: () => import(/* webpackChunkName: "page-personal-center-trainAccountCheck" */ `./1.0.1/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '12306账号核验',
                    need_alive: true,
                    need_head_nav: false,
                    need_footer_bar: false,

                    secondary_menu: 4,
                }
            },
        }
    ],
};
