import Vue from 'vue'
import loading001 from './index.vue'
import {Toast} from 'vant';
const LoadingConstructor = Vue.extend(loading001)

const instance = new LoadingConstructor({
    el: document.createElement('div')
})
let setIntervalNum = 60;
let setIntervalFun = null;
instance.show = false
const loading = {
    show() {
        instance.show = true
        document.body.appendChild(instance.$el);
        // 防止弹窗一直不消失，设定60秒后自动消失
        clearInterval(setIntervalFun);
        setIntervalNum = 60;
        setIntervalFun = setInterval(()=>{
            setIntervalNum--;
            if (setIntervalNum === 0) {
                instance.show = false;
                Toast('加载失败，请稍后重试');
                clearInterval(setIntervalFun);
            }
        }, 1000)
    },
    hide() {
        instance.show = false;
        clearInterval(setIntervalFun);
    }
}

export default {
    install() {
        if (!Vue.$showLoading) {
            Vue.$showLoading = loading
        }
        Vue.mixin({
            created() {
                this.$showLoading = Vue.$showLoading
            }
        })
    }
}
