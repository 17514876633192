// 获取用户信息
// import consumer_user_info from "@/lib/data-service/haolv-default/consumer_user_info";
// import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/get_user_info';
import get_user_info from '@/lib/common-service/get_user_info';
import consumer_menu_getUserMenu from '@/lib/data-service/haolv-default/consumer_menu_getUserMenu';
// import consumer_sys_inform_unreadMessagesNumber from '@/lib/data-service/haolv-default/consumer_sys_inform_unreadMessagesNumber'
import {mapGetters, mapActions} from "vuex";
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import { Badge } from 'vant';
export default {
  data() {
    return {
      // noticeNum: '',
      userInfo: {
        tusCompany: {},
      },
      errHead:
        "//haolv-enterprise-app-static.oss-cn-shenzhen.aliyuncs.com/2020/10/13/touxiang-01.png?x-oss-process=style/haolv-default",
        menuList: [
          {
            text: "机票",
            icon: "",
            img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/06/home-flight-icon.svg',
            name: "h5-flight-order-list",
            disable: false,
          },
          {
            text: "酒店",
            icon: "",
            img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/20/home-hotel-icon.svg',
            name: "h5-hotel-order-list",
            disable: false,
          },
          {
            text: "火车票",
            icon: "",
            img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/20/home-train-icon.svg',
            name: "h5-train-order-list",
            disable: false,
          },
          {
            text: "用车",
            icon: "",
            img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/20/home-car-icon.svg',
            name: "h5-car-order-list",
            disable: false,
          },
        ],
      isShowExternalCustomers: false,
    };
  },
  mounted(){
    this.getUserMenu();
    const api = global.api;
    keybackProcessor.addEventListener(() => {
      api.closeWidget()
      /*api.confirm({
          title: '确定要关闭程序吗？',
          buttons: ['确定', '关闭'],
      }, function (ret, err) {
          if (ret.buttonIndex === 1) {
              api.closeWidget()
          }
      })*/
    })
  },
  destroyed() {
    keybackProcessor.removeEventListener()
  },
  computed: {
    ...mapGetters(['noticeNum', 'noticeNumResult'])
  },
  methods: {
    //设置--跳转
    clickSetting(){
      this.$router.push({
        name: 'h5-setting'
      })
    },
    //系统通知--跳转
    clickMessage(){
      this.$router.push({
        name: 'h5-sys-message'
      })
    },
    // 更换头像
    toChangeLogo() {
      this.$router.push({
        name: "h5-information",
        query: {
          id: this.userInfo.staffId,
        },
      });
    },
    jump_page ({name, disable}) {
      if (disable) return this.$Toast('敬请期待');
      this.$router.push({
        name,
      });
    },
    async getUserMenu() {
      this.isShowExternalCustomers = false;
      let res = await consumer_menu_getUserMenu();
      res.datas.forEach(item => {
        if (item.name === '公司管理') {
          item.menus.forEach(menu => {
            if (menu.name === '外部客户') {
              this.isShowExternalCustomers = true;
            }
          })
        }
      })
    },
    // getNoticeNum() {
    //   consumer_sys_inform_unreadMessagesNumber().then(res => {
    //     let result = ''
    //     if (!res.datas.unreadNumber) {
    //       result = ''
    //     } else if (res.datas.unreadNumber > 99) {
    //       result = '99+'
    //     } else {
    //       result = res.datas.unreadNumber
    //     }
    //
    //     this.noticeNum = result
    //   })
    // },
    ...mapActions(['updateNoticeNum'])
  },
  created() {
    get_user_info().then(res => {
      this.userInfo = res.datas
    }).catch((err) => {

    });
    this.updateNoticeNum()
  }
};
