import _ from 'underscore'
import consumer_hotel_search_searchThink from "@/lib/data-service/haolv-default/consumer_hotel_search_searchThink";
import consumer_search_history_addOrUpdate from "@/lib/data-service/haolv-default/consumer_search_history_addOrUpdate";
import consumer_search_history_getList from "@/lib/data-service/haolv-default/consumer_search_history_getList";
import consumer_search_history_del from "@/lib/data-service/haolv-default/consumer_search_history_del";
import Icon from '@/component/color-icon/index'

export default {
  components: {
    Icon,
  },
  data() {
    return {
      thinkList: [],
      form: {
        search: '',
        cityId: '',
      },
      historyList: [],
      toggleLoading: false,
      toggleHistory: false,
    };
  },
  methods: {
    initCityId() {
      this.form.cityId = this.$route.query.cityId;
    },
    setThinkList() {
      this.toggleLoading = true;
      consumer_hotel_search_searchThink(this.form).then(data => {
        this.thinkList = data;
      }).finally(() => {
        this.toggleLoading = false;
      });
    },
    setHistoryList() {
      consumer_search_history_getList()
        .then(data => {
          this.historyList = data;
          if (this.form.search === '' && data.length) {
            this.toggleHistory = true;
          } else {
            this.toggleHistory = false;
          }
        });
    },
    onSearch(val) {
      this.addNewHistory(val)
        .then(() => {
          this.goToHotelSearch(val);
        })
    },
    onCancel() {
      this.$router.replace({
        path: '/h5/hotel-search',
        query: this.$route.query,
      }).catch(() => { });
    },
    onDelete() {
      this.$Dialog.confirm({
        title: '标题',
        message: '是否要清空历史记录？',
      })
        .then(() => {
          let list = this.historyList.map(item => item.id);
          consumer_search_history_del(list)
            .then(() => {
              this.$Toast('历史记录已清空');
              this.setHistoryList();
            });
          // on confirm
        })
    },
    onInput: _.debounce(function (value) {
      this.form.search = value;
      this.thinkList = [];
      if (!value && this.historyList.length) {
        this.toggleHistory = true;
      } else {
        this.toggleHistory = false;
        this.setThinkList();
      }
    }, 500),
    onClickLeft() {
      this.$router.replace({
        path: '/h5/hotel-search',
        query: this.$route.query,
      }).catch(() => { })
    },
    onThinkSearch(item) {
      const str = item.specSearch_DomSearchName;
      this.addNewHistory(str)
        .then(() => {
          this.goToHotelSearch(str);
        })
    },
    goToHotelSearch(keyword) {
      let query = Object.assign({}, JSON.parse(JSON.stringify(this.$route.query)));
      query.keyword = keyword;
      this.$router.replace({
        path: '/h5/hotel-search',
        query,
      }).catch(() => { })
    },
    addNewHistory(keyword) {
      return consumer_search_history_addOrUpdate(keyword);
    },
    onHistory(keyword) {
      this.goToHotelSearch(keyword);
    },
  },
  filters: {
    sign(value) {
      if (!value) {
        return '未知';
      }
      let obj = {
        "1": "酒店",
        "2": "行政区",
        "3": "商圈",
        "4": "品牌",
      }
      return obj[value]
    },
  },
  activated() {
    this.initCityId();
    this.setHistoryList();
  },
};
