import consumer_sys_inform_list from '@/lib/data-service/haolv-default/consumer_sys_inform_list';
import moment from 'moment'

export default {
    data() {
        return {
            pagination: {
                currentPage: 1,
                pageSize: 10,
                total: 0
            },
            systemMsgList: [],
            noData: false,
            loading: false,
            finished: false,
        }
    },
    filters: {
        format_date (val) {
            const nyr = moment(val).format('YYYY-MM-DD');
            const now = moment(new Date()).format('YYYY-MM-DD');
            const yesterday = moment(new Date()).add(-1, 'days').format('YYYY-MM-DD');
            if (nyr === now) {
                return moment(val).format('HH:mm');;
            } else if (nyr === yesterday ) {
                return '昨天';
            } else {
                return moment(val).format('MM-DD');
            }
        },
    },
    methods: {
        // 初始化
        init() {
            let params = this.getQueryParameter();
            consumer_sys_inform_list(params).then((res) => {
                if (res.pageResult.pageData.length == 0) {
                    this.finished = true;
                    return;
                }
                let temp = this.systemMsgList.concat(res.pageResult.pageData);
                this.systemMsgList = temp;
                if (this.systemMsgList.length == 0 && this.pagination.currentPage == 0) {
                    this.noData = true;
                } else {
                    this.noData = false;
                }
                this.pagination.currentPage += 1;
                this.loading = false;
            });
        },
        getQueryParameter() {
            let params = {};
            params.currentPage = this.pagination.currentPage;
            params.pageSize = this.pagination.pageSize;
            return params;
        },
        onLoad() {
            setTimeout(() => {
                this.init();
                this.loading = true;
            }, 1000);
        },
        toDetail(item, index) {
            this.$router.push({
                name: 'h5-sys-message-detail',
                query: {
                    id: item.id
                },
            });
        },
    },
}