import moment from 'moment';
import {Dialog, Toast} from "vant";
import consumer_t_od_hotel_order_interim_applyCancelOrder from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_applyCancelOrder";
import consumer_t_od_hotel_order_interim_getRefundResult from '@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_getRefundResult';
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
// import Vue from 'vue'

// 000000--轮询成功,退订成功
// 100000--退订申请接口返回错误
// 200000--页面已经跳转
// 300000--轮询超时(3秒),结束轮询
// 400000--轮询成功,退订失败
// 500000--轮询失败

/*export default function click_unsubscribe(parms,__this) {
    let pollingBox = null;
    let loading = Vue.$showLoading;
    let interfaceState = '';
    let interfaceNum = 0;
    return new Promise(function(resolve, reject) {
        Dialog.confirm({
            title: '申请退订',
            message: '确定退订该订单？',
            confirmButtonText: '确定退订',
            cancelButtonText: '我再想想',
        }).then(() => {
            loading.show();
            consumer_t_od_hotel_order_interim_applyCancelOrder(parms).then((res) => {
                if (true) {
                    // 订单已提交退订申请，无需重复操作
                    reject({code: '100000',msg: '订单已提交退订申请，无需重复操作'})
                }
                interfaceNum = 0;
                // 轮询
                pollingBox = setInterval(() => {
                    interfaceNum++;
                    if (interfaceNum > 6 || __this.$route.name !== 'h5-hotel-order-list') {
                        loading.hide();
                        clearInterval(pollingBox);
                        if (interfaceNum > 6) {
                            // 退订中
                            reject({code: '300000',msg: '已提交申请，请耐心等待退订结果'})
                        } else {
                            reject({code: '200000',msg: '页面已跳转'})
                        }
                    }
                    if (interfaceState === '' || interfaceState === 'end') {
                        interfaceState = 'loading';
                        consumer_t_od_hotel_order_interim_getRefundResult().then((CRes) => {
                            loading.hide();
                            clearInterval(pollingBox);
                            // resolve({code: '000000',msg: '申请成功，退订成功'})
                            // reject({code: '400000',msg: '申请失败'})
                        }).catch((CRej) => {
                            // 接口错误
                            // loading.hide();
                            // clearInterval(pollingBox);
                            // reject({code: '500000',msg: '退订失败'})
                        }).finally(() => {
                            interfaceState = 'end';
                        });
                    }
                },500);
            }).catch((rej) => {
                loading.hide();
                Toast({
                    message: `${rej.data.msg}`,
                    duration: 3000,
                    forbidClick: true,
                    onClose: () => {
                        reject({
                            code: '100000',
                            msg: `${rej.data.msg}`,
                        })
                    }
                });
            }).finally(() => {
                // loading.hide();
            });
        }).catch(() => {

        });
    })
}*/


export default {
    data() {
        return {
            commonResolve: null,
            commonReject: null,
            isGetResult: false,
            intervalFun: null,
        }
    },
    props: {},
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
        this.isGetResult = false;
        clearTimeout(this.intervalFun);
    },
    destroyed() {
        this.isGetResult = false;
        clearTimeout(this.intervalFun);
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        refundPromise() {
            return new Promise(resolve => {
                Dialog.confirm({
                    title: '申请退订',
                    message: '确定退订该订单？',
                    confirmButtonText: '确定退订',
                    cancelButtonText: '我再想想',
                }).then(() => {
                    resolve(true);
                }).catch(() => {
resolve(false)
                });
            })
        },
        getResult(params) {
            if (!this.isGetResult) {
                return
            }
            // 轮询方法
            /*let params = {
                orderNo: this.$route.query.orderNo,
            };*/
            consumer_t_od_hotel_order_interim_getRefundResult(params).then(res=>{
                if (!this.isGetResult) {
                    return;
                }
                // 订单状态 13：退订失败 16：退订成功
                let status = res.datas.status;
                switch (status) {
                    case 16:
                        // 成功
                        this.$showLoading.hide();
                        clearTimeout(this.intervalFun);
                        // 跳转
                        this.$message.success('退订成功');
                        setTimeout(()=>{
                            this.commonResolve();
                        }, 3000)
                        break;
                    case 13:
                        // 失败
                        this.$showLoading.hide();
                        clearTimeout(this.intervalFun);
                        Dialog.alert({
                            title: '申请退订',
                            message: '退订失败，有问题请联系客服',
                            confirmButtonText: '知道了'
                        }).then(() => {
                            this.commonReject();
                        });
                        break;
                    default:
                        // 继续轮询
                        this.getResult(params);
                }
            },error=>{
                this.$showLoading.hide();
                this.commonReject();
            }).catch(e=>{
                this.$showLoading.hide();
                this.commonReject();
            })
        },
        resultForSetInterval() {
            this.intervalFun = setTimeout(()=>{
                this.$showLoading.hide();
                this.isGetResult = false;
                // 弹出等待结果弹窗
                Dialog.alert({
                    title: '申请退订',
                    message: '已提交申请，请耐心等待退订结果',
                    confirmButtonText: '知道了'
                }).then(() => {
                    this.commonReject();
                });
            }, 3000);
        },
        clickUnsubscribe(params) {
            return new Promise(async (resolve, reject) => {
                this.commonResolve = resolve;
                this.commonReject = reject;
                // 二次确认
                let promiseResult = await this.refundPromise();
                if (!promiseResult) {
                    reject()
                    return;
                }
                this.$showLoading.show();
                let [err, res] = await awaitWrap( consumer_t_od_hotel_order_interim_applyCancelOrder(params));
                if (err) {
                    this.$showLoading.hide();
                    Toast({
                        message: `${err.data.msg}`,
                        duration: 3000,
                        forbidClick: true,
                        onClose: () => {
                            reject({
                                code: '100000',
                                msg: `${err.data.msg}`,
                            })
                        }
                    });
                    return;
                }
                // 开始轮询， 2秒后结束
                this.isGetResult = true;
                this.getResult(params);
                this.resultForSetInterval();
            })
        }
    }
}