import EpidemicPolicy from '@/page/travel-book/train/component/epidemicPolicy/1.0.0/index.vue'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import Calendar from '@/page/travel-book/train/component/calendar/1.0.1/index.vue';
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_trains_product_checkTheRemainingTrainTickets from '@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTickets';
import {Toast} from "vant";
import moment from "moment";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
export default {
    data() {
        return {
            active: 0,
            screenListNum: 0,
            screenListText: [],
            screenArrow: false,
            botButtonOff: false,
            trainListOff: false,
            activeName: [], // 用于手风琴展开收起

            fromStationCode: '', // 出发站ID
            startTrainParams: {}, // 出发站信息
            toStationCode: '', // 到达站ID
            arriveTrainParams: {}, // 到达站信息
            trainDate: '', // 出发时间

            evectionNo: '',
            evectionType: 2,
            orderNo: '',
            userList: '',

            maxDateText: null,
            minDateText: null,

            isScreenShow: false,
            trainType: false,
            isHasTicket: false, // 是否只显示有票的

            filterActiveBox: null, // 已选中筛选(出发时间、旅行耗时、到达时间)
            filterActive: 'departureTime',
            formStationTimeSort: false, // 发车时间排序
            formStationTimeSortType: 1, // 1：正序，
            formStationTimeArr: false, // 到达时间排序
            formStationTimeArrType: 1, // 1：正序，
            formConsumingTime: false, // 旅行耗时排序
            formConsumingTimeType: 1, // 1：正序，
            application: {
                trainTypeResults: [ // 车型选择list
                    {trainTypeCode: 0, trainTypeText: "不限"},
                    {trainTypeCode: 1, trainTypeText: "C/G高铁"},
                    {trainTypeCode: 2, trainTypeText: "D动车"},
                    {trainTypeCode: 3, trainTypeText: "普通列车"}
                ],
                trainTimeTypeResults: [ // 出发时间list
                    {trainTimeStartText: "不限", trainTimeEndText: "", trainTimeTypeCode: 0},
                    {trainTimeStartText: "凌晨00:00", trainTimeEndText: "06:00", trainTimeTypeCode: 4},
                    {trainTimeStartText: "上午06:00", trainTimeEndText: "12:00", trainTimeTypeCode: 1},
                    {trainTimeStartText: "下午12:00", trainTimeEndText: "18:00", trainTimeTypeCode: 2},
                    {trainTimeStartText: "晚上18:00", trainTimeEndText: "24:00", trainTimeTypeCode: 3},
                ],
                trainTimeTypeResultsEnd: [ // 到达时间list
                    {trainTimeStartText: "不限", trainTimeEndText: "", trainTimeTypeCode: 0},
                    {trainTimeStartText: "凌晨00:00", trainTimeEndText: "06:00", trainTimeTypeCode: 4},
                    {trainTimeStartText: "上午06:00", trainTimeEndText: "12:00", trainTimeTypeCode: 1},
                    {trainTimeStartText: "下午12:00", trainTimeEndText: "18:00", trainTimeTypeCode: 2},
                    {trainTimeStartText: "晚上18:00", trainTimeEndText: "24:00", trainTimeTypeCode: 3},
                ],
                startStationList: [
                    {stationCode: '', stationName: '不限'}
                ], // 出发车站
                endStationList: [
                    {stationCode: '', stationName: '不限'}
                ], // 到达车站
            },
            isAllTrain: true, // 是否查询所有类型车次
            checkTrainList: [],
            isAllSeat: true,
            checkSeatList: [],
            isAllFormTime: true, // 筛选出发时间
            checkFormTrainTimeList: [],
            isAllToTime: true, // 筛选到达时间
            checkToTrainTimeList: [],
            isAllFormStation: true, // 筛选出发车站
            checkFormStationList: [],
            isAllToStation: true, // 筛选到达车站
            checkToStationList: [],

            trainList: [], // 火车票列表
            oriTrainList: [], // 火车票列表
        }
    },
    components: {EpidemicPolicy, Calendar, NavBar},
    created() {
    },
    mounted() {
    },
    async activated() {
        let params = this.$route.query;
        // 获取入参
        const __this = this;
        this.startTrainParams = JSON.parse(params.startTrainParams);
        this.fromStationCode = this.startTrainParams.stationCode;
        this.arriveTrainParams = JSON.parse(params.arriveTrainParams);
        this.toStationCode = this.arriveTrainParams.stationCode;
        this.trainDate = params.trainDate;
        this.evectionNo = this.$route.query.evectionNo;
        this.evectionType = parseInt(this.$route.query.evectionType);
        this.orderNo = this.$route.query.orderNo;
        this.userList = this.$route.query.userList;
        this.maxDateText = params.maxDate,
        this.minDateText = params.minDate,
        await this._refundChangePolicyGet();
        this._initPage();
        keyback_processor.addEventListener(() => {
            const aCalendar = __this.$refs.aCalendar;
            const show_aCalendar = aCalendar.show;
            if (show_aCalendar) {
                aCalendar.show = false;
                return;
            }
            //筛选弹框--已筛选弹框
            if (__this.screenArrow) {
                __this.screenArrow = false;
                return;
            }

            //筛选弹框
            if (__this.isScreenShow) {
                __this.isScreenShow = false;
                return;
            }
            this._listBack()
        })
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        // 未开售字样
        saleTime(date){
            //当前时间
            let today = moment().format('YYYY-MM-DD');
            //发售时间
            let todayEnd = moment(date).format('YYYY-MM-DD');

            let text = '';

            //当天开售
            if (today == todayEnd) {
                let todayTime = moment(date).format('HH:mm');
                text = todayTime
            } else {
                //天数开售
                text = moment(todayEnd + ' 00:00:00').diff(moment(today + ' 00:00:00'),"days") + '天';
            }

            return text
        },
    },
    methods: {
        _listBack() {
            // this.$router.go(-1)
            const query = {
                orderNo: this.orderNo,
                fromStationName: this.startTrainParams.stationName,
                fromStationCode: this.fromStationCode,
                toStationName: this.arriveTrainParams.stationName,
                toStationCode: this.toStationCode,
                startDate: this.trainDate,
                evectionNo: this.evectionNo,
                evectionType: this.evectionType,
                userList: this.userList
            };
            this.$router.push({
                name: 'h5-train-change',
                query
            })
        },
        async _refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.evectionRequired = res.datas.evectionRequired;
            this.bookUpperBound = res.datas.bookUpperBound;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
            this.unLimitedJourney = res.datas.unLimitedJourney;
            this.changeEvectionCity = res.datas.changeEvectionCity;
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        _initEpidemicPolicy() {
            let set = setInterval(() => {
                if (this.$refs['epidemicPolicy']) {
                    this.$refs['epidemicPolicy'].init({
                        startCityCode: this.fromStationCode,
                        endCityCode: this.toStationCode
                    });
                    clearInterval(set)
                }

            }, 500)
        },
        _clickDate(val) {
            this._initPage()
        },
        _initPage() {
            const __this = this;
            if (__this.fromStationCode === '' || __this.fromStationCode === undefined) {
                Toast('请先选择出发地');
                return
            }
            if (__this.toStationCode === '' || __this.toStationCode === undefined) {
                Toast('请先选择目的地');
                return
            }
            if (__this.trainDate === '' || __this.trainDate === null) {
                Toast('请先选择日期');
                return
            }
            this.$showLoading.show();
            // this._initEpidemicPolicy();
            consumer_trains_product_checkTheRemainingTrainTickets({
                fromStationCode: this.fromStationCode,
                toStationCode: this.toStationCode,
                trainDate: this.trainDate,
            }).then(res => {
                res.resultList.forEach((item,index) => {
                    let trainBox = null;
                    item.trainSeatVoList.forEach((TItem,TIndex) => {
                        if (TItem.seatName == '无座') {
                            trainBox = TItem;
                            item.trainSeatVoList.splice(TIndex, 1);
                        }
                    });
                    item.trainSeatVoList.push(trainBox)
                });

                __this.trainListOff = true;
                __this.oriTrainList = [].concat(res.resultList);
                __this.trainList = __this.trainFilter(__this.oriTrainList);
                __this.trainList.forEach(value => {
                    value.trainSeatVoList.forEach(val => {
                        if (val) {
                            val.trainParams = {
                                evectionNo: '',
                                motorcycleType: value.motorcycleType,
                                seatName: val.seatName
                            }
                        }
                    })
                });

                const resultList = res.resultList;
                let startStationList = [];
                let endStationList = [];

                resultList.forEach(value => {
                    value.timeTable = [];
                    // 收集出发和目的地车站
                    let startStationItem = {
                        stationCode: value.fromStationCode,
                        stationName: value.fromStationName
                    };
                    if (!JSON.stringify(startStationList).includes(JSON.stringify(startStationItem))) {
                        startStationList.push(startStationItem)
                    }
                    let endStationItem = {
                        stationCode: value.toStationCode,
                        stationName: value.toStationName
                    };
                    if (!JSON.stringify(endStationList).includes(JSON.stringify(endStationItem))) {
                        endStationList.push(endStationItem)
                    }
                });
                __this.application.startStationList = [];
                __this.application.endStationList = [];
                __this.application.startStationList = __this.application.startStationList.concat(startStationList);
                __this.application.endStationList = __this.application.endStationList.concat(endStationList);

            }).finally(() => {
                this.$showLoading.hide();
            });
        },
        _seatItemClick(val) {
            this.$showLoading.show();
            this.$router.push({
                name: 'h5-train-change-search-train',
                query: {
                    fromStationCode: val.fromStationCode,
                    fromStationName: val.fromStationName,
                    toStationCode: val.toStationCode,
                    toStationName: val.toStationName,
                    trainDate: this.$moment(val.arriveDate).format('YYYY-MM-DD'),
                    trainNo: val.trainCode,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType,
                    orderNo: this.orderNo,
                    userList: this.userList,
                    minDate: this.minDateText,
                    maxDate: this.maxDateText,
                }
            })
        },
        // 耗时最短
        _shortestTime (val,trainList) {
            let timeBox = [];
            let valTime = Number(val.spanTime.replace(/分/,''));
            trainList.forEach((item,index) => {
                timeBox.push(Number(item.spanTime.replace(/分/,'')));
            });

            function func(a,b){
                return a-b;
            }
            if (timeBox.sort(func)[0] == valTime) {
                return true
            } else {
                return false
            }
        },
        // 售完字样
        _soldOut(data){
            let dataBox = [];
            let switchData = null;
            data.forEach((item,index) => {
                if (item) {
                    if (Number(item.seatInventory) == 0) {
                        dataBox.push(Number(item.seatInventory));
                    }
                }
            });
            if (dataBox.length == data.length) {
                switchData= true;
            } else {
                switchData= false;
            }

            return switchData
        },
        _screenClick() {
            this.screenArrow = !this.screenArrow;

            this.screenListText = [];

            this.application.trainTypeResults.forEach((item, index) => {
                if (this.checkTrainList.indexOf(item.trainTypeCode) != -1 && item.trainTypeCode != 0) {
                    this.screenListText.push({
                        text: item.trainTypeText,
                        code: item.trainTypeCode,
                        listName: 'this.checkTrainList',
                    });
                }
            });
            this.application.trainTimeTypeResults.forEach((item, index) => {
                if (this.checkFormTrainTimeList.indexOf(item.trainTimeTypeCode) != -1 && item.trainTimeTypeCode != 0) {
                    this.screenListText.push({
                        text: `出发：${item.trainTimeStartText}-${item.trainTimeEndText}`,
                        code: item.trainTimeTypeCode,
                        listName: 'this.checkFormTrainTimeList',
                    });
                }
            });
            this.application.trainTimeTypeResultsEnd.forEach((item, index) => {
                if (this.checkToTrainTimeList.indexOf(item.trainTimeTypeCode) != -1 && item.trainTimeTypeCode != 0) {
                    this.screenListText.push({
                        text: `到达：${item.trainTimeStartText}-${item.trainTimeEndText}`,
                        code: item.trainTimeTypeCode,
                        listName: 'this.checkToTrainTimeList',
                    });
                }
            });
            this.application.startStationList.forEach((item, index) => {
                if (this.checkFormStationList.indexOf(item.stationCode) != -1 && item.stationCode != '') {
                    this.screenListText.push({
                        text: `出发：${item.stationName}`,
                        code: item.stationCode,
                        listName: 'this.checkFormStationList',
                    });
                }
            });
            this.application.endStationList.forEach((item, index) => {
                if (this.checkToStationList.indexOf(item.stationCode) != -1 && item.stationCode != '') {
                    this.screenListText.push({
                        text: `到达：${item.stationName}`,
                        code: item.stationCode,
                        listName: 'this.checkToStationList',
                    });
                }
            });
        },
        // 确定筛选
        _onSubmitScreen() {
            const __this = this;
            __this.isScreenShow = false;
            __this.screenArrow = false;
            // 开始过滤筛选
            __this.trainList = __this.trainFilter(__this.oriTrainList);
        },
        //清空筛选
        _emptyClick() {
            //内层清空
            this.screenListText = [];
            //外层清空
            this.checkTrainList = [];
            this.checkFormTrainTimeList = [];
            this.checkToTrainTimeList = [];
            this.checkFormStationList = [];
            this.checkToStationList = [];

            this.screenListNum = 0;

            this.screenArrow = false;
        },
        _clickCross(item, index) {
            //内层回显
            this.screenListText.splice(index, 1);
            //外层回显
            eval(`
                ${item.listName}.splice(${item.listName}.indexOf(item.code),1)
            `);
            if (this.screenListText.length == 0) {
                this.screenArrow = false;
            }

            this.screenListNum = 0;

            if (this.checkTrainList.length != 0 && this.checkTrainList.indexOf(0) == -1) {
                this.screenListNum = this.screenListNum + this.checkTrainList.length;
            }
            if (this.checkFormTrainTimeList.length != 0 && this.checkFormTrainTimeList.indexOf(0) == -1) {
                this.screenListNum = this.screenListNum + this.checkFormTrainTimeList.length;
            }
            if (this.checkToTrainTimeList.length != 0 && this.checkToTrainTimeList.indexOf(0) == -1) {
                this.screenListNum = this.screenListNum + this.checkToTrainTimeList.length;
            }
            if (this.checkFormStationList.length != '' && this.checkFormStationList.indexOf('') == -1) {
                this.screenListNum = this.screenListNum + this.checkFormStationList.length;
            }
            if (this.checkToStationList.length != '' && this.checkToStationList.indexOf('') == -1) {
                this.screenListNum = this.screenListNum + this.checkToStationList.length;
            }
        },
        _tabbarChange(val){
            if (val != 3) {
                this.filterActiveBox = val;
            }
        },
        // 刷新
        onRefresh() {
            const __this = this;
            this.$showLoading.show();
            consumer_trains_product_checkTheRemainingTrainTickets({
                fromStationCode: __this.fromStationCode,
                toStationCode: __this.toStationCode,
                trainDate: __this.trainDate,
                applyId: __this.applyId,
            }).then(res => {
                res.resultList.forEach((item,index) => {
                    let trainBox = null;
                    item.trainSeatVoList.forEach((TItem,TIndex) => {
                        if (TItem.seatName == '无座') {
                            trainBox = TItem;
                            item.trainSeatVoList.splice(TIndex, 1);
                        }
                    });
                    item.trainSeatVoList.push(trainBox)
                });
                this.$showLoading.hide();
                __this.oriTrainList = [].concat(res.resultList);
                __this.trainList = [].concat(res.resultList);


                const resultList = res.resultList;
                let startStationList = [{stationCode: '', stationName: '不限'}];
                let endStationList = [{stationCode: '', stationName: '不限'}];

                resultList.forEach(value => {
                    value.timeTable = [];
                    // 收集出发和目的地车站
                    let startStationItem = {
                        stationCode: value.fromStationCode,
                        stationName: value.fromStationName
                    };
                    if (!JSON.stringify(startStationList).includes(JSON.stringify(startStationItem))) {
                        startStationList.push(startStationItem)
                    }
                    let endStationItem = {
                        stationCode: value.toStationCode,
                        stationName: value.toStationName
                    };
                    if (!JSON.stringify(endStationList).includes(JSON.stringify(endStationItem))) {
                        endStationList.push(endStationItem)
                    }
                });
                __this.application.startStationList = [];
                __this.application.endStationList = [];
                __this.application.startStationList = __this.application.startStationList.concat(startStationList);
                __this.application.endStationList = __this.application.endStationList.concat(endStationList);

                this.onSubmitScreen();
            }).catch(err => {
                this.$showLoading.hide();
            });
        },
        // 搜索
        _toSearch() {
            const __this = this;
            __this.travelPopupShow = false;
            __this.fromStationCode = __this.trainFromStationCode;
            __this.toStationCode = __this.trainToStationCode;
            __this.startTrainParams = JSON.parse(JSON.stringify(__this.trainStartTrainParams));
            __this.arriveTrainParams = JSON.parse(JSON.stringify(__this.trainArriveTrainParams));
            __this.trainDate = __this.trainStartDate;
            __this._onRefresh();
        },
        // 筛选开始
        _filterTime() { // 按时间排序
            const __this = this;
            __this.formStationTimeSort = true; // 开启自身排序
            __this.formStationTimeArr = false; // 关闭其他排序
            __this.formConsumingTime = false; // 关闭其他排序
            __this.formStationTimeSortType = 1;
            __this._setSort();
        },
        _filterPrice() { // 按价格排序
            const __this = this;
            __this.formStationTimeArr = true; // 开启自身排序
            __this.formStationTimeSort = false; // 关闭其他排序
            __this.formConsumingTime = false; // 关闭其他排序
            __this.formStationTimeArrType = 1;
            __this._setSort();
        },
        _consumingTimeClick() { // 按旅行耗时排序
            const __this = this;
            __this.formConsumingTime = true; // 开启自身排序
            __this.formStationTimeArr = false; // 关闭自身排序
            __this.formStationTimeSort = false; // 关闭其他排序
            __this.formConsumingTimeType = 1;
            __this._setSort();
        },
        _setSort() {
            const __this = this;
            let trainList = __this.trainList;

            let timeFormMinute = function (val) {
                let timeVal = val.split(':');
                let hour = parseInt(timeVal[0]) * 60;
                let minute = parseInt(timeVal[1]);
                return hour + minute
            };
            let timeToMinute = function (val) {
                let timeVal = val.toTime.split(':');
                let hour = timeVal[0] = (Number(timeVal[0]) +  Number(val.daysTrip*24)) * 60;
                let minute = Number(parseInt(timeVal[1]));
                return hour + minute
            };
            let formTimeCompare = function (x, y) { // 按出发时间正序排列
                let xFromTime = timeFormMinute(x.fromTime);
                let yFromTime = timeFormMinute(y.fromTime);

                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            };

            let formTimeCompareArr = function (x, y) { // 按到达时间正序排列
                let xToTime = timeToMinute(x);
                let yToTime = timeToMinute(y);
                if (xToTime < yToTime) {
                    return -1;
                } else if (xToTime > yToTime) {
                    return 1;
                } else {
                    return 0;
                }
            };

            let formConsumingTime = function (x, y) { // 按旅行耗时正序排列
                let xBox = x.spanTime.split('时');
                xBox[1] = xBox[1].replace(/分/,'');
                if (xBox[0].length == 1) {
                    xBox[0] = '0' + xBox[0];
                }
                if (xBox[1].length == 1) {
                    xBox[1] = '0' + xBox[1];
                }

                let xSpanTime = Number(`${xBox[0]}${xBox[1]}`);

                let yBox = y.spanTime.split('时');
                yBox[1] = yBox[1].replace(/分/,'');
                if (yBox[0].length == 1) {
                    yBox[0] = '0' + yBox[0];
                }
                if (yBox[1].length == 1) {
                    yBox[1] = '0' + yBox[1];
                }
                let ySpanTime = Number(`${yBox[0]}${yBox[1]}`);

                if (xSpanTime < ySpanTime) {
                    return -1;
                } else if (xSpanTime > ySpanTime) {
                    return 1;
                } else {
                    return 0;
                }
            };


            if (__this.formStationTimeSort) {
                trainList.sort(formTimeCompare);
            } else if (__this.formStationTimeArr) {
                trainList.sort(formTimeCompareArr)
            } else if (__this.formConsumingTime) {
                trainList.sort(formConsumingTime)
            }

            __this.trainList = trainList;
        },
        // 筛选开始
        trainFilter(list) {
            const __this = this;
            let trainList = list;

            // 筛选列车类型
            if (!__this.isAllTrain && __this.checkTrainList.length > 0) {
                let trainItemList = [];
                __this.checkTrainList.forEach(value => {
                    trainList.forEach(val => {
                        if (value === 0) {
                            trainItemList.push(val)
                        } else if (value === val.motorcycleType) {
                            trainItemList.push(val)
                        }
                    })
                });
                trainList = trainItemList
            }
            // 筛选出发时段
            if (!__this.isAllFormTime && __this.checkFormTrainTimeList.length > 0) {
                let trainItemList = [];
                __this.checkFormTrainTimeList.forEach(value => {
                    trainList.forEach(val => {
                        if (value === 0) {
                            trainItemList.push(val)
                        } else if (value === val.fromTimeCode) {
                            trainItemList.push(val)
                        }
                    })
                });
                trainList = trainItemList
            }
            // 筛选到达时段
            if (!__this.isAllToTime && __this.checkToTrainTimeList.length > 0) {
                let trainItemList = [];
                __this.checkToTrainTimeList.forEach(value => {
                    trainList.forEach(val => {
                        if (value === 0) {
                            trainItemList.push(val)
                        } else if (value === val.toTimeCode) {
                            trainItemList.push(val)
                        }
                    })
                });
                trainList = trainItemList
            }
            // 筛选出发车站
            if (!__this.isAllFormStation && __this.checkFormStationList.length > 0) {
                let trainItemList = [];
                __this.checkFormStationList.forEach(value => {
                    trainList.forEach(val => {
                        if (value === '') {
                            trainItemList.push(val)
                        } else if (value === val.fromStationCode) {
                            trainItemList.push(val)
                        }
                    })
                });
                trainList = trainItemList
            }
            // 筛选到达车站
            if (!__this.isAllToStation && __this.checkToStationList.length > 0) {
                let trainItemList = [];
                __this.checkToStationList.forEach(value => {
                    trainList.forEach(val => {
                        if (value === '') {
                            trainItemList.push(val)
                        } else if (value === val.toStationCode) {
                            trainItemList.push(val)
                        }
                    })
                });
                trainList = trainItemList
            }
            return trainList;
        },
        _changeTrainConditionItem(type, val) { // 点击筛选条件
            const __this = this;

            __this.isHasTicket = false;
            if (type === 'train') {
                __this.isAllTrain = val.length === 0 ? true : false;
            } else if (type === 'seat') {
                __this.isHasTicket = true;
                __this.isAllSeat = val.length === 0 ? true : false;
            } else if (type === 'formTime') {
                __this.isAllFormTime = val.length === 0 ? true : false;
            } else if (type === 'toTime') {
                __this.isAllToTime = val.length === 0 ? true : false;
            } else if (type === 'formStation') {
                __this.isAllFormStation = val.length === 0 ? true : false;
            } else {
                __this.isAllToStation = val.length === 0 ? true : false;
            }

            //单击不限和其他选项进行操作
            if (this.checkTrainList[this.checkTrainList.length - 1] == 0) {
                this.checkTrainList = [0];
            } else {
                this.checkTrainList.splice(this.checkTrainList.indexOf(0), this.checkTrainList.indexOf(0) + 1);
            }

            if (this.checkFormTrainTimeList[this.checkFormTrainTimeList.length - 1] == 0) {
                this.checkFormTrainTimeList = [0];
            } else {
                this.checkFormTrainTimeList.splice(this.checkFormTrainTimeList.indexOf(0), this.checkFormTrainTimeList.indexOf(0) + 1);
            }

            if (this.checkToTrainTimeList[this.checkToTrainTimeList.length - 1] == 0) {
                this.checkToTrainTimeList = [0];
            } else {
                this.checkToTrainTimeList.splice(this.checkToTrainTimeList.indexOf(0), this.checkToTrainTimeList.indexOf(0) + 1);
            }

            if (this.checkFormStationList[this.checkFormStationList.length - 1] == '') {
                this.checkFormStationList = [''];
            } else {
                this.checkFormStationList.splice(this.checkFormStationList.indexOf(''), this.checkFormStationList.indexOf('') + 1);
            }

            if (this.checkToStationList[this.checkToStationList.length - 1] == '') {
                this.checkToStationList = [''];
            } else {
                this.checkToStationList.splice(this.checkToStationList.indexOf(''), this.checkToStationList.indexOf('') + 1);
            }
            this.screenListNum = 0;

            if (this.checkTrainList.length != 0 && this.checkTrainList.indexOf(0) == -1) {
                this.screenListNum = this.screenListNum + this.checkTrainList.length;
            }
            if (this.checkFormTrainTimeList.length != 0 && this.checkFormTrainTimeList.indexOf(0) == -1) {
                this.screenListNum = this.screenListNum + this.checkFormTrainTimeList.length;
            }
            if (this.checkToTrainTimeList.length != 0 && this.checkToTrainTimeList.indexOf(0) == -1) {
                this.screenListNum = this.screenListNum + this.checkToTrainTimeList.length;
            }
            if (this.checkFormStationList.length != '' && this.checkFormStationList.indexOf('') == -1) {
                this.screenListNum = this.screenListNum + this.checkFormStationList.length;
            }
            if (this.checkToStationList.length != '' && this.checkToStationList.indexOf('') == -1) {
                this.screenListNum = this.screenListNum + this.checkToStationList.length;
            }

        },


    }
}