const __request = require(`yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/__request/__request_contentType_json`);
const redirect_to_login_page = require(`@/lib/common-service/redirect_to_login_page`).default;
const dialog_for_request = require(`@/lib/common-service/dialog_for_request`).default;

// const __alert = function (pMsg, pData) {
//     const get_detail_msg = function () {
//         if (!pData) return ``;
//         const axios_options = pData.axios_options;
//         if (!axios_options) return ``;
//         const axios_return_value = pData.axios_return_value;
//         if (!axios_return_value) return ``;
//         let msg = ``;
//         try {
//             msg += `axios_options=\n`;
//             msg += JSON.stringify(axios_options, null, 4);
//             msg += `\n`;
//             msg += `\n`;
//             msg += `axios_return_value=\n`;
//             msg += JSON.stringify(axios_return_value, null, 4);
//         } catch (ex) {
//             alert(ex);
//             console.error(ex);
//         }
//         return msg;
//     };
//     const detail_msg = get_detail_msg();
//
//     dialog_for_request.show({
//         msg: pMsg,
//         detail_msg,
//     });
// };

const __alert = function (pMsg, pData) {
    alert(pMsg);
};

const __request_contentType_json = function (pParameter) {
    if (!pParameter) pParameter = {};
    pParameter.appKey = `haolv-default`;
    pParameter.redirect_to_login_page = redirect_to_login_page;
    pParameter.alert = __alert;
    return __request(pParameter);
};
module.exports = __request_contentType_json;
