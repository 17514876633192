import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: "",
  component: () => import_latest_version_module(),
  children: [
    {
      path: "/h5/reserve-order-detail",
      name: "h5-reserve-order-detail",
      component: () => import(/* webpackChunkName: "page-evection-reserve-order-reserve-order-detail" */ `./index.vue`),
      meta: {
        h5_layout: {
          need_alive: true,
          need_head_nav: false,
          need_footer_bar: false,
          secondary_menu: 2,
        },
      },
    },
  ],
};
