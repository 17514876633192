import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
export default {
    data() {
        return {}
    },
    mounted() {
        const __this = this;
        /*fixStatusBar({
            type: `200`,
            element: __this.$refs.sticky.$el.querySelector(`.navbar`),
        });*/
        fixStatusBar({
            handler({api}) {
                __this.$refs.searchHeader.$el.style.paddingTop = api.safeArea.top + 'px'
            }
        });
    },
    methods: {
        _back() {
            // 判断是否在flutter app里
            if (window.getFlutter == true) {
                // 如果在，则向flutter app发送信息
                try {
                    window.flutter_inappwebview
                        //可以传递你所需要的任意类型数据，数组、对象等
                        .callHandler("backHandler", {'type': 'close'})
                        .then((res)=>{
                            console.log("res==flutter给html的数据", res);
                        })
                } catch (e) {}
                return
            }
            this.$router.go(-1);
        }
    }
}
