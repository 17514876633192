// 根据key值取appConfig的内容
import get_app_config from "@/lib/data-service/yinzhilv-temporary-storage-system/get_app_config";

export default {
  components: {},
  props: {
    page: {
      type: String || Number,
      required: true,
      default: 3,
    },
  },
  data() {
    return {
      currentPage: 1,
      pageVisible: false,
      currentText: "我的出差",
      pageList: [
        { text: "我的出差", value: 1 },
        { text: "我的预订", value: 2 },
        { text: "订单管理", value: 3 },
        // { text: "购买保险", value: 4 },
        // { text: "保单查询", value: 5 },
      ],
    };
  },
  methods: {
    change_visible() {
      this.pageVisible = !this.pageVisible;
    },
    select_getContainer() {
      return document.querySelector(".head");
    },
    change_page(page) {
      this.pageVisible = false;

      const val = Number(page.value);

      if (val === 1) {
        this.$router.push({
          name: "h5-evection-order-list",
        });
      } else if (val === 2) {
        this.$router.push({
          name: "h5-reserve-order-list",
        });
      } else if (val === 3) {
        this.$router.push({
          name: "h5-flight-order-list",
        });
      } else if (val === 4) {
        this.$router.push({
          name: "h5-buy-insurance",
        });
      } else if (val === 5) {
        this.search_insurance();
      }
    },

    search_insurance() {
      get_app_config({ key: "yinzhilv_insurance_search" })
        .then((result) => {
          this.$browser_open_url(result.data.allAppConfigs[0].value);
        })
        .catch((err) => {});
    },
  },
  created() {
    const page = Number(this.page);
    const _this = this;
    _this.currentPage = page;
    _this.pageList.forEach((item) => {
      if (item.value === page) _this.currentText = item.text;
    });
  },
  watch: {},
};
