import NavBar from "@/component/nav-bar/import_latest_version_module";
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
import consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2 from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2'
import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import consumer_trains_order_cancelChangeOrder from '@/lib/data-service/haolv-default/consumer_trains_order_cancelChangeOrder'
import consumer_t_od_trains_order_change_changePayCost from '@/lib/data-service/haolv-default/consumer_t-od-trains-order-change_changePayCost'
import consumer_t_od_trains_order_change_changeConfirm from '@/lib/data-service/haolv-default/consumer_t-od-trains-order-change_changeConfirm'
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import {handlePromise} from "@/page/travel-book/flight/common/unit";
import {Toast, Dialog} from "vant";
import moment from "moment";

export default {
    data() {
        return {
            detailShow: false,
            orderNo: '',
            changeNo: '',
            orderDetail: {},
            changeDetail: {},
            oriPassengerList: [],
            priceInfo: {
                paymentFlag: 3, // 支付类型(1-需要补款 2-需要退款 3-不退不补)
            },
            payTime: 0,
            // payTimeText: '',
            payTimeFun: '',
        }
    },
    components: {NavBar, TrainTimeTable},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo;
        this.changeNo = this.$route.query.changeNo;
        this.getChangeDetail();
        keyback_processor.addEventListener(() => {
            const oriLineShow = this.$refs['oriTrainTimeTable'].get_show();
            if (oriLineShow) {
                this.$refs['oriTrainTimeTable'].hide();
                return
            }
            const chaLineShow = this.$refs['changeTrainTimeTable'].get_show();
            if (chaLineShow) {
                this.$refs['changeTrainTimeTable'].hide();
                return
            }
            this.back()
        })
    },
    deactivated() {
        clearInterval(this.payTimeFun);
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {},
    computed: {
        payTimeText() {
            let payTime = this.payTime;
            if (payTime && payTime > 0) {
                let m = moment(payTime * 1000).format('mm:ss');
                return m
            } else {
                return '00:00'
            }
        },
    },
    filters: {},
    methods: {
        back() {
            // this.$router.go(-1)
            this.$router.push({
                name: 'h5-train-order-list',
                query: {
                    type: 2
                }
            });
        },
        toCancelOrder() {
            Dialog.confirm({
                title: '确认要取消改签吗？',
                message: '一天仅有3次取消改签的机会（包括自动取消），超过3次后当日将无法使用12306账号预订。',
                confirmButtonText: '取消改签',
                cancelButtonText: '稍后再说',
            })
                .then(() => {
                    Toast.loading({
                        message: '',
                        forbidClick: true,
                        duration: 0,
                    });
                    consumer_trains_order_cancelChangeOrder({changeNo: this.changeNo}).then(res => {
                        Toast.clear();
                        console.log(res.datas.ifIsSuccessful);
                        if (res.datas.ifIsSuccessful) {
                            console.log('j');
                            Toast.success('取消成功');
                            // 跳转
                            this.$router.push({
                                name: 'h5-train-change-order-detail',
                                query: {
                                    orderNo: this.changeNo,
                                    status: 3,
                                    jumpType: 'list',
                                }
                            })
                        }
                    }).catch(() => {
                        Toast.clear()
                    })
                })
                .catch(() => {

                });
        },
        openTimeLine(val) {
            this.$refs[val].show();
        },
        toggleShow() {
            this.detailShow = !this.detailShow
        },
        setStatus(val) {
            // 车票状态 0：待预定，1：待出票，2：出票中，3：出 票完成，4：出票失败, 5：退票已申请, 6：退票处理中, 7：退票完成， 8：退票失败，9：改签已申请，10：改签中， 11：改签完成，12：改签失败，13：改签已取消， 14：已改签， 15：待支付， 16：预定中， 17：占座失败
            let result = '';
            if ([0, 1, 2].indexOf(val) >= 0) {
                result = 'pending'
            } else if (val === 3) {
                result = 'success'
            } else if ([13].indexOf(val) >= 0) {
                result = 'cancel'
            } else {
                result = ''
            }
            return result
        },
        getChangeDetail() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
            });
            const getDetail = consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo});
            const getChangeDetail = consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2({changeNo: this.changeNo});
            const getPriceType = consumer_t_od_trains_order_change_changePayCost({changeNo: this.changeNo});
            Promise.all(handlePromise([getDetail, getChangeDetail, getPriceType])).then(res => {
                Toast.clear();
                let passengerIdList = [];
                if (res[1] && res[1].datas) {
                    this.changeDetail = res[1].datas;
                    this.changeDetail.changePassengerInfo.forEach(value => {
                        passengerIdList.push(value.userId)
                    });
                    if ([1, 2, 7].indexOf(this.changeDetail.changeOrderInfo.changeOrderStatus) >= 0) {
                        this.payTime = this.changeDetail.payLimitTime || 0;
                        this.setPayTime()
                    }

                    /*if (this.changeDetail.payLimitTime) {
                        let time = moment().valueOf();
                        time = time + (this.changeDetail.payLimitTime * 1000);
                        this.payTimeText = moment(time).format('HH:mm')
                    }*/
                }
                if (res[0] && res[0].datas) {
                    const datas = res[0].datas;
                    let passengerList = [];
                    datas.passengers.forEach(value => {
                        if (passengerIdList.indexOf(value.userId) >= 0) {
                            passengerList.push(value)
                        }
                    });
                    this.oriPassengerList = passengerList
                }
                if (res[2] && res[2].datas) {
                    this.priceInfo = res[2].datas
                }

                if (res[1] && res[1].datas) {
                    let setTime = setInterval(() => {
                        if (this.$refs['oriTrainTimeTable']) {
                            clearInterval(setTime);
                            this.$refs['oriTrainTimeTable'].init({
                                //出发站三字码
                                fromStationCode: this.changeDetail.originalTrainInfo.fromStationCode,
                                //到达站三字码
                                toStationCode: this.changeDetail.originalTrainInfo.toStationCode,
                                //车次号
                                trainCode: this.changeDetail.originalTrainInfo.trainCode,
                                //日期(2020-05-18)
                                trainDate: this.changeDetail.originalTrainInfo.startDate,
                            })
                        }
                    }, 200);
                    let setTime2 = setInterval(() => {
                        if (this.$refs['changeTrainTimeTable']) {
                            clearInterval(setTime2);
                            this.$refs['changeTrainTimeTable'].init({
                                //出发站三字码
                                fromStationCode: this.changeDetail.changeTrainInfo.changeFromStationCode,
                                //到达站三字码
                                toStationCode: this.changeDetail.changeTrainInfo.changeToStationCode,
                                //车次号
                                trainCode: this.changeDetail.changeTrainInfo.changeTrainCode,
                                //日期(2020-05-18)
                                trainDate: this.changeDetail.changeTrainInfo.changeStartDate,
                            })
                        }
                    }, 200)
                }
            }).catch(() => {
                Toast.clear();
            })
        },
        setPayTime() {
            this.payTimeFun = setInterval(() => {
                this.payTime--;
                if (this.payTime <= 0) {
                    clearInterval(this.payTimeFun);
                    // this.getChangeDetail();
                    Dialog.alert({
                        title: '温馨提示',
                        message: '订单支付已超时，请重新操作',
                    }).then(() => {
                        // on close
                        this.back();
                    });
                }
            }, 1000)
        },
        toChange() {
            if (this.priceInfo.paymentFlag === 1) {
                let changeNo = this.changeNo;
                this.$router.push({
                    name: "h5-travel-payment",
                    query: {
                        orderNo: changeNo,
                        orderType: "2",
                        pageType: 3,
                        businessType: 4,
                    },
                });
            } else {
                Toast.loading({
                    message: '',
                    duration: 0,
                    forbidClick: true,
                });
                consumer_t_od_trains_order_change_changeConfirm({changeNo: this.changeNo}).then(res => {
                    Toast.clear();
                    Toast.success('改签申请成功');
                    // 跳转
                    this.$router.push({
                        name: 'h5-train-change-order-detail',
                        query: {
                            orderNo: this.changeNo,
                            status: 3
                        }
                    })
                }).catch(() => {
                    Toast.clear()
                })
            }
        },
    }
}
