import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/approve-detail',
      name: 'h5-approve-detail',
      component: () => import(/* webpackChunkName: "page-office-approve-detail" */ `./1.0.5/index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '行程审批',
          need_alive: true,
          need_head_nav: false,
          need_footer_bar: false,
          secondary_menu: 2,
        }
      },
    }
  ],
};
