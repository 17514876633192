import moment from "moment";
// 出差单
import applyDetail from "@/lib/data-service/haolv-default/consumer_apply_detail";
// 出差单详情
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail";

export default {
    components: {
    },
    data() {
        return {
            detail: {},
            travelPerson: '', // 出行人员
            travelDate: '', // 出差日期
            detailShow: false
        };
    },
    methods: {
        /*日期处理*/
        format_date(val) {
            if (val) {
                return moment(val).format("YYYY-MM-DD HH:mm");
            } else {
                return '--';
            }
        },
        format_str(val) {
            if (val) {
                return val;
            } else {
                return '--';
            }
        },
        back() {
            this.$router.go(-1)
        },
        getDetail() {
            consumer_journey_getEvectionDetail({evectionNo: this.$route.query.evectionNo || ''}).then(res => {
                this.detail = res.datas;
                for(let item of this.detail.travellerList) { // 拼接出差人员
                    this.travelPerson += item.staffName + '，'
                }
                if(this.travelPerson.length > 0){
                    this.travelPerson = this.travelPerson.substr(0, this.travelPerson.length - 1)
                }
                if (this.detail.startDate && this.detail.endDate) {
                    this.travelDate = this.detail.startDate + ' 至 ' + this.detail.endDate;
                } else if (this.detail.startDate && !this.detail.endDate) {
                    this.travelDate = this.detail.startDate + ' 至 --';
                } else if (!this.detail.startDate && this.detail.endDate) {
                    this.travelDate = '-- 至 ' + this.detail.endDate;
                } else {
                    this.travelDate = '-- 至 --';
                }
            });
        },
        lookUrl(url) { // 下载附件
            window.open(url);
        },
        flight_change(row, index) { // 机票改签
            this.$router.push({
                name: "flight-change",
                query: {
                    evectionNo: row.flightDetail.evectionNo,
                    orderNo: row.flightDetail.orderList[index].orderNo
                }
            });
        },
        flight_refund(row, index) { // 机票退订
            this.$router.push({
                name: "flight-refund",
                query: {
                    orderStatus: 1,
                    orderNo: row.flightDetail.orderList[index].orderNo
                }
            });
        },
        to_flight_order_detail(row) { // 行程单机票详情
            this.$router.push({
                name: 'flight-order-detail',
                query: {
                    orderNo: row.orderNo
                },
            });
        },
        to_train_order_detail(row) { // 行程火车票详情
            this.$router.push({
                name: 'h5-train-order-detail',
                query: {
                    orderNo: row.orderNo
                },
            });
        },
        to_hotel_order_detail(row) { // 行程酒店情
            console.log(row)
            this.$router.push({
                name: 'h5-hotel-order-detail',
                query: {
                    orderNo: row.orderNo
                },
            });
        },
        train_change(row) { // 火车票改签
            this.$router.push({
                name: "h5-train-change",
                query: {
                    orderNo: row.orderNo
                }
            });
        },
        train_refund(row) { // 火车票退票
            this.$router.push({
                name: "h5-train-refund",
                query: {
                    orderNo: row.orderNo
                }
            });
        },
        hotel_refund(row) { // 酒店退订
            this.$router.push({
                name: "hotel-refund",
                query: {
                    orderNo: row.orderNo
                }
            });
        }
    },
    created() {
        

    },
    activated() {
        this.getDetail()

    },
    deactivated() {

    },
    filters: {
        format_status(val) {
            switch (Number(val)) {
                case 0:
                    return "待支付";
                    break;
                case 1:
                    return "支付中";
                    break;
                case 2:
                    return "已支付，待出房";
                    break;
                case 4:
                    return "已支付，待入住";
                    break;
                case 6:
                    return "已取消";
                    break;
                case 7:
                    return "取消中";
                    break;
                case 8:
                    return "取消失败";
                    break;
                case 9:
                    return "预下单";
                    break;
                case 10:
                    return "创建订单失败";
                    break;
                case 11:
                    return "已出房";
                    break;
                case 12:
                    return "已退款";
                    break;
                case 13:
                    return "退款失败";
                    break;
                case 14:
                    return "出房失败";
                    break;
                case 15:
                    return "退款中";
                    break;
                // case 16:
                //   return "已退房";
                //   break;
                // case 17:
                //   return "已退房";
                //   break;
                case -1:
                    return "已删除";
                    break;
            }
        },
    },
}
