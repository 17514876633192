import CommonlyUsedPassenger from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/commonly-used-passenger/1.0.3/index.vue';
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import {Toast} from "vant";
export default {
  components: {
    CommonlyUsedPassenger
  },
  data() {
    return {

    };
  },
  methods: {

  },
  mounted(){
    this.$refs.aCommonlyUsedPassenger.init({
        type: '000',
    });
  },
  activated () {
    keyback_processor.addEventListener(() => {
      const aCommonlyUsedPassenger = this.$refs.aCommonlyUsedPassenger
      if (aCommonlyUsedPassenger.getShow()) {
        aCommonlyUsedPassenger.hidePopup()
        return
      }
      this.$router.go(-1)
    })
  },
  deactivated () {
    keyback_processor.removeEventListener();
  }
};
