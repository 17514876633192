import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/hotel-about',
            name: 'h5-hotel-about',
            component: () => import(/* webpackChunkName: "page-travel-book-hotel-hotel-about" */ `./1.0.0/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '酒店介绍',
                    need_alive: true,
                },

                data_for_all_routes_processor: {
                    order_index: '012-003',
                },

            },
        }
    ],
};
