import moment from "moment";
import req_detail_data from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_queryHotelCancelOrderDetails";
export default {
    data () {
        return {
            detail: {
                order: {},
                personList: {},
                recordList: []
            },
            active: 1,
            person: '',
            psgNameList: []
        }
    },
    components: {},
    created () {
        this.getOrderDetail()
    },
    mounted () {},
    activated () {},
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    methods: {
        format_date(val) {
            return moment(val).format("YYYY-MM-DD HH:mm");
        },
        back() {
            this.$router.go(-1)
        },
        // 获取订单详情
        getOrderDetail() {
            const __this = this;
            const orderNo = this.$route.query.orderNo || '';
            req_detail_data({orderNo}).then(res => {
                this.detail = {
                    order: res.orderInterimResult,
                    personList: res.orderPersonList,
                    recordList: res.recordList,
                };
                for(let item of this.detail.personList) { // 拼接入住人员
                    this.person += item.lastName + item.firstName + '，'
                }
                switch (this.detail.order.cancelStatus) {
                    case 0:
                        __this.active = 1;
                        break;
                    case 1:
                        __this.active = 2;
                        break;
                    case 2:
                        __this.active = 1;
                        break;
                    case 3:
                        __this.active = 0;
                        break;
                    default:
                        __this.active = 0;
                }
            });
        },
    },
    filters: {
        format_date(val) {
            return moment(val).format("YYYY-MM-DD HH:mm");
        },
        format_approveStatus(val) {
            //审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return '未开始'
            } else if (val === 1) {
                return '待我审'
            } else if (val === 2) {
                return '审核通过'
            } else if (val === 3) {
                return '驳回'
            } else if (val === 4) {
                return '已失效'
            }
        },
    }
}