import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/external-customer-edit',
      name: 'h5-external-customer-edit',
      component: () => import(/* webpackChunkName: "page-personal-center-external-customer-external-customer-edit" */ `./index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '新增外部客户',
          need_head_nav: false,
          need_head_nav1: false,
          need_footer_bar: false,
          need_alive: true,
          secondary_menu: 3,
        },

        data_for_all_routes_processor: {
          order_index: `050-002`,
        },
      },
    }
  ],
};
