// consumer_phoneLogin app 短信登录
const Q = require(`q`);
const _ = require(`underscore`);
const set_token = require('yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/set_token');
const __request = require('yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/__request/__request_contentType_json');

// 接口文档地址：
// http://219.128.52.2:18765/doc.html#/haolv-consumer/center-controller/phoneLoginUsingPOST
const consumer_phoneLogin = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/phoneLogin',
        data: pParameter,
        check_token: false,
        enable_error_alert: false
    };
    return Q.when()
        .then(function () {
            return __request(p);
        })
        .then(function (data) {
            const datas = data.datas;
            if (!datas) {
                const msg = 'consumer_login parameter error, code=101';
                console.log(msg);
                return Q.reject({msg: msg});
            }

            const access_token = datas.access_token;
            if (!access_token) {
                const msg = 'consumer_login parameter error, code=102';
                console.log(msg);
                return Q.reject({msg: msg});
            }

            const user_detail = datas.user_detail;
            if (!user_detail) {
                const msg = 'consumer_login parameter error, code=103';
                console.log(msg);
                return Q.reject({msg: msg});
            }

            //账户类型：1-平台用户，2-客户，3-客户员工
            const userType = user_detail.userType;
            if (!_.contains([1, 2, 3], userType)) {
                const msg = 'consumer_login parameter error, code=201';
                console.log(msg);
                return Q.reject({msg: msg});
            }

            set_token({token: access_token});

            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.log(ex);
            if (ex) {
                if (ex.stack) {
                    console.log(ex.stack);
                }
            }
            return Q.reject(ex);
        });
};
module.exports = consumer_phoneLogin;
