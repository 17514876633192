import moment from "moment";
import consumer_web_city_areaLinkage from '@/lib/data-service/haolv-default/consumer_web_city_areaLinkage'
import consumer_web_staff_addOrUpdate from '@/lib/data-service/haolv-default/consumer_web_staff_addOrUpdate'
import consumer_tUsRank_rankList from '@/lib/data-service/haolv-default/consumer_t-us-rank_rankList' // 获取职级list
import consumer_web_staff_detail from '@/lib/data-service/haolv-default/consumer_web_staff_detail'
import consumer_department_getDepartments from '@/lib/data-service/haolv-default/consumer_department_getDepartments'
import {Toast} from "vant";
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
export default {
    data() {
        return {
            value: '',
            // list: [],
            result: [],

            newUserPopup: false, // 新增出行人弹窗
            newUserParams: {
                birthDate: '', // 生日
                birthdayText: '',
                certificates: [{
                    certificateType: 1, // 1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它
                    certificateTypeText: '身份证',
                    certificateValue: '', // 证件号
                    gmtCreate: '',
                    gmtModified: '',
                    id: '',
                    status: '', // 证件状态：1.正常，2.过期，3.删除
                }], // 证件合集
                nationality: 70007, // 国籍
                nationalityText: '中国',
                phone: '',
                rankId: '', // 职级id
                rankText: '',
                staffName: '', // 姓名
                staffSex: 1, // 性别：1.男，2.女
                deptId: '', // 部门id
                deptArr: [],
                email: '',
                englishName: '', // 英文姓名
                enSurname: '', // 英文性别
                enName: '', // 英文名字
                roleIds: [], // 角色id集合
                staffId: '', // 员工id,修改必填
                positionId: '', // 职位
            },

            minDate: new Date(1900, 0, 1),
            // maxDate: new Date(2025, 10, 1),
            newType: 'add',// 新增add 编辑edit

            showUserSexPicker: false,
            userSexColumn: [{text: '男', id: 1}, {text: '女', id: 2}],
            showUserCardTypePicker: false,
            userCardTypeColumn: [
                {text: '身份证', id: 1},
                {text: '护照', id: 2},
                {text: '军官证', id: 4},
                {text: '回乡证', id: 5},
                {text: '台胞证', id: 6},
                {text: '港澳通行证', id: 7},
                {text: '台湾通行证', id: 8},
                {text: '其他', id: 9},
                {text: '学生证', id: 10},
            ],
            showBirthdayPicker: false,
            showUserNationalityCodePicker: false,
            userNationalityCodeColumn: [],
            showRankPicker: false,
            rankList: [],
            structureList: [],
            paddingTop: 0,
            paddingBottom: 0,
        }
    },
    props: {
        // 出行人弹窗开关
        selectUserPopup: {
            type: Boolean,
            default: false
        },
        // 选中的出行人列表
        activeResultList: {
            type: Array,
            default: () => []
        },
        // 限制多选的人数
        maxCheckUser: {
            type: Number,
            default: 9
        },
        // 传入的员工列表
        list: {
            type: Array,
            default: () => []
        },
        showAddBtn: {
            type: Boolean,
            default: true
        }
    },
    components: {},
    created() {
    },
    mounted() {
        this.getAreaLine();
        this.getRankList();
        this.getStructureList();
        const __this = this;
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                __this.paddingTop = api.safeArea.top;
                __this.paddingBottom = api.safeArea.bottom
            }
        });
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        userPopup: {
            get () {
                return this.selectUserPopup
            },
            set (val) {
                this.$emit('update:selectUserPopup', val)
            }
        },
        activeResult: {
            get () {
                return this.activeResultList
            },
            set (val) {
                this.$emit('update:activeResultList', val)
            }
        },
    },
    filters: {
        filterIdType(val) {
            // 证件类型 (1:身份证 2:护照 4:港澳通行证 5:台胞证 6:台湾通行证 7:回乡证 8:军官证 9:其他 10:学生证)
            if (val === 1) {
                return '身份证'
            } else if (val === 2) {
                return '护照'
            } else if (val === 3) {
                return ''
            } else if (val === 4) {
                return '港澳通行证'
            } else if (val === 5) {
                return '台胞证'
            } else if (val === 6) {
                return '台湾通行证'
            } else if (val === 7) {
                return '回乡证'
            } else if (val === 8) {
                return '军官证'
            } else if (val === 9) {
                return '其他'
            } else if (val === 10) {
                return '学生证'
            } else {
                return ''
            }
        }
    },
    methods: {
        // 打开用户选择触发
        openUserPopup() {
            let result = []
            this.activeResult.forEach(value => {
                result.push(value.id)
            })
            this.result = result
        },
        addNewUser() {
            this.newType = 'add'
            this.newUserParams = {
                birthDate: '', // 生日
                birthdayText: '',
                certificates: [{
                    certificateType: 1, // 1.身份证，2.护照，3.港澳通行证，4.回乡证，5.台胞证，6.军官证，7.香港身份证，8.台湾通行证，9.户口本，10.其它
                    certificateTypeText: '身份证',
                    certificateValue: '', // 证件号
                    gmtCreate: '',
                    gmtModified: '',
                    id: '',
                    status: '', // 证件状态：1.正常，2.过期，3.删除
                }], // 证件合集
                nationality: 70007, // 国籍
                nationalityText: '中国',
                phone: '',
                rankId: '', // 职级id
                rankText: '',
                staffName: '', // 姓名
                staffSex: 1, // 性别：1.男，2.女
                deptId: '', // 部门id
                deptArr: [],
                email: '',
                englishName: '', // 英文姓名
                enSurname: '', // 英文性别
                enName: '', // 英文名字
                roleIds: [], // 角色id集合
                staffId: '', // 员工id,修改必填
                positionId: '', // 职位
            }
            this.newUserPopup = true
        },
        back() {
            this.userPopup = false
            this.$emit('close')
        },
        changeCheckBoxList(val) {

        },
        toggle(index, item) {
            this.$refs.checkboxes[index].toggle();
        },
        toEdit(val) {
            consumer_web_staff_detail({id: val.staffId}).then(res => {
                let userData = res.datas[0]
                this.newUserParams = Object.assign({}, this.newUserParams, userData)
                this.newUserParams.certificateList.forEach(value => {
                    value.certificateTypeText = this.getCardType(value.certificateType)
                })
                if (this.newUserParams.englishName) {
                    let englishNameArr = this.newUserParams.englishName.split('.')
                    this.newUserParams.enSurname = englishNameArr[0] // 英文性别
                    this.newUserParams.enName = englishNameArr[1] // 英文名字
                }
                // this.newParams.nationality = parseInt(this.newParams.nationality)
                this.newUserParams.deptArr = this.getDeptArr(this.newUserParams.deptId)
                let roleIds = []
                this.newUserParams.roleList.forEach(val => {
                    roleIds.push(val.id)
                })
                this.newUserParams.roleIds = roleIds // 角色列表
                this.newUserParams.rankText = this.getRank(this.newUserParams.rankId)
                this.newUserParams.certificates = this.newUserParams.certificateList // 身份文件列表


                this.newType = 'edit'
                this.newUserPopup = true
                this.$nextTick(() => {
                    this.$refs['newUserForm'].resetValidation()
                })
            })
        },
        getRank(val) {
            let text = ''
            this.rankList.forEach(value => {
                if (val === value.id) {
                    text = value.rankName
                }
            })
            return text
        },
        getCardType(val) {
            let text = ''
            this.userCardTypeColumn.forEach(value => {
                if (value.id === val) {
                    text = value.text
                }
            })
            return text
        },
        getDeptArr(val) {
            let deptArr = []
            let _this = this
            const getDept = (value) => {
                for (let i = 0, l = this.structureList.length; i < l; i++) {
                    if (value === this.structureList[i].id) {
                        deptArr.unshift(value)
                        if (this.structureList[i].parentId !== 0) {
                            getDept(this.structureList[i].parentId)
                        }
                    }
                }
            }
            getDept(val)
            return deptArr
        },
        toSubmitAdd() {
            let activeResult = []
            this.list.forEach(value => {
                if (this.result.indexOf(value.id) > -1) {
                    activeResult.push(value)
                }
            })
            this.activeResult = activeResult
            this.userPopup = false
            this.$emit('submitAddUser')
        },

        closeUserPopup() {
            this.newUserPopup = false
        },
        onAddOrEditSubmit() {
            console.log('chufa');
            this.newType === 'add' ? this.submitAdd() : this.submitEdit()
        },
        clickAddOrEditSubmit() {
            this.$refs.newUserForm.submit()
        },
        submitAdd() {
            Toast.loading({
                message: '',
                forbidClick: true,
            });
            let newParams = Object.assign({}, this.newUserParams)
            newParams.username = newParams.phone
            consumer_web_staff_addOrUpdate(newParams).then(res => {
                Toast.success('新增成功')
                this.newUserPopup = false
                this.$emit('addSuccess')
            }).catch(() => {
            }).finally(() => {
                Toast.clear()
            })
        },
        submitEdit() {
            this.newUserParams.deptId = this.newUserParams.deptArr[this.newUserParams.deptArr.length - 1]
            /*this.loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading',
            })*/
            Toast.loading({
                message: '',
                forbidClick: true,
            });
            let newParams = Object.assign({}, this.newUserParams)
            newParams.username = newParams.staffName
            consumer_web_staff_addOrUpdate(newParams).then(res => {
                // this.loading.close()
                Toast.clear()
                Toast.success('保存成功');
                this.newUserPopup = false
                this.$emit('addSuccess')
            }).catch(() => {
                Toast.clear()
            }).finally(() => {

            })
        },

        toShowUserCardTypePicker() {
            this.showUserCardTypePicker = true
            this.$nextTick(() => {
                for (let i = 0, l = this.userCardTypeColumn.length; i < l; i++) {
                    if (this.userCardTypeColumn[i].id === this.newUserParams.certificates[0].certificateType) {
                        this.$refs.userCardTypePicker.setIndexes([i])
                        break
                    }
                }
            })
        },
        getAreaLine() {
            consumer_web_city_areaLinkage({levelType: 1}).then(res => {
                this.userNationalityCodeColumn = res.datas
            })
        },
        getRankList() {
            consumer_tUsRank_rankList().then(res => {
                this.rankList = res.datas
            })
        },
        getStructureList() {
            consumer_department_getDepartments().then(res => {
                this.structureList = res.datas.departmentVos
            })
        },
        getIDCardDate(index, val) {
            console.log(index, val)
            let certificateType = this.newUserParams.certificates[index].certificateType
            if (certificateType !== 1) {
                return
            }
            const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            if (regIdCard.test(val)) {
                let birthDay = this.getBirth(val)
                this.newUserParams.birthDate = birthDay
            }
        },
        getBirth(idCard) {
            let birthday = '';
            if (idCard != null && idCard !== '') {
                if (idCard.length === 15) {
                    birthday = '19' + idCard.slice(6,12);
                } else if (idCard.length === 18) {
                    birthday = idCard.slice(6,14);
                }
                birthday = birthday.replace(/(.{4})(.{2})/,'$1-$2-');
                //通过正则表达式来指定输出格式为:1990-01-01
            }
            // 判断输入的生日号码是否为正常的日期
            if (isNaN(new Date(birthday).getTime())) {
                birthday = ''
            }
            console.log(birthday)
            return birthday;
        },
        idCardValidator(val) {
            if (this.newUserParams.idType === 1 && this.newUserParams.nationalityCode === 'CHN') {
                const regIdCard = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
                if (!regIdCard.test(val)) {
                    return false
                } else {
                    return true
                }
            } else {
                return true
            }
        },
        phoneValidator(val) {
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/
            if (regExp.test(val) && val.toString().length === 11) {
                return true
            } else {
                return false
            }
        },
        onUseCardTypeConfirm(val) {
            this.newUserParams.idType = val.id;
            this.newUserParams.idTypeName = val.text;
            this.showUserCardTypePicker = false
        },
        toShowUserSexPicker() {
            this.showUserSexPicker = true
            this.$nextTick(() => {
                for (let i = 0, l = this.userSexColumn.length; i < l; i++) {
                    if (this.userSexColumn[i].id === this.newUserParams.sex) {
                        this.$refs.sexPicker.setIndexes([i])
                        break
                    }
                }
            })
        },
        toShowUserNationalityCode() {
            this.showUserNationalityCodePicker = true
            this.$nextTick(() => {
                for (let i = 0, l = this.userNationalityCodeColumn.length; i < l; i++) {
                    if (this.userNationalityCodeColumn[i].id === this.newUserParams.nationality) {
                        this.$refs.countryPicker.setIndexes([i])
                        break
                    }
                }

            })
        },
        onUseNationalityCodeConfirm(val) {
            console.log(val)
            this.newUserParams.nationality = val.id;
            this.newUserParams.nationalityText = val.name.split('-')[1];
            this.showUserNationalityCodePicker = false
        },
        changeEnSurname(val) {
            this.newUserParams.englishName = val + '.' + this.newUserParams.enName
        },
        changeEnName(val) {
            this.newUserParams.englishName = this.newUserParams.enSurname + '.' + val
        },
        toShowBirthdayPicker() {
            this.newUserParams.birthdayText = new Date(this.newUserParams.birthDate)
            this.showBirthdayPicker = true
        },
        // 确定生日
        onBirthdayConfirm(val) {
            console.log(val)
            this.newUserParams.birthDate = moment(val).format('YYYY-MM-DD');
            this.newUserParams.birthdayText = val;
            this.showBirthdayPicker = false
        },
        toShowRankPicker() {
            this.showRankPicker = true
            this.$nextTick(() => {
                for (let i = 0, l = this.rankList.length; i < l; i++) {
                    if (this.rankList[i].id === this.newUserParams.rankId) {
                        this.$refs.rankPicker.setIndexes([i])
                        break
                    }
                }
            })
        },
        onRankTypeConfirm(val) {
            console.log(val)
            this.newUserParams.rankId = val.id;
            this.newUserParams.rankText = val.rankName;
            this.showRankPicker = false
        },
    }
}