import Vue from 'vue'
import Vuex from 'vuex'
import Q from 'q'
import moment from 'moment'
import * as getters from './getter'

const api_system_getCurrentUser = require(`@/lib/data-service/system/api_system_getCurrentUser`);
const api_system_getGlobalData = require(`@/lib/data-service/system/api_system_getGlobalData`);
const api_system_get_server_config = require(`@/lib/data-service/system/api_system_get_server_config`);
import consumer_apply_approveNumber from '@/lib/data-service/haolv-default/consumer_apply_approveNumber'
import consumer_sys_inform_unreadMessagesNumber
    from '@/lib/data-service/haolv-default/consumer_sys_inform_unreadMessagesNumber'
import consumer_menu_queryCompanyConfig from '@/lib/data-service/haolv-default/consumer_menu_queryCompanyConfig';
let __store;

let promise_init_global_data;
let promise_init_current_user;
let promise_init_server_config;

const init = function () {
    Vue.use(Vuex);

    __store = new Vuex.Store({
        state: {

            last_refresh_time: moment().format(`YYYY-MM-DD HH:mm:ss`),

            app_config: {},

            global_data: {},

            current_user: {},

            data_for_base_layout: {

                header: {
                    show: true,
                    title: ``,
                    style: {
                        paddingTop: `0`,
                    },
                },

                tab_bar: {
                    show: true,
                    style: {
                        paddingBottom: `0`,
                    },
                },

                transition: {
                    name: ``,

                    default_name: `van-fade`,
                },

            },

            server_phone: '400-626-8448',
            reserveTransitionName: 'van-slide',
            data_get_user_info: {},

            applyListNum: 0, // 未读审批数
            noticeNum: 0, // 未读通知数

            dialog_for_request: {
                show: false,
                title: ``,
                msg: ``,
                detail_msg: ``,
            },

            userInfoDefer: {},
            userInfo: {},

            policyDefer: {}, // 出差政策获取异步
            policy: {}, // 出差政策

            workflowDefer: {},
            workflow: '2', // 审批流类型 1 新， 2旧

            btnList: [], // 权限按钮列表  eg: ['getBudgetDetailButton'],

            evectionBackType: '000', // 单点登录模式； ‘000’：普通模式，非单点情况或者普通单点情况； ‘001’： 前海人寿规定情况

            tokenCancelNeedJumpToLogin: 1, // 1:默认，token失效就去登录页， 2:不去登录页，留在原地提示

            hotelTogether: false,
        },
        getters,
        mutations: {

            update_last_refresh_time(state, pData) {
                console.log(pData);
                state.last_refresh_time = pData;
            },

            init_app_config(state, pData) {
                state.app_config = pData;
            },

            init_global_data(state) {
                promise_init_global_data = Q.when()
                    .then(function () {
                        return api_system_getGlobalData();
                    })
                    .then(function (data) {
                        state.global_data = data.entity;
                        global.global_data = data.entity;
                    })
                    .catch(function (ex) {
                        console.error(ex);
                        if (ex) {
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                        return Q.reject(ex);
                    })
                ;

            },

            init_current_user(state) {
                promise_init_current_user = Q.when()
                    .then(function () {
                        return api_system_getCurrentUser();
                    })
                    .then(function (data) {
                        state.current_user = data.entity;
                        global.current_user = data.entity;
                    })
                    .catch(function (ex) {
                        console.error(ex);
                        if (ex) {
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                        return Q.reject(ex);
                    })
                ;

            },

            init_server_config(state) {
                promise_init_server_config = Q.when()
                    .then(function () {
                        return api_system_get_server_config();
                    })
                    .then(function (data) {
                        state.server_config = data.entity;
                        global.server_config = data.entity;
                    })
                    .catch(function (ex) {
                        console.error(ex);
                        if (ex) {
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                        return Q.reject(ex);
                    })
                ;

            },

            update_transitionName(state, params) {
                state.reserveTransitionName = params;
            },

            update_data_get_user_info(state, params) {
                state.data_get_user_info = params;
            },

            setApplyListNum(state, params) {
                state.applyListNum = params
            },

            setNoticeNum(state, params) {
                state.noticeNum = params
            },

            setUserInfo(state, val) {
                state.userInfo = val;
            },

            setWorkflow(state, val) {
                state.workflow = val;
            },

            setUserInfoDefer(state, val) {
                state.userInfoDefer = val;
            },

            setWorkflowDefer(state, val) {
                state.workflowDefer = val;
            },

            setPolicy(state, val) {
                state.policy = val;
            },

            setPolicyDefer(state, val) {
                state.policyDefer = val;
            },
            setBtnList(state, val) {
                state.btnList = val;
            },
            setEvectionBackType(state, val) {
                state.evectionBackType = val;
            },
            setTokenCancelNeedJumpToLogin(state, val) {
                state.tokenCancelNeedJumpToLogin = val;
            },
            setHotelTogether(state, val) {
                state.hotelTogether = val;
            }
        },
        actions: {
            updateCompanyInfo({commit}) {
                consumer_menu_queryCompanyConfig().then(res => {
                    let hotelTogether = res.datas.companyExpendVo.hotelTogether || false;
                    commit('setHotelTogether', hotelTogether);
                })
            },
            updateApplyListNum({commit}) {
                consumer_apply_approveNumber().then(res => {
                    let applyListNum = res.datas.approvedNumber
                    commit('setApplyListNum', applyListNum)
                })
            },
            updateNoticeNum({commit}) {
                consumer_sys_inform_unreadMessagesNumber().then(res => {
                    let unreadNumber = res.datas.unreadNumber
                    commit('setNoticeNum', unreadNumber)
                })
            }
        }
    });

    // __store.commit(`init_global_data`);
    // __store.commit(`init_current_user`);
    // __store.commit(`init_server_config`);

    global.__store = __store;

    return __store;
};
init();

const update_last_refresh_time = function (pData) {
    __store.commit(`update_last_refresh_time`, pData);
};

const get_global_data = function () {
    return Q.when()
        .then(function () {
            return promise_init_global_data;
        })
        .then(function () {
            return __store.state.global_data;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

const get_current_user = function () {
    return Q.when()
        .then(function () {
            return promise_init_current_user;
        })
        .then(function () {
            return __store.state.current_user;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

const get_server_config = function () {
    return Q.when()
        .then(function () {
            return promise_init_server_config;
        })
        .then(function () {
            return __store.state.server_config;
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;
};

export default __store;
