export default {
  props: {
    list: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    emptyType: {
      type: String,
      required: true,
    }
  },
  data() {
    return {

    };
  },
};
