import import_latest_version_module from "@/component/h5-layout/import_latest_version_module";

export default {
    path: "",
    component: () => import_latest_version_module(),
    children: [
        {
            path: "/h5/add-evection",
            name: "h5-add-evection",
            component: () => import(/* webpackChunkName: "page-office-add-evection" */ `./3.1.4/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: "添加出差单",
                    need_head_nav: false,
                    need_footer_bar: false,
                    secondary_menu: 2,
                    need_alive: true,
                },

                data_for_all_routes_processor: {
                    order_index: '040-001',
                },

            },
        },
    ],
};
