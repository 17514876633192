// consumer_car_link_getCarBudgetInfo
const __request = require(`./__request/__request_contentType_json`);
const consumer_car_link_getCarBudgetInfo = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/car/link/getCarBudgetInfo',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_car_link_getCarBudgetInfo;