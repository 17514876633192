import consumer_sys_sms_vcodeSend from '@/lib/data-service/haolv-default/consumer_sys_sms_vcodeSend';//获取验证码
import consumer_sys_sms_vcodeVerify from "@/lib/data-service/haolv-default/consumer_sys_sms_vcodeVerify";// 验证手机号码和验证码

export default {
    data() {
        // var validateCode = (value) => {
        //     console.log('value.length', value.length)
        //     if (value.length !== 6) {
        //         return false;
        //     }
        //     return true;
        // };
        return {
            phone: '', //旧手机号
            code: '', //验证码
            errMsg: '', //错误信息
            second: 60, //倒计时秒数
            isSending: false, //是否正在发送验证码
            // rules: {
            //     code: [
            //         { required: true, message: "请输入验证码"},
            //         { validator: validateCode,  message: "请输入正确格式的验证码"},
            //     ],
            // },
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        //初始化
        init() {
            this.phone = this.$route.query.phone;
            this.code = '';
            this.errMsg = '';
            clearInterval(this.intervalFun);
            this.second = 60;
            this.isSending = false;
        },

        //获取验证码
        async getCode() {
            if (this.isSending) {
                return;
            }
            this.isSending = true;
            clearInterval(this.intervalFun);
            this.setInterval();
            let params = {
                needChangePhone: false,
                phone: this.phone,
                sence: 'ALIYUN_VCODE_CHANGE_INFO',
                check_token: true,
                enable_error_alert: false
            }
            consumer_sys_sms_vcodeSend(params).then(res => {
                if (res.datas.code !== 'OK') {
                    this.errMsg = res.datas.message;
                    clearInterval(this.intervalFun);
                    this.second = 60;
                    this.isSending = false;
                    return;
                }
            }).catch(err => {
                clearInterval(this.intervalFun);
                this.second = 60;
                this.isSending = false;
                this.$toast(err.data.msg);
                return;
            })
        },

        //提交
        async submit() {
            if (!this.code) {
                this.errMsg = '请输入验证码';
                return;
            }
            if (this.code.length !== 6) {
                this.errMsg = '验证码格式不正确';
                return;
            }

            let params = {
                phone: this.phone,
                vcode: this.code,
            }
            consumer_sys_sms_vcodeVerify(params).then(res => {
                if (!res.datas) {
                    this.errMsg = res.msg;
                    return;
                }
                this.$router.push({
                    name: "h5-verify-new-phone"
                });
            }).catch(err => {
                this.errMsg = err.data.msg;
                return;
            });

        },

        //返回
        back() {
            this.$router.go(-1);
        },

        //倒计时
        setInterval() {
            this.intervalFun = setInterval(() => {
                this.second--;
                if (this.second <= 0) {
                    clearInterval(this.intervalFun);
                    this.second = 60;
                    this.isSending = false;
                }
            }, 1000)
        },
    }
}
