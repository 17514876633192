// consumer_flight_search_checkFlightPolicy
const __request = require(`./__request/__request_contentType_json`);
const consumer_flight_search_checkFlightPolicy = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: '/consumer/flight/search/checkFlightPolicy',
    data: pParameter
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_flight_search_checkFlightPolicy;
