import moment from "moment";
// 列表为空展示组件
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";
import Search from '@/page/personal-center/order/components/flightListSearch/1.0.0/index.vue'
// 订单列表
import getTrainsOrderList from "@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderList";
// 改签列表
import getTrainsChangeOrderList from "@/lib/data-service/haolv-default/consumer_trains_order_getTrainsChangerOrderList";
// 退款列表
import getTrainsReturnOrderList from "@/lib/data-service/haolv-default/consumer_trains_order_getTrainsReturnOrderList";
// 申请取消
import applyForCancel from "@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel";
// 取消改签
import cancelChangeOrder from "@/lib/data-service/haolv-default/consumer_trains_order_cancelChangeOrder";
// 订单列表--删除订单
import consumer_trains_order_deleteTrainsOrder from "@/lib/data-service/haolv-default/consumer_trains_order_deleteTrainsOrder";
// 滑屏返回
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";


import {Notify, Dialog, Toast} from "vant";

export default {
    components: {
        ListEmpty,
        Search
    },
    data() {
        return {
            statusList: [
                {label: "我的订单", value: "0", placeholder: '订单号/出行人姓名/城市'},
                {label: "改签单", value: "2", placeholder: '订单号/改签乘客姓名/城市'},
                {label: "退票单", value: "3", placeholder: '订单号/退票乘客姓名/城市'},
            ],
            form: {
                currentPage: 1,
                pageSize: 0,
                orderNo: "",
            },
            orderType: 0,
            tabType: 0,
            list: null,
            loading: false,
            finished: true,
            refreshing: false,
            emptyType: "",
            placeholder: '订单号/出行人姓名/城市'
        };
    },
    watch: {
        loading(val) {
            if (val) {
                this.$showLoading.show();
            } else {
                this.$showLoading.hide();
            }
        },
    },
    methods: {
        req_list() {
            this.emptyType = "";
            const form = this.form;
            let req_list;
            switch (this.orderType) {
                case 0:
                    req_list = getTrainsOrderList;
                    this.tabType = 1;
                    break;
                case 2:
                    req_list = getTrainsChangeOrderList;
                    this.tabType = 2;
                    break;
                case 3:
                    req_list = getTrainsReturnOrderList;
                    this.tabType = 3;
                    break;
                case '0':
                    req_list = getTrainsOrderList;
                    this.tabType = 1;
                    break;
                case '2':
                    req_list = getTrainsChangeOrderList;
                    this.tabType = 2;
                    break;
                case '3':
                    req_list = getTrainsReturnOrderList;
                    this.tabType = 3;
                    break;
            }

            let data = {
                currentPage: form.currentPage,
                pageSize: form.pageSize,
                keyword: form.orderNo,
            };
            req_list(data).then((result) => {
                const datas = result.datas;
                const totalCount = datas.totalCount;
                if (totalCount > form.pageSize) {
                    this.finished = false;
                } else {
                    form.pageSize = totalCount;
                    this.finished = true;
                }

                if (!datas.list.length) this.emptyType = "search";

                this.list = datas.list;
                this.refreshing = false;
                this.loading = false;
            }).catch((err) => {
                this.list = [];
                this.emptyType = "network";
                this.finished = true;
                this.refreshing = false;
                this.loading = false;
            });
        },
        onSecretary() {},
        //退票订单删除
        refund_delete(val){
            Dialog.confirm({
                title: '提示',
                message: '确定要删除订单吗?',
            }).then(() => {
                this.$showLoading.show();
                let data = {
                    businessType: 3,
                    orderNo: val,
                };
                consumer_trains_order_deleteTrainsOrder(data)
                    .then(res => {
                        this.$showLoading.hide();
                        this.$Toast.success('删除成功');
                        this.req_list();
                    })
                    .catch(rej => {
                        this.$showLoading.hide();
                        this.$Toast.fail('删除失败');
                    })
            })
            .catch(() => {
                this.$showLoading.hide();
            });
        },
        //改签订单删除
        change_delete(val){
            Dialog.confirm({
                title: '提示',
                message: '确定要删除订单吗?',
            }).then(() => {
                this.$showLoading.show();
                let data = {
                    businessType: 2,
                    orderNo: val,
                };
                consumer_trains_order_deleteTrainsOrder(data)
                    .then(res => {
                        this.$showLoading.hide();
                        this.$Toast.success('删除成功');
                        this.req_list();
                    })
                    .catch(rej => {
                        this.$showLoading.hide();
                        this.$Toast.fail('删除失败');
                    })
            })
            .catch(() => {
                this.$showLoading.hide();
            });
        },
        //原订单删除
        order_delete(val){
            Dialog.confirm({
                title: '提示',
                message: '确定要删除订单吗?',
            }).then(() => {
                this.$showLoading.show();
                let data = {
                    businessType: 1,
                    orderNo: val,
                };
                consumer_trains_order_deleteTrainsOrder(data)
                    .then(res => {
                        this.$showLoading.hide();
                        this.$Toast.success('删除成功');
                        this.req_list();
                    })
                    .catch(rej => {
                        this.$showLoading.hide();
                        this.$Toast.fail('删除失败');
                    })
            })
            .catch(() => {
                this.$showLoading.hide();
            });
        },

        onRefresh() {
            this.form.pageSize = 0;
            this.refreshing = false;
            this.onLoad();
        },
        onLoad() {
            if (this.refreshing) return;
            this.loading = true;
            // this.list = [];
            this.form.pageSize += 10;
            this.req_list();
        },

        search() {
            this.form.pageSize = 10;
            this.form.currentPage = 1;
            this.loading = true;
            this.req_list();
        },
        clickTest(){
            this.statusList.forEach((item,index) => {
               if (this.orderType == item.value) {
                   this.placeholder = item.placeholder;
               }
            });
            this.list = null;
            // 组装当前页面url
            let href = location.href.split('?')[0];
            let newUrl = href + '?type=' + this.orderType;
            history.replaceState(null, null, newUrl);
            this.search();
        },

        to_detail(order) {
            const go_detail = (name, query) => {
                this.$router.push({
                    name,
                    query,
                });
            };
            switch (this.orderType) {
                case "0":
                    if (order.orderType === 2 && (order.orderStatus === 2 || order.orderStatus === 7)) {
                        // 抢票的情况
                        go_detail("h5-train-order-detail-ticket", {orderNo: order.orderNo});
                    } else {
                        go_detail("h5-train-order-detail", {orderNo: order.orderNo});
                    }
                    break;
                case "2":
                    if (order.showChangeButton) {
                        this.to_change_payment(order)
                    } else {
                        go_detail("h5-train-change-order-detail", {
                            orderNo: order.changeNo,
                            status: order.orderStatus,
                        });
                    }

                    break;
                case "3":
                    go_detail("h5-train-refund-form-detail", {
                        refundNo: order.refundNo,
                        jumpType: 'list'
                    });
                    break;
            }
        },
        cancel_order_change(changeNo){
            Dialog.confirm({
                title: "确定要取消改签吗?",
                message: "一天仅有3次取消改签的机会（包括自动取消），超过3次后当天将无法使用12306账号预订。",
                cancelButtonText: '稍后再说',
                confirmButtonText: '取消改签',
            })
                .then(() => {
                    cancelChangeOrder({changeNo}).then((res) => {
                        Notify({ type: 'success', message: '取消改签成功' });
                        // 刷新
                        this.req_list();
                    });
                })
                .catch(() => {
                });
        },
        cancel_order(orderNo) {
            Dialog.confirm({
                title: "确认要取消订单吗?",
                message: "一天仅有3次取消订单的机会(包括自动取消)，超过3次后当日将无法使用12306账号预订",
                cancelButtonText: "稍后再说",
                confirmButtonText: "取消订单",
            })
                .then(() => {
                    applyForCancel({orderNo}).then((res) => {
                        Notify({ type: 'success', message: '取消订单成功' });
                        // 刷新
                        this.req_list();
                    });
                })
                .catch(() => {
                });
        },
        to_payment(order) {
            this.to_detail(order);
        },
        to_change_payment(orderNo) {
            this.$router.push({
                name: "h5-train-change-result",
                query: {
                    changeNo: orderNo.changeNo,
                    orderNo: orderNo.orderNo,
                    //orderType: "2",
                    //pageType: 3,
                },
            });
        },

        changes_order(orderNo) {
            this.$router.push({
                name: "h5-train-change",
                query: {
                    orderNo,
                },
            });
        },
        refund_order(orderNo) {
            this.$router.push({
                name: "h5-train-refund",
                query: {
                    orderNo,
                },
            });
        },
    },
    mounted(){

    },
    destroyed(){
        keyback_processor.removeEventListener();
    },
    activated() {
        if (this.$route.query.type) {
            this.orderType = this.$route.query.type + '';
        } else {
            this.orderType = '0';
        }
        this.statusList.forEach(value => {
            if (value.value === this.orderType) {
                this.placeholder = value.placeholder
            }
        });
        this.search();
        let jumpType = this.$route.query.jumpType ? this.$route.query.jumpType : '';
        const __this = this;
        keyback_processor.addEventListener(function () {
          if (jumpType == '') {
            __this.$router.push({name: "h5-personal-center"});
            return
          }
          if (jumpType == 'back') {
            __this.$router.back();
          }


        });
    },
    filters: {
        filter_date(val) {
            return val ? moment(val).format('MM-DD') : val;
        }
    }
};
