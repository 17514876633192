import NavBar from "@/component/nav-bar/import_latest_version_module";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
export default {
    data() {
        return {
            title: '酒店',
            orderNo: '',
            evectionType: 2
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
        const _this = this;
        this.$nextTick(() => {
            fixStatusBar({
                handler({api}) {
                    const bodyWidth = document.body.clientWidth;
                    const headNav = _this.$refs.topElementNav.$el;
                    const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
                    headNav.style.paddingTop = addPaddingTop + 'vw';
                }
            });
        })
    },
    activated() {
        const __this = this;
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback:(result) => {
                    __this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            },200)
        });

        this.orderNo = this.$route.query.orderNo || ''
    },
    deactivated() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        goDetail() {
            if (this.orderNo) {
                this.$router.replace({
                    name: 'h5-hotel-order-detail',
                    query: {
                        orderNo: this.orderNo,
                        evectionType: this.evectionType
                    }
                })
            }
        },
        back() {
            this.$router.replace({
                name: 'h5-hotel-home',
            })
        },
    }
}