/**
 * getLoaction返参
 * {
 *     formattedAddress: '',   //字符串类型；格式化地址
 country: '',            //字符串类型；国家
 province: '',           //字符串类型；省/直辖市
 city: '',               //字符串类型；市
 district: '',           //字符串类型；区
 township: '',           //字符串类型；乡镇(android不支持)，已废弃，建议用aMap模块的getNameFromCoords接口获取
 neighborhood: '',       //字符串类型；社区(android不支持)，已废弃，建议用aMap模块的getNameFromCoords接口获取
 building: '',           //字符串类型；建筑(android不支持)，已废弃，建议用aMap模块的getNameFromCoords接口获取
 citycode: '',           //字符串类型；城市编码
 adcode: '',             //字符串类型；区域编码
 street: '',             //字符串类型；街道名称
 number: '',             //字符串类型；门牌号
 POIName: '',            //字符串类型；兴趣点名称
 AOIName: '',             //字符串类型；所属兴趣点名称
 speed:'',
 bearing:'',
 name: '',  // 地方名，
 lon: '', // 经度
 lat: '', //纬度
 ctiyId: '', //城市code（tmc接口返回）
 cityName: '', // 城市名（tmc接口返回）
 uaType: '', // 用户使用环境。 '000': 浏览器环境, '100': apicloud环境
 }
 * **/
/**
 * 钉钉返回
 *
 * **/
import commonGetLocation from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/get_location/index'
import get_ua_type from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv-app/get_ua_type/index";
import consumer_user_getArea from '@/lib/data-service/haolv-default/consumer_user_getArea'
import * as dd from 'dingtalk-jsapi';
import cache_get from "nllib/src/cache/get";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import consumer_feishu_getSignature from "@/lib/data-service/haolv-default/consumer_feishu_getSignature";
function getUserArea(params) {
    return new Promise((resolve, reject) => {
        consumer_user_getArea(params).then(res => {
            resolve(res.datas)
        }, error => {
            reject()
        })
    })
}

function getDingDingLocation() {
    return new Promise((resolve, reject) => {
        dd.startLocating({
            iOSDistanceFilter: 10,
            targetAccuracy: 200,
            useCache: true,
            sceneId: '1',
            callBackInterval: 1000,
            withReGeocode: true,
            success: (res) => {
                const {
                    address,
                    city,
                    netType,
                    latitude,
                    errorMessage,
                    isWifiEnabled,
                    accuracy,
                    errorCode,
                    locationType,
                    isMobileEnabled,
                    province,
                    provider,
                    road,
                    isGpsEnabled,
                    district,
                    isFromMock,
                    operatorType,
                    longitude,
                    districtAdcode,
                } = res;

                dd.stopLocating({
                    sceneId: '1',
                    success: (sRes) => {
                        const { sceneId } = sRes;
                        resolve(res)
                    },
                    fail: () => {
                        reject();
                    },
                    complete: () => {},
                });
            },
            fail: () => {
                dd.stopLocating({
                    sceneId: '1',
                    success: (sRes) => {
                        const { sceneId } = sRes;
                        reject()
                    },
                    fail: () => {
                        reject();
                    },
                    complete: () => {},
                });
            },
            complete: () => {},
        });
    })
}

function getFeishuLocation() {
    tt.setAPIConfig({
        "apiConfig": {
            // 如果需要使用 getLocation，则进行以下配置，否则不需要配置。
            "getLocation": {
                "version": 2 // 取值 2 代表启用 getLocation 的新版 API。
            },
            // 如果需要使用 startLocationUpdate，则进行以下配置，否则不需要配置。
            "startLocationUpdate": {
                "version": 2 // 取值 2 代表启用 startLocationUpdate 的新版 API。
            },
            // 如果需要使用 stopLocationUpdate，则进行以下配置，否则不需要配置。
            "stopLocationUpdate": {
                "version": 2 // 取值 2 代表启用 stopLocationUpdate 的新版 API。
            },
            // 如果需要使用 chooseLocation，则进行以下配置，否则不需要配置。
            "chooseLocation": {
                "version": 2 // 取值 2 代表启用 chooseLocation 的新版 API。
            }
        }
    })
    return new Promise((resolve, reject) => {
        tt.getLocation({
            "type": "gcj02",
            "timeout": 5,
            "cacheTimeout": 30,
            "accuracy": "best",
            success(res) {
                let result = res;
                tt.reverseGeocode({
                    latitude:  res.latitude,
                    longitude: res.longitude,
                    success(res1) {
                        result = {...result, ...res1}
                        resolve(result);
                    },
                    fail(res1) {
                        reject()
                    }
                });
            },
            fail(res) {
                reject()
            }
        });
    })
}
function getLocation() {
    let promiseArr = []
    return new Promise(async(resolve, reject) => {
        let res = await get_ua_type();
        // type: '' // 用户使用环境。 '000': 浏览器环境, '100': apicloud环境， ‘200’：钉钉环境； ‘201’：飞书环境
        const uaType = res.type
        if (uaType === '000') {
            promiseArr.push(getUserArea());
            let allRes = await Promise.all(promiseArr);
            let result = {
                uaType,
                cityId: allRes.length > 0  && allRes[0]? allRes[0].cityId : '',
                cityName: allRes.length > 0 && allRes[0] ? allRes[0].cityName : '',
            }
            resolve(result)
        }
        if (uaType === '100') {
            promiseArr.push(getUserArea());
            promiseArr.push(commonGetLocation())
            let allRes = await Promise.all(promiseArr);
            let result = {
                uaType,
                cityId: allRes.length > 0  && allRes[0]? allRes[0].cityId : '',
                cityName: allRes.length > 0 && allRes[0] ? allRes[0].cityName : '',
            }
            const commonLocationResult = allRes[1]
            result = Object.assign({}, result, commonLocationResult)
            resolve(result)

            /*Promise.all(promiseArr).then(allRes => {
                let result = {
                    uaType,
                    cityId: allRes.length > 0  && allRes[0]? allRes[0].cityId : '',
                    cityName: allRes.length > 0 && allRes[0] ? allRes[0].cityName : ''
                }
                if (uaType === '100') {
                    const commonLocationResult = allRes[1]
                    result = Object.assign({}, result, commonLocationResult)
                }
                if (uaType === '200') {
                    const dingdingLocationResult = allRes[1]
                    result = Object.assign({}, result, dingdingLocationResult)
                }
                resolve(result)
            })*/
        }
        if (uaType === '200') {
            promiseArr.push(getDingDingLocation());
            let allRes = await Promise.all(promiseArr);
            let result = {
                uaType,
                ...allRes[0]
            }
            let areaRes = await getUserArea({
                platformCityId: result.districtAdcode,
                cityName: result.city,
            })
            result.cityId = areaRes.cityId;
            result.cityName = areaRes.cityName;
            resolve(result)
        }

        if (uaType === '201') {
            let url = location.href;
            console.log(url);
            let appId = cache_get('appID');
            let params = {
                "appId":  appId,
                "url": url,
            };
            let [feishuErr, feishuRes] = await awaitWrap(consumer_feishu_getSignature(params));
            if (feishuRes) {
                window.h5sdk.config({
                    appId: appId,
                    timestamp: feishuRes.data.timestamp,
                    nonceStr: feishuRes.data.noncestr,
                    signature: feishuRes.data.signature,
                    jsApiList: ['scope.userLocation'],
                    //成功回调
                    onSuccess: (res) => {
                    },
                    //失败回调
                    onFail: (err) => {
                    },
                });
            }
            promiseArr.push(getFeishuLocation());
            let allRes = await Promise.all(promiseArr);
            let result = {
                uaType,
                ...allRes[0]
            }
            let areaRes = await getUserArea({
                platformCityId: '',
                cityName: result.city,
            })
            result.cityId = areaRes.cityId;
            result.cityName = areaRes.cityName;
            resolve(result)
        }
    })
}
export default getLocation
