import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
      {
        path: '/h5/certificate',
        name: 'h5-certificate',
        component: () => import(/* webpackChunkName: "page-personal-center-certificate" */ `./4.0.0/index.vue`),
        meta: {
          h5_layout: {
            current_page_name: '我的证件',
            need_alive: true,
            need_head_nav: true,
            need_footer_bar: false,
            secondary_menu: 3,
          }
        },
      }
    ],
  };
