import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_trains_product_getOrderInsuranceInfo
    from '@/lib/data-service/haolv-default/consumer_trains_product_getOrderInsuranceInfo' // 获取保险列表
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_trains_product_checkTrainsOrderPolicy
    from '@/lib/data-service/haolv-default/consumer_trains_product_checkTrainsOrderPolicy' // 获取超标信息
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_trains_common_queryRailwayTimetable from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable' // 火车票时刻表
import EvectionReserveInfoInput from '@/component/evection-reserve-info-input/1.0.1/index.vue'
import consumer_journey_trainTicketcalculatePrice
    from '@/lib/data-service/haolv-default/consumer_journey_trainTicketcalculatePrice' // 计算价格
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_trains_product_getAlternativeTrainNumber
    from '@/lib/data-service/haolv-default/consumer_trains_product_getAlternativeTrainNumber' // 获取备选车次
import consumer_department_getDepartmentUsers
    from '@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers' //  请求员工数据
import consumer_web_externalCustomer_getExternalCustomerAll
    from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerAll' // 请求外部客户列表
import consumer_air_ticket_flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import Transfer from '@/page/travel-book/flight/component/transfer/index.vue'
import CheckTravel from '@/page/travel-book/flight/component/checkTravel/index.vue'
import CostAttributionList from '@/page/travel-book/flight/component/costAttributionList/index.vue'
import ChangClause from '@/page/travel-book/flight/component/changClause/1.0.0/index.vue'
import ReservePopupBox from '@/page/travel-book/flight/component/reservePopupBox/1.1.5/index.vue'
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import moment from "moment";
import {Toast, Dialog, Notify,Skeleton} from 'vant'
import SelectUser from '@/component/select-user/index.vue'
import consumer_frequent_contacts_getList from '@/lib/data-service/haolv-default/consumer_frequent_contacts_getList' // 获取常用联系人
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import TravelerMultipleSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-multiple-selector/1.2.7/index.vue'//选择联系人组件
import consumer_department_getStaffsAndCertificateByUserIds from '@/lib/data-service/haolv-default/consumer_department_getStaffsAndCertificateByUserIds'//通过userIdList获取用户信息
import AccountProcessStep0101
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process-step-0101/1.0.0/index.vue'//12306核验弹窗一
import AccountProcessStep0102
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process-step-0102/1.0.1/index.vue'//12306核验弹窗二z
import consumer_air_ticket_flightLuggage from '@/lib/common-service/consumer_air_ticket_flightLuggage'
import TrainCreateOrder from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-create-order/1.1.3/index.vue'//创建订单组件
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
// 获取是否需要审批
import consumer_journey_orderFreeApprove from '@/lib/data-service/haolv-default/consumer_journey_orderFreeApprove'
// 获取出差规则
import consumer_t_tp_refund_change_policy_get from '@/lib/data-service/haolv-default/consumert_tp_refund_change_policyget'
import consumer_payment_payReminder from '@/lib/data-service/haolv-default/consumer_payment_payReminder'
//动态表单
import DynamicForm from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/dynamic-form/1.0.0/index.vue'
//保险组件
import InsureChoice from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/insure-choice/1.0.2/index.vue'
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import TravelChoice from '../../component/travelChoice/import_latest_version_module'
import ReserveProcessBox from '@/component/reserve-process-box/1.0.0/index.vue'
// 时刻表
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
import TrainTravelerSelector from '@/component/train-traveler-selector/1.0.0/index.vue'
import TrainInstructions from '../commponent/train-instructions/index'
// 我的出差
import web_policyConfig_userTravelPolicy from "@/lib/data-service/haolv-default/web_policyConfig_userTravelPolicy";
import util from '@/lib/util/index';
export default {
    data() {
        return {
            aInsureChoiceData: {},
            trainInvoiceType: 0,
            // 动态表单容器
            DynamicFormList: [],
            paymentPage: 0, // 是否弹窗 0.不弹 1.弹
            hasNodeList: false,
            getAllPriceTime: null,
            // 是否需要审批
            orderFreeApprove: false,
            // 乘车人loading
            productLoading: true,
            // 乘车人setInterval
            setIntervalBox: null,
            // 说明弹框
            explainShow: false,
            title: '填写订单',
            trainOrderNo: null,
            //选座信息
            choiceSeat: {
                canChooseSeat: null,
                numberOfSeats: null,
                quantity: 0,
            },
            redirectType: '', // 跳转类型
            evectionNo: '', //
            userInfo: {}, // 个人信息
            evectionInfo: {}, // 出差信息合集
            evectionParams: {
                evectionNo: '',
                evectionType: 2,
                explainDesc: '',
                feeAffiliationId: '',
                feeAffiliationName: '', // 费用归属名称
                feeAffiliationType: 1,
                linkman: '',
                linkmanPhone: '',
                reason: '',
                travelUserList: [],
                smsType: 1, // 是否发送短信， 1为是， 0为否
                linkmanSms: 1, // 是否发送短信， 1为是， 0为否
                partnerEctionId: '',
                centerCode: '',
                projectCode: '',
                projectName: '',
                companyId: 0,
            },

            insuranceProId: '', // 选中的保险
            insuranceList: [], // 保险列表
            insuranceDesc: '', // 保险解释

            trainList: [], // 火车票行程列表
            hotelList: [], // 酒店行程列表

            travelUserList: [], // 出行人合集
            travelUserIdList: [], // 出行人id集合
            chooseTravelUserList: [], // 备选出行人集合

            trainNoSeatCheck: [], // 是否勾选无座的CheckBox里诶啊哦
            numberOfSeats: '', // 当前选座的列车座位数量
            currentTrainBusinessSeatArr: [], // 当前选座的列表的座位列表
            currentChooseSeatArr: [], // 当前选座的列车选中的座位
            currentTrainIndex: '', // 当前选座的列车序号

            businessSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {
                    label: 'F',
                    value: '1F',
                    active: false
                }],
                [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {
                    label: 'F',
                    value: '2F',
                    active: false
                }]
            ], // 特等座选位
            firstSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {
                    label: 'D',
                    value: '1D',
                    active: false
                }, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {
                    label: 'D',
                    value: '2D',
                    active: false
                }, {label: 'F', value: '2F', active: false}]
            ], // 一等座选位
            secondSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'B', value: '1B', active: false}, {
                    label: 'C',
                    value: '1C',
                    active: false
                }, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'B', value: '2B', active: false}, {
                    label: 'C',
                    value: '2C',
                    active: false
                }, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
            ], // 二等座选位

            trainPopup: false, // 备选车次弹窗
            trainPopupIndex: '', // 当前备选车次的火车序号
            currentChooseTrainList: [], // 当前备选弹窗选中的车次
            currentTrainList: [], // 当前备选车次列表

            trainSeatPopup: false, // 备选坐席弹窗
            currentChooseSeatList: [], // 当前备选弹窗选中的坐席
            currentTrainSeatList: [], // 当前备选坐席列表
            trainSeatPopupIndex: '', // 当前备选坐席的火车序号


            priceResult: {
                flightCalculates: []
            },

            seatPopup: false,

            haveTrainEvection: false, // 火车票是否允许继续下单

            popupShow: false,
            allUserItems: [], // 全部员工列表
            activeUserIds: [], // 选中的员工id

            canChooseUserList: [], // 出差单情况下选中的出行人

            userDelPopupShow: false, // 设置出行人弹窗
            feeAffiliationShow: false, // 费用归属弹窗

            ruleText: {}, // 退改规则
            luggage: '', // 行李信息
            luggageParams: {}, // 行李对象
            ruleShow: false, //退改规则弹窗

            ruleLoading: false,
            luggageLoading: false,

            newTravelParams: {
                travelCurrent: '',
                hotelDateArr: null,
                hotelKeyword: '',
                hotelCheckInDate: '',
                hotelCheckOutDate: '',
                businessType: 1
            },
            travelCurrent: null,

            dialogNewTrainOrderVisible: false, // 添加火车票航程弹窗

            travelPopupShow: false, // 出差单选择弹窗
            currentBusinessTravelList: [], // 当前的出差预定信息
            onlySearch: false, // 是否只搜索高铁 1=是，0=否
            showCalendar: false, //

            canSubmit: true, // 防连点

            productPricePopup: false, // 订单价格详情弹窗
            productPricePopupSet: false,
            successPopup: false, // 订单成功后弹窗
            resultMsg: '', // 消息
            isHaveOverproof: false,

            hotelRules: {},
            hotelUserIndex: '',

            selectUserPopup: false, // 选择出行人组件
            selectContactUserPopup: false, // 选择联系人组件

            contactUserList: [],
            activeContactUserList: [],

            footerSubmitPaddingBottom: 0,

            orderBookingPolling: '',

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 3,
            returnSetInterval: '',
            workTravelType: 1, // 因公出差的类型， 1是有选出差单，2是没有
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            isFreeApprove: 0,//是否免审--0非免审--1免审
            trainsControlRules: 0,//火车管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定
            boxTit: false,
            users: {
                name: '123',
                age: '123',
                school: '123',
            },
            showCheckNumber: false,
            //价格集合
            priceList: {
                trainNo: null, //火车信息
                seatName: '', //火车座位信息
                headCounts: null, //订单总人数
                seatPrice: null, //火车票费用
                robTicketsFee: null, //抢票费单价
                robTicketsAmount: null, //抢票费总价
                serviceChargePrice: null, //服务费单价
                serviceChargeAmount: null, //服务费总价
                showServiceFee: null, //是否展示服务费
                trainOrderAmount: null, //合计
            },
            showCheckDown: false,
            resultNumber: [],
            resultDown: [],
            tableDataOff: false,
            tableData: [
                {
                    userName: '',
                    standard: '',
                    standardContent: '',
                }
            ],
            packageCodes: [],
            trainDataTop: {
                //开始日期
                startDate: null,
                //星期几
                whatDay: null,
                //车次
                trainNo: null,
                //出发站
                fromStationName: null,
                //到达站
                toStationName: null,
                //发车时间
                startTime: null,
                //到达时间
                arriveTime: null,
                //跨越时间
                spanTime: null,
                //座位类型
                seatName: '',
                //坐席单价
                seatPrice: null,
            }, //火车行程
            // 已选坐席
            choiceSeatBox: [],
            // 联系人
            contact:[],
            form: {
                userList: [],//出行人
                userIdList: [],//出行人userIdList
                linkman: '',//联系人
                linkmanPhone: '',//联系人电话
                linkmanUnSensitivePhone: '', // 联系人电话脱敏
                smsType: 1,//发送行程信息到出行人手机。不发送：0；发送：1
                linkmanSms: 1//发送行程信息到联系人手机。不发送：0；发送：1
            },
            isSmsType: true,//发送行程信息到出行人手机。开关
            isLinkmanSms: true//发送行程信息到联系人手机。开关
        }
    },
    components: {
        Transfer,
        CostAttributionList,
        ChangClause,
        ReservePopupBox,
        CheckTravel,
        SelectUser,
        TravelerMultipleSelector,
        AccountProcessStep0101,
        AccountProcessStep0102,
        TrainCreateOrder,
        InsureChoice,
        OrangeNavBar,
        NavBar,
        TravelChoice,
        EvectionReserveInfoInput,
        ReserveProcessBox,
        TrainTimeTable,
        TrainTravelerSelector,
        DynamicForm,
        TrainInstructions
    },
    created() {
    },
    async mounted() {
        this.getPayRemider();
        this.$showLoading.show();
        const _this = this;
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                // document.querySelector('#navBar').style.paddingTop = `${api.safeArea.top}px`;
                // _this.$refs['navBar'].$el.style.paddingTop = `${api.safeArea.top}px`;
                // _this.$refs['footerSubmit'].style.paddingBottom = `${api.safeArea.bottom}px`;
                _this.footerSubmitPaddingBottom = api.safeArea.bottom
            }
        });
        this.$refs.aInsureChoice.init({
            mock_data: {
                //是否使用随机数据--true/false
                enable: false,
                //使用随机数据--模拟请求时间
                wait_seconds: 2000,
            },
            //业务类型--1：机票/2：火车票
            businessType: 2,
            //订单号
            evectionType: 2,
            orderNo: this.$route.query.trainOrderNo,
        });
        let res = await get_user_info();
        this.userInfo = res.datas;
        this.$showLoading.hide();
    },
    async activated() {
        // 初始化莫名转化为true
        this.dialogNewTrainOrderVisible = false;
        this.productLoading = true;
        this.redirectType = this.$route.query.redirectType;
        this.evectionNo = this.$route.query.evectionNo;
        this.evectionType = this.$route.query.evectionType;
        this.trainOrderNo = this.$route.query.trainOrderNo;
        this.workTravelType = this.$route.query.workTravelType; // // 因公出差的类型， 1是有选出差单，2是没有

        // 清空乘车人\座席
        this.form.userList = [];
        this.form.userIdList = [];
        this.choiceSeatBox = [];
        this.choiceSeat = {
            canChooseSeat: null,
            numberOfSeats: null,
            quantity: 0,
        };

        consumer_t_tp_refund_change_policy_get().then(res => {
            this.trainInvoiceType = res.datas.trainInvoiceType ? res.datas.trainInvoiceType : 0;
        });

        await this.refundChangePolicyGet();
        //await this.getUserInfo(); // 获取个人资料
        // await this.getInsuranceInfo(); // 获取保险信息列表
        await this.getEvectionInfo();
        await this.getTrainsOrderPolicy();
        await this.getOrderFreeApprove();

        this.getTravelInfoAndUserList(); // 获取当前出差单信息和可选择的出行人列表
        this.getAllPrice();

        this.getDepartmentUserList();

        this.getCurUser();

        if (this.evectionType === 1) {
            this.getUsuallyUser()// 获取常用旅客
        }

        const trainTravelerSelectorParams = {
            evectionNo: this.evectionNo,
        };
        this.$refs['trainTravelerSelector'].init(trainTravelerSelectorParams);

        let params = Object.assign({}, this.evectionParams);
        let userIdList = [];
        this.evectionParams.travelUserList.forEach(value => {
            userIdList.push(value.id)
        });

        // 初始化联系人组件
        this.$refs.aTravelerMultipleSelector.init({
            title: '选择联系人',
            situation: '101',
            businessType: 3,
            select_mode: "single"
        });

        let evectionDetailType = '';
        if (this.evectionType == 1) {
            // 因私
            evectionDetailType = '101'
        } else {
            // 因公
            if (this.workTravelType == 1) {
                // 有出差单
                evectionDetailType = '001'
            } else {
                // 没出差单
                evectionDetailType = '002'
            }
        }
        this.$refs['evectionReserveInfoInput'].init({
            form: this.evectionParams,
            businessType: 2,
            businessRuleControl: this.businessRuleControl,
            evectionDetailType: evectionDetailType,
            oaNo: this.evectionInfo.partnerEctionId,
            startDate: this.evectionInfo.ticketBookingPageDetailsResponseList[0].arriveDate,
            userId: userIdList,
        });
        if (this.businessRuleControl === 2 && this.orderFreeApprove) {
            // 流程
            this.$refs['reserveProcessBox'].init().then(res => {
              this.hasNodeList = res
            })
        }

        let __this = this;
        keyback_processor.addEventListener(function () {

            //差旅信息--成本归属弹框
            try {
                const evectionReserveInfoInput = __this.$refs.evectionReserveInfoInput;
                const show_evectionReserveInfoInput = evectionReserveInfoInput.getShow();
                if (show_evectionReserveInfoInput) {
                    evectionReserveInfoInput.hide();
                    return;
                }
            } catch (err) {

            }

            //时刻表
            try {
                const aTrainTimeTable = __this.$refs.aTrainTimeTable;
                let show_aTrainTimeTable = aTrainTimeTable.get_show();
                if (show_aTrainTimeTable) {
                    __this.$refs.aTrainTimeTable.hide();
                    return;
                }
            } catch (err) {

            }

            //说明弹框
            try {
                let show_explainShow = __this.explainShow;
                if (show_explainShow) {
                    __this.hideExplain();
                    return;
                }
            } catch (err) {

            }

            //选择联系人
            try {
                const aTravelerMultipleSelector = __this.$refs.aTravelerMultipleSelector;
                let show_aTravelerMultipleSelector = aTravelerMultipleSelector.get_show();
                if (show_aTravelerMultipleSelector) {
                    __this.$refs.aTravelerMultipleSelector.hide();
                    return;
                }
            } catch (err) {

            }

            //选择乘车人
            try {
                const trainTravelerSelector = __this.$refs.trainTravelerSelector;
                let show_trainTravelerSelector = trainTravelerSelector.get_show();
                if (show_trainTravelerSelector) {
                    __this.$refs.trainTravelerSelector.hide();
                    return;
                }
            } catch (err) {

            }

            //订单明细弹框
            if (__this.productPricePopup) {
                __this.productPricePopup = false;
                return;
            }

            //12306核验弹框
            // const aTrainCreateOrder = __this.$refs.aTrainCreateOrder;
            // const show_aTrainCreateOrder = aTrainCreateOrder.verificationDialogVisible;
            // if (show_aTrainCreateOrder) {
            //     aTrainCreateOrder.closeVerificationDialog();
            //     return;
            // }

            __this.back();

        });
    },
    deactivated() {
        clearInterval(this.setIntervalBox);
        this.orderBookingPolling = '';
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {
        travelCurrent(newVal) {
            if (newVal) {
                let active = newVal.active;
                this.newTravelParams.travelCurrent = `${active.departCityName}--${active.toCityName}`
            }
        },
        dialogNewTrainOrderVisible(newVal) {
            console.log('dialogNewTrainOrderVisible', newVal)
        },
        //从员工中选择联系人时，
        contact(newVal) {
            this.contactObj = this.$refs.aTravelerMultipleSelector.get_selected_entity();
            console.log('chufafffffffff', this.contactObj);
            this.form.linkman = this.contactObj.name;
            this.form.linkmanPhone = this.contactObj.phone;
            this.form.linkmanUnSensitivePhone = this.contactObj.unSensitivePhone
        }
    },
    computed: {},
    filters: {
        filterDate(val) {
            if (val) {
                return moment(val).format('YYYY年MM月')
            } else {
                return ''
            }
        },
        filterDay(val) {
            if (val) {
                return moment(val).format('MM月DD日')
            } else {
                return ''
            }
        },
        filterIdType(val) {
            // 证件类型 (1:身份证 2:护照 4:港澳通行证 5:台胞证 6:台湾通行证 7:回乡证 8:军官证 9:其他 10:学生证)
            if (val === 1) {
                return '身份证'
            } else if (val === 2) {
                return '护照'
            } else if (val === 3) {
                return ''
            } else if (val === 4) {
                return '港澳通行证'
            } else if (val === 5) {
                return '台胞证'
            } else if (val === 6) {
                return '台湾通行证'
            } else if (val === 7) {
                return '回乡证'
            } else if (val === 8) {
                return '军官证'
            } else if (val === 9) {
                return '其他'
            } else if (val === 10) {
                return '学生证'
            } else {
                return ''
            }
        }
    },
    methods: {
        getOrderFreeApprove(){
          return consumer_journey_orderFreeApprove({businessType: 2, orderNo: this.trainOrderNo}).then(res => {
            this.orderFreeApprove = res.datas.orderFreeApprove;
          });
        },
        getPassengerList(data){
          this.setIntervalBox = null;
          let userIdBox = [];
          new Promise((resolve, reject) => {
            this.setIntervalBox = setInterval(() => {
              if (this.evectionInfo.travellerList) {
                resolve(this.evectionInfo.travellerList);
              }
            },100);
          }).then(res => {
            res.forEach((item,index) => {
              userIdBox.push(item.userId);
            });
            let userList = [];
            data.forEach((item,index) => {
              if (userIdBox.indexOf(item.userId) != -1 && item.optional) {
                  // 不能直接push到this.form.userList，因为this.form.userList本身已经存在那几个乘客，push就会重复了
                userList.push(item);
                if (this.form.userIdList.indexOf(item.userId) == -1) {
                  this.form.userIdList.push(item.userId);
                }
              }
            });
              this.form.userList = userList;
              this.choiceSeat = {
                  canChooseSeat: this.choiceSeat.canChooseSeat,
                  numberOfSeats: this.choiceSeat.numberOfSeats,
                  quantity: this.form.userList.length,
              };
              this.getAllPrice(); // 获取价格信息
            this.productLoading = false;
          });
        },
        // 获取当前登陆用户信息
        async getCurUser() {
            let res = await get_user_info();
            this.form.linkman = res.datas.realName;
            this.form.linkmanPhone = res.datas.phone;
            this.form.linkmanUnSensitivePhone = util.phoneHide(res.datas.phone);
        },
        hideExplain(){
            this.explainShow = false;
        },
        // 说明click
        clickExplain(){
            this.explainShow = true;
            // this.$router.push({
            //     name: 'h5-train-instructions',
            //     query: {
            //         active: 1
            //     }
            // });
        },
        //发送行程信息到出行人手机--开关单击事件
        clickIsSmsType(){
            this.form.smsType = this.isSmsType ? 1 : 0;
            this.evectionParams.smsType = this.form.smsType;
        },
        //发送行程信息到联系人手机--开关单击事件
        clickIsLinkmanSms(){
            this.form.linkmanSms = this.isLinkmanSms ? 1 : 0;
            this.evectionParams.linkmanSms = this.form.linkmanSms;
        },
        // 校验手机
        validatePhone(rule, value, callback) {
            if (value.indexOf('*') > -1) {
                // 如果有星号，就当是脱敏的来处理
                callback();
                return
            }
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/;
            if (regExp.test(value) && value.toString().length === 11) {
                callback()
            } else {
                callback(new Error('请填写正确的手机号码'))
            }
        },
        //打开选择联系人popup
        openContactPopup() {
            this.$refs.aTravelerMultipleSelector.show();
        },
        //删除乘车人
        travelersDel(item,index){
            let boxIndex = null;
            this.form.userList.forEach((UItem,UIndex) => {
                if (item.cardId == UItem.cardId) {
                    //删除对应的用户数据
                    this.form.userList.splice(UIndex,1);
                    boxIndex = item.userId;
                    this.choiceSeatBox = [];
                    this.choiceSeat = {
                        canChooseSeat: this.choiceSeat.canChooseSeat,
                        numberOfSeats: this.choiceSeat.numberOfSeats,
                        quantity: this.form.userList.length,
                    };
                    // 获取价格信息
                    this.getAllPrice();
                }
            });
            this.form.userIdList.forEach((item,index) => {
              //删除对应的用户ID
              if (item == boxIndex) {
                this.form.userIdList.splice(index,1);
              }
            });
        },
        //选择乘车人触发事件
        _result(val) {
            console.log('val', val);
            //获取选中的用户的数据和ID
            this.form.userIdList = val.userIdList;
            this.form.userList = val.userList;
            this.choiceSeat = {
                canChooseSeat: this.choiceSeat.canChooseSeat,
                numberOfSeats: this.choiceSeat.numberOfSeats,
                quantity: val.userList.length,
            };
            // 获取价格信息
            this.getAllPrice();
        },
        //选择乘车人展示
        clickTravelers(){
            //就用户ID回传
            this.$refs['trainTravelerSelector'].toShow({
                activePassengerList: JSON.parse(JSON.stringify(this.form.userIdList))
            })
        },
        clickSiteNum(){
            this.$refs['aTrainTimeTable'].show();
        },
        // 今天、明天
        DateDiff(date){
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(date).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == todayEnd) {
                text = '今天';
            }

            if (tomorrow == todayEnd) {
                text = '明天';
            }

            if (afterTomorrow == todayEnd) {
                text = '后天';
            }

            return text

        },
        timeTexSplicing(date){
            if (date != null) {
                // 月日
                let monthDay = moment(date).format('YYYY-MM-DD');
                // 星期
                let weekText = this.filterWeek(date);
                // 今天、明天、后天
                let timeName = this.DateDiff(date);

                return `${monthDay} ${weekText} ${timeName}`
            }
        },
        refundChangePolicyGet() {
            refund_change_policy_get().then((res) => {
                this.businessRuleControl = res.datas.businessRuleControl;
                this.isFreeApprove = res.datas.isFreeApprove;
            });
            web_policyConfig_userTravelPolicy().then((res) => {
                this.trainsControlRules = res.datas.trainsControlRules;
            });
        },
        InsureChoiceChange(data) {
            this.packageCodes = data.id_list;
            this.getAllPrice();
        },
        getTravelerInfo(param) {
            this.evectionParams.travelUserList = param.userList;
            this.getAllPrice();
        },
        async getTrainsOrderPolicy() {

            let res = await consumer_trains_product_checkTrainsOrderPolicy({
                orderNo: this.$route.query.trainOrderNo
            });

            if (res.datas.length == 0) {
                this.tableDataOff = false;
            } else {
                this.tableDataOff = true;
                this.tableData = res.datas;
            }
        },
        clickTit() {
            this.boxTit = !this.boxTit;
        },
        filterTime(val) {
            if (val == null) return;
            let text = val.replace(/时/, "h").replace(/分/, 'm');

            return text
        },
        //12306登录操作
        clickSign() {
            this.$router.replace({
                name: "train-sign",
                query: {
                    evectionNo: this.$route.query.evectionNo,
                    redirectType: this.$route.query.redirectType,
                }
            });
        },
        back() {
            Dialog.confirm({
                title: '温馨提示',
                message: '您的订单尚未填写完成，确认离开当前页面？',
                cancelButtonText: '离开',
                confirmButtonText: '继续预订',
                confirmButtonColor: '#FF7002',
                className: 'reserve-back-warning-dialog',
            })
              .then(() => {
                  // on confirm
              })
              .catch(() => {
                  // on cancel
                  this.$router.go(-1)
              });
        },
        seatSubmit() {
            this.trainList[this.currentTrainIndex].seatArr = JSON.parse(JSON.stringify(this.currentTrainBusinessSeatArr));
            this.trainList[this.currentTrainIndex].chooseSeatArr = JSON.parse(JSON.stringify(this.currentChooseSeatArr));
            if (this.trainList[this.currentTrainIndex].chooseSeatArr.length > 0) {
                this.trainList[this.currentTrainIndex].choose = true
            } else {
                this.trainList[this.currentTrainIndex].choose = false
            }
            this.seatPopup = false
        },
        // 弹出座位选择弹窗
        showSeatPopup(val, index) {
            this.currentTrainIndex = index;
            if (this.trainList[this.currentTrainIndex].chooseSeatArr.length > 0) {
                this.trainList[this.currentTrainIndex].choose = true
            } else {
                this.trainList[this.currentTrainIndex].choose = false
            }
            this.numberOfSeats = val.numberOfSeats;
            this.currentTrainBusinessSeatArr = JSON.parse(JSON.stringify(val.seatArr));
            this.currentChooseSeatArr = JSON.parse(JSON.stringify(val.chooseSeatArr));
            this.seatPopup = true
        },
        // 选择座位
        handleChooseSeat(index, sIndex, val) {
            if (val.active) { // 取消
                this.currentTrainBusinessSeatArr[index][sIndex].active = false;
                let seatIndex = this.currentChooseSeatArr.indexOf(val.value);
                this.currentChooseSeatArr.splice(seatIndex, 1)
                // this.currentTrainBusinessSeat = this.currentChooseSeatArr.join(',')
            } else { // 选中
                if (this.evectionParams.travelUserList.length === 0) {
                    Toast('请先添加出行人')
                }
                if (this.currentChooseSeatArr.length >= this.evectionParams.travelUserList.length) {
                    return
                }
                this.currentTrainBusinessSeatArr[index][sIndex].active = true;
                this.currentChooseSeatArr.push(val.value)
                // this.currentTrainBusinessSeat = this.currentChooseSeatArr.join(',')
            }
        },
        // 打开抢票车次列表
        openTrainPopup(val, index) {
            this.currentTrainList = JSON.parse(JSON.stringify(val.alternativeTrainNumberList));
            this.currentChooseTrainList = JSON.parse(JSON.stringify(val.acceptTrainCodesArr));
            this.trainPopup = true;
            this.trainPopupIndex = index;
        },
        // 选择备选车次
        trainToggle(val, index) {
            if (this.currentChooseTrainList.length >= 4 && this.currentChooseTrainList.indexOf(val.trainCode) === -1) {
                Toast('最多可以选择4个备选车次');
                return
            }
            this.$refs.trainCheckboxes[index].toggle()
        },
        //
        changeTrainCode(checked) {
            if (checked.length > 4) {
                let lastCheck = checked[checked.length - 1];
                for (let i = 0, l = this.currentTrainList.length; i < l; i++) {
                    if (lastCheck === this.currentTrainList[i].trainCode) {
                        this.$refs.trainCheckboxes[i].toggle();
                        break
                    }
                }
            }
        },
        // 关闭备选车次弹窗
        closeTrainPopup() {
            this.trainPopup = false
        },
        // 确定选择备选车次
        submitTrainPopup() {
            this.trainList[this.trainPopupIndex].acceptTrainCodesArr = JSON.parse(JSON.stringify(this.currentChooseTrainList));
            this.trainList[this.trainPopupIndex].acceptTrainCodes = this.currentChooseTrainList.join(',');
            this.trainPopup = false;
            this.filterSeatList(this.trainPopupIndex)
        },
        // 关闭备选坐席弹窗
        closeTrainSeatPopup() {
            this.trainSeatPopup = false
        },
        // 确定选择备选坐席
        submitTrainSeatPopup() {
            this.trainList[this.trainSeatPopupIndex].seatList = JSON.parse(JSON.stringify(this.currentTrainSeatList));
            this.trainList[this.trainSeatPopupIndex].acceptSeatCodesArr = JSON.parse(JSON.stringify(this.currentChooseSeatList));
            this.trainSeatPopup = false;
            this.chooseSeat(this.trainSeatPopupIndex, this.trainList[this.trainSeatPopupIndex].acceptSeatCodesArr)
        },
        // 选择备选坐席
        trainSeatToggle(index) {
            this.$refs.seatCheckboxes[index].toggle()
        },
        // 打开备选坐席的弹窗
        openTrainSeatPopup(val, index) {
            this.currentTrainSeatList = JSON.parse(JSON.stringify(val.seatList));
            this.currentChooseSeatList = JSON.parse(JSON.stringify(val.acceptSeatCodesArr));
            this.trainSeatPopup = true;
            this.trainSeatPopupIndex = index
        },
        checkResult(val) {
            if (val.datas.code == '000000') {
                this.createOrder();
            } else {
                Toast.fail('验证码错误');
            }
        },
        loginResult(val) {
            if (val.accountStatus == 1) {
                this.createOrder();
            } else if (val.accountStatus == 2) {
                this.$refs.aAccountProcessStep0102.show()
            } else if (val.accountStatus == 3) {
                Toast.fail(val.accountStatusText);
                this.$refs.aAccountProcessStep0101.show()
            }
        },
        //选择坐席触发方法
        TravelChoiceChange(data){
            this.choiceSeatBox = data;
        },
        //创建订单new2023-06-13
        async toCreateOrder() {
            // 使用getInfo()获取验证结果和表单信息，返回是Promise
            //     res.valid--表单验证结果(true--全部通过)
            //     res.evectionParams--固定表单信息(对象)
            //     res.list--动态表单信息(数组)
            this.$refs.evectionReserveInfoInput.getInfo().then(res => {
                if (!res.valid) {
                    const listLength = res.list.length;
                    for (let i = 0; i < listLength; i++) {
                        if (!res.list[i].parameterForm) {
                            const text = `请填写${res.list[i].parameterText}`;
                            Toast(text);
                            break
                        }
                    }
                    return
                } else {
                    this.evectionParams.reason = res.evectionParams.reason;
                    this.evectionParams.feeAffiliationName = res.evectionParams.feeAffiliationName;
                    this.evectionParams.feeAffiliationType = res.evectionParams.feeAffiliationType;
                    this.evectionParams.partnerEctionId = res.evectionParams.partnerEctionId;
                    this.evectionParams.projectCode = res.evectionParams.projectCode;
                    this.evectionParams.projectName = res.evectionParams.projectName;
                }

                if (this.paymentPage === 1) {
                    // 弹窗
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '因公出行订单费用将由企业账户支付',
                        confirmButtonText: '继续预订',
                        cancelButtonText: '取消预订',
                    }).then(() => {
                        // on confirm
                        this.onSubmitTrainCreateOrder(res.list);
                    }).catch(() => {
                        // on cancel
                    });
                } else {
                    // 不弹
                    this.onSubmitTrainCreateOrder(res.list);
                }
            });
        },
        //创建订单new2022-02-14
        async onSubmitTrainCreateOrder(aDynamicFormList) {
            if (this.businessRuleControl === 2 && this.orderFreeApprove && !this.hasNodeList && this.$store.state.workflow === '2') {
                Toast('当前没有审批流，请联系管理员');
                return
            }
            if (this.form.userList.length == 0) {
                Toast({
                    message: '请先添加乘车人',
                    position: 'bottom',
                });
                return;
            }
            if (this.form.userList.length > 5) {
                Toast({
                    message: '乘车人最多可以添加5人',
                    position: 'bottom',
                });
                return;
            }
            if (this.form.linkman == '') {
                Toast({
                    message: '请先填写联系人',
                    position: 'bottom',
                });
                return;
            }
            if (this.form.linkmanUnSensitivePhone == '') {
                Toast({
                    message: '请先填写联系人电话',
                    position: 'bottom',
                });
                return;
            }

            await this.saveInfo();
            let productCodes = this.$refs.aInsureChoice.get_date();
            let _this = this;
            let passengers = [];
            this.form.userList.forEach((item,index) => {
                passengers.push({userId: item.userId});
            });

            // passengers
            const params = {
                mock_data: {
                    enable: false,
                    mode: '320141'
                },
                evectionType: Number(this.evectionType),           //出差类型
                workTravelType: Number(this.workTravelType),      //因公出差的类型
                evectionNo: this.$route.query.evectionNo,//出差单号
                orderNo: this.$route.query.trainOrderNo,//订单号
                trainInfo: {
                    fromStationName: this.trainDataTop.fromStationName,
                    toStationName: this.trainDataTop.toStationName,
                    trainNo: this.trainDataTop.trainNo,
                    startDate: this.trainDataTop.startDate,
                    spanTime: this.trainDataTop.startTime,
                },
                userList: this.form.userList,       //出行人列表
                passengers,
                businessRuleControl: this.businessRuleControl,
                reportConfiguration: aDynamicFormList,
                isHaveOverproof: this.tableDataOff,
                isFreeApprove: this.isFreeApprove,
                trainsControlRules: this.trainsControlRules,
                productCodes: productCodes.id_list,
            };

            let jsonData = JSON.stringify(params);
            this.$router.push({
                name: 'train-reserve-loading',
                query: {
                    useTravel: 1,
                    data: jsonData,
                }
            });

            // this.$refs.aTrainCreateOrder.run(params)
        },
        orderResult(result) {
            this.$router.push({
                name: 'h5-payment-center',
                query: {
                    evectionNo: this.evectionNo
                }
            })
        },
        // 检查表单
        checkForm() {
            return new Promise(resolve => {
                this.$refs['reviewForm'].validate().then(() => {
                    resolve(true)
                }).catch(() => {
                    resolve(false)
                })
            })
        },
        inputFontLong(val) {
            if (val && val.length < 3) {
                return ''
            } else {
                return 'small'
            }
        },
        submitEnd() {
            this.successPopup = false;
            this.$router.push({
                name: 'h5-evection-order-detail',
                query: {
                    pageType: 2,
                    evectionNo: this.evectionNo
                }
            })
        },

        to_payment() {
            this.$router.push({name: 'h5-travel-payment'});
        },
        // 获取个人信息
        async getUserInfo() {
            let res = await get_user_info();
            this.userInfo = res.datas
        },
        // 获取保险信息
        async getInsuranceInfo() {
            let [err, res] = await awaitWrap(consumer_trains_product_getOrderInsuranceInfo());
            if (err) {
                return
            }
            let insuranceList = res.data;
            insuranceList.forEach((value, index) => {
                value.value = value.id;
                value.text = `${value.insurancePrice}元/份 ${value.insuranceRemark}`
                /*if (index === 0) {
                    this.insuranceProId = value.id
                    this.insuranceDesc = value.insuranceDesc
                }*/
            });
            insuranceList.unshift({
                text: '无',
                value: '',
                id: ''
            });
            this.insuranceList = insuranceList
        },
        //处理时间信息，返回周几
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        // 获取出差信息
        getEvectionInfo() {
            return consumer_journey_getEvectionDetail({evectionNo: this.evectionNo, orderNo: this.$route.query.trainOrderNo, pageType: 1, personType: 2}).then(res =>{
              let data = res.datas;
              this.evectionInfo = res.datas;
              this.trainDataTop = this.evectionInfo.ticketBookingPageDetailsResponseList[0];
              let travelUserIdList = [];
              data.travellerList.forEach(value => {
                value.id = value.userId;
                value.text = value.staffName;
                value.certificateType = value.certificateList.length > 0 ? value.certificateList[0].certificateType : '';
                value.certificateValue = value.certificateList.length > 0 ? value.certificateList[0].certificateValue : '';

                travelUserIdList.push(value.userId)
              });
              this.travelUserIdList = travelUserIdList;
              this.chooseTravelUserList = JSON.parse(JSON.stringify(data.travellerList));
              this.evectionParams = {
                evectionNo: data.evectionNo,
                evectionType: data.evectionType,
                explainDesc: data.explainDesc,
                feeAffiliationId: data.feeAffiliationId,
                feeAffiliationName: data.feeAffiliationName, // 费用归属名称
                feeAffiliationType: data.feeAffiliationType ? data.feeAffiliationType : 1,
                linkman: data.initialName ? data.initialName : this.userInfo.realName,
                linkmanPhone: data.linkmanPhone ? data.linkmanPhone : this.userInfo.phone,
                reason: data.reason,
                travelUserList: data.travellerList ? data.travellerList : [],
                partnerEctionId: res.datas.partnerEctionId,
                smsType: res.datas.contactsList && res.datas.contactsList.length > 0 ? res.datas.contactsList[0].smsType : 1, // 是否发送短信， 1为是， 0为否
                linkmanSms: this.isLinkmanSms, // 是否发送短信， 1为是， 0为否
                travelUser: [],
                centerCode: '', // 成本中心独有字段
                projectCode: '',
                projectName: '',
                companyId: 0,
              };
              this.choiceSeat = {
                canChooseSeat: data.ticketBookingPageDetailsResponseList[0] ? data.ticketBookingPageDetailsResponseList[0].canChooseSeat : '',
                numberOfSeats: data.ticketBookingPageDetailsResponseList[0] ? data.ticketBookingPageDetailsResponseList[0].numberOfSeats : '',
                quantity: 0,
              };
              this.travelUserList = res.datas.travellerList ? res.datas.travellerList : [];
              this.form.userIdList = [];
              this.travelUserList.forEach((item,index) => {
                this.form.userIdList.push(item.userId);
              });
              // 获取火车票行程
              let trainList = res.datas.ticketBookingPageDetailsResponseList;
              let getAlternativeTrainList = []; // 需要获取
              trainList.forEach(value => {
                let seatArr = [];
                if (value.canChooseSeat === 1 && value.numberOfSeats === 5) {
                  [...seatArr] = this.secondSeatArr
                } else if (value.canChooseSeat === 1 && value.numberOfSeats === 4) {
                  [...seatArr] = this.firstSeatArr
                } else if (value.canChooseSeat === 1 && value.numberOfSeats === 3) {
                  [...seatArr] = this.businessSeatArr
                }
                value.seatArr = JSON.parse(JSON.stringify(seatArr));
                value.chooseSeatArr = [];
                value.choose = false;
                value.canAcceptNoSeat = value.acceptNoSeat === 1 ? true : false;
                this.trainNoSeatCheck.push(value.acceptNoSeat === 1 ? true : false);
                if (value.canChooseSeat === 1 && value.chooseSeat !== '' && value.chooseSeat !== null) {
                  let chooseSeatArr = [];
                  if (value.chooseSeat) {
                    for (let i = 0, l = value.chooseSeat.length; i < l; i += 2) {
                      let arr = value.chooseSeat.slice(i, i + 2);
                      chooseSeatArr.push(arr)
                    }
                  }
                  value.chooseSeatArr = chooseSeatArr;
                  value.seatArr.forEach(val => {
                    val.forEach(val1 => {
                      if (value.chooseSeatArr.indexOf(val1.value) > -1) {
                        val1.active = true
                      }
                    })
                  })
                }
                // 保险信息
                value.insuranceProId = value.insuranceProId ? parseInt(value.insuranceProId) : value.insuranceProId;
                value.insuranceDesc = '';
                if (value.insuranceProId !== null && value.insuranceProId !== '') {
                  for (let i = 0, l = this.insuranceList.length; i < l; i++) {
                    if (value.insuranceProId === this.insuranceList[i].id) {
                      value.insuranceDesc = this.insuranceList[i].insuranceDesc;
                      value.insurancePrice = this.insuranceList[i].insurancePrice; // 单价
                      value.insuranceName = this.insuranceList[i].insuranceClass.name // 名字
                    }
                  }
                }

                value.finalPrice = value.seatPrice; // 次行程的最终单价，因为有抢票的情况
                // 获取当前车次的坐席列表
                value.trainSeatList = JSON.parse(value.robTicketNote);
                value.seatList = [];


                // 抢票的情况需要获取备选车次列表
                if (value.orderType === 2) {
                  this.ticketGrabbingNumber++;

                  value.acceptSeatCodesArr = value.acceptSeatCodes ? value.acceptSeatCodes.split(',') : [];

                  let trainParams = {
                    fromStationCode: value.fromStationCode,
                    toStationCode: value.toStationCode,
                    trainCode: value.trainNo,
                    trainDate: value.startDate
                  };
                  let trainItem = consumer_trains_product_getAlternativeTrainNumber(trainParams);
                  getAlternativeTrainList.push(trainItem)
                }
              });
              this.trainList = trainList;

              Promise.all(getAlternativeTrainList).then(data => {
                let index = 0;
                let [...finalTrainList] = this.trainList;
                finalTrainList.forEach(value => {
                  if (value.orderType === 2) {
                    let acceptTrainCodesArr = value.acceptTrainCodes ? value.acceptTrainCodes.split(',') : [];
                    value.acceptTrainCodesArr = acceptTrainCodesArr;
                    let alternativeTrainNumberList = data[index].resultList;
                    /*alternativeTrainNumberList.forEach(value1 => {
                        if (acceptTrainCodesArr.indexOf(value1.trainCode) > -1) {
                            value1.active = true
                        } else {
                            value1.active = false
                        }
                    })*/
                    value.alternativeTrainNumberList = alternativeTrainNumberList;
                    index++
                  }
                });
                this.trainList = finalTrainList;
                this.trainList.forEach((value, index) => {
                  if (value.orderType === 2) {
                    this.filterSeatList(index)
                  }
                })
              }).finally(() => {
                // Toast.clear()
                this.$showLoading.hide();
              });

              if (this.trainDataTop) {
                consumer_trains_common_queryRailwayTimetable({
                  //出发站三字码
                  fromStationCode: this.trainDataTop.fromStationCode,
                  //到达站三字码
                  toStationCode: this.trainDataTop.toStationCode,
                  //车次号
                  trainCode: this.trainDataTop.trainNo,
                  //日期(2020-05-18)
                  trainDate: this.trainDataTop.arriveDate,
                }).then(res => {
                  let trueBox = [];
                  let switchOff = false;
                  res.results.forEach((item,index) => {
                    if (item.highlight) {
                      switchOff = !switchOff;
                    }
                    if (switchOff) {
                      trueBox.push(item);
                    }
                  });

                  hotelList.siteNum = trueBox.length - 1;
                });
              }
              this.initTrainTimeTable();
              let hotelList = res.datas.todHotelOrderInterimList ? res.datas.todHotelOrderInterimList : [];
              this.hotelList = hotelList
            }).finally(() => {
              // Toast.clear()
              this.$showLoading.hide();
            })

        },
        // 火车票时刻表组件
        initTrainTimeTable() {
          if (this.trainDataTop) {
            this.$refs['aTrainTimeTable'].init({
              //出发站三字码
              fromStationCode: this.trainDataTop.fromStationCode,
              //到达站三字码
              toStationCode: this.trainDataTop.toStationCode,
              //车次号
              trainCode: this.trainDataTop.trainNo,
              //日期(2020-05-18)
              trainDate: this.trainDataTop.arriveDate,
            })
          }
        },
        // 选中座位
        filterSeatList(trainIndex) {
            let [...trainList] = this.trainList;
            let [...seatList] = trainList[trainIndex].trainSeatList;
            let seatResult = [];

            trainList[trainIndex].alternativeTrainNumberList.forEach(value => {
                if (trainList[trainIndex].acceptTrainCodesArr.indexOf(value.trainCode) > -1) {
                    value.trainSeatVoList.forEach(val => {
                        seatList.push(val)
                    })
                }
            });
            // 获取备选车座列表
            for (let i = 0, l = seatList.length; i < l; i++) {
                let hasItem = false;
                for (let m = 0, n = seatResult.length; m < n; m++) {
                    if (seatResult[m].seatCode === seatList[i].seatCode) {
                        hasItem = true;
                        // 比较价格
                        if (parseFloat(seatResult[m].seatPrice) < parseFloat(seatList[i].seatPrice)) {
                            seatResult[m].seatPrice = seatList[i].seatPrice
                        }
                        break
                    }
                }
                if (!hasItem) {
                    seatResult.push(seatList[i])
                }
            }
            trainList[trainIndex].seatList = seatResult;
            this.trainList = trainList;

            // 再对比选中的坐席的价格
            this.chooseSeat(trainIndex, trainList[trainIndex].acceptSeatCodesArr)
        },
        chooseSeat(trainIndex, val) {
            let [...trainList] = this.trainList;
            this.trainList = trainList;
            // 比较最大价格
            let price = 0;
            let acceptSeatInfoArr = []; // 选中的坐席的信息数组，用于显示
            let [...seatList] = this.trainList[trainIndex].seatList;
            if (val !== undefined && val.length > 0) {
                // 有选中情况
                for (let i = 0, l = val.length; i < l; i++) {
                    for (let m = 0, n = seatList.length; m < n; m++) {
                        if (val[i] === seatList[m].seatCode && seatList[m].seatPrice > price) {
                            price = parseFloat(seatList[m].seatPrice)
                            // break
                        }
                        if (val[i] === seatList[m].seatCode) {
                            acceptSeatInfoArr.push(seatList[m])
                        }
                    }
                }
            }
            let seatPrice = price;
            this.trainList[trainIndex].finalPrice = seatPrice > this.trainList[trainIndex].seatPrice ? seatPrice : this.trainList[trainIndex].seatPrice;
            this.trainList[trainIndex].acceptSeatCodes = this.trainList[trainIndex].acceptSeatCodesArr.join(',');
            this.trainList[trainIndex].acceptSeatInfoArr = acceptSeatInfoArr;

            this.saveAndReactPrice()
        },
        // 保存并重新获取价格
        async saveAndReactPrice() {
            await this.saveInfo();
            await this.getAllPrice()
        },
        // 保存信息
        async saveInfo() {
            let params = Object.assign({}, this.evectionParams);
            let userIdList = [];
            let seat = '';
            this.evectionParams.travelUserList.forEach(value => {
                userIdList.push(value.id)
            });

            this.choiceSeatBox.forEach((item, index) => {
                seat = seat + String(item.value);
            });

            params.userIdList = userIdList;
            params.ticketRequests = this.trainList;
            params.evectionNo = this.evectionNo;
            if (this.form.linkmanUnSensitivePhone.includes('*')) {
                params.linkmanPhone = this.form.linkmanPhone;
            } else {
                params.linkmanPhone = this.form.linkmanUnSensitivePhone;
            }

            params.ticketRequests[0].chooseSeat = seat;
            params.smsType = this.form.smsType;
            params.linkmanSms = this.form.linkmanSms;


            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params));
            if (err) {
                return false
            }
            return res
        },
        // 获取订单所有价格
        getAllPrice() {
            if (this.getAllPriceTime) {
                clearTimeout(this.getAllPriceTime);
                this.getAllPriceTime = setTimeout(() => {
                    let params = {
                        evectionNo: this.evectionNo,
                        headCounts: this.form.userList.length,
                        packageCodes: this.packageCodes
                    };
                    consumer_journey_trainTicketcalculatePrice(params).then(res => {
                        this.priceResult = res.datas;
                        //价格集合-new
                        this.priceList = res.datas.orderAmountOfCalculationResults[0];
                        if (this.priceList.bookingInsuranceInfos) {
                            this.aInsureChoiceData = this.$refs.aInsureChoice.get_date();
                            this.priceResult.orderAmountOfCalculationResults[0].bookingInsuranceInfos.forEach((OItem,OIndex) => {
                                this.aInsureChoiceData.data_list.forEach((AItem,AIndex) => {
                                    if (AItem.packageName == OItem.packageName) {
                                        OItem.freeProduct = AItem.freeProduct;
                                    }
                                });
                            });
                        }
                        // Toast.clear();
                        this.$showLoading.hide();
                    }).finally(() => {
                        // Toast.clear();
                        this.$showLoading.hide();
                    })
                },2000);
            } else {
                this.getAllPriceTime = setTimeout(() => {
                    let params = {
                        evectionNo: this.evectionNo,
                        headCounts: this.form.userList.length,
                        packageCodes: this.packageCodes
                    };
                    consumer_journey_trainTicketcalculatePrice(params).then(res => {
                        this.priceResult = res.datas;
                        //价格集合-new
                        this.priceList = res.datas.orderAmountOfCalculationResults[0];
                        // Toast.clear();
                        this.$showLoading.hide();
                    }).finally(() => {
                        // Toast.clear();
                        this.$showLoading.hide();
                    })
                },2000);
            }
        },
        // 获取当前出差单信息和可选择的出行人列表
        getTravelInfoAndUserList() {
            if (this.evectionInfo.evectionType === 1) {
                // 个人出差
                this.getUsuallyUser()
            } else if (this.evectionInfo.evectionType === 2 && this.workTravelType === 2) {
                this.getDepartmentUser()
            } else {
                // 出差单出差
                let params = {
                    evectionNo: this.evectionNo,
                    approveType: 1,
                };
            }
        },
        // 获取公司所有员工
        async getDepartmentUser() {
            // 根据层级返回对应class
            let reduceClass = (val) => {
                if (val === 1) {
                    return 'firstClass'
                } else if (val === 2) {
                    return 'secondClass'
                } else if (val === 3) {
                    return 'thirdClass'
                } else {
                    return 'fourthClass'
                }
            };
            // 给多级数组增加树形
            let reduceData = (data, level) => {
                data.map((m, i) => {
                    m.level = level;
                    m.className = reduceClass(level);
                    m.id = m.deptId;
                    m.text = m.deptName;
                    m.childrenDept = m.deptList;
                    m.children = m.staffList;
                    delete m.deptList;
                    delete m.staffList;
                    if (m.children && m.children.length) {
                        m.children.forEach(value => {
                            value.id = value.subUserId;
                            value.text = value.subStaffName
                        })
                    }
                    if (m.childrenDept && m.childrenDept.length > 0) {
                        reduceData(m.childrenDept, level + 1)
                    }
                })
            };
            // 将多维数组转成一维数组
            let jsonToArray = (nodes) => {
                let r = [];
                if (Array.isArray(nodes)) {
                    for (let i = 0, l = nodes.length; i < l; i++) {
                        r.push(nodes[i]); // 取每项数据放入一个新数组
                        if (Array.isArray(nodes[i]["childrenDept"]) && nodes[i]["childrenDept"].length > 0)
                        // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
                            r = r.concat(jsonToArray(nodes[i]["childrenDept"]));
                        delete nodes[i]["childrenDept"]
                    }
                }
                return r;
            };
            let res = await get_user_info();
            this.userInfo = res.datas;
            let result = await consumer_department_getDepartmentUsers({companyId: this.userInfo.companyId});
            let cascadeList = result.datas;
            reduceData(cascadeList, 1);
            let newCascadeList = jsonToArray(cascadeList);
            let [err, customerResult] = await awaitWrap(consumer_web_externalCustomer_getExternalCustomerAll());
            if (err) {
                this.allUserItems = newCascadeList;
                return
            }
            let customerList = customerResult.datas;
            customerList.forEach(value => {
                value.id = value.userId;
                value.text = value.customerName
            });
            let customerDept = {
                childrenDept: [],
                deptId: -1,
                id: -1,
                deptLevel: 1,
                className: 'firstClass',
                deptName: '外部客户',
                deptStatus: 1,
                parentId: 0,
                children: []
            };
            customerDept.children = customerList;
            newCascadeList.push(customerDept);
            this.allUserItems = newCascadeList
        },
        cancelChooseUser() {
        },
        // 设置出行人
        setUser() {
            this.userDelPopupShow = true
        },
        // 出差单情况下选择出行人
        toggleUser(index) {
            this.$refs.checkboxes[index].toggle();
        },
        getFeeAffiliation() {
            if (this.evectionParams.evectionType === 2 && this.workTravelType === 1) {
                return
            }
            this.feeAffiliationShow = true
        },
        // 展示退改规则弹窗
        showThisRuleList(fIndex, index, val) {
            this.ruleLoading = true;
            this.luggageLoading = true;
            this.ruleText = {};
            this.luggageParams = {};
            this.ruleShow = true;
            let params = {
                cabinCode: val.cabinCode,
                flightNo: val.flightNo
            };
            consumer_air_ticket_flightRules(params).then(res => {
                let refRule = res.datas.rsData.refRule;
                let chaRule = res.datas.rsData.chaRule;
                if (refRule && chaRule) {
                    refRule = refRule.rules;
                    chaRule = chaRule.rules;
                    refRule.map(item => (item.type = "refRule"));
                    chaRule.map(item => (item.type = "chaRule"));
                    this.ruleText = {refRule, chaRule}
                }
                // this.luggage = res.datas.rsData.luggage
            }).finally(() => {
                this.ruleLoading = false
            });
            consumer_air_ticket_flightLuggage(params).then(res => {
                if (res.datas && res.datas.rsData) {
                    let rsData = res.datas.rsData;
                    this.luggageParams = {
                        flyFree: rsData.flyFree,
                        freeWithYou: rsData.freeWithYou,
                        rests: rsData.rests,
                    }
                }
            }).finally(() => {
                this.luggageLoading = false
            })
        },
        // 关闭退改规则弹窗
        closeRuleShow() {
            this.ruleShow = false
        },
        changeAcceptNoSeat(index, val) {
            this.trainList[index].acceptNoSeat = val ? 1 : 0
        },
        getCurrentEvection() {
        },
        // 打开出差单选择弹窗
        showReservePopup() {
            this.travelPopupShow = true
        },
        // 新增火车票
        async toSearchTrain() {
            if (this.evectionParams.evectionType === 2 && !this.haveTrainEvection) {
                return
            }
            let continueToTrain = await this.saveInfo();
            if (!continueToTrain) {
                return
            }
            if (this.evectionInfo.evectionType === 2) {
                // 出差单预定
                this.newTravelParams.travelCurrent = '';
                this.newTravelParams.businessType = 2;
                this.onlySearch = false;
                this.dialogNewTrainOrderVisible = true;
                this.travelCurrent = null
            } else {
                // 个人预定
                let fromStationName = '';
                let fromStationCode = '';
                let toStationName = '';
                let toStationCode = '';
                let startDate = '';
                if (this.trainList.length > 0) {
                    this.trainList.forEach(value => {
                        fromStationName = value.fromStationName;
                        fromStationCode = value.fromStationCode;
                        toStationName = value.toStationName;
                        toStationCode = value.toStationCode;
                        value.startDate
                    })
                }
                this.$router.push({
                    name: 'train-search',
                    query: {
                        fromStationName: fromStationName,
                        fromStationCode: fromStationCode,
                        toStationCode: toStationCode,
                        toStationName: toStationName,
                        trainDate: startDate,
                        redirectType: '001',
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType,
                        checked: false

                    }
                })
            }
        },
        // 确认去添加火车票
        submitSearchNewTravelToTrain() {
            this.dialogNewTrainOrderVisible = false;
            this.$router.push({
                name: 'train-search',
                query: {
                    redirectType: '001',
                    trainDate: this.travelCurrent.active.departTime,
                    fromStationCode: this.travelCurrent.active.departCityId,
                    toStationCode: this.travelCurrent.active.toCityId,
                    checked: this.onlySearch,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType
                }
            })
        },
        // 日期确定
        onCalendarConfirm(val) {
            if (val === '' || val === null || val.length === 0) {
                this.newTravelParams.hotelCheckInDate = '';
                this.newTravelParams.hotelCheckOutDate = ''
            } else {
                this.newTravelParams.hotelCheckInDate = val[0];
                this.newTravelParams.hotelCheckOutDate = val[1]
            }
        },
        // 展开价格详情
        showPriceInfoPopup() {
            this.productPricePopupSet = !this.productPricePopupSet;
            this.productPricePopup = !this.productPricePopup;
        },
        closeProductPricePopup() {
            this.productPricePopupSet = false
        },
        //
        // 校验入住联系人
        validteCheckIn(val) {

            if (val === "" || val === null || val === undefined) {
                return false
            } else {
                return true
            }
        },

        showUserPopup() {
            if (this.workTravelType === 2) {
                let activeUserIds = [];
                this.evectionParams.travelUserList.forEach(value => {
                    activeUserIds.push(value.id)
                });
                this.activeUserIds = activeUserIds;
                this.popupShow = true
            } else {
                this.selectUserPopup = true
            }
        },

        //
        async getDepartmentUserList() {
            // 给多级数组增加树形
            let reduceData = (data, level) => {
                data.map((m, i) => {
                    m.level = level;
                    //m.className = reduceClass(level)
                    m.id = m.deptId;
                    m.text = m.deptName;
                    m.childrenDept = m.deptList;
                    m.children = m.staffList;
                    delete m.deptList;
                    delete m.staffList;
                    if (m.children && m.children.length) {
                        m.children.forEach(value => {
                            value.id = value.subUserId;
                            value.text = value.subStaffName
                        })
                    }
                    if (m.childrenDept && m.childrenDept.length > 0) {
                        reduceData(m.childrenDept, level + 1)
                    }
                })
            };
            // 将多维数组转成一维数组
            let jsonToArray = (nodes) => {
                let r = [];
                if (Array.isArray(nodes)) {
                    for (let i = 0, l = nodes.length; i < l; i++) {
                        r.push(nodes[i]); // 取每项数据放入一个新数组
                        if (Array.isArray(nodes[i]["childrenDept"]) && nodes[i]["childrenDept"].length > 0)
                        // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
                            r = r.concat(jsonToArray(nodes[i]["childrenDept"]));
                        delete nodes[i]["childrenDept"]
                    }
                }
                return r;
            };

            let departmentUserList = [];
            let setDeptList = (list) => {
                list.forEach(value => {
                    if (value.staffList) {
                        value.staffList.forEach(value1 => {
                            value1.id = value1.subUserId;
                            value1.text = value1.subStaffName;
                            value1.name = value1.subStaffName;
                            value1.deptId = value.deptId;
                            value1.deptName = value.deptName;
                            departmentUserList.push(value1)
                        })
                    }
                    if (value.deptList) {
                        setDeptList(value.deptList)
                    }
                })
            };
            let [err, res] = await awaitWrap(consumer_department_getDepartmentUsers());
            let cascadeList = res.datas;
            /*reduceData(cascadeList, 1)
            let newCascadeList = jsonToArray(cascadeList)
            console.log(newCascadeList)
            let departmentUserList = []
            newCascadeList.forEach(value => {


            })*/
            setDeptList(cascadeList);
            let [customerErr, customerResult] = await awaitWrap(consumer_web_externalCustomer_getExternalCustomerAll());
            if (customerErr) {
                this.contactUserList = JSON.parse(JSON.stringify(departmentUserList))
            }
            let customerList = customerResult.datas;
            customerList.forEach(value => {
                value.id = value.userId;
                value.text = value.customerName;
                value.name = value.customerName;
                value.deptId = -1;
                value.deptName = '外部客户'
            });
            departmentUserList = departmentUserList.concat(customerList);
            this.contactUserList = JSON.parse(JSON.stringify(departmentUserList));
        },
        submitContactUser() {
            let activeContactUserList = JSON.parse(JSON.stringify(this.activeContactUserList));
            this.evectionParams.linkman = activeContactUserList[0].name;
            this.evectionParams.linkmanPhone = activeContactUserList[0].phone
        },
        submitAddUser() {
            let idList = [];
            this.chooseTravelUserList.forEach(value => {
                idList.push(value.id)
            });
            let travelUserList = [];
            let travelUserIdList = [];
            this.evectionParams.travelUserList.forEach((value, index) => {
                if (idList.indexOf(value.id) > -1) {
                    travelUserList.push(value);
                    travelUserIdList.push(value.id)
                }
            });
            this.evectionParams.travelUserList = travelUserList;
            this.travelUserIdList = travelUserIdList
        },

        // 获取常用旅客
        getUsuallyUser() {
            consumer_frequent_contacts_getList({currentPage: 1, pageSize: 100}).then(res => {
                let frequentPassenger = res.pageResult.pageData;
                frequentPassenger.forEach(value => {
                    value.value = value.uid;
                    value.id = value.uid;
                    value.text = value.username;
                    value.label = value.username;
                    value.name = value.username
                });
                this.canChooseUserList = frequentPassenger
            })
        },

        __successPopup_opened_event_handler() {
            const __this = this;
            fixStatusBar({
                type: `200`,
                element: __this.$refs.successPopup.$el.querySelector(`.popup-search-header`),
            });
        },
        getPayRemider() {
            consumer_payment_payReminder().then(res=>{
                this.paymentPage = res.datas.paymentPage;
            })
        }
    }
}
