import NavBar from '@/component/nav-bar/import_latest_version_module'
import ColorIcon from "@/component/color-icon/index.vue";
import consumer_trains_common_queryPassengerType from '@/lib/data-service/haolv-default/consumer_trains_common_queryPassengerType'
import consumer_trains_common_queryIdType from '@/lib/data-service/haolv-default/consumer_trains_common_queryIdType'
import consumer_trains_passenger_deletePassenger from '@/lib/data-service/haolv-default/consumer_trains_passenger_deletePassenger'
import consumer_trains_passenger_addPassenger from '@/lib/data-service/haolv-default/consumer_trains_passenger_addPassenger'
import consumer_trains_passenger_updatePassenger from '@/lib/data-service/haolv-default/consumer_trains_passenger_updatePassenger'
import mock_data_helper from './../../lib/mock_data_helper'
import isMobile from 'yinzhilv-js-sdk/src/common-service/other/is_mobile/index.js'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import extend from "extend";
import {Toast} from "vant";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
export default {
    data() {
        return {
            params: {
                pageType: '001',  // 001: 编辑模式  002： 新建模式
                userInfo: {},
                mock_data: {
                    enable: false,
                    test_case: '001'
                },
            },
            show: false,
            editPassStatus: true,
            form: {
                passengerIdTypeCode: 1, // 证件类型
                idTypeName: '身份证',
                mobileNo: '', // 手机
                passengerIdNo: '', // 证件号码
                passengerName: '', // 姓名
                passengerType: '1', // [{"msg":"成人","code":"1"},{"msg":"儿童","code":"2"},{"msg":"学生","code":"3"},{"msg":"残军","code":"4"}]
                passengerTypeName: '成人',
                oldPassengerIdNo: '',
                oldPassengerIdTypeCode: '',
                oldPassengerName: '',
            },
            passengerTypeList: [],
            cardIdList: [{value: 1, text: '身份证'}],

            showPicker: false,
            showCardIdPicker: false,
        }
    },
    components: {NavBar, ColorIcon},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        passengerNameReadonly() {
            const params = this.params;
            const editPassStatus = this.editPassStatus;
            if (params.pageType === '001') {
                return true
            } else {
                return false
            }
        },
        passengerTypeDisabled() {
            const params = this.params;
            const editPassStatus = this.editPassStatus;
            return false
        },
        passengerIdNoReadonly() {
            const params = this.params;
            const editPassStatus = this.editPassStatus;
            if (params.pageType === '001' && editPassStatus) {
                return true
            } else {
                return false
            }
        },
        mobileNoReadonly() {
            return false
        },
    },
    filters: {},
    methods: {
        _editBack() {
            this.show = false;
        },
        _queryPassengerType() {
            const queryPassengerType = this.params.mock_data.enable ? mock_data_helper.queryPassengerType() : consumer_trains_common_queryPassengerType();
            queryPassengerType.then(res => {
                let passengerTypeList = res.datas;
                passengerTypeList.forEach(value => {
                   value.value = value.code;
                   value.text = value.msg;
                });
                this.passengerTypeList = passengerTypeList
            })
        },
        _queryIdType() {
            const queryIdType = consumer_trains_common_queryIdType();
            queryIdType.then(res => {

            })
        },
        _toEditPassengerType() {
            this.showPicker = true
        },
        _closePicker() {
            this.showPicker = false;
        },
        _onConfirm(value, index) {
            this.form.passengerTypeName = value.msg;
            this.form.passengerType = value.code;
            this.showPicker = false;
        },
        _toEditCardIdType() {
            if (this.params.pageType === '001' && this.editPassStatus) {
                return
            }
            this.showCardIdPicker = true;
        },
        _closeCardPicker() {
            this.showCardIdPicker = false;
        },
        _onCardConfirm(value, index) {
            this.form.idTypeName = value.msg;
            this.form.passengerIdTypeCode = value.code;
            this.showCardIdPicker = false;
        },
        _delPassenger() {
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
            });
            let form = {
                id: this.params.userInfo.userId,
                passengerName: this.params.userInfo.name || '', // 姓名
                passengerIdNo: this.params.userInfo.cardId || '', // 证件号码
                idTypeCode: this.params.userInfo.cardType,
            }
            const deletePassenger = this.params.mock_data.enable ? mock_data_helper.deletePassenger(form) : consumer_trains_passenger_deletePassenger(form);
            deletePassenger.then(res => {
                Toast.clear();
                // 是否弹窗 0.不弹 1.弹
                if (res.datas.popuPorNot === 0) {
                    Toast.success('删除成功');
                    this.$emit('del');
                    this._editBack()
                } else {
                    Toast.fail(res.msg);
                }
            }).catch(() => {
                Toast.clear()
            })
        },
        _isChineseIDCardNumber(value) {
            const testRegExp = /^[1-9]([0-9]{14}|[0-9]{16}[0-9Xx])$/;
            const areaMap = [11, 12, 13, 14, 15, 21, 22, 23, 31, 32, 33, 34, 35, 36, 37, 41, 42, 43, 44, 45, 46, 50, 51, 52, 53, 54, 61, 62, 63, 64, 65, 71, 81, 82];
            const weightMap = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
            const codeMap = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];
            const len = value.length;
            if (len !== 18) {
                return false;
            }
            // 模式校验
            if (!testRegExp.test(value)) {
                return false;
            }
            // 地区校验
            if (areaMap.indexOf(+value.substr(0, 2)) === -1) {
                return false;
            }
            // 18 位
            if (!this._isValidDate(+value.substr(6, 4), +value.substr(10, 2), +value.substr(12, 2))) {
                return false;
            }
            // 校验码
            const sum = value.split('').slice(0, 17).reduce((s, num, index) => {
                return s += +num * weightMap[index];
            }, 0);
            return codeMap[sum % 11] === value[17].toUpperCase();
        },
        // 身份证号码验证
        _isValidDate(year, month, day) {
            const date = new Date(year, month - 1, day);
            return (
                date.getFullYear() === year
                && date.getMonth() + 1 === month
                && date.getDate() === day
                && date.getTime() < new Date().getTime()
            );
        },
        _checkForm() {
            return new Promise((resolve, reject) => {
                if (this.form.passengerName === '') {
                    Toast('请输入姓名');
                    resolve(false);
                    return
                }
                if (!this.form.passengerType) {
                    Toast('请选择旅客类型');
                    resolve(false);
                    return
                }
                if (!this.form.passengerIdTypeCode) {
                    Toast('请选择证件类型');
                    resolve(false);
                    return;
                }
                if (this.form.passengerIdNo === '') {
                    Toast('请填写证件号码');
                    resolve(false);
                    return;
                }
                /*if (this._isChineseIDCardNumber(this.form.passengerIdNo) === false) {
                    Toast('请输入正确的身份证号码');
                    resolve(false);
                    return
                }*/
                if (this.mobileNo === '') {
                    Toast('请填写手机号码');
                    resolve(false);
                    return;
                }
                /*if (!isMobile(this.form.mobileNo)) {
                    Toast('请输入正确的手机号码');
                    resolve(false);
                    return
                }*/
                resolve(true)
            })
        },
        async _submit() {
            // 校验
            let res = await this._checkForm();
            if (!res) {
                return
            }
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
            });
            const updatePassenger = this.params.mock_data.enable ? mock_data_helper.updatePassenger : consumer_trains_passenger_updatePassenger;
            const addPassenger = this.params.mock_data.enable ? mock_data_helper.addPassenger : consumer_trains_passenger_addPassenger;
            const submitFun = this.params.pageType === '001' ? updatePassenger(this.form) : addPassenger(this.form);
            let [resultErr, result] = await awaitWrap(submitFun);
            if (resultErr) {
                Toast.clear();
                return
            }
            Toast.clear();
            const successText = this.params.pageType === '001' ? '保存成功' : '添加成功';
            Toast.success(successText);
            let form = JSON.parse(JSON.stringify(this.form));
            form.userId = result.datas ? result.datas.userId : '';
            // // 001: 编辑模式  002： 新建模式
            this.$emit('result', this.params.pageType, form);
            this._editBack()
        },
        _openedResult() {
            const _this = this;
            fixStatusBar({
                handler({default_handler, api}) {
                    const bodyWidth = document.body.clientWidth;
                    const topElement = _this.$refs['navBar'].$el;
                    let orrPaddingTop = topElement.style.paddingTop;
                    if (bodyWidth >= 414) {
                        orrPaddingTop = orrPaddingTop * 2
                    }
                    let currentPaddingTop = (orrPaddingTop || 0)/(bodyWidth/100);
                    const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
                    topElement.style.paddingTop = (addPaddingTop + currentPaddingTop) + 'vw';
                }
            })
        },

        init(params) {
            let initParams = {
                pageType: '001',  // 001: 编辑模式  002： 新建模式
                userInfo: {},
                mock_data: {
                    enable: false,
                    test_case: '001'
                },
            };
            if (!params) {
                params = {}
            }
            extend(initParams, params);
            extend(initParams.mock_data, params.mock_data);
            this.params = JSON.parse(JSON.stringify(initParams));
            if (this.params.pageType === '001') {
                this.editPassStatus = this.params.userInfo.optional;
                this.form = {
                    id: this.params.userInfo.userId,
                    passengerIdTypeCode: this.params.userInfo.cardType || 1, // 证件类型
                    idTypeName: this.params.userInfo.cardTypeText || '身份证',
                    mobileNo: this.params.userInfo.mobileNo || '', // 手机
                    passengerIdNo: this.params.userInfo.cardId || '', // 证件号码
                    passengerName: this.params.userInfo.name || '', // 姓名
                    passengerType: this.params.userInfo.userType || '1', // [{"msg":"成人","code":"1"},{"msg":"儿童","code":"2"},{"msg":"学生","code":"3"},{"msg":"残军","code":"4"}]
                    passengerTypeName: this.params.userInfo.userTypeText || '成人',
                    oldPassengerIdNo: this.params.userInfo.cardId, // 原证件号
                    oldPassengerIdTypeCode: this.params.userInfo.cardType, // 原证件类型
                    oldPassengerName: this.params.userInfo.name, // 原姓名
                };
            } else {
                // 新建
                this.form = {
                    passengerIdTypeCode: 1, // 证件类型
                    idTypeName: '身份证',
                    mobileNo: '', // 手机
                    passengerIdNo: '', // 证件号码
                    passengerName: '', // 姓名
                    passengerType: '1', // [{"msg":"成人","code":"1"},{"msg":"儿童","code":"2"},{"msg":"学生","code":"3"},{"msg":"残军","code":"4"}]
                    passengerTypeName: '成人',
                    oldPassengerIdNo: '',
                    oldPassengerIdTypeCode: '',
                    oldPassengerName: '',
                }
            }
            this._queryPassengerType();
            // this._queryIdType();
            this.show = true;
        },

        get_show() {
            return this.show
        },
        hide() {
            this._editBack()
        },
    }
}
