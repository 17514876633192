const slider_left_back = (node, _this) => {
  const width = node.offsetWidth;
  let startX = 0;
  let startY = 0;
  node.addEventListener("touchstart", (e) => {
    startX = e.changedTouches[0].pageX;
    startY = e.changedTouches[0].pageY;
  });

  node.addEventListener("touchend", (e) => {
    const endX = e.changedTouches[0].pageX;
    const endY = e.changedTouches[0].pageY;

    const disX = endX - startX;
    const disY = endY - startY;
    if (Math.abs(disX) > Math.abs(disY) && Math.abs(disX) > (width / 3)) {
      if (disX > 0) _this.$router.push({ name: 'h5-home' });
    }
  });

  // const slideArr = ["h5-flight-home", "h5-hotel-home", "h5-train-home"];
  // const name = this.$route.name;
  // let index = slideArr.indexOf(name);
  // let transitionName = "van-slide";
  // if (slideArr.includes(name)) {
  //   const layout = document.getElementById("h5_layout");
  //   let startX = 0;
  //   let startY = 0;
  //   let endX = 0;
  //   let endY = 0;
  //   layout.addEventListener("touchstart", (e) => {
  //     startX = e.changedTouches[0].pageX;
  //     startY = e.changedTouches[0].pageY;
  //   });
  //   layout.addEventListener("touchend", (e) => {
  //     endX = e.changedTouches[0].pageX;
  //     endY = e.changedTouches[0].pageY;

  //     const disX = endX - startX;
  //     const disY = endY - startY;
  //     if (Math.abs(disX) > Math.abs(disY)) {
  //       if (disX > 0) {
  //         index -= 1;
  //         if (index < 0) index = 2;
  //         transitionName = "van-slide-right";
  //       }
  //       if (disX < 0) {
  //         index += 1;
  //         if (index > 2) index = 0;
  //         transitionName = "van-slide-left";
  //       }
  //     }
  //     store.commit("update_transitionName", transitionName);
  //     this.$router.push({
  //       name: slideArr[index],
  //     });
  //   });
  // } else {
  //   store.commit("update_transitionName", transitionName);
  // }
};

export default slider_left_back;