export default {
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        reactBackFun: {
            type: Boolean,
            default: false
        },
        backShow: {
            type: Boolean,
            default: false
        },
        backBtnColor: {
            type: String,
            default: '#ffffff'
        },
        backBtnName: {
            type: String,
            default: 'arrow-left'
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        back() {
            if (!this.reactBackFun) {
                this.$router.go(-1)
            }
            this.$emit('handleBack')
        },
    }
}