import NavBar from "@/component/nav-bar/import_latest_version_module";
import open from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window";
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'

export default {
    data() {
        return {
            businessType: '1',
            insuranceList: []
        }
    },
    components: {
        NavBar,
    },
    activated() {
        let list = [];
        let insuranceInfoList = [];
        this.businessType = this.$route.query.businessType ?? '1';

        insuranceInfoList = JSON.parse(this.$route.query.insuranceInfo);
        insuranceInfoList.forEach(item =>{
            list.push({
                insured: item.insured,
                userId: item.userId,
                insuranceOrderInfo: {
                    failureCause: item.failureCause,
                    insuranceControlType: item.insuranceControlType,
                    insuranceControlTypeText: item.insuranceControlTypeText,
                    insuranceOrderId: item.insuranceOrderId,
                    insurancePrice: item.insurancePrice,
                    insuranceStatus: item.insuranceStatus,
                    insuranceStatusText: item.insuranceStatusText,
                    insuranceType: item.insuranceType,
                    segList: item.segList
                }
            })
        })

        // 使用 lodash 库进行分组和转换
        const _ = require('lodash');

        // 将 list 中的元素按 userId 分组
        const list_grouped = _.groupBy(list, 'userId');

        // 将 list_grouped 中的元素转换为insuranceList的格式
        const insuranceList = _.map(list_grouped, (value, key) => {
            return {
                userId: key,
                insured: value[0].insured,
                orderObjList: _.map(value, 'insuranceOrderInfo')
            };
        });
        this.insuranceList = insuranceList;
    },
    methods: {
        //查看保险单详情
        checkInsuranceDetail(val) {
            this.$showLoading.show();
            const params = {
                insuranceOrderId: val.insuranceOrderId
            };
            consumer_insurance_getEInsuranceOrderUrl(params).then(res => {
                const url = res.datas.epolicyUrl;
                if (url) {
                    window.open(url);
                    /*open({
                        url,
                        title: '保险详情'
                    })*/
                }
            }).finally(() => {
                // Toast.clear()
                this.$showLoading.hide();
            })
        },
        // 返回
        back() {
            this.$router.go(-1);
        },
    }
}