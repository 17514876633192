import __get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/get_user_info';
import store from '@/lib/store';


const get_user_info = function (pParameter) {
    if (!pParameter) {
        pParameter = {};
    }

    return new Promise(async (resolve, reject) => {
        try {
            const res = await __get_user_info(pParameter)
            store.commit(`update_data_get_user_info`, res.datas)
            resolve(res)
        } catch (error) {
            reject(error)
        }
    })
};

export default get_user_info