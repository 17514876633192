import NavBar from "@/component/nav-bar/import_latest_version_module";
import TrainInstructions from '../../../../../travel-book/train/train-reserve/commponent/train-instructions/index'
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import consumer_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel'
// 获取出差规则
import consumer_t_tp_refund_change_policy_get from '@/lib/data-service/haolv-default/consumert_tp_refund_change_policyget'
import moment from "moment";
import {Toast,Dialog} from "vant";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
export default {
    data() {
        return {
            explainShow: false,
            trainInvoiceType: 0,
            orderNo: '',
            detailInfo: {
                passengers: [
                    {
                        boardingGates: ''
                    }
                ]
            },
            passengerList: [],
            activePassengerItem: {},
            intervalFun: '',
            changePassengerShow: false,
            changeResult: [],

            refundDialog: false,
        }
    },
    components: {NavBar, TrainTimeTable, TrainInstructions},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo;
        this.getDetail();
        let __this = this;
        consumer_t_tp_refund_change_policy_get().then(res => {
            this.trainInvoiceType = res.datas.trainInvoiceType ? res.datas.trainInvoiceType : 0;
        });
        try {
            let show_explainShow = __this.explainShow;
            if (show_explainShow) {
                __this.hideExplain();
                return;
            }
        } catch (err) {

        }
        keyback_processor.addEventListener(() => {
            const lineShow = this.$refs['aTrainTimeTable'].get_show();
            if (lineShow) {
                this.$refs['aTrainTimeTable'].hide();
                return
            }

            __this.clickGoList();
        })
    },
    deactivated() {
        clearInterval(this.intervalFun);
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterIntervalTime(val) {
            if (val) {
                // 转换为式分秒
                let h = parseInt(val / 60 / 60 % 24);
                h = h < 10 ? '0' + h : h;
                let m = parseInt(val / 60 % 60);
                m = m < 10 ? '0' + m : m;
                let s = parseInt(val % 60);
                s = s < 10 ? '0' + s : s;
                let text = `${m}分${s}秒`;
                if (h !== '00') {
                    text = h + '时' + text
                }
                return text
            } else {
                return '00分00秒'
            }
        },
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
    },
    methods: {
        //显示保险详情
        toInsuranceDetail() {
            this.$router.push({
                name: "flight-insurance",
                query: {
                    insuranceInfo: JSON.stringify(this.detailInfo.insuranceInfo),
                    businessType: 2,
                },
            });
        },
        getDetail() {
            this.$showLoading.show();
            consumer_trains_order_getTrainsOrderDetail({
                orderNo: this.orderNo
            }).then((res) => {
                this.$showLoading.hide();
                // 1.占座中  2.待支付  3.出票中  4.出票完成  5.出票失败  6.已取消  8.取消中  10.超标审批中'
                this.detailInfo = res.datas;
                let passengerList = [];
                this.detailInfo.passengers.forEach((value, index) => {
                    if (value.showChangeButton === 1) {
                        passengerList.push(value)
                    }
                });
                this.passengerList = passengerList;
                //this.passengersOne = res.datas.passengers[0];
                //this.passengersList = [].concat(res.datas.passengers);

                // if (this.detailInfo.trainstrainsOrder != null) {
                //     this.userNameText = this.detailInfo.trainstrainsOrder[0].userName;
                //     this.standardText = this.detailInfo.trainstrainsOrder[0].standard;
                //     this.standardContent = this.detailInfo.trainstrainsOrder[0].standardContent;
                // }

                this.$nextTick(() => {
                    this.$refs['aTrainTimeTable'].init({
                        //出发站三字码
                        fromStationCode: this.detailInfo.fromStationCode,
                        //到达站三字码
                        toStationCode: this.detailInfo.toStationCode,
                        //车次号
                        trainCode: this.detailInfo.trainNo,
                        //日期(2020-05-18)
                        trainDate: this.detailInfo.startDate,
                    });
                });

                if (this.detailInfo.milliseconds > 0) {
                    this.setPayInterval()
                }
                this.$refs.aDynamicForm.init({
                    type: 'immutable',
                    businessType: 2,
                    displayList: this.detailInfo.reportConfigurations ? this.detailInfo.reportConfigurations : [],
                    mock_data: {
                        enable: true,
                    }
                });
            }).catch(() => {
                this.$showLoading.hide();
            });
        },
        setPayInterval() {
            this.intervalFun = setInterval(() => {
                this.detailInfo.milliseconds--;
                if (this.detailInfo.milliseconds <= 0) {
                    clearInterval(this.intervalFun);
                    // this.getDetail();
                    this.$router.go();
                }
            }, 1000)
        },
        clickGoList(){
            if (this.intervalFun != null) {
                clearInterval(this.intervalFun);
            }
            if (this.$route.query.jumpType) {
                if (this.$route.query.jumpType === 'home') {
                    this.$router.push({
                        name: 'h5-home'
                    })
                } else if (this.$route.query.jumpType === 'list') {
                    this.$router.push({
                        name: 'h5-train-order-list'
                    })
                } else {
                    this.$router.go(-1)
                }
            } else {
                this.$router.go(-1)
            }
        },
        clickApplyNo(val){
            if (val.applicationid) {
                this.$router.push({
                    name: val.approveType === 2 ? 'h5-apply-approve-exceed' : 'h5-approve-detail',
                    query: {
                        id: val.applicationid,
                        approveType: val.approveType,
                        type: 'applyDetail',
                        applyNo: val.applyNo,
                        approveStatus: '',
                        selectType: 1,
                    },
                });
            }
        },
        firstCopySuccess(e) {
            Toast('已复制');
        },
        setStatus(val) {
            // 车票状态 0：待预定，1：待出票，2：出票中，3：出 票完成，4：出票失败, 5：退票已申请, 6：退票处理中, 7：退票完成， 8：退票失败，9：改签已申请，10：改签中， 11：改签完成，12：改签失败，13：改签已取消， 14：已改签， 15：待支付， 16：预定中， 17：占座失败
            let result = '';
            if ([0, 1, 2].indexOf(val) >= 0) {
                result = 'pending'
            } else if (val === 3) {
                result = 'success'
            } else if ([13].indexOf(val) >= 0) {
                result = 'cancel'
            } else {
                result = ''
            }
            return result
        },
        //支付
        toPayment(orderNo) {
            if (this.detailInfo.evectionType == 2) {
                this.$router.push({
                    name: "h5-travel-payment",
                    query: {
                        orderNo,
                        orderType: "3",
                        businessType: 2,
                    },
                });
            } else {
                this.$router.push({
                    name: "train-submit-order",
                    query: {
                        orderNo,
                    },
                });
            }
        },
        //取消订单
        toCancelOrder(orderNo) {
            let _this = this;
            Dialog.confirm({
                title: '确认要取消订单吗？',
                message: '一天仅有3次取消订单的机会（包括自动取消），超过3次后当日将无法使用12306账号预订。',
                confirmButtonText: '取消订单',
                cancelButtonText: '稍后再说',
            }).then(() => {
                this.$showLoading.show();
                consumer_trains_order_applyForCancel({orderNo: orderNo})
                  .then(res => {
                    _this.$showLoading.hide();
                    Dialog.alert({
                        title: '取消预订提示',
                        message: '你已成功取消预订',
                    }).then(() => {
                        this.getDetail();
                    })
                }).catch(() => {
                    _this.$showLoading.hide();
                    Dialog.alert({
                        title: '取消预订提示',
                        message: '订单取消失败',
                    })
                })
            }).catch(() => {

            });
        },
        openTimeLine() {
            this.$refs['aTrainTimeTable'].show();
        },
        toChange(item, index) {
            if (item.showChangeButton === 0) {
                return
            }
            // 判断是否只剩一个人
            if (this.passengerList.length === 1) {
                let userList = this.passengerList[0].userId;
                const query = {
                    orderNo: item.orderNo,
                    fromStationName: item.fromStationName,
                    fromStationCode: item.fromStationCode,
                    toStationName: item.toStationName,
                    toStationCode: item.toStationCode,
                    startDate: this.detailInfo.startDate,
                    evectionNo: this.detailInfo.evectionNo,
                    evectionType: this.detailInfo.evectionType,
                    userList: userList.toString()
                };
                this.$router.push({
                    name: 'h5-train-change',
                    query
                })
            } else {
                // 可选多人，弹窗选
                this.activePassengerItem = item;
                this.changeResult = [item.userId];
                this.changePassengerShow = true;
            }
        },
        toRefund(item, index) {
            if (item.showRefundButton === 0) {
                return
            }
            const query = {
                orderNo: item.orderNo,
                passengerSn: item.sn
            };
            this.$router.push({
                name: 'h5-train-refund',
                query
            })
        },
        changeToggle(index) {
            this.$refs.checkboxes[index].toggle();
        },
        submitChange() {
            if (this.changeResult.length === 0) {
                Toast('请选择改签出行人');
                return
            }
            this.changePassengerShow = false;
            let userList = this.changeResult.join(',');
            const query = {
                orderNo: this.activePassengerItem.orderNo,
                fromStationName: this.activePassengerItem.fromStationName,
                fromStationCode: this.activePassengerItem.fromStationCode,
                toStationName: this.activePassengerItem.toStationName,
                toStationCode: this.activePassengerItem.toStationCode,
                startDate: this.detailInfo.startDate,
                evectionNo: this.detailInfo.evectionNo,
                evectionType: this.detailInfo.evectionType,
                userList: userList
            };
            this.$router.push({
                name: 'h5-train-change',
                query
            })
        },

        hideExplain(){
            this.explainShow = false;
        },
        // 说明click
        clickExplain(){
            this.explainShow = true;
        },
        toEvectionDetail() {
            if (this.detailInfo.temporaryEvectionStatus !== 0) {
                return
            }
            this.$router.push({
                name: 'h5-office-evection-details',
                query: {
                    id: this.detailInfo.evectionNo,
                }
            })
        },
    }
}
