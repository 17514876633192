import { Notify } from 'vant';

// 获取用户信息
// import consumer_user_info from '@/lib/data-service/haolv-default/consumer_user_info';
// import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info';
import get_user_info from '@/lib/common-service/get_user_info';

import consumer_user_updateHead from '@/lib/data-service/haolv-default/consumer_user_updateHead';
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';

export default {
    data() {
        return {
            ladyLogoList:[
                {
                    category: 'lady',
                    imageSrc: 'https://haolv-enterprise-app-static.oss-cn-shenzhen.aliyuncs.com/2020/10/13/touxiang-05.png?x-oss-process=style/haolv-default'
                },
                {
                    category: 'lady',
                    imageSrc: 'https://haolv-enterprise-app-static.oss-cn-shenzhen.aliyuncs.com/2020/10/13/touxiang-06.png?x-oss-process=style/haolv-default'
                },
                {
                    category: 'lady',
                    imageSrc: 'https://haolv-enterprise-app-static.oss-cn-shenzhen.aliyuncs.com/2020/10/13/touxiang-07.png?x-oss-process=style/haolv-default'
                },
                {
                    category: 'lady',
                    imageSrc: 'https://haolv-enterprise-app-static.oss-cn-shenzhen.aliyuncs.com/2020/10/13/touxiang-08.png?x-oss-process=style/haolv-default'
                }
            ],
            manLogoList:[
                {
                    category: 'man',
                    imageSrc: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/08/08/touxiang-01.png'
                },
                {
                    category: 'man',
                    imageSrc: 'https://haolv-enterprise-app-static.oss-cn-shenzhen.aliyuncs.com/2020/10/13/touxiang-02.png?x-oss-process=style/haolv-default'
                },
                {
                    category: 'man',
                    imageSrc: 'https://haolv-enterprise-app-static.oss-cn-shenzhen.aliyuncs.com/2020/10/13/touxiang-03.png?x-oss-process=style/haolv-default'
                },
                {
                    category: 'man',
                    imageSrc: 'https://haolv-enterprise-app-static.oss-cn-shenzhen.aliyuncs.com/2020/10/13/touxiang-04.png?x-oss-process=style/haolv-default'
                }
            ],
            checkHeadSrc: ''
        }
    },
    created () {},
    async mounted () {
        let __this = this;
        fixStatusBar({
            handler({api}) {
                __this.$refs.searchHeader.$el.style.paddingTop = api.safeArea.top + 'px';
            }
        });
        await this.getUserInfo(); // 获取个人资料
    },
    methods: {
        back(){
          this.$router.go(-1);
        },
        // 获取个人信息
        async getUserInfo() {
            const __this = this;
            let res = await get_user_info();
            this.checkHeadSrc = res.datas.headPortraitCode;
        },
        selectImage(row, index) {
            const __this = this;
            __this.checkHeadSrc = row.imageSrc;
        },
        changeLogo() {
            const __this = this;
            if (__this.checkHeadSrc === '') {
                Notify({ type: 'warning', message: '请选择头像！' });
                return;
            }
            consumer_user_updateHead({headPortraitCode: __this.checkHeadSrc}).then((res) => {
                if(res.code === '000000') {
                    get_user_info({clear_cache: true});
                    Notify({
                        type: 'success',
                        message: '更换成功！',
                        duration: 1000,
                        onClose: function () {
                            if (window.getFlutter == true) {
                                __this.back();
                            } else {
                                __this.$router.push({
                                    name: 'h5-personal-center',
                                });
                            }

                            /*__this.$router.push({
                                name: 'h5-personal-center',
                            });*/
                        }
                    });
                }
            }).catch(() => {});
        }
    }
}
