import moment from 'moment';
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import wangyiServerOnline from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper";
import consumer_frequent_getLoginUserFrequents
    from "../../../../lib/data-service/haolv-default/consumer_frequent_getLoginUserFrequents";
import consumer_intention_order_submit from '@/lib/data-service/haolv-default/consumer_intention_order_submit';
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import CommonlyUsedPassenger
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/commonly-used-passenger/1.0.4/index.vue';
import {Toast} from "vant";

export default {
    data() {
        return {
            id: 1,
            showPicker: false,
            showIllustrate: false,
            showOftenTravelers: false,
            curProductObj: {},
            productList: [
                {
                    id: 1,
                    name: '北京环球影城',
                    src: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/05/harry.png'
                },
                {
                    id: 2,
                    name: '珠海长隆海洋王国',
                    src: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/05/whale.png'
                }
            ],
            frequentList: [],
            frequentIdList: [],
            intentionMsgVOList: [
                {
                    admissionTicketType: 1,//1成人
                    admissionTicketTypeText: '成人票（标准票）',
                    intentionNum: 1,
                    illustrateText: '',
                },
                {
                    admissionTicketType: 2,//2儿童
                    admissionTicketTypeText: '儿童票',
                    intentionNum: 0,
                    illustrateText: '',
                },
                {
                    admissionTicketType: 3,//4学生
                    admissionTicketTypeText: '学生票',
                    intentionNum: 0,
                    illustrateText: '',
                },
                {
                    admissionTicketType: 4,//3长者
                    admissionTicketTypeText: '长者票',
                    intentionNum: 0,
                    illustrateText: '',
                }
            ],//门票信息
            form: {
                productId: 0,//产品id
                productName: '',//产品名称
                departTime: moment().add(1, 'days').format('YYYY-MM-DD'),//预约时间
                contactsName: '',//联系人
                contactPhone: '',//联系电话
                intentionMsgVOList: [],//门票信息列表
                intentionTourists: [],//游客信息列表
            },
            rules: {
                departTime: [
                    {required: true, message: '请选择游园日期'}
                ],
                contactsName: [
                    {required: true, message: '请输入联系人名称'}
                ],
                contactPhone: [
                    {required: true, message: '请输入联系电话'}
                ],
            }
        }
    },
    activated() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback: (result) => {
                    this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            }, 1)
        });

        let _this = this;
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                _this.$refs['btnWrapper'].style.paddingBottom = `${api.safeArea.bottom - 10}px`;
            }
        });

        this.keyback();

        this.init();
    },
    destroyed() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });

        keyback_processor.removeEventListener();
    },
    components: {
        CommonlyUsedPassenger
    },
    methods: {
        //初始化
        async init() {
            Toast.loading({
                message: '',
                forbidClick: true,
                duration: 0
            })
            this.id = this.$route.query.id ? Number(this.$route.query.id) : 1;
            this.form.productId = this.id;
            this.form.productName = this.id === 1 ? '北京环球影城门票' : '珠海长隆海洋王国门票';
            this.curProductObj = this.productList[this.id - 1];
            switch (this.id) {
                case 1:
                    this.intentionMsgVOList.forEach(item => {
                        switch (item.admissionTicketType) {
                            case 1:
                                item.illustrateText = '年龄12周岁(含)~64周岁(...';
                                break;
                            case 2:
                                item.illustrateText = '年龄3周岁(含)~11周岁(...';
                                break;
                            case 3:
                                item.illustrateText = '中国内地普通高校在读...';
                                break;
                            case 4:
                                item.illustrateText = '年龄65周岁(含)以上(...';
                                break;

                        }
                    })
                    break;
                case 2:
                    this.intentionMsgVOList.forEach(item => {
                        switch (item.admissionTicketType) {
                            case 1:
                                item.illustrateText = '标准票适用于所有人群。符...';
                                break;
                            case 2:
                                item.illustrateText = '游园当日年龄已满3周岁(...';
                                break;
                            case 3:
                                item.illustrateText = '游园当日年龄已满12周岁(...';
                                break;
                            case 4:
                                item.illustrateText = '年龄65周岁(含)以上(...';
                                break;

                        }
                    })
                    break;
            }

            this.initCommonlyUsedPassenger();//初始化选人弹框数据
            await this.getUserInfo();//获取当前用户信息
            await this.getCommonlyPassenger();//获取常旅客列表

            this.$nextTick(() => {
                const __this = this;
                fixStatusBar({
                    type: `000`,
                    handler({default_handler, api}) {
                        default_handler({
                            element: __this.$refs.navBox,
                        });
                    }
                });
            })
            Toast.clear();
        },

        // 获取个人信息
        async getUserInfo() {
            let res = await get_user_info()
            let {
                realName,
                phone
            } = res.datas;
            this.form.contactsName = realName;
            this.form.contactPhone = phone;
        },

        //获取常旅客信息
        async getCommonlyPassenger() {
            let frequent = {};
            this.frequentList = [];
            this.form.intentionTourists = [];
            let res = await consumer_frequent_getLoginUserFrequents();
            if (res.datas.frequents) {
                res.datas.frequents.forEach((item, index) => {
                    frequent = {
                        id: item.frequentUserId,//游客id
                        touristName: item.frequentPassengerName,//游客姓名
                        touristTypeText: item.touristTypeText,//游客类型
                        cardNo: item.unSensitiveCertificateValue,//证件号
                        cardType: item.certificateType,//证件类型
                        cardTypeText: item.certificateTypeText,//证件类型名称
                        isChecked: false,
                    }
                    if (index < 3) {
                        this.frequentList.push(frequent);
                    }
                })
                this.frequentList[0].isChecked = true;
                this.form.intentionTourists.push(this.frequentList[0]);
                this.frequentIdList.push(this.frequentList[0].id);
            }
        },

        //初始化常旅客组件
        async initCommonlyUsedPassenger() {
            const _this = this;
            this.$refs.aCommonlyUsedPassenger.init({
                type: '100',
                btn_ok_click_event_handler(args) {
                    _this.form.intentionTourists = [];
                    const userList = args.user_list;
                    if (userList) {
                        let frequent = {};
                        _this.frequentList.forEach(item => {
                            item.isChecked = false;
                        })
                        userList.forEach(item => {
                            frequent = {
                                id: item.frequentUserId,//游客id
                                touristName: item.frequentPassengerName,//游客姓名
                                touristTypeText: item.touristTypeText,//游客类型
                                cardNo: item.unSensitiveCertificateValue,//证件号
                                cardType: item.certificateType,//证件类型
                                cardTypeText: item.certificateTypeText,//证件类型名称
                                isChecked: true,
                            }
                            _this.form.intentionTourists.push(frequent);

                            let traveler = _this.frequentList.find(i => {
                                return i.id === frequent.id;
                            })

                            if (traveler) {
                                traveler.isChecked = true;
                            } else {
                                if (_this.frequentList.length < 3) {
                                    _this.frequentList.push(frequent);
                                }
                            }
                        })

                        let idList = [];
                        _this.form.intentionTourists.forEach(item => {
                            idList.push(item.id)
                        })
                        _this.frequentIdList = idList;
                    }
                },
            })
        },

        //选择日期
        onConfirmDate(val) {
            this.form.departTime = this.$moment(val).format('YYYY-MM-DD')
            this.showPicker = false;
        },

        //选择游客
        onSelectedFrequent(traveler) {
            let travelerList = [];
            this.form.intentionTourists = [];
            this.frequentIdList = [];
            traveler.isChecked = !traveler.isChecked;
            this.frequentList.forEach(item => {
                if (item.isChecked === true) {
                    travelerList.push(item)
                }
            })
            if (travelerList) {
                travelerList.forEach(item => {
                    this.form.intentionTourists.push(item);
                    this.frequentIdList.push(item.id);
                })
            }
        },

        //删除已选游客
        onDelTraveler(traveler, index) {
            traveler.isChecked = !traveler.isChecked;
            let frequent = this.frequentList.find(item => {
                return item.id === traveler.id;
            })
            if (frequent) {
                frequent.isChecked = false;
            }
            this.form.intentionTourists.splice(index, 1);
            this.frequentIdList.splice(index, 1);
        },

        //编辑游客信息
        onEditTraveler(index) {

        },

        //显示选择游客弹框
        onShowTravelerPopup() {
            this.$refs.aCommonlyUsedPassenger.showPopup();
        },

        //跳转至意向单列表
        toIntentionList() {
            this.$router.push({
                name: 'h5-advert-intention-list'
            });
        },

        //返回
        onBack() {
            this.$router.go(-1);
        },

        //提交预订
        async onBook() {
            if (!this.form.departTime) {
                Toast({
                    message: '请选择游园日期',
                    position: 'center',
                });
                return
            }

            let ticketObj = {};
            this.form.intentionMsgVOList = [];
            this.intentionMsgVOList.forEach(item => {
                if (item.intentionNum > 0) {
                    ticketObj = {
                        admissionTicketType: item.admissionTicketType,
                        intentionNum: item.intentionNum,
                    }
                    this.form.intentionMsgVOList.push(ticketObj);
                }
            })
            if (this.form.intentionMsgVOList.length === 0) {
                Toast({
                    message: '请至少预订一张票',
                    position: 'center',
                });
                return
            }

            if (!this.form.contactsName || !this.form.contactPhone) {
                Toast({
                    message: '请输入联系人信息',
                    position: 'center',
                });
                return
            }

            let res = await consumer_intention_order_submit(this.form);
            Toast({
                message: '提交意向单成功',
                position: 'bottom',
            });
            this.$router.replace({
                name: 'h5-home',
            })
        },

        //物理键返回
        keyback() {
            const __this = this;

            keyback_processor.addEventListener(function () {
                const showPicker = __this.showPicker;
                if (showPicker) {
                    __this.showPicker = false;
                    return;
                }

                const showIllustrate = __this.showIllustrate;
                if (showIllustrate) {
                    __this.showIllustrate = false;
                    return;
                }

                const aCommonlyUsedPassenger = __this.$refs.aCommonlyUsedPassenger;
                const aCommonlyUsedPassengerShow = aCommonlyUsedPassenger.getShow();
                if (aCommonlyUsedPassengerShow) {
                    aCommonlyUsedPassenger.hidePopup();
                    return;
                }

                __this.onBack()
            });
        }
    }
}

