// consumer_apply_addBusinessDemand 收集问卷接口
const __request = require(`./__request/__request_contentType_json`);

const consumer_apply_addBusinessDemand = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/company/addBusinessDemand',
        data: pParameter,
        check_token: pParameter.check_token !== undefined ? pParameter.check_token : false,
        enable_error_alert: pParameter.enable_error_alert !== undefined ? pParameter.enable_error_alert : true
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_apply_addBusinessDemand;