import Q from 'q';
import _ from 'underscore';
import {Toast} from 'vant';

import mock_data_helper from './lib/mock_data_helper';

const __page_size__ = 5;

export default {
    data() {
        return {
            entity_list: [],
            page_index: 1,

            van_pull_refresh: {
                refreshing: false,
            },

            van_list: {
                loading: false,
                loading_text: `加载中...`,
                finished: false,
                finished_text: `全部数据加载完成`,
            },
        };
    },
    watch: {
        'van_list.loading'(pNewValue, pOldValue) {
            const __this = this;
            return;

            // if (pNewValue) {
            //     __this.__show_loading();
            // } else {
            //     __this.__hide_loading();
            // }

            if (pNewValue) {
                Toast({
                    message: `数据加载开始`,
                    duration: 1000,
                });
            } else {
                Toast({
                    message: `数据加载完成`,
                    duration: 1000,
                });
            }

        },
    },
    methods: {
        __show_loading() {
            const __this = this;
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
        },

        __hide_loading() {
            const __this = this;
            Toast.clear();
        },

        __load_data() {
            const __this = this;
            const page_index = __this.page_index;

            __this.van_list.loading = true;
            __this.van_list.finished = false;

            const loading_entity_list = [];
            for (let i = 0; i < __page_size__; i++) {
                loading_entity_list.push({
                    entity_type: `loading-entity`,
                    title: `加载中...`,
                });
            }

            // 如果是下拉刷新全部，不是上拉加载更多
            if (!__this.van_pull_refresh.refreshing) {
                __this.entity_list = __this.entity_list.concat(loading_entity_list);
            }

            return Q.when()
                .then(function () {
                    return mock_data_helper.get_pager_data({
                        page_index,
                    });
                })
                .then(function (data) {

                    // 如果是下拉刷新全部
                    if (__this.van_pull_refresh.refreshing) {
                        __this.entity_list = [];
                    }

                    const entity_list = data.entity_list;
                    const page_index = data.page_index;
                    const page_count = data.page_count;

                    console.log(`__load_data:加载了第${page_index}页的数据，共${entity_list.length}条数据`);

                    // 有问题
                    // __this.entity_list = __this.entity_list.filter(function (m) {
                    //     const entity_type = m.entity_type;
                    //     return entity_type === ``;
                    // });
                    // __this.entity_list = __this.entity_list.concat(entity_list);

                    // 有问题
                    // __this.entity_list = _.chain(__this.entity_list)
                    //     .filter(function (m) {
                    //         const entity_type = m.entity_type;
                    //         return entity_type === ``;
                    //     })
                    //     .value()
                    // ;
                    // __this.entity_list = __this.entity_list.concat(entity_list);

                    // 如果是上拉加载更多
                    if (!__this.van_pull_refresh.refreshing) {
                        const first_loading_entity = loading_entity_list[0];
                        const first_loading_entity_index = _.indexOf(__this.entity_list, first_loading_entity);

                        let index = first_loading_entity_index;
                        _.each(entity_list, function (entity) {
                            __this.$set(__this.entity_list, index++, entity);
                        });

                        //删除多余的loading-entity
                        const query_entity_list = _.chain(__this.entity_list)
                            .filter(function (m) {
                                const entity_type = m.entity_type;
                                return entity_type === `loading-entity`;
                            })
                            .value()
                        ;
                        if (query_entity_list.length > 0) {
                            const first_query_entity = query_entity_list[0];
                            const first_query_entity_index = _.indexOf(__this.entity_list, first_query_entity);
                            if (first_query_entity_index > -1) {
                                __this.entity_list.splice(first_query_entity_index, query_entity_list.length);
                            }
                        }

                    }

                    // 如果是下拉刷新全部
                    if (__this.van_pull_refresh.refreshing) {
                        __this.entity_list = __this.entity_list.concat(entity_list);
                        __this.van_pull_refresh.refreshing = false;
                    }

                    __this.van_list.loading = false;

                    if (page_index >= page_count) {
                        // 当前请求回来的数据，已经是最后一页了
                        __this.van_list.finished = true;
                    } else {
                        // 当前请求回来的数据，不是最后一页
                        __this.page_index = __this.page_index + 1;
                    }

                    // 参考：https://vant-contrib.gitee.io/vant/#/zh-CN/list
                    // loading 和 finished 分别是什么含义？
                    // List有以下三种状态，理解这些状态有助于你正确地使用List组件：
                    // 非加载中，loading为false，此时会根据列表滚动位置判断是否触发load事件（列表内容不足一屏幕时，会直接触发）
                    // 加载中，loading为true，表示正在发送异步请求，此时不会触发load事件
                    // 加载完成，finished为true，此时不会触发load事件
                    // 在每次请求完毕后，需要手动将loading设置为false，表示加载结束
                })
                .catch(function (ex) {
                    __this.van_list.loading = false;
                    __this.van_list.finished = true;
                    __this.van_pull_refresh.refreshing = false;
                    __this.__hide_loading();

                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })
                ;
        },

        __van_list_load_event_handler() {
            const __this = this;
            __this.__load_data();
        },

        __van_pull_refresh_refresh_event_handler() {
            const __this = this;
            __this.page_index = 1;
            __this.__load_data();
        },

        __van_cell_click_event_handler(pEntity) {
            const __this = this;
            const id = pEntity.id;
            if (!id) return;

            __this.$router.push({
                name: `demo-sample-data-detail`,
                query: {
                    id,
                }
            });
        },

        //base_layout_header，是否显示返回按钮
        __base_layout_header_get_btn_go_back_show() {
            return true;
        },

        //base_layout_header，返回按钮单击事件方法
        __base_layout_header_btn_go_back_click_event_handler() {
            const __this = this;
            console.log(`__base_layout_header_btn_go_back_click_event_handler`);
            __this.$toast(`自定义返回按钮的单击事件！`);
        },
    },
    mounted() {
        const __this = this;
    },
};

// TODO:优化代码
// TODO:2）错误处理，例如某一页返回不是5条数据，网络请求异常

