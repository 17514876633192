import consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2
    from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2'
import TrainInstructions from '../../../../../travel-book/train/train-reserve/commponent/train-instructions/index'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import moment from "moment";
import {Toast} from 'vant'
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
// 车次时刻表显示组件
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";

export default {
    data() {
        return {
            explainShow: false,
            trainData: {
                trainInfo: {
                    startDate: ''
                },
                bookingInfo: {
                    evectionTypeText: ''
                },
                receiveOption: {
                    serviceFee: 0
                }
            }
        }
    },
    components: {
        NavBar,
        TrainTimeTable,
        TrainInstructions
    },
    activated() {
        this.init();

        let __this = this;

        try {
            let show_explainShow = __this.explainShow;
            if (show_explainShow) {
                __this.hideExplain();
                return;
            }
        } catch (err) {

        }

        keyback_processor.addEventListener(function () {
            const trainTimeTable = __this.$refs['aTrainTimeTable'];
            if (trainTimeTable.get_show()) {
                trainTimeTable.hide();
                return
            }

            __this.goBack();
        })
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    filters: {
        format_date(val) {
            if (val) {
                return moment(val).format("YYYY-MM-DD HH:mm:ss");
            } else {
                return val
            }
        },
    },
    methods: {
        //初始化
        init() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            let refundNo = this.$route.query.refundNo;
            consumer_t_od_trains_order_refund_getTrainsReturnOrderDetailV2({refundNo}).then(res => {
                this.trainData = res.datas;

                //初始化时刻表组件
                this.$refs['aTrainTimeTable'].init({
                    //出发站三字码
                    fromStationCode: this.trainData.fromStationCode,
                    //到达站三字码
                    toStationCode: this.trainData.toStationCode,
                    //车次号
                    trainCode: this.trainData.trainInfo.trainNo,
                    //日期(2020-05-18)
                    trainDate: this.trainData.trainInfo.startDate,
                })

                Toast.clear();
            })
        },

        //显示时刻表
        showTrainTimeTable() {
            this.$refs['aTrainTimeTable'].show();
        },

        //跳转至出差单详情页
        toEvectionDetail() {
            this.$router.push({
                name: 'h5-approve-detail',
                query: {
                    id: this.trainData.applyId,
                    approveType: 1,
                    type: 'detail',
                }
            })
        },

        //跳转至审批详情页
        toApprovalDetail() {
            this.$router.push({
                name: 'h5-approve-detail',
                query: {
                    id: this.trainData.applyId,
                    approveType: 9
                }
            })
        },

        //返回
        goBack() {
            if (this.$route.query.jumpType) {
                if (this.$route.query.jumpType == 'list') {
                    this.$router.push({
                        name: 'h5-train-order-list',
                        query: {
                            type: 3
                        }
                    });
                }
            } else {
                this.$router.go(-1)
            }
        },
        hideExplain(){
            this.explainShow = false;
        },
        // 说明click
        clickExplain(){
            this.explainShow = true;
        },
    }
}