import NavBar from "@/component/nav-bar/import_latest_version_module";

export default {
    data() {
        return {
            upLevelInfoShow: true,
            upLevelInfoDetailShow: false,
            productEquityDescribeDetail: [],
        }
    },
    props: {
        productInfo: {
            type: Object,
            default: ()=>{}
        },
        personNum: {
            type: Number,
            default: 0,
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        showMoreUpLevelInfo() {
            this.upLevelInfoShow = !this.upLevelInfoShow
        },
        openUpLevelDetail() {
            this.productEquityDescribeDetail = JSON.parse(this.productInfo.productEquityDescribeDetail);
            this.upLevelInfoDetailShow = true;
        },
    }
}
