/**
 * 初始化方法
 * init
 * 入参
 * params {
 *     userIds: [], // 出行人Id列表
 *     cityId: '', // 城市ID
 *     cityName: '', // 城市名称
 * }
 * **/

import NavBar from "@/component/nav-bar/import_latest_version_module";
// 获取差标新
import consumer_web_policyConfig_hotelTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_hotelTravelPolicy'
export default {
    data() {
        return {
            loading: false,
            params: {},
            cityName: '',
            evectionRulePopupShow: false,
            policyDetail: null,
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        init(initParams) {
            this.params = Object.assign({}, initParams);
            console.log(this.params);
            // this.cityName = initParams && initParams.cityName ? initParams.cityName : '';
            if (this.params && this.params.cityName) {
                this.cityName = this.params.cityName
            } else {
                this.cityName = '';
            }
            this.getPolicy();
            this.show();
        },
        getPolicy() {
            this.loading = true;
            this.$showLoading.show();
            consumer_web_policyConfig_hotelTravelPolicy(this.params).then((res)=>{
                this.policyDetail = res.datas || {};
            }).finally(()=>{
                this.loading = false;
                this.$showLoading.hide();
            });
        },
        show() {
            this.evectionRulePopupShow = true;
        },
        hide() {
            this.evectionRulePopupShow = false;
        },
        isShow() {
            return this.evectionRulePopupShow;
        },
    }
}
