export default {
    data() {
        return {}
    },
    props: {
        policyType: {
            type: Number,
            default: ''
        },
        policyTypeText: {
            type: String,
            default: ''
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        setTypeLabelColor(val) {
            /*{
                "supplier": "1,2,3,4,12,13,9,OPL9,OPL6,OPL11,OPL18,OPL20,OPL16,60,FD,NFD,VARI",
                "supplierText": "平台优选",
                "bigCustomers": "QCYX",
                "bigCustomersText": "企业协议",
                "lowPrice": "YPL1,FPL1,BPL1,OPL4,OPL3,OPL5,OPL25,SPL1,OPL21,OPL15,OPL13,OPL14,OPL34,OPL1,SGL1,OPL26,OPL30",
                "lowPriceText": "低价推荐",
                "website": "21,22,23,24,25,31,6,CXF1,WS,GW311,GW312,OPL17,OPL23",
                "websiteText": "航司官网",
                "platformProprietary": "61",
                "platformProprietaryText": "商旅推荐"
            }*/
            /*const btoList = ['1','2','3','4','12','13', '9', 'OPL9','OPL6','OPL11','OPL18','OPL20','OPL16','60','FD','NFD','VARI']; // 平台优选
            const owList = ['21','22','23','24','25','6', '31', 'CXF1','WS', 'GW311','GW312','OPL17','OPL23']; // 航司官网
            const qcList = ['QCYX']; // 企业协议
            const ptList = ['61']; // 商旅推荐
            const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL14','OPL34','OPL1','SGL1','OPL26','OPL30']; // 低价推荐
            if (btoList.indexOf(val) > -1) {
                return 'type1'
            } else if (lprList.indexOf(val) > -1) {
                return 'type8'
            } else if (owList.indexOf(val) > -1) {
                return 'type4'
            } else if (qcList.indexOf(val) > -1) {
                return 'type2'
            } else if (ptList.indexOf(val) > -1) {
                return 'type5'
            } else {
                return 'type6'
            }*/

            // 1-平台优选 2-航司官网 3-企业协议 4-商旅推荐 5-低价推荐
            if (val === 1) {
                return 'type1'
            } else if (val === 2) {
                return 'type4'
            } else if (val === 3) {
                return 'type2'
            } else if (val === 4) {
                return 'type5'
            } else if (val === 5) {
                return 'type8'
            } else {
                return 'type6'
            }
        },
    }
}
