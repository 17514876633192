import moment from "moment";
import ColorIcon from "@/component/color-icon/index.vue";
// 列表为空展示组件
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";
import Search from '@/page/personal-center/order/components/flightListSearch/1.0.0/index.vue'
// 我的申请列表
import req_myApplyList_datas from "@/lib/data-service/haolv-default/consumer_apply_myApplyList.js";
// 我的申请列表-新审批流用
import consumer_apply_workflowMyApplyList from '@/lib/data-service/haolv-default/consumer_apply_workflowMyApplyList';
// 我的审批列表
import req_approveList_datas from "@/lib/data-service/haolv-default/consumer_apply_approveList.js";
// 我的审批列表-新审批流用
import consumer_apply_workflowApproveList from '@/lib/data-service/haolv-default/consumer_apply_workflowApproveList';
// 撤销申请
import req_repealApply from "@/lib/data-service/haolv-default/consumer_apply_repealApply.js";
// 撤销申请
import consumer_apply_deleteApply from "@/lib/data-service/haolv-default/consumer_apply_deleteApply.js";

import NavBar from "@/component/nav-bar/import_latest_version_module";
import VirtualList from 'vue-virtual-scroll-list'

import Q from 'q';
import ItemComponent from './component/Item'
import RevokeModal from "@/page/office/approve-detail/1.0.5/components/process/RevokeModal.vue";

export default {
    components: {
        RevokeModal,
        ListEmpty,
        NavBar,
        ColorIcon,
        Search,
        'virtual-list': VirtualList,
    },
    props: {
        // approve我的审批列表待(不显示撤销和删除操作按钮)，apply我的申请列表(显示撤销和删除操作按钮)
        type: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            netErr: false,
            ItemComponent: ItemComponent,

            searchText: '',
            placeholder: '姓名/城市/申请单号',
            loading: false,
            // 是否已加载全部数据
            finished: false,
            // 为空时的显示图标 空字符， search，network
            emptyType: "",
            approveTypeForm: [],
            form: {
                currentPage: 1,
                //审批状态 1.未审核，2.已审批 ,3已失效
                approveStatus: null,
                // 审批类型：1.出差申请，2报销申请，3.超标申请,4.改签，5.退票
                approveType: "",
                // 查询类型：1.我申请，2.我参与
                selectType: "",
                // 关键词搜索
                searchValue: "",
            },

            // 列表数据
            list: [],
            requestType: false,
            isFirst: true,

            totalPage: 0,
            totalCount: 0,

            selectVisible: false,

            applyTypeList: [
                {label: "全部", value: ""},
                {label: "出差单", value: "1"},
                {label: "超标单", value: "2"},
                {label: "审批单", value: "7"},
                {label: "改签单", value: "4"},
                {label: "退订单", value: "5"},
            ],

            approveStatus: [
                {text: '待我审批', value: 1},
                {text: '已审批', value: 2},
                {text: '已失效', value: 3},
            ],

            selectType: [
                {text: '我的申请', value: 1},
                {text: '我的参与', value: 2},
            ],

            modalConfig: {
                revokeVisible: false,
            },
            currentProcessInfo: {},
        };
    },
    computed: {
        finished_text() {
            const __this = this;
            const list = __this.list;
            if (list.length <= 0) {
                return ``;
            }

            return `全部数据加载完成`;
        },
    },
    mounted(){
        this.form.searchValue = '';
        // if (this.$route.query.emptySearch) {
        //     if (this.$route.query.emptySearch == 'true') {
        //         this.form.searchValue = '';
        //     }
        // }
    },
    methods: {
        getScroll(event){
            // 滚动条距离底部的距离scrollBottom
            let scrollBottom =
              event.target.scrollHeight -
              event.target.scrollTop -
              event.target.clientHeight;
            if (scrollBottom <= 0 && !this.finished) {
                console.log('cfs');
                this.onLoad(event);
            }
        },
        getTimeDay(startDate,endDate){
            if (startDate != null && endDate != null) {
                return (new Date(endDate)-new Date(startDate))/(1000*60*60*24)
            }

        },
        req_list_search(evectionNo) {
            const type = this.type;
          let data = {
            approveStatus: type === 'approve' ? this.form.approveStatus : this.form.selectType,
            approveType: '',
            currentPage: 1,
            searchValue: '',
            selectType: 1,
            evectionNo: evectionNo,
          };
          this.$showLoading.show();

          let myRequest = (myMethod) => {
            myMethod(data)
              .then((res) => {
                let list = res.datas.list;
                list.forEach((item) => {
                  if (item.recordList && item.recordList.length) {
                    const record = item.recordList[0];
                    item.lastNodeApprover = record.approverUserName;

                    item.lastNodeTime = moment(record.gmtCreate).format(
                      "MM-DD hh:mm"
                    );

                    switch (record.approveStatus) {
                      case 0:
                        item.lastNodeStatus = "未开始";
                        break;
                      case 1:
                        item.lastNodeStatus = "待审批";
                        break;
                      case 2:
                        item.lastNodeStatus = "已审批通过";
                        break;
                      case 3:
                        item.lastNodeStatus = "已审批拒绝";
                        break;
                      case 4:
                        item.lastNodeStatus = "已失效";
                        break;
                    }
                  } else {
                    item.lastNodeApprover = `发起人：${item.applyName}`;
                    item.lastNodeStatus = "";
                    item.lastNodeTime = "";
                  }
                });
                this.list.forEach((item,index) => {
                  if (item.evectionNo == evectionNo) {
                    this.list.splice(index, 1, list[0]);
                  }
                });
                this.$showLoading.hide();
              })
              .catch((err) => {
                this.$showLoading.hide();
              });
          };

          // 审批列表
          if (type === "approve") {
            myRequest(this.$store.state.workflow === '1' ? consumer_apply_workflowApproveList : req_approveList_datas);
            // 申请列表
          } else if (type === "apply") {
            myRequest(this.$store.state.workflow === '1' ? consumer_apply_workflowMyApplyList : req_myApplyList_datas);
          }
        },
        // 请求列表
        req_list(evectionNo) {
            // console.log(this.form)
            // 超标审批后再次跳回列表页，会调两次列表接口，第一次入参approveStatus会为null。第二次正常，但是第一次的接口时间更长，所以最后显示的第一次接口的数据。
            // 所以这里判断一下入参approveStatus为null的情况，规避
            // if (!this.form.approveStatus) {
            //     return
            // }
            if (evectionNo) {
              this.req_list_search(evectionNo);
              return;
            }
            const type = this.type;
            this.requestType = false;
            this.loading = true;
            this.netErr = false;
            this.$showLoading.show();
            let myRequest = (myMethod) => {
                myMethod(this.form)
                    .then((res) => {
                        let list = res.datas.list;
                        console.log('list', list);
                        list.forEach((item) => {
                            console.log('222',item);
                            if (item.recordList && item.recordList.length) {
                                const record = item.recordList[0];
                                item.lastNodeApprover = record.approverUserName;

                                item.lastNodeTime = moment(record.gmtCreate).format(
                                    "MM-DD hh:mm"
                                );
                                console.log('4', record.approveStatus)
                                switch (record.approveStatus) {
                                    case 0:
                                        item.lastNodeStatus = "未开始";
                                        break;
                                    case 1:
                                        item.lastNodeStatus = "待审批";
                                        break;
                                    case 2:
                                        item.lastNodeStatus = "已审批通过";
                                        break;
                                    case 3:
                                        item.lastNodeStatus = "已审批拒绝";
                                        break;
                                    case 4:
                                        item.lastNodeStatus = "已失效";
                                        break;
                                }
                            } else {
                                item.lastNodeApprover = `发起人：${item.applyName}`;
                                item.lastNodeStatus = "";
                                item.lastNodeTime = "";
                            }
                        });
                        if (this.form.currentPage === 1) {
                            this.list = list;
                        } else {
                            this.list = this.list.concat(list);
                        }
                        if (!this.list.length) this.emptyType = "search";
                        this.totalCount = res.datas.totalCount;
                        if (this.form.currentPage >= res.datas.totalPage) {
                            this.finished = true;
                        } else {
                            this.form.currentPage += 1;
                        }
                        this.loading = false;
                        this.$showLoading.hide();
                    })
                    .catch((err) => {
                        this.emptyType = "network";
                        this.loading = false;
                        this.$showLoading.hide();
                        this.finished = true;
                        this.netErr = true;
                    });
            };

            // 审批列表
            if (type === "approve") {
                myRequest(this.$store.state.workflow === '1' ? consumer_apply_workflowApproveList : req_approveList_datas);
                // 申请列表
            } else if (type === "apply") {
                myRequest(this.$store.state.workflow === '1' ? consumer_apply_workflowMyApplyList : req_myApplyList_datas);
            }
        },
        search() {
            this.finished = false;
            this.form.currentPage = 1;
            this.req_list();
        },
        refresh() {
            this.search();
        },

        onLoad() {
            if (this.isFirst) return;
            this.req_list();
        },

        init() {
            const type = this.type;
            const form = this.form;
            if (type === "approve") {
                if (!form.approveStatus) form.approveStatus = 1;
                delete form.selectType;
            } else if (type === "apply") {
                if (!form.selectType) form.selectType = 1;
                delete form.approveStatus;
            }
            if (this.$route.name != 'h5-my-apply-list') {
              this.form.approveStatus = Number(this.$route.query.id);
            } else {
              this.form.approveStatus = null;
            }
            // this.req_list();
            this.form.approveType = '';
            this.approveTypeForm = [];
            this.isFirst = false;
            if (this.$route.query.id == 1) {
                this.change_status(1, 'selectType');
            } else if (this.$route.query.id == 2) {
                this.change_status(2, 'selectType');
            } else if (this.$route.query.id == 3) {
                this.change_status(3, 'approveStatus');
            }else {
                this.req_list()
            }
        },

        // 顶部返回按钮
        back() {
            this.$router.replace({
                name: "h5-office-home",
            });
        },
        // 弹出申请类型选择框
        popup_selet() {
            this.selectVisible = true;
        },
        // 关闭弹框
        close_select() {
            this.selectVisible = false;
        },
        // 确认关闭搜索
        affirm_select() {
            this.selectVisible = false;
            this.search();
        },
        change_status_tabs(key) {
            let value = null;
            if (key == 'approveStatus') {
                value = this.form.approveStatus
            }
            if (key == 'selectType') {
                value = this.form.selectType
            }

            this.finished = false;
            const form = this.form;
            form.approveType = "";
            form[key] = value;
            this.list = [];
            this.approveTypeForm = [];
            this.search();

            let href = location.href.split('?')[0];
            let newUrl = href + '?id=' + value;
            history.replaceState(null, null, newUrl)
        },
        // 切换tab
        change_status(value, key) {
            this.finished = false;
            const form = this.form;
            form.approveType = "";
            form[key] = value;
            this.list = [];
            this.search();

            let href = location.href.split('?')[0];
            let newUrl = href + '?id=' + value;
            console.log('newUrl', newUrl);
            history.replaceState(null, null, newUrl)
        },
        // 切换申请类型
        change_apply_type(value) {
            this.form.approveType = value;
            this.approveTypeForm = [value];
        },
        // 撤销按钮
        revocation_item(applyNo) {
            this.$Dialog.confirm({
                title: "标题",
                message: "是否确认撤销",
            })
                .then(() => {
                    req_repealApply({applyNo})
                        .then((res) => {
                            this.$Toast.success("撤销成功");
                            this.list = [];
                            setTimeout(() => {
                                this.req_list(true);
                            }, 1000);
                        })
                        .catch((err) => {
                        });
                })
                .catch(() => {
                });
        },
        // 删除按钮
        delete_item(applyNo) {
            this.$Dialog.confirm({
                title: "提示",
                message: "是否确认删除",
            }).then(() => {
                    this.$Toast.loading({
                        duration: 0,
                        message: "请求中...",
                        forbidClick: true,
                    });
                    consumer_apply_deleteApply({applyNo})
                        .then((res) => {
                            this.$Toast.clear();
                            this.$Toast.success("删除成功");
                            setTimeout(() => {
                                this.req_list(true);
                            }, 1000);
                        })
                        .catch((err) => {
                            this.$Toast.clear();
                        });
                })
                .catch(() => {

                });
        },

        to_detail(id, approveType, applyNo, approveStatus, status, appPoint) {
            // debugger
            let type = "";
            if (this.type === "apply") {
                type = "applyDetail"
            }
            if (this.type === "approve") {
                if (Number(this.form.approveStatus) === 1) {
                    type = "approve"
                } else {
                    type = "approveDetail"
                }
            }
            //// 新版审批流 + 待我审批 + 预订审批 + 审批驳回 + 回到起点
            console.log(this.$store.state.workflow === '1', this.type === 'approve', this.form.approveStatus === 1, approveType === 1, status === 3, appPoint === true)
            if (this.$store.state.workflow === '1' && this.type === 'approve' && this.form.approveStatus === 1 && approveType === 1 && status === 3 && appPoint === true) {
                // 进入编辑
                console.log('进入编辑');
                this.$router.push({
                    name: 'h5-edit-evection',
                    query: {
                        applyNo,
                        type: 'edit',
                    }
                })
                return;
            }
            if (approveType === 2) {
                this.$router.push({
                    name: "h5-apply-approve-exceed",
                    query: {
                        applyId: id,
                        applyNo,
                        // approveType,
                        // 类型：“applyDetail”-申请详情，“approve”-审核，“approveDetail”-审核详情
                        approveStatus: approveStatus || this.form.approveStatus,
                        type: type,
                        selectType: this.type === 'approve' ? this.form.approveStatus : this.form.selectType,

                    }
                });
            }  else {
                this.$router.push({
                    name: "h5-approve-detail",
                    query: {
                        id,
                        applyNo,
                        approveType,
                        approveStatus: approveStatus || this.form.approveStatus,
                        listApproveStatus: this.type === 'approve' ? this.form.approveStatus : this.form.selectType,
                        // approve 有审批操作按钮， approveDetail 我的审批无操作按钮 detail 我的申请无审批操作按钮
                        type: type,
                        selectType: this.type === 'approve' ? this.form.approveStatus : this.form.selectType,
                    },
                });
            }
        },
        // 撤销
        revocation(item) {
            this.currentProcessInfo = {
                processInstanceId: item.applyNo,
                taskId: item.taskId,
                signFlag: null,
                currentUserInfo: {
                    id: this.$store.state.userInfo.id,
                    name: this.$store.state.userInfo.realName,
                },
            };
            this.modalConfig.revokeVisible = true;
        },
        _success() {
            this.search();
        },
    },
    async activated() {
        await this.$store.state.workflowDefer.promise;
        this.init();
    },
    deactivated() {
        this.$Toast.clear();
        this.isFirst = true;

    },
};
