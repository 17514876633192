import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/personal-center',
            name: 'h5-personal-center',
            component: () => import(/* webpackChunkName: "page-personal-center-home" */ `./3.0.3/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '我的',
                    need_head_nav: false,
                    need_head_nav1: false,
                    need_footer_bar: true,

                    secondary_menu: 3,
                },

                data_for_all_routes_processor: {
                    order_index: `050-000`,
                },
            },
        }
    ],
};
