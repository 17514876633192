import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: "",
  component: () => import_latest_version_module(),
  children: [
    {
      path: "/h5/privately-evection",
      name: "h5-privately-evection",
      component: () => import(/* webpackChunkName: "page-evection-evection-order-privately-evection" */ `./3.0.0/index.vue`),
      meta: {
        h5_layout: {
          current_page_name: "我的行程",
          need_head_nav: false,
          need_head_nav1: true,
          need_footer_bar: true,
          secondary_menu: 1,
          evectionMeta: "2",
        },
      },
    },
  ],
};
