import {Toast} from "vant";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import cache from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/cache";
import cache_get from 'nllib/src/cache/get'
import cache_set from 'nllib/src/cache/set'
import CodeInput from '@/page/login/component/code-input/3.0.0/index.vue'
import get_user_info from '@/lib/common-service/get_user_info';
import consumer_fastLogin from '@/lib/data-service/haolv-default/consumer_fastLogin';
import consumer_sys_sms_vcodeSend from '@/lib/data-service/haolv-default/consumer_sys_sms_vcodeSend'
import consumer_phoneLogin from '@/lib/data-service/haolv-default/consumer_phoneLogin'
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import app_loading from "@/lib/common-service/app-loading";
// import push_helper from '@/lib/common-service/apicloud/ajpush_helper';
// import api_ready_helper from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/api_ready_helper";

export default {
    data() {
        return {
            type: 1,
            pageType: 1,
            accountType: 2,
            agreement: false,
            showCodePage: false,
            showAccountPage: false,
            phone: '',
            phoneRemember: '', // 记录上次提交的手机号
            code: ['', '', '', '', '', ''], // 验证码
            eyeType: 1, // 1关，2开
            timeNum: 0,
            countDownFun: '',

            accountForm: {
                username: '',
                password: ''
            },
            accountFocus: false,
            passwordFocus: false,
            accountAgreement: false,

            isError: false,

            showCodeInput: true,
        }
    },
    components: {CodeInput},
    created() {
    },
    mounted() {
        /*let phone = cache_get({key: 'phone'});
        if (phone) {
            this.phone = phone
        }*/
        fixStatusBar({
            type: `100`,
            selector: `.banner`,
        });
        fixStatusBar({
            type: `100`,
            selector: `.code-wrapper`,
        });
        fixStatusBar({
            type: `100`,
            selector: `.account-wrapper`,
        });
        const api = global.api;
        if (api) {
            api.setWinAttr({
                softInputMode: 'pan'
            });
        }
        keybackProcessor.addEventListener(() => {
            if (this.showCodePage) {
                this.backCode()
                return
            }
            if (this.showAccountPage) {
                this.backAccount()
                return
            }
            api.closeWidget()
            /*api.confirm({
                title: '确定要关闭程序吗？',
                buttons: ['确定', '关闭'],
            }, function (ret, err) {
                if (ret.buttonIndex === 1) {
                    api.closeWidget()
                }
            })*/
        });
        app_loading.hide();
    },
    activated() {

    },
    deactivated() {
    },
    destroyed() {
        keybackProcessor.removeEventListener();
        const api = global.api;
        if (api) {
            api.setWinAttr({
                softInputMode: 'auto'
            });
        }
    },
    watch: {},
    computed: {
        canSubmitCode() {
            let submit = false
            const reg = /^1\d{10}$/;
            if (this.agreement && this.phone.length >= 11 && reg.test(this.phone)) {
                submit = true
            }
            return submit
        },
        canSubmitLogin() {
            let submit = false
            if (this.accountAgreement && this.accountForm.username.length > 0 && this.accountForm.password.length > 5) {
                submit = true
            }
            return submit
        },
    },
    filters: {},
    methods: {
        back() {
            this.type = 1
            this.pageType = 1
            this.$refs['content'].style.paddingTop = 0;
        },
        clickLogin() {
            if (this.type === 2) {
                return
            }
            this.type = 2;
            const api = global.api;
            if (api) {
                const bodyWidth = document.body.clientWidth;
                const addPaddingTop = (api.safeArea.top) / (bodyWidth / 100);
                this.$refs['content'].style.paddingTop = addPaddingTop + 'vw';
            }
            setTimeout(() => {
                this.pageType = 2
                this.$nextTick(() => {
                    this.$refs['account'].focus()
                    let dom = document.querySelectorAll('#account')
                    dom[0].focus()
                })
            }, 400)
        },
        toggleAgreement() {
            this.agreement = !this.agreement
        },
        goSever() {
            this.$router.push({
                name: 'service'
            })
        },
        goPolicy() {
            this.$router.push({
                name: 'privacy'
            })
        },
        async submitCode() {
            if (!this.canSubmitCode) {
                return
            }
            // 校验
            const reqPhone = /^1[0-9]{10}$/; // 手机号码 1开头 11位
            if (!reqPhone.test(this.phone)) {
                Toast('请输入正确手机号')
                return
            }
            // 如果手机号码没有改动，并且倒计时没有完结
            if (this.phone === this.phoneRemember && this.timeNum > 0) {
                this.showCodePage = true
                this.$refs['codeInput'].initAll()
                return
            }
            this.phoneRemember = this.phone;
            // 发送短信
            const params = {
                phone: this.phone,
                sence: 'ALIYUN_VCODE_LOGIN_SUCC'
            };
            Toast.loading({
                message: '',
                forbidClick: true,
                duration: 0
            })
            let [err, res] = await awaitWrap(consumer_sys_sms_vcodeSend(params))
            Toast.clear()
            if (err) {
                return
            }
            if (res.code === '000000' && res.datas.code !== 'OK') {
                Toast('验证码发送频繁，请稍后再试')
                this.showCodePage = true
                this.$refs['codeInput'].initAll()
                //this.$refs['codeInput'].getFocus()
                return
            }
            Toast.success({
                message: '验证码已发送成功，请查收',
                confirmButtonColor: '#FF6F21'
            });
            this.showCodePage = true
            this.$refs['codeInput'].initAll()
            //this.$refs['codeInput'].getFocus()
            this.timeNum = 60
            this.countDownFun = setInterval(() => {
                this.timeNum--
                if (this.timeNum <= 0) {
                    clearInterval(this.countDownFun)
                }
            }, 1000)
        },
        backCode() {
            this.type = 2
            this.pageType = 2
            this.showCodePage = false
        },
        async completeCode(val) {
            const params = {
                phoneNum: this.phone,
                validCode: val,
            };
            Toast.loading({
                message: '',
                forbidClick: true,
                duration: 0
            })
            let [err, res] = await awaitWrap(consumer_phoneLogin(params))
            Toast.clear()
            if (err) {
                this.isError = true
                Toast(err.data.msg)
                this.showCodeInput = false
                setTimeout(() => {
                    this.isError = false
                    this.showCodeInput = true
                    this.$nextTick(() => {
                        const codeInput = this.$refs['codeInput']
                        codeInput.initAll()
                    })
                }, 500)

                //codeInput.getFocus()
                return
            }
            const userName = this.phone;
            cache.set_user_name(userName);
            cache_set({key: 'phone', value: userName});
            let result = await get_user_info({
                clear_cache: true,
            })
            this.$router.replace({
                name: `redirect`,
            });
        },
        toAccountPage() {
            this.showAccountPage = true;
            // const reqPhone = /^1[0-9]{10}$/; // 手机号码 1开头 11位
            // if (reqPhone.test(this.phone)) {
            //     this.accountForm.username = this.phone
            // }
            this.accountForm.username = this.phone;
            // this.accountAgreement = this.agreement // 不能勾选一致，安卓市场不通过
            this.$nextTick(()=>{
                this.$refs.account2.focus();
            })
        },
        backAccount() {
            // this.type = 1
            // this.pageType = 1;
            this.showAccountPage = false;
            this.phone = this.accountForm.username;
            // this.agreement = this.accountAgreement // 不能勾选一致，安卓市场不通过
        },
        setAccountFocus() {
            this.accountFocus = true
        },
        blurAccountFocus() {
            this.accountFocus = false
        },
        setPasswordFocus() {
            this.passwordFocus = true
        },
        blurPasswordFocus() {
            this.passwordFocus = false
        },
        toggleAccountAgreement() {
            this.accountAgreement = !this.accountAgreement
        },
        toggleAccountType() {
            // this.accountType = this.accountType === 1 ? 2 : 1
        },
        async submitLogin() {
            if (!this.canSubmitLogin) {
                return
            }
            // 校验
            // const reqPhone = /^1[0-9]{10}$/; // 手机号码 1开头 11位
            // if (!reqPhone.test(this.accountForm.username)) {
            //     Toast('请输入正确手机号')
            //     return
            // }
            let params = JSON.parse(JSON.stringify(this.accountForm))
            Toast.loading({
                message: '',
                forbidClick: true,
                duration: 0
            })
            let [err, res] = await awaitWrap(consumer_fastLogin(params))
            Toast.clear()
            if (err) {
                Toast(err.data.msg)
                return
            }
            const userName = this.accountForm.username;
            cache.set_user_name(userName);
            cache_set({key: 'phone', value: userName});
            let result = await get_user_info({
                clear_cache: true,
            })
            this.$router.replace({
                name: `redirect`,
            });
        },
        clearPhone() {
            this.phone = ''
        },
        clearUsername() {
            this.accountForm.username = ''
        },
        toggleEyeType() {
            this.eyeType = this.eyeType === 1 ? 2 : 1
        },
    }
}
