import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import api_ready_helper from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/api_ready_helper';

import aRouter from '@/router';

let __event_handler;

const addEventListener = function (pParameter) {
    let handler;
    if (_.isFunction(pParameter)) {
        handler = pParameter;
    } else {
        if (!pParameter) pParameter = {};
        handler = pParameter.handler;
    }

    __event_handler = function (ret, err) {
        if (_.isFunction(handler)) {
            handler(ret, err);
            return;
        }

        aRouter.back(-1);
    };

    return Q.when()
        .then(function () {
            return api_ready_helper.get_api_ready_promise();
        })
        .then(function () {
            const api = global.api;
            api.addEventListener({name: `keyback`}, __event_handler);
        })
        .catch(function (ex) {
            if (!ex) return Q.reject(ex);
            const enable_log_error = !ex.disable_log_error;
            if (!enable_log_error) return Q.reject(ex);
            console.error(ex);
            console.trace();
            if (ex.stack) console.error(ex.stack);
            return Q.reject(ex);
        })
        ;
};

const removeEventListener = function () {
    return addEventListener(function () {
        aRouter.go(-1)
    });
};

const mock_key_back = function () {
    console.log(`mock_key_back:模拟物理键的返回按钮`);
    __event_handler();
};

global.mock_key_back = mock_key_back;

export default {
    addEventListener,
    removeEventListener,
}
