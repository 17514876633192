import import_latest_version_module from '@/component/base-layout/import_latest_version_module';

export default {
    path: '/demo/login',
    name: 'demo-login',
    component: () => import(/* webpackChunkName: "page-demo-login" */ `./index.vue`),
    meta: {
        enable_check_token: false,
    },
};
