import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/travel-loading-train',
            name: 'h5-travel-loading-train',
            component: () => import(/* webpackChunkName: "page-travel-book-train-travel-loading-success" */ `./1.0.0/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '订单支付',
                    need_alive: true,
                    need_head_nav: false,
                    need_footer_bar: false,
                    secondary_menu: 0,
                },

                data_for_all_routes_processor: {
                    order_index: `013-023`,
                },
            },
        }
    ],
};
