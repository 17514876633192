import _ from 'underscore';
import Q from 'q';
import moment from 'moment';
import {Dialog} from 'vant';
import wait_util from 'nllib/src/common/wait_util';
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
// 酒店房型
import consumer_hotel_search_getRoomPackageInfo
    from "@/lib/data-service/haolv-default/consumer_hotel_search_getRoomPackageInfo.js";
// 出差单员工
import consumer_journey_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail.js";
import consumer_hotel_search_getHotelInfoAndImage
    from "@/lib/data-service/haolv-default/consumer_hotel_search_getHotelInfoAndImage.js";
import CheckTravel from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/check-travel/1.0.1/index.vue'
// 酒店创建预订单
import consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness
    from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness.js";
//酒店因私创建预订单
import consumer_t_od_hotel_order_interim_addOrUpdateOnPerson
    from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_addOrUpdateOnPerson.js";
import go_back_processor from 'yinzhilv-js-sdk/frontend/common-project/mixin/yinzhilv/go_back_processor/1.1.1'; //返回方法
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
import HotelDetailSkeleton from '@/component/skeleton/hotel-detail-skeleton/index.vue'
import getEvectionAllType
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.1.1' //获取出差类型
import getJourneyOptionsCanBeModified
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
import TravelPopup from '@/component/travel-popup/1.0.2/index.vue'
import roomDetailPopup from '@/component/hotel-room-detail-popup/1.0.0/index.vue';// 房型详情弹框
import HotelPolicyPopup from '@/component/hotel-policy-popup/1.0.0/index.vue';// 差标弹窗组件
import consumer_hotel_search_checkHotelPolicy
    from "@/lib/data-service/haolv-default/consumer_hotel_search_checkHotelPolicy";//校验酒店差标
import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import aes_decrypt from 'nllib/src/common/aes/aes_decrypt';
import aes_encrypt from "nllib/src/common/aes/aes_encrypt";
import consumer_journey_getEvectionDetailV2
    from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetailV2";
import {Toast} from "vant";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import {hidePolicyLabelCompanyIdList} from "@/lib/ohter/index.js";
import HotelAlbumPopup from '@/component/hotel-album-popup/1.0.0/index.vue'
import HotelAboutPopup from '@/component/hotel-about-popup/1.0.0/index.vue'
import cache_set from "nllib/src/cache/set";
import cache_get from "nllib/src/cache/get";

// 创建最新超标申请单,旧会被删除

export default {
    mixins: [
        go_back_processor
    ],
    data() {
        return {
            loadingShow: true,
            difference: '',//记录修改出差单情况
            isShowErrorDialog: false,
            errorText: '',
            companyId: 0,
            isShowStandard: true,
            hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
            sticky: false,
            isFixedFilterCard: false,
            workTravelType: '',
            evectionDetailType: '',
            detail: {
                evectionType: 2
            },
            recommendList: [],
            recommendListCopy: [],//多保存一份推荐房型，用于是否符合差标筛选
            info: {
                imageUrl: '',
                hotelName: '',
                phone: '',
                cityName: '',
                cityCn: '',
                hotelAddr: '',
                nightDay: 1,
                list: null,
                longitude: '',
                latitude: '',

            },
            hotelStaticInfo: {},
            sourceList: [],

            travellerList: [],
            travellerIdList: [],
            userIdsWhetherShared: [],//合住人id list

            searchForm: {
                checkInDate: "",
                checkOutDate: "",
                hotelId: "",
            },

            // 预定表单
            reserveForm: {
                cityCn: "",
                cityName: "",
                // 入住时间
                checkInDate: "",
                // 离店时间
                checkOutDate: "",
                // 城市id
                cityId: "",
                // 出差单号
                evectionNo: "",
                // 酒店地址
                hotelAddr: "",
                // 酒店id
                hotelId: "",
                // 酒店名称
                hotelName: "",
                // 房型id
                hotelRoomTypeId: "",
                // 房型名称
                hotelRoomTypeName: "",
                keyId: "",
                // 最晚到店时间
                latestArrivalTime: "",
                hotelPicUrl: "",
                // 订单号
                orderNo: "",
                // 价格
                price: "",

                coseList: "",
                // 入住晚数
                stayNights: "",
                // 间夜信息
                nightlyRateList: null,
                // 第三方id
                thirdpartyHotelId: "",
                breakfast: '',
                journeyId: 0,

                countryName: '中国',//国家名称
                stateName: '',//省份名称
                areaName: '',//区县名称
                windowName: '',//窗户说明
                breakfastName: '',//早餐说明
                overproofApplyList: [],

                groupName: '',//酒店集团
                negotiatedPriceText: '',//协议价

                comfirmType: 0,//是否立即确认
                bookingRule: '',//预定规则
                refundRule: '',//退订规则
                invoiceMode: 1,
                invoiceModeText: '',

            },
            latestArrivalTime: "",
            lastDateVisible: false,

            paddingTop: 0,
            paddingTopPx: 0,
            paddingBottom: 0,

            evectionStartDate: '',
            evectionEndDate: '',
            timeCanEdit: true,

            titleFixed: false,
            scrollT: 0,
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            currentKeyId: null,//当前选中的产品id

            detailBoxScrollTop: 0,

            evectionDetail: null, // 出差单详情

            scrollTopIndex: 0,
            detailBoxScrollTopList: [],

            netErr: false, // 网络是否异常

            showNotBookDialog: false,
            minDate: this.$moment().format('YYYY-MM-DD'),
            maxDate: this.$moment('2100-01-01').format('YYYY-MM-DD'),
            today: '',//今天-'YYY-MM-DD'
            yesterday: '',//昨天-'YYY-MM-DD'
            isMorning: false,//当前时间是否为凌晨00:00-06:00
            isTodayMorning: false,//是否今天凌晨入住
            isUpdateAccord: false,//返回列表时，是否需要刷新差标
            unlimitedJourney: 0,
        };
    },
    methods: {
        //初始化
        async init(obj) {
            this.sticky = false;
            this.loadingShow = true;
            this.$showLoading.show();
            await this.getUserInfo();
            await this.initForm(obj);
            if (this.detail.evectionType === 2) {
                if (this.$route.query.params) {
                    const key = 'params';
                    const params = aes_decrypt({
                        data: this.$route.query.params,
                        key,
                    });
                    this.currentKeyId = JSON.parse(params).currentKeyId;
                }
                await this.getEvectionDetail();
            }
            await this.initInfo(obj);
            await this.refundChangePolicyGet();
            await this.getEvectionType();
            if (Number(this.detail.evectionType) === 1) {
                await this.getHotelInfoAndImage();
            }
        },

        //凌晨时进入预订页面需要做的事情
        initDateForMorning() {
            this.today = this.$moment().format('YYYY-MM-DD');
            this.yesterday = this.$moment().add(-1,'days').format('YYYY-MM-DD');
        },

        //获取用户信息
        async getUserInfo() {
            let res = await get_user_info();
            this.companyId = res.datas.companyId;
            this.isShowStandard = _.indexOf(hidePolicyLabelCompanyIdList, this.companyId) > -1 ? false : true;
        },

        //从地址栏初始化页面数据
        async initForm(obj) {
            this.detail = this.$route.query;
            const {
                hotelId,
                cityId,
                cityName,
                cityCn,
                keyword,
                applyId,
                journeyId,
                journeyType,
                evectionNo,
                evectionType,
                workTravelType,
                evectionRequired,
                evectionDetailType,
                evectionStartDate,
                evectionEndDate,
                hotelAddr,
                hotelName,
                imageUrl,
                orderNo,
                hotelStar,
                star,
                phone,
                d,
                sort,
                areaId,
                areaType,
                areaName,
                lineCode,
                stationValue,
                minPrice,
                maxPrice,
                hotelBrand,
                agreementEnable,
                differenceEnable,
                shareEvectionNo,
                shareJourneyId,
            } = this.detail;

            this.detail.evectionType = parseInt(evectionType);
            this.evectionDetailType = evectionDetailType;
            const checkInDate = !obj ? this.detail.checkInDate : obj.checkInDate;
            const checkOutDate = !obj ? this.detail.checkOutDate : obj.checkOutDate;
            this.evectionStartDate = evectionStartDate;
            this.evectionEndDate = evectionEndDate;

            let searchForm = this.searchForm;
            searchForm.checkInDate = checkInDate;
            searchForm.checkOutDate = checkOutDate;
            searchForm.hotelId = hotelId;

            let form = this.reserveForm;
            form.applyId = applyId;
            form.evectionStartDate = evectionStartDate;
            form.evectionEndDate = evectionEndDate;
            form.checkInDate = checkInDate;
            form.checkOutDate = checkOutDate;
            form.hotelId = hotelId;
            form.cityId = cityId;
            form.cityName = cityName;
            form.cityCn = cityCn;
            form.keyword = keyword;
            form.evectionNo = evectionNo;
            form.evectionType = evectionType;
            form.workTravelType = workTravelType;
            form.evectionDetailType = evectionDetailType;
            form.evectionRequired = evectionRequired;
            form.hotelAddr = hotelAddr;
            form.hotelName = hotelName;
            form.hotelPicUrl = imageUrl;
            form.orderNo = orderNo || '';
            form.hotelStar = hotelStar;
            form.star = star;
            form.hotelPhone = phone;
            form.journeyId = journeyId || 0;
            form.journeyType = journeyType || 0;
            form.d = d || '';
            form.sort = sort || 0;
            form.areaId = areaId || '';
            form.areaType = areaType || '';
            form.areaName = areaName || '';
            form.lineCode = lineCode || '';
            form.stationValue = stationValue || '';
            form.minPrice = minPrice || '';
            form.maxPrice = maxPrice || '';
            form.hotelBrand = hotelBrand || '';
            form.agreementEnable = agreementEnable || 0;
            form.differenceEnable = differenceEnable || 0;


            this.difference = '';
            this.workTravelType = workTravelType;
            this.journeyType = journeyType || 0;
            await this.canChangeFactor();
            this.getIsTodayMorning();
        },

        //获取酒店详情
        async initInfo(obj) {
            const _this = this;
            _this.$showLoading.show();
            _this.isFixedFilterCard = false;
            const {
                evectionNo,
                imageUrl,
                hotelName,
                phone,
                cityId,
                cityName,
                applyId,
                hotelAddr,
                hotelId,
            } = this.$route.query;
            const checkInDate = !obj ? this.$route.query.checkInDate : obj.checkInDate;
            const checkOutDate = !obj ? this.$route.query.checkOutDate : obj.checkOutDate;

            this.info = {
                ...this.info,
                imageUrl,
                hotelName,
                phone,
                cityName,
                hotelAddr,
                checkInDate,
                checkOutDate,
            };

            this.netErr = false;
            return Q.when()
                .then(function () {
                    return wait_util({
                        check() {
                            const dom_list = document.querySelectorAll(`.hotel-skeleton-list .skeleton`);
                            if (dom_list.length <= 0) return {flag: false};
                            return {flag: true};
                        },
                    });
                })
                .then(function () {
                    return consumer_hotel_search_getRoomPackageInfo({
                        evectionNo,
                        checkInDate,
                        checkOutDate,
                        hotelId,
                        cityId,
                        applyId
                    })
                })
                .then(res => {
                    const data = res.datas;
                    this.hotelStaticInfo = data.hotelStaticInfo;
                    this.sourceList = data.roomViewList;
                    this.recommendList = data.roomViewListRecommend;
                    this.recommendListCopy = data.roomViewListRecommend;
                    this.sourceList.forEach((item) => {
                        item.ratePlanViewList.forEach((value) => {
                            this.$set(value, 'isloadingPolicy', false);
                            this.$set(value, 'isAgreementPrice', false);
                            value.hotelParams = {
                                cityId: this.$route.query.cityId,
                                evectionNo: this.$route.query.evectionNo,
                                price: value.averagePrice
                            }
                        })
                    });
                    if (this.recommendList) {
                        this.recommendList.forEach((item) => {
                            item.ratePlanViewList.forEach((value) => {
                                this.$set(value, 'isloadingPolicy', false);
                                this.$set(value, 'isAgreementPrice', false);
                                value.hotelParams = {
                                    cityId: this.$route.query.cityId,
                                    evectionNo: this.$route.query.evectionNo,
                                    price: value.averagePrice
                                }
                            })
                        });
                    }
                    // testLogin.clear();
                    this.info = {
                        ...this.info,
                        nightDay: data.hotelDays,
                        hotelName: data.hotelStaticInfo.hotelNameCn,
                        list: this.sourceList,
                        longitude: data.hotelStaticInfo.longitude,
                        latitude: data.hotelStaticInfo.latitude,
                    };
                    // this.autoClick();
                    this.detail = {
                        ...this.detail,
                        nightDay: data.hotelDays,
                        longitude: data.hotelStaticInfo.longitude,
                        latitude: data.hotelStaticInfo.latitude,
                        hotelName: data.hotelStaticInfo.hotelNameCn,
                        phone: data.hotelStaticInfo.phone,
                        cityId: data.hotelStaticInfo.cityId,
                        cityName: data.hotelStaticInfo.cityCn,
                        cityCn: data.hotelStaticInfo.cityCn,
                        hotelAddr: data.hotelStaticInfo.addressCn,
                        areaName: data.hotelStaticInfo.areaName,
                        addressCn: data.hotelStaticInfo.addressCn,
                        stateCn: data.hotelStaticInfo.stateCn,
                        establishmentDate: data.hotelStaticInfo.establishmentDate,
                        star: data.hotelStaticInfo.hotelStar,
                        imageCount: data.hotelStaticInfo.imageCount
                    };
                    return wait_util({
                        check() {
                            const dom_list = document.querySelectorAll(`.collapse-card`);
                            if (dom_list.length <= 0) return {flag: false};
                            return {flag: true};
                        },
                    });
                }, err => {
                    if (err.code === '900' || (err.data && err.data.code === '340002')) {
                        _this.errorText = err.data.msg;
                        _this.isShowErrorDialog = true;
                    } else {
                        _this.sourceList = [];
                        _this.info.list = [];
                        _this.recommendList = [];
                        _this.recommendListCopy = [];
                        _this.netErr = true;
                    }
                    _this.loadingShow = false;
                    _this.$showLoading.hide();
                    return;
                })
                .then(function () {
                    _this.loadingShow = false;
                    _this.$showLoading.hide();
                })
                .finally(() => {
                    _this.$nextTick(() => {
                        /*在pc调试时开启,提交前注释-start*/
                        // const bodyHeight = document.body.clientHeight;
                        // const HeadTop = (_this.$refs['hotel-top'].offsetHeight - 14) / (bodyHeight / 100);
                        // _this.$refs.aFilterCardFixed.style.top = `${HeadTop}vh`;
                        /*在pc调试时开启,提交前注释-end*/
                    })
                })
                .catch((err) => {
                    this.loadingShow = false;
                    this.$showLoading.hide();
                });
        },

        //获取出差类型
        async getEvectionType() {
            let evectionParams = {
                vm: this,
                situation: '004'
            };
            let evectionQuery = await getEvectionAllType(evectionParams);
            this.evectionDetailType = evectionQuery.evectionDetailType;
        },

        //获取酒店基础信息，包含图片
        async getHotelInfoAndImage() {
            let res = await consumer_hotel_search_getHotelInfoAndImage({hotelId: Number(this.detail.hotelId)});
            this.detail = {
                ...this.detail,
                phone: res.data.phone,
                hotelName: res.data.hotelName,
                longitude: res.data.longitude,
                latitude: res.data.latitude,
                hotelAddr: res.data.addressCn,
                imageUrl: res.data.imageEntities[0].url
            };
            this.$forceUpdate();
        },

        //是否可修改搜索条件
        async canChangeFactor() {
            let res = await getJourneyOptionsCanBeModified({
                situation: '000',
                vm: this
            })
            this.timeCanEdit = res.timeCanEdit
            setTimeout(() => {
                this.$refs.aFilterCard.initHotelStayDatePicker();
                this.$refs.bFilterCard.initHotelStayDatePicker();
            }, 500)
        },

        //返回
        onBack() {
            const {
                applyId,
                evectionStartDate,
                evectionEndDate,
                cityId,
                cityName,
                cityCn,
                evectionNo,
                evectionType,
                evectionRequired,
                workTravelType,
                journeyType,
                journeyId,
                evectionDetailType,
                filterType,
                keyword,
                pt,
                sort,
                d,
                areaId,
                areaType,
                areaName,
                lineCode,
                stationValue,
                minPrice,
                maxPrice,
                hotelStar,
                hotelBrand,
                differenceEnable,
                agreementEnable,
            } = this.reserveForm;

            let {
                checkInDate,
                checkOutDate,
            } = this.info;

            let query = {
                applyId,
                checkInDate,
                checkOutDate,
                evectionStartDate,
                evectionEndDate,
                cityId,
                cityName,
                cityCn,
                evectionNo,
                evectionType,
                evectionRequired,
                workTravelType,
                journeyType,
                journeyId,
                evectionDetailType,
                filterType,
                keyword,
                pt,
                sort,
                d,
                areaId,
                areaType,
                areaName,
                lineCode,
                stationValue,
                minPrice,
                maxPrice,
                hotelStar,
                hotelBrand,
                differenceEnable,
                agreementEnable,
                isUpdateAccord: this.isUpdateAccord,
                isPOIName: this.$route.query.isPOIName,
                shareEvectionNo: this.detail.shareEvectionNo,
                shareJourneyId: this.detail.shareJourneyId,
            };

            if (applyId === 0) {
                this.$delete(query, 'evectionStartDate');
                this.$delete(query, 'evectionEndDate')
            }

            const type = Number(this.detail.evectionType);
            //因私
            if (type === 1) {
                this.$router.replace({
                    path: '/h5/hotel-search',
                    query: {
                        checkInDate,
                        checkOutDate,
                        cityId,
                        cityName,
                        cityCn,
                        evectionType,
                        filterType,
                        keyword,
                        pt,
                        sort,
                        d,
                        areaId,
                        areaType,
                        areaName,
                        lineCode,
                        stationValue,
                        minPrice,
                        maxPrice,
                        hotelStar,
                        hotelBrand,
                        differenceEnable,
                        agreementEnable,
                        workTravelType,
                    },
                });
            }

            //因公
            if (type === 2) {
                this.$router.replace({
                    path: '/h5/hotel-search',
                    query: query,
                });
            }
        },

        //筛选
        onFilter(obj) {
            let recommendListCopy = JSON.parse(JSON.stringify(this.recommendListCopy));
            let list = JSON.parse(JSON.stringify(this.sourceList));
            if (list.length === 0) return;

            this.$refs.aFilterCard.setFilterInfo(obj);
            this.$refs.bFilterCard.setFilterInfo(obj);

            if (obj.standard) {
                if (this.recommendListCopy && this.recommendListCopy.length > 0) {
                    recommendListCopy = this.recommendListCopy[0].ratePlanViewList[0].accord === 0 ? this.recommendListCopy : [];
                }
                list = list.map(item => {
                    const ratePlanViewList = item.ratePlanViewList.filter(cItem => cItem.accord === 0 && cItem.platformPrice > 0);
                    return {
                        ...item,
                        ratePlanViewList,
                    };
                });
            }
            if (obj.breakfast) {
                if (this.recommendListCopy && this.recommendListCopy.length > 0) {
                    recommendListCopy = this.recommendListCopy[0].ratePlanViewList[0].breakfast > 0 ? this.recommendListCopy : [];
                }
                list = list.map(item => {
                    const ratePlanViewList = item.ratePlanViewList.filter(cItem => cItem.breakfast > 0);
                    return {
                        ...item,
                        ratePlanViewList,
                    };
                });
            }
            if (obj.windows) {
                if (this.recommendListCopy && this.recommendListCopy.length > 0) {
                    let windowName = this.recommendListCopy[0].ratePlanViewList[0].windowName
                    recommendListCopy = (windowName === '有窗' || windowName === '落地窗' || windowName == '外窗' || windowName == '内窗' || windowName == '部分无窗' || windowName == '部分有窗' || windowName == '大部分有窗') ? this.recommendListCopy : [];
                }
                list = list.map(item => {
                    const ratePlanViewList = item.ratePlanViewList.filter(cItem => cItem.windowName === '有窗' || cItem.windowName === '落地窗' || cItem.windowName == '外窗' || cItem.windowName == '内窗' || cItem.windowName == '部分无窗' || cItem.windowName == '部分有窗' || cItem.windowName == '大部分有窗');
                    return {
                        ...item,
                        ratePlanViewList,
                    };
                });
            }
            if (obj.free) {
                if (this.recommendListCopy && this.recommendListCopy.length > 0) {
                    recommendListCopy = this.recommendListCopy[0].ratePlanViewList[0].refundRuleType === 2 ? this.recommendListCopy : [];
                }
                list = list.map(item => {
                    const ratePlanViewList = item.ratePlanViewList.filter(cItem => cItem.refundRuleType === 2);
                    return {
                        ...item,
                        ratePlanViewList,
                    };
                });
            }
            list = list.filter(item => item.ratePlanViewList.length > 0);
            this.recommendList = recommendListCopy;
            this.$set(this.info, 'list', list);
            this.$nextTick(() => {
                const cardList = this.$refs.collapseCard;
                cardList.forEach(item => {
                    item.updateHeight();
                });
                this.isFixedFilterCard = false;
                document.querySelector('.detail-box').scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            });
        },

        //获取出差单详情
        getEvectionDetail() {
            this.$showLoading.show();
            consumer_journey_getEvectionDetailV2({
                evectionNo: this.detail.evectionNo,
            })
                .then((res) => {
                    const detail = res.datas;
                    this.evectionDetail = detail;
                    this.travellerList = detail.psgList;
                    let travellerIdList = [];
                    let userIdsWhetherShared = [];
                    if (this.travellerList) {
                        this.travellerList.forEach(item => {
                            if(item.shareWith === 1) {
                                userIdsWhetherShared.push(item.userId);
                            } else {
                                travellerIdList.push(item.userId);
                            }
                        })
                    }
                    this.travellerIdList = travellerIdList ? travellerIdList : [];
                    this.userIdsWhetherShared = userIdsWhetherShared ? userIdsWhetherShared : [];
                })
                .catch((err) => {
                    this.loadingShow = false;
                    this.$showLoading.hide();
                });
        },

        //点击预订按钮
        async onReservation(row, item) {
            if (this.isTodayMorning) {
                let curTimeList = this.$moment().toArray();
                if(curTimeList[3] >= 6) {
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '此房型暂无法预订，请更换房型预订',
                        confirmButtonText: '更换房型',
                        showCancelButton: false
                    });
                    return;
                }
            }

            if (this.detail.evectionType === 2) {
                let {
                    evectionNo,
                    cityId,
                    checkInDate,
                    checkOutDate
                } = this.detail;
                let params = {
                    checkInDate,
                    checkOutDate,
                    cityId,
                    evectionNo,
                    id: item.hotelId,
                    price: item.status === 2 ? item.platformPrice : item.averagePrice
                }
                item.isloadingPolicy = true;
                await consumer_hotel_search_checkHotelPolicy(params).then(res => {
                    item.accord = res.accord;
                    item.isReserve = res.isReserve;
                    item.isloadingPolicy = false;
                })

                this.currentKeyId = null;
                if (item.isReserve === 0) {
                    this.currentKeyId = item.keyId + item.roomTypeId;
                    this.$refs['travelPopup'].init({
                        popupType: '001',
                        evectionNo: this.reserveForm.evectionNo,
                        evectionRequired: parseInt(this.detail.evectionRequired) || 0,
                        journeyId: parseInt(this.reserveForm.journeyId) || 0,
                        businessRuleControl: this.businessRuleControl,
                        hotelInfo: {
                            cityId: this.detail.cityId,
                            checkInDate: this.detail.checkInDate,
                            checkOutDate: this.detail.checkOutDate,
                            shareJourneyId: parseInt(this.detail.shareJourneyId || 0),
                            shareEvectionNo: this.detail.shareEvectionNo,
                        }
                    });
                    return
                }
            }

            let combo = item;

            let thirdpartyRoomTypeId = row.thirdpartyRoomTypeId;
            let additionalPrice = row.additionalPrice;

            const travellerList = this.travellerList;
            const checkInNum = travellerList.length;

            const canReserve = combo.nightlyRateList.map((item) => item.date);
            const length = canReserve.length;
            const form = this.searchForm;
            const start = form.checkInDate;
            const clearDays = moment(form.checkOutDate).diff(moment(start), "days");

            let incomplete = false;

            for (let i = 0; i < clearDays; i++) {
                const need = moment(start)
                    .add(i, "days")
                    .format("YYYY-MM-DD");
                if (!canReserve.includes(need)) incomplete = true;
            }
            let node = "";

            if (length === 0) {
            } else if (length === 1) {
                node = `当前仅“${canReserve[0]}”${length}天可预定！`;
            } else if (length === 2) {
                node = `当前仅“${canReserve[0]}，${canReserve[1]}</span>”，${length}天可预定!`;
            } else {
                for (let i = 0; i < canReserve.length; i++) {
                    const item = canReserve[i];
                    if (i === 0) {
                        node = `当前仅“${item}，`;
                    } else if (i === length - 1) {
                        node += `${item}”${length}天可预定！`;
                    } else {
                        node += `${item}，`;
                    }
                }
            }
            const _this = this;
            if (incomplete) {
                this.$Dialog
                    .alert({
                        title: "温馨提示",
                        message: node,
                    })
                    .then(() => {
                    });
            } else {
                reserve(_this);
            }

            function reserve(_this) {
                const searchForm = _this.searchForm;
                const form = _this.reserveForm;
                form.hotelId = combo.hotelId;
                form.hotelName = _this.detail.hotelName;
                form.checkInDate = searchForm.checkInDate;
                form.checkOutDate = searchForm.checkOutDate;

                form.hotelRoomTypeId = combo.roomTypeId;
                form.hotelRoomTypeName = combo.name;
                form.stayNights = length;
                form.price = combo.platformPrice / length;
                form.coseList = combo.coseList;
                form.thirdpartyHotelId = combo.thirdpartyHotelId;
                form.keyId = combo.keyId;
                form.additionalPrice = additionalPrice;
                form.breakfast = combo.breakfast;

                form.countryName = '中国';
                form.areaName = _this.detail.areaName;
                form.hotelAddr = _this.detail.addressCn;
                form.stateName = _this.detail.stateCn;
                form.windowName = combo.windowName;
                form.breakfastName = combo.breakfastName;
                form.nightlyRateList = combo.nightlyRateList;
                form.ifInvoice = combo.ifInvoice;
                form.orderFromType = row.orderFromType;
                form.groupName = combo.groupName;
                form.negotiatedPriceText = combo.negotiatedPriceText;
                form.hotelFrom = combo.hotelFrom;
                form.orderFromType = combo.hotelFrom;

                form.confirmType = combo.confirmType;
                form.bookingRule = combo.bookingRule;
                form.refundRuleType = combo.refundRuleType;
                form.refundRule = combo.refundRule;
                form.invoiceMode = combo.invoiceMode;
                form.invoiceModeText = combo.invoiceModeText;
                form['rateTypeId'] = combo['rateTypeId'];
                _this.reserve();
            }
        },

        //没选出差单预订
        req_reserve() {
            this.$showLoading.show();
            let params = {...this.reserveForm};
            const type = Number(this.detail.evectionType);
            if (type === 1) {
                consumer_t_od_hotel_order_interim_addOrUpdateOnPerson(params)
                    .then((res) => {
                        this.$showLoading.hide();
                        this.$router.push({
                            name: "h5-hotel-private-reserve",
                            query: {
                                orderNo: res.msg,
                            },
                        });
                    })
                    .catch((err) => {
                        this.$showLoading.hide();
                    });
            } else if (type === 2) {
                params = {
                    ...this.reserveForm,
                    longitude: this.info.longitude,
                    latitude: this.info.latitude,
                };
                consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness(params)
                    .then((res) => {
                        this.$showLoading.hide();
                        this.$router.push({
                            name: "h5-hotel-reserve",
                            query: {
                                evectionNo: res.msg,
                                evectionType: this.detail.evectionType,
                                workTravelType: this.detail.workTravelType,
                                shareEvectionNo: this.detail.shareEvectionNo,
                                shareJourneyId: this.detail.shareJourneyId,
                            },
                        });
                    })
                    .catch((err) => {
                        this.$showLoading.hide();
                    });
            }
        },

        //预订
        reserve() {
            const type = Number(this.detail.evectionType);
            if (type === 1) {
                this.req_reserve();
            } else if (type === 2) {
                this.checkHotelPolicy();
            }
        },

        //超标检查
        checkHotelPolicy() {
            const _this = this;
            const reserveForm = this.reserveForm;
            const businessParams = {
                cityId: reserveForm.cityId,
                evectionNo: reserveForm.evectionNo,
                price: reserveForm.price
            };
            this.$refs.checkTravelBox.check({
                businessType: 3, // 机票1，火车票2，酒店3
                businessParams: businessParams//校验差标的接口需要的入参对象
            }).then(res => {
                let params = {
                    ...this.reserveForm,
                    ...{overproofApplyList: res.policies},
                    longitude: this.info.longitude,
                    latitude: this.info.latitude,
                };
                this.$showLoading.show();
                consumer_t_od_hotel_order_interim_addOrUpdByOnBusiness(params).then(res => {
                    this.$showLoading.hide();
                    _this.$router.push({
                        name: "h5-hotel-reserve",
                        query: {
                            evectionNo: _this.reserveForm.evectionNo,
                            redirectType: _this.redirectType,
                            evectionType: _this.detail.evectionType,
                            workTravelType: _this.detail.workTravelType,
                            shareEvectionNo: _this.detail.shareEvectionNo,
                            shareJourneyId: _this.detail.shareJourneyId,
                        }
                    });
                }, error => {
                    this.$showLoading.hide();
                    // 根据code返回弹窗信息
                    this.setErrorDialog(error.data)
                }).catch(() => {
                    this.$showLoading.hide();
                })
            }).catch(err => {
                // 点取消或者不予许预定返回
                console.log(err);
                if (err) {
                    this.$router.go(-1) // 2021-11-13 要求返回上一级
                } else {
                    this.currentKeyId = null;
                    if (this.difference === '002') {
                        this.updateAccord();
                        this.difference = '';
                    }
                }
            })
        },

        //改变入住日期
        dateChange() {
            this.loadingShow = true;
            this.$showLoading.show();
            let urlParams = this.detail;
            urlParams.checkInDate = this.info.checkInDate;
            urlParams.checkOutDate = this.info.checkOutDate;
            let params = {
                currentKeyId: this.currentKeyId
            };
            const encrypted = aes_encrypt({
                data: JSON.stringify(params),
                key: 'params',
            });
            let p = {
                params: encrypted,
            }
            urlParams = Object.assign({}, urlParams, p)

            // 组装当前页面url
            let href = location.href.split('?')[0];
            let newUrl = href + '?';
            for (let i in urlParams) {
                newUrl += `${i}=${urlParams[i]}&`
            }
            history.replaceState(null, null, newUrl);

            //同步日期控件选中日期为默认日期
            let defaultDate = [new Date(this.info.checkInDate), new Date(this.info.checkOutDate)];
            this.$refs.aFilterCard.setDefaultDate(defaultDate);
            this.$refs.bFilterCard.setDefaultDate(defaultDate);

            //收起全部collapse card
            const collapseCard = this.$refs.collapseCard;
            if (collapseCard) {
                collapseCard.forEach(card => {
                    card.hideCard();
                })
            }

            this.init({checkInDate: this.info.checkInDate, checkOutDate: this.info.checkOutDate});
        },

        //计算高度，判断是否需要吸顶
        handleScroll() {
            let scrollTop = this.$refs.detailbox.scrollTop;
            this.detailBoxScrollTop = this.$refs.detailbox.scrollTop;
            this.sticky = scrollTop > 210 ? true : false; // 如果滚动到顶部了，this.sticky就为true


            let scrollT = document.querySelector('.detail-box').scrollTop;
            if (Math.abs(this.scrollT - scrollT) > 16) {
                this.scrollT = scrollT;
                return
            }
            this.scrollT = scrollT;

            let offsetTop = this.$refs.pride_tab_flow.getBoundingClientRect().top;
            if (this.isFixedFilterCard && offsetTop >= 60) {
                this.isFixedFilterCard = false
            } else if (!this.isFixedFilterCard && offsetTop < 60) {
                this.isFixedFilterCard = true;
                let __this = this;
                fixStatusBar({
                    type: `000`,
                    handler({default_handler, api}) {
                        const bodyHeight = document.body.clientHeight;
                        const addPaddingTop = api.safeArea.top / (bodyHeight / 100)
                        const HeadTop = (__this.$refs['hotel-top'].offsetHeight-14) / (bodyHeight / 100);
                        __this.$refs.aFilterCardFixed.style.top = `${addPaddingTop + HeadTop}vh`;
                    }
                });
            }
        },

        //打开出差单弹框
        editEvection() {
            this.currentKeyId = null;
            console.log('this.detail', this.detail);
            this.$refs['travelPopup'].init({
                popupType: '000',
                evectionNo: this.reserveForm.evectionNo,
                evectionRequired: parseInt(this.detail.evectionRequired) || 0,
                journeyId: parseInt(this.reserveForm.journeyId) || 0,
                businessRuleControl: this.businessRuleControl,
                hotelInfo: {
                    cityId: this.detail.cityId,
                    checkInTime: this.detail.checkInDate,
                    checkOutTime: this.detail.checkOutDate,
                    shareJourneyId: parseInt(this.detail.shareJourneyId || 0),
                    shareEvectionNo: this.detail.shareEvectionNo,
                }
            })
        },

        //更新出差单信息
        async updateEvectionInfo(val) {
            /*  返参： val
           * {
           *   difference: String, 是否有改动， 000： 没改动  001：城市改动，刷新页面  002：人改变，刷新差标  003:日期改动
           *   journeyId: string, 行程id
           *   evectionNo： string， 出差单号
           *   workTravelType: number, 因公出差的类型， 1是有选出差单，2是没有
           *   journeyType: number, 0 普通单， 1 不限行程单
           *   editType: string, 编辑类型，'000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
           *   travelCurrent： object, 当前选中的出差单， 如果没有选出差单则返回null
           * }
       * */
            let {
                difference,
                evectionNo,
                journeyId,
                journeyType,
                workTravelType,
                travelUserList,
                travelCurrent,
                shareEvectionNo,
                shareJourneyId
            } = val;
            this.difference = difference;
            this.travellerIdList = travelUserList;
            this.detail.evectionNo = evectionNo;
            this.detail.journeyId = journeyId || 0;
            this.detail.journeyType = journeyType || 0;
            this.detail.workTravelType = workTravelType;
            this.detail.shareEvectionNo = shareEvectionNo;
            this.detail.sharejourneyId = shareJourneyId;

            this.reserveForm.evectionNo = evectionNo;
            this.reserveForm.journeyId = journeyId || 0;
            this.reserveForm.journeyType = journeyType || 0;
            this.reserveForm.workTravelType = workTravelType;

            this.workTravelType = workTravelType;
            this.journeyType = journeyType || 0;
            await this.canChangeFactor();
            await this.getEvectionType();
            await this.changeUrlInfo(val);

            //判断是否凌晨（00:00-06:00）进入页面
            this.minDate = this.isMorning ? this.$moment().subtract(1, 'days').format('YYYY-MM-DD') : this.$moment().format('YYYY-MM-DD');
            this.maxDate = '2100-01-01';

            if (val.difference === '001' || val.difference === '003') {
                //为了返回列表页时，触发重新调接口获取列表
                cache_set({
                    key: 'hotelSearchOutTime',
                    value: '2000-01-01 00:00:00'
                })
            }
            if (val.difference === '000') {
                return
            } else if (val.difference === '002') {
                this.updateAccord();
                this.isUpdateAccord = true;
            } else {
                this.updateList(val);
            }
        },

        //修改地址信息
        changeUrlInfo(val) {
            let curTravel;
            let urlParams = this.$route.query;

            if (val.travelCurrent) {
                curTravel = val.travelCurrent.active;
                //因公，有出差单
                urlParams.applyId = val.travelCurrent.id;
                urlParams.journeyId = curTravel.id;
                urlParams.evectionStartDate = curTravel.departTime;
                urlParams.evectionEndDate = curTravel.returnTime;
                urlParams.checkInDate = curTravel.firstDepartTime;
                urlParams.checkOutDate = curTravel.firstReturnTime;
            } else {
                //因公，无出差单
                //无出行时间范围
                urlParams.applyId = 0;
                urlParams.journeyId = '';
                urlParams.checkInDate = this.info.checkInDate;
                urlParams.checkOutDate = this.info.checkOutDate;
                this.$delete(urlParams, 'evectionStartDate');
                this.$delete(urlParams, 'evectionEndDate')
            }

            urlParams.evectionNo = val.evectionNo;
            urlParams.workTravelType = val.workTravelType;
            urlParams.journeyType = val.journeyType;

            let params = {
                currentKeyId: this.currentKeyId
            };
            const encrypted = aes_encrypt({
                data: JSON.stringify(params),
                key: 'params',
            });
            let p = {
                params: encrypted,
            }
            urlParams = Object.assign({}, urlParams, p)
            // 组装当前页面url
            let href = location.href.split('?')[0];
            let newUrl = href + '?';
            for (let i in urlParams) {
                newUrl += `${i}=${urlParams[i]}&`
            }
            history.replaceState(null, null, newUrl);

            this.getIsTodayMorning();
        },

        //刷新差标
        async updateAccord() {
            let params = {};
            let item = null;
            let {
                evectionNo,
                cityId,
                checkInDate,
                checkOutDate
            } = this.detail;

            if (this.currentKeyId) {
                const currentKeyId = this.currentKeyId;
                let row = {};
                let item = {};
                this.info.list.forEach(r => {
                    let curItem = r.ratePlanViewList.find(i => {
                        return (i.keyId + i.roomTypeId) === currentKeyId;
                    });
                    if (curItem) {
                        row = r;
                        item = curItem;
                    }
                })

                params = {
                    checkInDate,
                    checkOutDate,
                    cityId,
                    evectionNo,
                    id: item.hotelId,
                    price: item.status === 2 ? item.platformPrice : item.averagePrice
                }
                item.isloadingPolicy = true;
                await consumer_hotel_search_checkHotelPolicy(params).then(res => {
                    item.accord = res.accord;
                    item.isReserve = res.isReserve;
                    item.isloadingPolicy = false;
                })
                this.onReservation(row, item);
                return
            }

            if (this.recommendList && this.recommendList.length > 0) {
                this.recommendList[0].ratePlanViewList.forEach(item => {
                    item.isloadingPolicy = true;
                })
            }

            if (this.sourceList && this.sourceList.length > 0) {
                this.sourceList.forEach(item => {
                    item.isloadingPolicy = true;
                    item.ratePlanViewList.forEach(product => {
                        product.isloadingPolicy = true;
                    })
                })
            }

            if (this.recommendList && this.recommendList.length > 0) {
                for (let i = 0; i < this.recommendList[0].ratePlanViewList.length; i++) {
                    item = this.recommendList[0].ratePlanViewList[i];
                    params = {
                        checkInDate,
                        checkOutDate,
                        cityId,
                        evectionNo,
                        id: item.hotelId,
                        price: item.status === 2 ? item.platformPrice : item.averagePrice
                    }
                    await consumer_hotel_search_checkHotelPolicy(params).then(res => {
                        item.accord = res.accord;
                        item.isReserve = res.isReserve;
                        item.isloadingPolicy = false;
                    })
                }
            }

            if (this.sourceList) {
                for (let i = 0; i < this.sourceList.length; i++) {
                    item = this.sourceList[i];
                    params = {
                        checkInDate,
                        checkOutDate,
                        cityId,
                        evectionNo,
                        id: item.hotelId,
                        price: item.platformPrice
                    }
                    await consumer_hotel_search_checkHotelPolicy(params).then(res => {
                        item.accord = res.accord;
                        item.isReserve = res.isReserve;
                        item.isloadingPolicy = false;
                    })

                    if (item.ratePlanViewList) {
                        let product = null;
                        for (let i = 0; i < item.ratePlanViewList.length; i++) {
                            product = item.ratePlanViewList[i];
                            params = {
                                checkInDate,
                                checkOutDate,
                                cityId,
                                evectionNo,
                                id: product.hotelId,
                                price: product.status === 2 ? product.platformPrice : product.averagePrice
                            }
                            await consumer_hotel_search_checkHotelPolicy(params).then(res => {
                                product.accord = res.accord;
                                product.isReserve = res.isReserve;
                                product.isloadingPolicy = false;
                            })
                        }
                    }
                }
            }
        },

        //刷新页面
        async updateList(val) {
            try {
                this.$showLoading.show();
                let curTravel;
                let urlParams = this.$route.query;

                if (val.travelCurrent) {
                    curTravel = val.travelCurrent.active;
                    //因公，有出差单
                    //改变城市时，去掉关于关键字的相关搜索条件
                    if (Number(curTravel.toCityId) !== Number(urlParams.cityId)) {
                        urlParams.filterType = 0;
                        urlParams.keyword = '';
                        urlParams.pt = '';
                    }
                    urlParams.applyId = val.travelCurrent.id;
                    urlParams.journeyId = curTravel.id;
                    urlParams.evectionStartDate = curTravel.departTime;
                    urlParams.evectionEndDate = curTravel.returnTime;
                    urlParams.checkInDate = curTravel.firstDepartTime;
                    urlParams.checkOutDate = curTravel.firstReturnTime;
                } else {
                    //因公，无出差单
                    //无出行时间范围
                    urlParams.applyId = 0;
                    urlParams.journeyId = '';
                    urlParams.checkInDate = this.info.checkInDate;
                    urlParams.checkOutDate = this.info.checkOutDate;
                    this.$delete(urlParams, 'evectionStartDate');
                    this.$delete(urlParams, 'evectionEndDate')
                }

                urlParams.evectionNo = val.evectionNo;
                urlParams.workTravelType = val.workTravelType;
                urlParams.journeyType = val.journeyType;

                let params = {
                    currentKeyId: this.currentKeyId
                };
                const encrypted = aes_encrypt({
                    data: JSON.stringify(params),
                    key: 'params',
                });
                let p = {
                    params: encrypted,
                }
                urlParams = Object.assign({}, urlParams, p)

                // 组装当前页面url
                let href = location.href.split('?')[0];
                let newUrl = href + '?';
                for (let i in urlParams) {
                    newUrl += `${i}=${urlParams[i]}&`
                }
                history.replaceState(null, null, newUrl);

                //difference：是否有改动， 000： 没改动，不刷新不返回  001：城市改动，返回  002：人改变，刷新差标  003:日期改动，刷新页面
                //editType: string, 编辑类型，'000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
                if (val.editType === '000' && val.difference === '001') {
                    this.$router.replace({
                        name: 'h5-hotel-search',
                        query: {
                            applyId: val.travelCurrent.id,
                            evectionStartDate: curTravel.departTime,
                            evectionEndDate: curTravel.returnTime,
                            checkInDate: curTravel.firstDepartTime,
                            checkOutDate: curTravel.firstReturnTime,
                            cityId: curTravel.toCityId || urlParams.cityId,
                            cityName: curTravel.toCityName || urlParams.cityName,
                            cityCn: curTravel.toCityName || urlParams.cityName,
                            isPOIName: false,
                            evectionNo: curTravel.evectionNo,
                            evectionRequired: this.evectionRequired,
                            evectionType: 2,
                            journeyType: curTravel.journeyType,
                            journeyId: curTravel.id,
                            workTravelType: val.workTravelType,
                            filterType: urlParams.filterType,
                            pt: urlParams.pt,
                            keyword: urlParams.keyword,
                            shareEvectionNo: this.detail.shareEvectionNo,
                            shareJourneyId: this.detail.shareJourneyId,
                        },
                    })
                } else {
                    location.reload();
                }
            } finally {
                this.$showLoading.hide();
            }
        },

        //获取退改政策
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.businessRuleControl = res.datas.businessRuleControl;
            this.unlimitedJourney = res.datas.unlimitedJourney;
        },

        //获取是否今天凌晨入住
        getIsTodayMorning() {
            if (this.isMorning) {
                let checkInDateStr = this.$moment(this.searchForm.checkInDate).format('YYYY-MM-DD');
                this.isTodayMorning = checkInDateStr === this.yesterday;
            }
        },

        //展开房型时
        jumpToTop(item, cardIdx, targetItem) {
            let HeadTop = this.$refs['hotel-top'].offsetHeight;//头部高度
            let aFilterCard = document.querySelectorAll('.filter-card-fixed')[0]//固定的filterCard距顶位置
            let bFilterCard = document.querySelectorAll('.filter-card-flow')[0]//固定的filterCard距顶位置
            let cardItem = targetItem ? targetItem : document.querySelectorAll('.collapse-card-' + cardIdx)[0];//当前元素
            let distance = document.querySelector('.detail-box').scrollTop // 滚动条距离滚动区域顶部的距离
            let scrollHeight = document.querySelector('.detail-box').scrollHeight;//滚动条区域总高度

            let d = this.paddingTopPx + HeadTop + aFilterCard.offsetHeight - 10;
            if (!this.isFixedFilterCard) {
                d = d + bFilterCard.offsetHeight + 36;
            }
            let total = cardItem.offsetTop - d// 锚点元素距离其offsetParent(这里是body)顶部的距离(待滚动的距离)


            //如果滚动条总高度不足，不用吸顶，滚动到最底
            let step;
            let d1 = total - distance;
            let d2 = scrollHeight - total
            if (d1 > d2) {
                step = (scrollHeight - distance) / 50;
                toBottom(document.querySelector('.detail-box'))
                return
            }

            // 滚动动画实现, 使用setTimeout的递归实现平滑滚动，将距离细分为50小段，10ms滚动一次
            // 计算每一小段的距离
            step = total / 50
            if (total > distance) {
                smoothDown(document.querySelector('.detail-box'))
            } else {
                let newTotal = distance - total
                step = newTotal / 50
                smoothUp(document.querySelector('.detail-box'))
            }

            // 参数element为滚动区域
            function smoothDown(element) {
                setTimeout(() => {
                    if (total > distance) {
                        distance += step
                        element.scrollTop = distance
                        smoothDown(element)
                    } else {
                        element.scrollTop = total;
                    }
                }, 10)
            }

            // 参数element为滚动区域
            function smoothUp(element) {
                if (total < distance) {
                    distance -= step
                    element.scrollTop = distance
                    setTimeout(smoothUp.bind(this, element), 10)
                } else {
                    element.scrollTop = total
                }
            }

            //参数element为滚动区域
            function toBottom(element) {
                if (distance < scrollHeight) {
                    distance += step
                    element.scrollTop = distance
                    setTimeout(toBottom.bind(this, element), 10)
                } else {
                    element.scrollTop = scrollHeight
                }
            }
        },

        //物理键返回
        keyback() {
            const __this = this;

            keyback_processor.addEventListener(function () {
                const travelPopup = __this.$refs.travelPopup
                if (travelPopup.getShowType()) {
                    travelPopup.hide()
                    return;
                }

                const aFilterCard = __this.$refs.aFilterCard;
                const bFilterCard = __this.$refs.bFilterCard;
                if (__this.evectionDetailType === '001') {
                    const hotelStayDatePickerShow = aFilterCard.hotelStayDatePickerShow() || bFilterCard.hotelStayDatePickerShow();
                    if (hotelStayDatePickerShow) {
                        aFilterCard.hideHotelStayDatePicker();
                        bFilterCard.hideHotelStayDatePicker();
                        return;
                    }
                }

                const calendarShow = aFilterCard.calendarShow() || bFilterCard.calendarShow();
                if (calendarShow) {
                    aFilterCard.hideCalendar();
                    bFilterCard.hideCalendar();
                    return;
                }

                const calendarFor003Show = aFilterCard.calendarFor003Show() || bFilterCard.calendarFor003Show();
                if (calendarFor003Show) {
                    aFilterCard.hideCalendarFor003();
                    bFilterCard.hideCalendarFor003();
                    return;
                }

                const checkTravelBox = __this.$refs.checkTravelBox
                if (checkTravelBox && checkTravelBox.getShowStatus()) {
                    checkTravelBox._checkErrorTableCancel()
                    return;
                }

                let recommendEvectionStandardExceedItem;
                const recommendEvectionStandardExceed = __this.$refs.recommendEvectionStandardExceed;
                if (recommendEvectionStandardExceed) {
                    recommendEvectionStandardExceedItem = recommendEvectionStandardExceed.find(item => {
                        return item.getShow() === true;
                    })
                    if (recommendEvectionStandardExceedItem) {
                        recommendEvectionStandardExceedItem.closePopup();
                        return;
                    }
                }

                let collapseCardItem;
                let standardExceedItem;
                const collapseCard = __this.$refs.collapseCard;
                if (collapseCard) {
                    collapseCardItem = collapseCard.find(item => {
                        standardExceedItem = item.getEvectionStandardExceedShow();
                        if (standardExceedItem) {
                            return standardExceedItem;
                        }
                    })
                    if (collapseCardItem) {
                        collapseCardItem.evectionStandardExceedHide(standardExceedItem);
                        return;
                    }
                }

                const hotelPolicyPopup = __this.$refs.hotelPolicyPopup;
                if (hotelPolicyPopup.isShow()) {
                    hotelPolicyPopup.hide();
                    return;
                }
                const hotelAlbumPopup = __this.$refs.hotelAlbumPopup;
                if (hotelAlbumPopup.isShow()) {
                    hotelAlbumPopup.back();
                    return;
                }

                const hotelAboutPopup = __this.$refs.hotelAboutPopup;
                if (hotelAboutPopup.isShow()) {
                    hotelAboutPopup.back();
                    return;
                }
                __this.onBack()
            });
        },

        //单击预订-触发选人-选人确定后刷新页面-自动激发预订按钮
        autoClick() {
            const currentKeyId = this.currentKeyId;
            if (currentKeyId !== null) {
                let row = {};
                let item = {};
                this.info.list.forEach(r => {
                    let curItem = r.ratePlanViewList.find(i => {
                        return (i.keyId + i.roomTypeId) === currentKeyId;
                    });
                    if (curItem) {
                        row = r;
                        item = curItem;
                    }
                })
                this.onReservation(row, item);
            }
        },

        // 查看差标
        openEvectionRulePopup() {
            let userIds = this.travellerIdList;
            let userIdsWhetherShared = this.userIdsWhetherShared;
            let cityId = this.reserveForm.cityId;
            let cityName = this.reserveForm.cityCn;
            let params = {
                userIds,
                userIdsWhetherShared,
                cityId,
                cityName,
                applyJourneyId: this.$route.query.journeyId ? this.$route.query.journeyId : '',
            };
            this.$refs.hotelPolicyPopup.init(params);
        },

        //跳转至酒店相册页
        toHotelPic() {
            let params = this.detail;
            if (this.detail.imageCount > 0) {
                this.$refs.hotelAlbumPopup.init({hotelId: params.hotelId});
            }
        },

        //跳转至酒店设施页
        toHotelFacilities() {
            this.$refs.hotelAboutPopup.init({
                hotelStaticInfo: this.hotelStaticInfo
            });
        },

        //显示房型详情弹框
        showRoomDetailPopup(item, cardIdx) {
            this.$refs.roomDetailPopup.init({
                roomViewItem: item,
                cardIdx: cardIdx
            });
        },

        //展开房型详情
        onShowRoom(item, cardIdx) {
            this.$refs.collapseCard[cardIdx].show = false;
            this.$refs.collapseCard[cardIdx].onToggle(item, cardIdx);
        },

        //点查看房型按钮关闭相册弹框，定位到房型列表
        onLocateRoomList() {
            if (this.recommendList && this.recommendList.length > 0) {
                this.jumpToTop(this.info.list[0], 0, this.$refs.recommendList);
            } else {
                this.jumpToTop(this.info.list[0], 0)
            }
        },

        //页面停留时间超过30分钟，重新搜索
        setSearchReact() {
            this.searchTemp = setInterval(() => {
                this.intervalNum++
                if (this.intervalNum >= 30 * 60) {
                    clearInterval(this.searchTemp)
                    Toast('页面停留时间超过30分钟，请您重新搜索该酒店信息')
                    this.init();
                    this.intervalNum = 0
                    this.setSearchReact()
                }
            }, 1000)
        },

        //重载页面
        reload() {
            location.reload();
        }
    },
    created() {
        let curTimeList = this.$moment().toArray();
        this.isMorning = curTimeList[3] < 6;
        if (this.isMorning) {
            //凌晨时进入预订页面需要做的事情
            this.initDateForMorning();
        }
        //判断是否凌晨（00:00-06:00）进入页面
        this.minDate = this.isMorning ? this.$moment().subtract(1, 'days').format('YYYY-MM-DD') : this.$moment().format('YYYY-MM-DD');
        this.maxDate = '2100-01-01';
    },
    mounted() {
        const __this = this;

        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                const bodyHeight = document.body.clientHeight
                const addPaddingTop = api.safeArea.top / (bodyHeight / 100)
                __this.$refs['navbar'].style.paddingTop = `${addPaddingTop}vh`;
                __this.paddingTop = addPaddingTop;
                __this.paddingTopPx = api.safeArea.top;
            }
        });
    },
    watch: {
        detailBoxScrollTop(newValue) {
            if (newValue === this.detailBoxScrollTopList[0] || newValue === this.detailBoxScrollTopList[1]) {
                this.isFixedFilterCard = true;
                return;
            }
            setTimeout(() => {
                this.detailBoxScrollTopList[this.scrollTopIndex] = newValue;
                this.scrollTopIndex = this.scrollTopIndex === 0 ? 1 : 0;

                if (newValue === this.$refs.detailbox.scrollTop) { //延时执行后当newValue等于this.$refs.detailbox.scrollTop，代表滚动结束
                    let offsetTop = this.$refs.pride_tab_flow.getBoundingClientRect().top;
                    if (this.isFixedFilterCard && offsetTop >= 60) {
                        this.isFixedFilterCard = false
                    } else if (!this.isFixedFilterCard && offsetTop < 60) {
                        this.isFixedFilterCard = true;
                    }
                }
            }, 20); //必须使用延时器，否则每次newValue和this.$refs.detailbox.scrollTop都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
        }
    },
    async beforeRouteEnter(to, from, next) {
        //第一个参数to，包含的内容是切换后的路由对象，也就是跳转后的路由对象
        //第二个参数from，包含的内容的是切换前的路由对象，也就是跳转前的路由对象
        //第三个参数next()，是否往下执行，执行的话，如果不写的话路由就不会跳转，操作将会终止
        if (to.name === 'h5-hotel-detail' && (from.name === 'h5-hotel-reserve' || from.name === 'h5-hotel-private-reserve' || from.name === 'h5-hotel-map')) {
            // 1是正常刷新， 2是从酒店详情页返回，要超过5分钟才刷新
            cache_set({
                key: 'hotelDetailReactType',
                value: '2'
            })
        } else {
            cache_set({
                key: 'hotelDetailReactType',
                value: '1'
            })
        }
        next();
    },
    async activated() {
        const outTime = cache_get({key: 'hotelDetailOutTime'});
        let minute = moment().diff(outTime, 'minute');
        let hotelDetailReactType = cache_get({key: 'hotelDetailReactType'});
        if (hotelDetailReactType === '2' && minute <= 5) {
            this.loadingShow = false;
            let hotelDetailInfo = cache_get({key: 'hotelDetailInfo'});
            hotelDetailInfo = JSON.parse(hotelDetailInfo);
            this.$nextTick(() => {
                this.currentKeyId = null;
                this.difference = hotelDetailInfo.difference;
                this.detail = hotelDetailInfo.detail;
                this.reserveForm = hotelDetailInfo.reserveForm;
                this.recommendList = hotelDetailInfo.recommendList;
                this.info = hotelDetailInfo.info;
                this.sourceList = this.info.list;
                this.searchForm.checkInDate = this.reserveForm.checkInDate;
                this.searchForm.checkOutDate = this.reserveForm.checkOutDate;
                this.searchForm.hotelId = this.reserveForm.hotelId;
                this.$refs.detailbox.scrollTop = hotelDetailInfo.scrollTop;
                if (this.difference === '002') {
                    this.updateAccord();
                    this.difference = '';
                }
            })
        } else {
            await this.init()
        }
        this.setSearchReact();
        this.keyback();
        this.isUpdateAccord = false;
        window.addEventListener('scroll', this.handleScroll, true);
    },
    deactivated() {
        const outTime = moment().format('YYYY-MM-DD HH:mm:ss');
        cache_set({key: 'hotelDetailOutTime', value: outTime})

        let params = {
            detail: this.detail,
            difference: this.difference,
            reserveForm: this.reserveForm,
            recommendList: this.recommendList,
            info: this.info,
            scrollTop: this.$refs.detailbox.scrollTop,
        }
        cache_set({
            key: 'hotelDetailInfo',
            value: JSON.stringify(params)
        })

        keyback_processor.removeEventListener();
        window.removeEventListener('scroll', this.handleScroll, true);
    },
    filters: {
        format_checkIn(val, travellerList) {
            for (let i = 0; i < travellerList.length; i++) {
                const item = travellerList[i];
                if (item.userId === val) return item.staffName;
            }
        },
    },
    components: {
        Card: () => import('./components/Card.vue'),
        FilterCard: () => import('./components/FilterCard.vue'),
        CollapseCard: () => import('./components/CollapseCard.vue'),
        Dialog,
        CheckTravel,
        HotelDetailSkeleton,
        TravelPopup,
        EvectionStandardExceed,
        HotelPolicyPopup,
        roomDetailPopup,
        HotelAlbumPopup,
        HotelAboutPopup
    },
};
