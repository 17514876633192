import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import req_detail_data from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_queryHotelOrderDetails";
import cancel_order from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_cancelOrder";// 取消订单
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import {Toast} from "vant";

import ApprovalProcess from "@/component/approval-process/1.0.0/index.vue"

import app_loading from "@/lib/common-service/app-loading";
import moment from "moment";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import HotelUnsubscribe from "@/page/personal-center/order/components/hotelUnsubscribe/1.0.0/index.vue";

export default {
    data() {
        return {
            detail: null,
            userId: 0,//当前用户id
            initialUserId: 0,//创建订单用户id
            isCanChange: false,//当前用户能否退改此行程
            hoteltrainsOrderTipsResponses: [],
            recordList: [],
            record: {},
            payType: 0, // 0:企业个人都有， 1:企业支付， 2:个人支付

            milliseconds: 0,
            paySetIntervalFun: '',
            isShowPriceDetail: false,

            isShareUser: false, // 是否是合住人
        };
    },
    methods: {
        firstCopySuccess(e) {
            Toast('已复制');
        },
        showPriceDetailDialog() {
            this.isShowPriceDetail = !this.isShowPriceDetail;
        },
        onBack() {
            if (this.$route.query.jumpType) {
                if (this.$route.query.jumpType == 'home') {
                    this.$router.push({
                        name: 'h5-home',
                    });
                } else if (this.$route.query.jumpType == 'go(-1)') {
                    this.$router.go(-1)
                } else {
                    this.$router.replace({
                        path: '/h5/hotel-order-list',
                        query: {
                            active: 0,
                        },
                    });
                }
            } else {
                this.$router.replace({
                    path: '/h5/hotel-order-list',
                    query: {
                        active: 0,
                    },
                });
            }
        },
        clickApplyNo(){
            console.log(this.detail);
            if (this.isShareUser) {
                return
            }
            if(this.detail.approveType === 2) {
                this.$router.push({
                    name: 'h5-apply-approve-exceed', // admin-my-apply-manage-apply-approve-exceed   ?applyId=3550&type=approve
                    query: {
                        applyId: this.detail.applicationid,
                        applyNo: this.detail.applyNo,
                        approveStatus: '',
                        selectType: 1,
                        // type: 'applyDetail',
                        approveType: 2,
                        type: 'applyDetail'
                    }
                })
            } else {
                // id&applyNo=1854425143800762368&approveType=1&approveStatus=5&listApproveStatus=1&type=applyDetail&selectType=1
                this.$router.push({
                    name: 'h5-approve-detail',
                    query: {
                        id: this.detail.applicationid,
                        approveType: this.detail.approveType,
                        type: 'applyDetail',
                        applyNo: this.detail.applyNo,
                        approveStatus: this.detail.approveStatus || 5,
                        listApproveStatus: 1,
                        selectType: 1,
                    },
                });
            }
        },
        // 获取订单详情
        getOrderDetail() {
            this.$showLoading.show();
            const orderNo = this.$route.query.orderNo || '';
            req_detail_data({orderNo}).then(res => {
                let detail = {
                    applyNo: res.applyNo,
                    applyId: res.applyId,
                    ifAgreement: res.ifAgreement === 1 ? true : false,
                    hotelGroup: res.hotelGroup,
                    cardNumber: res.cardNumber,
                    agreementHotelText: res.agreementHotelText,
                    serviceFee: res.hotelCostInfoResponse.serviceFee,
                    oneselfAmount: res.hotelCostInfoResponse.oneselfAmount, // 个人支付金额
                    creditAmount: res.hotelCostInfoResponse.creditAmount, // 企业支付金额
                    approveApply: res.approveApply,
                    initialName: res.initialName,
                    gmtCreate: res.gmtCreate,
                    sharedStatus: res.sharedStatus,
                    order: res.orderInterimResult,
                    personList: res.hotelOrderPersonList ? res.hotelOrderPersonList : [],
                    initialUserId: res.initialUserId,
                    evectionInfo: res.tusEvection,
                    timeoutApproval: res.timeoutApproval,
                    reservation: res.reservation,
                    applicationid: res.applicationid,
                    approveType: res.approveType,
                    approvalReason: res.approvalReason,
                    milliseconds: res.milliseconds,
                    refundRuleText: res.refundRuleText,
                    refundRuleHours: res.refundRuleHours,
                    travelFeeAffiliationMsgList: res.travelFeeAffiliationMsgList || [],
                };

                if(detail.sharedStatus === 1) {
                    let index = 0;
                    let traveler = detail.personList.find((item, idx) => {
                        index = idx;
                        return item.shareWith === 2;
                    })
                    if(index === 1) {
                        let personList = [detail.personList[1], detail.personList[0]];
                        detail.personList = personList;
                    }
                }

                let isBeforeCheckIn = moment().add(-1, 'd').isBefore(detail.order.checkInDate);
                detail.order.isBeforeCheckIn = isBeforeCheckIn;
                let nightlyRateList = res.hotelCostInfoResponse.roomViewList;
                detail.order.nightlyRateList = nightlyRateList;
                detail.isExceed = res.showOrNot;
                detail.hoteltrainsOrderTipsResponses = res.hoteltrainsOrderTipsResponses ? res.hoteltrainsOrderTipsResponses : [];
                if (detail.isExceed) {
                    detail.recordList = res.recordList ? res.recordList : []
                }
                this.milliseconds = detail.milliseconds || 0;
                if (this.milliseconds > 0) {
                    this.paySetIntervalFun = setInterval(() => {
                        this.milliseconds--;
                        if (this.milliseconds <= 0) {
                            clearInterval(this.paySetIntervalFun);
                            this.getOrderDetail()
                        }
                    }, 1000)
                }
                let record = {};
                if (detail.recordList) {
                    detail.recordList.forEach(value => {
                        record = value
                    })
                }
                detail.record = record;
                let payType = 0; // 0企业个人都有， 1企业支付， 2:个人支付
                if (detail.oneselfAmount === 0) {
                    payType = 1;
                } else if (detail.creditAmount === 0) {
                    payType = 2;
                } else {
                    payType = 0;
                }
                this.payType = payType;
                this.detail = detail;

                this.checkPermissions();
                this.$nextTick(() => {
                    const __this = this;
                    fixStatusBar({
                        type: `000`,
                        handler({default_handler, api}) {
                            default_handler({
                                element: __this.$refs.navBox,
                            });
                        }
                    });

                    /*this.$refs.approvalProcess.init({
                        showSwitch: true,
                        status: res.status,
                        statusText: res.statusText,
                        approvalData: this.detail.recordList,
                        approvalReason: res.approvalReason,
                        btnParams: {
                            isShow: false,
                            approvalParams: {}
                        }
                    });*/
                    get_user_info().then(userInfoRes=>{
                        let isShareUser = false;
                        let userId = userInfoRes.datas.id;
                        if (this.detail.travelFeeAffiliationMsgList) {
                            this.detail.travelFeeAffiliationMsgList.forEach(value => {
                                if (value.userId === userId) {
                                    isShareUser = true;
                                }
                            })
                        }
                        this.isShareUser = isShareUser;
                    })

                    app_loading.hide();
                    this.$showLoading.hide();
                })
            });
        },
        // 获取个人信息
        async getUserInfo() {
            let res = await get_user_info();
            this.userId = res.datas.id
        },
        //检查当前用户是否可退改此行程
        async checkPermissions() {
            await this.getUserInfo();
            if (this.detail.initialUserId === this.userId) {
                this.isCanChange = true
            }
        },
        getRecordStatus(val) {
            // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return 'wait'
            } else if (val === 1) {
                return 'wait'
            } else if (val === 2) {
                return 'pass'
            } else if (val === 3 || val === 4) {
                return 'fail'
            } else {
                return ''
            }
        },
        getRecordStatusText(val, isWholeState) {
            // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return isWholeState ? '审核中' : '待审批'
            } else if (val === 1) {
                return isWholeState ? '审核中' : '待审批'
            } else if (val === 2) {
                return '通过'
            } else if (val === 3) {
                return '驳回'
            } else if (val === 4) {
                return '已失效'
            } else {
                return ''
            }
        },
        recordEnd(val) {
            if (!val) {
                return false
            }
            if (val.approveStatus === 2 || val.approveStatus === 3 || val.approveStatus === 4) {
                return true
            } else {
                return false
            }
        },
        to_payment(orderNo) {
            this.$router.push({
                name: "h5-travel-payment",
                query: {
                    orderNo,
                    orderType: "3",
                    businessType: 3,
                },
            });
        },
        cancel_order(orderNo) {
            this.$Dialog.confirm({
                title: "提示",
                message: "确定要取消订单吗?",
                cancelButtonText: '稍后再说',
                confirmButtonText: '取消订单',
            }).then(() => {
                cancel_order({orderNo}).then((res) => {
                    Toast('取消订单成功');
                    this.getOrderDetail();
                });
            })
        },
        to_refund(row) { // 申请退订
            const params = {
                orderNo: row.orderNo,
                evectionNo: row.evectionNo,
                cancelRemark: ''
            };
            this.$refs.hotelUnsubscribe.clickUnsubscribe(params).then((res) => {
                console.log(res);
                // 刷新
                if (res) {
                    this.getOrderDetail();
                }
            }).catch((rej) => {
                console.log(rej);
            });
            // this.$router.push({
            //     name: "h5-hotel-refund",
            //     query: {
            //         orderNo: row.orderNo
            //     }
            // });
        },
        toApplyDetail() {
            if (this.detail.applyId || this.detail.applicationid) {
                this.$router.push({
                    name: 'h5-apply-approve-exceed', // admin-my-apply-manage-apply-approve-exceed   ?applyId=3550&type=approve
                    query: {
                        applyId: this.detail.applyId || this.detail.applicationid,
                        applyNo: this.detail.applyNo,
                        approveStatus: '',
                        selectType: 1,
                        // type: 'applyDetail',
                        approveType: 2,
                        type: 'applyDetail'
                    }
                })
            }
        },
        toEvectionDetail() {
            if (this.detail.evectionInfo.temporaryEvectionStatus !== 0) {
                return
            }
            if (this.isShareUser) {
                return;
            }
            this.$router.push({
                name: 'h5-office-evection-details',
                query: {
                    id: this.detail.evectionInfo.evectionNo,
                }
            })
        },
    },
    created() {

    },
    mounted() {
        this.getOrderDetail();
    },
    activated() {
    },
    deactivated() {
        clearInterval(this.paySetIntervalFun)
    },
    components: {
        NavBar,
        ApprovalProcess,
        CollapseCard: () => import('./components/collapse-card/index.vue'),
        HotelUnsubscribe,
    },
    filters: {
        format_create(val) {
            switch (Number(val)) {
                case 1:
                    return "个人创建";
                    break;
                case 2:
                    return "出差单创建";
                    break;
            }
        },
        filterTime(val) {
            if (val) {
                // 转换为式分秒
                let h = parseInt(val / 60 / 60 % 24);
                h = h < 10 ? '0' + h : h;
                let m = parseInt(val / 60 % 60);
                m = m < 10 ? '0' + m : m;
                let s = parseInt(val % 60);
                s = s < 10 ? '0' + s : s;
                let text = `${m}分${s}秒`;
                if (h !== '00') {
                    text = h + '时' + text
                }
                return text
            } else {
                return '00分00秒'
            }
        },
    },
}
