import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import app_loading from "@/lib/common-service/app-loading";

export default {
    data() {
        return {
            paddingTop: 0,
            showTitle: true,
        };
    },
    components: {
        NavBar
    },
    methods: {
        goClick() {
            // 判断是否在flutter app里
            if (window.getFlutter == true) {
                // 如果在，则向flutter app发送信息
                try {
                    window.flutter_inappwebview
                        //可以传递你所需要的任意类型数据，数组、对象等
                        .callHandler("backHandler", {'type': 'close'})
                        .then((res)=>{
                            console.log("res==flutter给html的数据", res);
                        })
                } catch (e) {}
                return
            }
            this.$router.go(-1);
        }
    },
    created() {
        app_loading.hide();
    },
    mounted() {
        const __this = this;
        if (__this.$route.query.showTitle !== undefined) {
            __this.showTitle = __this.$route.query.showTitle;
        }
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                __this.paddingTop = api.safeArea.top;
            }
        });
    },
    activated() {
    },
    deactivated() {
    },
};
