export default {
  components: {},
  props: {
    detail: {
      type: Object,
      required: true,
    },
    show: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      
    };
  },
  methods: {},
  created() {},
  filters: {
    format_cardType(val) {
      switch (Number(val)) {
        case 1:
          return "身份证";
          break;
        case 2:
          return "护照";
          break;
        case 4:
          return "回乡证";
          break;
        case 5:
          return "台胞证";
          break;
        case 6:
          return "军官证";
          break;
        case 7:
          return "香港身份证";
          break;
        case 11:
          return "澳门身份证";
          break;
        case 10:
          return "其它";
          break;
      }
    },
  },
};
