import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';
export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/office-evection/details',
      name: 'h5-office-evection-details',
      component: () => import(/* webpackChunkName: "page-evection-evection-order-office-evection-details" */ `./1.0.3/index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '出差单详情',
          need_head_nav: false,
          need_head_nav1: false,
          need_footer_bar: false,
          secondary_menu: 1,
          evectionMeta: '1',
          need_alive: true,
        }
      },
    }
  ],
};
