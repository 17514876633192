import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/hotel-refund-order-detail',
            name: 'h5-hotel-refund-order-detail',
            component: () => import(/* webpackChunkName: "page-personal-center-order-hotel-hotel-refund-order-detail" */ `./1.0.4/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '退订详情',
                    need_head_nav: false,
                    need_footer_bar: false,
                    secondary_menu: 4,
                }
            },
        }
    ],
};
