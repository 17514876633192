import {Dialog, Toast} from "vant";
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";// 列表为空展示组件
import Search from '@/page/personal-center/order/components/flightListSearch/1.0.0/index.vue'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import consumer_air_ticket_cancelChangePay from '@/lib/data-service/haolv-default/consumer_air_ticket_cancelChangePay'
import consumer_air_ticket_orderList from "@/lib/data-service/haolv-default/consumer_air_ticket_orderList";// 机票订单列表
import consumer_air_ticket_cancelOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";// 取消订单
import consumer_air_ticket_deletionOrder from '@/lib/data-service/haolv-default/consumer_air_ticket_deletionOrder'
export default {
  components: {
    ListEmpty,
    Search,
    NavBar
  },
  data() {
    return {
      statusList: [
        { label: "我的订单", value: "0", placeholder: '订单号/出行人姓名/城市' },
        { label: "退票单", value: "1", placeholder: '订单号/退票乘客姓名/城市' },
        { label: "改签单", value: "2", placeholder: '订单号/改签乘客姓名/城市' },
        /*{ label: "退款单", value: "3" },*/
      ],
      form: {
        currentPage: 1,
        pageSize: 0,
        mixture: "",
        type: "0",
      },
      list: [],
      loading: false,
      finished: true,
      refreshing: false,
      emptyType: "",
      placeholder: '订单号/出行人姓名/城市',
      netErr: false,
    };
  },
  watch: {
    loading(val) {
      // const Toast = this.$Toast;
      // if (val) {
      //   Toast.loading({
      //     duration: 0, // 持续展示 toast
      //     forbidClick: true,
      //     message: "加载中...",
      //   });
      // } else {
      //   Toast.clear();
      // }
    },
  },
  methods: {
    returnUserType(val) {
      let name = ''

      switch (val) {
        case '0':
          name = '出行人'
        break
        case '1':
          name = '退票乘客'
        break
        case '2':
          name = '改签乘客'
        break
        default:
          name = ''
      }
      return name
    },
    req_list() {
      this.emptyType = "";
      const form = this.form;
      this.netErr = false;
      consumer_air_ticket_orderList(form)
        .then((result) => {
          const datas = result.pageResult;
          const totalCount = datas.totalCount;
          if (totalCount > form.pageSize) {
            this.finished = false;
          } else {
            form.pageSize = totalCount;
            this.finished = true;
          }

          if (!datas.pageData.length) this.emptyType = "search";

          this.list = datas.pageData;
          this.refreshing = false;
          this.loading = false;
          // this.netErr = true;
        })
        .catch((err) => {
          console.log("err", err);
          this.list = [];
          this.netErr = true;
          this.emptyType = "network";
          this.finished = true;
          this.refreshing = false;
          this.loading = false;
        });
    },

    onRefresh() {
      this.form.pageSize = 0;
      this.refreshing = false;
      this.onLoad();
    },
    onLoad() {
      if (this.refreshing) return;
      this.loading = true;
      this.form.pageSize += 10;
      this.req_list();
    },

    search() {
      this.form.pageSize = 10;
      this.form.currentPage = 1;
      this.list = [];
      this.loading = true;
      this.req_list();
    },

    change_state(val) {
      this.form.type = val.value;
      this.placeholder = val.placeholder
      // 组装当前页面url
      let href = location.href.split('?')[0]
      let newUrl = href + '?type=' + val.value
      console.log('newUrl', newUrl)
      history.replaceState(null, null, newUrl)
      this.search();
    },

    to_detail(order) {
      const go_detail = (name, query) => {
        this.$router.push({
          name,
          query,
        });
      };
      switch (this.form.type) {
        case "0":
          go_detail("flight-order-detail", {
            orderNo: order.orderNo,
            evectionType: order.evectionType === '出差申请' ? 2 : 1
          });
          break;
        case "1":
          go_detail("flight-refund-order-detail", {
            orderNo: order.orderNo,
            evectionType: order.evectionType === '出差申请' ? 2 : 1
          });
          break;
        case "2":
          go_detail("flight-change-order-detail", {
            orderNo: order.changeOrderNo,
            oriOrderNo: order.orderNo,
            psgNoList: order.psgNo,
            evectionType: order.evectionType === '出差申请' ? 2 : 1
          });
          break;
        case "3":
          go_detail("flight-refund-form-detail", {
            orderNo: order.orderNo,
            evectionType: order.evectionType === '出差申请' ? 2 : 1
          });
          break;
      }
    },

    to_insurance() {},
    cancel_order(orderNo) {
      Dialog.confirm({
        title: "提示",
        message: "确定要取消订单吗?",
        confirmButtonText: '取消订单',
        cancelButtonText: '稍后再说',
      })
        .then(() => {
          this.$showLoading.show();
          consumer_air_ticket_cancelOrder({ orderNo }).then((res) => {
            this.$showLoading.hide();
            Toast.success('取消订单成功');
            // 刷新
            this.req_list();
          });
        })
        .catch(() => {
          this.$showLoading.hide();
        });
    },
    to_payment(orderNo) {
      this.$router.push({
        name: "h5-travel-payment",
        query: {
          orderNo,
          orderType: 1,
          businessType: 1,
          flightOrderList: orderNo,
        },
      });
    },
    change_order(orderNo, evectionNo) {
      this.$router.push({
        name: "flight-change",
        query: {
          orderNo,
          evectionNo,
        },
      });
    },
    refund_order(orderNo, orderStatus) {
      this.$router.push({
        name: "flight-refund",
        query: {
          orderNo,
          orderStatus,
        },
      });
    },
    cancelOrder(orderNo, businessType) {
      Dialog.confirm({
        title: '提示',
        message: '确定要删除订单吗?',
      }).then(() => {
        // on confirm
        let data = {
          businessType,
          orderNo
        };
        this.$showLoading.show();
        consumer_air_ticket_deletionOrder(data).then(res => {
          this.$showLoading.hide();
          Toast.success('删除成功')
          setTimeout(() => {
            this.req_list()
          }, 2000)
        });
      }).catch(() => {
        // on cancel
        this.$showLoading.hide();
      });
    },
    payChange(item) {
      this.$router.push({
        name: "h5-travel-payment",
        query: {
          orderNo: item.changeOrderNo,
          evectionNo: item.evectionNo,
          orderType: 1,
          pageType: 3,
          businessType: 1,
        },
      });
    },
    cancelChange(item) {
      Dialog.confirm({
        title: '提示',
        message: '确定取消改签订单吗?',
      }).then(() => {
            // on confirm
        let data = {
          changeNo: item.changeOrderNo
        };
        this.loading = true
        this.$showLoading.show();
        consumer_air_ticket_cancelChangePay(data).then(res => {
          this.loading = false
          this.$showLoading.hide();
          Toast.success('取消成功')
          this.req_list()
        }).catch(() => {
          this.loading = false
          this.$showLoading.hide();
        });
      }).catch(() => {
            // on cancel
      });
    },
    // 退票
    refund(orderNo, orderStatus) {
      this.$router.push({
        name: "flight-refund",
        query: {
          orderNo: orderNo,
          orderStatus: orderStatus
        },
      })
    },
    showOpaWrapper(order) {
      let isShow = false
      // 以下状态以及不是代订订单才显示
      if ((order.orderStatus === '待支付' || order.orderStatus === '超标审批中' || order.orderStatus === '已出票' || order.orderStatus === '已取消' || order.orderStatus === '部分出票成功') && order.helpOrder !== 1) {
        isShow = true
      }
      return isShow
    },
    showRefundOpaWrapper(order) {
      let isShow = false
      if ((order.orderStatus === '需补款' || order.orderStatus === '改签成功' || order.orderStatus === '已取消') && order.helpOrder !== 1) {
        isShow = true
      }
      return isShow
    },
    onSecretary() {},
  },
  activated() {
    if (this.$route.query.type) {
      this.form.type = this.$route.query.type
      this.statusList.forEach(value => {
        if (value.value === this.form.type) {
          this.placeholder = value.placeholder
        }
      })
    }
    this.search();
  },
};
