import NavBar from "@/component/nav-bar/import_latest_version_module";
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
import TrainCreateOrder from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-create-order/1.1.3/index.vue'//创建订单组件
import TrainCreatePrivateOrder  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-create-private-order/1.0.2/index.vue'
import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import {Toast, Dialog} from "vant";
import moment from "moment";
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar/1.0.1";
export default {
    data() {
        return {
            show: false,
            title: '改签车票',
            orderNo: '',
            evectionNo: '',
            evectionType: '',
            trainDetail: {},
            seatDetail: {},
            userList: '',
            userDetailList: [],
            businessRuleControl: 1,
            form: {
                reason: '出差行程变化',
                otherReason: '',
            },
            showPicker: false,
            columns: ['出差行程变化', '天气因素', '定错车次', '其他'],
        }
    },
    components: {NavBar, TrainTimeTable, TrainCreateOrder, TrainCreatePrivateOrder},
    created() {
    },
    mounted() {
    },
    activated() {
        this._refundChangePolicyGet();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterDay(val) {
            if (!val) {
                return ''
            }
            let day = moment(val).format('YYYY-MM-DD');
            return day
        },
        filterWeek(val) {
            if (!val) {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
    },
    methods: {
        _back() {
            this.show = false;

            return;
            Dialog.confirm({
                title: '温馨提示',
                message: '订单即将完成，您确定放弃预订吗？',
                confirmButtonText: '继续预订',
                cancelButtonText: '放弃预订'
            }).then(() => {

            }).catch(() => {

            });

        },
        _toRead() {
            this.$router.push({
                name: 'h5-train-instructions',
                query: {
                    active: 2,
                    anchor: ''
                }
            });
        },
        _clickSiteNum() {
            this.$refs['aTrainTimeTable'].show();
        },
        // 火车票时刻表组件
        _initTrainTimeTable() {
            this.$refs['aTrainTimeTable'].init({
                //出发站三字码
                fromStationCode: this.trainDetail.fromStationCode,
                //到达站三字码
                toStationCode: this.trainDetail.toStationCode,
                //车次号
                trainCode: this.trainDetail.trainCode,
                //日期(2020-05-18)
                trainDate: moment(this.trainDetail.trainStartDate).format('YYYY-MM-DD'),
            })
        },
        _getDetail() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            consumer_trains_order_getTrainsOrderDetail({
                orderNo: this.orderNo
            }).then((res) => {
                Toast.clear();
                let passengerList = [];
                const userList = this.userList.split(',');
                res.datas.passengers.forEach((value, index) => {
                    if (userList.indexOf(value.userId.toString()) >= 0) {
                        passengerList.push(value)
                    }
                });
                this.userDetailList = passengerList;
            }).catch(() => {
                Toast.clear();
            });
        },
        _opened() {
            const _this = this;
            let test = setInterval(() => {
                if (this.$refs.searchHeader !== undefined) {
                    fixStatusBar({
                        type: `000`,
                        handler({default_handler, api}) {
                            const bodyHeight = document.body.clientHeight;
                            const addPaddingTop = (api.safeArea.top)/(bodyHeight/100);
                            _this.$refs['searchHeader'].$el.style.paddingTop = `${addPaddingTop}vh`;
                        }
                    });
                    clearInterval(test);
                }
            },100);
            // fixStatusBar({
            //     type: `000`,
            //     handler({default_handler, api}) {
            //         const bodyHeight = document.body.clientHeight;
            //         let oriPaddingBottom = topElement.style.paddingBottom;
            //         if (oriPaddingBottom >= 414) {
            //             oriPaddingBottom = oriPaddingBottom * 2
            //         }
            //         let currentPaddingBottom = (oriPaddingBottom || 0)/(bodyHeight/100);
            //         const addPaddingBottom = (api.safeArea.top)/(bodyHeight/100);
            //         _this.$refs['foot'].style.paddingBottom = (addPaddingBottom + currentPaddingBottom) + 'vh';
            //     }
            // });
        },
        async _refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        _checkForm() {
            return new Promise(resolve => {
                this.$refs.form.validate().then(() => {
                    resolve(true)
                }).catch(() => {
                    resolve(false)
                })
            })
        },
        async _toSubmit() {
            // 校验
            let checkResult = await this._checkForm();
            if (!checkResult) {
                return
            }
            let params = {
                changeArriveDate: this.$moment(this.trainDetail.arriveDate).format('YYYY-MM-DD'),
                changeArriveTime: this.trainDetail.toTime,
                changeFromStationCode: this.trainDetail.fromStationCode,
                changeFromStationName: this.trainDetail.fromStationName,
                changeSeatCode: this.seatDetail.seatCode,
                changeSeatName: this.seatDetail.seatName,
                changeSeatPrice: this.seatDetail.seatPrice,
                changeStartDate: this.$moment(this.trainDetail.trainStartDate).format('YYYY-MM-DD'),
                changeStartTime: this.trainDetail.fromTime,
                changeToStationCode: this.trainDetail.toStationCode,
                changeToStationName: this.trainDetail.toStationName,
                changeTrainCode: this.trainDetail.trainCode,
                gmtChangeArrive: this.$moment(this.trainDetail.arriveDate).format('YYYY-MM-DD') + ' ' + this.trainDetail.toTime + ':00',
                gmtChangeStart: this.$moment(this.trainDetail.trainStartDate).format('YYYY-MM-DD') + ' ' + this.trainDetail.fromTime + ':00',
                orderNo: this.orderNo,
                changeNo: ''
            };
            let userIds = [];
            let passengerSns = [];
            let changeAdultCounts = 0; // 改签成人人数
            let changeChildrenCounts = 0; // 改签儿童人数
            this.userDetailList.forEach(value => {
                passengerSns.push(value.sn);
                userIds.push(value.userId);
                if (value.passengerType === 2) {
                    changeChildrenCounts++;
                } else {
                    changeAdultCounts++;
                }
            });
            let params2 = {
                changeAdultCounts: changeAdultCounts,
                changeChildrenCounts: changeChildrenCounts,
                changeHeadCounts: this.userDetailList.length,
                orderNo: this.orderNo,
                passengerSns: passengerSns,
                changeNo: '',
                overproofApplyList: [],
                reason: this.form.reason === '其他' ? this.form.otherReason : this.form.reason,
                otherReason: this.form.otherReason,
                scheduledWay: this.evectionType,
            };
            let resultParams = {
                type: '100',
                mock_data: {
                    enable: false,
                    mode: ''
                },
                evectionType: this.evectionType,           //出差类型
                workTravelType: '',      //因公出差的类型
                evectionNo: this.evectionNo,//出差单号
                orderNo: this.orderNo,//订单号
                trainInfo: {
                    fromStationName: this.trainDetail.fromStationName,
                    toStationName: this.trainDetail.toStationName,
                    trainNo: this.trainDetail.trainCode,
                    startDate: this.$moment(this.trainDetail.trainStartDate).format('YYYY-MM-DD'),
                    spanTime: this.trainDetail.fromTime,
                },
                userList: this.userDetailList,       //出行人列表
                businessRuleControl: this.businessRuleControl,
                advanceRebookParams: params,
                rebookParams: params2
            };
            console.log('resultParams', resultParams);
            if (this.evectionType === 2) {
              //  因公
              this.$set(resultParams,'changeOrderBookingParams',params2);
              this.$refs.aTrainCreateOrder.run(resultParams)
            } else {
              //  因私
              this.$set(resultParams,'data',{});
              this.$set(resultParams.data,'orderNo',resultParams.orderNo);
              this.$set(resultParams.data,'trainInfo',resultParams.trainInfo);
              this.$set(resultParams.data,'userList',resultParams.userList);
              this.$set(resultParams,'advanceRebookParams',params);
              this.$set(resultParams,'changeOrderBookingParams',params2);
              this.$refs.aTrainCreatePrivateOrder.run(resultParams)
            }
        },
        _showReasonPicker() {
            this.showPicker = true;
        },
        _closeReasonPicker() {
            this.showPicker = false;
        },
        _onReasonConfirm(value) {
            this.form.reason = value;
            this._closeReasonPicker();
        },

        init(params) {
            this.show = true;
            this.orderNo = params.orderNo;
            this.evectionNo = params.evectionNo;
            this.evectionType = params.evectionType;
            this.trainDetail = params.trainDetail;
            this.userList = params.userList;
            this.seatDetail = params.seatDetail;
            this.form = {
                reason: '出差行程变化',
                otherReason: '',
            };
            this._getDetail();
            setTimeout(() => {
                this._initTrainTimeTable();
            }, 500)
        },
        get_show() {
            const lineShow = this.$refs['aTrainTimeTable'].get_show();
            const showTrainCreateOrder = this.$refs.aTrainCreateOrder.verificationDialogVisible;
            return this.show || lineShow || showTrainCreateOrder
        },
        hide() {
            const lineShow = this.$refs['aTrainTimeTable'].get_show();
            if (lineShow) {
                this.$refs['aTrainTimeTable'].hide();
                return
            }
            const aTrainCreateOrder = this.$refs.aTrainCreateOrder;
            const show_aTrainCreateOrder = aTrainCreateOrder.verificationDialogVisible;
            if (show_aTrainCreateOrder) {
                aTrainCreateOrder.closeVerificationDialog();
                return;
            }
            this.show = false;
        },
    }
}