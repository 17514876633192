import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/office/notice',
            name: 'h5-office-notice',
            component: () => import(/* webpackChunkName: "page-office-notice" */ `./index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '公告',
                    need_head_nav: true,
                    need_footer_bar: true,
                    need_alive: true,
                    secondary_menu: 3,
                }
            },
        }
    ],
};
