import moment from "moment";
import {Toast} from "vant";
export default {
    props: {
        value: {
            type: Object,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            quantitySelected: 0,
            choiceDataArr:[],
            // 特等座选位
            businessSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {label: 'F', value: '2F', active: false}]
            ],
            // 一等座选位
            firstSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
            ],
            // 二等座选位
            secondSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'B', value: '1B', active: false}, {label: 'C', value: '1C', active: false}, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'B', value: '2B', active: false}, {label: 'C', value: '2C', active: false}, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
            ],
            choiceSeat:{
                canChooseSeat: null,
                numberOfSeats: null,
            }
        }
    },
    activated(){

    },
    methods: {
        getDataMethod(){
            let data = [];
            this.choiceDataArr.forEach((item,index) => {
               item.forEach((CItem,CIndex) => {
                   if (CItem.active) {
                       data.push(CItem);
                   }
               });
            });

            return data;
        },
        displayClick(index,CIndex){
            if (this.choiceSeat.quantity == 0) {
                Toast({
                    message: '请先添加乘车人',
                    position: 'bottom',
                });
            } else if (this.quantitySelected >= this.choiceSeat.quantity && !this.choiceDataArr[index][CIndex].active) {
                Toast({
                    message: '选择数量已超出范围',
                    position: 'bottom',
                });
            } else {
                this.choiceDataArr[index][CIndex].active = !this.choiceDataArr[index][CIndex].active;
                if (this.choiceDataArr[index][CIndex].active) {
                    this.quantitySelected++
                } else {
                    this.quantitySelected--
                }
            }

            this.$emit('TravelChoiceChange', this.getDataMethod());
        },
        reformData(){
            if (this.choiceSeat.numberOfSeats == 5) {
                if (this.secondSeatArr[0].length == 5) {
                    this.secondSeatArr.forEach((item,index) => {
                        item.splice(3,0,{});
                    });
                }
                this.choiceDataArr = this.secondSeatArr;
                console.log(this.secondSeatArr);
            } else if (this.choiceSeat.numberOfSeats == 4) {
                if (this.firstSeatArr[0].length == 4) {
                    this.firstSeatArr.forEach((item,index) => {
                        item.splice(2,0,{});
                    });
                }
                this.choiceDataArr = this.firstSeatArr;
                console.log(this.firstSeatArr);
            } else if (this.choiceSeat.numberOfSeats == 3) {
                if (this.businessSeatArr[0].length == 3) {
                    this.businessSeatArr.forEach((item,index) => {
                        item.splice(2,0,{});
                    });
                }
                this.choiceDataArr = this.businessSeatArr;
                console.log(this.businessSeatArr);
            }
        },
    },
    watch: {
        value: {
            handler(newValue, pOldValue) {
                if (newValue.canChooseSeat != null) {
                    this.choiceSeat = {
                        canChooseSeat: newValue.canChooseSeat,
                        numberOfSeats: newValue.numberOfSeats,
                        quantity: newValue.quantity,
                        // numberOfSeats: 5,
                    };
                    this.quantitySelected = 0;
                    this.choiceDataArr.forEach((item,index) => {
                       item.forEach((CItem,CIndex) => {
                           CItem.active = false;
                       })
                    });
                    if (pOldValue && pOldValue.canChooseSeat == null) {
                        //model返回数据对数组改造
                        this.reformData()
                    }
                }
            },
            immediate: true,
        },
    },
    mounted(){

    },
    created() {

    },
}