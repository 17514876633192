import moment from "moment";
import DomesticTicketAirportSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/domestic-ticket-airport-selector/1.2.1";
import Transfer from "@/page/travel-book/flight/component/transfer/index.vue";
import ReservePopupBox from "@/page/travel-book/flight/component/reservePopupBox/import_latest_version_module";
import ChooseTraveler from '@/component/choose-traveler/1.0.5/index.vue'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import ChangeEvectionCity from '@/component/change-evection-city/1.0.0/index.vue'
import {Toast, Dialog} from "vant";
import getJourneyOptionsCanBeModified from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'

import slider_left_back from "@/lib/common-service/slider_left_back/index";// 滑屏返回
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";

import refund_change_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get';
import consumer_web_policyConfig_myTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_myTravelPolicy'
import consumer_evection_addOrUpdate from "@/lib/data-service/haolv-default/consumer_evection_addOrUpdate";
import consumer_user_historyCity from '@/lib/data-service/haolv-default/consumer_user_historyCity'
import consumer_web_policyConfig_getAllowChangeCities from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getAllowChangeCities'
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail'
import consumer_journey_getEvectionDetailV2 from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetailV2';
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import {awaitWrap} from "../../common/unit";
import MyEvectionRulePopup from "@/page/travel-book/flight/component/myEvectionRulePopup/index.vue";
import MyEvectionRuleNewPopup from "@/page/travel-book/flight/component/myEvectionRuleNewPopup/index.vue";
import {hideEvectionTypeChangeTabCompanyIdList} from '@/lib/ohter/index.js'
import consumer_web_policyConfig_getUserTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getUserTravelPolicy';
import consumer_flight_search_citys from '@/lib/data-service/haolv-default/consumer_flight_search_citys';
export default {
    data() {
        return {
            showTabBar: false,
            loading: false,
            selectedStartFlightId: "", // 出发站id
            startFlightParams: {}, // 出发站信息
            selectedArriveFlightId: "", // 到达站id
            arriveFlightParams: {}, // 到达站信息
            userInfo: {}, // 用户信息
            businessTravelList: [], // 出差列表
            travelCurrent: null, // 当前出差,
            activeChooseUserList: [], // 当前选中的出行人
            userDelPopupShow: false, // 出行人列表删除弹出框

            flightType: 1, // 1单程，2往返
            isShowCalendar: false,
            flightDate: "", // 单程时的出发时间
            popupShow: false,
            travelPopupShow: false,
            isShowRangeCalendar: false,
            flightStartDate: "", // 往返时的出发时间
            flightEndDate: "", // 往返时的到达时间
            allUserItems: [], // 全部员工列表
            activeUserIds: [], // 选中的员工id
            activeUserIndex: 0, //
            travelUserSelectPopupShow: false, // 出差单情况下选择出行人
            canChooseUserList: [], // 出差单情况下可以选择的出行人
            activeCanChooseUserList: [], // 出差单情况下选中的出行人

            useTravel: 1, // 是否使用出差


            evectionRequired: 0, // 出差单是否必填

            evectionRulePopupShow: false,
            evectionRulePopupNewShow: false,
            myTravelPolicy: null,
            bookUpperBound: 6,
            unlimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            changeEvectionCity: 0, // 是否可选临近城市 0:否, 1:是
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            isFreeApprove: 0, // 是否免审：1.是，0.否

            applyJourneyId: 0,

            cityCanEdit: true,
            timeCanEdit: true,

            journeyDetail: {},

            formSelectedIdList: [],
            toSelectedIdList: [],

            evectionNo: '',
            evectionBackType: '000', // 回显的类型。 '000':正常模式，默认； '001'： 前海人寿，回显出差单
            evectionDetail: null, // 出差信息

            policyLoading: false,
            days: 0,//往返间隔天数
        };
    },
    components: {
        DomesticTicketAirportSelector,
        Transfer,
        ReservePopupBox,
        ChooseTraveler,
        NavBar,
        ChangeEvectionCity,
        MyEvectionRulePopup,
        MyEvectionRuleNewPopup
    },
    created() {
    },
    async mounted() {
        const __this = this;
        fixStatusBar({
            type: `100`,
            selector: `.flight-bg`,
        });
        fixStatusBar({
            type: `100`,
            selector: `.flight-cont`,
        });
        this.$refs.startAirportSelector.init({
            input: {
                placeholder: '出发城市(如北京/beijing/bj)'
            }
        })
        this.$refs.arriveAirportSelector.init({
            input: {
                placeholder: '目的城市(如北京/beijing/bj)'
            }
        })
        await this.getUserInfo()

        slider_left_back(this.$refs.flight_home, this);
    },
    async activated() {
        const __this = this;
        this.loading = true; // 回显未返回前不能点击

        /*keyback_processor.addEventListener(function () {
            const startAirportSelector = __this.$refs.startAirportSelector;
            const show_startAirportSelector = startAirportSelector.get_show();
            if (show_startAirportSelector) {
                startAirportSelector.hide();
                return;
            }

            const arriveAirportSelector = __this.$refs.arriveAirportSelector;
            const show_arriveAirportSelector = arriveAirportSelector.get_show();
            if (show_arriveAirportSelector) {
                arriveAirportSelector.hide();
                return;
            }

            const formChangeEvectionCity = __this.$refs.formChangeEvectionCity;
            const showFormChangeEvectionCity = formChangeEvectionCity.get_show()
            if (showFormChangeEvectionCity) {
                formChangeEvectionCity.hide()
                return;
            }
            const toChangeEvectionCity = __this.$refs.toChangeEvectionCity;
            const showToChangeEvectionCity = toChangeEvectionCity.get_show()
            if (showToChangeEvectionCity) {
                toChangeEvectionCity.hide()
                return;
            }

            const isShowCalendar = __this.isShowCalendar;
            if (isShowCalendar) {
                __this.isShowCalendar = false;
                return;
            }

            if (__this.evectionRulePopupShow) {
                __this.evectionRulePopupShow = false
                return
            }

            const reservePopupBox = __this.$refs.reservePopupBox
            if (reservePopupBox.getShow()) {
                reservePopupBox.closeEvectionlPopup()
                return
            }

            const traveler = __this.$refs.traveler;
            const show_traveler = traveler.get_show();
            if (show_traveler) {
                traveler.hide();
                return;
            }

            __this.back()
        });*/

        try {
            await this.refundChangePolicyGet()
        } catch (e) {

        }
        this.evectionNo = this.$route.query.evectionNo || '';
        this.evectionBackType = this.$route.query.evectionBackType || '000';
        // 判断是否是搜索页返回，地址栏带参数
        if (this.evectionNo && this.evectionBackType === '000') {
            const query = this.$route.query
            const evectionNo = query.evectionNo
            const journeyId = parseInt(query.journeyId) || 0;
            this.flightDate = query.startDate
            consumer_journey_getEvectionDetailV2({
                evectionNo,
            }).then(res => {
                this.evectionDetail = res.datas;
                // 如果不是有选出差单
                if (journeyId === 0) {
                    this.setDefault();
                    this.setHistory();
                }
                // 因公出行 且
                if (this.useTravel === 1 && this.businessRuleControl === 1) {
                    console.log('进入设置出差单');
                    this.$refs['reservePopupBox'].setJourneyId({journeyId})
                } else {
                    this.$nextTick(() => {
                        const params = {
                            travelUserList: this.evectionDetail.psgList
                        };
                        this.$refs['traveler'].setActiveUserList(params);
                        this.evectionDetail = null
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        } else if (this.evectionNo && this.evectionBackType === '001') {
            // 回显出差单号
            console.log('11222', this.$refs['reservePopupBox'], this.$route.query.journeyId);
            this.$refs['reservePopupBox'].setJourneyId({journeyId: parseInt(this.$route.query.journeyId || '0')});
            // 回显城市
            // 回显人
            this.loading = false
        } else {
            this.setDefault();
            this.setHistory();
            this.loading = false;
        }
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {
        flightDate(newVal, oldVal) {
            console.log('flightDate', newVal, oldVal)
        },
        selectedStartFlightId(newVal, oldVal) {
            console.log('1', newVal, oldVal);
            this.$nextTick(() => {
                // this.startFlightParams = this.$refs.startAirportSelector.get_selected_entity();
                this.$refs.startAirportSelector.get_selected_entity_promise({
                    id: newVal
                }).then((entity) => {
                    this.startFlightParams = entity || {}
                })
            });

            // 当是不限行程，且第一次选了出行城市之后
            if (newVal && !oldVal && this.travelCurrent && this.travelCurrent.active.journeyType === 1) {
                if (!this.selectedArriveFlightId) {
                    this.openArriveFlightPopup();
                } else if (!this.flightDate) {
                    this.showCalendar();
                }

            }
        },
        selectedArriveFlightId(newVal, oldVal) {
            this.$nextTick(() => {
                // this.arriveFlightParams = this.$refs.arriveAirportSelector.get_selected_entity();
                this.$refs.arriveAirportSelector.get_selected_entity_promise({
                    id: newVal
                }).then((entity) => {
                    this.arriveFlightParams = entity || {}
                })
            });
            // 当是不限行程，且第一次选了目的城市之后
            if (newVal && !oldVal && this.travelCurrent && this.travelCurrent.active.journeyType === 1 && !this.flightDate) {
                this.showCalendar();
            }
        },
        travelCurrent(newVal) {
            if (newVal === null) {
                this.activeChooseUserList = []
                this.applyJourneyId = 0;
                if (this.useTravel === 1 && this.bookUpperBound === 1 && this.isFreeApprove === 0) {
                    this.activeChooseUserList = [{
                        userId: this.userInfo.id,
                        staffName: this.userInfo.realName
                    }]
                }
            } else {
                this.applyJourneyId = newVal.active.id;
            }

            this.canChangeFactor()
        },
        useTravel(newVal) {
            this.canChangeFactor()
        },
    },
    computed: {},
    filters: {
        filterFlightDate(val) {
            return val ? moment(val).format("YYYY年MM月DD日") : '';
        },
        filterFlightDaY(val) {
            return val ? moment(val).format("MM月DD日") : '';
        },
        filterWeek(val) {
            if (!val) {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
        filterCabin(val) {
            if (val === 1) {
                return "头等舱";
            } else if (val === 2) {
                return "公务舱";
            } else if (val === 3) {
                return "经济舱";
            } else {
                return "";
            }
        },
        filterClassCabin(val) {
            if (val === '1,2') {
                return "头等/商务舱";
            } else if (val === '3') {
                return "经济舱";
            } else {
                return "";
            }
        },
    },
    methods: {
        canChangeFactor() {
            getJourneyOptionsCanBeModified({
                situation: '100',
                vm: this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit
                this.timeCanEdit = res.timeCanEdit
            })
        },
        async getUserInfo() {
            let res = await get_user_info();
            this.userInfo = res.datas;
            if (hideEvectionTypeChangeTabCompanyIdList.indexOf(this.userInfo.companyId) > -1) {
                this.showTabBar = false;
            } else  {
                this.showTabBar = true;
            }
        },
        setHistory() {
            consumer_user_historyCity().then(res => {
                let data = res.datas
                this.selectedStartFlightId = data && data.flightHistoryInfo && data.flightHistoryInfo.depCityPortCode ? data.flightHistoryInfo.depCityPortCode : 'CAN'
                this.selectedArriveFlightId = data && data.flightHistoryInfo && data.flightHistoryInfo.arrCityPortCode ? data.flightHistoryInfo.arrCityPortCode : 'BJS'
            }, error => {
                this.selectedStartFlightId = 'CAN'
                this.selectedArriveFlightId = 'BJS'
            })
        },
        setDefault() {
            this.selectedStartFlightId = "" // 出发站id
            this.startFlightParams = {} // 出发站信息
            this.selectedArriveFlightId = "" // 到达站id
            this.arriveFlightParams = {} // 到达站信息
            this.travelCurrent = null // 当前出差,
            this.flightDate = moment().format('YYYY-MM-DD')
            this.flightStartDate = moment().format('YYYY-MM-DD')
            this.flightEndDate = moment().add(1, 'days').format('YYYY-MM-DD')
            this.days = this.$moment(this.flightEndDate).diff(moment(this.flightStartDate), "days");

        },
        // 判断机场字段的长短返回样式
        inputFontLong(val) {
            if (val && val.length > 0 && val.length < 3) {
                return "";
            } else {
                return "small";
            }
        },
        change_page(page) {
            if (page === 1) {
                this.$router.push({
                    name: "h5-hotel-home",
                });
            } else if (page === 2) {
                this.$router.push({
                    name: "h5-train-home",
                });
            }
        },
        showCalendar() {
            if (!this.timeCanEdit && this.travelCurrent !== null) {
                Toast('不能修改出差单既定行程时间')
                return
            }
            if (!this.timeCanEdit && this.travelCurrent === null) {
                Toast('请先选择出差单')
                return
            }
            this.isShowCalendar = true;
        },
        onCalendarConfirm(data) {
            this.isShowCalendar = false;
            this.flightDate = moment(data).format("YYYY-MM-DD");
        },
        chooseUser() {
            if (this.evectionRequired === 1 && this.travelCurrent === null) {
                return
            }
            let activeUserIds = [];
            this.activeChooseUserList.forEach((value) => {
                activeUserIds.push(value.id);
            });
            this.activeUserIds = activeUserIds; // 不存在因私的情况了
            if (this.travelCurrent === null) {
                this.popupShow = true
            } else {
                this.activeCanChooseUserList = activeUserIds
                this.travelUserSelectPopupShow = true
            }
            //this.activeCanChooseUserList = activeUserIds;
            //this.travelUserSelectPopupShow = true;
        },
        cancelChooseUser() {
            this.popupShow = false;
        },
        submitChooseUser() {
            let activeChooseUserList = [];
            this.allUserItems.forEach((value) => {
                value.children.forEach((val) => {
                    if (this.activeUserIds.indexOf(val.id) > -1) {
                        activeChooseUserList.push(val);
                    }
                });
            });
            this.activeChooseUserList = activeChooseUserList;
        },
        chooseTravel() {
            this.travelPopupShow = true;
        },
        showRangeCalendar() {
            if (!this.timeCanEdit && this.travelCurrent !== null) {
                Toast('不能修改出差单既定行程')
                return
            }
            if (!this.timeCanEdit && this.travelCurrent === null) {
                Toast('请先选择出差单')
                return
            }
            this.isShowRangeCalendar = true;
        },
        onCalendarRangeConfirm(data) {
            this.isShowRangeCalendar = false;
            this.flightStartDate = moment(data[0]).format("YYYY-MM-DD");
            this.flightEndDate = moment(data[1]).format("YYYY-MM-DD");
            this.days = this.$moment(this.flightEndDate).diff(moment(this.flightStartDate), "days");
        },
        // 出发站到达站转换
        changeCity() {
            if (!this.cityCanEdit || this.travelCurrent !== null) {
                return
            }
            let selectedStartFlightId = this.selectedStartFlightId;
            let selectedArriveFlightId = this.selectedArriveFlightId;
            this.selectedStartFlightId = selectedArriveFlightId;
            this.selectedArriveFlightId = selectedStartFlightId;
            /*let fromStationCode = Object.assign({}, this.arriveFlightParams)
                  let toStationCode = Object.assign({}, this.startFlightParams)
                  this.startFlightParams = fromStationCode
                  this.arriveFlightParams = toStationCode*/
        },
        // 打开出发站选择弹框
        openStartFlightPopup() {
            if ((!this.cityCanEdit || this.unlimitedJourney === 0) && this.travelCurrent !== null && this.changeEvectionCity === 0) {
                Toast('不能修改出差单既定行程')
                return
            }
            if (!this.cityCanEdit && this.travelCurrent === null) {
                Toast('请先选择出差单')
                return
            }
            if (this.changeEvectionCity === 1 && this.journeyDetail.departCityChange === 1) {
                this.$refs.formChangeEvectionCity.show()
            } else {
                this.$refs.startAirportSelector.show();
            }

        },
        // 打开到达站选择弹框
        openArriveFlightPopup() {
            if ((!this.cityCanEdit || this.unlimitedJourney === 0) && this.travelCurrent !== null && this.changeEvectionCity === 0) {
                Toast('不能修改出差单既定行程')
                return
            }
            if (!this.cityCanEdit && this.travelCurrent === null) {
                Toast('请先选择出差单')
                return
            }
            if (this.changeEvectionCity === 1 && this.journeyDetail.toCityChange === 1) {
                this.$refs.toChangeEvectionCity.show()
            } else {
                this.$refs.arriveAirportSelector.show();
            }
        },
        // 获取出差预设列表
        /*getBusinessTravel() {
            let params = {
                pageSize: 100,
                currentPage: 1,
                status: 2,
                approveType: 1,
            };
            consumer_apply_myApplyList(params).then((res) => {
                this.businessTravelList = res.datas.list;
            });
        },*/
        // 选中出差单触发
        getCurrentEvection(newVal) {
            console.log('newVal', newVal)
            let __this = this
            if (newVal === null) {
                this.activeChooseUserList = [];
                this.journeyDetail = {}
                this.setHistory()
                if (this.evectionDetail) {
                    this.$nextTick(() => {
                        const params = {
                            travelUserList: this.evectionDetail.psgList
                        };
                        this.$refs['traveler'].setActiveUserList(params);
                        this.evectionDetail = null
                    })
                }
            } else {
                this.flightType = newVal.active.singleReturnType;
                // 更新城市和日期
                this.selectedStartFlightId = newVal.active.departCityId
                this.selectedArriveFlightId = newVal.active.toCityId
                // 如果不限行程，则不赋值城市
                if (newVal.active.journeyType === 1) {
                    this.selectedStartFlightId = '';
                    this.selectedArriveFlightId = '';
                }

                if (!this.evectionDetail) {
                    // 如果不是回显的情况
                    if (newVal.active.journeyType === 1) {
                        // 如果是不限行程
                        /*let startDate = newVal.active.departTime;
                        let momentStartDate = moment(startDate + ' 00:00:00').valueOf();
                        let today = moment().valueOf();
                        if (momentStartDate < today) {
                            // 如果出发日期小于今天，就是已过期，则选今天
                            this.flightDate = moment().format('YYYY-MM-DD');
                        } else {
                            this.flightDate = startDate;
                        }*/
                        // 2023-08-28 不限行程的时候，不自动填入出行行程
                        this.flightDate = '';
                    } else {
                        this.flightDate = newVal.active.tripDate;

                        this.flightStartDate = newVal.active.tripDate || newVal.active.departTime;
                        this.flightEndDate = newVal.active.returnTime ? newVal.active.returnTime : '';
                        this.days = this.$moment(this.flightEndDate).diff(moment(this.flightStartDate), "days");
                    }
                }
                // this.flightDate = this.evectionDetail ? this.flightDate : (newVal.active.tripDate || newVal.active.departTime) // 如果有回传出发日期


                console.log('2222', newVal.active.staffList)
                let [...staffList] = newVal.active.staffList;
                staffList = staffList.filter(value => value.bookStatus === 1)
                let [...canStaffList] = newVal.active.staffList;
                canStaffList = canStaffList.filter(value => value.bookStatus === 1)
                this.canChooseUserList = canStaffList
                staffList.forEach((value) => {
                    value.id = value.userId;
                    value.text = value.staffName;
                });
                let resultStaffList = []
                // 只能选一个人
                if (this.bookUpperBound === 1) {
                    let getMyself = false
                    staffList.forEach(value => {
                        if (value.id === this.userInfo.id) {
                            resultStaffList.push(value)
                            getMyself = true
                        }
                    })
                } else {
                    staffList = staffList.filter(value => value.bookStatus === 1)
                    staffList.forEach((value, index) => {
                        if (index < this.bookUpperBound) {
                            resultStaffList.push(value)
                        }
                    })
                }
                this.activeChooseUserList = resultStaffList;

                if (this.activeChooseUserList.length === 0) {
                    Toast('该行程没有可选的出行人')
                }

                if (this.changeEvectionCity) {
                    consumer_web_policyConfig_getAllowChangeCities({journeyId: this.travelCurrent.active.id}).then(res => {
                        this.journeyDetail = res.datas
                        if (this.journeyDetail.departCityChange === 1) {
                            this.formSelectedIdList = [this.selectedStartFlightId]
                            this.$refs.formChangeEvectionCity.init({
                                dialog: {
                                    title: `出发城市`,
                                },
                                get_data_source() {
                                    return new Promise((resolve, reject) => {
                                        let list = __this.journeyDetail.departSelectCities
                                        resolve({all_entity_list: list})
                                    })
                                },
                                btn_ok_click_event_handler(res) {
                                    let list = res.selected_entity_list
                                    __this.selectedStartFlightId = list[0].cityCode
                                    __this.startFlightParams = {
                                        name: list[0].cityName,
                                        code: list[0].cityCode
                                    }
                                },
                            });
                        }
                        if (this.journeyDetail.toCityChange === 1) {
                            this.toSelectedIdList = [this.selectedArriveFlightId]
                            this.$refs.toChangeEvectionCity.init({
                                dialog: {
                                    title: `到达城市`,
                                },
                                get_data_source() {
                                    return new Promise((resolve, reject) => {
                                        let list = __this.journeyDetail.toSelectCities
                                        resolve({all_entity_list: list})
                                    })
                                },
                                btn_ok_click_event_handler(res) {
                                    let list = res.selected_entity_list
                                    __this.selectedArriveFlightId = list[0].cityCode
                                    __this.arriveFlightParams = {
                                        name: list[0].cityName,
                                        code: list[0].cityCode
                                    }
                                },
                            });
                        }
                    })
                }

                // 如果是不限行程，则弹出城市选择
                if (newVal.active.journeyType === 1 && this.evectionBackType === '000') {
                    // 触发选择出发城市的弹窗方法
                    this.openStartFlightPopup();
                }
                if (newVal.active.journeyType === 1 && this.evectionBackType === '001') {
                    // 如果传过来的日期是当前日期以前的，则变成今天
                    let startDate = this.$route.query.startDate;
                    console.log('startDate',startDate);
                    const diff = moment().diff(startDate, 'days');
                    console.log(diff);
                    if (diff > 0) {
                        this.flightDate = moment().format('yyyy-MM-DD');
                    } else {
                        this.flightDate = this.$route.query.startDate;
                    }

                    // 回显城市
                    consumer_flight_search_citys().then(res=>{
                        let cityList = res.citys;
                        cityList.forEach(value=>{
                            if (value.name === this.$route.query.fromStationName) {
                                this.selectedStartFlightId = value.code;
                                this.startFlightParams = {
                                    code: value.code,
                                    name: value.name,
                                }
                            }
                            if (value.name === this.$route.query.toStationName) {
                                this.selectedArriveFlightId = value.code;
                                this.arriveFlightParams = {
                                    code: value.code,
                                    name: value.name,
                                }
                            }
                        })
                    })

                }

            }

        },
        getSelectedStaffIdList(selectedStaffIdList) {
            console.log('获取到出行人');
            this.activeChooseUserList = selectedStaffIdList;
            // 清除掉出差单详情
            this.evectionDetail = null;
        },
        // 提交搜索
        async searchSubmit() {
            console.log('12121');
            if(!this.selectedStartFlightId) {
                Toast.fail('请选择出发城市')
                return
            }
            if(!this.selectedArriveFlightId) {
                Toast.fail('请选择目的城市')
                return
            }
            if (!this.flightDate) {
                Toast.fail('请选择出行日期')
                return
            }
            // 当出行人大于帮订人数
            if (this.useTravel === 1 && this.travelCurrent === null && this.isFreeApprove === 0 && this.activeChooseUserList.length > this.bookUpperBound) {
                Toast.fail('出行人不能大于帮订人数')
                return
            }
            if (this.useTravel === 1 && this.travelCurrent === null && this.isFreeApprove === 1 && this.activeChooseUserList.length > 6) {
                Toast.fail('出行人不能大于帮订人数')
                return
            }
            if (this.useTravel === 1) {
                let [err, res] = await awaitWrap(this.$refs.traveler.check())
                if (err) {
                    if (err.type === '001') {
                        Toast.fail(err.msg)
                    }
                    return
                }
            }
            let staffList = [];
            this.activeChooseUserList.forEach((value) => {
                staffList.push(value);
            });
            if (this.useTravel === 1) {
                let params = {
                    journeyId: this.travelCurrent ? this.travelCurrent.active.id : 0,
                    applyId: this.travelCurrent ? this.travelCurrent.id : "",
                    endDate: this.travelCurrent ? this.travelCurrent.endDate : "",
                    evectionNo: this.travelCurrent ? this.travelCurrent.evectionNo : "",
                    evectionType: this.useTravel === 1 || this.businessRuleControl === 2 ? 2 : 1, // 1是个人出差，2是出差单出差
                    startDate: this.travelCurrent ? this.travelCurrent.startDate : "",
                    travellerList: staffList,
                    personType: 2,
                    //cabin: this.cabin,
                };
                this.$showLoading.show();
                let [err, res] = await awaitWrap(consumer_evection_addOrUpdate(params));
                if (err) {
                    this.$showLoading.hide();
                    return
                }
                this.$showLoading.hide();
                this.toSearchPage(res);
            } else {
                this.toPersonalSearchPage();
            }

        },
        toSearchPage(res) {
            this.$router.push({
                // name: this.flightType === 2 ? "flight-return-search" : "flight-business-search",
                // name: this.flightType === 2 ? "flight-return-search" : "flight-search",
                name: "flight-search",
                query: {
                    fromStationCode: this.selectedStartFlightId,
                    fromStationName: this.startFlightParams.name,
                    toStationCode: this.selectedArriveFlightId,
                    toStationName: this.arriveFlightParams.name,
                    startDate: this.flightType === 2 ? this.flightStartDate : this.flightDate,
                    endDate: this.flightType === 2 ? this.flightEndDate : "",
                    flightType: this.flightType,
                    evectionNo: res ? res.datas : "",
                    redirectType: "000",
                    evectionType: this.useTravel === 1 ? 2 : 1, // 1是个人出差，2是出差单出差
                    //cabin: this.cabin,
                    evectionRequired: this.evectionRequired,
                    workTravelType: this.travelCurrent ? 1 : 2, // 因公出差的类型， 1是有选出差单，2是没有
                    applyId: this.travelCurrent ? this.travelCurrent.id : "",
                    startRangeDate: this.travelCurrent ? this.travelCurrent.active.departTime : '',
                    endRangeDate: this.travelCurrent ? this.travelCurrent.active.departTimeEnd : '',
                    journeyType: this.travelCurrent ? this.travelCurrent.active.journeyType : 0, // 0 普通单， 1 不限行程单
                    journeyId: this.travelCurrent ? this.travelCurrent.active.id : 0,
                    flightIndex: 1,
                    evectionBackType: this.evectionBackType,
                },
            });
        },
        toPersonalSearchPage() {
            this.$router.push({
                name: "flight-search",
                query: {
                    fromStationCode: this.selectedStartFlightId,
                    fromStationName: this.startFlightParams.name,
                    toStationCode: this.selectedArriveFlightId,
                    toStationName: this.arriveFlightParams.name,
                    startDate: this.flightType === 2 ? this.flightStartDate : this.flightDate,
                    endDate: this.flightType === 2 ? this.flightEndDate : "",
                    flightType: this.flightType,
                    evectionType: this.useTravel === 1 ? 2 : 1, // 1是个人出差，2是出差单出差
                    flightIndex: 1,
                },
            });
        },

        // showCabinPopup() {
        //     this.cabinPopupShow = true;
        //     this.setCabin = this.cabin;
        // },
        // cancelCabinToClose() {
        //     this.cabinPopupShow = false;
        //     this.setCabin = this.cabin;
        // },
        // submitCabinToClose() {
        //     this.cabinPopupShow = false;
        //     this.cabin = this.setCabin;
        // },
        // changeCabin(val) {
        //     this.submitCabinToClose()
        // },

        // 切换出差类型时触发
        changeTravelType() {
            this.travelCurrent = null;
            this.activeChooseUserList = [];
        },
        changeTravelTabType(val) {
            this.useTravel = val
            this.changeTravelType()
        },
        back() {
            // this.$router.go(-1)
            if(this.$route.query.type === 'new') {
                this.$router.go(-1)
            } else {
                this.$router.replace({
                    name: 'h5-home',
                })
            }
        },

        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.evectionRequired = res.datas.evectionRequired;
            this.bookUpperBound = res.datas.bookUpperBound;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
            this.unlimitedJourney = res.datas.unlimitedJourney;
            this.changeEvectionCity = res.datas.changeEvectionCity;
            this.businessRuleControl = res.datas.businessRuleControl;
            this.isFreeApprove = res.datas.isFreeApprove || 0;
        },

        openEvectionRulePopup() {
            // this.getMyTravelPolicy();
            // this.evectionRulePopupShow = true
            this.getMyTravelPolicyNew();
            this.evectionRulePopupNewShow = true;
        },
        getMyTravelPolicyNew() {
            this.policyLoading = true;
            let staffList = [];
            this.activeChooseUserList.forEach((value) => {
                staffList.push(value);
            });
            consumer_web_policyConfig_getUserTravelPolicy({
                businessType: 1,
                userIds: staffList,
                'evectionNo': this.travelCurrent ? this.travelCurrent.evectionNo : '',
                'applyJourneyId': this.travelCurrent ? this.travelCurrent.active.id : '',
            }).then(res => {
                this.myTravelPolicy = res.datas
            }).finally(()=>{
                this.policyLoading = false;
            })
        },
        getMyTravelPolicy() {
            this.policyLoading = true;
            consumer_web_policyConfig_myTravelPolicy().then(res => {
                this.myTravelPolicy = res.datas
            }).finally(()=>{
                this.policyLoading = false;
            })
        },
        formatterFlightCalendar(day) {
            let type = ''
            const currentTime = day.date.getTime() // 需要判断的日期
            const nowDateString = moment().format('yyyy-MM-DD');
            const nowDate = moment(nowDateString + ' 00:00:00').valueOf() // 今天
            if (this.travelCurrent) {
                // 防旧数据没有这个字段或者这个字段没值
                if (!this.travelCurrent.active.departTime || !this.travelCurrent.active.departTimeEnd) {
                    type = (currentTime < nowDate) ? 'disabled' : ''
                }
                const startDate = this.travelCurrent.active.departTime + ' 00:00:00'
                const endDate = this.travelCurrent.active.departTimeEnd + ' 00:00:00'
                const startDateValue = moment(startDate).valueOf()
                const endDateValue = moment(endDate).valueOf()
                type = (currentTime < nowDate) || (currentTime < startDateValue) || (currentTime > endDateValue) ? 'disabled' : ''
            } else {
                type = (currentTime < nowDate) ? 'disabled' : ''
            }
            if (moment(currentTime).format('YYYY-MM-DD') === this.flightDate) {
                type = 'selected'
            }
            day.type = type
            return day
        },
        closeRulePopup() {
            this.evectionRulePopupShow = false
        },
        goOrderList() {
            this.$router.push({
                name: 'h5-flight-order-list',
                query: {
                    jumpType: 'back'
                }
            })
        },
        // 出差单匹配错误推出
        getCurrentErr() {
            if (this.applyJourneyId == 0) {
                return
            }
            // 判断是否
            Dialog.alert({
                title: '提示',
                message: '该出差单全部出行人已完成预订',
                confirmButtonText: '我知道了'
            }).then(() => {
                // on close
                this.$router.go(-1);
            });
        },

        beforeChange(name) {
            console.log(this.travelCurrent);
            if (this.travelCurrent == null) {
                return true;
            } else {
                return false;
            }
        }
    },
};
