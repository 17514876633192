/**
 * 初始化方法
 * init
 * 入参：OBJ
 *  {
 *       popupType: '000': 点击右上角编辑按钮展示， '001': 点击置灰预定按钮展示
 *       evectionNo: string，出差单号， 必填
 *       evectionRequired： number， 是否出差单必填， 必填
 *       journeyId： number， 行程id， 必填， 无选择出差单时默认为0
 *       businessRuleControl: 因公出差规则 1：出差单审批 2：预订审批
 *       flightInfo: {
 *           deptCityId: '', // 起飞城市或者机场id
 *           arrCityId: '', // 到达城市或者机场id
 *           deptTime: '', // 出发日期
 *       },
 *       trainInfo: {
 *           deptCityId: '', // 出发城市或者站点id
 *           arrCityId: '', // 到达城市或者站点id
 *           deptTime: '', // 出发日期
 *       },
 *       hotelInfo: {
 *          cityId: '', // 城市id
 *          checkInTime: '', // 入住时间
 *          checkOutTime: '', // 离店时间
 *       }
 *   }
 *
 *
 *  @saveInfo 回调方法 点确定按钮触发
 *  返参： obj
 * {
 *   journeyId: string, 行程id
 *   evectionNo： string， 出差单号
 *   workTravelType: number, 因公出差的类型， 1是有选出差单，2是没有
 *   journeyType: number, 0 普通单， 1 不限行程单
 *   editType: string, 编辑类型，'000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
 *   travelCurrent： object, 当前选中的出差单， 如果没有选出差单则返回null
 *   difference: String, 是否有改动， 000： 没改动  001：城市改动，刷新页面  002：人改变，刷新差标  003: 日期改动，刷新页面
 *   travelUserList: Array[Number], 出行人ID数组
 * }
 * */

import NavBar from '@/component/nav-bar/import_latest_version_module'
import ReservePopupBoxForId from "@/page/travel-book/flight/component/reservePopupBoxForId/import_latest_version_module";
import ChooseTravelerForTravelPopup from '@/component/choose-traveler-for-travel-popup/1.0.1/index.vue'
import CostAttributionList from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/cost-attribution-list/1.0.1/index.vue";
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail'
import consumer_journey_getEvectionDetailV2 from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetailV2'
import consumer_evection_addOrUpdate from "@/lib/data-service/haolv-default/consumer_evection_addOrUpdate";
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/get_user_info'
import consumer_web_staff_judgeOrderFree from '@/lib/data-service/haolv-default/consumer_web_staff_judgeOrderFree';
import applyButton from "@/lib/data-service/haolv-default/consumer_menu_applyButton";
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import {Toast} from "vant";
import moment from "moment";
import consumer_apply_applyHotelJourney from "@/lib/data-service/haolv-default/consumer_apply_applyHotelJourney";
export default {
    data() {
        return {
            title: '',
            show: false,
            loading: false,
            audit: false, // 是否显示办公
            popupType: '000',
            evectionNo: '',
            evectionRequired: 0,
            businessRuleControl: 1,
            allowChangeJourneyTime: 0, // 是否允许修改行程时间 0：否 1：是
            bookUpperBound: 6,
            isFreeApprove: 0,
            journeyId: 0,
            useTravel: '',
            travelCurrent: null, // 当前出差单信息
            evectionInfo: {},
            evectionParams: {
                journeyId: 0,
                reason: '', // 出差事由
                feeAffiliationType: 1,
                feeAffiliationName: '', // 各自所在部门
                feeAffiliationId: '', // -1
                partnerEctionId: ''
            },
            initTravelUserList: [], // 保存打开的时候当时的出行人列表
            travelUserList: [], // 当前选中的出行人
            showCost: false,
            showPartnerEctionIdCross: false,
            showReasonCross: false,

            businessInfo: {}, // 当前传入的业务城市时间

            jurisdiction: false, // 按钮权限范围

            userInfo: {},
            userCanApprove: 1, // 0-不可以预订 1 可以预订

            shareEvectionNo: '',
            shareJourneyId: 0,
        }
    },
    props: {
        businessType: {
            type: Number,
            default: 1, // 机票：1， 火车票：2， 酒店：3
        },
    },
    components: {NavBar, ReservePopupBoxForId, ChooseTravelerForTravelPopup, CostAttributionList},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        travelUserList(newVal, oldVal) {
            // console.log('travelUserList', newVal, oldVal)
        }
    },
    computed: {},
    filters: {},
    methods: {
        async init(params) {
            console.log('i', params)
            this.popupType = params.popupType;
            this.evectionNo = params.evectionNo;
            this.evectionRequired = params.evectionRequired;
            this.businessRuleControl = params.businessRuleControl;
            if (this.businessType === 1) {
                this.businessInfo = params.flightInfo || {}
            } else if (this.businessType === 2) {
                this.businessInfo = params.trainInfo || {}
            } else {
                this.businessInfo = params.hotelInfo || {}
                console.log('2222222222');
                console.log(this.businessInfo);
                this.shareEvectionNo = this.businessInfo.shareEvectionNo ? this.businessInfo.shareEvectionNo : '';
                this.shareJourneyId = this.businessInfo.shareJourneyId ? this.businessInfo.shareJourneyId : 0;
                console.log(this.shareEvectionNo, this.shareJourneyId)
            }
            this.show = true;
            this.loading = true;
            let [policyErr, policyRes] = await awaitWrap(refund_change_policy_get());
            if (policyErr) {
                this.loading = false;
            }
            let [jurisdictionErr, jurisdictionRes] = await awaitWrap(applyButton());
            if (!jurisdictionErr) {
                this.jurisdiction = jurisdictionRes.datas.jurisdiction
            }
            let userRes = await get_user_info();
            this.userInfo = userRes.datas;
            this.audit = userRes.datas.audit;
            this.evectionRequired = policyRes.datas.evectionRequired;
            this.businessRuleControl = policyRes.datas.businessRuleControl;
            this.allowChangeJourneyTime = policyRes.datas.allowChangeJourneyTime;
            this.bookUpperBound = policyRes.datas.bookUpperBound;
            this.isFreeApprove = policyRes.datas.isFreeApprove;
            let [err, res] = await awaitWrap(consumer_journey_getEvectionDetailV2({
                evectionNo: this.evectionNo,
            }));
            if (err) {
                this.loading = false;
                return
            }
            this.journeyId = params.journeyId;


            this.evectionInfo = res.datas;
            let sharedList = [];
            let evectionParamsTravellerList = [] // 防止返回的travellerList是[null]
            let sharedIdList = [];
            let evectionParamsTravellerIdList = [] // 防止返回的travellerList是[null]
            if (res.datas.psgList) {
                res.datas.psgList.forEach(value => {
                    if (value) {
                        if (value.shareWith === 1) {
                            sharedList.push(value);
                            sharedIdList.push(value.userId)
                        } else {
                            evectionParamsTravellerIdList.push(value.userId)
                            evectionParamsTravellerList.push(value);
                        }

                    }
                })
            }
            this.evectionParams = {
                journeyId: this.journeyId,
                evectionNo: res.datas.evectionNo,
                evectionType: res.datas.evectionType,
                explainDesc: res.datas.explainDesc,
                feeAffiliationId: res.datas.feeAffiliationId,
                feeAffiliationName: res.datas.feeAffiliationName, // 费用归属名称
                feeAffiliationType: res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1,
                linkman: res.datas.linkman ? res.datas.linkman : '',
                linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : '',
                reason: res.datas.reason || '',
                travelUserList: res.datas.psgList ? evectionParamsTravellerIdList : [],
                sharedList: sharedIdList,
                smsType: res.datas.contactsList && res.datas.contactsList.length > 0 ? res.datas.contactsList[0].smsType : 1, // 是否发送短信， 1为是， 0为否
                travelUser: [],
                partnerEctionId: res.datas.partnerEctionId,
            };
            await this.checkUserCanApprove();
            this.initTravelUserList = res.datas.psgList;
            this.travelUserList = res.datas.psgList;

            console.log('this.initTravelUserList', this.initTravelUserList, this.travelUserList)
            if (this.journeyId === 0) {
                const params = {
                    travelCurrent: null,
                    isSetTravelList: true,
                    travelUserList: evectionParamsTravellerList
                };
                this.$nextTick(() => {
                    this.$refs['traveler'].init(params)
                })
                this.loading = false;
            } else {
                this.$nextTick(()=>{
                    console.log(this.$refs.reservePopupBox);
                    this.$refs.reservePopupBox.setCurrentInfo().then(()=>{
                        console.log('进入合住00')
                        // 获取合住人
                        if (this.businessType === 3 && this.businessInfo.shareEvectionNo) {
                            // 调接口
                            console.log('进入合住');
                            consumer_apply_applyHotelJourney({
                                "departTime": this.travelCurrent.active.departTime,
                                "returnTime": this.travelCurrent.active.returnTime,
                                "toCityId": this.travelCurrent.active.toCityId,
                                "userName": sharedList[0].name,
                            }).then(res=>{
                                console.log('进入合住2');
                                let data = res.datas;
                                let shareCurrent = null;
                                let allShareUserList = [];
                                let otherUserList = [];
                                let otherUserIdList = sharedIdList;
                                console.log(data);
                                data.forEach(value=>{
                                    console.log(value)
                                    value.journeys.forEach(value1 => {
                                        console.log(value1);
                                        value.active = value1;
                                        console.log('333');
                                        console.log(this.businessInfo, this.shareEvectionNo,this.shareJourneyId,  value.evectionNo, value.active.id)
                                        if (this.businessInfo.shareEvectionNo === value.evectionNo && parseInt(this.businessInfo.shareJourneyId) === value.active.id) {
                                            console.log('jin1');
                                            shareCurrent = value;
                                            allShareUserList = value.active.staffList;
                                        }
                                    })
                                })
                                console.log('进入合住', allShareUserList, shareCurrent);
                                allShareUserList.forEach(value => {
                                    if (sharedIdList.indexOf(value.userId) > -1) {
                                        otherUserList.push(value)
                                    }
                                })
                                console.log('进入合住', otherUserList);
                                this.$refs.traveler.setShareRoomUser({
                                    shareTravelCurrent: shareCurrent,
                                    allSelectOtherUserLit: allShareUserList,
                                    otherUserList: otherUserList,
                                    otherUserIdList: otherUserIdList,
                                });
                            }).catch(e=>{
                            })
                        }
                        this.loading = false;
                    }).catch(()=>{
                        this.loading = false;
                    });
                })
            }

            // 判断是否可以预订（就是是否免审）

        },
        async checkUserCanApprove() {
            let userParams = {
                'userIds': [],
            };
            this.evectionParams['travelUserList'].forEach((value) => {
                userParams['userIds'].push(value);
            });
            this.evectionParams['sharedList'].forEach((value) => {
                userParams['userIds'].push(value);
            });
            let userRes = await consumer_web_staff_judgeOrderFree(userParams);
            this.userCanApprove = userRes['datas'];
        },
        getShowType() {
            return this.show
        },
        hide() {
            const reservePopupBox = this.$refs.reservePopupBox
            if (reservePopupBox && reservePopupBox.getShow()) {
                reservePopupBox.closeEvectionlPopup()
                return
            }
            if (this.showCost) {
                this.showCost = false;
                return;
            }
            const traveler = this.$refs.traveler;
            const show_traveler = traveler.get_show();
            if (show_traveler) {
                traveler.hide();
                return;
            }
            this.show = false
        },
        _handleBack() {
            this.show = false
        },
        _closeCallBack() {
            this.evectionParams.journeyId = this.journeyId;
            const api = global.api;
            if (api) {
                api.setWinAttr({
                    softInputMode: 'auto'
                });
            }
        },
        _getCurrentEvection(val) {
            console.log(val)
            this.travelCurrent = val;
            if (val) {
                this.evectionParams.journeyId = val.active.id;
                this.evectionParams.reason = val.evectionReason;
                this.evectionParams.feeAffiliationName = val.feeAffiliation;
                this.evectionParams.feeAffiliationType = val.feeAffiliationType;
                this.evectionParams.feeAffiliationId = val.feeAffiliationId;
                // 判断是否在出差单时间范围内
                // 酒店 而且开通是否允许修改行程时间
                if (val.active.businessType === 3 && val.active.allowChangeJourneyTime === 1) {
                    const evectionDeptTime = moment(val.active.departTime + ' 00:00:00').valueOf();
                    const evectionEndTime = moment(val.active.returnTime + ' 23:59:59').valueOf();
                    const journeyDeptTime = moment(this.businessInfo.checkInTime + ' 00:00:00').valueOf();
                    const journeyEndTime = moment(this.businessInfo.checkOutTime + ' 23:59:59').valueOf();
                    if ((evectionDeptTime <= journeyDeptTime && evectionEndTime > journeyDeptTime) && (evectionDeptTime < journeyEndTime && evectionEndTime >= journeyEndTime)) {
                        this.travelCurrent.active.departTime = this.businessInfo.checkInTime
                        this.travelCurrent.active.returnTime = this.businessInfo.checkOutTime
                    }
                }
            } else {
                this.travelUserList = [];
                this.evectionParams.journeyId = 0;
                //this.evectionParams.reason = this.evectionInfo.reason
                //this.evectionParams.feeAffiliationName = this.evectionInfo.feeAffiliationName
                //this.evectionParams.feeAffiliationId = this.evectionInfo.feeAffiliationId
                //this.evectionParams.feeAffiliationType = this.evectionInfo.feeAffiliationType ? this.evectionInfo.feeAffiliationType : 1
                this.evectionParams.reason = ''; // 商务出行
                this.evectionParams.feeAffiliationName = ''; // 各自所在的部门
                this.evectionParams.feeAffiliationId = ''; // -1
                this.evectionParams.feeAffiliationType = 1

                // 如果只能选一个人且不是免审角色
                if (this.bookUpperBound === 1 && this.isFreeApprove === 0) {
                    this.travelUserList = [{userId: this.userInfo.id, name: this.userInfo.realName}];
                }
            }

            // 当init之后第一次推送出来，当前journeyId是等于初始化时候的id的，获取完当前行程信息后，去显示当前init时候选中的出行人
            const isSetTravelList = (this.evectionParams.journeyId === this.journeyId && this.journeyId !== 0);
            let psgList = [];
            if (this.evectionInfo.psgList) {
                this.evectionInfo.psgList.forEach(value=>{
                    if (value && value.shareWith === 2) {
                        psgList.push(value);
                    }
                })
            }
            if (isSetTravelList) {
                this.travelUserList = psgList
            }
            const params = {
                travelCurrent: val,
                isSetTravelList,
                travelUserList: this.travelUserList
            };
            this.$nextTick(() => {
                console.log('99999');
                this.$refs['traveler'].init(params)
            })
        },
        _getSelectedStaffIdList(selectedStaffIdList) {
            this.evectionParams.travelUserList = selectedStaffIdList;
        },
        _getSelectedStaffIdListForHotel(val) {
            console.log('回旋', val);
            this.evectionParams.travelUserList = val.selectedStaffIdList;
            this.evectionParams.sharedList = val.otherUserIdList;
            this.shareEvectionNo = val['shareRoomEvection'] ? val['shareRoomEvection']['evectionNo'] : '';
            this.shareJourneyId = val['shareRoomEvection'] ? val['shareRoomEvection']['active']['id'] : 0;
        },
        _getJourneyInfo() {
            console.log('获取信息');
            console.log(this.travelCurrent);
            // 获取出差信息
            let params = {
                "departTime": this.travelCurrent ? this.travelCurrent.active.departTime : this.businessInfo.checkInDate,
                "returnTime": this.travelCurrent ? this.travelCurrent.active.returnTime : this.businessInfo.checkOutDate,
                "toCityId": this.travelCurrent ? this.travelCurrent.active.toCityId : this.businessInfo.cityId,
            };
            this.$refs.traveler.setHotelJourneyToShareRoomAndShowPopup(params);
        },
        _showCostAttributionList() {
            // if (this.businessRuleControl === 1) {
            //     return;
            // }
            if (this.travelCurrent) {
                return
            }
            this.showCost = true
        },
        _toApply() {
            this.$router.push({
                name: 'h5-add-evection'
            })
        },
        async _submit() {
            // 判断如果是出差单必填的，没有选出差单的话就弹窗提示
            await this.checkUserCanApprove();
            if (this.popupType === '001' &&
                this.businessRuleControl === 1 &&
                this.jurisdiction &&
                this.evectionRequired === 1 &&
                this.isFreeApprove === 0 &&
                this.travelCurrent === null &&
                this.userCanApprove === 0) {
                Toast('请选择一个出差单');
                return;
            }

            let checkParams = {
                evectionRequired: this.evectionRequired,
                evectionType: 2,
                workTravelType: this.travelCurrent ? 1 : 2,
                journeyType: this.travelCurrent ? this.travelCurrent.active.journeyType : '',
            };
            let [err, res] = await awaitWrap(this.$refs.traveler.check(checkParams));
            if (err) {
                if (err.type === '001') {
                    Toast.fail(err.msg)
                }
                return
            }
            // 无出差单->无出差单
            // 无出差单->有出差单
            // 有出差单-> 无出差单
            // 有出差单-> 有出差单
            let evectionNo = this.travelCurrent ? this.travelCurrent.evectionNo : this.evectionNo;
            if (this.evectionParams.journeyId === 0 && this.journeyId !== 0) {
                evectionNo = ''
            }
            let params = {
                journeyId: this.evectionParams.journeyId,
                applyId: this.travelCurrent ? this.travelCurrent.id : "",
                endDate: this.travelCurrent ? this.travelCurrent.endDate : "",
                evectionNo,
                evectionType: 2, // 1是个人出差，2是出差单出差
                startDate: this.travelCurrent ? this.travelCurrent.startDate : "",
                travellerList: this.evectionParams.travelUserList,
                sharedList: this.evectionParams.sharedList,
                sharedStatus: this.evectionParams.sharedList.length > 0 ? 1 : 2,
                personType: 2,
                cabin: '',

                reason: this.evectionParams.reason,
                feeAffiliationId: this.evectionParams.feeAffiliationId,
                feeAffiliationName: this.evectionParams.feeAffiliationName,
                feeAffiliationType: this.evectionParams.feeAffiliationType,
                partnerEctionId: this.evectionParams.partnerEctionId,
            };
            if (!this.travelCurrent) {
                params.feeAffiliationId = this.evectionParams.feeAffiliationId;
                params.feeAffiliationName = this.evectionParams.feeAffiliationName;
                params.feeAffiliationType = this.evectionParams.feeAffiliationType;
            }
            if (this.shareEvectionNo) {
                let sharedUserList = [];
                sharedUserList.push({
                    'userId': this.evectionParams['sharedList'].length > 0
                        ? this.evectionParams['sharedList'][0]
                        : '',
                    'evectionNo': this.shareEvectionNo,
                    'personType': 2,
                    'journeyId': this.shareJourneyId,
                });
                params['sharedUserList'] = sharedUserList;
            }
            this.$showLoading.show();
            let [error, result] = await awaitWrap(consumer_evection_addOrUpdate(params));
            if (error) {
                this.$showLoading.hide();
                return
            }
            this.$showLoading.hide();

            // editType: '000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
            let editType = '';
            if (result.datas === this.evectionNo && this.evectionParams.journeyId === this.journeyId) {
                editType = '002'
            } else {
                if (this.evectionParams.journeyId === 0 && this.journeyId !== 0) {
                    editType = '001'
                } else {
                    editType = '000'
                }
            }
            // 判断是否有变动
            let difference = '000'; // 000： 没改动  001：城市改动，刷新页面  002：人改变，刷新差标  003： 时间改变，刷新页面
            let oldUserIdList = this.initTravelUserList.map(element => element.userId).sort((a, b) => (a - b));
            let newUserIdList = this.evectionParams.travelUserList.sort((a, b) => (a - b));
            console.log('oldUserIdList', oldUserIdList);
            console.log('newUserIdList', newUserIdList);
            // 出差单未重新选择或出行人未增减时，点击确定无需刷新页面
            // if (result.datas !== this.evectionNo || this.evectionParams.journeyId !== this.journeyId || oldUserIdList.join(',') !== newUserIdList.join(',')) {
            //     difference = true;
            // }\
            // 2.刷不刷超标状态 只和 人有关
            if (oldUserIdList.join(',') !== newUserIdList.join(',')) {
                difference = '002'
            }
            console.log(this.travelCurrent, this.businessInfo)
            // 1.刷不刷页面 只和 城市+日期 有关   因为机票和火车票可能选了同一个城市下面的不同车站机场，导致城市id不一致。所以要判断一下editType
            if (this.travelCurrent && editType !== '002') {
                if (this.businessType === 1) {
                    if (this.travelCurrent.active.tripDate !== this.businessInfo.deptTime) {
                        difference = '003';
                    }
                    if (this.travelCurrent.active.departCityId !== this.businessInfo.deptCityId) {
                        difference = '001';
                    }
                    if (this.travelCurrent.active.toCityId !== this.businessInfo.arrCityId) {
                        difference = '001';
                    }
                } else if (this.businessType === 2) {
                    if (this.travelCurrent.active.tripDate !== this.businessInfo.deptTime) {
                        difference = '003';
                    }
                    if (this.travelCurrent.active.departCityId !== this.businessInfo.deptCityId) {
                        difference = '001';
                    }
                    if (this.travelCurrent.active.toCityId !== this.businessInfo.arrCityId) {
                        difference = '001';
                    }
                } else {
                    // 时间改动了
                    if (this.travelCurrent.active.departTime !== this.businessInfo.checkInTime) {
                        difference = '003';
                    }
                    if (this.travelCurrent.active.returnTime !== this.businessInfo.checkOutTime) {
                        difference = '003';
                    }
                    if (this.travelCurrent.active.toCityId !== this.businessInfo.cityId) {
                        difference = '001';
                    }
                }
            }
            /*if (this.travelCurrent && this.travelCurrent.active.journeyType == 1) {
                if (!this.travelCurrent.active.departCityId) {
                    this.travelCurrent.active.departCityId = this.evectionInfo
                }
                if (!this.travelCurrent.active.toCityId) {

                }
                if (!this.travelCurrent.active.departCityName) {

                }
                if (!this.travelCurrent.active.toCityName) {

                }
                if (!this.travelCurrent.active.tripDate) {

                }
            }*/
            this.journeyId = this.evectionParams.journeyId;
            let resultParams = {
                journeyId: this.journeyId,
                evectionNo: result ? result.datas : this.evectionNo,
                workTravelType: this.travelCurrent ? 1 : 2, // 因公出差的类型， 1是有选出差单，2是没有
                journeyType: this.travelCurrent ? this.travelCurrent.active.journeyType : '', // 0 普通单， 1 不限行程单
                travelCurrent: this.travelCurrent,
                editType,
                difference,
                travelUserList: newUserIdList,
                'shareEvectionNo': this.shareEvectionNo,
                'shareJourneyId': this.shareJourneyId,
            };
            this.$emit('saveInfo', resultParams);
            this._handleBack()
        },
        _openEvectionTravelPopup() {
            this.$refs['reservePopupBox'].chooseTravel()
        },
        _openTravelUserPopup() {
            if (this.evectionParams.journeyId !== 0) {
                return
            }
            this.$refs['traveler'].showStaffPopup()
        },
        _delReason() {
            this.evectionParams.reason = ''
        },
        _delEvectionId() {
            this.evectionParams.partnerEctionId = ''
        },
        _focusForPartnerEctionId() {
            this.showPartnerEctionIdCross = true
        },
        _blurForPartnerEctionId() {
            setTimeout(() => {
                this.showPartnerEctionIdCross = false
            }, 300)
        },
        _focusForReason() {
            this.showReasonCross = true
        },
        _blurForReason() {
            setTimeout(() => {
                this.showReasonCross = false
            }, 300)
        },
    }
}
