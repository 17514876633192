export default {
    name: 'ChangeReturnClause',
    data () {
        return {}
    },
    props: {
        ruleTextList: {
            type: Array,
            default: () => []
        },
        showProductRule: {
            type: Boolean,
            default: true
        },
        flightType: {
            type: Number,
            default: 1, // 1单程，2往返
        },
        ruleShowType: {
            type: String,
            default: '000', // 000 普通单程显示,数据对应为ruleText； 001 往返显示双程,是用001时，对应数据入参为ruleTextList
        },
        depFlightCity: {
            type: String,
            default: '',
        },
        arrFlightCity: {
            type: String,
            default: '',
        },
        showLuggage: {
            type: Boolean,
            default: true,
        },
        productTag: {
            type: Number,
            default: 0,
        }
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
