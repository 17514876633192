import NavBar from "@/component/nav-bar/import_latest_version_module";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar/1.0.1";

export default {
    data() {
        return {
            title: '火车票说明',
            active: 1,
        }
    },
    components: {
        NavBar,
    },
    activated() {
        this.init();
        this.keyback();
    },
    mounted(){
        fixStatusBar({
            type: `200`,
            element: this.$refs.topElement.$el,
        });
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    methods: {
        //初始化
        init() {
            this.active = this.$route.query.active || 1;
            //anchor:
            //1:定位到 什么情况下可以办理改签
            //2:定位到 改签手续费
            let anchor = this.$route.query.anchor || '';
            if (this.active === 2 && anchor !== '') {
                setTimeout(() => {
                    let d = 0;
                    switch (Number(anchor)) {
                        case 1:
                            this.goAnchor(document.getElementById('changeSituation'));
                            break;
                        case 2:
                            this.goAnchor(document.getElementById('serviceCharge'));
                            break;
                        case 3:
                            this.goAnchor(document.getElementById('refundCharge'));
                            break;
                    }
                }, 500);
            }
        },

        //跳转至锚点位置
        goAnchor(selector) {
            let total = selector.offsetTop - 40;
            let target = document.getElementsByClassName('van-tabs__content')[0];
            let distance = target.scrollTop;
            // 计算每一小段的距离
            let step = total / 30;
            let timer = setInterval(() => {
                if (distance < total) {
                    distance += step;
                    // 移动一小段
                    target.scrollTop = distance;
                } else {
                    // 限制滚动停止时的距离
                    target.scrollTop = total;
                    clearInterval(timer)
                }
            }, 10)
        },

        //返回
        goBack() {
            this.$emit('hideExplain')
        },

        keyback() {
            const __this = this;
            keyback_processor.addEventListener(function () {
                __this.goBack()
            });
        }
    }
}