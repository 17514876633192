import { Notify } from 'vant';

// 获取用户信息
// import consumer_user_info from '@/lib/data-service/haolv-default/consumer_user_info';
// import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info';
import get_user_info from '@/lib/common-service/get_user_info';

import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';

export default {
    data() {
        return {
            checkHeadSrc: '',
            //姓名
            realName: '',
            //公司名
            companyName: '',
            //部门名
            tusDeptName: '',
            //手机号码
            phone: '',
            email: '',//邮箱
            // 账号
            username: '',

            systemType: '',
            appVersion: '',
            environment: '',
        }
    },
    created () {},
    async mounted () {

        await this.getUserInfo(); // 获取个人资料

        const api = global.api;
        if (api) {
            this.systemType = api.systemType;
            this.appVersion = api.appVersion;
            this.environment = window.appConfig.environment;
        }
    },
    methods: {
        clickImage(){
            this.$router.push({
                name: "h5-change-logo",
                query: {
                    id: this.$route.query.id,
                },
            });
        },

        //修改手机号码
        toChangePhone() {
            if(this.phone) {
                this.$router.push({
                    name: "h5-verify-old-phone",
                    query: {
                        phone: this.phone,
                    },
                });
            }else {
                this.$router.push({
                    name: "h5-verify-new-phone"
                });
            }
        },

        //修改邮箱
        toChangeEmail() {
            this.$router.push({
                name: "h5-change-email",
                query: {
                    oldEmail: this.email,
                },
            });
        },

        back(){
          this.$router.go(-1);
        },
        // 获取个人信息
        async getUserInfo() {
            const __this = this;
            let res = await get_user_info();
            this.checkHeadSrc = res.datas.headPortraitCode;


            this.realName = res.datas.realName ? res.datas.realName : '';
            this.companyName = res.datas.tusCompany ? res.datas.tusCompany.companyName : '';
            this.tusDeptName = res.datas.tusDept ? res.datas.tusDept.name : '';
            this.phone = res.datas.phone ? res.datas.phone : '';
            this.username = res.datas.username ? res.datas.username : '';
            this.email = res.datas.email ? res.datas.email : '';
        },
    }
}
