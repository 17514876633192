// 取消行程 http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-evection-controller/evectionListUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_evection_cancelEvection = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/evection/cancelEvection',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_evection_cancelEvection;