// consumer_journey_calculatePrice 根据出差单获取预下单统计金额
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-journey-controller/calculatePriceUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_journey_calculatePrice = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/journey/calculatePrice',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_calculatePrice;
