import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import store from '@/lib/store';
import get_tab_list from './get_tab_list';
import to_boolean from "nllib/src/convert/to_boolean";

const __init_transition = function (pParameter) {
    // return;
    if (!pParameter) pParameter = {};
    const to = pParameter.to;
    const from = pParameter.from;

    const tab_list = get_tab_list();
    const route_name_list = _.chain(tab_list)
        .map(function (m) {
            return m.to.name;
        })
        .value()
    ;

    const to_route_name = to.name;
    const from_route_name = from.name;

    if (!_.contains(route_name_list, to_route_name)) {
        return;
    }

    if (!_.contains(route_name_list, from_route_name)) {
        return;
    }

    const to_tab = _.chain(tab_list)
        .findIndex(function (m) {
            return m.to.name === to_route_name;
        })
        .value()
    ;

    const from_tab = _.chain(tab_list)
        .findIndex(function (m) {
            return m.to.name === from_route_name;
        })
        .value()
    ;

    if (to_tab > from_tab) {
        // store.state.data_for_base_layout.transition_name = `van-slide-left`;
        store.state.data_for_base_layout.transition_name = `lyn-modify-van-slide-left`;
    } else {
        // store.state.data_for_base_layout.transition_name = `van-slide-right`;
        store.state.data_for_base_layout.transition_name = `lyn-modify-van-slide-right`;
    }
};

const init = function (pParameter) {
    if (!pParameter) pParameter = {};
    const to = pParameter.to;
    const from = pParameter.from;

    // __init_transition(pParameter);

    const get_value = function () {
        let title = ``;
        let show = true;
        if (to) {
            const meta = to.meta;
            if (meta) {
                const data_for_base_layout = meta.data_for_base_layout;
                if (data_for_base_layout) {
                    const tab_bar = data_for_base_layout.tab_bar;
                    if (tab_bar) {
                        show = to_boolean(tab_bar.show, true);
                    }
                }
            }
        }
        return {
            title,
            show,
        }
    };
    const v = get_value();
    const flag_show = v.show;
    if (flag_show) {
        show();
    } else {
        hide();
    }
};

const show = function () {
    store.state.data_for_base_layout.tab_bar.show = true;
};

const hide = function () {
    store.state.data_for_base_layout.tab_bar.show = false;
};

export default {
    init,
    show,
    hide,
};

//参考：
//https://vant-contrib.gitee.io/vant/#/zh-CN/field
