//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { agree } from "@/lib/data-service/haolv-default/design";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Toast} from 'vant';

export default {
  name: "AgreeModal",
  components: {NavBar},
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
        comments: "",
        imageList: [],
        fileList: [],
        signInfo: "",
      },
      rules: {
        comments: [
          { required: true, message: "请填写审批意见", trigger: "blur" },
        ],
        signInfo: [{ required: this.processInfo.signFlag, message: "请签名", trigger: "blur" }],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate().then(()=>{
          const { ...restParams } = this.formValue;
          const params = {
              ...this.processInfo,
              ...restParams,
              attachments: [],
          };
          /*if(this.processInfo.signFlag) {
            params.signInfo = signInfo;
          }*/
          this.loading = true;
          agree(params)
              .then(() => {
                  this.loading = false;
                  // this.$message.success("操作成功");
                  Toast.success('操作成功');
                  this.handleCancel();
                  this.$emit("success");
              })
              .finally(() => {
                  this.loading = false;
              });
      }).catch(()=>{
          console.log('11')
      });
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetValidation();
      this.formValue = {
        comments: "",
        imageList: [],
        fileList: [],
        signInfo: "",
      };
      this.show = false;
        this.$emit("cancel");
    },
  },
};
