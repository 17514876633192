import Popup from 'vant/es/popup';
import Vue from 'vue'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
require('./index.less');

const mixin = {
  data() {
    return {
      paddingBottom: 0,
      paddingTop: 0,
    }
  },
  methods: {},
  mounted() {
    const __this = this;
    fixStatusBar({
      type: `000`,
      handler({default_handler, api}) {
        __this.paddingTop = api.safeArea.top
        __this.paddingBottom = api.safeArea.bottom
      }
    });

  },
  created: function () {
  },
  watch: {
    value(newVal) {
      const __this = this
      if (newVal) { //  && __this.paddingBottom !== 0 忘了有啥用了
        this.$nextTick(() => {
          let thisElement = __this.$el
          if (thisElement.getAttribute('class').indexOf('van-popup--bottom') >= 0) {
            __this.$el.style.paddingBottom = __this.paddingBottom + 'px'
          }
          if (thisElement.getAttribute('class').indexOf('van-popup--top') >= 0) {
            __this.$el.style.paddingTop = __this.paddingTop + 'px'
          }
        })
      }
    }
  },
};

const Component = Popup;
let TestFunction = Component.mixins[0].methods.slots;
Component.mixins.push(mixin)

Component.install = function () {
  Vue.component(`van-popup`, Component);
};

export default Component;