/**
 * 初始化并打开
 * init
 * 入参 {
 *  roomViewItem: obj 对象。  就是传入房型列表里的房型内容
 * }
 * **/
import { ImagePreview } from 'vant';
export default {
    data() {
        return {
            popupShow: false,
            current: 0,
            more: false,

            cardIdx: 0,
            roomViewItem: {},

            titleType: "001", // 001 普通 002 吸顶
            imgHeight: 0,
        }
    },
    components: {},
    created() {
    },
    mounted() {

    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _close() {
            const scrollView = this.$refs['roomPopupCont'];
            scrollView.removeEventListener('scroll', this._scrollChange)
        },
        _openedPopup() {
            this.titleType = '001'
            const scrollView = this.$refs['roomPopupCont'];
            scrollView.scrollTop = 0;
            this.$nextTick(()=>{
                // 获取tit距离顶部的位置
                let top = this.$refs.hotelRoomDetailPopupTit.offsetTop;
                console.log(top)
                if ((!this.roomViewItem.roomTypePics || this.roomViewItem.roomTypePics.length) && top === 0) {
                    this.titleType = '002'
                } else {
                    this.titleType = '001'
                }
                if (this.roomViewItem.roomTypePics && this.roomViewItem.roomTypePics.length > 0) {
                    let swipe = document.querySelector ('.hotel-room-swipe');
                    const currentOffsetHeight = swipe.scrollHeight;
                    console.log(currentOffsetHeight);
                    this.imgHeight = currentOffsetHeight;

                    scrollView.addEventListener('scroll', this._scrollChange, true)
                }
            })
        },
        _scrollChange(val) {
            let scrollWrap = document.querySelector ('.room-popup-cont');

            const currentScrollTop = scrollWrap.scrollTop;
            const currentOffsetHeight = scrollWrap.scrollHeight;
            const currentClientHeight = scrollWrap.clientHeight;
            console.log(currentScrollTop, currentOffsetHeight, currentClientHeight);
            if (currentScrollTop >= this.imgHeight) {
                this.titleType = '002'
            } else {
                this.titleType = '001'
            }
        },
        _onChange(index) {
            this.current = index;
        },
        _showMore() {
            this.more = !this.more;
        },
        _showFullImg(index) {
            let imgPicList = [];
            this.roomViewItem.roomTypePics.forEach(value=>{
                imgPicList.push(value.url);
            })
            ImagePreview({
                images: imgPicList,
                startPosition: index,
                closeable: true,
            });
        },

        init(params) {
            let initParams = params || {};
            this.cardIdx = initParams.cardIdx;
            this.roomViewItem = initParams.roomViewItem;
            this.showPopup();
        },
        showPopup() {
            this.popupShow = true;
            this.$nextTick(() => {
                this.more = false;
                this.$refs.roomPopupCont.scrollTop = 0;
            })
        },
        hidePopup() {
            this.popupShow = false;
        },
        showRoom() {
            this.popupShow = false;
            this.$emit('show-room', this.roomViewItem, this.cardIdx);
        },
        isShow(){
            return this.popupShow
        }
    }
}
