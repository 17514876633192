import NavBar from "@/component/nav-bar/import_latest_version_module";
//橙色头部组件
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/payment-method-selector/1.2.0/payment-method-selector-000/index.vue'
import consumer_trains_order_trainOrderTakeTicketDetail from '@/lib/data-service/haolv-default/consumer_trains_order_trainOrderTakeTicketDetail'
import consumer_t_od_hotel_order_interim_cancelOrder from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_cancelOrder";
import {Dialog, Toast} from "vant";
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import Q from "q";
import moment from "moment";
// 彩色icon组件
import ColorIcon from "@/component/color-icon/index.vue";

export default {
    data() {
        return {
            loading: false,
            title: '确认订单信息',
            orderNo: '',
            detail: {
                orderInfo: {
                    orderNo: null
                },
                trainInfo:{
                    fromStationName: null,
                    toStationName: null,
                    startDate: null,
                    startTime: null,
                    endTime: null,
                    trainNo: null,
                }
            },
            milliseconds: 0,
            intervalFun: '',
        }
    },
    components: {
        NavBar,
        PaymentMethodSelector000,
        ColorIcon,
        OrangeNavBar
    },
    created() {
    },
    mounted() {
    },
    async activated() {
        const __this = this;
        this.orderNo = this.$route.query.orderNo;
        await this.getDetail();
        const aPaymentMethodSelector000 = __this.$refs.aPaymentMethodSelector000;
        const orderForPayVos = [
            {
                "orderNo": this.orderNo,
                "businessContent": ""
            },
        ];
        aPaymentMethodSelector000.init({
            mock_data: {
                enable: false,
            },
            count_down: {
                enable: true,
                pay_remaining_seconds: __this.detail.milliseconds,
            },
            get_params() {
                const p = {
                    orderForPayVos
                };
                return Q.resolve(p);
            },
            before_pay_event_handler() {
                return Q.resolve();
            },
            user_cancel_pay_event_handler() {},
            pay_successful_event_handler() {
                __this.$router.replace({
                    name: 'h5-payment-success',
                    query: {
                        orderNo: __this.orderNo,
                        orderType: 2,
                        evectionType: __this.evectionType
                    }
                })
            },
            pay_failed_event_handler() {},
        });
    },
    deactivated() {
        clearInterval(this.intervalFun)
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterIntervalTime(val) {
            if (val) {
                let m = moment(val*1000).format('mm分ss秒');
                return m
            } else {
                return '00分00秒'
            }
        },
    },
    methods: {
        back() {
            this.$router.push({
                name: "h5-train-order-detail",
                query: {
                    orderNo: this.orderNo,
                    jumpType: 'list',
                },
            });
        },
        async getDetail() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_trains_order_trainOrderTakeTicketDetail({orderNo: this.orderNo}));
            if (err) {
                Toast.clear();
                this.loading = false;
                return
            }
            Toast.clear();
            this.loading = false;
            this.detail = res.datas;
            this.milliseconds = res.datas && res.datas.seconds ? res.datas.seconds : 0;
            clearInterval(this.intervalFun);
            if (this.milliseconds > 0) {
                this.setPayInterval()
            } else {
                this.cancelOrder()
            }
        },
        setPayInterval() {
            this.intervalFun = setInterval(() => {
                this.milliseconds--;
                if (this.milliseconds <= 0) {
                    clearInterval(this.intervalFun);
                    this.cancelOrder()
                }
            }, 1000)
        },
        cancelOrder() {
            Toast('超时未支付，订单已自动取消');
            consumer_t_od_hotel_order_interim_cancelOrder({ orderNo: this.orderNo }).then((res) => {
                this.$router.replace({
                    name: 'h5-train-order-detail',
                    query: {
                        orderNo: this.orderNo,
                    }
                })
            });
        },
        submit() {
            let ToastBox = Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            const __this = this;
            const selected_payment_channel_entity_list = __this.$refs.aPaymentMethodSelector000.get_selected_payment_channel_entity_list();
            if (selected_payment_channel_entity_list.length <= 0) {
                Toast(`请选择支付方式！`);
                return;
            }
            __this.$refs.aPaymentMethodSelector000.trigger_pay();
            setTimeout(() => {
                ToastBox.clear();
            },1000);

        },
    }
}
