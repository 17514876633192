import NavBar from '@/component/nav-bar/import_latest_version_module.js'
import consumer_hotel_search_getHotelImage from '@/lib/data-service/haolv-default/consumer_hotel_search_getHotelImage'
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
import { ImagePreview } from 'vant';
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
export default {
    data() {
        return {
            popupShow: false,
            active: '',
            hotelId: '',
            query: {},
            tabList: [],
            loading: false,
            hotelImageList: [], // 总的酒店图片列表
            hotelImageListResults: [], // 酒店图片输出结果
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
        const __this = this
        /*wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback:(result) => {
                    __this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            },200)
        });*/
        // this.init();
    },
    deactivated() {
        /*wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });*/
    },
    methods: {
        _openedPopup() {
            const __this = this;
            setTimeout(()=>{
                fixStatusBar({
                    type: `000`,
                    handler({default_handler, api}) {
                        const bodyWidth = document.body.clientWidth;
                        let topElement = __this.$refs['hotelAlbumNavbar'].$el;
                        let orrPaddingTop = topElement.style.paddingTop;
                        if (bodyWidth >= 414) {
                            orrPaddingTop = orrPaddingTop * 2
                        }
                        let currentPaddingTop = (orrPaddingTop || 0)/(bodyWidth/100);
                        const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
                        topElement.style.paddingTop = (addPaddingTop + currentPaddingTop) + 'vw';
                        let bottomElement = __this.$refs['bottomBtnWrapper'];
                        let oriPaddingBottom = bottomElement.style.paddingBottom;
                        if (oriPaddingBottom >= 414) {
                            oriPaddingBottom = oriPaddingBottom * 2
                        }
                        let currentPaddingBottom = (oriPaddingBottom || 0)/(bodyWidth/100);
                        const addPaddingBottom = (api.safeArea.bottom)/(bodyWidth/100);
                        bottomElement.style.paddingBottom = (addPaddingBottom + currentPaddingBottom) + 'vw';
                    }
                });
            }, 500);
        },
        init(params) {
            // this.query = this.$route.query;
            this.hotelId = params.hotelId;
            this.active = '';
            this.show();
            this.getPicList();
        },
        getPicList() {
            this.$showLoading.show();
            consumer_hotel_search_getHotelImage({hotelId: this.hotelId}).then(res=>{
                let hotelImageListResults = res.data.hotelImageListResults;
                let tabList = [];
                let total = 0;
                hotelImageListResults.forEach((value, index)=>{
                    total+= value.count;
                    value.name = `${value.typeName}(${value.count})`;
                    tabList.push(value);
                });
                tabList.unshift({name: `全部(${total})`, type: '',});
                this.tabList = tabList;
                this.hotelImageList = hotelImageListResults;
                this.hotelImageListResults = hotelImageListResults;
            }).finally(()=>{
                this.$showLoading.hide();
            })
        },
        show() {
            this.popupShow = true;
        },
        back() {
            this.popupShow = false;
        },
        isShow() {
            return this.popupShow
        },
        toLocateRoomList() {
            this.popupShow = false;
            this.$emit('onLocateRoomList');
        },
        changeTabs(name, title) {
            if (name === '') {
                this.hotelImageListResults = JSON.parse(JSON.stringify(this.hotelImageList));
            } else {
                let result = [];
                this.hotelImageList.forEach(value => {
                    if (value.type === name) {
                        result.push(value)
                    }
                })
                this.hotelImageListResults = result;
            }
        },
        showImgPreview(hIndex, index) {
            let picList = [];
            let i = 0;
            this.hotelImageListResults.forEach((value,vIndex) => {
                picList = [...picList, ...value.urls];
                if (vIndex < hIndex) {
                    i+=value.count;
                }
            });
            i+=index;
            ImagePreview({
                images: picList,
                startPosition: i,
                closeable: true,
            });
        }
    }
}
