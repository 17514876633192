import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/reserve-order-list',
      name: 'h5-reserve-order-list',
      component: () => import(/* webpackChunkName: "page-evection-reserve-order-reserve-order-list" */ `./index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '我的预订',
          need_head_nav: false,
          need_footer_bar: true,
          secondary_menu: 1,
          level_three_menu: 'hotel'
        }
      },
    }
  ],
};
