export const applyListNum = (state) => state.applyListNum;
export const evectionBackType = (state) => state.evectionBackType;
export const applyListResult = (state) => {
    let result = state.applyListNum
    if (!state.applyListNum) {
        return result = ''
    }
    if (state.applyListNum > 99) {
        return result = '99+'
    }
    return result
}
export const noticeNum = (state) => state.noticeNum;
export const noticeNumResult = (state) => {
    let result = state.noticeNum
    if (!state.noticeNum) {
        return result = ''
    }
    if (state.noticeNum > 99) {
        return result = '99+'
    }
    return result
}