import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/qiyu',
            name: 'qiyu',
            component: () => import(/* webpackChunkName: "page-qiyu" */ `./index.vue`),
            meta: {
                h5_layout: {
                    need_alive: true,
                },
            },
        }
    ],
};
