import HeadNav from "./components/head-nav/1.0.1/index.vue";
import HeadNav1 from "./components/head-nav/3.0.0/index.vue";
import FooterBar from "./components/tab-bar/1.0.2/index.vue";
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import check_token_processor from '@/router/init_router/lib/check_token_processor';
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
import requestPermission from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/requestPermission";
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/get_user_info'
import refund_change_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get';
import consumer_workflow from '@/lib/data-service/haolv-default/consumer_workflow';
import consumer_web_staff_getButtonMenu from '@/lib/data-service/haolv-default/consumer_web_staff_getButtonMenu';
import {mapMutations} from "vuex";
import {Dialog} from "vant";
import push_helper from '@/lib/common-service/apicloud/ajpush_helper';

import app_loading from "@/lib/common-service/app-loading";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";

export default {
    components: {
        HeadNav,
        HeadNav1,
        FooterBar,
    },
    data() {
        return {
            // audit: {},
            toHomeArr: ["h5-flight-home", "h5-train-home", "h5-hotel-home"],
            userInfo: {},
        };
    },
    watch: {
        $route(newVal, oldVal) {
            this.setAppPadding()
        }
    },
    methods: {
        setAppPadding() {
            const __this = this;
            let num = 0
            let setFun = setInterval(() => {
                fixStatusBar({
                    handler({default_handler, api}) {
                        const bodyWidth = document.body.clientWidth;
                        const bodyHeight = document.body.clientHeight;
                        // 上顶部
                        if (!__this.$refs.routerView || !__this.$refs.routerView.$refs) {
                            return
                        }
                        let topElement = __this.$refs.routerView.$refs.topElement;
                        if (topElement) {
                            if (topElement.$el) {
                                topElement = topElement.$el
                            }
                            let orrPaddingTop = topElement.style.paddingTop;
                            if (bodyWidth >= 414) {
                                orrPaddingTop = orrPaddingTop * 2
                            }
                            let currentPaddingTop = (orrPaddingTop || 0)/(bodyWidth/100);
                            const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
                            topElement.style.paddingTop = (addPaddingTop + currentPaddingTop) + 'vw';
                        }
                        // 底部
                        let bottomElement = __this.$refs.routerView.$refs.bottomElement;
                        if (bottomElement) {
                            if (bottomElement.$el) {
                                bottomElement = bottomElement.$el
                            }
                            let oriPaddingBottom = bottomElement.style.paddingBottom;
                            if (oriPaddingBottom >= 414) {
                                oriPaddingBottom = oriPaddingBottom * 2
                            }
                            let currentPaddingBottom = (oriPaddingBottom || 0)/(bodyWidth/100);
                            const addPaddingBottom = (api.safeArea.bottom)/(bodyWidth/100);
                            bottomElement.style.paddingBottom = (addPaddingBottom + currentPaddingBottom) + 'vw';
                        }
                    }
                });
                num++
                if (num >= 5) {
                    clearInterval(setFun)
                }
            }, 500)
        },
        async initWebsocketToListenApplyListNum() {
            const aWebSocketServerClient = global.aWebSocketServerClient;
            // 监听申请数
            let res = await get_user_info();
            let audit = res.datas;
            this.userInfo = audit;
            const applyEvent = `haolv-app-my-approve-list-${audit.id}`;
            const noticeEvent = `haolv-app-notice-${audit.id}`;
            console.log(applyEvent, noticeEvent);
            aWebSocketServerClient.on({
                event_name: applyEvent,
                handler:(data) => {
                    //console.log('触发申请数', data)
                    let num = data.unreadNotificationNumber;
                    this.setApplyListNum(num)
                }
            });
            aWebSocketServerClient.on({
                event_name: noticeEvent,
                handler:(data) => {
                    //console.log('触发通知数', data)
                    let num = data.unreadNotificationNumber;
                    this.setNoticeNum(num)
                }
            })
        },
        async getWorkflow() {
            let [err, res] = await awaitWrap(consumer_workflow());
            if (err) {
                return {
                    datas: false,
                };
            }
            return res;
        },
        ...mapMutations(['setApplyListNum', 'setNoticeNum'])
    },
    created() {
    },
    async mounted() {
        this.$store.dispatch('updateCompanyInfo');
        this.$store.commit('setUserInfo', {});
        get_user_info().then((res) => {
            this.userInfo = res.datas;
            this.$store.commit('setUserInfo', JSON.parse(JSON.stringify(this.userInfo)));
            this.$store.state.userInfoDefer.resolve();
            console.log('保存userinfo');
        });
        this.$store.commit('setPolicy', {});
        refund_change_policy_get().then(res=>{
            this.policy = res.datas;
            this.$store.commit('setPolicy', JSON.parse(JSON.stringify(this.policy)));
            this.$store.state.policyDefer.resolve();
            console.log('保存policy');
        })
        this.$store.commit('setWorkflow', '2');
        let [err, res] = await awaitWrap(this.getWorkflow());
        let workflow = res.datas ? '1' : '2';
        this.$store.commit('setWorkflow', workflow);
        this.$store.state.workflowDefer.resolve();
        this.$store.commit('setBtnList', []);
        consumer_web_staff_getButtonMenu().then(res=>{
            let list = [];
            if (res.datas.menuList) {
                res.datas.menuList.forEach(value => {
                    list.push(value.url);
                })
            }
            this.$store.commit('setBtnList', list);
        })
        this.setAppPadding();
        let hideQiYuRouterNameList = [
            'flight-search',
            'h5-hotel-search',
            'train-search',
            'h5-hotel-detail',
            'h5-hotel-reserve',
            'h5-hotel-reserve-result',
            'h5-travel-payment',
            'flight-cabin-list',
            'flight-reserve',
            'train-search-trainNo',
            'train-reserve',
            'login',
            'wenJuan',
            'flight-return-search',
            'flight-return-cabin-list',
            'flight-personal-reserve',
            'login',
            'h5-advert-detail',
            'h5-advert-book',
            'h5-advert-intention-detail',
            'h5-advert-intention-list',
        ];
        /*wangyiServerOnline.init({
            type: '001',
            canDrag: true
        })*/

        if (hideQiYuRouterNameList.indexOf(this.$route.name) === -1) {
            wangyiServerOnline.config().then(() => {
                wangyiServerOnline.show()
            });
        }


        // 权限问题
        requestPermission().then(res => {});

        await this.initWebsocketToListenApplyListNum();
        app_loading.hide();
        const api = global.api;
        if (api) {

            api.setWinAttr({
                softInputMode: 'resize'
            });

            // 检查当前版本是否最新
            /*const systemType = api.systemType;
            const appVersion = api.appVersion;
            // 判断企业是胤之旅
            if (appVersion !== '2.0.1' && window.appConfig.environment === 'release' && [65,68,141].indexOf(this.userInfo.companyId) > -1) {
                //提示更新
                Dialog.confirm({
                    title: '更新提醒',
                    message: '当前客户端不是最新版本，请更新到最新版本获得更好体验。',
                })
                    .then(() => {
                        // on confirm
                        if (systemType === 'ios') {
                            api.openApp({
                                iosUrl: 'https://download.wholeok.com/download/ios'
                            });

                        } else {
                            api.openApp({
                                androidPkg: 'android.intent.action.VIEW',
                                mimeType: 'text/html',
                                uri: 'https://download.wholeok.com/download/android-new'
                            }, function(ret, err) {

                            });
                        }
                    })
                    .catch(() => {
                        // on cancel
                    });
            }*/
        }

        push_helper.init().then(()=>{
            push_helper.bind();
        });
    },
    beforeDestroy() {
        push_helper.bind_init_user();
    },
    activated() {
    },
    deactivated() {

    },
};
