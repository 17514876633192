const getEvectionDetail = function (params) {
    let evectionType = ''
    let journeys = []
    switch(params.testCase) {
        case '001':
            evectionType = 2;
            journeys = [{"id":2642,"gmtCreate":"2022-10-14T10:13:52","gmtModified":"2022-10-14T10:13:52","businessType":2,"toCityName":"北京","toCityId":"BJP","departCityId":"GZQ","departCityName":"广州","departTime":"2022-10-29","returnTime":null,"departTimeEnd":"2022-10-29","returnTimeEnd":null,"applyNo":"16571363147000","evectionNo":"16571363136100262","orderNo":null,"journeyStatus":0,"isChange":null,"refund":null,"singleReturnType":null,"partnerJourneyId":null,"reason":null,"journeyType":0,"hotelSearchKey":null,"discount":null},{"id":2643,"gmtCreate":"2022-10-14T10:13:52","gmtModified":"2022-10-14T10:13:52","businessType":2,"toCityName":"成都","toCityId":"CDW","departCityId":"GZQ","departCityName":"广州","departTime":"2022-11-17","returnTime":null,"departTimeEnd":"2022-11-17","returnTimeEnd":null,"applyNo":"16571363147000","evectionNo":"16571363136100262","orderNo":null,"journeyStatus":0,"isChange":null,"refund":null,"singleReturnType":null,"partnerJourneyId":null,"reason":null,"journeyType":0,"hotelSearchKey":null,"discount":null}]
            break
        case '002':
            evectionType = 2;
            break;
        case '003':
            evectionType = 1;
            break
    }
    const result = {
        "datas":{
            "pageType":null,
            "cacheKey":null,
            "orderNo":null,
            "totalTax":null,
            "totalPriceAll":null,
            "totalPay":null,
            "totalOrder":null,
            "payCommision":null,
            "orderTime":null,
            "settlementPrice":0,
            "fuel":0,
            "ticketChanging":null,
            "infrastructure":0,
            "evectionNo":"16571363136100262",
            "evectionApplyId":4133,
            "linkmanPhone":"15113366469",
            "initialName":"阮阮阮阮",
            "initialUserId":5685,
            "accessoryUrl":"",
            "startDate":"2022-10-18",
            "gmtCreate":"2022-10-14T10:13:51",
            "endDate":"2022-11-30",
            "toCityName":"广州",
            "toCityId":"70011",
            "linkman":"阮阮阮阮",
            "feeAffiliationType":1,
            "evectionType": evectionType,
            "businessType":null,
            "feeAffiliationId":-1,
            "feeAffiliationName":"各自所在部门",
            "explainDesc":"",
            "reason":"2222",
            "partnerEctionId":null,
            "recordList":[{"id":4271,"gmtCreate":"2022-10-14T10:13:52","gmtModified":"2022-10-14T10:13:53","applyNo":"16571363147000","rejectReason":null,"linkNum":1,"nodeName":null,"approverUserId":2024,"deptId":null,"deptName":null,"approverUserName":"张磊","approveStatus":2,"approveStatusText":null,"approvalNodeName":null}],
            "journeys": journeys,
            "businessContentVo":"",
            "travellerList":[
                {"staffName":"李媛媛","nationality":null,"englishName":null,"email":null,"phone":"13022222215","companyId":null,"staffId":null,"status":null,"birthDate":null,"staffSex":null,"objectType":null,"positionId":null,"rankId":null,"rankName":null,"deptId":null,"deptName":null,"roleList":null,"certificateList":[{"id":null,"gmtCreate":null,"gmtModified":null,"userId":null,"certificateType":1,"certificateValue":"441424199501296098","status":null,"passengerExpireDate":null,"countryId":null,"def":null}],"userId":123,"superUserId":null,"superUserName":null,"userName":null,"password":null,"approverType":null,"reserved":null},
                {"staffName":"张磊","nationality":null,"englishName":null,"email":null,"phone":"18710798337","companyId":null,"staffId":null,"status":null,"birthDate":null,"staffSex":null,"objectType":null,"positionId":null,"rankId":null,"rankName":null,"deptId":null,"deptName":null,"roleList":null,"certificateList":[{"id":null,"gmtCreate":null,"gmtModified":null,"userId":null,"certificateType":1,"certificateValue":"110101199005061356","status":null,"passengerExpireDate":null,"countryId":null,"def":null}],"userId":1234,"superUserId":null,"superUserName":null,"userName":null,"password":null,"approverType":null,"reserved":null},
                {"staffName":"古镜","nationality":null,"englishName":null,"email":null,"phone":"19527605070","companyId":null,"staffId":null,"status":null,"birthDate":null,"staffSex":null,"objectType":null,"positionId":null,"rankId":null,"rankName":null,"deptId":null,"deptName":null,"roleList":null,"certificateList":[{"id":null,"gmtCreate":null,"gmtModified":null,"userId":null,"certificateType":1,"certificateValue":"442000199807064501","status":null,"passengerExpireDate":null,"countryId":null,"def":null}],"userId":1997,"superUserId":null,"superUserName":null,"userName":null,"password":null,"approverType":null,"reserved":null},
                {"staffName":"阮阮阮阮","nationality":null,"englishName":null,"email":null,"phone":"15113366469","companyId":null,"staffId":null,"status":null,"birthDate":null,"staffSex":null,"objectType":null,"positionId":null,"rankId":null,"rankName":null,"deptId":null,"deptName":null,"roleList":null,"certificateList":[{"id":null,"gmtCreate":null,"gmtModified":null,"userId":null,"certificateType":1,"certificateValue":"442000199105052338","status":null,"passengerExpireDate":null,"countryId":null,"def":null}],"userId":5685,"superUserId":null,"superUserName":null,"userName":null,"password":null,"approverType":null,"reserved":null}],
            "trainsSum":"0.00",
            "hotelSum":"0.00",
            "flightSum":"0.00",
            "serviceFee":"0.00",
            "sum":"0.00",
            "ticketBookingPageDetailsResponseList":[],
            "trainFeeDetail":null,
            "flightDetail":null,
            "hotelApplyJourneList":null,
            "changeDetailResponse":null,
            "applyList":[{"applyNum":0,"applyType":2,"applyList":[]},{"applyNum":0,"applyType":3,"applyList":[]},{"applyNum":0,"applyType":4,"applyList":[]},{"applyNum":0,"applyType":5,"applyList":[]},{"applyNum":0,"applyType":6,"applyList":[]},{"applyNum":0,"applyType":7,"applyList":[]},{"applyNum":0,"applyType":8,"applyList":[]},{"applyNum":0,"applyType":9,"applyList":[]}],
            "contactsList":[],
            "linkmanSms":null,
            "bookingSms":null,
            "paymentChannel":null,
            "businessExt":null,
            "todHotelOrderInterimList":null,
            "todFlightPolicy":[]
        },
        "code":"000000",
        "msg":"成功"
    }
    return new Promise((resolve, reject) => {
        resolve(result)
    })
};
const myAccount = function (params) {
    return new Promise((resolve, reject) => {
        const result = {
            code: '000000',
            datas: {
                account: '模拟账号',
                accountStatus: 1,
                accountStatusText: '正常',
            },
        };
        resolve(result)
    })
};
const passengerList = function (params) {
    const result = {
        code: '000000',
        datas: [
            {
                bornDate: '1991-01-01',
                cardId: '442000199101012338',
                cardType: 1,
                cardTypeText: '身份证',
                isUserSelf: 'Y',
                mobileNo: '15113366469',
                mobileVerifyStatus: 'Y',
                mobileVerifyStatusText: '已通过',
                name: '测试名称',
                optional: true,
                userId: 123,
                userType: '1',
                userTypeText: '成人',
                verifyStatus: '1',
                verifyStatusText: '已通过'
            },
            {
                bornDate: '1991-01-01',
                cardId: '442000199101012338',
                cardType: 1,
                cardTypeText: '身份证',
                isUserSelf: 'N',
                mobileNo: '15113366469',
                mobileVerifyStatus: 'Y',
                mobileVerifyStatusText: '已通过',
                name: '测试名称1',
                optional: true,
                userId: 1234,
                userType: '1',
                userTypeText: '成人',
                verifyStatus: '1',
                verifyStatusText: '已通过'
            },
            {
                bornDate: '1991-01-01',
                cardId: '442000199101012338',
                cardType: 1,
                cardTypeText: '身份证',
                isUserSelf: 'N',
                mobileNo: '15113366469',
                mobileVerifyStatus: 'Y',
                mobileVerifyStatusText: '已通过',
                name: '测试名称3',
                optional: false,
                userId: 12345,
                userType: '1',
                userTypeText: '成人',
                verifyStatus: '1',
                verifyStatusText: '已通过'
            },
            {
                bornDate: '1991-01-01',
                cardId: '442000199101012338',
                cardType: 1,
                cardTypeText: '身份证',
                isUserSelf: 'N',
                mobileNo: '15113366469',
                mobileVerifyStatus: 'Y',
                mobileVerifyStatusText: '已通过',
                name: '测试名称4',
                optional: false,
                userId: null,
                userType: '1',
                userTypeText: '成人',
                verifyStatus: '1',
                verifyStatusText: '已通过'
            },
        ],
    };
    return new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(result)
        }, 2000)
    })
};
const getLoginUserFrequents = function () {
    let result = {
        "datas":{
            "frequents":[
                {
                    "operator":1,
                    "frequentUserId":5686,
                    "frequentPassengerName":"测试名称1",
                    "surnames":null,
                    "name":null,
                    "phone":"15113366469",
                    "unSensitivePhone":"151*****6469",
                    "certificateType":1,
                    "certificateTypeText":"身份证",
                    "certificateValue":"442000199105052338",
                    "unSensitiveCertificateValue":"442000********2338",
                    "birthday":"1991-05-05",
                    "countryId":null,
                    "countryName":null
                },
                {
                    "operator":1,
                    "frequentUserId":5687,
                    "frequentPassengerName":"测试名称2",
                    "surnames":null,
                    "name":null,
                    "phone":"15113366469",
                    "unSensitivePhone":"151*****6469",
                    "certificateType":1,
                    "certificateTypeText":"身份证",
                    "certificateValue":"442000199105052338",
                    "unSensitiveCertificateValue":"442000********2338",
                    "birthday":"1991-05-05",
                    "countryId":null,
                    "countryName":null
                },
            ]
        },
        "code":"000000",
        "msg":"成功"
    };
    return new Promise((resolve, reject) => {
        resolve(result)
    })
};
const queryPassengerType = function () {
    const result = {"datas":[{"msg":"成人","code":"1"},{"msg":"儿童","code":"2"},{"msg":"学生","code":"3"},{"msg":"残军","code":"4"}],"code":"000000","msg":"成功"}
    return new Promise((resolve, reject) => {
        resolve(result)
    })
};
const queryIdType = function () {
    const result = {"datas":[{"msg":"中国居民身份证","code":"1"},{"msg":"港澳台居民居住证","code":"2"},{"msg":"护照","code":"B"},{"msg":"港澳居民来往内地通行证","code":"C"},{"msg":"台湾居民来往大陆通行证","code":"G"},{"msg":"外国人永久居留身份证","code":"H"}],"code":"000000","msg":"成功"};
    return new Promise((resolve, reject) => {
        resolve(result)
    })
};
const deletePassenger = function () {
    const result = {
        code: '000000',
        datas: '',
    };
    return new Promise((resolve, reject) => {
        resolve(result)
    })
};
const addPassenger = function () {
    const result = {
        code: '000000',
        datas: '',
    };
    return new Promise((resolve, reject) => {
        resolve(result)
    })
};
const updatePassenger = function () {
    const result = {
        code: '000000',
        datas: '',
    };
    return new Promise((resolve, reject) => {
        resolve(result)
    })
};
const addPassengerBusiness = function () {
    const result = {
        code: '000000',
        datas: '',
    };
    return new Promise((resolve, reject) => {
        resolve(result)
    })
}
export default {
    getEvectionDetail,
    myAccount,
    passengerList,
    getLoginUserFrequents,
    queryPassengerType,
    queryIdType,
    deletePassenger,
    addPassenger,
    updatePassenger,
    addPassengerBusiness
}