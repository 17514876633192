import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';
import {Toast} from "vant";
import to_int from 'nllib/src/convert/to_int';
import TrainCitySelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-city-selector/1.2.1/index.vue';

import consumer_trains_product_checkTheRemainingTrainTickets
    from '@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTickets'

export default {
    components: {
        TrainCitySelector,
    },
    data() {
        return {
            data_consumer_trains_product_checkTheRemainingTrainTickets: {
                resultList: [],
            },

            trainToStationCode: '',

            transition_name: `van-slide-left`,

            calendar: {
                show: false,
            },

            popup_filter: {
                show: false,
                __enable_only_gaotie_or_dongche: false,
                enable_only_gaotie_or_dongche: false,
            },

        }
    },
    computed: {
        show_text_date() {
            const __this = this;
            const trainDate = __this.$route.query.trainDate;
            const date_001 = moment(trainDate);
            const date_002 = date_001.format(`MM-DD`);
            return date_002;
        },

        show_text_week() {
            const __this = this;
            const trainDate = __this.$route.query.trainDate;
            const date_001 = moment(trainDate);
            const week_001 = date_001.format(`d`);
            const list = [
                `日`,
                `一`,
                `二`,
                `三`,
                `四`,
                `五`,
                `六`,
            ];
            const s_001 = list[week_001];
            const s_002 = `周${s_001}`;
            return s_002;
        },

        train_entity_list() {
            const __this = this;

            const list_000 = __this.data_consumer_trains_product_checkTheRemainingTrainTickets.resultList;

            let list_001 = list_000;
            if (__this.popup_filter.enable_only_gaotie_or_dongche) {
                const motorcycleType_list = [
                    1,
                    2,
                ];

                list_001 = _.chain(list_000)
                    .filter(function (m) {
                        const motorcycleType = m.motorcycleType;
                        return _.contains(motorcycleType_list, motorcycleType);
                    })
                    .value()
                ;
            }

            _.each(list_001, function (m1) {
                const trainSeatVoList = m1.trainSeatVoList;
                const __trainSeatVoList = _.chain(trainSeatVoList)
                    .filter(function (m) {
                        const seatInventory_001 = m.seatInventory;
                        const seatInventory_002 = to_int(seatInventory_001, 0);
                        return seatInventory_002 > 0;
                    })
                    .value()
                ;
                m1.trainSeatVoList = __trainSeatVoList;
            });

            const list_002 = _.chain(list_001)
                .filter(function (m) {
                    const trainSeatVoList = m.trainSeatVoList;
                    return trainSeatVoList.length > 0;
                })
                .value()
            ;

            return list_002
        },
    },
    watch: {
        trainToStationCode(pNewValue, pOldValue) {
            const __this = this;
            __this.$nextTick(() => {
                const selected_entity = __this.$refs.aTrainCitySelector.get_selected_entity();

                const stationCode = selected_entity.stationCode;
                const stationName = selected_entity.stationName;
                if (stationCode === ``) return;

                const name = __this.$route.name;
                const query_000 = __this.$route.query;
                const query_001 = extend({}, query_000);
                query_001.toStationCode = stationCode;
                query_001.toStationName = stationName;

                __this.$router.push({
                    name,
                    query: query_001,
                });

            });
        },

        '$route'(pNewValue, pOldValue) {
            const __this = this;
            __this.__init();
        },
    },
    methods: {
        __init() {
            const __this = this;

            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });

            Q.when()
                .then(function () {
                    return consumer_trains_product_checkTheRemainingTrainTickets({
                        fromStationCode: __this.$route.query.fromStationCode,
                        toStationCode: __this.$route.query.toStationCode,
                        trainDate: __this.$route.query.trainDate,
                    });
                })
                .then((data) => {
                    __this.data_consumer_trains_product_checkTheRemainingTrainTickets = data;

                    Toast.clear();
                })
                .catch(function (ex) {
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })
            ;

        },

        __btn_pre_day_click() {
            const __this = this;
            const trainDate_000 = __this.$route.query.trainDate;
            const trainDate_001 = moment(trainDate_000);
            const trainDate_002 = trainDate_001.add(-1, `days`);
            const trainDate_003 = trainDate_002.format(`YYYY-MM-DD`);

            const name = __this.$route.name;
            const query_000 = __this.$route.query;
            const query_001 = extend({}, query_000);
            query_001.trainDate = trainDate_003;

            __this.$router.push({
                name,
                query: query_001,
            });
        },

        __btn_next_day_click() {
            const __this = this;
            const trainDate_000 = __this.$route.query.trainDate;
            const trainDate_001 = moment(trainDate_000);
            const trainDate_002 = trainDate_001.add(1, `days`);
            const trainDate_003 = trainDate_002.format(`YYYY-MM-DD`);

            const name = __this.$route.name;
            const query_000 = __this.$route.query;
            const query_001 = extend({}, query_000);
            query_001.trainDate = trainDate_003;

            __this.$router.push({
                name,
                query: query_001,
            });
        },

        __btn_train_click(pEntity) {
            const __this = this;

            const orderNo = __this.$route.query.orderNo;
            const trainDate = __this.$route.query.trainDate;
            const fromStationCode = __this.$route.query.fromStationCode;
            const toStationCode = __this.$route.query.toStationCode;

            __this.$router.push({
                name: 'h5-train-change-search-train-detail',
                query: {
                    orderNo,
                    trainDate,
                    fromStationCode,
                    toStationCode,
                    trainInfo: JSON.stringify(pEntity),
                },
            });
        },

        __calendar_confirm_event_handler(value) {
            const __this = this;
            const trainDate_001 = moment(value);
            const trainDate_002 = trainDate_001.format(`YYYY-MM-DD`);

            __this.calendar.show = false;

            const name = __this.$route.name;
            const query_000 = __this.$route.query;
            const query_001 = extend({}, query_000);
            query_001.trainDate = trainDate_002;

            __this.$router.push({
                name,
                query: query_001,
            });
        },

        __popup_filter_btn_ok_click() {
            const __this = this;

            __this.popup_filter.show = false;
            __this.popup_filter.enable_only_gaotie_or_dongche = __this.popup_filter.__enable_only_gaotie_or_dongche;
        },

        __popup_filter_btn_cancel_click() {
            const __this = this;

            __this.popup_filter.show = false;
            __this.popup_filter.__enable_only_gaotie_or_dongche = __this.popup_filter.enable_only_gaotie_or_dongche;
        },

        __btn_show_calendar_click() {
            const __this = this;
            __this.calendar.show = true;
        },

        __btn_show_TrainCitySelector_click() {
            const __this = this;
            __this.$refs.aTrainCitySelector.show();
        },

        __btn_show_popup_filter_click() {
            const __this = this;
            __this.popup_filter.__enable_only_gaotie_or_dongche = __this.popup_filter.enable_only_gaotie_or_dongche;
            __this.popup_filter.show = true;
        },

    },
    created() {
    },
    mounted() {
        const __this = this;
        __this.__init();
    },
}
