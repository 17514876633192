export default {
  components: {},
  data() {
    return {
      list: [{}, {}, {}, {}, {}],
    };
  },
  methods: {
    to_order_detail() {
      console.log("todetail");
    },

    delet_item() {
      console.log("delet");
    },
    edit_item() {
      console.log("edit");
    },

    read_file() {
      console.log("read");
    },
    commit() {
      console.log("commit");
    },
  },
  created() {},
  activated() {},
  deactivated() {},
};
