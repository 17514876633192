import NavBar from "@/component/nav-bar/import_latest_version_module";
export default {
  components: {
    NavBar
  },
  data() {
    return {
      orderNo: null,
      orderType: null,
    };
  },
  computed: {
    
  },
  methods: {
    getDataInit(){
      this.orderNo = this.$route.query.orderNo;
      this.orderType = this.$route.query.orderType;
    },
    //返回按钮
    back() {
      if (this.orderType === 4) {
        this.$router.replace({
          name: "h5-flight-order-list",
        });
      } else if (this.orderType === 2) {
        this.$router.replace({
          name: "h5-train-order-list",
        });
      } else if (this.orderType === 3) {
        this.$router.replace({
          name: "h5-hotel-order-list",
        });
      } else {
        this.$router.replace({
          name: "h5-home",
        });
      }
    },
    //查看详情
    to_detail () {
      if (this.orderType) {
        const orderNo = this.orderNo;
        if (this.orderType == 1) {
          this.$router.replace({
            name: 'flight-order-detail',
            query: {
              orderNo
            },
          })
        } else if (this.orderType == 2) {
          this.$router.replace({
            name: 'h5-train-order-detail',
            query: {
              orderNo
            },
          })
        } else if (this.orderType == 3) {
          this.$router.replace({
            name: 'h5-hotel-order-detail',
            query: {
              orderNo
            },
          })
        }
      } else {
        this.$router.replace({
          name: 'h5-office-evection',
        })
      }

    }
  },
  mounted(){
    const _this = this
    this.getDataInit();
  },
  activated() {},
};
