import consumer_air_ticket_refundDetail from "@/lib/data-service/haolv-default/consumer_air_ticket_refundDetail";
export default {
    data () {
        return {
            detail: {},
            active: 0,
            psgNameList: []
        }
    },
    components: {},
    created () {},
    mounted () {
        this.getDetail()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        filterCabin(val) {
            if (val === '1') {
                return '头等舱'
            } else if (val === '2') {
                return '公务舱'
            } else if (val === '3') {
                return '经济舱'
            } else {
                return ''
            }
        }
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        getDetail() {
            let orderNo = this.$route.query.orderNo;
            let psgNameList = []
            consumer_air_ticket_refundDetail({ orderNo }).then(res => {
                this.detail = res.orderrRfund;
                if (this.detail.reiStatus === '处理中') {
                    this.active = 1
                }
                if (this.detail.reiStatus === '已退款' || this.detail.reiStatus === '退款成功') {
                    this.active = 2
                }
                if (this.detail.reiStatus === '退款失败') {
                    this.active = 2
                }
                this.detail.listTheFlight.forEach(value => {
                    if (psgNameList.indexOf(value.psgName) === -1) {
                        psgNameList.push(value.psgName)
                    }
                })
                this.psgNameList = psgNameList
            });
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/