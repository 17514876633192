import Q from 'q';
import _ from 'underscore';
import __get_pager_data from 'nllib/src/pager-helper/get_pager_data';
import wait_some_seconds from 'nllib/src/common/wait_some_seconds';
import to_string from 'nllib/src/convert/to_string';

const __page_size__ = 5;
let __list_all__;

const get_list_all = function () {
    // const count = 40;
    const count = 12;
    // const count = 10;
    // const count = 6;
    // const count = 5;
    // const count = 3;

    if (__list_all__) return __list_all__;

    const list_001 = [];
    _.times(count, function (i) {
        const n = i + 1;
        list_001.push(n);
    });

    __list_all__ = list_001;

    return __list_all__;
};

//----------------------------------------------------------------------------------------------------------------------

const get_pager_data = function (pParameter) {
    if (!pParameter) pParameter = {};
    const page_index = pParameter.page_index || 1;
    const page_size = pParameter.page_size || __page_size__;

    const list_all = get_list_all({
        page_size,
    });

    return Q.when()
        .then(function () {
            return wait_some_seconds({
                seconds: 0.5,
            });
        })
        .then(function () {
            return __get_pager_data({
                list_all,
                page_index,
                page_size,
            });
        })
        .then(function (data) {
            const page_index = data.page_index;
            const list_000 = data.list;
            const list_001 = _.chain(list_000)
                .map(function (m) {
                    const number = m;
                    const id = m;
                    const title = `${number}(第${page_index}页)`;
                    return {
                        id,
                        number,
                        title,
                    };
                })
                .value()
            ;
            const entity_list = list_001;

            data.entity_list = entity_list;

            return Q.resolve(data);
        })
        .catch(function (ex) {
            if (ex) {
                const enable_log_error = !ex.disable_log_error;
                if (enable_log_error) {
                    console.error(ex);
                    console.trace();
                    if (ex.stack) {
                        console.error(ex.stack);
                    }
                }
            }
            return Q.reject(ex);
        })
        ;
};

const get_entity = function (pParameter) {
    if (!pParameter) pParameter = {};
    const __id = pParameter.id || ``;
    if (!__id) {
        const msg = `get_entity parameter error, code=001`;
        console.error(msg);
        console.trace();
        return Q.reject({msg: msg});
    }
    const id = to_string(__id);

    const list = get_list_all();
    const query = _.chain(list)
        .find(function (m) {
            const id_001 = m;
            const id_002 = to_string(id_001);
            return id === id_002;
        })
        .value()
    ;
    if (!query) return;

    const entity = {
        id: query,
        number: query,
    };
    return entity;

};

export default {
    get_pager_data,
    get_entity,
}
