import moment from "moment";
import cache_set from "nllib/src/cache/set";
import cache_get from "nllib/src/cache/get";
import cache_remove from "nllib/src/cache/remove";

export default {
    data() {
        return {
            hotelName: '',
            longitude: 39.12957,
            latitude: 116.48254,
            hotelLocation: [39.12957, 116.48254]//默认北京天安门广场的经纬度
        };
    },
    methods: {
        //初始化
        init() {
            this.initDate();
            this.initComponent();
        },

        //初始化数据
        initDate() {
            let {
                hotelName,
                longitude,
                latitude
            } = this.$route.query;
            this.hotelName = hotelName;
            this.longitude = Number(longitude);
            this.latitude = Number(latitude);

            //如果经纬度不为空，则使用酒店所在的经纬度
            if (longitude !== '' && latitude !== '') {
                this.hotelLocation = [longitude, latitude]
            }
        },

        //初始化组件
        initComponent() {
            setTimeout(() => {
                //初始化地图组件
                this.$refs.aMap.init({
                    hotelName: this.hotelName,
                    center: this.hotelLocation,
                    isOpenInfoWindow: true,
                })
            }, 500)
        },

        //返回
        back() {
            this.$router.go(-1);
        },
    },
    activated() {
        let minute = 6;
        const outTime = cache_get({key: 'hotelMapTime'});
        const hotelName = cache_get({key: 'hotelMapHotelName'});
        if(outTime) {
            minute = moment().diff(outTime, 'minute');
        }
        if(minute > 5 || this.$route.query.hotelName !== hotelName) {
            this.init();
        }
        cache_remove({ key: 'hotelMapTime'});
        cache_remove({ key: 'hotelMapHotelName'});
    },
    deactivated() {
        const outTime = moment().format('YYYY-MM-DD HH:mm:ss');
        cache_set({key: 'hotelMapTime', value: outTime})
        cache_set({key: 'hotelMapHotelName', value: this.hotelName})
    },
    components: {
        Map: () => import('@/component/map/1.0.1/index.vue'),
    },
};
