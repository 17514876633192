import moment from "moment";
import CommonlyUsedPassenger from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/commonly-used-passenger/1.0.1/index.vue';
import consumer_frequent_getLoginUserDefaultFrequents from '@/lib/data-service/haolv-default/consumer_frequent_getLoginUserDefaultFrequents'
import consumer_trains_order_getTrainOrderDeailByEvectionNo from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainOrderDeailByEvectionNo'
import InsureChoice  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/insure-choice/1.0.2/index.vue'
// 计算价格
import consumer_journey_calculatePrice from '@/lib/data-service/haolv-default/consumer_journey_calculatePrice'
import {Toast,Dialog} from "vant";
// 火车票时刻表
import consumer_trains_common_queryRailwayTimetable from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable'

// 组件
//橙色头部组件
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
// 时刻表
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
// 乘车人选择组件
import TrainTravelerSelector from '@/component/train-traveler-selector/1.0.0/index.vue'
// 座席选择组件
import TravelChoice from '../../component/travelChoice/import_latest_version_module'
// 头部组件
import NavBar from "@/component/nav-bar/import_latest_version_module";
//选择联系人组件
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-multiple-selector/1.2.6/index.vue'

export default {
    data () {
        return {
            priceData: {},
            //订单明细popup
            showDetailed: false,
            showDetailedClass: false,
            //选座信息
            choiceSeat:{
                //是否支持在线选座
                canChooseSeat: null,
                //每排座位数
                numberOfSeats: null,
                //可选最大数
                quantity: 0,
            },
            //火车详情
            trainsOrderData: {
                siteNum: null,
            },
            siteNum: null,
            //出差单号
            evectionNo:'',
            //页面展示常旅
            commonlyUserList:[],
            //组件回显勾选用户
            commonlyPopupData:[],
            //联系人信息
            contactPopupData:{
                frequentPassengerName:'',
                phone:'',
            },
            // 乘车人信息
            form: {
                userList: [],//出行人
                userIdList: [],//出行人userIdList
                linkman: '',//联系人
                linkmanPhone: '',//联系人电话
                smsType: 1,//发送行程信息到出行人手机。不发送：0；发送：1
                linkmanSms: 1//发送行程信息到联系人手机。不发送：0；发送：1
            },
            switchList:{
                travelSwitch: false,
                contactSwitch: false,
            },
            packageCodes: [],
            // 已选坐席
            choiceSeatBox: [],
            // 联系人
            contact:[],
            isSmsType: true,//发送行程信息到出行人手机。开关
            isLinkmanSms: true,//发送行程信息到联系人手机。开关
            evectionType: 1,
            orderNo: null,
        }
    },
    components: {
        CommonlyUsedPassenger,
        TravelChoice,
        InsureChoice,
        OrangeNavBar,
        TrainTimeTable,
        TrainTravelerSelector,
        NavBar,
        TravelerMultipleSelector
    },
    created () {},
    async mounted () {
        const __this = this;
        await __this.getFrequents();

        this.$refs.aInsureChoice.init({
            mock_data: {
                //是否使用随机数据--true/false
                enable: false,
                //使用随机数据--模拟请求时间
                wait_seconds: 2000,
            },
            //业务类型--1：机票/2：火车票
            businessType: 2,
            evectionType: 1,
            //订单号
            orderNo: this.$route.query.orderNo,
        });

        const trainTravelerSelectorParams = {
            evectionNo: this.evectionNo,
        };
        this.$refs['trainTravelerSelector'].init(trainTravelerSelectorParams);

    },
    activated () {
        // 清空乘车人
        this.form.userList = [];
        this.form.userIdList = [];
        this.choiceSeatBox = [];
        this.choiceSeat = {
            canChooseSeat: null,
            numberOfSeats: null,
            quantity: 0,
        };
    },
    deactivated() {

    },
    destroyed () {},
    watch: {
        //从员工中选择联系人时，
        contact(newVal) {
            this.contactObj = this.$refs.aTravelerMultipleSelector.get_selected_entity();
            this.form.linkman = this.contactObj.name;
            this.form.linkmanPhone = this.contactObj.phone;
        }
    },
    computed: {},
    filters: {
    },
    methods: {
        //发送行程信息到出行人手机--开关单击事件
        clickIsSmsType(){
            this.form.smsType = this.isSmsType ? 1 : 0;
        },
        //发送行程信息到联系人手机--开关单击事件
        clickIsLinkmanSms(){
            this.form.linkmanSms = this.isLinkmanSms ? 1 : 0;
        },
        // 校验手机
        validatePhone(rule, value, callback) {
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/;
            if (regExp.test(value) && value.toString().length === 11) {
                callback()
            } else {
                callback(new Error('请填写正确的手机号码'))
            }
        },
        //选择坐席触发方法
        TravelChoiceChange(data){
            this.choiceSeatBox = data;
        },
        //选择乘车人触发事件
        _result(val) {
            //获取选中的用户的数据和ID
            this.form.userIdList = val.userIdList;
            this.form.userList = val.userList;
            this.choiceSeat = {
                canChooseSeat: this.choiceSeat.canChooseSeat,
                numberOfSeats: this.choiceSeat.numberOfSeats,
                quantity: val.userList.length,
            };
            // 获取价格信息
            this.getAllPrice();
        },
        //删除乘车人
        travelersDel(item,index){
            this.form.userList.forEach((UItem,UIndex) => {
                if (item.cardId == UItem.cardId) {
                    //删除对应的用户数据
                    this.form.userList.splice(UIndex,1);
                    //删除对应的用户ID
                    this.form.userIdList.splice(UIndex,1);
                    this.choiceSeatBox = [];
                    this.choiceSeat = {
                        canChooseSeat: this.choiceSeat.canChooseSeat,
                        numberOfSeats: this.choiceSeat.numberOfSeats,
                        quantity: this.form.userList.length,
                    };
                    // 获取价格信息
                    this.getAllPrice();
                }
            });
        },
        //选择乘车人展示
        clickTravelers(){
            //就用户ID回传
            this.$refs['trainTravelerSelector'].toShow({
                activePassengerList: this.form.userIdList
            })
        },
        // 说明click
        clickExplain(){
            this.$router.push({
                name: 'h5-train-instructions',
                query: {
                    active: 1
                }
            });
        },
        InsureChoiceChange(data){
            this.packageCodes = data.id_list;
            this.getAllPrice();
        },
        // 获取价格信息
        getAllPrice(){
            let data = {
                evectionNo: this.evectionNo,
                headCounts: this.form.userList.length,
                packageCodes: this.packageCodes
            };
            consumer_journey_calculatePrice(data).then(resConsumer => {
                this.priceData = resConsumer.datas.orderAmountOfCalculationResults[0]
            })
        },
        //打开选择联系人popup
        openContactPopup() {
            this.$refs.aTravelerMultipleSelector.show();
        },
        getTravelerInfo(param) {
            let insure = this.$refs.aInsureChoice.get_date_promise();
            insure
              .then(res => {
                  this.choiceSeat = {
                      canChooseSeat: this.trainsOrderData.canChooseSeat,
                      numberOfSeats: this.trainsOrderData.numberOfSeats,
                      quantity: 0,
                  };

                  let data = {
                      evectionNo: this.evectionNo,
                      headCounts: this.form.userList.length,
                      packageCodes: res.id_list
                  };
                  consumer_journey_calculatePrice(data).then(resConsumer => {
                      this.priceData = resConsumer.datas.orderAmountOfCalculationResults[0]
                  })
              })
              .catch(rej => {
                  console.log(rej);
              })
        },
        popupClosed(){
            this.showDetailedClass = false;
        },
        clickDetailed(){
            this.showDetailed = true;
            this.showDetailedClass = true;
        },
        // 火车票时刻表组件
        initTrainTimeTable() {
            this.$refs['aTrainTimeTable'].init({
                //出发站三字码
                fromStationCode: this.trainsOrderData.fromStationCode,
                //到达站三字码
                toStationCode: this.trainsOrderData.toStationCode,
                //车次号
                trainCode: this.trainsOrderData.trainNo,
                //日期(2020-05-18)
                trainDate: this.trainsOrderData.startDate,
            })
        },
        // 唤起时刻表
        clickSiteNum(){
            this.$refs['aTrainTimeTable'].show();
        },
        //请求火车票信息
        getTrainsData(){
            this.evectionNo = this.$route.query.evectionNo;
            this.orderNo = this.$route.query.orderNo;
            this.evectionType = 1;
            this.workTravelType = 1;
            consumer_trains_order_getTrainOrderDeailByEvectionNo({evectionNo:this.evectionNo}).then(res => {
                this.trainsOrderData = res.datas;
                this.choiceSeat = {
                    canChooseSeat: res.datas.canChooseSeat,
                    numberOfSeats: res.datas.numberOfSeats,
                    quantity: 0,
                };
                this.getAllPrice();
                // // 请求火车票时刻表
                consumer_trains_common_queryRailwayTimetable({
                    //出发站三字码
                    fromStationCode: this.trainsOrderData.fromStationCode,
                    //到达站三字码
                    toStationCode: this.trainsOrderData.toStationCode,
                    //车次号
                    trainCode: this.trainsOrderData.trainNo,
                    //日期(2020-05-18)
                    trainDate: this.trainsOrderData.startDate,
                }).then(TRes => {
                    this.initTrainTimeTable();
                    let trueBox = [];
                    let switchOff = false;
                    TRes.results.forEach((item,index) => {
                        if (item.highlight) {
                            switchOff = !switchOff;
                        }
                        if (switchOff) {
                            trueBox.push(item);
                        }
                    });
                    this.siteNum = trueBox.length - 1;
                });
            });
        },
        getFrequents(){
            consumer_frequent_getLoginUserDefaultFrequents().then(res => {
                this.commonlyUserList = res.datas.frequents;
                res.datas.frequents.forEach((item,index) => {
                    this.commonlyPopupData.push(item.frequentUserId);
                    if (item.operator == 1) {
                        this.form.linkman = item.frequentPassengerName;
                        this.form.linkmanPhone = item.phone;
                    }
                });


            });
            this.getTrainsData()
        },
        //处理时间信息，返回周几
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterTime(val){
            if (val == null) return;
            let text = val.replace(/时/, "h").replace(/分/,'m');

            return text
        },
        back() {
            this.$router.go(-1)
        },
        //创建订单
        onSubmitTrainCreateOrder () {
            let _this = this;
            let data = this.$refs.aTravelChoice.getDataMethod();
            //判断乘车人
            if (this.form.userList.length == 0) {
                Toast({
                    message: '请添加乘车人',
                    position: 'bottom',
                });
                return
            } else if (this.form.linkman == '' || this.form.linkmanPhone == '') {
                Toast({
                    message: '请输入联系人信息',
                    position: 'bottom',
                });
                return
            } else if (this.trainsOrderData.seatName != '无座') {
                if (this.choiceSeat.quantity != data.length && this.trainsOrderData.canChooseSeat == 1) {
                    Toast({
                        message: '请选择座位',
                        position: 'bottom',
                    });
                    return
                }
            }
            let seat = [];
            data.forEach((item,index) => {
                seat.push(item.value);
            });

            const params = {
                mock_data: {
                    enable: false,
                    mode: ''
                },
                data:{
                    trainInfo:{
                        fromStationName: this.trainsOrderData.fromStationName,
                        toStationName: this.trainsOrderData.toStationName,
                        trainNo: this.trainsOrderData.trainNo,
                        startDate: this.trainsOrderData.startDate,
                        spanTime: this.trainsOrderData.startTime,
                    },
                    productCodes: this.packageCodes,
                    userList: this.form.userList,
                    orderNo: this.$route.query.orderNo,//订单号
                    frequentUserIds: this.form.userIdList,//出行人列表
                    linkman: this.form.linkman,//出行人列表
                    linkmanPhone: this.form.linkmanPhone,
                    chooseSeats: seat,
                    linkmanSms: this.form.linkmanSms,
                    bookingSms: this.form.smsType,
                },
                trainInfo:{
                    fromStationName: this.trainsOrderData.fromStationName,
                    toStationName: this.trainsOrderData.toStationName,
                    trainNo: this.trainsOrderData.trainNo,
                    startDate: this.trainsOrderData.startDate,
                    spanTime: this.trainsOrderData.startTime,
                },
                evectionType: 1,
                productCodes: this.packageCodes,
                userList: this.form.userList,
                orderNo: this.$route.query.orderNo,//订单号
                frequentUserIds: this.form.userIdList,//出行人列表
                linkman: this.form.linkman,//出行人列表
                linkmanPhone: this.form.linkmanPhone,
                chooseSeats: seat,
                linkmanSms: this.form.linkmanSms,
                bookingSms: this.form.smsType,
                insuranceFun(){

                }
            };
            let jsonData = JSON.stringify(params);
            this.$router.push({
                name: 'train-reserve-loading',
                query: {
                    useTravel: 2,
                    data: jsonData,
                }
            });
        }
    }
}
