import NavBar from "@/component/nav-bar/import_latest_version_module";
import ChangClause from '@/page/travel-book/flight/component/changClause/1.1.0/index.vue'
import ChangeClausePopup from '@/page/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import CheckTravel from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/check-travel/1.0.1/index.vue'
import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
import TravelPopup from '@/component/travel-popup/1.0.2/index.vue'
import consumer_flight_search_cabinDetails from '@/lib/data-service/haolv-default/consumer_flight_search_cabinDetails'
import consumer_flight_order_offers from '@/lib/common-service/consumer_flight_order_offers'
import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
import consumer_flight_order_personalBudgetOrder from '@/lib/data-service/haolv-default/consumer_flight_order_personalBudgetOrder'
import consumer_flight_order_budgetOrder from '@/lib/data-service/haolv-default/consumer_flight_order_budgetOrder'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_apply_applyJourneyList from '@/lib/data-service/haolv-default/consumer_apply_applyJourneyList'
import EpidemicPolicy from '@/page/travel-book/flight/component/epidemicPolicy/1.0.0/index.vue'
import PolicyTypeLabel from '@/page/travel-book/flight/component/policyTypeLabel/1.0.0/index.vue'
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import moment from "moment";
import {Dialog, Toast} from "vant";
import consumer_web_policyConfig_myTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_myTravelPolicy";
import consumer_journey_getEvectionDetailV2 from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetailV2'
import consumer_flight_search_checkFlightPolicy from '@/lib/data-service/haolv-default/consumer_flight_search_checkFlightPolicy'
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import {hidePolicyLabelCompanyIdList} from "@/lib/ohter/index.js";
import cache_get from 'nllib/src/cache/get'
import cache_set from 'nllib/src/cache/set'
import cache_remove from 'nllib/src/cache/remove'
import MyEvectionRulePopup from "@/page/travel-book/flight/component/myEvectionRulePopup/index.vue";
import MyEvectionRuleNewPopup from "@/page/travel-book/flight/component/myEvectionRuleNewPopup/index.vue";
import consumer_flight_search_queryPlaneNameByCodes from '@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes'
import consumer_flight_search_queryMeals from '@/lib/data-service/haolv-default/consumer_flight_search_queryMeals';
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import consumer_flight_order_flightsStop from '@/lib/data-service/haolv-default/consumer_flight_order_flightsStop.js';
import consumer_web_policyConfig_getUserTravelPolicy
    from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getUserTravelPolicy";
export default {
    data() {
        return {
            loading: false,
            title: '选择舱位',
            journeyId: 0,
            evectionRequired: 0,
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            flightKey: '',
            flightNo: '',
            evectionNo: '',
            evectionType: '',
            evectionBackType: '000',
            flightType: 1,
            psgType: 1, // psgType
            detail: {},
            tabActive: 1,
            accord: 0,
            startDate: '',
            endDate: '',
            workTravelType: 1,
            startRangeDate: '',
            endRangeDate: '',
            journeyType: 0,

            ruleLoading: false,
            ruleShow: false, // 退改签说明弹窗
            ruleText: {}, // 退改的文本
            ruleShowType: 1, // 要滚动到的地方。1是退改规则 2是行李额
            productTag: 0,

            policyShow:  false,
            teenOldInfo: {},

            canJourney: true,
            params: {},

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 3,
            returnSetInterval: '',

            yidingfeiShow: false,

            activityTitle: '',
            activityContent: '',
            activityImg: '',

            currentJourney: null,
            currentPolicyIndex: null,

            evectionRulePopupShow: false,
            evectionRulePopupNewShow: false,
            myTravelPolicy: null,
            policyLoading: false,

            travelUserList: [],

            companyId: '',
            hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
            stopData: [],

            minPrice: 0, // 最低价记录()
            allMinPrice: 0,
            basePrice: 0,
            // minPrice2: 0, // 头等舱最低价记录
        }
    },
    components: {NavBar,
        EvectionStandardExceed,
        ChangClause,
        CheckTravel,
        ChangeClausePopup,
        TravelPopup,
        EpidemicPolicy,
        PolicyTypeLabel,
        MyEvectionRulePopup,
        MyEvectionRuleNewPopup
    },
    created() {
    },
    mounted() {
        const _this = this
        this.$nextTick(() => {
            fixStatusBar({
                handler({api}) {
                    const bodyWidth = document.body.clientWidth;
                    const headNav = _this.$refs.topElementNav.$el;
                    const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
                    headNav.style.paddingTop = addPaddingTop + 'vw';
                }
            });
        });
        this.getUserInfo();
    },
    activated() {
        let flightCabinReactType =  cache_get({key: 'flightCabinReactType'});
        let flightErr =  cache_get({key: 'flightErr'});
        this.currentPolicyIndex = null;
        this.tabActive = 1;
        this.flightKey = this.$route.query.flightKey
        this.flightNo = this.$route.query.flightNo
        this.flightType = parseInt(this.$route.query.flightType)
        this.evectionType = parseInt(this.$route.query.evectionType)
        this.evectionNo = this.$route.query.evectionNo ? this.$route.query.evectionNo : ''
        this.startDate = this.$route.query.startDate ? this.$route.query.startDate : '';
        this.endDate = this.$route.query.endDate ? this.$route.query.endDate : '';
        this.journeyId = parseInt(this.$route.query.journeyId) || 0;
        this.accord = parseInt(this.$route.query.accord);
        this.workTravelType = parseInt(this.$route.query.workTravelType);
        this.startRangeDate = this.$route.query.startRangeDate;
        this.endRangeDate = this.$route.query.endRangeDate;
        this.journeyType = parseInt(this.$route.query.journeyType);
        this.evectionBackType = this.$route.query.evectionBackType || '000';
        this.minPrice = parseInt(this.$route.query.minPrice || '0');
        this.basePrice = parseInt(this.$route.query.basePrice || '0');
        if (this.flightType == 2) {
            this.title = '去程选择舱位';
        }
        this.getPolicyRule();
        // this.getDetail();
        if (flightCabinReactType === '1' || flightErr == 1) {
            this.getDetail()
        } else if (flightCabinReactType === '2') {
            // 计算5分钟
            const outTime = cache_get({key: 'flightCabinOutTime'});
            let minute = moment().diff(outTime, 'minute');
            if (minute >= 5) {
                this.getDetail()
            } else {
                const detail = cache_get({key: 'flightCabinDetail'});
                this.detail = JSON.parse(detail);
            }
        }
        cache_remove({
            key: 'flightCabinOutTime',
        });
        cache_remove({
            key: 'flightCabinDetail',
        });
        cache_remove({
            key: 'flightErr',
        });
        this.getEvectionDetail();
        // this.getCurrentJourney(this.journeyId)

        keybackProcessor.addEventListener(() => {
            // const epidemicPolicy = this.$refs.epidemicPolicy
            // if (epidemicPolicy.getShowStatus()) {
            //     epidemicPolicy._close()
            //     return;
            // }
            if (this.ruleShow) {
                this.ruleShow = false
                return;
            }
            if (this.policyShow) {
                this.policyShow = false
                return;
            }
            const checkTravelBox = this.$refs.checkTravelBox
            if (checkTravelBox.getShowStatus()) {
                checkTravelBox._checkErrorTableCancel()
                return;
            }
            if (this.yidingfeiShow) {
                this.yidingfeiShow = false
                return;
            }
            const travelPopup = this.$refs.travelPopup
            if (travelPopup.getShowType()) {
                travelPopup.hide()
                return;
            }
            /*const aEvectionStandardExceed = this.$refs.aEvectionStandardExceed
            let showEvectionExceedItem = null
            aEvectionStandardExceed.forEach(value => {
                if (value.getShow()) {
                    showEvectionExceedItem = value
                }
            })
            if (showEvectionExceedItem) {
                showEvectionExceedItem.closePopup()
                return;
            }*/
            this.back()
        })
    },
    deactivated() {
        keybackProcessor.removeEventListener();
        const outTime = moment().format('YYYY-MM-DD HH:mm:ss');
        console.log(outTime);
        cache_set({
            key: 'flightCabinOutTime',
            value: outTime
        });
        cache_set({
            key: 'flightCabinDetail',
            value: JSON.stringify(this.detail)
        });
    },
    destroyed() {
    },
    async beforeRouteEnter(to, from, next) {
        //第一个参数to，包含的内容是切换后的路由对象，也就是跳转后的路由对象
        //第二个参数from，包含的内容的是切换前的路由对象，也就是跳转前的路由对象
        //第三个参数next()，是否往下执行，执行的话，如果不写的话路由就不会跳转，操作将会终止
        console.log('route', to, from);
        if (to.name === 'flight-cabin-list' && from.name === 'flight-reserve') {
            // 1是正常刷新， 2是舱位列表返回，要超过5分钟才刷新
            cache_set({
                key: 'flightCabinReactType',
                value: '2'
            })
        } else {
            cache_set({
                key: 'flightCabinReactType',
                value: '1'
            })
        }
        next();
    },
    watch: {
        journeyId(newVal) {
            if (newVal !== 0) {
                this.getCurrentJourney(newVal)
            } else {
                this.currentJourney = null
            }
        }
    },
    computed: {},
    filters: {
        filterFlightDate(val) {
            return val === '' ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterFlightDaY(val) {
            return val === '' ? '' : moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day()
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '公务舱'
            } else if (val === 3) {
                return '经济舱'
            } else {
                return '不限'
            }
        },
        filterPlaneSize(val) {
            if (val === 1) {
                return '大型机'
            } else if (val === 2) {
                return '中型机'
            } else if (val === 3) {
                return '小型机'
            } else {
                return ''
            }
        }
    },
    methods: {
        back() {
            clearInterval(this.returnSetInterval)
            console.log('this.currentJourney', this.currentJourney)
            // this.$router.go(-1)
            this.$router.replace({
                // name: this.flightType === 2 ? "flight-return-search" : "flight-search",
                name: "flight-search",
                query: {
                    fromStationCode: this.detail.depCityCode || this.$route.query.fromStationCode,
                    fromStationName: this.detail.depCityName || this.$route.query.fromStationName,
                    toStationCode: this.detail.arrCityCode || this.$route.query.toStationCode,
                    toStationName: this.detail.arrCityName || this.$route.query.toStationName,
                    startDate: this.startDate,
                    endDate: this.flightType === 2 ? this.endDate : '',
                    flightType: this.flightType,
                    evectionNo: this.evectionNo,
                    redirectType: "000",
                    evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                    evectionRequired: this.evectionRequired,
                    workTravelType: this.journeyId === 0 ? 2 : 1, // 因公出差的类型， 1是有选出差单，2是没有
                    // applyId: val.travelCurrent.id,
                    startRangeDate: this.currentJourney ? this.currentJourney.departTime : '',
                    endRangeDate: this.currentJourney ? this.currentJourney.departTimeEnd : '',
                    journeyType: this.currentJourney ? this.currentJourney.journeyType : '', // 0 普通单， 1 不限行程单
                    journeyId: this.journeyId
                },
            })
        },
        getPolicyRule() {
            refund_change_policy_get().then(res => {
                this.evectionRequired = res.datas.evectionRequired;
                this.businessRuleControl = res.datas.businessRuleControl;
            })
        },
        getDetail() {
            // Toast.loading({
            //     message: '加载中...',
            //     forbidClick: true,
            //     duration: 0
            // })
            this.loading = true
            this.detail = {};
            const cabinParamsArr = [1, 2] // 头等商务舱类型合集
            const params = {
                flightKey: this.flightKey,
                flightNo: this.flightNo,
                evectionNo: this.evectionNo,
                accord: this.accord,
                switches: false, // 新旧查询方式 默认为旧 true为旧 新为false
            }
            consumer_flight_search_cabinDetails(params).then(res => {
                let detail = res.datas
                let productList = [] // 经济舱产品
                let productVipList = [] // 头等舱产品
                let allPriceList = [];
                let priceList = []; // 经济舱价格保存列表
                let priceList2 = []; // 头等舱价格保存列表
                if (detail.flightPolicyList && detail.flightPolicyList.length > 0) {
                    let arr1 = [] // 经济舱产品
                    let arr2 = [] // 头等舱产品
                    detail.flightPolicyList.forEach((value, index) => {
                        allPriceList.push(value.priceDatas[0].settlement);
                        if (cabinParamsArr.indexOf(value.cabin[0].cabinRank) >= 0) {
                            priceList2.push(value.priceDatas[0].settlement);
                            arr2.push(value)
                        } else {
                            priceList.push(value.priceDatas[0].settlement);
                            arr1.push(value)
                        }
                    })
                    if (arr1.length > 0) {
                        productList.push(arr1)
                    }
                    if (arr2.length > 0) {
                        productVipList.push(arr2)
                    }
                }
                if (detail.policys && detail.policys.length > 0) {
                    let arr1 = [] // 经济舱产品
                    let arr2 = [] // 头等舱产品
                    detail.policys.forEach(value => {
                        allPriceList.push(value.priceDatas[0].settlement);
                        if (cabinParamsArr.indexOf(value.cabin[0].cabinRank) >= 0) {
                            priceList2.push(value.priceDatas[0].settlement);
                            arr2.push(value)
                        } else {
                            priceList.push(value.priceDatas[0].settlement);
                            arr1.push(value)
                        }
                    })
                    if (arr1.length > 0) {
                        productList.push(arr1)
                    }
                    if (arr2.length > 0) {
                        productVipList.push(arr2)
                    }
                }
                detail.productList = productList
                detail.productVipList = productVipList
                detail.show = 1
                detail.vipShow = 1
                this.detail = detail
                this.allMinPrice = Math.min(...allPriceList);
                // 获取舱位的最低价
                //this.minPrice = Math.min(...priceList);
                //this.minPrice2 = Math.min(...priceList2);
                // this.initEpidemicPolicy();
                this._getFlightInfo();
                // 直接模拟点击预定按钮
                this.autoClick()
            }, error => {
                // Toast.clear()
                this.loading = false
                let errorCode = error.data.rsCode;
                this.errorText = error.data.rsMessage || error.data.msg;
                if (errorCode === '090002') {
                    // this.show = true;
                    this.dialogErrorVisible = true;
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.errorBack();
                        }
                    }, 1000)
                } else {
                    Dialog.alert({
                        title: '温馨提示',
                        message: this.errorText,
                    }).then(() => {
                        cache_set({
                            key: 'flightErr',
                            value:  1
                        })
                        // this.$router.go(-1)
                        this.back();
                    })
                }
            }).finally(() => {
                // Toast.clear()
                this.loading = false
            })
        },
        async _getFlightInfo() {
            if (!this.detail) {
                return;
            }
            console.log('11');
            await this._queryPlaneNameByCodes();
            console.log('33');
            await this._queryMeals();
            this._getStopInfo();
        },
        async _queryPlaneNameByCodes() {
            let flightTypeList = [this.detail.planeType];
            let [err, res] = await awaitWrap(consumer_flight_search_queryPlaneNameByCodes(flightTypeList));
            if (err) {
                return
            }
            let resList = res.datas;
            resList.forEach(value1 => {
                if (this.detail.planeType == value1.planeType) {
                    this.detail.planeName = value1.planeName;

                }
            })
            console.log('22');
            this.$forceUpdate();
        },
        async _queryMeals() {
            let params = {
                "flightNo":this.detail.flightNo,
                "deptTime":this.detail.depTime,
                "arrTime":this.detail.arrTime,
            };
            let res = await consumer_flight_search_queryMeals(params);
            let result = res.datas;
            let hasMealsText = '';
            if (result) {
                if (result.hasMeals == 1) {
                    hasMealsText = '有餐食';
                } else {
                    hasMealsText = '无餐食';
                }
                this.detail.hasMeals = result ? result.hasMeals : '';
                this.detail.hasMealsText = hasMealsText;
            }
            console.log('44');
            this.$forceUpdate();
        },
        async _getStopInfo() {
            this.stopData = [];
            if (this.detail['stopNum'] === 0) {
                return;
            }
            let params = {
                "rqData": {
                    "date": this.detail['depDate'],
                    "flightNo": this.detail['flightNo'],
                    'depCityCode': this.detail['depCityCode'],
                    'arrCityCode': this.detail['arrCityCode'],
                    'arrTime': this.detail['arrTime'],
                    'deptTime': this.detail['depTime'],
                    'depAirCode': this.detail['depAirportCode'],
                    'arrAirCode': this.detail['arrAirportCode'],
                }
            };
            let res = await consumer_flight_order_flightsStop(params);
            let stopData = res.datas ? res.datas.stopInfos : [];
            if (stopData && stopData.length > 0) {
                stopData.forEach((value, index)=>{
                    if (index !== 0 && index !== (stopData.length - 1)) {
                        const _depTimeArr = value['depTime'].split(':');
                        const _arrTimeArr = value['arrTime'].split(':');
                        const _depTime =
                            parseInt(_depTimeArr[0]) * 60 + parseInt(_depTimeArr[1]);
                        const _arrTime =
                            parseInt(_arrTimeArr[0]) * 60 + parseInt(_arrTimeArr[1]);
                        value['time'] = _depTime - _arrTime;
                        this.stopData.push(value);
                    }
                });
                this.$forceUpdate();
            }
        },
        autoClick() {
            const indexObj = this.currentPolicyIndex;
            if (indexObj !== null) {
                let item = {};
                if (this.tabActive === 1) {
                    item = this.detail.productList[indexObj.index][indexObj.pIndex];
                } else {
                    item = this.detail.productVipList[indexObj.index][indexObj.pIndex];
                }
                console.log(indexObj, item);
                this.currentPolicyIndex = null;
                if (!item.bookStatus) {
                    return
                }
                this.submit(item, indexObj.index, indexObj.pIndex);
            } else {
                // 如果经济舱没有返回，则看下显示商务舱
                if (this.detail.productList.length === 0 && this.detail.productVipList.length !== 0) {
                    this.tabActive = 2;
                }
            }
        },
        initEpidemicPolicy() {
            this.$refs['epidemicPolicy'].init({
                startCityCode: this.detail.depAirportCode,
                endCityCode: this.detail.arrAirportCode,
                autoOpen: true
            })
        },
        changeTabType(val) {
            this.tabActive = val
        },
        showYoungOldLabel(policyItem) {
            let params = {
                "businessExt": policyItem.businessExt,
                "depTime": this.detail.depTime,
                "deptCode": this.detail.depAirportCode,
                "deptDate": this.detail.depDate,
                "destCode": this.detail.arrCityCode,
                "flightNo": this.detail.flightNo,
                "policyId": this.detail.policyId,
                "supplierType": policyItem.productType
            }
            consumer_flight_order_offers(params).then(res => {
                const socpAges = res.socpAges
                let msg = '适用年龄：'
                const l = socpAges.length
                for (let i = 0; i < l; i++) {
                    msg+= `${socpAges[i].minAge} - ${socpAges[i].maxAge}`
                    if (i + 1 < l) {
                        msg+= `,`
                    }
                }
                Dialog.alert({
                    message: msg,
                    confirmButtonText: '知道了',
                    confirmButtonColor: '#FF9C00'
                }).then(() => {
                    // on close
                });
            })
        },
        showPolicyLabel(policyItem) {
            this.teenOldInfo = policyItem.teenOldInfo
            this.policyShow = true
        },
        clickShowMore() {
            this.detail.show === this.detail.productList.length || this.detail.productList.length === 0 ? this.detail.show = 1 : this.detail.show++
        },
        clickVipShowMore() {
            this.detail.vipShow === this.detail.productVipList.length || this.detail.productVipList.length === 0 ? this.detail.vipShow = 1 : this.detail.vipShow++
        },
        returnFlightParams(item, level) {
            // level: 1:经济舱， 2:头等舱
            if (item) {
                return {
                    deptDate: this.detail.depDate,
                    evectionNo: this.evectionNo,
                    policyList: [item],
                    lowPrice: this.basePrice,
                    flightNo: this.flightNo,
                }
            } else {
                return ''
            }
        },
        // 关闭退改规则弹窗
        closeRuleShow() {
            this.ruleShow = false
        },
        showRulePopup(val, ruleShowType) {
            this.ruleShowType = ruleShowType ? ruleShowType : 1;
            this.productTag = val.productTag;
            this.ruleShow = true
            this.ruleLoading = true
            this.ruleText = {}
            let params = {
                rqData: {
                    cabinCode: val.cabin[0].cabinCode,
                    flightKey: this.flightKey,
                    flightNo: this.flightNo,
                    policyId: val.policyId
                }
            }
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleText = res.datas.rsData
            }).finally(() => {
                this.ruleLoading = false
            })
        },
        timeLongTips(time) {
            return new Promise((resolve, reject) => {
                if (time && time >= 20) {
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '该订单支付后预计30分钟内出票，是否继续预订？',
                        confirmButtonText: '继续预订',
                        cancelButtonText: '取消',
                    })
                        .then(() => {
                            // on confirm
                            resolve(true);
                        })
                        .catch(() => {
                            // on cancel
                            resolve(false);
                        });
                } else {
                    resolve(true)
                }
            })
        },
        async submit(policys, index, pIndex) {
            if (policys.buttonStatus === false) {
                return;
            }
            this.currentPolicyIndex = null;
            if (this.travelUserList.length === 0 && this.evectionType === 2 && this.journeyId !== 0) {
                Toast('请选择出行人');
            }
            if (!policys.bookStatus) {
                this.currentPolicyIndex = {index, pIndex};
                this.$refs['travelPopup'].init({
                    popupType: '001',
                    evectionNo: this.evectionNo,
                    evectionRequired: this.evectionRequired,
                    journeyId: this.journeyId,
                    businessRuleControl: this.businessRuleControl,
                    flightInfo: {
                        deptCityId: this.detail.depAirportCode, // 起飞城市或者机场id
                        arrCityId: this.detail.arrAirportCode, // 到达城市或者机场id
                        deptTime: this.detail.depDate, // 出发日期
                    },
                })
                return
            }
            //无自营价格只能出飞常准携程运价，且该运价出票时限大于20分钟，点击创建订单时，弹出提示
            /*let timeTipsRes = await this.timeLongTips(policys.cabin[0].ticketLimitTime);
            if (!timeTipsRes) {
                return;
            }*/
            if (!this.canJourney) {
                return
            }
            this.canJourney = false
            // Toast.loading({
            //     message: '',
            //     forbidClick: true,
            //     duration: 0
            // })
            this.$showLoading.show();
            let formData = {
                journeyId: this.$route.query.journeyId ? parseInt(this.$route.query.journeyId) : 0,
                rqData: {
                    evectionNo: this.evectionNo,
                    journeyNo: this.orderNo,
                    cabinRank: policys.cabin[0].cabinRank,
                    directFlight: 0,
                    flightType: this.flightType,
                    psgType: this.psgType,
                    segmentList: [],
                    evectionType: this.evectionType
                }
            }
            let segment = {
                'ticketLimitTime': policys.cabin[0].ticketLimitTime,
                "airportTax": policys.priceDatas[0].airportTax,
                "billingFee": policys.priceDatas[0].billingFee,
                "cabinName": policys.cabin[0].cabinName,
                "cabinCode": policys.cabin[0].cabinCode,
                "cabinRank": policys.cabin[0].cabinRank,
                "chdCabinCode": policys.cabin[0].chdCabinCode,
                "comment": policys.comment,
                "crewType": policys.priceDatas[0].crewType,
                "discount": policys.cabin[0].discount,
                "fuelTax": policys.priceDatas[0].fuelTax,
                "policyId": policys.policyId,
                "price": policys.priceDatas[0].price,
                "priceTaxes": policys.priceDatas[0].priceTaxes,
                "productType": policys.productType,
                "segmentType": 1,//去返程
                "settlement": policys.priceDatas[0].settlement,
                "settlementPriceTaxes": policys.priceDatas[0].settlementPriceTaxes,
                "vtWorkTime": policys.vtWorkTime,
                "workTime": policys.workTime,
                "flightProductType": policys.flightProductType,
                businessExt: policys.businessExt,
                airlineQuota: policys.priceDatas[0].airlineQuota,
                priceType: policys.priceDatas[0].priceType,
                priceSource: policys.priceDatas[0].priceType,
                'tagProperty': policys['tagProperty'],
                'timeLimitQueue': policys.cabin[0]['timeLimitQueue'],
                "productTag": policys.productTag,

                "airCompanyCode": this.detail.airCompanyCode,
                "airCompanyName": this.detail.airCompanyName,
                "arrAirportCode": this.detail.arrAirportCode,
                "arrAirportName": this.detail.arrAirportName,
                "arrCityCode": this.detail.arrCityCode,
                "arrCityName": this.detail.arrCityName,
                "arrDate": this.detail.arrDate,
                "arrTerminal": this.detail.arrTerminal,
                "arrTime": this.detail.arrTime,
                "chaRule": this.detail.chaRule,
                "depAirportCode": this.detail.depAirportCode,
                "depAirportName": this.detail.depAirportName,
                "depCityCode": this.detail.depCityCode,
                "depCityName": this.detail.depCityName,
                "depDate": this.detail.depDate,
                "depTime": this.detail.depTime,
                "depTerminal": this.detail.depTerminal,
                "directFlight": this.detail.directFlight,
                "duration": this.detail.duration,
                "flightNo": this.detail.flightNo,
                "planeType": this.detail.planeType,
                "refRule": this.detail.refRule,
                "stopNum": this.detail.stopNum,
                hasShare: this.detail.hasShare,
                sharedFlightNo: this.detail.sharedFlightNo,
                sharedAirCompanyCode: this.detail.sharedAirCompanyCode,
                isShareText: `实际乘坐航班：${this.detail.sharedAirCompanyName}${this.detail.sharedFlightNo}请按实际乘坐航班办理值机。`,
                flightKey: this.flightKey,

                policyType: policys.policyType,
                isShare: this.detail.hasShare ? 1 : 0, // 是否共享航班 1-是 0-否"
            }
            formData.rqData.segmentList.push(Object.assign({}, segment))
            this.params = formData

            if (this.evectionType === 2) {
                // 因公
                // 出差单出差情况进行审核
                // Toast.clear()
                this.$showLoading.hide();
                this.$refs.checkTravelBox.check({
                    businessType: 1,
                    businessParams: {
                        evectionNo: this.evectionNo,
                        policyList: [policys],
                        deptDate: this.detail.depDate,
                        lowPrice: this.basePrice,
                        flightNo: this.flightNo,
                    },
                    flightType: this.flightType,
                    depFlightCity: this.detail['depCityName'],
                    arrFlightCity: this.detail['arrCityName']
                }).then(res => {
                    /*Toast.loading({
                        message: '',
                        forbidClick: true,
                        duration: 0
                    })*/
                    this.params.rqData.overproofApplyList = [];
                    if (res.policies && res.policies.length > 0) {
                        res.policies.forEach(policies => {
                            policies['segmentSerial'] = 1;
                            this.params.rqData.overproofApplyList.push(policies)
                        })

                    }

                    if (this.flightType === 2) {
                        this.canJourney = true;
                        let detail = JSON.parse(JSON.stringify(this.detail));
                        detail = Object.assign({}, detail, policys);
                        detail['stopData'] = this.stopData;
                        localStorage.setItem('flightFirstJourney', JSON.stringify(detail));
                        localStorage.setItem('flightReturnJourneyInfo', JSON.stringify(this.params));
                        let nextQuery = {
                            'evectionNo': this.evectionNo,
                            'evectionType': this.evectionType,
                            'fromStationCode': this.detail['depCityCode'],
                            'fromStationName': this.detail['depCityName'],
                            'toStationCode': this.detail['arrCityCode'],
                            'toStationName': this.detail['arrCityName'],
                            'startDate': this.startDate,
                            'endDate': this.endDate,
                            'flightType': this.flightType,
                            'workTravelType': this.workTravelType,
                            'startRangeDate': this.startRangeDate,
                            'endRangeDate': this.endRangeDate,
                            'journeyType': this.journeyType,
                            'journeyId': this.journeyId,
                            'flightIndex': 2,
                            'evectionBackType': this.evectionBackType,
                        };
                        console.log(nextQuery);
                        this.$router.push({
                            name: 'flight-return-search',
                            query: nextQuery,
                        })
                        return;
                    }
                    this.$showLoading.show();
                    consumer_flight_order_budgetOrder(this.params).then(res => {
                        // Toast.clear()
                        this.$showLoading.hide();
                        this.canJourney = true
                        this.$router.push({
                            name: 'flight-reserve',
                            query: {
                                orderNo: res.datas.rsData.orderNo,
                                evectionNo: res.datas.rsData.evectionNo,
                                evectionType: this.evectionType,
                                workTravelType: this.journeyId === 0 ? 2 : 1, // // 因公出差的类型， 1是有选出差单，2是没有
                                ticketLimitTime: res.datas.rsData.ticketLimitTime,
                                evectionBackType: this.evectionBackType,
                            }
                        })
                    }, error => {
                        // Toast.clear()
                        this.$showLoading.hide();
                        this.canJourney = true
                        // 根据code返回弹窗信息
                        this.setErrorDialog(error.data)
                    }).catch(() => {
                        this.canJourney = true
                        this.$showLoading.hide();
                    })
                }).catch(error => {
                    this.$showLoading.hide();
                    this.canJourney = true
                })
            } else {
                // 因私
                this.setOrder()
            }
        },
        setOrder() {
            this.$showLoading.hide();
            if (this.flightType === 2) {
                localStorage.setItem('flightFirstJourney', JSON.stringify(this.detail));
                localStorage.setItem('flightReturnJourneyInfo', JSON.stringify(this.params));
                let nextQuery = {
                    'evectionNo': this.evectionNo,
                    'evectionType': this.evectionType,
                    'fromStationCode': this.detail['depCityCode'],
                    'fromStationName': this.detail['depCityName'],
                    'toStationCode': this.detail['arrCityCode'],
                    'toStationName': this.detail['arrCityName'],
                    'startDate': this.startDate,
                    'endDate': this.endDate,
                    'flightType': this.flightType,
                    'workTravelType': this.workTravelType,
                    'startRangeDate': this.startRangeDate,
                    'endRangeDate': this.endRangeDate,
                    'journeyType': this.journeyType,
                    'journeyId': this.journeyId,
                    'flightIndex': 2,
                };
                this.$router.push({
                    name: 'flight-return-search',
                    query: nextQuery,
                })
                return;
            }
            // 提交
            let params = JSON.parse(JSON.stringify(this.params))
            params.enable_error_alert = false // 临时隐藏
            this.$showLoading.show();
            consumer_flight_order_personalBudgetOrder(params).then(res => {
                // Toast.clear()
                this.$showLoading.hide();
                this.canJourney = true
                this.$router.push({
                    name: 'flight-personal-reserve',
                    query: {
                        orderNo: res.datas.rsData.orderNo,
                        //evectionNo: res.datas.rsData.evectionNo,
                        evectionType: this.evectionType,
                        ticketLimitTime: res.datas.rsData.ticketLimitTime,
                    }
                })
            }, error => {
                // Toast.clear()
                this.$showLoading.hide();
                // 根据code返回弹窗信息
                this.setErrorDialog(error.data)
            }).catch((error) => {
                // Toast.clear()
                this.$showLoading.hide();
                this.canJourney = true
            })
        },
        setErrorDialog(error) {
            this.errorText = error.msg
            if (error.code === '200028') {
                this.dialogErrorVisible = true;
                this.returnSecond = 3;
                this.returnSetInterval = setInterval(() => {
                    this.returnSecond--;
                    if (this.returnSecond <= 0) {
                        this.errorBack();
                    }
                }, 1000)
            } else {
                this.dialogError2Visible = true
            }
        },
        errorBack() {
            clearInterval(this.returnSetInterval)
            // this.$router.go(-1)
            this.dialogErrorVisible = false
            cache_set({
                key: 'flightErr',
                value:  1
            })
            this.back();
        },
        errorBack2() {
            this.dialogError2Visible = false
            cache_set({
                key: 'flightErr',
                value:  1
            })
            this.back();
        },
        showYiDingFeiPopup(val) {
            this.activityTitle = val.activityLabelText
            this.activityContent = val.activityContent
            this.activityImg = val.url
            this.yidingfeiShow = true
        },
        closeYiDingFei() {
            this.yidingfeiShow = false
        },

        editEvection() {
            this.currentPolicyIndex = null;
            this.$refs['travelPopup'].init({
                popupType: '000',
                evectionNo: this.evectionNo,
                evectionRequired: this.evectionRequired,
                journeyId: this.journeyId,
                businessRuleControl: this.businessRuleControl,
                flightInfo: {
                    deptCityId: this.detail.depAirportCode, // 起飞城市或者机场id
                    arrCityId: this.detail.arrAirportCode, // 到达城市或者机场id
                    deptTime: this.detail.depDate, // 出发日期
                },
            })
        },
        updateEvectionInfo(val) {
            /*
            * 返参： obj
            {
              journeyId: string, 行程id
              evectionNo： string， 出差单号
              workTravelType: number, 因公出差的类型， 1是有选出差单，2是没有
              journeyType: number, 0 普通单， 1 不限行程单
              editType: string, 编辑类型，'000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
              travelCurrent： object, 当前选中的出差单， 如果没有选出差单则返回null
              difference: String, 是否有改动， 000： 没改动  001：城市改动，刷新页面  002：人改变，刷新差标  003: 日期改动，刷新页面
              travelUserList: Array[Number], 出行人ID数组
            }*/
            console.log(val);
            this.evectionNo = val.evectionNo;
            this.workTravelType = val.workTravelType;
            this.journeyId = val.journeyId;
            this.travelUserList = val.travelUserList;
            if (val.difference === '000') {
                this.getDetail();
                //return
            } else if (val.difference === '002' || val.difference === '003') {
                this.updateAccord();
            } else {
                this.$router.replace({
                    // name: this.flightType === 2 ? "flight-return-search" : "flight-search",
                    name: "flight-search",
                    query: {
                        // 注意不限行程
                        fromStationCode: val.travelCurrent.active.departCityId || this.detail.depAirportCode,
                        fromStationName: val.travelCurrent.active.departCityName || this.detail.depCityName,
                        toStationCode: val.travelCurrent.active.toCityId || this.detail.arrAirportCode,
                        toStationName: val.travelCurrent.active.toCityName || this.detail.arrCityName,
                        startDate: val.travelCurrent.active.tripDate || val.travelCurrent.active.departTime,
                        endDate: val.travelCurrent.active.returnTime || '',
                        flightType: val.travelCurrent.active.singleReturnType || 1,
                        evectionNo: val.evectionNo,
                        redirectType: "000",
                        evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                        cabin: '',
                        evectionRequired: this.evectionRequired,
                        workTravelType: val.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                        applyId: val.travelCurrent.id,
                        startRangeDate: val.travelCurrent.active.departTime,
                        endRangeDate: val.travelCurrent.active.departTimeEnd,
                        journeyType: val.journeyType, // 0 普通单， 1 不限行程单
                        journeyId: val.journeyId
                    },
                })
            }
        },
        updateAccord() {
            this.$showLoading.show();
            const params = {
                arrCityPortCode: this.detail.arrAirportCode,
                depCityPortCode: this.detail.depAirportCode,
                depDate: this.startDate,
                evectionNo: this.evectionNo,
                flightKey: this.flightKey,
                flightNo: this.flightNo,
            };
            consumer_flight_search_checkFlightPolicy(params).then(res=>{
                const checkList = res.checkList;
                const bookStatus = res.isReserve;
                let detail = this.detail;
                detail.productList.forEach(value => {
                    value.forEach(value1=>{
                        value1.bookStatus = bookStatus;
                        value1.buttonStatus = res.buttonStatus;
                        for (let i = 0, l = checkList.length; i < l; i++) {
                            if (value1.businessExt === checkList[i].key) {
                                value1.accord = checkList[i].accord
                                break
                            }
                        }
                    })
                });
                detail.productVipList.forEach(value => {
                    value.forEach(value1=>{
                        value1.bookStatus = bookStatus;
                        value1.buttonStatus = res.buttonStatus;
                        for (let i = 0, l = checkList.length; i < l; i++) {
                            if (value1.businessExt === checkList[i].key) {
                                value1.accord = checkList[i].accord
                                break
                            }
                        }
                    })
                });
                this.detail = JSON.parse(JSON.stringify(detail));
                // 直接模拟点击预定按钮
                this.autoClick();
            }).finally(()=>{
                this.$showLoading.hide();
            })
        },
        getCurrentJourney(journeyId) {
            let params = {
                pageSize: 1000,
                currentPage: 1,
                status: 2,
                approveType: 1,
            };
            consumer_apply_applyJourneyList(params).then(res => {
                const businessTravelList = res.datas
                businessTravelList.forEach(value => {
                    if (value.journeys) {
                        value.journeys.forEach(value1 => {
                            if (value1.id === journeyId) {
                                this.currentJourney = value1
                            }
                        })
                    }
                })

            })
        },

        openEvectionRulePopup() {
            // this.evectionRulePopupShow = true;
            // this.getMyTravelPolicy();
            this.getMyTravelPolicyNew();
            this.evectionRulePopupNewShow = true;
        },
        getMyTravelPolicyNew() {
            this.policyLoading = true;
            consumer_web_policyConfig_getUserTravelPolicy({
                businessType: 1,
                userIds: this.travelUserList,
                'evectionNo': this.evectionNo,
                'applyJourneyId': this.journeyId || '',
            }).then(res => {
                this.myTravelPolicy = res.datas
            }).finally(()=>{
                this.policyLoading = false;
            })
        },
        getMyTravelPolicy() {
            consumer_web_policyConfig_myTravelPolicy().then(res => {
                this.myTravelPolicy = res.datas
            })
        },
        closeRulePopup() {
            this.evectionRulePopupShow = false
        },

        getEvectionDetail() {
            if (this.evectionType !== 2) {
                return
            }
            consumer_journey_getEvectionDetailV2({
                evectionNo: this.evectionNo,
            }).then(res => {
                let travelUserList = [];
                if (res.datas.psgList) {
                    travelUserList = res.datas.psgList.map(element => element.userId)
                }
                this.travelUserList = travelUserList
            })
        },
        getUserInfo() {
            get_user_info().then(res=>{
                console.log(res)
                this.companyId = res.datas.companyId;
            })
        },
    }
}
