import ColorIcon from "@/component/color-icon/index.vue";
import HomeTripList from "@/component/home-trip-list/import_latest_version_module.js";
import moment from "moment/moment";
export default {
    data() {
        return {}
    },
    props: {
        index: { // index of current item
            type: Number
        },
        source: { // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default () {
                return {}
            }
        },
        toJourneyDetail: {
            type: Function,
        },
        clickCarDetail: {
            type: Function
        },
        clickTrainPhone: {
            type: Function
        },
    },
    components: {ColorIcon, HomeTripList},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        returnDayType(val) {
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(val).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == todayEnd) {
                text = `今天`;
            }

            if (tomorrow == todayEnd) {
                text = `明天`;
            }

            if (afterTomorrow == todayEnd) {
                text = `后天`;
            }
            return text
        },
        colorJourneyStatusTextCar(type){
            let color = '#FF7002';
            switch (type) {
                case 0:
                    color = '#FF7002';
                    break;
                case 1:
                    color = '#FF7002';
                    break;
                case 2:
                    color = '#FF7002';
                    break;
                case 3:
                    color = '#FF7002';
                    break;
                case 4:
                    color = '#FF7002';
                    break;
                case 5:
                    color = '#FF7002';
                    break;
                case 6:
                    color = '#FF7002';
                    break;
                case 7:
                    color = '#FF7002';
                    break;
            }
            return color
        },
        // 今天、明天
        DateDiff(date){
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(date).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == this.trainDate) {
                text = '今天';
            }

            if (tomorrow == todayEnd) {
                text = '明天';
            }

            if (afterTomorrow == todayEnd) {
                text = '后天';
            }
            if (text == '') {
                text = moment(date).format('YYYY-MM-DD HH:mm:ss');
            }
            return text

        },
    }
}
