import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import store from '@/lib/store';

import all_routes_processor from "@/router/init_router/lib/all_routes_processor";

// const type = ``;
// const type = `000`;
// const type = `100`;
const type = `101`;

const transition_name_type_000 = `van-fade`;

// const transition_name_type_100 = `van-slide-left`;//加这一行，方便以后Ctrl+F
const transition_name_type_100 = `van-slide`;

// const transition_name_type_101 = `lyn-modify-van-slide-left`;//加这一行，方便以后Ctrl+F
const transition_name_type_101 = `lyn-modify-van-slide`;

const init = function (pParameter) {
    if (!pParameter) pParameter = {};
    const from = pParameter.from;
    const to = pParameter.to;

    const name_from = from.name;
    const name_to = to.name;

    const params_to = to.params || {};
    const transition = params_to.transition || {};
    const transition_name_from_params = transition.name;

    const get_data_type_1xx = function () {
        if (!type.match(/^1\d{2}$/gi)) {
            return {};
        }

        const all_route_list = all_routes_processor.get_all_route_list();
        const route_from = _.chain(all_route_list)
            .find(function (m) {
                const name = m.name;
                return name === name_from;
            })
            .value()
        ;
        const route_to = _.chain(all_route_list)
            .find(function (m) {
                const name = m.name;
                return name === name_to;
            })
            .value()
        ;
        if (!route_from) {
            return {};
        }
        if (!route_to) {
            return {};
        }

        const order_index_from = route_from.meta.data_for_all_routes_processor.order_index;
        const order_index_to = route_to.meta.data_for_all_routes_processor.order_index;

        return {
            order_index_from,
            order_index_to,
        };
    };
    const data_type_1xx = get_data_type_1xx();
    const order_index_from = data_type_1xx.order_index_from;
    const order_index_to = data_type_1xx.order_index_to;

    let transition_name = ``;
    if (transition_name_from_params) {
        transition_name = transition_name_from_params;
    } else {
        //前进：向左滑动
        //后退：向右滑动
        if (type === `000`) {
            transition_name = transition_name_type_000;
        } else if (type === `100`) {
            if (order_index_to > order_index_from) {
                transition_name = `${transition_name_type_100}-left`;
            } else {
                transition_name = `${transition_name_type_100}-right`;
            }
        } else if (type === `101`) {
            if (order_index_to > order_index_from) {
                transition_name = `${transition_name_type_101}-left`;
            } else {
                transition_name = `${transition_name_type_101}-right`;
            }
        } else {
            transition_name = transition_name_type_000;
        }
    }

    store.state.data_for_base_layout.transition.name = transition_name;
};

export default {
    init,
};

//参考：
//https://vant-contrib.gitee.io/vant/#/zh-CN/field
