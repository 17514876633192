import Q from 'q';
import _ from 'underscore';
import { Dialog, Notify } from 'vant';
import consumer_sys_sms_vcodeSend from '@/lib/data-service/haolv-default/consumer_sys_sms_vcodeSend'
import consumer_appRegister from '@/lib/data-service/haolv-default/consumer_appRegister'

export default {
    components: {},
    data() {
        const validatePhone = (value) => {
            const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/;
            return regExp.test(value);
        };
        const validatePassword = (value) => {
            const regExp = /^(?=.*[A-Za-z])(?=.*[0-9]).{6,20}$/;
            return regExp.test(value);
        };
        return {
            num: 60,
            count: 60,
            isDisabled: false,
            isChecked: true,
            ruleForm: {},
            rules: {
                phone: [
                    {required: true, message: '请输入手机号码'},
                    {validator:validatePhone, message: '请输入正确的手机号码'}
                ],
                password: [
                    {required: true, message: '请输入密码'},
                    {validator:validatePassword, message: '请输入6~20位带字母字符密码'}
                ],
                verification_code: [
                    {required: true, message: '请输入验证码'}
                ],
            },
            init_parameter: {
                form: {
                    row_phone: {
                        left_icon: ` haolv-enterprise-app-iconfont haolv-enterprise-app-iconregister_phone`,
                        placeholder: `手机号码`,
                    },
                    row_password: {
                        left_icon: ` haolv-enterprise-app-iconfont haolv-enterprise-app-iconregister_password`,
                        placeholder: `密码`,
                    },
                    row_verification_code: {
                        left_icon: ` haolv-enterprise-app-iconfont haolv-enterprise-app-iconregister_safe`,
                        placeholder: `验证码`,
                    },
                },
                event_handler: {
                    event_handler_login_successfully: null,
                },
            },

        };
    },
    methods: {
        to_login() {
            const __this = this;
            __this.$router.push({
                name: `login`,
            });
        },
        sendVerCode(el) {
            const __this = this;
            __this.$refs.aVanForm.validate('phone').then(() => { // 验证通过请求验证码
                __this.get_verification_code(el);
            }).catch(error => {
                console.log(error);
            });
        },
        get_verification_code(el) {
            const __this = this;
            const params = {
                phone: __this.ruleForm.phone,
                sence: 'ALIYUN_VCODE_REGISTER'
            };
            consumer_sys_sms_vcodeSend(params).then(res => {
                Notify({type: 'success', message: '发送成功', duration: 1000});
                if(__this.num < 60) return; // 时间小于count秒 return
                __this.isDisabled = true;
                __this.num -= 1;
                el.target.innerHTML = __this.num + 's';
                let timer = setInterval(function () {
                    __this.num = __this.num -1;
                    el.target.innerHTML = __this.num + 's';
                    if (__this.num === 0) {
                        __this.isDisabled = false;
                        el.target.innerHTML = '获取';
                        clearInterval(timer);
                        __this.num = 60;//重置count秒
                    }
                }, 1000);
            }).catch((res) => {
            })
        },

        __event_handler_form_submit() {
            const __this = this;
            if (!__this.isChecked) {
                Notify({type: 'warning', message: '请阅读同意《铨成使用条款》', duration: 1000});
                return;
            }


            console.log(__this.ruleForm)
            consumer_appRegister(__this.ruleForm).then(res => {
                __this.$router.push({
                    name: `registration-success`,
                });
            }).catch((error) => {
                console.log(error)
                __this.$router.push({
                    params: {
                        msg: error.data.msg
                    },
                    name: `registration-error`,
                });
            });
        },
        __event_handler_form_failed(data) {
            const __this = this;
            const errors = data.errors;
            if (!_.isArray(errors)) return;
            if (errors.length <= 0) return;
            const first_error = errors[0];
            const first_error_name = first_error.name;

            const aVanForm = __this.$refs.aVanForm;
            aVanForm.scrollToField(first_error_name, true);
        },
    },
    created() {
        // const __this = this;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
