import TrainTravelerSelector from '@/component/train-traveler-selector/1.0.0/index.vue'
export default {
    data() {
        return {
            passengerList: [123],
        }
    },
    components: {TrainTravelerSelector},
    created() {
    },
    mounted() {
        const params = {
            evectionNo: '16571363136100262',
            mock_data: {
                enable: true,
                test_case: '002'
            }
        };
        this.$refs['trainTravelerSelector'].init(params)
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _openPopup() {
            this.$refs['trainTravelerSelector'].toShow({
                activePassengerList: this.passengerList
            })
        },
        _result(val) {
            console.log(val)
            this.passengerList = val.userIdList
        },
    }
}