import NavBar from "@/component/nav-bar/import_latest_version_module";
import getCityLevel from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getCityLevel";
import consumer_web_policyConfig_hotelPolicyTemplateDetail from '@/lib/data-service/haolv-default/consumer_web_policyConfig_hotelPolicyTemplateDetail'
export default {
    data() {
        return {
            popupShow: false,

            cityLevelList: [],
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        init(params) {
            this.popupShow = true;
            this.cityLevelList = [];
            this.$showLoading.show();
            consumer_web_policyConfig_hotelPolicyTemplateDetail().then(res=> {
                let datas = res.datas;
                this.cityLevelList = datas.sortList;
                console.log(this.cityLevelList)
            }).finally(()=>{
                this.$showLoading.hide();
            })
        }
    }
}
