//高德地图操作手册 https://lbs.amap.com/api/javascript-api/guide/abc/quickstart

import Q from 'q';
import _ from 'underscore';
import extend from "extend";
import get_uuid from "nllib/src/common/get_uuid";
import OpenLocationProcessor
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/open_location_processor/1.0.0/index.vue'
import {Toast} from "vant";

export default {
    data() {
        return {
            uid: '',
            map: {},
            isOpenInfoWindow: false,
            hotelName: '',
            longitude: 0,
            latitude: 0,
        }
    },
    components: {
        OpenLocationProcessor
    },
    methods: {
        //初始化
        init(pParameter) {
            this.isOpenInfoWindow = pParameter.isOpenInfoWindow;
            this.hotelName = pParameter.hotelName;
            this.longitude = pParameter.center[0];
            this.latitude = pParameter.center[1];

            const defaultParameter = {
                center: [116.3972, 39.9096],
                zoom: 16
            };
            const parameter = extend(true, {}, defaultParameter, pParameter);
            if (!_.isArray(parameter.center) || parameter.center.length !== 2) {
                const msg = `init map pParameter error，code=001`;
                console.error(msg);
                console.trace();
                return Q.reject({msg: msg});
            }
            this.uid = get_uuid();
            this.$nextTick(function () {
                const container = this.$refs['amap-' + this.uid];
                this.map = new AMap.Map(container, parameter);

                //创建点标记
                const marker = new AMap.Marker({
                    // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
                    position: new AMap.LngLat(parameter.center[0], parameter.center[1]),
                });
                //将创建的点标记添加到已有的地图实例：
                this.map.add(marker);

                if (pParameter.isOpenInfoWindow) {
                    this.openInfoWindow(pParameter);
                }
            })
        },

        //显示信息框，包含导航按钮
        openInfoWindow(params) {
            let infoWindowContent = this.$refs.infoWindowContent;

            //构建自定义信息窗体
            let infoWindow = new AMap.InfoWindow({
                content: infoWindowContent,
                offset: new AMap.Pixel(0, -45)
            });

            infoWindow.open(this.map, params.center);
        },

        //开始导航
        starNav() {
            let data = {
                lat: this.latitude,
                lon: this.longitude,
                name: this.hotelName
            };
            this.$refs.aOpenLocationProcessor.run(data);
        },
        copySuc() {
            Toast('复制成功');
        }
    }
}
