export default {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "page-login" */ `./2.0.3/index.vue`),
    meta: {
        data_for_base_layout: {
            header: {
                show: false,
                title: ``,
            },
            tab_bar: {
                show: false,
            }
        },

        enable_check_token: false,
    },
};
