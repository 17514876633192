import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_trains_product_getOrderInsuranceInfo from '@/lib/data-service/haolv-default/consumer_trains_product_getOrderInsuranceInfo' // 获取保险列表
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_journey_calculatePrice from '@/lib/data-service/haolv-default/consumer_journey_calculatePrice' // 计算价格
import consumer_trains_product_getAlternativeTrainNumber from '@/lib/data-service/haolv-default/consumer_trains_product_getAlternativeTrainNumber' // 获取备选车次
import consumer_apply_myApplyList from '@/lib/data-service/haolv-default/consumer_apply_myApplyList' // 我的出差
import consumer_department_getDepartmentUsers from '@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers' //  请求员工数据
import consumer_web_externalCustomer_getExternalCustomerAll from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerAll' // 请求外部客户列表
import consumer_air_ticket_flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import consumer_flight_order_deleteOrder from '@/lib/data-service/haolv-default/consumer_flight_order_deleteOrder' // 删除机票订单
import consumer_trains_order_delOrder from '@/lib/data-service/haolv-default/consumer_trains_order_delOrder' // 删除火车订单
import consumer_tOdHotelOrderInterim_del from '@/lib/data-service/haolv-default/consumer_tOdHotelOrderInterim_del' // 删除酒店订单
import consumer_journey_unityCreate from '@/lib/data-service/haolv-default/consumer_journey_unityCreate' // 最终创建订单
import consumer_trains_order_orderScheduledCheck from '@/lib/data-service/haolv-default/consumer_trains_order_orderScheduledCheck' // 校验超标
import consumer_journey_addOrUpdateOverproof from '@/lib/data-service/haolv-default/consumer_journey_addOrUpdateOverproof' // 创建超标订单
import consumer_tOdHotelOrderInterim_updateCheckInPerson from '@/lib/data-service/haolv-default/consumer_tOdHotelOrderInterim_updateCheckInPerson' // 重新安排酒店房间入住
import Transfer from '@/page/travel-book/flight/component/transfer/index.vue'
import CheckTravel from '@/page/travel-book/flight/component/checkTravel/index.vue'
import CostAttributionList from '@/page/travel-book/flight/component/costAttributionList/index.vue'
import ChangClause from '@/page/travel-book/flight/component/changClause/1.0.0/index.vue'
import ReservePopupBox from '@/page/travel-book/flight/component/reservePopupBox/1.1.5/index.vue'
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import moment from "moment";
import { Toast, Dialog } from 'vant'
import SelectUser from '@/component/select-user/index.vue'
// import consumer_air_ticket_flightLuggage from '@/lib/data-service/haolv-default/consumer_air_ticket_flightLuggage'
import consumer_frequent_contacts_getList from '@/lib/data-service/haolv-default/consumer_frequent_contacts_getList' // 获取常用联系人
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import consumer_trains_order_orderBookingPolling from '@/lib/data-service/haolv-default/consumer_trains_order_orderBookingPolling'
import consumer_apply_myApplyListV2 from '@/lib/data-service/haolv-default/consumer_apply_myApplyListV2'
//import consumer_admin_behalfCustomer_getTravelerList from '@/lib/data-service/haolv-default/consumer_admin_behalfCustomer_getTravelerList'

import consumer_air_ticket_flightLuggage from '@/lib/common-service/consumer_air_ticket_flightLuggage'
export default {
    data () {
        return {
            redirectType: '', // 跳转类型
            evectionNo: '', //
            userInfo: {}, // 个人信息
            evectionInfo: {}, // 出差信息合集
            evectionParams: {
                evectionNo: '',
                evectionType: 2,
                explainDesc: '',
                feeAffiliationId: '',
                feeAffiliationName: '', // 费用归属名称
                feeAffiliationType: 1,
                linkman: '',
                linkmanPhone: '',
                reason: '',
                travelUserList: []
            },

            insuranceProId: '', // 选中的保险
            insuranceList: [], // 保险列表
            insuranceDesc: '', // 保险解释
            insuranceTrainIndex: '', // 当前选择的火车序号
            insurancePopup: false, // 保险弹窗

            trainList: [], // 火车票行程列表
            flightList: [], // 飞机票行程列表
            hotelList: [], // 酒店行程列表

            travelUserList: [], // 出行人合集
            travelUserIdList: [], // 出行人id集合
            chooseTravelUserList: [], // 备选出行人集合

            trainNoSeatCheck: [], // 是否勾选无座的CheckBox里诶啊哦
            numberOfSeats: '', // 当前选座的列车座位数量
            currentTrainBusinessSeatArr: [], // 当前选座的列表的座位列表
            currentChooseSeatArr: [], // 当前选座的列车选中的座位
            currentTrainIndex: '', // 当前选座的列车序号

            businessSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {label: 'F', value: '2F', active: false}]
            ], // 特等座选位
            firstSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
            ], // 一等座选位
            secondSeatArr: [
                [{label: 'A', value: '1A', active: false}, {label: 'B', value: '1B', active: false}, {label: 'C', value: '1C', active: false}, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
                [{label: 'A', value: '2A', active: false}, {label: 'B', value: '2B', active: false}, {label: 'C', value: '2C', active: false}, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
            ], // 二等座选位

            trainPopup: false, // 备选车次弹窗
            trainPopupIndex: '', // 当前备选车次的火车序号
            currentChooseTrainList: [], // 当前备选弹窗选中的车次
            currentTrainList: [], // 当前备选车次列表

            trainSeatPopup: false, // 备选坐席弹窗
            currentChooseSeatList: [], // 当前备选弹窗选中的坐席
            currentTrainSeatList: [], // 当前备选坐席列表
            trainSeatPopupIndex: '', // 当前备选坐席的火车序号


            priceResult: {
                flightCalculates: []
            },

            params: {
                evection: ''
            },
            seatPopup: false,
            checked: false,

            haveTrainEvection: false, // 火车票是否允许继续下单
            haveFlightEvection: false, // 机票是否允许继续下单
            haveHotelEvection: false, // 酒店是否允许继续下单

            popupShow: false,
            allUserItems: [], // 全部员工列表
            activeUserIds: [], // 选中的员工id

            travelUserSelectPopupShow: false, // 出差单情况下选择出行人
            activeCanChooseUserList: [], // 出差单情况下可以选择的出行人
            canChooseUserList: [], // 出差单情况下选中的出行人

            userDelPopupShow: false, // 设置出行人弹窗
            feeAffiliationShow: false, // 费用归属弹窗

            ruleText: {}, // 退改规则
            luggage: '', // 行李信息
            luggageParams: {}, // 行李对象
            ruleShow: false, //退改规则弹窗

            ruleLoading: false,
            luggageLoading: false,

            newTravelParams: {
                travelCurrent: '',
                hotelDateArr: null,
                hotelKeyword: '',
                hotelCheckInDate: '',
                hotelCheckOutDate: '',
                businessType: 1
            },
            travelCurrent: null,

            dialogNewFlightOrderVisible: false, // 添加机票航程弹窗
            dialogNewTrainOrderVisible: false, // 添加火车票航程弹窗
            dialogNewHotelOrderVisible: false, // 添加酒店弹窗

            travelPopupShow: false, // 出差单选择弹窗
            currentBusinessTravelList: [], // 当前的出差预定信息
            onlySearch: false, // 是否只搜索高铁 1=是，0=否
            showCalendar: false, //

            canSubmit: true, // 防连点

            productPricePopup: false, // 订单价格详情弹窗
            productPricePopupSet: false,
            successPopup: false, // 订单成功后弹窗
            resultMsg: '', // 消息
            isHaveOverproof: false,

            hotelUserVisible: false,
            hotelForm: {
                roomNum: 0,
                checkInPerson: []
            },
            hotelRules: {},
            hotelUserList: [],
            showHotelUserPicker: false,
            hotelUserIndex: '',

            selectUserPopup: false, // 选择出行人组件
            selectContactUserPopup: false, // 选择联系人组件

            contactUserList: [],
            activeContactUserList: [],

            footerSubmitPaddingBottom: 0,

            orderBookingPolling: '',

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 3,
            returnSetInterval: '',
            workTravelType: 1, // 因公出差的类型， 1是有选出差单，2是没有
        }
    },
    components: {
        Transfer,
        CostAttributionList,
        ChangClause,
        ReservePopupBox,
        CheckTravel,
        SelectUser
    },
    created () {},
    mounted () {
        const _this = this
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                // document.querySelector('#navBar').style.paddingTop = `${api.safeArea.top}px`;
                _this.$refs['navBar'].$el.style.paddingTop = `${api.safeArea.top}px`;
                _this.$refs['footerSubmit'].style.paddingBottom = `${api.safeArea.bottom}px`;
                _this.footerSubmitPaddingBottom = api.safeArea.bottom
            }
        });
    },
    async activated () {
        // 初始化莫名转化为true
        this.dialogNewTrainOrderVisible = false
        this.dialogNewFlightOrderVisible = false
        this.dialogNewHotelOrderVisible = false
        this.redirectType = this.$route.query.redirectType;
        this.evectionNo = this.$route.query.evectionNo;
        this.evectionType = this.$route.query.evectionType
        this.workTravelType = parseInt(this.$route.query.workTravelType) // // 因公出差的类型， 1是有选出差单，2是没有

        Toast.loading({
            message: '',
            forbidClick: true,
        });
        //await this.getUserInfo(); // 获取个人资料
        await this.getInsuranceInfo(); // 获取保险信息列表
        await this.getEvectionInfo()

        this.getTravelInfoAndUserList() // 获取当前出差单信息和可选择的出行人列表
        this.initCheckTravelBox() // 初始化校验弹框
        this.getAllPrice()

        this.getDepartmentUserList()

        if (this.evectionType === 1) {
            this.getUsuallyUser()// 获取常用旅客
        }
    },
    deactivated() {
        this.orderBookingPolling = ''
    },
    destroyed () {},
    watch: {
        travelCurrent(newVal) {
            if (newVal) {
                let active = newVal.active
                this.newTravelParams.travelCurrent = `${active.departCityName}--${active.toCityName}`
            }
        },
        dialogNewTrainOrderVisible(newVal) {
            console.log('dialogNewTrainOrderVisible', newVal)
        },
    },
    computed: {},
    filters: {
        filterDate(val) {
            if (val) {
                return moment(val).format('YYYY年MM月')
            } else {
                return ''
            }
        },
        filterDay(val) {
            if (val) {
                return moment(val).format('MM月DD日')
            } else {
                return ''
            }
        },
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
        filterIdType(val) {
            // 证件类型 (1:身份证 2:护照 4:港澳通行证 5:台胞证 6:台湾通行证 7:回乡证 8:军官证 9:其他 10:学生证)
            if (val === 1) {
                return '身份证'
            } else if (val === 2) {
                return '护照'
            } else if (val === 3) {
                return ''
            } else if (val === 4) {
                return '港澳通行证'
            } else if (val === 5) {
                return '台胞证'
            } else if (val === 6) {
                return '台湾通行证'
            } else if (val === 7) {
                return '回乡证'
            } else if (val === 8) {
                return '军官证'
            } else if (val === 9) {
                return '其他'
            } else if (val === 10) {
                return '学生证'
            } else {
                return ''
            }
        }
    },
    methods: {
        // 启动超标检测组件
        initCheckTravelBox() {
            this.$refs.checkTravelBox.init({
                checkFun: () => {
                    let flightDate = []
                    if (this.flightList.length > 0) {
                        this.flightList.forEach(value => {
                            let flightItem = {
                                bottomPrice: '',
                                cabinRank: value.cabinRank,
                                depDate: value.segmentList[0].depDate,
                                discount: value.segmentList[0].discount,
                                price: value.segmentList[0].price,
                                evectionNo: this.evectionNo,
                                depTime: value.segmentList[0].depTime,
                                rqData: value.segmentList[0]
                            }
                            flightDate.push(flightItem)
                        })
                    }
                    let params = {
                        evectionNo: this.evectionNo,
                        userIds: this.activeUserIds,
                        excessRequest: flightDate
                    }
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                        duration: 0
                    })
                    return consumer_trains_order_orderScheduledCheck(params).then(res => {
                        Toast.clear()
                        return res.datas.violationResults || [] // 因无超标人时返回的空数组现在变成null，所以需自传空数组
                    }).catch(() => {
                        Toast.clear()
                        return null
                    })
                },
                reserveSubmitFun: (warningList) => {
                    let overproofApplyList = []
                    if (!warningList) {
                        this.canJourney = true
                        Toast.clear()
                        return
                    }
                    warningList.forEach(value => {
                        let item = {
                            businessType: value.businessType,
                            controlRule: value.controlRules,
                            orderNo: value.orderNo,
                            reason: value.reason,
                            reserveContent: value.violationOfReason,
                            standard: value.standard,
                            standardContent: value.seatName,
                            userId: value.userId,
                            userName: value.userRealName
                        }
                        overproofApplyList.push(item)
                    })

                    let params ={
                        evectionNo: this.evectionNo,
                        overproofApplyList
                    }
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                        duration: 0
                    })
                    consumer_journey_addOrUpdateOverproof(params).then(res => {
                        Toast.clear()
                        let travelUserList = []
                        this.canChooseUserList.forEach(value1 => {
                            if (this.activeUserIds.indexOf(value1.id) > -1) {
                                travelUserList.push(value1)
                            }
                        })
                        this.evectionParams.travelUserList = travelUserList
                        // 清除报错提示
                        // this.$refs['orderForm'].clearValidate('travelUserList')
                        this.saveAndReactPrice()

                        // 如果有酒店订单
                        if (this.evectionParams.travelUserList.length > 0) {
                            this.reactHotelUser()
                        }
                    }).catch(() => {
                        Toast.clear()
                    })
                }
            })
        },
        reactHotelUser() {
            if (this.hotelList.length > 0) {
                this.hotelForm.roomNum = this.hotelList[0].roomNum
                let checkInPerson = []
                for (let i = 0, l = this.hotelForm.roomNum; i < l; i++) {
                    checkInPerson.push('')
                }
                this.hotelForm.checkInPerson = checkInPerson
                this.hotelUserList = JSON.parse(JSON.stringify(this.evectionParams.travelUserList))
                this.hotelUserList.forEach(value => {
                    value.disabled = false
                })
                this.hotelUserVisible = true
            }
        },
        back() {
            this.$router.go(-1)
        },
        seatSubmit() {
            this.trainList[this.currentTrainIndex].seatArr = JSON.parse(JSON.stringify(this.currentTrainBusinessSeatArr))
            this.trainList[this.currentTrainIndex].chooseSeatArr = JSON.parse(JSON.stringify(this.currentChooseSeatArr))
            if (this.trainList[this.currentTrainIndex].chooseSeatArr.length > 0) {
                this.trainList[this.currentTrainIndex].choose = true
            } else {
                this.trainList[this.currentTrainIndex].choose = false
            }
            this.seatPopup = false
        },
        // 弹出座位选择弹窗
        showSeatPopup(val, index) {
            this.currentTrainIndex = index
            if (this.trainList[this.currentTrainIndex].chooseSeatArr.length > 0) {
                this.trainList[this.currentTrainIndex].choose = true
            } else {
                this.trainList[this.currentTrainIndex].choose = false
            }
            this.numberOfSeats = val.numberOfSeats
            this.currentTrainBusinessSeatArr = JSON.parse(JSON.stringify(val.seatArr))
            this.currentChooseSeatArr = JSON.parse(JSON.stringify(val.chooseSeatArr))
            this.seatPopup = true
        },
        // 选择座位
        handleChooseSeat(index, sIndex, val) {
            if (val.active) { // 取消
                this.currentTrainBusinessSeatArr[index][sIndex].active = false
                let seatIndex = this.currentChooseSeatArr.indexOf(val.value)
                this.currentChooseSeatArr.splice(seatIndex, 1)
                // this.currentTrainBusinessSeat = this.currentChooseSeatArr.join(',')
            } else { // 选中
                if (this.evectionParams.travelUserList.length === 0) {
                    Toast('请先添加出行人')
                }
                if (this.currentChooseSeatArr.length >= this.evectionParams.travelUserList.length) {
                    return
                }
                this.currentTrainBusinessSeatArr[index][sIndex].active = true
                this.currentChooseSeatArr.push(val.value)
                // this.currentTrainBusinessSeat = this.currentChooseSeatArr.join(',')
            }
        },
        // 确认保险
        onInsuranceConfirm(val) {
            this.trainList[this.insuranceTrainIndex].insuracneFlag = val.id === '' ? 0 : 1
            if (val.id === '') {
                this.trainList[this.insuranceTrainIndex].insuranceProId = ''
                this.trainList[this.insuranceTrainIndex].insuranceDesc = null
                this.trainList[this.insuranceTrainIndex].insurancePrice = null // 单价
                this.trainList[this.insuranceTrainIndex].insuranceName = null // 名字
            } else {
                this.trainList[this.insuranceTrainIndex].insuranceProId = val.id
                this.trainList[this.insuranceTrainIndex].insuranceDesc = val.insuranceDesc
                this.trainList[this.insuranceTrainIndex].insurancePrice = val.insurancePrice // 单价
                this.trainList[this.insuranceTrainIndex].insuranceName = val.insuranceClass.name // 名字
            }
            this.insurancePopup = false
            this.saveAndReactPrice()
        },
        // 取消选择保险
        onInsuranceCancel() {
            this.insurancePopup = false
        },
        // 选择保险
        onInsuranceChange() {},
        // 显示保险弹窗
        showInsurancePopup(val, index) {
            this.insuranceTrainIndex = index
            let showIndex = 0
            if (val.insuranceProId !== null && val.insuranceProId !== '') {
                for (let i = 0, l = this.insuranceList.length; i < l; i++) {
                    if (val.insuranceProId === this.insuranceList[i].id) {
                        showIndex = i
                        break
                    }
                }
            }
            this.insurancePopup = true
            this.$nextTick(() => {
                this.$refs.insurancePopupBox.setIndexes([showIndex])
            })
        },
        // 打开抢票车次列表
        openTrainPopup(val, index) {
            this.currentTrainList = JSON.parse(JSON.stringify(val.alternativeTrainNumberList))
            this.currentChooseTrainList = JSON.parse(JSON.stringify(val.acceptTrainCodesArr))
            this.trainPopup = true
            this.trainPopupIndex = index
        },
        // 选择备选车次
        trainToggle(val, index) {
            if (this.currentChooseTrainList.length >= 4 && this.currentChooseTrainList.indexOf(val.trainCode) === -1) {
                Toast('最多可以选择4个备选车次')
                return
            }
            this.$refs.trainCheckboxes[index].toggle()
        },
        //
        changeTrainCode(checked) {
            if (checked.length > 4) {
                let lastCheck = checked[checked.length - 1]
                for (let i = 0, l = this.currentTrainList.length; i < l; i++) {
                    if (lastCheck === this.currentTrainList[i].trainCode) {
                        this.$refs.trainCheckboxes[i].toggle()
                        break
                    }
                }
            }
        },
        // 关闭备选车次弹窗
        closeTrainPopup() {
            this.trainPopup = false
        },
        // 确定选择备选车次
        submitTrainPopup() {
            this.trainList[this.trainPopupIndex].acceptTrainCodesArr = JSON.parse(JSON.stringify(this.currentChooseTrainList))
            this.trainList[this.trainPopupIndex].acceptTrainCodes = this.currentChooseTrainList.join(',')
            this.trainPopup = false
            this.filterSeatList(this.trainPopupIndex)
        },
        // 关闭备选坐席弹窗
        closeTrainSeatPopup() {
            this.trainSeatPopup = false
        },
        // 确定选择备选坐席
        submitTrainSeatPopup() {
            this.trainList[this.trainSeatPopupIndex].seatList = JSON.parse(JSON.stringify(this.currentTrainSeatList))
            this.trainList[this.trainSeatPopupIndex].acceptSeatCodesArr = JSON.parse(JSON.stringify(this.currentChooseSeatList))
            this.trainSeatPopup = false
            this.chooseSeat(this.trainSeatPopupIndex, this.trainList[this.trainSeatPopupIndex].acceptSeatCodesArr)
        },
        // 选择备选坐席
        trainSeatToggle(index) {
            this.$refs.seatCheckboxes[index].toggle()
        },
        // 打开备选坐席的弹窗
        openTrainSeatPopup(val, index) {
            this.currentTrainSeatList = JSON.parse(JSON.stringify(val.seatList))
            this.currentChooseSeatList = JSON.parse(JSON.stringify(val.acceptSeatCodesArr))
            this.trainSeatPopup = true
            this.trainSeatPopupIndex = index
        },
        // 提交
        async onSubmit() {
            if (!this.canSubmit) {
                return
            }
            if (this.flightList.length === 0 && this.trainList.length === 0 && this.hotelList.length === 0) {
                Toast.fail('请先添加行程');
                return
            }
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
            });
            let valid = await this.checkForm();
            if (!valid) {
                Toast.clear();
                return
            }
            let saveResult = await this.saveInfo();
            if (!saveResult) {
                Toast.clear();
                return
            }
            // 重新保存酒店出行人，当前有酒店的情况（当前默认只能订一间酒店）
            if (this.hotelList.length > 0) {
                let params = {
                    checkInPerson: [],
                    orderNo: ''
                };
                let roomNum = this.hotelList[0].roomNum;
                this.evectionParams.travelUserList.forEach((value, index) => {
                    if (roomNum > index) {
                        params.checkInPerson.push(value.userId || value.id)
                    }
                });
                // if (this.evectionInfo.evectionType === 2) {
                //     let roomNum = this.hotelList[0].roomNum
                //     this.evectionParams.travelUserList.forEach((value, index) => {
                //         if (roomNum > index) {
                //             params.checkInPerson.push(value.userId)
                //         }
                //     })
                // } else {
                //     saveResult.datas.forEach((value, index) => {
                //         let roomNum = this.hotelList[0].roomNum
                //         if (roomNum > index) {
                //             params.checkInPerson.push(value)
                //         }
                //     })
                // }

                params.orderNo = this.hotelList[0].orderNo
                let [hotelErr, hotelRes] = await awaitWrap(consumer_tOdHotelOrderInterim_updateCheckInPerson(params))
                if (hotelErr) {
                    Toast.clear()
                    return
                }
            }
            this.canSubmit = false
            let [err, result] = await awaitWrap(consumer_journey_unityCreate({evectionNo: this.evectionNo, pageType: 1}))
            this.canSubmit = true
            if (err) {
                Toast.clear()
                let errorCode = err.data.code;
                let orderResultList = err.data.datas.orderResultList
                this.errorText = err.data.msg;
                this.dialogError2Visible = true
                if (!orderResultList) {
                    return
                }
                return
            }
            let orderResult = result.datas
            let canSubmit = true
            orderResult.orderResultList.forEach(value => {
                if (!value.isSuccess) {
                    canSubmit = false
                }
            });
            // Toast.clear()
            if (canSubmit) {
                // 如果有火车票
                let _this = this
                this.orderBookingPolling = function (trainOrderList, orderResult) {
                    consumer_trains_order_orderBookingPolling({orderNos: trainOrderList}).then(result => {
                        let resultSuccessfulList = []
                        result.orderBookingPollingResults.forEach(value => {
                            resultSuccessfulList.push(value.successful)
                        })
                        if (resultSuccessfulList.indexOf(0) >= 0) {
                            // 继续轮询
                            setTimeout(() => {
                                if (this.orderBookingPolling === '') {
                                    return
                                }
                                this.orderBookingPolling(trainOrderList, orderResult)
                            }, 500)
                        } else {
                            // 成功
                            Toast.clear()
                            this.orderResult(orderResult)
                        }
                    }, error => {
                        // 失败
                        Toast.clear()
                        let errorCode = error.data.code;
                        let orderResultList = error.data.datas.orderBookingPollingResults
                        this.errorText = error.data.msg;
                        Dialog.alert({
                            title: '温馨提示',
                            message: this.errorText,
                        }).then(() => {
                            // on close
                        });
                    }).catch(() => {
                        Toast.clear()
                    })
                }

                if (this.trainList.length > 0) {
                    let trainOrderList = []
                    this.trainList.forEach(value => {
                        trainOrderList.push(value.orderNo)
                    })
                    this.orderBookingPolling(trainOrderList, result)
                } else {
                    Toast.clear()
                    this.orderResult(result)
                }


                /*if (this.evectionInfo.evectionType === 2) {
                    this.resultMsg = result.msg
                    this.isHaveOverproof = orderResult.isHaveOverproof
                    this.successPopup = true
                } else {
                    this.$router.push({
                        name: 'h5-travel-payment',
                        query: {
                            evectionNo: this.evectionNo
                        }
                    })
                }*/

                if (this.trainList.length > 0) {
                    let trainOrderList = []
                    this.trainList.forEach(value => {
                        trainOrderList.push(value.orderNo)
                    })
                    this.orderBookingPolling(trainOrderList, result)
                } else {
                    Toast.clear()
                    this.orderResult(result)
                }

            } else {
                Toast.fail('订票失败，请重新选择行程')
            }
        },
        orderResult(result) {
            if (result.datas.creditExtension || this.evectionInfo.evectionType === 1) {
                // 走收银台
                this.$router.push({
                    name: 'h5-travel-payment',
                    query: {
                        evectionNo: this.evectionNo,
                        businessType: 2,
                    }
                })
            } else {
                let orderType = null;
                let orderNo = null;
                if (this.priceResult.flightCalculates != null) {
                    orderType = 1;
                    orderNo = this.priceResult.flightCalculates[0].orderNo
                } else if (this.priceResult.orderAmountOfCalculationResults != null ) {
                    orderType = 2;
                    orderNo = this.priceResult.orderAmountOfCalculationResults[0].orderNo
                } else if (this.priceResult.todHotelOrderInterims != null) {
                    orderType = 3;
                    orderNo = this.priceResult.todHotelOrderInterims[0].orderNo
                }
                // 完成支付
                this.$router.push({
                    name: 'h5-payment-success',
                    query: {
                        orderNo,
                        orderType
                    }
                });
            }
        },
        // 检查表单
        checkForm() {
            return new Promise(resolve => {
                this.$refs['reviewForm'].validate().then(() => {
                    resolve(true)
                }).catch(() => {
                    resolve(false)
                })
            })
        },
        inputFontLong(val) {
            if (val && val.length < 3) {
                return ''
            } else {
                return 'small'
            }
        },
        submitEnd() {
            this.successPopup = false
            this.$router.push({
                name: 'h5-evection-order-detail',
                query: {
                    pageType: 2,
                    evectionNo: this.evectionNo
                }
            })
        },

        to_payment () {
            this.$router.push({name: 'h5-travel-payment', businessType: 2,});
        },
        // 获取个人信息
        async getUserInfo() {
            let res = await get_user_info()
            this.userInfo = res.datas
        },
        // 获取保险信息
        async getInsuranceInfo() {
            let [err, res] = await awaitWrap(consumer_trains_product_getOrderInsuranceInfo())
            if (err) {
                return
            }
            let insuranceList = res.data
            insuranceList.forEach((value, index) => {
                value.value = value.id
                value.text = `${value.insurancePrice}元/份 ${value.insuranceRemark}`
                /*if (index === 0) {
                    this.insuranceProId = value.id
                    this.insuranceDesc = value.insuranceDesc
                }*/
            })
            insuranceList.unshift({
                text: '无',
                value: '',
                id: ''
            })
            this.insuranceList = insuranceList
        },
        // 获取出差信息
        async getEvectionInfo() {
            let [err, res] = await awaitWrap(consumer_journey_getEvectionDetail({evectionNo: this.evectionNo, pageType: 1, personType: 2}))
            if (err) {
                Toast.clear()
                return
            }
            let data = res.datas
            this.evectionInfo = res.datas
            let travelUserIdList = []
            data.travellerList.forEach(value => {
                value.id = value.userId
                value.text = value.staffName
                value.certificateType = value.certificateList.length > 0 ? value.certificateList[0].certificateType : ''
                value.certificateValue = value.certificateList.length > 0 ? value.certificateList[0].certificateValue : ''

                travelUserIdList.push(value.userId)
            })
            this.travelUserIdList = travelUserIdList
            this.chooseTravelUserList = JSON.parse(JSON.stringify(data.travellerList))
            this.evectionParams = {
                evectionNo: data.evectionNo,
                evectionType: data.evectionType,
                explainDesc: data.explainDesc,
                feeAffiliationId: data.feeAffiliationId,
                feeAffiliationName: data.feeAffiliationName, // 费用归属名称
                feeAffiliationType: data.feeAffiliationType ? data.feeAffiliationType : 1,
                linkman: data.linkman ? data.linkman : this.userInfo.realName,
                linkmanPhone: data.linkmanPhone ? data.linkmanPhone : this.userInfo.phone,
                reason: data.reason,
                travelUserList: data.travellerList ? data.travellerList : []
            }
            this.travelUserList = res.datas.travellerList ? res.datas.travellerList : []
            // 获取火车票行程
            let trainList = res.datas.ticketBookingPageDetailsResponseList
            let getAlternativeTrainList = [] // 需要获取
            trainList.forEach(value => {
                let seatArr = []
                if (value.canChooseSeat === 1 && value.numberOfSeats === 5) {
                    [...seatArr] = this.secondSeatArr
                } else if (value.canChooseSeat === 1 && value.numberOfSeats === 4) {
                    [...seatArr] = this.firstSeatArr
                } else if (value.canChooseSeat === 1 && value.numberOfSeats === 3) {
                    [...seatArr] = this.businessSeatArr
                }
                value.seatArr = JSON.parse(JSON.stringify(seatArr))
                value.chooseSeatArr = []
                value.choose = false
                value.canAcceptNoSeat = value.acceptNoSeat === 1 ? true : false
                this.trainNoSeatCheck.push(value.acceptNoSeat === 1 ? true : false)
                if (value.canChooseSeat === 1 && value.chooseSeat !== ''  && value.chooseSeat !== null) {
                    let chooseSeatArr = []
                    if (value.chooseSeat) {
                        for (let i = 0, l = value.chooseSeat.length; i < l; i+=2) {
                            let arr = value.chooseSeat.slice(i, i + 2)
                            chooseSeatArr.push(arr)
                        }
                    }
                    value.chooseSeatArr = chooseSeatArr
                    value.seatArr.forEach(val => {
                        val.forEach(val1 => {
                            if (value.chooseSeatArr.indexOf(val1.value) > -1) {
                                val1.active = true
                            }
                        })
                    })
                }
                // 保险信息
                value.insuranceProId = value.insuranceProId ? parseInt(value.insuranceProId) : value.insuranceProId
                value.insuranceDesc = ''
                if (value.insuranceProId !== null && value.insuranceProId !== '') {
                    for (let i = 0, l = this.insuranceList.length; i < l; i++) {
                        if (value.insuranceProId === this.insuranceList[i].id) {
                            value.insuranceDesc = this.insuranceList[i].insuranceDesc
                            value.insurancePrice = this.insuranceList[i].insurancePrice // 单价
                            value.insuranceName = this.insuranceList[i].insuranceClass.name // 名字
                        }
                    }
                }

                value.finalPrice = value.seatPrice // 次行程的最终单价，因为有抢票的情况
                // 获取当前车次的坐席列表
                value.trainSeatList = JSON.parse(value.robTicketNote)
                value.seatList = []


                // 抢票的情况需要获取备选车次列表
                if (value.orderType === 2) {
                    this.ticketGrabbingNumber++

                    value.acceptSeatCodesArr = value.acceptSeatCodes ? value.acceptSeatCodes.split(',') : []

                    let trainParams = {
                        fromStationCode: value.fromStationCode,
                        toStationCode: value.toStationCode,
                        trainCode: value.trainNo,
                        trainDate: value.startDate
                    }
                    let trainItem = consumer_trains_product_getAlternativeTrainNumber(trainParams)
                    getAlternativeTrainList.push(trainItem)
                }
                // consumer_trains_product_getAlternativeTrainNumber
            })
            this.trainList = trainList

            Promise.all(getAlternativeTrainList).then(data => {
                let index = 0
                let [...finalTrainList] = this.trainList
                finalTrainList.forEach(value => {
                    if (value.orderType === 2) {
                        let acceptTrainCodesArr = value.acceptTrainCodes ? value.acceptTrainCodes.split(',') : []
                        value.acceptTrainCodesArr = acceptTrainCodesArr
                        let alternativeTrainNumberList = data[index].resultList
                        /*alternativeTrainNumberList.forEach(value1 => {
                            if (acceptTrainCodesArr.indexOf(value1.trainCode) > -1) {
                                value1.active = true
                            } else {
                                value1.active = false
                            }
                        })*/
                        value.alternativeTrainNumberList = alternativeTrainNumberList
                        index++
                    }
                })
                this.trainList = finalTrainList
                this.trainList.forEach((value, index) => {
                    if (value.orderType === 2) {
                        this.filterSeatList(index)
                    }
                })
            }).catch(() => {
                Toast.clear()
            })


            // 获取机票行程
            let flightList = res.datas.flightDetail ? res.datas.flightDetail.orderList : []
            /*flightList.forEach(value => {
                value.segmentList.forEach(value1 => {})
            })*/
            this.flightList = flightList

            let hotelList = res.datas.todHotelOrderInterimList ? res.datas.todHotelOrderInterimList : []
            this.hotelList = hotelList

            Toast.clear()
        },
        // 选中座位
        filterSeatList(trainIndex) {
            let [...trainList] = this.trainList
            let [...seatList] = trainList[trainIndex].trainSeatList
            let seatResult = []

            trainList[trainIndex].alternativeTrainNumberList.forEach(value => {
                if (trainList[trainIndex].acceptTrainCodesArr.indexOf(value.trainCode) > -1) {
                    value.trainSeatVoList.forEach(val => {
                        seatList.push(val)
                    })
                }
            })
            // 获取备选车座列表
            for (let i = 0, l = seatList.length; i < l; i++) {
                let hasItem = false
                for (let m = 0, n = seatResult.length; m < n; m++) {
                    if (seatResult[m].seatCode === seatList[i].seatCode) {
                        hasItem = true
                        // 比较价格
                        if (parseFloat(seatResult[m].seatPrice) < parseFloat(seatList[i].seatPrice)) {
                            seatResult[m].seatPrice = seatList[i].seatPrice
                        }
                        break
                    }
                }
                if (!hasItem) {
                    seatResult.push(seatList[i])
                }
            }
            trainList[trainIndex].seatList = seatResult
            this.trainList = trainList

            // 再对比选中的坐席的价格
            this.chooseSeat(trainIndex, trainList[trainIndex].acceptSeatCodesArr)
        },
        chooseSeat(trainIndex, val) {
            let [...trainList] = this.trainList
            this.trainList = trainList
            // 比较最大价格
            let price = 0
            let acceptSeatInfoArr = [] // 选中的坐席的信息数组，用于显示
            let [...seatList] = this.trainList[trainIndex].seatList
            if (val !== undefined && val.length > 0) {
                // 有选中情况
                for (let i = 0, l = val.length; i < l; i++) {
                    for (let m = 0, n = seatList.length; m < n; m++) {
                        if (val[i] === seatList[m].seatCode && seatList[m].seatPrice > price) {
                            price = parseFloat(seatList[m].seatPrice)
                            // break
                        }
                        if (val[i] === seatList[m].seatCode) {
                            acceptSeatInfoArr.push(seatList[m])
                        }
                    }
                }
            }
            let seatPrice = price
            this.trainList[trainIndex].finalPrice = seatPrice > this.trainList[trainIndex].seatPrice ? seatPrice : this.trainList[trainIndex].seatPrice
            this.trainList[trainIndex].acceptSeatCodes = this.trainList[trainIndex].acceptSeatCodesArr.join(',')
            this.trainList[trainIndex].acceptSeatInfoArr = acceptSeatInfoArr

            this.saveAndReactPrice()
        },
        // 保存并重新获取价格
        async saveAndReactPrice() {
            await this.saveInfo()
            await this.getAllPrice()
        },
        // 保存信息
        async saveInfo() {
            let params = Object.assign({}, this.evectionParams)
            let userIdList = []
            this.evectionParams.travelUserList.forEach(value => {
                userIdList.push(value.id)
            })
            params.userIdList = userIdList
            params.ticketRequests = this.trainList
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params))
            if (err) {
                return false
            }
            return res
        },
        // 获取订单所有价格
        async getAllPrice() {
            let params = {
                evectionNo: this.evectionNo,
                headCounts: this.evectionParams.travelUserList.length
            }
            let res = await consumer_journey_calculatePrice(params)
            this.priceResult = res.datas
        },
        // 获取当前出差单信息和可选择的出行人列表
        getTravelInfoAndUserList() {
            if (this.evectionInfo.evectionType === 1) {
                // 个人出差
                // this.getDepartmentUser()
                this.getUsuallyUser()
            } else if (this.evectionInfo.evectionType === 2 && this.workTravelType === 2) {
                this.getDepartmentUser()
            } else {
                // 出差单出差
                let params = {
                    evectionNo: this.evectionNo,
                    approveType: 1,
                }
                consumer_apply_myApplyListV2(params).then(res => {
                    let currentBusinessTravelList = res.datas.list
                    this.currentBusinessTravelList = currentBusinessTravelList
                    let staffList = res.datas.list[res.datas.list.length - 1].staffList
                    staffList.forEach(value => {
                        console.log(value)
                        value.value = value.userId
                        value.id = value.userId
                        value.text = value.staffName
                        value.label = value.staffName
                        value.name = value.staffName
                        value.certificateType = value.certificateList ? value.certificateList[0].certificateType : ''
                        value.certificateValue = value.certificateList ? value.certificateList[0].certificateValue : ''
                    })
                    // this.canSelectTravelUserList = staffList
                    this.canChooseUserList = staffList
                    console.log('canChooseUserList', this.canChooseUserList)

                    // 判断是否含有以下行程
                    let journeys = currentBusinessTravelList[res.datas.list.length - 1].journeys
                    journeys.forEach(value => {
                        if (value.businessType === 1) {
                            this.haveFlightEvection = true
                        } else if (value.businessType === 2) {
                            this.haveTrainEvection = true
                        } else {
                            this.haveHotelEvection = true
                        }
                    })
                })
            }
        },
        // 获取公司所有员工
        async getDepartmentUser() {
            // 根据层级返回对应class
            let reduceClass = (val) => {
                if (val === 1) {
                    return 'firstClass'
                } else if (val === 2) {
                    return 'secondClass'
                } else if (val === 3) {
                    return 'thirdClass'
                } else {
                    return 'fourthClass'
                }
            }
            // 给多级数组增加树形
            let reduceData = (data, level) => {
                data.map((m, i) => {
                    m.level = level
                    m.className = reduceClass(level)
                    m.id = m.deptId
                    m.text = m.deptName
                    m.childrenDept = m.deptList
                    m.children = m.staffList
                    delete m.deptList
                    delete m.staffList
                    if (m.children && m.children.length) {
                        m.children.forEach(value => {
                            value.id = value.subUserId
                            value.text = value.subStaffName
                        })
                    }
                    if (m.childrenDept && m.childrenDept.length > 0) {
                        reduceData(m.childrenDept, level + 1)
                    }
                })
            }
            // 将多维数组转成一维数组
            let jsonToArray = (nodes) => {
                let r = [];
                if (Array.isArray(nodes)) {
                    for (let i = 0, l = nodes.length; i < l; i++) {
                        r.push(nodes[i]); // 取每项数据放入一个新数组
                        if (Array.isArray(nodes[i]["childrenDept"])&&nodes[i]["childrenDept"].length>0)
                            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
                            r = r.concat(jsonToArray(nodes[i]["childrenDept"]));
                        delete nodes[i]["childrenDept"]
                    }
                }
                return r;
            }
            let res = await get_user_info()
            this.userInfo = res.datas
            let result = await consumer_department_getDepartmentUsers({ companyId: this.userInfo.companyId })
            let cascadeList = result.datas
            reduceData(cascadeList, 1)
            let newCascadeList = jsonToArray(cascadeList)
            let [err, customerResult] = await awaitWrap(consumer_web_externalCustomer_getExternalCustomerAll())
            if (err) {
                this.allUserItems = newCascadeList
                return
            }
            let customerList = customerResult.datas
            customerList.forEach(value => {
                value.id = value.userId
                value.text = value.customerName
            })
            let customerDept = {
                childrenDept: [],
                deptId: -1,
                id: -1,
                deptLevel: 1,
                className: 'firstClass',
                deptName: '外部客户',
                deptStatus: 1,
                parentId: 0,
                children: []
            }
            customerDept.children = customerList
            newCascadeList.push(customerDept)
            this.allUserItems = newCascadeList
        },
        // 选取出行人
        showSelectUserPopup() {
            let activeUserIds = []
            this.evectionParams.travelUserList.forEach(value => {
                activeUserIds.push(value.id)
            })
            this.activeUserIds = activeUserIds
            if (this.evectionInfo.evectionType === 1) {
                // 个人出差
                this.popupShow = true
            } else {
                // 出差单出差
                this.canChooseUserList
                // this.activeCanChooseUserList = activeUserIds
                this.travelUserSelectPopupShow = true
            }
        },
        cancelChooseUser() {},
        submitChooseUser() {
            let activeChooseUserList = []
            this.allUserItems.forEach(value => {
                value.children.forEach(val => {
                    if (this.activeUserIds.indexOf(val.id) > -1) {
                        activeChooseUserList.push(val)
                    }
                })
            })
            console.log(activeChooseUserList)
            this.evectionParams.travelUserList = activeChooseUserList
            this.chooseTravelUserList = activeChooseUserList
            this.travelUserIdList = JSON.parse(JSON.stringify(this.activeUserIds))
            this.saveAndReactPrice()

            // 如果有酒店订单
            if (this.evectionParams.travelUserList.length > 0) {
                this.reactHotelUser()
            }
        },
        // 设置出行人
        setUser() {
            this.userDelPopupShow = true
        },
        // 关闭设置出行人弹窗
        closeUserDelPopup() {
            this.userDelPopupShow = false
            // this.saveAndReactPrice()
        },
        // 删除出行人
        delUser(val, index) {
            this.evectionParams.travelUserList.splice(index, 1)
            let userIdIndex = this.travelUserIdList.indexOf(val.id)
            this.travelUserIdList.splice(userIdIndex, 1)

            // 算价
            this.saveAndReactPrice()
        },
        // 关闭有出差单出差的出行人选择弹窗
        closeUserSelectPopup() {
            this.travelUserSelectPopupShow = false
        },
        // 有出差单出差的出行人-确定选择
        submitUserSelectPopup() {
            this.travelUserSelectPopupShow = false

            // 提交校验
            this.$refs.checkTravelBox.run()
        },
        // 监听关闭有出差单出差的出行人选择弹窗
        listenCloseUserSelectPopup() {
            let activeChooseUserList = []
            // this.activeUserIds = JSON.parse(JSON.stringify(this.activeCanChooseUserList))
            this.canChooseUserList.forEach(value => {
                if (this.activeUserIds.indexOf(value.id) > -1) {
                    activeChooseUserList.push(value)
                }
            })
            this.evectionParams.travelUserList = activeChooseUserList
            this.saveAndReactPrice()
        },
        // 出差单情况下选择出行人
        toggleUser(index) {
            this.$refs.checkboxes[index].toggle();
        },
        getFeeAffiliation() {
            if (this.evectionParams.evectionType === 2 && this.workTravelType === 1) {
                return
            }
            this.feeAffiliationShow = true
        },
        // 展示退改规则弹窗
        showThisRuleList(fIndex, index, val) {
            this.ruleLoading = true
            this.luggageLoading = true
            this.ruleText = {};
            this.luggageParams = {}
            this.ruleShow = true;
            let params = {
                cabinCode: val.cabinCode,
                flightNo: val.flightNo
            }
            consumer_air_ticket_flightRules(params).then(res => {
                let refRule = res.datas.rsData.refRule;
                let chaRule = res.datas.rsData.chaRule;
                if (refRule && chaRule) {
                    refRule = refRule.rules;
                    chaRule = chaRule.rules;
                    refRule.map(item => (item.type = "refRule"));
                    chaRule.map(item => (item.type = "chaRule"));
                    this.ruleText = {refRule, chaRule}
                }
                // this.luggage = res.datas.rsData.luggage
            }).finally(() => {
                this.ruleLoading = false
            })
            consumer_air_ticket_flightLuggage(params).then(res => {
                if (res.datas && res.datas.rsData) {
                    let rsData = res.datas.rsData
                    this.luggageParams = {
                        flyFree: rsData.flyFree,
                        freeWithYou: rsData.freeWithYou,
                        rests: rsData.rests,
                    }
                }
            }).finally(() => {
                this.luggageLoading = false
            })
        },
        // 关闭退改规则弹窗
        closeRuleShow() {
            this.ruleShow = false
        },
        changeAcceptNoSeat(index, val) {
            this.trainList[index].acceptNoSeat = val ? 1 : 0
        },
        // 新增机票
        async toSearchFlight() {
            if (this.evectionParams.evectionType === 2 && !this.haveFlightEvection) {
                return
            }
            let continueToFlight = await this.saveInfo()
            if (!continueToFlight) {
                return
            }
            if (this.evectionInfo.evectionType === 2) {
                // 出差单预定
                this.newTravelParams.travelCurrent = ''
                this.newTravelParams.businessType = 1
                this.dialogNewFlightOrderVisible = true
                this.travelCurrent = null
            } else {
                // 个人预定
                let fromStationCode = ''
                let fromStationName = ''
                let toStationCode = ''
                let toStationName = ''
                let startDate = ''
                let endDate = ''
                this.flightList.forEach(value => {
                    fromStationCode = value.segmentList[0].depCityCode
                    fromStationName = value.segmentList[0].depCityName
                    toStationCode = value.segmentList[0].arrCityCode
                    toStationName = value.segmentList[0].arrCityName
                    startDate = value.segmentList[0].depDate
                })
                this.$router.push({
                    name: 'flight-search',
                    query: {
                        fromStationCode: fromStationCode,
                        fromStationName: fromStationName,
                        toStationCode: toStationCode,
                        toStationName: toStationName,
                        startDate: startDate,
                        endDate: endDate,
                        flightType: 1,
                        redirectType: '001',
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType
                    }
                })
            }
        },
        getCurrentEvection() {
        },
        // 确定去添加新机票
        submitSearchNewTravelToFlight() {
            this.dialogNewFlightOrderVisible = false
            this.$router.push({
                name: 'flight-search',
                query: {
                    redirectType: '001',
                    startDate: this.travelCurrent.active.departTime,
                    endDate: this.travelCurrent.active.returnTime,
                    fromStationCode: this.travelCurrent.active.departCityId,
                    fromStationName: this.travelCurrent.active.departCityName,
                    toStationCode: this.travelCurrent.active.toCityId,
                    toStationName: this.travelCurrent.active.toCityName,
                    flightType: 1,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType
                }
            })
        },
        // 打开出差单选择弹窗
        showReservePopup() {
            this.travelPopupShow = true
        },
        // 新增火车票
        async toSearchTrain() {
            if (this.evectionParams.evectionType === 2 && !this.haveTrainEvection) {
                return
            }
            let continueToTrain = await this.saveInfo()
            if (!continueToTrain) {
                return
            }
            if (this.evectionInfo.evectionType === 2) {
                // 出差单预定
                this.newTravelParams.travelCurrent = ''
                this.newTravelParams.businessType = 2
                this.onlySearch = false
                this.dialogNewTrainOrderVisible = true
                this.travelCurrent = null
            } else {
                // 个人预定
                let fromStationName = ''
                let fromStationCode = ''
                let toStationName = ''
                let toStationCode = ''
                let startDate = ''
                if (this.trainList.length > 0) {
                    this.trainList.forEach(value => {
                        fromStationName = value.fromStationName
                        fromStationCode = value.fromStationCode
                        toStationName = value.toStationName
                        toStationCode = value.toStationCode
                        startDate: value.startDate
                    })
                }
                this.$router.push({
                    name: 'train-search',
                    query: {
                        fromStationName: fromStationName,
                        fromStationCode: fromStationCode,
                        toStationCode: toStationCode,
                        toStationName: toStationName,
                        trainDate: startDate,
                        redirectType: '001',
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType,
                        checked: false

                    }
                })
            }
        },
        // 确认去添加火车票
        submitSearchNewTravelToTrain() {
            this.dialogNewTrainOrderVisible = false
            this.$router.push({
                name: 'train-search',
                query: {
                    redirectType: '001',
                    trainDate: this.travelCurrent.active.departTime,
                    fromStationCode: this.travelCurrent.active.departCityId,
                    toStationCode: this.travelCurrent.active.toCityId,
                    checked : this.onlySearch,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType
                }
            })
        },
        // 新增酒店
        async toSearchHotel() {
            if (this.evectionParams.evectionType === 2 && !this.haveHotelEvection) {
                return
            }
            let continueToHotel = await this.saveInfo()
            if (!continueToHotel) {
                return
            }
            if (this.evectionInfo.evectionType === 2) {
                // 出差单预定
                this.newTravelParams.travelCurrent = ''
                this.newTravelParams.businessType = 3
                this.dialogNewHotelOrderVisible = true
                this.travelCurrent = null
            } else {
                // 个人预定
                let cityId = ''
                let cityName = ''
                let checkInDate = ''
                let checkOutDate = ''
                let hotelName = ''
                this.hotelList.forEach(value => {
                    cityId = value.cityId
                    cityName = value.cityName
                    checkInDate = value.checkInDate
                    checkOutDate = value.checkOutDate
                    hotelName = value.hotelName
                })
                this.$router.push({
                    name: 'h5-hotel-search',
                    query: {
                        cityId: cityId,
                        cityName: cityName,
                        checkInDate: checkInDate,
                        checkOutDate: checkOutDate,
                        keyword: hotelName,
                        hotelStar: '',
                        redirectType: '001',
                        evectionNo: this.evectionNo,
                        evectionType: this.evectionParams.evectionType
                    }
                })
            }
        },
        // 日期确定
        onCalendarConfirm(val) {
            if (val === '' || val === null || val.length === 0) {
                this.newTravelParams.hotelCheckInDate = ''
                this.newTravelParams.hotelCheckOutDate = ''
            } else {
                this.newTravelParams.hotelCheckInDate = val[0]
                this.newTravelParams.hotelCheckOutDate = val[1]
            }
        },
        // 确认去添加酒店
        submitSearchNewTravelToHotel() {
            this.dialogNewHotelOrderVisible = false
            this.$router.push({
                name: 'h5-hotel-search',
                query: {
                    redirectType: '001',
                    cityId: this.travelCurrent.active.departCityId,
                    cityName: this.travelCurrent.active.departCityName,
                    checkInDate: this.newTravelParams.hotelCheckInDate,
                    checkOutDate: this.newTravelParams.hotelCheckOutDate,
                    keyword: this.newTravelParams.hotelKeyword,
                    hotelStar: '',
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionParams.evectionType
                }
            })
        },
        // 换航班
        async changeFlight(segment, segmentIndex, flight, fIndex) {
            await this.saveInfo()
            this.$router.push({
                name: 'flight-search',
                query: {
                    fromStationCode: segment.depCityCode,
                    fromStationName: segment.depCityName,
                    toStationCode: segment.arrCityCode,
                    toStationName: segment.arrCityName,
                    startDate: segment.depDate,
                    flightType: flight.flightType,
                    evectionNo: this.evectionNo,
                    redirectType: '002',
                    orderNo: flight.journeyNo,
                    evectionType: this.evectionParams.evectionType
                }
            })
        },
        // 删除航班
        delFlightOrder(segment, segmentIndex, flight, fIndex) {
            Dialog.confirm({
                title: '提示',
                message: '此操作将删除该行程, 是否继续?',
            }).then(() => {
                consumer_flight_order_deleteOrder({journeyNo: flight.journeyNo}).then(res => {
                    this.flightList.splice(fIndex, 1)
                    this.saveAndReactPrice()
                })
            }).catch(() => {

            })
        },
        // 换火车车次
        async changeTrain(val, index) {
            await this.saveInfo()
            this.$router.push({
                name: 'train-search',
                query: {
                    fromStationName: val.fromStationName,
                    fromStationCode: val.fromStationCode,
                    toStationName: val.toStationName,
                    toStationCode: val.toStationCode,
                    trainDate: val.startDate,
                    evectionNo: this.evectionNo,
                    orderNo: val.orderNo,
                    redirectType: '002',
                    evectionType: this.evectionParams.evectionType,
                    checked: false
                }
            })
        },
        // 删除火车订单
        delTrainOrder(val, index) {
            Dialog.confirm({
                title: '提示',
                message: '此操作将删除该行程, 是否继续?',
            }).then(() => {
                consumer_trains_order_delOrder({orderNo: val.orderNo}).then(res => {
                    this.trainList.splice(index, 1)
                    this.saveAndReactPrice()
                })
            }).catch(() => {

            })
        },
        // 换酒店
        async changeHotel(val, index) {
            await this.saveInfo()
            this.$router.push({
                name: 'h5-hotel-search',
                query: {
                    cityId: val.cityId,
                    cityName: val.cityName,
                    checkInDate: val.checkInDate,
                    checkOutDate: val.checkOutDate,
                    // keyword: '',
                    // hotelStar: '',
                    evectionNo: this.evectionNo,
                    redirectType: '002',
                    orderNo: val.orderNo,
                    evectionType: this.evectionParams.evectionType
                }
            })
        },
        // 删除酒店
        delHotelOrder(val, index) {
            Dialog.confirm({
                title: '提示',
                message: '此操作将删除该行程, 是否继续?',
            }).then(() => {
                consumer_tOdHotelOrderInterim_del({id: val.id}).then(res => {
                    if (res.datas) {
                        this.hotelList.splice(index, 1)
                        this.saveAndReactPrice()
                    } else {
                        Toast.fail(res.msg)
                    }
                })
            }).catch(() => {

            })
        },
        // 展开价格详情
        showPriceInfoPopup() {
            this.productPricePopupSet = true
            this.productPricePopup = true
        },
        closeProductPricePopup() {
            this.productPricePopupSet = false
        },
        //
        onSubmitHotelConfirm(val, index) {
            this.hotelForm.checkInPerson[this.hotelUserIndex] = val
            this.showHotelUserPicker = false
        },
        // 校验入住联系人
        validteCheckIn(val) {

            if (val === "" || val === null || val === undefined) {
                return false
            } else {
                return true
            }
        },
        // 清除入住联系人
        clearHotelUser(val, index) {
            this.hotelForm.checkInPerson.splice(index, 1, '')
            this.hotelUserList.forEach(value => {
                if (value.id === val) {
                    value.disabled = false
                }
            })
        },
        openHotelUserPicker(index) {
            this.hotelUserIndex = index
            this.showHotelUserPicker = true
        },
        changeHotelUser(picker, values) {
            let val = values[0]
            if (val !== '') {
                this.hotelUserList.forEach(value => {
                    if (value.id === val || this.hotelForm.checkInPerson.indexOf(value.id) > -1) {
                        value.disabled = true
                    } else {
                        value.disabled = false
                    }
                })
                this.$refs.reserveForm.clearValidate(`checkInPerson.${index}`)
            } else {
                this.hotelUserList.forEach(value => {
                    if (value.disabled && this.hotelForm.checkInPerson.indexOf(value.id) === -1) {
                        value.disabled = false
                    }
                })
            }
        },
        // 确定添加入住联系人
        commitHotelReserve() {
            let checkInPerson = []
            this.hotelForm.checkInPerson.forEach(value => {
                checkInPerson.push(value.id)
            })
            let params = {
                checkInPerson: checkInPerson,
                orderNo: this.hotelList[0].orderNo
            }
            // params.orderNo = this.hotelList[0].orderNo
            consumer_tOdHotelOrderInterim_updateCheckInPerson(params).then(res => {
                Toast.success('入住安排保存成功')
                this.hotelUserVisible = false
            }).catch(() => {

            })
        },

        showUserPopup() {
            if (this.workTravelType === 2) {
                let activeUserIds = []
                this.evectionParams.travelUserList.forEach(value => {
                    activeUserIds.push(value.id)
                })
                this.activeUserIds = activeUserIds
                this.popupShow = true
            } else {
                this.selectUserPopup = true
            }
        },

        toggleChooseUser(val) {
            let idIndex = this.travelUserIdList.indexOf(val.id)
            if (idIndex >= 0) {
                // 去掉
                this.travelUserIdList.splice(idIndex, 1)
                this.evectionParams.travelUserList.forEach((value, index) => {
                    if (value.id === val.id) {
                        this.evectionParams.travelUserList.splice(index, 1)
                    }
                })
            } else {
                // 添加
                this.travelUserIdList.push(val.id)
                this.evectionParams.travelUserList.push(val)
            }

            // 算价
            this.saveAndReactPrice()
        },

        //
        async getDepartmentUserList() {
            // 给多级数组增加树形
            let reduceData = (data, level) => {
                data.map((m, i) => {
                    m.level = level
                    //m.className = reduceClass(level)
                    m.id = m.deptId
                    m.text = m.deptName
                    m.childrenDept = m.deptList
                    m.children = m.staffList
                    delete m.deptList
                    delete m.staffList
                    if (m.children && m.children.length) {
                        m.children.forEach(value => {
                            value.id = value.subUserId
                            value.text = value.subStaffName
                        })
                    }
                    if (m.childrenDept && m.childrenDept.length > 0) {
                        reduceData(m.childrenDept, level + 1)
                    }
                })
            }
            // 将多维数组转成一维数组
            let jsonToArray = (nodes) => {
                let r = [];
                if (Array.isArray(nodes)) {
                    for (let i = 0, l = nodes.length; i < l; i++) {
                        r.push(nodes[i]); // 取每项数据放入一个新数组
                        if (Array.isArray(nodes[i]["childrenDept"])&&nodes[i]["childrenDept"].length>0)
                        // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
                            r = r.concat(jsonToArray(nodes[i]["childrenDept"]));
                        delete nodes[i]["childrenDept"]
                    }
                }
                return r;
            }

            let departmentUserList = []
            let setDeptList = (list) => {
                list.forEach(value => {
                    if (value.staffList) {
                        value.staffList.forEach(value1 => {
                            value1.id = value1.subUserId
                            value1.text = value1.subStaffName
                            value1.name = value1.subStaffName
                            value1.deptId = value.deptId
                            value1.deptName = value.deptName
                            departmentUserList.push(value1)
                        })
                    }
                    if (value.deptList) {
                        setDeptList(value.deptList)
                    }
                })
            }
            let [err, res] = await awaitWrap(consumer_department_getDepartmentUsers())
            let cascadeList = res.datas
            /*reduceData(cascadeList, 1)
            let newCascadeList = jsonToArray(cascadeList)
            console.log(newCascadeList)
            let departmentUserList = []
            newCascadeList.forEach(value => {

            })*/
            setDeptList(cascadeList)
            console.log(departmentUserList)
            let [customerErr, customerResult] = await awaitWrap(consumer_web_externalCustomer_getExternalCustomerAll())
            if (customerErr) {
                this.contactUserList = JSON.parse(JSON.stringify(departmentUserList))
            }
            let customerList = customerResult.datas
            customerList.forEach(value => {
                value.id = value.userId
                value.text = value.customerName
                value.name = value.customerName
                value.deptId = -1
                value.deptName = '外部客户'
            })
            departmentUserList = departmentUserList.concat(customerList)
            this.contactUserList = JSON.parse(JSON.stringify(departmentUserList))
            console.log(this.contactUserList)
        },

        openContactPopup() {
            return
            this.activeContactUserList = []
            this.selectContactUserPopup = true
        },
        submitContactUser() {
            console.log(this.activeContactUserList)
            let activeContactUserList = JSON.parse(JSON.stringify(this.activeContactUserList))
            this.evectionParams.linkman = activeContactUserList[0].name
            this.evectionParams.linkmanPhone = activeContactUserList[0].phone
        },
        submitAddUser() {
            let idList = []
            this.chooseTravelUserList.forEach(value => {
                idList.push(value.id)
            })
            let travelUserList = []
            let travelUserIdList = []
            this.evectionParams.travelUserList.forEach((value, index) => {
                if (idList.indexOf(value.id) > -1) {
                    travelUserList.push(value)
                    travelUserIdList.push(value.id)
                }
            })
            this.evectionParams.travelUserList = travelUserList
            this.travelUserIdList = travelUserIdList
        },

        // 获取常用旅客
        getUsuallyUser() {
            consumer_frequent_contacts_getList({currentPage: 1, pageSize: 100}).then(res => {
                let frequentPassenger = res.pageResult.pageData
                /*frequentPassenger.forEach(value => {
                    if (value.status === 0) {
                        this.frequentPassenger.push(value)
                    }
                })*/
                frequentPassenger.forEach(value => {
                    value.value = value.uid
                    value.id = value.uid
                    value.text = value.username
                    value.label = value.username
                    value.name = value.username
                })
                this.canChooseUserList = frequentPassenger
            })
        },

        __successPopup_opened_event_handler(){
            const __this = this;
            fixStatusBar({
                type: `200`,
                element: __this.$refs.successPopup.$el.querySelector(`.popup-search-header`),
            });
        },
    }
}
