const extend = require('extend');
const Q = require('q');
const _ = require('underscore');
const axios = require(`axios`);

const get_token = require(`nllib/src/model/get_token`);
const get_url_prefix = require(`yinzhilv-js-sdk/frontend/common-project/lib/common-service/get_url_prefix`);
const __redirect_to_login_page = require(`yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/redirect_to_login_page`).default;
const __alert = require(`yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/alert`).default;
// const get_enable_mock_from_app_config = require(`yinzhilv-js-sdk/frontend/common-project/lib/data-service/default/__request/get_enable_mock_from_app_config`);

const __request_contentType_json = function (pParameter) {
    const defaultParameter = {};
    defaultParameter.method = `post`;
    defaultParameter.appKey = `default`;
    defaultParameter.urlSuffix = ``;
    defaultParameter.data = {};
    defaultParameter.redirect_to_login_page = null;
    defaultParameter.alert = null;
    defaultParameter.check_token = true;
    defaultParameter.process_response_data = true;
    defaultParameter.enable_mock = false;
    defaultParameter.enable_error_alert = true;

    const parameter = extend(true, {}, defaultParameter, pParameter);

    const method = parameter.method;
    const appKey = parameter.appKey;
    const urlSuffix = parameter.urlSuffix;
    const data = parameter.data;
    const check_token = parameter.check_token;
    const process_response_data = parameter.process_response_data;
    const enable_mock = parameter.enable_mock;
    const enable_error_alert = parameter.enable_error_alert;

    let redirect_to_login_page;
    if (_.isFunction(parameter.redirect_to_login_page)) {
        redirect_to_login_page = parameter.redirect_to_login_page;
    } else {
        redirect_to_login_page = __redirect_to_login_page;
    }

    let __alert__;
    if (_.isFunction(parameter.alert)) {
        __alert__ = parameter.alert;
    } else {
        __alert__ = __alert;
    }

    if (!global.appConfig) {
        const msg = '__request_contentType_json parameter error, code=001';
        console.error(msg);
        return Q.reject({msg: msg});
    }

    const environment = global.appConfig.environment;
    if (!environment) {
        const msg = '__request_contentType_json parameter error, code=002';
        console.error(msg);
        return Q.reject({msg: msg});
    }

    let token = ``;
    if (check_token) {
        token = get_token();
    }

    const urlPrefix = get_url_prefix({
        environment: environment,
        appKey: appKey,
        enable_mock: enable_mock,
    });

    const options = {
        method: method,
        baseURL: urlPrefix,
        url: urlSuffix,
        data: data,
        headers: {},

        // proxy: {
        //   host: '127.0.0.1',
        //   port: 8888,
        // },
    };

    if (check_token) {
        options.headers[`Token`] = token;
    }

    let r = Q.when()
        .then(function () {
            return axios(options);
        })
        .then(function (r) {
            const data = r.data;
            return Q.resolve(data);
        })
    ;

    if (!enable_mock && process_response_data) {
        r = r.then(function (data) {
            if (!data) {
                const msg = '__request_contentType_json parameter error, code=100';
                console.error(msg);
                return Q.reject({msg: msg});
            }

            return data;

        });
    }

    r = r.catch(function (ex) {
        console.error(ex);
        if (ex) {
            if (ex.stack) {
                console.error(ex.stack);
            }
        }
        return Q.reject(ex);
    })
    ;

    return r;
};

module.exports = __request_contentType_json;
