import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import wangyiServerOnline from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper";
import consumer_intention_order_list from '@/lib/data-service/haolv-default/consumer_intention_order_list';

export default {
    data() {
        return {
            list: [],
            error: false,
            loading: false,
            finished: false,
            refreshing: false, // 上拉刷新状态
        }
    },
    activated() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback:(result) => {
                    this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            },1)
        });
        this.keyback();
        this.init();
    },
    destroyed() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });
        keyback_processor.removeEventListener();
    },
    methods: {
        //初始化
        async init() {
            await this.getList();
        },

        //获取列表
        async getList() {
            try {
                this.loading = true;
                this.finished = false;
                this.refreshing = true; // 上拉刷新状态
                let res = await consumer_intention_order_list();
                this.list = res.datas.ticketIntentionOrderList;
                this.loading = false;
                this.finished = true
                this.refreshing = false; // 上拉刷新状态
            } catch (e) {
                this.error = true;
                this.loading = false;
                this.finished = true
                this.refreshing = false; // 上拉刷新状态
            }
        },

        //查看详情
        toDetails(orderNo) {
            this.$router.push({
                name: "h5-advert-intention-detail",
                query: {
                    orderNo
                },
            });
        },

        //返回
        onBack(){
            this.$router.go(-1);
        },

        //物理键返回
        keyback() {
            const __this = this;

            keyback_processor.addEventListener(function () {
                __this.onBack()
            });
        }
    }
}