// consumer_user_getArea 【263】根据IP获取所在城市
// 接口文档地址：http://yapi.yinzhitravel.com/project/52/interface/api/14131
const __request = require(`./__request/__request_contentType_json`);
const consumer_user_getArea = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/user/getArea',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_user_getArea;