import Field from 'vant/es/field';
import Vue from 'vue'
require('./index.less');

const mixin = {
  methods: {
    hello() {
      console.log('overwrite van-field:hello!');
    }
  },
  created: function () {
    const __this = this;
    // __this.hello();
  },
};

const Component = Field;

//在将mixin赋给mixins后
//mixin会覆盖methods中原有的方法slot，会导致报错
//需要手动重写slots方法
//或者将slots方法保存下来
//再与mixin拼接后再赋给mixins
let TestFunction = Component.mixins[0].methods.slots;

Component.mixins = [mixin];

Component.mixins[0].methods.slots = TestFunction;

// Component.mixins[0].methods.push('1');

console.log(Component.mixins[0].methods);

Component.install = function () {
  Vue.component(`van-field`, Component);
};


export default Component;
