//---引用第三方---------------------------------------------------------------------------------------------------------
import moment from 'moment';
import Vue from 'vue';
import Vant from 'vant';
import {Toast, Dialog} from 'vant';
import 'element-ui/lib/theme-chalk/index.css'; //引入elementui样式
// import 'vant/lib/index.css'; //默认主题
import 'vant/lib/index.less'; //自定义主题
import FastClick from 'fastclick';
import VueClipboard from 'vue-clipboard2'
import BScroll from '@better-scroll/core';
import PullDown from '@better-scroll/pull-down';
import Pullup from '@better-scroll/pull-up';
import WebSocketServerClient from './lib/common-service/websocket-server-client/1.0.0';
import loading001 from '@/component/loading/init.js'
import '@vant/touch-emulator';


// 启用rem第一步
// import 'lib-flexible';

//---引用本项目---------------------------------------------------------------------------------------------------------
import App from './App.vue';
import aRouter from './router';
import init_router from './router/init_router';
import store from './lib/store';
import get_token from './lib/common-service/default/get_token';
import set_token from './lib/common-service/default/set_token';
import keyback_processor from './lib/common-service/apicloud/keyback_processor';
import browser_open_url from './lib/common-service/browser_open_url';

import './common-style/common/index.less'; //全局样式入口

import vue_plugin_global_method from './lib/vue-plugin/global-method'
import vue_router_helper from './lib/vue-plugin/vue-router-helper'
// import OverwriteElButton from './component/overwrite-vant/el-button';
import OverwriteVantCalendar from '@/component/overwrite-vant/van-calendar';
import OverwriteVantField from '@/component/overwrite-vant/van-field';
import OverwriteVantPopup from '@/component/overwrite-vant/van-popup';

//---Vue.use()----------------------------------------------------------------------------------------------------------
Toast.setDefaultOptions('loading', { forbidClick: true, duration: 1000 * 60 });

Vue.config.productionTip = false;

Vue.prototype.$moment = moment;
Vue.prototype.$Toast = Toast;
Vue.prototype.$Dialog = Dialog;
Vue.prototype.$browser_open_url = browser_open_url;

Vue.use(Vant);
// Vue.use(Vant, {size: 'small'});
Vue.use(vue_plugin_global_method);
// Vue.use(vue_router_helper, {debug: true});
// Vue.use(vue_router_helper, {debug: false});
// Vue.use(OverwriteElButton);
Vue.use(OverwriteVantCalendar);
Vue.use(OverwriteVantField);
Vue.use(OverwriteVantPopup);
Vue.use(VueClipboard);
Vue.use(loading001);

BScroll.use(PullDown);
BScroll.use(Pullup);

//---init---------------------------------------------------------------------------------------------------------------
//加快webpack热更新（潘磊找的方法）
//第二步：调用init_router方法
init_router();

// keyback_processor.init();

WebSocketServerClient.init();

FastClick.attach(document.body);
//----------------------------------------------------------------------------------------------------------------------
const aApp = new Vue({
    router: aRouter,
    store,
    render: h => h(App)
}).$mount('#app');
// global.aApp = aApp;
// global.aRouter = aRouter;
// global.get_token = get_token;
// global.set_token = set_token;
