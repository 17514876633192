/*
*出差申请审批  http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-approve-apply-controller/aduitEvectionUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/apply/aduitEvection',
        data: data
    }
    return __request(pParameter)
}
export default request