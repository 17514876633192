import Moment from 'moment';

import NavBar from "@/component/nav-bar/import_latest_version_module";

import ChooseTraveler from '@/component/choose-traveler/1.0.5/index.vue'
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import {Toast} from "vant";
import {awaitWrap} from "../../../flight/common/unit";
import currentLocation from '@/lib/common-service/current_location_helper/index.js' //在app中获取用户当前位置
import getEvectionAllType
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.1.1' //获取出差类型
//获取出差类型
import getJourneyOptionsCanBeModified
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
// 差标弹窗组件
import HotelPolicyPopup from '@/component/hotel-policy-popup/1.0.0/index.vue';

// 城市选择弹出组件
import HotelCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/hotel-city-selector/2.1.0/index.vue";
//选择出差单弹窗组件
import ReservePopupBox from "@/page/travel-book/flight/component/reservePopupBox/import_latest_version_module";
//选择入住和离店日期组件
import HotelStayDatePicker
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/hotel-stay-date-picker/1.0.2/index.vue';
// 增加或修改出差单
import req_change_evection_datas from "@/lib/data-service/haolv-default/consumer_evection_addOrUpdate";
import consumer_t_od_hotel_order_interim_hotelCityForbid from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelCityForbid";//酒店预定-禁止预定城市
//查询退改政策
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
//获取差标
import consumer_web_policyConfig_myTravelPolicy
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_myTravelPolicy'
import consumer_web_policyConfig_myLongShortTravelPolicy
    from '@/lib/data-service/haolv-default/consumer_web_policyConfig_myLongShortTravelPolicy'

import consumer_user_historyCity from '@/lib/data-service/haolv-default/consumer_user_historyCity' //用户最后一次选择城市
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import HotelKeywordSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/hotel-keyword-selector/1.0.4/index.vue'//酒店关键字选择组件
import consumer_journey_getEvectionDetailV2 from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetailV2';
import requestPermission from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/requestPermission";

import slider_left_back from "@/lib/common-service/slider_left_back/index";// 滑屏返回
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import {hideEvectionTypeChangeTabCompanyIdList} from '@/lib/ohter/index.js'
import consumer_apply_applyHotelJourney from "@/lib/data-service/haolv-default/consumer_apply_applyHotelJourney";
// 滑屏返回

export default {
    data() {
        return {
            showTabBar: false,
            form: {
                isPOIName: false,//使用定位地址
                cityCn: '北京',//城市名，主要用于酒店列表页的展示
                cityName: '北京',//城市名或者具体地址
                cityId: '70082',
                checkInDate: '',
                checkOutDate: '',
                keyword: '',
                evectionStartDate: '',
                evectionEndDate: '',
            },
            city: '',
            nightDay: 0,
            isFirst: true,//是否第一次加载

            orderId: '',
            orderValue: null,
            applyJourneyId: 0,
            showOrder: false,
            allowChangeJourneyTime: 1,
            unlimitedJourney: 0,//是否不限行程 0：否 1：是
            evectionRequired: 0, // 出差单是否必填
            evectionDetailType: '001',//'001':因公出差带普通出差单, '002': 因公出差不带出差单, '003': 因公出差带不限出差单 '101':因私出差
            bookUpperBound: 6,
            ifLongShortTrip: 0,//是否长-短期出差标准 0：否 1：是
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            isFreeApprove: 0, // 是否免审：1.是，0.否

            // 选择出行人
            showTravelPeople: false,
            popupShow: false, // 出行人弹窗
            activeUserIds: [], // 选中的员工id
            otherUserIdList: [], // 选中的合住人id
            allUserItems: [],// 全部员工列表

            shareRoomEvection: null, // 合住人所在的出差单

            tabs: [
                {
                    id: 2,
                    name: '因公',
                },
                {
                    id: 1,
                    name: '因私',
                },
            ],
            tabActiveId: 2,
            useTravel: 1,

            showCalendar: false,
            userInfo: {},
            evectionRulePopupShow: false,
            longShortTripRulePopupShow: false,
            myTravelPolicy: null,

            isShowDateRangeCal: false,
            isShowDateRangeCalFor003: false,
            dateRange: '',
            dateRangeFor002: [],
            dateRangeFor003: [],

            cityCanEdit: true,
            timeCanEdit: true,

            minDateFor002: new Date(),
            maxDateFor002: new Date(parseInt(+new Date() + 3600 * 1000 * 24 * 1000 * 365)),

            minDateFor003: new Date(),
            maxDateFor003: new Date(),

            pt: '',//关键字坐标
            filterType: 0,//是否需要带坐标进行搜索

            networkErr: false,//网络异常
            evectionDetail: null, // 出差信息

            today: '',//今天-'YYY-MM-DD'
            yesterday: '',//昨天-'YYY-MM-DD'
            isMorning: false,//当前时间是否为凌晨00:00-06:00
            isInDateRange: false,//今天是否在可选入离日期范围内。有限制行程：行程时间；无限制行程：出差单时间
            isTodayMorning: false,//入住日期是否选择今天凌晨入住

            locationLoading: false,


        };
    },
    methods: {
        //判断是否凌晨时段进入页面
        getIsMorning() {
            let curTimeList = this.$moment().toArray();
            this.isMorning = curTimeList[3] < 6;
            if (this.isMorning) {
                //凌晨时进入需要做的事情
                this.initDateForMorning();
            }
        },

        //凌晨进入页面时，获取today和yesterday字符串
        initDateForMorning() {
            this.today = this.$moment().format('YYYY-MM-DD');
            this.yesterday = this.$moment().add(-1,'days').format('YYYY-MM-DD');
        },

        //如果是凌晨时段进入页面，判断当前选择的入住日期是否为昨天，作为提示语的判断
        getIsTodayMorning() {
            if (this.isMorning) {
                let checkInDateStr = this.$moment(this.form.checkInDate).format('YYYY-MM-DD');
                this.isTodayMorning = checkInDateStr === this.yesterday;
            }
        },

        //初始化信息-关于关键字、定位等
        initInfo() {
            let {
                filterType,
                keyword,
                pt
            } = this.$route.query;
            this.filterType = filterType ? filterType : 0;
            this.form.keyword = keyword ? keyword : '';
            this.pt = pt ? pt : '';
        },

        //清除地址栏信息
        clearUrlParams() {
            let href = location.href.split('?')[0];
            history.replaceState(null, null, href);
        },

        //清除关键字、定位信息
        clearKeywordParams() {
            this.filterType = 0;
            this.form.keyword = '';
            this.pt = '';
        },

        //获取出差类型，如果evectionDetailType==='001'则初始化日期组件
        getEvectionType() {
            let evectionParams = {
                vm: this,
                situation: '002'
            }
            getEvectionAllType(evectionParams).then((evectionQuery) => {
                this.evectionDetailType = evectionQuery.evectionDetailType;
                if (this.evectionDetailType === '001') {
                    this.initHotelStayDatePicker();
                }
            })
        },

        //查询是否可修改城市和时间
        canChangeFactor() {
            return getJourneyOptionsCanBeModified({
                situation: '101',
                vm: this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit
                this.timeCanEdit = res.timeCanEdit
            }).catch(() => {
                this.networkErr = true;
                this.$Toast('网络异常，请稍后再试！')
            })
        },

        //获取用户信息
        getUserInfo() {
            get_user_info().then(res => {
                this.userInfo = res.datas
                if (hideEvectionTypeChangeTabCompanyIdList.indexOf(this.userInfo.companyId) > -1) {
                    this.showTabBar = false;
                } else  {
                    this.showTabBar = true;
                }
            })
        },

        //显示城市弹框
        onShowCitySelect() {
            if (this.checkEvectionRequired()) {
                this.$refs.aHotelCitySelector.show();
            }
        },

        //检查是否可修改城市
        checkEvectionRequired() {
            let isOk = true;
            if (!this.cityCanEdit) {
                isOk = false;
                this.$Toast('不能修改出差单既定行程')
            }
            return isOk;
        },

        //初始化日期
        initDate() {
            let {
                checkInDate,
                checkOutDate
            } = this.$route.query;
            if(checkInDate && checkOutDate) {
                this.form.checkInDate = checkInDate;
                this.form.checkOutDate = checkOutDate;
            } else {
                const moment = this.$moment();
                this.form.checkInDate = moment.format('YYYY-MM-DD');
                moment.add(1, 'd');
                this.form.checkOutDate = moment.format('YYYY-MM-DD');
            }
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);
            this.minDateFor002 = this.isMorning ? yesterday : new Date();
            this.maxDateFor002 = new Date('2100-01-01');
            this.dateRangeFor002 = [new Date(this.form.checkInDate), new Date(this.form.checkOutDate)];

            this.getIsTodayMorning();
        },

        //获取历史城市
        getHistoryCity() {
            let cityObj = {
                cityId: '70082',
                cityName: '北京'
            };
            consumer_user_historyCity()
                .then(res => {
                    let city = res.datas.hotelHistoryInfo ? res.datas.hotelHistoryInfo : cityObj;
                    this.city = city.cityId;
                    this.form.cityId = city.cityId;
                    this.form.cityName = city.cityName;
                    this.form.cityCn = city.cityCn && city.cityCn !== 'null' ? city.cityCn : city.cityName;
                    this.form.isPOIName = (city.cityCn && city.cityCn !== 'null' && city.cityCn !== city.cityName) ? true : false;
                    this.setHotelCitySelectorVal();
                }, error => {
                    this.city = '70082';
                    this.form.cityId = '70082';
                    this.form.cityName = '北京';
                    this.form.cityCn = '北京';
                    this.form.isPOIName = false;
                })
        },

        //修改选中的出差单
        onOrderClick(newVal) {
            if (newVal === null) {
                this.activeUserIds = [];
                this.dateRangeFor002 = [new Date(this.form.checkInDate), new Date(this.form.checkOutDate)];
                this.setHotelCitySelectorVal();
            } else {
                this.showOrder = false;
                this.orderId = this.orderValue.id;

                //设置出行人
                let [...canStaffList] = this.orderValue.active.staffList;
                canStaffList = canStaffList.filter(value => value.bookStatus === 1)
                let [...canStaffIdList] = canStaffList.map(value => value.userId)
                this.activeUserIds = canStaffIdList;
                if (this.activeUserIds.length === 0) {
                    Toast('该行程没有可选的出行人')
                }

                //不限行程时,城市和时间默认为空
                if ((!(this.$route.query && this.$route.query.evectionNo) || !this.isFirst) && this.unlimitedJourney === 1) {
                    this.form.cityId = '';
                    this.form.cityName = '';
                    this.form.cityCn = '';
                    this.form.isPOIName = false;
                    this.nightDay = 0;
                    this.form.checkInDate = '';
                    this.form.checkOutDate = '';
                    this.form.evectionStartDate = this.$moment(this.orderValue.startDate).format('YYYY-MM-DD');
                    this.form.evectionEndDate = this.$moment(this.orderValue.endDate).format('YYYY-MM-DD');
                    this.minDateFor003 = new Date(this.orderValue.startDate);
                    this.maxDateFor003 = new Date(this.orderValue.endDate);
                    this.dateRangeFor003 = [];
                    let oldCityId = JSON.parse(JSON.stringify(this.form.cityId));
                    let mode = oldCityId === this.orderValue.active.toCityId ? '000' : '100';
                    this.setHotelCitySelectorVal(mode);
                    this.onShowCitySelect();
                    return;
                }

                if(this.unlimitedJourney === 1 && this.$route.query && this.$route.query.evectionNo) {
                    this.form.evectionStartDate = this.$moment(this.orderValue.startDate).format('YYYY-MM-DD');
                    this.form.evectionEndDate = this.$moment(this.orderValue.endDate).format('YYYY-MM-DD');
                    this.form.checkInDate = this.$route.query.checkInDate;
                    this.form.checkOutDate = this.$route.query.checkOutDate;
                    this.minDateFor003 = new Date(this.orderValue.startDate);
                    this.maxDateFor003 = new Date(this.orderValue.endDate);
                    this.dateRangeFor003 = [];
                    this.setHotelCitySelectorVal('100');
                    return;
                }

                //设置城市和时间
                let oldCityId = JSON.parse(JSON.stringify(this.form.cityId));
                this.form.cityId = this.orderValue.active.toCityId;
                this.form.cityName = this.orderValue.active.toCityName;
                this.form.cityCn = this.orderValue.active.toCityName;
                this.form.isPOIName = false;
                if(!this.$route.query && this.$route.query.checkInDate && this.$route.query.checkOutDate) {
                    this.form.checkInDate = this.$route.query.checkInDate;
                    this.form.checkOutDate = this.$route.query.checkOutDate;
                } else {
                    this.form.checkInDate = this.$moment(this.orderValue.active.firstDepartTime).format('YYYY-MM-DD');
                    this.form.checkOutDate = this.$moment(this.orderValue.active.firstReturnTime).format('YYYY-MM-DD');
                }
                this.form.evectionStartDate = this.$moment(this.orderValue.active.firstDepartTime).format('YYYY-MM-DD');

                this.minDateFor003 = new Date(this.form.checkInDate);
                if (this.allowChangeJourneyTime === 0) {
                    this.maxDateFor003 = new Date(this.form.checkOutDate);
                    this.dateRangeFor003 = [this.minDateFor003, this.maxDateFor003];
                    this.form.evectionEndDate = this.$moment(this.orderValue.active.returnTime).format('YYYY-MM-DD');
                } else {
                    this.maxDateFor003 = new Date(this.orderValue.endDate);
                    this.dateRangeFor003 = [new Date(this.form.checkInDate), new Date(this.form.checkOutDate)];
                    this.form.evectionEndDate = this.$moment(this.orderValue.endDate).format('YYYY-MM-DD');
                }

                let mode = oldCityId === this.orderValue.active.toCityId? '000' : '100';
                this.setHotelCitySelectorVal(mode);
            }

            // 如果是搜索页返回的情况
            if (this.evectionDetail) {
                this.$nextTick(() => {
                    let {
                        journeyId,
                        checkInDate,
                        checkOutDate
                    } = this.$route.query;
                    if (journeyId && newVal !== null && journeyId === newVal.active.id) {
                        if (checkInDate && checkOutDate) {
                            this.form.checkInDate = checkInDate;
                            this.form.checkOutDate = checkOutDate;
                        }
                        const params = {
                            travelUserList: this.evectionDetail.psgList ? this.evectionDetail.psgList : []
                        }
                        this.$refs['traveler'].setActiveUserList(params)
                    }
                    this.evectionDetail = null
                })
            }

            this.getIsTodayMorning();
        },

        //获取选中的入住人id
        getSelectedStaffIdList(resObj) {
            this.activeUserIds = resObj.selectedStaffIdList;
            this.otherUserIdList = resObj.otherUserIdList;
            this.shareRoomEvection = resObj.shareRoomEvection;
        },

        //获取系统设置
        refundChangePolicyGet() {
            return refund_change_policy_get().then(res => {
                this.evectionRequired = res.datas.evectionRequired;
                this.bookUpperBound = res.datas.bookUpperBound;
                this.ifLongShortTrip = res.datas.ifLongShortTrip;
                this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
                this.businessRuleControl = res.datas.businessRuleControl;
                this.isFreeApprove = res.datas.isFreeApprove || 0;
                this.unlimitedJourney = res.datas.unlimitedJourney;
            })
        },

        //显示入住人弹框
        onShowTravelPeople() {
            if (this.orderValue === null) {
                this.popupShow = true
            } else {
                this.showTravelPeople = true;
            }
        },

        //修改出行类型
        onTabClick(row) {
            this.tabActiveId = row;
            this.useTravel = this.tabActiveId === 2 ? 1 : 2;
            this.orderValue = null;
        },

        //检查表单-是否已选城市；是否已选时间
        async verifyForm() {
            let isOk = true;
            if (!this.form.cityId || this.form.cityId === '' || !this.form.cityName || this.form.cityName === '') {
                isOk = false;
                this.$Toast('请选择城市');
            }
            if (this.form.checkInDate === '' || this.form.checkOutDate === '') {
                isOk = false;
                this.$Toast('请选择入离日期');
            }
            return isOk;
        },

        //查询
        async onSearch() {
            //检查网络
            if (this.networkErr) {
                this.$Toast('网络异常，请稍后再试！');
                return;
            }

            //检查表单
            const verifyResult = await this.verifyForm();
            if (verifyResult === false) return;

            //检查当前用户是否可查询该城市的酒店信息
            let isValidCity = await this.checkCity();
            if (!isValidCity) {
                return;
            }

            console.log('this.otherUserIdList', this.otherUserIdList);
            // 组装合住人信息
            let sharedUserList = [];
            if (this.otherUserIdList.length > 0 && this.shareRoomEvection) {
                sharedUserList.push({
                    userId: this.otherUserIdList[0],
                    evectionNo: this.shareRoomEvection.evectionNo,
                    personType: 2,
                    journeyId: this.shareRoomEvection.active.id,
                })
            }
            let query = {
                evectionType: this.tabActiveId,
                personType: 2,
                travellerList: this.activeUserIds,
                sharedList: this.otherUserIdList,
                sharedStatus: this.otherUserIdList.length > 0 ? 1 : 2,
                location: this.pt,
                filterType: this.filterType,
                sharedUserList: sharedUserList,
            };

            //因公-如必选出差单，是否选中出差单并选中出行人；如出行人全为外部客户时，检验当前登陆用户是否设置差标
            if (this.tabActiveId === 2) {
                let [err, res] = await awaitWrap(this.$refs.traveler.check())
                if (err) {
                    if (err.type === '001') {
                        Toast.fail(err.msg)
                    }
                    return
                }
                query = {
                    ...query,
                    applyId: (this.orderValue && this.orderValue.id) ? this.orderValue.id : '',
                    evectionNo: (this.orderValue && this.orderValue.evectionNo) ? this.orderValue.evectionNo : '',
                    startDate: this.form.checkInDate,
                    endDate: this.form.checkOutDate,
                };
            }
            this.$showLoading.show();
            req_change_evection_datas(query).then((res) => {
                const evectionNo = res.datas;

                const {
                    cityId,
                    cityName,
                    cityCn,
                    isPOIName,
                    checkInDate,
                    checkOutDate,
                    keyword,
                    evectionStartDate,
                    evectionEndDate
                } = this.form;

                if (this.tabActiveId === 2) {
                    this.$router.push({
                        name: "h5-hotel-search",
                        query: {
                            cityId,
                            cityName,
                            cityCn,
                            isPOIName,
                            checkInDate,
                            checkOutDate,
                            keyword,
                            evectionStartDate,
                            evectionEndDate,
                            evectionNo,
                            evectionType: this.useTravel === 1 || this.businessRuleControl === 2 ? 2 : 1, // 1是个人出差，2是出差单出差
                            evectionRequired: this.evectionRequired,
                            allowChangeJourneyTime: this.allowChangeJourneyTime,
                            workTravelType: this.orderValue ? 1 : 2, // 因公出差的类型， 1是有选出差单，2是没有
                            journeyType: (this.orderValue && this.orderValue.active) ? this.orderValue.active.journeyType : 0,
                            journeyId: (this.orderValue && this.orderValue.active) ? this.orderValue.active.id : '',
                            applyId: this.orderValue ? this.orderValue.id : '',
                            pt: this.pt,
                            filterType: this.filterType,
                            shareJourneyId: this.shareRoomEvection ? this.shareRoomEvection.active.id : '',
                            shareEvectionNo: this.shareRoomEvection ? this.shareRoomEvection.evectionNo : '',
                        },
                    });
                } else {
                    this.$router.push({
                        name: "h5-hotel-search",
                        query: {
                            cityId,
                            cityName,
                            cityCn,
                            isPOIName,
                            checkInDate,
                            checkOutDate,
                            keyword,
                            evectionType: this.tabActiveId,
                            pt: this.pt,
                            filterType: this.filterType,
                        },
                    })
                }

            }).finally(()=>{
                this.$showLoading.hide();
            });
            // ?cityId=70002&cityName=深圳&checkInDate=2021-05-30&checkOutDate=2021-05-31&keyword=&evectionNo=16221670285895250&evectionType=1
        },

        //系统设置为限制行程，并已选出差单时，初始化酒店入住和离店时间组件
        initHotelStayDatePicker() {
            this.$refs['hotelStayDatePicker'].init({
                __this: this,
                applyJourneyId: this.orderValue ? this.orderValue.active.id : 0,
                timeCanEdit: this.timeCanEdit
            })
        },

        //初始化酒店关键字选择组件
        initHotelKeywordSelector() {
            const _this = this;
            this.$refs['hotelKeywordSelector'].init({
                get_params() {
                    return {
                        cityId: _this.form.cityId ? _this.form.cityId : '',
                    };
                },
            })
        },

        //系统设置为不限行程或因私出行时，显示日期弹框
        onShowDateRangeCal() {
            if(!this.timeCanEdit) {
                this.$Toast('不能修改出差单既定行程时间')
                return
            }
            if(this.evectionDetailType === '003') {
                this.isShowDateRangeCalFor003 = true;
            } else {
                this.isShowDateRangeCal = true;
            }
        },

        //系统设置为不限行程或因私出行时,修改选中的入离时间
        onConfirmDateRange(date) {
            const [start, end] = date;
            this.form.checkInDate = this.$moment(start).format('YYYY-MM-DD');
            this.form.checkOutDate = this.$moment(end).format('YYYY-MM-DD');
            this.dateRangeFor002 = date;
            this.dateRangeFor003 = date;
            this.isShowDateRangeCal = false;
            this.isShowDateRangeCalFor003 = false;
            //不限行程单中，选完时间，城市未选时，自动弹出城市弹框
            if (this.form.cityId === '') {
                this.onShowCitySelect();
            }
            this.getIsTodayMorning();
        },

        //获取差标-无长短期出差标准
        getMyTravelPolicy() {
            consumer_web_policyConfig_myTravelPolicy().then(res => {
                this.myTravelPolicy = res.datas
            })
        },

        //获取差标-有长短期出差标准
        getMyLongShortTripTravelPolicy() {
            consumer_web_policyConfig_myLongShortTravelPolicy().then(res => {
                this.myLongShortTravelPolicy = res.datas
            })
        },

        //查看差标
        openEvectionRulePopup() {
            let userIds = this.activeUserIds;
            let userIdsWhetherShared = this.otherUserIdList;
            let cityId = this.form.cityId;
            let cityName = this.form.cityCn;
            let applyJourneyId = '';
            if (this.orderValue) {
                this.orderValue.journeys.forEach((item,index) => {
                    if (item.businessType == 3) {
                        applyJourneyId = item.id;
                    }
                });
            }
            let params = {
                userIds,
                userIdsWhetherShared,
                cityId,
                cityName,
                applyJourneyId,
            };
            this.$refs.hotelPolicyPopup.init(params);
            /*switch (this.ifLongShortTrip) {
                case 0:
                    await this.getMyTravelPolicy();
                    this.evectionRulePopupShow = true;
                    break;
                case 1:
                    await this.getMyLongShortTripTravelPolicy();
                    this.longShortTripRulePopupShow = true;
                    break;
                default:
                    break;
            }*/

        },

        //初始化定位
        async initLocation() {
            await currentLocation.init();
        },

        //获取定位
        locate() {
            if (this.checkEvectionRequired()) {
                this.$Toast({message: '定位中...'});
                this.locationLoading = true;
                currentLocation.get_current_location().then((location) => {
                    this.locationLoading = false;
                    this.city = location.cityId;
                    this.form.cityId = location.cityId;
                    this.form.cityName = location.POIName || location.address || '';
                    this.form.cityCn = location.cityName;
                    this.form.isPOIName = true;
                    this.setHotelCitySelectorVal('100');
                    if (!location.locationStr) {
                        this.form.isPOIName = false;
                        // 权限问题
                        requestPermission();
                        this.form.cityName = location.cityName;
                        this.$Toast({message: '获取当前位置失败，请稍后再试!'});
                    } else {
                        this.$Toast({message: '定位成功'});
                        setTimeout(() => {
                            this.checkCity();
                        }, 200)
                    }
                })
            }
        },

        //返回
        back() {
            if(this.$route.query.type === 'new') {
                this.$router.go(-1)
            } else {
                this.$router.replace({
                    name: 'h5-home',
                })
            }
        },

        //初始化城市组件
        initHotelCitySelector() {
            const __this = this;
            const aSelector = __this.$refs.aHotelCitySelector;
            aSelector.init({
                mock_data: {
                    enable: false,
                    wait_seconds: 0.5,
                },
                panel_select_entity: {
                    input: {
                        placeholder: `城市/区域/位置/酒店名`,
                    },
                },
                panel_filter_entity: {
                    input: {
                        placeholder: `城市/区域/位置/酒店名`,
                    },
                },
                event_handler: {
                    change_event_handler(args) {
                        __this.city = args.id;
                        __this.form.cityId = args.id;
                        __this.form.cityName = args.name;
                        __this.form.cityCn = args.entity.cityCn;
                        __this.form.isPOIName = __this.form.cityCn && __this.form.cityCn !== 'null' && __this.form.cityCn !== __this.form.cityName ? true : false;
                        if (args.type !== '5') {
                            __this.form.keyword = args.key ? args.key : '';
                        } else {
                            __this.form.keyword = '';
                        }
                        if(args.entity.filterType === 1) {
                            __this.pt = args.entity.location;
                        } else {
                            __this.pt = '';
                        }
                        __this.filterType = args.entity.filterType ? 1 : 0;

                        setTimeout(() => {
                            //检查当前城市是否可预订
                            __this.checkCity();
                            //不限行程单中，选完城市，入离时间未选时，自动弹出时间弹框
                            if(__this.form.cityId !== '' && (__this.form.checkInDate === '' || __this.form.checkOutDate === '')) {
                                __this.onShowDateRangeCal();
                            }
                        }, 200);
                    },
                },

            });
        },

        //将选中的城市传给城市组件
        setHotelCitySelectorVal(mode) {
            const __this = this;
            const aSelector = __this.$refs.aHotelCitySelector;
            aSelector.set_value({
                "id": __this.form.cityId,
                "name": __this.form.cityName,
                "entity": {
                    "cityId": __this.form.cityId,
                    "cityCn": __this.form.cityCn,
                    "cityPinyin": "",
                    "cityPinyinSimp": "",
                    "initial": "",
                    "isHot": "",
                    "cityName": null,
                    "key": null
                },
            });
            setTimeout(() => {
                switch (mode) {
                    case '100':
                        this.clearUrlParams();
                        this.clearKeywordParams();
                        break;
                    default:
                        this.initInfo();
                        break;
                }
            }, 500)
        },

        //修改关键字
        changeKeyword(val) {
            this.pt = '';
            this.filterType = val.filterType;
            if(val.filterType === 1) {
                this.pt = val.location;
            }
            //选中酒店时
            if (val.type === 1) {
                this.onSearch();
            }
        },

        //跳转至订单列表
        toOrderList() {
            this.$router.push({
                name: 'h5-hotel-order-list',
                query: {
                    jumpType: 'back'
                }
            })
        },

        //物理键返回
        keyback() {
            const __this = this;

            keyback_processor.addEventListener(function () {
                const reservePopupBox = __this.$refs.reservePopupBox
                if (reservePopupBox.getShow()) {
                    reservePopupBox.closeEvectionlPopup()
                    return
                }

                const aHotelCitySelector = __this.$refs.aHotelCitySelector;
                const show_aHotelCitySelector = aHotelCitySelector.get_show();
                if (show_aHotelCitySelector) {
                    aHotelCitySelector.hide();
                    return;
                }

                /*const evectionRulePopupShow = __this.evectionRulePopupShow;
                if (evectionRulePopupShow) {
                    __this.evectionRulePopupShow = false;
                    return;
                }

                const longShortTripRulePopupShow = __this.longShortTripRulePopupShow;
                if (longShortTripRulePopupShow) {
                    __this.longShortTripRulePopupShow = false;
                    return;
                }*/

                const isShowDateRangeCal = __this.isShowDateRangeCal;
                if (isShowDateRangeCal) {
                    __this.isShowDateRangeCal = false;
                    return;
                }

                const isShowDateRangeCalFor003 = __this.isShowDateRangeCalFor003;
                if (isShowDateRangeCalFor003) {
                    __this.isShowDateRangeCalFor003 = false;
                    return;
                }

                if(__this.evectionDetailType==='001') {
                    const hotelStayDatePicker = __this.$refs.hotelStayDatePicker
                    const hotelStayDatePickerShow = hotelStayDatePicker.get_show();
                    if (hotelStayDatePickerShow) {
                        hotelStayDatePicker.hide();
                        return;
                    }
                }

                const traveler = __this.$refs.traveler;
                const show_traveler = traveler.get_show();
                if (show_traveler) {
                    traveler.hide();
                    return;
                }

                const hotelKeywordSelector = __this.$refs.hotelKeywordSelector;
                const show_hotelKeywordSelector = hotelKeywordSelector.get_show();
                if (show_hotelKeywordSelector) {
                    hotelKeywordSelector.hide();
                    return;
                }

                const hotelPolicyPopup = __this.$refs.hotelPolicyPopup;
                if (hotelPolicyPopup.isShow()) {
                    hotelPolicyPopup.hide();
                    return;
                }

                __this.back()
            });
        },

        //检查是否可查询该城市的酒店
        async checkCity() {
            let isOk = true;
            if (this.tabActiveId === 2) {
                let res = await consumer_t_od_hotel_order_interim_hotelCityForbid({
                    cityId: this.form.cityId,
                    companyId: this.userInfo.companyId
                });
                if (res.datas.isForbid) {
                    this.$Toast({message: res.datas.forbidMsg});
                    isOk = false;
                }
            }
            return isOk;
        },

        //
        _getJourneyInfo() {
            // 获取出差信息
            let params = {
                "departTime": this.orderValue.active.departTime,
                "returnTime": this.orderValue.active.returnTime,
                "toCityId": this.form.cityId,
            };
            this.$refs.traveler.setHotelJourneyToShareRoomAndShowPopup(params);
        },
    },
    filters: {
        //格式化日期-MM月DD日
        formatDate(val) {
            const moment = Moment(val);
            return moment.format('MM月DD日');
        },

        //获取星期
        formatWeekDay(val) {
            if (!val) return '';
            const moment = Moment();
            const today = moment.format('YYYY-MM-DD');
            const tomorrow = moment.add(1, 'd').format('YYYY-MM-DD');

            const day = Moment(val).day();

            if (val === today) return '今天';
            if (val === tomorrow) return '明天';

            const week = {
                0: '周日',
                1: '周一',
                2: '周二',
                3: '周三',
                4: '周四',
                5: '周五',
                6: '周六',
            };

            return week[day];
        },
    },
    watch: {
        //监听已选出差单
        async orderValue(newVal) {
            this.getEvectionType();//获取出差类型，如果evectionDetailType==='001'则初始化日期组件
            await this.canChangeFactor();//查询是否可修改城市和时间
            if (newVal === null) {
                this.activeUserIds = []
                this.applyJourneyId = 0;
                if (this.tabActiveId === 2 && this.bookUpperBound === 1 && this.isFreeApprove === 0) {
                    this.activeUserIds = [this.userInfo.id]
                }
                this.initDate();
                this.getHistoryCity();
            } else {
                this.applyJourneyId = newVal.active.id;
            }
        },

        //监听出行类型-因私/因公
        async tabActiveId() {
            this.getEvectionType();//获取出差类型，如果evectionDetailType==='001'则初始化日期组件
            await this.canChangeFactor();//查询是否可修改城市和时间
        },
    },
    mounted() {
        this.getUserInfo();//获取用户信息
        this.initHotelCitySelector();//初始化城市组件

        //用以在移动端空出顶部
        fixStatusBar({
            type: `100`,
            selector: `.hotel-bg`,
        });
        fixStatusBar({
            type: `100`,
            selector: `.hotel-cont`,
        });
        slider_left_back(this.$refs.hotel_home, this);//滑动返回上一页
    },
    async activated () {
        this.$showLoading.show();//显示loading
        this.locationLoading = false;

        this.keyback();//监听物理键返回

        //回显出行类型-因公/因私
        let tabActiveId = this.$route.query.evectionType ? Number(this.$route.query.evectionType) : 2;
        this.onTabClick(tabActiveId);

        await this.refundChangePolicyGet();//获取系统设置
        this.initLocation();//初始化定位组件
        this.initHotelKeywordSelector();//初始化关键字组件
        this.getIsMorning();//判断是否凌晨进入页面
        this.getHistoryCity();//获取上一次查询的城市
        this.initDate();//初始化日期

        // 判断是否是搜索页返回，地址栏带参数
        if (this.$route.query.evectionNo) {
            const query = this.$route.query
            const evectionNo = query.evectionNo
            const journeyId = parseInt(query.journeyId)
            const shareJourneyId = parseInt(query.shareJourneyId || 0);
            const shareEvectionNo = query.shareEvectionNo || '';
            //获取已选的出差单详情
            consumer_journey_getEvectionDetailV2({
                evectionNo,
            }).then(res => {
                this.evectionDetail = res.datas
                if (journeyId) {
                    this.$refs['reservePopupBox'].setJourneyId({journeyId}).then((currentItem) => {
                        console.log('1', currentItem);
                        this.travelCurrent = currentItem;
                        this.isFirst = false;
                        let sharedList = [];
                        let evectionParamsTravellerList = [] // 防止返回的travellerList是[null]
                        let sharedIdList = [];
                        let evectionParamsTravellerIdList = [] // 防止返回的travellerList是[null]
                        if (res.datas.psgList) {
                            res.datas.psgList.forEach(value => {
                                if (value) {
                                    if (value.shareWith === 1) {
                                        sharedList.push(value);
                                        sharedIdList.push(value.userId)
                                    } else {
                                        evectionParamsTravellerIdList.push(value.userId)
                                        evectionParamsTravellerList.push(value);
                                    }

                                }
                            })
                        }

                        // 回显合住人
                        console.log('2', shareEvectionNo);
                        if (shareEvectionNo) {
                            consumer_apply_applyHotelJourney({
                                "departTime": this.travelCurrent.active.departTime,
                                "returnTime": this.travelCurrent.active.returnTime,
                                "toCityId": this.travelCurrent.active.toCityId,
                                "userName": sharedList[0].name,
                            }).then(res=>{
                                console.log('进入合住2');
                                let data = res.datas;
                                let shareCurrent = null;
                                let allShareUserList = [];
                                let otherUserList = [];
                                let otherUserIdList = sharedIdList;
                                console.log(data);
                                data.forEach(value=>{
                                    console.log(value)
                                    value.journeys.forEach(value1 => {
                                        console.log(value1);
                                        value.active = value1;
                                        console.log('333');
                                        if (shareEvectionNo === value.evectionNo && shareJourneyId === value.active.id) {
                                            console.log('jin1');
                                            shareCurrent = value;
                                            allShareUserList = value.active.staffList;
                                        }
                                    })
                                })
                                console.log('进入合住', allShareUserList, shareCurrent);
                                allShareUserList.forEach(value => {
                                    if (sharedIdList.indexOf(value.userId) > -1) {
                                        otherUserList.push(value)
                                    }
                                })
                                console.log('进入合住', otherUserList);
                                this.shareRoomEvection = shareCurrent;
                                // this.otherUserIdList = otherUserIdList;
                                this.$refs.traveler.setShareRoomUser({
                                    shareTravelCurrent: shareCurrent,
                                    allSelectOtherUserLit: allShareUserList,
                                    otherUserList: otherUserList,
                                    otherUserIdList: otherUserIdList,
                                });
                            }).catch(e=>{
                            })
                        }
                        this.$showLoading.hide();
                    });
                } else {
                    this.isFirst = false;
                    this.getEvectionType();//获取出差类型，如果evectionDetailType==='001'则初始化日期组件
                    this.canChangeFactor();//查询是否可修改城市和时间
                    if (this.evectionDetail && this.evectionDetail.psgList && this.evectionDetail.psgList.length > 0) {
                        let isShareRoom = false;
                        this.evectionDetail.psgList.forEach(item => {
                            if (item.shareWith === 1) {
                                isShareRoom = true;
                            }
                        })
                        if (isShareRoom) {
                            const params = {
                                travelUserList: this.evectionDetail.psgList ? this.evectionDetail.psgList : []
                            }
                            this.$refs['traveler'].setActiveUserList(params)
                        }
                    }
                    this.$showLoading.hide();//隐藏loading

                    this.evectionDetail = null;
                }
            })
        } else {
            this.isFirst = false;
            this.getEvectionType();//获取出差类型，如果evectionDetailType==='001'则初始化日期组件
            this.canChangeFactor();//查询是否可修改城市和时间
            this.$showLoading.hide();//隐藏loading
        }
    },
    deactivated() {
        //去除监听物理键返回方法
        keyback_processor.removeEventListener();
    },
    components: {
        NavBar,
        HotelKeywordSelector,
        HotelCitySelector,
        ReservePopupBox,
        ChooseTraveler,
        HotelStayDatePicker,
        HotelPolicyPopup
    },
};
