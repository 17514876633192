// 取消订单-酒店 http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-hotel-order-interim-controller/cancelOrderUsingPOST_2

const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_hotel_order_interim_cancelOrder = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/cancelOrder',
        data: pParameter,
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_order_interim_cancelOrder;