//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrgPicker from "./../OrgPicker.vue";
import { addMulti } from "@/lib/data-service/haolv-default/design";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Toast} from 'vant';

export default {
  components: {NavBar, OrgPicker},
  name: "AddMultiModal",
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
        comments: "",
        multiAddUserInfo: null,
        imageList: [],
        fileList: [],
      },
      rules: {
        multiAddUserInfo: [
          { required: true, message: "请选择加签用户", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
    // 用户名展示
    multiAddUserInfoUserName() {
        let nameList = [];
        if (!this.formValue.multiAddUserInfo) {
            return '';
        }
        this.formValue.multiAddUserInfo.forEach(value=>{
            nameList.push(value.name);
        })
      return nameList.join(',') || "";
    },
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate().then(()=>{
          const { imageList, fileList, ...restParams } = this.formValue;
          let phoneList = [];
          restParams.multiAddUserInfo.forEach(value=>{
              phoneList.push(value.phone)
          })
          const params = {
              ...this.processInfo,
              ...restParams,
              attachments: [...imageList, ...fileList],
              phone: phoneList.join(',')
          };
          this.loading = true;
          addMulti(params)
              .then(() => {
                  this.loading = false;
                  Toast.success("操作成功");
                  this.handleCancel();
                  this.$emit("success");
              })
              .finally(() => {
                  this.loading = false;
              });
      }).catch(()=>{});
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetValidation();
      this.formValue = {
        comments: "",
        multiAddUserInfo: null,
        imageList: [],
        fileList: [],
      };
      this.show = false;
        this.$emit("cancel");
    },
    // 显示用户选择器
    handleShowOrgPicker() {
      this.$refs.orgPicker.show();
    },
    // 选择用户确认操作
    handleSelectedOrg(selected) {
      if (!selected.length) {
        this.formValue.multiAddUserInfo = null;
        return;
      }
        for (let i = 0; i < selected.length; i++) {
            if (selected[i].id === this.processInfo.currentUserInfo?.id) {
                Toast.fail('不允许加签本人');
                break;
            }
        }
      /*const [item] = selected;
      if (item.id === this.processInfo.currentUserInfo?.id) {
        this.$message.error("不允许加签本人");
        return;
      }*/
      this.formValue.multiAddUserInfo = selected;
    },
  },
};
