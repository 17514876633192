// consumer_user_updatePassword 企业注册(注册步骤1)
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-beta.haolvtrip.com/doc.html#/haolv-consumer/t-us-user-controller/updatePasswordUsingPOST
const consumer_user_updatePassword = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/user/updatePassword',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_user_updatePassword;