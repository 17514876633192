import import_latest_version_module from '@/component/base-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/haolv/feature/domestic-ticket-airport-selector',
            name: 'haolv-feature-domestic-ticket-airport-selector',
            component: () => import(/* webpackChunkName: "page-demo-yinzhilv-js-sdk-haolv-feature-domestic-ticket-airport-selector" */ `./index.vue`),
            meta: {
                data_for_base_layout: {
                    header: {
                        show: true,
                        title: `国内机票机场选择组件`,
                    },
                    tab_bar: {
                        show: false,
                    }
                },
            },
        },
    ],
};

