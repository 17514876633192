const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://192.168.0.21:8765/doc.html#/haolv-biz/%E7%94%A8%E6%88%B7%E6%93%8D%E4%BD%9C%E6%90%9C%E7%B4%A2%E8%AE%B0%E5%BD%95/addOrUpdateUsingPOST_3
const consumer_search_history_addOrUpdate = (keyword) => {
  const p = {
    method: 'post',
    urlSuffix: '/consumer/search/history/addOrUpdate',
    data: {
      searchHistory: {
        businessType: 2,
        keyword,
      },
    },
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p));
  }).then(data => {
    return data;
  })
};
module.exports = consumer_search_history_addOrUpdate;