/**
 * 调用方法：
 * 初始化方法： init
 * 入参：OBJ
 * {
 *     evectionNo: String 出差单号
 *     mock_data: Obj {
 *         enable: Boolean 默认false true： 使用虚拟数据 false: 不适用虚拟数据
 *         test_case: String 001: 因公有出差单 002：因公无出差单 003: 因私
 *     }
 * }
 *
 * 打开窗口: toShow
 * 入参： Obj
 * {
 *     activePassengerList:  Array(String) 当前选中的用户id数组
 * }
 *
 * 获取当前窗口打开的状态: get_show
 *
 * 关闭最上一层窗口: hide
 *
 *
 * 组件回调事件
 * @result
 * 返参：Obj
 * {
 *     userList: Array(Object) 选取的实例数组
 *     userIdList: Array(String) 选中的id数组
 * }
 * */
import NavBar from '@/component/nav-bar/import_latest_version_module'
import TravelerEdit from '@/component/train-traveler-selector/1.0.0/components/traveler-edit/index.vue'
import EvectionTravelerList from '@/component/train-traveler-selector/1.0.0/components/evection-traveler-list/index.vue'
import TravelerMultipleSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-multiple-selector/1.2.7/index.vue'
import AccountProcessStep from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process/1.0.0/index.vue'
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail'
import consumer_trains_account_myAccount from '@/lib/data-service/haolv-default/consumer_trains_account_myAccount'
import consumer_trains_passenger_passengerList from '@/lib/data-service/haolv-default/consumer_trains_passenger_passengerList'
import consumer_frequent_getLoginUserFrequents from '@/lib/data-service/haolv-default/consumer_frequent_getLoginUserFrequents'
import consumer_trains_passenger_addPassengerBusiness from '@/lib/data-service/haolv-default/consumer_trains_passenger_addPassengerBusiness'
import mock_data_helper from './lib/mock_data_helper'
import extend from "extend";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import {Toast} from "vant";

export default {
    data() {
        return {
            show: false,
            searchKey: '', // 搜索关键词
            result: [],

            evectionType: '001', // 001: 因公有出差单 002：因公无出差单 003： 因私
            params: {},
            accountInfo: {}, // 当前登录人信息

            //selectMode: `single`, // multiple, single
            selectedIdList: [],

            canChooseTravelerList: [], // 因公有出差单情况的可选人列表
            oriPassengerList: [], // 12306乘客原始列表
            passengerList: [], // 12306乘客列表--列表轮询出来的

            isLoading: false,
        }
    },
    components: {NavBar, TravelerEdit, EvectionTravelerList, TravelerMultipleSelector, AccountProcessStep},
    created() {
    },
    mounted() {

    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        title() {
            let text = '选择乘车人';
            let passengerNum  = this.result.length;
            if (passengerNum > 0) {
                text = `已选择${passengerNum}个乘车人`
            }
            return text
        },
    },
    filters: {},
    methods: {
        _handleCheckBox(item) {
            if (item.type ==='001' || item.type ==='003') {
                Toast('当前出行人不能选择');
            }
        },
        _checkDisabled(item) {
            let disabled = false;
            let type ='000'; // 000: 正常, 001: 接口返回不通过, 002: 不是公司的人, 003: 因公时不是出差的人
            // 接口返回不行就不行
            if (!item.optional) {
                disabled = true;
                type = '001'
            } else if (!item.userId) {
                disabled = true;
                type = '002'
            } else {
                console.log(this.evectionType);
                // 否则判断情景
                if (this.evectionType === '001' || this.evectionType === '002') {
                    let canChooseUserIdList = [];
                    this.canChooseTravelerList.forEach(value => {
                        canChooseUserIdList.push(value.userId);
                    });
                    if (canChooseUserIdList.indexOf(item.userId) >= 0) {
                        disabled = false;
                        type = '000'
                    } else {
                        disabled = true;
                        type = '003'
                    }
                } else {
                    disabled = false;
                    type = '000'
                }
            }
            return {disabled, type}
        },
        _selectBack() {
            this.show = false;
        },
        _toggle(index, item) {
            console.log(index, item);
            if (item.disabled) {
                this._handleCheckBox(item);
                return
            }
            this.$refs.checkboxes[index].toggle();
        },
        _edit(item) {
            const params = {
                pageType: '001',
                userInfo: item,
                mock_data: this.params.mock_data,
            };
            this.$refs['travelerEdit'].init(params);
        },
        _toAdd() {
            if (this.evectionType === '001') {
                // this.$refs['evectionTravelerList'].showPopup();
                // 先获取12306乘车人Id列表
                let all12306UserIdList = this.passengerList.map(value => value.userId).filter(value => value);
                console.log(all12306UserIdList);
                let canChooseTravelerIdList = [];
                this.canChooseTravelerList.forEach(value => {
                    if (all12306UserIdList.indexOf(value.userId) === -1) {
                        canChooseTravelerIdList.push(value.userId)
                    }
                });

                this.selectedIdList = canChooseTravelerIdList;
                this.$refs['aTravelerMultipleSelector'].show();
            } else if (this.evectionType === '002') {
                // this.selectedIdList = [];
                let canChooseTravelerIdList = [];
                this.canChooseTravelerList.forEach(value => {
                    // 现在没有的人，才需要勾选上
                    let nowHasItem = false;
                    this.passengerList.forEach(value1 => {
                        if (value1.userId === value.userId) {
                            nowHasItem = true
                        }
                    });
                    if (!nowHasItem) {
                        canChooseTravelerIdList.push(value.userId)
                    }
                });
                this.selectedIdList = canChooseTravelerIdList;
                this.$refs['aTravelerMultipleSelector'].show();
            } else {
                this.$refs['evectionTravelerList'].showPopup();
            }
        },
        _createNew() {
            const params = {
                pageType: '002',
                userInfo: {},
                mock_data: this.params.mock_data,
            };
            this.$refs['travelerEdit'].init(params);
        },
        _search() {
            this._filterPassenger()
        },
        _clearSearch() {
            this.searchKey = ''
        },
        _filterPassenger() {
            const oriPassengerList = JSON.parse(JSON.stringify(this.oriPassengerList));
            let passengerList = [];
            if (this.searchKey === '') {
                passengerList = oriPassengerList
            } else {
                oriPassengerList.forEach(value => {
                    if (value.name.indexOf(this.searchKey) >= 0) {
                        passengerList.push(value)
                    }
                })
            }
            this.passengerList = passengerList
        },
        _submit() {
            let travelerList = [];
            this.oriPassengerList.forEach(value => {
                if (this.result.indexOf(value.userId) >= 0) {
                    travelerList.push(value)
                }
            });
            const result = {
                userList: travelerList,
                userIdList: JSON.parse(JSON.stringify(this.result))
            };
            this.$emit('result', result);
            this._selectBack()
        },
        _delAfter() {
            this._getPassengerList()
        },
        _addOrUpdateResult(pageType, val) {
            // 001: 编辑模式  002： 新建模式
            if (pageType === '002') {
                let userId = val.userId;
                if (userId && this.result.indexOf(userId) === -1) {
                    this.result.push(userId)
                }
            }
            this._clearSearch();
            this._getPassengerList()
        },
        _addPassengerList(result) {
            const list = result.list;
            this.result = this.result.concat(list);
            this._clearSearch();
            this._getPassengerList()
        },
        async _addEvectionNewPassengerList() {
            if (this.selectedIdList.length === 0) {
                return
            }
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
            });
            const params = {
                userIds: this.selectedIdList
            };
            const addPassengerBusiness = this.params.mock_data.enable ? mock_data_helper.addPassengerBusiness(params) : consumer_trains_passenger_addPassengerBusiness(params);
            let [err, res] = await awaitWrap(addPassengerBusiness);
            if (err) {
                Toast.clear();
                return
            }
            Toast.clear();
            Toast.success('添加成功');
            console.log(this.selectedIdList);
            this.selectedIdList.forEach(value => {
                console.log(value);
                if (this.result.indexOf(value) === -1) {
                    this.result.push(value)
                }
            });
            this._clearSearch();
            this._getPassengerList();
        },
        // 获取12306乘客列表
        _getPassengerList() {
            let params = {};
            if (this.evectionType !== '003' ) {
                params.evectionNo = this.params.evectionNo
            }
            const getPassengerList = this.params.mock_data.enable ? mock_data_helper.passengerList(params) : consumer_trains_passenger_passengerList(params);
            getPassengerList.then(res => {
                let oriPassengerList = res.datas;
                let obj = null;
                let haveMyself = false;
                for (let i = 0; i < oriPassengerList.length; i++) {
                    if(oriPassengerList[i].isUserSelf === 'Y'){
                        obj = oriPassengerList[i];
                        oriPassengerList.splice(i, 1);
                        haveMyself = true;
                        break
                    }
                }
                if (haveMyself) {
                    oriPassengerList.unshift(obj);
                }
                let resultList = [];
                oriPassengerList.forEach(value => {
                    const result = this._checkDisabled(value);
                    value.disabled = result.disabled;
                    value.type = result.type;
                    // 去掉不能选的新增项
                    const resultIndex = this.result.indexOf(value.userId);
                    if (resultIndex >= 0 && !value.disabled) {
                        // this.result.splice(resultIndex, 1);
                        resultList.push(this.result[resultIndex])
                    }
                });
                this.result = resultList;
                this.oriPassengerList = oriPassengerList;
                this._filterPassenger();

                // 把12306的人推出到页面
                this.$emit('getPassengerList', JSON.parse(JSON.stringify(this.oriPassengerList)))
            }).finally(()=>{
                this.isLoading = false;
            })
        },
        _openedResult() {
            const api = global.api;
            if (api) {
                api.setWinAttr({
                    softInputMode: 'pan'
                });
            }
            const _this = this;
            fixStatusBar({
                handler({default_handler, api}) {
                    const bodyWidth = document.body.clientWidth;
                    const topElement = _this.$refs['navBar'].$el;
                    let orrPaddingTop = topElement.style.paddingTop;
                    if (bodyWidth >= 414) {
                        orrPaddingTop = orrPaddingTop * 2
                    }
                    let currentPaddingTop = (orrPaddingTop || 0)/(bodyWidth/100);
                    const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
                    topElement.style.paddingTop = (addPaddingTop + currentPaddingTop) + 'vw';
                }
            })
        },
        _closeSoftInputMode() {
            const api = global.api;
            if (api) {
                api.setWinAttr({
                    softInputMode: 'auto'
                });
            }
        },
        _changeAccount() {
            this.$refs.aAccountProcessStep.changeAccount().then(() => {
                // 获取账号信息
                this._getAccountInfo();
                // 获取12306乘客列表
                
                this._getPassengerList();
            }).catch(()=>{

            })
        },
        _reactList() {
            if (this.evectionType === '003') {
                // 获取常旅客表
                const getLoginUserFrequents = this.params.mock_data.enable ? mock_data_helper.getLoginUserFrequents() : consumer_frequent_getLoginUserFrequents();
                getLoginUserFrequents.then(res => {
                    let frequents = res.datas.frequents;
                    frequents.forEach(value => {
                        value.userId = value.frequentUserId;
                        value.staffName = value.frequentPassengerName;
                        value.certificates = [{
                            certificateType: value.certificateType,
                            certificateTypeText: value.certificateTypeText,
                            certificateValue: value.certificateValue,
                        }]
                    });
                    this.canChooseTravelerList = frequents;
                    this.$refs['evectionTravelerList'].init({
                        canChooseTravelerList: this.canChooseTravelerList,
                        type: '002',
                        maxNumber: 0,
                        mock_data: this.params.mock_data
                    })
                })
            }
        },

        init(params) {
            let initParams = {
                evectionNo: '',
                mock_data: {
                    enable: false,
                    test_case: '001'
                }
            };
            if (!params) {
                params = {}
            }
            extend(initParams, params);
            extend(initParams.mock_data, params.mock_data);
            this.params = JSON.parse(JSON.stringify(initParams));
            // 获取出差单信息
            const evectionParams = {
                evectionNo: this.params.evectionNo,
                pageType: 1,
                personType: 2,
                testCase: this.params.mock_data.test_case
            };
            const getEvectionDetail = this.params.mock_data.enable ? mock_data_helper.getEvectionDetail(evectionParams) : consumer_journey_getEvectionDetail(evectionParams);
            getEvectionDetail.then(res => {
                const data = res.datas;
                // 选中当前出差单的出行人
                let userResult = [];
                if (data.travellerList) {
                    data.travellerList.forEach(value => {
                        userResult.push(value.userId)
                    })
                }
                this.result = userResult;
                if (data.evectionType === 1) {
                    this.evectionType = '003';
                    // 获取常旅客表
                    const getLoginUserFrequents = this.params.mock_data.enable ? mock_data_helper.getLoginUserFrequents() : consumer_frequent_getLoginUserFrequents();
                    getLoginUserFrequents.then(res => {
                        let frequents = res.datas.frequents;
                        frequents.forEach(value => {
                            value.userId = value.frequentUserId;
                            value.staffName = value.frequentPassengerName;
                            value.certificates = [{
                                certificateType: value.certificateType,
                                certificateTypeText: value.certificateTypeText,
                                certificateValue: value.certificateValue,
                            }]
                        });
                        this.canChooseTravelerList = frequents;
                        this.$refs['evectionTravelerList'].init({
                            canChooseTravelerList: this.canChooseTravelerList,
                            type: '002',
                            maxNumber: 0,
                            mock_data: this.params.mock_data
                        })
                    })
                } else {
                    this.canChooseTravelerList = data.travellerList;
                    let canChooseTravelerIdList = [];
                    data.travellerList.forEach(value => {
                        canChooseTravelerIdList.push(value.userId)
                    });
                    // 因公有出差单
                    if (data.journeys && data.journeys.length > 0) {
                        this.evectionType = '001';
                        /*this.$refs['evectionTravelerList'].init({
                            canChooseTravelerList: this.canChooseTravelerList,
                            type: '001',
                            maxNumber: 0,
                            mock_data: this.params.mock_data
                        })*/
                        this.$refs.aTravelerMultipleSelector.init({
                            situation: '102',
                            businessType: 2,
                            select_mode: `multiple`, // multiple, single
                            select_max_number: 6,
                            mock_data: {
                                enable: false,
                                wait_seconds: 2,
                            },
                            popup_style: {
                                style: '001', // 000: 80vh； 001： 100vh
                            },
                            user_entity_define: {
                                property_name_for_id: `userId`,
                                property_name_for_name: `name`,
                                property_name_for_phone: `phone`,
                                get_enable(pParameter) {
                                    if (!pParameter) pParameter = {};
                                    return canChooseTravelerIdList.indexOf(pParameter.entity.userId) >= 0
                                },
                                get_enable_false_msg(pParameter) {
                                    if (!pParameter) pParameter = {};
                                    return '当前出行人不能选择';
                                },
                            }
                        });
                    } else {
                        // 因公没有出差单
                        this.evectionType = '002';
                        this.$refs.aTravelerMultipleSelector.init({
                            situation: '102',
                            businessType: 2,
                            select_mode: `multiple`, // multiple, single
                            select_max_number: 6,
                            mock_data: {
                                enable: false,
                                wait_seconds: 2,
                            },
                            popup_style: {
                                style: '001', // 000: 80vh； 001： 100vh
                            },
                            user_entity_define: {
                                property_name_for_id: `userId`,
                                property_name_for_name: `name`,
                                property_name_for_phone: `phone`,
                                get_enable(pParameter) {
                                    if (!pParameter) pParameter = {};
                                    return canChooseTravelerIdList.indexOf(pParameter.entity.userId) >= 0
                                },
                                get_enable_false_msg(pParameter) {
                                    if (!pParameter) pParameter = {};
                                    return '当前出行人不能选择';
                                },
                            }
                        });
                    }
                }
                // 获取12306乘客列表
                this._getPassengerList()
            });
            // 获取账号信息
            this._getAccountInfo();

        },
        _getAccountInfo() {
            // 获取账号信息
            const getMyAccount = this.params.mock_data.enable ? mock_data_helper.myAccount() : consumer_trains_account_myAccount();
            getMyAccount.then(res => {
                this.accountInfo = res.datas
            });
        },

        _onRefresh() {
            this.isLoading = true;
            // 获取账号信息
            this._getPassengerList();
        },


        toShow(params) {
            let initParams = {
                activePassengerList: []
            };
            if (!params) {
                params = {}
            }
            extend(initParams, params);
            extend(initParams.activePassengerList, params.activePassengerList);
            let passengerList = initParams.activePassengerList;
            let result = [];
            this.oriPassengerList.forEach(value => {
                const resultIndex = passengerList.indexOf(value.userId);
                if (resultIndex >= 0 && !value.disabled) {
                    result.push(passengerList[resultIndex])
                }
            });
            this.result = result;
            this.show = true;
            // 获取12306乘客列表
            // this._getPassengerList()
        },

        get_show() {
            const travelerMultipleSelectorShow = this.$refs.aTravelerMultipleSelector.get_show();
            const evectionTravelerListShow = this.$refs.evectionTravelerList.get_show();
            const travelerEditShow = this.$refs.travelerEdit.get_show();
            return this.show || travelerMultipleSelectorShow || evectionTravelerListShow || travelerEditShow
        },

        hide() {
            const aTravelerMultipleSelector = this.$refs.aTravelerMultipleSelector;
            const travelerMultipleSelectorShow = aTravelerMultipleSelector.get_show();
            if (travelerMultipleSelectorShow) {
                aTravelerMultipleSelector.hide();
                return
            }
            const evectionTravelerList = this.$refs.evectionTravelerList;
            const evectionTravelerListShow = evectionTravelerList.get_show();
            if (evectionTravelerListShow) {
                evectionTravelerList.hide();
                return
            }
            const travelerEdit = this.$refs.travelerEdit;
            const travelerEditShow = travelerEdit.get_show();
            if (travelerEditShow) {
                travelerEdit.hide();
                return
            }
            if (this.show) {
                this._selectBack()
            }
        },
    }
}
