import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/payment-success',
      name: 'h5-payment-success',
      component: () => import(/* webpackChunkName: "page-travel-book-payment-payment-success" */ `./1.0.1/index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '支付结果',
          need_head_nav: false,
          need_footer_bar: false,
          secondary_menu: 0,
        },
      
	data_for_all_routes_processor: {
    		order_index: '014-002',
	},

},
    }
  ],
};
