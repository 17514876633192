//consumer_evection_queryOaInfo
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：

const consumer_evection_queryOaInfo = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/evection/queryOaInfo',
        data: pParameter,
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_evection_queryOaInfo;
