// consumer_t-od-trains-order-change_changeConfirm
const __request = require(`./__request/__request_contentType_json`);
const consumer_t_od_trains_order_change_changeConfirm = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-trains-order-change/changeConfirm',
        data: pParameter,
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_trains_order_change_changeConfirm;