import Q from 'q';
import _ from 'underscore';
import {Dialog, Notify, Toast} from 'vant';
import consumer_user_updatePassword from '@/lib/data-service/haolv-default/consumer_user_updatePassword';
import consumer_user_logout
    from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_user_logout";

export default {
    data() {
        const validatePassword = (value) => {
            const regExp = /^(?=.*[A-Za-z])(?=.*[0-9]).{6,20}$/;
            return regExp.test(value);
        };
        const validateConfirmPassword = (value) => {
            return value === this.ruleForm.newPassword;
        };
        return {
            ruleForm: {
                oldPassword: '',
                newPassword: '',
                confirmPassword: ''
            },
            rules: {
                oldPassword: [
                    {required: true, message: '请输入当前密码'}
                ],
                newPassword: [
                    {required: true, message: '请输入新密码'},
                    {validator:validatePassword, message: '请输入6~20位带字母字符密码'}
                ],
                confirmPassword: [
                    {required: true, message: '请确认密码'},
                    {validator:validateConfirmPassword, message: '两次输入密码不一致'}
                ],
            },
        }
    },
    methods: {
        __event_handler_form_submit() {
            const __this = this;
            consumer_user_updatePassword(__this.ruleForm).then(res => {
                if (res.code === '000000') {
                    this.$Dialog.confirm({
                        title: "",
                        message: "修改密码成功",
                        confirmButtonColor: '#FF9C00',
                        showCancelButton: false,
                        confirmButtonText: '返回设置',
                      })
                        .then(() => {
                            // 判断是否在flutter app里
                            if (window.getFlutter == true) {
                                // 如果在，则向flutter app发送信息
                                try {
                                    window.flutter_inappwebview
                                        //可以传递你所需要的任意类型数据，数组、对象等
                                        .callHandler("backHandler", {'type': 'close'})
                                        .then((res)=>{
                                            console.log("res==flutter给html的数据", res);
                                        })
                                } catch (e) {}
                                return
                            }
                          this.$router.replace({
                              name: 'h5-setting',
                          });
                        })
                        .catch(() => {

                        });
                }
            }).catch((error) => {
                console.log(error);
            });
        },
        __event_handler_form_failed(data) {
            const __this = this;
            const errors = data.errors;
            if (!_.isArray(errors)) return;
            if (errors.length <= 0) return;
            const first_error = errors[0];
            const first_error_name = first_error.name;

            const aVanForm = __this.$refs.aVanForm;
            aVanForm.scrollToField(first_error_name, true);
        },
        logout() {
            const __this = this;
            Q.when()
                .then(function () {
                    return consumer_user_logout();
                })
                .then(function (res) {
                    __this.$router.replace({
                        name: `login`
                    });
                })
                .catch(function (ex) {
                    if (ex) {
                        if (ex.stack) {
                            console.log(ex.stack);
                        }
                    }
                    return Q.reject(ex);
                });
        }
    },
}
