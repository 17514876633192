// 广告
import consumer_sys_ad_adPosition from '@/lib/data-service/haolv-default/consumer_sys_ad_adPosition.js';
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import moment from "moment";

let adPositionList = [];
const adUit = {
    getAdList (params) {
        return new Promise(async(resolve, reject) => {
            // 获取广告图
            let [err, res] = await awaitWrap(consumer_sys_ad_adPosition()) ;
            if (err) {
                resolve(false)
                return;
            }
            adPositionList = res['datas']['adPositionList'];
            // 分组
            let now = moment();
            // 广告位位置 1-启动页全屏展示 2-首页弹窗 3-首页banner广告 4-内页广告
            let _startPageAdList = []; // 启动页广告
            let _homePopAdList = []; // 首页弹窗广告
            let _homeBannerAdList = []; // 首页banner轮播图广告
            let _contPageAdList = []; // 内页广告
            adPositionList.forEach((element, index) => {
                if (element['adStatus'] !== 1) {
                    return;
                }

                if (element['adValidityStart'] != null &&
                    now.isBefore(moment(element['adValidityStart']))) {
                    return;
                }
                if (element['adValidityEnd'] != null &&
                    now.isAfter(moment(element['adValidityEnd']))) {
                    return;
                }
                if (element['companyId'] !== '' &&
                    element['companyId'] ===
                    params['companyId']) {
                    return;
                }
                switch (element['adPosition']) {
                    case 1:
                        _startPageAdList.push(element);
                        _startPageAdList.sort(
                            (a, b) => {
                                return (a['adShowSerial'] || 1) - (b['adShowSerial'] || 1);
                            },
                        );
                        break;
                    case 2:
                        _homePopAdList.push(element);
                        _homePopAdList.sort(
                            (a, b) => {
                                return (a['adShowSerial'] || 1) - (b['adShowSerial'] || 1);
                            },
                        );
                        break;
                    case 3:
                        _homeBannerAdList.push(element);
                        _homeBannerAdList.sort(
                            (a, b) => {
                                return (a['adShowSerial'] || 1) - (b['adShowSerial'] || 1);
                            },
                        );
                        break;
                    case 4:
                        _contPageAdList.push(element);
                        _contPageAdList.sort(
                            (a, b) => {
                                return (a['adShowSerial'] || 1) - (b['adShowSerial'] || 1);
                            },
                        );
                        break;
                    default:
                }
                localStorage.setItem('startPageAdList', JSON.stringify(_startPageAdList));
                localStorage.setItem('homePopAdList', JSON.stringify(_homePopAdList));
                localStorage.setItem('homeBannerAdList', JSON.stringify(_homeBannerAdList));
                localStorage.setItem('contPageAdList', JSON.stringify(_contPageAdList));
                resolve(true)
            })
        });

    },
    // 获取启动页广告
    getStartPageAdList() {
        return new Promise(async (resolve) => {
            let listString = localStorage.getItem('startPageAdList');
            if (listString == null) {
                resolve([]);
                return;
            }
            let list = JSON.parse(listString);
            resolve(list);
        })
    },

    // 获取首页弹窗广告
    getHomeAdList() {
        return new Promise(async (resolve) => {
            let listString = localStorage.getItem('homePopAdList');
            if (listString == null) {
                resolve([]);
                return;
            }
            let list = JSON.parse(listString);
            resolve(list);
        })
    },

    // 获取首页banner轮播图广告
    getHomeBannerAdList() {
        return new Promise(async (resolve) => {
            let listString = localStorage.getItem('homeBannerAdList');
            if (listString == null) {
                resolve([]);
                return;
            }
            let list = JSON.parse(listString);
            resolve(list);
        })
    },

    // 获取内页广告
    getContPageAdList() {
        return new Promise(async (resolve) => {
            let listString = localStorage.getItem('contPageAdList');
            if (listString == null) {
                resolve([]);
                return;
            }
            let list = JSON.parse(listString);
            resolve(list);
        })
    }
}
export default adUit;