const get_tab_list = function () {
    const tab_list = [
        {title: `首页`, icon: `wap-home`, badge: undefined, to: {name: `demo-home`}},
        {title: `示例数据`, icon: `wap-nav`, badge: 3, to: {name: `demo-sample-data-list`}},
        {title: `设置`, icon: `setting`, badge: undefined, to: {name: `demo-setting`}},
    ];
    return tab_list;
};

export default get_tab_list;
