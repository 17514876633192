import consumer_trains_common_queryOverweightBecause from '@/lib/data-service/haolv-default/consumer_trains_common_queryOverweightBecause'
export default {
    name: 'CheckTravel',
    data () {
        return {
            show: false,
            checkFun: null, // 检查的方法
            submitFun: null, // 提交的方法
            cancelFun: null, // 取消选择而关闭窗口触发的方法
            // resultFun: null, // 结果返回的方法
            errorList: [], // 不允许预定的人员列表
            warningList: [], // 需要填写原因的人员列表
            successList: [], // 只做提醒的人员列表
            // reasonList: [{value: 1, label: '余票不足'}, {value: 2, label: '出发或到达时间不合适'}, {value: 3, label: '出发到达车站选择'}],
            flightResultList: [],
            hotelResultList: [],
            trainsResultList:[],
            reason: '', // 原因
            name: '',

        }
    },
    components: {},
    created () {},
    mounted () {
        this._getReasonList()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // 初始化
        init(params) {
            if (!params) params = {}
            // 判断各入参是否符合标准
            let defaultParams = {
                checkFun: null,
                reserveSubmitFun: null,
                cancelFun: null,
                // reserveResultFun: null
            }
            const parameter = Object.assign({}, defaultParams, params)
            if (!this._isFunction(parameter.checkFun)) {
                const msg = 'init parameter error, code=001';
                console.error(msg);
                return Promise.reject({msg})
            }
            if (!this._isFunction(parameter.reserveSubmitFun)) {
                const msg = 'init parameter error, code=002';
                console.error(msg);
                return Promise.reject({msg})
            }
            /*if (!this._isFunction(parameter.cancelFun)) {
                const msg = 'init parameter error, code=003';
                console.error(msg);
                return Promise.reject({msg})
            }*/
            /*if (!this._isFunction(parameter.reserveResultFun)) {
                const msg = 'init parameter error, code=003';
                console.error(msg);
                return Promise.reject({msg})
            }*/
            // 赋值
            this.checkFun = params.checkFun
            this.submitFun = params.reserveSubmitFun
            this.closeFun = params.closeFun
            // this.resultFun = params.reserveResultFun
        },
        // 执行
        run(name) {
            this.name = name
            this._check()
        },
        // 开始检测
        async _check() {
            if (!this.checkFun) return
            let res = await this.checkFun()
            if (res === null) {
                return
            }
            let violationResults = res
            let form = []
            let result = []
            let errorResult = []
            let successResult = []
            violationResults.forEach(value => {
                /*if (value.controlRules === 1) {
                    successResult.push(value)
                } else if (value.controlRules === 2) {
                    value.reason = ''
                    result.push(value)
                }else if (value.controlRules === 3) {
                    errorResult.push(value)
                }*/
                if (value.controlRules === 1 || value.controlRules === 2 || value.handleType === 3) {
                    value.reason = ''
                    result.push(value)
                }else if (value.controlRules === 3 || value.handleType === 2) {
                    errorResult.push(value)
                }
            })
            console.log(result)
            this.errorList = errorResult
            this.successList = successResult
            this.warningList = result
            if (result.length === 0 && successResult.length === 0 && errorResult.length === 0) {  // violationResults.length === 0
                // 通过
                if (!this.submitFun) return
                await this.submitFun(violationResults)
                // if (!this.resultFun) return
                // this.resultFun(result)
            } else {
                this.show = true
                this.$nextTick(() => {
                    this.$refs.checkSwipe.swipeTo(0)
                })
            }
        },
        // 判断是否是function
        _isFunction(obj){
            return Object.prototype.toString.call(obj)==='[object Function]'
        },
        // 获取各业务的原因
        _getReasonList() {
            consumer_trains_common_queryOverweightBecause().then(res => {
                this.flightResultList = res.datas.flightResult
                this.hotelResultList = res.datas.hotelResult
                this.trainsResultList = res.datas.trainsResult
            })
        },
        _next() {
            if (this.warningList.length > 0) {
                this.$refs.checkSwipe.next()
            } else {
                // 继续预定
                this.submitFun(this.successList)
                this.show = false
            }
        },
        _submit() {
            this.$refs.checkForm.validate().then(res => {
                this.warningList.forEach(value => {
                    value.reason = this.reason
                })
                let list = [...this.warningList, ...this.successList]
                // 继续预定
                this.submitFun(list)
                this.show = false
            })

        },
        // 返回
        _getBack() {
            this.show = false
            this.submitFun(null)
        },
        // 关闭
        closeSwipe() {
            this.show = false
            if (this.closeFun) {
                this.closeFun()
            }
            this.submitFun(null)
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/