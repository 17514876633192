import ChangClause from '@/page/travel-book/flight/component/changClause/1.2.0/index.vue'
import NavBar from '@/component/nav-bar/import_latest_version_module'
import ChangeReturnClause from '@/page/travel-book/flight/component/changeReturnClause/1.0.0/index.vue'
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
export default {
    data() {
        return {}
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        ruleText: {
            type: Object,
            default: () => {}
        },
        showProductRule: {
            type: Boolean,
            default: true
        },
        showType: {
            type: Number,
            default: 1, // 要滚动到的地方。1是退改规则 2是行李额
        },
        flightType: {
            type: Number,
            default: 1, // 1单程，2往返
        },
        depFlightCity: {
            type: String,
            default: '',
        },
        arrFlightCity: {
            type: String,
            default: '',
        },
        ruleShowType: {
            type: String,
            default: '000', // 000 普通单程显示,数据对应为ruleText； 001 往返显示双程,是用001时，对应数据入参为ruleTextList
        },
        ruleTextList: {
            type: Array,
            default: () => []
        },
        showLuggage: {
            type: Boolean,
            default: true,
        },
        productTag: {
            type: Number,
            default: 0,
        }
    },
    components: {ChangClause, NavBar, ChangeReturnClause},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        loading(newVal, oldVal) {
            if (!newVal) {
                this.$nextTick(() => {
                    const popupBox = this.$refs.popupBox;
                    if (this.showType === 1) {
                        popupBox.scrollTop = 0;
                    } else {
                        popupBox.scrollTop = popupBox.scrollHeight;
                    }

                })
            }
        }
    },
    computed: {
        ruleShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        },
        ruleLoading: {
            get () {
                return this.loading
            },
            set (val) {
                this.$emit('update:loading', val)
            }
        }
    },
    filters: {},
    methods: {
        closeRuleShow() {
            this.ruleShow = false
        },
        opened() {
            let _this = this
            // let test = setInterval(() => {
            //     if (this.$refs['ruleTit'] !== undefined) {
            //         fixStatusBar({
            //             type: `000`,
            //             handler({default_handler, api}) {
            //                 const bodyHeight = document.body.clientHeight
            //                 const addPaddingTop = (api.safeArea.top + 20)/(bodyHeight/100)
            //                 _this.$refs['ruleTit'].$el.style.paddingTop = `${addPaddingTop}vh`;
            //             }
            //         });
            //         clearInterval(test);
            //     }
            // },100)
        },
    }
}
