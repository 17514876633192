//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
export default {
  props: {
    source: {
      type: Object,
      default() {
        return {};
      },
    },
    parent_this: {
      type: Object,
      default() {
        return {};
      },
    }
  },
  components: {
    EvectionStandardExceed
  },
  methods: {
    stopClick() {

    }
  }
}
