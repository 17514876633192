import de from "element-ui/src/locale/lang/de";
import {Dialog } from 'vant';
export default {
    data () {
        return {
            userName: null,
            password: null,
            XianShiOff: false,
            checked: false,
            show: false,
        }
    },
    components: {

    },
    created () {},
    mounted () {

    },
    activated () {
        console.log(this.$router);
        if (this.$router.testData == 1) {
            this.checked = true;

            delete this.$router.testData
        }
    },
    deactivated() {

    },
    destroyed () {},
    watch: {

    },
    computed: {},
    filters: {

    },
    methods: {
        clickCheck(){
            if (this.checked == false) {
                this.show = true;
            }
        },
        clickPush(){
            this.show = false;
            this.$router.replace({
                name: "train-treaty",
                query:{
                    evectionNo: this.$route.query.evectionNo,
                    redirectType: this.$route.query.redirectType,
                }
            });
        },
        clickXianShi(){
            this.XianShiOff = !this.XianShiOff;
        },
        back() {
            this.$router.go(-1);
            this.show = false;
        },
    }
}
