import app_loading from "@/lib/common-service/app-loading/1.0.1";
import {Toast} from "vant";
import cache from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/cache";
import cache_set from "nllib/src/cache/set";
import set_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/set_token";
import get_user_info from "@/lib/common-service/get_user_info";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import consumer_feishuLogin from '@/lib/data-service/haolv-default/consumer_feishuLogin';
import consumer_feishu_getSignature from '@/lib/data-service/haolv-default/consumer_feishu_getSignature';

export default {
    data() {
        return {
            appID: '',
            // agentId: '',
        }
    },
    props: {},
    components: {},
    created() {
        // app_loading.hide();
    },
    mounted() {
        this.appID = this.$route.query.appID || '';
        this._login();
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        async _getAuth() {
            let url = location.href;
            let params = {
                "appId":  this.appID,
                "url": url,
            };
            let [err, res] = await awaitWrap(consumer_feishu_getSignature(params));
            if (err) {
                return;
            }
            window.h5sdk.config({
                appId: this.appID,
                timestamp: res.data.timestamp,
                nonceStr: res.data.noncestr,
                signature: res.data.signature,
                jsApiList: ['scope.userLocation'],
                //成功回调
                onSuccess: (res) => {
                    console.log(`config success: ${JSON.stringify(res)}`);
                },
                //失败回调
                onFail: (err) => {
                    console.log(`config failed: ${JSON.stringify(err)}`);
                },
            });
        },
        _login() {
            window.tt.requestAccess({
                scopeList: [],
                appID: this.appID, // 网页应用必传
                success: async (res) => {
                    let {code} = res;
                    const params = {appId: this.appID, feishuCode: code};
                    // 调用code换token接口
                    let [lErr, lRes] = await awaitWrap(consumer_feishuLogin(params));
                    if (lErr) {
                        app_loading.hide();
                        Toast(lErr.data.msg);
                        return
                    }
                    set_token({token: lRes.datas.access_token});
                    cache_set({key: 'token', value: lRes.datas.access_token});
                    cache_set({key: 'appID', value: this.appID});
                    // 关闭loading
                    app_loading.hide();
                    Toast.success('登录成功');
                    // 保存token并跳转到首页
                    get_user_info({
                        clear_cache: true,
                    })
                    const phone = lRes.datas.user_detail.phone;
                    cache.set_user_name(phone);
                    cache_set({key: 'phone', value: phone});

                    // 鉴权
                    await this._getAuth();
                    this.$router.replace({
                        name: `redirect`,
                    });
                },
                fail(res) {
                    app_loading.hide();
                },
            });
        }
    }
}
