import app_loading from "@/lib/common-service/app-loading";
import consumer_apply_addBusinessDemand from '@/lib/data-service/haolv-default/consumer_apply_addBusinessDemand'

export default {
    data() {
        return {
            linkman: '',               // 联系人
            linkmanPhone: '',          // 联系电话
            corporateName: '',         // 企业名称
            isShowErrorDialog: false,
        };
    },
    components: {},
    methods: {
        confirmDialog(val) {
            this.$router.go();
        },
        clickSubmit() {
            if (!this.linkman) {
                this.$Toast('请填写联系人姓名！');
                return
            } else if (!this.linkmanPhone) {
                this.$Toast('请填写手机号码！');
                return
            } else if (!this.corporateName) {
                this.$Toast('请填写企业名称！');
                return
            }
            this.$showLoading.show();
            consumer_apply_addBusinessDemand({
                linkman: this.linkman,
                linkmanPhone: this.linkmanPhone,
                corporateName: this.corporateName,
            }).then(res => {
                if (res.code === '000000') {
                    this.isShowErrorDialog = true;
                }
            }).catch(rej => {
                this.$Toast(`${rej.msg ?? '系统繁忙，请稍后再试'}`);
            }).finally(() => {
                this.$showLoading.hide();
            });
        },
        keyupInput(name,val) {
            eval(`this.${name} = this.${name}.match(${val}) == null ? '' : this.${name}.match(${val})[0];`);
        },
    },
    created() {
        app_loading.hide();
    },
    mounted() {
        this.linkman = '';
        this.linkmanPhone = '';
        this.corporateName = '';
    },
    activated() {

    },
    deactivated() {

    },
    watch: {

    }
}
