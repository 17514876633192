
export default {
    data () {
        return {

        }
    },
    components: {

    },
    created () {},
    mounted () {

    },
    activated () {

    },
    deactivated() {

    },
    destroyed () {},
    watch: {

    },
    computed: {},
    filters: {

    },
    methods: {
        onSubmit(){
            this.$router.testData = 1;
            this.$router.go(-1);
        },
        back() {
            this.$router.go(-1);
        },
    }
}
