//获取12306登录账号信息
import consumer_trains_account_myAccount from "@/lib/data-service/haolv-default/consumer_trains_account_myAccount";

export default {
    data() {
        return {
            trainAccount: {}
        }
    },
    activated() {
        this.getTrainAccountInfo();
    },
    methods: {
        clickAllDiv() {
            if (this.trainAccount.accountStatus == 2) {
                this.toChangeTrainAccount(this.trainAccount.accountStatus);
            }
        },
        //获取12306登录账号信息
        async getTrainAccountInfo() {
            let res = await consumer_trains_account_myAccount();
            this.trainAccount = res.datas;
        },
        //获取修改12306账号的按钮文字
        getTrainAccountBtnTxt(status) {
            let btnTxt = '';
            switch (status) {
                case 2:
                    btnTxt = '去核验';
                    break;
                case 4:
                    btnTxt = '去授权12306';
                    break;
                default:
                    btnTxt = '变更账号';
                    break;
            }
            return btnTxt
        },
        //跳转至变更12306账号的页面
        toChangeTrainAccount(status) {
            let routerName = '';
            switch (status) {
                case 2:
                    routerName = 'h5-train-account-check';
                    break;
                default:
                    routerName = 'h5-train-account-depute';
                    break;
            }
            this.$router.push({
                name: routerName
            })
        },
    }
};
