import moment from "moment";
import {Dialog} from "vant";
import {hidePolicyLabelCompanyIdList} from "@/lib/ohter/index.js";
import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
import consumer_flight_search_flightPrice from '@/lib/data-service/haolv-default/consumer_flight_search_flightPrice'
import consumer_flight_order_offers from '@/lib/common-service/consumer_flight_order_offers'

export default {
    name: 'flight-item-component',
    props: {
        index: { // index of current item
            type: Number
        },
        source: { // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default () {
                return {}
            }
        },
        backStopDetail: {
            type: Function,
        },
        submit: {
            type: Function
        },
        companyId: {
            type: [Number, String]
        }
    },
    data() {
        return {hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList}
    },
    components: {
        EvectionStandardExceed
    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
        clearInterval(this.returnSetInterval)
    },
    destroyed() {
        clearInterval(this.returnSetInterval)
    },
    watch: {},
    computed: {},
    filters: {
        filterFlightDate(val) {
            return val === '' ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterFlightDaY(val) {
            return val === '' ? '' : moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day()
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '公务舱'
            } else if (val === 3) {
                return '经济舱'
            } else {
                return '不限'
            }
        },
        filterPlaneSize(val) {
            if (val === 1) {
                return '大型机'
            } else if (val === 2) {
                return '中型机'
            } else if (val === 3) {
                return '小型机'
            } else {
                return ''
            }
        },
        filterDuration(val) {
            let duration = '';
            if (val != null) {
                duration = val.replaceAll('小时', 'h').replaceAll('分钟', 'm').replaceAll('分', 'm');
            }
            return duration;
        }
    },
    methods: {
        showMore(val, index) {

        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        showShareDetail(val) {
            Dialog.alert({
                title: `实际承运-${val.sharedAirCompanyName}${val.sharedFlightNo}`,
                message: '请您前往实际承运的航司值机柜台办理登记手续',
                confirmButtonColor: '#FF9C00'
            }).then(() => {
                // on close
            });
        },
    }
}
