import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_trains_product_getOrderInsuranceInfo
  from '@/lib/data-service/haolv-default/consumer_trains_product_getOrderInsuranceInfo' // 获取保险列表
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_trains_product_checkTrainsOrderPolicy
  from '@/lib/data-service/haolv-default/consumer_trains_product_checkTrainsOrderPolicy' // 获取超标信息
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_journey_calculatePrice from '@/lib/data-service/haolv-default/consumer_journey_calculatePrice' // 计算价格
import consumer_trains_product_getAlternativeTrainNumber
  from '@/lib/data-service/haolv-default/consumer_trains_product_getAlternativeTrainNumber' // 获取备选车次
import consumer_department_getDepartmentUsers
  from '@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers' //  请求员工数据
import consumer_web_externalCustomer_getExternalCustomerAll
  from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerAll' // 请求外部客户列表

import consumer_trains_order_createTrainOrder
  from '@/lib/data-service/haolv-default/consumer_trains_order_createTrainOrder' // 最终创建订单--最新版
import consumer_trains_account_checkAccounts
  from '@/lib/data-service/haolv-default/consumer_trains_account_checkAccounts'
import consumer_trains_order_orderScheduledCheck
  from '@/lib/data-service/haolv-default/consumer_trains_order_orderScheduledCheck' // 校验超标
import consumer_journey_addOrUpdateOverproof
  from '@/lib/data-service/haolv-default/consumer_journey_addOrUpdateOverproof' // 创建超标订单
import Transfer from '@/page/travel-book/flight/component/transfer/index.vue'
import CheckTravel from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/check-travel/1.0.0/index.vue'
import CostAttributionList from '@/page/travel-book/flight/component/costAttributionList/index.vue'
import ChangClause from '@/page/travel-book/flight/component/changClause/1.0.0/index.vue'
import ReservePopupBox from '@/page/travel-book/flight/component/reservePopupBox/1.1.5/index.vue'
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import moment from "moment";
import {Toast, Dialog, Notify} from 'vant'
import SelectUser from '@/component/select-user/index.vue'
import consumer_frequent_contacts_getList from '@/lib/data-service/haolv-default/consumer_frequent_contacts_getList' // 获取常用联系人
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import consumer_trains_order_orderBookingPolling
  from '@/lib/data-service/haolv-default/consumer_trains_order_orderBookingPolling'
import consumer_apply_myApplyListV2 from '@/lib/data-service/haolv-default/consumer_apply_myApplyListV2'
import consumer_trains_order_OrderToSnapUpV2
  from '@/lib/data-service/haolv-default/consumer_trains_order_OrderToSnapUpV2'
import TravelerListForReserve
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-list-for-reserve/1.0.5/index.vue'//选择出行人组件
import NavBar from "@/component/nav-bar/import_latest_version_module";
//12306核验弹窗一
import AccountProcessStep0101
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process-step-0101/1.0.0/index.vue'
//12306核验弹窗二
import AccountProcessStep0102
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process-step-0102/1.0.1/index.vue'
//创建订单组件
import TrainCreateOrder
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-create-order/1.1.1/index.vue'
//选择乘车人组件
import TrainTravelerSelector from '@/component/train-traveler-selector/1.0.0/index.vue'
//选择车次组件
import TrainNoList from '@/component/train-no-list/1.0.0/index.vue'
//选择联系人组件
import TravelerMultipleSelector
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-multiple-selector/1.2.6/index.vue'
//火车票时刻表
import TrainTimeTable
  from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
//抢票说明--弹框
import SnatchTicketsInstructions from '../commponent/snatch-tickets-instructions/index'

export default {
  data() {
    return {
      // 联系人
      contact: [],
      trainOrderNo: null,
      checkResData: {
        infoList: [
          {
            userName: ''
          }
        ]
      },
      dialogOff: false,
      redirectType: '', // 跳转类型
      evectionNo: '', //
      userInfo: {}, // 个人信息
      evectionInfo: {}, // 出差信息合集
      evectionParams: {
        evectionNo: '',
        evectionType: 2,
        explainDesc: '',
        feeAffiliationId: '',
        feeAffiliationName: '', // 费用归属名称
        feeAffiliationType: 1,
        linkman: '',
        linkmanPhone: '',
        reason: '',
        travelUserList: []
      },

      insuranceProId: '', // 选中的保险
      insuranceList: [], // 保险列表
      insuranceDesc: '', // 保险解释
      insuranceTrainIndex: '', // 当前选择的火车序号
      insurancePopup: false, // 保险弹窗

      trainList: [], // 火车票行程列表
      flightList: [], // 飞机票行程列表
      hotelList: [], // 酒店行程列表

      travelUserList: [], // 出行人合集
      travelUserIdList: [], // 出行人id集合
      chooseTravelUserList: [], // 备选出行人集合

      trainNoSeatCheck: [], // 是否勾选无座的CheckBox里诶啊哦
      numberOfSeats: '', // 当前选座的列车座位数量
      currentTrainBusinessSeatArr: [], // 当前选座的列表的座位列表
      currentChooseSeatArr: [], // 当前选座的列车选中的座位
      currentTrainIndex: '', // 当前选座的列车序号

      businessSeatArr: [
        [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {
          label: 'F',
          value: '1F',
          active: false
        }],
        [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {
          label: 'F',
          value: '2F',
          active: false
        }]
      ], // 特等座选位
      firstSeatArr: [
        [{label: 'A', value: '1A', active: false}, {label: 'C', value: '1C', active: false}, {
          label: 'D',
          value: '1D',
          active: false
        }, {label: 'F', value: '1F', active: false}],
        [{label: 'A', value: '2A', active: false}, {label: 'C', value: '2C', active: false}, {
          label: 'D',
          value: '2D',
          active: false
        }, {label: 'F', value: '2F', active: false}]
      ], // 一等座选位
      secondSeatArr: [
        [{label: 'A', value: '1A', active: false}, {label: 'B', value: '1B', active: false}, {
          label: 'C',
          value: '1C',
          active: false
        }, {label: 'D', value: '1D', active: false}, {label: 'F', value: '1F', active: false}],
        [{label: 'A', value: '2A', active: false}, {label: 'B', value: '2B', active: false}, {
          label: 'C',
          value: '2C',
          active: false
        }, {label: 'D', value: '2D', active: false}, {label: 'F', value: '2F', active: false}]
      ], // 二等座选位

      trainPopup: false, // 备选车次弹窗
      trainPopupIndex: '', // 当前备选车次的火车序号
      currentChooseTrainList: [], // 当前备选弹窗选中的车次
      currentTrainList: [], // 当前备选车次列表

      trainSeatPopup: false, // 备选坐席弹窗
      currentChooseSeatList: [], // 当前备选弹窗选中的坐席
      currentTrainSeatList: [], // 当前备选坐席列表
      trainSeatPopupIndex: '', // 当前备选坐席的火车序号

      priceResultOne: '',
      priceResult: {
        flightCalculates: [],
        orderAmountOfCalculationResults: [
          {
            seatPrice: ''
          }
        ],
      },

      params: {
        evection: ''
      },
      seatPopup: false,
      checked: false,

      haveTrainEvection: false, // 火车票是否允许继续下单

      popupShow: false,
      allUserItems: [], // 全部员工列表
      activeUserIds: [], // 选中的员工id

      travelUserSelectPopupShow: false, // 出差单情况下选择出行人
      activeCanChooseUserList: [], // 出差单情况下可以选择的出行人
      canChooseUserList: [], // 出差单情况下选中的出行人

      userDelPopupShow: false, // 设置出行人弹窗
      feeAffiliationShow: false, // 费用归属弹窗

      ruleText: {}, // 退改规则
      luggage: '', // 行李信息
      luggageParams: {}, // 行李对象

      ruleLoading: false,
      luggageLoading: false,

      newTravelParams: {
        travelCurrent: '',
        hotelDateArr: null,
        hotelKeyword: '',
        hotelCheckInDate: '',
        hotelCheckOutDate: '',
        businessType: 1
      },
      travelCurrent: null,

      travelPopupShow: false, // 出差单选择弹窗
      currentBusinessTravelList: [], // 当前的出差预定信息
      onlySearch: false, // 是否只搜索高铁 1=是，0=否
      showCalendar: false, //

      canSubmit: true, // 防连点

      productPricePopup: false, // 订单价格详情弹窗
      productPricePopupSet: false,
      successPopup: false, // 订单成功后弹窗
      resultMsg: '', // 消息
      isHaveOverproof: false,

      hotelRules: {},
      hotelUserIndex: '',

      selectUserPopup: false, // 选择出行人组件
      selectContactUserPopup: false, // 选择联系人组件

      contactUserList: [],
      activeContactUserList: [],

      footerSubmitPaddingBottom: 0,

      orderBookingPolling: '',

      dialogErrorVisible: false,
      dialogError2Visible: false,
      errorText: '',
      returnSecond: 3,
      returnSetInterval: '',
      workTravelType: 1, // 因公出差的类型， 1是有选出差单，2是没有
      boxTit: false,
      trainNumber: null,
      sitDown: null,
      users: {
        name: '123',
        age: '123',
        school: '123',
      },
      checkTimeBox: [],
      checkTimeBoxBox: [],
      showCheckTime: false,
      showCheckNumber: false,
      showCheckDown: false,
      resultNumber: [],
      resultDown: [],
      trainDataBox: [],
      trainNumberAll: [],
      trainNumberAllData: [],
      trainNumberAllSeat: [],
      userIdListAll: null,
      tableDataOff: false,
      BeforeDrivingBox: null,
      BeforeDrivingSwitch: false,
      HairlineTime: 30,
      tableData: [
        {
          userName: '',
          standard: '',
          standardContent: '',
        }
      ],
      isSmsType: true,//发送行程信息到出行人手机。开关
      isLinkmanSms: true,//发送行程信息到联系人手机。开关
      bookingSms: 1,//发送行程信息到出行人手机。不发送：0；发送：1
      linkmanSms: 1,//发送行程信息到联系人手机。不发送：0；发送：1
      explainShow: false,
      trainDataTop: {
        //开始日期
        startDate: null,
        //星期几
        whatDay: null,
        //车次
        trainNo: null,
        //出发站
        fromStationName: null,
        //到达站
        toStationName: null,
        //发车时间
        startTime: null,
        //到达时间
        arriveTime: null,
        //跨越时间
        spanTime: null,
        //座位类型
        seatName: null,
        //坐席单价
        seatPrice: null,
      }, //火车行程
    }
  },
  components: {
    Transfer,
    CostAttributionList,
    ChangClause,
    ReservePopupBox,
    CheckTravel,
    SelectUser,
    TravelerListForReserve,
    AccountProcessStep0101,
    AccountProcessStep0102,
    TrainCreateOrder,
    NavBar,
    TrainTravelerSelector,
    TravelerMultipleSelector,
    TrainNoList,
    TrainTimeTable,
    SnatchTicketsInstructions
  },
  created() {
  },
  mounted() {
    Toast.loading({
      message: '加载中...',
      duration: 0,
      forbidClick: true,
    });
    const _this = this;
    fixStatusBar({
      type: `000`,
      handler({default_handler, api}) {
        // document.querySelector('#navBar').style.paddingTop = `${api.safeArea.top}px`;
        _this.$refs['navBar'].$el.style.paddingTop = `${api.safeArea.top}px`;
        _this.$refs['footerSubmit'].style.paddingBottom = `${api.safeArea.bottom}px`;
        _this.footerSubmitPaddingBottom = api.safeArea.bottom
      }
    });

  },
  async activated() {
    this.$refs.showCheckTimeRef.reset();
    this.redirectType = this.$route.query.redirectType;
    this.evectionNo = this.$route.query.evectionNo;
    this.evectionType = this.$route.query.evectionType;
    this.trainOrderNo = this.$route.query.trainOrderNo;
    this.workTravelType = parseInt(this.$route.query.workTravelType); // // 因公出差的类型， 1是有选出差单，2是没有

    this.checkTimeBox = [];
    this.checkTimeBoxBox = [];
    this.checkTimeBoxBox.push(this.$moment().format('YYYY-MM-DD'));
    this.trainNumber = null;
    this.sitDown = null;

    //await this.getUserInfo(); // 获取个人资料
    // await this.getInsuranceInfo(); // 获取保险信息列表
    await this.getEvectionInfo();   // 获取出差信息
    await this.getTrainsOrderPolicy();

    await this.getTravelInfoAndUserList(); // 获取当前出差单信息和可选择的出行人列表
    // this.initCheckTravelBox() // 初始化校验弹框
    await this.getAllPrice();
    await this.getDepartmentUserList();

    if (this.evectionType === 1) {
      await this.getUsuallyUser()// 获取常用旅客
    }

    // init乘车人组件
    const trainTravelerSelectorParams = {
      evectionNo: this.evectionNo,
    };
    this.$refs['trainTravelerSelector'].init(trainTravelerSelectorParams);

    // init联系人组件
    this.$refs.aTravelerMultipleSelector.init({
      title: '选择联系人',
      situation: '101',
      businessType: 3,
      select_mode: "single"
    });

    let timeBox = setInterval(() => {
      if (this.trainDataTop) {
        // init车次组件
        const trainNoListSelectorParams = {
          fromStationCode: this.trainDataTop.fromStationCode,
          toStationCode: this.trainDataTop.toStationCode,
          trainDate: this.trainDataTop.startDate,
          evectionNo: this.evectionNo,
        };
        this.$refs['aTrainNoList'].init(trainNoListSelectorParams);

        // init时刻表
        const trainTimeTableParams = {
          fromStationCode: this.trainDataTop.fromStationCode,
          toStationCode: this.trainDataTop.toStationCode,
          trainDate: this.trainDataTop.startDate,
          trainCode: this.trainDataTop.trainNo,
        };
        this.$refs.aTrainTimeTable.init(trainTimeTableParams);
      }
      clearInterval(timeBox);
    },500);

  },
  deactivated() {
    this.priceResultOne = '';
    this.orderBookingPolling = '';
    this.BeforeDrivingBox = '';
  },
  destroyed() {
  },
  watch: {
    travelCurrent(newVal) {
      if (newVal) {
        let active = newVal.active;
        this.newTravelParams.travelCurrent = `${active.departCityName}--${active.toCityName}`
      }
    },
    //从员工中选择联系人时，
    contact(newVal) {
      let contactObj = this.$refs.aTravelerMultipleSelector.get_selected_entity();
      this.evectionParams.linkman = contactObj.name;
      this.evectionParams.linkmanPhone = contactObj.phone;
    }
  },
  computed: {},
  filters: {
    filterDate(val) {
      if (val) {
        return moment(val).format('YYYY年MM月')
      } else {
        return ''
      }
    },
    filterDay(val) {
      if (val) {
        return moment(val).format('MM月DD日')
      } else {
        return ''
      }
    },
    filterIdType(val) {
      // 证件类型 (1:身份证 2:护照 4:港澳通行证 5:台胞证 6:台湾通行证 7:回乡证 8:军官证 9:其他 10:学生证)
      if (val === 1) {
        return '身份证'
      } else if (val === 2) {
        return '护照'
      } else if (val === 3) {
        return ''
      } else if (val === 4) {
        return '港澳通行证'
      } else if (val === 5) {
        return '台胞证'
      } else if (val === 6) {
        return '台湾通行证'
      } else if (val === 7) {
        return '回乡证'
      } else if (val === 8) {
        return '军官证'
      } else if (val === 9) {
        return '其他'
      } else if (val === 10) {
        return '学生证'
      } else {
        return ''
      }
    }
  },
  methods: {
    //发送行程信息到出行人手机--开关单击事件
    clickIsSmsType(){
      this.bookingSms = this.isSmsType ? 1 : 0;
    },
    //发送行程信息到联系人手机--开关单击事件
    clickIsLinkmanSms(){
      this.linkmanSms = this.isLinkmanSms ? 1 : 0;
    },
    // 抢票说明--关闭
    hideExplain(){
      this.explainShow = false;
    },
    // 抢票说明--开启
    ticketsExplain(){
      this.explainShow = true;
    },
    // 时刻表
    clickTimetable() {
      this.$refs.aTrainTimeTable.show();
    },
    // 截止时间选择
    clickHairline(val) {
      this.HairlineTime = val;
    },
    clickBeforeDriving() {
      this.BeforeDrivingSwitch = false;
    },
    closedBeforeDriving() {
      this.$router.push('/h5/train-home');
    },
    // 校验手机
    validatePhone(rule, value, callback) {
      const regExp = /^(?:13\d|14[5-9]|15\d|166|17\d|18\d|19[0|8|9]|106)-?\d{3,8}$/;
      if (regExp.test(value) && value.toString().length === 11) {
        callback()
      } else {
        callback(new Error('请填写正确的手机号码'))
      }
    },
    openContactPopup() {
      this.$refs.aTravelerMultipleSelector.show();
    },
    //选择乘车人触发事件
    _result(val) {
      this.evectionParams.travelUserList = val.userList;
      this.getAllPrice();
    },
    //最多可以选择4个备选车次
    changeGroup(val) {
      if (val.length >= 5) {
        this.resultNumber = val.slice(0, 4);
        Toast({
          message: '最多可以选择4个备选车次',
          position: 'bottom'
        });
      }
    },
    async getTrainsOrderPolicy() {
      let res = await consumer_trains_product_checkTrainsOrderPolicy({
        orderNo: this.$route.query.trainOrderNo
      });

      if (res.datas.length == 0) {
        this.tableDataOff = false;
      } else {
        this.tableDataOff = true;

        this.tableData[0].userName = res.datas[0].userName;
        this.tableData[0].standard = res.datas[0].standard;
        this.tableData[0].standardContent = res.datas[0].standardContent;
      }
    },
    async clickDown() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });

      this.showCheckDown = !this.showCheckDown;
      let text = '';

      this.resultDown.forEach((item, index) => {
        text = text + ',' + this.trainNumberAllSeat[item].seatName;
      });

      this.sitDown = text.slice(1);

      await this.saveInfo();
      await this.getAllPrice();

      setTimeout(() => {
        Toast.clear();
      }, 1000)
    },
    async clickNumber() {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });

      this.showCheckNumber = !this.showCheckNumber;
      let text = '';

      this.resultNumber.forEach(index => {
        text = text + ',' + this.trainNumberAll[index].trainCode;
        this.trainNumberAll[index].trainSeatVoList.forEach((item) => {
          this.trainNumberAllData.push(item);
        });
      });
      this.trainNumber = text.slice(1);

      this.countTrainAll();

      this.sitDown = null;
      this.resultDown = [];

      await this.saveInfo();
      await this.getAllPrice();

      Toast.clear();
    },
    //计算最大值
    countTrainAll() {
      let yzSeat = [];
      let ywSeat = [];
      let rwSeat = [];
      let wzSeat = [];
      let edzSeat = [];
      let ydzSeat = [];

      this.trainNumberAllData.forEach((item, index) => {
        switch (item.seatCode) {
          case 'yz':
            yzSeat.push(item);
            break;
          case 'yw':
            ywSeat.push(item);
            break;
          case 'rw':
            rwSeat.push(item);
            break;
          case 'wz':
            wzSeat.push(item);
            break;
          case 'edz':
            edzSeat.push(item);
            break;
          case 'ydz':
            ydzSeat.push(item);
            break;
        }
      });

      let maxNumber = (data) => {
        let max = null;
        data.forEach((item, index) => {
          if (data.length == 1) {
            max = data[index];
          } else if (data[index + 1] != undefined) {
            max = Number(data[index].seatPrice) > Number(data[index + 1].seatPrice) ? data[index] : data[index + 1];
          }
        });
        return max
      };

      this.trainNumberAllSeat = [maxNumber(yzSeat), maxNumber(ywSeat), maxNumber(rwSeat), maxNumber(wzSeat), maxNumber(edzSeat), maxNumber(ydzSeat)].filter(n => n);
    },
    rightCancelBtn(data) {
      let dataBox = data;
      let dataText = [];
      dataBox.forEach((item, index) => {
        dataText.push(item.trainCode);
      });
      this.trainNumber = dataText.join(',');
      dataBox.forEach((item, index) => {
        item.trainSeatVoList.forEach((TItem) => {
          this.trainNumberAllData.push(TItem);
        });
      });
      this.trainDataBox = data;
      this.countTrainAll();
    },
    clickCheck(num) {
      // if (this.trainNumberAll == null || this.trainNumberAll == '') {
      //     let form = {
      //         fromStationCode: this.trainDataTop.fromStationCode,
      //         toStationCode: this.trainDataTop.toStationCode,
      //         trainCode: this.trainDataTop.trainNo,
      //         motorcycleType: this.$route.query.motorcycleType,
      //         trainDate: this.trainDataTop.startDate,
      //     };
      //
      //     consumer_trains_product_getAlternativeTrainNumber(form).then(res => {
      //         this.trainNumberAll = res.resultList;
      //     });
      // }
      if (num == 3) {
        this.showCheckTime = true
      } else if (num == 1) {
        this.$refs['aTrainNoList'].toShow();
      } else if (num == 2) {
        if (this.trainNumber == '' || this.trainNumber == null) {
          Toast({
            message: '请先选择车次',
            position: 'bottom'
          });
        } else {
          this.showCheckDown = true;
        }
      }
    },
    // 修改乘车人
    clickTravelers() {
      let userIdList = [];
      this.evectionParams.travelUserList.forEach((item, index) => {
        userIdList.push(item.userId);
      });
      if (userIdList.length == 0) {
        this.travelUserList.forEach((item, index) => {
          userIdList.push(item.userId);
        });
        //就用户ID回传
        this.$refs['trainTravelerSelector'].toShow({
          activePassengerList: userIdList
        })
      } else {
        //就用户ID回传
        this.$refs['trainTravelerSelector'].toShow({
          activePassengerList: userIdList
        })
      }
    },
    // 删除乘车人
    travelersDel(item, index) {
      this.evectionParams.travelUserList.forEach((TItem,TIndex) => {
        if (TItem.userId == item.userId) {
          this.evectionParams.travelUserList.splice(TIndex,1);
          this.getAllPrice();
        }
      })
    },
    clickConfirm(){
      if (this.checkTimeBoxBox.length > 5) {
        Toast.fail('抢票日期多选最多5个');
      } else {
        this.checkTimeBox = this.checkTimeBoxBox;
        this.showCheckTime = false;
      }
    },
    unselectConfirm(data){
      let dataBox = this.$moment(data).format('YYYY-MM-DD');

      for (var i = 0;i < this.checkTimeBoxBox.length;i++) {
        if (this.checkTimeBoxBox[i] == dataBox) {
          this.checkTimeBoxBox.splice(i,1);
        }
      }
    },
    onConfirm(date) {
      let dateBox = [];
      date.forEach((item, index) => {
        dateBox.push(this.$moment(item).format('YYYY-MM-DD'));
      });
      this.checkTimeBoxBox = dateBox;
    },
    clickTit() {
      this.boxTit = !this.boxTit;
    },
    filterText(val) {
      let text = val.slice(3, -4);
      let test = val.replace(text, '***********');

      return test
    },
    filterTime(val) {
      if (val == null) return;
      let text = val.replace(/时/, "时").replace(/分/, '分');

      return text
    },
    //12306登录操作
    clickSign() {
      this.$router.replace({
        name: "train-sign",
        query: {
          evectionNo: this.$route.query.evectionNo,
          redirectType: this.$route.query.redirectType,
        }
      });
    },
    // 启动超标检测组件
    initCheckTravelBox() {
      this.$refs.checkTravelBox.init({
        checkFun: () => {
          let flightDate = [];
          if (this.flightList.length > 0) {
            this.flightList.forEach(value => {
              let flightItem = {
                bottomPrice: '',
                cabinRank: value.cabinRank,
                depDate: value.segmentList[0].depDate,
                discount: value.segmentList[0].discount,
                price: value.segmentList[0].price,
                evectionNo: this.evectionNo,
                depTime: value.segmentList[0].depTime,
                rqData: value.segmentList[0]
              };
              flightDate.push(flightItem)
            })
          }
          let params = {
            evectionNo: this.evectionNo,
            userIds: this.activeUserIds,
            excessRequest: flightDate
          };
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 0
          });
          return consumer_trains_order_orderScheduledCheck(params).then(res => {
            Toast.clear();
            return res.datas.violationResults || [] // 因无超标人时返回的空数组现在变成null，所以需自传空数组
          }).catch(() => {
            Toast.clear();
            return null
          })
        },
        reserveSubmitFun: (warningList) => {
          let overproofApplyList = [];
          if (!warningList) {
            this.canJourney = true;
            Toast.clear();
            return
          }
          warningList.forEach(value => {
            let item = {
              businessType: value.businessType,
              controlRule: value.controlRules,
              orderNo: value.orderNo,
              reason: value.reason,
              reserveContent: value.violationOfReason,
              standard: value.standard,
              standardContent: value.seatName,
              userId: value.userId,
              userName: value.userRealName
            };
            overproofApplyList.push(item)
          });

          let params = {
            evectionNo: this.evectionNo,
            overproofApplyList
          };
          Toast.loading({
            message: '加载中...',
            forbidClick: true,
            duration: 0
          });
          consumer_journey_addOrUpdateOverproof(params).then(res => {
            Toast.clear();
            let travelUserList = [];
            this.canChooseUserList.forEach(value1 => {
              if (this.activeUserIds.indexOf(value1.id) > -1) {
                travelUserList.push(value1)
              }
            });
            this.evectionParams.travelUserList = travelUserList;
            // 清除报错提示
            // this.$refs['orderForm'].clearValidate('travelUserList')
            this.saveAndReactPrice()

          }).catch(() => {
            Toast.clear()
          })
        }
      })
    },
    back() {
      this.$router.go(-1)
    },
    seatSubmit() {
      this.trainList[this.currentTrainIndex].seatArr = JSON.parse(JSON.stringify(this.currentTrainBusinessSeatArr));
      this.trainList[this.currentTrainIndex].chooseSeatArr = JSON.parse(JSON.stringify(this.currentChooseSeatArr));
      if (this.trainList[this.currentTrainIndex].chooseSeatArr.length > 0) {
        this.trainList[this.currentTrainIndex].choose = true
      } else {
        this.trainList[this.currentTrainIndex].choose = false
      }
      this.seatPopup = false
    },
    // 弹出座位选择弹窗
    showSeatPopup(val, index) {
      this.currentTrainIndex = index;
      if (this.trainList[this.currentTrainIndex].chooseSeatArr.length > 0) {
        this.trainList[this.currentTrainIndex].choose = true
      } else {
        this.trainList[this.currentTrainIndex].choose = false
      }
      this.numberOfSeats = val.numberOfSeats;
      this.currentTrainBusinessSeatArr = JSON.parse(JSON.stringify(val.seatArr));
      this.currentChooseSeatArr = JSON.parse(JSON.stringify(val.chooseSeatArr));
      this.seatPopup = true
    },
    // 选择座位
    handleChooseSeat(index, sIndex, val) {
      if (val.active) { // 取消
        this.currentTrainBusinessSeatArr[index][sIndex].active = false;
        let seatIndex = this.currentChooseSeatArr.indexOf(val.value);
        this.currentChooseSeatArr.splice(seatIndex, 1)
        // this.currentTrainBusinessSeat = this.currentChooseSeatArr.join(',')
      } else { // 选中
        if (this.evectionParams.travelUserList.length === 0) {
          Toast('请先添加出行人')
        }
        if (this.currentChooseSeatArr.length >= this.evectionParams.travelUserList.length) {
          return
        }
        this.currentTrainBusinessSeatArr[index][sIndex].active = true;
        this.currentChooseSeatArr.push(val.value)
        // this.currentTrainBusinessSeat = this.currentChooseSeatArr.join(',')
      }
    },
    // 确认保险
    onInsuranceConfirm(val) {
      this.trainList[this.insuranceTrainIndex].insuracneFlag = val.id === '' ? 0 : 1;
      if (val.id === '') {
        this.trainList[this.insuranceTrainIndex].insuranceProId = '';
        this.trainList[this.insuranceTrainIndex].insuranceDesc = null;
        this.trainList[this.insuranceTrainIndex].insurancePrice = null; // 单价
        this.trainList[this.insuranceTrainIndex].insuranceName = null // 名字
      } else {
        this.trainList[this.insuranceTrainIndex].insuranceProId = val.id;
        this.trainList[this.insuranceTrainIndex].insuranceDesc = val.insuranceDesc;
        this.trainList[this.insuranceTrainIndex].insurancePrice = val.insurancePrice; // 单价
        this.trainList[this.insuranceTrainIndex].insuranceName = val.insuranceClass.name // 名字
      }
      this.insurancePopup = false;
      this.saveAndReactPrice()
    },
    // 取消选择保险
    onInsuranceCancel() {
      this.insurancePopup = false
    },
    // 选择保险
    onInsuranceChange() {
    },
    // 显示保险弹窗
    showInsurancePopup(val, index) {
      this.insuranceTrainIndex = index;
      let showIndex = 0;
      if (val.insuranceProId !== null && val.insuranceProId !== '') {
        for (let i = 0, l = this.insuranceList.length; i < l; i++) {
          if (val.insuranceProId === this.insuranceList[i].id) {
            showIndex = i;
            break
          }
        }
      }
      this.insurancePopup = true;
      this.$nextTick(() => {
        this.$refs.insurancePopupBox.setIndexes([showIndex])
      })
    },
    // 打开抢票车次列表
    openTrainPopup(val, index) {
      this.currentTrainList = JSON.parse(JSON.stringify(val.alternativeTrainNumberList));
      this.currentChooseTrainList = JSON.parse(JSON.stringify(val.acceptTrainCodesArr));
      this.trainPopup = true;
      this.trainPopupIndex = index
    },
    // 选择备选车次
    trainToggle(val, index) {
      if (this.currentChooseTrainList.length >= 4 && this.currentChooseTrainList.indexOf(val.trainCode) === -1) {
        Toast('最多可以选择4个备选车次');
        return
      }
      this.$refs.trainCheckboxes[index].toggle()
    },
    //
    changeTrainCode(checked) {
      if (checked.length > 4) {
        let lastCheck = checked[checked.length - 1];
        for (let i = 0, l = this.currentTrainList.length; i < l; i++) {
          if (lastCheck === this.currentTrainList[i].trainCode) {
            this.$refs.trainCheckboxes[i].toggle();
            break
          }
        }
      }
    },
    // 关闭备选车次弹窗
    closeTrainPopup() {
      this.trainPopup = false
    },
    // 确定选择备选车次
    submitTrainPopup() {
      this.trainList[this.trainPopupIndex].acceptTrainCodesArr = JSON.parse(JSON.stringify(this.currentChooseTrainList));
      this.trainList[this.trainPopupIndex].acceptTrainCodes = this.currentChooseTrainList.join(',');
      this.trainPopup = false;
      this.filterSeatList(this.trainPopupIndex)
    },
    // 关闭备选坐席弹窗
    closeTrainSeatPopup() {
      this.trainSeatPopup = false
    },
    // 确定选择备选坐席
    submitTrainSeatPopup() {
      this.trainList[this.trainSeatPopupIndex].seatList = JSON.parse(JSON.stringify(this.currentTrainSeatList));
      this.trainList[this.trainSeatPopupIndex].acceptSeatCodesArr = JSON.parse(JSON.stringify(this.currentChooseSeatList));
      this.trainSeatPopup = false;
      this.chooseSeat(this.trainSeatPopupIndex, this.trainList[this.trainSeatPopupIndex].acceptSeatCodesArr)
    },
    // 选择备选坐席
    trainSeatToggle(index) {
      this.$refs.seatCheckboxes[index].toggle()
    },
    // 打开备选坐席的弹窗
    openTrainSeatPopup(val, index) {
      this.currentTrainSeatList = JSON.parse(JSON.stringify(val.seatList));
      this.currentChooseSeatList = JSON.parse(JSON.stringify(val.acceptSeatCodesArr));
      this.trainSeatPopup = true;
      this.trainSeatPopupIndex = index
    },
    checkResult(val) {
      if (val.datas.code == '000000') {
        this.createOrder();
      } else {
        Notify({type: 'warning', message: '验证码错误'});
      }
    },
    loginResult(val) {
      if (val.accountStatus == 1) {
        this.createOrder();
      } else if (val.accountStatus == 2) {
        this.$refs.aAccountProcessStep0102.show()
      } else if (val.accountStatus == 3) {
        Notify({type: 'warning', message: val.accountStatusText});
        this.$refs.aAccountProcessStep0101.show()
      }
    },
    clickDialogTest() {
      this.$refs.aAccountProcessStep0101.show()
    },
    clickDialog() {
      this.onSubmit();
    },
    //创建订单new2022-02-14
    onSubmitTrainCreateOrder() {
      // 收集备选座席
      let sitDownBox = [];
      let acceptSeatCodesBox = [];
      if (this.sitDown) {
        sitDownBox = this.sitDown.split(',');
        this.trainNumberAllSeat.forEach((item, index) => {
          if (sitDownBox.indexOf(item.seatName) != -1) {
            acceptSeatCodesBox.push(item.seatCode)
          }
        });
      }

      // 收集车次号、出发车站、到达车站信息--车次号@出发地名@到达地名--顺序与出发时间、到达时间对应
      let acceptTrainCodesAndStations = [];
      // 收集出发时间、到达时间信息--出发时间,到达时间--顺序与车次号、出发车站、到达车站
      let acceptTrainTime = [];
      this.trainDataBox.forEach((item,index) => {
        acceptTrainCodesAndStations.push(`${item.trainCode}@${item.fromStationName}@${item.toStationName}`);
        acceptTrainTime.push(`${item.fromTime},${item.toTime}`);
      });

      // 手机乘车人信息
      let trainsOrderPassengersBox = [];
      this.evectionParams.travelUserList.forEach((item,index) => {
        trainsOrderPassengersBox.push({
          passengerSeq: index+1,
          passengerBirthday: this.$moment(item.bornDate).format('YYYY-MM-DD'),
          passengerCardNumber: item.cardId,
          passengerCardType: item.cardType,
          passengerName: item.name,
          passengerSex: item.sexText === '男' ? 1 : 2,
          passengerType: item.userType,
          userId: item.userId,
        });
      });

      // 收集最早时间
      let earliestBox = this.checkTimeBox.split(',');
      let earliestDate = null;
      earliestBox.sort((a,b) => {
        let num = 0;
        if (this.$moment(a).isBefore(this.$moment(b))) {
          num = -1
        } else {
          num = 1
        }
        return num;
      });
      earliestDate = earliestBox[0];
      let earliestTimeBox = [];
      this.trainDataBox.forEach((item,index) => {
        earliestTimeBox.push(`${earliestDate} ${item.fromTime}`)
      });
      earliestTimeBox.push(`${this.trainDataTop.startDate} ${this.trainDataTop.startTime}`);
      earliestTimeBox.sort((a,b) => {
        let num = 0;
        if (this.$moment(a).isBefore(this.$moment(b))) {
          num = -1
        } else {
          num = 1
        }
        return num;
      });


      if (this.evectionParams.travelUserList.length <= 0) {
        Toast.fail('请选择乘车人');
        return;
      }
      if (!this.evectionParams.linkman) {
        Toast.fail('请填写联系人信息');
        return;
      }
      if (!this.evectionParams.linkmanPhone) {
        Toast.fail('请填写联系人信息');
        return;
      }

      let data = {
        insuranceInfoVos: [],
        orderBookingInfoVo: {
          earliestDepartureTime: earliestTimeBox[0],
          acceptSeatCodes: acceptSeatCodesBox.join(','),
          acceptTrainCodes: this.trainNumber,
          acceptTrainCodesAndStations: acceptTrainCodesAndStations.join(';'),
          acceptTrainTime: acceptTrainTime.join(';'),
          acceptTrainDate: this.checkTimeBox,
          adultCounts: this.evectionParams.travelUserList.length,
          agentTicketPrice: 0,
          arriveDate: this.trainDataTop.arriveDate,
          arriveTime: this.trainDataTop.arriveTime,
          buyTicketsType: 4,
          buyerContactEmail: '',
          buyerContactName: this.evectionParams.linkman,
          buyerContactPhone: this.evectionParams.linkmanPhone,
          remark: '',
          childrenCounts: 0,
          fromStationCode: this.trainDataTop.fromStationCode,
          fromStationName: this.trainDataTop.fromStationName,
          headCounts: this.evectionParams.travelUserList.length,
          insuracneFlag: 0,
          insuranceAmount: 0,
          insuranceName: '',
          insurancePrice: 0,
          orderAmount: this.priceResult.sum,
          orderNo: this.$route.query.trainOrderNo,
          evectionNo: this.$route.query.evectionNo,
          serviceChargeAmount: this.priceResult.orderAmountOfCalculationResults[0].serviceChargePrice*this.priceResult.orderAmountOfCalculationResults[0].headCounts,
          serviceChargePrice: this.priceResult.orderAmountOfCalculationResults[0].serviceChargePrice,
          robTicketsAmount: 0,
          robTicketsFee: 0,
          seatAmount: this.priceResultOne.orderAmountOfCalculationResults[0].seatPrice*this.priceResult.orderAmountOfCalculationResults[0].headCounts,
          seatCode: this.priceResultOne.orderAmountOfCalculationResults[0].seatCode,
          seatName: this.priceResultOne.orderAmountOfCalculationResults[0].seatName,
          seatPrice: this.priceResultOne.orderAmountOfCalculationResults[0].seatPrice,
          startDate: this.trainDataTop.startDate,
          startTime: this.trainDataTop.startTime,
          toStationCode: this.trainDataTop.toStationCode,
          toStationName: this.trainDataTop.toStationName,
          trainCode: this.trainDataTop.trainInnerCode,
          trainNo: this.trainDataTop.trainNo,
          majorSeatPrice: this.priceResultOne.orderAmountOfCalculationResults[0].seatPrice,
          finalTime: this.HairlineTime,
        },
        bookingSms: this.bookingSms,
        linkmanSms: this.linkmanSms,
        trainsOrderPassengers: trainsOrderPassengersBox
      };


      consumer_trains_order_OrderToSnapUpV2(data).then(res => {
        const params = {
          orderNo: res.orderNo,
          evectionNo: this.evectionNo,
          flightOrderList: '',
          orderType: '2', // 当是火车票
          businessType: 2,
        };
        this.$router.push({
          name: 'h5-travel-payment',
          query: params
        })

      });

    },
    // 提交
    async onSubmit() {
      if (!this.canSubmit) {
        return
      }
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        duration: 0,
      });
      let valid = await this.checkForm();
      if (!valid) {
        Toast.clear();
        return
      }
      let saveResult = await this.saveInfo();
      if (!saveResult) {
        Toast.clear();
        return
      }

      // 校验12306
      let userIds = [];
      this.chooseTravelUserList.forEach((item, index) => {
        userIds.push(item.userId)
      });

      let [checkErr, checkRes] = await awaitWrap(consumer_trains_account_checkAccounts({userIds}));
      this.checkResData = checkRes.datas;
      if (checkRes.datas.consequence == 2) {
        Notify({type: 'warning', message: '12306核验不通过'});
        Toast.clear();
        this.dialogOff = true;
        return
      } else {
        Notify({type: 'success', message: '12306核验通过'});
        this.dialogOff = false;
      }

      this.canSubmit = false;
      let [err, result] = await awaitWrap(consumer_trains_order_createTrainOrder({
        evectionNo: this.evectionNo,
        trainOrderNo: this.trainOrderNo,
        pageType: 1,
        passenger: {
          accountId: this.checkResData.infoList[0].accountId,
          userId: this.checkResData.infoList[0].userId,
        }
      }));
      this.canSubmit = true;
      if (err) {
        Toast.clear();
        let errorCode = err.data.code;
        let orderResultList = err.data.datas.orderResultList;
        this.errorText = err.data.msg;
        this.dialogError2Visible = true;
        if (!orderResultList) {
          return
        }
        return
      }
      let orderResult = result.datas;
      let canSubmit = true;
      orderResult.orderResultList.forEach(value => {
        if (!value.isSuccess) {
          canSubmit = false
        }
      });
      // Toast.clear()
      if (canSubmit) {
        // 如果有火车票
        let _this = this;
        this.orderBookingPolling = function (trainOrderList, orderResult) {
          consumer_trains_order_orderBookingPolling({orderNos: trainOrderList}).then(result => {
            let resultSuccessfulList = [];
            result.orderBookingPollingResults.forEach(value => {
              resultSuccessfulList.push(value.successful)
            });
            if (resultSuccessfulList.indexOf(0) >= 0) {
              // 继续轮询
              setTimeout(() => {
                if (this.orderBookingPolling === '') {
                  return
                }
                this.orderBookingPolling(trainOrderList, orderResult)
              }, 500)
            } else {
              // 成功
              Toast.clear();
              this.orderResult(orderResult)
            }
          }, error => {
            // 失败
            Toast.clear();
            let errorCode = error.data.code;
            let orderResultList = error.data.datas.orderBookingPollingResults;
            this.errorText = error.data.msg;
            Dialog.alert({
              title: '温馨提示',
              message: this.errorText,
            }).then(() => {
              // on close
            });
          }).catch(() => {
            Toast.clear()
          })
        };

        if (this.trainList.length > 0) {
          let trainOrderList = [];
          this.trainList.forEach(value => {
            trainOrderList.push(value.orderNo)
          });
          this.orderBookingPolling(trainOrderList, result)
        } else {
          Toast.clear();
          this.orderResult(result)
        }


        /*if (this.evectionInfo.evectionType === 2) {
            this.resultMsg = result.msg
            this.isHaveOverproof = orderResult.isHaveOverproof
            this.successPopup = true
        } else {
            this.$router.push({
                name: 'h5-travel-payment',
                query: {
                    evectionNo: this.evectionNo
                }
            })
        }*/

        if (this.trainList.length > 0) {
          let trainOrderList = [];
          this.trainList.forEach(value => {
            trainOrderList.push(value.orderNo)
          });
          this.orderBookingPolling(trainOrderList, result)
        } else {
          Toast.clear();
          this.orderResult(result)
        }

      } else {
        Toast.fail('订票失败，请重新选择行程')
      }
    },
    orderResult(result) {
      if (result.datas.creditExtension || this.evectionInfo.evectionType === 1) {
        // 走收银台
        this.$router.push({
          name: 'h5-travel-payment',
          query: {
            evectionNo: this.evectionNo,
            businessType: 2,
          }
        })
      } else {
        let orderType = null;
        let orderNo = null;
        if (this.priceResult.flightCalculates != null) {
          orderType = 1;
          orderNo = this.priceResult.flightCalculates[0].orderNo
        } else if (this.priceResult.orderAmountOfCalculationResults != null) {
          orderType = 2;
          orderNo = this.priceResult.orderAmountOfCalculationResults[0].orderNo
        } else if (this.priceResult.todHotelOrderInterims != null) {
          orderType = 3;
          orderNo = this.priceResult.todHotelOrderInterims[0].orderNo
        }
        // 完成支付
        this.$router.push({
          name: 'h5-payment-success',
          query: {
            orderNo,
            orderType
          }
        });
      }
    },
    // 检查表单
    checkForm() {
      return new Promise(resolve => {
        this.$refs['reviewForm'].validate().then(() => {
          resolve(true)
        }).catch(() => {
          resolve(false)
        })
      })
    },
    inputFontLong(val) {
      if (val && val.length < 3) {
        return ''
      } else {
        return 'small'
      }
    },
    submitEnd() {
      this.successPopup = false;
      this.$router.push({
        name: 'h5-evection-order-detail',
        query: {
          pageType: 2,
          evectionNo: this.evectionNo
        }
      })
    },

    to_payment() {
      this.$router.push({name: 'h5-travel-payment', businessType: 2,});
    },
    // 获取个人信息
    async getUserInfo() {
      let res = await get_user_info();
      this.userInfo = res.datas
    },
    // 获取保险信息
    async getInsuranceInfo() {
      let [err, res] = await awaitWrap(consumer_trains_product_getOrderInsuranceInfo());
      if (err) {
        return
      }
      let insuranceList = res.data;
      insuranceList.forEach((value, index) => {
        value.value = value.id;
        value.text = `${value.insurancePrice}元/份 ${value.insuranceRemark}`
        /*if (index === 0) {
        /*if (index === 0) {
            this.insuranceProId = value.id
            this.insuranceDesc = value.insuranceDesc
        }*/
      });
      insuranceList.unshift({
        text: '无',
        value: '',
        id: ''
      });
      this.insuranceList = insuranceList
    },
    filterDays(val) {
      let date = this.$moment().format('YYYY-MM-DD');
      let dateVal = this.$moment(val).format('YYYY-MM-DD');

      return this.$moment(dateVal).diff(this.$moment(date), 'days')
    },
    //处理时间信息，返回周几
    filterWeek(val) {
      if (val === '') {
        return ''
      }
      let day = moment(val).day();
      if (day === 0) {
        return '星期日'
      } else if (day === 1) {
        return '星期一'
      } else if (day === 2) {
        return '星期二'
      } else if (day === 3) {
        return '星期三'
      } else if (day === 4) {
        return '星期四'
      } else if (day === 5) {
        return '星期五'
      } else if (day === 6) {
        return '星期六'
      } else {
        return ''
      }
    },
    // 开车前30分钟
    getBeforeDriving() {
      if (this.trainDataTop) {
        this.BeforeDrivingBox = setInterval(() => {
          let endTime = moment(`${this.trainDataTop.startDate} ${this.trainDataTop.startTime}`);
          let startTime = moment();
          let sum = endTime.diff(startTime, 'minute');//获取到相差月份
          if (sum <= 30) {
            clearInterval(this.BeforeDrivingBox);
            this.BeforeDrivingSwitch = true;
          }
        }, 1000)
      }
    },
    // 获取出差信息
    async getEvectionInfo() {
      let [err, res] = await awaitWrap(consumer_journey_getEvectionDetail({
        evectionNo: this.evectionNo,
        pageType: 1,
        personType: 2
      }));
      if (err) {
        Toast.clear();
        return
      }

      let data = res.datas;
      this.evectionInfo = res.datas;
      this.trainDataTop = this.evectionInfo.ticketBookingPageDetailsResponseList[0];
      this.getBeforeDriving();
      let travelUserIdList = [];
      data.travellerList.forEach(value => {
        value.id = value.userId;
        value.text = value.staffName;
        value.certificateType = value.certificateList.length > 0 ? value.certificateList[0].certificateType : '';
        value.certificateValue = value.certificateList.length > 0 ? value.certificateList[0].certificateValue : '';

        travelUserIdList.push(value.userId)
      });
      this.travelUserIdList = travelUserIdList;
      this.chooseTravelUserList = JSON.parse(JSON.stringify(data.travellerList));
      this.evectionParams = {
        evectionNo: this.evectionNo,
        evectionType: data.evectionType,
        explainDesc: data.explainDesc,
        feeAffiliationId: data.feeAffiliationId,
        feeAffiliationName: data.feeAffiliationName, // 费用归属名称
        feeAffiliationType: data.feeAffiliationType ? data.feeAffiliationType : 1,
        linkman: data.initialName ? data.initialName : this.userInfo.realName,
        linkmanPhone: data.linkmanPhone ? data.linkmanPhone : this.userInfo.phone,
        reason: data.reason,
        travelUserList: []
      };
      this.travelUserList = res.datas.travellerList ? res.datas.travellerList : [];
      // 获取火车票行程
      let trainList = res.datas.ticketBookingPageDetailsResponseList;
      let getAlternativeTrainList = []; // 需要获取
      trainList.forEach(value => {
        let seatArr = [];
        if (value.canChooseSeat === 1 && value.numberOfSeats === 5) {
          [...seatArr] = this.secondSeatArr
        } else if (value.canChooseSeat === 1 && value.numberOfSeats === 4) {
          [...seatArr] = this.firstSeatArr
        } else if (value.canChooseSeat === 1 && value.numberOfSeats === 3) {
          [...seatArr] = this.businessSeatArr
        }
        value.seatArr = JSON.parse(JSON.stringify(seatArr));
        value.chooseSeatArr = [];
        value.choose = false;
        value.canAcceptNoSeat = value.acceptNoSeat === 1 ? true : false;
        this.trainNoSeatCheck.push(value.acceptNoSeat === 1 ? true : false);
        if (value.canChooseSeat === 1 && value.chooseSeat !== '' && value.chooseSeat !== null) {
          let chooseSeatArr = [];
          if (value.chooseSeat) {
            for (let i = 0, l = value.chooseSeat.length; i < l; i += 2) {
              let arr = value.chooseSeat.slice(i, i + 2);
              chooseSeatArr.push(arr)
            }
          }
          value.chooseSeatArr = chooseSeatArr;
          value.seatArr.forEach(val => {
            val.forEach(val1 => {
              if (value.chooseSeatArr.indexOf(val1.value) > -1) {
                val1.active = true
              }
            })
          })
        }
        // 保险信息
        value.insuranceProId = value.insuranceProId ? parseInt(value.insuranceProId) : value.insuranceProId;
        value.insuranceDesc = '';
        if (value.insuranceProId !== null && value.insuranceProId !== '') {
          for (let i = 0, l = this.insuranceList.length; i < l; i++) {
            if (value.insuranceProId === this.insuranceList[i].id) {
              value.insuranceDesc = this.insuranceList[i].insuranceDesc;
              value.insurancePrice = this.insuranceList[i].insurancePrice; // 单价
              value.insuranceName = this.insuranceList[i].insuranceClass.name // 名字
            }
          }
        }

        value.finalPrice = value.seatPrice; // 次行程的最终单价，因为有抢票的情况
        // 获取当前车次的坐席列表
        value.trainSeatList = JSON.parse(value.robTicketNote);
        value.seatList = [];


        // 抢票的情况需要获取备选车次列表
        if (value.orderType === 2) {
          this.ticketGrabbingNumber++;

          value.acceptSeatCodesArr = value.acceptSeatCodes ? value.acceptSeatCodes.split(',') : [];

          let trainParams = {
            fromStationCode: value.fromStationCode,
            toStationCode: value.toStationCode,
            trainCode: value.trainNo,
            trainDate: value.startDate
          };
          let trainItem = consumer_trains_product_getAlternativeTrainNumber(trainParams);
          getAlternativeTrainList.push(trainItem)
        }
        // consumer_trains_product_getAlternativeTrainNumber
      });
      this.trainList = trainList;

      Promise.all(getAlternativeTrainList).then(data => {
        let index = 0;
        let [...finalTrainList] = this.trainList;
        finalTrainList.forEach(value => {
          if (value.orderType === 2) {
            let acceptTrainCodesArr = value.acceptTrainCodes ? value.acceptTrainCodes.split(',') : [];
            value.acceptTrainCodesArr = acceptTrainCodesArr;
            let alternativeTrainNumberList = data[index].resultList;
            /*alternativeTrainNumberList.forEach(value1 => {
                if (acceptTrainCodesArr.indexOf(value1.trainCode) > -1) {
                    value1.active = true
                } else {
                    value1.active = false
                }
            })*/
            value.alternativeTrainNumberList = alternativeTrainNumberList;
            index++
          }
        });
        this.trainList = finalTrainList;
        this.trainList.forEach((value, index) => {
          if (value.orderType === 2) {
            this.filterSeatList(index)
          }
        });
      }).catch(() => {
        Toast.clear()
      });


      let hotelList = res.datas.todHotelOrderInterimList ? res.datas.todHotelOrderInterimList : [];
      this.hotelList = hotelList

    },
    // 选中座位
    filterSeatList(trainIndex) {
      let [...trainList] = this.trainList;
      let [...seatList] = trainList[trainIndex].trainSeatList;
      let seatResult = [];

      trainList[trainIndex].alternativeTrainNumberList.forEach(value => {
        if (trainList[trainIndex].acceptTrainCodesArr.indexOf(value.trainCode) > -1) {
          value.trainSeatVoList.forEach(val => {
            seatList.push(val)
          })
        }
      });
      // 获取备选车座列表
      for (let i = 0, l = seatList.length; i < l; i++) {
        let hasItem = false;
        for (let m = 0, n = seatResult.length; m < n; m++) {
          if (seatResult[m].seatCode === seatList[i].seatCode) {
            hasItem = true;
            // 比较价格
            if (parseFloat(seatResult[m].seatPrice) < parseFloat(seatList[i].seatPrice)) {
              seatResult[m].seatPrice = seatList[i].seatPrice
            }
            break
          }
        }
        if (!hasItem) {
          seatResult.push(seatList[i])
        }
      }
      trainList[trainIndex].seatList = seatResult;
      this.trainList = trainList;

      // 再对比选中的坐席的价格
      this.chooseSeat(trainIndex, trainList[trainIndex].acceptSeatCodesArr)
    },
    chooseSeat(trainIndex, val) {
      let [...trainList] = this.trainList;
      this.trainList = trainList;
      // 比较最大价格
      let price = 0;
      let acceptSeatInfoArr = []; // 选中的坐席的信息数组，用于显示
      let [...seatList] = this.trainList[trainIndex].seatList;
      if (val !== undefined && val.length > 0) {
        // 有选中情况
        for (let i = 0, l = val.length; i < l; i++) {
          for (let m = 0, n = seatList.length; m < n; m++) {
            if (val[i] === seatList[m].seatCode && seatList[m].seatPrice > price) {
              price = parseFloat(seatList[m].seatPrice)
              // break
            }
            if (val[i] === seatList[m].seatCode) {
              acceptSeatInfoArr.push(seatList[m])
            }
          }
        }
      }
      let seatPrice = price;
      this.trainList[trainIndex].finalPrice = seatPrice > this.trainList[trainIndex].seatPrice ? seatPrice : this.trainList[trainIndex].seatPrice;
      this.trainList[trainIndex].acceptSeatCodes = this.trainList[trainIndex].acceptSeatCodesArr.join(',');
      this.trainList[trainIndex].acceptSeatInfoArr = acceptSeatInfoArr;

      this.saveAndReactPrice()
    },
    // 保存并重新获取价格
    async saveAndReactPrice() {
      await this.saveInfo();
      await this.getAllPrice();
      Toast.clear();
    },
    // 保存信息
    async saveInfo() {
      let params = Object.assign({}, this.evectionParams);
      let userIdList = [];
      this.evectionParams.travelUserList.forEach(value => {
        userIdList.push(value.id)
      });

      // this.$refs['travelerListForReserve'].init({
      //     situation: '100',
      //     businessType: 2,
      //     userIdList
      // });
      params.userIdList = userIdList;
      params.ticketRequests = this.trainList;
      //整合最贵的价格
      let maxData = [];
      this.resultDown.forEach((item, index) => {
        maxData.push(Number(this.trainNumberAllSeat[item].seatPrice));
      });

      if (maxData.length != 0) {
        params.ticketRequests[0].alternativeSeatPrice = Math.max(...maxData) > this.trainDataTop.seatPrice ? Math.max(...maxData) : this.trainDataTop.seatPrice;
      } else {
        params.ticketRequests[0].alternativeSeatPrice = this.trainDataTop.seatPrice;
      }

      let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params));
      if (err) {
        return false
      }
      return res
    },
    // 获取订单所有价格
    getAllPrice() {
      let params = {
        evectionNo: this.evectionNo,
        headCounts: this.evectionParams.travelUserList.length
      };

      consumer_journey_calculatePrice(params).then(res => {
        this.priceResult = res.datas;
        if (this.priceResultOne == '') {
          this.priceResultOne = this.priceResult;
        }
      })

    },
    // 获取当前出差单信息和可选择的出行人列表
    getTravelInfoAndUserList() {
      if (this.evectionInfo.evectionType === 1) {
        // 个人出差
        // this.getDepartmentUser()
        this.getUsuallyUser()
      } else if (this.evectionInfo.evectionType === 2 && this.workTravelType === 2) {
        this.getDepartmentUser()
      } else {
        // 出差单出差
        let params = {
          evectionNo: this.evectionNo,
          approveType: 1,
        };
        consumer_apply_myApplyListV2(params).then(res => {
          let currentBusinessTravelList = res.datas.list;
          this.currentBusinessTravelList = currentBusinessTravelList;
          let staffList = res.datas.list[res.datas.list.length - 1].staffList;
          staffList.forEach(value => {
            value.value = value.userId;
            value.id = value.userId;
            value.text = value.staffName;
            value.label = value.staffName;
            value.name = value.staffName;
            value.certificateType = value.certificateList ? value.certificateList[0].certificateType : '';
            value.certificateValue = value.certificateList ? value.certificateList[0].certificateValue : ''
          });
          // this.canSelectTravelUserList = staffList
          this.canChooseUserList = staffList;
          console.log('canChooseUserList', this.canChooseUserList)
        })
      }
    },
    // 获取公司所有员工
    async getDepartmentUser() {
      // 根据层级返回对应class
      let reduceClass = (val) => {
        if (val === 1) {
          return 'firstClass'
        } else if (val === 2) {
          return 'secondClass'
        } else if (val === 3) {
          return 'thirdClass'
        } else {
          return 'fourthClass'
        }
      };
      // 给多级数组增加树形
      let reduceData = (data, level) => {
        data.map((m, i) => {
          m.level = level;
          m.className = reduceClass(level);
          m.id = m.deptId;
          m.text = m.deptName;
          m.childrenDept = m.deptList;
          m.children = m.staffList;
          delete m.deptList;
          delete m.staffList;
          if (m.children && m.children.length) {
            m.children.forEach(value => {
              value.id = value.subUserId;
              value.text = value.subStaffName
            })
          }
          if (m.childrenDept && m.childrenDept.length > 0) {
            reduceData(m.childrenDept, level + 1)
          }
        })
      };
      // 将多维数组转成一维数组
      let jsonToArray = (nodes) => {
        let r = [];
        if (Array.isArray(nodes)) {
          for (let i = 0, l = nodes.length; i < l; i++) {
            r.push(nodes[i]); // 取每项数据放入一个新数组
            if (Array.isArray(nodes[i]["childrenDept"]) && nodes[i]["childrenDept"].length > 0)
            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
              r = r.concat(jsonToArray(nodes[i]["childrenDept"]));
            delete nodes[i]["childrenDept"]
          }
        }
        return r;
      };
      let res = await get_user_info();
      this.userInfo = res.datas;
      let result = await consumer_department_getDepartmentUsers({companyId: this.userInfo.companyId});
      let cascadeList = result.datas;
      reduceData(cascadeList, 1);
      let newCascadeList = jsonToArray(cascadeList);
      let [err, customerResult] = await awaitWrap(consumer_web_externalCustomer_getExternalCustomerAll());
      if (err) {
        this.allUserItems = newCascadeList;
        return
      }
      let customerList = customerResult.datas;
      customerList.forEach(value => {
        value.id = value.userId;
        value.text = value.customerName
      });
      let customerDept = {
        childrenDept: [],
        deptId: -1,
        id: -1,
        deptLevel: 1,
        className: 'firstClass',
        deptName: '外部客户',
        deptStatus: 1,
        parentId: 0,
        children: []
      };
      customerDept.children = customerList;
      newCascadeList.push(customerDept);
      this.allUserItems = newCascadeList
    },
    // 关闭有出差单出差的出行人选择弹窗
    closeUserSelectPopup() {
      this.travelUserSelectPopupShow = false
    },
    // 有出差单出差的出行人-确定选择
    submitUserSelectPopup() {
      this.travelUserSelectPopupShow = false;

      // 提交校验
      this.$refs.checkTravelBox.run()
    },
    // 监听关闭有出差单出差的出行人选择弹窗
    listenCloseUserSelectPopup() {
      let activeChooseUserList = [];
      // this.activeUserIds = JSON.parse(JSON.stringify(this.activeCanChooseUserList))
      this.canChooseUserList.forEach(value => {
        if (this.activeUserIds.indexOf(value.id) > -1) {
          activeChooseUserList.push(value)
        }
      });
      this.evectionParams.travelUserList = activeChooseUserList
    },
    // 出差单情况下选择出行人
    toggleUser(index) {
      this.$refs.checkboxes[index].toggle();
    },
    getCurrentEvection() {
    },
    // 打开出差单选择弹窗
    showReservePopup() {
      this.travelPopupShow = true
    },
    // 日期确定
    onCalendarConfirm(val) {
      if (val === '' || val === null || val.length === 0) {
        this.newTravelParams.hotelCheckInDate = '';
        this.newTravelParams.hotelCheckOutDate = ''
      } else {
        this.newTravelParams.hotelCheckInDate = val[0];
        this.newTravelParams.hotelCheckOutDate = val[1]
      }
    },
    // 展开价格详情
    showPriceInfoPopup() {
      this.productPricePopupSet = !this.productPricePopupSet;
      this.productPricePopup = !this.productPricePopup;
    },
    closeProductPricePopup() {
      this.productPricePopupSet = false
    },
    //
    async getDepartmentUserList() {
      // 给多级数组增加树形
      let reduceData = (data, level) => {
        data.map((m, i) => {
          m.level = level;
          //m.className = reduceClass(level)
          m.id = m.deptId;
          m.text = m.deptName;
          m.childrenDept = m.deptList;
          m.children = m.staffList;
          delete m.deptList;
          delete m.staffList;
          if (m.children && m.children.length) {
            m.children.forEach(value => {
              value.id = value.subUserId;
              value.text = value.subStaffName
            })
          }
          if (m.childrenDept && m.childrenDept.length > 0) {
            reduceData(m.childrenDept, level + 1)
          }
        })
      };
      // 将多维数组转成一维数组
      let jsonToArray = (nodes) => {
        let r = [];
        if (Array.isArray(nodes)) {
          for (let i = 0, l = nodes.length; i < l; i++) {
            r.push(nodes[i]); // 取每项数据放入一个新数组
            if (Array.isArray(nodes[i]["childrenDept"]) && nodes[i]["childrenDept"].length > 0)
            // 若存在children则递归调用，把数据拼接到新数组中，并且删除该children
              r = r.concat(jsonToArray(nodes[i]["childrenDept"]));
            delete nodes[i]["childrenDept"]
          }
        }
        return r;
      };

      let departmentUserList = [];
      let setDeptList = (list) => {
        list.forEach(value => {
          if (value.staffList) {
            value.staffList.forEach(value1 => {
              value1.id = value1.subUserId;
              value1.text = value1.subStaffName;
              value1.name = value1.subStaffName;
              value1.deptId = value.deptId;
              value1.deptName = value.deptName;
              departmentUserList.push(value1)
            })
          }
          if (value.deptList) {
            setDeptList(value.deptList)
          }
        })
      };
      let [err, res] = await awaitWrap(consumer_department_getDepartmentUsers());
      let cascadeList = res.datas;
      /*reduceData(cascadeList, 1)
      let newCascadeList = jsonToArray(cascadeList)
      console.log(newCascadeList)
      let departmentUserList = []
      newCascadeList.forEach(value => {

      })*/
      setDeptList(cascadeList);
      console.log(departmentUserList);
      let [customerErr, customerResult] = await awaitWrap(consumer_web_externalCustomer_getExternalCustomerAll());
      if (customerErr) {
        this.contactUserList = JSON.parse(JSON.stringify(departmentUserList))
      }
      let customerList = customerResult.datas;
      customerList.forEach(value => {
        value.id = value.userId;
        value.text = value.customerName;
        value.name = value.customerName;
        value.deptId = -1;
        value.deptName = '外部客户'
      });
      departmentUserList = departmentUserList.concat(customerList);
      this.contactUserList = JSON.parse(JSON.stringify(departmentUserList));
      console.log(this.contactUserList)
    },
    submitContactUser() {
      console.log(this.activeContactUserList);
      let activeContactUserList = JSON.parse(JSON.stringify(this.activeContactUserList));
      this.evectionParams.linkman = activeContactUserList[0].name;
      this.evectionParams.linkmanPhone = activeContactUserList[0].phone
    },
    submitAddUser() {
      let idList = [];
      this.chooseTravelUserList.forEach(value => {
        idList.push(value.id)
      });
      let travelUserList = [];
      let travelUserIdList = [];
      this.evectionParams.travelUserList.forEach((value, index) => {
        if (idList.indexOf(value.id) > -1) {
          travelUserList.push(value);
          travelUserIdList.push(value.id)
        }
      });
      this.evectionParams.travelUserList = travelUserList;
      this.travelUserIdList = travelUserIdList;
    },

    // 获取常用旅客
    getUsuallyUser() {
      consumer_frequent_contacts_getList({currentPage: 1, pageSize: 100}).then(res => {
        let frequentPassenger = res.pageResult.pageData;
        /*frequentPassenger.forEach(value => {
            if (value.status === 0) {
                this.frequentPassenger.push(value)
            }
        })*/
        frequentPassenger.forEach(value => {
          value.value = value.uid;
          value.id = value.uid;
          value.text = value.username;
          value.label = value.username;
          value.name = value.username
        });
        this.canChooseUserList = frequentPassenger
      })
    },

    __successPopup_opened_event_handler() {
      const __this = this;
      fixStatusBar({
        type: `200`,
        element: __this.$refs.successPopup.$el.querySelector(`.popup-search-header`),
      });
    },
  }
}
