import {Toast} from 'vant';
import dialog_for_request from "@/lib/common-service/dialog_for_request";

export default {
    data() {
        return {
            van_popup: {
                show: false,
                style: {
                    height: `80vh`,
                },
            },
        }
    },
    methods: {
        __van_dialog_btn_close_click_event_handler() {
            const __this = this;
            dialog_for_request.close();
        },

        __van_dialog_btn_view_detail_click_event_handler() {
            const __this = this;
            const van_popup = __this.van_popup;
            van_popup.show = true;
        },

        __van_popup_opened_event_handler() {

        },

        __van_pop_btn_copy_click_event_handler() {
            const __this = this;
            const text = __this.$store.state.dialog_for_request.detail_msg;
            __this.$copyText(text).then(function (e) {
                Toast.success('复制成功！');
            }, function (e) {
                Toast.fail('复制失败！');
            })
        },

        __van_pop_btn_close_click_event_handler() {
            const __this = this;
            const van_popup = __this.van_popup;
            van_popup.show = false;
        },
    }
}
