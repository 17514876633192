import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import moment from 'moment';
import Client from "yinzhilv-websocket-server/src/WebSocketServer/Client/index";
// import check_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/yinzhilv/check_token";
import superheroes from "superheroes";

const check_token = function(){
  return Q.resolve({
    flag:true,
  });
}

let aWebSocketServerClient

const init = function () {
  const aWebSocketUri = global.appConfig[`yinzhilv-websocket-server`].uri;

  aWebSocketServerClient = new Client({
    info: {
      remarks: `${superheroes.random()}`,
    },
    WebSocketUri: aWebSocketUri,
  });
  aWebSocketServerClient.onCallback = aWebSocketServerClient.on
  aWebSocketServerClient.on = function(pParameter) {
    pParameter.before_handler = () => {
      return check_token({clear_cache: true}).then((res) => {
        if (res.flag) {
          return Q.resolve()
        } else {
          return Q.reject()
        }
      })
    }
    this.onCallback(pParameter)
  }
  global.aWebSocketServerClient = aWebSocketServerClient;
};


export default {
  init,
}
