import NavBar from "@/component/nav-bar/import_latest_version_module";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import consumer_journey_orderFreeApprove from '@/lib/data-service/haolv-default/consumer_journey_orderFreeApprove'
export default {
    data() {
        return {
            title: '机票',
            orderNo: '',
            evectionType: 2,
            orderFreeApprove: false,
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
        const _this = this;
        this.$nextTick(() => {
            fixStatusBar({
                handler({api}) {
                    const bodyWidth = document.body.clientWidth;
                    const headNav = _this.$refs.topElementNav.$el;
                    const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
                    headNav.style.paddingTop = addPaddingTop + 'vw';
                }
            });
        })
    },
    activated() {
        this.orderNo = this.$route.query.orderNo || '';
        this.getOrderFreeApprove();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getOrderFreeApprove() {
            consumer_journey_orderFreeApprove({
                businessType: 1,
                orderNo: this.orderNo
            }).then(res => {
                this.orderFreeApprove = res.datas.orderFreeApprove
            })
        },
        goDetail() {
            if (this.orderNo) {
                this.$router.replace({
                    name: 'flight-order-detail',
                    query: {
                        orderNo: this.orderNo,
                        evectionType: this.evectionType,
                        jumpType: 'list'
                    }
                })
            }
        },
        back() {
            this.$router.replace({
                name: 'h5-flight-home',
            })
        },
    }
}
