import Header from './component/header/index.vue';
import TabBar from './component/tab-bar/index.vue';

export default {
    components: {
        Header,
        TabBar,
    },
    data() {
        return {};
    },
}
