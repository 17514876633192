import consumer_trains_product_checkTheRemainingTrainTickets
    from '@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTickets'
import consumer_trains_order_advanceRebooking
    from '@/lib/data-service/haolv-default/consumer_trains_order_advanceRebooking'
import consumer_trains_common_queryRailwayTimetable
    from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable'
import {Toast} from "vant";

export default {
    data() {
        return {
            list: [],
            trainParams: {},
            trainDate: '',
            fromStationCode: '',
            toStationCode: '',
            showCalendar: false,
            week: '',
            showTrainWayListPopup: false,
            railwayTimeList: [], // 经停信息列表
        }
    },
    computed: {
        getTrainParams() {
            return this.$route.query;
        },
    },
    methods: {
        formatterDate(date) {
            return this.$moment(date).format('YYYY-MM-DD');
        },
        // 刷新
        onRefresh() {
            Toast.loading({
                message: '正在刷新...',
                forbidClick: true,
                duration: 0,
            });
            consumer_trains_product_checkTheRemainingTrainTickets({
                fromStationCode: this.fromStationCode,
                toStationCode: this.toStationCode,
                trainDate: this.$moment(this.trainDate).format('YYYY-MM-DD'),
            }).then((res) => {
                Toast.clear();
                let _list = res.resultList;
                for (let i = 0; i < _list.length; i++) {
                    if (_list[i].trainCode == this.trainParams.trainCode) {
                        this.trainParams = _list[i];
                    }
                }
            }).catch(err => {
                Toast.clear();
            });
        },
        // 打开日期选择
        openCalendar() {
            this.showCalendar = true;
        },
        // 选择日期确认
        onConfirmCalendar(value) {
            this.showCalendar = false;
            this.trainDate = this.$moment(value).format('YYYY-MM-DD');
            this.week = this.getWeek(this.trainDate);
            this.onRefresh();
        },
        // 根据日期判断星期几
        getWeek(dateString) {
            let dateArray = dateString.split('-');
            let date = new Date(dateArray[0], parseInt(dateArray[1] - 1), dateArray[2]);
            return '周' + '日一二三四五六'.charAt(date.getDay());
        },
        // 前一天
        onPrev() {
            let _date = new Date(this.trainDate);
            _date.setDate(_date.getDate() - 1);
            this.trainDate = this.$moment(_date).format('YYYY-MM-DD');
            this.onRefresh();
        },
        // 后一天
        onNext() {
            let _date = new Date(this.trainDate);
            _date.setDate(_date.getDate() + 1);
            this.trainDate = this.$moment(_date).format('YYYY-MM-DD');
            this.onRefresh();
        },
        // 改签
        reserve(item, index) {
            let params = {
                changeArriveDate: this.$moment(this.trainParams.arriveDate).format('YYYY-MM-DD'),
                changeArriveTime: this.trainParams.toTime,
                changeFromStationCode: this.trainParams.fromStationCode,
                changeFromStationName: this.trainParams.fromStationName,
                changeSeatCode: item.seatCode,
                changeSeatName: item.seatName,
                changeSeatPrice: item.seatPrice,
                changeStartDate: this.$moment(this.trainParams.trainStartDate).format('YYYY-MM-DD'),
                changeStartTime: this.trainParams.fromTime,
                changeToStationCode: this.trainParams.toStationCode,
                changeToStationName: this.trainParams.toStationName,
                changeTrainCode: this.trainParams.trainCode,
                gmtChangeArrive: this.$moment(this.trainParams.arriveDate).format('YYYY-MM-DD HH:mm:ss'),
                gmtChangeStart: this.$moment(this.trainParams.trainStartDate).format('YYYY-MM-DD') + ' ' + this.trainParams.fromTime + ':00',
                orderNo: this.getTrainParams.orderNo,
                changeNo: ''
            };

            consumer_trains_order_advanceRebooking(params).then((res) => {
                this.$router.push({
                    name: 'h5-train-change',
                    query: {
                        orderNo: this.getTrainParams.orderNo,
                        changeNo: res.datas.changeNo
                    },
                });
            });
        },
        // 获取经停信息列表
        getRailwayTime() {
            consumer_trains_common_queryRailwayTimetable({
                fromStationCode: this.trainParams.fromStationCode,
                toStationCode: this.trainParams.toStationCode,
                trainCode: this.trainParams.trainCode,
                trainDate: this.getTrainParams.trainDate,
            }).then((res) => {
                this.railwayTimeList = [].concat(res.results);
            });
        },
        closeTrainWayPopup() {
            this.showTrainWayListPopup = false;
        },
    },
    created() {
        let _trainInfo = decodeURIComponent(this.getTrainParams.trainInfo);
        this.trainParams = JSON.parse(_trainInfo);
        this.trainDate = this.getTrainParams.trainDate;
        this.fromStationCode = this.getTrainParams.fromStationCode;
        this.toStationCode = this.getTrainParams.toStationCode;
        this.week = this.getWeek(this.trainDate);
        this.getRailwayTime();
    },
}