// consumer_journey_getEvectionDetail 根据出差单号获取详情
// http://219.128.52.2:18765/doc.html#/haolv-consumer/t-od-journey-controller/getEvectionDetailUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_journey_getEvectionDetail = (pParameter) => {
    if (!pParameter) pParameter = {};

    let datas = {};
    datas.choice =  pParameter.choice ? pParameter.choice : 0;
    datas.evectionNo =  pParameter.evectionNo ? pParameter.evectionNo : '';
    datas.orderNo =  pParameter.orderNo ? pParameter.orderNo : '';
    datas.ofcId =  pParameter.ofcId ? pParameter.ofcId : [];
    datas.overproofType =  pParameter.overproofType ? pParameter.overproofType : 0;
    datas.pageType =  pParameter.pageType ? pParameter.pageType : 0;
    datas.personType =  pParameter.personType ? pParameter.personType : 0;

    const p = {
        method: 'post',
        urlSuffix: '/consumer/journey/getEvectionDetail',
        data: datas
    };

    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_journey_getEvectionDetail;
