import consumer_notice_list from "@/lib/data-service/haolv-default/consumer_notice_list.js";

export default {
  data() {
    return {
      loading: false,
      list: [],
      form: {
        currentPage: 1,
        pageSize: 50,
      },
      emptyType: "",
    };
  },
  
  watch: {},
  methods: {
    //初始化
    init (){
      this.list = [
        {
          id: 0,
          noticeTitle: '',
          noticeContent: '',
          createTime: ''
        }
      ]
    },
    // 获取列表
    async getList () {
      try {
        if (this.loading) return
        this.loading = true
        const res = await consumer_notice_list(this.form)
        console.log(res)
        this.list = res.list;
      } catch (error) {
        this.list = []
      } finally {
        this.loading = false
      }
    },
    detailClick (id) {
      this.$router.push({
        name: 'h5-office-notice-detail',
        query: {
          id
        }
      });
    }
  },
  created (){
    this.init()
  },
  activated () {
    this.getList()
  }
};
