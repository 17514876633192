// import getLocation from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/get_location/1.0.0/index.js';
import getLocation from '@/lib/common-service/get_location/1.0.0/index.js';
import Q from "q";

let currentLocation = {};
const defer = Q.defer();

const init = function () {
    return getLocation().then(res => {
        currentLocation = res;
        currentLocation.locationStr = '';
        if (currentLocation.uaType === '100' && currentLocation.lat && currentLocation.lon) {
            currentLocation.locationStr = [currentLocation.lat, currentLocation.lon].toString();
        }
        // 在钉钉里
        if (currentLocation.uaType === '200' && currentLocation.latitude && currentLocation.longitude) {
            currentLocation.locationStr = [currentLocation.latitude, currentLocation.longitude].toString();
        }
        // 在飞书里
        if (currentLocation.uaType === '201' && currentLocation.latitude && currentLocation.longitude) {
            currentLocation.locationStr = [currentLocation.latitude, currentLocation.longitude].toString();
        }
        // currentLocation.locationStr = currentLocation.uaType === '100' && currentLocation.lat && currentLocation.lon ? [currentLocation.lat, currentLocation.lon].toString() : '';
        defer.resolve();
    })
};

const check_is_current_city = function (cityId) {
    let isCurrent = false;
    if (currentLocation.cityId === cityId) {
        isCurrent = true;
    }
    return isCurrent;
};

const checkCurrentLocation = function () {
    return defer.promise
};

const get_current_location = function () {
    return checkCurrentLocation().then(() => {
        return currentLocation;
    })
};

export default {
    init,
    check_is_current_city,
    get_current_location
}
