// consumer_web_policyConfig_getUsefulCarScene
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_policyConfig_getUsefulCarScene = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/getUsefulCarScene',
        data: pParameter,
        enable_error_alert: false // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_policyConfig_getUsefulCarScene;
