import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/hotel-submit-order',
            name: 'hotel-submit-order',
            component: () => import(/* webpackChunkName: "page-travel-book-hotel-hotel-submit-order" */ `./1.0.0/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '确认订单信息',
                    need_head_nav: false,
                    need_head_nav1: false,
                    need_footer_bar: false,
                    secondary_menu: 0,
                    need_alive: true,
                },
            
	data_for_all_routes_processor: {
    		order_index: '012-004',
	},

},
        }
    ],
};
