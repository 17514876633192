import Q from 'q';
import _ from 'underscore';
import {Dialog, Notify, Toast} from "vant";
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import EvectionReserveInfoInput from '@/component/evection-reserve-info-input/1.0.1/index.vue'
import ReserveProcessBox from '@/component/reserve-process-box/1.0.0/index.vue'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_t_od_hotel_order_interim_hotelOrderPolicyTips from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelOrderPolicyTips' // 酒店预定页-个人差标提示
import consumer_department_getStaffsByEvectionNo from '@/lib/data-service/haolv-default/consumer_department_getStaffsByEvectionNo' // 根据出差单号返回关联的出行人员
import consumer_admin_journey_calculateHotelPrice
    from '@/lib/data-service/haolv-default/consumer_admin_journey_calculateHotelPrice'//计算总价
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_t_od_hotel_order_interim_createOrderByOnBusiness
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_createOrderByOnBusiness' //创建订单
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_journey_orderFreeApprove from '@/lib/data-service/haolv-default/consumer_journey_orderFreeApprove'
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.0'
import TravelerMultipleSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-multiple-selector/1.2.5/index.vue';//选人列表组件
import BaseMultipleEntitySelector0301
    from 'yinzhilv-js-sdk/frontend/common-project/component/yinzhilv-app/base-multiple-entity-selector-0301/1.0.3/index.vue';//在出差单出行人中选择入住人列表组件
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import roomDetailPopup from '@/component/hotel-room-detail-popup/1.0.0/index.vue';// 房型详情弹框
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
import consumer_payment_payReminder from "@/lib/data-service/haolv-default/consumer_payment_payReminder";
import CostAttributionList from '@/page/travel-book/flight/component/costAttributionList/import_latest_version_module'
import cache_set from "nllib/src/cache/set";


export default {
    data () {
        return {
            loading: true,
            hasNodeList: false,
            submitting: false,
            isShowRoomNumPopup: false,
            isShowArrivalTimePopup: false,
            isShowPriceDetailPopup: false,
            isFreeApprove: false,//是否需要审批
            priceDetailArrow: 'arrow-up',
            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            evectionRequired: 0,
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            activeNames: [],
            roomAmountList: [],
            curRoomId: 0,
            aUserIdList: [],
            info: {
                hotelName: '',
                nightDay: 0,
                list: [],
            },
            evectionParams: {
                evectionNo: '',
                evectionType: 2,
                explainDesc: '',
                feeAffiliationId: '',
                feeAffiliationName: '', // 费用归属名称
                feeAffiliationType: 1,
                linkman: '',
                linkmanPhone: '',
                reason: '',
                travelUserList: [],
                roomAmount: 1,
                partnerEctionId: '',
                centerCode: '',//成本中心新增
                projectCode: '',//归属项目新增Code
                projectName: '',//归属项目新增name
                companyId: 0,//归属项目新增
            },
            evectionType: '',
            workTravelType: '',
            form: {
                arrivalTime: '13:00',
                remarks: '',
                linkman: '',
                linkmanPhone: '',
                smsType: 0,
                linkmanSms: 0,
                userIdList: [],
                userList: [],
                contact: [],
                roomAmount: 0,
                roomList: [{
                    roomId: 1,
                    user: {}
                }],
                feeAffiliationName: ''
            },
            isSmsType: true,//是否发送行程信息到出行人手机
            isLinkmanSms: true,//是否发送行程信息到联系人手机
            isBindAgreementCard: true,//是否绑定企业协议卡号
            roomNumText: '1间',
            evectionInfo: {},
            hotelOrderInfo: {},
            policyTipsList: [],
            priceResult: {},
            orderNo: '',//创建的订单号
            isShowBackDialog: false,
            pickerOptions: [],//可选时间范围

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 3,
            returnSetInterval: null,
            hotelOrderInterims: {},
            paymentPage: 0, // 是否弹窗 0.不弹 1.弹
            feeAffiliationShow: false,
            today: '',
            isTodayMorning: false,
            overproofPrice: 0, //一间房的超标范围金额
            payType: 0, // 0：企业个人支付都有；1:企业支付；2:个人支付

            travelFeeAffiliationMsgList: [], // 合住人出差信息列表
        }
    },
    components: {
        TravelerMultipleSelector,
        BaseMultipleEntitySelector0301,
        EvectionReserveInfoInput,
        ReserveProcessBox,
        roomDetailPopup,
        CostAttributionList
    },
    mounted() {
        let evectionParams = {
            vm: this
        };
        const evectionQuery = getEvectionAllType(evectionParams);
        this.evectionDetailType = evectionQuery.evectionDetailType;
        this.getPayRemider();
    },
    async activated() {
        const __this = this;
        await this.refundChangePolicyGet()
        this.keyback();
        this.form.roomAmount = 0;
        await this.init();
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    watch: {
         //房间数量改变时，改变需要选定入住人数和预定总价
        'form.roomAmount'(newVal) {
            if(this.evectionInfo.shareWith !== 1) {
                this.form.roomList = [{
                    roomId: 1,
                    user: {},
                    userIdList: []
                }];
                for (let i = 1; i < newVal; i++) {
                    this.form.roomList.push({
                        roomId: i + 1,
                        user: {},
                        userIdList: []
                    })
                }
                if (this.availableTraveler.length > 0) {
                    for (let i = 0; i < this.form.roomList.length; i++) {
                        if (this.availableTraveler[i]) {
                            this.form.roomList[i].user = {
                                userId: this.availableTraveler[i].userId,
                                name: this.availableTraveler[i].name,
                                phone: this.availableTraveler[i].phone,
                                certificateName: this.availableTraveler[i].certificateName,
                                certificateValue: this.availableTraveler[i].certificateValue,
                                certificateType: this.availableTraveler[i].certificateType,
                                unSensitiveCertificateValue: this.availableTraveler[i].unSensitiveCertificateValue,
                                unSensitivePhone: this.availableTraveler[i].unSensitivePhone
                            }
                            this.form.roomList[i].userIdList = [this.availableTraveler[i].userId]
                        }
                    }
                }
            }
            this.getAllPrice();
        },
        //userIdList变化时，更新userList，并发布userIdList和userList给父组件
        'aUserIdList'(newVal) {
            this.form.roomList.find(room => {
                if (room.roomId === this.curRoomId) {
                    if (newVal.length > 0) {
                        const id = newVal[newVal.length - 1];
                        if (this.evectionDetailType === '001') {
                            this.$refs.aBaseMultipleEntitySelector0301.get_selected_entity_promise({
                                id,
                            }).then(function ({entity}) {
                                room.user = entity;
                            });
                        } else {
                            this.$refs.aTravelerSingleSelector.get_selected_entity_promise({
                                id,
                            }).then(function ({entity}) {
                                room.user = entity;
                            });
                        }
                        room.userIdList = newVal;
                    }
                }
            });
        },
        //从员工中选择联系人时，
        'form.contact'(newVal) {
            this.contactObj = this.$refs.aContactSingleSelector.get_selected_entity();
            this.form.linkman = this.contactObj.name;
            this.form.linkmanPhone = this.contactObj.phone;
        }
    },
    methods: {
        //初始化
        async init() {
            this.loading = true;
            this.$showLoading.show();
            this.isSmsType = true;//是否发送行程信息到出行人手机
            this.isLinkmanSms = true;//是否发送行程信息到联系人手机
            this.activeNames = [];//酒店差标默认收起
            this.evectionNo = this.$route.query.evectionNo;
            this.evectionType = parseInt(this.$route.query.evectionType)
            this.workTravelType = this.$route.query.workTravelType === undefined ? '' : parseInt(this.$route.query.workTravelType);
            this.getEvectionType();
            await this.getStaffsByEvectionNo();
            await this.getEvectionInfo();
            await this.checkFreeApprove();
            await this.getHotelOrderPolicyTips();
            // this.initSelectorData();
            this.initSelectorData();
            await this.getCurUser();
            this.setDefaultVal();
            this.initApproval();
            this.loading = false;
            this.$showLoading.hide();
        },
        //获取出差类型
        getEvectionType() {
            let evectionParams = {
                vm: this
            }
            const evectionQuery = getEvectionAllType(evectionParams)
            this.evectionDetailType = evectionQuery.evectionDetailType;
        },
        // 获取出差信息
        async getEvectionInfo() {
            let [err, res] = await awaitWrap(consumer_journey_getEvectionDetail({
                evectionNo: this.evectionNo,
                pageType: 1,
                personType: 2
            }));
            if (err) {
                return
            }
            this.evectionInfo = res.datas;
            this.travelFeeAffiliationMsgList = this.evectionInfo.travelFeeAffiliationMsgList || [];
            this.form.travelReason = this.evectionInfo.reason;
            let feeAffiliationId = res.datas.feeAffiliationId;
            let feeAffiliationName = res.datas.feeAffiliationName;
            this.form.feeAffiliationName = this.evectionInfo.feeAffiliationName;
            let feeAffiliationType = res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1;
            if (!feeAffiliationName && res.datas.evectionType === 2) {
                feeAffiliationId = -1;
                feeAffiliationName = '各自所在部门';
                feeAffiliationType = 1
            }

            let reason = res.datas.reason;
            if (!reason && res.datas.evectionType === 2) {
                reason = '商务出行'
            }

            //合住
            if(this.evectionInfo.shareWith == 1) {
                this.form.roomList = [{
                    roomId: 1,
                    user: {},
                    userIdList: []
                },{
                    roomId: 1,
                    user: {},
                    userIdList: []
                }];
                if (this.availableTraveler.length > 0) {
                    let traveler = this.availableTraveler.find(traveler => {
                        return traveler.shareWith == 2;
                    })

                    this.form.roomList[0].user = {
                        userId: traveler.userId,
                        name: traveler.name,
                        phone: traveler.phone,
                        shareWith: traveler.shareWith,
                        certificateName: traveler.certificateName,
                        certificateValue: traveler.certificateValue,
                        certificateType: traveler.certificateType,
                        unSensitiveCertificateValue: traveler.unSensitiveCertificateValue,
                        unSensitivePhone: traveler.unSensitivePhone
                    }
                    this.form.roomList[0].userIdList = [traveler.userId]

                    traveler = this.availableTraveler.find(traveler => {
                        return traveler.shareWith == 1;
                    })

                    this.form.roomList[1].user = {
                        userId: traveler.userId,
                        name: traveler.name,
                        phone: traveler.phone,
                        shareWith: traveler.shareWith,
                        certificateName: traveler.certificateName,
                        certificateValue: traveler.certificateValue,
                        certificateType: traveler.certificateType,
                        unSensitiveCertificateValue: traveler.unSensitiveCertificateValue,
                        unSensitivePhone: traveler.unSensitivePhone
                    }
                    this.form.roomList[1].userIdList = [traveler.userId]
                }
            }

            let travellerName = [];
            if (res.datas.travellerList) {
                res.datas.travellerList.forEach(value=>{
                    travellerName.push(value.staffName)
                })
            }
            this.evectionParams = {
                evectionNo: res.datas.evectionNo,
                evectionType: res.datas.evectionType,
                explainDesc: res.datas.explainDesc,
                feeAffiliationId: this.evectionInfo.feeAffiliationId,
                feeAffiliationName: this.evectionInfo.feeAffiliationName, // 费用归属名称
                feeAffiliationType: this.evectionInfo.feeAffiliationType,
                linkman: res.datas.linkman ? res.datas.linkman : this.form.linkman,
                linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : this.form.linkmanPhone,
                reason: this.evectionInfo.reason,
                travelUserList: res.datas.travellerList ? res.datas.travellerList : [],
                smsType: res.datas.contactsList && res.datas.contactsList.length > 0 ? res.datas.contactsList[0].smsType : 1, // 是否发送短信， 1为是， 0为否
                travelUser: this.evectionInfo.travellerList,
                partnerEctionId: res.datas.partnerEctionId,
                travellerName: travellerName.join(','),
                centerCode: '',//成本中心新增
                projectCode: '',//归属项目新增Code
                projectName: '',//归属项目新增name
                companyId: 0,//归属项目新增
            };
            // this.evectionFormParams = {
            //     reason: this.evectionInfo.reason,
            //     travelUserList: this.evectionInfo.travellerList,
            //     feeAffiliationType: this.evectionInfo.feeAffiliationType,
            //     feeAffiliationName: this.evectionInfo.feeAffiliationName,
            //     feeAffiliationId: this.evectionInfo.feeAffiliationId
            // }
            this.hotelOrderInfo = this.evectionInfo.todHotelOrderInterimList[0];
            this.orderNo = this.hotelOrderInfo.orderNo
            this.roomAmountList = [];
            for(let i=1; i<this.hotelOrderInfo.maxRoomNum+1; i++){
                this.roomAmountList.push(i+'间');
            }
            this.hotelOrderInfo.newNightlyRateList.forEach(item => {
                let dateText = this.$moment(item.date).format('MM月DD日');
                this.$set(item, 'dateText', dateText);
            })

            this.today = this.$moment().format('YYYY-MM-DD');
            let yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
            this.isTodayMorning = this.hotelOrderInfo.checkInDate === yesterday;
            this.overproofPrice = this.hotelOrderInfo.overproofPrice;
        },
        async initApproval() {
            let userIdList = [];
            this.evectionParams.travelUser.forEach(value => {
                userIdList.push(value['userId']);
            });
            setTimeout(() => {
                this.$refs['evectionReserveInfoInput'].init({
                    form: this.evectionParams,
                    businessType: 3,
                    businessRuleControl: this.businessRuleControl,
                    evectionDetailType: this.evectionDetailType,
                    oaNo: this.evectionInfo.partnerEctionId,
                    startDate: this.hotelOrderInfo.checkInDate,
                    userId: userIdList
                })
                if (this.isFreeApprove) {
                    this.$refs['reserveProcessBox'].init().then(res => {
                        this.hasNodeList = res
                    }) // 流程
                }
            }, 500)
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.evectionRequired = res.datas.evectionRequired;
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        //酒店预定页-个人差标提示
        async getHotelOrderPolicyTips() {
            let res = await consumer_t_od_hotel_order_interim_hotelOrderPolicyTips({
                orderNo: this.orderNo
            });
            this.policyTipsList = res.datas;
        },
        //获取出行人
        async getStaffsByEvectionNo() {
            this.availableTraveler = [];
            this.availableTravelerListForSelector = [];
            let [err, res] = await awaitWrap(consumer_department_getStaffsByEvectionNo({
                evectionNo: this.evectionNo,
                personType: 2,
                pageSize: 999
            }));
            if (err) {
                this.availableTraveler = [];
                this.form.roomAmount = 1;
                this.availableTraveler = [];
                return
            }
            this.availableTraveler = res.datas.list;
            this.form.roomAmount = 1;
            this.availableTraveler.forEach(item => {
                this.availableTravelerListForSelector.push({
                    userId: item.userId,
                    name: item.name,
                    phone: item.phone,
                    shareWith: item.shareWith,
                    certificateName: item.certificateName,
                    certificateValue: item.certificateValue,
                    certificateType: item.certificateType,
                    unSensitiveCertificateValue: item.unSensitiveCertificateValue,
                    unSensitivePhone: item.unSensitivePhone
                })
            })
        },
        //初始化选人弹框数据
        initSelectorData() {
            this.getTravelerMultipleSelectorData();
            this.getContactSingleSelectorData();
        },
        // 获取当前登陆用户信息
        async getCurUser() {
            let res = await get_user_info();
            this.curUser = res.datas;
        },
        //设置默认值
        setDefaultVal() {
            const moment = this.$moment();
            const curDate = moment.format('YYYY-MM-DD');
            if (this.isTodayMorning) {
                //当天凌晨入住
                let hour = 1;
                if (this.$moment().hour() > 1) {
                    hour = this.$moment().hour() + 1;
                }
                this.form.arrivalTime = `${hour}:00`;
                for (let i = hour; i < 7; i++) {
                    this.pickerOptions.push(i + ':00')
                }
            } else {
                if (curDate === this.hotelOrderInfo.checkInDate) {
                    let hour = 13;
                    if (this.$moment().hour() > 13 || (this.$moment().hour() === 13 && this.$moment().minute() > 0)) {
                        hour = this.$moment().hour() + 1;
                    }
                    this.form.arrivalTime = `${hour}:00`;
                    for (let i = hour; i < 25; i++) {
                        this.pickerOptions.push(i + ':00')
                    }
                } else {
                    this.form.arrivalTime = '13:00';
                    this.pickerOptions = [];
                    for (let i = 13; i < 25; i++) {
                        this.pickerOptions.push(i + ':00')
                    }
                }
            }

            this.form.linkman = this.curUser.realName;
            this.form.linkmanPhone = this.curUser.phone;
        },
        //初始化选择出行人弹框
        getTravelerMultipleSelectorData() {
            const __this = this;
            if (this.evectionDetailType === '001') {
                this.$refs.aBaseMultipleEntitySelector0301.init({
                    select_mode: `single`,

                    entity_define: {
                        property_name_for_id: `userId`,
                        property_name_for_name: `name`,
                        get_enable() {
                            return true;
                        },
                    },

                    table: {
                        column_list: [
                            {
                                prop: `userId`,
                                label: `id`,
                                width: `80`,
                            },
                            {
                                prop: `name`,
                                label: `姓名`,
                                width: `70`,
                            },
                            {
                                prop: `unSensitivePhone`,
                                label: `联系方式`,
                                width: ``,
                            },
                        ],
                    },

                    get_data_source() {
                        const all_entity_list = __this.availableTravelerListForSelector;
                        return Q.resolve({
                            all_entity_list,
                        });
                    },

                });
            } else {
                this.$refs.aTravelerSingleSelector.init({
                    select_mode: `single`,

                    entity_define: {
                        property_name_for_id: `userId`,
                        property_name_for_name: `name`,
                        get_enable() {
                            return true;
                        },
                    },

                    table: {
                        column_list: [
                            {
                                prop: `userId`,
                                label: `id`,
                                width: `80`,
                            },
                            {
                                prop: `name`,
                                label: `姓名`,
                                width: `70`,
                            },
                            {
                                prop: `unSensitivePhone`,
                                label: `联系方式`,
                                width: ``,
                            },
                        ],
                    },

                    get_data_source() {
                        const all_entity_list = __this.availableTravelerListForSelector;
                        return Q.resolve({
                            all_entity_list,
                        });
                    },

                });
            }
        },
        //初始化选择联系人弹框
        getContactSingleSelectorData() {
            this.$refs.aContactSingleSelector.init({
                title: '请选择联系人',
                businessType: 3,
                select_mode: "single"
            })
        },
        //返回
        back() {
            this.isShowBackDialog = true;
            Dialog.confirm({
                title: '温馨提示',
                message: '您的订单尚未填写完成，确认离开当前页面？',
                cancelButtonText: '离开',
                confirmButtonText: '继续预订',
                confirmButtonColor: '#ff7002',
            }).then(() => {
                this.isShowBackDialog = false;
            }).catch(() => {
                this.isShowBackDialog = false;
                this.$router.go(-1)
            });
        },
        //选择出行人
        chooseTraveler(room) {
            this.curRoomId = room.roomId;
            if (JSON.stringify(room.user) !== '{}') {
                this.aUserIdList = [room.user.userId];
            } else {
                this.aUserIdList.splice(0, 1);
            }
            if (this.evectionDetailType === '001') {
                this.$refs.aBaseMultipleEntitySelector0301.show();
            } else {
                this.$refs.aTravelerSingleSelector.show();
            }
        },
        //打开选择联系人popup
        openContactPopup() {
            this.$refs.aContactSingleSelector.show();
        },
        showPriceDetailPopup() {
            this.isShowPriceDetailPopup = !this.isShowPriceDetailPopup;
            this.priceDetailArrow = this.isShowPriceDetailPopup? 'arrow-down':'arrow-up'
        },
        showRoomNumPopup() {
            if(this.evectionInfo.shareWith === 1) {
                return;
            }
            this.isShowRoomNumPopup = true;
            this.$nextTick(()=>{
                let index = this.form.roomAmount ? this.form.roomAmount - 1 : 0;
                this.$refs.roomPicker.setIndexes([index]);
            })
        },
        onRoomNumConfirm(value, index) {
            this.isShowRoomNumPopup = false;
            this.form.roomAmount = index + 1;
        },
        onRoomNumCancel() {
            this.isShowRoomNumPopup = false;
        },
        showArrivalTime() {
            this.isShowArrivalTimePopup = true;
            let arrivalTime = '';
            if (this.form.arrivalTime) {
                arrivalTime = this.form.arrivalTime;
                this.$nextTick(()=>{
                    this.$refs.timePicker.setValues([arrivalTime]);
                })

            }
        },
        closeArrivalTime() {
            this.isShowArrivalTimePopup = false;
        },
        confirmArrivalTime(val) {
            this.form.arrivalTime = val;
            this.closeArrivalTime();
        },
        // 计算订单价格
        async getAllPrice() {
            let params = {
                evectionNo: this.evectionNo,
                headCounts: this.form.roomAmount,
                roomNum: this.form.roomAmount
            }
            let res = await consumer_admin_journey_calculateHotelPrice(params);
            let priceResult = res.datas;
            let payType = 0;
            if (priceResult.creditAmount <= 0) {
                payType = 2;
            } else if (priceResult.oneselfAmount <= 0) {
                payType = 1;
            } else {
                payType = 0;
            }
            this.priceResult = priceResult;
            this.payType = payType;
            this.hotelOrderInterims = this.priceResult.todHotelOrderInterims[0];
        },

        //关闭报错提示弹框
        onCloseErrDialog() {
            this.dialogError2Visible = false;
            this.$router.go(-1);
        },

        payTypeSet(val) {
            let text = '';
            switch (val) {
                case 0:
                    text = '总价';
                    break;
                case 1:
                    text = '企业支付';
                    break;
                case 2:
                    text = '个人支付';
                    break;
            }
            return text;
        },

        // 保存信息
        async saveInfo() {
            this.form.smsType = this.isSmsType ? 1 : 0;
            this.form.linkmanSms = this.isLinkmanSms ? 1 : 0;
            this.form.isBindAgreementCard = this.isBindAgreementCard ? 1 : 0;

            this.evectionParams.linkman = this.form.linkman;
            this.evectionParams.linkmanPhone = this.form.linkmanPhone;
            this.evectionParams.smsType = this.form.smsType;
            this.evectionParams.linkmanSms = this.form.linkmanSms;
            this.evectionParams.userList = this.form.userList;
            if (this.evectionParams.linkman === '' || this.evectionParams.linkmanPhone === '') {
                Toast.clear();
                Toast.fail('请填写联系人');
                return
            }

            let infoResult = await this.$refs['evectionReserveInfoInput'].getInfo();
            if (infoResult.valid) {
                this.evectionParams.reason = infoResult.evectionParams.reason;
                this.evectionParams.feeAffiliationName = infoResult.evectionParams.feeAffiliationName;
                this.evectionParams.feeAffiliationType = infoResult.evectionParams.feeAffiliationType;
                this.evectionParams.feeAffiliationId = infoResult.evectionParams.feeAffiliationId;
                this.evectionParams.partnerEctionId = infoResult.evectionParams.partnerEctionId;
                this.evectionParams.centerCode = infoResult.evectionParams.centerCode;
                this.evectionParams.projectCode = infoResult.evectionParams.projectCode;
                this.evectionParams.projectName = infoResult.evectionParams.projectName;
                this.evectionParams.companyId = infoResult.evectionParams.companyId;
            }

            this.evectionParams.evectionNo = this.evectionNo;
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(this.evectionParams))
            if (err) {
                return false
            }
            return res
        },
        //创建订单
        async createOrder(formInfo) {
            let params = {
                evectionNo: this.evectionNo,
                roomNum: this.form.roomAmount,
                latestArrivalTime: this.form.arrivalTime,
                hotelRemark: this.form.remarks,
                orderAmount: this.priceResult.sum,
                'reportConfiguration': formInfo['list'],
            };
            let [err, result] = await awaitWrap(consumer_t_od_hotel_order_interim_createOrderByOnBusiness(params));
            if (err) {
                let errorCode = err.data.code;
                this.errorText = err.data.msg;
                //为了返回列表页时，触发重新调接口获取列表
                cache_set({
                    key: 'hotelDetailOutTime',
                    value: '2000-01-01 00:00:00'
                })
                if (errorCode === '340001') {
                    this.errorText = '发生变价下单失败，请重新查询';
                    this.dialogErrorVisible = true;
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.returnToSearch()
                        }
                    }, 1000)
                } else {
                    this.dialogError2Visible = true
                }
                return false
            }
            this.orderNo = result.datas.orderNo;
            return result.datas;
        },
        returnToSearch() {
            if (!this.dialogErrorVisible) {
                return
            }
            this.dialogErrorVisible = false;
            clearInterval(this.returnSetInterval);
            this.$router.go(-1);
        },
        async checkForm() {
            let canSubmit = false;

            //必填-到店时间
            if (!this.form.arrivalTime) {
                Toast.fail('请选择到店时间');
                return canSubmit;
            }

            //必填-入住人
            let user = {};
            this.form.userList = [];
            this.form.roomList.forEach(item => {
                if (item.user.userId) {
                    user = {
                        userId: item.user.userId,
                        certificateType: item.user.certificateType
                    };
                    this.form.userList.push(user);
                }
            })
            if (!this.form.userList || this.form.userList.length !== this.form.roomList.length) {
                Dialog.alert({
                    title: '温馨提示',
                    message: '请先添加入住人',
                    confirmButtonColor: '#ff7002',
                }).then(() => {
                    return canSubmit;
                })
                return canSubmit;
            } else {
                let userList = _.uniq(_.pluck(this.form.userList, 'userId'));
                if (userList.length !== this.form.userList.length) {
                    Dialog.alert({
                        title: '温馨提示',
                        message: '入住人不可选择同一人',
                        confirmButtonColor: '#ff7002',
                    }).then(() => {
                        return canSubmit;
                    })
                    return canSubmit;
                }
            }

            //必填-联系人
            if (this.form.linkman === '' || this.form.linkmanPhone === '') {
                Toast.fail('请先填写联系人')
                return canSubmit;
            }

            //预定审批时，必填-成本归属
            /*if (this.businessRuleControl === 2) {
                let infoResult = await this.$refs['evectionReserveInfoInput'].getInfo();
                if (!infoResult.valid) {
                    Toast.fail('请先填写费用归属');
                    return canSubmit;
                }
            }*/

            //必填-出差事由
            /*if (!this.evectionParams.reason) {
                Toast.fail('请填写出差事由')
                return canSubmit;
            }*/

            canSubmit = true;
            return canSubmit;
        },

        //点击创建订单按钮
        async toCreateOrder() {
            if (this.isTodayMorning) {
                let curTimeList = this.$moment().toArray();
                if(curTimeList[3] >= 6) {
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '此房型暂无法预订，请更换房型预订',
                        confirmButtonText: '更换房型',
                        showCancelButton: false
                    }).then(() => {
                        this.$router.back();
                    });
                    return;
                }
            }

            //判断是否需要审批，如需审批则是否有审批流
            if (this.businessRuleControl === 2 && this.orderFreeApprove && !this.hasNodeList && this.$store.state.workflow === '2') {
                Toast('当前没有审批流，请联系管理员')
                return
            }

            //检查动态表单是否可提交
            let infoResult = await this.$refs['evectionReserveInfoInput'].getInfo();
            if (!infoResult.valid) {
                const listLength = infoResult.list.length;
                for (let i = 0; i < listLength; i++) {
                    if (!infoResult.list[i].parameterForm) {
                        const text = `请填写${infoResult.list[i].parameterText}`;
                        Toast(text);
                        break
                    }
                }
                return
            }

            //检查是否可提交
            let canSubmit = await this.checkForm();
            if (canSubmit) {
                let _this = this
                if (this.paymentPage === 1) {
                    // 判断是否弹出因公费用由企业账户支付的提示
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '因公出行订单费用将由企业账户支付',
                        confirmButtonText: '继续预定',
                        cancelButtonText: '取消预定',
                    }).then(() => {
                        // on confirm
                        _this.submit(infoResult);
                    }).catch(() => {
                        // on cancel
                    });
                } else {
                    // 不弹
                    _this.submit(infoResult);
                }
            }
        },

        async submit(formInfo) {
            this.submitting = true;
            this.$showLoading.show();

            //保存订单
            let saveResult = await this.saveInfo();
            if (!saveResult) {
                this.submitting = false;
                this.$showLoading.hide();
                Notify({type: 'warning', message: '保存订单信息失败，请重新选择'});
                return
            }

            //创建订单
            let createOrderResult = await this.createOrder(formInfo);
            if (!createOrderResult.isSuccess) {
                this.submitting = false;
                this.$showLoading.hide();
                Notify({type: 'warning', message: '创建订单失败，请重新选择'});
                return
            }

            this.submitting = false;
            this.$showLoading.hide();
            let _this = this;

            // businessRuleControl: 因公出差规则 1：出差单审批 2：预订审批
            // overproofStatus: 超标状态：0，未超标；1，超标
            // overprooRule: 酒店管控规则:1.允许预定,只做提示;3.不允许预定 10超标审批11.超额自付 只有超标会有这个字段
            // (出差单审批+已超标+超标审批)或者(预定审批+需要审批) 跳转至 提交成功页
            if ((this.businessRuleControl === 1 && createOrderResult.overproofStatus === 1 && createOrderResult.overprooRule === 10) ||
                (this.businessRuleControl === 2 && this.isFreeApprove)) {
                this.$router.replace({
                    name: 'h5-hotel-reserve-result',
                    query: {
                        orderNo: _this.orderNo
                    }
                })
            } else {
                const params = {
                    orderNo: _this.orderNo,
                    evectionNo: _this.evectionNo,
                    orderType: '3',
                    businessType: 3,
                };
                this.$router.replace({
                    name: 'h5-travel-payment',
                    query: params
                })
            }
        },

        keyback() {
            const __this = this;

            keyback_processor.addEventListener(function () {
                if (__this.isShowRoomNumPopup) {
                    __this.isShowRoomNumPopup = false;
                    return;
                }

                if (__this.isShowArrivalTimePopup) {
                    __this.isShowArrivalTimePopup = false;
                    return;
                }

                if (__this.isShowPriceDetailPopup) {
                    __this.isShowPriceDetailPopup = false;
                    return;
                }

                const aTravelerSingleSelector = __this.$refs.aTravelerSingleSelector;
                if (aTravelerSingleSelector) {
                    const aTravelerSingleSelectorShow = aTravelerSingleSelector.get_show();
                    if (aTravelerSingleSelectorShow) {
                        aTravelerSingleSelector.hide();
                        return;
                    }
                }

                const aBaseMultipleEntitySelector0301 = __this.$refs.aBaseMultipleEntitySelector0301;
                if (aBaseMultipleEntitySelector0301) {
                    const aBaseMultipleEntitySelector0301Show = aBaseMultipleEntitySelector0301.get_show();
                    if (aBaseMultipleEntitySelector0301Show) {
                        aBaseMultipleEntitySelector0301.hide();
                        return;
                    }
                }

                const aContactSingleSelector = __this.$refs.aContactSingleSelector;
                const aContactSingleSelectorShow = aContactSingleSelector.get_show();
                if (aContactSingleSelectorShow) {
                    aContactSingleSelector.hide();
                    return;
                }

                if (__this.isShowBackDialog) {
                    Dialog.close();
                    __this.isShowBackDialog = false;
                    return;
                }

                __this.back()
            });
        },
        //是否免审
        async checkFreeApprove() {
            let params = {
                businessType: 3,
                orderNo: this.orderNo
            }
            let res = await consumer_journey_orderFreeApprove(params);
            this.isFreeApprove = res.datas.orderFreeApprove;
        },

        //显示房型详情弹框
        showRoomDetailPopup() {
            this.$refs.roomDetailPopup.init({roomViewItem: this.priceResult.todHotelOrderInterims[0]});
        },

        getPayRemider() {
            consumer_payment_payReminder().then(res=>{
                this.paymentPage = res.datas.paymentPage;
            })
        },
        getFeeAffiliation() {
            if (this.evectionType === 2 && this.workTravelType === 1) {
                return
            }
            this.feeAffiliationShow = true
        },
    }
}
