// consumer_trains_passenger_passengerList
const __request = require(`./__request/__request_contentType_json`);
const consumer_trains_passenger_passengerList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/passenger/passengerList',
        data: pParameter,
        enable_error_alert: false // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_passenger_passengerList;
