import consumer_air_ticket_refundOrderDetail from '@/lib/data-service/haolv-default/consumer_air_ticket_refundOrderDetail'
import consumer_air_ticket_flightRules from '@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules'
import consumer_air_ticket_approvals from '@/lib/data-service/haolv-default/consumer_air_ticket_approvals'
import ChangClause from '@/page/travel-book/flight/component/changClause/index.vue'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import moment from "moment";
import {Toast} from "vant";

export default {
    data () {
        return {
            detail: {},
            activities: [],
            activitiesIndex: 0,
        }
    },
    components: {NavBar},
    created () {},
    mounted () {
        this.getOrderDetail()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {
        filterCabin(val) {
            if (val === '1') {
                return '头等舱'
            } else if (val === '2') {
                return '公务舱'
            } else if (val === '3') {
                return '经济舱'
            } else {
                return ''
            }
        },
        filterDay(val) {
            if (val) {
                return moment(val).format('YYYY-MM-DD HH:mm:ss')
            } else {
                return ''
            }
        },
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        // 获取订单详情
        getOrderDetail() {
            let orderNo = this.$route.query.orderNo
            /*let psgNoList = this.$route.query.psgNoList
            let psgNoArr = []
            if (psgNoList !== undefined && psgNoList !== '' && psgNoList !== null) {
                psgNoArr = psgNoList.split(',')
            }*/
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            })
            consumer_air_ticket_refundOrderDetail({ orderNo }).then(res => {
                console.log(res)
                Toast.clear()
                let detail = res.result
                this.detail = detail

                if (res.result.applyNo) {
                    this._approvals(res.result.applyNo)
                }
            }).catch(() => {
                Toast.clear()
            })
        },
        // 获取进度
        _approvals(applyNo) {
            consumer_air_ticket_approvals({ applyNo }).then(res => {
                let startTime = ''
                let nodeName = ''
                let currentIndex = 0
                let activities = res.recordList
                activities.forEach((item, index) => {
                    if (index === 0) {
                        startTime = item.gmtCreate
                        nodeName = item.nodeName
                    }
                    let status = item.approveStatus; //未开始，1.待我审，2.审核通过，3.驳回，4.已失效
                    let statusText = ''
                    switch(status) {
                        case 0:
                            statusText = '未开始'
                            break
                        case 1:
                            statusText = '待我审'
                            break
                        case 2:
                            statusText = '审核通过'
                            break
                        case 3:
                            statusText = '驳回'
                            break
                        case 4:
                            statusText = '已失效'
                            break
                        default:
                            statusText = ''
                    }
                    if (status !== 0) {
                        currentIndex++
                    }
                    item.content = item.deptName
                    item.timestamp = statusText
                    item.status = status
                })
                activities.unshift(
                    {
                        content: nodeName,
                        timestamp: `发起流程`,
                        status: 2,
                        gmtCreate: startTime
                    }
                )
                this.activities = activities
                this.activitiesIndex = currentIndex
            })
        },
        toRefundApply() {
            this.$router.push({
                name: 'h5-approve-detail',
                query: {
                    id: this.detail.applyId,
                    approveType: 5,
                    type: 'detail',
                }
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/