import consumer_flight_order_orderChange from "@/lib/data-service/haolv-default/consumer_flight_order_orderChange";
import consumer_flight_order_change from "@/lib/data-service/haolv-default/consumer_flight_order_change";
import consumer_air_ticket_flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
import consumer_journey_addOrUpdateOverproof from '@/lib/data-service/haolv-default/consumer_journey_addOrUpdateOverproof'
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import FlightSearch from '@/page/personal-center/order/components/flightSearch/1.0.2/index.vue'
import FlightItem from '@/page/personal-center/order/components/flightItem/index.vue'
import ChangClause from '@/page/travel-book/flight/component/changClause/1.0.0/index.vue'
import ChangeClausePopup from '@/page/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import {Dialog, Toast} from "vant";
import moment from "moment";
import { awaitWrap } from "@/page/travel-book/flight/common/unit";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import consumer_air_ticket_refChaReasonEnum from "@/lib/data-service/haolv-default/consumer_air_ticket_refChaReasonEnum";

export default {
    data () {
        return {
            orderNo: '',
            detail: {}, // 详情
            ossClient: new OssClient(),

            form: {
                chaReason: -1, // 改签类型
                chaReasonText: '',
                changeType: 1, // 变更类型
                changeTypeText: '',
                realReason: -1,
                realReasonText1: '',
                realReasonText: '',
                segmentList: [],
                url: '', //
                remark: '',
                manualStatus: 0, // 是否为手工单 0 否 1 是
            }, // 提交的数据
            showChangeTypePicker: false, // 变更类型弹窗
            showTypePicker: false, // 改签类型弹窗
            fileList: [], // 上传图片列表
            resultFileList: [], // 上传图片结果列表

            showRealReasonPicker: false,
            result: [], // 勾选中的航班
            needChangeResult: [], // 去重后需要改签的航班

            newTravelUser: [],

            changeForm: {}, // 改签去搜索航班的搜索条件
            changePopupShow: false, // 机票搜索弹窗
            changeEvectionType: 1,
            oldSegmentList: [], // 旧号码列表
            changeIndex: 0, // 当前要去更换的航班序号
            changeSegmentList: [], // 选中的航班的列表

            // ruleShow: false, // 退改规则弹窗
            ruleText: {},
            // ruleLoading: false,
            luggage: '',

            changeTipsPopup: false, // 改签说明弹窗
            canSubmit: true,

            refReasonEnum: [],
            ruleTextList: [],
            ruleLoading: false,
            ruleShow: false,
            ruleShowType: '001',
        }
    },
    components: {
        FlightSearch,
        FlightItem,
        ChangClause,
        NavBar,
        ChangeClausePopup
    },
    created () {},
    mounted () {
    },
    activated () {
        this.getRefReasonEnum();
        this.reactInfo();
        this.getDetail()
    },
    deactivated() {
        this.$showLoading.hide();
    },
    destroyed () {
        // Toast.clear()
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getRefReasonEnum() {
            consumer_air_ticket_refChaReasonEnum({'bizType': 2}).then(res=>{
                this.refReasonEnum = res.datas;
            })
        },
        reactInfo() {
            this.form = {
                chaReason: -1, // 改签类型
                chaReasonText: '',
                changeType: 1, // 变更类型
                changeTypeText: '',
                realReason: -1,
                realReasonText1: '',
                realReasonText: '',
                segmentList: [],
                url: '', //
                remark: '',
                manualStatus: 0, // 是否为手工单 0 否 1 是
            };
            this.fileList = []; // 上传图片列表
            this.resultFileList = [];
            this.result = []
            this.changeSegmentList = [];
            this.needChangeResult = [];
        },
        back() {
            this.$router.go(-1)
        },
        toggleReason(index) {
            this.$refs.reasonCheckboxes[index].toggle();
        },
        toggleReasonRadio(val, index) {
            console.log(val, index)
            this.form.realReason = val.value
        },
        onChangeTypeConfirm(val) {
            console.log(val)
            this.form.changeType = val.value
            this.form.changeTypeText = val.text
            this.showChangeTypePicker = false

            // 清除已选航班
            this.changeSegmentList = []
        },
        onRealReasonPickerConfirm(val) {
            this.form.realReason = val.value
            this.form.realReasonText1 = val.text
            this.showRealReasonPicker = false
        },
        onTypeConfirm(val) {
            this.form.chaReason = val.value
            this.form.chaReasonText = val.text
            this.showTypePicker = false
        },
        afterRead(file, indexType) {
            // 此时可以自行将文件上传至服务器
            console.log(file, indexType);
            let fileList = []
            console.log(typeof(file))
            if (!Array.isArray(file)) {
                fileList = [file]
            } else {
                fileList = file
            }

            fileList.forEach((value, index) => {
                this.uploadChange(value.file, index)
            })
        },
        uploadChange(file, index) {
            this.ossClient.multipartUpload({file: file}).then(res => {
                console.log(res)
                this.resultFileList.push(res.url)
            });
        },
        // 删除预览图片触发
        deleteImg(file, indexType) {
            console.log(file, indexType)
            this.resultFileList.splice(indexType.index, 1)
        },
        // 过滤时间
        formatterTime(val) {
            if (val) {
                return moment(val).format('YYYY-MM-DD HH:mm:ss')
            } else {
                return ''
            }
        },
        // 获取详情
        getDetail() {
            let orderNo = this.$route.query.orderNo;
            this.orderNo = orderNo
            this.$showLoading.show();
            consumer_flight_order_orderChange({ orderNo }).then(res => {
                this.$showLoading.hide();
                if (res.datas.changeRes) {
                    let detail = res.datas.changeRes
                    detail.creatType = parseInt(detail.creatType)
                    let hasChange = 0
                    if (detail.passengerList) {
                        detail.passengerList.forEach(value => {
                            value.changeSegmentList.forEach(value1 => {
                                hasChange++
                                value1.psgNo = value.psgNo
                                value1.psgName = value.psgName
                                value1.changeDate = value1.beginDate
                                value1.userId = value.userId ? parseInt(value.userId) : ''
                            })
                        })
                    }
                    this.detail = detail;
                    console.log(this.detail)
                    if (hasChange === 0) {
                        // 弹窗
                        Dialog.alert({
                            message: '该订单无可改签航班',
                        }).then(() => {
                            // on close
                            this.back()
                        });
                    }
                } else {
                    // 弹窗
                    Dialog.alert({
                        message: '该订单无可改签航班',
                    }).then(() => {
                        // on close
                        this.back()
                    });
                }
            }).catch(() => {
                this.$showLoading.hide();
                Dialog.alert({
                    message: '该订单无可改签航班',
                }).then(() => {
                    // on close
                    this.back()
                });
            });
        },
        // 上传大小超过500k触发
        onOversize(file) {
            console.log(file);
            Toast('文件大小不能超过 5MB');
        },
        // 监听
        changeCheckBoxGroup(val) {
            console.log('name', val)
            // 筛选出航程
            let flightNoArr = []
            let needChangeSegmentList = []
            val.forEach(segment => {
                let flightIndex = flightNoArr.indexOf(`${segment.flightNo}-${segment.beginDate}`)
                if (flightIndex === -1) {
                    flightNoArr.push(`${segment.flightNo}-${segment.beginDate}`)
                    segment.psgNameArr = [segment.psgName]
                    segment.userIdArr = [segment.userId]
                    segment.userInfoArr = [{
                        id: segment.userId,
                        ticketNo: segment.ticketNumber,
                        psgNo: segment.psgNo
                    }]
                    needChangeSegmentList.push(segment)
                } else {
                    needChangeSegmentList[flightIndex].psgNameArr.push(segment.psgName)
                    needChangeSegmentList[flightIndex].userIdArr.push(segment.userId)
                    needChangeSegmentList[flightIndex].userInfoArr.push({
                        id: segment.userId,
                        ticketNo: segment.ticketNumber,
                        psgNo: segment.psgNo
                    })
                }
            })
            // 清除已选航班
            this.changeSegmentList = [];
            this.needChangeResult = needChangeSegmentList
            console.log(this.needChangeResult)

        },
        toggle(pIndex, sIndex, passenger, segment) {
            let boxName = `checkboxes${pIndex}${sIndex}`
            this.$refs[boxName][0].toggle();
        },
        // 去换航班
        toChangeFlight(segment, index) {
            console.log(segment)
            let obj = {
                cabinRank: segment.cabinRank,
                fromStationCode: segment.scityCode || segment.sairportCode,
                toStationCode: segment.ecityCode || segment.eairportCode,
                startDate: segment.changeDate,
                airlineName: segment.airlineName, // 航空公司名称
                airLineCode: segment.airlineCode, // 航空公司二字码
                segmentTypeText: segment.segmentTypeText,
                startFlightParams: {
                    name: segment.scityName,
                    code: segment.scityCode || segment.sairportCode
                },
                arriveFlightParams: {
                    name: segment.ecityName,
                    code: segment.ecityCode || segment.eairportCode
                },
                flightNo: segment.flightNo,
                segmentNo: segment.segmentNo
            }
            /*if (this.form.changeType === 2) {
                obj.flightNo = segment.flightNo
            }*/
            this.changeForm = obj
            this.oldSegmentList[index] = segment.segmentNo
            this.changeIndex = index
            this.newTravelUser = segment.userIdArr
            console.log('this.newTravelUser',this.newTravelUser);
            this.changePopupShow = true
        },
        // 从子组件获取选择的航班的反馈
        getSegment(val) {
            console.log(val);
            let res = val.params.rqData.segmentList[0];
            res.overproofApplyList = val.violationResults
            res.segmentNo = this.oldSegmentList[this.changeIndex]

            let userInfoArr = this.needChangeResult[this.changeIndex].userInfoArr
            let violationResults = val.violationResults
            let ticketNumberList = []
            let passengerNoList = []
            if (violationResults.length > 0) {
                violationResults.forEach(value => {
                    userInfoArr.forEach(value1 => {
                        if (value.userId === value1.id) {
                            ticketNumberList.push(value1.ticketNo)
                            passengerNoList.push(value1.psgNo)
                        }
                    })
                })
            }
            res.ticketNumberList = ticketNumberList
            res.passengerNoList = passengerNoList
            res.changePrice = res.settlement
            /*let segment = {
                arrDate: res.arrDate,
                arrTime: res.arrTime,
                cabinCode: res.cabinCode,
                depDate: res.depDate,
                depTime: res.depTime,
                flightNo: res.flightNo,
                overproofApplyList: val.violationResults,
                segmentNo: this.oldSegmentList[this.changeIndex]
            }*/
            this.$set(this.changeSegmentList, this.changeIndex, res)

            console.log('changeSegmentList', this.changeSegmentList)
        },
        // 获取退改信息
        getRuleBack(val) {
            this.ruleShow = true
            this.ruleLoading = true
            this.ruleText = {}
            let params = {
                rqData: {
                    cabinCode: val.cabin || val.cabinCode,
                    flightKey: val.flightKey,
                    flightNo: val.flightNo,
                    policyId: val.policyId
                }
            }
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleText = res.datas.rsData
            }).finally(() => {
                this.ruleLoading = false
            })
        },
        // 关闭退改信息
        closeRuleShow() {
            this.ruleShow = false
        },
        // 提交
        async submit() {
            if (this.form.realReason === -1) {
                Toast({
                    message: '请选择原因',
                    position: 'bottom',
                });
                return
            }
            if (this.changeSegmentList.length === 0) {
                Toast({
                    message: '请选择航班',
                    position: 'bottom',
                });
                return
            }
            if (this.needChangeResult.length > 1) {
                let segmentTypeList = [];
                this.needChangeResult.forEach(value => {
                    if (segmentTypeList.indexOf(value.segmentType) === -1) {
                        segmentTypeList.push(value.segmentType)
                    }
                })
                Toast({
                    message: segmentTypeList.length > 1 ? '去程和回程不能同时选择' : '航班号或舱位不一致，请分别提交改签',
                    position: 'bottom',
                });
                return
            }
            /*if (this.form.realReason === 4 && this.form.realReasonText === '') {
                Toast({
                    message: '请输入改签原因',
                    position: 'bottom',
                });
                return
            }*/
            // 非自愿改签需要传附件
            if (this.form.chaReason === 2 && this.resultFileList.length === 0) {
                Toast({
                    message: '请上传证明',
                    position: 'bottom',
                });
                return
            }

            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false;

            let ticketNumberList = []
            let passengerNoList = []
            let adultTicketNo = []
            this.result.forEach(value => {
                ticketNumberList.push(value.ticketNumber)
                if (value.aduTicketNumber) {
                    adultTicketNo.push(value.aduTicketNumber)
                }
                if (passengerNoList.indexOf(value.psgNo) === -1) {
                    passengerNoList.push(value.psgNo)
                }
            })
            let overproofApplyList = []
            this.changeSegmentList.forEach(value => {
                let warningList = value.overproofApplyList
                warningList.forEach(value2 => {
                    let item = {
                        businessType: value2.businessType,
                        controlRule: value2.controlRules,
                        orderNo: value2.orderNo,
                        reason: value2.reason,
                        violationOfReason: value2.violationOfReason,
                        reserveContent: value2.violationOfReason,
                        standard: value2.standard,
                        seatName: value2.seatName,
                        standardContent: value2.seatName,
                        userId: value2.userId,
                        userName: value2.userRealName,
                        userRealName: value2.userRealName,
                        overproofType: 2
                    }
                    overproofApplyList.push(item)
                })
            })

            let checkParams = {
                evectionNo: this.detail.evectionNo,
                overproofApplyList
            }
            let [resultErr, result] = await awaitWrap(consumer_journey_addOrUpdateOverproof(checkParams))
            if (resultErr) {
                return
            }

            let data = {
                ticketNumberList: ticketNumberList,
                adultTicketNo: adultTicketNo.join(','),
                passengerNoList: passengerNoList,
                orderNo: this.$route.query.orderNo,
                evectionNo: this.detail.evectionNo,
                segmentList: this.changeSegmentList
            }
            let form = Object.assign({}, this.form, data)
            if (form.chaReason === 2) {
                form.url = this.resultFileList.join(',');
            }
            // Toast.loading({
            //     message: '提交中...',
            //     forbidClick: true,
            //     duration: 0
            // })
            console.log(form);
            //return
            this.$showLoading.show();
            consumer_flight_order_change(form).then(res => {
                console.log(res);
                this.canSubmit = true;
                // Toast.clear()
                this.$showLoading.hide();
                Toast.success('改签成功');
                this.$router.replace({
                    name: 'h5-flight-order-list',
                    query: {
                        type: '2'
                    }
                })
            }, error => {
                this.canSubmit = true;
                // Toast.clear()
                this.$showLoading.hide();
            }).catch(() => {
                this.canSubmit = true;
                // Toast.clear()
                this.$showLoading.hide();
            })
        },
        showChangeTipsPopup() {
            this.changeTipsPopup = true
        },
        closeChangeTipsPopup() {
            this.changeTipsPopup = false
        },

        submitReason(val) {
            console.log(val);
            this.form.chaReason = val.chaReason;
            this.form.chaReasonText = val.chaReason === 1 ? '自愿改签' : '非自愿改签';
            this.form.realReason = val.code
            this.form.realReasonText = val.msg
            this.form.realReasonText1 = val.msg + '（'+ (val.chaReason === 1 ? '自愿改签' : '非自愿改签') +'）';
            this.showRealReasonPicker = false;
            console.log(this.form);
        },

        backRule() {
            this.ruleTextList = [];
            this.ruleShow = true;
            this.ruleLoading = true;
            let segmentNoList = [];
            let segmentApiList = [];
            this.detail.passengerList.forEach(value=>{
                value.changeSegmentList.forEach(value1 => {
                    if (segmentNoList.indexOf(value1.segmentNo) == -1) {
                        segmentNoList.push(value1.segmentNo);
                        segmentApiList.push(consumer_air_ticket_flightRules({
                            segmentNo: value1.segmentNo,
                        }))
                    }
                })
            })
            Promise.all(segmentApiList).then(res=>{
                let ruleTextList = [];
                res.forEach(value => {
                    ruleTextList.push(value.datas.rsData);
                })
                this.ruleTextList = ruleTextList;
                this.ruleLoading = false;
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
