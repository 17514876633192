import ColorIcon from "@/component/color-icon/index.vue";
import {hideTrainOpenCompanyIdList} from "@/lib/ohter/index.js";
import moment from "moment";
export default {
    data() {
        return {
            hideTrainOpenCompanyIdList: hideTrainOpenCompanyIdList,
            companyId: '',
        }
    },
    props: {
        index: { // index of current item
            type: Number
        },
        source: { // here is: {uid: 'unique_1', text: 'abc'}
            type: Object,
            default () {
                return {}
            }
        },
        toJourney: {
            type: Function,
        },
        submit: {
            type: Function
        },
        isShowUseCarBtn: {
            type: Boolean,
            default: false
        }
    },
    components: {ColorIcon},
    created() {
    },
    async mounted() {
        await this.$store.state.userInfoDefer.promise;
        this.companyId = this.$store.state.userInfo.companyId;
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterTime(val) {
            if (val) {
                return moment(val).format('MM月DD日')
            } else {
                return ''
            }
        }
    },
    methods: {
        setCarIcon(val) {
            let hrefName = '';
            switch (val) {
                case 1:
                    hrefName = '#haolv-enterprise-app-iconpolicy_trave_vehicles';
                    break
                case 2:
                    hrefName = '#haolv-enterprise-app-iconpolicy_overtime_car';
                    break
                case 3:
                    hrefName = '#haolv-enterprise-app-iconpolicy_shuttle_service';
                    break
                case 4:
                    hrefName = '#haolv-enterprise-app-iconpolicy_car_hailing';
                    break
                case 5:
                    hrefName = '#haolv-enterprise-app-iconpolicy_fixed_point';
                    break
                case 6:
                    hrefName = '#haolv-enterprise-app-iconpolicy_field_vehicles';
                    break
                default:
                    hrefName = '#haolv-enterprise-app-iconuseCar_round_30';
            };
            return hrefName;
        },
    }
}
