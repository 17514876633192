import consumer_admin_flight_generate_certificateType
    from "@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType.js";
import consumer_user_signUser from "@/lib/data-service/haolv-default/consumer_user_signUser.js";
import consumer_user_updateUser from "@/lib/data-service/haolv-default/consumer_user_updateUser.js";
import consumer_country_queryCountryTwoWord from '@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";

export default {
    data() {
        var validateRealID = () => {
            const idCard = this.form.certificates.find(item => {
                return item.certificateType === 1;
            })
            let val = idCard.certificateValue;
            if (val.length != 0) {
                let patternOne = /^[1-9][0-9]/;
                let patternTwo = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
                let patternThree = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                let provs = {
                    11: "北京",
                    12: "天津",
                    13: "河北",
                    14: "山西",
                    15: "内蒙古",
                    21: "辽宁",
                    22: "吉林",
                    23: "黑龙江 ",
                    31: "上海",
                    32: "江苏",
                    33: "浙江",
                    34: "安徽",
                    35: "福建",
                    36: "江西",
                    37: "山东",
                    41: "河南",
                    42: "湖北 ",
                    43: "湖南",
                    44: "广东",
                    45: "广西",
                    46: "海南",
                    50: "重庆",
                    51: "四川",
                    52: "贵州",
                    53: "云南",
                    54: "西藏 ",
                    61: "陕西",
                    62: "甘肃",
                    63: "青海",
                    64: "宁夏",
                    65: "新疆",
                    71: "台湾",
                    81: "香港",
                    82: "澳门"
                };
                let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
                //身份证校验
                if (val.length !== 18) {
                    return false
                }
                if (!patternOne.test(val.substring(0, 2))) {
                    return false
                }
                if (provs[val.substring(0, 2)] == undefined) {
                    return false
                }
                if (!patternTwo.test(val.substring(6, 14))) {
                    return false
                } else {
                    let year = val.substring(6, 10);
                    let month = val.substring(10, 12);
                    let date = val.substring(12, 14);
                    let dateTime = new Date();
                    let date2 = new Date(year + "-" + month + "-" + date);

                    if (dateTime <= date2) {
                        return false
                    }
                    if (!(date2 && date2.getMonth() == (parseInt(month) - 1))) {
                        return false
                    }
                }
                if (!patternThree.test(val)) {
                    return false
                } else {
                    let sum = 0;
                    let code = val.substring(17);
                    for (let i = 0; i <= 16; i++) {
                        sum = sum + val[i] * factor[i];
                    }
                    if (parity[sum % 11] != code) {
                        return false
                    }
                }
                return true
            } else {
                return true
            }
            return true
        };

        return {
            userId: '',//用户id
            realName: '',//用户中文名
            englishSurnames: '',//英文姓
            englishName: '',//英文名
            birthday: new Date(),//出生日期
            countryCode: 'CN',//国籍二字码
            countryName: '中国CN',//国籍名
            expireDate: new Date(),//有效期限
            isShowGenderPopup: false,//是否显示选择性别弹框
            isShowBirthdayPopup: false,//是否显示选择出生日期弹框
            isShowCountryPopup: false,//是否显示选择国籍弹框
            isShowCardTypePopup: false,//是否显示证件类型弹框
            isShowIssCountryPopup: false,//是否显示发证国弹框
            isShowExpireDatePopup: false,//是否显示证件有效期
            currCertificateIndex: 0,//当前编辑的证件序号
            form: {
                userId: "",//用户id
                realName: "",//中文名
                englishSurnames: "",//英文姓
                englishName: "",// 英文名
                staffSex: "",//性别代码
                staffSexText: '',//性别名称
                countryCode: 'CN',//国籍码
                countryName: "中国",//国籍名称
                certificates: [],//证件
            },
            staffSexList: [
                {name: 1, text: "男"},
                {name: 2, text: "女"},
            ],//性别枚举list
            minDate: this.$moment().add(-100, 'years')._d,//出生日期可选的最小日期
            maxDate: this.$moment()._d,//出生日期可选的最大日期
            minExpireDate: this.$moment().add(-1, 'day')._d,//证件有效期可选的最小日期
            maxExpireDate: this.$moment().add(+100, 'years')._d,//证件有效期可选的最小日期
            countryList: [],//国家枚举list
            cardTypeList: [],//证件类型枚举list
            newCardTypeId: '',//新证件id
            newCardTypeName: '',//新证件名称
            rules: {
                realName: [
                    {required: true, message: "请输入姓名"},
                    {validator: this.validateRealName, message: "请输入正确姓名"},
                ],
                staffSex: [
                    {required: true, message: "请选择性别"},
                ],
                birthday: [
                    {required: true, message: "请选择出生日期"},
                ],
                countryName: [
                    {required: true, message: "请选择国籍"},
                ],
                idCardNum: [
                    {required: true, message: "请输入证件号码"},
                    {validator: validateRealID, message: "请输入正确证件号码"},
                ],
                certificate: [
                    {required: true, message: "请输入正确证件号码"},
                ],
                issCountryCode: [
                    {required: true, message: "请选择发证国家"},
                ],
                passengerExpireDate: [
                    {required: true, message: "请选择证件有效期"},
                ],
            },
        };
    },
    methods: {
        //初始化
        init() {
            this.userId = this.$route.query.id;
            this.getDetail();
        },

        //获取详情
        getDetail() {
            this.$showLoading.show();
            consumer_user_signUser({id: this.userId})
                .then((res) => {
                    const {
                        userId,//用户id
                        realName,//中文名
                        englishSurnames,//英文姓
                        englishName,// 英文名
                        staffSex,//性别代码
                        birthday,//出生日期
                        certificates,
                    } = res.datas;

                    certificates.forEach(certificate => {
                        certificate.passengerExpireDate = certificate.passengerExpireDate ? this.$moment(certificate.passengerExpireDate).format('YYYY-MM-DD') : '';
                        this.cardTypeList.forEach(item => {
                            if (certificate.certificateType === item.code) {
                                item.isSelected = true;
                                certificate.certificateName = item.msg;
                            }
                            certificate.certificateHideValue = this.getHideNum(certificate.certificateType, certificate.certificateValue);
                        })
                        if (!certificate.issCountryName) {
                            this.countryList.forEach(item => {
                                if (item.code === certificate.issCountryCode) {
                                    certificate.issCountryName = item.cnName + item.code;
                                }
                            })
                        }
                    })

                    const form = this.form;
                    form.userId = userId;
                    form.realName = realName;
                    form.englishSurnames = englishSurnames;
                    form.englishName = englishName;
                    form.staffSex = staffSex;
                    form.staffSexText = staffSex === 1 ? '男' : '女';
                    form.birthday = birthday;
                    form.certificates = certificates;
                    form.countryCode = certificates[0].countryCode;
                    this.countryList.forEach(item => {
                        if (item.code === form.countryCode) {
                            form.countryCode = item.code;
                            form.countryName = item.cnName;

                            this.countryCode = item.code;
                            this.countryName = item.cnName + item.code;
                        }
                    })

                    this.realName = realName;
                    this.englishSurnames = englishSurnames;
                    this.englishName = englishName;
                    this.birthday = new Date(birthday);

                    this.$showLoading.hide();
                }).catch((err) => {
                this.$showLoading.hide();
            });
        },

        //获取国籍枚举值
        queryCountryTwoWord() {
            consumer_country_queryCountryTwoWord().then(res => {
                this.countryList = res.datas
            })
        },

        //获取证件枚举值
        async getCertificateType() {
            let res = await consumer_admin_flight_generate_certificateType();
            this.cardTypeList = res.datas;
        },

        //显示选择性别弹框
        onShowGenderPopup() {
            this.isShowGenderPopup = true;
            this.$nextTick(() => {
                this.$refs.genderPicker.setIndexes([this.form.staffSex - 1])
            })
        },

        //选择性别
        confirmGender(val) {
            this.isShowGenderPopup = false;
            this.form.staffSex = val.name;
            this.form.staffSexText = val.text;
        },

        //显示选择出生日期弹框
        onShowBirthdatePopup() {
            this.isShowBirthdayPopup = true;
            this.birthday = new Date(this.form.birthday)
        },

        //修改出生日期
        changeBirthday(val) {
            if (val) {
                this.form.birthday = this.$moment(val).format("YYYY-MM-DD");
                this.birthday = new Date(this.form.birthday)
            }
            this.isShowBirthdayPopup = false;
        },

        //显示选择国籍弹框
        onShowCountryPopup() {
            this.isShowCountryPopup = true;
            this.$nextTick(() => {
                let activeIndex = 0
                this.countryList.find((country, index) => {
                    if (this.form.countryName === country.cnName) {
                        activeIndex = index;
                        return country;
                    }
                })
                this.$refs.countryPicker.setIndexes([activeIndex])
            })
        },

        //选择国籍
        onCountryConfirm(val) {
            this.countryCode = val.code;
            this.countryName = val.cnName + val.code;
            this.form.countryCode = val.code;
            this.form.countryName = val.cnName;
            this.isShowCountryPopup = false;
        },

        //添加证件
        addCertificate() {
            this.newCardTypeId = '';
            this.newCardTypeName = '';
            this.isShowCardTypePopup = true;
        },

        //选择证件类型
        onUseCardTypeConfirm(val) {
            let selectedItem = this.form.certificates.find(item => {
                return val.code === item.certificateType;
            });
            if (selectedItem) {
                return;
            }

            this.newCardTypeId = val.code;
            this.newCardTypeName = val.msg;
            let certificate = {
                certificateType: val.code,
                certificateName: val.msg,
                certificateValue: "",
                issCountryCode: 'CN',
                issCountryName: '中国CN',
                passengerExpireDate: '',
            }
            this.form.certificates.push(certificate)
            setTimeout(() => {
                this.isShowCardTypePopup = false;
            }, 300)
            setTimeout(() => {
                val.isSelected = true;
            }, 800)
        },

        //删除证件
        deleteCertificate(val, index) {
            this.form.certificates.splice(index, 1);
            this.cardTypeList.forEach(item => {
                if (item.code === val.certificateType) {
                    item.isSelected = false;
                }
            })
            if(!this.form.certificates || this.form.certificates.length === 0) {
                this.countryCode = 'CN'
                this.countryName = '中国CN'

                this.form.countryCode = 'CN'
                this.form.countryName = '中国'
            }
        },

        //显示发证国弹框
        onShowIssCountry(certificate, index) {
            if ([12].indexOf(certificate.certificateType) > -1) {
                return
            }
            this.isShowIssCountryPopup = true;
            this.currCertificateIndex = index;
            this.$nextTick(() => {
                if (certificate.certificateType === 1 || !certificate.issCountryCode) {
                    return;
                }
                const code = certificate.issCountryCode;
                let activeIndex = 0
                let item = {};
                for (let i = 0, l = this.countryList.length; i < l; i++) {
                    const active = this.countryList[i];
                    if (code === active.code) {
                        item = active;
                        activeIndex = i
                    }
                }
                this.$refs.issPicker.setIndexes([activeIndex])
            })
        },

        //选择发证国
        onIssCountryConfirm(val) {
            let index = this.currCertificateIndex;
            this.form.certificates[index].issCountryCode = val.code;
            this.form.certificates[index].issCountryName = val.cnName + val.code;
            this.isShowIssCountryPopup = false;
        },

        //显示选择证件有效期弹框
        onShowExpireDatePopup(certificate, index) {
            this.isShowExpireDatePopup = true;
            this.currCertificateIndex = index;
            if (certificate.passengerExpireDate) {
                this.expireDate = new Date(certificate.passengerExpireDate)
            } else {
                this.expireDate = new Date()
            }
        },

        //选择证件有效期
        changeExpireDate(val) {
            let index = this.currCertificateIndex;
            this.form.certificates[index].passengerExpireDate = this.$moment(val).format("YYYY-MM-DD");
            this.isShowExpireDatePopup = false;
        },

        //证件号码脱敏
        getHideNum(type, value) {
            if (value != '') {
                if (type == 1) {
                    //身份证
                    // let text = value.slice(0, 4) + '********' + value.slice(15);
                    let text=value.replace(/(\d{4})(\d+)(\d{3})/,function(x,y,z,p){
                        var i="";
                        while(i.length<z.length){i+="*"}
                        return y+i+p
                    })
                    return text
                } else {
                    let text=value.replace(/(\d{2})(\d+)(\d{2})/,function(x,y,z,p){
                        var i="";
                        while(i.length<z.length){i+="*"}
                        return y+i+p
                    })
                    return text
                }
                /*if (type == 2) {
                    //护照
                    let text = value.slice(0, parseInt(value.length / 2) - 2) + '****' + value.slice(parseInt(value.length / 2) + 2);
                    return text
                }
                if (type == 3 || type == 8 || type == 9) {
                    //港澳通行证、台湾通行证、户口本
                    let text = value.slice(0, 2) + '****' + value.slice(parseInt(value.length) - 2);
                    return text
                }
                if (type == 4) {
                    //回乡证
                    let text = value.slice(0, parseInt(value.length / 2) - 2) + '****' + value.slice(parseInt(value.length / 2) + 2);
                    return text
                }
                if (type == 5) {
                    //台胞证
                    let text = value.slice(0, parseInt(value.length / 2) - 4) + '********' + value.slice(parseInt(value.length / 2) + 4);
                    return text
                }
                if (type == 6) {
                    //军官证
                    let text = value.slice(0, parseInt(value.length / 2) - 1) + '***' + value.slice(parseInt(value.length / 2) + 2);
                    return text
                }
                if (type == 7) {
                    //香港身份证
                    let text = value.slice(0, parseInt(value.length / 2) - 4) + '********' + value.slice(parseInt(value.length / 2) + 4);
                    return text
                }
                if (type == 11) {
                    //澳门身份证
                    let text = value.slice(0, parseInt(value.length / 2) - 4) + '********' + value.slice(parseInt(value.length / 2) + 4);
                    return text
                }
                if (type == 10) {
                    //其他
                    let text = value.slice(0, parseInt(value.length / 2) - 4) + '********' + value.slice(parseInt(value.length / 2) + 4);
                    return text
                }
                if (type == 12) {
                    //身份证
                    let text = value.slice(0, 5) + '********' + value.slice(13);
                    return text
                }*/
            } else {
                return ''
            }
        },

        //格式化日期选择控件里的内容
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            } else if (type === 'day') {
                return `${val}日`;
            }
            return val;
        },

        //当前获得焦点的元素，失去焦点，阻止移动端软键盘弹出
        focusFun() {
            document.activeElement.blur();
        },

        //保存
        commit() {
            if (this.form.certificates.length === 0) {
                this.$Toast.fail('请添加至少一项证件信息');
                return;
            }
            this.form.certificates.forEach(item => {
                if (item.certificateHideValue && item.certificateHideValue.indexOf('*') === -1) {
                    item.certificateValue = item.certificateHideValue;
                }
                item.countryCode = this.form.countryCode;
            })
            this.$refs.form
                .validate()
                .then((res) => {
                    this.$showLoading.show();
                    this.form.realName = this.realName;
                    this.form.englishSurnames = this.englishSurnames;
                    this.form.englishName = this.englishName;
                    this.form.birthday = this.$moment(this.birthday).format("YYYY-MM-DD");
                    let params = {...this.form};
                    params.certificates.forEach(item => {
                        if (item.certificateHideValue && item.certificateHideValue.indexOf('*') === -1) {
                            item.certificateValue = item.certificateHideValue;
                        }
                        item.countryCode = params.countryCode;
                        item.countryName = params.countryName;
                    })
                    consumer_user_updateUser(params)
                        .then((res) => {
                            this.$showLoading.hide();
                            this.$Toast.success("修改成功");
                            // 判断是否在flutter app里
                            if (window.getFlutter == true) {
                                // 如果在，则向flutter app发送信息
                                try {
                                    window.flutter_inappwebview
                                        //可以传递你所需要的任意类型数据，数组、对象等
                                        .callHandler("backHandler", {'type': 'close'})
                                        .then((res)=>{
                                            console.log("res==flutter给html的数据", res);
                                        })
                                } catch (e) {}
                                return
                            }
                            this.$router.back();
                        })
                        .catch((err) => {
                            this.$showLoading.hide();
                        });
                })
                .catch((err) => {
                });
        },
        validateRealName() {
            let hasMustChinaCertificate = false;
            this.form.certificates.forEach(value1 => {
                if ([1, 12].indexOf(value1.certificateType) > -1) {
                    hasMustChinaCertificate = true;
                }
            });
            const regExp = hasMustChinaCertificate ? /^[\u4e00-\u9fa5]+$/ : /^[\u0391-\uFFE5A-Za-z]+$/
            if (regExp.test(this.realName)) {
                return true;
            } else {
                return false;
            }
        }
    },
    mounted() {
        this.getCertificateType();
        this.queryCountryTwoWord();
    },
    activated() {
        this.init();

        let _this = this;
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                _this.$refs['saveBox'].style.paddingBottom = `${api.safeArea.bottom + 8}px`;
            }
        });
    },
};
