import {Notify, Toast} from "vant";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import AccountProcessStep0001
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process-step-0001/1.1.0/index.vue'
import AccountProcessStepRegister
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process-step-register/1.0.0/index.vue'
import AccountProcessStepCode
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process-step-code/1.0.0/index.vue'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";

export default {
    data() {
        return {
            title: '12306账号授权',
            failDialogVisible: false,
            showRegister: false,
            showCode: false,
            flutterSwitch: false,
        }
    },
    components: {
        NavBar,
        AccountProcessStep0001,
        AccountProcessStepRegister,
        AccountProcessStepCode
    },
    mounted() {
        const _this = this;
        this.flutterSwitch = window.getFlutter;
        /*fixStatusBar({
          type: `000`,
          handler({default_handler, api}) {
            // document.querySelector('#navBar').style.paddingTop = `${api.safeArea.top}px`;
            _this.$refs['navBar'].$el.style.paddingTop = `${api.safeArea.top}px`;
            _this.$refs['footerSubmit'].style.paddingBottom = `${api.safeArea.bottom}px`;
            _this.footerSubmitPaddingBottom = api.safeArea.bottom
          }
        });*/
        keyback_processor.addEventListener(() => {
            if (this.showCode) {
                this.showCode = false;
                return
            }
            if (this.showRegister) {
                this.showRegister = false;
                return
            }
            if (this.failDialogVisible) {
                this.failDialogVisible = false;
                return
            }
            this.beforeBack()
        });
        this.$refs.accountProcessStep0001.init()
    },
    deactivated() {
    },
    destroyed() {
        keyback_processor.removeEventListener();
    },
    methods: {
        loginResult(trainAccount) {
            switch (trainAccount.accountStatus) {
                case 1:
                    Toast.success(trainAccount.accountStatusText);
                    this.back();
                    break;
                case 2:
                    this.$router.replace({
                        name: 'h5-train-account-check'
                    });
                    break;
                case 3:
                    Toast.fail(trainAccount.accountStatusText);
                    break;
            }
        },
        failSubmit() {
            this.failDialogVisible = false;
            this.back();
        },
        beforeBack() {
            this.failDialogVisible = true;
        },
        back() {
            this.flutterSwitch = window.getFlutter;
            if (this.flutterSwitch == true) {
                // 如果在，则向flutter app发送信息
                try {
                    window.flutter_inappwebview
                    //可以传递你所需要的任意类型数据，数组、对象等
                      .callHandler("backHandler", {'type': 'close'})
                      .then((res)=>{
                        console.log("res==flutter给html的数据", res);
                      })
                } catch (e) {}
                return
            }
            this.$router.go(-1);
        },
        toRegister() {
            this.showRegister = true;
            this.$nextTick(() => {
                this.$refs['accountProcessStepRegister'].init()
            })
        },
        _openedResult(elementName) {
            const api = global.api;
            if (api) {
                api.setWinAttr({
                    softInputMode: 'pan'
                });
            }
            const _this = this;
            fixStatusBar({
                handler({default_handler, api}) {
                    const bodyWidth = document.body.clientWidth;
                    const topElement = _this.$refs[elementName].$el;
                    let orrPaddingTop = topElement.style.paddingTop;
                    if (bodyWidth >= 414) {
                        orrPaddingTop = orrPaddingTop * 2
                    }
                    let currentPaddingTop = (orrPaddingTop || 0) / (bodyWidth / 100);
                    const addPaddingTop = (api.safeArea.top) / (bodyWidth / 100);
                    topElement.style.paddingTop = (addPaddingTop + currentPaddingTop) + 'vw';
                }
            })
        },
        _registerBack() {
            this.showRegister = false;
        },
        _registerToCode(val) {
            this.showCode = true;
            this.$nextTick(() => {
                this.$refs['accountProcessStepCode'].init(val.form)
            })
        },
        _codeBack() {
            this.showCode = false;
        },
        _registerResult(val) {
            if (val.result) {
                this.showCode = false;
                this.showRegister = false;
                Toast.success('注册成功');
                this._closeSoftInputMode();
                this.back();
            } else {
                this.showCode = false;
            }
        },
        _closeSoftInputMode() {
            const api = global.api;
            if (api) {
                api.setWinAttr({
                    softInputMode: 'auto'
                });
            }
        },
    },
};
