const Q = require('q');
const to_int = require('nllib/src/convert/to_int');
const __request = require(`./__request`);

const api_apicloud_push_message = function (pParameter) {
    if (!pParameter) pParameter = {};
    const title = pParameter.title || ``;
    const content = pParameter.content || ``;
    const type = to_int(pParameter.type, 1);
    const platform = pParameter.platform || 0;
    const userIds = pParameter.userIds || ``;

    return Q.when()
        .then(function () {
            return __request({
                method: 'post',
                url: '/api/apicloud/push_message',
                data: {
                    title,
                    content,
                    type,
                    platform,
                    userIds,
                },
            });
        })
        .then(function (data) {
            if (!data) {
                const msg = `api_apicloud_push_message parameter error, code=000`;
                console.error(msg);
                console.trace();
                return Q.reject({msg: msg});
            }
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;

};

module.exports = api_apicloud_push_message;
