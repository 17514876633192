import getMyJourneyList from '@/lib/data-service/haolv-default/consumer_journey_myJourneyList'
// 列表为空展示组件
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";
// 彩色icon组件
import ColorIcon from "@/component/color-icon/index.vue";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
//头部切换我的出差和我的行程
import TravelHeader from '@/component/travel-header/1.0.1/index.vue';
import { Toast } from "vant"
import MyEvection from "@/page/evection/evection-order/office-evection/4.0.0/index.vue";


export default {
    components: {
        ColorIcon,
        TravelHeader,
        ListEmpty,
        MyEvection
    },
    data() {
        return {
            listLoading: false,
            params: {
                startDate: '',
                endDate: '',
                journeyType: 0
            },
            myJourneyList: [],
            loading: false,
            emptyType: 'search',
            orderType: 'b',
        }
    },
    mounted() {
        this._getMyJourneyList();
        const __this = this;
        fixStatusBar({
            handler({api}) {
                __this.$refs.headNavShow.style.paddingTop = api.safeArea.top + 'px';
            }
        });
    },
    methods: {
        changeTabs(val){
            // Toast.loading({
            //     message: '加载中...',
            //     forbidClick: true,
            // });
        },
        clickButton() {
            this.$router.push({
                name: 'h5-home',
            })
        },
        testClick(data){
            this.params = {
                startDate: data.flightSetOutStartDate,
                endDate: data.flightSetOutEndDate,
                journeyType: data.approveTypeNewNum
            };
            this._getMyJourneyList();
        },
        async _getMyJourneyList() {
            // let ToastBox = Toast.loading({
            //     message: '加载中...',
            //     forbidClick: true,
            // });
            this.listLoading = true;
            let res = await getMyJourneyList(this.params);
            let myJourneyList = res.datas;
            let dateList = [...new Set(myJourneyList.map(item => item.startDate))];
            this.myJourneyList = [...new Set(dateList.map(date => {
                    let filterList = myJourneyList.filter(item => item.startDate === date);
                    return {
                        recentDate: filterList[0].dayType,
                        startDate: date,
                        week: filterList[0].week,
                        journeyList: filterList
                    }
                })
            )];
            this.listLoading = false;
            // ToastBox.clear();
        },
        toJourneyDetail(myJourney) {
            const toDetail = (name, query) => {
                this.$router.push({
                    name,
                    query,
                });
            };

            switch (myJourney.businessType) {
                case 1:
                    toDetail("flight-order-detail", {
                        orderNo: myJourney.orderNo
                    });
                    break;
                case 2:
                    toDetail("h5-train-order-detail", {
                        orderNo: myJourney.orderNo
                    });
                    break;
                case 3:
                    toDetail("h5-hotel-order-detail", {
                        orderNo: myJourney.orderNo
                    });
                    break;
            }
        }
    }
}
