import NavBar from "@/component/nav-bar/import_latest_version_module";
import consumer_trains_common_getEpidemicPolicy from '@/lib/data-service/haolv-default/consumer_trains_common_getEpidemicPolicy'
import {handlePromise} from '@/page/travel-book/flight/common/unit'
export default {
    data() {
        return {
            showBanner: false,
            show: false,
            startCityInfo: {},
            endCityInfo: {},
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _close() {
            this.show = false
        },
        _showPopup() {
            this.show = true
        },
        _openToScrollTop() {
            this.$nextTick(() => {
                this.$refs['content'].scrollTop = 0
            })
        },
        _filterText(val) {
            if (!val) {
                return val
            }
            let text = val.replace(/\n/g, "<br/>")
            return text
        },
        init(params) {
            let startCityCode = params.startCityCode
            let endCityCode = params.endCityCode
            let autoOpen = params.autoOpen || false
            const promiseList = [consumer_trains_common_getEpidemicPolicy({cityCode: startCityCode}), consumer_trains_common_getEpidemicPolicy({cityCode: endCityCode})]
            Promise.all(handlePromise(promiseList)).then(res => {
                let startCityInfo = null
                let endCityInfo = null
                if (res[0] && res[0].datas && res[0].datas.cityName) {
                    startCityInfo = res[0].datas
                    startCityInfo.leavePolicy = this._filterText(startCityInfo.leavePolicy)
                    startCityInfo.comePolicy = this._filterText(startCityInfo.comePolicy)
                    this.startCityInfo = startCityInfo
                } else {
                    this.startCityInfo = {}
                }
                if (res[1] && res[1].datas && res[1].datas.cityName) {
                    endCityInfo = res[1].datas
                    endCityInfo.leavePolicy = this._filterText(endCityInfo.leavePolicy)
                    endCityInfo.comePolicy = this._filterText(endCityInfo.comePolicy)
                    this.endCityInfo = endCityInfo
                } else {
                    this.endCityInfo = {}
                }
                if (autoOpen && (startCityInfo || endCityInfo)) {
                    this._showPopup()
                }
                const result = {
                    startCityInfo,
                    endCityInfo
                }
                this.$emit('getPolicy', result)
            })
        },
        getShowStatus() {
            return this.show
        },
    }
}