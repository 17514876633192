import moment from "moment";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import Calendar from '@/page/travel-book/flight/component/calendar/1.0.1/index.vue'
import StopData from '@/page/travel-book/flight/component/stopData/1.0.0/index.vue'
import StopDataPopup from '@/page/travel-book/flight/component/stopDataPopup/1.0.0/index.vue'
import {Toast, Dialog} from 'vant'
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
import VirtualList from 'vue-virtual-scroll-list'
import DomesticTicketAirportSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/domestic-ticket-airport-selector/1.2.1";
import NewFlightItem from '@/page/travel-book/flight/component/newFlightItem'
import consumer_flight_search_flightInfo from '@/lib/data-service/haolv-default/consumer_flight_search_flightInfo'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_web_policyConfig_getAllowChangeCities from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getAllowChangeCities'
import FlightListSkeleton from '@/component/skeleton/flight-list-skeleton/index.vue'
import ChangeEvectionCity from '@/component/change-evection-city/1.0.0/index.vue'
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.0/index'
import getJourneyOptionsCanBeModified from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop'
import TravelPopup from '@/component/travel-popup/1.0.2/index.vue'
import EpidemicPolicy from '@/page/travel-book/flight/component/epidemicPolicy/1.0.0/index.vue'
import consumer_web_policyConfig_myTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_myTravelPolicy";
import consumer_journey_getEvectionDetailV2 from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetailV2'
import consumer_flight_search_checkFlightPolicy from '@/lib/data-service/haolv-default/consumer_flight_search_checkFlightPolicy'
import consumer_flight_search_queryPlaneNameByCodes from '@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes'
import consumer_flight_search_queryMeals from '@/lib/data-service/haolv-default/consumer_flight_search_queryMeals';
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import cache_get from 'nllib/src/cache/get'
import cache_set from 'nllib/src/cache/set'
import cache_remove from 'nllib/src/cache/remove'
import MyEvectionRulePopup from "@/page/travel-book/flight/component/myEvectionRulePopup/index.vue";
import MyEvectionRuleNewPopup from "@/page/travel-book/flight/component/myEvectionRuleNewPopup/index.vue";
import {flightListSortByPriceCompanyIdList} from '@/lib/ohter/index.js';
import consumer_web_policyConfig_getUserTravelPolicy
    from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getUserTravelPolicy";
export default {
    data() {
        return {
            fromStationCode: '', // 出发站id
            toStationCode: '', // 到达站id
            startFlightParams: {}, // 出发站信息
            arriveFlightParams: {}, //到达站信息
            startDate: '', // 出发时间
            endDate: '', //返程时间
            flightType: 1, // 航程类型
            cabinRank: '', // 仓位登记 0=不限 1=头等舱 2=公务舱 3=经济舱
            airLineCode: '', // 航司二字码
            psgType: 1, // psgType
            takeOffInterval: '', //
            flightNo: '', // 航班

            orderNo: '', //订单编号
            evectionNo: '', // 出差单编号
            evectionType: 1, // 出差类型
            redirectType: '', // 返回类型
            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            journeyNo: '', // 订单号
            workTravelType: '',
            journeyType: '',
            journeyId: 0,

            startRangeDate: '', // 开始范围
            endRangeDate: '', // 结束范围

            flightList: [], // 机票列表
            oriFlightsList: [], // 原始机票列表
            filterFlightList: [], // 中间存储列表

            travelPopupShow: false,
            flightFromStationCode: '', // 搜搜条件-出发站
            flightToStationCode: '', // 搜索条件-到达站
            flightStartFlightParams: {}, // 搜索条件
            flightArriveFlightParams: {}, // 搜索条件
            flightStartDate: '', // 搜索条件-出发日期
            flightEndDate: '', // 搜索条件-返程日期

            canJourney: true, // 防连点
            isShowCalendar: false, // 时间选择弹窗
            isShowRangeCalendar: false,
            searchRangeType: 'default', // default: 日历选择后不直接搜索; search: 日历选择后直接搜索

            params: {}, //

            loadingShow: true,
            percent: 0,
            percentSetInterval: '',

            cabinPopupShow: false, // 舱位弹出框

            flightItemComponent: NewFlightItem,

            active: 0, // 当前tab
            orderField: 'depTime', // 排序字段，depTime-起飞时间 arrTime-到达时间。初始数据排列默认按起飞时间和到达时间从早到晚排序, 后续其他排序前端自行处理
            orderType: '', // 排序类型，asc-从小到大/从早到晚/从低到高 desc-从大到小/从晚到早/从高到低
            priceType: '', // 价格排序，同上

            screenShow: false, // 筛选框展示
            screenDetailShow: false, // 筛选框详情展示

            onlyLineFly: [], // 只看直飞，
            setOnlyLineFly: [],
            hideShareFly: [], // 隐藏共享航班
            setHideShareFly: [],
            agreement: [], // 企业协议航班
            setAgreement: [],
            canSetAgreement: false,

            takeOffTime: [
                /*{ value: "", label: "全部" },*/
                {value: "00:00-05:59", label: "凌晨00:00-05:59"},
                {value: "06:00-11:59", label: "上午06:00-11:59"},
                {value: "12:00-17:59", label: "下午12:00-17:59"},
                {value: "18:00-23:59", label: "晚上18:00-23:59"}
            ],
            takeOffTimeParams: [], // 起飞时间段
            setTakeOffTimeParams: [],
            setTakeOffTimeParamsInfo: [],
            endTakeOffTimeParams: '', // 降落时间段
            setEndTakeOffTimeParams: '',

            depAirportList: [],
            depAirportParams: [], // 出发城市机场搜索关键词
            setDepAirportParams: [],
            setDepAirportParamsInfo: [],
            arrAirportList: [],
            arrAirportParams: [],
            setArrAirportParams: [],
            setArrAirportParamsInfo: [],
            planeSizeList: [
                /*{value: '', label: '所有机型'},*/
                {value: 1, label: '大型机'},
                {value: 2, label: '中型机'},
                {value: 3, label: '小型机'},
                /*{value: 3, label: '其他型机'},*/
            ],
            planeSizeParams: [],
            setPlaneSizeParams: [],
            setPlaneSizeParamsInfo: [],
            classTypeList: [
                //  舱位列表
                {
                    value: '',
                    label: "所有舱位"
                },
                {
                    value: '1,2',
                    label: "头等/商务舱"
                },
                /*{
                    value: 2,
                    label: "公务舱"
                },*/
                {
                    value: '3',
                    label: "经济舱"
                }
            ],
            cabinParams: '', // 舱位筛选
            setCabinParams: '',
            airlinesList: [],
            airlinesParams: [],
            setAirlinesParams: [],
            setAirlinesParamsInfo: [],

            evectionRequired: 0,
            bookUpperBound: 6,
            unlimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            changeEvectionCity: 0, // 是否可选临近城市 0:否, 1:是
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批

            searchTemp: '',
            intervalNum: 0,

            stopDataShow: false,
            stopData: [], // 经停信息
            stopDataLoading: false,

            formSelectedIdList: [],
            toSelectedIdList: [],
            journeyDetail: {},

            evectionRulePopupShow: false,
            evectionRulePopupNewShow: false,
            policyLoading: false,
            myTravelPolicy: null,

            travelUserList: [],

            companyId: '',
            netErr: false, // 网络是否异常

            evectionBackType: '000',
        }
    },
    components: {
        NavBar,
        Calendar,
        DomesticTicketAirportSelector,
        'virtual-list': VirtualList,
        FlightListSkeleton,
        StopData,
        StopDataPopup,
        ChangeEvectionCity,
        TravelPopup,
        EpidemicPolicy,
        MyEvectionRulePopup,
        MyEvectionRuleNewPopup
    },
    created() {

        /*if (this.evectionType === 1) {
            this.active = 0
            this.orderField = 'depTime'
            this.orderType = 'asc'
        }*/
        this.active = 0
        this.orderField = 'depTime'
        this.orderType = 'asc'
        if (this.redirectType === '002') {
            this.orderNo = this.$route.query.orderNo
        }
    },
    mounted() {

    },
    async activated() {
        this.evectionRequired = parseInt(this.$route.query.evectionRequired)
        this.fromStationCode = this.$route.query.fromStationCode || 'CAN'
        this.toStationCode = this.$route.query.toStationCode || 'BJS'
        this.startFlightParams.name = this.$route.query.fromStationName || '广州'
        this.arriveFlightParams.name = this.$route.query.toStationName || '北京'
        this.startDate = this.$route.query.startDate ? this.$route.query.startDate : moment().locale('zh-cn').format('YYYY-MM-DD')
        this.endDate =  this.$route.query.endDate ? this.$route.query.endDate : '';
        this.flightType = parseInt(this.$route.query.flightType) || 1
        this.evectionNo = this.$route.query.evectionNo || ''
        this.evectionType = parseInt(this.$route.query.evectionType)
        this.redirectType = this.$route.query.redirectType ? this.$route.query.redirectType : '000'
        this.workTravelType = this.$route.query.workTravelType ? parseInt(this.$route.query.workTravelType) : 2
        this.cabinParams = this.$route.query.cabin ? this.$route.query.cabin : ''
        this.startRangeDate = this.$route.query.startRangeDate || '' // 开始范围
        this.endRangeDate = this.$route.query.endRangeDate || '' // 结束范围
        this.journeyType = this.$route.query.journeyType ? this.$route.query.journeyType : 0 // 0 普通单， 1 不限行程单
        this.journeyId = parseInt(this.$route.query.journeyId) || 0
        this.evectionBackType = this.$route.query.evectionBackType || '000';

        const __this = this;
        this.$refs.startAirportSelector.init({
            input: {
                placeholder: '出发城市(如北京/beijing/bj)'
            }
        })
        this.$refs.arriveAirportSelector.init({
            input: {
                placeholder: '目的城市(如北京/beijing/bj)'
            }
        })
        let evectionParams = {
            vm: this
        }
        const evectionQuery = getEvectionAllType(evectionParams)
        this.evectionDetailType = evectionQuery.evectionDetailType

        await this.getUserInfo();
        // 如果是290
        if(flightListSortByPriceCompanyIdList.indexOf(this.companyId) > -1) {
            this.active = 1;
            this.orderField = 'price';
            this.orderType = '';
            this.priceType = 'asc'
        }

        let flightSearchReactType =  cache_get({key: 'flightSearchReactType'});
        let flightErr =  cache_get({key: 'flightErr'});
        const _this = this
        this.intervalNum = 0
        this.canChangeFactor()
        try {
            await this.refundChangePolicyGet()
        } catch (e) {

        }
        if (this.evectionType === 2) {
            this.getEvectionDetail();// 获取出差单详情
        }

        // 正常跳转或者跳转到舱位页但是报错的返回
        if (flightSearchReactType === '1' || flightErr == 1) {
            this.search()
        } else if (flightSearchReactType === '2') {
            // 找回之前的筛选条件
            let filterParams = cache_get({key: 'flightFilterParams'});
            if (filterParams) {
                filterParams = JSON.parse(filterParams);
                this.onlyLineFly = filterParams.setOnlyLineFly;
                this.setOnlyLineFly = filterParams.setOnlyLineFly;
                this.hideShareFly = filterParams.setHideShareFly;
                this.setHideShareFly = filterParams.setHideShareFly;
                this.agreement = filterParams.setAgreement;
                this.setAgreement = filterParams.setAgreement;

                this.setTakeOffTimeParamsInfo = filterParams.setTakeOffTimeParamsInfo;
                this.setDepAirportParamsInfo = filterParams.setDepAirportParamsInfo;
                this.setArrAirportParamsInfo = filterParams.setArrAirportParamsInfo;
                this.setPlaneSizeParamsInfo = filterParams.setPlaneSizeParamsInfo;
                this.setAirlinesParamsInfo = filterParams.setAirlinesParamsInfo;
                this.takeOffTimeParams = filterParams.setTakeOffTimeParams;
                this.setTakeOffTimeParams = filterParams.setTakeOffTimeParams;
                this.depAirportParams = filterParams.setDepAirportParams;
                this.setDepAirportParams = filterParams.setDepAirportParams;
                this.arrAirportParams = filterParams.setArrAirportParams;
                this.setArrAirportParams = filterParams.setArrAirportParams;
                this.planeSizeParams = filterParams.setArrAirportParams;
                this.setPlaneSizeParams = filterParams.setPlaneSizeParams;
                this.airlinesParams = filterParams.setAirlinesParams;
                this.setAirlinesParams = filterParams.setAirlinesParams;
                this.orderField = filterParams.orderField;
                this.orderType = filterParams.orderType;
                this.priceType = filterParams.priceType;
                this.active = filterParams.active;
            }
            // 计算5分钟
            const outTime = cache_get({key: 'flightSearchOutTime'});
            let minute = moment().diff(outTime, 'minute');
            if (minute >= 5) {
                this.search()
            } else {
                console.log(this.orderField, this.orderType, this.priceType);
                let oriFlightsList = cache_get({key: 'flightOriFlightsList'});
                oriFlightsList = JSON.parse(oriFlightsList);
                this.setFilter(oriFlightsList);
                this.trainFilter();
                //this.oriFlightsList = JSON.parse(oriFlightsList);
                //const flightList = cache_get({key: 'flightFlightList'});
                //this.flightList = JSON.parse(flightList);
                this.loadingShow = false
            }
        }
        cache_remove({
            key: 'flightSearchOutTime',
        });
        cache_remove({
            key: 'flightOriFlightsList',
        });
        cache_remove({
            key: 'flightFlightList',
        });
        cache_remove({
            key: 'flightErr',
        });

        if (this.changeEvectionCity === 1 && this.journeyId) {
            this.getAllowChangeCity()
        }
        this.setSearchReact();

        // this.getEvectionDetail();
        // this.initEpidemicPolicy()

        keybackProcessor.addEventListener(() => {
            if (_this.isShowCalendar) {
                _this.isShowCalendar = false
                return
            }
            const startAirportSelector = _this.$refs.startAirportSelector;
            const showStartAirportSelector = startAirportSelector.get_show();
            if (showStartAirportSelector) {
                startAirportSelector.hide();
                return;
            }

            const arriveAirportSelector = _this.$refs.arriveAirportSelector;
            const showArriveAirportSelector = arriveAirportSelector.get_show();
            if (showArriveAirportSelector) {
                arriveAirportSelector.hide();
                return;
            }
            const formChangeEvectionCity = _this.$refs.formChangeEvectionCity;
            const showFormChangeEvectionCity = formChangeEvectionCity.get_show()
            if (showFormChangeEvectionCity) {
                formChangeEvectionCity.hide()
                return;
            }
            const toChangeEvectionCity = _this.$refs.toChangeEvectionCity;
            const showToChangeEvectionCity = toChangeEvectionCity.get_show()
            if (showToChangeEvectionCity) {
                toChangeEvectionCity.hide()
                return;
            }
            if (_this.travelPopupShow) {
                _this.travelPopupShow = false
                return
            }
            const calendar = _this.$refs['calendar']
            if (calendar.getShowType()) {
                calendar.hide()
                return
            }
            if (_this.stopDataShow) {
                _this.stopDataShow = false
                return;
            }
            if (_this.screenShow) {
                _this.screenShow = false
                return;
            }
            const travelPopup = this.$refs.travelPopup
            if (travelPopup.getShowType()) {
                travelPopup.hide()
                return;
            }
            // const epidemicPolicy = this.$refs.epidemicPolicy
            // if (epidemicPolicy.getShowStatus()) {
            //     epidemicPolicy._close()
            //     return;
            // }
            _this.back()
        })
    },
    async beforeRouteEnter(to, from, next) {
        //第一个参数to，包含的内容是切换后的路由对象，也就是跳转后的路由对象
        //第二个参数from，包含的内容的是切换前的路由对象，也就是跳转前的路由对象
        //第三个参数next()，是否往下执行，执行的话，如果不写的话路由就不会跳转，操作将会终止
        console.log('route', to, from);
        if (to.name === 'flight-search' && from.name === 'flight-cabin-list') {
            // 1是正常刷新， 2是舱位列表返回，要超过5分钟才刷新
            cache_set({
                key: 'flightSearchReactType',
                value: '2'
            })
        } else {
            cache_set({
                key: 'flightSearchReactType',
                value: '1'
            })
        }
        next();
    },
    deactivated() {
        clearInterval(this.searchTemp)
        clearInterval(this.percentSetInterval)
        keybackProcessor.removeEventListener();
        const outTime = moment().format('YYYY-MM-DD HH:mm:ss');
        console.log(outTime);
        cache_set({
            key: 'flightSearchOutTime',
            value: outTime
        });
        cache_set({
            key: 'flightOriFlightsList',
            value: JSON.stringify(this.oriFlightsList)
        });
        cache_set({
            key: 'flightFlightList',
            value:  JSON.stringify(this.flightList)
        })
        let filterParams = {
            setOnlyLineFly: this.setOnlyLineFly,
            setHideShareFly: this.setHideShareFly,
            setAgreement: this.setAgreement,
            setTakeOffTimeParamsInfo: this.setTakeOffTimeParamsInfo,
            setDepAirportParamsInfo: this.setDepAirportParamsInfo,
            setArrAirportParamsInfo: this.setArrAirportParamsInfo,
            setPlaneSizeParamsInfo: this.setPlaneSizeParamsInfo,
            setAirlinesParamsInfo: this.setAirlinesParamsInfo,
            setTakeOffTimeParams: this.setTakeOffTimeParams,
            setDepAirportParams: this.setDepAirportParams,
            setArrAirportParams: this.setArrAirportParams,
            setPlaneSizeParams: this.setPlaneSizeParams,
            setAirlinesParams: this.setAirlinesParams,
            orderField: this.orderField,
            orderType: this.orderType,
            priceType: this.priceType,
            active: this.active,
        };
        cache_set({
            key: 'flightFilterParams',
            value:  JSON.stringify(filterParams)
        })
    },
    destroyed() {
        clearInterval(this.percentSetInterval)
        clearInterval(this.searchTemp)
    },
    watch: {
        startDate(newVal) {
            // this.search()
        },
        flightFromStationCode(newVal) {
            this.$nextTick(() => {
                this.flightStartFlightParams = this.$refs.startAirportSelector.get_selected_entity()
            })
        },
        flightToStationCode(newVal) {
            this.$nextTick(() => {
                this.flightArriveFlightParams = this.$refs.arriveAirportSelector.get_selected_entity()
            })
        },
    },
    computed: {
        filterNum() {
            let setOnlyLineFly = this.setOnlyLineFly.length
            let setHideShareFly = this.setHideShareFly.length
            let setAgreement = this.setAgreement.length
            let setTakeOffTimeParamsInfo = this.setTakeOffTimeParamsInfo.length
            let setDepAirportParamsInfo = this.setDepAirportParamsInfo.length
            let setArrAirportParamsInfo = this.setArrAirportParamsInfo.length
            let setPlaneSizeParamsInfo = this.setPlaneSizeParamsInfo.length
            let setAirlinesParamsInfo = this.setAirlinesParamsInfo.length
            return setOnlyLineFly + setHideShareFly + setAgreement + setTakeOffTimeParamsInfo + setDepAirportParamsInfo + setArrAirportParamsInfo + setPlaneSizeParamsInfo + setAirlinesParamsInfo
        },
    },
    filters: {
        filterFlightDate(val) {
            return val === '' ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterFlightDaY(val) {
            return val === '' ? '' : moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day()
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '公务舱'
            } else if (val === 3) {
                return '经济舱'
            } else {
                return '不限'
            }
        },
        filterPercent(val) {
            if (val < 16) {
                return '航班信息准备中...'
            } else if (val >= 16 && val < 36) {
                return '机票舱位准备中...'
            } else if (val >= 36 && val < 56) {
                return '机票坐席准备中...'
            } else if (val >= 56 && val < 80) {
                return '机票折扣准备中...'
            } else if (val >= 80 && val < 100) {
                return '低价机票准备中...'
            } else {
                return ''
            }
        },
        filterOrderType(val) {
            if (val === 'asc') {
                return '出发 早-晚'
            } else if (val === 'desc') {
                return '出发 晚-早'
            } else {
                return '时间'
            }
        },
        filterPriceType(val) {
            if (val === 'asc') {
                return '低-高'
            } else if (val === 'desc') {
                return '高-低'
            } else {
                return ''
            }
        },
    },
    methods: {
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.evectionRequired = res.datas.evectionRequired;
            this.bookUpperBound = res.datas.bookUpperBound;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
            this.unlimitedJourney = res.datas.unlimitedJourney;
            this.changeEvectionCity = res.datas.changeEvectionCity;
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        initEpidemicPolicy() {
            this.$refs['epidemicPolicy'].init({
                startCityCode: this.fromStationCode,
                endCityCode: this.toStationCode
            })
        },
        getAllowChangeCity() {
            let _this = this
            consumer_web_policyConfig_getAllowChangeCities({journeyId: this.journeyId}).then(res => {
                this.journeyDetail = res.datas
                if (this.journeyDetail.departCityChange === 1) {
                    this.formSelectedIdList = [this.fromStationCode]
                    this.$nextTick(() => {
                        this.$refs.formChangeEvectionCity.init({
                            dialog: {
                                title: `出发城市`,
                            },
                            get_data_source() {
                                return new Promise((resolve, reject) => {
                                    let list = _this.journeyDetail.departSelectCities
                                    resolve({all_entity_list: list})
                                })
                            },
                            btn_ok_click_event_handler(res) {
                                let list = res.selected_entity_list
                                _this.flightFromStationCode = list[0].cityCode
                                _this.flightStartFlightParams = {
                                    name: list[0].cityName,
                                    code: list[0].cityCode
                                }
                            },
                        });
                    })
                }
                if (this.journeyDetail.toCityChange === 1) {
                    this.toSelectedIdList = [this.toStationCode]
                    this.$nextTick(() => {
                        this.$refs.toChangeEvectionCity.init({
                            dialog: {
                                title: `到达城市`,
                            },
                            get_data_source() {
                                return new Promise((resolve, reject) => {
                                    let list = _this.journeyDetail.toSelectCities
                                    resolve({all_entity_list: list})
                                })
                            },
                            btn_ok_click_event_handler(res) {
                                let list = res.selected_entity_list
                                _this.flightToStationCode = list[0].cityCode
                                _this.flightArriveFlightParams = {
                                    name: list[0].cityName,
                                    code: list[0].cityCode
                                }
                            },
                        });
                    })
                }
            })
        },
        canChangeFactor() {
            getJourneyOptionsCanBeModified({
                situation: '000',
                vm: this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit
                this.timeCanEdit = res.timeCanEdit
            })
        },
        // 判断机场字段的长短返回样式
        inputFontLong(val) {
            if (val && (val.length > 0 && val.length < 3)) {
                return ''
            } else {
                return 'small'
            }
        },
        // 关闭搜索下拉框
        closeTravelPopup() {
            this.travelPopupShow = false
        },
        // 返回
        back() {
            let query = {}
            if (this.evectionType === 2) {
                query.evectionNo = this.evectionNo
                query.journeyId = this.journeyId
                query.startDate = this.startDate
                query.endDate = this.endDate
                query.evectionBackType = this.evectionBackType;
                query.fromStationName = this.startFlightParams.name;
                query.toStationName = this.arriveFlightParams.name;
            }
            this.$router.replace({
                name: 'h5-flight-home',
                query
            })
        },
        // 打开搜索下拉框
        showSearchBox() {
            this.travelPopupShow = true;
        },
        // 打开完搜索下拉框后触发
        openSearchBox() {
            let _this = this
            this.flightFromStationCode = this.fromStationCode
            this.flightToStationCode = this.toStationCode
            this.flightStartDate = this.startDate
            this.flightEndDate = this.endDate
            // let test = setInterval(() => {
            //     if (this.$refs.searchHeader !== undefined) {
            //         fixStatusBar({
            //             type: `000`,
            //             handler({default_handler, api}) {
            //                 const bodyHeight = document.body.clientHeight
            //                 const addPaddingTop = (api.safeArea.top)/(bodyHeight/100)
            //                 _this.$refs['searchHeader'].$el.style.paddingTop = `${addPaddingTop}vh`;
            //             }
            //         });
            //         clearInterval(test);
            //     }
            // },100)
        },
        // 选择时间
        showCalendar() {
            if (!this.timeCanEdit) {
                return
            }
            this.isShowCalendar = true
        },
        showRangeCalendar() {
            if (!this.timeCanEdit) {
                return
            }
            this.isShowRangeCalendar = true
            // 日历选择后不直接搜索
            this.searchRangeType = 'default';
        },
        // 往返情况在通栏日历选择了比回程大的去程时间时
        errorToShowRangeCalendar() {
            if (!this.timeCanEdit) {
                return
            }
            this.isShowRangeCalendar = true
            // 日历选择后直接搜索
            this.searchRangeType = 'search';
        },
        // 确认选择时间
        onCalendarConfirm(val) {
            this.flightStartDate = moment(val).format('YYYY-MM-DD')
            this.isShowCalendar = false
        },
        // 确认选择时间
        onCalendarRangeConfirm(val) {
            this.flightStartDate = moment(val[0]).format("YYYY-MM-DD");
            this.flightEndDate = moment(val[1]).format("YYYY-MM-DD");
            this.isShowRangeCalendar = false
            if (this.searchRangeType === 'search') {
                this.startDate = moment(val[0]).format("YYYY-MM-DD");
                this.endDate = moment(val[1]).format("YYYY-MM-DD");
                this.search();
            }
        },
        // 打开出发站选择弹框
        openStartFlightPopup() {
            if ((!this.cityCanEdit || this.unlimitedJourney === 0) && this.workTravelType === 1 && this.journeyDetail.departCityChange !== 1) {
                Toast('不能修改出差单既定行程')
                return
            }
            if (this.journeyDetail.departCityChange === 1) {
                this.$refs.formChangeEvectionCity.show()
            } else {
                this.$refs.startAirportSelector.show()
            }
        },
        // 打开到达站选择弹框
        openArriveFlightPopup() {
            if ((!this.cityCanEdit || this.unlimitedJourney === 0) && this.workTravelType === 1 && this.journeyDetail.toCityChange !== 1) {
                Toast('不能修改出差单既定行程')
                return
            }
            if (this.journeyDetail.toCityChange === 1) {
                this.$refs.toChangeEvectionCity.show()
            } else {
                this.$refs.arriveAirportSelector.show()
            }
        },
        toSearch() {
            this.travelPopupShow = false
            let hasDifferent = false;
            if (this.fromStationCode !== this.flightFromStationCode || this.toStationCode !== this.flightToStationCode || this.startDate !== this.flightStartDate) {
                hasDifferent = true;
            }
            this.fromStationCode = this.flightFromStationCode
            this.toStationCode = this.flightToStationCode
            this.startFlightParams = JSON.parse(JSON.stringify(this.flightStartFlightParams))
            this.arriveFlightParams = JSON.parse(JSON.stringify(this.flightArriveFlightParams))
            this.startDate = this.flightStartDate
            this.endDate = this.flightEndDate;
            // this.initEpidemicPolicy()
            if (hasDifferent) {
                this.clearFilter();
                this.search()
            }
        },
        clickDate(val) {
            this.clearFilter();
            this.search()
        },
        reload() {
            location.reload();
        },
        search() {
            if (this.fromStationCode === '') {
                Toast({
                    message: '请选择出发地',
                    position: 'bottom',
                });
                this.loadingShow = false
                return
            }
            if (this.toStationCode === '') {
                Toast({
                    message: '请选择目的地',
                    position: 'bottom',
                });
                this.loadingShow = false
                return
            }
            if (this.startDate === null || this.startDate === '') {
                Toast({
                    message: '请选择日期',
                    position: 'bottom',
                });
                this.loadingShow = false
                return
            }
            let params = {
                // 航程类型
                flightType: this.flightType,
                needPnr: 0,
                // 出发城市三字码。
                depCityPortCode: this.fromStationCode,
                // 到达城市三字码。
                arrCityPortCode: this.toStationCode,
                // 出发日期
                depDate: this.startDate,
                // 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱
                cabinRank: this.cabinRank,
                // 航空公司二字码
                airLineCode: '',
                // psgType
                psgType: this.psgType,
                //  是否直飞
                directFlight: 0,
                // 折扣
                discount: 0,
                orderField: "",
                takeOffInterval: '',
                flightNo: '',

                orderType: '',
                planeSize: '',
            };
            let data = {
                companyCode: '',
                rqData: Object.assign({}, params),
                sign: '',
                timeStamp: '',
                ignoreKeyAccountCompany: this.evectionType === 1 ? 1 : '',
                // applyId: this.$route.query.applyId ? parseInt(this.$route.query.applyId) : '',
                evectionNo: this.evectionNo,
            }
            this.loadingShow = true
            // 替换地址栏参数
            let urlParams
            if (this.evectionType === 1) {
                urlParams = {
                    fromStationCode: this.fromStationCode,
                    fromStationName: this.startFlightParams.name,
                    toStationCode: this.toStationCode,
                    toStationName: this.arriveFlightParams.name,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    flightType: this.flightType,
                    evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                }
            } else {
                urlParams = {
                    fromStationCode: this.fromStationCode,
                    fromStationName: this.startFlightParams.name,
                    toStationCode: this.toStationCode,
                    toStationName: this.arriveFlightParams.name,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    flightType: this.flightType,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                    evectionRequired: this.evectionRequired,
                    workTravelType: this.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                    applyId: this.$route.query.applyId,
                    startRangeDate: this.startRangeDate,
                    endRangeDate: this.endRangeDate,
                    journeyId: this.journeyId,
                    journeyType: this.$route.query.journeyType !== undefined ? parseInt(this.$route.query.journeyType) : '',
                }
            }

            // 组装当前页面url
            let href = location.href.split('?')[0]
            let newUrl = href + '?'
            for(let i in urlParams) {
                newUrl += `${i}=${urlParams[i]}&`
            }
            history.replaceState(null, null, newUrl)
            this.netErr = false;
            consumer_flight_search_flightInfo(data).then(res => {
                if (!res.rsData) {
                    this.loadingShow = false
                    return
                }
                let flightList = res.rsData
                this.setFilter(flightList);
                //flightList = flightList.concat(JSON.parse(JSON.stringify(res.rsData)))
                //flightList = flightList.concat(JSON.parse(JSON.stringify(res.rsData)))
                //flightList = flightList.concat(JSON.parse(JSON.stringify(res.rsData)))

                this.trainFilter(true)
                // Toast.clear()
            }).catch((e) => {
                this.netErr = true;
                this.loadingShow = false;
                this.oriFlightsList = [];
                this.flightList = [];
                if (e.data.msg.indexOf('系统繁忙') == -1) {
                    Dialog.alert({
                        title: '温馨提示',
                        message: e.data.msg,
                    }).then(() => {
                        // on close
                    });
                }
            })
        },
        clearFilter(getFlightInfo = false) {
            this.active = 0
            this.orderField = 'depTime'
            this.orderType = 'asc'
            // 如果是290
            if(flightListSortByPriceCompanyIdList.indexOf(this.companyId) > -1) {
                this.active = 1;
                this.orderField = 'price';
                this.orderType = '';
                this.priceType = 'asc'
            }

            this.setOnlyLineFly = []
            this.setHideShareFly = []
            this.setAgreement = []
            this.setTakeOffTimeParams = []
            this.setTakeOffTimeParamsInfo = []
            this.setEndTakeOffTimeParams = ''
            this.setDepAirportParams = []
            this.setDepAirportParamsInfo = []
            this.setArrAirportParams = []
            this.setArrAirportParamsInfo = []
            this.setPlaneSizeParams = []
            this.setPlaneSizeParamsInfo = []
            this.setCabinParams = ''
            this.setAirlinesParams = []
            this.setAirlinesParamsInfo = []

            this.onlyLineFly = []
            this.hideShareFly = []
            this.agreement = []
            this.takeOffTimeParams = []
            this.endTakeOffTimeParams = ''
            this.depAirportParams = []
            this.arrAirportParams = []
            this.planeSizeParams = []
            this.cabinParams = ''
            this.airlinesParams = []
        },
        setFilter(flightList) {
            let airlinesCodeList = [] // 航司Code合集
            let airlinesList = [] // 航司合集
            let depAirportCodeList = [] // 起飞机场的code合集
            let depAirportList = [] // 起飞机场的合集
            let arrAirportCodeList = [] // 降落机场的code合集
            let arrAirportList = [] // 降落机场的合集
            let canSetAgreement = false
            flightList.forEach((value, index) => {
                value.show = 0
                value.price = 0
                // value.discount = 0
                value.productList = []
                value.cabin = ''
                value.loading = false
                value.hasMeals = value.isHasMeal;
                if (value.agreement) {
                    canSetAgreement = true
                }

                if (airlinesCodeList.indexOf(value.airCompanyCode) === -1) {
                    airlinesCodeList.push(value.airCompanyCode)
                    airlinesList.push({
                        value: value.airCompanyCode,
                        label: value.airCompanyName,
                        logo: value.airCompanyLogoImg
                    })
                }
                if (depAirportCodeList.indexOf(value.depAirportCode) === -1) {
                    depAirportCodeList.push(value.depAirportCode)
                    depAirportList.push({
                        value: value.depAirportCode,
                        label: value.depAirportName,
                    })
                }
                if (arrAirportCodeList.indexOf(value.arrAirportCode) === -1) {
                    arrAirportCodeList.push(value.arrAirportCode)
                    arrAirportList.push({
                        value: value.arrAirportCode,
                        label: value.arrAirportName
                    })
                }
            })
            // this.flightList = flightList
            this.airlinesList = airlinesList
            this.depAirportList = depAirportList
            this.arrAirportList = arrAirportList
            this.oriFlightsList = flightList
            this.canSetAgreement = canSetAgreement
        },
        trainFilter(getFlightInfo = false) {
            let oriFlightsList = JSON.parse(JSON.stringify(this.oriFlightsList))
            // 是否只看直飞
            if (this.onlyLineFly.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.stopNum === 0) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            // 筛选企业协议航班
            if (this.agreement.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.agreement) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            // 是否隐藏共享航班
            if (this.hideShareFly.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (!value.hasShare) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            if (this.airlinesParams.length > 0) {
                // this.airlinesParams.value
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (this.airlinesParams.indexOf(value.airCompanyCode) > -1) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            if (this.cabinParams !== '') {
                let flightItemList = []
                let cabinParamsArr = this.cabinParams.split(',')
                oriFlightsList.forEach(value => {
                    let policys = []
                    if (value.productList.length > 0) {
                        value.productList.forEach(value1 => {
                            let product = []
                            value1.productList.forEach(value2 => {
                                if (cabinParamsArr.indexOf(value2.cabin[0].cabinRank.toString()) >= 0) {
                                    product.push(value2)
                                }
                            })
                            value1.productList = product
                            if (product.length > 0) {
                                policys.push(value1)
                            }
                        })
                        value.productList = policys
                        if (policys.length > 0) {
                            flightItemList.push(value)
                        }
                    } else {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.takeOffTimeParams.length > 0) {
                let flightItemList = []
                // let takeOffTimeArr = this.takeOffTimeParams.value.split('-')
                let takeOffTimeParamsArr = []
                this.takeOffTimeParams.forEach(value => {
                    let takeOffTimeArr = value.split('-')
                    let leftTime = this.timeToMinute(takeOffTimeArr[0])
                    let rightTime = this.timeToMinute(takeOffTimeArr[1])
                    takeOffTimeParamsArr.push([leftTime, rightTime])
                })

                oriFlightsList.forEach(value => {
                    let depTime = this.timeToMinute(value.depTime)
                    takeOffTimeParamsArr.forEach(value1 => {
                        if (depTime >= value1[0] && depTime <= value1[1]) {
                            flightItemList.push(value)
                        }
                    })
                })
                oriFlightsList = flightItemList
            }

            if (this.planeSizeParams.length > 0) {
                let flightItemList = [];
                let cKeyWord = '';
                let otherKeyWord = [];
                switch (this.planeSizeParams[0]) {
                    case 1:
                        cKeyWord = '大';
                        otherKeyWord = ['中', '小'];
                        break;
                    case 2:
                        cKeyWord = '中';
                        otherKeyWord = ['大', '小'];
                        break;
                    case 3:
                        cKeyWord = '小';
                        otherKeyWord = ['中', '大'];
                        break;
                }
                oriFlightsList.forEach(value => {
                    if (!value.planeName) {
                        flightItemList.push(value)
                        return
                    }
                    if (value.planeName.indexOf(cKeyWord) > -1 ) {
                        flightItemList.push(value)
                    } else {
                        let hasOtherKey = false;
                        otherKeyWord.forEach(element=>{
                            if (value['planeName'].indexOf(element) > -1) {
                                hasOtherKey = true;
                            }
                        })
                        if (!hasOtherKey) {
                            flightItemList.add(value);
                        }
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.depAirportParams.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (this.depAirportParams.indexOf(value.depAirportCode) > -1 ) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.arrAirportParams.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (this.arrAirportParams.indexOf(value.arrAirportCode) > -1 ) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            this.filterFlightList = oriFlightsList
            this.sortList(getFlightInfo)
        },
        timeToMinute(val) {
            let timeVal = val.split(':')
            let hour = parseInt(timeVal[0]) * 60
            let minute = parseInt(timeVal[1])
            return hour + minute
        },
        sortList(getFlightInfo = false) {
            let flightList = JSON.parse(JSON.stringify(this.filterFlightList))

            let timeToMinute = function (val) {
                let timeVal = val.split(':')
                let hour = parseInt(timeVal[0]) * 60
                let minute = parseInt(timeVal[1])
                return hour + minute
            }
            // 按出发时间正序排列
            let depTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按出发时间反序排列
            let depTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按到达时间正序排列
            let arrTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按到达时间反序排列
            let arrTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按价格正序排列
            let priceCompare = function (x, y) {
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return -1;
                } else if (xPrice > yPrice) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按价格反序排序
            let priceReverseCompare = function (x, y) {
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return 1;
                } else if (xPrice > yPrice) {
                    return -1;
                } else {
                    return 0;
                }
            }
            if (this.orderField === 'depTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(depTimeCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(depTimeReverseCompare)
                }
            } else if (this.orderField === 'arrTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(arrTimeCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(arrTimeReverseCompare)
                }
            } else if (this.orderField === 'price') {
                if (this.priceType === 'asc') {
                    flightList.sort(priceCompare)
                } else if (this.priceType === 'desc') {
                    flightList.sort(priceReverseCompare)
                }
            }
            if (flightList.length === 0) {
                this.loadingShow = false
                this.flightList = flightList
                return
            }
            this.loadingShow = false
            this.flightList = flightList;

            this._getFlightInfo(getFlightInfo);
        },
        async _getFlightInfo(getFlightInfo) {
            if (!getFlightInfo) {
                return
            }
            await this._queryPlaneNameByCodes();
            // this._queryMeals();
        },
        async _queryPlaneNameByCodes() {
            let flightTypeList = [];
          this.oriFlightsList.forEach(value => {
              if (flightTypeList.indexOf(value.planeType) == -1) {
                  flightTypeList.push(value.planeType);
              }
          });
          if (flightTypeList.length == 0) {
              return
          }
          let [err, res] = await awaitWrap(consumer_flight_search_queryPlaneNameByCodes(flightTypeList));
          if (err) {
              return
          }
          let resList = res.datas;
          this.oriFlightsList.forEach(value => {
              resList.forEach(value1 => {
                  if (value.planeType == value1.planeType) {
                      value.planeName = value1.planeName;

                  }
              })
          })
            this.trainFilter();
          this.$forceUpdate();
        },
        _queryMeals() {
            let apiList = [];
            this.oriFlightsList.forEach(async value => {
                let params = {
                    "flightNo":value.flightNo,
                    "deptTime":value.depTime,
                    "arrTime":value.arrTime,
                };
                apiList.push(consumer_flight_search_queryMeals(params));
            });
            Promise.all(apiList).then(resList => {
                resList.forEach(value => {
                    let res = value.datas;
                    if (res == null) {
                        return
                    }
                    this.oriFlightsList.forEach(value1 => {
                        if (res.flightNo == value1.flightNo) {
                            let hasMealsText = '';
                            if (res) {
                                if (res.hasMeals == 1) {
                                    hasMealsText = '有餐食';
                                } else {
                                    hasMealsText = '无餐食';
                                }
                            }
                            value1.hasMeals = res ? res.hasMeals : '';
                            value1.hasMealsText = hasMealsText;
                        }
                    })
                });
                console.log(this.oriFlightsList);
                this.trainFilter();
                this.$forceUpdate();
            })

        },
        showMore(val, index) {
            val.show = !val.show
            this.flightList[index] = val
        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        // 关闭经停信息弹窗
        closeStopDataShow() {
            this.stopDataShow = false
        },
        submit(flight, index) {
            let minPriceList = [];
            this.oriFlightsList.forEach(value => {
                if (value.depTime === flight.depTime) {
                    minPriceList.push(value.basePrice);
                }
            })
            let query = {
                flightKey: flight.flightKey,
                flightNo: flight.flightNo,
                evectionType: this.evectionType,
                flightType: this.flightType,
                journeyId: this.journeyId,
                accord: flight.accord,
                startDate: this.startDate,
                endDate: this.endDate,
                fromStationCode: this.fromStationCode,
                toStationCode: this.toStationCode,
                fromStationName: this.startFlightParams.name,
                toStationName: this.arriveFlightParams.name,
                workTravelType: this.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                // applyId: this.travelCurrent ? this.travelCurrent.id : "",
                startRangeDate: this.startRangeDate,
                endRangeDate: this.endRangeDate,
                journeyType: this.journeyType, // 0 普通单， 1 不限行程单
                minPrice: Math.min(...minPriceList),
                basePrice: flight.basePrice,
            }
            if (this.evectionType === 2) {
                query.evectionNo = this.evectionNo
                query.evectionBackType = this.evectionBackType;
                // query.accord = flight.accord
            }
            this.$router.push({
                name: 'flight-cabin-list',
                query
            })
        },

        toChangeTimeSort() {
            this.priceType = ''
            this.orderField = 'depTime'
            let orderType = this.orderType
            if (orderType === '') {
                this.orderType = 'asc'
            } else if (orderType === 'asc') {
                this.orderType = 'desc'
            } else {
                // 因私没有空
                // this.orderType = this.evectionType === 2 ? '' : 'asc'
                this.orderType = 'asc'
            }
            this.sortList()
        },
        toChangePriceSort() {
            this.orderType = ''
            this.orderField = 'price'
            let priceType = this.priceType
            if (priceType === '') {
                this.priceType = 'asc'
            } else if (priceType === 'asc') {
                this.priceType = 'desc'
            } else {
                // 因私没有空
                // this.priceType = this.evectionType === 2 ? '' : 'asc'
                this.priceType = 'asc'
            }
            this.sortList()
        },
        toShowFilter() {
            this.setOnlyLineFly = JSON.parse(JSON.stringify(this.onlyLineFly))
            this.setAgreement = JSON.parse(JSON.stringify(this.agreement))
            this.setHideShareFly = JSON.parse(JSON.stringify(this.hideShareFly))
            this.setTakeOffTimeParams = this.takeOffTimeParams
            this.setEndTakeOffTimeParams = this.endTakeOffTimeParams
            this.setDepAirportParams = this.depAirportParams
            this.setArrAirportParams = this.arrAirportParams
            this.setPlaneSizeParams = this.planeSizeParams
            this.setCabinParams = this.cabinParams
            this.setAirlinesParams = this.airlinesParams
            this.screenDetailShow = false;
            this.screenShow = true
        },
        toggleOnlyLineFly() {
            this.$refs.onlyLineFlyCheckbox.toggle()
        },
        changeOnlyLineFly(val) {
            this.setOnlyLineFly = val
            this.submitToFilter()
        },
        toggleHideShareFly() {
            this.$refs.hideShareFlyCheckbox.toggle()
        },
        changeHideShareFly(val) {
            this.setHideShareFly = val
            this.submitToFilter()
        },
        changeAgreement(val) {
            this.setAgreement = val
            this.submitToFilter()
        },
        toggleAgreement() {
            if (!this.canSetAgreement) {
                return
            }
            this.$refs.agreementCheckbox.toggle()
        },
        clearAllCommandToSearch() {
            this.setOnlyLineFly = []
            this.setHideShareFly = []
            this.setAgreement = []
            this.setTakeOffTimeParams = []
            this.setTakeOffTimeParamsInfo = []
            this.setEndTakeOffTimeParams = ''
            this.setDepAirportParams = []
            this.setDepAirportParamsInfo = []
            this.setArrAirportParams = []
            this.setArrAirportParamsInfo = []
            this.setPlaneSizeParams = []
            this.setPlaneSizeParamsInfo = []
            this.setCabinParams = ''
            this.setAirlinesParams = []
            this.setAirlinesParamsInfo = []

            this.onlyLineFly = []
            this.hideShareFly = []
            this.agreement = []
            this.takeOffTimeParams = []
            this.endTakeOffTimeParams = ''
            this.depAirportParams = []
            this.arrAirportParams = []
            this.planeSizeParams = []
            this.cabinParams = ''
            this.airlinesParams = []

            this.screenDetailShow = false
            this.trainFilter()
        },
        submitToClose() {
            this.screenShow = false
        },
        submitToFilter() {
            this.onlyLineFly = JSON.parse(JSON.stringify(this.setOnlyLineFly))
            this.hideShareFly = JSON.parse(JSON.stringify(this.setHideShareFly))
            this.agreement = JSON.parse(JSON.stringify(this.setAgreement))
            this.takeOffTimeParams = this.setTakeOffTimeParams
            this.endTakeOffTimeParams = this.setEndTakeOffTimeParams
            this.depAirportParams = this.setDepAirportParams
            this.arrAirportParams = this.setArrAirportParams
            this.planeSizeParams = this.setPlaneSizeParams
            this.cabinParams = this.setCabinParams
            this.airlinesParams = this.setAirlinesParams
            //this.screenShow = false
            this.trainFilter()
        },
        formatterCalendar(day) {
            let type = ''
            const current = moment(day.date).format('YYYY-MM-DD')
            const currentDay = moment(current + ' 00:00:00').valueOf()
            const nowDate = moment().format('YYYY-MM-DD')
            const today = moment(nowDate + ' 00:00:00').valueOf()
            let isPassMinDate = true
            let isPassMaxDate = true
            let isPassToday = true
            isPassToday = currentDay >= today
            if (this.startRangeDate && this.startRangeDate !== '') {
                isPassMinDate = moment(this.startRangeDate + ' 00:00:00').valueOf() <= currentDay
            }
            if (this.endRangeDate && this.endRangeDate !== '') {
                isPassMaxDate = moment(this.endRangeDate + ' 00:00:00') >= currentDay
            }
            type = (isPassToday && isPassMinDate && isPassMaxDate) ? '' : 'disabled'
            if (moment(day.date).format('YYYY-MM-DD') === this.flightStartDate) {
                type = 'selected'
            }
            day.type = type
            return day
        },
        setSearchReact() {
            this.searchTemp = setInterval(() => {
                this.intervalNum++
                if (this.intervalNum >= 30 * 60) {
                    console.log(`去程搜索页倒计时${this.intervalNum}`);
                    clearInterval(this.searchTemp)
                    Toast('页面停留时间超过30分钟，请您重新搜索航班')
                    this.search()
                    this.intervalNum = 0
                    this.setSearchReact()
                }
            }, 1000)
        },

        closeScreenPopup() {
            this.screenShow = false
            //this.submitToFilter()
        },
        toggleSetTakeOffTimeParams(val) {
            if (this.setBlank(val)) {
                return
            }
            let setIndex = this.setTakeOffTimeParams.indexOf(val.value)
            if (setIndex > -1) {
                this.setTakeOffTimeParams.splice(setIndex, 1)
                this.setTakeOffTimeParamsInfo.splice(setIndex, 1)
            } else {
                this.setTakeOffTimeParams.push(val.value)
                this.setTakeOffTimeParamsInfo.push(val)
            }

            this.submitToFilter()
        },
        setBlank(val) {
            const currentDay = moment().format('YYYY-MM-DD')
            const currentTime = moment().valueOf()
            if (currentDay !== this.startDate) {
                return false
            }
            const valTimeArr = val.value.split('-')
            const rightTime = moment(`${currentDay} ${valTimeArr[1]}:59`).valueOf()
            if (currentTime < rightTime) {
                return false
            } else {
                return true
            }
        },
        toggleSetDepAirportParams(val) {
            /*let setIndex = this.setDepAirportParams.indexOf(val.value)
            if (setIndex > -1) {
                this.setDepAirportParams.splice(setIndex, 1)
                this.setDepAirportParamsInfo.splice(setIndex, 1)
            } else {
                this.setDepAirportParams.push(val.value)
                this.setTakeOffTimeParamsInfo.push(val)
            }*/
            this.setDepAirportParams = [val.value]
            this.setDepAirportParamsInfo = [val]

            this.submitToFilter()
        },
        toggleSetArrAirportParams(val) {
            /*let setIndex = this.setArrAirportParams.indexOf(val.value)
            if (setIndex > -1) {
                this.setArrAirportParams.splice(setIndex, 1)
                this.setArrAirportParamsInfo.splice(setIndex, 1)
            } else {
                this.setArrAirportParams.push(val.value)
                this.setArrAirportParamsInfo.push(val)
            }*/
            this.setArrAirportParams = [val.value]
            this.setArrAirportParamsInfo = [val]
            this.submitToFilter()
        },
        toggleSetPlaneSizeParams(val) {
            /*let setIndex = this.setPlaneSizeParams.indexOf(val.value)
            if (setIndex > -1) {
                this.setPlaneSizeParams.splice(setIndex, 1)
                this.setPlaneSizeParamsInfo.splice(setIndex, 1)
            } else {
                this.setPlaneSizeParams.push(val.value)
                this.setPlaneSizeParamsInfo.push(val)
            }*/
            this.setPlaneSizeParams = [val.value]
            this.setPlaneSizeParamsInfo = [val]
            this.submitToFilter()
        },
        toggleSetAirlinesParams(val) {
            let setIndex = this.setAirlinesParams.indexOf(val.value)
            if (setIndex > -1) {
                this.setAirlinesParams.splice(setIndex, 1)
                this.setAirlinesParamsInfo.splice(setIndex, 1)
            } else {
                this.setAirlinesParams.push(val.value)
                this.setAirlinesParamsInfo.push(val)
            }

            this.submitToFilter()
        },
        toggleScreenDetailShow() {
            this.screenDetailShow = !this.screenDetailShow
        },
        clearSetTakeOffTimeParams() {
            this.setTakeOffTimeParams = []
            this.setTakeOffTimeParamsInfo = []
            this.submitToFilter()
        },
        clearSetDepAirportParams() {
            this.setDepAirportParams = []
            this.setDepAirportParamsInfo = []
            this.submitToFilter()
        },
        clearSetArrAirportParams() {
            this.setArrAirportParams = []
            this.setArrAirportParamsInfo = []
            this.submitToFilter()
        },
        clearSetPlaneSizeParams() {
            this.setPlaneSizeParams = []
            this.setPlaneSizeParamsInfo = []
            this.submitToFilter()
        },
        clearSetAirlinesParams() {
            this.setAirlinesParams = []
            this.setAirlinesParamsInfo = []
            this.submitToFilter()
        },
        clearSetOnlyLineFly() {
            this.setOnlyLineFly = []
            this.$nextTick(() => {
                if (this.filterNum === 0) {
                    this.screenDetailShow = false
                }
            })
            this.submitToFilter()
        },
        clearSetAgreement() {
            this.setAgreement = []
            this.$nextTick(() => {
                if (this.filterNum === 0) {
                    this.screenDetailShow = false
                }
            })
            this.submitToFilter()
        },
        clearSetHideShareFly() {
            this.setHideShareFly = []
            this.$nextTick(() => {
                if (this.filterNum === 0) {
                    this.screenDetailShow = false
                }
            })
            this.submitToFilter()
        },
        delSetTakeOffTimeIndex(index) {
            this.setTakeOffTimeParams.splice(index, 1)
            this.setTakeOffTimeParamsInfo.splice(index, 1)
            this.$nextTick(() => {
                if (this.filterNum === 0) {
                    this.screenDetailShow = false
                }
            })
            this.submitToFilter()
        },
        delSetDepAirportIndex(index) {
            this.setDepAirportParams.splice(index, 1)
            this.setDepAirportParamsInfo.splice(index, 1)
            this.$nextTick(() => {
                if (this.filterNum === 0) {
                    this.screenDetailShow = false
                }
            })
            this.submitToFilter()
        },
        delSetArrAirportIndex(index) {
            this.setArrAirportParams.splice(index, 1)
            this.setArrAirportParamsInfo.splice(index, 1)
            this.$nextTick(() => {
                if (this.filterNum === 0) {
                    this.screenDetailShow = false
                }
            })
            this.submitToFilter()
        },
        delSetPlaneSizeIndex(index) {
            this.setPlaneSizeParams.splice(index, 1)
            this.setPlaneSizeParamsInfo.splice(index, 1)
            this.$nextTick(() => {
                if (this.filterNum === 0) {
                    this.screenDetailShow = false
                }
            })
            this.submitToFilter()
        },
        delSetAirlinesIndex(index) {
            this.setAirlinesParams.splice(index, 1)
            this.setAirlinesParamsInfo.splice(index, 1)
            this.$nextTick(() => {
                if (this.filterNum === 0) {
                    this.screenDetailShow = false
                }
            })
            this.submitToFilter()
        },
        backStopDetail(val) {
            if (val.stopNum > 0) {
                this.stopDataShow = true
                this.stopData = []
                this.stopDataLoading = true
                let params = {
                    "rqData": {
                        "date": val.depDate,
                        "flightNo": val.hasShare ? val.sharedFlightNo : val.flightNo,
                        'depCityCode': val.depCityCode,
                        'arrCityCode': val.arrCityCode,
                        'arrTime': val.arrTime,
                        'deptTime': val.depTime,
                        'depAirCode': val.depAirportCode,
                        'arrAirCode': val.arrAirportCode,
                    }
                }
                consumer_flight_order_flightsStop(params).then(res => {
                    this.stopData = res.datas.stopInfos;
                }).finally(() => {
                    this.stopDataLoading = false
                })
            }
        },
        editEvection() {
            this.$refs['travelPopup'].init({
                popupType: '000',
                evectionNo: this.evectionNo,
                evectionRequired: this.evectionRequired,
                journeyId: this.journeyId,
                businessRuleControl: this.businessRuleControl,
                flightInfo: {
                    deptCityId: this.fromStationCode, // 起飞城市或者机场id
                    arrCityId: this.toStationCode, // 到达城市或者机场id
                    deptTime: this.startDate, // 出发日期
                },
            })
        },
        updateEvectionInfo(val) {
            /*
            * 返参： obj
            {
              journeyId: string, 行程id
              evectionNo： string， 出差单号
              workTravelType: number, 因公出差的类型， 1是有选出差单，2是没有
              journeyType: number, 0 普通单， 1 不限行程单
              editType: string, 编辑类型，'000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
              travelCurrent： object, 当前选中的出差单， 如果没有选出差单则返回null
              difference: String, 是否有改动， 000： 没改动  001：城市改动，刷新页面  002：人改变，刷新差标  003: 日期改动，刷新页面
              travelUserList: Array[Number], 出行人ID数组
            }*/
            console.log(val);
            // this.travelUserList = val.travelUserList;
            if (val.travelCurrent) {
                // 注意不限行程
                this.fromStationCode = val.travelCurrent.active.departCityId || this.fromStationCode;
                this.toStationCode = val.travelCurrent.active.toCityId || this.toStationCode;
                this.startFlightParams.name =  val.travelCurrent.active.departCityName || this.startFlightParams.name;
                this.arriveFlightParams.name = val.travelCurrent.active.toCityName || this.arriveFlightParams.name;
                this.startDate = val.travelCurrent.active.tripDate || val.travelCurrent.active.departTime;
                this.endDate = val.travelCurrent.active.returnTime || ''
                this.startRangeDate = val.travelCurrent.active.departTime
                this.endRangeDate = val.travelCurrent.active.departTimeEnd
                this.flightType = val.travelCurrent.active.singleReturnType;
            } else {
                this.startRangeDate = '';
                this.endRangeDate = '';
            }
            this.evectionNo = val.evectionNo
            this.workTravelType = val.workTravelType
            this.journeyType = val.journeyType
            this.journeyId = val.journeyId
            if (val.editType === '000') {
                // 清除所有筛选项
                this.onlyLineFly = []; // 只看直飞，
                this.hideShareFly = []; // 隐藏共享航班
                this.takeOffTimeParams = [];
                this.endTakeOffTimeParams = [];
                this.airlinesParams = [];
                this.cabinParams = '';
                this.planeSizeParams = [];
                this.depAirportParams = [];
                this.arrAirportParams = [];
            }
            // 有改变出差单或者人 但城市和日期不变的情况下，只更新差标
            // 有改变出差单或者人 且城市或日期有改变的情况下，重新搜索
            /*if (val.difference) {
                this.search()
            }*/
            if (this.changeEvectionCity === 1 && this.journeyId) {
                this.getAllowChangeCity()
            }
            this.canChangeFactor();
            if (val.difference === '000') {
                return
            }else if(val.difference === '002') {
                this.updateAccord();
            } else {
                this.clearFilter();
                this.search(val);
            }

        },
        changeCity() {
            if (this.journeyId !== 0) {
                return
            }
            let flightFromStationCode = this.flightFromStationCode;
            let flightToStationCode = this.flightToStationCode;
            this.flightFromStationCode = flightToStationCode;
            this.flightToStationCode = flightFromStationCode;
        },

        openEvectionRulePopup() {
            // this.evectionRulePopupShow = true;
            //this.getMyTravelPolicy();
            this.getMyTravelPolicyNew();
            this.evectionRulePopupNewShow = true;
        },
        getMyTravelPolicyNew() {
            this.policyLoading = true;
            consumer_web_policyConfig_getUserTravelPolicy({
                businessType: 1,
                userIds: this.travelUserList,
                'evectionNo': this.evectionNo,
                'applyJourneyId': this.journeyId || '',
            }).then(res => {
                this.myTravelPolicy = res.datas
            }).finally(()=>{
                this.policyLoading = false;
            })
        },
        getMyTravelPolicy() {
            consumer_web_policyConfig_myTravelPolicy().then(res => {
                this.myTravelPolicy = res.datas
            })
        },
        closeRulePopup() {
            this.evectionRulePopupShow = false
        },

        getEvectionDetail() {
            consumer_journey_getEvectionDetailV2({
                evectionNo: this.evectionNo,
            }).then(res => {
                let travelUserList = [];
                if (res.datas.psgList) {
                    travelUserList = res.datas.psgList.map(element => element.userId)
                }
                this.travelUserList = travelUserList
            })
        },
        async getUserInfo() {
            let res = await get_user_info();
            this.companyId = res.datas.companyId;
        },
        updateAccord() {
            this.$showLoading.show();
            const params = {
                arrCityPortCode: this.toStationCode,
                depCityPortCode: this.fromStationCode,
                depDate: this.startDate,
                evectionNo: this.evectionNo,
                flightKey: '',
                flightNo: '',
            };
            consumer_flight_search_checkFlightPolicy(params).then(res=>{
                const checkList = res.checkList;
                this.oriFlightsList.forEach(value => {
                    for (let i = 0, l = checkList.length; i < l; i++) {
                        if (value.flightKey === checkList[i].key) {
                            value.accord = checkList[i].accord
                            break
                        }
                    }
                });
                this.trainFilter();
            }).finally(()=>{
                this.$showLoading.hide();
            })
        },
        openReturnCalendar() {
            // this.showSearchBox();
            this.flightStartDate = this.startDate
            this.flightEndDate = this.endDate
            this.$nextTick(()=>{
                this.errorToShowRangeCalendar();
            })
        },

    }
}
