import Q from 'q';
import _ from 'underscore';

export default {
    components: {},

    data() {
        return {
            msg: `您好！`,
        };
    },

    methods: {
        __hello(pMsg) {
            const __this = this;
            __this.msg = pMsg;
            console.log(`hello`, pMsg);
        },

        //base_layout_header，是否显示返回按钮
        __base_layout_header_get_btn_go_back_show() {
            return false;
        },

        //base_layout_header，返回按钮单击事件方法
        __base_layout_header_btn_go_back_click_event_handler() {
            const __this = this;
            console.log(`__base_layout_header_btn_go_back_click_event_handler`);
        },

        __btn_transition_name_test_click_event_handler() {
            const __this = this;
            const $router = __this.$router;
            $router.push({
                name: `demo-setting`,
                params: {
                    transition: {
                        name: `van-slide-up`,
                    },
                },
            });
        },
    },

    created() {
        // const __this = this;
    },

    activated() {
        // const __this = this;
    },

    deactivated() {
        // const __this = this;
    },

}
