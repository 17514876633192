// 订单列表
import order_list from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_queryHotelOrderListV2";
import consumer_t_od_hotel_order_interim_deleteHotelOrder
    from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_deleteHotelOrder";
// 取消订单
import cancel_order from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_cancelOrder";

import moment from 'moment';
import {Dialog, Toast} from "vant";
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";// 列表为空展示组件
import click_unsubscribe from "@/page/personal-center/order/components/hotelUnsubscribe/1.0.0/index";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import HotelUnsubscribe from "@/page/personal-center/order/components/hotelUnsubscribe/1.0.0/index.vue";

export default {
    data() {
        return {
            userId: '',

            form: {
                currentPage: 1,
                pageSize: 0,
                searchValue: '',
            },
            list: [],
            loading: false,
            finished: false,
            emptyType: "",
            refreshing: false,
            netErr: false, // 网络是否异常
        };
    },
    watch: {
        loading(val) {
            if (val) {
                this.$showLoading.show();
            } else {
                this.$showLoading.hide();
            }
        },
    },
    methods: {
        to_delete(row) {
            let data = {
                businessType: 1,
                orderNo: row,
            };
            this.$showLoading.show();
            consumer_t_od_hotel_order_interim_deleteHotelOrder(data)
                .then(res => {
                    this.$showLoading.hide();
                    this.req_list();
                    this.$Toast.success('删除成功');
                })
                .catch(rej => {
                    this.$showLoading.hide();
                    this.$Toast.fail('删除失败');
                })
        },
        to_refund(row) { // 申请退订
            console.log(row);
            const params = {
                orderNo: row.orderNo,
                evectionNo: row.evectionNo,
                cancelRemark: ''
            };
            console.log(params)
            this.$refs.hotelUnsubscribe.clickUnsubscribe(params).then((res) => {
                console.log(res);
                // 刷新
                if (res) {
                    this.req_list();
                }
            }).catch((rej) => {
                console.log(rej);
            });
            // this.$Dialog.confirm({
            //     title: '申请退订',
            //     message: '确定退订该订单？',
            //     confirmButtonText: '确定退订',
            //     cancelButtonText: '我再想想',
            // }).then(() => {
            //
            // }) .catch(() => {
            //
            // });
            // this.$router.push({
            //     name: "h5-hotel-refund",
            //     query: {
            //         orderNo: row
            //     }
            // });
        },
        // 获取个人信息
        async getUserInfo() {
            let res = await get_user_info();
            this.userId = res.datas.id
        },
        req_list() {
            this.emptyType = "";
            const form = {...this.form};

            this.netErr = false;
            order_list(form)
                .then((result) => {
                    const datas = result.pageResult;

                    const totalCount = datas.totalCount;
                    if (totalCount > form.pageSize) {
                        this.finished = false;
                    } else {
                        form.pageSize = totalCount;
                        this.finished = true;
                    }

                    if (!datas.pageData.length) this.emptyType = "search";

                    this.list = datas.pageData;
                    if (this.list) {
                        this.list.forEach(item => {
                            let isBeforeCheckIn = moment().add(-1, 'd').isBefore(item.checkInDate)
                            item.isBeforeCheckIn = isBeforeCheckIn
                        })
                    }
                    this.refreshing = false;
                    this.loading = false;
                    this.$showLoading.hide();
                })
                .catch(() => {
                    this.list = [];
                    this.emptyType = "network";
                    this.refreshing = false;
                    this.loading = false;
                    this.$showLoading.hide();
                    this.netErr = true;
                    this.finished = true;
                });
        },
        onRefresh() {
            this.form.pageSize = 0;
            this.refreshing = false;
            this.onLoad();
        },
        onLoad() {
            if (this.refreshing) return;
            this.loading = true;
            this.$showLoading.show();
            this.form.pageSize += 10;
            this.req_list();
        },
        onReLoad() {
            this.form.pageSize = 0;
            this.loading = true;
            this.$showLoading.show();
            this.finished = false;
            this.list = [];
            this.onLoad();
        },
        onSearch() {
            // if (this.form.searchValue === '') return;
            this.form.pageSize = 10;
            this.loading = true;
            this.$showLoading.show();
            this.onReLoad();
        },
        onSecretary() {
            this.$Toast('功能暂未开放');
        },
        onDetail(orderNo) {
            this.$router.push({name: 'h5-hotel-order-detail', query: {orderNo}});
        },
        cancel_order(orderNo) {
            this.$Dialog.confirm({
                title: "提示",
                message: "确定要取消订单吗?",
                cancelButtonText: '稍后再说',
                confirmButtonText: '取消订单',
            })
                .then(() => {
                    cancel_order({orderNo}).then((res) => {
                        Toast('取消订单成功');
                        this.onReLoad();
                    });
                })
                .catch(() => {
                });
        },
        to_payment(orderNo) {
            this.$router.push({
                name: "h5-travel-payment",
                query: {
                    orderNo,
                    orderType: "3",
                    businessType: 3,
                },
            });
        },
    },
    created() {
        this.getUserInfo();
    },
    filters: {
        formatDate(val) {
            return moment(val).format('MM-DD');
        },
    },
    components: {
        ListEmpty,
        Search: () => import('./components/Search.vue'),
        HotelUnsubscribe,
    },
};
