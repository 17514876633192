// 我的申请列表 http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-evection-controller/addOrUpdateUsingPOST_4
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/apply/myApplyList',
        data: data
    }
    return __request(pParameter)
}
export default request