import import_latest_version_module from '@/component/base-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/demo/train-traveler-selector',
            name: 'demo-train-traveler-selector',
            component: () => import(/* webpackChunkName: "page-demo-train-traveler-selector" */ `./index.vue`),
            meta: {
                data_for_base_layout: {
                    header: {
                        show: true,
                        title: `火车票乘客选择`,
                    },
                    tab_bar: {
                        show: true,
                    }
                },

                data_for_all_routes_processor: {
                    order_index: `010-000`,
                },
            },
        },
    ],
};

