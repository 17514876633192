import NavBar from "@/component/nav-bar/import_latest_version_module";
// 获取订单类型--抢票、普通
import consumer_trains_order_getOrderType from '@/lib/data-service/haolv-default/consumer_trains_order_getOrderType'
import { Toast } from 'vant';
export default {
  components: {
    NavBar
  },
  data() {
    return {
      orderNo: null,
      orderType: null,
      pageType: 2, // 1,2:预定支付？ , 3:改签
      businessType: '',
    };
  },
  computed: {

  },
  methods: {
    getDataInit(){
      this.orderNo = this.$route.query.orderNo;
      this.orderType = parseInt(this.$route.query.orderType) || null;
      this.pageType = parseInt(this.$route.query.pageType) || 2;
      this.businessType = parseInt(this.$route.query.businessType) || '';
    },
    back() {
      if (this.orderType === 1) {
        this.$router.replace({
          name: "h5-flight-order-list",
        });
      } else if (this.orderType === 2) {
        this.$router.replace({
          name: "h5-train-order-list",
        });
      } else if (this.orderType === 3) {
        this.$router.replace({
          name: "h5-hotel-order-list",
        });
      } else {
        this.$router.replace({
          name: "h5-home",
        });
      }
    },
    to_detail () {
      if (this.orderType) {
        const orderNo = this.orderNo;
        if (this.pageType === 1 || this.pageType === 2) {
          // 预定支付
          if (this.businessType === 1) {
            this.$router.replace({
              name: 'flight-order-detail',
              query: {
                orderNo,
                jumpType: 'list'
              },
            })
          } else if (this.businessType === 2) {
            Toast.loading({
              message: '加载中...',
              forbidClick: true,
            });
            consumer_trains_order_getOrderType({orderNo}).then(res => {
              Toast.clear();
              if (res.datas.orderType == 2 && (res.datas.orderStatus == 2 || res.datas.orderStatus == 7)) {
                this.$router.replace({
                  name: 'h5-train-order-detail-ticket',
                  query: {
                    orderNo,
                    jumpType: 'list'
                  },
                })
              } else {
                this.$router.replace({
                  name: 'h5-train-order-detail',
                  query: {
                    orderNo,
                    jumpType: 'list'
                  },
                })
              }
            });
          } else if (this.businessType === 3) {
            this.$router.replace({
              name: 'h5-hotel-order-detail',
              query: {
                orderNo
              },
            })
          } else if (this.orderType === 4) {
            this.$router.replace({
              name: 'h5-home',
            })
          }
        } else {
          // 改签
          if (this.orderType === 1) {
            this.$router.replace({
              name: 'flight-change-order-detail',
              query: {
                  orderNo,
                  jumpType: 'list'
              },
            })
          } else if (this.orderType === 2) {
            this.$router.replace({
              name: 'h5-train-change-order-detail',
              query: {
                orderNo
              },
            })
          }
        }
      } else {
        this.$router.replace({
          name: 'h5-office-evection',
        })
      }
    },
  },
  mounted(){
    this.getDataInit();
  },
  activated() {

  },
};
