import consumer_sys_sms_vcodeSend from '@/lib/data-service/haolv-default/consumer_sys_sms_vcodeSend';//获取验证码
import consumer_sys_sms_vcodeVerify from "@/lib/data-service/haolv-default/consumer_sys_sms_vcodeVerify";// 验证手机号码和验证码
import consumer_user_updatePhone from "@/lib/data-service/haolv-default/consumer_user_updatePhone";

export default {
    data() {
        return {
            phone: '', //新手机号
            code: '', //验证码
            second: 60, //倒计时秒数
            isSending: false, //是否正在发送验证码
            errPhoneMsg: '', //手机号错误信息
            errCodeMsg: '', //验证码错误信息
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        //初始化
        init() {
            this.phone = '';
            this.code = '';
            clearInterval(this.intervalFun);
            this.second = 60;
            this.isSending = false;
            this.errPhoneMsg = '';
            this.errCodeMsg = '';
        },

        //获取验证码
        async getCode() {
            if (this.isSending || !this.checkPhone()) {
                return;
            }
            this.isSending = true;
            clearInterval(this.intervalFun);
            this.setInterval();
            let params = {
                needChangePhone: true,
                phone: this.phone,
                sence: 'ALIYUN_VCODE_CHANGE_INFO',
                check_token: true,
                enable_error_alert: false
            }
            consumer_sys_sms_vcodeSend(params).then(res => {
                if (res.datas.code !== 'OK') {
                    clearInterval(this.intervalFun);
                    this.second = 60;
                    this.isSending = false;
                    this.errPhoneMsg = res.datas.message;
                    return;
                }
            }).catch(err => {
                clearInterval(this.intervalFun);
                this.second = 60;
                this.isSending = false;
                this.$toast(err.data.msg);
                return;
            })
        },

        //检查表单
        checkForm() {
            let isPhoneOk = this.checkPhone();
            let isCodeOk = this.checkCode();
            return isPhoneOk && isCodeOk;
        },

        //检查手机号
        checkPhone() {
            if (this.phone === '') {
                this.errPhoneMsg = '请输入新手机号码';
                return false;
            }

            const regex = /^1[3-9]\d{9}$/;
            if (!regex.test(this.phone)) {
                this.errPhoneMsg = '请输入正确的新手机号码';
                return false;
            }
            this.errPhoneMsg = '';
            return true;
        },

        //检查验证码
        checkCode() {
            if (!this.code) {
                this.errCodeMsg = '请输入验证码';
                return false;
            }

            if (this.code.length !== 6) {
                this.errCodeMsg = '验证码格式不正确';
                return false;
            }
            this.errCodeMsg = '';
            return true;
        },

        //提交
        async submit() {
            if (!this.checkForm()) {
                return;
            }

            let params = {
                phone: this.phone,
                vcode: this.code,
            }
            consumer_user_updatePhone(params).then(res => {
                if (!res.datas) {
                    this.errCodeMsg = res.msg;
                    return;
                }
                this.$toast("修改手机号码成功");
                this.$router.go(-2);
            }).catch(err => {
                this.errCodeMsg = err.data.msg;
                return;
            });
        },

        //倒计时
        setInterval() {
            this.intervalFun = setInterval(() => {
                this.second--;
                if (this.second <= 0) {
                    clearInterval(this.intervalFun);
                    this.second = 60;
                    this.isSending = false;
                }
            }, 1000)
        },

        //返回
        back() {
            this.$router.go(-1);
        },
    }
}
