import moment from "moment"

import ApprovalProcess from "@/component/approval-process/1.0.0/index.vue"
import ApprovePass
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/approval-process-approval-process/1.0.1/index.vue"
import ApprovalProgressForWorkflow from "@/component/approval-progress-for-workflow/index.vue";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import overproofDetail from "@/lib/data-service/haolv-default/consumer_overproof_detail"   // 超标单详情
import repealApply from "@/lib/data-service/haolv-default/consumer_apply_repealApply"   // 撤销申请
import aduitOverproofOnBusiness from "@/lib/data-service/haolv-default/consumer_apply_aduitOverproofOnBusiness"
import {
    formatStartNodeShow,
    getProcessInstanceInfo,
    operablePermissions
} from "@/lib/data-service/haolv-default/design";
import RollbackModal from "@/page/office/approve-detail/1.0.5/components/process/RollbackModal.vue";
import DeleteMultiModal from "@/page/office/approve-detail/1.0.5/components/process/DeleteMultiModal.vue";
import ResolveModal from "@/page/office/approve-detail/1.0.5/components/process/ResolveModal.vue";
import RevokeModal from "@/page/office/approve-detail/1.0.5/components/process/RevokeModal.vue";
import RefuseModal from "@/page/office/approve-detail/1.0.5/components/process/RefuseModal.vue";
import DelegateModal from "@/page/office/approve-detail/1.0.5/components/process/DelegateModal.vue";
import AgreeModal from "@/page/office/approve-detail/1.0.5/components/process/AgreeModal.vue";
import AssigneeModal from "@/page/office/approve-detail/1.0.5/components/process/AssigneeModal.vue";
import AddMultiModal from "@/page/office/approve-detail/1.0.5/components/process/AddMultiModal.vue";   // 因公改版超标申请审批

export default {
    components: {
        AddMultiModal,
        AssigneeModal,
        AgreeModal,
        DelegateModal,
        RefuseModal,
        RevokeModal,
        ResolveModal,
        DeleteMultiModal,
        RollbackModal,
        NavBar,
        ApprovalProcess,
        ApprovePass,
        CollapseCard: () => import('./components/collapse-card/index.vue'),
        ApprovalProgressForWorkflow,
    },

    data() {
        return {
            flightReturnShow: false,
            loading: false,
            type: '',   // 类型：“applyDetail”-申请详情，“approve”-审核，“approveDetail”-审核详情
            form: '',   // 类型：flutter
            applyId: 0,   // 申请单id
            applyNo: '',
            approveStatus: '',
            selectType: '',

            exceedDetail: {},
            businessType: 0,   // 业务类型：1.机票，2.火车票，3.酒店
            status: 0,   // 审批状态：1.审核中，2.审批同意，3.已驳回，4.已撤销

            flightData: {},   // 机票
            trainData: {},   // 火车票
            hotelData: {},   // 酒店
            orderInfo: {},   // 酒店订单
            exceedInfoData: {},   // 超标信息
            exceedInfoList: [],   // 超标
            approvalParams: {},   // 审批进度传参
            approvalData: [],   // 审批进度
            applyInfoData: {},   // 出差信息

            record: {},   // 超标审批流程

            failVisible: false,   // 审批拒绝
            rejectReason: '',   // 拒绝理由

            workflowList: [], // 新版审批流流程数组
            opaList: [], // 新版审批流操作按钮权限列表
            processInfo: {}, // 提交操作表单
            opaShow: false,
            modalConfig: {
                agreeVisible: false,
                commentVisible: false,
                delegateVisible: false,
                assigneeVisible: false,
                resolveVisible: false,
                refuseVisible: false,
                rollbackVisible: false,
                addMultiVisible: false,
                queryMultiUserVisible: false,
                revokeVisible: false,
                deleteMultiVisible: false
            },

            isFinish: true,
        }
    },
    async activated() {
        this.form = this.$route.query.form ? this.$route.query.form : '';
        this.type = this.$route.query.type;
        this.applyId = this.$route.query.applyId;
        this.applyNo = this.$route.query.applyNo;
        this.approveStatus = parseInt(this.$route.query.approveStatus) || '';
        this.selectType = parseInt(this.$route.query.selectType) || '';
        await this.$store.state.workflowDefer.promise;
        await this.$store.state.userInfoDefer.promise;
        // this.applyId = parseInt(this.$route.query.applyId);
        this.getExceedDetail()
    },
    deactivated() {
        this.$Toast.clear()
    },
    watch: {
        loading(val) {
            if (val) {
                this.$Toast.loading({
                    duration: 0,
                    message: "加载中...",
                    forbidClick: true
                })
            } else {
                this.$Toast.clear()
            }
        }
    },
    computed:{
        showMoreOpa() {
            let moreBtnList = [];
            let moreBtn = ['reject', 'frontJoin', 'backJoin', 'addAssignee', 'delAssignee'];
            this.opaList.forEach(value => {
                if (moreBtn.indexOf(value.key) > -1 && value.checked) {
                    moreBtnList.push(value);
                }
            })
            return moreBtnList;
        },
    },
    filters: {
        format_monthDay(val) {
          return val ? moment(val).format("MM-DD") : ""
        },
        format_time(val) {
            return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : ""
        },
        filterWeek(val) {
            if (val === "") {
                return ""
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日"
            } else if (day === 1) {
                return "周一"
            } else if (day === 2) {
                return "周二"
            } else if (day === 3) {
                return "周三"
            } else if (day === 4) {
                return "周四"
            } else if (day === 5) {
                return "周五"
            } else if (day === 6) {
                return "周六"
            } else {
                return ""
            }
        },
        filterCabinRank(val) {
            if (parseInt(val) === 1) {
                return '头等舱'
            } else if (parseInt(val) === 2) {
                return '公务舱'
            } else if (parseInt(val) === 3) {
                return '经济舱'
            } else {
                return ''
            }
        }
    },
    methods: {
        getDays (depDate,arrDate){
          // depDate = '2024-01-15';
          // arrDate = '2024-01-16';
          return moment(arrDate).diff(depDate, 'day')
        },
        backFlightReturn() {
          this.flightReturnShow = false;
        },
        // 机票往返详情
        flightReturnClick() {
          this.flightReturnShow = true;
        },
        _back(){
            this.$router.go(-1);
        },
        // 超标单详情
        async getExceedDetail() {
            try {
                this.loading = true;
                const res = await overproofDetail({
                    applyId: this.applyId,
                    applyNo: this.applyNo,
                    appType: this.$route.query.type === 'applyDetail' ? 1 : 2,
                    approveStatus: this.approveStatus,
                    selectType: this.selectType,
                });
                this.exceedDetail = res.datas;
                this.businessType = this.exceedDetail.evectionDetailResponse.businessType;
                this.applyInfoData = this.exceedDetail.evectionDetailResponse;
                this.status = this.exceedDetail.status;
                this.approvalData = this.exceedDetail.tusApproveRecords;
                this.approvalParams = {
                    approveType: 2,   // approveType 审批类型：1.出差申请，2报销申请，3.超标申请，4.改签，5.退票
                    applyNo: this.exceedDetail.applyNo,   // 申请单编号
                    approveFlowId: this.exceedDetail.approveFlowId,   // 申请流水编号
                    isHasFlow: this.exceedDetail.isHasFlow,   // 是否有审批流程
                    isLastNode: this.exceedDetail.isLastNode   // 是否最后的节点
                };
                if (this.businessType === 1) {
                    this.flightData = this.exceedDetail.orderDetailResponse;
                    this.flightData.applyId = this.exceedDetail.evectionApplyId;
                    if (this.flightData.theFlightInformationList) {
                        this.flightData.theFlightInformationList.forEach(value => {
                            value.ruleText = [];
                            value.luggage = ''
                        })
                    }
                    this.exceedInfoData = this.flightData;
                    this.exceedInfoList = this.exceedInfoData.trainsOrderTipsResponses
                }
                if (this.businessType === 2) {
                    this.trainData = this.exceedDetail.trainsOrderDetailResponse;
                    this.trainData.applyId = this.exceedDetail.evectionApplyId;
                    this.exceedInfoData = this.trainData;
                    this.exceedInfoList = this.exceedInfoData.trainstrainsOrder
                }
                if (this.businessType === 3) {
                    this.hotelData = this.exceedDetail.todHotelOrderInterimResponse;
                    this.orderInfo = this.hotelData.orderInterimResult;
                    this.exceedInfoData = this.hotelData;
                    this.exceedInfoList = this.exceedInfoData.hoteltrainsOrderTipsResponses
                }
                if (this.approvalData.length) {
                    this.approvalData.forEach(value => {
                        this.record = value
                    })
                }
                if (this.$store.state.workflow === '2') {
                    this.$nextTick(() => {
                        this.$refs.approvalProcess.init({
                            showSwitch: (this.exceedDetail.processInstanceId == null || this.exceedDetail.processInstanceId == undefined || this.exceedDetail.processInstanceId == ''),
                            status: res.datas.status,
                            statusText: res.datas.statusText,
                            approvalData: this.approvalData,
                            approvalReason: res.datas.approvalReason,
                            btnParams: {
                                isShow: false,
                                approvalParams: {}
                            }
                        })
                    })
                }

                if (this.$store.state.workflow === '1') {
                    let budgetAmount = 0;
                    if (this.exceedDetail.evection) {
                        budgetAmount = this.exceedDetail.evection.budgetAmount
                    } else if (this.exceedDetail.budgetAmount) {
                        budgetAmount = this.exceedDetail.budgetAmount
                    }
                    const params = {
                        processInstanceId: this.applyNo,
                        userId: this.$store.state.userInfo.id,
                        deptId: this.$store.state.userInfo.deptId,
                        budgetAmount: budgetAmount,
                    }
                    console.log(params);
                    formatStartNodeShow(params).then((res2)=>{
                        console.log('res2', res2)
                        let list = res2.result;
                        let result = [];
                        list.forEach((value, index)=>{
                            if (value.showTimeStr && value.showTimeStr !== 'null') {
                                value.showTimeStr = moment(value.showTimeStr).format('yyyy-MM-DD HH:mm:ss');
                            } else {
                                value.showTimeStr = '';
                            }
                            result.push(value);
                        })
                        console.log(result);
                        this.workflowList = result;
                    })
                    console.log('123')

                    this.processInfo = {
                        processInstanceId: this.applyNo,
                        taskId: res.datas.taskId,
                        signFlag: null,
                        currentUserInfo: {
                            id: this.$store.state.userInfo.id,
                        },
                    }
                    console.log('333', res)
                    // 只有审批中才能调
                    if (res.datas.status === 1) {
                        operablePermissions({
                            processInstanceId: this.applyNo,
                            userId: this.$store.state.userInfo.id,
                            taskId: res.datas.taskId,

                        }).then((result) => {
                            this.opaList = result.result || [];
                        })
                    }
                    console.log('444')
                    getProcessInstanceInfo(this.applyNo, res.datas.taskId).then(resultInfo=>{
                        const formData = { ...resultInfo.result.formData };
                        console.log('formData', formData);
                        this.processInfo.formData = formData;
                    })
                }
            } catch (error) {

            } finally {
                this.loading = false
            }
        },
        // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效，5.发起人
        getRecordStatus(val) {
            if (val === 0) {
                return 'wait'
            } else if (val === 1) {
                return 'wait'
            } else if (val === 2) {
                return 'pass'
            } else if (val === 3 || val === 4) {
                return 'fail'
            } else {
                return ''
            }
        },
        recordEnd(val) {
            if (!Object.keys(val).length) {
                return false
            }
            if (val.approveStatus === 2 || val.approveStatus === 3 || val.approveStatus === 4) {
                return true
            } else {
                return false
            }
        },
        // 跳转
        handleDetailClick(val) {
            if (this.flightData.orderNo === val) {
                this.$router.push({
                    name: "flight-order-detail",
                    query: {
                        orderNo: this.flightData.orderNo
                    }
                })
            }
            if (this.trainData.orderNo === val) {
                this.$router.push({
                    name: "h5-train-order-detail",
                    query: {
                        orderNo: this.trainData.orderNo
                    }
                })
            }
            if (this.orderInfo.orderNo === val) {
                this.$router.push({
                    name: "h5-hotel-order-detail",
                    query: {
                        orderNo: this.orderInfo.orderNo
                    }
                })
            }
            if (this.applyInfoData.evectionNo === val) {
                this.$router.push({
                    name: "h5-approve-detail",
                    query: {
                        id: this.applyInfoData.evectionApplyId,
                        approveType: 1,
                        type: "detail"
                    }
                })
            }
        },

        handleDetailClick_applyInfoData(val) {
            const __this = this;

            const $router = __this.$router;

            const applyInfoData = __this.applyInfoData;
            const evectionApplyId = applyInfoData.evectionApplyId;
            if (!evectionApplyId) return;

            $router.push({
                name: "h5-approve-detail",
                query: {
                    id: evectionApplyId,
                    approveType: 1,
                    type: "detail"
                }
            })

        },

        getTime(gmtStart, gmtArrive) {
            let oldTime = new Date(gmtStart);
            let newTime = new Date(gmtArrive);

            let difftime = (newTime - oldTime) / 1000;   // 计算时间差,并把毫秒转换成秒

            let days = parseInt(difftime / 86400);
            let hours = parseInt(difftime / 3600) - 24 * days;
            let minutes = parseInt(difftime % 3600 / 60);

            if (days == 0) {
                return `${hours}时${minutes}分`
            } else {
                return `${days}天${hours}时${minutes}分`
            }

        },
        datedifference(sDate1, sDate2) {
            let dateSpan, iDays;
            sDate1 = Date.parse(sDate1);
            sDate2 = Date.parse(sDate2);
            dateSpan = sDate2 - sDate1;
            dateSpan = Math.abs(dateSpan);
            iDays = Math.floor(dateSpan / (24 * 3600 * 1000));
            return iDays
        },
        // 撤销
        handleCancelClick() {
            this.$Dialog.confirm({
                title: '提示',
                message: '确定要撤销吗？',
                confirmButtonText: "确定",
                cancelButtonText: "取消"
            })
                .then(() => {
                    repealApply({applyNo: this.exceedDetail.applyNo}).then((res) => {
                        this.$Toast.success("撤销成功！");
                        setTimeout(() => {
                            this.getExceedDetail(true)
                        }, 1000)
                    })
                })
                .catch(() => {
                })
        },
        // 审批通过
        applyPass() {
            this.$refs.approvePass.run(this.approvalParams).then(() => {
                this.$router.push({name: "h5-my-approve-list"})
            }).catch(() => {
            })
        },
        // 审批拒绝
        applyReject() {
            this.rejectReason = '';
            this.failVisible = true
        },
        // 取消
        cancelReject() {
            this.failVisible = false;
            this.$refs.failForm.resetValidation("rejectReason")
        },
        // 确认拒绝
        determineReject() {
            this.$refs.failForm.validate().then(() => {
                let data = {
                    applyNo: this.approvalParams.applyNo,
                    approveStatus: 3,   // 审核状态：2.通过，3.拒绝
                    rejectReason: this.rejectReason
                };
                aduitOverproofOnBusiness(data).then(() => {
                    this.failVisible = false;
                    this.$Toast.success("审批拒绝！");
                    setTimeout(() => {
                        this.$router.push({name: "h5-my-approve-list"})
                    }, 1000)
                }).catch(() => {
                })
            }).catch((err) => {
                console.error(err, "err")
            })
        },
        newPass(val) {
            this.opaShow = false;
            switch (val.key) {
                case 'pass':
                    // 通过
                    this.onAgree();
                    break
                case 'refuse':
                    // 拒绝
                    this.onRefuse();
                    break
                case 'reject':
                    // 驳回
                    this.onRollback();
                    break
                case 'frontJoin':
                    // 委派
                    this.onDelegateTask();
                    break
                case 'backJoin':
                    // 转办
                    this.onAssignee();
                    break
                case 'addAssignee':
                    // 加签
                    this.onAddMulti();
                    break
                case 'delAssignee':
                    // 减签
                    this.onDeleteMulti();
                    break
            }
        },
        handleCommand() {
            this.opaShow = true;
        },
        onAgree() {
            this.modalConfig.agreeVisible = true;
        },
        onDelegateTask() {
            this.modalConfig.delegateVisible = true;
        },
        onRefuse() {
            this.modalConfig.refuseVisible = true
        },
        onAssignee() {
            this.modalConfig.assigneeVisible = true
        },
        onRollback() {
            this.modalConfig.rollbackVisible = true;
        },
        onAddMulti() {
            this.modalConfig.addMultiVisible = true;
        },
        onDeleteMulti() {
            this.modalConfig.deleteMultiVisible = true;
        },
        onResolveTask() {
            this.modalConfig.resolveVisible = true
        },
        _success() {
            console.log('33')
            this._back();
        },
    }
}
