import extend from 'extend';
import Q from 'q';
import _ from 'underscore';
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/get_user_info';

import api_ready_helper from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/api_ready_helper';

const init_userId = `init_userId`;
const init_userName = `init_userName`;

let last_userId = ``;
let last_userName = ``;

const init = function () {
    return Q.when()
        .then(function () {
            return api_ready_helper.get_api_ready_promise();
        })
        .then(function (data) {
            //alert(`lyn测试，不用理会：111`);
            const api = global.api;
            const ajpush = api.require('ajpush');

            const defer = Q.defer();
            ajpush.setAuth({
                auth:true
            },function(ret) {
                if (ret && ret.status){
                    //success
                    ajpush.init(function (ret) {
                        //alert(`lyn测试，不用理会：222`);
                        if (ret && ret.status) {
                            //alert(`lyn测试，不用理会：333`);
                            //success
                            defer.resolve();
                            return;
                        }
                        //alert(`lyn测试，不用理会：444`);
                        defer.reject();
                    });
                }
            });


            // ajpush.getRegistrationId(function (ret) {
            //     const registrationId = ret.id;
            //     console.log(`registrationId=${registrationId}`);
            //     alert(`registrationId=${registrationId}`);
            // });

            return defer.promise;
        })
        .catch(function (ex) {
            if (ex) {
                const enable_log_error = !ex.disable_log_error;
                if (enable_log_error) {
                    console.error(ex);
                    console.trace();
                    if (ex.stack) {
                        console.error(ex.stack);
                    }
                }
            }
            return Q.reject(ex);
        })
        ;
};

const bind_init_user = function () {
    return Q.when()
        .then(function () {
            return api_ready_helper.get_api_ready_promise();
        })
        .then(function () {
            const userId = init_userId;
            const userName = init_userName;

            last_userId = userId;
            last_userName = userName;

            const api = global.api;
            const ajpush = api.require('ajpush');

            console.log(`@/lib/apicloud/ajpush_helper:bind_init_user:userId=${userId}`);
            console.log(`@/lib/apicloud/ajpush_helper:bind_init_user:userName=${userName}`);

            const defer = Q.defer();
            const p = {alias: userId, sequence:100};
            ajpush.setAlias(p,(ret) => {
                const statusCode = ret.statusCode;
                // alert(`statusCode=${statusCode}`);
                console.log(`@/lib/apicloud/ajpush_helper:bind:setAlias:statusCode=${statusCode}`);
                if (statusCode === 0) {
                    defer.resolve();
                }
                defer.reject();
            });


            /*const p = {alias: userId, tags: []};
            // alert(`lyn测试，不用理会：111`);
            ajpush.bindAliasAndTags(p, function (ret) {
                const statusCode = ret.statusCode;
                // alert(`statusCode=${statusCode}`);
                console.log(`@/lib/apicloud/ajpush_helper:bind_init_user:bindAliasAndTags:statusCode=${statusCode}`);
                // alert(`@/lib/apicloud/ajpush_helper:bind_init_user:bindAliasAndTags:statusCode=${statusCode}`);
                if (statusCode === 0) {
                    defer.resolve();
                }
                defer.reject();
            });*/

            return defer.promise;
        })
        .catch(function (ex) {
            if (ex) {
                const enable_log_error = !ex.disable_log_error;
                if (enable_log_error) {
                    console.error(ex);
                    console.trace();
                    if (ex.stack) {
                        console.error(ex.stack);
                    }
                }
            }
            return Q.reject(ex);
        })
        ;
};

const bind = function () {
    return Q.when()
        .then(function () {
            return api_ready_helper.get_api_ready_promise();
        })
        .then(function () {
            return get_user_info();
        })
        .then(function (data) {
            const datas = data.datas;
            const userId = datas.id;
            const userName = datas.userName;

            last_userId = userId;
            last_userName = userName;

            const api = global.api;
            const ajpush = api.require('ajpush');

            console.log(`@/lib/apicloud/ajpush_helper:bind:userId=${userId}`);
            console.log(`@/lib/apicloud/ajpush_helper:bind:userName=${userName}`);
            //alert('2222测试')
            const defer = Q.defer();
            const p = {alias: userId, sequence:100};
            ajpush.setAlias(p,(ret) => {
                const statusCode = ret.statusCode;
                //alert(`statusCode=${statusCode}`);
                console.log(`@/lib/apicloud/ajpush_helper:bind:setAlias:statusCode=${statusCode}`);
                if (statusCode === 0) {
                    defer.resolve();
                }
                defer.reject();
            });

            // 监听
            /*ajpush.setListener(
                function(ret) {
                    //alert('监听触发');
                    var id = ret.id;
                    var title = ret.title;
                    var content = ret.content;
                    var extra = ret.extra;
                }
            );*/

            /*const p = {alias: userId, tags: []};
            ajpush.bindAliasAndTags(p, function (ret) {
                const statusCode = ret.statusCode;
                // alert(`statusCode=${statusCode}`);
                console.log(`@/lib/apicloud/ajpush_helper:bind:bindAliasAndTags:statusCode=${statusCode}`);
                if (statusCode === 0) {
                    defer.resolve();
                }
                defer.reject();
            });*/

            return defer.promise;
        })
        .catch(function (ex) {
            if (ex) {
                const enable_log_error = !ex.disable_log_error;
                if (enable_log_error) {
                    console.error(ex);
                    console.trace();
                    if (ex.stack) {
                        console.error(ex.stack);
                    }
                }
            }
            return Q.reject(ex);
        })
        ;
};

const init_for_route_guard = function ({to}) {
    const route_name_list = [
        `root`,
        `login`,
    ];
    const to_name = to.name;
    if (!_.contains(route_name_list, to_name)) return;

    return bind_init_user();
};

export default {
    init,
    bind_init_user,
    bind,
    init_for_route_guard,
}
