import import_latest_version_module from '@/component/h5-layout/import_latest_version_module'

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/apply-approve-exceed',
      name: 'h5-apply-approve-exceed',
      component: () => import(/* webpackChunkName: "page-office-applyApproveExceed" */ `./1.0.4/index.vue`),
      meta: {
        h5_layout: {
          show_header: true,
          current_page_name: '超标申请详情', // 出差审批超标详情
          need_head_nav: false,
          need_footer_bar: false,
          secondary_menu: 2,
          need_alive: true,
        }
      }
    }
  ]
}
