//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String,
            default: '标题'
        },
        showMore: {
            type: Boolean,
            default: true
        },
        detail: {
            type: Boolean,
            default: false
        },
        detailNumber: {
            type: String,
            default: ''
        },
        enable_detail_click: {
            type: Boolean,
            default: true,
        }
    },
    data() {
        return {
            show: true
        }
    },
    mounted() {
    },
    methods: {
        onToggle() {
            this.show = !this.show
        },
        handleDetailClick(val) {
            this.$emit('handleDetailClick', val)
        }
    }
}
