import NavBar from "@/component/nav-bar/import_latest_version_module";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
export default {
  data() {
    return {
      title: '火车票',
      orderNo: '',
      evectionType: 2
    }
  },
  components: {NavBar},
  created() {
  },
  mounted() {
    const _this = this;
    this.$nextTick(() => {
      fixStatusBar({
        handler({api}) {
          const bodyWidth = document.body.clientWidth;
          const headNav = _this.$refs.topElementNav.$el;
          const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
          headNav.style.paddingTop = addPaddingTop + 'vw';
        }
      });
    })
  },
  activated() {
    this.orderNo = this.$route.query.orderNo || ''
  },
  deactivated() {
  },
  destroyed() {
  },
  watch: {},
  computed: {},
  filters: {},
  methods: {
    goDetail() {
      if (this.orderNo) {
        this.$router.replace({
          name: 'h5-train-order-detail',
          query: {
            orderNo: this.orderNo,
            jumpType: 'list'
          }
        })
      }
    },
    back() {
      this.$router.push({
        name: 'h5-train-home',
      })
    },
  }
}