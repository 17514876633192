import NavBar from "@/component/nav-bar/import_latest_version_module";
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
import consumer_trains_order_qpOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_qpOrderDetail'
import consumer_trains_order_applyForCancel from "@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel";
import moment from 'moment'
import {Dialog, Toast} from "vant";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
export default {
    data() {
        return {
            detailShow: false,
            orderNo: '',
            detailInfo: {},
            intervalFun: '',
        }
    },
    components: {NavBar, TrainTimeTable},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo;
        this.getDetail();
        let __this = this;
        keyback_processor.addEventListener(() => {
            const lineShow = this.$refs['aTrainTimeTable'].get_show();
            if (lineShow) {
                this.$refs['aTrainTimeTable'].hide();
                return
            }

            __this.clickGoList();
        })
    },
    deactivated() {
        clearInterval(this.intervalFun);
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterIntervalTime(val) {
            if (val) {
                // 转换为式分秒
                let h = parseInt(val / 60 / 60 % 24);
                h = h < 10 ? '0' + h : h;
                let m = parseInt(val / 60 % 60);
                m = m < 10 ? '0' + m : m;
                let s = parseInt(val % 60);
                s = s < 10 ? '0' + s : s;
                let text = `${m}分${s}秒`;
                if (h !== '00') {
                    text = h + '时' + text
                }
                return text
            } else {
                return '00分00秒'
            }
        },
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
    },
    methods: {
        clickGoList() {
            if (this.intervalFun != null) {
                clearInterval(this.intervalFun);
            }
            if (this.$route.query.jumpType) {
                if (this.$route.query.jumpType === 'list') {
                    this.$router.push({
                        name: 'h5-train-order-list'
                    })
                } else {
                    this.$router.go(-1)
                }
            } else {
                this.$router.go(-1)
            }
        },
        openTimeLine() {
            this.$refs['aTrainTimeTable'].show();
        },
        showDetail() {
            this.detailShow = !this.detailShow
        },
        getDetail() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            const params = {
                orderNo: this.orderNo
            };
            consumer_trains_order_qpOrderDetail(params).then(res => {
                Toast.clear();
                let detailInfo = res.datas;
                if (detailInfo.orderStatus !== 2 && detailInfo.orderStatus !== 7) {
                    this.$router.replace({
                        name: 'h5-train-order-detail',
                        query: {
                            orderNo: this.orderNo
                        }
                    });
                    return
                }
                this.detailInfo = detailInfo;
                this.$nextTick(() => {
                    this.$refs['aTrainTimeTable'].init({
                        //出发站三字码
                        fromStationCode: this.detailInfo.fromStationCode,
                        //到达站三字码
                        toStationCode: this.detailInfo.toStationCode,
                        //车次号
                        trainCode: this.detailInfo.trainNo,
                        //日期(2020-05-18)
                        trainDate: this.detailInfo.startDate,
                    });
                });

                if (this.detailInfo.milliseconds > 0) {
                    this.setPayInterval()
                }
            }).catch(() => {
                Toast.clear();
            })
        },
        setPayInterval() {
            this.intervalFun = setInterval(() => {
                this.detailInfo.milliseconds--;
                if (this.detailInfo.milliseconds <= 0) {
                    clearInterval(this.intervalFun);
                    // this.getDetail();
                    this.$router.go();
                }
            }, 1000)
        },
        setOrderWidth(val) {
            if (!val.robStartTime) {
                return 'width: 0%'
            }
            const startTimeVal = moment(val.robStartTime).valueOf();
            const endTimeVal = moment(val.robFinalTime).valueOf();
            const todayVal = moment().valueOf();
            let width = parseFloat((todayVal - startTimeVal)/(endTimeVal - startTimeVal)*100);
            if (width >= 90) {
              width = 92;
            }
            return `width: ${width}%`
        },
        cancelOrder() {
            let _this = this;
            Dialog.confirm({
                title: '确认要取消订单吗？',
                message: '一天仅有3次取消订单的机会（包括自动取消），超过3次后当日将无法使用12306账号预订。',
                confirmButtonText: '取消订单',
                cancelButtonText: '稍后再说',
            }).then(() => {
                let loading = this.$Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true
                });
                consumer_trains_order_applyForCancel({orderNo: _this.orderNo})
                    .then(res => {
                        _this.$Toast.clear();
                        Dialog.alert({
                            title: '取消预订提示',
                            message: '你已成功取消预订',
                        }).then(() => {
                            this.$router.replace({
                                name: 'h5-train-order-detail',
                                query: {
                                    orderNo: _this.orderNo
                                }
                            })
                        })
                    }).catch(() => {
                    _this.$Toast.clear();
                    Dialog.alert({
                        title: '取消预订提示',
                        message: '订单取消失败',
                    })
                })
            }).catch(() => {

            });
        },
        toPayment() {
            this.$router.push({
                name: "h5-travel-payment",
                query: {
                    orderNo: this.orderNo,
                    orderType: "3",
                },
            });
        },
    }
}
