import TrainCreateOrder from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-create-order/1.1.3/index.vue'//创建订单组件
import TrainCreatePrivateOrder  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-create-private-order/1.0.3/index.vue'//因私创建订单组件
import consumer_insurance_saveChoosePackage from '@/lib/data-service/haolv-default/consumer_insurance_saveChoosePackage';
import NavBar from "@/component/nav-bar/import_latest_version_module";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
import {Toast, Dialog} from 'vant';
export default {
    data() {
        return {
            useTravel: null,
            dataBox: {

            },
        }
    },
    components: {
        TrainCreatePrivateOrder,
        TrainCreateOrder,
        NavBar,
    },
    created() {
    },
    mounted() {

    },
    async activated() {
        let _this = this;
        _this.dataBox = JSON.parse(_this.$route.query.data);
        _this.dataBox.insuranceFun = function () {
            // productCodes
            // 提交保险
            consumer_insurance_saveChoosePackage({
                businessType: 2,
                orderNo: _this.dataBox.orderNo,
                productCodes: _this.dataBox.productCodes ? _this.dataBox.productCodes : [],
                evectionType: _this.dataBox.evectionType ? _this.dataBox.evectionType : 2,
            })
      }
        _this.useTravel = _this.$route.query.useTravel;
      if (_this.useTravel == 1) {
          _this.$refs.aTrainCreateOrder.init();
          _this.$refs.aTrainCreateOrder.run(_this.dataBox);
      } else {
          _this.$refs.aTrainCreatePrivateOrder.run(_this.dataBox);
      }


      const __this = this;
      keyback_processor.addEventListener(function () {

        __this.back();
      });
    },
    deactivated() {

    },
    destroyed() {
    },
    watch: {

    },
    computed: {},
    filters: {

    },
    methods: {

        back() {
            // this.$router.go(-1);
            this.$router.push({
              name: `h5-train-order-detail`,
              query: {
                orderNo: this.dataBox.orderNo,
                jumpType: 'list',
              },
            });
            // Dialog.confirm({
            //     title: '温馨提示',
            //     message: '您的订单尚未填写完成，确认离开当前页面？',
            //     cancelButtonText: '离开',
            //     confirmButtonText: '继续预订',
            //     confirmButtonColor: '#FF7002',
            //     className: 'reserve-back-warning-dialog',
            // })
            //   .then(() => {
            //       // on confirm
            //   })
            //   .catch(() => {
            //       // on cancel
            //       this.$router.go(-1)
            //   });
        },

    }
}
