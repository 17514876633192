const get_tab_list = function () {
    const tab_list = [
        {
            title: `首页`,
            icon: `haolv-enterprise-app-iconfont haolv-enterprise-app-iconhome-5`,
            badge: undefined,
            to: {name: `h5-home`}
        },
        {
            title: `出差`,
            icon: `haolv-enterprise-app-iconfont haolv-enterprise-app-iconevection-5`,
            badge: undefined,
            // to: {name: `h5-add-evection`}
            to: {name: `h5-office-evection`}
        },
        /*{
            title: `订单`,
            icon: `haolv-enterprise-app-iconfont haolv-enterprise-app-iconorder-5`,
            badge: undefined,
            to: {name: `h5-office-order`}
        },*/
        {
            title: `办公`,
            icon: `haolv-enterprise-app-iconfont haolv-enterprise-app-iconoffice-5`,
            badge: undefined,
            to: {name: `h5-office-home`}
        },
        {
            title: `我的`,
            icon: `haolv-enterprise-app-iconfont haolv-enterprise-app-iconmy-5`,
            badge: undefined,
            to: {name: `h5-personal-center`}
        },

    ];
    return tab_list;
};

export default get_tab_list;
