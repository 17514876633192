// consumer_car_link_getGaodeLinkByPolicyId
const __request = require(`./__request/__request_contentType_json`)
const consumer_car_link_getGaodeLinkByPolicyId = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/car/link/getGaodeLinkByPolicyId',
        data: data,
        enable_error_alert: false // 临时隐藏
    }
    return __request(pParameter)
}
export default consumer_car_link_getGaodeLinkByPolicyId
