export default {
    data() {
        return {}
    },
    computed: {
        routeParam() {
            return this.$route.query;
        },
    },
    methods: {

    },
}