const Q = require('q');
const to_int = require('nllib/src/convert/to_int');
const __request = require(`./__request`);

const api_jiguang_push = function (pParameter) {
    if (!pParameter) pParameter = {};
    const content = pParameter.content || ``;
    const alias = pParameter.alias || ``;

    return Q.when()
        .then(function () {
            return __request({
                method: 'post',
                url: '/api/jiguang/push',
                data: {
                    content,
                    alias,
                },
            });
        })
        .then(function (data) {
            if (!data) {
                const msg = `api_jiguang_push parameter error, code=000`;
                console.error(msg);
                console.trace();
                return Q.reject({msg: msg});
            }
            return Q.resolve(data);
        })
        .catch(function (ex) {
            console.error(ex);
            if (ex) {
                if (ex.stack) {
                    console.error(ex.stack);
                }
            }
            return Q.reject(ex);
        })
        ;

};

module.exports = api_jiguang_push;
