//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    index: { // index of current item
      type: Number
    },
    source: { // here is: {uid: 'unique_1', text: 'abc'}
      type: Object,
      default () {
        return {}
      }
    },
    checked: Array,
  },
  methods: {
    brandToggle() {
      this.$refs.brandCheckboxes.toggle();
    },
  },
}
