import wangyiServerOnline from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index";
export default {
    data() {
        return {}
    },
    props: {},
    components: {},
    created() {
    },
    mounted() {

    },
    activated() {
        wangyiServerOnline.config();
        wangyiServerOnline.showTalkWin();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {}
}