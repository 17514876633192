// consumer_web_externalCustomer_getExternalCustomerAll 获取外部客户列表
// 接口文档地址：http://192.168.0.13:8765/doc.html#/haolv-consumer/t-us-external-customer-controller/getExternalCustomerAllUsingPOST
const __request = require(`./__request/__request_contentType_json`);
const consumer_web_externalCustomer_getExternalCustomerAll = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/web/externalCustomer/getExternalCustomerAll',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_web_externalCustomer_getExternalCustomerAll;
