//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import _ from 'underscore';
    import VirtualList from 'vue-virtual-scroll-list';
    import BrandItem from './BrandItem.vue';
    import HotelAreaSelector
        from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/hotel-area-selector/1.0.1/index.vue'
    import consumer_hotel_search_getHotelBrandList
        from "@/lib/data-service/haolv-default/consumer_hotel_search_getHotelBrandList.js";

    export default {
        components: {
            VirtualList,
            HotelAreaSelector
        },
        props: {
            hotelStarList: {
                type: Array,
                default() {
                    return [];
                },
            },
            hotelBrandList: {
                type: Array,
                default() {
                    return [];
                },
            },
            cityId: {
                type: [Number, String],
                default: ''
            },
            evectionDetailType: {
                type: String,
                default() {
                    return '101';
                }
            },
            isFirstLoad: {
                type: Boolean,
                default: true
            },
            isPOIName: {
                type: Boolean,
                default: false
            },
            cityName: {
                type: String,
                default() {
                    return '';
                }
            },
        },
        data() {
            return {
                switch1: false,
                switch2: false,

                salesSort: '',
                salesSortList: [
                    {
                        name: '综合排序',
                        value: '',
                    },
                    {
                        name: '销量高到低',
                        value: 'desc',
                    },
                    {
                        name: '销量低到高',
                        value: 'asc',
                    },
                ],

                hotelStar: '',
                sliderValue: [0, 1050],
                miniPrice: '',
                highestPrice: '',

                // hotelBrandSidebar: '',

                hotelBrand: [],
                selectedBrandNameList: [],
                itemComponent: BrandItem,

                hotelTheme: [],
                hotelThemeList: [
                    {
                        value: 'biz',
                        label: '商务酒店',
                    },
                    {
                        value: 'spa',
                        label: '温泉SPA',
                    },
                    {
                        value: 'economy',
                        label: '经济型酒店',
                    },
                    {
                        value: 'vacation',
                        label: '山水名胜',
                    },
                    {
                        value: 'huiyi',
                        label: '会议酒店',
                    },
                    {
                        value: '1hx',
                        label: '冰雪假期',
                    },
                    {
                        value: '2hb',
                        label: '阳光沙滩',
                    },
                    {
                        value: '3dm',
                        label: '大漠绿洲',
                    },
                    {
                        value: '4gz',
                        label: '古镇风情',
                    },
                    {
                        value: '5dj',
                        label: '高尔夫度假',
                    },
                    {
                        value: '6bw',
                        label: '台湾风情',
                    },
                    {
                        value: '7ds',
                        label: '都市购物',
                    },
                    {
                        value: '8hd',
                        label: '海岛休闲',
                    },
                    {
                        value: '9zt',
                        label: '主题乐园',
                    },
                    {
                        value: '10gd',
                        label: '高端奢华',
                    },
                    {
                        value: '11sw',
                        label: '商务精选',
                    },
                    {
                        value: '12bc',
                        label: '博彩娱乐',
                    },
                ],

                sortMenuList: [
                    {
                      id: 1,
                      name: '推荐排序',
                      isSelected: true
                    },
                    {
                        id: 2,
                        name: '位置区域',
                        isSelected: false
                    },
                    {
                        id: 3,
                        name: '价格/星级',
                        isSelected: false
                    },
                    {
                        id: 4,
                        name: '品牌连锁',
                        isSelected: false
                    },
                ],
                recommendList: [
                    {
                        id: 1,
                        code: 0,
                        name: '推荐排序',
                        isSelected: true
                    },
                    {
                        id: 2,
                        code: 2,
                        name: '价格 低-高',
                        isSelected: false
                    },
                    {
                        id: 3,
                        code: 3,
                        name: '价格 高-低',
                        isSelected: false
                    },
                    {
                        id: 4,
                        code: 1,
                        name: '直线距离 近-远',
                        isSelected: false
                    },
                ],
                sort: 0,
                isRightPrice: true,
                priceWarningText: '最低价需小于最高价，请重新输入',
                starList: [],
                starCodeList: [],
                currentBrandClassifiedId: 1,
                brandClassifiedList: [
                    {
                        id: 1,
                        name: '快捷连锁',
                        isSelected: true,
                        hasChecked: false
                    },
                    {
                        id: 2,
                        name: '中端连锁',
                        isSelected: false,
                        hasChecked: false
                    },
                    {
                        id: 3,
                        name: '高端连锁',
                        isSelected: false,
                        hasChecked: false
                    },
                    {
                        id: 4,
                        name: '其他品牌',
                        isSelected: false,
                        hasChecked: false
                    },
                ],
                brandList: [],
                currBrandList: [],
                selectedBrandList: [],
                selectedBrandClassifiedId: 1,
                isShowRecommendPopup: false,
                isShowPriceStarPopup: false,
                isShowBrandPopup: false,
                isShowSelectedBrandPopup: false,
                popularList: [
                    {
                        id: 1,
                        name: '符合差标',
                        isSelected: false
                    },
                    {
                        id: 2,
                        name: '协议酒店',
                        isSelected: false
                    }
                ],
                isDifferenceEnable: false,//是否符合差标,用于checkbox
                differenceEnable: 0,//是否符合差标
                agreementEnable: 0,//是否是协议酒店
                areaInfo: {},//选中的位置区域信息
                selectedDistance: '',
                distanceList: [
                    {
                        id: 1,
                        label: '500米以内',
                        value: '0.5',
                        isSelected: false
                    },
                    {
                        id: 2,
                        label: '1公里内',
                        value: '1',
                        isSelected: false
                    },
                    {
                        id: 3,
                        label: '2公里内',
                        value: '2',
                        isSelected: false
                    },
                    {
                        id: 4,
                        label: '4公里内',
                        value: '4',
                        isSelected: false
                    },
                    {
                        id: 5,
                        label: '8公里内',
                        value: '8',
                        isSelected: false
                    },
                    {
                        id: 6,
                        label: '10公里内',
                        value: '10',
                        isSelected: false
                    },
                ],
            };
        },
        watch: {
            cityId: {
                handler(newVal, oldVal) {
                    if (newVal) {
                        let init = setInterval(() => {
                            let hotelAreaSelector = this.$refs.hotelAreaSelector
                            if (hotelAreaSelector) {
                                clearInterval(init)
                                this.$refs.hotelAreaSelector.init({
                                    cityId: newVal, // 城市id
                                    cityName: this.cityName,
                                    isPOIName: this.isPOIName,
                                    mock_data: {
                                        enable: false, // 是否使用虚拟数据
                                    }
                                })
                            }
                        }, 500)

                    }
                },
                immediate: true,
                //deep: true
            },
            'areaInfo.label'(newVal) {
                let sortMenuItem = this.sortMenuList.find(item => {
                    return item.id === 2;
                });
                if (this.selectedDistance === '' && !newVal) {
                    sortMenuItem.isSelected = false;
                } else {
                    sortMenuItem.isSelected = true;
                }
            },
            selectedDistance(newVal) {
                let sortMenuItem = this.sortMenuList.find(item => {
                    return item.id === 2;
                });
                if (!this.areaInfo.label && newVal === '') {
                    sortMenuItem.isSelected = false;
                } else {
                    sortMenuItem.isSelected = true;
                }
            },
            hotelStar(newVal) {
                let sortMenuItem = this.sortMenuList.find(item => {
                    return item.id === 3;
                });
                if (this.sliderValue[0] === 0 && this.sliderValue[1] === 1050 && newVal === '') {
                    sortMenuItem.isSelected = false;
                } else {
                    sortMenuItem.isSelected = true;
                }
            },
            sliderValue(newVal) {
                let sortMenuItem = this.sortMenuList.find(item => {
                    return item.id === 3;
                });
                if (newVal[0] === 0 && newVal[1] === 1050 && this.hotelStar === '') {
                    sortMenuItem.isSelected = false;
                } else {
                    sortMenuItem.isSelected = true;
                }
            },
            selectedBrandList(newVal) {
                let sortMenuItem = this.sortMenuList.find(item => {
                    return item.id === 4;
                });
                if (newVal && newVal.length > 0) {
                    sortMenuItem.isSelected = true;
                } else {
                    sortMenuItem.isSelected = false;
                }
            }
        },
        mounted() {
            this.getHotelBrandList();
            this.starList = this.hotelStarList;
            this.starList.forEach(item => {
                this.$set(item, 'isSelected', false)
            })
        },
        methods: {
            setValue(params) {
                let sortMenuItem = {};

                /*设置推荐排序*/
                this.sort = params.sort || 0;
                this.recommendList.forEach(item => {
                    item.isSelected = false;
                    if (item.code === this.sort) {
                        item.isSelected = true;
                        this.sortMenuList.find(menuItem => {
                            return menuItem.id === 1;
                        }).name = item.name;
                    }
                })

                /*设置直线距离*/
                sortMenuItem = this.sortMenuList.find(item => {
                    return item.id === 2;
                });
                sortMenuItem.isSelected = false;
                this.$refs.hotelAreaSelector.setDistance('');
                if (params.d) {
                    sortMenuItem.isSelected = true;
                    this.selectedDistance = params.d;
                    this.$refs.hotelAreaSelector.setDistance(params.d);
                }

                /*设置区域位置*/
                sortMenuItem = this.sortMenuList.find(item => {
                    return item.id === 2;
                });
                sortMenuItem.isSelected = false;
                if(params.areaName) {
                    sortMenuItem.isSelected = true;
                    this.areaInfo.label = params.areaName;
                    this.$refs.hotelAreaSelector.setActiveItem(params.areaType, params.areaName, params.lineCode, params.stationValue)
                }

                /*设置价格范围*/
                sortMenuItem = this.sortMenuList.find(item => {
                    return item.id === 3;
                });
                sortMenuItem.isSelected = false;
                if(params.minPrice) {
                    this.sliderValue[0] = Number(params.minPrice)
                    sortMenuItem.isSelected = true;
                }
                if(params.maxPrice) {
                    this.sliderValue[1] = Number(params.maxPrice)
                    sortMenuItem.isSelected = true;
                }

                /*设置星级*/
                let starList = params.hotelStar.split(' ');
                starList = _.without(starList, '');
                starList.forEach(item => {
                    item = parseInt(item);
                })
                this.starList.forEach(item => {
                    item.isSelected = false;
                    if(starList.indexOf(item.code) > -1) {
                        item.isSelected = true;
                        sortMenuItem.isSelected = true;
                    }
                })
                this.starCodeList = starList;
                this.hotelStar = params.hotelStar;

                /*设置是否符合差标和是否协议价酒店*/
                this.differenceEnable = params.differenceEnable ? 1 : 0;
                this.agreementEnable = params.agreementEnable ? 1 : 0;
                if(this.differenceEnable) {
                    this.popularList.find(item => {
                        return item.id === 1;
                    }).isSelected = true;
                }
                if(this.agreementEnable) {
                    this.popularList.find(item => {
                        return item.id === 2;
                    }).isSelected = true;
                }

                /*设置品牌*/
                sortMenuItem = this.sortMenuList.find(item => {
                    return item.id === 4;
                });
                sortMenuItem.isSelected = false;
                if (params.hotelBrand !== '') {
                    this.selectedBrandNameList = params.hotelBrand.split(' ');
                }
                if (this.selectedBrandNameList && this.selectedBrandNameList.length > 0) {
                    sortMenuItem.isSelected = true;
                }
            },
            chooseSortMenu(id) {
                switch (id) {
                    case 1:
                        this.isShowRecommendPopup = true;
                        break
                    case 2:
                        // 打开区域选择弹窗
                        this.$refs.hotelAreaSelector.show()
                        break
                    case 3:
                        //打开价格/星级选择弹窗
                        this.isShowPriceStarPopup = true;
                        break
                    case 4:
                        //打开品牌连锁选择弹窗
                        this.isShowBrandPopup = true;
                        break
                    default:

                }
            },
            choseRecommend(item) {
                this.recommendList.forEach(item => {
                    item.isSelected = false;
                })
                item.isSelected = true;
                this.sort = item.code;
                this.sortMenuList.find(menuItem => {
                    return menuItem.id === 1;
                }).name = item.name;
                this.$emit('on-change', this.formatEmitValue('recommend'));
                this.isShowRecommendPopup = false;
            },
            choseHotelStar(item) {
                if(!item.isSelected){
                    this.starCodeList.push(item.code);
                    item.isSelected = true;
                } else {
                    let index = this.starCodeList.indexOf(item.code)
                    this.starCodeList.splice(index, 1)
                    item.isSelected = false;
                }
                if (this.starCodeList.length) {
                    let str = '';
                    this.starCodeList.forEach(item => {
                        str += item + ' '
                    })
                    this.hotelStar = str
                } else {
                    this.hotelStar = '';
                }
            },
            choseBrandClassified(classifiedItem) {
                this.brandClassifiedList.forEach(item => {
                    this.$set(item, 'isSelected', false)
                    if(item.id === classifiedItem.id) {
                        this.$set(item, 'isSelected', true)
                    }
                });
                this.currentBrandClassifiedId = classifiedItem.id;
                switch (classifiedItem.id) {
                    case 1:
                        this.currBrandList = this.brandList.fresult;
                        break;
                    case 2:
                        this.currBrandList = this.brandList.sresult;
                        break;
                    case 3:
                        this.currBrandList = this.brandList.tresult;
                        break;
                    case 4:
                        this.currBrandList = this.brandList.oresult;
                        break;
                }

            },
            async getHotelBrandList() {
              let res = await consumer_hotel_search_getHotelBrandList();
              this.brandList = res.datas;
              this.currBrandList = this.brandList.fresult;

              if(this.selectedBrandNameList.length > 0) {
                  let index = 0;
                  for(let list in this.brandList) {
                      this.brandList[list].forEach(item => {
                          item.isSelected = false;
                          if(this.selectedBrandNameList.indexOf(item.hotelBrandName) > -1) {
                              item.isSelected = true;
                              this.brandClassifiedList[index].hasChecked = true;
                              this.hotelBrand.push(item.hotelBrandId);
                              this.selectedBrandList.push(item);
                          }
                      })
                      index += 1;
                  }
              }
            },
            toggle(item, index){
                this.$refs.brandCheckbox[index].toggle();
                if(!this.$refs.brandCheckbox[index].checked) {
                    this.selectedBrandList.push(item)
                    this.$set(item, 'isSelected', true);
                } else {
                    let idx = this.selectedBrandList.indexOf(item)
                    this.selectedBrandList.splice(idx, 1)
                    this.$set(item, 'isSelected', false);
                }
                let obj = this.currBrandList.find(item => {
                    return item.isSelected === true;
                })
                if (obj) {
                    this.brandClassifiedList[this.currentBrandClassifiedId - 1].hasChecked = true;
                } else {
                    this.brandClassifiedList[this.currentBrandClassifiedId - 1].hasChecked = false;
                }
            },
            showSelectedBrand() {
                if (this.isShowBrandPopup) {
                    this.isShowBrandPopup = false;
                    this.isShowSelectedBrandPopup = true;
                } else {
                    this.isShowBrandPopup = true;
                    this.isShowSelectedBrandPopup = false;
                }

            },
            checkPriceRange() {
                this.isRightPrice = true;
                if(Number(this.sliderValue[0]) > Number(this.sliderValue[1])) {
                    this.isRightPrice = false;
                }
            },
            onSortClick(item) {
                this.salesSort = item.value;
                this.emitOnChange('sortMenu');
            },
            onStarSubmit() {
                if(this.isRightPrice) {
                    this.emitOnChange('starMenu');
                    this.isShowPriceStarPopup = false;
                }
            },
            onStarClear() {
                this.hotelStar = '';
                this.sliderValue = [0, 1050];
                this.emitOnChange('starMenu');
            },
            onBrandSubmit() {
                this.isShowBrandPopup = false;
                this.isShowSelectedBrandPopup = false;
                this.emitOnChange('brandMenu');
            },
            onBrandClear() {
                this.hotelBrand = [];
                this.selectedBrandList = [];
                this.emitOnChange('brandMenu');
            },
            onBrandDel(item) {
                let index = this.hotelBrand.indexOf(item.hotelBrandId)
                this.hotelBrand.splice(index, 1);
                this.selectedBrandList.splice(index, 1);
            },
            onThemeClear() {
                this.hotelTheme = [];
                this.emitOnChange('themeMenu');
            },
            onThemeSubmit() {
                this.emitOnChange('themeMenu');
            },
            emitOnChange(dropdown) {
                // this.$refs[dropdown].toggle(false);
                if(!this.isFirstLoad) {
                    this.$emit('on-change', this.formatEmitValue(dropdown));
                }
            },
            //changeStr：改变值的组件名
            formatEmitValue(changeStr) {
                let hotelAreaSelectorInfo = this.$refs['hotelAreaSelector'].getActiveInfo()
                this.areaInfo = hotelAreaSelectorInfo.firstActiveItem;
                this.selectedDistance = hotelAreaSelectorInfo.selectedDistance;
                let areaInfo = this.areaInfo;
                let areaId = '';
                if (areaInfo.type === '002') {
                    areaId = areaInfo.districtId;
                }
                let minPrice = '';
                let maxPrice = '';
                if (this.sliderValue[0] !== 0 && this.sliderValue[1] === 1050) {
                    minPrice = this.sliderValue[0];
                    maxPrice = '100000';
                }
                if (this.sliderValue[0] === 0 && this.sliderValue[1] !== 1050) {
                    minPrice = '0';
                    maxPrice = this.sliderValue[1];
                }
                if (this.sliderValue[0] !== 0 && this.sliderValue[1] !== 1050) {
                    minPrice = this.sliderValue[0];
                    maxPrice = this.sliderValue[1];
                }
                let selectedBrandNameList = [];
                this.selectedBrandList.forEach((item) => {
                    selectedBrandNameList.push(item.hotelBrandName)
                })

                let value = {};
                switch (changeStr) {
                    case 'recommend':
                        value = {
                            type: changeStr,
                            sort: this.sort
                        };
                        break;
                    case 'area':
                        value = {
                            type: changeStr,
                            areaId: areaId,
                            areaType: areaInfo.label ? areaInfo.type : '001',
                            areaName: areaInfo.label ? areaInfo.label : '',//已选的区域位置名字
                            lineCode: areaInfo.type === '003' ? areaInfo.lineCode : '',
                            stationValue: areaInfo.type === '004' ? areaInfo.firstValue : '',
                            d: this.selectedDistance,
                            pt: areaInfo.longitude && areaInfo.latitude ? `${areaInfo.latitude},${areaInfo.longitude}` : '',// 经度，纬度
                            pt_from_type: '200'
                        };
                        if(areaInfo.label) {
                            this.$set(value, 'filterType', 0)
                        }
                        break;
                    case 'starMenu':
                        value = {
                            type: changeStr,
                            hotelStar: this.hotelStar,
                            minPrice: minPrice,
                            maxPrice: maxPrice,
                        };
                        break;
                    case 'brandMenu':
                        value = {
                            type: changeStr,
                            hotelBrand: selectedBrandNameList.join(' '),
                        };
                        break;
                    case 'popular':
                        value = {
                            type: changeStr,
                            differenceEnable: this.differenceEnable,//是否符合差标
                            agreementEnable: this.agreementEnable,//是否是协议酒店
                        };
                        break;
                }

                // let value = {
                //     salesSort: this.salesSort,
                //     hotelStar: this.hotelStar,
                //     minPrice: minPrice,
                //     maxPrice: maxPrice,
                //     hotelBrand: selectedBrandNameList.join(' '),
                //
                //     // 商区信息
                //     // businessId: areaInfo.businessId ? areaInfo.businessId : '',
                //     // businessName: areaInfo.label ? areaInfo.label : '',
                //     // 区域信息
                //     // districtId: areaInfo.districtId ? areaInfo.districtId : '',
                //     // districtName: areaInfo.label ? areaInfo.label : '',
                //     areaId: areaId,
                //     //已选的区域位置名字
                //     areaName: areaInfo.label ? areaInfo.label : '',
                //     // 经度，纬度
                //     d: this.selectedDistance,
                //     pt: areaInfo.longitude && areaInfo.latitude ? `${areaInfo.latitude},${areaInfo.longitude}` : '',
                //     differenceEnable: this.differenceEnable,//是否符合差标
                //     agreementEnable: this.agreementEnable,//是否是协议酒店
                // };
                return value;
            },
            onThemeToggle(index) {
                this.$refs.themeCheckboxes[index].toggle();
            },
            //修改热门筛选
            onChangePopular(item) {
                item.isSelected = !item.isSelected;
                switch (item.id) {
                    case 1:
                        this.differenceEnable = item.isSelected ? 1 : 0;
                        break;
                    case 2:
                        this.agreementEnable = item.isSelected ? 1 : 0;
                        break;
                }
                if (!this.isFirstLoad) {
                    this.$emit('on-change', this.formatEmitValue('popular'));
                }
            },
            changeArea(val) {
                // 选了区域后接收的推出
                // 商区信息
                /*let businessId = val.businessId ? val.businessId : ''
                let businessName = val.businessName ? val.businessName : ''
                // 区域信息
                let districtId = val.districtId ? val.districtId : ''
                let districtName = val.districtName ? val.districtName : ''
                // 经度，纬度
                let pt = val.longitude && val.latitude ? `${val.longitude},${val.latitude}` : ''

                let params = this.formatEmitValue()
                params.businessId = businessId
                params.businessName = businessName
                params.districtId = districtId
                params.districtName = districtName
                params.pt = pt
                this.$emit('on-change', params);*/
                // this.clearSelectedDistance();
                if(!this.isFirstLoad) {
                    this.$emit('on-change', this.formatEmitValue('area'));
                }
            },
            //清空已选位置区域
            clearSelectedArea() {
                this.areaInfo = {};
                this.$refs.hotelAreaSelector.clearActiveItem();
            },
            //选择直线距离
            chooseDistance(distance) {
                this.selectedDistance = '';
                this.distanceList.forEach(item => {
                    item.isSelected = false;
                    if (item.id === distance.id) {
                        item.isSelected = true;
                        this.selectedDistance = item.value;
                    }
                })
                this.clearSelectedArea();
                this.isShowDistancePopup = false;
                this.$emit('on-change', this.formatEmitValue('distance'));
            },
            //清空已选的直线距离
            clearSelectedDistance() {
                this.selectedDistance = '';
                this.distanceList.forEach(item => {
                    item.isSelected = false;
                })
                this.sortMenuList.find(item => {
                    if (item.id === 1) {
                        item.isSelected = false;
                    }
                })
            },
            resetPriceStar() {
                this.sliderValue = [0, 1050];
                this.isRightPrice = true;
                this.starCodeList = [];
                this.hotelStar = '';
                this.differenceEnable = 0;
                this.isDifferenceEnable = false;
                this.starList.forEach(item => {
                    item.isSelected = false;
                })
            },
            onDifferenceEnable() {
                this.isDifferenceEnable = !this.isDifferenceEnable;
                this.differenceEnable = this.isDifferenceEnable? 1 : 0;
            },
            resetBrand() {
                this.brandClassifiedList.forEach(item => {
                    item.hasChecked = false;
                    item.isSelected = false;
                })
                this.brandClassifiedList[0].isSelected = true;

                for(let list in this.brandList) {
                    this.brandList[list].forEach(item => {
                        item.isSelected = false;
                    })
                }

                this.hotelBrand = [];
                this.selectedBrandList = [];
                this.currBrandList = this.brandList.fresult;
            },
            areaPopupShow() {
                return this.$refs.hotelAreaSelector.areaPopupShow();
            },
            hideAreaPopup() {
                this.$refs.hotelAreaSelector.hide();
            },
            priceStarPopupShow() {
                return this.isShowPriceStarPopup;
            },
            hidePriceStarPopup() {
                this.isShowPriceStarPopup = false;
            },
            brandPopupShow() {
                return this.isShowBrandPopup;
            },
            hideBrandPopup() {
                this.isShowBrandPopup = false;
            }
  },
}
