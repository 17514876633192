export default {
    path: '/wenJuan',
    name: 'wenJuan',
    component: () => import(/* webpackChunkName: "page-wen-juan" */ `./1.0.0/index.vue`),
    meta: {
        data_for_base_layout: {
            header: {
                show: false,
                title: ``,
            },
            tab_bar: {
                show: false,
            }
        },
        enable_check_token: false,
    },
};


