//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    model: {
        prop: 'input',
        event: 'input'
    },
    props: {
        input: String,
    },
    methods: {
        onSearch() {
            this.$emit('on-search');
        },
        onClickSecretary() {
            this.$emit('on-secretary');
        },
    },
}
