import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/my-apply-list',
      name: 'h5-my-apply-list',
      component: () => import(/* webpackChunkName: "page-office-my-apply-list" */ `./3.0.1/index.vue`),
      meta: {
        h5_layout: {
          show_header: true,
          current_page_name: '我的申请',
          need_head_nav: false,
          need_footer_bar: false,
          secondary_menu: 2,
          need_alive: true,
        }
      },
    }
  ],
};
