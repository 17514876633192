import consumer_sys_inform_detail from '@/lib/data-service/haolv-default/consumer_sys_inform_detail'

export default {
    data() {
        return {
            systemInform: {},
        }
    },
    computed: {
        msgId() {
            return this.$route.query.id;
        },
    },
    methods: {
        getMsgDetail() {
            consumer_sys_inform_detail({
                id: this.msgId
            }).then((res) => {
                this.systemInform = res.systemInform;
            });
        },
    },
    created() {
        this.getMsgDetail();
    },
}