// 详情
import req_detail_data from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_queryHotelOrderDetails";
// 退订
import req_refund from "@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_applyCancelOrder";
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";

export default {
    data() {
        return {
            detail: null,
            result: [],

            dialogShow: false,
            cancelRemark: '',
        };
    },
    methods: {
        // 获取订单详情
        getOrderDetail() {
            const orderNo = this.$route.query.orderNo || '';
            req_detail_data({ orderNo }).then(res => {
                this.detail = {
                    order: res.orderInterimResult,
                    personList: res.orderPersonList,
                };
                this.result = res.orderPersonList.map(item => item.id);
            });

            this.timeHandler();

        },
        timeHandler() {
            let test =setInterval(() => {
                if (this.$refs.navBox != undefined) {
                    const __this = this;
                    fixStatusBar({
                        type: `000`,
                        handler({default_handler, api}) {
                            default_handler({
                                element: __this.$refs.navBox,
                            });
                        }
                    });
                    clearInterval(test);
                }
            },100)
        },
        onBack() {
            this.$router.back();
        },
        confirm() {
            this.dialogShow = true;
        },
        handleConfirm() {
            this.$showLoading.show();
            const __this = this;
            const parms = {
                orderNo: __this.detail.order.orderNo,
                evectionNo: __this.detail.order.evectionNo,
                cancelRemark: __this.cancelRemark
            };
            req_refund(parms).then((res) => {
                __this.$Toast({
                    message: '提交成功，请等待退订结果',
                    onClose: function () {
                        __this.$router.push({
                            name: "h5-hotel-order-list",
                            query: {
                                active: 3
                            }
                        });
                    }
                });
                this.$showLoading.hide();
            }).catch((error) => {
                this.$showLoading.hide();
            });
        },
        onBeforeClose(action, done) {
            if (action === "confirm") {
                this.$refs['remarkForm'].validate().then(() => {
                    return done();
                }).catch(() => {
                    return done(false);
                });
            } else {
                // 重置表单校验
                this.$refs["remarkForm"].resetValidation("cancelRemark");
                this.cancelRemark = '';
                return done();
            }
        },
    },
    filters: {
        format_create(val) {
            switch (Number(val)) {
                case 1:
                    return "个人创建";
                case 2:
                    return "出差单创建";
            }
        },
    },
    created() {
        this.getOrderDetail();
    },
}