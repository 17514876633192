import Q from 'q';
import _ from 'underscore';
import {Toast} from 'vant';
import TrainCitySelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-city-selector/1.2.1/index.vue';

export default {
    components: {
        TrainCitySelector,
    },
    data() {
        return {
            selected_entity_id: ``,
        };
    },
    methods: {
        __btn_get_value_click() {
            const __this = this;
            console.log(__this.selected_entity_id);
        },

        __btn_set_value_click() {
            const __this = this;
            __this.selected_entity_id = `GZQ`;
        },

        __btn_reset_value_click() {
            const __this = this;
            __this.selected_entity_id = ``;
        },

        __btn_get_selected_entity_click() {
            const __this = this;
            const selected_entity = __this.$refs.aTrainCitySelector.get_selected_entity();
            console.log(JSON.stringify(selected_entity));
        },
    },
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;
        // __this.selected_entity_id = `GZQ`;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
