const show = function () {
    const rd_001 = Math.floor(Math.random() * 10);
    const rd_002 = rd_001 % 2;
    // console.log(`rd_001=`, rd_001);
    console.log(`rd_002=`, rd_002);

    const svg_rotate = document.querySelector(`#app-loading #svg-rotate`);
    const svg_shakeY = document.querySelector(`#app-loading #svg-shakeY`);

    if (rd_002 === 0) {
        svg_rotate.style.display = ``;
        svg_shakeY.style.display = `none`;
    } else {
        svg_rotate.style.display = `none`;
        svg_shakeY.style.display = ``;
    }

    const element = document.querySelector(`#app-loading`);
    element.style.display = ``;
    console.log(`app-loading:show`);
};

const hide = function () {
    const element = document.querySelector(`#app-loading`);
    element.style.display = `none`;
    console.log(`app-loading:show`);
};

// window.show = show;
// window.hide = hide;

export default {
    show,
    hide,
}
