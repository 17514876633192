import consumer_air_ticket_refundOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_refundOrder";
import consumer_air_ticket_saveRefundOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_saveRefundOrder";
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
import consumer_air_ticket_getFlightRulesList from '@/lib/data-service/haolv-default/consumer_air_ticket_getFlightRulesList'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Dialog, Toast} from "vant";
import consumer_air_ticket_refChaReasonEnum from "@/lib/data-service/haolv-default/consumer_air_ticket_refChaReasonEnum";
import consumer_air_ticket_flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import ChangeClausePopup from "@/page/travel-book/flight/component/changeClausePopup/1.0.0/index.vue";

export default {
    data () {
        return {
            detail: {},
            ossClient: new OssClient(),

            noPassenger: true,
            form: {
                code: '',
                refReason: '',
                refReasonText: '',
                explainMsg: '',
                remarkText: '',
                remarkText1: '',
                otherRefundReason: '',
                otherRefundReasonText: '',
                url: ''
            },
            showRefundOptionsPicker: false,

            showOptionsPicker: false,

            showRefundSecondTypePicker: false,

            fileList: [], // 上传图片列表
            resultFileList: [], // 上传图片结果列表

            result: [],

            changeTipsPopup: false,

            refundAmount: '',

            ruleText: {},
            canSubmit: true,

            refReasonEnum: [],
            ruleTextList: [],
            ruleLoading: false,
            ruleShow: false,
            ruleShowType: '001',
        }
    },
    components: {ChangeClausePopup, NavBar},
    created () {},
    mounted () {
    },
    activated () {
        this.getRefReasonEnum();
        this.reactInfo();
        this.getDetail()
    },
    deactivated() {
        this.$showLoading.hide();
    },
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getRefReasonEnum() {
            consumer_air_ticket_refChaReasonEnum({'bizType': 3}).then(res=>{
                this.refReasonEnum = res.datas;
            })
        },
        reactInfo() {
            this.form = {
                code: '',
                refReason: '',
                // refReasonText: '',
                explainMsg: '',
                remarkText: '',
                remarkText1: '',
                otherRefundReason: '',
                otherRefundReasonText: '',
                url: ''
            };
            this.fileList = []; // 上传图片列表
            this.resultFileList = [];
            this.result = []
        },
        back() {
            this.$router.go(-1)
        },
        toggle(pIndex, index) {
            let boxName = `checkboxes${pIndex}${index}`
            this.$refs[boxName][0].toggle();
        },
        onChangeTypeConfirm(val) {
            this.form.refReason = val.value
            this.form.refReasonText = val.text
            this.showRefundOptionsPicker = false
            // 计算
            if (this.result.length > 0) {
                let psgNos = []
                this.result.forEach(value => {
                    psgNos.push(value.psgNo)
                })
                let params = {
                    cabinCode: this.result[0].cabin,
                    flightNo: this.result[0].flightNo,
                    orderNo: this.$route.query.orderNo,
                    dateTime: `${this.result[0].depDate} ${this.result[0].depTime}`,
                    psgNos,
                    refReason: this.form.refReason
                }
                consumer_air_ticket_getFlightRulesList(params).then(res => {
                    this.refundAmount = res.datas.refundAmount
                })
            }
        },
        onRefundSecondTypeConfirm(val) {
            this.form.otherRefundReason = val.value
            this.form.otherRefundReasonText = val.text
            this.showRefundSecondTypePicker = false
        },
        afterRead(file, indexType) {
            // 此时可以自行将文件上传至服务器
            console.log(file, indexType);
            let fileList = []
            console.log(typeof(file))
            if (!Array.isArray(file)) {
                fileList = [file]
            } else {
                fileList = file
            }

            fileList.forEach((value, index) => {
                this.uploadChange(value.file, index)
            })
        },
        uploadChange(file, index) {
            this.ossClient.multipartUpload({file: file}).then(res => {
                console.log(res)
                this.resultFileList.push(res.url)
            });
        },
        getDetail() {
            let orderNo = this.$route.query.orderNo;
            let orderStatus = this.$route.query.orderStatus
            this.$showLoading.show();
            consumer_air_ticket_refundOrder({ orderNo, orderStatus }).then(res => {
                this.$showLoading.hide();
                console.log('11111');
                if (res.code !== '000000') {
                    // 弹窗
                    Dialog.alert({
                        message: res.msg,
                    }).then(() => {
                        // on close
                        this.back()
                    });
                    return
                }
                let noPassenger = true
                let cabinCode = ''
                let flightNo = ''
                let dateTime = ''
                let detail = res.orderRefund
                if (detail.airplanePassengerList) {
                    detail.airplanePassengerList.forEach(value => {
                        if (value.theFlightList && value.theFlightList.length > 0) {
                            value.theFlightList.forEach(val => {
                                val.psgNo = value.psgNo
                                val.ticketNumber = value.ticketNumber

                                if (cabinCode === '') {
                                    cabinCode = val.cabin
                                    flightNo = val.flightNo
                                    dateTime = `${val.depDate} ${val.depTime}`
                                }
                            })
                            noPassenger = false
                        }
                    })
                }
                this.detail = detail
                console.log(this.detail, Object.keys(this.detail))
                this.noPassenger = noPassenger

                /*if (this.noPassenger) {
                } else {
                    // 加载退改规则
                    this.ruleText = [];
                    let params = {
                        cabinCode,
                        flightNo,
                        orderNo,
                        dateTime,
                        psgNos: [],
                        refReason: this.form.refReason,
                    }
                    consumer_air_ticket_getFlightRulesList(params).then(res => {
                        this.refundAmount = res.datas.refundAmount
                        let orilist = res.datas ? res.datas.rulesList : []
                        let titList = []
                        let list = []
                        orilist.forEach(value => {
                            if (titList.indexOf(`${value.ruleType}-${value.serial}`) === -1) {
                                titList.push(`${value.ruleType}-${value.serial}`)
                                list.push(value)
                            }
                        })
                        let refRule = [];
                        let chaRule = [];
                        list.forEach(value => {
                            value.rate = value.rate.slice(0,value.rate.length-1)
                            if (value.ruleType === '2') {
                                refRule.push(value)
                            } else {
                                chaRule.push(value)
                            }
                        })
                        refRule.sort((a, b) => {
                            return a.serial - b.serial
                        })
                        chaRule.sort((a, b) => {
                            return a.serial - b.serial
                        })
                        this.ruleText = [refRule, chaRule];
                    })
                }*/
            }).catch((e) => {
                console.log(e);
                this.$showLoading.hide();
                // 弹窗
                Dialog.alert({
                    message: e.data.msg,
                }).then(() => {
                    // on close
                    this.back()
                });
            }).finally(() => {
                this.$showLoading.hide();
            });
        },
        // 上传大小超过500k触发
        onOversize(file) {
            console.log(file);
            Toast('文件大小不能超过 5MB');
        },
        // 删除预览图片触发
        deleteImg(file, indexType) {
            console.log(file, indexType)
            this.resultFileList.splice(indexType.index, 1)
        },
        // 监听
        changeCheckBoxGroup(val) {
            console.log('name', val)
            // 筛选出航程
            if (val.length > 0) {
                let psgNos = []
                val.forEach(value => {
                    psgNos.push(value.psgNo)
                })
                let params = {
                    cabinCode: val[0].cabin,
                    flightNo: val[0].flightNo,
                    orderNo: this.$route.query.orderNo,
                    dateTime: `${val[0].depDate} ${val[0].depTime}`,
                    psgNos,
                    refReason: this.form.refReason
                }
                consumer_air_ticket_getFlightRulesList(params).then(res => {
                    this.refundAmount = res.datas.refundAmount
                })
            }
        },
        submit() {
            if (this.result.length === 0) {
                Toast({
                    message: '请选择航程',
                    position: 'bottom',
                });
                return
            }
            let segmentTypeList = [];
            this.result.forEach(value=>{
                if (segmentTypeList.indexOf(value.segmentType) === -1) {
                    segmentTypeList.push(value.segmentType)
                }
            })
            if (segmentTypeList.length > 1) {
                Toast({
                    message: '去程和回程不能同时选择',
                    position: 'bottom',
                });
                return
            }
            if(this.form.refReason === -1 || this.form.refReason === '') {
                Toast({
                    message: '请选择原因',
                    position: 'bottom',
                });
                return
            }
            if (this.form.refReason !== -1 && this.form.refReason !== 1 && this.fileList.length === 0) {
                Toast({
                    message: '请上传证明',
                    position: 'bottom',
                });
                return
            }
            if (this.form.refReason === 5 && this.form.explainMsg === '') {
                Toast({
                    message: '请填写具体说明5到80字',
                    position: 'bottom',
                });
                return
            }
            // if (this.form.refReason === 5 && this.form.otherRefundReason === '') {
            //     Toast({
            //         message: '请选择退票理由',
            //         position: 'bottom',
            //     });
            //     return
            // }
            if (!this.canSubmit) {
                return;
            }
            this.canSubmit = false;

            let psgNo = []
            let flightNo = []
            let segmentNo = []
            this.result.forEach(value => {
                psgNo.push(value.psgNo);
                flightNo.push(value.flightNo);
                segmentNo.push(value.segmentNo);
            });
            let form = {
                psgNo,
                flightNo,
                segmentNo,
                orderNo: this.detail.orderNo,
                evectionNo: this.detail.evectionNo,
                evectionType: this.detail.evectionType === '个人预定' ? 1 : 2
            };
            let params = Object.assign({}, this.form, form);
            params.url = this.resultFileList.join(',');
            let data = {
                applet: params
            };
            console.log(data);
            // Toast.loading({
            //     duration: 0, // 持续展示 toast
            //     forbidClick: true,
            //     message: "加载中...",
            // })
            this.$showLoading.show();
            consumer_air_ticket_saveRefundOrder(data).then(res => {
                this.canSubmit = true;
                // Toast.clear();
                this.$showLoading.hide();
                Toast.success('提交退票成功');
                setTimeout(() => {
                    this.$router.push({
                        name: "h5-flight-order-list",
                        query: {
                            type: '1'
                        }
                    });
                }, 1500)
            }, error => {
                this.canSubmit= true;
                // Toast.clear()
                this.$showLoading.hide();
            }).catch(() => {
                this.canSubmit = true;
                // Toast.clear()
                this.$showLoading.hide();
            });
        },
        showChangeTipsPopup() {
            this.changeTipsPopup = true
        },
        closeChangeTipsPopup() {
            this.changeTipsPopup = false
        },
        submitReason(val) {
            console.log(val);
            this.form.refReason = val.refReason;
            this.form.refReasonText = val.refReason === 1 ? '自愿退票' : '非自愿退票';
            this.form.code = val.code;
            this.form.remarkText = val.msg;
            this.form.remarkText1 = val.msg + '（' + this.form.refReasonText + '）';
            this.showRefundOptionsPicker = false;

            // 计算
            if (this.result.length > 0) {
                let psgNos = []
                this.result.forEach(value => {
                    psgNos.push(value.psgNo)
                })
                let params = {
                    cabinCode: this.result[0].cabin,
                    flightNo: this.result[0].flightNo,
                    orderNo: this.$route.query.orderNo,
                    dateTime: `${this.result[0].depDate} ${this.result[0].depTime}`,
                    psgNos,
                    refReason: this.form.refReason
                }
                consumer_air_ticket_getFlightRulesList(params).then(res => {
                    this.refundAmount = res.datas.refundAmount
                })
            }
        },

        backRule() {
            this.ruleTextList = [];
            this.ruleShow = true;
            this.ruleLoading = true;
            let segmentNoList = [];
            let segmentApiList = [];
            this.detail.airplanePassengerList.forEach(value=>{
                value.theFlightList.forEach(value1 => {
                    if (segmentNoList.indexOf(value1.segmentNo) == -1) {
                        segmentNoList.push(value1.segmentNo);
                        segmentApiList.push(consumer_air_ticket_flightRules({
                            segmentNo: value1.segmentNo,
                        }))
                    }
                })
            })
            Promise.all(segmentApiList).then(res=>{
                let ruleTextList = [];
                res.forEach(value => {
                    ruleTextList.push(value.datas.rsData);
                })
                this.ruleTextList = ruleTextList;
                this.ruleLoading = false;
            })
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
