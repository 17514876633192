// import PageHead from '@/page/evection/components/page-head/index.vue';
// 列表为空展示组件
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";
// 出差单列表
import consumer_evection_evectionList from "@/lib/data-service/haolv-default/consumer_evection_evectionList";
import moment from "moment";

export default {
    components: {
        // PageHead,
        ListEmpty
    },
    data() {
        return {
            error: false,
            loading: false,
            refreshing: false,
            finished: false,
            emptyType: '',
            formData: { // 订单搜索
                selectType: 1, //1.我的出差，2.我的预订,
                currentPage: 1,
                pageSize: 10,
                total: 0,
                evectionNo: "",
                evectionReason: "",
                evectionStatus: "",
                evectionType: ""
            },
            evectioinList: [], // 出差单列表
        };
    },
    methods: {
        get_evection_data() {
            this.emptyType = "";
            consumer_evection_evectionList(this.formData).then((res) => {
                const datas = res.datas;
                const totalCount = datas.totalCount;
                for (let item of res.datas.list) {
                    item.isDevelop = false;
                }
                if (totalCount > this.formData.pageSize) {
                    this.finished = false;
                } else {
                    this.formData.pageSize = totalCount;
                    this.finished = true;
                }

                this.evectioinList = datas.list;
                this.refreshing = false;
                this.loading = false;
            }).catch((err) => {
                this.evectioinList = [];
                this.emptyType = "network";
                this.finished = true;
                this.refreshing = false;
                this.loading = false;
            });
        },
        onLoad() {
            if (this.refreshing) return;
            this.loading = true;
            this.formData.pageSize += 10;
            this.get_evection_data();
        },
        onRefresh() {
            this.formData = {
                selectType: 1, //1.我的出差，2.我的预订,
                currentPage: 1,
                pageSize: 10,
                total: 0,
                evectionNo: "",
                evectionReason: "",
                evectionStatus: "",
                evectionType: ""
            };
            this.evectioinList = [];
            this.refreshing = false;
            this.onLoad();
        },
        to_detail(row) {
            this.$router.push({
                name: 'h5-evection-order-detail',
                query: {
                    pageType: 2,
                    evectionNo: row.evectionNo
                },
            });
        },
    },
    created() {
    },
    activated() {
    },
    filters: {
        format_date(val) {
            if (val) {
                return moment(val).format("YYYY-MM-DD");
            } else {
                return val
            }

        },
    },
    watch: {
        loading(val) {
            const Toast = this.$Toast;
            if (val) {
                Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中...",
                });
            } else {
                Toast.clear();
            }
        },
    },
};
