import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/approve-budget',
      name: 'h5-approve-budget',
      component: () => import(/* webpackChunkName: "page-office-approve-budget" */ `./1.0.0/index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '查看预算',
          need_alive: true,
          need_head_nav: false,
          need_footer_bar: false,
          secondary_menu: 2,
        }
      },
    }
  ],
};
