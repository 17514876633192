import { Toast } from "vant"
// 超标提示标签
import consumer_flight_search_getVerification from '@/lib/data-service/haolv-default/consumer_flight_search_getVerification'
// 火车票差标检测-个人差标提示
import consumer_trains_product_trainsPolicyTips from '@/lib/data-service/haolv-default/consumer_trains_product_trainsPolicyTips'
// 差标检测-个人差标提示
import consumer_t_od_hotel_order_interim_hotelPolicyTips from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelPolicyTips'
// 差标提示
import consumer_web_policyConfig_travelPolicyTips from '@/lib/data-service/haolv-default/consumer_web_policyConfig_travelPolicyTips'
import NavBar from "@/component/nav-bar/import_latest_version_module";
export default {
    data () {
        return {
            catchMsg: '',
            policyControlText: '依据出行人中最严差标为准',
            show: false,
            exceedContentList: [],
            travelPolicyTips: [],
            trainPolicyTips: {},
            hotelExceedMsg: '',//酒店超标提示语
        }
    },
    props: {
        // 业务类型：'flight'-机票，'train'-火车票，'hotel'-酒店
        businessType: {
            type: String,
            default: ''
        },
        // 接口地址：http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/机票%20-%20查询/getReserveVerificationUsingPOST
        flightParams: {
            type: Object,
            default: () => {}
        },
        // 接口地址：http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/product-controller/trainsPolicyTipsUsingPOST
        trainParams: {
            type: Object,
            default: () => {}
        },
        // 接口地址：http://api-alpha.wholeok.com/doc.html#/yinzhilv-tmc/酒店订单/hotelPolicyTipsUsingPOST
        hotelParams: {
            type: Object,
            default: () => {}
        }
    },
    components: {NavBar},
    created () {},
    mounted () {},
    activated () {},
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // 展示弹出层
        showPopup() {
            this.show = true;
            this.$showLoading.show();
            Promise.all([this.getExceedContentList(), this.getTravelPolicyTips()]).finally(() => {
                this.$showLoading.hide();
            })
        },
        // 关闭弹出层
        closePopup() {
            this.show = false
        },
        getShow() {
            return this.show
        },
        // 获取超标内容
        async getExceedContentList() {
            try {
                if (this.businessType === 'flight') {
                    const res = await consumer_flight_search_getVerification(this.flightParams);
                    this.exceedContentList = res.datas
                }
                if (this.businessType === 'train') {
                  const res = await consumer_trains_product_trainsPolicyTips(this.trainParams);
                  this.exceedContentList = res.datas
                }
                if (this.businessType === 'hotel') {
                    const res = await consumer_t_od_hotel_order_interim_hotelPolicyTips(this.hotelParams);
                    this.exceedContentList = res.datas;
                    this.hotelExceedMsg = this.exceedContentList[0].exceedMsg;
                }
            } catch (error) {

            }
        },
        // 获取整体的差标提示
        // 业务类型：1.机票，2.火车票，3.酒店
        async getTravelPolicyTips() {
            let params = {};
            if (this.businessType === 'flight') {
                params = {
                    businessType: 1,
                    evectionNo: this.flightParams.evectionNo
                }
            }
            if (this.businessType === 'train') {
                params = {
                    businessType: 2,
                    evectionNo: this.trainParams.evectionNo
                }
            }
            if (this.businessType === 'hotel') {
                params = {
                    businessType: 3,
                    evectionNo: this.hotelParams.evectionNo,
                    hotelCityId: this.hotelParams.cityId
                }
            }
            this.catchMsg = '';
            try {
                const res = await consumer_web_policyConfig_travelPolicyTips(params);
                let travelPolicyTips = [];
                let newTravelPolicyTips = [];
                if (this.businessType === 'flight') {
                    travelPolicyTips = res.datas.flightMsg
                }
                if (this.businessType === 'hotel') {
                    travelPolicyTips = res.datas.hotelMsg
                }
                travelPolicyTips.forEach(value => {
                    newTravelPolicyTips.push(value.replace(/：/g, ':').split(':'))
                });
                this.policyControlText = res.datas.policyControlText;
                this.travelPolicyTips = newTravelPolicyTips;
                this.trainPolicyTips = res.datas.trainsMsg
            } catch (error) {
              if (error.data.code == '1000000') {
                this.catchMsg = '未设置差标';
              }
            }
        }
    }
}
