import _ from 'underscore';
import ColorIcon from "@/component/color-icon/index.vue";
import VueVirtualScrollList from 'vue-virtual-scroll-list'
import EvectionItem from "@/page/evection/evection-order/office-evection/4.0.0/components/evectionItem/index.vue";
import JourneyItem from '@/page/evection/evection-order/office-evection/4.0.0/components/journeyItem/1.0.0/index.vue'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import evectionList from "@/lib/data-service/haolv-default/consumer_evection_evectionListChange";
import moment from "moment";
import consumer_car_link_getGaodeLinkForPickUp from "@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkForPickUp";
import open from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window";
import consumer_car_link_getGaodeLinkByOrderNo from "@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkByOrderNo";
import {Toast} from "vant";
import getMyJourneyList from "@/lib/data-service/haolv-default/consumer_journey_myJourneyList";
import applyButton from "@/lib/data-service/haolv-default/consumer_menu_applyButton";
import MyrReserve from "@/page/reserve/1.0.2/index.vue";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import {getCarUserShow} from "@/lib/ohter";
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_web_company_checkCompanyUseCar from '@/lib/data-service/haolv-default/consumer_web_company_checkCompanyUseCar'
import cache_set from "nllib/src/cache/set";
import cache_get from "nllib/src/cache/get";
import cache_remove from "nllib/src/cache/remove";
export default {
    data() {
        return {
            orderType: 'a',
            jurisdiction: false,
            evectionNetErr: false,
            journeyNetErr: false,

            isLoading: false,
            listLoading: false,
            evectionList: [],
            EvectionItem: EvectionItem,
            form: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            //判断出差单数据为空时显示组件
            dataOff:true,

            isJourneyLoading: false,
            journeyListLoading: false,
            params: {
                startDate: '',
                endDate: '',
                journeyType: 0
            },
            myJourneyList: [],
            JourneyItem: JourneyItem,
            isShowUseCarBtn: false,
            useCar: false,
        }
    },
    components: {ColorIcon, VueVirtualScrollList},
    created() {
    },
    mounted() {

    },
    async activated() {
        let evectionListReactType =  cache_get({key: 'evectionListReactType'});
        if (this.$route.query.orderType) {
            this.orderType = this.$route.query.orderType;
        }
        if (evectionListReactType === '1') {
            if (this.orderType === 'a') {
                this.evectionList = [];
                this.getEvectionList();
            } else {
                this.myJourneyList = [];
                this._getMyJourneyList();
            }
        } else if (evectionListReactType === '2') {
            // 计算2分钟
            const outTime = cache_get({key: 'evectionListOutTime'});
            let minute = moment().diff(outTime, 'minute');
            if (minute >= 2) {
                if (this.orderType === 'a') {
                    this.evectionList = [];
                    this.getEvectionList();
                } else {
                    this.myJourneyList = [];
                    this._getMyJourneyList();
                }
            } else {
                const evectionList = cache_get({key: 'evectionList'});
                this.evectionList = JSON.parse(evectionList);
                const myJourneyList = cache_get({key: 'myJourneyList'});
                this.myJourneyList = JSON.parse(myJourneyList);
                this.loadingShow = false;

                this.$nextTick(()=>{
                    const evectionListIndex = cache_get({key: 'evectionListIndex'});
                    // 获取距离div的高度距离
                    const source = this.evectionList[evectionListIndex];
                    let top = document.getElementById(`evection-list-evection-${source.evectionNo}`).offsetTop;
                    this.$refs.aBoxBodyDiv.scrollTop = top;
                    //const aVueVirtualScrollList = this.$refs.aVueVirtualScrollList;
                    //console.log(aVueVirtualScrollList);
                    //aVueVirtualScrollList.scrollToBottom(2000);
                })
            }
            cache_remove({
                key: 'evectionListOutTime',
            });
            cache_remove({
                key: 'evectionList',
            });
            cache_remove({
                key: 'myJourneyList',
            });

            const __this = this;
            /*fixStatusBar({
                handler({api}) {
                    __this.$refs.headNavShow.style.paddingTop = api.safeArea.top + 'px';
                }
            });*/
            const api = global.api;
            keybackProcessor.addEventListener(() => {
                api.closeWidget()
            });

        }

        this.getApplyButton();

        this.isShowUseCarBtn = false;

        let userInfo = await get_user_info();
        this.isShowUseCarBtn = getCarUserShow(userInfo.datas.companyId, userInfo.datas.id);

        consumer_web_company_checkCompanyUseCar().then(res => {
            this.useCar = res.datas.useCar;
        })
    },
    deactivated() {
        const outTime = moment().format('YYYY-MM-DD HH:mm:ss');
        cache_set({
            key: 'evectionListOutTime',
            value: outTime
        });
        cache_set({
            key: 'evectionList',
            value: JSON.stringify(this.evectionList)
        });
        cache_set({
            key: 'myJourneyList',
            value: JSON.stringify(this.myJourneyList)
        });
        keybackProcessor.removeEventListener();
    },
    destroyed() {
    },
    async beforeRouteEnter(to, from, next) {
        //第一个参数to，包含的内容是切换后的路由对象，也就是跳转后的路由对象
        //第二个参数from，包含的内容的是切换前的路由对象，也就是跳转前的路由对象
        //第三个参数next()，是否往下执行，执行的话，如果不写的话路由就不会跳转，操作将会终止
        console.log('route', to, from);
        if (to.name === 'h5-office-evection' && (from.name === 'h5-office-evection-details' || from.name === 'flight-order-detail' || from.name === 'h5-train-order-detail' || from.name === 'h5-hotel-order-detail')) {
            // 1是正常刷新， 2是详情返回，要超过2分钟才刷新
            cache_set({
                key: 'evectionListReactType',
                value: '2'
            })
        } else {
            cache_set({
                key: 'evectionListReactType',
                value: '1'
            })
        }
        next();
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // 出差申请按钮状态
        async getApplyButton() {
            try {
                const res = await applyButton();
                this.jurisdiction = res.datas.jurisdiction
            } catch (error) {

            }
        },
        changeTabs(val) {
            if (this.orderType === 'a') {
                this.evectionList = [];
                this.getEvectionList();
            } else {
                this.myJourneyList = [];
                this._getMyJourneyList();
            }
        },
        onRefresh() {
            this.form = {
                pageSize: 30,
                currentPage: 1,
                total: 0,
            };
            this.isLoading = true;
            this.evectionList = [];
            this.getEvectionList();
        },
        __aVueVirtualScrollListToBottomEventHandler() {
            if (this.form.total === this.evectionList.length) {
                return
            }
            this.form.currentPage++;
            this.getEvectionList();
        },
        getEvectionList(){
            const __this = this;
            // let ToastBox = Toast.loading({
            //   duration: 0,
            //   message: '加载中...',
            //   forbidClick: true,
            // });
            // this.$showLoading.show();
            this.listLoading = true;
            this.evectionNetErr = false;
            evectionList(this.form).then(res => {
                let list = res.datas.list || [];
                this.form.total = res.datas.totalCount;
                this.getDatas(list);
                if (this.evectionList.length === 0) {
                    fixStatusBar({
                        handler({api}) {
                            __this.$refs.evectionNotLis.style.paddingTop = api.safeArea.top + 'px';
                            __this.$refs.evectionNotLis.style.paddingBottom = api.safeArea.bottom + 'px';
                        }
                    });
                }
                // this.evectionList = [];
                // this.evectionNetErr = true
            }).catch(()=>{
                this.evectionList = [];
                this.evectionNetErr = true;
                fixStatusBar({
                    handler({api}) {
                        __this.$refs.evectionNetErr.style.paddingTop = api.safeArea.top + 'px';
                        __this.$refs.evectionNetErr.style.paddingBottom = api.safeArea.bottom + 'px';
                    }
                });
            }).finally(() => {
                // this.$showLoading.hide();
                this.isLoading = false;
                this.listLoading = false;
            })
        },
        //格式化数据
        getDatas(list){
            let start = null;
            let end = null;
            if (list.length === 0) {
                this.dataOff = false;
            } else  {
                list.forEach((item) => {
                    start = item.startDate;
                    end = item.endDate;
                    item.days = moment(end).diff(moment(start), "days") + 1;
                    item.journeyList.forEach(value=>{
                        if (value.businessType == '1' || value.businessType == '2') {
                            if (value.cityName) {
                                const cityNameArr = value.cityName.split('-');
                                if (cityNameArr.length > 1) {
                                    value.cityName1 = cityNameArr[0];
                                    value.cityName2 = cityNameArr[1]
                                } else {
                                    value.cityName1 = cityNameArr[0];
                                    value.cityName2 = ''
                                }
                            } else {
                                value.cityName1 = '';
                                value.cityName2 = ''
                            }
                        }
                    })
                });
                if (this.evectionList && this.evectionList.length > 0) {
                    this.evectionList = this.evectionList.concat(list);
                } else {
                    this.evectionList = list;
                }
                // if (this.dataOld) {
                //     this.dataOld = this.dataOld.concat(list);
                // } else {
                //     this.dataOld = list;
                // }
            }
        },
        submit(val, index) {
            // 记录当前index
            cache_set({
                key: 'evectionListIndex',
                value: index
            });
            this.$router.push({
                name: 'h5-office-evection-details',
                query: {
                    id: val.evectionNo
                }
            })
        },
        toJourney(val, item, index) {
            switch (item.businessType) {
                case 1:
                    this.$router.push({
                        name: 'h5-flight-home',
                        query: {
                            evectionNo: val.evectionNo,
                            journeyId: item.id,
                            startDate: item.startTime,
                            type: 'new',
                        }
                    });
                    break;
                case 2:
                    this.$router.push({
                        name: 'h5-train-home',
                        query: {
                            evectionNo: val.evectionNo,
                            journeyId: item.id,
                            startDate: item.startTime,
                            type: 'new',
                        }
                    });
                    break;
                case 3:
                    this.$router.push({
                        name: 'h5-hotel-home',
                        query: {
                            evectionNo: val.evectionNo,
                            journeyId: item.id,
                            startDate: item.startTime,
                            type: 'new',
                        }
                    });
                    break;
                case 4:
                    if(this.useCar || this.isShowUseCarBtn) {
                        this.$router.push({
                            name: 'h5-car-home',
                            query: {
                                evectionNo: val.evectionNo,
                                journeyId: item.id,
                            }
                        })
                    } else {
                        Toast('企业暂未开通用车功能，开通请咨询在线客服')
                    }

                    break;
                default:

            }
        },
        clickEvectionButton() {
            this.$router.push({
                name: 'h5-add-evection',
            })
        },


        onJourneyRefresh() {

            this._getMyJourneyList();
        },
        async _getMyJourneyList() {
            /*let ToastBox = Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });*/
            this.isJourneyLoading = true;
            this.journeyNetErr = false;
            let [err, res] = await awaitWrap(getMyJourneyList(this.params));
            if (err) {
                this.myJourneyList = [];
                this.isJourneyLoading = false;
                this.journeyNetErr = true;
                return
            }
            res.datas.forEach((MItem,MIndex) => {
                if (MItem.businessType == 1 && MItem.airborneInfo && [2,3].indexOf(MItem.airborneInfo.changeType) != -1) {
                    MItem.startDate = MItem.airborneInfo.changeArriveDate;
                }
            });
            let myJourneyList = res.datas;
            let dateList = [...new Set(myJourneyList.map(item => item.startDate))];
            this.myJourneyList = [...new Set(dateList.map(date => {
                    let filterList = myJourneyList.filter(item => item.startDate === date);
                    return {
                        recentDate: filterList[0].dayType,
                        startDate: date,
                        week: filterList[0].week,
                        journeyList: filterList
                    }
                })
            )];
            this.myJourneyList = _.sortBy(this.myJourneyList, 'startDate');
            this.isJourneyLoading = false;
            // this.journeyNetErr = true;
            // ToastBox.clear();
        },
        clickTrainPhone(val){
            let businessType = val;
            consumer_car_link_getGaodeLinkForPickUp({businessType}).then(res => {
                let url = res.datas.url;
                open({
                    url,
                    title: '用车订单详情',
                    btn_back_click_event_handler: () => {
                        this._getMyJourneyList();
                    }
                })
            });
        },
        clickCarDetail(value){
            consumer_car_link_getGaodeLinkByOrderNo({orderNo:value.orderNo}).then(res => {
                let url = res.datas.url;
                open({
                    url,
                    title: '用车订单详情',
                    btn_back_click_event_handler: () => {
                        this._getMyJourneyList();
                    }
                })
            })
        },
        toJourneyDetail(myJourney) {
            const toDetail = (name, query) => {
                this.$router.push({
                    name,
                    query,
                });
            };

            switch (myJourney.businessType) {
                case 1:
                    toDetail("flight-order-detail", {
                        orderNo: myJourney.orderNo
                    });
                    break;
                case 2:
                    toDetail("h5-train-order-detail", {
                        orderNo: myJourney.orderNo
                    });
                    break;
                case 3:
                    toDetail("h5-hotel-order-detail", {
                        orderNo: myJourney.orderNo
                    });
                    break;
            }
        },
        clickButton() {
            this.$router.push({
                name: 'h5-home',
            })
        },

    }
}
