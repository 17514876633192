import moment from "moment"
import consumer_apply_aduitOverproofOnBusiness
    from "@/lib/data-service/haolv-default/consumer_apply_aduitOverproofOnBusiness"   // 因公改版超标申请审批

export default {
    data() {
        return {
            params: {
                status: 0,
                statusText: '',
                approvalReason: '',
                approvalData: [],// 审批进度
                btnParams: {
                    isShow: false,
                    approvalParams: {},//审批按钮的操作参数
                },
            },
            record: {}//最新一条审批记录
        }
    },
    components: {
        CollapseCard: () => import('./components/collapse-card/index.vue'),
    },
    filters: {
        format_time(val) {
            return val ? moment(val).format("YYYY-MM-DD HH:mm:ss") : ""
        }
    },
    methods: {
        //初始化
        init(params) {
            this.params = {...this.params, ...params}
        },
        // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效，5.发起人
        getRecordStatus(val) {
            if (val === 0) {
                return 'wait'
            } else if (val === 1) {
                return 'wait'
            } else if (val === 2) {
                return 'pass'
            } else if (val === 3 || val === 4) {
                return 'fail'
            } else if (val === 6) {
                return 'lose'
            } else {
                return ''
            }
        },
        //是否审批结束
        recordEnd(val) {
            if (!Object.keys(val).length) {
                return false
            }
            if (val.approveStatus === 2 || val.approveStatus === 3 || val.approveStatus === 4) {
                return true
            } else {
                return false
            }
        },
        // 审批通过
        applyPass() {
            this.$refs.approvePass.run(this.params.btnParams.approvalParams).then(() => {
                this.$router.push({name: "admin-my-apply-manage-have-approve"})
            })
        },
        // 审批驳回
        applyReject() {
            this.$prompt("拒绝理由：", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^\S/,
                inputErrorMessage: "请输入拒绝理由",
            })
                .then((value) => {
                    let data = {
                        applyNo: this.params.btnParams.approvalParams.applyNo,
                        approveStatus: 3,   // 审核状态：2.通过，3.拒绝
                        rejectReason: value.value,
                    }
                    consumer_apply_aduitOverproofOnBusiness(data).then((res) => {
                        this.$message({type: "error", message: "审批驳回！"})
                        this.$router.push({name: "admin-my-apply-manage-have-approve"})
                    })
                })
        }
    }
}
