import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/office-home',
            name: 'h5-office-home',
            component: () => import(/* webpackChunkName: "page-office-home" */ `./4.0.2/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '工作台',
                    need_head_nav: false,
                    need_head_nav1: true,
                    need_footer_bar: true,
                    need_alive: true,
                    secondary_menu: 2,
                },

                data_for_all_routes_processor: {
                    order_index: `040-000`,
                },
            },
        }
    ],
};
