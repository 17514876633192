import moment from "moment";
import {Toast, Dialog} from "vant";
import {hidePolicyLabelCompanyIdList} from "@/lib/ohter/index.js";
import Calendar from '@/page/travel-book/train/component/calendar/1.0.1/index.vue';
import CheckTravel from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/check-travel/1.0.1/index.vue'
import DomesticTicketAirportSelector
  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-city-selector/1.2.1/index.vue';
import consumer_trains_product_checkTheRemainingTrainTickets
  from '@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTickets';
import consumer_trains_order_addJourneyPC from '@/lib/data-service/haolv-default/consumer_trains_order_addJourneyPC';
import consumer_trains_order_addJourneyForPersonalPC
  from '@/lib/data-service/haolv-default/consumer_trains_order_addJourneyForPersonalPC';
import consumer_trains_order_scheduledToCheck
  from '@/lib/data-service/haolv-default/consumer_trains_order_scheduledToCheck'
// 获取登录人信息
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
import fixTabBar from '@/lib/common-service/apicloud/fixTabBar'
import getJourneyOptionsCanBeModified
  from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
//获取12306登录账号信息
import consumer_trains_account_myAccount from "@/lib/data-service/haolv-default/consumer_trains_account_myAccount";
import TravelPopup from '@/component/travel-popup/1.0.2/index.vue'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import EpidemicPolicy from '@/page/travel-book/train/component/epidemicPolicy/1.0.0/index.vue'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
// 我的出差
import web_policyConfig_userTravelPolicy from "@/lib/data-service/haolv-default/web_policyConfig_userTravelPolicy";
// 【576】获取出行人差标
import consumer_web_policyConfig_getUserTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getUserTravelPolicy";
// 列表骨架
import TrainListSkeleton from '@/component/skeleton/train-list-skeleton/index.vue'

export default {
  data() {
    return {
        // 网络异常
        netErr:false,
      // 根据ID判断是否显示差标信息的ID集合
      hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
      companyId: null,
      // 顶部搜索到达地
      oldFromStationCode: null,
      // 顶部搜索出发地
      oldToStationCode: null,
      // 顶部搜索出发时间
      oldTrainStartDate: null,
      showBiao: false,  //差标提示组件
      showBiaoData: {},  //差标提示信息
      // 已选中筛选(出发时间、旅行耗时、到达时间)
      filterActiveBox: null,
      active: 0,
      screenListNum: 0,
      screenListText: [],
      screenArrow: false,
      botButtonOff: false,
      trainListOff: false,
      query: {},
      activeName: [], // 用于手风琴展开收起
      fromStationCode: '', // 出发站id
      toStationCode: '', // 到达站id
      startTrainParams: {}, // 出发站信息
      arriveTrainParams: {}, // 到达站信息
      trainDate: '', // 出发时间

      applyId: '',
      evectionNo: '', // 出差单编号
      evectionType: 1, // 出差类型
      redirectType: '', // 返回类型
      journeyNo: '', // 订单号
      journeyId: null,

      trainList: [], // 火车票列表
      oriTrainList: [], // 火车票列表

      travelPopupShow: false,
      trainFromStationCode: '',  // 搜搜条件-出发站
      trainToStationCode: '', // 搜索条件-到达站
      trainStartTrainParams: {}, // 搜索条件
      trainArriveTrainParams: {}, // 搜索条件
      trainStartDate: '', // 搜索条件-出发日期

      isShowCalendar: false, // 出行日期弹窗

      flightStartDate: '',
      flightEndDate: '',
      isScreenShow: false,
      trainType: false,
      isHasTicket: false, // 是否只显示有票的

      filterActive: 'departureTime',
      formStationTimeSort: false, // 发车时间排序
      formStationTimeSortType: 1, // 1：正序，
      formStationTimeArr: false, // 到达时间排序
      formStationTimeArrType: 1, // 1：正序，
      formConsumingTime: false, // 旅行耗时排序
      formConsumingTimeType: 1, // 1：正序，
      application: {
        trainTypeResults: [ // 车型选择list
          {trainTypeCode: 0, trainTypeText: "不限"},
          {trainTypeCode: 1, trainTypeText: "C/G高铁"},
          {trainTypeCode: 2, trainTypeText: "D动车"},
          {trainTypeCode: 3, trainTypeText: "普通列车"}
        ],
        trainTimeTypeResults: [ // 出发时间list
          {trainTimeStartText: "不限", trainTimeEndText: "", trainTimeTypeCode: 0},
          {trainTimeStartText: "凌晨00:00", trainTimeEndText: "06:00", trainTimeTypeCode: 4},
          {trainTimeStartText: "上午06:00", trainTimeEndText: "12:00", trainTimeTypeCode: 1},
          {trainTimeStartText: "下午12:00", trainTimeEndText: "18:00", trainTimeTypeCode: 2},
          {trainTimeStartText: "晚上18:00", trainTimeEndText: "24:00", trainTimeTypeCode: 3},
        ],
        trainTimeTypeResultsEnd: [ // 到达时间list
          {trainTimeStartText: "不限", trainTimeEndText: "", trainTimeTypeCode: 0},
          {trainTimeStartText: "凌晨00:00", trainTimeEndText: "06:00", trainTimeTypeCode: 4},
          {trainTimeStartText: "上午06:00", trainTimeEndText: "12:00", trainTimeTypeCode: 1},
          {trainTimeStartText: "下午12:00", trainTimeEndText: "18:00", trainTimeTypeCode: 2},
          {trainTimeStartText: "晚上18:00", trainTimeEndText: "24:00", trainTimeTypeCode: 3},
        ],
        startStationList: [
          {stationCode: '', stationName: '不限'}
        ], // 出发车站
        endStationList: [
          {stationCode: '', stationName: '不限'}
        ], // 到达车站
      },
      isAllTrain: true, // 是否查询所有类型车次
      checkTrainList: [],
      isAllSeat: true,
      checkSeatList: [],
      isAllFormTime: true, // 筛选出发时间
      checkFormTrainTimeList: [],
      isAllToTime: true, // 筛选到达时间
      checkToTrainTimeList: [],
      isAllFormStation: true, // 筛选出发车站
      checkFormStationList: [],
      isAllToStation: true, // 筛选到达车站
      checkToStationList: [],

      trainParams: {}, // 预定用的
      orderNo: '',
      evectionRequired: 0,
      bookUpperBound: 6,
      unlimitedJourney: 1, // 是否不限行程 0：否 1：是
      allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
      changeEvectionCity: 0, // 是否可选临近城市 0:否, 1:是
      businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
      workTravelType: 1,// 是否已选择出差单
      showOver: false,
      showOverLoging: true,
      maxDateText: null,
      minDateText: null,
      cityCanEdit: true,
      timeCanEdit: true,

      policyLoading: false,
    }
  },
  components: {
    Calendar,
    CheckTravel,
    DomesticTicketAirportSelector,
    TravelPopup,
    NavBar,
    EpidemicPolicy,
    TrainListSkeleton,
  },
  methods: {
    // 交换出发地和目的地
    changeAdr(toData,formData) {
      if (this.workTravelType == 1 && this.unlimitedJourney === 0) {
        return
      }
      this.trainStartTrainParams = formData;
      this.trainArriveTrainParams = toData;
      let trainFromStationCodeBox = this.trainFromStationCode;
      let trainToStationCodeBox = this.trainToStationCode;
      this.trainFromStationCode = trainToStationCodeBox;
      this.trainToStationCode = trainFromStationCodeBox;
    },
    //差标提示
    openEvectionRulePopup() {
      this.showBiao = true;
      this.policyLoading = true;

      let dataBox = {
        applyJourneyId: this.journeyId ? this.journeyId : '',
        businessType: 2,
        evectionNo: this.evectionNo ? this.evectionNo : '',
        userIds: this.$route.query.activeChooseUserList ? this.$route.query.activeChooseUserList.split(',') : [],
      };
      consumer_web_policyConfig_getUserTravelPolicy(dataBox).then(res => {
        this.showBiaoData.trainsPolicy = res.datas.trainsMsg.trainsPolicy;
        this.showBiaoData.trainsPolicyText = res.datas.trainsMsg.trainsPolicyText;
        this.showBiaoData.cGSeatName = res.datas.trainsMsg.cGSeatName;
        this.showBiaoData.dSeatName = res.datas.trainsMsg.dSeatName;
        this.showBiaoData.seatName = res.datas.trainsMsg.seatName;
        this.showBiaoData.trainsControlRulesText = res.datas.trainsMsg.trainsControlRulesText;
        this.showBiaoData.travelerNames = res.datas.travelerNames;
      }).finally(()=>{
        this.policyLoading = false;
      });
      // if (this.$route.query.activeChooseUserList && this.$route.query.activeChooseUserList.length != 0) {
      //
      // } else if (this.showBiaoData.trainsPolicyText == undefined) {
      //   web_policyConfig_userTravelPolicy().then(res => {
      //     this.showBiaoData = res.datas;
      //   }).finally(()=>{
      //     this.policyLoading = false;
      //   });
      // }
    },
    tabbarChange(val){
      if (val != 3) {
        this.filterActiveBox = val;
      }
    },
    //进入车次详情页面
    seatItemClick(val){
      if (val.saleDateTime == '' && val.trainSeatVoList[0] == null) {
        return
      }
      this.$router.push({
        name: `train-search-trainNo`,
        query: {
          fromStationCode: val.fromStationCode,
          fromStationName: val.fromStationName,
          toStationCode: val.toStationCode,
          toStationName: val.toStationName,
          trainDate: this.$moment(val.trainStartDate).format('YYYY-MM-DD'),
          trainNo: val.trainCode,
          maxDate: this.maxDateText,
          minDate: this.minDateText,
          evectionNo: this.evectionNo,

          // 出差信息选择参数
          evectionRequired: Number(this.$route.query.evectionRequired),
          journeyId: Number(this.journeyId),
          businessRuleControl: this.businessRuleControl,

          workTravelType: this.workTravelType,
          evectionType: this.evectionType,
          redirectType: this.redirectType,

          applyId: this.$route.query.applyId,

          activeChooseUserList: this.$route.query.activeChooseUserList,
          checked: this.$route.query.checked,
          allowChangeJourneyTime: this.allowChangeJourneyTime,
        },
      });
    },
    initEpidemicPolicy() {
      this.$refs['epidemicPolicy'].init({
        startCityCode: this.fromStationCode,
        endCityCode: this.toStationCode
      })
    },
    async refundChangePolicyGet() {
      let res = await refund_change_policy_get();
      this.evectionRequired = res.datas.evectionRequired;
      this.bookUpperBound = res.datas.bookUpperBound;
      this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
      this.unlimitedJourney = res.datas.unlimitedJourney;
      this.changeEvectionCity = res.datas.changeEvectionCity;
      this.businessRuleControl = res.datas.businessRuleControl;
    },
    back(){
      let query = {};
      if (this.evectionType === 2) {
        query.evectionNo = this.evectionNo;
        query.journeyId = this.journeyId;
        query.startDate = this.trainStartDate
      }
      this.$router.replace({
        name: 'h5-train-home',
        query
      })
    },
    //右上角按钮单击事件
    editEvection() {
      let deptCityId = this.trainStartTrainParams.stationCode ? this.trainStartTrainParams.stationCode : this.$route.query.fromStationCode;
      let arrCityId = this.trainArriveTrainParams.stationCode ? this.trainArriveTrainParams.stationCode : this.$route.query.toStationCode;
      this.$refs['travelPopup'].init({
        popupType: '000',   //000--右上角展示按钮，001--置灰展示按钮
        evectionNo: this.evectionNo,
        evectionRequired: Number(this.evectionRequired),
        journeyId: Number(this.journeyId),
        businessRuleControl: this.businessRuleControl,
        trainInfo: {
          deptCityId,
          arrCityId,
          deptTime: this.trainDate,
        },
      })
    },
    //因公出行弹框--选择后点击确认按钮返回事件
    updateEvectionInfo(val) {
      //editType: string, 编辑类型，
      // '000' 变更出差单号(无出差单->有出差单),
      // '001': 变更出差单（有出差单->无出差单）--地点改变
      // '003': 变更出差单（有出差单->无出差单）--时间改变
      // '002' 修改原本出差单内容
      // 原地刷新、返回列表页面
      // console.log(val);
      if (val.editType === '002') {
        if (val.difference == '002' || val.difference == '001' || val.difference == '003') {
          this.evectionNo = val.evectionNo;
          this.journeyId = val.journeyId;
          this.workTravelType = val.workTravelType;
          this.initPageNoninductive();
        }
      } else if (val.editType === '000') {
        if (val.difference == '002') {
          this.evectionNo = val.evectionNo;
          this.journeyId = val.journeyId;
          this.workTravelType = val.workTravelType;
          return
        }

        let userId = [];
        val.travelCurrent.active.staffList.forEach((item,index) => {
          if (item.bookStatus != 2) {
            userId.push(item.userId);
          }
        });

        this.$router.push({
          name: "train-search",
          query: {
            // 注意不限行程
            fromStationCode: val.travelCurrent.active.departCityId || this.fromStationCode,
            fromStationName: val.travelCurrent.active.departCityName || this.startTrainParams.stationName,
            toStationCode: val.travelCurrent.active.toCityId || this.toStationCode,
            toStationName: val.travelCurrent.active.toCityName || this.arriveTrainParams.stationName,
            trainDate: val.travelCurrent.active.tripDate,
            activeChooseUserList: userId.join(),
            workTravelType: val.travelCurrent == null ? 2 : 1,
            evectionNo: val.evectionNo,
            journeyId: val.journeyId,
            journeyType: val.journeyType,
            redirectType: this.$route.query.redirectType,
            evectionType: this.$route.query.evectionType,
            checked: this.$route.query.checked,
            evectionRequired: this.$route.query.evectionRequired,
            applyId: this.$route.query.applyId,
            maxDateText: val.travelCurrent.journeys[0].departTimeEnd,
            minDateText: val.travelCurrent.journeys[0].departTime,
            allowChangeJourneyTime: this.$route.query.allowChangeJourneyTime,
          }
        })
      } else if (val.editType === '001') {
        this.$router.push({
          name: "train-search",
          query: {
            // 注意不限行程
            fromStationCode: val.travelCurrent ? val.travelCurrent.active.departCityId :  this.fromStationCode,
            fromStationName: val.travelCurrent ? val.travelCurrent.active.departCityName : this.startTrainParams.stationName,
            toStationCode: val.travelCurrent ? val.travelCurrent.active.toCityId : this.toStationCode,
            toStationName: val.travelCurrent ? val.travelCurrent.active.toCityName : this.arriveTrainParams.stationName,
            trainDate: this.trainDate,
            activeChooseUserList: this.$route.query.activeChooseUserList,
            workTravelType: val.travelCurrent == null ? 2 : 1,
            evectionNo: val.evectionNo,
            journeyId: val.journeyId,
            journeyType: val.journeyType,
            redirectType: this.$route.query.redirectType,
            evectionType: this.$route.query.evectionType,
            checked: this.$route.query.checked,
            evectionRequired: this.$route.query.evectionRequired,
            applyId: this.$route.query.applyId,
            maxDateText: '',
            minDateText: '',
            allowChangeJourneyTime: this.$route.query.allowChangeJourneyTime,
          }
        })
      }

    },
    modifyCityClose() {
      this.travelPopupShow = false;
    },
    //清空筛选
    emptyClick() {
      //内层清空
      this.screenListText = [];
      //外层清空
      this.checkTrainList = [];
      this.checkFormTrainTimeList = [];
      this.checkToTrainTimeList = [];
      this.checkFormStationList = [];
      this.checkToStationList = [];

      this.screenListNum = 0;

      this.screenArrow = false;
    },
    clickCross(item, index) {
      //内层回显
      this.screenListText.splice(index, 1);
      //外层回显
      eval(`
                ${item.listName}.splice(${item.listName}.indexOf(item.code),1)
            `);
      if (this.screenListText.length == 0) {
        this.screenArrow = false;
      }

      this.screenListNum = 0;

      if (this.checkTrainList.length != 0 && this.checkTrainList.indexOf(0) == -1) {
        this.screenListNum = this.screenListNum + this.checkTrainList.length;
      }
      if (this.checkFormTrainTimeList.length != 0 && this.checkFormTrainTimeList.indexOf(0) == -1) {
        this.screenListNum = this.screenListNum + this.checkFormTrainTimeList.length;
      }
      if (this.checkToTrainTimeList.length != 0 && this.checkToTrainTimeList.indexOf(0) == -1) {
        this.screenListNum = this.screenListNum + this.checkToTrainTimeList.length;
      }
      if (this.checkFormStationList.length != '' && this.checkFormStationList.indexOf('') == -1) {
        this.screenListNum = this.screenListNum + this.checkFormStationList.length;
      }
      if (this.checkToStationList.length != '' && this.checkToStationList.indexOf('') == -1) {
        this.screenListNum = this.screenListNum + this.checkToStationList.length;
      }
    },
    screenClick() {
      this.screenArrow = !this.screenArrow;

      this.screenListText = [];

      this.application.trainTypeResults.forEach((item, index) => {
        if (this.checkTrainList.indexOf(item.trainTypeCode) != -1 && item.trainTypeCode != 0) {
          this.screenListText.push({
            text: item.trainTypeText,
            code: item.trainTypeCode,
            listName: 'this.checkTrainList',
          });
        }
      });
      this.application.trainTimeTypeResults.forEach((item, index) => {
        if (this.checkFormTrainTimeList.indexOf(item.trainTimeTypeCode) != -1 && item.trainTimeTypeCode != 0) {
          this.screenListText.push({
            text: `出发：${item.trainTimeStartText}-${item.trainTimeEndText}`,
            code: item.trainTimeTypeCode,
            listName: 'this.checkFormTrainTimeList',
          });
        }
      });
      this.application.trainTimeTypeResultsEnd.forEach((item, index) => {
        if (this.checkToTrainTimeList.indexOf(item.trainTimeTypeCode) != -1 && item.trainTimeTypeCode != 0) {
          this.screenListText.push({
            text: `到达：${item.trainTimeStartText}-${item.trainTimeEndText}`,
            code: item.trainTimeTypeCode,
            listName: 'this.checkToTrainTimeList',
          });
        }
      });
      this.application.startStationList.forEach((item, index) => {
        if (this.checkFormStationList.indexOf(item.stationCode) != -1 && item.stationCode != '') {
          this.screenListText.push({
            text: `出发：${item.stationName}`,
            code: item.stationCode,
            listName: 'this.checkFormStationList',
          });
        }
      });
      this.application.endStationList.forEach((item, index) => {
        if (this.checkToStationList.indexOf(item.stationCode) != -1 && item.stationCode != '') {
          this.screenListText.push({
            text: `到达：${item.stationName}`,
            code: item.stationCode,
            listName: 'this.checkToStationList',
          });
        }
      });
    },
    canChangeFactor() {
      const __this = this;
      getJourneyOptionsCanBeModified({
        situation: '200',
        vm: __this
      }).then(res => {
        this.cityCanEdit = res.cityCanEdit;
        this.timeCanEdit = res.timeCanEdit;
      })
    },
    initPageNoninductive(){
      const __this = this;
      if (__this.fromStationCode === '' || __this.fromStationCode === undefined) {
        Toast('请先选择出发地');
        return
      }
      if (__this.toStationCode === '' || __this.toStationCode === undefined) {
        Toast('请先选择目的地');
        return
      }
      if (__this.trainDate === '' || __this.trainDate === null) {
        Toast('请先选择日期');
        return
      }
        get_user_info().then(res => {
            this.companyId = res.datas.companyId;
        });
      // 强制写入头部日历组件日期
      __this.$refs.aCalendar.currentDate = __this.trainDate;
      this.netErr = false;
      consumer_trains_product_checkTheRemainingTrainTickets({
        fromStationCode: __this.fromStationCode,
        toStationCode: __this.toStationCode,
        trainDate: __this.trainDate,
        applyId: __this.applyId,
        evectionNo: __this.evectionNo,
        checked: this.$route.query.checked,
      }).then(res => {
        res.resultList.forEach((item,index) => {
          let trainBox = null;
          item.trainSeatVoList.forEach((TItem,TIndex) => {
            if (TItem.seatName == '无座') {
              trainBox = TItem;
              item.trainSeatVoList.splice(TIndex, 1);
            }
          });
          item.trainSeatVoList.push(trainBox)
        });

        __this.trainListOff = true;
        __this.oriTrainList = [].concat(res.resultList);
        __this.trainList = __this.trainFilter(__this.oriTrainList);
        __this.trainList.forEach(value => {
          value.trainSeatVoList.forEach(val => {
            if (val) {
              val.trainParams = {
                evectionNo: this.evectionNo,
                motorcycleType: value.motorcycleType,
                seatName: val.seatName
              }
            }
          })
        });
        const resultList = res.resultList;
        let startStationList = [];
        let endStationList = [];

        resultList.forEach(value => {
          value.timeTable = [];
          // 收集出发和目的地车站
          let startStationItem = {
            stationCode: value.fromStationCode,
            stationName: value.fromStationName
          };
          if (!JSON.stringify(startStationList).includes(JSON.stringify(startStationItem))) {
            startStationList.push(startStationItem)
          }
          let endStationItem = {
            stationCode: value.toStationCode,
            stationName: value.toStationName
          };
          if (!JSON.stringify(endStationList).includes(JSON.stringify(endStationItem))) {
            endStationList.push(endStationItem)
          }
        });
        __this.application.startStationList = [];
        __this.application.endStationList = [];
        __this.application.startStationList = __this.application.startStationList.concat(startStationList);
        __this.application.endStationList = __this.application.endStationList.concat(endStationList);

      }).catch(() => {
        // Toast.clear();
          this.netErr = true;
      });
    },
    initPage() {
      const __this = this;
      if (__this.fromStationCode === '' || __this.fromStationCode === undefined) {
        Toast('请先选择出发地');
        return
      }
      if (__this.toStationCode === '' || __this.toStationCode === undefined) {
        Toast('请先选择目的地');
        return
      }
      if (__this.trainDate === '' || __this.trainDate === null) {
        Toast('请先选择日期');
        return
      }
        get_user_info().then(res => {
            this.companyId = res.datas.companyId;
        });
      // 强制写入头部日历组件日期
      __this.$refs.aCalendar.currentDate = __this.trainDate;
      this.$showLoading.show();
      // this.initEpidemicPolicy();
        this.netErr = false;
      consumer_trains_product_checkTheRemainingTrainTickets({
        fromStationCode: __this.fromStationCode,
        toStationCode: __this.toStationCode,
        trainDate: __this.trainDate,
        applyId: __this.applyId,
        evectionNo: __this.evectionNo,
        checked: this.$route.query.checked,
      }).then(res => {
        res.resultList.forEach((item,index) => {
          let trainBox = null;
          item.trainSeatVoList.forEach((TItem,TIndex) => {
            if (TItem.seatName == '无座') {
              trainBox = TItem;
              item.trainSeatVoList.splice(TIndex, 1);
            }
          });
          item.trainSeatVoList.push(trainBox)
        });

        __this.trainListOff = true;
        __this.oriTrainList = [].concat(res.resultList);
        __this.trainList = __this.trainFilter(__this.oriTrainList);
        __this.trainList.forEach(value => {
          value.trainSeatVoList.forEach(val => {
            if (val) {
              val.trainParams = {
                evectionNo: this.evectionNo,
                motorcycleType: value.motorcycleType,
                seatName: val.seatName
              }
            }
          })
        });

        const resultList = res.resultList;
        let startStationList = [];
        let endStationList = [];

        resultList.forEach(value => {
          value.timeTable = [];
          // 收集出发和目的地车站
          let startStationItem = {
            stationCode: value.fromStationCode,
            stationName: value.fromStationName
          };
          if (!JSON.stringify(startStationList).includes(JSON.stringify(startStationItem))) {
            startStationList.push(startStationItem)
          }
          let endStationItem = {
            stationCode: value.toStationCode,
            stationName: value.toStationName
          };
          if (!JSON.stringify(endStationList).includes(JSON.stringify(endStationItem))) {
            endStationList.push(endStationItem)
          }
        });
        __this.application.startStationList = [];
        __this.application.endStationList = [];
        __this.application.startStationList = __this.application.startStationList.concat(startStationList);
        __this.application.endStationList = __this.application.endStationList.concat(endStationList);

        // __this.trainList.forEach((item,index) => {
        //     let trainBox = null;
        //       item.trainSeatVoList.forEach((TItem,TIndex) => {
        //         if (TItem.seatName == '无座') {
        //             trainBox = TItem;
        //             item.trainSeatVoList.splice(TIndex, 1);
        //         }
        //     });
        //     item.trainSeatVoList.push(trainBox)
        // });
      }).catch(() => {
          this.netErr = true;
          this.$showLoading.hide();
      }).finally(() => {
          this.$showLoading.hide();
      })
    },
    inputFontLong(val) {
      if (val && (val.length > 0 && val.length < 3)) {
        return ''
      } else {
        return 'small'
      }
    },
    // 打开出发站选择框
    openStartTrainPopup() {
      if (this.workTravelType == 1 && this.unlimitedJourney === 0) {
        return
      }
      const __this = this;
      __this.$refs.startAirportSelector.show();
    },
    // 打开到达站选择框
    openArriveTrainPopup() {
      //workTravelType -- 是否选择出差单
      //cityCanEdit -- 在新规则下判断是否可以刚刚行程内容
      if (this.workTravelType == 1 && this.unlimitedJourney === 0) {
        return
      }
      const __this = this;
      __this.$refs.arriveAirportSelector.show();
    },
    // 打开搜索下拉框
    showSearchBox() {
      if (this.workTravelType == 1 && this.allowChangeJourneyTime == 0) {
        Toast({
          message: '不能修改出差单既定行程',
          position: 'bottom'
        });
        return;
      }
      this.oldFromStationCode = this.trainStartTrainParams.stationCode ? this.trainStartTrainParams.stationCode : this.$route.query.fromStationCode;
      this.oldToStationCode = this.trainArriveTrainParams.stationCode ? this.trainArriveTrainParams.stationCode : this.$route.query.toStationCode;
      this.oldTrainStartDate = this.trainStartDate;

      this.travelPopupShow = true;
    },
    // 打开完搜索下拉框后触发
    openSearchBox() {
      const __this = this;
      __this.trainFromStationCode = __this.fromStationCode;
      __this.trainToStationCode = __this.toStationCode;

      // let test = setInterval(() => {
      //     if (this.$refs.searchHeader !== undefined) {
      //         fixStatusBar({
      //             type: `000`,
      //             handler({default_handler, api}) {
      //                 const bodyHeight = document.body.clientHeight;
      //                 const addPaddingTop = (api.safeArea.top) / (bodyHeight / 100);
      //
      //                 console.log('bodyHeight', bodyHeight);
      //                 console.log('addPaddingTop', addPaddingTop);
      //                 console.log('paddingTop', __this.$refs['searchHeader'].$el.style.paddingTop);
      //                 __this.$refs['searchHeader'].$el.style.paddingTop = `${addPaddingTop}vh`;
      //                 console.log('paddingTop', __this.$refs['searchHeader'].$el.style.paddingTop)
      //             }
      //         });
      //         clearInterval(test);
      //     }
      // }, 100)
    },
    // 出行日期弹窗
    showCalendar() {
      if (this.workTravelType == 1 && this.allowChangeJourneyTime == 0) {
        Toast({
          message: '不能修改出差单既定行程时间',
          position: 'bottom'
        });
        return
      }
      const __this = this;
      __this.isShowCalendar = true;
    },
    // 确定日期选择
    onCalendarConfirm(date) {
      const __this = this;
      __this.trainStartDate = __this.$moment(date).format('YYYY-MM-DD');
      __this.isShowCalendar = false;

      if (!__this.travelPopupShow) {
        __this.onRefresh();
      }
    },
    // 刷新
    onRefresh() {
      const __this = this;
      // Toast.loading({
      //   message: '正在加载...',
      //   forbidClick: true,
      //   duration: 0
      // });
      this.$showLoading.show();
        this.netErr = false;
      consumer_trains_product_checkTheRemainingTrainTickets({
        fromStationCode: __this.fromStationCode,
        toStationCode: __this.toStationCode,
        trainDate: __this.trainDate,
        applyId: __this.applyId,
      }).then(res => {
        res.resultList.forEach((item,index) => {
          let trainBox = null;
          item.trainSeatVoList.forEach((TItem,TIndex) => {
            if (TItem.seatName == '无座') {
              trainBox = TItem;
              item.trainSeatVoList.splice(TIndex, 1);
            }
          });
          item.trainSeatVoList.push(trainBox)
        });

        // Toast.clear();
        this.$showLoading.hide();
        __this.oriTrainList = [].concat(res.resultList);
        __this.trainList = [].concat(res.resultList);


        const resultList = res.resultList;
        let startStationList = [{stationCode: '', stationName: '不限'}];
        let endStationList = [{stationCode: '', stationName: '不限'}];

        resultList.forEach(value => {
          value.timeTable = [];
          // 收集出发和目的地车站
          let startStationItem = {
            stationCode: value.fromStationCode,
            stationName: value.fromStationName
          };
          if (!JSON.stringify(startStationList).includes(JSON.stringify(startStationItem))) {
            startStationList.push(startStationItem)
          }
          let endStationItem = {
            stationCode: value.toStationCode,
            stationName: value.toStationName
          };
          if (!JSON.stringify(endStationList).includes(JSON.stringify(endStationItem))) {
            endStationList.push(endStationItem)
          }
        });
        __this.application.startStationList = [];
        __this.application.endStationList = [];
        __this.application.startStationList = __this.application.startStationList.concat(startStationList);
        __this.application.endStationList = __this.application.endStationList.concat(endStationList);

        // __this.trainList.forEach((item,index) => {
        //     let trainBox = null;
        //     item.trainSeatVoList.forEach((TItem,TIndex) => {
        //         if (TItem.seatName == '无座') {
        //             trainBox = TItem;
        //             item.trainSeatVoList.splice(TIndex, 1);
        //         }
        //     });
        //     item.trainSeatVoList.push(trainBox)
        // });

        this.onSubmitScreen();
      }).catch(() => {
          this.netErr = true;
          this.$showLoading.hide();
      }).finally(() => {
          this.$showLoading.hide();
      })
    },
    clickDate(val) {
      this.$showLoading.show();
      if (this.trainDate == val) {
        this.$router.go()
      } else {
        this.$router.push({
          name: "train-search",
          query: {
            fromStationCode: this.$route.query.fromStationCode,
            fromStationName: this.$route.query.fromStationName,
            toStationCode: this.$route.query.toStationCode,
            toStationName: this.$route.query.toStationName,
            trainDate: val,
            activeChooseUserList: this.$route.query.activeChooseUserList,
            workTravelType: this.$route.query.workTravelType,
            evectionNo: this.$route.query.evectionNo,
            journeyId: this.$route.query.journeyId,
            journeyType: this.$route.query.journeyType,
            redirectType: this.$route.query.redirectType,
            evectionType: this.$route.query.evectionType,
            checked: this.$route.query.checked,
            evectionRequired: this.$route.query.evectionRequired,
            applyId: this.$route.query.applyId,
            maxDateText: this.$route.query.maxDateText,
            minDateText: this.$route.query.minDateText,
            allowChangeJourneyTime: this.$route.query.allowChangeJourneyTime,
          }
        })
      }
    },
    // 耗时最短
    shortestTime (val,trainList) {
      let timeBox = [];
      let valTime = Number(val.spanTime.replace(/分/,''));
      trainList.forEach((item,index) => {
        timeBox.push(Number(item.spanTime.replace(/分/,'')));
      });

      function func(a,b){
        return a-b;
      }
      if (timeBox.sort(func)[0] == valTime) {
        return true
      } else {
        return false
      }
    },
    // 售完字样
    soldOut(data){
      let dataBox = [];
      let switchData = null;
      data.forEach((item,index) => {
        if (item) {
          if (Number(item.seatInventory) == 0) {
            dataBox.push(Number(item.seatInventory));
          }
        }
      });
      if (dataBox.length == data.length) {
        switchData= true;
      } else {
        switchData= false;
      }

      return switchData
    },
    // 搜索
    toSearch() {
      const __this = this;
      if (
        (this.oldFromStationCode == __this.trainFromStationCode) &&
        (this.oldToStationCode == __this.trainToStationCode) &&
        (this.oldTrainStartDate == __this.trainStartDate)
      ) {
        __this.travelPopupShow = false;
        return
      }
      __this.travelPopupShow = false;
      __this.fromStationCode = __this.trainFromStationCode;
      __this.toStationCode = __this.trainToStationCode;
      __this.startTrainParams = JSON.parse(JSON.stringify(__this.trainStartTrainParams));
      __this.arriveTrainParams = JSON.parse(JSON.stringify(__this.trainArriveTrainParams));
      __this.trainDate = __this.trainStartDate;
      __this.onRefresh();
    },
    // 筛选开始
    filterTime() { // 按时间排序
      const __this = this;
      __this.formStationTimeSort = true; // 开启自身排序
      __this.formStationTimeArr = false; // 关闭其他排序
      __this.formConsumingTime = false; // 关闭其他排序
      __this.formStationTimeSortType = 1;
      __this.setSort();
    },
    filterPrice() { // 按价格排序
      const __this = this;
      __this.formStationTimeArr = true; // 开启自身排序
      __this.formStationTimeSort = false; // 关闭其他排序
      __this.formConsumingTime = false; // 关闭其他排序
      __this.formStationTimeArrType = 1;
      __this.setSort();
    },
    consumingTimeClick() { // 按旅行耗时排序
      const __this = this;
      __this.formConsumingTime = true; // 开启自身排序
      __this.formStationTimeArr = false; // 关闭其他排序
      __this.formStationTimeSort = false; // 关闭其他排序
      __this.formConsumingTimeType = 1;
      __this.setSort();
    },
    setSort() {
      const __this = this;
      let trainList = __this.trainList;

      let timeFormMinute = function (val) {
        let timeVal = val.split(':');
        let hour = parseInt(timeVal[0]) * 60;
        let minute = parseInt(timeVal[1]);
        return hour + minute
      };
      let timeToMinute = function (val) {
        let timeVal = val.toTime.split(':');
        let hour = timeVal[0] = (Number(timeVal[0]) +  Number(val.daysTrip*24)) * 60;
        let minute = Number(parseInt(timeVal[1]));
        return hour + minute
      };
      let formTimeCompare = function (x, y) { // 按出发时间正序排列
        let xFromTime = timeFormMinute(x.fromTime);
        let yFromTime = timeFormMinute(y.fromTime);

        if (xFromTime < yFromTime) {
          return -1;
        } else if (xFromTime > yFromTime) {
          return 1;
        } else {
          return 0;
        }
      };

      let formTimeCompareArr = function (x, y) { // 按到达时间正序排列
        let xToTime = timeToMinute(x);
        let yToTime = timeToMinute(y);
        if (xToTime < yToTime) {
          return -1;
        } else if (xToTime > yToTime) {
          return 1;
        } else {
          return 0;
        }
      };

      let formConsumingTime = function (x, y) { // 按旅行耗时正序排列
        let xBox = x.spanTime.split('时');
        let xSpanTime = 0;
        if (xBox.length == 2) {
          xSpanTime = xBox[0]*100 + Number(xBox[1].replace('分',''));
        }
        if (xBox.length == 1) {
          xSpanTime = Number(xBox[0].replace('分',''));
        }

        let yBox = y.spanTime.split('时');
        let ySpanTime = 0;
        if (yBox.length == 2) {
          ySpanTime = yBox[0]*100 + Number(yBox[1].replace('分',''));
        }
        if (yBox.length == 1) {
          ySpanTime = Number(yBox[0].replace('分',''));
        }


        if (xSpanTime < ySpanTime) {
          return -1;
        } else if (xSpanTime > ySpanTime) {
          return 1;
        } else {
          return 0;
        }
      };

      if (__this.formStationTimeSort) {
        trainList.sort(formTimeCompare);
      } else if (__this.formStationTimeArr) {
        trainList.sort(formTimeCompareArr)
      } else if (__this.formConsumingTime) {
        trainList.sort(formConsumingTime)
      }

      __this.trainList = trainList;
    },

    // 确定筛选
    onSubmitScreen() {
      const __this = this;
      __this.isScreenShow = false;
      __this.screenArrow = false;
      // 开始过滤筛选
      __this.trainList = __this.trainFilter(__this.oriTrainList);
    },
    changeTrainConditionItem(type, val) { // 点击筛选条件
      const __this = this;

      __this.isHasTicket = false;
      if (type === 'train') {
        __this.isAllTrain = val.length === 0 ? true : false;
      } else if (type === 'seat') {
        __this.isHasTicket = true;
        __this.isAllSeat = val.length === 0 ? true : false;
      } else if (type === 'formTime') {
        __this.isAllFormTime = val.length === 0 ? true : false;
      } else if (type === 'toTime') {
        __this.isAllToTime = val.length === 0 ? true : false;
      } else if (type === 'formStation') {
        __this.isAllFormStation = val.length === 0 ? true : false;
      } else {
        __this.isAllToStation = val.length === 0 ? true : false;
      }

      //单击不限和其他选项进行操作
      if (this.checkTrainList[this.checkTrainList.length - 1] == 0) {
        this.checkTrainList = [0];
      } else {
        this.checkTrainList.splice(this.checkTrainList.indexOf(0), this.checkTrainList.indexOf(0) + 1);
      }

      if (this.checkFormTrainTimeList[this.checkFormTrainTimeList.length - 1] == 0) {
        this.checkFormTrainTimeList = [0];
      } else {
        this.checkFormTrainTimeList.splice(this.checkFormTrainTimeList.indexOf(0), this.checkFormTrainTimeList.indexOf(0) + 1);
      }

      if (this.checkToTrainTimeList[this.checkToTrainTimeList.length - 1] == 0) {
        this.checkToTrainTimeList = [0];
      } else {
        this.checkToTrainTimeList.splice(this.checkToTrainTimeList.indexOf(0), this.checkToTrainTimeList.indexOf(0) + 1);
      }

      if (this.checkFormStationList[this.checkFormStationList.length - 1] == '') {
        this.checkFormStationList = [''];
      } else {
        this.checkFormStationList.splice(this.checkFormStationList.indexOf(''), this.checkFormStationList.indexOf('') + 1);
      }

      if (this.checkToStationList[this.checkToStationList.length - 1] == '') {
        this.checkToStationList = [''];
      } else {
        this.checkToStationList.splice(this.checkToStationList.indexOf(''), this.checkToStationList.indexOf('') + 1);
      }
      this.screenListNum = 0;

      if (this.checkTrainList.length != 0 && this.checkTrainList.indexOf(0) == -1) {
        this.screenListNum = this.screenListNum + this.checkTrainList.length;
      }
      if (this.checkFormTrainTimeList.length != 0 && this.checkFormTrainTimeList.indexOf(0) == -1) {
        this.screenListNum = this.screenListNum + this.checkFormTrainTimeList.length;
      }
      if (this.checkToTrainTimeList.length != 0 && this.checkToTrainTimeList.indexOf(0) == -1) {
        this.screenListNum = this.screenListNum + this.checkToTrainTimeList.length;
      }
      if (this.checkFormStationList.length != '' && this.checkFormStationList.indexOf('') == -1) {
        this.screenListNum = this.screenListNum + this.checkFormStationList.length;
      }
      if (this.checkToStationList.length != '' && this.checkToStationList.indexOf('') == -1) {
        this.screenListNum = this.screenListNum + this.checkToStationList.length;
      }

    },
    // 筛选开始
    trainFilter(list) {
      const __this = this;
      let trainList = list;

      // 筛选列车类型
      if (!__this.isAllTrain && __this.checkTrainList.length > 0) {
        let trainItemList = [];
        __this.checkTrainList.forEach(value => {
          trainList.forEach(val => {
            if (value === 0) {
              trainItemList.push(val)
            } else if (value === val.motorcycleType) {
              trainItemList.push(val)
            }
          })
        });
        trainList = trainItemList
      }
      // 筛选出发时段
      if (!__this.isAllFormTime && __this.checkFormTrainTimeList.length > 0) {
        let trainItemList = [];
        __this.checkFormTrainTimeList.forEach(value => {
          trainList.forEach(val => {
            if (value === 0) {
              trainItemList.push(val)
            } else if (value === val.fromTimeCode) {
              trainItemList.push(val)
            }
          })
        });
        trainList = trainItemList
      }
      // 筛选到达时段
      if (!__this.isAllToTime && __this.checkToTrainTimeList.length > 0) {
        let trainItemList = [];
        __this.checkToTrainTimeList.forEach(value => {
          trainList.forEach(val => {
            if (value === 0) {
              trainItemList.push(val)
            } else if (value === val.toTimeCode) {
              trainItemList.push(val)
            }
          })
        });
        trainList = trainItemList
      }
      // 筛选出发车站
      if (!__this.isAllFormStation && __this.checkFormStationList.length > 0) {
        let trainItemList = [];
        __this.checkFormStationList.forEach(value => {
          trainList.forEach(val => {
            if (value === '') {
              trainItemList.push(val)
            } else if (value === val.fromStationCode) {
              trainItemList.push(val)
            }
          })
        });
        trainList = trainItemList
      }
      // 筛选到达车站
      if (!__this.isAllToStation && __this.checkToStationList.length > 0) {
        let trainItemList = [];
        __this.checkToStationList.forEach(value => {
          trainList.forEach(val => {
            if (value === '') {
              trainItemList.push(val)
            } else if (value === val.toStationCode) {
              trainItemList.push(val)
            }
          })
        });
        trainList = trainItemList
      }
      return trainList;
    },
    async setOrder(_trainParams) {
      // Toast.loading({
      //   message: '加载中...',
      //   forbidClick: true,
      //   duration: 0
      // });
      this.$showLoading.show();

      let res = await consumer_trains_account_myAccount();
      this.$showLoading.hide();
      if (res.datas.accountStatus == 2) {
        Dialog.confirm({
          title: '温馨提示',
          message: '根据铁路局规定，购买火车票必须要实名制，请先授权12306账号信息',
          confirmButtonText: '去授权12306'
        })
          .then(() => {
            this.$router.push({
              name: 'h5-train-account'
            });
          })
          .catch(() => {

          });
        return
      } else if (res.datas.accountStatus == 3) {
        Dialog.confirm({
          title: '温馨提示',
          message: '您的12306账号需要核验绑定的手机号，请先完成手机号核验',
          confirmButtonText: '去核验手机号'
        })
          .then(() => {
            // on confirm
            this.$router.push({
              name: 'h5-train-account-check'
            });
          })
          .catch(() => {
            // on cancel
          });
        return
      } else if (res.datas.accountStatus == 4) {
        Dialog.confirm({
          title: '温馨提示',
          message: '您的12306账号，购票核验时提示账号密码变更，请重新授权变更后的账号',
          confirmButtonText: '去授权12306'
        })
          .then(() => {
            this.$router.push({
              name: 'h5-train-account'
            });
          })
          .catch(() => {

          });
        return
      }

      const __this = this;
      let buyTicketsType = 0;
      if (_trainParams.isSale === '1' && parseInt(_trainParams.trainSeatVoList[_trainParams.sIndex].seatInventory) > 0) {
        buyTicketsType = 0;
      } else if (_trainParams.isSale === '1' && parseInt(_trainParams.trainSeatVoList[_trainParams.sIndex].seatInventory) <= 0) {
        buyTicketsType = 4;
      } else {
        buyTicketsType = 5;
      }
      let [...robTicketNote] = _trainParams.trainSeatVoList;
      // 19
      let params = {
        acceptNoSeat: 1, // 是否接受无座: 1：接受 0：不接受
        fromStationCode: _trainParams.fromStationCode,
        fromStationName: _trainParams.fromStationName,
        fromTime: _trainParams.fromTime,
        seatCode: _trainParams.trainSeatVoList[_trainParams.sIndex].seatCode,
        seatName: _trainParams.trainSeatVoList[_trainParams.sIndex].seatName,
        seatPrice: _trainParams.trainSeatVoList[_trainParams.sIndex].seatPrice,
        canChooseSeat: _trainParams.trainSeatVoList[_trainParams.sIndex].canChooseSeat == true ? 1 : 2,
        numberOfSeats: _trainParams.trainSeatVoList[_trainParams.sIndex].numberOfSeats,
        startDate: __this.$moment(_trainParams.trainStartDate).format('YYYY-MM-DD'),
        toStationCode: _trainParams.toStationCode,
        toStationName: _trainParams.toStationName,
        trainCode: _trainParams.trainCode,
        arriveDate: __this.$moment(_trainParams.arriveDate).format('YYYY-MM-DD'),
        buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
        toTime: _trainParams.toTime,
        trainNo: _trainParams.trainNo,
        evectionNo: __this.evectionNo,
        // overproofApplyList: [],
        robTicketNote: JSON.stringify(robTicketNote),
        // orderNo: __this.orderNo,
      };
      consumer_trains_order_addJourneyForPersonalPC(params).then((res) => {
        if (buyTicketsType == 0) {
          //普通预订
          this.$showLoading.hide();
          __this.$router.push({
            name: 'train-reserve-private',
            query: {
              evectionNo: res.datas.evectionNo,
              orderNo: res.datas.orderNo,
            }
          });
        } else {
          //抢票预订
          // Toast.clear();
          this.$showLoading.hide();
          __this.$router.push({
            name: 'train-gradding',
            query: {
              motorcycleType: _trainParams.motorcycleType,
              evectionNo: res.datas.evectionNo,
              redirectType: '000',
            }
          });
        }
      }).finally(() => {
          this.$showLoading.hide();
      })
    },
    // 启动超标检测组件
    initCheckTravelBox() {
      const __this = this;

      __this.$refs.checkTravelBox.init({
        checkFun: () => {
          let params = {
            evectionNo: this.evectionNo,
            motorcycleType: this.currentTrainInfo.motorcycleType,
            seatCode: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatCode,
            seatName: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatName
          };
          loading = this.$loading({
            lock: true,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          return consumer_trains_order_scheduledToCheck(params).then(res => {
            let violationResults = res.datas.violationResults;
            loading.close();
            return violationResults // 返回一个数组给组件
            /* 数组每项包含以下内容
            * businessType: 业务类型:1.火车票;2.机票;3.酒店 integer(int32)
            * controlRules: 管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定,4不限 integer(int32)
            * seatName: 差标政策 string
            * standard: 差标类型 string
            * userId: 违反用户id integer(int64)
            * userRealName 违反用户名字 string
            * violationOfReason 预订信息 string
            * */
          }).catch(() => {
            loading.close();
            return null
          })
        },
        reserveSubmitFun: (warningList) => { // 返回的warningList数组里面的每一项会多出一个reason的字段，是存储选中的超标原因
          let buyTicketsType = 0;
          if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) > 0) {
            buyTicketsType = 0
          } else if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) <= 0) {
            buyTicketsType = 4
          } else {
            buyTicketsType = 5
          }

          let overproofApplyList = [];
          warningList.forEach(value => {
            let item = {
              businessType: 2,
              controlRule: value.controlRules,
              reason: value.reason ? value.reason : '',
              reserveContent: value.violationOfReason,
              standard: value.standard,
              standardContent: value.seatName,
              userId: value.userId,
              userName: value.userRealName,
            };
            overproofApplyList.push(item)
          });
          let [...robTicketNote] = this.currentTrainInfo.trainSeatVoList;
          let params = {
            controlRules: this.$refs.checkTravelBox.controlRules,
            acceptNoSeat: 1, // 是否接受无座: 1：接受 0：不接受
            fromStationCode: this.currentTrainInfo.fromStationCode,
            fromStationName: this.currentTrainInfo.fromStationName,
            fromTime: this.currentTrainInfo.fromTime,
            seatCode: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatCode,
            seatName: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatName,
            seatPrice: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatPrice,
            canChooseSeat: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].canChooseSeat === true ? 1 : 2,
            numberOfSeats: this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].numberOfSeats,
            startDate: this.$moment(this.currentTrainInfo.trainStartDate).format('YYYY-MM-DD'),
            toStationCode: this.currentTrainInfo.toStationCode,
            toStationName: this.currentTrainInfo.toStationName,
            trainCode: this.currentTrainInfo.trainCode,
            arriveDate: this.$moment(this.currentTrainInfo.arriveDate).format('YYYY-MM-DD'),
            buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
            toTime: this.currentTrainInfo.toTime,
            trainNo: this.currentTrainInfo.trainNo,
            evectionNo: this.evectionNo,
            overproofApplyList: overproofApplyList,
            robTicketNote: JSON.stringify(robTicketNote),
            orderNo: this.orderNo,
            journeyId: parseInt(this.query.journeyId)
          };
          loading = this.$loading({
            lock: true,
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          return consumer_trains_order_addJourneyPC(params).then(res => {
            loading.close();
            return {
              evectionNo: res.datas.evectionNo
            }
          }).catch(() => {
            loading.close();
            return null
          })
        },
        reserveResultFun: (result) => { // 返回evectionNo
          if (!result) {
            return
          }
          //分离普通预订、抢票预订
          if (this.currentTrainInfo.isSale === '1' && parseInt(this.currentTrainInfo.trainSeatVoList[this.currentTrainInfo.sIndex].seatInventory) > 0) {
            //普通预订
            this.$router.push({
              name: 'admin-travel-reserve',
              query: {
                trainOrderNo: this.trainOrderNo,
                evectionNo: result.evectionNo,
                evectionType: this.evectionType,
                redirectType: this.redirectType,
                motorcycleType: this.currentTrainInfo.motorcycleType,
              }
            })
          } else {
            //抢票预订、预约抢
            this.$router.push({
              name: 'admin-travel-grabbing',
              query: {
                evectionNo: result.evectionNo,
                evectionType: this.evectionType,
                redirectType: this.redirectType,
                motorcycleType: this.currentTrainInfo.motorcycleType,
              }
            })
          }

        }
      })
    },
  },
  created() {
    const __this = this;
    let _routeParam = __this.routeParam;
    __this.evectionRequired = parseInt(_routeParam.evectionRequired);
    __this.fromStationCode = _routeParam.fromStationCode;
    __this.toStationCode = _routeParam.toStationCode;
    __this.startTrainParams.stationName = _routeParam.fromStationName;
    __this.arriveTrainParams.stationName = _routeParam.toStationName;
    __this.trainDate = _routeParam.trainDate ? _routeParam.trainDate : moment().locale('zh-cn').format('YYYY-MM-DD');
    __this.evectionNo = _routeParam.evectionNo;
    __this.applyId = _routeParam.applyId ? _routeParam.applyId : '';
    __this.evectionType = parseInt(_routeParam.evectionType);
    __this.redirectType = _routeParam.redirectType;
    __this.trainStartDate = _routeParam.trainDate ? _routeParam.trainDate : moment().locale('zh-cn').format('YYYY-MM-DD');
    __this.isAllTrain = _routeParam.checked;
    if (__this.$route.query.orderNo) {
      __this.orderNo = __this.$route.query.orderNo;
    }
  },
  async activated() {
    const __this = this;

    await __this.initPage();
    await this.refundChangePolicyGet();

    this.filterActive = 'departureTime';
    this.filterTime();

    // this.openSearchBox();
    this.maxDateText = this.$route.query.maxDateText;
    this.minDateText = this.$route.query.minDateText;
    if (this.maxDateText == '' || this.minDateText == '') {

    } else {
      if (this.trainDate < this.minDateText) {
        this.minDateText = this.trainDate;
        this.trainStartDate = this.minDateText;
      }
    }
    this.allowChangeJourneyTime = this.$route.query.allowChangeJourneyTime;
    this.workTravelType = this.$route.query.workTravelType;
    this.evectionNo = this.$route.query.evectionNo;
    this.evectionRequired = this.$route.query.evectionRequired;
    this.journeyId = this.$route.query.journeyId;
    __this.$refs.startAirportSelector.init();
    __this.$refs.arriveAirportSelector.init();
    __this.canChangeFactor();

    keyback_processor.addEventListener(function () {
      const travelPopup = __this.$refs.travelPopup;
      //出差单弹框--选择出行人弹框--已选择出行人弹框
      try {
        const show_aTravelerMultipleSelector = travelPopup.$refs.traveler.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.popup_selected_user_entity_list.show;
        if (show_aTravelerMultipleSelector) {
          travelPopup.$refs.traveler.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.popup_selected_user_entity_list.show = false;
          return;
        }
      } catch (err) {

      }

      //出差单弹框--选择出行人弹框
      try {
        const show_traveler = travelPopup.$refs.traveler.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.get_show();
        if (show_traveler) {
          travelPopup.$refs.traveler.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.hide();
          return;
        }
      } catch (err) {

      }

      //出差单弹框--选择出差单
      try {
        const show_reservePopupBox = travelPopup.$refs.reservePopupBox.currentPopupShow;
        if (show_reservePopupBox) {
          __this.$refs.travelPopup.$refs.reservePopupBox.currentPopupShow = false;
          return;
        }
      } catch (err) {

      }

      //出差单弹框--成本归属
      try {
        const show_travelCurrent = travelPopup.showCost;
        if (show_travelCurrent) {
          travelPopup.showCost = false;
          return;
        }
      } catch (err) {

      }

      //出差单弹框
      try {
        const show_travelPopup = __this.$refs.travelPopup.show;
        if (show_travelPopup) {
          __this.$refs.travelPopup.hide();
          return;
        }
      } catch (err) {

      }

      //顶部弹框--出发地弹框
      try {
        const startAirportSelector = __this.$refs.startAirportSelector;
        const show_startAirportSelector = startAirportSelector.popup.show;
        if (show_startAirportSelector) {
          __this.$refs.startAirportSelector.popup.show = false;
          return;
        }
      } catch (err) {

      }

      //顶部弹框--目的地弹框
      try {
        const arriveAirportSelector = __this.$refs.arriveAirportSelector;
        const show_arriveAirportSelector = arriveAirportSelector.popup.show;
        if (show_arriveAirportSelector) {
          __this.$refs.arriveAirportSelector.popup.show = false;
          return;
        }
      } catch (err) {

      }

      //顶部弹框--日期弹框
      try {
        if (__this.isShowCalendar) {
          __this.isShowCalendar = false;
          return;
        }
      } catch (err) {

      }

      //顶部弹框
      try {
        if (__this.travelPopupShow) {
          __this.travelPopupShow = false;
          return;
        }
      } catch (err) {

      }

      //日期选择弹框
      try {
        const aCalendar = __this.$refs.aCalendar;
        const show_aCalendar = aCalendar.show;
        if (show_aCalendar) {
          aCalendar.show = false;
          return;
        }
      } catch (err) {

      }

      //筛选弹框--已筛选弹框
      if (__this.screenArrow) {
        __this.screenArrow = false;
        return;
      }

      //筛选弹框
      if (__this.isScreenShow) {
        __this.isScreenShow = false;
        return;
      }

      //超标提示弹框
      try {
        const checkTravelBox = __this.$refs.checkTravelBox;
        const show_checkTravelBox = checkTravelBox.dialogVisible;
        if (show_checkTravelBox) {
          __this.$refs.checkTravelBox.dialogVisible = false;
          return;
        }
      } catch (err) {

      }


      __this.back();
    })
  },
  mounted() {
    // const __this = this;
    //
    // __this.initPage();
    // __this.canChangeFactor();
    //
    // fixStatusBar({
    //     type: `200`,
    //     element: __this.$refs.navBar.$el,
    // });
    // fixTabBar({
    //     type: `200`,
    //     element: __this.$refs.tabBar.$el.querySelector(`.van-tabbar`),
    // });
    //
    // // this.openSearchBox();
    // this.maxDateText = this.$route.query.maxDateText;
    // this.minDateText = this.$route.query.minDateText;
    //
    // if (this.maxDateText == '' || this.minDateText == '') {
    //
    // } else {
    //     if (this.trainDate > this.minDateText) {
    //         this.minDateText = this.trainDate;
    //         this.trainStartDate = this.minDateText;
    //     }
    // }
  },
  watch: {
    trainDate(newVal) {
    },
    trainFromStationCode(newVal) {
      const __this = this;
      __this.$nextTick(() => {
        __this.trainStartTrainParams = __this.$refs.startAirportSelector.get_selected_entity();
      })
    },
    trainToStationCode(newVal) {
      const __this = this;
      __this.$nextTick(() => {
        __this.trainArriveTrainParams = __this.$refs.arriveAirportSelector.get_selected_entity()
      });
    },
  },
  computed: {
    routeParam() {
      const __this = this;
      return __this.$route.query;
    }
  },
  deactivated() {
    keyback_processor.removeEventListener();
  },
  filters: {
    // 未开售字样
    saleTime(date){
      //当前时间
      let today = moment().format('YYYY-MM-DD');
      //发售时间
      let todayEnd = moment(date).format('YYYY-MM-DD');

      let text = '';

      //当天开售
      if (today == todayEnd) {
        let todayTime = moment(date).format('HH:mm');
        text = todayTime
      } else {
        //天数开售
        text = moment(todayEnd + ' 00:00:00').diff(moment(today + ' 00:00:00'),"days") + '天';
      }

      return text
    },
    filterTrainDaY(val) {
      return val === '' ? '' : moment(val).format('MM月DD日')
    },
    filterWeek(val) {
      if (val === '') {
        return ''
      }
      let day = moment(val).day();
      if (day === 0) {
        return '周日'
      } else if (day === 1) {
        return '周一'
      } else if (day === 2) {
        return '周二'
      } else if (day === 3) {
        return '周三'
      } else if (day === 4) {
        return '周四'
      } else if (day === 5) {
        return '周五'
      } else if (day === 6) {
        return '周六'
      } else {
        return ''
      }
    },
    formatterTrainDate(val) {
      return val === '' ? '' : moment(val).format('MM-DD');
    },
  },
}
