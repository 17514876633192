//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '标题',
    },
    isShowTip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: true,
    };
  },
  mounted() {},
  methods: {
    onToggle() {
      this.show = !this.show;
    },
  },
}
