export default {
  data() {
    return {
      currentRate: 100,
    };
  },
  methods: {
    count_down() {
      const __this = this;
      const countDown = setInterval(() => {
        if (__this.currentRate > 0) {
          __this.currentRate--;
        } else {
          clearInterval(countDown);
          __this.$router.push({
            name: "login",
          });
        }
      }, 50);
    },
  },
  created() {
    const __this = this;
    __this.count_down();
  },
  mounted() {
    // const __this = this;
  },
  computed: {
    text() {
      const __this = this;
      return (__this.currentRate / 20).toFixed(0) + "s";
    },
  },
};
