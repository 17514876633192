import Calendar from 'vant/es/calendar';
import Vue from 'vue'
require('./index.less');

const mixin = {
  methods: {
    hello() {
      console.log('overwrite van-calendar:hello!');
    }
  },
  mounted(){
    // console.log(document.querySelector('.van-popup'));
  },
  created: function () {
    const __this = this;
    // __this.hello();
  },
};

const Component = Calendar;

Component.mixins = [mixin];
Component.props.showConfirm = {
  type: Boolean,
  default: false
};
Component.mixins[0].methods.slots = function slots(name, props) {
  if (name === void 0) {
    name = 'default';
  }
  var $slots = this.$slots,
      $scopedSlots = this.$scopedSlots;
  var scopedSlot = $scopedSlots[name];

  if (scopedSlot) {
    return scopedSlot(props);
  }

  return $slots[name];
};


Component.install = function () {
  Vue.component(`van-calendar`, Component);
};


export default Component;
