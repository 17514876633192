import {Toast} from "vant";
import consumer_trains_order_getTrainsOrderDetail
    from "@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail";
import consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2
    from "@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2";
import consumer_t_od_trains_order_change_changePayCost
    from "@/lib/data-service/haolv-default/consumer_t-od-trains-order-change_changePayCost";
import {handlePromise} from "@/page/travel-book/flight/common/unit";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import TrainTimeTable
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
import moment from "moment/moment";

export default {
    data() {
        return {
            orderNo: '',
            changeNo: '',
            orderDetail: {},
            changeDetail: {},
            failMsg: '',
            minDate: new Date(),   //时间范围
            maxDate: new Date(new Date().getFullYear() + 10, 1, 1),
        }
    },
    props: {},
    components: {TrainTimeTable, NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
        this.orderNo = this.$route.query.orderNo || '';
        this.changeNo = this.$route.query.changeNo || '';
        this.failMsg = this.$route.query.failMsg || '';
        this.getChangeDetail();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        getChangeDetail() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
            });
            const getDetail = consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNo});
            const getChangeDetail = consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2({changeNo: this.changeNo});
            const getPriceType = consumer_t_od_trains_order_change_changePayCost({changeNo: this.changeNo});
            Promise.all(handlePromise([getDetail, getChangeDetail, getPriceType])).then(res => {
                Toast.clear();
                if (res[0] && res[0].datas) {
                    this.orderDetail = res[0].datas;
                }
                if (res[1] && res[1].datas) {
                    this.changeDetail = res[1].datas;
                }

                if (res[2] && res[2].datas) {
                    this.priceInfo = res[2].datas
                }

                if (res[1] && res[1].datas) {

                    let setTime2 = setInterval(() => {
                        if (this.$refs['changeTrainTimeTable']) {
                            clearInterval(setTime2);
                            this.$refs['changeTrainTimeTable'].init({
                                //出发站三字码
                                fromStationCode: this.changeDetail.changeTrainInfo.changeFromStationCode,
                                //到达站三字码
                                toStationCode: this.changeDetail.changeTrainInfo.changeToStationCode,
                                //车次号
                                trainCode: this.changeDetail.changeTrainInfo.changeTrainCode,
                                //日期(2020-05-18)
                                trainDate: this.changeDetail.changeTrainInfo.changeStartDate,
                            })
                        }
                    }, 200)
                }
            }).catch(() => {
                Toast.clear();
            })
        },
        openTimeLine(val) {
            this.$refs[val].show();
        },
        back() {
            this.$router.push({
                name: 'h5-train-order-list',
                query: {
                    type: 2
                }
            });
        },
        reactChange() {
            const startTrainParams = {
                stationCode: this.changeDetail.changeTrainInfo.changeFromStationCode,
                stationName: this.changeDetail.changeTrainInfo.changeFromStationName
            };
            const arriveTrainParams = {
                stationCode: this.changeDetail.changeTrainInfo.changeToStationCode,
                stationName: this.changeDetail.changeTrainInfo.changeToStationName
            };
            let userList = [];
            this.orderDetail.passengers.forEach(value=>{
                userList.push(value.userId);
            })
            const params = {
                startTrainParams: JSON.stringify(startTrainParams), // 出发站信息
                arriveTrainParams: JSON.stringify(arriveTrainParams), // 到达站信息
                trainDate: this.changeDetail.changeTrainInfo.changeStartDate,
                evectionNo: this.orderDetail.evectionNo,
                evectionType: this.orderDetail.evectionType,
                orderNo: this.orderNo,
                userList: userList,
                minDate: moment(this.minDate).format('YYYY-MM-DD'),
                maxDate: moment(this.maxDate).format('YYYY-MM-DD'),
            };
            this.$router.replace({
                name: 'h5-train-change-search-list',
                query: params
            })
        },
    }
}