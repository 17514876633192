// 酒店分类 http://192.168.0.19:8765/doc.html#/haolv-consumer/hotel-search-controller/brandListUsingPOST

const __request = require(`./__request/__request_contentType_json`);
const consumer_hotel_search_brandList = () => {

    const p = {
        method: 'post',
        urlSuffix: '/consumer/hotel/search/brandList',
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_hotel_search_brandList;