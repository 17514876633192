import moment from "moment";
import consumer_journey_journeyType from '@/lib/data-service/haolv-default/consumer_journey_journeyType'

export default {
  name:'MyTrip',
  props:[
    "testOff",
  ],
  data(){
    return {
      zoneOff:false,
      form:{
        //选项选中的渲染数据
        approveTypeNew:[0],
        approveTypeNewNum:null
      },
      //选项选中的保存数据
      approveTypeOld:[0],
      //创建时间
      // isShowFoundCalendar: false,
      // flightFoundStartDate: "", // 往返时的出发时间
      // flightFoundEndDate: "", // 往返时的到达时间
      //出行时间
      isShowSetOutCalendar: false,
      flightSetOutStartDate: "", // 往返时的出发时间
      flightSetOutEndDate: "", // 往返时的到达时间
      applyTypeList:[],
      // applyTypeList: [
      //   { label: "全部", value: "" },
      //   { label: "待审核", value: "1" },
      //   { label: "待出行", value: "2" },
      //   { label: "出行中", value: "3" },
      //   { label: "已结束", value: "4" },
      // ],
    }
  },
  mounted(){
    this.GetGetMyJourneyList();
  },
  watch:{
    testOff:{
      immediate: true,
      handler(value) {
        if(value == true){
          this.form.flightSetOutStartDate = this.flightSetOutStartDate + ' 00:00:00';
          this.form.flightSetOutEndDate = this.flightSetOutEndDate + ' 23:59:59';

          this.form.flightSetOutStartDate = this.flightSetOutStartDate == '' ? '' : this.flightSetOutStartDate + ' 00:00:00';
          this.form.flightSetOutEndDate = this.flightSetOutEndDate == '' ? '' :this.flightSetOutEndDate + ' 23:59:59';

          this.form.approveTypeNewNum = this.form.approveTypeNew.map(Number)[0];
          this.$emit('clickFormNew',this.form);
          this.approveTypeOld = this.form.approveTypeNew;
        }else if(value == false) {
          if (this.zoneOff) {
            this.approveTypeOld = [0];
            this.zoneOff = !this.zoneOff;
          }
          this.form.approveTypeNew = this.approveTypeOld;
          // this.flightFoundStartDate = [];
          // this.flightFoundEndDate = [];
          this.flightSetOutStartDate = [];
          this.flightSetOutEndDate = [];

          //重置组件中的日期
          // this.$refs.FoundDate.reset();
          if (this.$refs.SetOutDate) {
            this.$refs.SetOutDate.reset();
          }

        }
      }
    },
  },
  methods:{
    async GetGetMyJourneyList(){
      let test = await consumer_journey_journeyType();
      this.applyTypeList = test.datas;
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
      this.form.approveTypeNew = [index];
      // if (index == 0) {
      //   this.form.approveTypeNew = [0]
      // } else if (index != 0) {
      //   if (this.form.approveTypeNew.includes(0)) {
      //     if (this.approveTypeOld.includes(0)) {
      //       this.zoneOff = !this.zoneOff;
      //     }
      //     this.form.approveTypeNew.splice(this.form.approveTypeNew.indexOf(0));
      //   }
      // }
    },
    // showFoundCalendar() {
    //   this.isShowFoundCalendar = true;
    // },
    // onCalendarRangeConfirmFound(data) {
    //   this.isShowFoundCalendar = false;
    //   this.flightFoundStartDate = moment(data[0]).format("YYYY-MM-DD");
    //   this.flightFoundEndDate = moment(data[1]).format("YYYY-MM-DD");
    // },
    showSetOutCalendar() {
      this.isShowSetOutCalendar = true;
    },
    onCalendarRangeConfirmSetOut(data) {
      this.isShowSetOutCalendar = false;
      this.flightSetOutStartDate = moment(data[0]).format("YYYY-MM-DD");
      this.flightSetOutEndDate = moment(data[1]).format("YYYY-MM-DD");
    },
  }
}
