import Q from 'q';
import _ from 'underscore';

export default {
    components: {},
    data() {
        return {
            msg: 123
        };
    },
    methods: {
        to_login() {
            this.$router.push({
                name: `login`,
            });
        }
    },
    created() {
        const __this = this;
        console.log(__this.msg)
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
