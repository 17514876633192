import consumer_flight_order_changeDetail from "@/lib/data-service/haolv-default/consumer_flight_order_changeDetail";
import consumer_air_ticket_flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import ChangeClausePopup from '@/page/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import moment from "moment";
import {Dialog, Toast} from "vant";
import open from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window'
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
export default {
    data() {
        return {
            show: true,
            evectionType: 1,
            detail: {},
            ruleShow: false, // 退改签说明弹窗
            ruleLoading: false,
            insuranceShow: true,
            ruleText: {}, // 退改的文本
        }
    },
    components: {NavBar, ChangeClausePopup},
    created() {
    },
    mounted() {

    },
    activated() {
        this.evectionType = this.$route.query.evectionType ? parseInt(this.$route.query.evectionType) : 1
        this.getOrderDetail()
        keyback_processor.addEventListener(() => {
            if (this.ruleShow) {
                this.ruleShow = false
                return
            }
            this.back()
        })
    },
    deactivated() {
        keyback_processor.removeEventListener();
        this.$showLoading.hide();
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
        filterCabinRank(val) {
            if (parseInt(val) === 1) {
                return '头等舱'
            } else if (parseInt(val) === 2) {
                return '公务舱'
            } else if (parseInt(val) === 3) {
                return '经济舱'
            } else {
                return ''
            }
        },
        filterDuration(val) {
            if (val) {
                return val.replace(/小时/i, '时').replace(/分钟/i, '分')
            } else {
                return ''
            }
        }
    },
    methods: {
        checkDownload(url) {
            let pParameter = {
                url: url,
            };
            // DownloadAttachment.download_attachment(pParameter);
            // DownloadAttachment(pParameter);
            window.open(url);
        },
        // 返回
        back() {
            this.$router.go(-1)
        },
        showMore() {
            this.show = !this.show
        },
        // 获取订单详情
        getOrderDetail() {
            let chaOrderNo = this.$route.query.orderNo
            /*let psgNoList = this.$route.query.psgNoList
            let psgNoArr = []
            if (psgNoList !== undefined && psgNoList !== '' && psgNoList !== null) {
                psgNoArr = psgNoList.split(',')
            }*/
            this.$showLoading.show();
            consumer_flight_order_changeDetail({ chaOrderNo }).then(res => {
                let detail = res.datas.changeDetail
                detail.insuranceInfo = res.datas.insuranceInfo
                detail.passengerList.forEach(value => {
                    let oldTicketNumber = []
                    let newTicketNumber = []
                    value.segmentResultList.forEach(value1 => {
                        if (value1.oldTicketNumber) {
                            oldTicketNumber.push(value1.oldTicketNumber)
                        }
                        if (value1.newTicketNumber) {
                            newTicketNumber.push(value1.newTicketNumber)
                        }
                    })
                    value.oldTicketNumber = oldTicketNumber
                    value.newTicketNumber = newTicketNumber
                });
                if (detail.url) {
                    let urlName = detail.url.split('/')[detail.url.split('/').length - 1]
                    detail.urlName = `${urlName}`.length > 13 ? `${urlName.slice(0,12)}...` : `${urlName}`;
                }
                this.detail = detail
            }).finally(() => {
                // Toast.clear()
                this.$showLoading.hide();
            })
        },
        checkOrderDetail() {
            this.$router.push({
                name: 'flight-order-detail',
                query: {
                    orderNo: this.detail.orderNo
                }
            })
        },
        checkApplyDetail() {
            if (this.detail.applicationid) {
                this.$router.push({
                    name: 'h5-approve-detail',
                    query: {
                        id: this.detail.applicationid,
                        approveType: this.detail.approveType,
                        type: 'applyDetail',
                        applyNo: this.detail.applyNo,
                        selectType: 1,
                    },
                });
            }
        },
        // 查看退改规则
        backRule(val) {
            this.ruleText = {}
            this.ruleShow = true
            let data = {
                segmentNo: val.segmentNo
            }
            this.ruleLoading = true
            consumer_air_ticket_flightRules(data).then(res => {
                this.ruleLoading = false
                this.ruleText = res.datas.rsData
            }).catch(() => {
                this.ruleLoading = false
            })
        },
        showMoreInsuranceInfo() {
            this.insuranceShow = !this.insuranceShow
        },
        checkInsuranceDetail(val) {
            // Toast.loading({
            //     duration: 0, // 持续展示 toast
            //     forbidClick: true,
            // })
            this.$showLoading.show();
            const params = {
                insuranceOrderId: val.insuranceOrderId
            }
            consumer_insurance_getEInsuranceOrderUrl(params).then(res => {
                const url = res.datas.epolicyUrl
                if (url) {
                    open({
                        url,
                        title: '保险详情'
                    })
                }
            }).finally(() => {
                // Toast.clear()
                this.$showLoading.hide();
            })
        },

        // 去改签
        goChange() {
            this.$router.push({
                name: "flight-change",
                query: {
                    orderNo: this.detail.orderNo,
                    evectionNo: this.detail.evectionNo,
                },
            })
        },
        goRefund() {
            this.$router.push({
                name: "flight-refund",
                query: {
                    orderNo: this.detail.orderNo,
                    orderStatus: 1
                },
            })
        },
    }
}
