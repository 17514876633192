/*
* init function 初始化方法
*
* */
//动态表单
import DynamicForm from '@/component/dynamic-form/1.0.0/index.vue'
import CostAttributionList from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/cost-attribution-list/1.0.1/index.vue";
import BelongToProjectList from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/belong-to-project-list/1.0.0/index.vue";
export default {
    data() {
        return {
            parameterListBox: [],
            // 业务类型
            businessType: 1,
            oaNo: '',
            startDate: '',
            userId: '',
            evectionDetailType: '', // // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            evectionParams: {
                projectCode: '',
                projectName: '',
                companyId: 0,
                reason: '',
                feeAffiliationName: '各自所在部门',
                feeAffiliationType: 1,
                feeAffiliationId: -1,
                partnerEctionId: '',
                centerCode: '',  // 成本中心独有字段
            },
            showCost: false,
            showBelong: false,
            showPartnerEctionIdCross: false,
            showReasonCross: false,

            hasEvectionNo: false,
        }
    },
    props: {
        // evectionInfoParams: {
        //     type: Object,
        //     default: {reason: '', feeAffiliationName: '', feeAffiliationType: 1, feeAffiliationId: ''}
        //
        // }
    },
    components: {CostAttributionList,DynamicForm,BelongToProjectList},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {

    },
    watch: {},
    computed: {
        // evectionParams: {
        //     get () {
        //         return this.evectionInfoParams
        //     },
        //     set (val) {
        //         this.$emit('update:evectionInfoParams', val)
        //     }
        // },
    },
    filters: {},
    methods: {
        _delReason() {
            this.evectionParams.reason = ''
        },
        _delEvectionId() {
            this.evectionParams.partnerEctionId = ''
        },
        // 归属项目选择框
        _showBelongToProjectList() {
            this.showBelong = true
        },
        // 成本中心选择框
        _showCostAttributionList() {
            console.log(this.evectionDetailType, this.businessRuleControl)
            if (this.evectionDetailType === '001' && this.businessRuleControl === 1) {
                return
            }
            this.showCost = true
        },
        _focusForPartnerEctionId() {
            this.showPartnerEctionIdCross = true
        },
        _blurForPartnerEctionId() {
            setTimeout(() => {
                this.showPartnerEctionIdCross = false
            }, 300)
        },
        _focusForReason() {
            this.showReasonCross = true
        },
        _blurForReason() {
            setTimeout(() => {
                this.showReasonCross = false
            }, 300)
        },
        init(params) {
            this.businessRuleControl = params.businessRuleControl;
            this.evectionDetailType = params.evectionDetailType;
            this.businessType = params.businessType;
            this.oaNo = params.oaNo || '';
            this.startDate = params.startDate || '';
            this.userId = params.userId || [];

            this.evectionParams = {
                evectionNo: params.form.evectionNo,
                reason: params.form.reason,
                feeAffiliationName: params.form.feeAffiliationName,
                feeAffiliationType: params.form.feeAffiliationType,
                feeAffiliationId: params.form.feeAffiliationId,
                partnerEctionId: params.form.partnerEctionId,
                centerCode: params.form.centerCode, // 成本中心独有字段
                projectCode: params.form.projectCode,
                projectName: params.form.projectName,
                companyId: params.form.companyId,
            };
            this.$refs.aDynamicForm.init({
              type: 'display',
              businessType: this.businessType,
              oaNo: this.oaNo,
              startDate: this.startDate,
              userId: this.userId,
              evectionParams: this.evectionParams,
            }).then(res => {
              this.parameterListBox = res;
            });
            if (params.form.evectionNo) {
                this.hasEvectionNo = true;
            } else {
                this.hasEvectionNo = false;
            }

        },
        _aDynamicFormGetData(){
            return this.$refs.aDynamicForm.getData();
        },
        hide(){
            this.$refs.aDynamicForm.hide();
            this.showCost = false;
        },
        getShow(){
            let show_aDynamicForm = this.$refs.aDynamicForm.getShow();
            let box = this.showCost || show_aDynamicForm.showPicker || show_aDynamicForm.showCost;

            return box
        },
        getInfo() {
            // 先校验
            return new Promise(resolve => {
                this.$refs['infoForm'].validate().then(() => {
                    this._aDynamicFormGetData().then(res => {
                        resolve({
                            valid: res.checkForm,
                            evectionParams: this.evectionParams,
                            list: res.list,
                        })
                    });
                }).catch(() => {
                    this._aDynamicFormGetData().then(res => {
                        resolve({
                            valid: false,
                            evectionParams: this.evectionParams,
                            list: res.list,
                        })
                    });
                })
            })
        },
        returnOaInfo(val) {
            this.evectionParams = Object.assign({}, this.evectionParams, val);
            console.log(val);
            console.log(this.evectionParams);
        },
    }
}
