import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import fixTabBar from '@/lib/common-service/apicloud/fixTabBar';
import NavBar from '@/component/nav-bar/import_latest_version_module'
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
export default {
    components: {NavBar},
    props: {
        currentPageName: {
            type: String,
            required: false,
            default: "",
        },
    },
    data() {
        return {
            // maskImg: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2021/03/18/background_mask.png',
            toHomeArr: ["h5-flight-home", "h5-train-home", "h5-hotel-home"],
            toEvectionArr: ['h5-evection-order-detail'],
            toOrderListArr: ['h5-flight-order-list', 'h5-hotel-order-list', 'h5-train-order-list','h5-car-order-list'],
        };
    },
    methods: {
        onClickLeft() {
            // 判断是否在flutter app里
            if (window.getFlutter == true) {
                // 如果在，则向flutter app发送信息
                try {
                    window.flutter_inappwebview
                        //可以传递你所需要的任意类型数据，数组、对象等
                        .callHandler("backHandler", {'type': 'close'})
                        .then((res)=>{
                            console.log("res==flutter给html的数据", res);
                        })
                } catch (e) {}
                return
            }
            const name = this.$route.name;
            let jumpType = this.$route.query.jumpType ? this.$route.query.jumpType : '';
            if (this.toHomeArr.includes(name)) {
                this.$router.push({name: "h5-home"});
            } if (this.toEvectionArr.includes(name)) {
                this.$router.push({name: "h5-office-evection"});
            } if (this.toOrderListArr.includes(name)) {
                if (jumpType == '') {
                    this.$router.push({name: "h5-personal-center"});
                    return
                }
                if (jumpType == 'back') {
                  this.$router.back();
                }
            } else {
                this.$router.back();
            }
        },
    },
    created() {
    },
    mounted() {
        const __this = this;

        // fixStatusBar({
        //     type: `000`,
        //     selector: `.h5-layout-nav-bar`,
        // });

        // fixStatusBar({
        //     type: `100`,
        //     element: __this.$refs.navBar.$el,
        // });

        // fixStatusBar({
        //     handler({api}) {
        //         __this.$refs.navBar.$el.style.paddingTop = api.safeArea.top + 'px';
        //     }
        // });

        setTimeout(() => {
            fixStatusBar({
                handler({api}) {
                    const bodyWidth = document.body.clientWidth;
                    const bodyHeight = document.body.clientHeight;
                    let headNav = __this.$refs.navBar.$el;
                    const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
                    headNav.style.paddingTop = addPaddingTop + 'vw';
                }
            });
        }, 500)

    },
    destroyed(){
        keyback_processor.removeEventListener();
    },
    activated() {
      const __this = this;
      keyback_processor.addEventListener(function () {

        __this.onClickLeft();
      });
    },
    deactivated() {
    },
};
