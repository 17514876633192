import Q from 'q';
// 获取动态表单
import consumer_t_tp_refund_change_policy_get from '@/lib/data-service/haolv-default/consumer_t-tp-refund-change-policy_get'
import consumer_admin_flight_reportConfiguration
  from "@/lib/data-service/haolv-default/consumer_admin_flight_reportConfiguration";
import consumer_evection_queryOaInfo from '@/lib/data-service/haolv-default/consumer_evection_queryOaInfo'
// 成本中心
import CostAttributionList from "@/component/cost-attribution-list/1.0.1/index.vue";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";

export default {
    data() {
        return {
            // 用于收集接口(shownName)返回需要隐藏的选项对应的中文名
            hideFieldNameBox: [],
            // ID容器
            companyId: null,
            // 数据是否可以改动
            immutableSwitch: false,
            // 动态表单--整体数据
            reportConfigurations: [],
            // 收集选择器类型的枚举
            TypePickerData: [],
            // 选择器弹框
            showPicker: false,
            // 选择器默认选择项
            TypePickerDefaultIndex: 0,
            // 选择器当前要展示的数据集合
            TypePickerSwitch: [],
            // 选择器当前要展示的名称
            TypePickerName: null,
            // 成本中心当前展示的名称
            costAttributionListName: null,
            // 选择成本中心--弹框
            showCost: false,
            // 选择成本中心--数据
            evectionParams: {
                reason: '',
                feeAffiliationName: '',
                feeAffiliationType: 1,
                feeAffiliationId: null,
                partnerEctionId: ''
            },

            oaNo: '',
            startDate: '',
            userId: [],
            isFreeApprove: 0,
            oaList: [],
            canEdit: true,
            showOaListPopup: false,
            keyword: '',
            oaResultList: [],
        }
    },
    components: {CostAttributionList, NavBar},
    watch: {
        showCost(newData, oldData) {
            if (!newData) {
                this.reportConfigurations.forEach((item, index) => {
                    if (item.parameterText == this.costAttributionListName) {
                        item.parameterValue = this.evectionParams.feeAffiliationId;
                        item.parameterValueText = this.evectionParams.feeAffiliationName;
                        item.feeAffiliationType = this.evectionParams.feeAffiliationType;
                        item.parameterCode = this.evectionParams.centerCode;
                    }
                });
            }
        }
    },
    methods: {
        //用于物理返回
        //是否有显示弹框
        getShow() {
            let data = {
                showPicker: this.showPicker,
                showCost: this.showCost,
            };
            return data;
        },
        //关闭弹框
        hide() {
            this.showPicker = false;
            this.showCost = false;
        },
        getParameterList(){
            return this.hideFieldNameBox;
        },
        getData(){
            let data = {
                checkForm: true,
                list: []
            };
            let _this = this;
            return new Promise(function(resolve, reject) {
                _this.$refs.infoForm.validate()
                    .then(res => {
                        _this.reportConfigurations.forEach((item, index) => {
                            let feeAffiliationTypeBox = null;
                            let dataBox = {
                                parameter: item.parameter,
                                parameterForm: true,
                                parameterText: item.parameterText,
                                parameterValue: item.parameterValue,
                            };
                            if (item.parameter == "feeAffiliationId") {
                                feeAffiliationTypeBox = item.feeAffiliationType;
                            }
                            if (item.parameter == 'attributionType') {
                                dataBox.parameterValue = feeAffiliationTypeBox == 1 ? 2 : 1;
                            }
                            data.list.push(dataBox);
                        });
                        resolve(data);
                    })
                    .catch(rej => {
                        let formList = [];
                        data.checkForm = false;
                        rej.forEach((rejItem, rejIndex) => {
                            formList.push(rejItem.name);
                        });
                        _this.reportConfigurations.forEach((item, index) => {
                            let feeAffiliationTypeBox = null;
                            let dataBox = {
                                parameter: item.parameter,
                                parameterForm: true,
                                parameterText: item.parameterText,
                                parameterValue: item.parameterValue,
                            };
                            if (item.parameter == "feeAffiliationId") {
                                feeAffiliationTypeBox = item.feeAffiliationType;
                            }
                            if (item.parameter == 'attributionType') {
                                item.parameterValue = feeAffiliationTypeBox == 1 ? 2 : 1;
                                if (!item.parameterValue) {
                                    dataBox.parameterForm = false;
                                }
                            }
                            if (formList.indexOf(item.parameterText) != -1) {
                                dataBox.parameterForm = false;
                            }
                            data.list.push(dataBox);
                        });
                        resolve(data);
                    });
            });
        },

        // 选择器确认按钮
        _TypePickerConfirm(value) {
            this.reportConfigurations.forEach((item, index) => {
                if (item.parameterText == this.TypePickerName) {
                    item.parameterValue = value.code;
                    item.parameterValueText = value.text;
                }
            });
            this.showPicker = false;
        },
        // 展开选择器弹框
        _showCostField(value) {
            if (!this.canEdit) {
                return;
            }
            if (this.immutableSwitch) return
            if (value) {
                this.TypePickerSwitch = value.opetions;
                this.TypePickerName = value.parameterText;
                this.TypePickerDefaultIndex = value.parameterValue;
                this.$nextTick(() => {
                    // 设置默认值
                    this.$refs.vanPicker.setIndexes([this.TypePickerDefaultIndex]);
                });
            }
            this.showPicker = true;
        },
        // 展开成本中心组件弹框
        _showCostAttributionList(value) {
            if (!this.canEdit) {
                return;
            }
            if (this.immutableSwitch) return
            this.costAttributionListName = value.parameterText;
            // 回显已选择的成本中心
            this.reportConfigurations.forEach((item, index) => {
                if (item.parameterText === this.costAttributionListName) {
                    this.evectionParams.feeAffiliationId = item.parameterValue;
                    this.evectionParams.feeAffiliationName = item.parameterValueText;
                    this.evectionParams.feeAffiliationType = item.feeAffiliationType;
                    this.evectionParams.centerCode = item.parameterCode;
                    this._init_CostAttributionList(item.type);
                }
            });
            // this._init_CostAttributionList(item.type);
            this.showCost = true
        },
        // 初始化成本中心组件数据--按需
        _init_CostAttributionList(type) {
            // this.showCost = true;
            // 选择成本中心--数据
            // evectionParams: {
            //     reason: '',
            //         feeAffiliationName: '',
            //         feeAffiliationType: 1,
            //         feeAffiliationId: null,
            //         partnerEctionId: ''
            // },
            const params = {
                showDept: type == 4 ? false : true,
            };
            this.$refs.aCostAttributionList.setParams(params);
        },
        _dataEcho(display_list,type){
            let SwitchCostAttributionList = true;
            if (type == 'immutable') {
                this.immutableSwitch = true;
            }
            if (type == 'immutable' || type == 'display') {
                if (display_list.length == 0) return
                this.reportConfigurations.forEach((item,index) => {
                    display_list.forEach((itemList,indexList) => {
                        if (item.parameterText == itemList.parameterText) {
                            item.parameterValue = itemList.parameterValue;
                            item.parameterValueText = itemList.parameterValue;

                            if (item.type == 3 || item.type == 4) {
                                if (SwitchCostAttributionList) {
                                    this.$nextTick(() => {
                                        this._init_CostAttributionList(item.type);
                                    });
                                    SwitchCostAttributionList = false;
                                }
                                this.$refs.aCostAttributionList.getFeeAffiliationName(itemList.parameterValue).then(res => {
                                    item.parameterValue = res.feeAffiliationId;
                                    item.parameterValueText = res.feeAffiliationName;
                                    item.feeAffiliationType = res.feeAffiliationType;
                                })
                            }
                            if (item.type == 2) {
                                item.opetions.forEach((opetionsItem,opetionsIndex) => {
                                    if (itemList.parameterValue == opetionsItem.code) {
                                        item.parameterValueText = opetionsItem.text;
                                    }
                                });
                            }
                        }
                    })
                });
            }
        },
        _showOAPopup(val) {
            console.log(val);
            if (this.isFreeApprove === 0) {
                return
            }
            this.showOaListPopup = true;
            this.keyword = val.parameterValue || '';
            this._onInput(this.keyword);

        },
        _closePop() {
            this.showOaListPopup = false;
        },
        _onClear() {
            this.keyword = '';
            this._onInput();
        },
        _onSearch(item) {
            // enter触发
            console.log('search', item)
            this.canEdit = true;
            this._closePop();
            this.reportConfigurations.forEach((value, index) => {
                if (value.parameter == 'partnerEvectionId') {
                    value.parameterValue = this.keyword;
                    value.parameterValueText = this.keyword;
                } else {
                    value.parameterValue = '';
                    value.parameterValueText = '';
                }
            });
            // this.$emit('returnOaInfo', this.reportConfigurations);
        },
        _onInput(item) {
            // 输入触发
            console.log('input', this.keyword);
            let _oaResultList = [];
            if (this.keyword === '') {
                _oaResultList = JSON.parse(JSON.stringify(this.oaList));
            } else {
                this.oaList.forEach(value => {
                    let flag = false;
                    const keyword = this.keyword.toLowerCase();
                    flag = flag || value['partnerOaNo'].toLowerCase().indexOf(keyword) > -1;
                    flag = flag || value['oaDesc'].toLowerCase().indexOf(keyword) > -1;
                    if (flag) {
                        _oaResultList.push(value);
                    }
                })
            }
            this.oaResultList = _oaResultList;
        },
        _chooseOAItem(item) {
            console.log('111', item);
            this.canEdit = false;
            this._closePop();
            item['reportConfigurations'].sort((a, b) => {
                return (a['serial'] || 2) - (b['serial'] || 2);
            });
            this.reportConfigurations = item['reportConfigurations'];
            this.reportConfigurations.forEach((element) => {
                element['isMust'] = element['isMust'] ?? 1;
                element['deptName'] = '';
                element['feeAffiliationType'] = 0;
                element['switchForm'] = false;
                element.parameterValueText = element.parameterValue;
                // 成本中心需回显
                if (element['parameter'] === 'feeAffiliationId') {
                    element['deptName'] = element['parameterValueText'];
                    element['parameterValue'] = element['parameterId'];
                    element['feeAffiliationType'] = item['feeAffiliationType'];

                    this.evectionParams.feeAffiliationId = element.parameterValue;
                    this.evectionParams.feeAffiliationName = element.parameterValueText;
                    this.evectionParams.feeAffiliationType = element.feeAffiliationType;
                    this.evectionParams.centerCode = element.parameterCode;
                }
                if (element['parameter'] === 'oaReason') {
                    this.evectionParams.reason = element['parameterValue'];
                }
            })
            this.$emit('returnOaInfo', this.evectionParams);
        },
        init(val) {
            let _this = this;
            return new Promise(async (resolve, reject) => {
                let mock_data = val.mock_data ? val.mock_data : {enable: false,};
                let display_list = val.displayList ? val.displayList : [];
                let type = val.type ? val.type : 'immutable';
                _this.companyId = val.companyId ? val.companyId : null;
                _this.oaNo = val.oaNo || '';
                _this.startDate = val.startDate || '';
                _this.userId = val.userId || [];

                this.evectionParams = {
                    feeAffiliationId: val.evectionParams.feeAffiliationId ? val.evectionParams.feeAffiliationId : null,
                    feeAffiliationName: val.evectionParams.feeAffiliationName ? val.evectionParams.feeAffiliationName : "",
                    feeAffiliationType: val.evectionParams.feeAffiliationType ? val.evectionParams.feeAffiliationType : 1,
                    partnerEctionId: val.evectionParams.partnerEctionId ? val.evectionParams.partnerEctionId : "",
                    reason: val.evectionParams.reason ? val.evectionParams.reason : "",
                };

                let data = {
                    // 业务类型：1机票、2火车票、3酒店、4汽车
                    businessType: val.businessType,
                };
                // if (mock_data.enable)  mock虚拟先不要了
                let [err, res] = await awaitWrap(consumer_admin_flight_reportConfiguration(data));
                if (err) {
                    reject();
                    return;
                }
                _this.hideFieldNameBox = [];
                res.datas.reportConfigurations.sort((a, b) => {
                    return (a['serial'] || 2) - (b['serial'] || 2);
                })
                res.datas.reportConfigurations.forEach((item, index) => {
                    item.isMust = item.isMust != 0 && item.isMust != 1 ? 1 : item.isMust;
                    item.deptName = '';
                    item.parameterValue = '';
                    item.parameterValueText = '';
                    item.feeAffiliationType = 0;
                    item.switchForm = false;
                    // 成本中心需回显一个默认的
                    if (item.parameter === 'feeAffiliationId') {
                        item.deptName = '各自所在的部门';
                        item.parameterValue = -1;
                        item.parameterValueText = '各自所在的部门';
                    }
                    if (item.parameter === 'attributionType') {
                        item.parameterValue = 1;
                        item.parameterValueText = '组织架构';
                    }
                    if (item.isNotShown == 1){
                        _this.hideFieldNameBox.push(item.shownName);
                    }
                });

                _this.reportConfigurations = res.datas.reportConfigurations;
                _this.reportConfigurations.forEach((item, index) => {
                    _this.TypePickerData = [];
                    // 收集type为2的数据，为选择器初始化
                    if (item.type == 2) {
                        _this.TypePickerData.push({
                            opetions: item.opetions,
                            parameterText: item.parameterText
                        });
                    }
                });
                _this._dataEcho(display_list,type);

                //
                let [uErr, uRes] = await awaitWrap(consumer_t_tp_refund_change_policy_get());
                if (uErr) {
                    reject();
                    return;
                }
                _this.isFreeApprove = uRes.datas.isFreeApprove;
                // 通过oa获取oa单号列表-明阳
                if (_this.userId != null && _this.userId.length === 1) {
                    const _oaParams = {
                        'businessType': val.businessType,
                        'startDate': this.startDate,
                        'userId': this.userId[0],
                        'callChannel': 1,
                    };
                    let [rErr, rRes] = await awaitWrap(consumer_evection_queryOaInfo(_oaParams));
                    if (rErr) {
                        return;
                    }
                    _this.oaList = rRes['datas'];
                    if (_this.oaNo != '') {
                        // 匹配oa
                        let currentOaInfo = {};
                        let canMate = false;
                        _this.oaList.forEach(value => {
                            if (value['partnerOaNo'] === _this.oaNo) {
                                canMate = true;
                                currentOaInfo = value;
                                _this.canEdit = false;
                                // 匹配赋值
                                currentOaInfo['reportConfigurations'].sort((a, b) => {
                                    return (a['serial'] || 2) - (b['serial'] || 2);
                                });
                                _this.reportConfigurations = JSON.parse(JSON.stringify(currentOaInfo['reportConfigurations']));
                                _this.reportConfigurations.forEach((element) => {
                                    element['isMust'] = element['isMust'] ?? 1;
                                    element['deptName'] = '';
                                    element.feeAffiliationType = 0;
                                    element['switchForm'] = false;
                                    // 成本中心需回显
                                    if (element['parameter'] === 'feeAffiliationId') {
                                        element['parameterValueText'] = element['parameterValue'];
                                        element['parameterValue'] = element['parameterId'];
                                        element['deptName'] = element['parameterValueText'];
                                        element.feeAffiliationType =
                                            currentOaInfo['feeAffiliationType'];

                                        this.evectionParams.feeAffiliationId = element.parameterValue;
                                        this.evectionParams.feeAffiliationName = element.parameterValueText;
                                        this.evectionParams.feeAffiliationType = element.feeAffiliationType;
                                        this.evectionParams.centerCode = element.parameterCode;
                                    }

                                    if (element['parameter'] === 'oaReason') {
                                        this.evectionParams.reason = element['parameterValue'];
                                    }
                                })
                            }
                        })
                        console.log(this.evectionParams);
                        if (canMate) {
                            this.$emit('returnOaInfo', this.evectionParams);
                        }

                    }
                }
                console.log('_this.hideFieldNameBox: ', _this.hideFieldNameBox);
                resolve(_this.hideFieldNameBox);
            });
        }
    }
}
