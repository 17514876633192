//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import Moment from 'moment';
    //选择入住和离店日期组件
    import HotelStayDatePicker
        from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/hotel-stay-date-picker/1.0.2/index.vue';
    import {Toast} from "vant";

    export default {
  props: {
    evectionDetailType: {
      type: String,
      default() {
        return '';
      }
    },
    info: {
      type: Object,
      default() {
        return {};
      },
    },
    applyJourneyId: {
      type: Number,
      default: 0
    },
    timeCanEdit: {
      type: Boolean,
      default: true
    },
    isFixedTop: {
      type: Boolean,
      default: false
    },
    evectionStartDate: {
      type: String,
      default: ''
    },
    evectionEndDate: {
      type: String,
      default: ''
    },
    minDate: {
      type: String,
      default: '2023-01-01'
    },
    maxDate: {
      type: String,
      default: '2100-01-01'
    },
  },
  data() {
    return {
      show: false,
      showCalendar: false,
      showCalendarFor003: false,
      defaultDate: [],
      calendarValue: [],
      radio: '1',
      radioList: [
        {
          label: '不限',
          value: '1',
        },
        {
          label: '大床',
          value: '2',
        },
        {
          label: '双床',
          value: '3',
        },
        {
          label: '单人床',
          value: '4',
        },
      ],
      standard: false,
      breakfast: false,
      windows: false,
      free: false,
      minDateFor002: new Date(),
      maxDateFor002: new Date('2100-01-01'),
      minDateFor003: new Date(),
      maxDateFor003: new Date(),
    };
  },
  created() {
    this.initCalendarData();
  },
  mounted() {
    this.$nextTick(() => {
      if (this.evectionDetailType === '001') {
        this.initHotelStayDatePicker();
      }
    })
  },
  watch: {
    evectionDetailType(val) {
      if (val === '001') {
        this.initHotelStayDatePicker();
      }
    }
  },
  methods: {
    setFilterInfo(obj) {
      this.breakfast = obj.breakfast;
      this.windows = obj.windows;
      this.free = obj.free;
    },
    onClickFilter(type) {
      this[type] = !this[type];
      this.$emit('on-filter', {
        standard: this.standard,
        breakfast: this.breakfast,
        windows: this.windows,
        free: this.free,
      });
    },
    //初始化酒店入住和离店时间组件
    initHotelStayDatePicker() {
      this.$refs['hotelStayDatePicker'].init({
        __this: this.$parent,
        mode: '200',
        applyJourneyId: this.applyJourneyId,
        timeCanEdit: this.timeCanEdit
      })
    },
    //初始化时间组件所需数据
    initCalendarData() {
      const {
        checkInDate,
        checkOutDate,
      } = this.$route.query;
      if (checkInDate) {
        const startMoment = this.$moment(checkInDate);
        this.defaultDate[0] = startMoment._d;
        this.minDateFor003 = this.$route.query.evectionStartDate ? new Date(this.$route.query.evectionStartDate) :new Date();
      }
      if (checkOutDate) {
        const endMoment = this.$moment(checkOutDate);
        this.defaultDate[1] = endMoment._d;
        this.maxDateFor003 = this.$route.query.evectionEndDate ? new Date(this.$route.query.evectionEndDate) : new Date();
      }

      this.minDateFor002 = new Date(this.minDate);
      this.maxDateFor002 = new Date(this.maxDate);
    },
    onShowCalendar() {
      if (!this.timeCanEdit) {
        Toast({message: '不能修改出差单既定行程', className: 'can-not-change-tip'});
        return
      }
      if(this.evectionDetailType === '003') {
        this.showCalendarFor003 = true;
      } else {
        this.showCalendar = true;
      }
    },
    // 格式化日历显示内容
    formatter(day) {
      return day;
    },
    //入离时间改变
    dateChange(date) {
      this.$emit('date-change');
    },
    onConfirm(date) {
      this.showCalendar = false;
      this.showCalendarFor003 = false;
      this.calendarValue = date;
      const startMoment = this.$moment(date[0]);
      const endMoment = this.$moment(date[1]);
      this.info.checkInDate = startMoment.format('YYYY-MM-DD');
      this.info.checkOutDate = endMoment.format('YYYY-MM-DD');
      this.info.nightDay = endMoment.diff(startMoment, 'days');
      this.$emit('date-change');
    },
    hotelStayDatePickerShow() {
      const hotelStayDatePicker = this.$refs.hotelStayDatePicker;
      const hotelStayDatePickerShow = hotelStayDatePicker.get_show();
      return hotelStayDatePickerShow;
    },
    hideHotelStayDatePicker() {
      this.$refs.hotelStayDatePicker.hide();
    },
    calendarShow() {
      return this.showCalendar;
    },
    hideCalendar() {
      this.showCalendar = false;
    },
    calendarFor003Show() {
      return this.showCalendarFor003;
    },
    hideCalendarFor003() {
      this.showCalendarFor003 = false;
    },
    setDefaultDate(defaultDate) {
      this.defaultDate = defaultDate;
    }
  },
  filters: {
    formatDate(val) {
      const moment = Moment(val);
      return moment.format('MM月DD日');
    },
    formatWeekDay(val) {
      if (!val) return '';
      const moment = Moment();
      const today = moment.format('YYYY-MM-DD');
      const tomorrow = moment.add(1, 'd').format('YYYY-MM-DD');

      const day = Moment(val).day();

      if (val === today) return '今天';
      if (val === tomorrow) return '明天';

      const week = {
        0: '周日',
        1: '周一',
        2: '周二',
        3: '周三',
        4: '周四',
        5: '周五',
        6: '周六',
      };

      return week[day];
    },
  },
  components: {
    HotelStayDatePicker
  }
}
