import moment from 'moment'
import consumer_flight_search_lowestPrice from '@/lib/data-service/haolv-default/consumer_flight_search_lowestPrice'
import {Toast} from "vant";
export default {
    data () {
        return {
            show: false,
            weekList: [], // 日历列表
            events: [],
            minDate: this.returnDateType(),
            maxDate: this.returnDateType(moment().add(24, 'month').format('YYYY-MM-DD'))
        }
    },
    props: {
        currentDate: String,
        minDateText: {
            type: String,
            default: ''
        },
        maxDateText: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        },
        businessType: {
            type: [Number, String],
            default: 0,
        },
        flightIndex: {
            type: Number,
            default: 1,
        },
        flightStartDate: {
            type: String,
            default: ''
        },
        flightEndDate: {
            type: String,
            default: ''
        },
    },
    components: {},
    created () {},
    mounted () {
        this.getDayList()

    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {
        currentDate: {
            immediate: true, // immediate选项可以开启首次赋值监听
            handler(newVal, oldVal) {
                // console.log(newVal, oldVal)
            }
        },
        currentWeek(newVal) {
            this.getDayList()
        },
        // minDateText: {
        //     deep: true,
        //     handler(newVal) {
        //         this.minDate = this.returnDateType(newVal)
        //     }
        // },
        // maxDateText: {
        //     deep: true,
        //     handler(newVal) {
        //         console.log('max', newVal)
        //         this.maxDate = this.returnDateType(newVal)
        //     }
        // }
    },
    computed: {
        currentWeek: {
            get () {
                return this.currentDate
            },
            set (val) {
                this.$emit('update:currentDate', val)
            }
        }
    },
    filters: {

    },
    methods: {
        filterDate(val) {
            if (this.businessType === 1 &&
                val === this.flightStartDate &&
                val === this.flightEndDate &&
                this.flightStartDate == this.flightEndDate) {
                return '往返同天';
            }
            if (this.businessType === 1 &&
                this.flightIndex === 1 &&
                val === this.flightEndDate) {
                return '返程日期';
            }
            if (this.businessType === 1 &&
                this.flightIndex === 2 &&
                val === this.flightStartDate) {
                return '去程日期';
            }
            if (val) {
                return moment(val).format('MM-DD')
            } else {
                return ''
            }
        },
        returnDateType(val) {
            let day = moment(val)
            return day.toDate()
        },
        // 获取7天日历
        getDayList() {
            let now = new Date().valueOf();
            let location = this.locationDay(2);
            if (location - now <= 0) {
                this._getWeek();
            } else {
                this._getWeek(location);
            }
            this.getPrice()
        },
        // 获取指定日期
        locationDay(val) {
            return Date.parse(this.currentWeek) - val * Math.floor(24 * 3600 * 1000);
        },
        // 获取一周时间
        _getWeek(val) {
            let arr = []
            val ? val : ''
            for (let i = 0; i < 7; i++) {
                let dateTime = moment(val).subtract(-i, "days").format("YYYY-MM-DD") //
                let week = moment(val).subtract(-i, "days")._d // 获取周几
                week = week.getDay()
                let weekName = ''
                switch (week) {
                    case 0: weekName = '星期天'
                        break
                    case 1: weekName = '星期一'
                        break
                    case 2: weekName = '星期二'
                        break
                    case 3: weekName = '星期三'
                        break
                    case 4: weekName = '星期四'
                        break
                    case 5: weekName = '星期五'
                        break
                    case 6: weekName = '星期六'
                        break
                    default: weekName = ''
                }
                let obj = {
                    week: weekName,
                    date: dateTime,
                    status: false
                };
                arr.push(obj)
            }
            arr.map((item, index) => {
                item.date === this.currentWeek ? (item.status = true) : ''
            });
            this.weekList = arr

        },
        // 获取每日最低价
        getPrice() {
            let fromStationCode = this.$parent.fromStationCode
            let toStationCode = this.$parent.toStationCode
            if (!fromStationCode || !toStationCode) return;
            let endTime = new Date().valueOf() + 24 * 3600 * 1000 * 29;
            let data = {
                arrCode: fromStationCode,
                depCode: toStationCode,
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment(endTime).format("YYYY-MM-DD"),
                supplierType: 1
            };
            consumer_flight_search_lowestPrice(data).then(res => {
                this.events = res.result;
                this.setPrice();
            });
        },
        // 设置价格
        setPrice() {
            this.weekList.map(item => {
                this.events.map(day => {
                    if (item.date === day.depDate) {
                        item.price = day.ticketPrice;
                    }
                });
            });
        },
        // 点击出发
        setTime(val) {
            if (this.disabled) {
                return
            }
            const disable = this.returnDisable(val)
            if (!disable) {
                return;
            }
            const flightDisable = this._returnFlightDisable(val);
            if (flightDisable['disable'] == false) {
                //
                this.$emit("openReturnCalendar");
                // Toast(flightDisable['disableMsg']);
                return;
            }
            this.weekList.map(item => {
                item.status = false
            });
            val.status = true
            let difference = false;
            if (this.currentWeek !== val.date) {
                difference = true;
            }
            this.currentWeek = val.date
            if (difference) {
                this.$emit("clickDate", val.date);
            }
        },
        _returnFlightDisable(value) {
            let disable = true;
            let disableMsg = '';
            let currentDay = value['date'];
            const currentDayInt = moment(currentDay + ' 00:00:00').valueOf();
            if (this.businessType === 1) {
                // 机票业务
                if (this.flightIndex === 1 &&
                    this.flightEndDate !== null &&
                    this.flightEndDate !== '') {
                    // 机票业务下，行程为去程， 且返程日期不为空的时候，需要判断当前点击日期少于等于返程日期
                    let maxFlightEndDate = moment(`${this.flightEndDate} 00:00:00`).valueOf();
                    if (currentDayInt > maxFlightEndDate) {
                        disable = false;
                        disableMsg = '去程日期不能在返程日期之后';
                    }
                }
                if (this.flightIndex === 2 &&
                    this.flightStartDate != null &&
                    this.flightStartDate !== '') {
                    // 机票业务下，行程为返程， 且返程日期不为空的时候，需要判断当前点击日期大于等于去程日期
                    let minFlightStartDate = moment(`${this.flightStartDate} 00:00:00`).valueOf();
                    if (currentDayInt < minFlightStartDate) {
                        disable = false;
                        disableMsg = '返程日期不能在去程日期之前';
                    }
                }
            }
            return {
                'disable': disable,
                'disableMsg': disableMsg,
            };
        },
        showVanCalendar() {
            if (this.disabled) {
                return
            }
            this.show = true
        },
        onConfirm(date) {
            this.currentWeek = moment(date).format('YYYY-MM-DD')
            this.$emit("clickDate", date);
            this.show = false
        },
        returnDisable(value) {
            const today = moment().format('YYYY-MM-DD')
            const currentDay = value.date
            let isPassMinDate = true
            let isPassMaxDate = true
            let isPassToday = true
            isPassToday = moment(currentDay + ' 00:00:00').valueOf() >= moment(today + ' 00:00:00').valueOf()
            if (this.minDateText && this.minDateText !== '') {
                isPassMinDate = moment(this.minDateText + ' 00:00:00').valueOf() <= moment(currentDay + ' 00:00:00').valueOf()
            }
            if (this.maxDateText && this.maxDateText !== '') {
                isPassMaxDate = moment(this.maxDateText + ' 00:00:00') >= moment(currentDay + ' 00:00:00').valueOf()
            }
            return isPassToday && isPassMinDate && isPassMaxDate
        },
        formatterCalendar(day) {
            let type = ''
            const current = moment(day.date).format('YYYY-MM-DD')
            const currentDay = moment(current + ' 00:00:00').valueOf()
            const nowDate = moment().format('YYYY-MM-DD')
            const today = moment(nowDate + ' 00:00:00').valueOf()
            let isPassMinDate = true
            let isPassMaxDate = true
            let isPassToday = true
            isPassToday = currentDay >= today
            if (this.minDateText && this.minDateText !== '') {
                isPassMinDate = moment(this.minDateText + ' 00:00:00').valueOf() <= currentDay
            }
            if (this.maxDateText && this.maxDateText !== '') {
                isPassMaxDate = moment(this.maxDateText + ' 00:00:00') >= currentDay
            }
            type = (isPassToday && isPassMinDate && isPassMaxDate) ? '' : 'disabled'

            let disable = true;
            // const currentDayInt = moment(currentDay + ' 00:00:00').valueOf();
            if (this.businessType === 1) {
                // 机票业务
                if (this.flightIndex === 1 &&
                    this.flightEndDate !== null &&
                    this.flightEndDate !== '') {
                    // 机票业务下，行程为去程， 且返程日期不为空的时候，需要判断当前点击日期少于等于返程日期
                    let maxFlightEndDate = moment(`${this.flightEndDate} 00:00:00`).valueOf();
                    if (currentDay > maxFlightEndDate) {
                        disable = false;
                        // disableMsg = '去程日期不能在返程日期之后';
                    }
                }
                if (this.flightIndex === 2 &&
                    this.flightStartDate != null &&
                    this.flightStartDate !== '') {
                    // 机票业务下，行程为返程， 且返程日期不为空的时候，需要判断当前点击日期大于等于去程日期
                    let minFlightStartDate = moment(`${this.flightStartDate} 00:00:00`).valueOf();
                    if (currentDay < minFlightStartDate) {
                        disable = false;
                        // disableMsg = '返程日期不能在去程日期之前';
                    }
                }
            }
            if (!disable) {
                type = 'disabled';
            }
            if (moment(day.date).format('YYYY-MM-DD') === this.currentWeek) {
                type = 'selected'
            }
            day.type = type
            return day
        },
        getShowType() {
            return this.show
        },
        hide() {
            this.show = false
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
