import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import wangyiServerOnline from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper";
import consumer_intention_order_details from '@/lib/data-service/haolv-default/consumer_intention_order_details';

export default {
    data() {
        return {
            orderNo: '',
            detail: {}
        }
    },
    activated() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback: (result) => {
                    this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            }, 1)
        });
        this.keyback();
        this.init();
    },
    destroyed() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });
        keyback_processor.removeEventListener();
    },
    methods: {
        //初始化
        async init() {
            this.orderNo = this.$route.query.orderNo ? this.$route.query.orderNo : '';
            await this.getDetails();
        },

        //获取列表
        async getDetails() {
            let res = await consumer_intention_order_details({orderNo: this.orderNo});
            this.detail = res.datas;
        },

        //返回
        onBack() {
            this.$router.go(-1);
        },

        //物理键返回
        keyback() {
            const __this = this;

            keyback_processor.addEventListener(function () {
                __this.onBack()
            });
        }
    }
}