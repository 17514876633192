import NavBar from "@/component/nav-bar/import_latest_version_module";
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import ChangeClausePopup from '@/page/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import ViolationResultBox from '@/page/travel-book/flight/component/violationResultBox/index.vue'
import TravelerListForReserve from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-list-for-reserve/1.0.5/index.vue'//选择出行人组件
import EvectionReserveInfoInput from '@/component/evection-reserve-info-input/1.0.1/index.vue'
import ReserveProcessBox from '@/component/reserve-process-box/1.0.0/index.vue'
import consumer_journey_getEvectionDetail from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail' // 获取出差信息
import consumer_journey_planeTicketcalculatePrice from '@/lib/data-service/haolv-default/consumer_journey_planeTicketcalculatePrice' // 计算价格
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg' // 保存信息
import consumer_journey_unityCreatePlaneTicket from '@/lib/data-service/haolv-default/consumer_journey_unityCreatePlaneTicket' // 提交订单
import consumer_web_policyConfig_flighttravelPolicyTips from '@/lib/data-service/haolv-default/consumer_web_policyConfig_flighttravelPolicyTips'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
import consumer_apply_addReserveApply from '@/lib/data-service/haolv-default/consumer_apply_addReserveApply'
import consumer_journey_orderFreeApprove from '@/lib/data-service/haolv-default/consumer_journey_orderFreeApprove'
import { awaitWrap } from "@/page/travel-book/flight/common/unit";
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import InsureChoice  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/insure-choice/1.0.2/index.vue'
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import moment from 'moment'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import {Toast, Dialog} from "vant";
import consumer_payment_payReminder from "@/lib/data-service/haolv-default/consumer_payment_payReminder";
import getEvectionAllType from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.0";
import DynamicForm from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/dynamic-form/1.0.0/index.vue'
import CostAttributionList from '@/page/travel-book/flight/component/costAttributionList/import_latest_version_module'
import FlightInsureSharePopup from '@/component/flight-insure-share-popup/1.0.0/index.vue';
import consumer_insurance_checkFreeProduct from '@/lib/data-service/haolv-default/consumer_insurance_checkFreeProduct'
import consumer_flight_search_returnStipulatesRule from '@/lib/data-service/haolv-default/consumer_flight_search_returnStipulatesRule'
import consumer_flight_search_queryPlaneNameByCodes from '@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes'
import consumer_flight_search_queryMeals from '@/lib/data-service/haolv-default/consumer_flight_search_queryMeals';
import consumer_flight_order_flightsStop from '@/lib/data-service/haolv-default/consumer_flight_order_flightsStop.js';
import cache_set from "nllib/src/cache/set";
import FlightUpLevelPackage from '@/component/flight-up-level-package/index.vue';

export default {
    data() {
        return {
            title: '填写订单',
            evectionDetailType: '',   // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            detailShow: false,
            orderFreeApprove: false,

            backDialogShow: false,

            loading: false,
            evectionNo: '',
            orderNo: '',
            evectionType: '',
            evectionRequired: '',
            workTravelType: '',
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批

            userInfo: {},
            evectionInfo: {},
            evectionParams: {
                evectionNo: '',
                evectionType: 2,
                explainDesc: '',
                feeAffiliationId: '',
                feeAffiliationName: '', // 费用归属名称
                feeAffiliationType: 1,
                linkman: '',
                linkmanPhone: '',
                reason: '',
                travelUserList: [],
                smsType: 1, // 是否发送短信， 1为是， 0为否
                partnerEctionId: '',
                centerCode: '',
                projectCode: '',
                projectName: '',
                companyId: 0,
            },
            // evectionFormParams: {},
            priceResult: {},
            flightList: [],
            violationResults: [],

            ruleShow: false,
            ruleLoading: false,
            ruleText: [], // 退改规则
            luggage: '', // 行李信息
            luggageParams: {}, // 行李对象
            productTag: 0,

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 5,
            returnSetInterval: null,

            searchTemp: '',
            intervalNum: 0,

            insuranceIdArr: [],

            canSubmit: true,

            hasNodeList: false,

            paymentPage: 0, // 是否弹窗 0.不弹 1.弹
            feeAffiliationShow: false,
            hasFreeInsureProduct: false,

            startCity: '',
            endCity: '',
            startDate: '',
            endDate: '',
            flightType: 1,
            flightDetailShow: false,

            tipsHide: true,
            hasAgreementPricePro: false, // 是否含有协议价产品
            dialogError3Visible: false,

            bookingEquityProductInfos: null, // 加享产品info
            payType: 0, // 0：企业个人支付都有；1:企业支付；2:个人支付
            tmcSnailValuations: [],
        }
    },
    components: {
        NavBar,
        OrangeNavBar,
        ViolationResultBox,
        ChangeClausePopup,
        TravelerListForReserve,
        InsureChoice,
        EvectionReserveInfoInput,
        ReserveProcessBox,
        DynamicForm,
        CostAttributionList,
        FlightInsureSharePopup,
        FlightUpLevelPackage,
    },
    created() {
    },
    mounted() {
        const _this = this;

        this.getPayRemider();
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                // _this.$refs['navBar'].$el.style.paddingTop = `${api.safeArea.top}px`;
                _this.$refs['reserveBottom'].style.paddingBottom = `${api.safeArea.bottom + 8}px`;
                _this.$refs['reserveDetailBottom'].style.paddingBottom = `${api.safeArea.bottom + 64}px`;
            }
        });
    },
    async activated() {
        let evectionParams = {
            vm: this
        };
        const evectionQuery = getEvectionAllType(evectionParams);
        console.log('evectionQuery', evectionQuery);
        this.evectionDetailType = evectionQuery.evectionDetailType;
        this.intervalNum = 0;
        this.evectionNo = this.$route.query.evectionNo;
        this.orderNo = this.$route.query.orderNo;
        this.evectionType = parseInt(this.$route.query.evectionType);
        this.evectionRequired = this.$route.query.evectionRequired === undefined ? 0 : parseInt(this.$route.query.evectionRequired);
        this.workTravelType = this.$route.query.workTravelType === undefined ? '' : parseInt(this.$route.query.workTravelType);
        await this.getOrderFreeApprove();
        await this.refundChangePolicyGet()
        await this.getUserInfo(); // 获取个人资料
        await this.getEvectionInfo();
        this.getAllPrice(); // 获取价格信息
        this.getCheckExcess();
        let userIdList = [];
        this.evectionParams.travelUserList.forEach(value => {
            userIdList.push(value.userId)
        });
        this.$refs['travelerListForReserve'].init({
            situation: '100',
            businessType: 1,
            userIdList: userIdList,
            startTime: this.flightList[0].segmentList[0].depDate,
            flightList: this.flightList,
            flightTmcSnailValuations: this.tmcSnailValuations,
        });
        console.log({
            form: this.evectionParams,
            businessType: 1,
            businessRuleControl: this.businessRuleControl,
            evectionDetailType: this.evectionDetailType
        })
        this.$refs['evectionReserveInfoInput'].init({
            form: this.evectionParams,
            businessType: 1,
            businessRuleControl: this.businessRuleControl,
            evectionDetailType: this.evectionDetailType,
            oaNo: this.evectionInfo['partnerEctionId'],
            startDate: this.flightList[0]['segmentList'][0]['depDate'],
            userId: userIdList
        })

        this.$nextTick(() => {
            console.log(this.businessRuleControl === 2, this.orderFreeApprove);
            if (this.businessRuleControl === 2 && this.orderFreeApprove) {

                // 流程
                console.log('res', this.$refs['reserveProcessBox']);
                this.$refs['reserveProcessBox'].init().then(res => {
                    this.hasNodeList = res
                })
            }
            this.initInsure()

            this.$refs.aFlightUpLevelPackage.init({
                orderNo: this.orderNo,
            })
        });
        this.setSearchReact();
        // 获取是否含赠送保险
        this.checkFreeProduct();

        keybackProcessor.addEventListener(() => {
            if (this.detailShow) {
                this.detailShow = false;
                return
            }
            if (this.ruleShow) {
                this.ruleShow = false;
                return;
            }
            if (this.$refs.travelerListForReserve.getShow()) {
                this.$refs.travelerListForReserve.hidePopup();
                return;
            }
            if (this.$refs.aInsureChoice.getShow()) {
                this.$refs.aInsureChoice.hidePopup();
                return;
            }

            if (this.backDialogShow) {
                this.backDialogShow = false
                return;
            }
            //差旅信息--成本归属弹框
            try {
                if (this.$refs.evectionReserveInfoInput.getShow()) {
                    this.$refs.evectionReserveInfoInput.hide();
                    return;
                }
            } catch (err) {

            }
            if (this.$refs.aFlightInsureSharePopup.isShow()) {
                this.$refs.aFlightInsureSharePopup.hide();
                return;
            }
            this.back()
        })
    },
    deactivated() {
        clearInterval(this.searchTemp);
        keybackProcessor.removeEventListener();
    },
    destroyed() {
        clearInterval(this.searchTemp)
    },
    watch: {},
    computed: {},
    filters: {
        filterDate(val) {
            return moment(val).format('MM月DD日')
        },
        filterShortDate(val) {
            if (val == '') {
                return '';
            }
            return moment(val).format('MM-DD')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        back() {
            this.backDialogShow = true
            /*Dialog.confirm({
                title: '温馨提示',
                message: '您的订单尚未填写完成，确认离开当前页面？',
                cancelButtonText: '离开',
                confirmButtonText: '继续预订',
                confirmButtonColor: '#ff7002',
                className: 'reserve-back-warning-dialog',
            }).then(() => {
            }).catch(() => {
                this.$router.go(-1)
            });*/
        },
        toBack() {
            this.$router.go(-1)
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.evectionRequired = res.datas.evectionRequired;
            //this.bookUpperBound = res.datas.bookUpperBound;
            //this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
            //this.unLimitedJourney = res.datas.unLimitedJourney;
            //this.changeEvectionCity = res.datas.changeEvectionCity;
            this.businessRuleControl = res.datas.businessRuleControl;
        },
        showDetail() {
            this.detailShow = !this.detailShow
        },
        async getUserInfo() {
            /*Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });*/
            this.$showLoading.show();
            let [err, res] = await awaitWrap(get_user_info());
            // Toast.clear();
            this.$showLoading.hide();
            this.userInfo = res.datas
        },
        async getEvectionInfo() {
            /*Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });*/
            this.$showLoading.show();
            let params = {
                evectionNo: this.evectionNo,
                orderNo: this.orderNo,
                pageType: 1,
                personType: 2
            };
            let [err, res] = await awaitWrap(consumer_journey_getEvectionDetail(params));
            // Toast.clear();
            this.$showLoading.hide();
            if (err) {
                return
            }
            this.evectionInfo = res.datas;
            let feeAffiliationId = res.datas.feeAffiliationId;
            let feeAffiliationName = res.datas.feeAffiliationName;
            let feeAffiliationType = res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1;
            if (!feeAffiliationName && res.datas.evectionType === 2) {
                feeAffiliationId = -1;
                feeAffiliationName = '各自所在部门';
                feeAffiliationType = 1
            }
            let reason = res.datas.reason;
            if (!reason && res.datas.evectionType === 2) {
                reason = '商务出行'
            }
            let travellerName = [];
            if (res.datas.travellerList) {
                res.datas.travellerList.forEach(value=>{
                    travellerName.push(value.staffName)
                })
            }

            this.evectionParams = {
                evectionNo: res.datas.evectionNo,
                evectionType: res.datas.evectionType,
                explainDesc: res.datas.explainDesc,
                feeAffiliationId: feeAffiliationId,
                feeAffiliationName: feeAffiliationName, // 费用归属名称
                feeAffiliationType: feeAffiliationType,
                linkman: res.datas.linkman ? res.datas.linkman : this.userInfo.realName,
                linkmanPhone: res.datas.linkmanPhone ? res.datas.linkmanPhone : this.userInfo.phone,
                reason: reason,
                travelUserList: res.datas.travellerList ? res.datas.travellerList : [],
                smsType: res.datas.contactsList && res.datas.contactsList.length > 0 ? res.datas.contactsList[0].smsType : 1, // 是否发送短信， 1为是， 0为否
                travelUser: [],
                partnerEctionId: res.datas.partnerEctionId,
                travellerName: travellerName.join(','),
                centerCode: '', // 成本中心独有字段
                projectCode: '',
                projectName: '',
                companyId: 0,
            };
            // this.evectionFormParams = {
            //     reason: this.evectionParams.reason,
            //     // travelUserList: this.evectionParams.travelUserList,
            //     feeAffiliationType: this.evectionParams.feeAffiliationType,
            //     feeAffiliationName: this.evectionParams.feeAffiliationName,
            //     feeAffiliationId: this.evectionParams.feeAffiliationId
            // };
            let tmcSnailValuations = [];
            // 获取机票行程
            let startCity = '';
            let endCity = '';
            let startDate = '';
            let endDate = '';
            let flightType = 1;
            let flightList = res.datas.flightDetail ? res.datas.flightDetail.orderList : [];
            let flightTypeList = [];
            let flightQueryMealsList = [];
            let flightStopList = [];
            flightList.forEach(value => {
                value.segmentList.forEach((value1, index1) => {
                    if (value1.policyType === 'QCYX') {
                        this.hasAgreementPricePro = true;
                    }
                    flightTypeList.push(value1.planeType);
                    flightQueryMealsList.push({
                        "flightNo":value1.flightNo,
                        "deptTime":value1.depTime,
                        "arrTime":value1.arrTime,
                    });
                    if (value1['stopNum'] > 0) {
                        flightStopList.push({
                            "rqData": {
                                "date": value1['depDate'],
                                "flightNo": value1['flightNo'],
                                'depCityCode': value1['depCityCode'],
                                'arrCityCode': value1['arrCityCode'],
                                'arrTime': value1['arrTime'],
                                'deptTime': value1['depTime'],
                                'depAirCode': value1['depCityPortCode'],
                                'arrAirCode': value1['arrCityPortCode'],
                            }
                        });
                    }
                    if (index1 === 0) {
                        startCity = value1.depCityName;
                        endCity = value1.arrCityName;
                        startDate = value1.depDate;
                    }
                    if (index1 === 1) {
                        endDate = value1.depDate;
                        flightType = 2;
                    }
                    value1.loading = false;
                    value1.ruleText = [];
                    value1.luggage = '';

                    if (value1.tmcSnailValuations) {
                        tmcSnailValuations = [...tmcSnailValuations, ...value1.tmcSnailValuations];
                    }
                })
            });
            this.tmcSnailValuations = tmcSnailValuations;
            this.flightList = flightList
            this.startCity = startCity;
            this.endCity = endCity;
            this.startDate = startDate;
            this.endDate = endDate;
            this.flightType = flightType;
            this._queryPlaneNameByCodes(flightTypeList);
            this._queryMeals(flightQueryMealsList);
            this._queryStopInfo(flightStopList);
            this.getRuleDetail();
        },
        _queryPlaneNameByCodes(flightTypeList) {
            consumer_flight_search_queryPlaneNameByCodes(flightTypeList).then(res=>{
                let resList = res.datas;
                this.flightList.forEach(value => {
                    value.segmentList.forEach((value1, index1) => {
                        resList.forEach(value2 => {
                            if (value1.planeType === value2.planeType) {
                                value1.planeName = value2.planeName;

                            }
                        })
                    })
                })
            })
        },
        _queryMeals(flightQueryMealsList) {
            let apiList = [];
            if (flightQueryMealsList) {
                flightQueryMealsList.forEach(value=>{
                    apiList.push(consumer_flight_search_queryMeals(value));
                })
            }
            Promise.all(apiList).then(resList => {
                resList.forEach(value2 => {
                    let res = value2.datas;
                    if (res == null) {
                        return
                    }
                    this.flightList.forEach(value => {
                        value.segmentList.forEach((value1, index1) => {
                            if (res.flightNo === value1.flightNo) {
                                let hasMealsText = '';
                                if (res) {
                                    if (res.hasMeals === 1) {
                                        hasMealsText = '有餐食';
                                    } else {
                                        hasMealsText = '无餐食';
                                    }
                                }
                                value1.hasMeals = res ? res.hasMeals : '';
                                value1.hasMealsText = hasMealsText;
                            }
                        })

                    })
                });
            })
        },
        _queryStopInfo(flightStopList) {
            if (flightStopList === undefined || flightStopList === null) {
                return;
            }
            let apiList = [];
            flightStopList.forEach(value => {
                apiList.push(consumer_flight_order_flightsStop(value));
            })

            Promise.all(apiList).then(resList => {
                resList.forEach(value2 => {
                    let res = value2.datas;
                    if (res == null) {
                        return
                    }
                    this.flightList.forEach(value => {
                        value.segmentList.forEach((value1, index1) => {
                            if (res['flightNo'] === value1['flightNo'] &&
                                res['date'] === value1['depDate']) {
                                let _stopData = res['stopInfos'];
                                let _stopDataResult = [];
                                if (_stopData && _stopData.length > 0) {
                                    _stopData.forEach((value, index) => {
                                        if (index !== 0 && index !== (_stopData.length - 1)) {
                                            const _depTimeArr = value['depTime'].split(':');
                                            const _arrTimeArr = value['arrTime'].split(':');
                                            const _depTime =
                                                parseInt(_depTimeArr[0]) * 60 + parseInt(_depTimeArr[1]);
                                            const _arrTime =
                                                parseInt(_arrTimeArr[0]) * 60 + parseInt(_arrTimeArr[1]);
                                            value['time'] = _depTime - _arrTime;
                                            _stopDataResult.push(value);
                                        }
                                    });
                                    value1['stopData'] = _stopDataResult;
                                }
                            }
                        });
                    });
                })
            })
        },
        getRuleDetail() {
            this.ruleText = [];
            let params = {
                rqData: []
            };
            this.flightList.forEach(value => {
                value.segmentList.forEach(value1=>{
                    const rqData = {
                        cabinCode: value1.cabinCode,
                        flightKey: value1.flightKey,
                        flightNo: value1.flightNo,
                        policyId: value1.policyId
                    };
                    params.rqData.push(rqData);
                })

            })
            consumer_flight_search_returnStipulatesRule(params).then(res=>{
                let ruleText = res.datas.rsData;
                this.flightList.forEach(value=>{
                    ruleText.forEach(value1 => {
                        if (value1.policyId === value.policyId) {
                            value1.productTag = value.productTag;
                            value1.depCityName = value.depCityName;
                            value1.arrCityName = value.arrCityName;
                        }
                    });
                })
                this.ruleText = ruleText;
            }).catch(()=>{
            })
        },
        // 获取订单所有价格
        async getAllPrice() {
            let productIds = [];
            if (this.bookingEquityProductInfos) {
                productIds.push(this.bookingEquityProductInfos.productId);
            }
            let params = {
                evectionNo: this.evectionNo,
                orderNo: this.orderNo,
                headCounts: this.evectionType === 2 ? this.evectionParams.travelUserList.length : this.evectionParams.travelUser.length,
                packageCodes: this.insuranceIdArr,
                productIds: productIds, //加享产品
            };
            let res = await consumer_journey_planeTicketcalculatePrice(params);
            let priceResult = res.datas;
            let payType = 0;
            if (priceResult.companyPayAmount <= 0) {
                payType = 2;
            } else if (priceResult.personPayAmount <= 0) {
                payType = 1;
            } else {
                payType = 0;
            }
            let flightRebatePrice = 0;
            let flightAirportTaxAndFuelTax = 0;
            let flightSubtotal = 0;
            if (priceResult.flightCalculates) {
                priceResult.flightCalculates.forEach(value => {
                    flightRebatePrice += parseFloat(value.rebatePrice);
                    flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax + parseFloat(value.airportTax) + parseFloat(value.fuelTax);
                    flightSubtotal += parseFloat(value.subtotal)
                })
            }
            priceResult.flightRebatePrice = flightRebatePrice;
            priceResult.flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax;
            priceResult.flightSubtotal = flightSubtotal;
            this.payType = payType;
            this.priceResult = priceResult
        },
        payTypeSet(val) {
            let text = '';
            switch (val) {
                case 0:
                    text = '总价';
                    break;
                case 1:
                    text = '企业支付';
                    break;
                case 2:
                    text = '个人支付';
                    break;
            }
            return text;
        },
        getCheckExcess() {
            this.flightList.forEach(value => {
                let params = {
                    "evectionNo": this.evectionNo,
                    "orderNo": this.orderNo,
                };
                consumer_web_policyConfig_flighttravelPolicyTips(params).then(res => {
                    this.violationResults = res.datas || []
                })
            })
        },
        // 展示退改规则弹窗
        showThisRuleList(fIndex, index, val, item) {
            this.ruleLoading = true;
            this.ruleText = [];
            this.ruleShow = true;
            let params = {
                rqData: {
                    cabinCode: val.cabinCode,
                    flightKey: val.flightKey,
                    flightNo: val.flightNo,
                    policyId: val.policyId
                }
            };
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleText = res.datas.rsData;
            }).finally(() => {
                this.ruleLoading = false
            })
        },
        // 关闭退改规则弹窗
        closeRuleShow() {
            this.ruleShow = false
        },
        // 保存信息
        async saveInfo() {
            let params = Object.assign({}, this.evectionParams);
            params.evectionNo = this.evectionNo;
            console.log(params);
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params));
            if (err) {
                return false
            }
            return res
        },
        // 最终创建订单
        submitLowPrice() {
            // const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL14','OPL34','OPL1','SGL1','OPL26','OPL30'];
            return new Promise((resolve,reject)=> {
                //debugger
                let index = 0;
                let hasLowPrice = [];
                this.flightList.forEach(value => {
                    value.segmentList.forEach(value1 => {
                        if (value1.productTag === 5) {
                            hasLowPrice.push(index);
                            index++;
                        }
                    })
                })
                if (hasLowPrice.length === 0) {
                    resolve(true);
                } else {
                    // 弹窗
                    let message = '';
                    this.ruleText.forEach((value, index) => {
                        if (hasLowPrice.indexOf(index) > -1) {
                            message += `本产品为低价精选产品，最晚于${value.timeLimitQueue}出票，报销凭证提供${value.tagProperty}。\n`;
                            if (value.certificateLimit) {
                                message += `证件限制:${value.certificateLimit}\n`;
                            }
                            if (value.ageLimit) {
                                message += `年龄限制:${value.ageLimit}\n`;
                            }
                            if (value.numberLimit) {
                                message += `人数限制:${value.numberLimit}\n`;
                            }
                        }
                    })

                    Dialog.confirm({
                        title: '温馨提示',
                        message: message,
                        confirmButtonText: '继续预定',
                        cancelButtonText: '重新选择价格',
                    }).then(() => {
                        // on confirm
                        resolve(true);
                    }).catch(() => {
                        // on cancel
                        reject(false);
                    });
                }
            })
        },
        submitPayTips() {
            return new Promise((resolve, reject)=>{
                if (this.paymentPage === 1) {
                    // 弹窗
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '因公出行订单费用将由企业账户支付',
                        confirmButtonText: '继续预定',
                        cancelButtonText: '取消预定',
                    }).then(() => {
                        // on confirm
                        resolve(true);
                    }).catch(() => {
                        // on cancel
                        reject(false);
                    });
                } else {
                    // 不弹
                    resolve(true);
                }
            })
        },
        showPersonalPayTips() {
            return new Promise((resolve, reject) => {
                if (this.bookingEquityProductInfos && this.payType === 0) {
                    // 弹窗
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '定制服务于机票产品的报销凭证将一并开具给公司，个人不可开具发票。',
                        confirmButtonText: '继续预定',
                        cancelButtonText: '取消预定',
                    }).then(() => {
                        // on confirm
                        resolve(true);
                    }).catch(() => {
                        // on cancel
                        reject(false);
                    });
                } else {
                    resolve(true)
                }
            })
        },
        timeLongTips(time) {
            return new Promise((resolve, reject) => {
                if (time && time >= 20) {
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '该订单支付后预计30分钟内出票，是否继续预订？',
                        confirmButtonText: '继续预订',
                        cancelButtonText: '取消',
                    })
                        .then(() => {
                            // on confirm
                            resolve(true);
                        })
                        .catch(() => {
                            // on cancel
                            resolve(false);
                        });
                } else {
                    resolve(true)
                }
            })
        },
        // 最终创建订单
        async createOrder() {
            if (this.businessRuleControl === 2 && this.orderFreeApprove && !this.hasNodeList && this.$store.state.workflow === '2') {
                Toast('当前没有审批流，请联系管理员')
                return
            }
            //无自营价格只能出飞常准携程运价，且该运价出票时限大于20分钟，点击创建订单时，弹出提示
            let timeTipsRes = await this.timeLongTips(this.$route.query.ticketLimitTime);
            if (!timeTipsRes) {
                return;
            }
            // 使用getInfo()获取验证结果和表单信息，返回是Promise
            //     res.valid--表单验证结果(true--全部通过)
            //     res.evectionParams--固定表单信息(对象)
            //     res.list--动态表单信息(数组)
            let formInfo = await this.$refs.evectionReserveInfoInput.getInfo();
            console.log(formInfo);
            if (!formInfo.valid) {
                const listLength = formInfo.list.length;
                for (let i = 0; i < listLength; i++) {
                    if (!formInfo.list[i].parameterForm) {
                        const text = `请填写${formInfo.list[i].parameterText}`;
                        Toast(text);
                        break
                    }
                }
                return
            } else {
                this.evectionParams.reason = formInfo.evectionParams.reason;
                this.evectionParams.feeAffiliationName = formInfo.evectionParams.feeAffiliationName;
                this.evectionParams.feeAffiliationType = formInfo.evectionParams.feeAffiliationType;
                this.evectionParams.feeAffiliationId = formInfo.evectionParams.feeAffiliationId;
                this.evectionParams.partnerEctionId = formInfo.evectionParams.partnerEctionId;
                this.evectionParams.centerCode = formInfo.evectionParams.centerCode;
                this.evectionParams.companyId = formInfo.evectionParams.companyId;
                this.evectionParams.projectCode = formInfo.evectionParams.projectCode;
                this.evectionParams.projectName = formInfo.evectionParams.projectName;
            }


            if (!this.canSubmit) {
                return
            }
            this.canSubmit = false;
            /*Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "",
            });*/
            this.$showLoading.show();
            let travelerInfo = this.$refs['travelerListForReserve'].getTravelerInfo();
            this.evectionParams.linkman = travelerInfo.linkman;
            this.evectionParams.linkmanPhone = travelerInfo.linkmanPhone;
            this.evectionParams.smsType = travelerInfo.smsType;
            this.evectionParams.linkmanSms = travelerInfo.linkmanSms;
            this.evectionParams.userList = travelerInfo.userList;

            let hasChild = false;
            let hasDifferentAge = false;
            let hasDifferentAgeNameList = [];
            let hasNotCertificateType = false;
            let hasUserCardErr = false;
            let userErrCardTips = '';
            let userCardErrName = [];
            let hasUserAgeErr = false;
            let userErrAgeTips = '';
            let userAgeErrName = [];
            let hasUserLengthErr = false;
            let userLengthErrTips = '';
            this.evectionParams.userList.forEach(item=>{
                if (!item.certificateType) {
                    hasNotCertificateType = true;
                }
                if (item.age < 13) {
                    hasChild = true;
                }
                if (item.age < 22 || item.age > 65) {
                    hasDifferentAge = true;
                    hasDifferentAgeNameList.push(item.staffName);
                }
                if (item.cardErrShow) {
                    hasUserCardErr = true;
                    userErrCardTips = item.cardErrTips;
                    userCardErrName.push(item.name);
                }
                if (item.ageErrShow) {
                    hasUserAgeErr = true;
                    userErrAgeTips = item.ageErrTips;
                    userAgeErrName.push(item.name);
                }
                this.tmcSnailValuations.forEach(value => {
                    if (value.passengerNum !== '1,9') {
                        let passengerNum = value.passengerNum.split(',');
                        const userLength = this.evectionParams.userList.length;
                        if (passengerNum[0] > userLength || passengerNum[1] < userLength) {
                            hasUserLengthErr = true;
                            userLengthErrTips = `该产品仅限${passengerNum[0]}-${passengerNum[1]}人预订，当前出行人数不符合条件`
                        }
                    }
                })
            })
            if (hasNotCertificateType) {
                this.$showLoading.hide();
                Toast.fail('请先添加出行人的证件');
                return;
            }
            if (hasChild) {
                this.$showLoading.hide();
                Toast.fail('暂不支持在线预订儿童票');
                return;
            }
            if (this.hasAgreementPricePro && hasDifferentAge) {
                this.$showLoading.hide();
                this.errorText = `协议价仅限乘客年龄22至65周岁预订，乘客${hasDifferentAgeNameList.join('，')}年龄不符合要求，请选择其他非协议价预订`;
                this.dialogError3Visible = true;
                return;
            }
            if (this.evectionParams.linkman === '' || this.evectionParams.linkmanPhone === '') {
                this.canSubmit = true;
                // Toast.clear();
                this.$showLoading.hide();
                Toast.fail('请填写联系人');
                return
            }

            let errText = ``;
            if (hasUserLengthErr) {
                errText += `${userLengthErrTips}\/n`;
            }
            if (hasUserAgeErr) {
                errText += `${userErrAgeTips},当前出行人${userAgeErrName.join(',')}不符合要求\/n`;
            }
            if (hasUserCardErr) {
                errText += `${userErrCardTips},当前出行人${userCardErrName.join(',')}不符合要求\/n`;
            }
            if (errText) {
                this.$showLoading.hide();
                Dialog({message: errText});
                return
            }
            this.$showLoading.hide();

            const [sErr, sRes] = await awaitWrap(this.submitLowPrice());
            if (sErr === false) {
                return
            }
            const [eErr, eRes] = await awaitWrap(this.showPersonalPayTips());
            if (eErr === false) {
                return
            }
            const [pErr, pRes] = await awaitWrap(this.submitPayTips());
            if (pErr === false) {
                return
            }
            this.$showLoading.show();
            let saveResult = await this.saveInfo();
            if (!saveResult) {
                this.canSubmit = true;
                // Toast.clear();
                this.$showLoading.hide();
                return
            }
            clearInterval(this.searchTemp);
            let equityProductId = '';
            let equityProductPrice = '';
            if (this.bookingEquityProductInfos) {
                equityProductId = this.bookingEquityProductInfos.productId;
                equityProductPrice = this.bookingEquityProductInfos.farePat;
            }
            let [err, result] = await awaitWrap(consumer_journey_unityCreatePlaneTicket({
                evectionNo: this.evectionNo,
                orderNo: this.orderNo,
                pageType: 1,
                orderClient: 2,
                reportConfiguration: formInfo.list,
                equityProductId: equityProductId,
                equityProductPrice: equityProductPrice,
            }));
            if (err) {
                this.setSearchReact();
                // 200017 下单失败!机票订票失败:该航班变价，请重新搜索！
                this.canSubmit = true;
                // Toast.clear();
                this.$showLoading.hide();
                let orderResultList = err.data.datas ? err.data.datas.orderResultList : [];
                let errorCode = err.data.code;
                this.errorText = err.data.msg;
                if (errorCode === '200017' || errorCode === '1000000' || errorCode === '200029' || errorCode === '300052') {
                    this.dialogError3Visible = true;
                    /*this.dialogErrorVisible = true;
                    this.returnSecond = 5;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.returnToSearch()
                        }
                    }, 1000)*/
                } else {
                    this.dialogError2Visible = true
                }
                if (orderResultList) {
                    orderResultList.forEach(value => {
                        if (!value.isSuccess) {
                            let [...flightList] = this.flightList;
                            flightList.forEach(value1 => {
                                if (value1.journeyNo === value.orderNo) {
                                    value1.showError = true;
                                    value1.errorReason = value.desc
                                }
                            });
                            this.flightList = flightList
                        }
                    })
                }
                return
            }
            let orderResult = result.datas;
            let canSubmit = true;
            let desc = ''
            let paymentPage = 0
            orderResult.orderResultList.forEach((value,index) => {
                if (index === 0) {
                    desc = value.desc
                    paymentPage = value.paymentPage
                }
                if (!value.isSuccess) {
                    canSubmit = false;
                    let [...flightList] = this.flightList;
                    flightList.forEach(value1 => {
                        if (value1.journeyNo === value.orderNo) {
                            value1.showError = true;
                            value1.errorReason = value.desc
                        }
                    });
                    this.flightList = flightList
                }
            });
            if (canSubmit) {
                let controlRule; // 超标管控规则 1:允许预定,只做提示, 3:不允许预定 10:超标需审批 11:超额自付
                if (this.violationResults.length > 0) {
                    controlRule = this.violationResults[0].controlRule;
                }
                let [iErr, iRes] = await awaitWrap(this.$refs.aInsureChoice.submit());
                if (iErr) {
                    this.canSubmit = true;
                    // Toast.clear()
                    this.$showLoading.hide();
                    return;
                }
                this.canSubmit = true;
                // Toast.clear();
                this.$showLoading.hide();
                if (paymentPage && desc.indexOf('机票预订成功') === -1) {
                    await Dialog.alert({
                        title: '温馨提示',
                        message: desc,
                    })
                }
                const orderNo = orderResult.orderResultList[0].orderNo;
                const params = {
                    orderNo,
                    evectionNo: this.evectionNo,
                    flightOrderList: orderNo,
                    orderType: '5', // 去机票订单列表
                    businessType: 1,
                };
                // 因公出差规则 1：出差单审批 2：预订审批
                if (this.businessRuleControl === 1) {
                    if (orderResult.isHaveOverproof && controlRule !== 1) {
                        // 如果超标 去结果页面
                        this.$router.push({
                            name: 'flight-reserve-result',
                            query: {
                                orderNo
                            }
                        })
                    } else {
                        //没有差标 去支付
                        this.$router.push({
                            name: 'h5-travel-payment',
                            query: params
                        })
                    }
                } else {
                    // 提交审批
                    // 跳转完成页面
                    // 订单是否需要审批 true:需要 false:不用
                    if (this.orderFreeApprove || (orderResult.isHaveOverproof && controlRule !== 1)) {
                        this.$router.push({
                            name: 'flight-reserve-result',
                            query: {
                                orderNo
                            }
                        })
                    } else {
                        //Toast.clear();
                        this.$router.push({
                            name: 'h5-travel-payment',
                            query: params
                        })
                    }
                }
            } else {
                this.setSearchReact();
                this.canSubmit = true;
                // Toast.clear();
                this.$showLoading.hide();
                Toast.fail('订票失败，请重新选择行程')
            }
        },
        returnCabin() {
            this.dialogError3Visible = false;
            this.$router.go(-1);
        },
        returnToSearch() {
            if (!this.dialogErrorVisible) {
                return
            }
            this.dialogErrorVisible = false;
            clearInterval(this.returnSetInterval);
            cache_set({
                key: 'flightErr',
                value:  1
            })
            /*this.$router.replace({
                name: 'h5-flight-home'
            })*/
            this.$router.go(-2);
        },
        getTravelerInfo(param) {
            this.evectionParams.userList = param.userList;
            this.getAllPrice()
        },
        setSearchReact() {
            this.searchTemp = setInterval(() => {
                this.intervalNum++;
                if (this.intervalNum >= 30 * 60) {
                    console.log(`因公预订页倒计时${this.intervalNum}`);
                    clearInterval(this.searchTemp);
                    Toast('页面停留时间超过30分钟，请您重新搜索航班');
                    this.intervalNum = 0;
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1000)
                }
            }, 1000)
        },
        initInsure() {
            this.$refs.aInsureChoice.init({
                mock_data:{
                    //是否使用随机数据--true/false
                    enable: false,
                },
                //业务类型--1：机票/2：火车票
                businessType: 1,
                //订单号
                orderNo: this.orderNo,
                evectionType: this.evectionType,
                flightType: this.flightType,
            });
        },
        changeInsure(data) {
            this.insuranceIdArr = data.id_list;
            this.getAllPrice()
        },
        async getOrderFreeApprove() {
            let res = await consumer_journey_orderFreeApprove({
                businessType: 1,
                orderNo: this.orderNo
            })
            this.orderFreeApprove = res.datas.orderFreeApprove
        },
        getPayRemider() {
            consumer_payment_payReminder().then(res=>{
                this.paymentPage = res.datas.paymentPage;
            })
        },
        getFeeAffiliation() {
            console.log(this.evectionType, this.workTravelType);
            if (this.evectionType === 2 && this.workTravelType === 1) {
                return
            }
            this.feeAffiliationShow = true
        },
        showInsureSharePopup() {
            this.$refs.aFlightInsureSharePopup.show();
        },
        checkFreeProduct() {
            consumer_insurance_checkFreeProduct().then(res => {
                this.hasFreeInsureProduct = res.datas;
            })
        },
        showFlightDetail() {
            this.flightDetailShow = true;
        },
        showChangeReturnDetail() {
            this.ruleLoading = true;
            this.ruleText = [];
            this.ruleShow = true;
            let params = {
                rqData: []
            };
            this.flightList.forEach(value => {
                value.segmentList.forEach(value1=>{
                    const rqData = {
                        cabinCode: value1.cabinCode,
                        flightKey: value1.flightKey,
                        flightNo: value1.flightNo,
                        policyId: value1.policyId
                    };
                    params.rqData.push(rqData);

                    this.productTag = value1.productTag;
                })

            })
            consumer_flight_search_returnStipulatesRule(params).then(res=>{
                this.ruleLoading = false;
                let ruleText = res.datas.rsData;
                this.flightList.forEach(value=>{
                    ruleText.forEach(value1 => {
                        if (value1.policyId === value.policyId) {
                            value1.depCityName = value.depCityName;
                            value1.arrCityName = value.arrCityName;
                            value1.productTag = value.productTag;
                        }
                    });
                })
                this.ruleText = ruleText;
            }).catch(()=>{
                this.ruleLoading = false;
            })
        },

        showOrHideTips() {
            this.tipsHide = !this.tipsHide;
        },
        changeProduct(item) {
            this.bookingEquityProductInfos = item;
            // 去计算价格
            this.getAllPrice();
        },
        matchFlightPrice(item) {
            let result = 0;
            result = parseFloat(item.rebatePrice || 0) + parseFloat(parseFloat(item.airportTax) + parseFloat(item.fuelTax) || 0);
            // 加上加享产品
            if (this.priceResult.bookingEquityProductInfos) {
                this.priceResult.bookingEquityProductInfos.forEach(value=>{
                    result += parseFloat(value.markupPrice || 0);
                })
            }
            return result;
        },
        showProductDetail() {
            this.$refs.aFlightUpLevelPackage._showDetail(this.bookingEquityProductInfos);
        },
    }
}
