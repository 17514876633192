import _ from 'underscore';
import extend from 'extend';
import {Dialog, Notify, Toast} from "vant";
import {awaitWrap} from '@/page/travel-book/flight/common/unit'

import consumer_t_od_hotel_order_interim_hotelOrderBookingDetail
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_hotelOrderBookingDetail'//获取订单信息
import consumer_t_od_hotel_order_interim_calculateHotelPersonPrice
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_calculateHotelPersonPrice'//计算总价
import consumer_t_od_hotel_order_interim_createOrderOnPerson
    from '@/lib/data-service/haolv-default/consumer_t_od_hotel_order_interim_createOrderOnPerson' //创建订单
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息

import CommonlyUsedPassenger
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/commonly-used-passenger/1.0.1/index.vue"//常旅客组件
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'

export default {
    data() {
        return {
            loading: true,//是否加载中
            orderNo: '',//订单号
            submitting: false,//是否提交中
            isShowRoomNumPopup: false,//是否显示房间数弹框
            isShowArrivalTimePopup: false,//是否显示房间数弹框
            isShowPriceDetailPopup: false,//是否显示房间数弹框
            isShowCertificateTypePopup: false,//是否显示证件类型list
            priceDetailArrow: 'arrow-up',//价格明细表示是否显示弹框的箭头
            roomAmountList: [],//房间数量的list
            pickerOptions: [],
            form: {
                arrivalTime: '13:00',//最晚到店时间
                remarks: '',//备注
                linkman: '',//联系人
                linkmanPhone: '',//联系人电话
                userList: [],//入住人信息
                roomAmount: 0,//预定的房间数量
                roomList: [{
                    roomId: 1,
                    user: {
                        personName: '',
                        certificateType: 0,
                        certificateValue: '',
                        certificateTypeText: '',
                        certificateTypeList: [],//证件列表
                    }
                }],
            },
            isLinkmanSms: true,//是否发送行程信息到联系人手机
            arrivalTime: '13:00',//弹框中选中的最晚到店时间
            hotelOrderInfo: {},//酒店订单信息
            priceResult: {},//订单价格信息
            curUser: {},//当前登陆用户信息
            curUserCertificateType: 0,//当前登录用户选中的证件类型
            curUserCertificateText: '',//当前登录用户选中的证件名称
            curUserCertificateValue: '',//当前登录用户选中的证件号码
            curUserCertificateTypeList: [],//当前登录用户可用的证件list
            certificateType: 0,//可选证件list中默认选中的证件类型
            certificateText: '',//可选证件list中默认选中的证件名称
            certificateTypeList: [],//可选证件list中默认选中的证件名称
            curRoomIndex: 0,//当前需要更改证件信息的房间index
            curCertificateTypeList: [],//当前需要更改证件信息的证件类型列表
            choiceCardType: {},//证件类型弹框中选中的证件类型
            isShowBackDialog: false,
            today: '',
            isTodayMorning: false,
        }
    },
    components: {
        CommonlyUsedPassenger
    },
    async activated() {
        const __this = this;
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback:(result) => {
                    __this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            },200)
        });

        this.keyback();
        this.form.roomAmount = 1;
        this.init();
    },
    deactivated() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });

        keyback_processor.removeEventListener();
    },
    watch: {
        //房间数量改变时，改变需要选定入住人数和预定总价
        'form.roomAmount'(newVal) {
            this.form.roomList = [{
                roomId: 1,
                user: {
                    personName: this.curUser.realName,
                    certificateType: this.curUserCertificateType,
                    certificateValue: this.curUserCertificateValue,
                    certificateTypeText: this.certificateTypeText,
                    certificateTypeList: this.curUserCertificateTypeList,
                }
            }];
            for (let i = 1; i < newVal; i++) {
                const p = {
                    roomId: i + 1,
                    user: {
                        personName: '',
                        certificateType: this.certificateType,
                        certificateValue: '',
                        certificateTypeText: this.certificateTypeText,
                        certificateTypeList: this.certificateTypeList,
                    }
                };
                const o = extend(true, {}, p);
                // const o = extend({},p);

                this.form.roomList.push(o)
            }
            this.getAllPrice();
        },
    },
    methods: {
        //初始化
        async init() {
            this.loading = true;
            this.$showLoading.show();
            this.orderNo = this.$route.query.orderNo;
            await this.getOrderInfo();
            this.initSelectorData();
            await this.getCurUser();
            this.setDefaultVal();
            this.loading = false;
            this.$showLoading.hide();
        },

        //获取订单信息
        async getOrderInfo() {
            let res = await consumer_t_od_hotel_order_interim_hotelOrderBookingDetail({orderNo: this.orderNo});
            this.hotelOrderInfo = res.datas;

            this.today = this.$moment().format('YYYY-MM-DD');
            let yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
            this.isTodayMorning = this.hotelOrderInfo.checkInDate === yesterday;
        },

        //初始化选人弹框数据
        initSelectorData() {
            this.getContactSingleSelectorData();
        },

        // 获取当前登陆用户信息
        async getCurUser() {
            let res = await get_user_info();
            this.curUser = res.datas;
        },

        //设置默认值
        setDefaultVal() {
            this.roomAmountList = [];
            for (let i = 1; i < 9; i++) {
                this.roomAmountList.push(i + '间');
            }
            this.form.roomList[0].user.personName = this.curUser.realName;

            let certificateTypeList = this.hotelOrderInfo.certificateResults;
            if (this.hotelOrderInfo.needCertificate === 0 &&
                this.curUser.certificates && this.curUser.certificates.length > 0 &&
                certificateTypeList && certificateTypeList.length > 0
            ) {
                //该酒店可选的证件list
                this.certificateTypeList = certificateTypeList;
                let certificateObj = certificateTypeList.find(item => {
                    return item.certificateType === 1;
                })
                if (!certificateObj) {
                    certificateObj = certificateTypeList[0];
                }
                this.certificateTypeList.forEach(item => {
                    this.$set(item, 'isSelected', false);
                    if (item.certificateType === certificateObj.certificateType) {
                        this.$set(item, 'isSelected', true);
                        this.certificateType = item.certificateType;
                        this.certificateTypeText = item.certificateTypeText;
                    }
                })

                //在当前登录人证件list和该酒店可选的证件list中寻找当前登录人默认选中的证件信息
                const certificateTypeList1 = certificateTypeList.map(item => {
                    return item.certificateType;
                });
                const certificateTypeList2 = this.curUser.certificates.map(item => {
                    return item.certificateType;
                });
                const certificateTypeList3 = _.intersection(certificateTypeList1, certificateTypeList2);
                const certificateTypeList4 = this.curUser.certificates.filter(function (item) {
                    const certificateType = item.certificateType;
                    return _.contains(certificateTypeList3, certificateType);
                });
                let certificateObj1 = certificateTypeList4.find(item => {
                    return item.certificateType === 1;
                })
                if (!certificateObj1) {
                    let type1 = {
                        certificateType: 1,
                        certificateTypeText: '身份证',
                        certificateValue: '',
                    }
                    certificateObj1 = certificateTypeList4[0] ? certificateTypeList4[0] : type1;
                }
                let certificateObj2 = certificateTypeList.find(item => {
                    return item.certificateType === certificateObj1.certificateType;
                })
                this.curUserCertificateType = certificateObj1.certificateType;
                this.curUserCertificateText = certificateObj2.certificateTypeText;
                this.curUserCertificateValue = certificateObj1.certificateValue;
                this.form.roomList[0].user.certificateType = certificateObj1.certificateType;
                this.form.roomList[0].user.certificateTypeText = certificateObj2.certificateTypeText;
                this.form.roomList[0].user.certificateValue = certificateObj1.certificateValue;

                certificateTypeList.forEach(item => {
                    this.$set(item, 'isSelected', false);
                    if (item.certificateType === certificateObj1.certificateType) {
                        this.$set(item, 'isSelected', true);
                    }
                })
                this.curUserCertificateTypeList = certificateTypeList;
                this.form.roomList[0].user.certificateTypeList = certificateTypeList;
            }

            const moment = this.$moment();
            const curDate = moment.format('YYYY-MM-DD');
            if (this.isTodayMorning) {
                //当天凌晨入住
                let hour = 1;
                if (this.$moment().hour() > 1) {
                    hour = this.$moment().hour() + 1;
                }
                this.form.arrivalTime = `${hour}:00`;
                for (let i = hour; i < 7; i++) {
                    this.pickerOptions.push(i + ':00')
                }
            } else {
                if (curDate === this.hotelOrderInfo.checkInDate) {
                    let hour = 13;
                    if (moment.hour() > 13 || (this.$moment().hour() === 13 && this.$moment().minute() > 0)) {
                        hour = moment.hour() + 1;
                    }
                    this.form.arrivalTime = `${hour}:00`;
                    for (let i = hour; i < 25; i++) {
                        this.pickerOptions.push(i + ':00')
                    }
                } else {
                    this.form.arrivalTime = '13:00';
                    this.pickerOptions = [];
                    for (let i = 13; i < 25; i++) {
                        this.pickerOptions.push(i + ':00')
                    }
                }
            }

            this.form.linkman = this.curUser.realName;
            this.form.linkmanPhone = this.curUser.phone;
        },

        //初始化选择联系人弹框
        getContactSingleSelectorData() {
            const _this = this;
            this.$refs.aCommonlyUsedPassenger.init({
                type: '100',
                btn_contacts_click_event_handler(args) {
                    const contacts = args.user_list;
                    _this.form.linkman = contacts.frequentPassengerName;
                    _this.form.linkmanPhone = contacts.phone;
                },
            })
        },

        //返回
        back() {
            this.isShowBackDialog = true;
            Dialog.confirm({
                title: '温馨提示',
                message: '您的订单尚未填写完成，确认离开当前页面？',
                cancelButtonText: '离开',
                confirmButtonText: '继续预订',
                confirmButtonColor: '#ff7002',
            }).then(() => {
                this.isShowBackDialog = false;
            }).catch(() => {
                this.isShowBackDialog = false;
                this.$router.go(-1)
            });
        },

        //打开证件类型弹框
        showCertificateListPopup(item, index) {
            this.curRoomIndex = index;
            // this.curCertificateTypeList = JSON.parse(JSON.stringify(item.user.certificateTypeList));
            this.curCertificateTypeList = item.user.certificateTypeList;
            this.isShowCertificateTypePopup = true;
        },

        //选中证件类型
        choseCertificateType(item) {
            this.curCertificateTypeList.forEach(m => {
                m.isSelected = false;
            })
            this.$set(item, 'isSelected', true)
            let curUser = this.form.roomList[this.curRoomIndex].user;
            curUser.certificateType = item.certificateType;
            curUser.certificateValue = '';
            curUser.certificateTypeText = item.certificateTypeText;
            curUser.certificateTypeList = this.curCertificateTypeList;
            this.isShowCertificateTypePopup = false;
        },

        //打开选择联系人popup
        openContactPopup() {
            this.$refs.aCommonlyUsedPassenger.showContactsPopup();
        },

        //打开价格明细弹框
        showPriceDetailPopup() {
            this.isShowPriceDetailPopup = !this.isShowPriceDetailPopup;
            this.priceDetailArrow = this.isShowPriceDetailPopup ? 'arrow-down' : 'arrow-up';
        },

        //打开设置预定房间数量的弹框
        showRoomNumPopup() {
            this.isShowRoomNumPopup = true;
        },

        //确定选择房间数量
        onRoomNumConfirm(value, index) {
            this.isShowRoomNumPopup = false;
            this.form.roomAmount = index + 1;
        },

        //取消修改房间数量
        onRoomNumCancel() {
            this.isShowRoomNumPopup = false;
        },

        //获取房间号
        getRoomTxt(index) {
            let roomIdx = index + 1;
            return "入住人" + roomIdx
        },

        //打开选择最晚到点时间弹框
        showArrivalTime() {
            this.isShowArrivalTimePopup = true;
        },

        //关闭选择最晚到点时间弹框
        closeArrivalTime() {
            this.arrivalTime = this.form.arrivalTime;
            this.isShowArrivalTimePopup = false;
        },

        //确定修改最晚到点时间
        confirmArrivalTime() {
            this.form.arrivalTime = this.arrivalTime;
            this.closeArrivalTime();
        },

        // 计算订单价格
        async getAllPrice() {
            let params = {
                orderNo: this.orderNo,
                headCounts: this.form.roomAmount,
                roomNum: this.form.roomAmount
            }
            let res = await consumer_t_od_hotel_order_interim_calculateHotelPersonPrice(params)
            this.priceResult = res.datas
        },

        //创建订单
        async createOrder() {
            let params = {
                orderNo: this.orderNo,
                linkman: this.form.linkman,
                linkmanPhone: this.form.linkmanPhone,
                linkmanSms: this.isLinkmanSms ? 1 : 0,
                roomNum: this.form.roomAmount,
                latestArrivalTime: this.form.arrivalTime,
                hotelRemark: this.form.remarks,
                orderAmount: this.priceResult.sum,
                personResults: this.form.roomList.map(item => {
                    return item.user
                }),
            };
            let [err, result] = await awaitWrap(consumer_t_od_hotel_order_interim_createOrderOnPerson(params));
            if (err) {
                return false
            }
            let canSubmit = result.datas.isSuccess;
            return canSubmit;
        },

        //检查是否可提交
        checkForm() {
            let canSubmit = false;

            //必填-到店时间
            if (!this.form.arrivalTime) {
                Notify({type: 'warning', message: '请选择到店时间'});
                return canSubmit;
            }

            //必填-入住人
            let user = {};
            this.form.userList = [];
            this.form.roomList.forEach(item => {
                if (item.user.personName) {
                    user = {
                        personName: item.user.personName,
                        certificateType: item.user.certificateType,
                        certificateValue: item.user.certificateValue
                    };
                    this.form.userList.push(user);
                }
            })
            if (!this.form.userList || this.form.userList.length !== this.form.roomList.length) {
                Dialog.alert({
                    title: '温馨提示',
                    message: '请先添加入住人',
                    confirmButtonColor: '#ff7002',
                }).then(() => {
                    return canSubmit;
                })
                return canSubmit;
            }

            //必填-入住人证件信息
            if (this.hotelOrderInfo.needCertificate === 0) {
                let noCertificateUser = [];
                this.form.roomList.forEach(item => {
                    if (!item.user.certificateValue) {
                        noCertificateUser.push(item.user);
                    }
                })
                if (noCertificateUser && noCertificateUser.length > 0) {
                    Notify({type: 'warning', message: '请先完善入住人证件信息'});
                    return canSubmit;
                } else {
                    let userList = _.uniq(_.pluck(this.form.userList, 'certificateValue'));
                    if (userList.length !== this.form.userList.length) {
                        Dialog.alert({
                            title: '温馨提示',
                            message: '入住人不可选择同一人',
                            confirmButtonColor: '#ff7002',
                        }).then(() => {
                            return canSubmit;
                        })
                        return canSubmit;
                    }
                }
            }

            //必填-联系人
            if (this.form.linkman === '' || this.form.linkmanPhone === '') {
                Toast.fail('请先填写联系人')
                return canSubmit;
            }

            canSubmit = true;
            return canSubmit;
        },

        //提交
        async submit() {
            if (this.isTodayMorning) {
                let curTimeList = this.$moment().toArray();
                if(curTimeList[3] >= 6) {
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '此房型暂无法预订，请更换房型预订',
                        confirmButtonText: '更换房型',
                        showCancelButton: false
                    }).then(() => {
                        this.$router.back();
                    });
                    return;
                }
            }

            if (this.checkForm()) {
                this.submitting = true;
                this.$showLoading.show();

                //创建订单
                let createOrderResult = await this.createOrder();
                if (!createOrderResult) {
                    this.submitting = false;
                    this.$showLoading.hide();
                    Notify({type: 'warning', message: '创建订单失败，请重新选择'});
                    return
                }

                this.submitting = false;
                this.$showLoading.hide();
                this.$router.push({
                    name: 'hotel-submit-order',
                    query: {
                        orderNo: this.orderNo
                    }
                })
            }
        },

        //物理键返回
        keyback() {
            const __this = this;

            keyback_processor.addEventListener(function () {
                if (__this.isShowRoomNumPopup) {
                    __this.isShowRoomNumPopup = false;
                    return;
                }

                if (__this.isShowCertificateTypePopup) {
                    __this.isShowCertificateTypePopup = false;
                    return;
                }

                if (__this.isShowArrivalTimePopup) {
                    __this.isShowArrivalTimePopup = false;
                    return;
                }

                if (__this.isShowPriceDetailPopup) {
                    __this.isShowPriceDetailPopup = false;
                    return;
                }

                const aCommonlyUsedPassenger = __this.$refs.aCommonlyUsedPassenger;
                const aCommonlyUsedPassengerShow = aCommonlyUsedPassenger.getShow();
                if(aCommonlyUsedPassengerShow) {
                    aCommonlyUsedPassenger.hidePopup();
                    return;
                }

                if (__this.isShowBackDialog) {
                    Dialog.close();
                    __this.isShowBackDialog = false;
                    return;
                }

                __this.back()
            });
        }
    }
}
