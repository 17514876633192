// 机票行李
import wrap_cache from 'nllib/src/promise-helper/wrap_cache/1.0.1';
import Q from 'q'
import flightLuggage from '@/lib/data-service/haolv-default/consumer_air_ticket_flightLuggage'

const consumer_air_ticket_flightLuggage = function (pParameter) {
    if (!pParameter) {
        pParameter = {};
    }
    const clear_cache = pParameter.clear_cache || false;
    const p = pParameter;
    const cache_key = `luggage-${JSON.stringify(p)}`;
    const fn = flightLuggage;
    const get_parameter_for_fn = function () {
        return Q.resolve(p);
    };
    return wrap_cache({
        clear_cache,
        cache_key,
        fn,
        get_parameter_for_fn,
    });
}

export default consumer_air_ticket_flightLuggage