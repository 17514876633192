/**
 * 调用方法：
 * 初始化方法： init
 * 入参：OBJ
 * {
 *     evectionNo: String 出差单号
 *     mock_data: Obj {
 *         enable: Boolean 默认false true： 使用虚拟数据 false: 不适用虚拟数据
 *         test_case: String 001: 因公有出差单 002：因公无出差单 003: 因私
 *     }
 * }
 *
 * 打开窗口: toShow
 * 入参： Obj
 * {
 *     activePassengerList:  Array(String) 当前选中的用户id数组
 * }
 *
 * 获取当前窗口打开的状态: get_show
 *
 * 关闭最上一层窗口: hide
 *
 *
 * 组件回调事件
 * @result
 * 返参：Obj
 * {
 *     userList: Array(Object) 选取的实例数组
 *     userIdList: Array(String) 选中的id数组
 * }
 * */
import NavBar from '@/component/nav-bar/import_latest_version_module'
// 获取备选车次
import consumer_trains_product_checkTheRemainingTrainTickets from '@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTickets'
import {Toast} from "vant";

export default {
    data() {
        return {
            checked: false,
            show: false,
            trainNumberAll: [],
            trainNumberAllOld: [],
            title: {
              fromStationName: '',
              toStationName: '',
            },
            filterActive: 'departureTime',
            formStationTimeSort: true,
            formStationTimeArr: false,
            formConsumingTime: false,
            screenListNum: 0,
            application: {
              trainTypeResults: [ // 车型选择list
                {trainTypeCode: 0, trainTypeText: "不限"},
                {trainTypeCode: 1, trainTypeText: "C/G高铁"},
                {trainTypeCode: 2, trainTypeText: "D动车"},
                {trainTypeCode: 3, trainTypeText: "普通列车"}
              ],
              trainTimeTypeResults: [ // 出发时间list
                {trainTimeStartText: "不限", trainTimeEndText: "", trainTimeTypeCode: 0},
                {trainTimeStartText: "凌晨00:00", trainTimeEndText: "06:00", trainTimeTypeCode: 4},
                {trainTimeStartText: "上午06:00", trainTimeEndText: "12:00", trainTimeTypeCode: 1},
                {trainTimeStartText: "下午12:00", trainTimeEndText: "18:00", trainTimeTypeCode: 2},
                {trainTimeStartText: "晚上18:00", trainTimeEndText: "24:00", trainTimeTypeCode: 3},
              ],
              trainTimeTypeResultsEnd: [ // 到达时间list
                {trainTimeStartText: "不限", trainTimeEndText: "", trainTimeTypeCode: 0},
                {trainTimeStartText: "凌晨00:00", trainTimeEndText: "06:00", trainTimeTypeCode: 4},
                {trainTimeStartText: "上午06:00", trainTimeEndText: "12:00", trainTimeTypeCode: 1},
                {trainTimeStartText: "下午12:00", trainTimeEndText: "18:00", trainTimeTypeCode: 2},
                {trainTimeStartText: "晚上18:00", trainTimeEndText: "24:00", trainTimeTypeCode: 3},
              ],
              startStationList: [
                {stationCode: '', stationName: '不限'}
              ], // 出发车站
              endStationList: [
                {stationCode: '', stationName: '不限'}
              ], // 到达车站
            },
            isScreenShow: false,
            // 已选中筛选条件--车次类型
            checkTrainList: [],
            // 已选中筛选条件--出发时段
            checkFormTrainTimeList: [],
            // 已选中筛选条件--到达时段
            checkToTrainTimeList: [],
            // 已选中筛选条件--出发车站
            checkFormStationList: [],
            // 已选中筛选条件--到达车站
            checkToStationList: [],
            screenArrow: false,
            screenListText: [],
        }
    },
    components: {NavBar,},
    created() {
    },
    mounted() {

    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
    },
    filters: {},
    methods: {
        // 清空筛选
        emptyClick(){
          this.checkTrainList = [];
          this.checkFormTrainTimeList = [];
          this.checkToTrainTimeList = [];
          this.checkFormStationList = [];
          this.checkToStationList = [];
          this.screenListNum = 0;
          this.screenListText = [];
          this.onSubmitScreen();
        },
        // 车次选中
        clickChoiceBox(){
          let dataBox = [];
          this.trainNumberAll.forEach((item,index) => {
            if (item.choiceBox) {
              dataBox.push(item);
            }
          });
          if (dataBox.length >= 5) {
            this.trainNumberAll.forEach((item,index) => {
              if (!item.choiceBox) {
                item.disableBox = true;
              }
            });
          } else {
            this.trainNumberAll.forEach((item,index) => {
              item.disableBox = false;
            });
          }
        },
        // 右上角确认
        rightCancelBtn(){
          let dataBox = [];
          this.trainNumberAll.forEach((item,index) => {
            if (item.choiceBox) {
              dataBox.push(item);
            }
          });
          if (dataBox.length <= 5) {
            this.$emit('rightCancelBtn', dataBox);
            this.hide();
          } else {
            Toast.fail('备选车次最多能选择5个');
          }
        },
        // 筛选项--删除
        clickCross(item,index){
          this.screenListText.splice(index, 1);
          this.screenListNum = this.screenListText.length;
          let box = {};
          eval(`
            box = this.${item.code}[${item.num}];
          `);
          if (item.code == 'application.trainTypeResults') {
            this.checkTrainList.splice(this.checkTrainList.indexOf(box.trainTypeCode),1)
          } else if (item.code == 'application.trainTimeTypeResults') {
            this.checkFormTrainTimeList.splice(this.checkFormTrainTimeList.indexOf(box.trainTimeTypeCode),1)
          } else if (item.code == 'application.trainTimeTypeResultsEnd') {
            this.checkToTrainTimeList.splice(this.checkToTrainTimeList.indexOf(box.trainTimeTypeCode),1)
          } else if (item.code == 'application.startStationList') {
            this.checkFormStationList.splice(this.checkFormStationList.indexOf(box.stationCode),1)
          } else if (item.code == 'application.endStationList') {
            this.checkToStationList.splice(this.checkToStationList.indexOf(box.stationCode),1)
          }
        },
        // 筛选项--弹框
        screenClick(){
          this.screenArrow = !this.screenArrow;
          const __this = this;
          __this.screenListText = [];
          this.checkTrainList.forEach((item,index) => {
            __this.application.trainTypeResults.forEach((TItem,TIndex) => {
              if (item == TItem.trainTypeCode) {
                __this.screenListText.push({
                  text: TItem.trainTypeText,
                  code: `application.trainTypeResults`,
                  num: TIndex
                });
              }
            })
          });
          this.checkFormTrainTimeList.forEach((item,index) => {
            __this.application.trainTimeTypeResults.forEach((TItem,TIndex) => {
              if (item == TItem.trainTimeTypeCode) {
                __this.screenListText.push({
                  text: `出发:${TItem.trainTimeStartText}-${TItem.trainTimeEndText}`,
                  code: `application.trainTimeTypeResults`,
                  num: TIndex
                });
              }
            })
          });
          this.checkToTrainTimeList.forEach((item,index) => {
            __this.application.trainTimeTypeResultsEnd.forEach((TItem,TIndex) => {
              if (item == TItem.trainTimeTypeCode) {
                __this.screenListText.push({
                  text: `到达:${TItem.trainTimeStartText}-${TItem.trainTimeEndText}`,
                  code: `application.trainTimeTypeResultsEnd`,
                  num: TIndex
                });
              }
            })
          });
          this.checkFormStationList.forEach((item,index) => {
            __this.application.startStationList.forEach((TItem,TIndex) => {
              if (item == TItem.stationCode) {
                __this.screenListText.push({
                  text: `出发:${TItem.stationName}`,
                  code: `application.startStationList`,
                  num: TIndex
                });
              }
            })
          });
          this.checkToStationList.forEach((item,index) => {
            __this.application.endStationList.forEach((TItem,TIndex) => {
              if (item == TItem.stationCode) {
                __this.screenListText.push({
                  text: `到达:${TItem.stationName}`,
                  code: `application.endStationList`,
                  num: TIndex
                });
              }
            })
          });

        },
        // 筛选弹框--显示筛选结果
        onSubmitScreen(){
          this.isScreenShow = false;
          this.trainNumberAll = this.trainFilter(this.trainNumberAllOld);
          this.trainNumberAll.forEach((item,index) => {
            item.choiceBox = false;
          });
        },
        // 开始筛选
        trainFilter(list){
          const __this = this;
          let trainList = list;

          // 车次类型筛选
          if (__this.checkTrainList.length != 0) {
            let trainItemList = [];
            __this.checkTrainList.forEach(value => {
              trainList.forEach(val => {
                if (value === 0) {
                  trainItemList.push(val)
                } else if (value === val.motorcycleType) {
                  trainItemList.push(val)
                }
              })
            });
            trainList = trainItemList
          }
          // 出发时段筛选
          if (__this.checkFormTrainTimeList.length != 0) {
            let trainItemList = [];
            __this.checkFormTrainTimeList.forEach(value => {
              trainList.forEach(val => {
                if (value === 0) {
                  trainItemList.push(val)
                } else if (value === val.fromTimeCode) {
                  trainItemList.push(val)
                }
              })
            });
            trainList = trainItemList
          }
          // 到达时段筛选
          if (__this.checkToTrainTimeList.length != 0) {
            let trainItemList = [];
            __this.checkToTrainTimeList.forEach(value => {
              trainList.forEach(val => {
                if (value === 0) {
                  trainItemList.push(val)
                } else if (value === val.toTimeCode) {
                  trainItemList.push(val)
                }
              })
            });
            trainList = trainItemList
          }
          // 筛选出发车站
          if (__this.checkFormStationList.length > 0) {
            let trainItemList = [];
            __this.checkFormStationList.forEach(value => {
              trainList.forEach(val => {
                if (value === '') {
                  trainItemList.push(val)
                } else if (value === val.fromStationCode) {
                  trainItemList.push(val)
                }
              })
            });
            trainList = trainItemList
          }
          // 筛选到达车站
          if (__this.checkToStationList.length > 0) {
            let trainItemList = [];
            __this.checkToStationList.forEach(value => {
              trainList.forEach(val => {
                if (value === '') {
                  trainItemList.push(val)
                } else if (value === val.toStationCode) {
                  trainItemList.push(val)
                }
              })
            });
            trainList = trainItemList
          }

          return trainList;
        },
        changeTrainConditionItem(type,text){
          if (text == '不限') {
            if (type == 'train') {
              this.checkTrainList = [0];
            } else if (type == 'formTime') {
              this.checkFormTrainTimeList = [0];
            }  else if (type == 'toTime') {
              this.checkToTrainTimeList = [0];
            }  else if (type == 'formStation') {
              this.checkFormStationList = [''];
            }  else if (type == 'toStation') {
              this.checkToStationList = [''];
            }
          } else{
            if (type == 'train' && this.checkTrainList.indexOf(0) != -1) {
              this.checkTrainList.splice(this.checkTrainList.indexOf(0),1);
            } else if (type == 'formTime' && this.checkFormTrainTimeList.indexOf(0) != -1) {
              this.checkFormTrainTimeList.splice(this.checkFormTrainTimeList.indexOf(0),1);
            }  else if (type == 'toTime' && this.checkToTrainTimeList.indexOf(0) != -1) {
              this.checkToTrainTimeList.splice(this.checkToTrainTimeList.indexOf(0),1)
            }  else if (type == 'formStation' && this.checkFormStationList.indexOf('') != -1) {
              this.checkFormStationList.splice(this.checkFormStationList.indexOf(''),1)
            }  else if (type == 'toStation' && this.checkToStationList.indexOf('') != -1) {
              this.checkToStationList.splice(this.checkToStationList.indexOf(''),1)
            }
          }

          this.screenListNum = this.checkTrainList.length+this.checkFormTrainTimeList.length+this.checkToTrainTimeList.length+this.checkFormStationList.length+this.checkToStationList.length;
        },
        init(params) {
          if (!params) {
            params = {}
          }
          consumer_trains_product_checkTheRemainingTrainTickets(params).then(res => {
            this.trainNumberAll = res.resultList;
            this.trainNumberAllOld = this.trainNumberAll;
            this.title.fromStationName = this.trainNumberAll[0].fromStationName;
            this.title.toStationName = this.trainNumberAll[0].toStationName;

            this.trainNumberAll.forEach((item,index) => {
              this.$set(item, 'choiceBox', false);
              this.$set(item, 'disableBox', false);
            });

            // 收集筛选弹框出发站、到达站
            let fromStationNameBox = [];
            let toStationNameBox = [];
            this.trainNumberAll.forEach((item,index) => {
              fromStationNameBox.push({
                stationCode: item.fromStationCode,
                stationName: item.fromStationName
              });
              toStationNameBox.push({
                stationCode: item.toStationCode,
                stationName: item.toStationName
              });
            });

            let mapForm = new Map();
            for (let item of fromStationNameBox) {
              mapForm.set(item.stationCode, item);
            }
            this.application.startStationList = [...mapForm.values()];
            let mapTo = new Map();
            for (let item of toStationNameBox) {
              mapTo.set(item.stationCode, item);
            }
            this.application.endStationList = [...mapTo.values()];
          })
        },
        tabbarChange(val){
          if (val != 3) {
            this.filterActiveBox = val;
          }
        },
        // 筛选开始
        setSort(){
          const __this = this;
          let trainNumberAll = __this.trainNumberAll;

          let timeFormMinute = function (val) {
            let timeVal = val.split(':');
            let hour = parseInt(timeVal[0]) * 60;
            let minute = parseInt(timeVal[1]);
            return hour + minute
          };
          let timeToMinute = function (val) {
            let timeVal = val.toTime.split(':');
            let hour = Number(parseInt(timeVal[0]) * 60);
            let minute = Number(parseInt(timeVal[1]));
            return hour + minute
          };
          let formTimeCompare = function (x, y) { // 按出发时间正序排列
            let xFromTime = timeFormMinute(x.fromTime);
            let yFromTime = timeFormMinute(y.fromTime);

            if (xFromTime < yFromTime) {
              return -1;
            } else if (xFromTime > yFromTime) {
              return 1;
            } else {
              return 0;
            }
          };

          let formTimeCompareArr = function (x, y) { // 按到达时间正序排列
            let xToTime = timeToMinute(x);
            let yToTime = timeToMinute(y);
            if (xToTime < yToTime) {
              return -1;
            } else if (xToTime > yToTime) {
              return 1;
            } else {
              return 0;
            }
          };

          let formConsumingTime = function (x, y) { // 按旅行耗时正序排列
            let xBox = x.spanTime.split('时');
            let xSpanTime = 0;
            if (xBox.length == 2) {
              xSpanTime = xBox[0]*100 + Number(xBox[1].replace('分',''));
            }
            if (xBox.length == 1) {
              xSpanTime = Number(xBox[0].replace('分',''));
            }

            let yBox = y.spanTime.split('时');
            let ySpanTime = 0;
            if (yBox.length == 2) {
              ySpanTime = yBox[0]*100 + Number(yBox[1].replace('分',''));
            }
            if (yBox.length == 1) {
              ySpanTime = Number(yBox[0].replace('分',''));
            }


            if (xSpanTime < ySpanTime) {
              return -1;
            } else if (xSpanTime > ySpanTime) {
              return 1;
            } else {
              return 0;
            }
          };
          if (__this.formStationTimeSort) {
            trainNumberAll.sort(formTimeCompare);
          } else if (__this.formStationTimeArr) {
            trainNumberAll.sort(formTimeCompareArr)
          } else if (__this.formConsumingTime) {
            trainNumberAll.sort(formConsumingTime)
          }

          __this.trainNumberAll = trainNumberAll;
        },
        filterTime(){
          // 按时间排序
          const __this = this;
          __this.formStationTimeSort = true; // 开启自身排序
          __this.formStationTimeArr = false; // 关闭其他排序
          __this.formConsumingTime = false; // 关闭其他排序
          __this.setSort();
        },
        consumingTimeClick() {
          // 按旅行耗时排序
          const __this = this;
          __this.formConsumingTime = true; // 开启自身排序
          __this.formStationTimeArr = false; // 关闭其他排序
          __this.formStationTimeSort = false; // 关闭其他排序
          __this.setSort();
        },
        filterPrice() {
          // 按到达时间排序
          const __this = this;
          __this.formStationTimeArr = true; // 开启自身排序
          __this.formStationTimeSort = false; // 关闭其他排序
          __this.formConsumingTime = false; // 关闭其他排序
          __this.setSort();
        },
        _getAccountInfo() {

        },


        toShow(params) {
          this.show = true;
        },

        get_show() {

        },
        hide() {
          this.show = false;
        },
    }
}