//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { deleteMulti, queryMultiUsersInfo } from "@/lib/data-service/haolv-default/design";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Toast} from 'vant';

export default {
    name: "DeleteMultiModal",
    components: {NavBar},
    props: {
        // 是否显示
        visible: {
            type: Boolean,
            default: false,
        },
        processInfo: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            loading: false,
            formValue: {
                execution: [],
                executionName: [],
                executionIds: [],
                imageList: [],
                fileList: [],
            },
            rules: {
                executionIds: [
                    { required: true, message: "请选择减签用户", trigger: "change" },
                ],
            },
            options: [],
            showPicker: false,
        };
    },
    computed: {
        show: {
            get() {
                return this.visible;
            },
            set(visible) {
                this.$emit("update:visible", visible);
            },
        },
    },
    methods: {
        // 确认操作
        handleConfirm() {
            this.$refs.formRef.validate().then(() => {

                const { imageList, fileList, ...restParams } = this.formValue;
                const params = {
                    ...this.processInfo,
                    ...restParams,
                    attachments: [...imageList, ...fileList],
                };
                this.loading = true;
                deleteMulti(params)
                    .then(() => {
                        this.loading = false;
                        Toast.success("操作成功");
                        this.handleCancel();
                        this.$emit("success");
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }).catch(()=>{});
        },
        // 取消操作
        handleCancel() {
            this.options = [];
            this.$refs.formRef.resetValidation();
            this.formValue = {
                execution: [],
                executionName: [],
                executionIds: [],
                imageList: [],
                fileList: [],
            };
            this.show = false;
            this.$emit("cancel");
        },
        // 打开弹框操作
        handleOpen() {
            const { processInstanceId, taskId } = this.processInfo;
            queryMultiUsersInfo({ processInstanceId, taskId }).then((res) => {
                this.options = (res.result || []).map((item) => {
                    return {
                        label: item.userName || item.userId,
                        value: item.executionId,
                    };
                });
            });
        },
        toggle(index) {
            this.$refs.checkboxes[index].toggle();
        },
        handleCancelPicker() {
            this.showPicker = false;
        },
        onConfirm() {
            console.log(this.formValue.execution);
            let executionName = [];
            let executionIds = [];
            this.formValue.execution.forEach(value => {
                executionName.push(value.label);
                executionIds.push(value.value);
            })
            this.formValue.executionName = executionName;
            this.formValue.executionIds = executionIds;
            this.showPicker = false;
        },
    },
};
