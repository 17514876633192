/*
*获取登录用户的出差政策      http://219.128.52.2:18765/doc.html#/haolv-consumer/t-bd-policy-config-controller/userTravelPolicyUsingPOST
*/
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/web/policyConfig/userTravelPolicy',
        data: data
    }
    return __request(pParameter)
}
export default request