import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import aRouter from "@/router";
import all_routes_processor from "@/router/init_router/lib/all_routes_processor";
import transition_processor from "@/router/init_router/lib/transition_processor";

import h5_layout_tab_bar_api from "@/component/h5-layout/3.0.3/components/tab-bar/1.0.2/api";
import wangyiServerOnline from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import consumer_feishu_getSignature from "@/lib/data-service/haolv-default/consumer_feishu_getSignature";
import cache_get from "nllib/src/cache/get";

const init_route_guard = function () {
    const get_all_route_list = all_routes_processor.get_all_route_list;

    aRouter.beforeEach(async (to, from, next) => {
        console.log('form', to, from)
        h5_layout_tab_bar_api.init({from, to});
        transition_processor.init({from, to});
        return next();

    });
    // 12410 【差旅app端】机票、火车票、酒店，搜索之后的所有预订页面无需客服浮窗
    aRouter.afterEach(async (to, from, failure) => {
        console.log('to', to, location)
        if (window.tt) {
            let url = location.href;
            console.log(url);
            let appId = cache_get('appID');
            let params = {
                "appId":  appId,
                "url": url,
            };
            let [err, res] = await awaitWrap(consumer_feishu_getSignature(params));
            if (res) {
                window.h5sdk.config({
                    appId: appId,
                    timestamp: res.data.timestamp,
                    nonceStr: res.data.noncestr,
                    signature: res.data.signature,
                    jsApiList: ['scope.userLocation'],
                    //成功回调
                    onSuccess: (res) => {
                    },
                    //失败回调
                    onFail: (err) => {
                    },
                });
            }
        }
        /*let showQiYuRouterNameList = [
            'h5-flight-home',
            'h5-hotel-home',
            'h5-train-home',
            'h5-car-home',
            'h5-flight-order-list',
            'flight-order-detail',
            'h5-hotel-order-list',
            'h5-hotel-order-detail',
            'h5-train-order-list',
            'h5-train-order-detail',
            'h5-car-order-list',
            'h5-car-order-details'
        ];*/
        let hideQiYuRouterNameList = [
            'flight-search',
            'h5-hotel-search',
            'train-search',
            'h5-hotel-detail',
            'h5-hotel-reserve',
            'h5-hotel-reserve-result',
            'h5-travel-payment',
            'flight-cabin-list',
            'flight-return-search',
            'flight-return-cabin-list',
            'flight-reserve',
            'flight-personal-reserve',
            'train-search-trainNo',
            'train-reserve',
            'login',
            'wenJuan',
            'service',
            'privacy',
	          'h5-certificate',
            'h5-hotel-private-reserve',
            'flight-personal-reserve',
            'train-reserve-private',
            'train-submit-order',
            'hotel-submit-order',
            'flight-submit-order',
            'redirect',
            'root',
            'h5-office-evection',
            'h5-office-evection-details',
            'train-reserve-loading',
            'train-reserve-result',
            'h5-hotel-map',
            'h5-about',
            'h5-advert-detail',
            'h5-advert-book',
            'h5-advert-intention-detail',
            'h5-advert-intention-list',
            'dd-login',
        ];
        /*if (showQiYuRouterNameList.indexOf(to.name) > -1) {
            wangyiServerOnline.config().then(() => {
                wangyiServerOnline.show();
                wangyiServerOnline.destructionGetUnReadMsgNum();
            });
        }*/
        if (hideQiYuRouterNameList.indexOf(to.name) > -1) {
            wangyiServerOnline.onlyHide();
        } else {
            wangyiServerOnline.config().then(() => {
                wangyiServerOnline.show();
                wangyiServerOnline.destructionGetUnReadMsgNum();
            });
        }
    })
};
export default init_route_guard;
