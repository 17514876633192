export default {
    data() {
        return {}
    },
    props: {
        workflowList: {
            type: Array,
            default: ()=> [],
        }
    },
    components: {
        CollapseCard: () => import('@/component/collapse-card/index.vue'),
    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {}
}