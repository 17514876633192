import Q from 'q'
import router from '@/router'
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
import cache_get from "nllib/src/cache/get";
const redirect_to_login_page = function (pParameter) {
    wangyiServerOnline.hide()
    let tokenCancelNeedJumpToLogin = cache_get({key: 'tokenCancelNeedJumpToLogin'});
    // tokenCancelNeedJumpToLogin: 1:默认，token失效就去登录页， 2:不去登录页，留在原地提示
   if (tokenCancelNeedJumpToLogin === 2) {
       return
   }
    router.push({
        name: `login`,
    });

    // const environment = global.appConfig.environment;
    // if (environment === `local`) {
    //     router.push({
    //         name: `login`,
    //     });
    //     return;
    // }
    //
    // const login_page_url = global.appConfig.login_page_url;
    // if (!login_page_url) {
    //     const msg = `redirect_to_login_page parameter error, code=001`;
    //     console.error(msg);
    //     console.trace();
    //     return Q.reject({msg: msg});
    // }
    // global.location.replace(login_page_url);
};

export default redirect_to_login_page;
