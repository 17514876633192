import NavBar from "@/component/nav-bar/import_latest_version_module";
import TrainInstructions from '../../../../../travel-book/train/train-reserve/commponent/train-instructions/index'
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
import consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2 from '@/lib/data-service/haolv-default/consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2'
import {Toast} from "vant";
export default {
    data() {
        return {
            explainShow: false,
            changeNo: '',
            changeDetail: {},
        }
    },
    components: {NavBar, TrainTimeTable, TrainInstructions},
    created() {
    },
    mounted() {
    },
    activated() {
        this.changeNo = this.$route.query.orderNo;
        this.getDetail();
        try {
            let show_explainShow = __this.explainShow;
            if (show_explainShow) {
                __this.hideExplain();

            }
        } catch (err) {

        }
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        //显示保险详情
        toInsuranceDetail() {
            this.$router.push({
                name: "flight-insurance",
                query: {
                    insuranceInfo: JSON.stringify(this.changeDetail.insuranceInfo),
                    businessType: 2,
                },
            });
        },
        back() {
            this.$router.push({
                name: 'h5-train-order-list',
                query: {
                    type: 2
                }
            });
        },
        getDetail() {
            Toast.loading({
                forbidClick: true,
                message: '',
                duration: 0
            });
            consumer_t_od_trains_order_change_getTrainsChangeOrderDetailV2({changeNo: this.changeNo}).then(res => {
                Toast.clear();
                this.changeDetail = res.datas;
                this.initTrainTimeTable()

            }).catch(() => {
                Toast.clear()
            })
        },
        // 火车票时刻表组件
        initTrainTimeTable() {
            this.$refs['aTrainTimeTable'].init({
                //出发站三字码
                fromStationCode: this.changeDetail.changeTrainInfo.changeFromStationCode,
                //到达站三字码
                toStationCode: this.changeDetail.changeTrainInfo.changeToStationCode,
                //车次号
                trainCode: this.changeDetail.changeTrainInfo.changeTrainCode,
                //日期(2020-05-18)
                trainDate: this.changeDetail.changeTrainInfo.changeStartDate,
            })
        },
        setStatus(val) {
            // 车票状态 0：待预定，1：待出票，2：出票中，3：出 票完成，4：出票失败, 5：退票已申请, 6：退票处理中, 7：退票完成， 8：退票失败，9：改签已申请，10：改签中， 11：改签完成，12：改签失败，13：改签已取消， 14：已改签， 15：待支付， 16：预定中， 17：占座失败
            let result = '';
            if (!val) {
                return ''
            }
            if ([0, 1, 2].indexOf(val) >= 0) {
                result = 'pending'
            } else if (val === 3) {
                result = 'success'
            } else if ([13].indexOf(val) >= 0) {
                result = 'cancel'
            } else {
                result = ''
            }
            return result
        },
        toRefund(item, index) {
            if (item.showRefundButton === 0) {
                return
            }
            const query = {
                orderNo: this.changeDetail.changeOrderInfo.orderNo,
                passengerSn: item.sn
            };
            this.$router.push({
                name: 'h5-train-refund',
                query
            })
        },
        toOriOrderNo() {
            this.$router.push({
                name: 'h5-train-order-detail',
                query: {
                    orderNo: this.changeDetail.changeOrderInfo.orderNo
                }
            })
        },
        toEvectionDetail() {
            this.$router.push({
                name: 'h5-office-evection-details',
                query: {
                    id: this.changeDetail.changeOrderInfo.evectionNo
                }
            })
        },
        toApplyDetail() {
            this.$router.push({
                name: 'h5-approve-detail',
                query: {
                    id: this.changeDetail.applyId,
                    approveType: 8
                }
            })
        },
        openTimeLine() {
            this.$refs['aTrainTimeTable'].show();
        },
        hideExplain(){
            this.explainShow = false;
        },
        // 说明click
        clickExplain(){
            this.explainShow = true;
        },
        toPayment(changeNo,orderNo){
          this.$router.push({
            name: "h5-travel-payment",
            query: {
              orderNo: changeNo,
              dataBox: orderNo,
              orderType: "2",
              pageType: 3,
              businessType: 5,
            },
          });
        },
    }
}
