import {Dialog, Toast} from "vant";
import moment from 'moment';
import store from '@/lib/store';
import Q from 'q';
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
// 选择出行人弹窗组件
import Transfer from "@/page/travel-book/flight/component/transfer/import_latest_version_module";
import TravelerMultipleSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-multiple-selector/1.2.7";//选人列表组件
/* 最新出差城市选择 */
import TravelApplicationCitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/travel-application-city-selector/1.0.3/index.vue";
/* 最新机票城市选择 */
import DomesticTicketAirportSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/domestic-ticket-airport-selector-1000/1.0.0";
/* 最新酒店城市选择 */
import HotelCitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/hotel-city-selector/2.1.0/index.vue";
/* 最新火车票城市选择 */
import TrainCitySelector
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-city-selector/1.2.1/index.vue";
/* 上传文件 */
import OssClient from "yinzhilv-js-sdk/frontend/common-project/lib/system-docking/aliyun/oss/frontend/client";
// 选择费用归属
import CostAttributionList from "@/component/cost-attribution-list/1.0.1/index.vue";
// 彩色icon组件
import ColorIcon from "@/component/color-icon/index.vue";
// 用车类型
import consumer_web_policyConfig_getUsefulCarScene from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getUsefulCarScene'
//  请求员工数据
import consumer_department_getDepartmentUsers
    from "@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers";
// 检测出行人的审批人，是否面审
import matching from "@/lib/data-service/haolv-default/consumer_approveFlow_matching";
// 请求外部客户列表
import consumer_web_externalCustomer_getExternalCustomerAll
    from "@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerAll";
// import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
// /* 默认成本中心 */
// import get_web_costCenter from "@/lib/data-service/haolv-default/consumer_web_costCenter_allList";
// // 部门员工列表
// import getDepartmentUsers from "@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers";
/* 申请出差单 */
import addEvection from "@/lib/data-service/haolv-default/consumer_apply_addEvection";
//查询退改政策
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_web_company_checkCompanyUseCar from '@/lib/data-service/haolv-default/consumer_web_company_checkCompanyUseCar'
import consumer_web_costCenter_costCenterByDep from '@/lib/data-service/haolv-default/consumer_web_costCenter_costCenterByDep'
// 【576】获取预算项目种类
import consumer_budget_getBudgetPro from '@/lib/data-service/haolv-default/consumer_budget_getBudgetPro'
// 【576】获取预算险类种类
import consumer_budget_getBudgetInsurance from '@/lib/data-service/haolv-default/consumer_budget_getBudgetInsurance'
// 【576】获取配置服务费
import consumer_admin_flight_generate_configServiceFee from '@/lib/data-service/haolv-default/consumer_admin_flight_generate_configServiceFee'
// 【576】查询指定城市的出差补助信息
import consumer_budget_getSubsidyStandard from '@/lib/data-service/haolv-default/consumer_budget_getSubsidyStandard'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import fixTabBar from "@/lib/common-service/apicloud/fixTabBar";

import currentLocation from '@/lib/common-service/current_location_helper/index.js' //在app中获取用户当前位置
import requestPermission from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/requestPermission";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import Moment from "moment";
import consumer_apply_workflowDetail from "@/lib/data-service/haolv-default/consumer_apply_workflowDetail";
import {
    agree,
    formatStartNodeShow,
    getProcessInstanceInfo,
    operablePermissions
} from "@/lib/data-service/haolv-default/design";
import ApprovalProgressForWorkflow from "@/component/approval-progress-for-workflow/index.vue";
import consumer_product_getBusinessOpen from '@/lib/data-service/haolv-default/consumer_product_getBusinessOpen';
export default {
    components: {
        Transfer,
        TravelerMultipleSelector,
        TravelApplicationCitySelector,
        DomesticTicketAirportSelector,
        HotelCitySelector,
        TrainCitySelector,
        CostAttributionList,
        ColorIcon,
        NavBar,
        ApprovalProgressForWorkflow
    },
    data() {
        return {
            type: 'add',
            applyNo: '',
            taskId: '',
            businessTypeList: [],
            journeyVisibleTypeTwo: false,
            journeyVisibleType: 'addition',
            journeyVisibleIndex: null,
            focusFieldItem: {},
            subsidyLoading: false,
            singleReturnType: 1,
            carShowPopup: false,
            budgetListAll: [],
            budgetInsuranceList: [],  //预算险类
            budgetProList: [],   //项目
            budgetItemList: [
                {name: '管理',code: 1},
                {name: '理赔',code: 2},
                {name: '销售',code: 3},
            ],//国家枚举list
            budgetItemPopup: false,
            budgetPopup: false,
            budgetPopupTitle: '预算险类',
            ossClient: new OssClient(),
            startDate: moment().toDate(),//找产品确定一下这个时间区间，区间太大的话，会影响第一次打开的速度
            endDate: moment().add(6, `months`).toDate(),//找产品确定一下这个时间区间，区间太大的话，会影响第一次打开的速度
            conflictDateArr: [],
            journeyIndex: 0,
            travelDate: new Date(),
            travelStartDate: new Date(),
            travelEndDate: new Date(),

            budgetItemText: '',

            form: {
                staffIdList: [], //出行人id列表
                startDate: "", //出差开始时间
                endDate: "", //出差结束时间
                reason: "", //出差事由
                explainDesc: "", //详情说明
                toCityId: "", //出差城市id列表
                toCityName: "", //出差城市名字
                feeAffiliationName: "各自所在部门", //费用归属名称
                feeAffiliationId: -1, //费用归属部门id
                feeAffiliationType: 1, //1.组织架构，2.成本中心
                budgetItemType: "", //科目类型 1-管理 2-理赔 3-销售
                budgetInsuranceCode: '',
                budgetInsuranceName: '',
                budgetProCode: '',
                budgetProName: '',

                journeyList: [], //行程
                accessoryUrl: "", //附件
                recordList: [], //审批流程
            },
            rules: {},

            subsidyAmountPeopleList: [],
            selectStaffList: [], //预选出行人
            checkStaffList: [], //确认出行人

            staffVisible: false, //是否显示选择出行人弹窗

            bookUpperBound: 6,//出行人数上限
            unlimitedJourney: 0,//是否不限行程 0：否 1：是
            subsidyControlRule: 0,//预算管控 0：不开启 1：开启（只允许按出差单申请金额内预订行程） 默认：0
            activeUserIds: [], //出行人弹窗已选中员工id

            staffAllList: [], //所有员工数据(一维数组)
            staffExternalCustomerList: [], //(所有人一维数组)

            staffTreeList: [], //构造好的部门树形数据
            externalCustomerTreeList: [], //构造好的外部客户树形数据
            staffExternalCustomerTree: [],

            showCalendar: false, //控制出差时间日历组件显示

            evectionCityListIds: [], //已选中出差城市id列表
            // evectionCityList: [], //已选中出差城市名字列表

            showCost: false, //控制费用归属组件显示

            uploadFileList: [], //文件列表

            // 添加行程
            showJourney: false,
            journeyType: 0, // 出差行程类型
            journeyData: {
                budgetAmount: null,    // 申请金额
                subsidyAmount: 0,    // 补助金额
                serviceAmount: null,     // 服务费--回显使用
                serviceAmountType: 0,     // 百分比1、定额=2
                serviceFee: 0,      // 接口返回服务费
                subsidyInfos: [],   // 出差补助信息
            },
            // 机票
            airportCityStartAdrId: "", //机票出发城市id
            airportCityArriveAdrId: "", //机票目的城市id
            airportDates: "", // 机票出发回程日期组合
            showJourneyCalendar2: false,
            airportCityStartAdrParams: {},
            airportCityArriveAdrParams: {},
            // 火车票
            trainCityStartAdrId: "", // 火车票出发站id
            trainCityArriveAdrId: "", // 火车票到达站id
            trainCityStartAdrParams: {}, // 火车票出发站信息
            trainCityArriveAdrParams: {}, // 火车票到达站信息
            trainDates: "", // 火车票出发回程日期组合
            // 用车
            carCityArriveAdrParamsName: '',   // 用车类型
            showPickerCar: false,   // 用车类型弹框
            columnsCar: [],   // 用车类型弹框选择
            carCityAdrParams: {},   // 用车类型信息
            columnsCarLoading: false, // 用车类型弹框loading
            currentIndexCar: [],
            // 酒店
            hotelCityAdrId: "", // 酒店城市id
            hotelCityAdrParams: {}, // 酒店城市信息
            showJourneyCalendar: false,
            // 审批流
            approveShow: false,
            approveUserIds: [], //已选中审批人id列表
            approver: [], //已选中审批
            //是否免审
            isFreeApprove: false,
            //是否有审批流程
            isHasApprovalProcess: false,
            //审批流列表
            approvalNodeList: [{
                staffs: {
                    userId: 0,
                    staffName: ''
                }
            }],
            //下级审批人id
            approvalUserId: '',
            approveFlowId: '',
            paddingTop: 0,

            nightDay: 1,

            useCar: false,

            workflowList: [],
            processInfo: {},
        };
    },
    computed: {
        dataArr() {
            const {startDate, endDate} = this.form;
            return startDate || endDate ? startDate + " 至 " + endDate : "";
        },
        totalBudget() {
            let total = 0;
            this.form.journeyList.forEach(value => {
                total+= this.subtotalFunction(value);
            })
            return total;
        },
    },
    watch: {
        uploadFileList(newVal) {
            console.log(newVal);
        },
        "approvalUserId"(val) {
            this.approvalNodeList[0].staffs.forEach((item) => {
                if (item.userId === val) {
                    this.form.recordList = [{
                        approverUserId: item.userId,
                        approverUserName: item.staffName,
                        linkNum: 1,
                    }];
                }
            });
        },
        /* 最新出差城市选择 */
        evectionCityListIds(val) {
            const form = this.form;
            form.toCityId = val.join();
            const evectionCityList = this.$refs.aTravelApplicationCitySelector.get_selected_entity_list();
            form.toCityName = evectionCityList.map((item) => item.name).join("，");
        },
        selectStaffList(val) {
            this.subsidyAmountPeopleList = val;
        },
        hotelCityAdrId(pNewValue, pOldValue) {
            if (pNewValue) {
                this.getSubsidyStandard(this.hotelCityAdrParams.cityId,this.hotelCityAdrParams.cityCn,3);
            } else {
                this.$refs.aHotelCitySelector.value_for_v_model = '';
                this.$refs.aHotelCitySelector.private_value.id = '';
            }
        },
        /* 最新机票出发城市选择 */
        airportCityStartAdrId(pNewValue, pOldValue) {
            this.airportCityStartAdrParams = this.$refs.aDomesticTicketAirportSelectorByStart.get_selected_entity();
        },
        /* 最新机票到达城市选择 */
        airportCityArriveAdrId(pNewValue, pOldValue) {
            console.log(this.$refs.infoForm);
            if (this.$refs.infoForm) {
                this.$refs.infoForm.resetValidation();
            }

            this.airportCityArriveAdrParams = this.$refs.aDomesticTicketAirportSelectorByArrive.get_selected_entity();
            if (pNewValue) {
                this.getSubsidyStandard(this.airportCityArriveAdrParams.code,this.airportCityArriveAdrParams.name,1);
            } else {

            }
        },
        /* 最新火车票出发站城市选择 */
        trainCityStartAdrId(pNewValue, pOldValue) {
            this.trainCityStartAdrParams = this.$refs.aTrainCitySelectorByStart.get_selected_entity();
        },
        /* 最新火车票到达站城市选择 */
        trainCityArriveAdrId(pNewValue, pOldValue) {
            this.trainCityArriveAdrParams = this.$refs.aTrainCitySelectorByArrive.get_selected_entity();
        },
        activeUserIds(newVal) {
            if (newVal && newVal.length != 0 && this.subsidyControlRule == 1) {
                consumer_web_costCenter_costCenterByDep({"userId": newVal[0]}).then(res => {
                    this.form.feeAffiliationName = res.datas.centerName;
                    this.form.feeAffiliationId = res.datas.id;
                    this.form.feeAffiliationType = 2;
                });
            }

            this.submitChooseUser();
        }
    },
    methods: {
        subtotalFunction(data) {
            let num = 0;
            if (data) {
                let budgetAmount = data.budgetAmount ? Number(`${data.budgetAmount}`) : 0;
                let serviceAmount = data.serviceAmount ? Number(`${data.serviceAmount}`) : 0;
                let subsidyAmount = data.subsidyAmount ? Number(`${data.subsidyAmount}`) : 0;
                num = num + budgetAmount + serviceAmount + subsidyAmount;
            } else {
                this.subsidyAmountPeopleList.forEach((item,index) => {
                    num = num + Number(`${item.subsidyAmount}`);
                });
                num = num + Number(`${this.journeyData.budgetAmount}`) + Number(`${this.journeyData.serviceAmount}`);
            }
            return num;
        },
        inputBudgetAmount() {
            this.journeyData.budgetAmount = this.journeyData.budgetAmount.match(/[0-9]+(\.[0-9]{0,2})?/g) == null ? '' : this.journeyData.budgetAmount.match(/[0-9]+(\.[0-9]{0,2})?/g)[0];
            if (this.journeyData.serviceAmountType == 1) {
                this.journeyData.serviceAmount = (this.journeyData.budgetAmount * (this.journeyData.serviceFee * 0.01)).toFixed(2);
            }
        },
        focusField(item) {
            this.focusFieldItem = item;
        },
        validteSubsidyAmountTime() {
            let box = true;
            if (this.journeyType == 3 && (!this.journeyStartDate || !this.journeyEndDate)) {
                box = false;
            } else {
                box = true;
            }
            return box;
        },
        validteSubsidyAmountMax(item) {
            let box = true;
            if (this.journeyType == 3) {
                let dayBox = Number(moment(this.journeyEndDate).diff(moment(this.journeyStartDate),'days')) + 1;
                if (item > Number(this.focusFieldItem.subsidyAmountMax)*Number(dayBox)) {
                    box = false;
                }
            } else {
                if (item > this.focusFieldItem.subsidyAmountMax) {
                    box = false;
                }
            }
            return box;
        },
        validteSubsidyAmount() {
            let box = true;
            if (this.journeyType == 3) {
                if (this.hotelCityAdrParams.cityCn === '' || this.hotelCityAdrParams.cityCn === null || this.hotelCityAdrParams.cityCn === undefined) {
                    box = false;
                }
            } else {
                if (this.airportCityArriveAdrParams.name === '' || this.airportCityArriveAdrParams.name === null || this.airportCityArriveAdrParams.name === undefined) {
                    box = false;
                }
            }
            return box;
        },
        inputMatch(value,rule) {
            eval(`${value} = ${value}.match(${rule}) == null ? '' : ${value}.match(${rule})[0]`);
        },
        columnsCarConfirm(data){
            if (data) {
                this.showPickerCar = false;
                this.carCityAdrParams = data;
            }
        },
        clickFlightReturn() {
          this.singleReturnType = this.singleReturnType == 1 ? 2 : 1;
          this.airportDates = '';
        },
        showPickerCarClick(){
            this.showPickerCar = true;
            this.columnsCarLoading = true;

            consumer_web_policyConfig_getUsefulCarScene().then(res => {
                this.columnsCarLoading = false;
                this.columnsCar = [];
                res.datas.carSceneResults.forEach(item => {
                    let box = {
                        carPolicyTypeText: item.carPolicyTypeText,
                        carPolicyType: item.carPolicyType,
                        carPolicyTypeDescribe: '',
                    };
                    switch (item.carPolicyType) {
                        case 1:
                            // 出差用车
                            box.carPolicyTypeDescribe = '适用于员工异地出差期间用车';
                            break;
                        case 2:
                            // 加班用车
                            box.carPolicyTypeDescribe = '适用于工作日/节假日加班';
                            break;
                        case 3:
                            // 接送服务
                            box.carPolicyTypeDescribe = '适用于接送机场，接送火车站';
                            break;
                        case 4:
                            // 代叫用车
                            box.carPolicyTypeDescribe = '适用于为领导、高管、客户、参会人员等角色代叫车';
                            break;
                        case 5:
                            // 定点通勤
                            box.carPolicyTypeDescribe = '适用于多个办公地点之间往返通勤';
                            break;
                        case 6:
                            // 外勤用车
                            box.carPolicyTypeDescribe = '适用于日常公务外勤，如拜访客户、高管出行等';
                            break;
                    }
                    this.columnsCar.push(box);
                });
            });
        },
        async refundChangePolicyGet() {
            await this.$store.state.policyDefer.promise;
            await this.$store.state.userInfoDefer.promise;
            this.bookUpperBound = store.state.policy.bookUpperBound;
            this.unlimitedJourney = store.state.policy.unlimitedJourney;
            this.subsidyControlRule = store.state.policy.subsidyControlRule;
            // this.subsidyControlRule = 0;
            if (this.bookUpperBound == 1) {
                this.form.staffIdList = [store.state.userInfo.id];
                this.activeUserIds = this.form.staffIdList;
                this.selectStaffList = [{
                    id: store.state.userInfo.id,
                    text: store.state.userInfo.realName,
                    subsidyAmount: '',   // 用户输入金额
                    subsidyAmountMax: '',   // 用户输入金额的最高标准
                    subsidyStandard: '',    // 接口返回用户标准--展示用的字段
                }];
            }
            if (this.subsidyControlRule == 1) {
                const params = {
                  showDept: false,
                };
                this.$refs.aCostAttributionList.setParams(params);
            }
        },
        clickDelete(index) {
            this.form.journeyList.splice(index, 1);
        },
        // 请求部门员工数据与格式化
        req_staffList() {
            consumer_department_getDepartmentUsers()
                .then((result) => {
                    let oldList = [];
                    const newList = [];

                    format_staff_list(result.datas);
                    this.getApproveFlow();

                    function format_staff_list(list) {
                        for (const item of list) {
                            const department = {text: item.deptName, children: []};
                            if (item.deptList && item.deptList.length)
                                format_staff_list(item.deptList);
                            department.children = item.staffList.map((staff) => {
                                return {text: staff.subStaffName, id: staff.subUserId};
                            });
                            oldList = [...oldList, ...item.staffList];
                            newList.push(department);
                        }
                    }

                    this.staffAllList = oldList.map((staff) => {
                        // const {subUserId: id, subStaffName: text, deptId, deptName} = staff;
                        const {subUserId: id, subStaffName: text} = staff;
                        // return { id, text, deptId, deptName };
                        return {id, text};
                    });
                    this.staffTreeList = newList;
                    this.staffExternalCustomerTree = [
                        ...newList,
                        ...this.staffExternalCustomerTree,
                    ];
                })
                .catch((err) => {
                });
        },
        // 请求外部客户数据与格式化
        req_externalCustomer() {
            consumer_web_externalCustomer_getExternalCustomerAll()
                .then((result) => {
                    const list = result.datas;
                    this.externalCustomerList = list;
                    this.externalCustomerTreeList = {
                        text: "外部客户",
                        children: list.map((item) => {
                            return {text: item.customerName, id: item.userId};
                        }),
                    };
                    this.staffExternalCustomerTree = [
                        ...this.staffExternalCustomerTree,
                        this.externalCustomerTreeList,
                    ];
                })
                .catch((err) => {
                });
        },

        //请求下级审批人
        async getApproveFlow() {
            let res = await matching({applyType: 1, staffIds: []});
            let datas = res.datas;
            this.isFreeApprove = Number(datas.isFreeApprove);
            this.isHasApprovalProcess = this.isFreeApprove === 0 && datas !== null && datas.nodeList !== null && datas.nodeList.length > 0;
            if (this.isHasApprovalProcess) {
                this.approvalNodeList = datas.nodeList;
                // 存在this.approvalNodeList[0].staffs为空数组的情况
                this.approvalUserId = this.approvalNodeList[0].staffs.length > 0 ? this.approvalNodeList[0].staffs[0].userId : '';
                this.approveFlowId = this.approvalNodeList[0].approveFlowId;
                let approvalUserId = this.approvalNodeList[0]['staffs'].length > 0
                  ? this.approvalNodeList[0]['staffs'][0]['userId']
                  : 0;
                this.approvalNodeList[0]['staffs'].forEach((index,item) => {
                    if (item.userId == approvalUserId) {
                        this.form.recordList = [
                            {
                                'approverUserId': item.userId,
                                'approverUserName': item.staffName,
                                'linkNum': 1,
                            }
                        ];
                    }
                })
            } else {
                this.approvalNodeList = [];
                this.form.recordList = [];
            }
        },

        init() {
            this.approvalUserId = '';
            if(this.$store.state.workflow === '2') {

            }
            this.req_staffList();
            this.setBusinessTypeList();
            this.req_externalCustomer();
            this.initTravelerSelector();
        },
        setBusinessTypeList() {
            consumer_product_getBusinessOpen().then(res=>{
                this.businessTypeList = res.datas.businessTypeList;
            })
        },
        //初始化选择出行人组件
        initTravelerSelector() {
            // this.$refs.aTravelerMultiSelector.init_parameter.get_data_source_parameter = () => {
            //     const d = {
            //         companyId: this.companyId,
            //         isSelectCustomer: true,
            //     };
            //     return Q.resolve(d);
            // };
            this.$refs.aTravelerMultiSelector.init({

                // 场景
                // 100:行程预订首页，选择出行人（1.有差标管控;2.能选择外部客户;）
                // 101:出差申请页面，选择出行人（1.没有差标管控;2.能选择外部客户;）
                // 102:出差申请页面，选择审批人员（1.没有差标管控;2.不能选择外部客户;）
                situation: '101',
                select_max_number: this.bookUpperBound,
                businessType: 3
            })
        },
        // 弹出预选出行人
        async show_select_staff() {

            await this.$store.state.policyDefer.promise;
            this.bookUpperBound = store.state.policy.bookUpperBound;

            if (this.bookUpperBound == 1) {
                this.$Toast('最大帮订人数为1时，出差人员不允许修改！');
                return;
            }

            let activeUserIds = [];
            this.selectStaffList.forEach(value => {
                activeUserIds.push(value.id)
            });
            this.activeUserIds = activeUserIds;
            /*let data = {
                bookUpperBound: this.bookUpperBound,
            };*/
            this.$refs.aTravelerMultiSelector.show();
        },
        // 取消预选出行人
        cancelChooseUser() {
        },
        // 确认预选出行人
        submitChooseUser() {
            if (this.activeUserIds.length > this.bookUpperBound) {
                /*let data = {
                    bookUpperBound: this.bookUpperBound,
                };*/
                this.$refs.aTravelerMultiSelector.show();
                this.$Toast('最多出行人数为' + this.bookUpperBound + '人，请重新选择！')
            } else {
                const _this = this;
                let id_list = _this.activeUserIds;
                this.form.staffIdList = _this.activeUserIds;
                this.$refs.aTravelerMultiSelector.get_selected_entity_list_promise({
                    id_list,
                }).then(function ({entity_list}) {
                    let selectStaffList = entity_list.map(item => {
                        return {
                            id: item.userId,
                            text: item.name,
                            subsidyAmount: '',   // 用户输入金额
                            subsidyAmountMax: '',   // 用户输入金额的最高标准
                            subsidyStandard: '',    // 接口返回用户标准--展示用的字段
                        }
                    });
                    _this.selectStaffList = selectStaffList;
                });
            }
        },
        //删除出行人
        deletePassenger(passenger) {
            if (this.bookUpperBound == 1) {
                this.$Toast('最大帮订人数为1时，出差人员不允许修改！');
                return;
            };
            this.selectStaffList.splice(this.selectStaffList.indexOf(passenger), 1);
            this.activeUserIds.splice(this.activeUserIds.indexOf(passenger.id), 1);
        },
        // 出差日期弹窗
        popup_calendar() {
            this.showCalendar = true;
        },

        // 选择日期区间
        onConfirmByCalendar(date) {
            this.showCalendar = false;
            this.travelDate = date;
            const [start, end] = date;
            if (this.form.journeyList.length === 0 || this.checkDate(start, end)) {
                this.setTravelDate();
            } else {
                Toast('出差时间与行程时间冲突');
            }
        },

        setTravelDate() {
            const moment = this.$moment;
            const [start, end] = this.travelDate;
            [this.travelStartDate, this.travelEndDate] = this.travelDate;
            this.startDate = start;
            this.endDate = end;
            this.form.startDate = moment(start).format("YYYY-MM-DD");
            this.form.endDate = moment(end).format("YYYY-MM-DD");
        },

        //检查出差日期和行程日期是否冲突
        checkDate(start, end) {
            let itemDate = '';
            this.conflictDateArr = [];
            let minDate = this.$moment(this.form.journeyList[0].departTime);
            let maxDate = this.$moment(this.form.journeyList[0].departTime);

            //找到行程最小最大日期
            this.form.journeyList.forEach((item) => {
                itemDate = item.businessType === 3 ? this.$moment(item.returnTime) : this.$moment(item.departTime);
                if (this.$moment(item.departTime).diff(minDate, 'days') < 0) {
                    minDate = item.departTime;
                }
                if (this.$moment(itemDate).diff(this.$moment(maxDate), 'days') > 0) {
                    maxDate = itemDate;
                }
            });

            //找到冲突的行程
            this.form.journeyList.forEach((item, index) => {
                itemDate = item.businessType === 3 ? this.$moment(item.returnTime) : this.$moment(item.departTime);
                if (this.$moment(item.departTime).diff(start, 'days') < 0 || this.$moment(itemDate).diff(this.$moment(end), 'days') > 0) {
                    this.conflictDateArr.push({
                        index: index + 1,
                        journeyIndex: item.journeyIndex
                    });
                }
            });
            this.conflictDateArr = [...new Set(this.conflictDateArr)];
            if (start <= minDate && end >= maxDate) {
                return true;
            } else {
                return false;
            }
        },

        //确认出差日期和行程日期冲突
        confirmDateConflict() {
            this.conflictDateArr.forEach(item => {
                this.form.journeyList.some((journey, i) => {
                    if (journey.journeyIndex === item.journeyIndex) {
                        this.form.journeyList.splice(i, 1);
                        return true
                    }
                })
            });
            this.setTravelDate()
        },

        clickBudgetInsurance(item) {
            if (this.budgetPopupTitle == '预算险类') {
                this.form.budgetInsuranceCode = item.code;
                this.form.budgetInsuranceName = item.value;
            } else {
                this.form.budgetProCode = item.code;
                this.form.budgetProName = item.value;
            }

            this.budgetPopup = false;
        },
        popup_budgetBudgetPro() {
            this.budgetPopupTitle = '项目';
            this.budgetPopup = true;
            this.budgetListAll = this.budgetProList;
        },
        popup_budgetInsurance() {
            this.budgetPopupTitle = '预算险类';
            this.budgetPopup = true;
            this.budgetListAll = this.budgetInsuranceList;
        },
        budgetClass(value) {
            let box = false;
            if (this.budgetPopupTitle == '预算险类') {
                eval(`box = this.form.budgetInsuranceName == '${value}';`);
            } else {
                eval(`box = this.form.budgetProName == '${value}';`);
            }
            return box;
        },

        columnsBudgetItem() {
            let box = this.$refs.budgetItemRef.getValues()[0];
            this.budgetItemText = box.name;
            this.form.budgetItemType = box.code;
            this.budgetItemPopup = false;
            this.$forceUpdate();
        },

        popup_budgetItemType() {
            this.budgetItemPopup = true;
        },

        // 城市弹窗
        popup_city_components() {
            this.$refs.aTravelApplicationCitySelector.show();
        },

        // 选择归属弹窗
        openCostPopup() {
            this.showCost = true;
        },

        // 上传文件之前
        onBeforeRead(file) {
            let _fileSize = 3 * 1024 * 1024;
            if (file.size > _fileSize) {
                Toast("文件不可超过3M");
                return false;
            }
            return true;
        },
        // 上传附件
        onAfterRead(file) {
            const _ossClient = this.ossClient;
            const form = this.form;
            _ossClient
                .multipartUpload({
                    file: file.file,
                })
                .then((res) => {
                    const list = form.accessoryUrl ? form.accessoryUrl.split(",") : [];
                    list.push(res.url);
                    form.accessoryUrl = list.join(",");
                    this.form = form;
                });
        },
        // 删除已上传文件
        deletFile() {
            const form = this.form;
            const list = form.accessoryUrl ? form.accessoryUrl.split(",") : [];
            list.splice(arguments[1].index, 1);
            form.accessoryUrl = list.join(",");
        },
        onJourneySubmitThen() {
            let _self = this;
            let _businessType = this.journeyType;
            if (_businessType == 1) {
                // 机票
                if (_self.airportCityStartAdrId == "") {
                    Toast("请选择出发城市");
                    return
                } else if (_self.airportCityArriveAdrId == "") {
                    Toast("请选择目的城市");
                    return;
                } else if (_self.airportDates == "") {
                    Toast("请选择日期");
                    return;
                }
            } else if (_businessType == 2) {
                // 火车票
                if (_self.trainCityStartAdrId == "") {
                    Toast("请选择出发城市");
                    return;
                } else if (_self.trainCityArriveAdrId == "") {
                    Toast("请选择目的城市");
                    return;
                } else if (_self.trainDates == "") {
                    Toast("请选择日期");
                    return;
                }
            } else if (_businessType == 3) {
                // 酒店
                if (_self.hotelCityAdrId == "") {
                    Toast("请选择入住城市");
                    return;
                } else if (_self.hotelDates == "") {
                    Toast("请选择日期");
                    return;
                }
            } else if (_businessType == 4) {
                if (!_self.carCityAdrParams.carPolicyTypeText) {
                    Toast("请选择用车类型");
                    return;
                }
            }
            let params = {
                journeyIndex: this.journeyIndex,
                businessType: _businessType,
            };
            this.journeyIndex = this.journeyIndex + 1;
            this.journeyVisibleTypeTwo = false;
            if (_businessType == 1) {
                // 机票
                params.departCityId = _self.airportCityStartAdrParams.code;
                params.departCityName = _self.airportCityStartAdrParams.name;
                params.toCityId = _self.airportCityArriveAdrParams.code;
                params.toCityName = _self.airportCityArriveAdrParams.name;
                params.departTime = this.journeyStartDate;
                params.returnTime = this.journeyEndDate;
                params.singleReturnType = this.singleReturnType;

                if (this.subsidyControlRule == 1) {
                    params.budgetAmount = Number(this.journeyData.budgetAmount);
                    params.budgetSum = Number(this.journeyData.budgetAmount) + Number(this.journeyData.serviceAmount);
                    params.serviceAmount = Number(this.journeyData.serviceAmount);
                    params.subsidyAmount = 0;  // 补助金额
                    params.subsidyInfos = [];
                    this.subsidyAmountPeopleList.forEach((item,index) => {
                        params.subsidyAmount = Number(params.subsidyAmount) + Number(item.subsidyAmount);
                        params.subsidyInfos.push({
                            cityCode: _self.airportCityArriveAdrParams.code,
                            cityName: _self.airportCityArriveAdrParams.name,
                            subsidyAmount: item.subsidyAmount,
                            subsidyStandard: item.subsidyAmountMax,
                            userId: item.id,
                            userName: item.text,
                            subsidyStandardText: item.subsidyStandard,
                        });
                    });
                }
                if (this.journeyVisibleType == 'edit' && this.journeyVisibleIndex !== null && this.journeyVisibleIndex !== '' && this.journeyVisibleIndex !== undefined) {
                    this.form.journeyList.splice(this.journeyVisibleIndex, 1, params);
                } else if (this.journeyVisibleType == 'addition') {
                    this.form.journeyList.push(params);
                }
                this.showJourney = false;
            } else if (_businessType == 2) {
                // 火车票
                params.departCityId = _self.trainCityStartAdrParams.stationCode;
                params.departCityName = _self.trainCityStartAdrParams.stationName;
                params.toCityId = _self.trainCityArriveAdrParams.stationCode;
                params.toCityName = _self.trainCityArriveAdrParams.stationName;
                params.departTime = this.journeyStartDate;
                params.returnTime = this.journeyEndDate;
                params.singleReturnType = null;
                this.form.journeyList.push(params);
                this.showJourney = false;
            } else if (_businessType == 3) {
                // 酒店
                params.departCityId = _self.hotelCityAdrParams.cityId;
                params.departCityName = _self.hotelCityAdrParams.cityCn;
                params.toCityId = _self.hotelCityAdrParams.cityId;
                params.toCityName = _self.hotelCityAdrParams.cityCn;
                params.departTime = this.journeyStartDate;
                params.returnTime = this.journeyEndDate;
                params.singleReturnType = null;

                if (this.subsidyControlRule == 1) {
                    params.budgetAmount = Number(this.journeyData.budgetAmount);
                    params.budgetSum = Number(this.journeyData.budgetAmount) + Number(this.journeyData.serviceAmount);
                    params.serviceAmount = Number(this.journeyData.serviceAmount);
                    params.subsidyAmount = 0;  // 补助金额
                    params.subsidyInfos = [];
                    this.subsidyAmountPeopleList.forEach((item,index) => {
                        params.subsidyAmount = Number(params.subsidyAmount) + Number(item.subsidyAmount);
                        params.subsidyInfos.push({
                          cityCode: _self.hotelCityAdrParams.cityId,
                          cityName: _self.hotelCityAdrParams.cityCn,
                          subsidyAmount: item.subsidyAmount,
                          subsidyStandard: item.subsidyAmountMax,
                          userId: item.id,
                          userName: item.text,
                          subsidyStandardText: item.subsidyStandard,
                        });
                    });
                }
                if (this.journeyVisibleType == 'edit' && this.journeyVisibleIndex !== null && this.journeyVisibleIndex !== '' && this.journeyVisibleIndex !== undefined) {
                  this.form.journeyList.splice(this.journeyVisibleIndex, 1, params);
                } else if (this.journeyVisibleType == 'addition') {
                  this.form.journeyList.push(params);
                }
                this.showJourney = false;
            } else {
                // 用车
                params.carPolicyType = _self.carCityAdrParams.carPolicyType;
                params.singleReturnType = null;
                this.form.journeyList.push(params);
                this.showJourney = false;
            }
        },
        // 确定添加出差行程
        onJourneySubmit() {
            let _self = this;
            let _businessType = this.journeyType;
            if (this.subsidyControlRule == 1) {
                if (_businessType == 1) {
                    // 机票
                    if (_self.airportCityStartAdrId == "") {
                        Toast("请选择出发城市");
                        return
                    } else if (_self.airportCityArriveAdrId == "") {
                        Toast("请选择目的城市");
                        return;
                    } else if (_self.airportDates == "") {
                        Toast("请选择日期");
                        return;
                    }
                } else if (_businessType == 3) {
                    // 酒店
                    if (_self.hotelCityAdrId == "") {
                        Toast("请选择入住城市");
                        return;
                    } else if (_self.hotelDates == "") {
                        Toast("请选择日期");
                        return;
                    }
                }
                this.$refs.infoForm.validate().then(() => {
                    this.onJourneySubmitThen();
                });
            } else {
                this.onJourneySubmitThen();
            }
        },
        changeTrain(){
            let trainCityStartAdrId = this.trainCityStartAdrId;
            let trainCityStartAdrParams = JSON.parse(JSON.stringify(this.trainCityStartAdrParams));
            let trainCityArriveAdrId = this.trainCityArriveAdrId;
            let trainCityArriveAdrParams = JSON.parse(JSON.stringify(this.trainCityArriveAdrParams));
            this.trainCityStartAdrId = trainCityArriveAdrId;
            this.trainCityArriveAdrId = trainCityStartAdrId;
            this.$nextTick(() => {
                this.trainCityStartAdrParams = trainCityArriveAdrParams;
                this.trainCityArriveAdrParams = trainCityStartAdrParams
            })
        },
        changeCity() {
            let airportCityStartAdrId = this.airportCityStartAdrId;
            let airportCityStartAdrParams = JSON.parse(JSON.stringify(this.airportCityStartAdrParams));
            let airportCityArriveAdrId = this.airportCityArriveAdrId;
            let airportCityArriveAdrParams = JSON.parse(JSON.stringify(this.airportCityArriveAdrParams));
            this.airportCityStartAdrId = airportCityArriveAdrId;
            this.airportCityArriveAdrId = airportCityStartAdrId;
            this.$nextTick(() => {
                this.airportCityStartAdrParams = airportCityArriveAdrParams;
                this.airportCityArriveAdrParams = airportCityStartAdrParams
            })
        },
        // 查询出差补助信息
        getSubsidyStandard(code,name,type,TIndex) {
            this.subsidyLoading = true;
            this.subsidyAmountPeopleList.forEach((item,index) => {
                consumer_budget_getSubsidyStandard({
                    businessType: type,
                    cityId: code,
                    cityName: name,
                    userId: item.id,
                }).then(res => {
                    item.subsidyStandard = `${item.text}${res.datas.subsidyStandard == null || res.datas.subsidyStandard == '未设置请联系管理员' ? '未设置请联系管理员' : `￥${res.datas.subsidyStandard}/天`}`;
                    if (res.datas.subsidyStandard != '未设置请联系管理员' && res.datas.subsidyStandard) {
                        if (this.journeyVisibleType == 'edit') {
                            // 编辑
                            if (item.subsidyAmountMax !== 0 && !item.subsidyAmountMax) {
                                item.subsidyAmount = Number(`${res.datas.subsidyStandard}`);
                                item.subsidyAmountMax = item.subsidyAmount;
                                // this.form.journeyList[TIndex].subsidyInfos.push({
                                //     "cityCode": code,
                                //     "cityName": name,
                                //     "subsidyAmount": item.subsidyAmount,
                                //     "subsidyStandard": item.subsidyAmountMax,
                                //     "userId": item.id,
                                //     "userName": item.text,
                                //     "subsidyStandardText": `${item.text}￥${item.subsidyAmountMax}/天`
                                // })
                            }
                        } else {
                            // 新增
                            item.subsidyAmount = Number(`${res.datas.subsidyStandard}`);
                            item.subsidyAmountMax = item.subsidyAmount;
                            if (this.journeyStartDate && this.journeyEndDate) {
                                let dayBox = Number(moment(this.journeyEndDate).diff(moment(this.journeyStartDate),'days')) + 1;
                                item.subsidyAmount = Number(`${item.subsidyAmount}`) * dayBox;
                            }
                        }
                    } else {
                        item.subsidyAmount = 0;
                        item.subsidyAmountMax = item.subsidyAmount;
                    }
                    if (index + 1 >= this.subsidyAmountPeopleList.length) {
                        this.subsidyLoading = false;
                    }
                }).finally(() => {
                    this.subsidyLoading = false;
                })
            });
        },
        editJourney(item,index) {
            console.log('1', item);
            if (this.subsidyControlRule == 1 && (item.businessType == 1 || item.businessType == 3)) {
                this.showJourney = true;
                this.journeyVisibleTypeTwo = true;
                this.journeyVisibleType = 'edit';
                this.journeyVisibleIndex = index;
                this.journeyType = item.businessType;
                this.journeyData.budgetAmount = item.budgetAmount;
                this.journeyData.serviceAmount = item.serviceAmount;


                if (item.businessType == 1) {
                    // 出发城市
                    this.$refs.aDomesticTicketAirportSelectorByStart.set_value({
                      id: item.departCityId,
                      name: item.departCityName,
                    });
                    // 目的城市
                    this.$refs.aDomesticTicketAirportSelectorByArrive.set_value({
                      id: item.toCityId,
                      name: item.toCityName,
                    });
                    this.airportCityStartAdrId = item.departCityId;
                    this.airportCityArriveAdrId = item.toCityId;
                    this.airportDates = item.departTime;
                } else {
                    this.$refs.aHotelCitySelector.set_value({
                      id: item.toCityId,
                      name: item.toCityName,
                    });
                    this.journeyStartDate = item.departTime;
                    this.journeyEndDate = item.returnTime;
                }

                let itemName = [];
                this.subsidyAmountPeopleList.forEach((FItem,FIndex) => {
                    itemName.push(FItem.text);
                });
                this.subsidyAmountPeopleList.forEach((SItem,SIndex) => {
                    item.subsidyInfos.forEach((FItem,FIndex) => {
                        if (FItem.userName == SItem.text) {
                            this.subsidyAmountPeopleList[SIndex].subsidyAmount = FItem.subsidyAmount;
                            this.subsidyAmountPeopleList[SIndex].subsidyAmountMax = FItem.subsidyStandard;
                            this.subsidyAmountPeopleList[SIndex].subsidyStandard = FItem.subsidyStandardText;
                        }
                    });
                });
                this.getSubsidyStandard(item.toCityId,item.toCityName,item.businessType,index);
                let dataBox = {
                    companyId: store.state.userInfo.companyId,
                    evectionType: 2,
                    businessType: item.businessType,
                };
                this.journeyData.budgetAmount = 0;
                this.journeyData.budgetAmount = item.budgetAmount;
                consumer_admin_flight_generate_configServiceFee(dataBox).then(res => {
                    this.journeyData.serviceAmountType = res.datas.serviceAmountType;
                    // this.journeyData.serviceAmountType = 1;
                    if (this.journeyData.serviceAmountType == 2) {
                        this.journeyData.serviceAmount = (Number(res.datas.serviceFee) * this.subsidyAmountPeopleList.length).toFixed(2);
                    } else {
                        this.journeyData.serviceAmount = 0;
                    }
                    this.journeyData.serviceFee = res.datas.serviceFee;
                });
            }
        },
        // 添加出差行程
        onJourney(type) {
            if (this.subsidyControlRule == 1 && (this.subsidyAmountPeopleList == null || this.subsidyAmountPeopleList == undefined || this.subsidyAmountPeopleList.length == 0)) {
                Toast('请先选择出差人');
                return;
            }
            /* 重置机票 */
            this.airportCityStartAdrId = "";
            this.airportCityArriveAdrId = "";
            this.$nextTick(() => {
                this.airportCityStartAdrParams = {};
                this.airportCityArriveAdrParams = {};
            });
            this.airportDates = "";
            this.singleReturnType = 1;
            /* 重置酒店 */
            this.hotelCityAdrId = "";
            this.$nextTick(() => {
                this.hotelCityAdrParams = {};
            });
            this.hotelDates = "";
            /* 重置火车票 */
            this.trainCityStartAdrId = "";
            this.trainCityArriveAdrId = "";
            this.$nextTick(() => {
                this.trainCityStartAdrParams = {};
                this.trainCityArriveAdrParams = {};
            });
            this.trainDates = "";
            /* 重置酒店 */
            this.carCityAdrParams = {};
            /* 重置日期 */
            this.journeyStartDate = ""; // 出发日期
            this.journeyEndDate = ""; // 回程日期
            this.journeyType = type;
            if ((type == 1 || type == 3) && this.subsidyControlRule == 1){
                this.subsidyAmountPeopleList.forEach((SItem,SIndex) => {
                    SItem.subsidyAmount = '';
                    SItem.subsidyAmountMax = '';
                    SItem.subsidyStandard = '';
                });
                this.$nextTick(() => {
                    this.$refs.infoForm.resetValidation();
                });
                let dataBox = {
                    companyId: store.state.userInfo.companyId,
                    evectionType: 2,
                    businessType: type,
                };
                this.journeyData.budgetAmount = 0;
                consumer_admin_flight_generate_configServiceFee(dataBox).then(res => {
                    this.journeyData.serviceAmountType = res.datas.serviceAmountType;
                    // this.journeyData.serviceAmountType = 1;
                    if (this.journeyData.serviceAmountType == 2) {
                        this.journeyData.serviceAmount = (Number(res.datas.serviceFee) * this.subsidyAmountPeopleList.length).toFixed(2);
                    } else {
                        this.journeyData.serviceAmount = 0;
                    }
                    this.journeyData.serviceFee = res.datas.serviceFee;
                });
            }
            if (type == 4){
                if (!this.useCar) {
                    Toast('企业暂未开通用车功能，开通请咨询在线客服');
                    return
                }
                this.carShowPopup = true;
                this.showPickerCarClick();
            } else {
                this.showJourney = true;
                this.journeyVisibleType = 'addition';
                // reset_value
                this.$refs.aDomesticTicketAirportSelectorByStart.reset_value();
                this.$refs.aDomesticTicketAirportSelectorByArrive.reset_value();
                this.$refs.aHotelCitySelector.reset_value();
            }

            //重置出差行程日期选择组件
            // this.$refs.aConfirmByJourneyCalendar2.reset();
        },
        // 用车行程--确认按钮
        columnsCarChange(data){
            let journeyListBox = [];
            let carPolicyTypeBox = [];
            let switchBox = false;
            // 收集外部用车行程信息
            this.form.journeyList.forEach((item,index) => {
                if (item.businessType == 4) {
                    journeyListBox.push(item.carPolicyType);
                }
            });
            // 收集所有用车行程信息
            this.columnsCar.forEach((item,index) => {
                carPolicyTypeBox.push(item.carPolicyType);
            });
            // 获取所有信息和已选信息的差集
            let difference = [];
            for (let item of new Set(carPolicyTypeBox)) {
                if (!new Set(this.currentIndexCar).has(item)) {
                    difference.push(item);
                }
            }

            this.currentIndexCar.forEach((item,index) => {
                if (journeyListBox.indexOf(item) == -1) {
                    let boxData = {
                        businessType: 4,
                        carPolicyType: item,
                        journeyIndex: this.journeyIndex,
                    };
                    this.journeyIndex = this.journeyIndex + 1;
                    this.form.journeyList.push(boxData);
                }
            });
            this.form.journeyList.forEach((item,index) => {
                if (item.businessType == 4 && difference.indexOf(item.carPolicyType) != -1) {
                    this.form.journeyList.splice(index,1);
                    switchBox = true;
                }
            });

            if (switchBox) {
                this.form.journeyList.forEach((item,index) => {
                    item.journeyIndex = index;
                });
                this.journeyIndex = this.form.journeyList.length;
            }

            this.carShowPopup = false;
        },
        // 用车行程--点击用车添加
        clickAddCar(item){
            let box = this.currentIndexCar.indexOf(item.carPolicyType);
            if (box == -1) {
                this.currentIndexCar.push(item.carPolicyType);
            } else {
                this.currentIndexCar.splice(box,1);
            }
        },
        // 用车行程外部内部联动，依据外部信息回显内部
        overlayCarShowPopup(){
            // 外部已选择的用车行程
            let journeyListCarBox = [];
            this.form.journeyList.forEach((item,index) => {
                if (item.businessType == 4) {
                    journeyListCarBox.push(item.carPolicyType);
                }
            });
            // 内部已选择的用车行程
            this.currentIndexCar = journeyListCarBox;
        },
        // 用车行程--叉叉取消
        closeEvectionlPopup(){
            this.overlayCarShowPopup();
            this.carShowPopup = false;
        },
        // 选择出发回程日期
        onConfirmByJourneyCalendar(date) {
            let _self = this;
            let _journeyType = this.journeyType;
            this.showJourneyCalendar = false;
            const [start, end] = date;
            this.journeyStartDate = this.$moment(start).format("YYYY-MM-DD");
            this.journeyEndDate = this.$moment(end).format("YYYY-MM-DD");
            if (_journeyType == 1) {
                // 机票
                _self.airportDates = _self.journeyStartDate + "--" + _self.journeyEndDate;
            } else if (_journeyType == 2) {
                // 火车票
                _self.trainDates = _self.journeyStartDate + "-" + _self.journeyEndDate;
            } else if (_journeyType == 3) {
                // 酒店
                _self.hotelDates = _self.journeyStartDate + "至" + _self.journeyEndDate;
                _self.nightDay = this.$moment(end).diff(this.$moment(start), 'day');
            }
            if (this.subsidyControlRule == 1) {
                this.$refs.infoForm.resetValidation();
                if (this.journeyData.serviceAmountType == 2 && _journeyType == 3) {
                    let startDay = this.$moment(this.journeyStartDate);
                    let searchDay = this.$moment(this.journeyEndDate);
                    let days = Math.abs(startDay.diff(searchDay, 'days')) + 1;
                    this.journeyData.serviceAmount = (Number(this.journeyData.serviceFee) * days * this.subsidyAmountPeopleList.length).toFixed(2);
                    this.subsidyAmountPeopleList.forEach((item,index) => {
                        if (item.subsidyAmount || item.subsidyAmount == 0) {
                            item.subsidyAmount = Number(`${item.subsidyAmountMax}`) * days;
                        }
                    });
                }
            }

        },

        onConfirmByJourneyCalendar2(date) {
            let _self = this;
            let _journeyType = this.journeyType;
            this.showJourneyCalendar2 = false;
            this.journeyStartDate = this.$moment(date).format("YYYY-MM-DD");
            if (_journeyType == 1) {
                // 机票
                _self.airportDates = _self.journeyStartDate;
            } else if (_journeyType == 2) {
                // 火车票
                _self.trainDates = _self.journeyStartDate;
            }

        },

        // 审批流程
        onApprove() {
            this.approveShow = true;
        },
        approveCancelChooseUser() {
            this.approveShow = false;
        },
        // 审批流程
        approveSubmitChooseUser() {
            this.approver = [];
            for (const staff of this.staffAllList) {
                if (this.approveUserIds.includes(staff.id)) this.approver.push(staff);
            }
            // const { id: approverUserId, text: approverUserName, deptId, deptName } = this.approver[0];
            if (this.approver.length > 0) {
                const {id: approverUserId, text: approverUserName} = this.approver[0];

                this.form.recordList = [
                    {
                        // approveStatus: 1,
                        approverUserId,
                        approverUserName,
                        // deptId,
                        // deptName,
                        linkNum: 1,
                        // nodeName: '',
                    },
                ];

                if (this.approver.length >= 2) {
                    Toast("只能选择一个审批人");
                }
            }
        },

        closePopup() {
            if (this.journeyVisibleType == 'edit' && this.journeyVisibleTypeTwo) {
                // Toast('该行程未保存，请点击确定按钮保存')
            }
        },

        commit() {
            // const Toast = this.$Toast;
            const verifyArr = [
                // key要验证的值，type值的类型：1.数组，2.字符串
                {key: "staffIdList", type: "1", message: "请选择出行人"},
                // {key: 'dataArr', type: '2', message: '请选择出差日期'},
                {key: "startDate", type: "2", message: "请选择出差日期"},
                {key: "endDate", type: "2", message: "请选择出差日期"},
                {key: "toCityId", type: "1", message: "请选择出差城市"},
                {key: "reason", type: "2", message: "请填写出差事由"},
                {key: "feeAffiliationName", type: "2", message: "请选择成本归属"},
                {key: this.subsidyControlRule == 1 ? "budgetItemType" : "", type: "1", message: "请选择科目类型"},
                {key: this.subsidyControlRule == 1 ? "budgetInsuranceName" : "", type: "1", message: "请选择预算险类"},
                {key: this.subsidyControlRule == 1 ? "budgetProName" : "", type: "1", message: "请选择项目"},
                {key: "journeyList", type: "1", message: "请添加出差行程"},

                // {key: 'accessoryUrl', type: '2', message: '请上传附件'},
                // {key: "recordList", type: "1", message: "请选择审批人"},
            ];
            let form = JSON.parse(JSON.stringify(this.form));
            form.approveFlowId = this.approveFlowId;
            for (const item of verifyArr) {
                const {key, type, message} = item;
                if (key) {
                  if (!form[key] || !`${form[key]}`.length && (form[key] != 'journeyList' && this.unlimitedJourney != 1)) return Toast(message);
                }
                // if (type === '1') {
                //   if (!form[key] || !form[key].length) return ;
                // } else if (type === '2') {
                //   if (!form[key] || !form[key].length) return ;
                // }
            }

            if (form.journeyList == null || form.journeyList == [] || form.journeyList == undefined || form.journeyList == '' || form.journeyList.length == 0) {
              return Toast('请添加出差行程');
            }

            if (this.isFreeApprove === 0 && this.form.recordList.length === 0 && this.$store.state.workflow === '2') {
                return Toast("请选择审批人");
            }


            if (this.subsidyControlRule == 1) {
                let selectStaffNameBox = [];
                let selectStaffNameIndex = [];
                this.subsidyAmountPeopleList.forEach((SItem,SIndex) => {
                    selectStaffNameBox.push(SItem.text);
                });
                this.form.journeyList.forEach((HItem,HIndex) => {
                    let boxName = [...selectStaffNameBox];
                    HItem.subsidyInfos.forEach((SItem,SIndex) => {
                        if (boxName.indexOf(SItem.userName) == -1) {
                            selectStaffNameIndex.push({index: HIndex,type: 1});
                        } else {
                            boxName.splice(boxName.indexOf(SItem.userName),1);
                            if (HItem.subsidyInfos.length == SIndex + 1 && boxName.length != 0) {
                                selectStaffNameIndex.push({index: HIndex,type: 2});
                            }
                        }
                    });
                });

                if (selectStaffNameIndex.length != 0) {
                    if (selectStaffNameIndex[0].type == 1) {
                        // 出差人员比行程中出差人员多
                        Toast(`出差人员发生变动请编辑行程${selectStaffNameIndex[0].index + 1}`);
                        return;
                    } else {
                        // 行程中出差人员比出差人员多
                        Toast(`行程${selectStaffNameIndex[0].index + 1}存在部分出差人员没有填写补助金额`);
                        return;
                    }
                }
            }
            // form.budgetAmount
            form.budgetAmount = 0;

            form.journeyList.forEach((item,index) => {
              form.budgetAmount = form.budgetAmount + item.budgetSum + item.subsidyAmount
            });
            Toast.loading({
                duration: 0,
                message: "请求中...",
                forbidClick: true,
            });
            if(this.type === 'add') {
                this._addEvection(form);
            }
            if(this.type === 'edit') {
                this._editEvection(form);
            }

        },
        _addEvection(form) {
            addEvection(form)
                .then((result) => {
                    Toast.clear();
                    Dialog.alert({
                        title: "申请成功",
                        message: "您的出差申请已提交成功",
                    }).then(() => {
                        this.$router.push({
                            name: "h5-office-home",
                        });
                    });
                })
                .catch((err) => {
                    Toast.clear();
                });
        },
        async _editEvection(form) {
            let [err, res] = await awaitWrap(addEvection(form));
            if (err) {
                Toast.clear();
                return;
            }
            let formValue = {
                'comments': "",
                'imageList': [],
                'fileList': [],
                'signInfo': "",
            };
            let params = {
                ...this.processInfo,
                ...formValue,
                'attachments': [],
            };
            let [aErr, aRes] = await awaitWrap(agree(params));
            if (aErr) {
                Toast.clear();
                return;
            }
            Toast.success('操作成功');
            this.back();
        },
        // 删除出差行程
        deleteJourney(item, index) {
            this.form.journeyList.splice(index, 1);
            this.$refs.refSwipeCell[index].close();
        },
        back() {
            this.$router.go(-1)
            // this.$router.back();
        },

        //城市定位
        async initLocation() {
            await currentLocation.init();
        },

        //获取城市定位
        locate() {
            this.$Toast({message: '定位中...'});
            currentLocation.get_current_location().then((location) => {
                this.hotelCityAdrId = location.cityId;
                this.hotelCityAdrParams.cityId = location.cityId;
                this.hotelCityAdrParams.cityCn = location.cityName;
                this.setHotelCitySelectorVal();
                if (!location.locationStr) {
                    // 权限问题
                    requestPermission();
                    this.$Toast({message: '获取当前位置失败，请稍后再试!'});
                }
            })
        },

        //初始化入离日期
        initDate() {
            const moment = this.$moment();
            this.journeyStartDate = moment.format('YYYY-MM-DD');
            moment.add(1, 'd');
            this.journeyEndDate = moment.format('YYYY-MM-DD');
        },

        initHotelCitySelector() {
            const __this = this;
            const aSelector = __this.$refs.aHotelCitySelector;
            aSelector.init({
                mock_data: {
                    enable: false,
                    wait_seconds: 0.5,
                },
                panel_select_entity: {
                    input: {
                        placeholder: ``,
                    },
                },
                panel_filter_entity: {
                    input: {
                        placeholder: ``,
                    },
                },
                event_handler: {
                    change_event_handler(args) {
                        __this.hotelCityAdrId = args.id;
                        __this.hotelCityAdrParams.cityId = args.id;
                        __this.hotelCityAdrParams.cityCn = args.name;
                    },
                },
            });
        },

        //设置酒店城市组件选中的值
        setHotelCitySelectorVal() {
            const __this = this;
            const aSelector = __this.$refs.aHotelCitySelector;
            aSelector.set_value({
                "id": __this.hotelCityAdrParams.cityId,
                "name": __this.hotelCityAdrParams.cityCn,
                "entity": {
                    "cityId": __this.hotelCityAdrParams.cityId,
                    "cityCn": __this.hotelCityAdrParams.cityCn,
                    "cityPinyin": "",
                    "cityPinyinSimp": "",
                    "initial": "",
                    "isHot": "",
                    "cityName": null,
                    "key": null
                },
            });
        },
        scrollToTop() {
            window.scrollTo(0,0)
        },
        // 展示时间段日历
        toShowCalendar() {
            if (this.dataArr == '' && this.subsidyControlRule == 0) {
              Toast('请先选择出差日期');
              return;
            }
            this.showJourneyCalendar = true;
            this.$nextTick(()=>{
                if (this.subsidyControlRule == 1 && this.journeyStartDate && this.journeyEndDate) {
                    this.$refs.journeyCalendar.reset([new Date(moment(`${this.journeyStartDate}`).format('YYYY-MM-DD')),new Date(moment(`${this.journeyEndDate}`).format('YYYY-MM-DD'))]);
                } else {
                    this.$refs.journeyCalendar.reset();
                }
            })
        },
        // 展示单选日历
        toShowCalendar2() {
            if (this.dataArr == '' && this.subsidyControlRule == 0) {
              Toast('请先选择出差日期');
              return;
            }
            this.showJourneyCalendar2 = true;
            this.$nextTick(()=>{
                if (this.subsidyControlRule == 1 && this.airportDates) {
                    this.$refs.journeyCalendar2.reset(new Date(moment(`${this.airportDates}`).format('YYYY-MM-DD')));
                } else {
                    this.$refs.journeyCalendar2.reset();
                }
            })
        },
        formatDateSpan(startDate,endDate) {
            return moment(endDate).diff(startDate, 'day') + 1;
        },
        async getDetail() {
            let [err, res] = await  awaitWrap(consumer_apply_workflowDetail({
                "applyNo": this.applyNo, "appType": 2, "approveStatus": 1
            }))
            if (err) {
                return;
            }
            let detail = res.datas;
            this.taskId = detail['approveApply']['taskId'];
            this.selectStaffList = detail['staffDetailVos'];
            let _activeUserIdList = [];
            this.selectStaffList.forEach(element=>{
                element['text'] = element['staffName'];
                _activeUserIdList.push(element['userId']);
            })
            // selectedStaffIdList = _activeUserIdList;
            this.travelStartDate = detail['evection']['startDate'];
            this.travelEndDate = detail['evection']['endDate'];
            console.log(detail['evection']['toCityId'].split(','))
            const cityCodeList = detail['evection']['toCityId'].split(',');
            let evectionCityListIds = [];
            cityCodeList.forEach(value=>{
                evectionCityListIds.push(parseInt(value));
            })
            this.evectionCityListIds = evectionCityListIds;
            console.log('this.evectionCityListIds', this.evectionCityListIds);
            this.budgetItemText = detail['evection']['budgetItemTypeName'] ?? '';
            if (detail['evection']['accessoryUrl'] != '' &&
                detail['evection']['accessoryUrl'] != null) {
                const uploadFileList = detail['evection']['accessoryUrl'].split(',');
                uploadFileList.forEach(value=>{
                    this.uploadFileList.push({
                        url: value
                    })
                })
            }
            let journeyList = detail['journeyList'];
            journeyList.forEach((element, eIndex) =>{
                let businessTypeText = '';
                element['journeyIndex'] = eIndex;
                switch (element['businessType']) {
                    case 1:
                        businessTypeText = '机票';
                        element['flightType'] = element['flightType'] ?? 1;
                        element['budgetAmount'] = element['budgetAmount'];
                        element['subsidyInfos'] = element['subsidyJson'] != null
                            ? JSON.parse(element['subsidyJson'])
                            : [];
                        element['subsidyInfos'].forEach((val)  => {
                        val['subsidyAmount'] = val['subsidyAmount'];
                        val['subsidyStandard'] = val['subsidyStandard'];
                        val['serviceAmount'] = val['serviceAmount'];
                    });
                        break;
                    case 2:
                        businessTypeText = '火车票';
                        break;
                    case 3:
                        businessTypeText = '酒店';
                        element['departCityName'] = element['toCityName'];
                        element['budgetAmount'] = element['budgetAmount'];
                        element['subsidyInfos'] = element['subsidyJson'] != null
                            ? JSON.parse(element['subsidyJson'])
                            : [];
                        element['subsidyInfos'].forEach((val) => {
                        val['subsidyAmount'] = val['subsidyAmount'];
                        val['subsidyStandard'] = val['subsidyStandard'];
                        val['serviceAmount'] = val['serviceAmount'];
                    });
                        break;
                    case 4:
                        businessTypeText = '用车';
                        break;
                }
                element['businessTypeText'] = businessTypeText;
            });
            this.$nextTick(()=>{
                this.form = {
                    'staffIdList': _activeUserIdList, //出行人id列表
                    'startDate': detail['evection']['startDate'], //出差开始时间
                    'endDate': detail['evection']['endDate'], //出差结束时间
                    'reason': detail['evection']['reason'], //出差事由
                    'explainDesc': detail['evection']['explainDesc'], //详情说明
                    'toCityId': detail['evection']['toCityId'], //出差城市id列表
                    'toCityName': detail['evection']['toCityName'], //出差城市名字
                    'feeAffiliationName': detail['evection']['feeAffiliationName'], //费用归属名称
                    'feeAffiliationId': detail['evection']['feeAffiliationId'], //费用归属部门id
                    'feeAffiliationType': detail['evection']
                        ['feeAffiliationType'], //1.组织架构，2.成本中心

                    'journeyList': journeyList, //行程
                    'accessoryUrl': detail['evection']['accessoryUrl'], //附件
                    'recordList': [], //审批流程

                    'budgetItemType': detail['evection']['budgetItemType'], // 科目
                    'budgetItemTypeName':
                        detail['evection']['budgetItemTypeName'] ?? '', // 科目名称

                    'budgetInsuranceCode': detail['evection']
                        ['budgetInsuranceCode'], // 预算险类code
                    'budgetInsuranceName':
                        detail['evection']['budgetInsuranceName'] ?? '', // 预算险类

                    'budgetProCode': detail['evection']['budgetProCode'], // 项目code
                    'budgetProName': detail['evection']['budgetProName'] ?? '', // 项目

                    'budgetAmount': detail['evection']['budgetAmount'] ?? 0, // 申请预算总金额(含服务费)

                    'processInstanceId': this.applyNo,
                    'taskId': this.taskId,
                };
            })

            // 获取审批流
            let [fErr, fRes] = await awaitWrap(formatStartNodeShow({
                'deptId': this.$store.state.userInfo['deptId'],
                'processInstanceId': this.applyNo,
                'userId': this.$store.state.userInfo['id'],
                'budgetAmount': this.form['budgetAmount'] ?? this.form['budgetAmount'] ?? 0,
            }));
            if (fErr) {
                return;
            }
            this.workflowList = fRes['result'];

            this.processInfo = {
                'processInstanceId': this.applyNo,
                'taskId': this.taskId,
                'signFlag': null,
                'currentUserInfo': {
                    'id': this.$store.state.userInfo['id'],
                    'name': this.$store.state.userInfo['realName'],
                },
            };
            getProcessInstanceInfo(this.applyNo, this.taskId)
                .then((resultInfo) => {
                let formData = {...resultInfo['result']['formData']};
                this.processInfo['formData'] = formData;
                    console.log(this.processInfo);
            });
        }
    },
    mounted() {},
    async activated() {
        this.type = this.$route.query.type || 'add';
        this.applyNo = this.$route.query.applyNo || '';
        this.selectStaffList = [];
        this.subsidyAmountPeopleList = [];
        //文件列表
        this.uploadFileList = [];
        this.form = {
          staffIdList: [], //出行人id列表
          startDate: "", //出差开始时间
          endDate: "", //出差结束时间
          reason: "", //出差事由
          explainDesc: "", //详情说明
          toCityId: "", //出差城市id列表
          toCityName: "", //出差城市名字
          feeAffiliationName: "各自所在部门", //费用归属名称
          feeAffiliationId: -1, //费用归属部门id
          feeAffiliationType: 1, //1.组织架构，2.成本中心
          budgetItemType: "", //科目类型 1-管理 2-理赔 3-销售
          budgetInsuranceCode: '',
          budgetInsuranceName: '',
          budgetProCode: '',
          budgetProName: '',

          journeyList: [], //行程
          accessoryUrl: "", //附件
          recordList: [], //审批流程
        }


        await this.$store.state.userInfoDefer.promise;
        await this.$store.state.workflowDefer.promise;
        await this.$store.state.policyDefer.promise;
        await this.refundChangePolicyGet();
        this.init();
        const __this = this;

        this.travelStartDate = new Date();
        this.travelEndDate = new Date();
        this.budgetItemText = '';
        this.form.budgetItemType = '';

        consumer_web_company_checkCompanyUseCar().then(res => {
            this.useCar = res.datas.useCar;
        });

        consumer_budget_getBudgetPro().then(res => {
            this.budgetProList = res.datas;
        });
        consumer_budget_getBudgetInsurance().then(res => {
            this.budgetInsuranceList = res.datas;
        });

        fixTabBar({
            type: `200`,
            element: __this.$refs.bottomButtonContainer,
        });
        fixStatusBar({
            handler({api}) {
                __this.paddingTop = api.safeArea.top;
            }
        });
        this.$refs.aTrainCitySelectorByStart.init();
        this.$refs.aTrainCitySelectorByArrive.init();
        this.$refs.aTravelApplicationCitySelector.init();
        this.$refs.aDomesticTicketAirportSelectorByStart.init({
            input: {
                placeholder: '出发城市(如北京/beijing/bj)'
            }
        });
        this.$refs.aDomesticTicketAirportSelectorByArrive.init({
            input: {
                placeholder: '目的城市(如北京/beijing/bj)'
            }
        });
        this.$refs.aHotelCitySelector.init();
        this.initLocation();
        this.initDate();
        this.initHotelCitySelector();

        keyback_processor.addEventListener(() => {
            const carShowPopup = this.carShowPopup;
            if (carShowPopup) {
                this.carShowPopup = false;
                return;
            }

            const aDomesticTicketAirportSelectorByStart = this.$refs.aDomesticTicketAirportSelectorByStart;
            if (aDomesticTicketAirportSelectorByStart.get_show()) {
                aDomesticTicketAirportSelectorByStart.hide();
                return
            }
            const aDomesticTicketAirportSelectorByArrive = this.$refs.aDomesticTicketAirportSelectorByArrive;
            if (aDomesticTicketAirportSelectorByArrive.get_show()) {
                aDomesticTicketAirportSelectorByArrive.hide();
                return
            }
            if (this.showPickerCar) {
                this.showPickerCar = false;
                return
            }
            if (this.showJourneyCalendar) {
                this.showJourneyCalendar = false;
                return
            }
            if (this.showJourneyCalendar2) {
                this.showJourneyCalendar2 = false;
                return;
            }
            const aTrainCitySelectorByStart = this.$refs.aTrainCitySelectorByStart;
            if (aTrainCitySelectorByStart.get_show()) {
                aTrainCitySelectorByStart.hide();
                return;
            }
            const aTrainCitySelectorByArrive = this.$refs.aTrainCitySelectorByArrive;
            if (aTrainCitySelectorByArrive.get_show()) {
                aTrainCitySelectorByArrive.hide();
                return;
            }
            const aHotelCitySelector = this.$refs.aHotelCitySelector;
            if (aHotelCitySelector.get_show()) {
                aHotelCitySelector.hide();
                return;
            }
            if (this.approveShow) {
                this.approveShow = false;
                return;
            }
            if (this.staffVisible) {
                this.staffVisible = false;
                return;
            }
            const aTravelerMultiSelector = this.$refs.aTravelerMultiSelector;
            if (aTravelerMultiSelector.get_show()) {
                aTravelerMultiSelector.hide();
                return;
            }

            if (this.showCalendar) {
                this.showCalendar = false;
                return;
            }

            const aTravelApplicationCitySelector = this.$refs.aTravelApplicationCitySelector;
            if (aTravelApplicationCitySelector.get_show()) {
                aTravelApplicationCitySelector.hide();
                return;
            }

            if (this.showCost) {
                this.showCost = false;
                return;
            }
            if (this.showJourney) {
                this.showJourney = false;
                return;
            }
            this.back()
        });

        if (this.type === 'edit') {
            this.getDetail();
        }
    },
    deactivated() {
        this.evectionCityListIds = [];
        keyback_processor.removeEventListener();
    },
    filters: {
        formatDate(val) {
            const moment = Moment(val);
            return moment.format('MM月DD日');
        },
        formatWeekDay(val) {
            if (!val) return '';
            const moment = Moment();
            const today = moment.format('YYYY-MM-DD');
            const tomorrow = moment.add(1, 'd').format('YYYY-MM-DD');

            const day = Moment(val).day();

            if (val === today) return '今天';
            if (val === tomorrow) return '明天';

            const week = {
                0: '周日',
                1: '周一',
                2: '周二',
                3: '周三',
                4: '周四',
                5: '周五',
                6: '周六',
            };

            return week[day];
        },
        filterFlightDateReturn(val) {
          return val === "" ? "" : moment(val).format("MM月DD日");
        },
        filterFlightDate(val) {
            return val === "" ? "" : moment(val).format("YYYY年MM月DD日");
        },
        filterFlightDaY(val) {
            return val === "" ? "" : moment(val).format("MM月DD日");
        },
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
    },
};
