import import_latest_version_module from '@/component/base-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/haolv/feature/feature-list',
            name: 'haolv-feature-feature-list',
            component: () => import(/* webpackChunkName: "page-demo-yinzhilv-js-sdk-haolv-feature-feature-list" */ `./index.vue`),
            meta: {
                data_for_base_layout: {
                    header: {
                        show: true,
                        title: `haolv功能列表`,
                    },
                    tab_bar: {
                        show: false,
                    }
                },
            },
        },
    ],
};

