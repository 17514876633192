// 组件
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import EpidemicPolicy from '@/page/travel-book/train/component/epidemicPolicy/1.0.0/index.vue'
// 时刻表
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
// 12306核验
import AccountProcessStep from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process/1.0.0/index.vue";
// 超标展示组件
import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
// 改签确认
import TrainChangeSubmit from '@/page/personal-center/order/train/train-change-search-train/1.0.0/components/train-change-submit/1.0.0/index.vue'
//方法
// 滑屏返回
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";

// 接口
// 余票查询
import consumer_trains_product_checkTheRemainingTrainTicketsDetail from "@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTicketsDetail";
import consumer_trains_order_addJourneyPC from '@/lib/data-service/haolv-default/consumer_trains_order_addJourneyPC';
import consumer_trains_order_advanceRebooking from '@/lib/data-service/haolv-default/consumer_trains_order_advanceRebooking'
// vant
import {Toast} from "vant";
// moment
import moment from 'moment';
export default {
    data() {
        return {
            workTravelType: null,
            evectionType: null,
            redirectType: null,
            trainOrderNo: null,
            evectionRequired: null,
            journeyId: null,
            businessRuleControl: null,
            applyId: null,
            orderNo: '',
            evectionNo:'',
            userList: '',
            trainParams: [],       //传入超标信息
            failDialogVisible: false,  // 余票查询为空数组--提醒dialog
            resultList: [{
                fromStationName: null,
                fromTime: null,
                trainCode: null,
                spanTime: null,
                toStationName: null,
                toTime: null,
                arriveDate: null,
                trainSeatVoList: [],
            }],
            title: '车次详情(改签)',
            minDate: '2023-11-08',
            maxDate: '2044-12-12',            // 日历组件--可选最大日期
            fromStationCode: null,
            fromStationName: '',
            toStationCode: null,
            toStationName: '',
            trainDate: null,
            trainNo: null,
            calendarShow: false,  // 日历组件Show
        }
    },
    components: {
        OrangeNavBar,
        EpidemicPolicy,
        TrainTimeTable,
        AccountProcessStep,
        EvectionStandardExceed,
        TrainChangeSubmit
    },
    created() {
    },
    mounted() {
    },
    activated() {
        let __this = this;
        this.fromStationCode = this.$route.query.fromStationCode;
        this.toStationCode = this.$route.query.toStationCode;
        this.fromStationName = this.$route.query.fromStationName;
        this.toStationName = this.$route.query.toStationName;
        this.trainDate = this.$route.query.trainDate;
        this.trainNo = this.$route.query.trainNo;
        this.evectionNo = this.$route.query.evectionNo;
        this.evectionType = parseInt(this.$route.query.evectionType);
        this.orderNo = this.$route.query.orderNo;
        this.userList = this.$route.query.userList;
        this.minDate = this.$route.query.minDate;
        this.maxDate = this.$route.query.maxDate;
        //this.applyId = this.$route.query.applyId;
        //this.evectionRequired = this.$route.query.evectionRequired;
        //this.journeyId = this.$route.query.journeyId;
        //this.businessRuleControl = this.$route.query.businessRuleControl;

        //this.workTravelType = this.$route.query.workTravelType;
        //this.evectionType = this.$route.query.evectionType;
        //this.redirectType = this.$route.query.redirectType;

        //this.maxDate = this.$route.query.maxDate == '' ? this.dateMax() : this.$route.query.maxDate;
        this.init();
        keyback_processor.addEventListener(() => {
            if (this.failDialogVisible) {
                this.failDialogVisible = false;
                return
            }
            if (this.calendarShow) {
                this.calendarShow = false;
                return
            }
            const lineShow = this.$refs['aTrainTimeTable'].get_show();
            if (lineShow) {
                this.$refs['aTrainTimeTable'].hide();
                return
            }

            const aTrainChangeSubmit = __this.$refs.aTrainChangeSubmit;
            if (aTrainChangeSubmit.get_show()) {
                aTrainChangeSubmit.hide();
                return;
            }
            this.back()
        })
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // 组装当前页面url
        locationHref(timeDate){
            /*
            * fromStationCode: val.fromStationCode,
                    toStationCode: val.toStationCode,
                    trainDate: this.$moment(val.arriveDate).format('YYYY-MM-DD'),
                    trainNo: val.trainCode,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType,
                    orderNo: this.orderNo,
                    userList: this.userList,*/
            let href = location.href.split('?')[0];
            let newUrl = ``+
                `${href}?`+
                `fromStationCode=${this.fromStationCode}&`+
                `toStationCode=${this.toStationCode}&`+
                `trainDate=${timeDate}&`+
                `trainNo=${this.trainNo}&`+
                `evectionNo=${this.evectionNo}&`+
                `evectionType=${this.evectionType}&`+
                `orderNo=${this.orderNo}&`+
                `userList=${this.userList}&`+
                `minDate=${this.minDate}&`+
                `maxDate=${this.maxDate}`;
            // history.replaceState(null, null, newUrl);
        },
        // 日历组件选中
        selectCalendar (day) {
            this.$showLoading.show();
            let timeDate = moment(day).format('YYYY-MM-DD');

            // 更改时间
            this.trainDate = timeDate;
            let dataPost = {
                fromStationCode: this.fromStationCode,
                toStationCode: this.toStationCode,
                trainDate: this.trainDate,
                trainNo: this.trainNo,
                applyId: this.applyId,
                evectionNo: this.evectionNo,
            };
            consumer_trains_product_checkTheRemainingTrainTicketsDetail(dataPost)
                .then((res) => {
                    if (res.resultList.length == 0) {
                        this.$showLoading.hide();
                        this.failDialogVisible = true;
                        return
                    }
                    this.resultList = res.resultList;
                    this.$showLoading.hide();
                    this.initEvectionStandardExceed();
                })
                .finally(() => {
                    this.$showLoading.hide();
                });
            // this.initEpidemicPolicy();
            this.initTrainTimeTable();
            this.calendarShow = false;
        },
        // 今天、明天
        DateDiff(date){
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(date).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == this.trainDate) {
                text = '今天';
            }

            if (tomorrow == todayEnd) {
                text = '明天';
            }

            if (afterTomorrow == todayEnd) {
                text = '后天';
            }

            return text

        },
        // 星期
        format_week (val) {
            switch (moment(val).day()) {
                case 0:
                    return '星期天';
                case 1:
                    return '星期一';
                case 2:
                    return '星期二';
                case 3:
                    return '星期三';
                case 4:
                    return '星期四';
                case 5:
                    return '星期五';
                case 6:
                    return '星期六'
            }
        },
        // 时间拼接
        timeTexSplicing(date){
            if (date != null) {
                // 月日
                let monthDay = moment(date).format('MM-DD');
                // 星期
                let weekText = this.format_week(date);
                // 今天、明天、后天
                let timeName = this.DateDiff(date);

                return `${monthDay} ${weekText} ${timeName}`
            }

        },
        //前一天(true)后一天(false)--前置判断(不可单击时置灰)
        ifDateSide(val){
            let switchBox = false;
            if (!this.trainDate) return;
            if (val) {
                if (this.minDate) {
                    // 前一天
                    let newDate = moment(this.minDate).format('YYYY-MM-DD');
                    let trainDate = moment(this.trainDate).format('YYYY-MM-DD');
                    if (newDate == trainDate) {
                        switchBox = true;
                    } else {
                        switchBox = false;
                    }
                }

            } else {
                if (this.maxDate) {
                    // 后一天
                    let newDate = moment(new Date(this.trainDate)).format('YYYY-MM-DD');
                    if (newDate == this.maxDate) {
                        switchBox = true;
                    } else {
                        switchBox = false;
                    }
                }
            }
            return switchBox
        },
        //前一天(true)后一天(false)--click事件
        clickDate(val){
            if (this.ifDateSide(val)) {
                return;
            }
            this.$showLoading.show();
            let timeDate = null;
            if (val) {
                // 前一天
                let disabled = this.ifDateSide(val);
                if (disabled) {
                    return
                }
                timeDate = moment(new Date(this.trainDate)).subtract(1, 'd').format('YYYY-MM-DD');
            } else {
                // 后一天
                let disabled = this.ifDateSide(val);
                if (disabled) {
                    return
                }
                timeDate = moment(new Date(this.trainDate)).add(1, 'd').format('YYYY-MM-DD');
            }
            // 更改时间
            this.trainDate = timeDate;
            let dataPost = {
                fromStationCode: this.fromStationCode,
                toStationCode: this.toStationCode,
                trainDate: this.trainDate,
                trainNo: this.trainNo,
                applyId: this.applyId,
                evectionNo: this.evectionNo,
            };
            consumer_trains_product_checkTheRemainingTrainTicketsDetail(dataPost).then((res) => {
                this.$showLoading.hide();
                if (res.resultList.length == 0) {
                    this.failDialogVisible = true;
                    return
                }
                this.initRequest();
            })
            .finally(() => {
                this.$showLoading.hide();
            });
        },
        initRequest(){
            this.$showLoading.show();
            let dataPost = {
                fromStationCode: this.fromStationCode,
                toStationCode: this.toStationCode,
                trainDate: this.trainDate,
                trainNo: this.trainNo,
                applyId: this.applyId,
                evectionNo: this.evectionNo,
            };
            consumer_trains_product_checkTheRemainingTrainTicketsDetail(dataPost).then((res) => {
                if (res.resultList.length == 0) {
                    this.$showLoading.hide();
                    this.failDialogVisible = true;
                    return
                }
                this.resultList = res.resultList;
                this.$showLoading.hide();
                this.initEvectionStandardExceed();
            })
            .finally(() => {
                this.$showLoading.hide();
            });
            // this.initEpidemicPolicy();
            this.initTrainTimeTable();
        },
        // 日历组件
        timeCalenderClick(){
            this.calendarShow = true;
        },
        clickSiteNum(){
            this.$refs['aTrainTimeTable'].show();
        },
        //整合超标信息
        initEvectionStandardExceed(){
            this.resultList[0].trainSeatVoList.forEach((TItem,TIndex) => {
                this.trainParams.push({
                    evectionNo: this.evectionNo,
                    motorcycleType: this.resultList[0].motorcycleType,
                    seatName: TItem.seatName
                })
            });
        },
        // 防疫政策组件
        initEpidemicPolicy() {
            this.$refs['epidemicPolicy'].init({
                startCityCode: this.fromStationCode,
                endCityCode: this.toStationCode
            });
        },
        // 火车票时刻表组件
        initTrainTimeTable() {
            this.$refs['aTrainTimeTable'].init({
                //出发站三字码
                fromStationCode: this.fromStationCode,
                //到达站三字码
                toStationCode: this.toStationCode,
                //车次号
                trainCode: this.trainNo,
                //日期(2020-05-18)
                trainDate: this.trainDate,
            })
        },
        dateMax(){
            let dateBox = moment(new Date()).format('YYYY-MM-DD').split('-');
            dateBox[0] = Number(dateBox[0]) + 10;

            return `${dateBox[0]}-${dateBox[1]}-${dateBox[2]}`
        },
        //因公出行弹框--选择后点击确认按钮返回事件
        updateEvectionInfo(val){
            //editType: string, 编辑类型，'000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
            // 原地刷新、返回列表页面
            if (val.editType === '002') {
                this.evectionNo = val.evectionNo;
                this.journeyId = val.journeyId;

                this.initRequest();
            }


        },
        clickDisabled(){
            this.$refs['travelPopup'].init({
                popupType: '001',   //000--右上角展示按钮，001--置灰展示按钮
                evectionNo: this.evectionNo,
                evectionRequired: Number(this.$route.query.evectionRequired),
                journeyId: Number(this.journeyId),
                businessRuleControl: this.businessRuleControl,
            })
        },
        clickSubscribe(item,index){
            this.$showLoading.show();
            this.$showLoading.hide();
            this.onReserve(item, index);

        },
        // 预订--跳转
        onReserve (item, sIndex){
            let params = {
                trainDetail: this.resultList[0],
                orderNo: this.orderNo,
                userList: this.userList,
                seatDetail: item,
                evectionNo: this.evectionNo,
                evectionType: this.evectionType,
            };
            this.$refs.aTrainChangeSubmit.init(params)
        },
        init () {
            this.$showLoading.show();
            let data = {
                fromStationCode: this.fromStationCode,
                toStationCode: this.toStationCode,
                trainDate: this.trainDate,
                trainNo: this.trainNo,
                applyId: this.applyId,
                evectionNo: this.evectionNo,
            };

            consumer_trains_product_checkTheRemainingTrainTicketsDetail(data).then((res) => {
                if (res.resultList.length == 0) {
                    this.$showLoading.hide();
                    this.failDialogVisible = true;
                    return
                }
                this.resultList = res.resultList;
                this.$showLoading.hide();
                this.initEvectionStandardExceed();
            })
            .finally(() => {
                this.$showLoading.hide();
            });

            // this.initEpidemicPolicy();
            this.initTrainTimeTable();
            // this.$refs['epidemicPolicy']._showPopup();
        },
        back(){
            // this.$router.go(-1);
            const startTrainParams = {
                stationCode: this.fromStationCode,
                stationName: this.fromStationName,
            };
            const arriveTrainParams = {
                stationCode: this.toStationCode,
                stationName: this.toStationName
            };
            const params = {
                startTrainParams: JSON.stringify(startTrainParams), // 出发站信息
                arriveTrainParams: JSON.stringify(arriveTrainParams), // 到达站信息
                trainDate: this.trainDate,
                evectionNo: this.evectionNo,
                evectionType: this.evectionType,
                orderNo: this.orderNo,
                userList: this.userList,
                minDate: this.minDate,
                maxDate: this.maxDate,
            };
            this.$router.push({
                name: 'h5-train-change-search-list',
                query: params
            })
        },
    }
}