import _ from 'underscore';
import getMyJourneyList from '@/lib/data-service/haolv-default/consumer_journey_myJourneyList';
import consumer_web_company_checkCompanyUseCar from '@/lib/data-service/haolv-default/consumer_web_company_checkCompanyUseCar'
import consumer_sys_inform_list from '@/lib/data-service/haolv-default/consumer_sys_inform_list';
import consumer_apply_approveNumber from '@/lib/data-service/haolv-default/consumer_apply_approveNumber'
import consumer_car_link_getGaodeLinkForPickUp from '@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkForPickUp'
//根据订单号获取跳转订单详情链接接口
import consumer_car_link_getGaodeLinkByOrderNo
  from "@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkByOrderNo";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import get_user_info from '@/lib/common-service/get_user_info';
import ColorIcon from "@/component/color-icon/index.vue";
import HomeTripList from "@/component/home-trip-list/import_latest_version_module.js";
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
import moment from "moment";
import {Dialog, Toast} from "vant";
import open from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window";
import applyButton from "@/lib/data-service/haolv-default/consumer_menu_applyButton";
import {getCarUserShow} from "@/lib/ohter";
import {hideTrainOpenCompanyIdList} from "@/lib/ohter/index.js";
import Cookies from 'js-cookie'
import adUit from "@/lib/common-service/ad";
import store from '@/lib/store';

export default {
    data() {
        return {
            hideTrainOpenCompanyIdList: hideTrainOpenCompanyIdList,
            companyId: '',
            getCarUserShowBox: false,
            useCar: null,
            timeBoxExamine: null,
            examineForm: true,
            clickExamineType: true,
            badgeNum: 0,
            readMsgNum: 0,
            loading: false,
            menuList: [
                {
                    className: 'flight-pic',
                    title: '机票',
                    cont: '低价机票，舒适出行',
                    path: 'h5-flight-home',
                },
                {
                    className: 'hotel-pic',
                    title: '酒店',
                    cont: '尊贵入住，商旅独享',
                    path: 'h5-hotel-home',
                },
                {
                    className: 'train-pic',
                    title: '火车票',
                    cont: '一键出票，订票便捷',
                    path: 'h5-train-home',
                },
                {
                    className: 'car-pic',
                    title: '用车',
                    cont: '立即用车，想走就走',
                    path: 'h5-car-home',
                }
            ],
            myJourneyList: [],
            now: '',

            audit: false,
            info: {
                tusDept: {},
                tusCompany: {},
            },

            systemMsgList: [],

            setTimeInterval: '',

            advertImgList: [
                /*{
                    id: 1,
                    src: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2024/01/membership1.png',
                },
                {
                    id: 2,
                    src: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/05/ad-universal-studios.png',
                },
                {
                    id: 3,
                    src: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2023/05/ad-ocean-park.png',
                }*/
            ],

            jurisdiction: false, // 按钮权限范围

            popAdImgList: [],
            adShow: false,
            subsidyControlRule: 0,//预算管控 0：不开启 1：开启（只允许按出差单申请金额内预订行程） 默认：0
        }
    },
    components: {ColorIcon,HomeTripList},
    created() {
    },
    async mounted() {
      await this.$store.state.policyDefer.promise;
      this.subsidyControlRule = store.state.policy.subsidyControlRule;
    },
    async activated() {
        this.clickExamineType= true;
        await this.activatedFunction();
        // 提示更新
        let updateTips = Cookies.get('updateTips');
        console.log(updateTips);
        let updateTipsPass = false;
        const today = moment();

        if (updateTips == undefined || updateTips == '1') {
            updateTipsPass = true;
        } else if (today.diff(moment(updateTips), 'days') >= 1) {
            updateTipsPass = true;
        }
        console.log(updateTipsPass, this.companyId);
        const api = global.api;
        if (api) {
            // 检查当前版本是否最新
            const systemType = api.systemType;
            const appVersion = api.appVersion;
            // 判断企业是胤之旅 或 明阳   2024-03-15 不再判断公司
            if (appVersion !== '2.0.1' && window.appConfig.environment === 'release' && updateTipsPass) {
                //提示更新
                let nowTime = moment().format('YYYY-MM-DD');
                Cookies.set('updateTips', nowTime, { expires: 1 });
                Dialog.confirm({
                    title: '更新提醒',
                    message: '当前客户端不是最新版本，请更新到最新版本获得更好体验。',
                })
                    .then(() => {
                        // on confirm
                        if (systemType === 'ios') {
                            api.openApp({
                                iosUrl: 'https://download.wholeok.com/download/ios'
                            });

                        } else {
                            api.openApp({
                                androidPkg: 'android.intent.action.VIEW',
                                mimeType: 'text/html',
                                uri: 'https://download.wholeok.com/download/android-new'
                            }, function(ret, err) {

                            });
                        }
                    })
                    .catch(() => {
                        // on cancel
                    });
            }
        }

        let adRes = await adUit.getAdList({
            companyId: this.companyId,
        });
        if (!adRes) {
            return;
        }
        this.advertImgList = await adUit.getHomeBannerAdList();
        this.popAdImgList = await adUit.getHomeAdList();
        let ck = Cookies.get('homePopAd');
        if (this.popAdImgList.length > 0 && ck === undefined) {
            this.adShow = true;
            Cookies.set('homePopAd', '1', {expires: 1,});
        }
    },
    deactivated() {
    },
    destroyed() {
        wangyiServerOnline.config().then(() => {
          wangyiServerOnline.show();
          wangyiServerOnline.destructionGetUnReadMsgNum();
        });
        keybackProcessor.removeEventListener();
        clearInterval(this.setTimeInterval)

    },
    watch: {},
    computed: {
        hello() {
            let text = '';
            let nowTime = this.now;
            const morning = ['05', '06', '07', '08', '09', '10', '11'];
            const noon = ['12', '13'];
            const afternoon = ['14', '15', '16', '17', '18'];
            const evening = ['19', '20', '21', '22', '23'];
            const beforeDawn = ['00', '01', '02', '03', '04'];
            if (morning.indexOf(nowTime) > -1) {
                text = '上午'
            } else if (noon.indexOf(nowTime) > -1) {
                text = '中午'
            } else if (afternoon.indexOf(nowTime) > -1) {
                text = '下午'
            } else if (evening.indexOf(nowTime) > -1) {
                text = '晚上'
            } else if (beforeDawn.indexOf(nowTime) > -1) {
                text = '凌晨'
            }
            return text
        },
    },
    filters: {

    },
    methods: {
        getToday(item){
            let box = false;
            if (moment(item.startDate).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
                box = true;
            }
            return box;
        },
        async activatedFunction(){
            wangyiServerOnline.config().then(() => {
                wangyiServerOnline.getUnReadMsgNum({
                    callback:(result) => {
                        this.readMsgNum = result;
                    }
                });
                setTimeout(() => {
                    wangyiServerOnline.onlyHide();
                },200)
            });

            setTimeout(() => {
                this.floatExamineContract();
            },3000);
            this.getApplyButton();
            await this.getUserInfo();
            this.setTime();
            this.getJourneyList();
            this.getNotice();
            fixStatusBar({
                type: `100`,
                selector: `.bg`,
            });
            fixStatusBar({
                type: `100`,
                selector: `.content`,
            });
            const api = global.api;
            keybackProcessor.addEventListener(() => {
                api.closeWidget()
                /*api.confirm({
                    title: '确定要关闭程序吗？',
                    buttons: ['确定', '关闭'],
                }, function (ret, err) {
                    if (ret.buttonIndex === 1) {
                        api.closeWidget()
                    }
                })*/
            })
        },
        // 今天、明天
        DateDiff(date){
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(date).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == this.trainDate) {
                text = `今天 ${moment(date).format('MM-DD HH:mm')}`;
            }

            if (tomorrow == todayEnd) {
                text = `明天 ${moment(date).format('MM-DD HH:mm')}`;
            }

            if (afterTomorrow == todayEnd) {
                text = `后天 ${moment(date).format('MM-DD HH:mm')}`;
            }
            if (text == '') {
                text = moment(date).format('MM-DD HH:mm');
            }
            return text

        },
        clickTrainPhone(val){
          let businessType = val;
          consumer_car_link_getGaodeLinkForPickUp({businessType}).then(res => {
            let url = res.datas.url;
            open({
              url,
              title: '用车订单详情',
              btn_back_click_event_handler: () => {
                this.activatedFunction();
              }
            })
          });
        },
        clickCarPhone(Phone){
            // open({
            //   url: 'tel:' + Phone,
            // })
        },
        clickCarDetail(value){
          consumer_car_link_getGaodeLinkByOrderNo({orderNo:value.orderNo}).then(res => {
            let url = res.datas.url;
            open({
              url,
              title: '用车订单详情',
              btn_back_click_event_handler: () => {
                this.activatedFunction();
              }
            })
          })
        },
        clickMap(val){
            let hotelName = val ? val.hotelName : '';
            let longitude = val ? val.hotelLongitude : '';
            let latitude = val ? val.hotelLatitude : '';

            if (hotelName && longitude && latitude) {
                this.$router.push({
                    name: "h5-hotel-map",
                    query: {
                        hotelName,
                        longitude,
                        latitude,
                    }
                })
            }
        },
        colorJourneyStatusTextCar(type){
          let color = '#FF7002';
          switch (type) {
            case 0:
              color = '#FF7002';
              break;
            case 1:
              color = '#FF7002';
              break;
            case 2:
              color = '#FF7002';
              break;
            case 3:
              color = '#FF7002';
              break;
            case 4:
              color = '#FF7002';
              break;
            case 5:
              color = '#FF7002';
              break;
            case 6:
              color = '#FF7002';
              break;
            case 7:
              color = '#FF7002';
              break;
          }
          return color
        },
        colorJourneyStatusText(text){
          let color = '#B3B3B3';
          switch (text) {
            case `已作废`:
              color = '#B3B3B3';
              break;
            case `待审核`:
              color = '#FF7002';
              break;
            case `待预定`:
              color = '#FF7002';
              break;
            case `预定中`:
              color = '#FF7002';
              break;
            case `待出行`:
              color = '#FF7002';
              break;
            case `出行中`:
              color = '#3F8EF7';
              break;
            case `已完成`:
              color = '#333333';
              break;
            case `已取消`:
              color = '#B3B3B3';
              break;
          }
          return color
        },
        touchmoveHome(){
          this.floatExamineTransparent();
        },
        // 浮动审批--点击
        clickExamine(){
          this.$refs.floatExamine.style.opacity = '1';
          this.$refs.floatExamine.style.right = '0px';
          this.clickExamineType = false;
          // 跳转
          setTimeout(() => {
            this.$router.push({
              name: "h5-my-approve-list",
              query: {
                id: 1,
                jumpType: 'h5-home',
              }
            })
          },500)
        },
        // 浮动审批--展开
        floatExamineExpand(){
          this.examineForm = true;
          this.$refs.floatExamine.style.width = '119px';
          this.$refs.floatExamine.childNodes[1].style.width = '65px';
          this.$refs.floatExamine.childNodes[1].style.paddingLeft = '6px';
        },
        // 浮动审批--收缩
        floatExamineContract(){
          this.examineForm = false;
          if (this.$refs.floatExamine) {
            this.$refs.floatExamine.childNodes[1].style.width = '0px';
            this.$refs.floatExamine.childNodes[1].style.paddingLeft = '0px';
            this.$refs.floatExamine.style.width = '50px';
          }

        },
        // 浮动审批--透明
        floatExamineTransparent(){
          if (!this.examineForm) {
            clearTimeout(this.timeBoxExamine);
            if (this.$refs.floatExamine.style.opacity != '0.3') {
              this.$refs.floatExamine.style.opacity = '0.3';
              this.$refs.floatExamine.style.right = '-25px';
            }
            this.timeBoxExamine = setTimeout(() => {
              this.$refs.floatExamine.style.opacity = '1';
              this.$refs.floatExamine.style.right = '0px';
            },1000);
          }
        },
        clickShowTalkWin(){
          this.$Toast.loading({
            duration: 1000,
            message: "加载中...",
            forbidClick: true,
          });
          wangyiServerOnline.config().then(() => {
            wangyiServerOnline.showTalkWin()
          });
        },
        async getUserInfo() {
            let res = await get_user_info();
            this.info = res.datas;
            this.audit = res.datas.audit;
            this.getCarUserShowBox = getCarUserShow(this.info.companyId,this.info.id);
            this.companyId = res.datas.companyId;
        },
        setTime() {
            this.now = moment().format('HH');
            this.setTimeInterval = setInterval(() => {
                this.now = moment().format('HH')
            }, 1000 * 60 * 20)
        },
        getJourneyList() {
            this.loading = true;
            const params = {
                currentPage: 1,
                pageSize: 5,
                startDate: '',
                journeyType: 0
            };
            getMyJourneyList(params).then((res) => {
                res.datas.forEach((MItem,MIndex) => {
                    if (MItem.businessType == 1 && MItem.airborneInfo && [2,3].indexOf(MItem.airborneInfo.changeType) != -1) {
                        MItem.startDate = MItem.airborneInfo.changeArriveDate;
                    }
                });
                let myJourneyList = res.datas;
                let dateList = [...new Set(myJourneyList.map(item => item.startDate))];
                this.myJourneyList = [...new Set(dateList.map(date => {
                        let filterList = myJourneyList.filter(item => item.startDate === date);
                        return {
                                recentDate: filterList[0].dayType,
                                startDate: date,
                                week: filterList[0].week,
                                journeyList: filterList
                        }
                    }))
                ];
                this.myJourneyList = _.sortBy(this.myJourneyList, 'startDate');
                this.loading = false;
            }).catch((err) => {
                this.loading = false;
            });
        },
        toAdDetail(item) {
            this.adShow = false;
            console.log(item);
            if (item['adJumpType'] === 1) {
                let query = JSON.parse(item['extendData']);
                console.log(query);
                this.$router.push({
                    name: item['adJumpUrl'],
                    query: query,
                });
            } else {
                window.open(item['adJumpUrl'])
            }

        },
        jumpPage(val) {
            if (val.title == '用车' && this.getCarUserShowBox == false) return;
            if (val.path === 'h5-car-home') {
                consumer_web_company_checkCompanyUseCar().then(res => {
                    const useCar = res.datas.useCar;
                    if (useCar === 1) {
                        if (this.subsidyControlRule == 1) {
                            this.$router.push({
                                name: 'h5-car-application',
                            });
                        } else {
                            this.$router.push({
                                name: val.path,
                            });
                        }
                    } else {
                        Toast('企业暂未开通用车功能，开通请咨询在线客服')
                    }
                });
                return
            }
            this.$router.push({
                name: val.path,
            });
        },
        toAddEvection() {
            if (!this.audit) {
                Toast('暂未开通办公功能，不能添加行程');
                return
            }
            this.$router.push({
                name: 'h5-add-evection',
            })
        },
        getNotice() {
            const params = {
                currentPage: 1,
                pageSize: 1,
            };
            consumer_sys_inform_list(params).then((res) => {
                this.systemMsgList = res.pageResult.pageData
            });
            consumer_apply_approveNumber().then((res) =>{
              this.badgeNum = res.datas.approvedNumber
            });
        },
        toNoticeDetail(item) {
            this.$router.push({
                name: 'h5-sys-message-detail',
                query: {
                    id: item.id
                },
            });
        },
        toTypeServe(val) {
            if (val.businessType === 1) {
                this.$router.push({
                    name: 'flight-order-detail',
                    query: {
                        orderNo: val.orderNo
                    }
                })
            }
            if (val.businessType === 2) {
                this.$router.push({
                    name: 'h5-train-order-detail',
                    query: {
                        orderNo: val.orderNo
                    }
                })
            }
            if (val.businessType === 3) {
                this.$router.push({
                    name: 'h5-hotel-order-detail',
                    query: {
                        orderNo: val.orderNo
                    }
                })
            }
            if (val.businessType === 4) {

            }
        },
        returnDayType(val) {
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(val).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == todayEnd) {
                text = `今天`;
            }

            if (tomorrow == todayEnd) {
                text = `明天`;
            }

            if (afterTomorrow == todayEnd) {
                text = `后天`;
            }
            return text
        },
        // 出差申请按钮状态
        async getApplyButton() {
            try {
                const res = await applyButton();
                this.jurisdiction = res.datas.jurisdiction;
            } catch (error) {

            }
        },
        closeAd() {
            this.adShow = false;
        }
    }
}
