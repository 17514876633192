export default {
    path: '/registration/registration',
    name: 'registration-registration',
    component: () => import(/* webpackChunkName: "page-registration-registration" */ `./index.vue`),
    meta: {
        h5_layout: {
            current_page_name: '注册',
            need_head_nav: false,
            need_left_arrow: false,
            need_footer_bar: false
        }
    },
};
