const get_feature_list = function () {
    const list = [
        {
            component_name: `blank-selector`,
            component_title: `银行选择组件`,
            route_name: `haolv-feature-blank-selector`,
        },
        {
            component_name: `domestic-ticket-airline-selector`,
            component_title: `国内机票航空公司选择组件`,
            route_name: `haolv-feature-domestic-ticket-airline-selector`,
        },
        {
            component_name: `domestic-ticket-airport-selector`,
            component_title: `国内机票机场选择组件`,
            route_name: `haolv-feature-domestic-ticket-airport-selector`,
        },
        {
            component_name: `hotel-city-selector`,
            component_title: `酒店城市选择组件`,
            route_name: `haolv-feature-hotel-city-selector`,
        },
        {
            component_name: `payment-method-selector`,
            component_title: `支付组件`,
            route_name: `haolv-feature-payment-method-selector`,
        },
        {
            component_name: `train-city-selector`,
            component_title: `火车票车站选择组件`,
            route_name: `haolv-feature-train-city-selector`,
        },
        {
            component_name: `travel-application-city-selector`,
            component_title: `出差申请城市选择组件`,
            route_name: `haolv-feature-travel-application-city-selector`,
        },
    ];
    return list;
};
export default get_feature_list;
