import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_car_order_carOrderDetails
  from '@/lib/data-service/haolv-default/consumer_car_order_carOrderDetails'
import moment from 'moment'
//根据订单号获取跳转订单详情链接接口
import consumer_car_link_getGaodeLinkByOrderNo from "@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkByOrderNo";
import open from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window";
// 彩色icon组件
import ColorIcon from "@/component/color-icon/index.vue";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Notify} from "vant";

export default {
  components: {
    ColorIcon,
    NavBar,
  },
  data() {
    return {
      budgetPopup: false,
      this_: this,
      detailInfo: {
        orderInfoResult:{},
        orderCostResult:{},
        orderJourneyResult:{},
      },
      informationToggle: false,
      costToggle: false,
    }
  },
  computed: {
    orderNo() {
      return this.$route.query.orderNo;
    },
  },
  filters: {

  },
  activated() {
    this.init();
  },
  mounted() {

  },
  methods: {
    clickCostPopup() {
        this.budgetPopup = true;
    },
    _setBudgetItemTypeName(val) {
      // 科目类型 1-管理 2-理赔 3-销售
      let text = '';
      switch (val) {
        case 1:
          text = '管理';
          break;
        case 2:
          text = '理赔';
          break;
        case 3:
          text = '销售';
          break;
        default:
          text = '';
      }
      return text;
    },
    //换算时间
    getTimeText(data){
      let dataText = null;
      if (data != null) {
        dataText = this.$moment(data).format('HH:mm')
      }
      return dataText
    },
    getHeightDiv(data) {
      let dataBox = null;
      let dataBoxId = null;
      let dataHeight = null;
      eval(`dataBox = this.$refs.${data}`);
      eval(`dataBoxId = document.querySelector('#${data}')`);
      let setTime = setInterval(() => {
        if (dataBox.children[0].clientHeight != 0) {
          dataHeight = dataBox.children[0].clientHeight + 5;
          dataBoxId.style.height = `${dataHeight}px`;
          clearInterval(setTime);
        }
      }, 100);
    },
    // 初始化
    init() {
      consumer_car_order_carOrderDetails({
        orderNo: this.orderNo
      }).then((res) => {
        this.detailInfo = res.datas;
      });
    },
    //支付
    to_payment(orderNo) {
      window.init = this.init;

      consumer_car_link_getGaodeLinkByOrderNo({orderNo}).then(res => {
        let url = res.datas.url;
        open({
          url,
          title: '用车订单详情',
          parent_window_name: `main`,
          wait_seconds_to_exec_script: 2,
          btn_back_click_event_handler: () => {
            window.init()
          }
        })
      });
    },
  },
  created() {

  },
}