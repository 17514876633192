//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    title: {
      type: String,
      default: '标题',
    },
    isShowTip: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: true,
      maxHeight: '',
    };
  },
  mounted() {
    const overflowEle = this.$refs.overflow;
    const childEle = overflowEle.children[0];
    this.maxHeight = childEle.offsetHeight;
  },
  methods: {
    onToggle() {
      this.show = !this.show;
    },
  },
}
