import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
      {
        path: '/h5/buy-insurance',
        name: 'h5-buy-insurance',
        component: () => import(/* webpackChunkName: "page-evection-insurance-buy-insurance" */ `./index.vue`),
        meta: {
          h5_layout: {
            current_page_name: '购买保险',
            need_head_nav: false,
            need_footer_bar: true,
            secondary_menu: 1,
          }
        },
      }
    ],
  };
