import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/add-reimbursement',
      name: 'h5-add-reimbursement',
      component: () => import(/* webpackChunkName: "page-office-add-reimbursement" */ `./index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '添加报销单',
          need_head_nav: true,
          need_footer_bar: false,
          secondary_menu: 2,
        }
      },
    }
  ],
};
