// 我的行程列表
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：

const consumer_evection_orderStatusList = (pParameter) => {
  if (!pParameter) pParameter = {};
  const p = {
    method: 'post',
    urlSuffix: 'consumer/evection/orderStatusList',
    data: pParameter,
  };
  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_evection_orderStatusList;