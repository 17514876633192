import import_latest_version_module from "@/component/h5-layout/import_latest_version_module";

export default {
    path: "",
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/information',
            name: 'h5-information',
            component: () => import(/* webpackChunkName: "page-personal-center-information" */ `./index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: "个人信息",
                    need_alive: false,
                    need_head_nav: true,
                    need_footer_bar: false,
                    secondary_menu: 4,
                },
            },
        },
    ],
};
