import Q from "q";
import _ from 'underscore';
import {Toast} from "vant";
import consumer_user_logout from "yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_user_logout";
import wangyiServerOnline from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index";
import check_token_processor from '@/router/init_router/lib/check_token_processor';
import * as dd from "dingtalk-jsapi";
import app_loading from "@/lib/common-service/app-loading/1.0.1";

export default {
    data() {
        return {
            size: "0B",
            show: false,
            clear_cache_loading: false,
            actions: ["确定退出当前账号？"],
            version: '',
        };
    },
    created() {
        this.getCacheSize();
        const api = global.api;
        if (api) {
            this.version = api.appVersion;
        }
    },
    methods: {
        // 关于我们
        toAbout() {
            this.$router.push({
                name: "h5-about",
            });
        },
        // 用户服务协议
        toServer() {
            this.$router.push({
                name: "service",
            });
        },
        //隐私协议条款
        toPrivacyAgreement() {
            this.$router.push({
                name: "privacy",
            });
        },
        getCacheSize() {
            // 获取缓存大小
            const __this = this;
            const api = global.api;
            if (!api) return;
            if (!_.isFunction(api.getCacheSize)) return; // apicloud 清理缓存
            api.getCacheSize(function (ret) {
                let fileSizeString = "0B";
                if (ret.size === 0) {
                    fileSizeString = "0B";
                } else if (ret.size < 1024) {
                    fileSizeString = ret.size + "B";
                } else if (ret.size < 1048576) {
                    fileSizeString = (ret.size / 1024).toFixed(2) + "KB";
                } else if (ret.size < 1073741824) {
                    fileSizeString = (ret.size / 1048576).toFixed(2) + "MB";
                } else {
                    fileSizeString = (ret.size / 1073741824).toFixed(2) + "GB";
                }
                __this.size = fileSizeString;
            });
        },
        clear_cache() {
            const __this = this;
            __this.clear_cache_loading = true;
            if (!api.clearCache) return; // apicloud 清理缓存
            api.clearCache(function (data) {
                __this.clear_cache_loading = false;
                __this.getCacheSize();
                Toast("清理缓存成功！");
            });
        },
        update() {
            if (!api) return; // apicloud 清理缓存
            const systemType = api.systemType;
            if (systemType === 'ios') {
                api.openApp({
                    iosUrl: 'https://download.wholeok.com/download/ios'
                });

            } else {
                api.openApp({
                    androidPkg: 'android.intent.action.VIEW',
                    mimeType: 'text/html',
                    uri: 'https://download.wholeok.com/download/android-new'
                }, function(ret, err) {

                });
            }
        },
        // 修改密码
        toModifyPwd() {
            this.$router.push({
                name: "h5-modify-password",
            });
        },

        click_logout() {
            this.$Dialog.confirm({
                title: "",
                message: "确认退出当前账号？",
                confirmButtonColor: '#FF9C00',
                cancelButtonColor: '#999999',
            })
                .then(() => {
                    check_token_processor.logout();
                    this.logout();
                })
                .catch(() => {
                    // on cancel
                });
        },

        logout() {
            const __this = this;
            __this.$showLoading.show();
            Q.when()
                .then(function () {
                    return consumer_user_logout();
                })
                .then(function (res) {
                    __this.$showLoading.hide();
                    if (res.code === "000000") {

                        Toast({
                            message: res.msg,
                            onClose:  () => {
                                wangyiServerOnline.hide()
                                if (dd.env.platform !== "notInDingTalk") {
                                    dd.getStorage({
                                        key: 'corpId',
                                        success: (res) => {
                                            __this.$router.replace({
                                                name: `dd-login`,
                                                query: {
                                                    corpId: res,
                                                }
                                            });
                                        },
                                        fail: () => {},
                                        complete: () => {},
                                    });
                                    return;
                                }
                                __this.$router.replace({
                                    name: `login`,
                                });
                            },
                        });
                    } else {
                        Toast(res.msg);
                    }
                })
                .catch(function (ex) {
                    __this.$showLoading.hide();
                    console.log(ex);
                    console.trace();
                    if (ex) {
                        if (ex.stack) {
                            console.log(ex.stack);
                        }
                    }
                    return Q.reject(ex);
                });
        },
    },
};
