const browser_open_url = (url) => {
  api.openApp(
    {
      iosUrl: url,
      androidPkg: "android.intent.action.VIEW",
      uri: url,
    },
    function(ret, err) {}
  );
};

export default browser_open_url;
