// consumer_department_getDepartmentUsers 部门员工列表
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-dept-controller/getDepartmentUsersUsingPOST
const consumer_department_getDepartmentUsers = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/department/getDepartmentUsers',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_department_getDepartmentUsers;