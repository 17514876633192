import NavBar from "@/component/nav-bar/import_latest_version_module";
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import ColorIcon from "@/component/color-icon/index.vue";
import CostAttributionList from '@/page/travel-book/flight/component/costAttributionList/import_latest_version_module'
import AvailableTravellerSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/available-traveller-selector/1.1.0/index.vue'
import TravelerMultipleSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-multiple-selector/1.2.5";
import consumer_journey_payCenterDetails from '@/lib/data-service/haolv-default/consumer_journey_payCenterDetails'
import consumer_flight_order_flightsStop from '@/lib/data-service/haolv-default/consumer_flight_order_flightsStop'
import consumer_journey_orderTotal from '@/lib/data-service/haolv-default/consumer_journey_orderTotal'
//import consumer_trains_order_delOrder from '@/lib/data-service/haolv-default/consumer_trains_order_delOrder' // 删除火车订单
//import consumer_flight_order_deleteOrder from '@/lib/data-service/haolv-default/consumer_flight_order_deleteOrder' // 删除机票订单
// import consumer_tOdHotelOrderInterim_del from '@/lib/data-service/haolv-default/consumer_tOdHotelOrderInterim_del' // 删除酒店订单
import hotel_order from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_cancelOrder";//酒店取消接口
import trains_order from "@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel";//火车取消接口
import air_order from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";//飞机取消接口
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg'
import consumer_journey_pendingTrip from '@/lib/data-service/haolv-default/consumer_journey_pendingTrip'
import consumer_evection_addOrUpdate from "@/lib/data-service/haolv-default/consumer_evection_addOrUpdate"; // 新增或修改出差单
import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.0/index'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import { awaitWrap } from "@/page/travel-book/flight/common/unit";
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import moment from 'moment'
import {Toast, Dialog} from "vant";

export default {
    data() {
        return {
            title: '预订中心',
            evectionDetailType: '',   // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            reason: '',
            evectionNo: '',
            evectionType: '',
            evectionRequired: '',
            unLimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            workTravelType: '',
            evectionInfo: {},
            evectionParams: {},
            journeyList: [],
            flightList: [],
            flightOrderList: [],
            hotelList: [],
            hotelOrderList: [],
            trainList: [],
            trainOrderList: [],
            priceInfo: {},
            applyId: '',

            temp: '',

            feeAffiliationShow: false,

            orderDetailShow: true,
            detailShow: false,
            hasJourneyNoDialog: false,
            notHasJourneyNoDialog: false,

            menuList: [
                {
                    businessType: 1,
                    text: "添加机票",
                    img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/06/home-flight-icon.svg',
                    show: true,
                },
                {
                    businessType: 3,
                    text: "添加酒店",
                    img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/20/home-hotel-icon.svg',
                    show: true,
                },
                {
                    businessType: 2,
                    text: "添加火车票",
                    img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/20/home-train-icon.svg',
                    show: true,
                },
                {
                    businessType: 4,
                    text: "添加用车",
                    img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/20/home-car-icon.svg',
                    show: false,
                },
            ],

            selectedIdList: [],
            newJourneyItem: {},
            otherJourneyItem: {},

            overProofDialog: false,

            isLoading: false,
        }
    },
    components: {NavBar, OrangeNavBar, ColorIcon, CostAttributionList, AvailableTravellerSelector, TravelerMultipleSelector},
    created() {
    },
    mounted() {
        const _this = this;
        let evectionParams = {
            vm: this
        };
        const evectionQuery = getEvectionAllType(evectionParams);
        console.log('evectionQuery', evectionQuery);
        this.evectionDetailType = evectionQuery.evectionDetailType;
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                _this.$refs['reserveBottom'].style.paddingBottom = `${api.safeArea.bottom + 8}px`;
                _this.$refs['reserveDetailBottom'].style.paddingBottom = `${api.safeArea.bottom + 64}px`;
            }
        });

        _this.$refs.aAvailableTravellerSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log(`btn_ok_click_event_handler`);
            console.log(args);
            let params = {
                journeyId: this.newJourneyItem.journeyId,
                applyId: this.applyId,
                endDate: this.newJourneyItem.returnTime,
                evectionNo: this.evectionNo,
                evectionType: 2, // 1是个人出差，2是出差单出差
                startDate: this.newJourneyItem.tripDate,
                travellerList: this.selectedIdList,
                personType: 2,
                cabin: '',
            };
            consumer_evection_addOrUpdate(params).then(res => {
                this.goNewJourney()
            })
        };

        _this.$refs.aTravelerMultipleSelector.init_parameter.btn_ok_click_event_handler = (args) => {
            console.log(`btn_ok_click_event_handler`);
            console.log(args);
            let params = {
                journeyId: this.newJourneyItem.journeyId,
                applyId: this.applyId,
                endDate: this.newJourneyItem.returnTime,
                evectionNo: this.evectionNo,
                evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                startDate: this.newJourneyItem.tripDate,
                travellerList: this.selectedIdList,
                personType: 2,
                cabin: '',
            };
            consumer_evection_addOrUpdate(params).then(res => {
                this.goNewOtherJourney()
            })
        };
    },
    activated() {
        this.init()

        keybackProcessor.addEventListener(() => {
            if (this.detailShow) {
                this.detailShow = false
                return
            }
            if (this.hasJourneyNoDialog) {
                this.hasJourneyNoDialog = false
                return;
            }
            if (this.notHasJourneyNoDialog) {
                this.notHasJourneyNoDialog = false
                return;
            }
            if (this.feeAffiliationShow) {
                this.feeAffiliationShow = false
                return;
            }
            const aAvailableTravellerSelector = this.$refs.aAvailableTravellerSelector
            if (aAvailableTravellerSelector.get_show()) {
                aAvailableTravellerSelector.hide()
                return;
            }
            const aTravelerMultipleSelector = this.$refs.aTravelerMultipleSelector
            if (aTravelerMultipleSelector.get_show()) {
                aTravelerMultipleSelector.hide()
                return;
            }

            this.back()
        })
    },
    deactivated() {
        clearInterval(this.temp)
        keybackProcessor.removeEventListener();
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterIntervalTime(val) {
            if (val) {
                let m = moment(val).format('mm分ss秒');
                return m
            } else {
                return '00分00秒'
            }
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        onRefresh() {
            this.isLoading = false;
            this.init();
        },
        handleShowOrderDetail() {
            this.orderDetailShow = !this.orderDetailShow
        },
        showDetail() {
            this.detailShow = !this.detailShow
        },
        closeHasJourneyNoDialog() {
            this.hasJourneyNoDialog = false
        },
        closeNotHasJourneyNoDialog() {
            this.notHasJourneyNoDialog = false
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get()
            this.evectionRequired = res.datas.evectionRequired
            //this.bookUpperBound = res.datas.bookUpperBound
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime
            this.unLimitedJourney = res.datas.unLimitedJourney;
            //this.changeEvectionCity = res.datas.changeEvectionCity
        },
        async init() {
            this.evectionNo = this.$route.query.evectionNo;
            this.evectionType = parseInt(this.$route.query.evectionType);
            //this.evectionRequired = this.$route.query.evectionRequired === undefined ? 0 : parseInt(this.$route.query.evectionRequired);
            this.workTravelType = this.$route.query.workTravelType === undefined ? '' : parseInt(this.$route.query.workTravelType);
            //this.applyId = this.$route.query.applyId === undefined ? '' : this.$route.query.applyId;
            await this.getDetail();
            this.pendingTrip();
            this.getAllPrice();
            this.refundChangePolicyGet()

            console.log('this.workTravelType', this.workTravelType)
        },
        async getDetail() {
            const params = {
                evectionNo: this.evectionNo
            };
            this.loading = true;
            let [err, res] = await awaitWrap(consumer_journey_payCenterDetails(params));
            if (err) {
                this.loading = false;
                return
            }
            this.loading = false;
            this.evectionInfo = res.datas;
            let feeAffiliationId = res.datas.centerId;
            let feeAffiliationName = res.datas.centerName;
            let feeAffiliationType = res.datas.feeAffiliationType ? res.datas.feeAffiliationType : 1;
            let reason = res.datas.evectionReason;
            if (!feeAffiliationName && this.evectionType === 2) {
                feeAffiliationId = -1;
                feeAffiliationName = '各自所在部门';
                feeAffiliationType = 1
            }
            if (!reason && this.evectionType === 2) {
                reason = '商务出行'
            }
            this.evectionParams = {
                evectionNo: this.evectionNo,
                feeAffiliationType: feeAffiliationType,
                feeAffiliationName: feeAffiliationName,
                feeAffiliationId: feeAffiliationId,
                reason: reason,
                travellerName: res.datas.travellerName
            };
            //let journeys = res.datas.tripList
            //this.journeyList = journeys
            let hasOverproofOrder = false;
            let flightOrderList = res.datas.flightDetail && res.datas.flightDetail.orderList ? res.datas.flightDetail.orderList : [];
            let flightOverproofList = res.datas.flightDetail && res.datas.flightDetail.overproofList ? res.datas.flightDetail.overproofList : [];
            if (flightOverproofList.length > 0) {
                hasOverproofOrder = true
            }
            let flightList = [...flightOrderList, ...flightOverproofList];
            let fOrderList = [];
            flightOrderList.forEach(value => {
                fOrderList.push(value.orderNo)
            });
            flightList.forEach(value => {
                if (value.milliseconds && value.milliseconds > 0) {
                    value.millisecondsNum = parseInt(value.milliseconds) * 1000
                } else {
                    value.millisecondsNum = null
                }
            });
            this.flightOrderList = fOrderList;
            this.flightList = flightList;

            let hotelUnPaidHotelList = res.datas.hotelDetail && res.datas.hotelDetail.unPaidHotelList ? res.datas.hotelDetail.unPaidHotelList : [];
            let hotelOverproofApproveHotelList = res.datas.hotelDetail && res.datas.hotelDetail.overproofApproveHotelList ? res.datas.hotelDetail.overproofApproveHotelList : [];
            if (hotelOverproofApproveHotelList.length > 0) {
                hasOverproofOrder = true
            }
            let hOrderList = [];
            let hotelList = [...hotelUnPaidHotelList, ...hotelOverproofApproveHotelList];
            hotelUnPaidHotelList.forEach(value => {
                hOrderList.push(value.orderNo)
            });
            hotelList.forEach(value => {
                if (value.milliseconds && value.milliseconds > 0) {
                    value.millisecondsNum = parseInt(value.milliseconds) * 1000
                } else {
                    value.millisecondsNum = null
                }
            });
            this.hotelOrderList = hOrderList;
            this.hotelList = hotelList;

            let payOrderList = res.datas.trainDetail && res.datas.trainDetail.payOrderList ? res.datas.trainDetail.payOrderList : []; // 获取可用火车票行程
            let noPayOrderList = res.datas.trainDetail && res.datas.trainDetail.noPayOrderList ? res.datas.trainDetail.noPayOrderList : []; // 获取不可用火车票行程
            if (noPayOrderList.length > 0) {
                hasOverproofOrder = true
            }
            let tOrderList = [];
            let trainList = [...payOrderList, ...noPayOrderList];
            payOrderList.forEach(value => {
                tOrderList.push(value.orderNo)
            });
            trainList.forEach(value => {
                if (value.milliseconds && value.milliseconds > 0) {
                    value.millisecondsNum = parseInt(value.milliseconds) * 1000
                } else {
                    value.millisecondsNum = null
                }
            });
            this.trainOrderList = tOrderList;
            this.trainList = trainList;
            clearInterval(this.temp);
            this.timer();
            if (hasOverproofOrder) {
                this.overProofDialog = true;

                Dialog.alert({
                    title: '提示',
                    message: '当前存在超标需审批订单，需要在订单时效内审批通过后才能发起支付，超时未处理订单将自动取消。',
                    confirmButtonText: '知道了',
                    confirmButtonColor: '#1D8CFF'
                }).then(() => {
                    // on close
                });
            }
        },
        timer() {
            //主要逻辑都在这页面更新
            this.temp = setInterval(() => {
                let isReact = false;
                let flightList = [...this.flightList];
                flightList.forEach((item, index) => {
                    if (item.millisecondsNum) {
                        item.millisecondsNum = item.millisecondsNum - 1000
                        // this.$set(item, 'surplusSec', surplusSec);
                    } else if (item.millisecondsNum === null) {

                    } else {
                        isReact = true;
                        item.millisecondsNum = null
                    }
                });
                this.flightList = flightList;
                let hotelList = [...this.hotelList];
                hotelList.forEach((item, index) => {
                    if (item.millisecondsNum) {
                        item.millisecondsNum = item.millisecondsNum - 1000
                        // this.$set(item, 'surplusSec', surplusSec);
                    } else if (item.millisecondsNum === null) {

                    }  else {
                        isReact = true;
                        item.millisecondsNum = null
                    }
                });
                this.hotelList = hotelList;
                let trainList = [...this.trainList];
                trainList.forEach((item, index) => {
                    if (item.millisecondsNum) {
                        item.millisecondsNum = item.millisecondsNum - 1000
                        // this.$set(item, 'surplusSec', surplusSec);
                    } else if (item.millisecondsNum === null) {

                    }  else {
                        isReact = true;
                        item.millisecondsNum = null
                    }
                });
                this.trainList = trainList;
                if (isReact) {
                    this.init()
                }
            }, 1000);
        },
        getAllPrice() {
            let orderNos = [...this.flightOrderList, ...this.hotelOrderList, ...this.trainOrderList];
            const params = {
                orderNos
            };
            consumer_journey_orderTotal(params).then(res => {
                let priceInfo = res.datas;
                let i = 1;
                if (priceInfo && priceInfo.flightAmountsList) {
                    priceInfo.flightAmountsList.forEach(value => {
                        value.index = i;
                        i++
                    })
                }
                if (priceInfo && priceInfo.trainTotalAmountList) {
                    priceInfo.trainTotalAmountList.forEach(value => {
                        value.index = i;
                        i++
                    })
                }
                if (priceInfo && priceInfo.hotelTotalAmountList) {
                    priceInfo.hotelTotalAmountList.forEach(value => {
                        value.index = i;
                        i++
                    })
                }
                this.priceInfo = priceInfo
            })
        },
        pendingTrip() {
            let params = {
                evectionNo: this.evectionNo
            };
            consumer_journey_pendingTrip(params).then(res => {
                this.journeyList = res.datas.list || []
            })
        },
        toNewJourney(item) {
            this.closeJourneyDialog();
            // 弹出出行人弹窗
            this.newJourneyItem = item;
            this.selectedIdList = [];
            if (item.travellerIds) {
                let selectedIdList = item.travellerIds.split(',');
                let lastSelectedIdList = [];
                selectedIdList.forEach(value => {
                    lastSelectedIdList.push(parseInt(value))
                });
                this.selectedIdList = lastSelectedIdList
            }
            const journeyId = item.journeyId;
            this.$refs.aAvailableTravellerSelector.init({
                get_params() {
                    const p = {
                        applyJourneyId: journeyId,
                    };
                    return p;
                },
            });
            this.$refs.aAvailableTravellerSelector.show()

        },
        goNewJourney() {
            if (this.newJourneyItem.businessType === 1) {
                this.$router.push({
                    name: 'flight-search',
                    query: {
                        redirectType: '003',
                        startDate: this.newJourneyItem.tripDate,
                        endDate: this.newJourneyItem.returnTime || '',
                        fromStationCode: this.newJourneyItem.departCityId,
                        fromStationName: this.newJourneyItem.departCityName,
                        toStationCode: this.newJourneyItem.toCityId,
                        toStationName: this.newJourneyItem.toCityName,
                        flightType: 1,
                        journeyId: this.newJourneyItem.journeyId,
                        applyId: this.applyId,
                        startRangeDate: this.newJourneyItem.departTime,
                        endRangeDate: this.newJourneyItem.departTimeEnd,
                        evectionRequired: this.evectionRequired,
                        journeyType: this.newJourneyItem.journeyType,
                        workTravelType: 1,
                        evectionNo: this.evectionNo,
                        evectionType: 2,
                    }
                })
            } else if (this.newJourneyItem.businessType === 3) {
                // 酒店
                this.$router.push({
                    name: 'h5-hotel-search',
                    query: {
                        redirectType: '003',
                        cityId: this.newJourneyItem.toCityId,
                        cityName: this.newJourneyItem.toCityName,
                        checkInDate: this.newJourneyItem.tripDate,
                        checkOutDate: this.newJourneyItem.returnTime,
                        keyword: '',
                        hotelStar: '',
                        journeyId: this.newJourneyItem.journeyId,
                        evectionStartDate: this.newJourneyItem.departTime,
                        evectionEndDate: this.newJourneyItem.departTimeEnd,
                        evectionRequired: this.evectionRequired,
                        allowChangeJourneyTime: this.allowChangeJourneyTime,
                        workTravelType: 1,
                        journeyType: this.newJourneyItem.journeyType,
                        applyId: '',
                        pt: '',
                        filterType: 0,
                        evectionNo: this.evectionNo,
                        evectionType: 2,

                    }
                })
            } else {
                this.$router.push({
                    name: 'train-search',
                    query: {
                        redirectType: '003',
                        trainDate: this.newJourneyItem.tripDate,
                        fromStationCode: this.newJourneyItem.departCityId,
                        fromStationName: this.newJourneyItem.departCityName,
                        toStationCode: this.newJourneyItem.toCityId,
                        toStationName: this.newJourneyItem.toCityName,
                        onlySearch: 0,
                        journeyType: this.newJourneyItem.journeyType,
                        journeyId: this.newJourneyItem.journeyId,
                        minDateText: this.newJourneyItem.departTime,
                        maxDateText: this.newJourneyItem.departTimeEnd,
                        checked: false,
                        activeChooseUserList: '',
                        allowChangeJourneyTime: this.allowChangeJourneyTime,
                        unlimitedJourney: this.unlimitedJourney,
                        evectionRequired: this.evectionRequired,
                        workTravelType: 1,
                        applyId: '',
                        evectionNo: this.evectionNo,
                        evectionType: 2,
                    }
                })
            }
        },
        containerToOrder() {
            if (this.workTravelType === 1) {
                // 有出差单
                let hasJourney = false
                if (this.journeyList && this.journeyList.length > 0) {
                    this.journeyList.forEach(value => {
                        if (value.travellerIds && value.travellerIds !== '') {
                            hasJourney = true
                        }
                    })
                }
                if (!hasJourney) {
                    return
                }
                this.hasJourneyNoDialog = true
            } else {
                this.notHasJourneyNoDialog = true
            }
        },
        closeJourneyDialog() {
            this.hasJourneyNoDialog = false;
            this.notHasJourneyNoDialog = false
        },
        toOrderNewJourney(menu) {
            this.closeJourneyDialog();
            if (menu.businessType === 4) {
                this.$router.push({
                    name: 'h5-car-home'
                });
                return
            }
            this.otherJourneyItem = menu;
            this.$refs.aTravelerMultipleSelector.init({
                situation: '100',
                businessType: menu.businessType,
                select_mode: menu.businessType === 2 ? `single` : `multiple`,
                dialog: {
                    title: '请选择出行人',
                    width: '1000px'
                },
            });
            this.$refs.aTravelerMultipleSelector.show()
            // if (menu.businessType === 1) {
            //     this.$router.push({
            //         name: 'h5-flight-home'
            //     })
            // }
            // if (menu.businessType === 2) {
            //     this.$router.push({
            //         name: 'h5-train-home'
            //     })
            // }
            // if (menu.businessType === 3) {
            //     this.$router.push({
            //         name: 'h5-hotel-home'
            //     })
            // }
        },
        goNewOtherJourney() {
            if (this.otherJourneyItem.businessType === 1) {
                this.toSearchFlight()
            }
            if (this.otherJourneyItem.businessType === 2) {
                this.toSearchTrain()
            }
            if (this.otherJourneyItem.businessType === 3) {
                this.toSearchHotel()
            }
        },
        // 新增机票
        async toSearchFlight() {
            // let continueToFlight = await this.saveInfo()
            // if (!continueToFlight) {
            //     return
            // }
            let fromStationCode = 'CAN';
            let fromStationName = '广州';
            let toStationCode = 'BJS';
            let toStationName = '北京';
            let startDate = moment().format('YYYY-MM-DD');
            let endDate = '';
            this.flightList.forEach(value => {
                fromStationCode = value.segmentVO[0].depCityCode;
                fromStationName = value.segmentVO[0].depCityName;
                toStationCode = value.segmentVO[0].arrCityCode;
                toStationName = value.segmentVO[0].arrCityName;
                startDate = value.segmentVO[0].depDate
            });
            this.$router.push({
                name: 'flight-search',
                query: {
                    fromStationCode: fromStationCode,
                    fromStationName: fromStationName,
                    toStationCode: toStationCode,
                    toStationName: toStationName,
                    startDate: startDate,
                    endDate: endDate,
                    flightType: 1,
                    redirectType: '003',
                    evectionRequired: this.evectionRequired,
                    workTravelType: 2,
                    applyId: '',
                    startRangeDate: '',
                    endRangeDate: '',
                    journeyType: 0,
                    journeyId: 0,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType
                }
            })
        },
        // 新增酒店
        async toSearchHotel() {
            // let continueToHotel = await this.saveInfo()
            // if (!continueToHotel) {
            //     return
            // }
            let cityId = '70011';
            let cityName = '广州';
            let checkInDate = this.$moment().locale('zh-cn').format('YYYY-MM-DD');
            let checkOutDate = this.$moment().add(1,'days').locale('zh-cn').format('YYYY-MM-DD');
            let hotelName = '';
            this.hotelList.forEach(value => {
                cityId = value.cityId;
                cityName = value.cityName;
                checkInDate = value.checkInDate;
                checkOutDate = value.checkOutDate;
                hotelName = value.hotelName
            });
            this.$router.push({
                name: 'h5-hotel-search',
                query: {
                    cityId: cityId,
                    cityName: cityName,
                    checkInDate: checkInDate,
                    checkOutDate: checkOutDate,
                    keyword: hotelName,
                    hotelStar: '',
                    redirectType: '001',
                    evectionRequired: this.evectionRequired,
                    allowChangeJourneyTime: this.allowChangeJourneyTime,
                    workTravelType: 2,
                    journeyType: 0,
                    journeyId: '',
                    applyId: '',
                    pt: '',
                    filterType: 0,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType
                }
            })
        },
        // 新增火车票
        async toSearchTrain() {
            // fromStationCode=GZQ&
            // fromStationName=广州&
            // toStationCode=BJP&
            // toStationName=北京&
            // trainDate=2021-12-16&
            // evectionNo=13903581192500262&
            // redirectType=000&
            // evectionType=2&
            // checked=false&
            // evectionRequired=0&
            // workTravelType=2&
            // applyId=&
            // maxDateText=&
            // minDateText=
            // let continueToTrain = await this.saveInfo()
            // if (!continueToTrain) {
            //     return
            // }
            // 个人预定
            let fromStationCode = 'GZQ';
            let toStationCode = 'BJP';
            let fromStationName = '广州';
            let toStationName = '北京';
            let startDate = this.$moment().format('YYYY-MM-DD');
            if (this.trainList.length > 0) {
                this.trainList.forEach(value => {
                    fromStationCode = value.fromStationCode;
                    fromStationName = value.fromStationName;
                    toStationCode = value.toStationCode;
                    toStationName = value.toStationName;
                    value.startDate
                })
            }
            this.$router.push({
                name: 'train-search',
                query: {
                    fromStationCode: fromStationCode,
                    fromStationName: fromStationName,
                    toStationCode: toStationCode,
                    toStationName: toStationName,
                    trainDate: startDate,
                    redirectType: '003',
                    checked: '',
                    evectionRequired: this.evectionRequired,
                    workTravelType: 2,
                    applyId: '',
                    maxDateText: '',
                    minDateText: '',
                    journeyId: 0,
                    journeyType: 0,
                    activeChooseUserList: [],
                    allowChangeJourneyTime: this.allowChangeJourneyTime,
                    unlimitedJourney: this.unlimitedJourney,
                    evectionNo: this.evectionNo,
                    evectionType: this.evectionType
                }
            })
        },
        checkForm() {
            return new Promise(resolve => {
                this.$refs['reviewForm'].validate().then(() => {
                    resolve(true)
                }).catch(() => {
                    resolve(false)
                })
            })
        },
        async toPay() {
            let orderNos = [];
            orderNos = [...this.flightOrderList, ...this.trainOrderList, ...this.hotelOrderList];
            if (orderNos.length === 0) {
                Toast.fail('没有可以支付的订单');
                return
            }
            Toast.loading({
                message: '',
                forbidClick: true,
                //duration: 0,
            });
            let valid = await this.checkForm();
            if (!valid) {
                Toast.clear();
                return
            }
            let saveResult = await this.saveInfo();
            if (!saveResult) {
                Toast.clear();
                return
            }
            const params = {
                orderNo: orderNos.join(','),
                evectionNo: this.evectionNo,
                flightOrderList: this.flightOrderList.length > 0 ? this.flightOrderList.join(',') : '',
                orderType: '4', // 去首页
            };
            Toast.clear();
            this.$router.push({
                name: 'h5-travel-payment',
                query: params
            })
        },
        // 保存信息
        async saveInfo() {
            let params = Object.assign({}, this.evectionParams);
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params));
            if (err) {
                return false
            }
            return res
        },
        // 删除机票订单
        delFlightOrder(segment, segmentIndex, flight, fIndex) {
            Dialog.confirm({
                title: '提示',
                message: '此操作将删除该行程, 是否继续?',
            }).then(() => {
                Toast.loading({
                    //duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中...",
                });
                air_order({orderNo: flight.orderNo}).then(res => {
                    Toast.clear();
                    Toast.success('取消成功');
                    this.init()
                }, error => {
                    Toast.clear()
                }).catch(() => {
                    Toast.clear()
                })
            }).catch(() => {
                    // on cancel
            });
        },
        // 删除火车票订单
        delTrainOrder(val, index) {
            Dialog.confirm({
                title: '提示',
                message: '此操作将删除该行程, 是否继续?',
            }).then(() => {
                Toast.loading({
                    //duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中...",
                });
                trains_order({orderNo: val.orderNo}).then(res => {
                    Toast.clear();
                    Toast.success('取消成功');
                    this.init()
                }, error => {
                    Toast.clear()
                }).catch(() => {
                    Toast.clear()
                })
            }).catch(() => {
                // on cancel
            });
        },
        // 删除酒店订单
        delHotelOrder(val, index) {
            Dialog.confirm({
                title: '提示',
                message: '此操作将删除该行程, 是否继续?',
            }).then(() => {
                Toast.loading({
                    //duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中...",
                });
                hotel_order({orderNo: val.orderNo}).then(res => {
                    Toast.clear();
                    Toast.success('取消成功');
                    this.init()
                }, error => {
                    Toast.clear()
                }).catch(() => {
                    Toast.clear()
                })
            }).catch(() => {
                // on cancel
            });
        },
        getFeeAffiliation() {
            console.log(this.evectionType, this.workTravelType);
            if (this.evectionType === 2 && this.workTravelType === 1) {
                return
            }
            this.feeAffiliationShow = true
        },
        back() {
            Dialog.confirm({
                message: '您的行程预订尚未完成，确认离开当前页面？',
                confirmButtonText: '继续预订',
                cancelButtonText: '离开',
                confirmButtonColor: '#ff7002',
                className: 'reserve-back-warning-dialog',
            })
                .then(() => {
                    // on confirm
                })
                .catch(() => {
                    // on cancel
                    this.$router.replace({
                        name: 'h5-home'
                    })
                });

        },
        setDisabled() {
            let hasJourney = false
            if (this.journeyList && this.journeyList.length > 0) {
                this.journeyList.forEach(value => {
                    if (value.travellerIds && value.travellerIds !== '') {
                        hasJourney = true
                    }
                })
            }
            if (this.workTravelType === 1 && !hasJourney) {
                return 'disabled'
            } else {
                return ''
            }
        },
    }
}
