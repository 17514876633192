import consumer_car_link_getGaodeLinkForPickUp from '@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkForPickUp'
//根据订单号获取跳转订单详情链接接口
import consumer_car_link_getGaodeLinkByOrderNo from "@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkByOrderNo";
import consumer_web_company_checkCompanyUseCar from '@/lib/data-service/haolv-default/consumer_web_company_checkCompanyUseCar'
import get_user_info from '@/lib/common-service/get_user_info';
import {getCarUserShow} from "@/lib/ohter";

import open from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window";
import ColorIcon from "@/component/color-icon/index.vue";
import app_loading from "@/lib/common-service/app-loading";
import moment from "moment";
import {Dialog, Toast} from "vant";
import cache_set from "nllib/src/cache/set";

export default {
    components: {ColorIcon},
    props: {
      dataList: {
        type: Object,
        default() {
          return {};
        },
      },
    },
    data() {
        return {
            info: null,
            getCarUserShowBox: null,
        };
    },
    watch: {

    },
    methods: {
        clickBoardingGates() {
          Dialog.alert({
            title: '温馨提示',
            message: '如有变更，请以现场公告为准',
            confirmButtonText: '我知道了',
            confirmButtonColor: '#FF7002',
          }).then(() => {

          })
        },
        init(data){
            this.getCarUserShowBox = data.getCarUserShowBox ? data.getCarUserShowBox : false;
        },
        getCarUserShowFunction(){
            get_user_info().then(res => {
                this.info = res.datas;
                this.getCarUserShowBox = getCarUserShow(this.info.companyId,this.info.id);
            }).catch((err) => {

            });
        },
        // 点击跳转
        toTypeServe(val) {
            if (val.businessType === 1) {
                if (val.bizType == 1) {
                    // 机票
                    this.$router.push({
                        name: 'flight-order-detail',
                        query: {
                            orderNo: val.orderNo,
                            jumpType: 'home',
                            evectionType: val.evectionType === '出差申请' ? 2 : 1
                        }
                    })
                } else if (val.bizType == 2) {
                    // 机票
                    this.$router.push({
                        name: 'flight-change-order-detail',
                        query: {
                            orderNo: val.orderNo,
                            jumpType: 'home',
                            psgNoList: val.psgNo,
                            evectionType: val.evectionType === '出差申请' ? 2 : 1
                        }
                    })
                } else if (val.bizType == 3) {
                    this.$router.push({
                        name: 'flight-refund-order-detail',
                        query: {
                            orderNo: val.orderNo,
                            jumpType: 'home',
                            evectionType: val.evectionType === '出差申请' ? 2 : 1
                        }
                    })
                }

            }
            if (val.businessType === 2) {
                // 火车票
                if (val.bizType == 1) {
                    this.$router.push({
                        name: 'h5-train-order-detail',
                        query: {
                            orderNo: val.orderNo,
                            jumpType: 'home'
                        }
                    })
                } else if (val.bizType == 2) {
                    this.$router.push({
                        name: 'h5-train-change-order-detail',
                        query: {
                            orderNo: val.orderNo,
                            jumpType: 'home',
                            status: val.orderStatus,
                        }
                    })
                } else if (val.bizType == 3) {
                    this.$router.push({
                        name: 'h5-train-refund-form-detail',
                        query: {
                            refundNo: val.orderNo,
                            jumpType: 'home',
                        }
                    })
                }
            }
            if (val.businessType === 3) {
                // 酒店
                if (val.bizType == 1) {
                    this.$router.push({
                        name: 'h5-hotel-order-detail',
                        query: {
                            orderNo: val.orderNo,
                            jumpType: 'home'
                        }
                    })
                } else if (val.bizType == 3) {
                    this.$router.push({
                        name: 'h5-hotel-refund-order-detail',
                        query: {
                            orderNo: val.orderNo,
                            jumpType: 'home'
                        }
                    })
                }

            }
            if (val.businessType === 4) {
                // 用车
                this.clickCarDetail();
            }
        },
        clickTrainPhone(val){
            let businessType = val;
            consumer_web_company_checkCompanyUseCar().then(res => {
                const useCar = res.datas.useCar;
                if (useCar === 1) {
                    consumer_car_link_getGaodeLinkForPickUp({businessType}).then(res => {
                        let url = res.datas.url;
                        open({
                            url,
                            title: '用车订单详情',
                            btn_back_click_event_handler: () => {
                                this.$emit('getJourneyList');
                                this.$parent.activatedFunction();
                            }
                        })
                    });
                } else {
                    Toast('企业暂未开通用车功能，开通请咨询在线客服')
                }
            });
        },
        clickCarDetail(value){
            consumer_car_link_getGaodeLinkByOrderNo({orderNo:value.orderNo}).then(res => {
                let url = res.datas.url;
                open({
                    url,
                    title: '用车订单详情',
                    btn_back_click_event_handler: () => {
                        this.$emit('getJourneyList');
                        this.$parent.activatedFunction();
                    }
                })
            })
        },
        colorJourneyStatusTextCar(type){
            let color = '#FF7002';
            switch (type) {
                case 0:
                    color = '#FF7002';
                    break;
                case 1:
                    color = '#FF7002';
                    break;
                case 2:
                    color = '#FF7002';
                    break;
                case 3:
                    color = '#FF7002';
                    break;
                case 4:
                    color = '#FF7002';
                    break;
                case 5:
                    color = '#FF7002';
                    break;
                case 6:
                    color = '#FF7002';
                    break;
                case 7:
                    color = '#FF7002';
                    break;
            }
            return color
        },
        // 今天、明天
        DateDiff(date){
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(date).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == todayEnd) {
                text = `今天 ${moment(date).format(' HH:mm')}`;
            }

            if (tomorrow == todayEnd) {
                text = `明天 ${moment(date).format(' HH:mm')}`;
            }

            if (afterTomorrow == todayEnd) {
                text = `后天 ${moment(date).format(' HH:mm')}`;
            }
            if (text == '') {
                text = moment(date).format(' HH:mm');
            }
            return text
        },
        clickCarPhone(Phone){
            // open({
            //   url: 'tel:' + Phone,
            // })
        },
        clickMap(val){
            let hotelName = val ? val.hotelName : '';
            let longitude = val ? val.hotelLongitude : '';
            let latitude = val ? val.hotelLatitude : '';

            if (hotelName && longitude && latitude) {
                this.$router.push({
                    name: "h5-hotel-map",
                    query: {
                        hotelName,
                        longitude,
                        latitude,
                    }
                })
            }
        },
    },
    created() {},
    mounted() {},
    activated() {
    },
    deactivated() {

    },
};
