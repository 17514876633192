import _ from 'underscore';
import moment from "moment";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import VueVirtualScrollList from 'vue-virtual-scroll-list'
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import currentLocation from '@/lib/common-service/current_location_helper/index.js' //在app中获取用户当前位置
// 酒店列表
import consumer_hotel_search_hotelList from "@/lib/data-service/haolv-default/consumer_hotel_search_hotelList.js";
//酒店推荐列表
import consumer_hotel_search_hotelListRecommendV2 from "@/lib/data-service/haolv-default/consumer_hotel_search_hotelListRecommendV2.js";
// 最低价
import consumer_hotel_search_getHotelMinPrice
    from "@/lib/data-service/haolv-default/consumer_hotel_search_getHotelMinPrice.js";
import consumer_hotel_search_checkHotelPolicy from "@/lib/data-service/haolv-default/consumer_hotel_search_checkHotelPolicy";//校验酒店差标
// 品牌列表
import consumer_hotel_search_brandList from "@/lib/data-service/haolv-default/consumer_hotel_search_brandList.js";
import DropdownMenu from './components/DropdownMenu.vue';
import SearchHead from './components/SearchHead.vue';
import ProductItem from './components/ProductItem.vue';
import getJourneyOptionsCanBeModified
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
import consumer_web_policyConfig_hotelTravelPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_hotelTravelPolicy'
import go_back_processor from 'yinzhilv-js-sdk/frontend/common-project/mixin/yinzhilv/go_back_processor/1.1.1'; //返回方法
import getEvectionAllType
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.1.1' //获取出差类型
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'

import consumer_journey_getEvectionDetailV2 from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetailV2';
import TravelPopup from '@/component/travel-popup/1.0.2/index.vue'
// 差标弹窗组件
import HotelPolicyPopup from '@/component/hotel-policy-popup/1.0.0/index.vue';

import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
import {hidePolicyLabelCompanyIdList} from "@/lib/ohter/index.js";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
import cache_get from 'nllib/src/cache/get'
import cache_set from 'nllib/src/cache/set'
import cache_remove from 'nllib/src/cache/remove'

export default {
    components: {
        SearchHead,
        DropdownMenu,
        TravelPopup,
        VueVirtualScrollList,
        HotelPolicyPopup
    },
    mixins: [
        go_back_processor
    ],
    data() {
        return {
            isKeepAlive: false,
            loading: true,//是否加载中
            cityNotValid: false,//当前城市是否可用于搜索酒店
            cityNotValidMsg: '',//当前城市不可搜索酒店的提示语
            companyId: 0,
            searchInfo: {},//cache保存的搜索条件
            isShowToTopBtn: false,
            isShowStandard: true,
            hidePolicyLabelCompanyIdList: hidePolicyLabelCompanyIdList,
            travellerList: [],//入住人对象数组
            travellerIdList: [],//入住人userId数组
            userIdsWhetherShared: [],//合住人userId数组

            evectionRequired: 0,//是否必选出差单

            aVueVirtualScrollList: {
                data_key: `hotelId`,
                data_sources: [],
                data_component: ProductItem,
                estimate_size: 173,
                extra_props: {},
                loading: false,
            },
            bVueVirtualScrollList: {
                data_key: `hotelId`,
                data_sources: [],
                data_component: ProductItem,
                estimate_size: 173,
                extra_props: {},
                loading: false,
            },
            hot_data: {
                title: ``,
                list: [],

                wait_seconds: 0.5,
            },

            hotelThemeList: [],
            hotelStarList: [
                // {
                //     code: '',
                //     msg: '不限',
                // },
                {
                    code: '2',
                    msg: '≤2',
                    name: '经济'
                },
                {
                    code: '3',
                    msg: '3',
                    name: '舒适'
                },
                {
                    code: '4',
                    msg: '4',
                    name: '高档'
                },
                {
                    code: '5',
                    msg: '5',
                    name: '豪华'
                },
            ],
            hotelBrandList: [],
            landmark: '',//已选的地标类型搜索条件的名称字符串，eg.位置区域名称或者地标关键字名称

            productLoading: false,//酒店列表获取中
            productFinished: false,//全部酒店已获取完成
            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            unlimitedJourney: 0,
            allowChangeJourneyTime: 0,

            evectionType: 2,//为了适应getJourneyOptionsCanBeModified
            workTravelType: 1,//为了适应getJourneyOptionsCanBeModified
            journeyType: 0,//为了适应getJourneyOptionsCanBeModified

            query: {
                filterType: '',
                pt: '',
                ptUser: '',
                pt_from_type:`000`,//000:来自上一个页面；100:来自keyword；200:来自area
            },
            keyword: '',//当前keyword的文字

            total: 0,//总数
            productList: [],//酒店列表
            recommendProductList: [],//推荐酒店列表
            districtName: '',//已选的区域位置的名字

            cityCanEdit: true,//是否可修改入住城市
            timeCanEdit: true,//是否可修改入住时间

            isFirstLoad: true,//是否第一次加载

            evectionDetail: null, // 出差单详情

            netErr: false, // 网络是否异常
        };
    },
    async beforeRouteEnter(to, from, next) {
        //第一个参数to，包含的内容是切换后的路由对象，也就是跳转后的路由对象
        //第二个参数from，包含的内容的是切换前的路由对象，也就是跳转前的路由对象
        //第三个参数next()，是否往下执行，执行的话，如果不写的话路由就不会跳转，操作将会终止
        if (to.name === 'h5-hotel-search' && from.name === 'h5-hotel-detail') {
            // 1是正常刷新， 2是从酒店详情页返回，要超过5分钟才刷新
            cache_set({
                key: 'hotelSearchReactType',
                value: '2'
            })
        } else {
            cache_set({
                key: 'hotelSearchReactType',
                value: '1'
            })
        }
        next();
    },
    created() {
        this.isFirstLoad = true;
        let {
            checkInDate,
            checkOutDate
        } = this.$route.query;
        this.query.checkInDate = checkInDate;
        this.query.checkOutDate = checkOutDate
    },
    mounted() {
        const __this = this;

        fixStatusBar({
            type: `200`,
            element: __this.$refs.navBar.$el,
        });
        fixStatusBar({
            handler({api}) {
              if (__this.$refs.list) {
                __this.$refs.list.$el.style.paddingBottom = api.safeArea.bottom + 'px';
              }
            }
        });

    },
    async activated() {
        const outTime = cache_get({key: 'hotelSearchOutTime'});
        let minute = moment().diff(outTime, 'minute');
        let hotelSearchReactType = cache_get({key: 'hotelSearchReactType'});
        if (hotelSearchReactType === '2' && minute <= 5) {
            this.searchInfo = JSON.parse(cache_get({key: 'hotelSearchInfo'}));
            const evectionNo = this.$route.query.evectionNo;
            if (evectionNo !== this.searchInfo.evectionNo|| this.query.checkInDate !== this.searchInfo.checkInDate || this.query.checkOutDate !== this.searchInfo.checkOutDate) {
                await this.activatedFun()
            } else {
                this.isKeepAlive = true;
                this.productFinished = this.searchInfo.productFinished;
                this.keyword = this.searchInfo.keyword;
                await this.notReload();
                if (this.$route.query.isUpdateAccord === 'true') {
                    await this.updateAccord();
                }
            }
        } else {
            await this.activatedFun()
        }
        this.setSearchReact();
    },
    deactivated() {
        this.isFirstLoad = true;

        keyback_processor.removeEventListener();
        window.removeEventListener('scroll', this.handleScroll, true);

        const outTime = moment().format('YYYY-MM-DD HH:mm:ss');
        cache_set({
            key: 'hotelSearchOutTime',
            value: outTime
        })
        cache_set({
            key: 'hotelProductList',
            value: JSON.stringify(this.productList)
        });
        cache_set({
            key: 'hotelRecommendProductList',
            value:  JSON.stringify(this.recommendProductList)
        })

        let scrollTop = document.getElementById('list').scrollTop;
        if (this.recommendProductList.length > 0) {
            scrollTop = document.getElementById('mainList').scrollTop;
        }
        let params = {
            evectionNo: this.query.evectionNo || '',
            checkInDate: this.query.checkInDate,
            checkOutDate: this.query.checkOutDate,
            currentPage: this.query.currentPage,
            productFinished: this.productFinished,
            keyword: this.keyword,
            scrollTop: scrollTop,
        }
        cache_set({
            key: 'hotelSearchInfo',
            value: JSON.stringify(params)
        })
    },
    watch:{
        //列表加载中
        productLoading(val){
            this.$nextTick(() => {
                this.styleChange(val)
            })
        },
        //是否有推荐列表，改变样式
        recommendProductList(newVal) {
            this.$nextTick(() => {
                setTimeout(() => {
                    if (newVal && newVal.length === 0) {
                        this.$refs.mainList.style.overflowY = 'hidden';
                        document.getElementById('list').style.height = '100%';
                    } else {
                        this.$refs.mainList.style.overflowY = 'scroll';
                        if (document.getElementById('list')) {
                            document.getElementById('list').style.height = 'auto';
                        }
                    }
                }, 200)
            })
        }
    },
    methods: {
        async notReload() {
            this.loading = false;
            this.isFirstLoad = true;
            this.productLoading = true;

            let idList = [];

            const productList = cache_get({key: 'hotelProductList'});
            if (productList) {
                this.productList = JSON.parse(productList);
                this.aVueVirtualScrollList.data_sources = this.productList;
                this.aVueVirtualScrollList.extra_props.parent_this = this;

                idList = [];
                this.productList.forEach(item => {
                    if(item.minPrice === undefined) {
                        idList.push(item.hotelId)
                    }
                })
                if(idList.length > 0) {
                    idList.forEach( hotelId => {
                        this.appendMinPrice(this.query.cityId, hotelId).then(resData => {
                            this.productList = this.productList.map(item => {
                                let minPrice = 0;
                                let accord = 0;
                                for (let i = 0; i < resData.length; i++) {
                                    if (resData[i].hotelId == item.hotelId) {
                                        minPrice = resData[i].minPrice;
                                        accord = resData[i].accord;
                                        this.$set(item, 'minPrice', minPrice);
                                        this.$set(item, 'accord', accord);
                                        item.hotelParams = {
                                            cityId: this.query.cityId,
                                            evectionNo: this.query.evectionNo,
                                            price: item.minPrice
                                        }
                                        break;
                                    }
                                }
                                return item;
                            });
                        });
                    });
                }
                setTimeout(() => {
                    document.getElementById('list').scrollTop = this.searchInfo.scrollTop;
                }, 200)
            }

            const recommendProductList = cache_get({key: 'hotelRecommendProductList'});
            if (recommendProductList) {
                this.recommendProductList = JSON.parse(recommendProductList);
                this.bVueVirtualScrollList.data_sources = this.recommendProductList;
                this.bVueVirtualScrollList.extra_props.parent_this = this;

                idList = [];
                this.recommendProductList.forEach(item => {
                    if(item.minPrice === undefined) {
                        idList.push(item.hotelId)
                    }
                })
                if(idList.length > 0) {
                    idList.forEach( hotelId => {
                        this.appendMinPrice(this.query.cityId, hotelId).then(resData => {
                            this.recommendProductList = this.recommendProductList.map(item => {
                                let minPrice = 0;
                                let accord = 0;
                                for (let i = 0; i < resData.length; i++) {
                                    if (resData[i].hotelId == item.hotelId) {
                                        minPrice = resData[i].minPrice;
                                        accord = resData[i].accord;
                                        this.$set(item, 'minPrice', minPrice);
                                        this.$set(item, 'accord', accord);
                                        item.hotelParams = {
                                            cityId: this.query.cityId,
                                            evectionNo: this.query.evectionNo,
                                            price: item.minPrice
                                        }
                                        break;
                                    }
                                }
                                return item;
                            });
                        });
                    });
                }
                if (document.getElementById('recommendList')) {
                    setTimeout(() => {
                        document.getElementById('mainList').scrollTop = this.searchInfo.scrollTop;
                    }, 200)
                }
            }

            setTimeout(() => {
                this.isFirstLoad = false;
                this.productLoading = false;
            }, 1000)

            await this.refundChangePolicyGet();
            this.isFirstLoad = true;
            // this.productFinished = false;
            this.init();
            this.keyback();
            this.canChangeFactor();
            this.getCurLocation();
            if (this.evectionType === 2) {
                this.getEvectionDetail();// 获取出差单详情
            }

            this.$refs['dropdown-menu'].setValue(this.query);
            this.$refs['dropdown-menu'].getHotelBrandList();
            window.addEventListener('scroll', this.handleScroll, true);
        },

        async activatedFun() {
            await this.refundChangePolicyGet();
            this.isFirstLoad = true;
            this.productFinished = false;
            this.init();
            this.keyback();
            this.canChangeFactor();
            this.getCurLocation();
            if (this.evectionType === 2) {
                this.getEvectionDetail();// 获取出差单详情
            }

            this.productLoading = false;

            this.$refs['dropdown-menu'].setValue(this.query);
            this.$refs['dropdown-menu'].getHotelBrandList();
            window.addEventListener('scroll', this.handleScroll, true);

            await this.onLoad();
            if (this.productList && this.productList.length === 1) {
                await this.getRecommendList();
            }
        },

        //初始化
        init() {
            this.getUserInfo();
            this.initQuery();
            this.initEnumeration();
            this.getEvectionType();
        },

        //获取用户信息
        async getUserInfo() {
            let res = await get_user_info();
            this.companyId = res.datas.companyId;
            this.isShowStandard = _.indexOf(hidePolicyLabelCompanyIdList,this.companyId) > -1? false : true;
        },

        //获取当前定位
        async getCurLocation() {
            this.query.ptUser = '';
            if (currentLocation.check_is_current_city(this.query.cityId)) {
                let locationObj = await currentLocation.get_current_location();
                this.query.ptUser = locationObj.locationStr;
                if (currentLocation.locationStr === '') {
                    this.$Toast({message: '获取当前位置失败，请稍后再试!'});
                }
            }
        },

        //初始化地址栏传过来的数据
        initQuery() {
            const {
                evectionNo,
                evectionRequired,
                checkInDate,
                checkOutDate,
                evectionStartDate,
                evectionEndDate,
                cityId,
                cityName,
                cityCn,
                isPOIName,
                applyId,
                journeyId,
                journeyType,
                evectionType,
                workTravelType,
                allowChangeJourneyTime,
                filterType,
                keyword,
                pt,
                sort,
                d,
                areaId,
                areaType,
                areaName,
                lineCode,
                stationValue,
                minPrice,
                maxPrice,
                hotelStar,
                hotelBrand,
                differenceEnable,
                agreementEnable,
                shareJourneyId,
                shareEvectionNo,
            } = this.$route.query;
            let query = {
                evectionNo,
                evectionRequired,
                evectionType: Number(evectionType),
                workTravelType: Number(workTravelType),
                allowChangeJourneyTime,
                checkInDate,
                checkOutDate,
                evectionStartDate,
                evectionEndDate,
                cityId,
                cityName: cityName,
                cityCn,
                isPOIName: (isPOIName == 'true' || isPOIName == true) ? true : false,
                applyId,
                journeyId,
                journeyType,
                currentPage: this.isKeepAlive ? this.searchInfo.currentPage : 1,
                hotelTheme: '',
                keyword,
                countryType: 1,
                pageSize: 10,
                productType: 3,
                salesSort: '',
                pt,
                filterType,
                ptUser: '',//用户当前位置坐标
                pt_from_type:`000`,//000:来自上一个页面；100:来自keyword；200:来自area

                sort: sort ? parseInt(sort) : 0,
                d: d ? d : '',
                areaType: areaType ? areaType: '001',
                areaId: areaId ? areaId : '',
                areaName: areaName ? areaName : '',
                lineCode: lineCode ? lineCode: '',
                stationValue: stationValue ? stationValue: '',
                minPrice: minPrice ? minPrice : '',
                maxPrice: maxPrice ? maxPrice : '',
                hotelStar: hotelStar ? hotelStar : '',
                hotelBrand: hotelBrand ? hotelBrand : '',
                differenceEnable: differenceEnable ? parseInt(differenceEnable) : 0,//是否符合差标
                agreementEnable: agreementEnable ? parseInt(agreementEnable) : 0,//是否是协议酒店

                shareJourneyId,
                shareEvectionNo,
            };

            this.query = query;
            this.isFirstLoad = true;
            this.evectionType = parseInt(evectionType);
            this.workTravelType = parseInt(workTravelType);
            this.journeyType = parseInt(journeyType);
            this.allowChangeJourneyTime = parseInt(allowChangeJourneyTime);
            this.evectionRequired = parseInt(evectionRequired);

            this.keyword = keyword;
            if (Number(filterType) === 1) {
                this.query.keyword = '';
                this.landmark = keyword;
            } else {
                this.landmark = areaName;
            }

            // search-head组件里面的initData在create生命周期过早调用，但是父页面的query在activated才获取，所以获取完再调一次子组件的初始化方法initData
            this.$nextTick(()=>{
                this.$refs['search-head'].initData();
            })
        },

        //获取品牌枚举值
        initEnumeration() {
            this.$showLoading.show();
            Promise.all([
                consumer_hotel_search_brandList()
            ])
                .then((data) => {
                    this.hotelBrandList = data[0].pageResult.pageData;
                })
                .finally(() => {
                    this.$showLoading.hide();
                    this.loading = false;
                });
        },

        //获取出差类型
        getEvectionType() {
            let evectionParams = {
                vm: this,
                situation: '003'
            };
            getEvectionAllType(evectionParams).then((evectionQuery) => {
                this.evectionDetailType = evectionQuery.evectionDetailType;
            })
        },

        //获取是否可修改城市/日期
        canChangeFactor() {
            getJourneyOptionsCanBeModified({
                situation: '200',
                vm: this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit;
                this.timeCanEdit = res.timeCanEdit
            })
        },

        //从左到右划返回上一页
        onClickLeft() {
            this.$router.replace({
                name: 'h5-hotel-home',
                query: {
                    evectionNo: this.query.evectionNo,
                    evectionType: this.query.evectionType,
                    journeyId: this.query.journeyId,
                    checkInDate: this.query.checkInDate,
                    checkOutDate: this.query.checkOutDate,
                    keyword: this.keyword,
                    filterType: this.query.filterType,
                    pt: Number(this.query.filterType) ? this.query.pt : '',
                    shareJourneyId: parseInt(this.query.shareJourneyId || 0),
                    shareEvectionNo: this.query.shareEvectionNo,
                }
            })
        },

        //获取酒店最低价
        async appendMinPrice(cityId, hotelId) {
            const query = {
                evectionNo: this.query.evectionNo,
                checkInDate: this.query.checkInDate,
                checkOutDate: this.query.checkOutDate,
                cityId: cityId,
                hotelIds: [hotelId],
                applyJourneyId: this.query.journeyId ? this.query.journeyId : '',
            };
            const res = await consumer_hotel_search_getHotelMinPrice(query);
            const resData = res.minPriceResults;
            return resData;
        },

        //加载列表数据
        async onLoad() {
            //由于app端加载过多会造成闪退，所以暂时限制最大加载数量为100
            if(this.productList.length >= 100){
                this.productLoading = false;
                this.productFinished = true;
                return;
            }
            // this.$showLoading.show();
            this.productLoading = true;
            await this.getCurLocation();
            this.netErr = false;
            let [err, res] = await awaitWrap(consumer_hotel_search_hotelList(this.query));
            if (err) {
                //HOTEL_MAX_MIN_PRICE("200041","查询异常,所选的价格区间与差标区间不符合"),
                //HOTEL_CITY_FORBID("200042","禁止城市预定"),
                //CHECK_IN_DATE_CHECK("200043","入住日选择有误");
                if (err.data.code === '200042') {
                    //"禁止城市预定"
                    this.cityNotValid = true;
                    this.cityNotValidMsg = err.data.msg;
                } else if(err.data.code === '200043') {
                    //"入住日选择有误"，弹出提示
                    this.$Toast(err.data.msg);
                }else if (err.data.code !== '200041') {
                    //除以上报错以外的报错
                    //除“系统繁忙”以外，都需要弹出提示
                    if(!err.data.msg.includes('系统繁忙')) {
                        this.$Toast(err.data.msg);
                    }
                    this.netErr = true;
                } else {
                    //"查询异常,所选的价格区间与差标区间不符合"，弹出提示
                    this.$Toast(err.data.msg);
                }
                this.productLoading = false;
                this.productList = [];
                this.recommendProductList = [];
                this.productFinished = true;
                // this.$showLoading.hide();
                setTimeout(() => {
                    this.isFirstLoad = false;
                }, 1000)
                return;
            }
            let data = res.dataMap;
            this.total = data.total;
            data.rows.forEach(item => {
                item.minPrice = undefined;
            });
            let nextPage = data.nextPage;
            if (this.query.currentPage === 1) {
                this.productList = data.rows;
            } else {
                this.productList = this.productList.concat(data.rows);
            }

            data.rows.forEach(item => {
                item.hotelParams = {
                    cityId: this.query.cityId,
                    evectionNo: this.query.evectionNo,
                    price: item.minPrice
                }
            })

            if (this.query.currentPage === 1) {
                this.aVueVirtualScrollList.data_sources = data.rows;
                this.$nextTick(() => {
                    if (document.querySelector('.product-list')) {
                        document.querySelector('.product-list').scrollTop = 0;
                    }
                })
            } else {
                this.aVueVirtualScrollList.data_sources = this.aVueVirtualScrollList.data_sources.concat(data.rows)
            }
            this.aVueVirtualScrollList.extra_props.parent_this = this;

            if(this.productList) {
                this.productList.forEach(item => {
                    this.$set(item, 'isAgreementPrice', false)
                })
            }
            if (nextPage === '1') {
                this.query.currentPage += 1;
            } else {
                this.productFinished = true;
            }
            const cityId = this.query.cityId;
            const hotelIds = data.rows.map(item => item.hotelId);
            hotelIds.forEach( hotelId => {
                this.appendMinPrice(cityId, hotelId).then(resData => {
                    this.productList = this.productList.map(item => {
                        let minPrice = 0;
                        let accord = 0;
                        for (let i = 0; i < resData.length; i++) {
                            if (resData[i].hotelId == item.hotelId) {
                                minPrice = resData[i].minPrice;
                                accord = resData[i].accord;
                                this.$set(item, 'minPrice', minPrice);
                                this.$set(item, 'accord', accord);
                                item.hotelParams = {
                                    cityId: this.query.cityId,
                                    evectionNo: this.query.evectionNo,
                                    price: item.minPrice
                                }
                                break;
                            }
                        }
                        return item;
                    });
                });
            });
            this.productLoading = false;
            setTimeout(() => {
                if (this.productList.length < 10 && !this.productFinished && nextPage === '1') {
                    this.query.currentPage++;
                    this.onLoad();
                } else {
                    this.$showLoading.hide();
                }
            }, 500);
            setTimeout(() => {
                this.isFirstLoad = false;
            }, 1000)
        },

        //获取酒店推荐列表
        async getRecommendList() {
            // this.$showLoading.show();
            let [err, res] = await awaitWrap(consumer_hotel_search_hotelListRecommendV2(this.query));
            if (err) {
                this.recommendProductList = [];
                this.$showLoading.hide();
                return;
            }
            let data = res.dataMap;
            this.recommendProductList = data.rows;
            this.bVueVirtualScrollList.data_sources = data.rows;
            this.bVueVirtualScrollList.extra_props.parent_this = this;
            if (this.recommendProductList && this.recommendProductList.length >0) {
                this.recommendProductList.forEach(item => {
                    this.$set(item, 'isAgreementPrice', false);
                    this.$set(item, 'distanceHotel', item.distance)
                })
            }
            const cityId = this.query.cityId;
            const hotelIds = data.rows.map(item => item.hotelId);
            hotelIds.forEach( hotelId => {
                this.appendMinPrice(cityId, hotelId).then(resData => {
                    this.recommendProductList = this.recommendProductList.map(item => {
                        let minPrice = 0;
                        let accord = 0;
                        for (let i = 0; i < resData.length; i++) {
                            if (resData[i].hotelId == item.hotelId) {
                                minPrice = resData[i].minPrice;
                                accord = resData[i].accord;
                                this.$set(item, 'minPrice', minPrice);
                                this.$set(item, 'accord', accord);
                                item.hotelParams = {
                                    cityId: this.query.cityId,
                                    evectionNo: this.query.evectionNo,
                                    price: item.minPrice
                                }
                                break;
                            }
                        }
                        return item;
                    });
                });
            });
            // this.$showLoading.hide();
        },

        //因私-更新地址栏数据
        updateUrlForPrivate() {
            let {
                cityId,
                cityName,
                cityCn,
                isPOIName,
                checkInDate,
                checkOutDate,
                evectionType,
                pt,
                filterType,

                d,
                areaId,
                areaType,
                areaName,
                lineCode,
                stationValue,
                minPrice,
                maxPrice,
                hotelStar,
                hotelBrand,
                differenceEnable,
                agreementEnable,
            } = this.query;

            let urlParams = {
                cityId,
                cityName,
                cityCn,
                isPOIName,
                checkInDate,
                checkOutDate,
                keyword: this.keyword,
                evectionType,
                pt,
                filterType,

                d,
                areaId,
                areaType,
                areaName,
                lineCode,
                stationValue,
                minPrice,
                maxPrice,
                hotelStar,
                hotelBrand,
                differenceEnable,
                agreementEnable,
            };

            // 组装当前页面url
            let href = location.href.split('?')[0];
            let newUrl = href + '?';
            for(let i in urlParams) {
                newUrl += `${i}=${urlParams[i]}&`
            }
            history.replaceState(null, null, newUrl)
        },

        //因公-更新地址栏数据
        updateUrlForOfficial() {
            let {
                applyId,
                cityId,
                cityName,
                cityCn,
                isPOIName,
                checkInDate,
                checkOutDate,
                evectionStartDate,
                evectionEndDate,
                evectionNo,
                evectionType,
                evectionRequired,
                workTravelType,
                allowChangeJourneyTime,
                journeyId,
                journeyType,
                pt,
                filterType,

                d,
                areaId,
                areaType,
                areaName,
                lineCode,
                stationValue,
                minPrice,
                maxPrice,
                hotelStar,
                hotelBrand,
                differenceEnable,
                agreementEnable,
            } = this.query;

            let urlParams = {
                applyId: applyId,
                evectionStartDate: evectionStartDate,
                evectionEndDate: evectionEndDate,
                checkInDate: checkInDate,
                checkOutDate: checkOutDate,
                cityId: cityId,
                cityName: cityName,
                cityCn: cityCn,
                isPOIName,
                evectionNo: evectionNo,
                evectionRequired: evectionRequired,
                evectionType: evectionType,
                journeyType: journeyType,
                journeyId: journeyId,
                workTravelType: this.$route.query.workTravelType,
                allowChangeJourneyTime: allowChangeJourneyTime,
                filterType: filterType,
                pt: pt,
                keyword: this.keyword,

                d,
                areaId,
                areaType,
                areaName,
                lineCode,
                stationValue,
                minPrice,
                maxPrice,
                hotelStar,
                hotelBrand,
                differenceEnable,
                agreementEnable,
            };

            // 组装当前页面url
            let href = location.href.split('?')[0];
            let newUrl = href + '?';
            for(let i in urlParams) {
                newUrl += `${i}=${urlParams[i]}&`
            }
            history.replaceState(null, null, newUrl)
        },

        //改变搜索条件
        async onChange(value) {
            // 判断是否有更新
            let hasDifference = false;
            switch (value.type) {
                case "cityOrDate":
                    if (this.query.checkInDate !== value.checkInDate || this.query.checkOutDate !== value.checkOutDate ||
                        this.query.cityId !== value.cityId || this.query.isPOIName !== value.isPOIName||
                        this.query.pt !== value.pt || this.query.keyword !== value.keyword
                    ) {
                        hasDifference = true;
                        this.query.isPOIName = value.isPOIName;
                    }
                    break;
                case "recommend":
                    if (this.query.sort !== value.sort) {
                        hasDifference = true;
                    }
                    break
                case "area":
                    if (value.areaId !== this.query.areaId || value.areaName !== this.query.areaName || value.areaType !== this.query.areaType || value.d !== this.query.d || value.pt !== this.query.pt) {
                        hasDifference = true;
                    }
                    break
                case "starMenu":
                    if (value.hotelStar !== this.query.hotelStar || value.minPrice !== this.query.minPrice || value.maxPrice !== this.query.maxPrice) {
                        hasDifference = true;
                    }
                    break
                case "brandMenu":
                    if (value.hotelBrand !== this.query.hotelBrand) {
                        hasDifference = true;
                    }
                    break
                case "popular":
                    hasDifference = true;
                    break
            }
            if (!hasDifference) {
                return
            }

            //转换城市时，需要将已选的位置区域值清空和已选地标关键字清空
            if (value.cityId && Number(value.cityId) !== Number(this.query.cityId)) {
                this.query.pt = '';
                this.query.d = '';
                this.query.keyword = '';
                this.query.areaId = '';
                this.query.areaType = '';
                this.query.areaName = '';
                this.query.lineCode = '';
                this.query.stationValue = '';
                //不用手动清空，在下面对用于显示的关键字keyword重新赋值就行
                // this.$refs['search-head'].clearKeyword();
                this.$refs['dropdown-menu'].setValue(this.query)
            }

            //this.keyword:用于页面显示关键字；this.query.keyword:用于搜索的关键字字符串
            //获取用于显示的关键字
            this.keyword = value.keyword || value.keyword === '' ? value.keyword : this.keyword;
            //对用于显示的关键字keyword重新赋值
            this.$refs['search-head'].setKeyword(this.keyword);

            let oldPt = this.query.pt ? JSON.parse(JSON.stringify(this.query.pt)) : null;
            let oldFilterType = this.query.filterType ? JSON.parse(JSON.stringify(this.query.filterType)) : null;
            let oldPtFromType = this.query.pt_from_type ? JSON.parse(JSON.stringify(this.query.pt_from_type)) : null;

            //将改变值赋给搜索条件
            this.query = Object.assign({}, this.query, value, {
                currentPage: 1,
            });

            //当前一个pt来自关键字keyword，并且这次来自区域位置组件的areaName为空
            //则需要沿用上一个来自关键字keyword的pt值
            if(((oldPtFromType === '000' && Number(oldFilterType) === 1) || oldPtFromType === '100') && value.pt_from_type === '200' && value.areaName === '') {
                this.query.pt = oldPt;
            }

            //当前一个pt来自区域位置，并且这次来自关键字组件的keyword为非地标类型
            //则需要沿用上一个来自区域位置的pt值
            if (oldPtFromType === '200' && value.pt_from_type === '100' && Number(value.filterType) === 0) {
                this.query.pt = oldPt;
            }

            /***
            如果已选的关键字是地标类型，则
             1.离我半径距离d需清空 --- 2022-08-02修改为区域位置和d可同时选中，无需相互清空。
             2.搜索条件中的keyword需要传''
             3.已选的位置区域值清空
             4.landmark为此时的关键字
            ***/
            if (Number(value.filterType) === 1) {
                // 1.离我半径距离d需清空
                // this.query.d = '';
                // this.$refs['dropdown-menu'].clearSelectedDistance();

                // 2.搜索条件中的keyword需要传''
                this.query.keyword = '';

                // 3.已选的位置区域值清空
                this.query.areaId = '';
                this.query.areaName = '';
                this.$refs['dropdown-menu'].clearSelectedArea();

                //4.landmark为此时的关键字
                this.landmark = value.keyword;
            }

            /***
             *2022-08-02修改为区域位置和d可同时选中，无需相互清空。以下作废。
            如果选了离我半径距离d，则
             1.已选的位置区域值清空
             2.已选的坐标类型的关键字需清空
            ***/
            // if (value.d) {
                // 1.已选的位置区域值清空
                // this.query.areaId = '';
                // this.query.areaName = '';
                // this.$refs['dropdown-menu'].clearSelectedArea();

                // 2.已选的坐标类型的关键字需清空
                // if (Number(this.query.filterType) === 1) {
                //     this.keyword = '';
                //     this.query.pt = '';
                //     this.query.keyword = '';
                //     this.$refs['search-head'].clearKeyword();
                // }
            // }

            /***
            如果位置区域，则
             1.离我半径距离d清空 --- 2022-08-02修改为区域位置和d可同时选中，无需相互清空。
             2.已选的坐标类型的关键字需清空
             3.landmark为此时的位置区域名称
            ***/
            if (value.areaName) {
                // 1.离我半径距离d需清空
                // this.query.d = '';
                // this.$refs['dropdown-menu'].clearSelectedDistance();

                // 2.已选的坐标类型的关键字需清空
                if (Number(oldFilterType) === 1) {
                    this.keyword = '';
                    // this.query.pt = '';//此时pt已是已选区域位置的pt，所以不需要清空
                    this.query.keyword = '';
                    this.$refs['search-head'].clearKeyword();
                }

                //3.landmark为此时的位置区域名称
                this.landmark = value.areaName;
            }

            //重组地址栏的值
            setTimeout(() => {
                if (this.query.evectionType === 1) {
                    //因私
                    this.updateUrlForPrivate();
                } else {
                    //因公
                    this.updateUrlForOfficial();
                }
            }, 500);

            this.productList = [];
            this.recommendProductList = [];
            this.productFinished = false;
            await this.onLoad();
            if (this.productList && this.productList.length === 1) {
                await this.getRecommendList();
            }
            this.query.pt_from_type = value.pt_from_type ? value.pt_from_type : this.query.pt_from_type;

            //当前一个pt来自关键字keyword，并且这次来自区域位置组件的areaName为空
            //则需要沿用上一个来自关键字keyword的pt值,所以pt_from_type仍为'100'
            if(((oldPtFromType === '000' && Number(oldFilterType) === 1) || oldPtFromType === '100') && value.pt_from_type === '200' && value.areaName === '') {
                this.query.pt_from_type = '100';
            }

            //当前一个pt来自区域位置，并且这次来自关键字组件的keyword为非地标类型
            //则需要沿用上一个来自区域位置的pt值,所以pt_from_type仍为'200'
            if (oldPtFromType === '200' && value.pt_from_type === '100' && Number(value.filterType) === 0) {
                this.query.pt_from_type = '200';
            }
        },

        //修改城市
        onCityChange() {
            this.$refs['dropdown-menu'].clearSelectedArea();
        },

        //加载中时，不允许滚动页面
        styleChange(val) {
            let list = document.getElementById('list');
            this.$nextTick(() => {
                if (list) {
                    if (val) {
                        list.style.overflow = 'hidden'
                    } else {
                        list.style.overflow = 'scroll'
                    }
                }
            })
        },

        // 打开酒店详情页
        handleOpenDetail(row) {
            if (Number(row.minPrice) === 0) {
                this.$Toast('房间已满');
                return;
            }
            const {
                evectionNo,
                evectionType,
                workTravelType,
                checkInDate,
                checkOutDate,
                evectionStartDate,
                evectionEndDate,
                cityId,
                cityName,
                cityCn,
                applyId,
                journeyId,
                filterType,
                keyword,
                pt,

                sort,
                d,
                areaId,
                areaType,
                areaName,
                lineCode,
                stationValue,
                minPrice,
                maxPrice,
                hotelStar,
                hotelBrand,
                differenceEnable,
                agreementEnable,
                isPOIName,
                shareJourneyId,
                shareEvectionNo,
            } = this.query;

            if(Number(evectionType) === 1) {
                this.$router.push({
                    name: "h5-hotel-detail",
                    query: {
                        cityId,
                        cityName,
                        cityCn,
                        hotelId: row.hotelId,
                        checkInDate,
                        checkOutDate,
                        evectionType,
                        workTravelType,
                        filterType,
                        keyword: this.keyword,
                        pt,
                        sort,
                        d,
                        areaId,
                        areaType,
                        areaName,
                        lineCode,
                        stationValue,
                        minPrice,
                        maxPrice,
                        hotelStar,
                        hotelBrand,
                        differenceEnable,
                        agreementEnable,
                        isPOIName,
                        shareJourneyId,
                        shareEvectionNo,
                    }
                });
            } else {
                this.$router.push({
                    name: "h5-hotel-detail",
                    query: {
                        checkInDate,
                        checkOutDate,
                        evectionStartDate,
                        evectionEndDate,
                        cityId,
                        cityName,
                        cityCn,
                        applyId,
                        evectionNo,
                        evectionType,
                        workTravelType,
                        journeyType: this.journeyType,
                        journeyId,
                        evectionRequired: this.evectionRequired,
                        evectionDetailType: this.evectionDetailType,
                        filterType,
                        keyword: this.keyword,
                        pt,
                        sort,
                        d,
                        areaId,
                        areaType,
                        areaName,
                        lineCode,
                        stationValue,
                        minPrice,
                        maxPrice,
                        hotelStar,
                        hotelBrand,
                        differenceEnable,
                        agreementEnable,
                        isPOIName,

                        bizName: row.bizName,
                        facilities: row.facilities,
                        hotelName: row.hotelNameCn,
                        hotelNameEn: row.hotelNameEn,
                        hotelAddr: row.addressCn,
                        hotelId: row.hotelId,
                        phone: row.phone,
                        imageUrl: row.imageUrl,
                        star: row.star,

                        shareJourneyId,
                        shareEvectionNo,
                    },
                });
            }

            // cityId=70082&cityName=北京&checkInDate=2021-05-26&checkOutDate=2021-05-27&keyword=&evectionNo=16201815775123262&evectionType=2
        },

        //打开出差单弹框
        editEvection() {
            // 关闭搜索弹窗
            this.$refs['search-head'].closeAllPopup();
            console.log('this.query', this.query);
            this.$refs['travelPopup'].init({
                popupType: '000',
                evectionNo: this.query.evectionNo,
                evectionRequired: this.evectionRequired,
                journeyId: parseInt(this.query.journeyId) || 0,
                businessRuleControl: this.businessRuleControl,
                hotelInfo: {
                    cityId: this.query.cityId,
                    checkInTime: this.query.checkInDate,
                    checkOutTime: this.query.checkOutDate,
                    shareJourneyId: parseInt(this.query.shareJourneyId || 0),
                    shareEvectionNo: this.query.shareEvectionNo,
                }
            })
        },

        //更新出差单数据
        async updateEvectionInfo(val) {
            console.log('翻参：', val);
        /*  返参： val
            * {
            *   difference: String, 是否有改动， 000： 没改动  001：城市或者日期改动，刷新页面  002：人改变，刷新差标
            *   journeyId: string, 行程id
            *   evectionNo： string， 出差单号
            *   workTravelType: number, 因公出差的类型， 1是有选出差单，2是没有
            *   journeyType: number, 0 普通单， 1 不限行程单
            *   editType: string, 编辑类型，'000' 变更出差单号(无出差单->有出差单 或者 有出差单->有出差单), '001': 变更出差单（有出差单->无出差单）  '002' 修改原本出差单内容
            *   travelCurrent： object, 当前选中的出差单， 如果没有选出差单则返回null
            * }
        * */
            this.travellerIdList = val.travelUserList;
            this.query.evectionNo = val.evectionNo;
            this.query.journeyId = val.journeyId || 0;
            this.journeyType = parseInt(val.journeyType);
            this.query.workTravelType = val.workTravelType;
            this.workTravelType = parseInt(val.workTravelType);
            this.query.shareEvectionNo = val.shareEvectionNo;
            this.query.shareJourneyId = val.shareJourneyId;
            this.canChangeFactor();
            this.getEvectionType();
            this.changeUrlInfo(val);
            if (val.difference === '000') {
                return
            }else if(val.difference === '002') {
                this.updateAccord();
            } else {
                this.updateList(val);
            }
        },

        //修改地址信息
        changeUrlInfo(val) {
            let {
                applyId,
                cityId,
                cityName,
                cityCn,
                checkInDate,
                checkOutDate,
                evectionStartDate,
                evectionEndDate,
                evectionNo,
                evectionType,
                evectionRequired,
                workTravelType,
                journeyId,
                journeyType,
                pt,
                filterType,
                shareEvectionNo,
                shareJourneyId,
            } = this.query;

            let params = {
                toCityId: cityId,//选中的出差单城市id
                toCityName: cityName,//选中的出差单城市名
                cityCn: cityCn,//选中的出差单城市名
                isPOIName: cityCn && cityCn !== 'null' && cityCn !== cityName ? true : false,
                evectionNo,//选中的出差单号
                departTime: evectionStartDate,//选中的出差单的到店时间
                returnTime: evectionEndDate,//选中的出差单的离店时间
                firstDepartTime: checkInDate,//选中的出差单的默认到店时间
                firstReturnTime: checkOutDate,//选中的出差单的默认离店时间
                applyId,//选中的出差单的行程id
                journeyId,
                journeyType,
                evectionRequired,//是否必选出差单
                workTravelType,
                filterType,
                keyword: this.keyword,
                pt,
                evectionType,
                shareJourneyId,
                shareEvectionNo,
            };

            let urlParams = {
                applyId: params.applyId,
                evectionStartDate: params.departTime,
                evectionEndDate: params.returnTime,
                checkInDate: params.firstDepartTime,
                checkOutDate: params.firstReturnTime,
                cityId: params.toCityId,
                cityName: params.toCityName,
                cityCn: params.cityCn,
                isPOIName: params.isPOIName,
                evectionNo: params.evectionNo,
                evectionRequired: params.evectionRequired,
                evectionType: 2,
                journeyType: params.journeyType,
                journeyId: params.journeyId,
                workTravelType: val.workTravelType,
                filterType: 0,
                pt: '',
                keyword: '',
                shareJourneyId,
                shareEvectionNo,
            };

            // 组装当前页面url
            let href = location.href.split('?')[0];
            let newUrl = href + '?';
            for(let i in urlParams) {
                newUrl += `${i}=${urlParams[i]}&`
            }
            history.replaceState(null, null, newUrl);
        },

        //刷新差标
        async updateAccord() {
            try {
                this.$showLoading.show();
                let params = {};
                let {
                    evectionNo,
                    cityId,
                    checkInDate,
                    checkOutDate
                } = this.query;
                if (this.aVueVirtualScrollList.data_sources) {
                    this.aVueVirtualScrollList.data_sources.forEach(item => {
                        params = {
                            checkInDate,
                            checkOutDate,
                            cityId,
                            evectionNo,
                            id: item.hotelId,
                            price: item.minPrice || 0,
                            applyJourneyId: this.query.journeyId ? this.query.journeyId : '',
                        }
                        consumer_hotel_search_checkHotelPolicy(params).then(res => {
                            item.accord = res.accord;
                        })
                    });
                }

                if (this.bVueVirtualScrollList.data_sources) {
                    this.bVueVirtualScrollList.data_sources.forEach(item => {
                        params = {
                            checkInDate,
                            checkOutDate,
                            cityId,
                            evectionNo,
                            id: item.hotelId,
                            price: item.minPrice || 0,
                            applyJourneyId: this.query.journeyId ? this.query.journeyId : '',
                        }
                        consumer_hotel_search_checkHotelPolicy(params).then(res => {
                            item.accord = res.accord;
                        })
                    });
                }
            } finally {
                this.$showLoading.hide();
            }

        },

        //刷新页面
        async updateList(val) {
            try {
                this.$showLoading.show();
                let curTravel;
                let {
                    applyId,
                    cityId,
                    cityName,
                    checkInDate,
                    checkOutDate,
                    evectionStartDate,
                    evectionEndDate,
                    evectionNo,
                    evectionType,
                    evectionRequired,
                    workTravelType,
                    journeyType,
                    pt,
                    filterType,
                } = this.query;

                let params = {
                    toCityId: cityId,//选中的出差单城市id
                    toCityName: cityName,//选中的出差单城市名
                    evectionNo,//选中的出差单号
                    departTime: evectionStartDate,//选中的出差单的到店时间
                    returnTime: evectionEndDate,//选中的出差单的离店时间
                    firstDepartTime: checkInDate,//选中的出差单的默认到店时间
                    firstReturnTime: checkOutDate,//选中的出差单的默认离店时间
                    applyId,//选中的出差单的行程id
                    journeyType,
                    evectionRequired,//是否必选出差单
                    workTravelType,
                    filterType,
                    keyword: this.keyword,
                    pt,
                    evectionType
                };

                if (val.travelCurrent) {
                    curTravel = val.travelCurrent.active;
                    //因公，有出差单
                    //改变城市时，去掉关于关键字的相关搜索条件
                    if(applyId && Number(val.travelCurrent.active.id) !== Number(applyId)) {
                        params.filterType = 0;
                        params.keyword = '';
                        params.pt = '';
                    }
                    params.cityCn = curTravel.toCityName || cityName;

                    // 注意不限行程
                    if (!val.travelCurrent.active.toCityId) {
                        val.travelCurrent.active.toCityId = cityId;
                    }
                    if (!val.travelCurrent.active.toCityName) {
                        val.travelCurrent.active.toCityName = cityName;
                    }
                    params = {
                        ...params,
                        ...val.travelCurrent.active
                    };

                    params.applyId = val.travelCurrent.id;
                    params.journeyId = curTravel.id;
                    params.departTime = val.travelCurrent.startDate;
                    params.returnTime = val.travelCurrent.endDate;
                    params.checkInDate = curTravel.firstDepartTime;
                    params.checkOutDate = curTravel.firstReturnTime;
                } else {
                    //因公，无出差单
                    //无出行时间范围
                    params.applyId = 0;
                    params.journeyId = '';
                    params.departTime = '';
                    params.returnTime = '';
                    params.evectionNo = val.evectionNo;
                    params.cityCn = cityName;
                }

                let urlParams = {
                    applyId: params.applyId,
                    evectionStartDate: params.departTime,
                    evectionEndDate: params.returnTime,
                    checkInDate: params.firstDepartTime,
                    checkOutDate: params.firstReturnTime,
                    cityId: params.toCityId,
                    cityName: params.toCityName,
                    cityCn: params.cityCn,
                    evectionNo: params.evectionNo,
                    evectionRequired: params.evectionRequired,
                    evectionType: 2,
                    journeyType: params.journeyType,
                    journeyId: params.journeyId,
                    workTravelType: val.workTravelType,
                    filterType: 0,
                    pt: '',
                    keyword: ''
                };

                // 组装当前页面url
                let href = location.href.split('?')[0];
                let newUrl = href + '?';
                for(let i in urlParams) {
                    newUrl += `${i}=${urlParams[i]}&`
                }
                history.replaceState(null, null, newUrl);


                if(val.editType === '000') {
                    location.reload();
                }

                if(val.editType !== '000') {
                    this.query.applyId = params.applyId;
                    this.query.evectionNo = params.evectionNo;
                    this.query.workTravelType = val.workTravelType;
                    this.query.journeyType = val.journeyType;
                    this.query.journeyId = params.journeyId;

                    this.workTravelType = parseInt(val.workTravelType);
                    this.journeyType = parseInt(val.journeyType);

                    this.query.currentPage = 1;
                    this.productList = [];
                    this.recommendProductList = [];

                    this.getEvectionType();
                    this.canChangeFactor();
                    await this.onLoad();
                    if (this.productList && this.productList.length === 1) {
                        await this.getRecommendList();
                    }
                }

                this.getEvectionDetail();// 获取出差单详情
            } finally {
                this.$showLoading.hide();
            }
        },

        //获取因公出差规则 1：出差单审批 2：预订审批
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.businessRuleControl = res.datas.businessRuleControl;
            this.unlimitedJourney = res.datas.unlimitedJourney;
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
        },

        //安卓物理键返回时
        keyback() {
            const __this = this;

            keyback_processor.addEventListener(function () {
                const travelPopup = __this.$refs.travelPopup;
                if (travelPopup.getShowType()) {
                    travelPopup.hide();
                    return;
                }

                const searchHead = __this.$refs['search-head'];

                const hotelCitySelectorShow = searchHead.hotelCitySelectorShow();
                if(hotelCitySelectorShow) {
                    searchHead.hideHotelCitySelector();
                    return;
                }

                if(__this.evectionDetailType==='001') {
                    const hotelStayDatePickerShow = searchHead.hotelStayDatePickerShow();
                    if(hotelStayDatePickerShow) {
                        searchHead.hideHotelStayDatePicker();
                        return;
                    }
                }

                const calendarShow = searchHead.calendarShow();
                if(calendarShow) {
                    searchHead.hideCalendar();
                    return;
                }

                const calendarFor003Show = searchHead.calendarFor003Show();
                if(calendarFor003Show) {
                    searchHead.hideCalendarFor003();
                    return;
                }

                const keywordPopupShow = searchHead.keywordPopupShow();
                if(keywordPopupShow) {
                    searchHead.hideKeywordPopup();
                    return;
                }

                const searchPopupShow = searchHead.searchPopupShow();
                if(searchPopupShow) {
                    searchHead.hideSearchPopup();
                    return;
                }

                const dropDownMenu = __this.$refs['dropdown-menu'];

                const areaPopupShow = dropDownMenu.areaPopupShow();
                if(areaPopupShow) {
                    dropDownMenu.hideAreaPopup();
                    return;
                }

                const priceStarPopupShow = dropDownMenu.priceStarPopupShow();
                if(priceStarPopupShow) {
                    dropDownMenu.hidePriceStarPopup();
                    return;
                }

                const brandPopupShow = dropDownMenu.brandPopupShow();
                if(brandPopupShow) {
                    dropDownMenu.hideBrandPopup();
                    return;
                }

                const hotelPolicyPopup = __this.$refs.hotelPolicyPopup;
                if (hotelPolicyPopup.isShow()) {
                    hotelPolicyPopup.hide();
                    return;
                }

                __this.onClickLeft()
            });
        },

        //虚拟列表滚动到底部时，加载更多
        __aVueVirtualScrollList_to_bottom_event_handler() {
            if (!this.productLoading && !this.productFinished) {
                this.onLoad();
            }
        },

        // 查看差标
        openEvectionRulePopup() {
            let userIds = this.travellerIdList;
            let userIdsWhetherShared = this.userIdsWhetherShared;
            let cityId = this.query.cityId;
            let cityName = this.query.cityCn;
            let params = {
                userIds,
                userIdsWhetherShared,
                cityId,
                cityName,
                applyJourneyId: this.query.journeyId ? this.query.journeyId : '',
            }
            const searchHead = this.$refs['search-head'];
            const searchPopupShow = searchHead.searchPopupShow();
            if(searchPopupShow) {
                searchHead.hideSearchPopup();
            }
            this.$refs.hotelPolicyPopup.init(params);
        },

        // 获取出差单详情
        getEvectionDetail() {
            consumer_journey_getEvectionDetailV2({evectionNo: this.query.evectionNo}).then(res => {
                this.evectionDetail = res.datas;
                this.travellerList = this.evectionDetail.psgList;
                let travellerIdList = [];
                let userIdsWhetherShared = [];
                if (this.travellerList) {
                    this.travellerList.forEach(item => {
                        if(item.shareWith === 1) {
                            userIdsWhetherShared.push(item.userId);
                        } else {
                            travellerIdList.push(item.userId);
                        }
                    })
                }
                this.travellerIdList = travellerIdList ? travellerIdList : [];
                this.userIdsWhetherShared = userIdsWhetherShared ? userIdsWhetherShared : [];
            });
        },

        //计算高度
        handleScroll() {
            let aTop = document.getElementById('list').scrollTop;
            this.isShowToTopBtn = aTop > 166 ? true : false;

            if (this.recommendProductList.length > 0) {
                let bTop = document.getElementById('mainList').scrollTop;
                this.isShowToTopBtn = bTop > 166 ? true : false;
            }

            let scrollTop = document.getElementById('list').scrollTop;
            let clientHeight = document.getElementById('list').clientHeight;
            let scrollHeight = document.getElementById('list').scrollHeight;
            //不知为什么有时滚动条到底时，scrollTop + clientHeight 和 scrollHeight 相差 1
            if (!this.productLoading && this.productList.length < this.total && scrollTop + clientHeight >= scrollHeight - 20) {
                this.onLoad();
            }
        },

        //回到顶部
        toTop() {
            let step = 0;
            let distance = 0;
            let recommendList = document.getElementById('recommendList')
            let list = document.getElementById('list')

            if (recommendList) {
                let mainList = document.getElementById('mainList')
                distance = mainList.scrollTop;
                step = distance / 50
                smoothUp(mainList)
            }

            if (list) {
                distance = list.scrollTop;
                step = distance / 50
                smoothUp(list)
            }

            // 参数element为滚动区域
            function smoothUp(element) {
                setTimeout(() => {
                    if (distance > step) {
                        distance -= step
                        element.scrollTop = distance
                        smoothUp(element)
                    } else {
                        element.scrollTop = 0;
                    }
                }, 10)
            }
        },

        //页面停留时间超过30分钟，重新搜索酒店
        setSearchReact() {
            this.searchTemp = setInterval(() => {
                this.intervalNum++
                if (this.intervalNum >= 30 * 60) {
                    clearInterval(this.searchTemp)
                    Toast('页面停留时间超过30分钟，请您重新搜索酒店')
                    this.init();
                    this.onLoad();
                    this.intervalNum = 0
                    this.setSearchReact()
                }
            }, 1000)
        },

        //重载页面
        reload() {
            location.reload();
        }
    }
}
