
// 彩色icon组件
import ColorIcon from "@/component/color-icon/index.vue";

// 我的出差
import web_policyConfig_userTravelPolicy from "@/lib/data-service/haolv-default/web_policyConfig_userTravelPolicy";
import HotelCityListPopup from '@/component/hotel-city-list-popup/2.0.0/index.vue'
import SubsidyCityListPopup from '@/component/subsidy-city-list-popup/1.0.0/index.vue'
export default {
  components: {
    ColorIcon,
    HotelCityListPopup,
    SubsidyCityListPopup,
  },
  data() {
    return {
      detail: {},
    };
  },
  methods: {
    req_detail () {
      this.$Toast.loading({
        duration: 0,
        message: '加载中...',
        forbidClick: true,
      })
      web_policyConfig_userTravelPolicy().then((res) => {
        this.detail = res.datas;
        this.$Toast.clear();
      }).catch((err) => {
        this.$Toast.clear();
      })
    },
    returnUseCarTimeText(val) {
      // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
      const useCarType = [2, 5, 6]
      if (useCarType.indexOf(val) > -1) {
        return true
      } else {
        return false
      }
    },
    returnUseCarLocationType(val) {
      // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
      const useCarType = [2, 5]
      if (useCarType.indexOf(val) > -1) {
        return true
      } else {
        return false
      }
    },
    returnWorkLocation(val) {
      // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
      const useCarType = [2, 5]
      if (useCarType.indexOf(val) > -1) {
        return true
      } else {
        return false
      }
    },
    returnUsageScenarios(val) {
      // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
      const useCarType = [2, 3]
      if (useCarType.indexOf(val) > -1) {
        return true
      } else {
        return false
      }
    },
    toShowCityList() {
      this.$refs.hotelCityListPopup.init();
    },
    toShowSubsidyCityList() {
      this.$refs.subsidyCityListPopup.init();
    },
  },
  async activated () {
    await this.$store.state.policyDefer.promise;
    this.req_detail();
  },
  deactivated () {
    this.$Toast.clear();
  }
};
