import * as dd from 'dingtalk-jsapi';
import moment from "moment";
import app_loading from "@/lib/common-service/app-loading/1.0.1";
import {Toast} from "vant";
import cache from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/cache";
import cache_set from "nllib/src/cache/set";
import consumer_dingdingLogin from '@/lib/data-service/haolv-default/consumer_dingdingLogin';
import consumer_dingding_getJsApiSign from '@/lib/data-service/haolv-default/consumer_dingding_getJsApiSign';
import set_token from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/set_token";
import get_user_info from "@/lib/common-service/get_user_info";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";

export default {
    data() {
        return {
            corpId: '',
            agentId: '',
        }
    },
    props: {},
    components: {},
    created() {
        // app_loading.hide();
    },
    mounted() {
        console.log(dd, dd.env);
        this.corpId = this.$route.query.corpId || '';
        this.agentId = this.$route.query.agentId || '';
        if (dd.env.platform === "notInDingTalk") {
            console.log("不在钉钉环境");
            app_loading.hide();
            Toast('不在钉钉环境');
            return;
        }
        dd.setStorage({
            data: this.corpId,
            key: 'corpId',
        })
            // 设置导航栏标题
        dd.biz.navigation.setTitle({
            title: '铨成商旅',
            onSuccess() {},
            onFail() {},
        }).catch((err) => { console.log(`${err }`); });

        this._login();
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        async _getAuth() {
            let url = location.href;
            let params = {
                "agentId": this.agentId,
                "corpId": this.corpId,
                "nonceStr": "quancheng",
                "url": url,
            };
            // alert(JSON.stringify(params));
            let [err, res] = await awaitWrap(consumer_dingding_getJsApiSign(params));
            if (err) {
                return;
            }
            dd.config({
                        agentId: this.agentId, // 必填，微应用ID
                        corpId: this.corpId,//必填，企业ID
                        timeStamp: res.datas.timeStamp, // 必填，生成签名的时间戳
                        nonceStr: 'quancheng', // 必填，自定义固定字符串。
                        signature: res.datas.sign, // 必填，签名
                        type: 0,   //选填。0表示微应用的jsapi,1表示服务窗的jsapi；不填默认为0。该参数从dingtalk.js的0.8.3版本开始支持
                        jsApiList : [
                            'startLocating',
                            'stopLocating'
                        ] // 必填，需要使用的jsapi列表，注意：不要带dd。
                    });
            dd.error(function (err) {
                // alert('dd error: ' + JSON.stringify(err));
                console.log('dd error: ' + JSON.stringify(err));
            })//该方法必须带上，用来捕获鉴权出现的异常信息，否则不方便排查出现的问题
        },
        _login() {
            //alert(`公司corpId：${this.corpId}`);
            dd.getAuthCode({
                // corpId: 'ding09a595d36cde39d9acaaa37764f94726',
                corpId: this.corpId,
                success: async (res) => {

                    const { code } = res;
                    //alert(`成功code:${code}`)
                    const params = {corpId: this.corpId, dingdingCode: code};
                    //alert(JSON.stringify(params));
                    // 调用code换token接口
                    let [lErr, lRes] = await awaitWrap(consumer_dingdingLogin(params));
                    if (lErr) {
                        //alert('登录失败')
                        Toast(lErr.data.msg);
                        return
                    }
                    //alert(`登录成功: ${lRes.datas.access_token}`);
                    set_token({token: lRes.datas.access_token});
                    set_token({corpId: this.corpId});
                    set_token({agentId: this.agentId});
                    // 关闭loading
                    app_loading.hide();
                    Toast.success('登录成功');
                    // 保存token并跳转到首页
                    get_user_info({
                        clear_cache: true,
                    })
                    const phone = lRes.datas.user_detail.phone;
                    cache.set_user_name(phone);
                    cache_set({key: 'phone', value: phone});
                    // 鉴权
                    await this._getAuth();
                    this.$router.replace({
                        name: `redirect`,
                    });
                },
                fail: () => {
                    app_loading.hide();
                },
                complete: () => {},
            });
        }
    }
}