// 列表组件
import EvectionList from "../../components/evection-list/3.0.3/index.vue";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
export default {
  components: {
    EvectionList,
    NavBar,
  },
  data () {
    return {
      jumpType: '',
    };
  },
  mounted(){
    this.jumpType = this.$route.query.jumpType ? this.$route.query.jumpType : '';
  },
  activated() {
    keyback_processor.addEventListener(() => {
      this.back()
    })
  },
  deactivated() {
    keyback_processor.removeEventListener();
  },
  methods: {
    back(){
      if (window.getFlutter == true) {
        console.log(window.getFlutter);
        try {
          window.flutter_inappwebview
              //可以传递你所需要的任意类型数据，数组、对象等
              .callHandler("backHandler", {'type': 'close'})
              .then((res)=>{
                console.log("res========flutter给html的数据", res);
              })
        } catch (e) {}
        return
      }
      // this.$router.go(-1)
      if (this.jumpType) {
        if (this.jumpType == 'h5-home') {
          this.$router.replace({
            name: 'h5-home'
          })
        } else {
          this.$router.replace({
            name: 'h5-office-home'
          })
        }
      } else {
        this.$router.replace({
          name: 'h5-office-home'
        })
      }
    }
  }
}
