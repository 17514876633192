import {Dialog, Toast} from "vant";
import moment from 'moment';
import ColorIcon from "@/component/color-icon/index.vue";
// 获取确认订单信息
import consumer_trains_order_trainOrderTakeTicketDetail from '@/lib/data-service/haolv-default/consumer_trains_order_trainOrderTakeTicketDetail'
//取消订单
import consumer_trains_order_applyForCancel from "@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel";
export default {
    components: {
        ColorIcon,
    },
    data() {
        return {
            nameData: '',
            showOff: false,
            divOff: true,
            costOff: true,
            timeData: 5,
            timeOff: null,
            trainData: {
                takeTicketFailMsg: null,
                trainInfo:{
                    startDate: null,
                },
                priceInfo: {
                    headCounts: 2, //人数
                    robTicketPrice: 10, //抢票费
                    servicePrice: 20, //服务费
                    ticketPriceInfos: [
                        {
                            headCounts: 2,
                            seatName: '硬卧',
                            seatPrice: 185,
                        }
                    ]
                },
            }, //确认信息
            orderNoText: null, //订单号
            testData:[
                {
                    name:'陈立群（成人）',
                    testOne: '43122319900529XXXX',
                    testTwo: 'E350411838',
                    testThree: '三层B24',
                    testFour: '上铺  08车厢08F号',
                    testFive: '200',
                    testSix: '200',
                    testSeven: '未占到下铺',
                },
                {
                    name:'陈立群（成人）',
                    testOne: '43122319900529XXXX',
                    testTwo: '占座失败',
                    testThree: null,
                    testFour: null,
                    testFive: null,
                    testSix: null,
                    testSeven: null,
                }
            ]
        };
    },
    computed: {},
    watch: {},
    methods: {
        buttonClickExitGo(){
            let ToastOff = Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
            });

            let data = {
                orderNo:this.orderNoText
            };
            consumer_trains_order_applyForCancel(data)
                .then((res) => {
                    ToastOff.clear();
                    if (res.rsCode == '000000') {
                        Toast.success('取消订单成功');
                    } else {
                        Toast.fail('取消订单失败');
                    }
                    setTimeout(()=> {
                        this.$router.push({
                            name: "h5-train-order-list",
                        });
                    },1000)
                })
                .catch((res) => {
                    ToastOff.clear();
                    Toast.fail('取消订单失败');
                    setTimeout(()=> {
                        this.$router.push({
                            name: "h5-train-order-list",
                        });
                    },1000)
                })
        },
        buttonClickExitExit(){
            this.showOff = false;
            this.initTime();
        },
        buttonClickExit () {
            clearInterval(this.timeOff);
            let nameData = '';
            this.trainData.passengerInfos.forEach((item,index) => {
                if (item.takeTicketStatusText == '占座成功') {
                    nameData = nameData + item.passengerName + '、';
                }
            });

            this.nameData = nameData.slice(0,nameData.length-1);

            this.showOff = true;
        },
        buttonClickGo(){
            clearInterval(this.timeOff);
            let evectionNo = this.$route.query.evectionNo;
            this.$router.push({
                name: 'h5-payment-center',
                query: {
                    // evectionNo: this.evectionNo
                    evectionNo
                }
            })
        },
        costOffClick(){
          this.costOff = !this.costOff;
        },
        passengerOff(){
            this.divOff = !this.divOff;
        },
        initTime(){
            // this.timeData = 5;
            this.timeOff = setInterval(() => {
                --this.timeData;
                if (this.timeData == 0) {
                    clearInterval(this.timeOff);
                    let evectionNo = this.$route.query.evectionNo;
                    this.$router.push({
                        name: 'h5-payment-center',
                        query: {
                            // evectionNo: this.evectionNo
                            evectionNo
                        }
                    })
                }
            },1000)
        },
        initPost(){
            let data = {
                orderNo:this.$route.query.orderNo
            };
            this.orderNoText = data.orderNo;
            consumer_trains_order_trainOrderTakeTicketDetail(data).then((res) => {
                this.trainData = res.datas;
                this.initTime();
            })
        },
    },
    activated(){
        this.timeData = 5;
        this.initPost();
    },
    mounted() {

    },
};
