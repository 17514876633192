import getMyJourneyList from '@/lib/data-service/haolv-default/consumer_journey_myJourneyList'
// 列表为空展示组件
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";
// 彩色icon组件
import ColorIcon from "@/component/color-icon/index.vue";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
//头部切换我的出差和我的行程
import TravelHeader from '@/component/travel-header/1.0.1/index.vue';
import consumer_car_link_getGaodeLinkForPickUp from '@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkForPickUp'
//根据订单号获取跳转订单详情链接接口
import consumer_car_link_getGaodeLinkByOrderNo
    from "@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkByOrderNo";
import { Toast } from "vant"
import MyEvection from "@/page/evection/evection-order/office-evection/4.0.0/index.vue";
import open from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window";
import moment from "moment";
import HomeTripList from "@/component/home-trip-list/import_latest_version_module.js";
import _ from 'underscore';

export default {
    components: {
        ColorIcon,
        TravelHeader,
        ListEmpty,
        MyEvection,
        HomeTripList
    },
    data() {
        return {
            params: {
                startDate: '',
                endDate: '',
                journeyType: 0
            },
            myJourneyList: true,
            loading: false,
            emptyType: 'search',
            orderType: 'b',
        }
    },
    mounted() {
        this.activatedFunction();
    },
    methods: {
        returnDayType(val) {
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(val).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == todayEnd) {
                text = `今天`;
            }

            if (tomorrow == todayEnd) {
                text = `明天`;
            }

            if (afterTomorrow == todayEnd) {
                text = `后天`;
            }
            return text
        },
        activatedFunction(){
            this._getMyJourneyList();
            const __this = this;
            fixStatusBar({
                handler({api}) {
                    __this.$refs.headNavShow.style.paddingTop = api.safeArea.top + 'px';
                }
            });
        },
        // 今天、明天
        DateDiff(date){
            let today = moment().format('YYYY-MM-DD');
            let todayEnd = moment(date).format('YYYY-MM-DD');
            let tomorrow = moment().add(1, 'd').format('YYYY-MM-DD');
            let afterTomorrow = moment().add(2, 'd').format('YYYY-MM-DD');

            let text = '';

            if (today == this.trainDate) {
                text = '今天';
            }

            if (tomorrow == todayEnd) {
                text = '明天';
            }

            if (afterTomorrow == todayEnd) {
                text = '后天';
            }
            if (text == '') {
                text = moment(date).format('YYYY-MM-DD HH:mm:ss');
            }
            return text

        },
        clickTrainPhone(val){
            let businessType = val;
            consumer_car_link_getGaodeLinkForPickUp({businessType}).then(res => {
                let url = res.datas.url;
                open({
                    url,
                    title: '用车订单详情',
                    btn_back_click_event_handler: () => {
                        this.search();
                    }
                })
            });
        },
        clickCarDetail(value){
            consumer_car_link_getGaodeLinkByOrderNo({orderNo:value.orderNo}).then(res => {
                let url = res.datas.url;
                open({
                    url,
                    title: '用车订单详情',
                    btn_back_click_event_handler: () => {
                        this.search();
                    }
                })
            })
        },
        clickCarPhone(){

        },
        colorJourneyStatusTextCar(type){
            let color = '#FF7002';
            switch (type) {
                case 0:
                    color = '#FF7002';
                    break;
                case 1:
                    color = '#FF7002';
                    break;
                case 2:
                    color = '#FF7002';
                    break;
                case 3:
                    color = '#FF7002';
                    break;
                case 4:
                    color = '#FF7002';
                    break;
                case 5:
                    color = '#FF7002';
                    break;
                case 6:
                    color = '#FF7002';
                    break;
                case 7:
                    color = '#FF7002';
                    break;
            }
            return color
        },
        changeTabs(val){
            // Toast.loading({
            //     message: '加载中...',
            //     forbidClick: true,
            // });
        },
        clickButton() {
            this.$router.push({
                name: 'h5-home',
            })
        },
        testClick(data){
            this.params = {
                startDate: data.flightSetOutStartDate,
                endDate: data.flightSetOutEndDate,
                journeyType: data.approveTypeNewNum
            };
            this._getMyJourneyList();
        },
        async _getMyJourneyList() {
            let ToastBox = Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            let res = await getMyJourneyList(this.params);
            let myJourneyList = res.datas;
            let dateList = [...new Set(myJourneyList.map(item => item.startDate))];
            this.myJourneyList = [...new Set(dateList.map(date => {
                    let filterList = myJourneyList.filter(item => item.startDate === date);
                    return {
                        recentDate: filterList[0].dayType,
                        startDate: date,
                        week: filterList[0].week,
                        journeyList: filterList
                    }
                })
            )];
            this.myJourneyList = _.sortBy(this.myJourneyList, 'startDate');
            ToastBox.clear();
        },
        toJourneyDetail(myJourney) {
            const toDetail = (name, query) => {
                this.$router.push({
                    name,
                    query,
                });
            };

            switch (myJourney.businessType) {
                case 1:
                    toDetail("flight-order-detail", {
                        orderNo: myJourney.orderNo
                    });
                    break;
                case 2:
                    toDetail("h5-train-order-detail", {
                        orderNo: myJourney.orderNo
                    });
                    break;
                case 3:
                    toDetail("h5-hotel-order-detail", {
                        orderNo: myJourney.orderNo
                    });
                    break;
            }
        }
    }
}