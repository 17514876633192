import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/evection-order-list',
      name: 'h5-evection-order-list',
      component: () => import(/* webpackChunkName: "page-evection-evection-order-evection-order-list" */ `./3.0.1/index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '出差列表',
          need_head_nav: false,
          need_head_nav1: true,
          need_footer_bar: true,
          secondary_menu: 1,
        }
      },
    }
  ],
};
