import moment from "moment";
import fa from "element-ui/src/locale/lang/fa";
import el from "element-ui/src/locale/lang/el";

import MyBusiness from "@/component/travel-header/1.0.0/component/Mybusiness/index.vue"
import MyTrip from "@/component/travel-header/1.0.0/component/Mytrip/index.vue"
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import { Toast } from 'vant';
export default {
  name:'TravelHeader',
  components:{
    MyBusiness,
    MyTrip
  },
  data() {
    return {
      choiceShow:false,
      screenShow:false,
      testOff:null,
      showTop:0,
      radio: '0',
      showData:[
        {
          name:'我的出差',
          key:0,
          to:'/h5/office-evection'
        },
        {
          name:'我的行程',
          key:1,
          to:'/h5/my-reserve'
        }
      ],
    };
  },
  mounted(){
    if (this.$route.path === '/h5/office-evection') {
      this.radio = '0';
    }else if (this.$route.path === '/h5/my-reserve') {
      this.radio = '1';
    }
    const __this = this;
    fixStatusBar({
      handler({api}) {
        __this.showTop = api.safeArea.top;
        __this.$refs.headNav.style.paddingTop = api.safeArea.top + 'px';
        __this.$refs.headNavShow.style.paddingTop = api.safeArea.top + 'px';
      }
    });
  },
  methods: {
    clickFormBusiness(data){
      this.$emit('dataScreen',data);
    },
    clickFormTrip(data){
      this.$emit('dataScreen',data);
    },
    //差单和行程切换按钮
    clickRadio(key){
      this.radio = key;
    },
    //取消按钮--筛选
    clickCancel() {
      this.testOff = false;
      this.screenShow = false;
    },
    //确定按钮--筛选
    clickDetermine() {
      this.testOff = true;
      this.screenShow = false;
    },
    //单击遮罩--筛选
    clickOverlay () {
      this.clickCancel();
    },
    //切换标签--跳转
    tabsClick(name){
      if (this.showData[name].to != this.$route.fullPath) {
        Toast.loading({
          message: '加载中...',
          forbidClick: true,
        });
        this.$router.push(this.showData[name].to);
      }
    },
    //筛选框
    clickScreen () {
      if (this.choiceShow) {
        this.choiceShow = !this.choiceShow;
      }else {
        this.testOff = null;
        this.screenShow = !this.screenShow;
      }
    },
    postDataTest(res){
      this.$emit('postDataTop',res);
    }
  },
  created() {},
  activated() {},
  deactivated() {},
};
