// consumer_t_od_hotel_order_interim_deleteHotelOrder  删除酒店订单
const __request = require(`./__request/__request_contentType_json`);

const consumer_t_od_hotel_order_interim_deleteHotelOrder = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/t-od-hotel-order-interim/deleteHotelOrder',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_t_od_hotel_order_interim_deleteHotelOrder;
