import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    // component: () => import(`@/component/h5-layout/3.0.0/index.vue`),
    children: [
        {
            path: '/h5/home',
            name: 'h5-home',
            component: () => import(/* webpackChunkName: "page-home" */ `./4.0.3/index.vue`),
            meta: {
                h5_layout: {
                    need_alive: true,
                    need_head_nav: false,
                    need_footer_bar: true,
                    secondary_menu: 0,
                },

                data_for_all_routes_processor: {
                    order_index: `010-000`,
                },
            },
        }
    ],
};
