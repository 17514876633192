// consumer_web_staff_detail 获取员工详情
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://hoteltest.yinzhilv.com/swagger-ui.html#/build-solr-controller/searchThinkUsingPOST
const consumer_hotel_search_searchThink = (pParameter) => {
  if (!pParameter) pParameter = {};

  const search = pParameter.search
  const cityId = pParameter.cityId

  const p = {
    method: 'post',
    urlSuffix: '/consumer/hotel/search/searchThink',
    data: {
      search,
      cityId,
    }
  };
  return new Promise(function (resolve, reject) {
    resolve(__request(p))
  }).then(data => {
    return data.searchThinkResults
  })
};
module.exports = consumer_hotel_search_searchThink;