import BaseMultipleEntitySelector0301 from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv-app/base-multiple-entity-selector-0301/1.0.3/index.js";
import extend from "extend";
import Q from 'q';
import _ from 'underscore';
import consumer_apply_queryJourneyUserList from 'yinzhilv-js-sdk/frontend/common-project/lib/data-service/haolv-default/consumer_apply_queryJourneyUserList'
export default {
    mixins: [BaseMultipleEntitySelector0301],
    data() {
        return {
            init_parameter: {
                // 场景：
                // 000:value_for_v_model和temp_value_for_v_model的值同步，即在ui上马上选，value_for_v_model的值马上变化
                // 100:value_for_v_model和temp_value_for_v_model的值不同步，即要还需要一个操作将value_for_v_model赋值为temp_value_for_v_model
                situation: `100`,

                // 选择模式
                // multiple：多选
                // single：单选
                select_mode: `single`,

                entity_define: {
                    property_name_for_id: `cityCode`,
                    property_name_for_name: `cityName`,
                },
                get_params: null,
                get_data_source: null,
            },
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        init(pInitParameter) {
            const __this = this;
            if (!pInitParameter) pInitParameter = {};

            const default_init_parameter = {};

            extend(__this.init_parameter, default_init_parameter, pInitParameter);
            extend(__this.init_parameter.entity_define, default_init_parameter.entity_define, pInitParameter.entity_define);
            extend(__this.init_parameter.get_data_source, default_init_parameter.get_data_source, pInitParameter.get_data_source);

            console.log('__this', __this);
        },
    }
}