import NavBar from "@/component/nav-bar/import_latest_version_module";
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import StopData from '@/page/travel-book/flight/component/stopData/1.0.0/index.vue'
import StopDataPopup from '@/page/travel-book/flight/component/stopDataPopup/1.0.0/index.vue'
import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/payment-method-selector/1.2.1/payment-method-selector-000/index.vue'
import consumer_flight_search_confirmationDetails from '@/lib/data-service/haolv-default/consumer_flight_search_confirmationDetails'
import consumer_air_ticket_cancelOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";
import consumer_admin_flight_checkFlightPay from '@/lib/data-service/haolv-default/consumer_admin_flight_checkFlightPay'
import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop'
import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo';
import moment from "moment";
import {Dialog, Toast} from "vant";
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import Q from "q";

export default {
    data() {
        return {
            loading: false,
            title: '确认订单信息',
            orderNo: '',
            evectionNo: '',
            evectionType: 1,
            detail: {},
            milliseconds: 0,
            intervalFun: '',

            stopDataShow: false,
            stopData: [], // 经停信息
            stopDataLoading: false,

            productDetail: {}, // 加享产品详情
            upLevelInfoDetailShow: false,
        }
    },
    components: {NavBar, OrangeNavBar, PaymentMethodSelector000, StopData, StopDataPopup},
    created() {
    },
    mounted() {
    },
    async activated() {
        const __this = this
        this.orderNo = this.$route.query.orderNo
        this.evectionNo = this.$route.query.evectionNo
        this.evectionType = parseInt(this.$route.query.evectionType)
        await this.getDetail();
        this.getProductDetail();
        const aPaymentMethodSelector000 = __this.$refs.aPaymentMethodSelector000;
        const orderForPayVos = [
            {
                "orderNo": this.orderNo,
                "businessContent": ""
            },
        ]
        aPaymentMethodSelector000.init({
            mock_data: {
                enable: false,
            },
            count_down: {
                enable: true,
                pay_remaining_seconds: __this.detail.milliseconds,//支付剩余时间，以秒为单位
            },
            //在这里传入订单详情的相关参数
            get_params() {
                const p = {
                    orderForPayVos
                };

                return Q.resolve(p);
            },
            //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
            before_pay_event_handler() {
                console.log(`before_pay_event_handler`);
                return consumer_admin_flight_checkFlightPay({orderNo: [__this.orderNo]}).then(res => {
                    Toast.clear();
                    return Q.resolve();
                }, error => {
                    Toast.clear()
                    Dialog.alert({
                        title: '温馨提示',
                        message: error.data.msg,
                    }).then(() => {
                        // on close
                        if (error.data.code === '200029') {
                            __this.$router.go(-1)
                        } else {
                            __this.getDetail()
                        }

                    });
                    //__this.init()
                    return Q.reject()
                }).finally(() => {
                    // __this.$Toast.clear();
                })
            },
            //用户取消支付的事件处理方法
            user_cancel_pay_event_handler() {
                // const __this = this;
                Toast.clear()
                console.log(`user_cancel_pay_event_handler`);
                // alert(`user_cancel_pay_event_handler`);
            },
            //支付成功事件处理方法
            pay_successful_event_handler() {
                console.log(`pay_successful_event_handler`);
                Toast.clear();
                __this.$router.replace({
                    name: 'h5-payment-success',
                    query: {
                        orderNo: __this.orderNo,
                        orderType: 1,
                        evectionType: __this.evectionType
                    }
                })
            },
            //支付失败事件处理方法
            pay_failed_event_handler() {
                Toast.clear()
                console.log(`pay_failed_event_handler`);
            },
        });
    },
    deactivated() {
        clearInterval(this.intervalFun)
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day()
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterIntervalTime(val) {
            if (val) {
                let m = moment(val).format('mm分ss秒')
                return m
            } else {
                return '00分00秒'
            }
        },
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        async getDetail() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            })
            this.loading = true
            let [err, res] = await awaitWrap(consumer_flight_search_confirmationDetails({orderNo: this.orderNo}))
            if (err) {
                Toast.clear()
                this.loading = false
                return
            }
            Toast.clear()
            this.loading = false
            this.detail = res.datas

            // this.detail.confirmationDetails = [
            //     this.detail.confirmationDetails,
            //     this.detail.confirmationDetails
            // ];

            this.milliseconds = res.datas && res.datas.confirmationDetails ? res.datas.confirmationDetails.milliseconds * 1000 || 0 : 0
            clearInterval(this.intervalFun)
            if (this.milliseconds > 0) {
                this.setPayInterval()
            }
        },
        setPayInterval() {
            this.intervalFun = setInterval(() => {
                this.milliseconds = this.milliseconds - 1000
                if (this.milliseconds <= 0) {
                    clearInterval(this.intervalFun)
                    // 超时未支付，进行取消订单
                    this.cancelOrder()
                }
            }, 1000)
        },
        cancelOrder() {
            Toast('超时未支付，订单已自动取消')
            consumer_air_ticket_cancelOrder({ orderNo: this.orderNo }).then((res) => {
                // 跳转订单详情
                this.$router.replace({
                    name: 'flight-order-detail',
                    query: {
                        orderNo: this.orderNo,
                        evectionType: this.evectionType
                    }
                })
            });
        },
        submit() {
            const __this = this;
            const selected_payment_channel_entity_list = __this.$refs.aPaymentMethodSelector000.get_selected_payment_channel_entity_list();
            if (selected_payment_channel_entity_list.length <= 0) {
                Toast(`请选择支付方式！`);
                return;
            }
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "",
            });
            __this.$refs.aPaymentMethodSelector000.trigger_pay();
        },
        showShareDetail() {
            Dialog.alert({
                title: `实际承运-${this.detail.sharedAirCompanyName}${this.detail.sharedFlightNo}`,
                message: '请您前往实际承运的航司值机柜台办理登记手续',
                confirmButtonColor: '#FF9C00'
            }).then(() => {
                // on close
            });
        },
        backStopDetail() {
            if (this.evectionInfo.stopNum > 0) {
                this.stopDataShow = true
                this.stopData = []
                this.stopDataLoading = true
                this.stopData = this.detail.confirmationDetails.stopData.stopInfos
            }
        },
        // 关闭经停信息弹窗
        closeStopDataShow() {
            this.stopDataShow = false
        },
        getProductDetail() {
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.orderNo}).then(res=>{
                this.productDetail = res.datas;
            })
        },
        showProductDetail() {
            this.upLevelInfoDetailShow = true;
        },
    }
}
