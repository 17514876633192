/**
 * 通过出生日期获取当前年龄
 * @param strBirthday：指的是出生日期，格式为"1990-01-01",字符串类型
 * @param strDate: 指比较的日期，格式为“2023-01-01”， 字符串类型
 */
let getCurrentAgeByBirthDate = function(strBirthday, strDate){
  // 将出生日期的字符串通过"-"分割成数组
  const strBirthdayArr = strBirthday.split("-")
  // 拿到出生日期的年
  const birthYear = strBirthdayArr[0]
  // 拿到出生日期的月
  const birthMonth = strBirthdayArr[1]
  // 拿到出生日期的日
  const birthDay = strBirthdayArr[2]
  // 创建一个时间对象
  const d = strDate ? new Date(strDate) : new Date();
  // 拿到当前时间的年
  const nowYear = d.getFullYear()
  // 拿到当前时间的月
  const nowMonth = d.getMonth() + 1
  // 拿到当前时间的日
  const nowDay = d.getDate()
  // 如果出生日期的年等于当前时间的年
  if(nowYear === birthYear) return 0 // 返回周岁年龄 0,并终止函数执行
  // 如果如果出生日期的年不等于于当前时间的年,则拿到年之差
  const ageDiff = nowYear - birthYear ; // 年之差
  // 如果年之差是个负数,则表示用户输入的出生日期错误,晚于今天,返回 -1,并终止函数执行
  if(ageDiff < 0) return -1 // 返回错误 -1,并终止函数执行
  // 如果年之差是个正整数,但出生日期的月与当前时间的月不相等
  if(nowMonth !== birthMonth){
    // 拿到出生日期的日与当前时间的月之差
    const monthDiff = nowMonth - birthMonth; // 月之差
    // 如果月之差是个负数,则年之差 - 1后得到周岁年龄,否则直接得到周岁年龄
    return monthDiff < 0 ? ageDiff - 1 : ageDiff  // 返回周岁年龄,并终止函数执行
  }
  // 如果出生日期的月与当前时间的月相等,则拿到出生日期的日与当前时间的日之差
  const dayDiff = nowDay - birthDay;
  // 如果日之差是个负数,则年之差 - 1得到周岁年龄,否则直接得到周岁年龄
  return dayDiff < 0 ? ageDiff - 1 : ageDiff // 返回周岁年龄,并终止函数执行
}

// 手机号做脱敏处理
let phoneHide = function(phone) {
  let reg = /^(1[3-9][0-9])\d{4}(\d{4}$)/; // 定义手机号正则表达式
  phone = phone.replace(reg, '$1****$2');
  return phone; // 185****6696
}

export default {
  getCurrentAgeByBirthDate,
  phoneHide,
}
