import store from '@/lib/store';
// 头部
import NavBar from "@/component/nav-bar/import_latest_version_module";
import moment from "moment";

// 接口
// 预算组织
import consumer_budget_getBudgetOrgList from '@/lib/data-service/haolv-default/consumer_budget_getBudgetOrgList';
// 【576】获取预算科目种类
import consumer_budget_getBudgetItem from '@/lib/data-service/haolv-default/consumer_budget_getBudgetItem';
// 【576】获取预算险类种类
import consumer_budget_getBudgetInsurance from '@/lib/data-service/haolv-default/consumer_budget_getBudgetInsurance';
// 【576】获取预算项目种类
import consumer_budget_getBudgetPro from '@/lib/data-service/haolv-default/consumer_budget_getBudgetPro';
// 【576】查看预算
import consumer_budget_getBudgetInfo from '@/lib/data-service/haolv-default/consumer_budget_getBudgetInfo';
// 【576】筛选预算信息
import consumer_budget_getBudgetInfoByCondition from '@/lib/data-service/haolv-default/consumer_budget_getBudgetInfoByCondition';


export default {
  components: {
    NavBar,
  },
  data() {
    return {
      monthSelect: null,
      monthSelectData: {
        usedSettlingAmount: 0,
        usedSettledAmount: 0,
        occupyAmount: 0,
        budgetAmount: 0,
        max: 0,
      },
      monthBox: [],
      maxNumber: 0,
      polar: {},
      budgetInfo: {
        usedSettlingAmount: 0,
        usedSettledAmount: 0,
        occupyAmount: 0,
        residueAmount: 0,
      },
      screenCode_outside: [],
      screenCode: [],
      budgetOrgList: [],   //预算组织
      budgetItem: [],     //预算科目
      budgetInsuranceList: [],     //预算险类
      budgetProList: [],     //项目
      budgetInfoData: {},

      budgetOrgName: '',
      budgetItemName: '',
      budgetInsuranceName: '',
      budgetProName: '',

      budgetMonthMin: '',
      budgetMonthMax: '',
      selectVisibleTitle: '开始预算区间',
      budgetMonth: '',
      selectVisible: false,
      screenVisible: false,
      budgetStartMonth: '',
      budgetEndMonth: '',
      form: {
        budgetStartMonth: '',   // 预算期间月份开始时间
        budgetEndMonth: '',    // 预算期间月份结束时间
        budgetInsuranceCode: '',   // 预算险类
        budgetInsuranceName: '',
        budgetItemCode: '',   // 科目
        budgetItemName: '',
        budgetOrgCode: '',   // 预算组织
        budgetOrgName: '',
        budgetProCode: '',   // 项目
        budgetProName: '',
        currentPage: 1,
        pageSize: 1,
      },
    };
  },
  computed: {

  },
  methods: {
    screenVisibleClose() {
      if (this.screenCode_outside === null) {
        this.screenCode_outside = this.screenCode;
      } else {
        this.screenCode = this.screenCode_outside;
      }
    },
    clickShowBut() {
      this.screenCode_outside = null;
      this.screenVisible = false;
      let dataBox = {
        budgetStartMonth: this.form.budgetStartMonth,
        budgetEndMonth: this.form.budgetEndMonth,
        budgetInsuranceCode: '',
        budgetInsuranceName: this.budgetInsuranceName,
        budgetItemCode: '',
        budgetItemName: this.budgetItemName,
        budgetOrgCode: '',
        budgetOrgName: this.budgetOrgName,
        budgetProCode: '',
        budgetProName: this.budgetProName,
        currentPage: 1,
        keyword: '',
        orderBy: '',
        pageSize: 1,
      };
      this.$showLoading.show();
      consumer_budget_getBudgetInfoByCondition(dataBox).then((res) => {
        this.budgetInfo = res.datas;
        this.maxNumber = 0;
        let monthBox = [];
        res.datas.collectAmountList.forEach((item,index) => {
          let number = Number(`${item.usedSettlingAmount}`) + Number(`${item.usedSettledAmount}`) + Number(`${item.occupyAmount}`) + Number(`${item.budgetAmount}`);
          if (this.monthSelect == null) {
            this.monthSelect = Number(`${item.budgetMonth.split('-')[1]}`);
            this.monthSelectData = {max: number,...item};
          }
          monthBox.push({index: Number(`${item.budgetMonth.split('-')[1]}`),max: number,switchBox: true,...item});
          this.maxNumber = this.maxNumber < number ? number : this.maxNumber;
        });
        this.monthBox = monthBox;
        for (let i = 0;i < 12;i++) {
          let box = null;
          this.monthBox.forEach((item,index) => {
            if (i + 1 == item.index) {
              box = item;
            }
          });
          if (box == null ) {
            this.monthBox[i] = {index: i + 1,switchBox: false,max: 0};
          }
        }
        this.maxNumber = Number((Number(`${this.maxNumber}`.split('.')[0][0]) + 1).toString().padEnd(`${this.maxNumber}`.split('.')[0].length,'0'));
        this.budgetInfoData = res.datas;
      }).finally(() => {
        this.$showLoading.hide();
      });
    },
    clickResetBut() {
      this.screenCode = [];
    },
    getScreenCode(a,b){
      return this.screenCode.indexOf(`${a}${b}`) == -1 ? false : true;
    },
    clickScreenCode(a,b,name,type){
      this.screenCode = this.screenCode.filter(item => item[0] != a);
      if (this.screenCode.indexOf(`${a}${b}`) == -1) {
        this.screenCode.push(`${a}${b}`);
        eval(`this.${name} = '${type}'`);
      } else {
        this.screenCode.splice(this.screenCode.indexOf(`${a}${b}`),1);
        eval(`this.${name} = ''`);
      }
    },
    clickScreenVisible() {
      this.screenVisible = true;
    },
    formatter(type, val) {
      if (type === 'month') {
        return `${val}月`;
      } else if (type === 'day') {
        return `${val}日`;
      }
      return val;
    },
    confirmBudgetMonth() {
      this.selectVisible = false;
      if (this.selectVisibleTitle == '开始预算区间') {
        this.budgetStartMonth = moment(this.budgetMonth).format('YYYY-MM');
        setTimeout(() => {
          let dataMin = moment(this.budgetMonth).subtract(1,"month").format('YYYY-MM').split('-');
          let dataMax = moment(this.budgetMonth).add(10,"month").format('YYYY-MM').split('-');
          this.budgetMonthMin = new Date(Number(`${dataMin[0]}`),Number(`${dataMin[1]}`),1);
          this.budgetMonthMax = new Date(Number(`${dataMax[0]}`),Number(`${dataMax[1]}`),1);
          this.selectVisibleTitle = '结算预算区间';
          this.selectVisible = true;

          setTimeout(() => {
            let dateBox = moment(this.budgetEndMonth).subtract(1,"month").format('YYYY-MM');
            this.budgetMonth = this.budgetMonth ? new Date(Number(`${dateBox.split('-')[0]}`),Number(`${dateBox.split('-')[1]}`)) : '';
          },100)
        }, 300)
      } else {
        this.selectVisibleTitle = '';
        this.budgetEndMonth = moment(this.budgetMonth).format('YYYY-MM');

        this.form.budgetStartMonth = this.budgetStartMonth;
        this.form.budgetEndMonth = this.budgetEndMonth;
        this.clickShowBut();
      }
    },
    cancelBudgetMonth() {
      this.selectVisibleTitle = '';
      this.selectVisible = false;
    },
    clickBudgetMonth() {
      this.selectVisible = true;
      this.selectVisibleTitle = '开始预算区间';
      this.budgetMonthMin = new Date(Number(`${moment().subtract(3,"year").format('YYYY-MM').split('-')[0]}`),0,1);
      this.budgetMonthMax = new Date(Number(`${moment().add(3,"year").format('YYYY-MM').split('-')[0]}`),11,1);

      this.budgetStartMonth = this.form.budgetStartMonth;
      this.budgetEndMonth = this.form.budgetEndMonth;
      setTimeout(() => {
        let dateBox = moment(this.budgetStartMonth).subtract(1,"month").format('YYYY-MM');
        if (this.form.budgetStartMonth) {
          this.budgetMonth = this.form.budgetStartMonth;
        }
        this.budgetMonth = this.budgetMonth ? new Date(Number(`${dateBox.split('-')[0]}`),Number(`${dateBox.split('-')[1]}`)) : new Date();
      },100)
    },
    back() {
      if(this.$route.query.form == 'flutter') {
        // 判断是否在flutter app里
        if (window.getFlutter == true) {
          // 如果在，则向flutter app发送信息
          try {
            window.flutter_inappwebview
            //可以传递你所需要的任意类型数据，数组、对象等
              .callHandler("backHandler", {'type': 'close'})
              .then((res)=>{
                console.log("res==flutter给html的数据", res);
              })
          } catch (e) {}

        }

      } else {
        this.$router.go(-1);
      }
    },
    clickMonth(value) {
      this.monthBox.forEach((item,index) => {
        item.clickBox = false;
      });
      if (value.switchBox) {
        value.clickBox = true;
        this.monthSelect = value.index;
        this.monthSelectData = value;
      }
    },
  },
  async mounted(){
    await this.$store.state.policyDefer.promise;
    this.subsidyControlRule = store.state.policy.subsidyControlRule;
    await this.$store.state.userInfoDefer.promise;

    this.budgetOrgList = [];
    let functionBox = (list,text) => {
      let textBox = '';
      list.forEach((item,index) => {
        textBox = text ? `${text}>${item.budgetOrgName}` : `${item.budgetOrgName}`;
        let box = [];
        if (item.childrenList) {
          item.childrenList.forEach((FItem,FIndex) => {
            box.unshift({budgetOrgName: FItem.budgetOrgName})
            if (FItem.childrenList) {
              functionBox(item.childrenList,item.budgetOrgName);
            }
          });
        }
        this.budgetOrgList.unshift({
          title: textBox,
          value: box,
        });
      });
    };

    consumer_budget_getBudgetOrgList({companyId: this.$store.state.userInfo.companyId}).then(res => {
      // this.budgetOrgList = res.datas.departmentListResults;
      // res.datas.departmentListResults = [
      //   {
      //     budgetOrgName: "开发部-1",
      //     childrenList: [
      //       {
      //         budgetOrgName: "开发部-2",
      //         childrenList: [
      //           {
      //             budgetOrgName: "开发部-3",
      //             childrenList: null
      //           }
      //         ]
      //       }
      //     ]
      //   },
      //   {
      //     budgetOrgName: "飞机部-1",
      //     childrenList: [
      //       {
      //         budgetOrgName: "飞机部-2",
      //         childrenList: [
      //           {
      //             budgetOrgName: "飞机部-3",
      //             childrenList: null
      //           }
      //         ]
      //       }
      //     ]
      //   }
      // ];
      functionBox(res.datas.departmentListResults,'');
    });
    consumer_budget_getBudgetItem().then(res => {
      this.budgetItem = res.datas;
      this.budgetItem.unshift({value: '无限'});
    });
    consumer_budget_getBudgetInsurance().then(res => {
      this.budgetInsuranceList = res.datas;
      this.budgetInsuranceList.unshift({value: '无限'});
    });
    consumer_budget_getBudgetPro().then(res => {
      this.budgetProList = res.datas;
      this.budgetProList.unshift({value: '无限'});
    });
    this.$showLoading.show();
    consumer_budget_getBudgetInfo({evectionNo: this.$route.query.evectionNo}).then(res => {
      this.budgetInfo = res.datas;
      this.form.budgetEndMonth = res.datas.budgetEndMonth;
      this.form.budgetStartMonth = res.datas.budgetStartMonth;

      this.maxNumber = 0;
      let monthBox = [];
      // res.datas.collectAmountList = [
      //   {
      //     budgetMonth: '2024-01',
      //     usedSettlingAmount: 1000,
      //     usedSettledAmount: 1000,
      //     occupyAmount: 1000,
      //     budgetAmount: 1000,
      //   },
      //   {
      //     budgetMonth: '2024-02',
      //     usedSettlingAmount: 888,
      //     usedSettledAmount: 222,
      //     occupyAmount: 999,
      //     budgetAmount: 111,
      //   },
      //   {
      //     budgetMonth: '2024-03',
      //     usedSettlingAmount: 867,
      //     usedSettledAmount: 129,
      //     occupyAmount: 109,
      //     budgetAmount: 281,
      //   },
      //   {
      //     budgetMonth: '2024-04',
      //     usedSettlingAmount: 12,
      //     usedSettledAmount: 129,
      //     occupyAmount: 128,
      //     budgetAmount: 899,
      //   },
      //   {
      //     budgetMonth: '2024-05',
      //     usedSettlingAmount: 120,
      //     usedSettledAmount: 932,
      //     occupyAmount: 123,
      //     budgetAmount: 49,
      //   },
      //   {
      //     budgetMonth: '2024-06',
      //     usedSettlingAmount: 298,
      //     usedSettledAmount: 238,
      //     occupyAmount: 126,
      //     budgetAmount: 3128,
      //   },
      //   {
      //     budgetMonth: '2024-07',
      //     usedSettlingAmount: 1283,
      //     usedSettledAmount: 120,
      //     occupyAmount: 123,
      //     budgetAmount: 764,
      //   },
      //   {
      //     budgetMonth: '2024-08',
      //     usedSettlingAmount: 112,
      //     usedSettledAmount: 120,
      //     occupyAmount: 437,
      //     budgetAmount: 8,
      //   },
      //   {
      //     budgetMonth: '2024-09',
      //     usedSettlingAmount: 120,
      //     usedSettledAmount: 46,
      //     occupyAmount: 22,
      //     budgetAmount: 248,
      //   },
      //   {
      //     budgetMonth: '2024-10',
      //     usedSettlingAmount: 357,
      //     usedSettledAmount: 467,
      //     occupyAmount: 78,
      //     budgetAmount: 121,
      //   },
      //   {
      //     budgetMonth: '2024-11',
      //     usedSettlingAmount: 346,
      //     usedSettledAmount: 1,
      //     occupyAmount: 436,
      //     budgetAmount: 19,
      //   },
      //   {
      //     budgetMonth: '2024-12',
      //     usedSettlingAmount: 1000,
      //     usedSettledAmount: 1000,
      //     occupyAmount: 1000,
      //     budgetAmount: 1000,
      //   },
      // ];
      res.datas.collectAmountList.forEach((item,index) => {
        let number = Number(`${item.usedSettlingAmount}`) + Number(`${item.usedSettledAmount}`) + Number(`${item.occupyAmount}`) + Number(`${item.budgetAmount}`);
        if (this.monthSelect == null) {
          this.monthSelect = Number(`${item.budgetMonth.split('-')[1]}`);
          this.monthSelectData = {max: number,...item};
        }
        monthBox.push({index: Number(`${item.budgetMonth.split('-')[1]}`),max: number,switchBox: true,clickBox: false,...item});
        this.maxNumber = this.maxNumber < number ? number : this.maxNumber;
      });
      this.monthBox = monthBox;
      for (let i = 0;i < 12;i++) {
        let box = null;
        this.monthBox.forEach((item,index) => {
          if (i + 1 == item.index) {
            box = item;
          }
        });
        if (box == null ) {
          this.monthBox[i] = {index: i + 1,switchBox: false,max: 0};
        }
      }
      this.maxNumber = Number((Number(`${this.maxNumber}`.split('.')[0][0]) + 1).toString().padEnd(`${this.maxNumber}`.split('.')[0].length,'0'));
      // usedSettlingAmount --- blue
      // usedSettledAmount --- orange
      // occupyAmount --- green
      // budgetAmount --- red
      this.budgetInfoData = res.datas;
    }).catch((rej) => {
      this.budgetInfo = null;
    }).finally(() => {
      this.$showLoading.hide();
      if (this.budgetProList.length != 0) {
        this.budgetProList.forEach((item,index) => {
          if (item.value == this.budgetInfoData.budgetProName) {
            this.clickScreenCode(3,index,'budgetProName',item.value);
          }
        });
      }
      if (this.budgetInsuranceList.length != 0) {
        this.budgetInsuranceList.forEach((item,index) => {
          if (item.value == this.budgetInfoData.budgetInsuranceName) {
            this.clickScreenCode(2,index,'budgetInsuranceName',item.value);
          }
        });
      }
      if (this.budgetItem.length != 0) {
        this.budgetItem.forEach((item,index) => {
          if (item.value == this.budgetInfoData.budgetItemName) {
            this.clickScreenCode(1,index,'budgetItemName',item.value);
          }
        });
      }
      if (this.budgetOrgList.length != 0) {
        this.budgetOrgList.forEach((item,index) => {
          item.value.forEach((CItem,CIndex) => {
            if (CItem.budgetOrgName == this.budgetInfoData.budgetOrgName) {
              this.clickScreenCode(0,`${index}${CIndex}`,'budgetOrgName',CItem.budgetOrgName);
            }
          });
        });
      }
      this.screenCode_outside = this.screenCode;
    });

  },
  watch: {

  },
};
