import {Notify, Toast} from "vant";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import AccountProcessStep0002
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/12306-account-process-step/12306-account-process-step-0002/1.1.0/index.vue'

export default {
    data() {
        return {
            title: '12306账号核验',
            backDialogVisible: false,
            flutterSwitch: false,
        }
    },
    components: {
        NavBar,
        AccountProcessStep0002
    },
    mounted() {
        this.$refs.accountProcessStep0002.init()
    },
    methods: {
        clickChange() {
            this.addNewAccount();
        },
        addNewAccount() {
            this.$router.push({
                name: 'h5-train-account-depute'
            });
        },
        checkResult(result) {
            switch (result.checkCode) {
                case '000000':
                    Toast.success(result.checkMsg);
                    this.back();
                    break;
                default:
                    Toast.fail(result.checkMsg);
                    break
            }
        },
        beforeBack() {
            this.backDialogVisible = true;
        },
        back() {
            this.flutterSwitch = window.getFlutter;
            if (this.flutterSwitch == true) {
                // 如果在，则向flutter app发送信息
                try {
                    window.flutter_inappwebview
                    //可以传递你所需要的任意类型数据，数组、对象等
                        .callHandler("backHandler", {'type': 'close'})
                        .then((res)=>{
                            console.log("res==flutter给html的数据", res);
                        })
                } catch (e) {}
                return
            }
            this.backDialogVisible = false;
            this.$router.go(-1);
        }
    },
};
