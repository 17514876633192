export default {
    data() {
        return {
            showMore: true
        }
    },
    props: {
        violationResults: {
            type: Array,
            default: () => []
        }
    },
    components: {

    },
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        resultList() {
            let res = [];
            let indexList = [];
            if (this.violationResults && this.violationResults.length > 0) {
                this.violationResults.forEach(value => {
                    if (indexList.indexOf(value.segmentSerial) == -1) {
                        indexList.push(value.segmentSerial);
                        res.push([]);
                    }
                    let index = indexList.indexOf(value.segmentSerial);
                    res[index].push(value);
                })
            }
            return res;
        }
    },
    filters: {},
    methods: {
        more() {
            this.showMore = !this.showMore
        }
    }
}
