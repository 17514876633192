export default {
    model: {
        prop: 'input',
        event: 'input'
    },
    props: {
        input: String,
        placeholder: {
            type: String,
            default: '订单号/姓名/城市'
        }
    },
    data() {
        return {}
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        onSearch() {
            this.$emit('on-search');
        },
        onClickSecretary() {
            this.$emit('on-secretary');
        },
    }
}