import Q from "q";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/payment-method-selector/1.2.1/payment-method-selector-000/index.vue';
import {Dialog, Toast} from "vant";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
import consumer_admin_flight_checkFlightPay from '@/lib/data-service/haolv-default/consumer_admin_flight_checkFlightPay'
import consumer_journey_orderTotal from '@/lib/data-service/haolv-default/consumer_journey_orderTotal'
import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail';
import consumer_admin_flight_equity_getOrderProductInfo from '@/lib/data-service/haolv-default/consumer_admin_flight_equity_getOrderProductInfo';
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";


export default {
    components: {
        PaymentMethodSelector000,
        NavBar,
    },
    data() {
        return {
            businessType: '', // 1机票，2火车票，3酒店
            evectionNo: '',
            dataBox: '',
            orderNos: [],
            orderType: "",
            pageType: "",
            detail: {},
            flightOrderList: [],

            detailShow: false,
            priceInfo: {
              serviceSettlementType: 0,
            },
            payInfo: {},
            payAmount: 0,
            outPayAmount: 0,

            milliseconds: -1, // 倒计时 -1时不显示 秒
            countdownFun: null,

            trainOrderType: 1, // 1: 正常预订 2: 抢票
            trainDetail: {}, // 火车票详情

            productDetail: {}, // 加享产品详情
            upLevelInfoDetailShow: false,
        };
    },
    computed: {
        countdownTimeStr() {
            let milliseconds = this.milliseconds;
            if (milliseconds && milliseconds > 0) {
                // 转换为式分秒
                let h = parseInt(milliseconds / 60 / 60 % 24);
                h = h < 10 ? '0' + h : h;
                let m = parseInt(milliseconds / 60 % 60);
                m = m < 10 ? '0' + m : m;
                let s = parseInt(milliseconds % 60);
                s = s < 10 ? '0' + s : s;
                // 作为返回值返回
                return `${h}:${m}:${s}`
            } else {
                return '00:00:00'
            }
        },
        // 是否展示服务费：true：展示，false：不展示
        showSeverPriceTips() {
            let showTips = false;
            if (this.priceInfo.flightAmountsList && this.priceInfo.flightAmountsList.length > 0) {
                showTips = this.priceInfo.flightAmountsList[0].showServiceFee === 1;   // 0不展示 1展示
            }
            if (this.priceInfo.hotelTotalAmountList && this.priceInfo.hotelTotalAmountList.length > 0) {
                showTips = this.priceInfo.hotelTotalAmountList[0].showServiceFee === 1;   // 0不展示 1展示
            }
            if (this.priceInfo.trainTotalAmountList && this.priceInfo.trainTotalAmountList.length > 0) {
                showTips = this.priceInfo.trainTotalAmountList[0].showServiceFee === 1;   // 0不展示 1展示
            }
            let hasShouXinPayType = false;
            if (!this.payInfo.paymentChannels) {
                return false;
            }
            this.payInfo.paymentChannels.forEach(value=>{
                if (value.code === 24) {
                    hasShouXinPayType = true;
                }
            })

            return showTips && hasShouXinPayType;
        },
    },
    mounted(){
        const __this = this;
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                __this.$refs['reserveBottom'].style.paddingBottom = `${api.safeArea.bottom + 8}px`;
                __this.$refs['reserveDetailBottom'].style.paddingBottom = `${api.safeArea.bottom}px`;
            }
        });
    },
    activated() {
        const __this = this;
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback:(result) => {
                    __this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            },200)
        });

        this.init();
        this.$refs.aPaymentMethodSelector000.get_data().then(res=>{
            console.log('aa', res);
            this.payInfo = res;
            let milliseconds = res.milliseconds;
            if (milliseconds > 0) {
                this.milliseconds = milliseconds;
                // 倒计时开始
                this.startCountdown();
            } else if (milliseconds === 0) {
                this.milliseconds = milliseconds;
                // 弹出提示
                this.showTimeEndDialog();
            } else {
                this.milliseconds = -1;
            }
        });
        keybackProcessor.addEventListener(() => {
            if (this.detailShow) {
                this.detailShow = false;
                return
            }
            this.close_current_page()
        })
    },
    deactivated() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });

        clearInterval(this.countdownFun);
        keybackProcessor.removeEventListener();
    },
    deactivate() {
        this.$Toast.clear();
    },
    filters: {},
    watch: {},
    methods: {
        showDetail() {
            this.detailShow = !this.detailShow
        },
        getAllPrice() {
            const params = {
                orderNos: this.orderNos
            };
            consumer_journey_orderTotal(params).then(res => {
                let priceInfo = res.datas;
                let i = 1;
                if (priceInfo && priceInfo.flightAmountsList) {
                    priceInfo.flightAmountsList.forEach(value => {
                        value.index = i;
                        i++
                    })
                }
                if (priceInfo && priceInfo.trainTotalAmountList) {
                    priceInfo.trainTotalAmountList.forEach(value => {
                        value.index = i;
                        i++
                    })
                }
                if (priceInfo && priceInfo.hotelTotalAmountList) {
                    priceInfo.hotelTotalAmountList.forEach(value => {
                        value.index = i;
                        i++
                    })
                }
                this.priceInfo = priceInfo
            })
        },
        init() {
            this.businessType = this.$route.query.businessType ? this.$route.query.businessType : '';
            this.evectionNo = this.$route.query.evectionNo ? this.$route.query.evectionNo : '';
            this.orderNos = this.$route.query.orderNo ? this.$route.query.orderNo.split(',') : [];
            this.flightOrderList = this.$route.query.flightOrderList ? this.$route.query.flightOrderList.split(',') : [];
            this.orderType = Number(this.$route.query.orderType) || 4;
            this.pageType = Number(this.$route.query.pageType) || 2;
            this.dataBox = this.$route.query.dataBox;
            this.paymentInit();
            this.getAllPrice();
            this.getOrderDetail();
            if (this.businessType == 1) {
                this.getProductDetail();
            }
        },

        getProductDetail() {
            consumer_admin_flight_equity_getOrderProductInfo({orderNo: this.orderNos[0]}).then(res=>{
                this.productDetail = res.datas || {};
            })
        },

        async getOrderDetail() {
            if (this.businessType === 2) {
                let [err, res] = await awaitWrap(consumer_trains_order_getTrainsOrderDetail({orderNo: this.orderNos[0]}));
                if (err) {
                    return
                }
                this.trainDetail = res.datas;
                this.trainOrderType = res.datas.orderType;
            }
        },

        paymentInit() {
            const __this = this;
            let orderForPayVos = [];
            this.orderNos.forEach(value => {
                const params = {
                    orderNo: value,
                    businessContent: ''
                };
                orderForPayVos.push(params)
            });
            const init_000 = function() {
                __this.$refs.aPaymentMethodSelector000.init({
                    page_this: __this,
                    mock_data: {
                        enable: false,
                        // enable: true,

                        // wait_seconds: 2,
                        wait_seconds: 0.5,

                        //000:只有支付宝
                        //100:只有授信支付
                        //200:同时有授信支付和支付宝
                        // test_case: `000`,
                        // test_case: `100`,
                        test_case: `200`,
                    },
                    //在这里传入订单详情的相关参数
                    get_params() {
                        const p = {
                            "orderForPayVos": orderForPayVos,
                            "payType":  __this.pageType !== 3 ? 1 : 2
                        };
                        console.log(__this.pageType);
                        console.log(p);
                        return Q.resolve(p);
                    },

                    //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                    before_pay_event_handler() {
                        // alert(`不能够支付！原因：XXX`);
                        // return Q.reject();
                        if (__this.flightOrderList.length > 0 && __this.pageType !== 3) {
                            // 如果有机票订单，则验价
                            return consumer_admin_flight_checkFlightPay({orderNo: __this.flightOrderList}).then(res => {
                                Toast.clear();
                                return Q.resolve();
                            }, error => {
                                Toast.clear();
                                // __this.errorText = error.data.msg
                                // __this.errorDialog = true
                                Dialog.alert({
                                    title: '温馨提示',
                                    message: error.data.msg,
                                }).then(() => {
                                    // on close
                                    // 因为有可能是从机票订单列表或详情进来支付的，所以要返回上一页
                                    if (error.data.code === '200029') {
                                        __this.$router.go(-1)
                                    } else {
                                        __this.init()
                                    }

                                });
                                //__this.init()
                                return Q.reject()
                            }).finally(() => {
                                // Toast.clear();
                            })
                        } else {
                            return Q.resolve();
                        }




                        //__this.$Toast.clear();
                        //console.log(`before_pay_event_handler`);
                        //return Q.resolve();
                    },

                    //用户取消支付的事件处理方法
                    user_cancel_pay_event_handler() {
                        Toast.clear();
                        // __this.$router.push({
                        //     name: "h5-no-payment",
                        //     query: __this.$route.query,
                        // });
                    },

                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        Toast.clear();
                        __this.$router.replace({
                            name: "h5-payment-success",
                            query: {
                                businessType: __this.businessType,
                                orderType: __this.orderType,
                                pageType: __this.pageType,
                                orderNo: __this.orderNos.length > 0 ? __this.orderNos[0] : ''
                            }
                        });
                        console.log(`pay_successful_event_handler`);
                    },
                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        Toast.clear();
                        console.log(`pay_failed_event_handler`);
                    },
                    //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                    dialog_code_300052_btn_ok_click_event_handler() {
                        Toast.clear();
                        console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                        __this.$router.replace({
                            name: `h5-office-evection`,
                        });
                        return Q.resolve(true);
                        // return true;
                    },
                });

                __this.$refs.aPaymentMethodSelector000.get_data().then(data => {
                    __this.outPayAmount = 0;
                    __this.payAmount = data.payAmount;
                    if (data.paymentChannels && data.paymentChannels.length > 1) {
                        let hasCompanyPay = false;
                        data.paymentChannels.forEach(value => {
                            if (value.code === 24) {
                                hasCompanyPay = true
                            }
                        });
                        if (hasCompanyPay) {
                            data.paymentChannels.forEach(value => {
                                if (value.code !== 24) {
                                    __this.outPayAmount = value.payAmount
                                }
                            })
                        }
                    }
                })
            };

            init_000();
        },

        close_current_page() {
            if (this.milliseconds > 0 || this.milliseconds === -1) {
                // 倒计时没结束或不用倒计时
                Dialog.confirm({
                    title: '温馨提示',
                    message: '您的订单尚未完成支付，确认要离开？',
                    confirmButtonText: '确认离开',
                    cancelButtonText: '继续支付',
                    confirmButtonColor: '#FF7002',
                }).then(() => {
                        switch (Number(this.businessType)) {
                            case 1:
                                if (this.pageType === 3) {
                                    // 去改签单详情
                                    this.$router.replace({
                                        name: 'flight-change-order-detail',
                                        query: {
                                            orderNo: this.orderNos[0],
                                            jumpType: 'list'
                                        }
                                    });
                                } else {
                                    // 去机票订单详情页
                                    this.$router.replace({
                                        name: 'flight-order-detail',
                                        query: {
                                            orderNo: this.orderNos[0],
                                            jumpType: 'list'
                                        }
                                    });
                                }

                                break;
                            case 2:
                                if (this.pageType === 3) {
                                    // 去火车票订单改签详情页
                                    this.$router.replace({
                                        name: 'h5-train-change-order-detail',
                                        query: {
                                            orderNo: this.orderNos[0],
                                            jumpType: 'list'
                                        }
                                    });
                                } else {
                                    // 去火车票订单详情页
                                    this.$router.replace({
                                        name: 'h5-train-order-detail',
                                        query: {
                                            orderNo: this.orderNos[0],
                                            jumpType: 'list'
                                        }
                                    });
                                }

                                break;
                            case 3:
                                // 去酒店订单详情页
                                this.$router.replace({
                                    name: 'h5-hotel-order-detail',
                                    query: {
                                        orderNo: this.orderNos[0],
                                        jumpType: 'list'
                                    }
                                });
                                break;
                            case 4:
                                // 去火车票改签订单详情页
                                // /h5/train-change-order-detail?orderNo=29535003208400&status=3
                                this.$router.replace({
                                    name: 'h5-train-change-order-detail',
                                    query: {
                                        orderNo: this.orderNos[0],
                                        jumpType: 'list'
                                    }
                                });
                                break;
                            case 5:
                                // 去火车票改签详情页
                                this.$router.replace({
                                  name: 'h5-train-change-result',
                                  query: {
                                    changeNo: this.orderNos[0],
                                    orderNo: this.dataBox,
                                  }
                                });
                                break;
                            default:
                                this.$router.go(-1)
                        }
                        // on confirm
                        /*const orderType = this.orderType;
                        if (orderType === 1) {
                            this.$router.replace({
                                name: 'h5-flight-order-list',
                            })
                        } else if (orderType === 2) {
                            this.$router.go(-1)
                        } else if (orderType === 3) {
                            //页面回退
                            this.$router.go(-1);
                        } else if (orderType === 4) {
                            //页面回退
                            this.$router.go(-1);
                        } else if (orderType === 5) {
                            // 去机票订单详情页
                            this.$router.replace({
                                name: 'flight-order-detail',
                                query: {
                                    orderNo: this.orderNos[0],
                                    jumpType: 'list'
                                }
                            })
                        } else if (orderType === 6) {
                            // 去火车票订单详情页
                            this.$router.replace({
                                name: 'h5-train-order-detail',
                                query: {
                                    orderNo: this.orderNos[0],
                                    jumpType: 'list'
                                }
                            })
                        } else if (orderType === 7) {
                            // 去酒店订单详情页
                            this.$router.replace({
                                name: 'h5-hotel-order-detail',
                                query: {
                                    orderNo: this.orderNos[0],
                                    jumpType: 'list'
                                }
                            })
                        }else {
                            this.$router.replace({
                                name: 'h5-personal-center',
                            })
                        }*/
                }).catch(() => {
                    // on cancel
                });
            } else {
                // 超时
                // 13920 APP端酒店/飞机/火车票 支付界面优化 禅道bug
                this.outTimeToReturn()
            }

        },
        outTimeToReturn() {
            // 13920 APP端酒店/飞机/火车票 支付界面优化 禅道bug
            switch (parseInt(this.businessType)) {
                case 1:
                    this.$router.replace({
                        // name: 'h5-flight-order-list',
                        name: 'h5-flight-home',
                    });
                    break;
                case 2:
                    this.$router.replace({
                        // name: 'h5-train-order-list',
                        name: 'h5-train-home',
                    });
                    break;
                case 3:
                    this.$router.replace({
                        // name: 'h5-hotel-order-list',
                        name: 'h5-hotel-home'
                    });
                    break;
                default:
            }
        },
        payment() {
            const __this = this;
            const selected_payment_channel_entity_list = __this.$refs.aPaymentMethodSelector000.get_selected_payment_channel_entity_list();
            if (selected_payment_channel_entity_list.length <= 0) {
                alert(`请选择支付方式！`);
                return;
            }
            // 如果有授信支付-存在个人超额的时候，需要判断除了授信之外还需另外选择一个支付方式
            if (this.outPayAmount !== 0 && selected_payment_channel_entity_list.length <= 1) {
                alert(`请选择个人支付方式！`);
                return;
            }
            // 倒计时结束不让支付
            if (this.milliseconds === 0) {
                alert(`支付超时！`);
                return;
            }

            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            __this.$refs.aPaymentMethodSelector000.trigger_pay();
            // this.$router.replace({
            //   name: "h5-payment-success",
            // });
        },
        // 倒计时
        startCountdown() {
            if (this.countdownFun) {
                clearInterval(this.countdownFun);
            }
            this.countdownFun = setInterval(()=>{
                this.milliseconds--;
                if (this.milliseconds === 0) {
                    clearInterval(this.countdownFun);
                    // 弹出提示
                    this.showTimeEndDialog();
                }
            }, 1000)
        },
        showTimeEndDialog() {
            Dialog.alert({
                title: '温馨提示',
                message: '支付超时，请重新下单',
                confirmButtonText: '我知道了'
            }).then(() => {
                // on close
                // 13920 APP端酒店/飞机/火车票 支付界面优化 禅道bug
                this.outTimeToReturn()
            });
        },
        matchFlightPrice(item) {
            let result = 0;
            result = parseFloat(item.ticketPrice || 0) + parseFloat(item.totalAirportTax|| 0);
            // 加上加享产品
            if (item.bookingEquityProductInfos) {
                item.bookingEquityProductInfos.forEach(value=>{
                    result += parseFloat(value.markupPrice || 0);
                })
            }
            return result;
        },
        showProductDetail() {
            this.upLevelInfoDetailShow = true;
        },
    },
};
