import StopData from '@/page/travel-book/flight/component/stopData/1.0.0/index.vue'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
export default {
    data() {
        return {}
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        stopData: {
            type: Array,
            default: () => []
        },
    },
    components: {StopData, NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        stopDataShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        },
        stopDataLoading: {
            get () {
                return this.loading
            },
            set (val) {
                this.$emit('update:loading', val)
            }
        }
    },
    filters: {},
    methods: {
        closeStopDataShow() {
            this.stopDataShow = false
        },
    }
}