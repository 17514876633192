import consumer_user_updateEmail from "@/lib/data-service/haolv-default/consumer_user_updateEmail";// 绑定邮箱

export default {
    data() {
        return {
            oldEmail: '', //旧邮箱
            newEmail: '', //新邮箱
            placeholderTxt: '请输入新邮箱',//提示语
            errMsg: '', //错误信息
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        //初始化
        init() {
            this.oldEmail = this.$route.query.oldEmail;
            this.placeholderTxt = this.oldEmail ? '请输入新邮箱' : '请输入需绑定的邮箱'
            this.newEmail = '';
            this.errMsg = '';
        },

        //提交
        async submit() {
            if (!this.newEmail) {
                this.errMsg = this.placeholderTxt;
                return;
            }

            const regExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/; // 定义邮箱格式的正则表达式
            if (!regExp.test(this.newEmail)) {
                this.errMsg = '新邮箱格式不正确';
                return;
            }

            consumer_user_updateEmail({email: this.newEmail}).then(res => {
                if (res.code !== '000000') {
                    this.errMsg = res.msg;
                    return;
                }
                this.$toast('修改邮箱成功');
                this.back();
            }).catch(err => {
                this.$toast(err.data.msg);
                return;
            });

        },

        //返回
        back() {
            this.$router.go(-1);
        },
    }
}
