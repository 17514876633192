//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { rollback, getRollbackNodes } from "@/lib/data-service/haolv-default/design";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Toast} from 'vant';

export default {
  name: "RollbackModal",
  components: {NavBar},
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
          rollbackName: '',
        rollbackId: "",
        comments: "",
        imageList: [],
        fileList: [],
      },
      options: [],
      rules: {
        rollbackId: [
          {
            required: true,
            message: "请选择要回退到的节点",
            trigger: "change",
          },
        ],
      },
      showPicker: false,
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate().then(() => {
          const { imageList, fileList, ...restParams } = this.formValue;
          const params = {
              ...this.processInfo,
              ...restParams,
              attachments: [...imageList, ...fileList],
          };
          this.loading = true;
          rollback(params)
              .then(() => {
                  this.loading = false;
                  Toast.success("操作成功");
                  this.handleCancel();
                  this.$emit("success");
              })
              .finally(() => {
                  this.loading = false;
              });
      }).catch(()=>{});
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetValidation();
      this.formValue = {
        rollbackId: "",
        comments: "",
        imageList: [],
        fileList: [],
      };
      this.options = [];
      this.show = false;
        this.$emit("cancel");
    },
    // 打开弹框操作
    handleOpen() {
      const { processInstanceId, taskId } = this.processInfo;
      getRollbackNodes({ processInstanceId, taskId }).then((res) => {
        const nodes = res.result || {};
          let options = [];
          Object.keys(nodes).forEach(key => {
              if (parseInt(this.$route.query.approveType) !== 1 && nodes[key] === '发起人') {
                  return;
              }
              options.push({
                  label: nodes[key],
                  value: key,
              })
          })
          console.log(options);
          this.options = options;

        /*this.options = Object.keys(nodes).map((key) => {
          return {
            label: nodes[key],
            value: key,
          };
        });*/
      });
    },
    onConfirm(value) {
        console.log(value);
        this.formValue.rollbackName = value.label;
      this.formValue.rollbackId = value.value;
      this.showPicker = false;
    },
  },
};
