import ColorIcon from "@/component/color-icon/index.vue";
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";


import DownloadAttachment from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/download_attachment/index";
//获取审批信息
import consumer_apply_getApprovalProgress from "@/lib/data-service/haolv-default/consumer_apply_getApprovalProgress.js";
//获取数据接口
import evectionDC from "@/lib/data-service/haolv-default/consumer_evection_evectionDC.js";
//酒店取消接口
import hotel_order from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_cancelOrder";
//火车取消接口
import trains_order from "@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel";
//飞机取消接口
import air_order from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";
import moment from "moment";
import {Toast} from "vant";
import {getCarUserShow} from "@/lib/ohter";
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_web_company_checkCompanyUseCar from '@/lib/data-service/haolv-default/consumer_web_company_checkCompanyUseCar'
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import {formatStartNodeShow} from "@/lib/data-service/haolv-default/design";
import {hideTrainOpenCompanyIdList} from "@/lib/ohter/index.js";
// 滑屏返回

export default {
  components: {
    ColorIcon,
    ListEmpty,
      OrangeNavBar,
  },
  data() {
    return {
      hideTrainOpenCompanyIdList: hideTrainOpenCompanyIdList,
      companyId: '',
      netErr: false,
      isFinish:true,
      //  审批进度信息
      recordList: [],
        workflowList: [], // 新审批流
      //  审批进度弹框
      showApproval: false,
      tripNum: 1,
      uploadFileList: [], //文件列表
      divOff: true,
      dataUser: {
          accessoryUrl: [],
        journeys: [
          {}
        ]
      },
      dataOld: {
        accessoryUrl: "123,321,5456",
        creatorName: "冯纪源",
        endDate: "2021-08-06",
        evectionNo: "1268499472100021",
        evectionStatus: "待审核",
        explainDesc: "测试数据",
        feeAffiliation: "组织架构",
        gmtCreate: "2021-07-21 14:45:47",
        journeyList: [
          {
            businessType: "1",
            businessTypeText: "",
            endTime: "",
            flightJourneyList: [
              {
                airlineName: "南方航空",
                arrAirportName: "大兴机场",
                arrTime: "11:00",
                cabinName: "经济舱",
                cardType: "身份证",
                depAirportName: "白云机场",
                depTime: "08:00",
                flightNo: "CZ3101",
                journeyStatus: "-----",
                orderStatus: "待支付",
                travellerName: "",
                depDate:"2021-07-06",
                depCityName:"广州",
                arrCityName:"北京"
              }
            ],
            hotelJourneyList: [
              {
                arrTime: "2021-07-31",
                breakfast: "0",
                cardType: "身份证",
                cityName: "深圳",
                depAirportName: "",
                depTime: "2021-07-30",
                gmtCreate: "2021-07-28 15:27:04",
                hotelName: "维也纳酒店（宝安翻身地铁站店）",
                hotelRoomTypeName: "高级大床房",
                journeyStatus: "待预定",
                orderStatus: "出票中",
                travellerName: "李总,冯纪源,不及丢",
                roomNum:'1',
              }
            ],
            startTime: "",
            trainJourneyList: [
              {
                arrAirportName: "北京西",
                arrTime: "18:22",
                boardingGates: "boarding_gates",
                cabinName: "二等座",
                cardType: "----",
                depAirportName: "广州南",
                depTime: "07:47",
                duration: "10时35分",
                journeyStatus: "待预定",
                orderStatus: "-1",
                orderStatusText: "已出票",
                trainCode: "G72",
                travellerName: "",
                depDate:"2021-07-06"
              }
            ]
          }
        ],
        reason: "抗疫情",
        staffDetail: "李总,冯纪源,不及丢",
        startDate: "2021-06-22",
        toCityName: "广州"
      },
        isShowUseCarBtn: false,
        useCar: false,
    }
  },
  activated(){

      if (this.dataUser.journeys) {
          this.dataUser.journeys.forEach((item,index) => {
             item.clickGoBookLoading = false;
          });
      }

      const __this = this;
      keyback_processor.addEventListener(function () {

          try {
              if (__this.showApproval) {
                  __this.showApproval = false;
                  return;
              }
          } catch (err) {

          }


          __this.back();
      });
  },
    deactivated() {
        keyback_processor.removeEventListener();
    },
  async mounted(){
      await this.$store.state.workflowDefer.promise;
      this.ReqgetEvectionDetail(this.$route.query.id);

      let userInfo = await get_user_info();
      this.companyId = userInfo.datas.companyId;
      this.isShowUseCarBtn = getCarUserShow(userInfo.datas.companyId, userInfo.datas.id);
      consumer_web_company_checkCompanyUseCar().then(res => {
          this.useCar = res.datas.useCar;
      });
  },
  watch: {

  },
  methods: {
      clickAnnex(item){
          window.open(item);
          /*let pParameter = {
              url: `${item}`,
          };
          DownloadAttachment(pParameter);*/
      },
      getFileType(val){
          let text = val.slice(-8).split('.')[1];
          let box = 4;
          if (text == 'doc' || text == 'docx') {
                box = 1;
          } else if (text == 'xls' || text == 'xlsx') {
                box = 2;
          } else if (text == 'png' || text == 'jpg' || text == 'jpeg') {
                box = 3;
          }

          return box;
      },
      back() {
          this.$router.go(-1);
      },
    obtainEvening(start,end){
        let timeStart = moment(start);
        let timeEnd = moment(end);
        return timeEnd.diff(timeStart, 'day')
    },
    // 去预订、去用车
    clickGoBook(item) {
      item.clickGoBookLoading = true;
      if (item.businessType == 1) {
          // 机票
          // /h5/flight-home?evectionNo=188356084407002&journeyId=0&startDate=2023-07-03&endDate=
          this.$router.push({
              name: 'h5-flight-home',
              query: {
                  journeyId: item.id,
                  evectionNo: item.evectionNo,
                  startDate: item.departTime || '',
                  endDate: item.departTimeEnd || '',
                  type: 'new',
              }
          })
      }
      if (item.businessType == 2) {
          // 火车票
          // /h5/train-home?evectionNo=188350946943002&journeyId=5085&startDate=2023-07-15
          this.$router.push({
              name: 'h5-train-home',
              query: {
                  journeyId: item.id,
                  evectionNo: item.evectionNo,
                  startDate: item.startTime || '',
                  type: 'new',
              }
          })
      }
      if (item.businessType == 3) {
          // 酒店
          // /h5/hotel-home?evectionNo=188350946943002&evectionType=2&journeyId=5084&checkInDate=2023-07-30&checkOutDate=2023-07-31&keyword=&filterType=0&pt=
          this.$router.push({
              name: 'h5-hotel-home',
              query: {
                  evectionNo: item.evectionNo,
                  journeyId: item.id,
                  checkInDate: item.departTime || '',
                  checkOutDate: item.returnTime || '',
                  type: 'new',
              }
          })
      }
      if (item.businessType == 4) {
          // 用车
          if(this.useCar) {
              this.$router.push({
                  name: 'h5-car-home',
                  query: {
                      evectionNo: item.evectionNo,
                      journeyId: item.id,
                  }
              })
          } else {
              Toast('企业暂未开通用车功能，开通请咨询在线客服')
          }
      }
    },
    clickApproval(){
        this.showApproval = true;
        this.netErr = false;
        let evectionNo = this.$route.query.id ? this.$route.query.id : '';
        if (this.$store.state.workflow === '2' && this.recordList.length !== 0) return;
        if (this.$store.state.workflow === '1' && this.workflowList.length !== 0) return;
        if (this.$store.state.workflow === '2') {
            consumer_apply_getApprovalProgress({evectionNo}).then(res => {
                this.recordList = res.datas ? res.datas : [];
                if (this.recordList) {
                    let off = 0;
                    let isFinish = false;
                    this.recordList.forEach((item,index) => {
                        if (item.approveStatus == 2 || item.approveStatus == 3) {
                            off++;
                        }
                        if (item.approveStatus == 2 || item.approveStatus == 3) {
                            isFinish = true
                        } else {
                            isFinish = false
                        }
                        this.isFinish = isFinish;
                    });
                }
            }).catch(() => {
                this.netErr = true;
            })
        }
        if (this.$store.state.workflow === '1') {
            formatStartNodeShow({
                'deptId': this.$store.state.userInfo['deptId'],
                'processInstanceId': this.dataUser.applyNo,
                'userId': this.$store.state.userInfo['id'],
                'budgetAmount': this.dataUser['budgetAmount'] || 0,
            }).then((res)=>{
                this.workflowList = res['result'];
            }).catch(() => {
                this.netErr = true;
            })
        }
    },
    toTypeServe(val,data){
      if (val === 1) {
        this.$router.push({
          name: 'flight-order-detail',
          query: {
            orderNo: data.orderNo
          }
        })
      }
      if (val === 2) {
        this.$router.push({
          name: 'h5-train-order-detail',
          query: {
            orderNo: data.orderNo
          }
        })
      }
      if (val === 3) {
        this.$router.push({
          name: 'h5-hotel-order-detail',
          query: {
            orderNo: data.orderNo
          }
        })
      }
      if (val.businessType === 4) {

      }
    },
    clickDivOff(){
      this.divOff = !this.divOff;
    },
    onTripNum(){
      // this.tripNum = this.tripNum + 1;
      console.log(this.tripNum);
      // this.tripNum = this.tripNum + 1;
      // return this.tripNum
    },
    // 上传文件之前
    onBeforeRead(file) {
      let _fileSize = 3 * 1024 * 1024;
      if (file.size > _fileSize) {
        Toast("文件不可超过3M");
        return false;
      }
      return true;
    },
    // 上传附件
    onAfterRead(file) {
      const _ossClient = this.ossClient;
      const form = this.form;
      _ossClient
          .multipartUpload({
            file: file.file,
          })
          .then((res) => {
            const list = form.accessoryUrl ? form.accessoryUrl.split(",") : [];
            list.push(res.url);
            form.accessoryUrl = list.join(",");
          });
    },
    // 删除已上传文件
    deletFile() {
      const form = this.form;
      const list = form.accessoryUrl ? form.accessoryUrl.split(",") : [];
      list.splice(arguments[1].index, 1);
      form.accessoryUrl = list.join(",");
    },
    //预定产品--跳转到预定页面
    clickPushReserve(data){
      if (data == 'plane') {
        this.$router.push('/h5/flight-home');
      }else if (data == 'train') {
        this.$router.push('/h5/train-home');
      } else if (data == 'hotel') {
        this.$router.push('/h5/hotel-home');
      }
    },
    //取消订单--访问对应接口，传值订单编号orderNo
    clickPushCancel(data){
      let orderNo = this.$route.query.id;
      if (data == 'hotel') {
        hotel_order({ orderNo }).then((res) => {
          console.log(res);
        });
      } else if (data == 'trains') {
        trains_order({ orderNo }).then(res => {
          console.log(res);
        })
      } else if (data == 'plane') {
        air_order({ orderNo }).then(res => {
          console.log(res);
        })
      }
    },
    //支付--跳转到支付页面，orderType用户在支付页面回退时判断
    clickPushPayment(data){
      this.$router.push({
        name:'h5-travel-payment',
        query:{
          orderType:4,
        }
      });
    },
    //改签--跳转改签页面，只有机票和火车票
    clickPushChanges(data){
      let orderNo = this.dataUser.orderNo;
      let evectionNo = this.dataUser.evectionNo;
      if (data == 'plane') {
        this.$router.push({
          name: "flight-change",
          query: {
            orderNo,
            evectionNo,
          },
        });
      }else if (data == 'train') {
        this.$router.push({
          name: 'h5-train-change',
          query: {
            orderNo
          },
        });
      }
    },
    //退票--跳转退票页面，只有机票和火车票
    clickPushRefund(data){
      let orderNo = this.dataUser.orderNo;
      if (data == 'plane') {
        this.$router.push({
          name: "flight-refund",
          query: {
            orderNo,
            orderStatus:1,
          },
        });
      }else if (data == 'train') {
        this.$router.push({
          name: 'h5-train-refund',
          query: {
            orderNo
          },
        });
      }
    },
    //获取数据
    ReqgetEvectionDetail(num){
      let data = {
        evectionNo: num,
      };
      // let loadingBox = Toast.loading({
      //   duration: 0,
      //   message: '加载中...',
      //   forbidClick: true,
      // });
      this.$showLoading.show();
      evectionDC(data).then(res => {
        this.dataUser = res.datas;
        //切割--发起时间
        this.dataUser.getTime = this.dataUser.gmtCreate == null ? '' : this.dataUser.gmtCreate.slice(0,10);
        //计算--出差日期天数
        this.dataUser.days = moment(this.dataUser.endDate).diff(moment(this.dataUser.startDate), "days") + 1;
        //附件下载地址--分割
        this.dataUser.accessoryUrl = this.dataUser.accessoryUrl ? this.dataUser.accessoryUrl.split(',') : [];
        //出行人--字符串分割
        this.dataUser.staffDetail = this.dataUser.staffDetail == null ? '' : this.dataUser.staffDetail.split(',');
        //行程安排--出发到达地点字符串分割
        this.dataUser.journeys.forEach((item,index) => {
            let oneBox = item.cityName.split('-')[0] ? item.cityName.split('-')[0] : '';
            let twoBox = item.cityName.split('-')[1] ? item.cityName.split('-')[1] : '';

            this.$set(item, 'departCityName', oneBox);
            this.$set(item, 'toCityName', twoBox);
        });

          this.dataUser.journeys.forEach((item,index) => {
            this.$set(item, 'clickGoBookLoading', false)
          });

        // loadingBox.clear();
        this.$showLoading.hide();

      },error => {
          this.$showLoading.hide();
      }).catch(()=>{
        this.$showLoading.hide();
      });
    }
  },
  filters: {
      filterTime(val) {
          if (val) {
              return moment(val).format('MM月DD日')
          } else {
              return ''
          }
      },
      format_nodestatus(val) {
          switch (val) {
              case 0:
                  return "未开始";
                  break;
              case 1:
                  return "待审批";
                  break;
              case 2:
                  return "通过";
                  break;
              case 3:
                  return "拒绝";
                  break;
              case 4:
                  return "失效";
                  break;
          }
      },
  },
  created() {

  },
};
