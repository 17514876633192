import import_latest_version_module from "@/component/h5-layout/import_latest_version_module";

export default {
  path: "",
  component: () => import_latest_version_module(),
  children: [
    {
      path: "/h5/poor-mark",
      name: "h5-poor-mark",
      component: () => import(/* webpackChunkName: "page-personal-center-poor-mark" */ `./3.0.3/index.vue`),
      meta: {
        h5_layout: {
          current_page_name: "我的差标",
          need_alive: true,
          need_head_nav: true,
          need_footer_bar: false,
          secondary_menu: 3,
        },
      },
    },
  ],
};
