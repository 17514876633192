import _ from 'underscore';
import get_tab_list from './get_tab_list';
import fixTabBar from "@/lib/common-service/apicloud/fixTabBar";
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/get_user_info'
import consumer_apply_approveList from "@/lib/data-service/haolv-default/consumer_apply_approveList";
import consumer_apply_approveNumber from '@/lib/data-service/haolv-default/consumer_apply_approveNumber'
import {mapGetters, mapActions} from "vuex";
export default {
    data() {
        return {
            active: 0,
            tab_list: [],
            audit: false,
            // setIntervalForApplyNum: '',
        };
    },
    methods: {
        __init_tab_list() {
            const __this = this;
            const tab_list = get_tab_list();
            __this.tab_list = tab_list;
        },
        // _initGetApplyNum() {
        //     this.getApplyNum()
        //     this.setIntervalForApplyNum = setInterval(() => {
        //         this.getApplyNum()
        //     }, 1000 * 60 * 2)
        // },
        // _getApplyNum() {
        //     consumer_apply_approveNumber().then(res => {
        //         const listNum = res.datas.approvedNumber
        //         this.tab_list.forEach(value => {
        //             if (value.title === '办公') {
        //                 value.badge = listNum > 0 ? listNum : undefined
        //             }
        //         })
        //     });
        // },
        getBadge(val) {
            if (val.title === '办公') {
                return this.applyListResult
            } else {
                return ''
            }
        },
        ...mapActions(['updateApplyListNum'])
    },
    watch: {
        $route: {
            handler(to, from) {
                const index = to.meta.h5_layout.secondary_menu;
                if (index || index === 0) this.active = index;
            },
            immediate: true,
        },
    },
    computed: {
        ...mapGetters(['applyListNum', 'applyListResult'])
    },
    created() {
        const __this = this;
        __this.__init_tab_list();
        this.updateApplyListNum()
        // __this._getApplyNum()
        const tab_list = __this.tab_list;
        const route_name = __this.$route.name;
        const index = _.chain(tab_list).findIndex(function (m) {
                return m.to.name === route_name;
            }).value();
        if (index > -1) {
            __this.active = index;
        }
    },
    mounted() {
        const __this = this;
        fixTabBar({
            type: `100`,
            selector: `.footer .tab-bar .van-tabbar`,
        });
        get_user_info().then(res => {
            this.audit = res.datas.audit
        })
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
        //clearInterval(this.setIntervalForApplyNum)
    },
}
