import NavBar from "@/component/nav-bar/import_latest_version_module";
import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/payment-method-selector/1.2.0/payment-method-selector-000/index.vue'
import consumer_t_od_hotel_order_interim_hotelOrderConfirmDetail from '@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_hotelOrderConfirmDetail'
import consumer_t_od_hotel_order_interim_cancelOrder from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_cancelOrder";
import {Dialog, Toast} from "vant";
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import Q from "q";
import moment from "moment";
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'

export default {
    data() {
        return {
            loading: false,
            title: '确认订单信息',
            orderNo: '',
            detail: {},
            milliseconds: 0,
            intervalFun: '',
        }
    },
    components: {NavBar, PaymentMethodSelector000},
    created() {
    },
    mounted() {
    },
    async activated() {
        const __this = this
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback:(result) => {
                    __this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            },200)
        });

        this.orderNo = this.$route.query.orderNo
        await this.getDetail()
        const aPaymentMethodSelector000 = __this.$refs.aPaymentMethodSelector000;
        const orderForPayVos = [
            {
                "orderNo": this.orderNo,
                "businessContent": ""
            },
        ]
        aPaymentMethodSelector000.init({
            mock_data: {
                enable: false,
            },
            count_down: {
                enable: true,
                pay_remaining_seconds: this.detail.milliseconds,
            },
            get_params() {
                const p = {
                    orderForPayVos
                };
                return Q.resolve(p);
            },
            before_pay_event_handler() {
                return Q.resolve();
            },
            user_cancel_pay_event_handler() {},
            pay_successful_event_handler() {
                this.$router.replace({
                    name: 'h5-payment-success',
                    query: {
                        orderNo: this.orderNo,
                        orderType: 3,
                        evectionType: this.evectionType
                    }
                })
            },
            pay_failed_event_handler() {},
        });
    },
    deactivated() {
        clearInterval(this.intervalFun)

        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {
        filterIntervalTime(val) {
            if (val) {
                let m = moment(val).format('mm分ss秒')
                return m
            } else {
                return '00分00秒'
            }
        },
    },
    methods: {
        back() {
            this.$router.go(-1)
        },
        async getDetail() {
            this.loading = true
            this.$showLoading.show();
            let [err, res] = await awaitWrap(consumer_t_od_hotel_order_interim_hotelOrderConfirmDetail({orderNo: this.orderNo}))
            if (err) {
                this.loading = false
                this.$showLoading.hide();
                return
            }
            this.loading = false
            this.$showLoading.hide();
            this.detail = res.datas
            this.milliseconds = res.datas && res.datas.milliseconds ? res.datas.milliseconds * 1000 || 0 : 0
            clearInterval(this.intervalFun)
            if (this.milliseconds > 0) {
                this.setPayInterval()
            }
        },
        setPayInterval() {
            this.intervalFun = setInterval(() => {
                this.milliseconds--
                if (this.milliseconds <= 0) {
                    clearInterval(this.intervalFun)
                    this.cancelOrder()
                }
            }, 1000)
        },
        cancelOrder() {
            Toast('超时未支付，订单已自动取消')
            consumer_t_od_hotel_order_interim_cancelOrder({ orderNo: this.orderNo }).then((res) => {
                this.$router.replace({
                    name: 'h5-hotel-order-detail',
                    query: {
                        orderNo: this.orderNo,
                    }
                })
            });
        },
        submit() {
            const __this = this;
            const selected_payment_channel_entity_list = __this.$refs.aPaymentMethodSelector000.get_selected_payment_channel_entity_list();
            if (selected_payment_channel_entity_list.length <= 0) {
                Toast(`请选择支付方式！`);
                return;
            }
            __this.$refs.aPaymentMethodSelector000.trigger_pay();
        },
    }
}