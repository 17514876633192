//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Q from 'q';
import api_ready_helper from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/api_ready_helper';
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {};
      },
    },
  },
    methods:{
        clickPhone(val){
            Q.when()
                .then(function () {
                    return api_ready_helper.get_api_ready_promise();
                })
                .then(function () {
                    window. api.call({
                        type: 'tel_prompt',
                        number: val
                    });
                });
        },
        toHotelMap() {
            if(this.info && this.info.longitude !== '' && this.info.latitude !== ''){
                this.$router.push({
                    name: "h5-hotel-map",
                    query: {
                        hotelName: this.info.hotelName,
                        longitude: this.info.longitude,
                        latitude: this.info.latitude
                    }
                })
            }
        }
    },
}
