import moment from "moment";
import consumer_apply_myApplyList from "@/lib/data-service/haolv-default/consumer_apply_myApplyList";
import consumer_apply_applyJourneyList from '@/lib/data-service/haolv-default/consumer_apply_applyJourneyList'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import applyButton from "@/lib/data-service/haolv-default/consumer_menu_applyButton";
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv-app/get_user_info";
let deferred = function () {
    let dfd = {};
    dfd.promise = new Promise((resolve, reject) => {
        dfd.resolve = resolve;
        dfd.reject = reject;
    });
    return dfd
};
export default {
    data () {
        return {
            loading: false,
            businessTravelList: [],
            currentPopupShow: false,
            paddingBottom: 0,
            haveTravel: false,
            listDeferred: deferred(),
            jurisdiction: false, // 按钮权限范围
        }
    },
    props: {
        popupShow: {
            type: Boolean,
            default: false
        },
        list: {
            type: Array,
            default: () => []
        },
        activeItem: {
            type: Object,
            default: null
        },
        // 筛选显示到类型
        businessType: {
            type: [Number, String],
            default: '' // 1.机票，2.火车票，3.酒店
        },
        enable: {
            type: Boolean,
            default: true,
        }
    },
    components: {NavBar},
    created () {},
    mounted () {
        let __this = this;
        this.getApplyButton();
        this.getBusinessTravel()
    },
    activated () {

    },
    deactivated() {},
    destroyed () {},
    watch: {
        businessType(newVal, oldVal) {
            this.setHaveTravel()
        }
    },
    computed: {
        /*currentPopupShow: {
            get () {
                return this.popupShow
            },
            set (val) {
                this.$emit('update:popupShow', val)
            }
        },*/
        currentActiveItem: {
            get () {
                return this.activeItem
            },
            set (val) {
                this.$emit('update:activeItem', val)
            }
        },
    },
    filters: {
        changeDepartTime(val) {
            let day = moment(val).format('YYYY年MM月DD日');
            return day
        },
        changeDepartDayAndTime(val) {
            let day = moment(val).format('YYYY-MM-DD');
            return day
        },
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
    },
    methods: {
        setBusinessName(val) {
            let text = '';
            switch (val) {
                case 1:
                    text = '机票';
                    break
                case 2:
                    text = '火车票';
                    break
                case 3:
                    text = '酒店';
                    break
                case 4:
                    text = '用车';
                    break
                default:
                    text = ''
            }
            return text;
        },
        getShow() {
            return this.currentPopupShow
        },
        chooseTravel() {
            if (!this.enable) {
                return;
            }
            this.currentPopupShow = true
        },
        callbackReady() {
            return this.listDeferred.promise
        },
        getBusinessTravel() {
            this.loading = true;
            let params = {
                pageSize: 1000,
                currentPage: 1,
                status: 2,
                approveType: 1,
                businessType: this.businessType
            };
            consumer_apply_applyJourneyList(params).then((res) => {
                let businessTravelList = res.datas;
                // 前海人寿情况
                if (this.$store.state.evectionBackType === '001') {
                    businessTravelList.forEach(value=>{
                        if (value.journeys) {
                            value.journeys.forEach(value1 => {
                                if (value1.businessType === 1) {
                                    value1.departCityName = localStorage.getItem('fromStationName');
                                    value1.toCityName = localStorage.getItem('toStationName');
                                }
                            })
                        }
                    })
                }
                this.businessTravelList = businessTravelList;
                this.setHaveTravel();
                this.listDeferred.resolve()
            }).finally(() => {
                this.loading = false
            });
        },
        setHaveTravel() {
            let haveTravel = false;
            if (this.businessTravelList.length === 0) {
                haveTravel = false
            }
            this.businessTravelList.forEach(value => {
                if (value.journeys) {
                    value.journeys.forEach(value1 => {
                        if (this.businessType === 0 || value1.businessType === this.businessType) {
                            haveTravel = true
                        }
                    })
                }
            });
            this.haveTravel = haveTravel
        },
        getCurrentItem(item, journey, index, jIndex) {
            if (journey.businessType == 1 && journey.areaType == 2) {
              return;
            }
            let current = JSON.parse(JSON.stringify(item));
            current.active = journey;
            current.active.evectionId = item.id;
            this.currentActiveItem = current;
            this.$emit('getCurrent', current);
            this.currentPopupShow = false
        },
        canShowItem(item) {
            let isThisBusinessType = (this.businessType === 0 || item.businessType === this.businessType);
            // let isHasPassUser = false
            // item.staffList.forEach(value => {
            //     if (value.bookStatus === 1) {
            //         isHasPassUser = true
            //     }
            // })
            // return isThisBusinessType && isHasPassUser
            return isThisBusinessType
        },
        closeEvectionlPopup() {
            this.currentPopupShow = false
        },
        returnStaffList(val) {
            if (!val) {
                return ''
            }
            let staffList = [];
            val.staffList.forEach(value => {
                staffList.push(value.staffName)
            });
            return staffList.join('/')
        },
        clearCurrent() {
            if (!this.enable) {
                return;
            }
            this.currentActiveItem = null;
            this.$emit('getCurrent', null)
        },
        setJourneyId(params) {
            let journeyId = params.journeyId;
            return new Promise(resolve => {
                this.callbackReady().then(() => {
                let currentItem = null;
                this.businessTravelList.forEach(value => {
                    value.journeys.forEach(value1 => {
                        if (value1.id === journeyId) {
                            currentItem = JSON.parse(JSON.stringify(value));
                            currentItem.active = value1;
                            currentItem.active.evectionId = value.id
                        }
                    })
                });
                this.currentActiveItem = currentItem;
                this.$emit('getCurrent', currentItem);
                // 如果没有匹配上，则推送错误
                if (!currentItem) {
                    this.$emit('getCurrentErr')
                }
                resolve(currentItem);
            })})
        },
        setActiveItemName(activeItem) {
            if (!activeItem) {
                return ''
            }
            let name = '';
            name += `${activeItem.evectionReason}-`;
            let businessName = '';
            if (activeItem.active.businessType === 1) {
                businessName = '机票'
            } else if (activeItem.active.businessType === 2) {
                businessName = '火车票'
            } else if (activeItem.active.businessType === 3) {
                businessName = '酒店'
            } else {
                businessName = '用车'
            }
            name += `[${businessName}] `;
            if (activeItem.active.businessType === 3) {
                name += `${activeItem.active.departCityName || activeItem.active.toCityName}`
            } else if (activeItem.active.businessType === 4) {
                name += `${activeItem.active.carPolicyText}`
            }else {
                name += `${activeItem.active.departCityName}-${activeItem.active.toCityName}`
            }
            return name
        },

        toApply() {
            this.currentPopupShow = false;
            this.$router.push({
                name: 'h5-add-evection',
            })
        },
// 出差申请按钮状态
async getApplyButton() {
    try {
      const res = await applyButton();
      this.jurisdiction = res.datas.jurisdiction
    } catch (error) {

    }
  },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
