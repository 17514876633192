import consumer_web_externalCustomer_getExternalCustomerList from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_getExternalCustomerList.js';
import consumer_web_externalCustomer_delExternalCustomer from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_delExternalCustomer.js';
import {Dialog, Toast} from "vant";
export default {
    data() {
        return {
            refreshing: false,
            loading: false,
            finished: false,
            form:{
                currentPage:1,
                pageSize:10,
                thpOrName:"",
                total: 0
            },
            list: [],
            delLoading: false,

            userShow: false,
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
        this.onSearch();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        onRefresh() {
            console.log('触发刷新')
            this.form.currentPage = 0;
            this.refreshing = false;
            this.onLoad();
        },
        onLoad() {
            console.log('出发加载')
            if (this.refreshing) return;
            this.loading = true;
            this.form.currentPage += 1;
            this.getList();
        },
        onSearch() {
            this.form.pageSize = 10;
            this.form.currentPage = 1;
            this.list = [];
            this.loading = true;
            this.getList();
        },
        getList() {
            const form = JSON.parse(JSON.stringify(this.form));
            consumer_web_externalCustomer_getExternalCustomerList(form).then(res=>{
                this.form.total = res.datas.totalCount;
                this.list = this.list.concat(res.datas.list);
                if (this.list.length == this.form.total) {
                    this.finished =true;
                } else {
                    this.finished =false;
                }
                this.refreshing = false;
                this.loading = false;
            })
        },
        delUser(user, index) {
            Dialog.confirm({
                title: '提示',
                message: '确认删除此帐号？',
            })
                .then(() => {
                    // on confirm
                    this.delLoading = true;
                    consumer_web_externalCustomer_delExternalCustomer({id:user.id}).then(res=>{
                        this.delLoading = false;
                        Toast.success('已删除');
                        this.list.splice(index, 1);
                        this.form.total = this.form.total - 1;
                    }).catch(e=>{
                        this.delLoading = false;
                    })
                })
                .catch(() => {
                    // on cancel
                });
        },
        edit(user, index) {
            this.$router.push({
                name: 'h5-external-customer-edit',
                query: {
                    id: user.id,
                    userId: user.userId,
                    type: 'edit'
                }
            })
        },
        addUser() {
            this.$router.push({
                name: 'h5-external-customer-edit',
                query: {
                    type: 'add'
                }
            })
        },
    }
}
