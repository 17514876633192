import store from '@/lib/store';
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Toast} from "vant";
// 选择费用归属
import CostAttributionList from "@/component/cost-attribution-list/1.0.1/index.vue";
// 【576】获取预算险类种类
import consumer_budget_getBudgetInsurance from '@/lib/data-service/haolv-default/consumer_budget_getBudgetInsurance'
// 【576】获取预算项目种类
import consumer_budget_getBudgetPro from '@/lib/data-service/haolv-default/consumer_budget_getBudgetPro'
// 【576】获取预算科目种类
import consumer_budget_getBudgetItem from '@/lib/data-service/haolv-default/consumer_budget_getBudgetItem'
// 【576】获取用车预算
import consumer_car_link_getCarBudgetInfo from '@/lib/data-service/haolv-default/consumer_car_link_getCarBudgetInfo'
// 【576】根据用户的部门查询对应的成本中心
import consumer_web_costCenter_costCenterByDep from '@/lib/data-service/haolv-default/consumer_web_costCenter_costCenterByDep'

export default {
    data () {
        return {
            subsidyControlRule: 0,//预算管控 0：不开启 1：开启（只允许按出差单申请金额内预订行程） 默认：0
            budgetItemText: '',
            form: {
                budgetInsuranceName: '',
                budgetProName: '',
                budgetItemType: "", //科目类型 1-管理 2-理赔 3-销售
                feeAffiliationName: "", //费用归属名称
                feeAffiliationId: null, //费用归属部门id
                feeAffiliationType: 1, //1.组织架构，2.成本中心
            },
            budgetPopupTitle: '预算险类',
            budgetItemPopup: false,
            showCost: false,
            budgetPopup: false,
            budgetListAll: [],
            budgetInsuranceList: [],
            budgetProList: [],
            budgetItemList: [
                {name: '管理',code: 1},
                {name: '理赔',code: 2},
                {name: '销售',code: 3},
            ],
        }
    },
    components: {
        NavBar,
        CostAttributionList,
    },
    created () {},
    async mounted () {
        this.$showLoading.show();
        let resOne = await consumer_budget_getBudgetInsurance();
        let resTwo = await consumer_budget_getBudgetPro();
        let resThree = await consumer_budget_getBudgetItem();
        this.budgetProList = resTwo.datas;
        this.budgetInsuranceList = resOne.datas;
        // this.budgetItemList = resThree.datas;
        this.$showLoading.hide();
        await this.$store.state.policyDefer.promise;
        await this.$store.state.userInfoDefer.promise;
        this.subsidyControlRule = store.state.policy.subsidyControlRule;
        if (this.subsidyControlRule == 1) {
            const params = {
              showDept: false,
            };
            this.$refs.aCostAttributionList.setParams(params);
        }
        consumer_web_costCenter_costCenterByDep({userId: this.$store.state.userInfo.id}).then(res => {
            this.form.feeAffiliationName = res.datas.centerName;
            this.form.feeAffiliationId = res.datas.id;
            this.form.feeAffiliationType = 2;
        });
    },
    activated () {
        this.budgetItemText = '';
        this.form = {
            budgetInsuranceName: '',
            budgetProName: '',
            budgetItemType: "", //科目类型 1-管理 2-理赔 3-销售
            feeAffiliationName: "", //费用归属名称
            feeAffiliationId: null, //费用归属部门id
            feeAffiliationType: 1, //1.组织架构，2.成本中心
        };
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        // 返回
        back() {
            this.$router.go(-1)
        },
        popup_budgetItemType() {
            this.budgetItemPopup = true;
        },
        openCostPopup() {
            this.showCost = true;
        },
        popup_budgetInsurance() {
            this.budgetPopupTitle = '预算险类';
            this.budgetPopup = true;
            this.budgetListAll = this.budgetInsuranceList;
        },
        popup_budgetBudgetPro() {
            this.budgetPopupTitle = '项目';
            this.budgetPopup = true;
            this.budgetListAll = this.budgetProList;
        },
        columnsBudgetItem() {
            let box = this.$refs.budgetItemRef.getValues()[0];
            this.budgetItemText = box.name;
            this.form.budgetItemType = box.code;
            this.budgetItemPopup = false;
            this.$forceUpdate();
        },
        clickBudgetInsurance(item) {
            if (this.budgetPopupTitle == '预算险类') {
                this.form.budgetInsuranceCode = item.code;
                this.form.budgetInsuranceName = item.value;
            } else {
                this.form.budgetProCode = item.code;
                this.form.budgetProName = item.value;
            }

            this.budgetPopup = false;
        },
        budgetClass(value) {
            let box = false;
            if (this.budgetPopupTitle == '预算险类') {
                eval(`box = this.form.budgetInsuranceName == '${value}';`);
            } else {
                eval(`box = this.form.budgetProName == '${value}';`);
            }
            return box;
        },
        clickCarApplication() {
            if (!this.budgetItemText) {
                Toast('请选择科目类型');
                return;
            }
            if (!this.form.feeAffiliationName) {
                Toast('请选择成本归属');
                return;
            }
            if (!this.form.budgetInsuranceName) {
                Toast('请选择预算险类');
                return;
            }
            if (!this.form.budgetProName) {
                Toast('请选择项目');
                return;
            }
            let dataBox = {
                budgetInsuranceCode: this.form.budgetInsuranceCode,
                budgetInsuranceName: this.form.budgetInsuranceName,
                budgetItemType: this.form.budgetItemType,
                budgetProCode: this.form.budgetProCode,
                budgetProName: this.form.budgetProName,
                feeAffiliationId: this.form.feeAffiliationId,
                feeAffiliationName: this.form.feeAffiliationName,
                feeAffiliationType: this.form.feeAffiliationType,
            };
            this.$showLoading.show();
            consumer_car_link_getCarBudgetInfo(dataBox).then(res => {
                this.$router.push({
                    name: 'h5-car-home',
                });
            }).finally(() => {
                this.$showLoading.hide();
            });
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
