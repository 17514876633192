import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import NavBar from '@/component/nav-bar/import_latest_version_module'
export default {
  data() {
    return {
      screenShow:false,
      radio: 0,
      showData:[
        {
          name:'我的出差',
          key:0
        },
        {
          name:'我的行程',
          key:1
        }
      ]
    };
  },
  components: {NavBar},
  methods: {
    clickScreen() {
      this.screenShow = !this.screenShow;
    }
  },
  created() {},
  mounted() {
    const __this = this;
    setTimeout(() => {
      fixStatusBar({
        handler({api}) {
          const bodyWidth = document.body.clientWidth;
          const bodyHeight = document.body.clientHeight;
          let headNav = __this.$refs.headNav.$el;
          const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
          headNav.style.paddingTop = addPaddingTop + 'vw';
        }
      });
    }, 500)
  },
  activated() {},
  deactivated() {},
};
