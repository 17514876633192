import JourneySecretary from '@/component/journey-secretary/index.vue';
export default {
  components: {
    JourneySecretary
  },
  data () {
    return {    
      activePage: '1',
      pageList: [ 
        { title: '因公出差', name: '1', router: 'h5-office-evection' },
        { title: '因私出差', name: '2', router: 'h5-privately-evection' },
       ],
    }
  },
  methods: {
    change_tab (item) {
      this.activePage = item.name;
      this.$router.push({
        name: item.router,
      })
    }
  },
  created () {
    this.activePage = this.$route.meta.h5_layout.evectionMeta.toString();
  },
}