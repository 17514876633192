

export default {
  install(Vue) {
    Vue.prototype.showMsg = function (pParameter) {
      const __this = this;

      const color = pParameter.color || `info`;
      const msg = pParameter.msg || ``;

      const snackbar = __this.$root.$refs.aApp.snackbar;
      snackbar.msg = msg;
      snackbar.color = color;
      snackbar.show = true;
    };

    Vue.prototype.getAppConfig = function () {
      return global.appConfig;
    };

    // Vue.prototype.globalData = (async function () {
    //   let globalData;
    //   if (global.globalData) {
    //     globalData = global.globalData;
    //   } else {
    //     globalData = await api_system_getGlobalData();
    //   }
    //   return globalData;
    // })();

    // Vue.prototype.globalData = {};

    // const init_globalData = async function () {
    //   Vue.prototype.globalData = {
    //     sign_out_url: `111`,
    //   };
    //   const globalData = await api_system_getGlobalData();
    //   Object.assign(Vue.prototype.globalData, globalData);
    //   // Vue.prototype.globalData = globalData;
    // };
    // init_globalData();

  }
}
