// 出差申请单详情 http://219.128.52.2:18765/doc.html#/haolv-consumer/t-us-approve-apply-controller/detailUsingPOST_1
const __request = require(`./__request/__request_contentType_json`)
const request = (data) => {
    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/apply/detail',
        data: data
    }
    return __request(pParameter)
}
export default request
// consumer_apply_detail