//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    import moment from 'moment'
    import {Toast} from 'vant';
    import HotelCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/hotel-city-selector/2.1.0/index.vue";
    //选择入住和离店日期组件
    import HotelStayDatePicker
        from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/hotel-stay-date-picker/1.0.2/index.vue';
    //关键字搜索选择组件
    import HotelKeywordSelector
        from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/hotel-keyword-selector/1.0.4/index.vue'
    import Moment from "moment";
    import requestPermission
      from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/requestPermission";
    import currentLocation from '@/lib/common-service/current_location_helper/index.js'
    import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar"; //在app中获取用户当前位置
    export default {
  props: {
      allowChangeJourneyTime: {
          type: Number,
          default: 0,
      },
    evectionDetailType: {
      type: String,
      default() {
        return '101';
      }
    },
    applyJourneyId: {
      type: Number,
      default: 0
    },
    query: {
      type: Object,
      default: {
        checkInDate: '',
        checkOutDate: '',
        evectionStartDate: '',
        evectionEndDate: '',
      }
    },
    cityCanEdit: {
      type: Boolean,
      default: true
    },
    timeCanEdit: {
      type: Boolean,
      default: true
    },
    isFirstLoad: {
      type: Boolean,
      default: true
    },
    unlimitedJourney: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      // 搜索框
      keyword: '',

      // 日历
      startStr: '',
      endStr: '',
      showCalendar: false,
      showCalendarFor003: false,
      calendarValue: [],
      detailStartStr: '',
      detailEndStr: '',
      startWeekDay: 0,
      endWeekDay: 0,
      night: 0,
      weekDayObj: {
        0: '周日',
        1: '周一',
        2: '周二',
        3: '周三',
        4: '周四',
        5: '周五',
        6: '周六',
      },
      showPopup: false,
      showCalendarDetail: false,

      // 城市选择器
      cityName: '',//城市名或者具体的地址
      cityNameCopy: '',
      cityId: 0,
      currentCityId: 0,
      cityCn: '',//城市名，用于页面展示

      minDateFor003: new Date(),
      maxDateFor003: new Date(),

      filterType: '',
      pt: '',

      minDate: new Date(),
      maxDate: new Date('2100-01-01'),

      hasOpenSelect: false, // 是否有打开过搜索
      isPOIName: false,//cityNames是否是具体地址
      yesterday: '',
      isMorning: false,//当前时间是否为凌晨00:00-06:00
    };
  },
  components: {
    HotelCitySelector,
    HotelStayDatePicker,
    HotelKeywordSelector
  },
  created() {
    this.initData()
  },
      mounted() {
        let __this = this;
        fixStatusBar({
          handler({api}) {
            __this.$refs.popup.style.top = api.safeArea.top + 93 + 'px';
          }
        });
      },
      activated() {
        let _this = this;
        this.showCalendarDetail = false;

        //判断是否凌晨（00:00-06:00）进入页面
        let curTimeList = this.$moment().toArray();
        this.isMorning = curTimeList[3] < 6;
        this.yesterday = this.$moment().add(-1, 'days').format('YYYY-MM-DD');
        let yesterday = new Date();
        yesterday.setDate(yesterday.getDate() - 1);
        this.minDate = this.isMorning? yesterday: new Date();
        this.maxDate = new Date('2100-01-01');

        this.initLocation();
        this.initHotelCitySelector();
        this.$nextTick(() => {
          this.setHotelCitySelectorVal();
        })
        setTimeout(() => {
          const {
            filterType,
            keyword,
            pt,
          } = _this.$route.query;
          _this.filterType = Number(filterType) ? 1 : 0;
          _this.keyword = keyword ? keyword : '';
          _this.pt = pt ? pt : '';
          _this.initHotelKeywordSelector();

          if (!_this.isFirstLoad) {
            _this.$emit('on-change', this.formatEmitValue());
          }
        }, 500);
      },
  computed: {
    defaultDate() {
      let res = [];
      if(this.evectionDetailType !== '001' || (this.unlimitedJourney === 1 && this.allowChangeJourneyTime === 1)) {
        let startMoment = this.$moment(this.query.checkInDate);
        let endMoment = this.$moment(this.query.checkOutDate);
        res[0] = startMoment._d;
        res[1] = endMoment._d;
      }
      return res;
    }
  },
  filters: {
    formatDate(val) {
      if (!val) return '';
      const date = moment(val);
      return date.format('MM-DD');
    },
    formatWeekDay(val) {
      if (!val) return '';
      const moment = Moment();
      const today = moment.format('YYYY-MM-DD');
      const tomorrow = moment.add(1, 'd').format('YYYY-MM-DD');

      const day = Moment(val).day();

      if (val === today) return '今天';
      if (val === tomorrow) return '明天';

      const week = {
        0: '周日',
        1: '周一',
        2: '周二',
        3: '周三',
        4: '周四',
        5: '周五',
        6: '周六',
      };

      return week[day];
    },
  },
  methods: {
    initData() {
      const {
        cityCn,
        cityName,
        isPOIName,
        cityId,
        filterType,
        keyword,
              pt,
      } = this.$route.query;

      if (keyword) this.keyword = keyword;
      if (filterType) this.filterType = filterType;
      if (pt) this.pt = pt;
      this.isPOIName = (isPOIName == 'true' || isPOIName == true) ? true : false;
      if (cityName) this.cityName = cityName;
      if (cityId) this.cityId = cityId;
      this.cityCn = cityCn && cityCn !== 'null' ? cityCn : this.cityName;

      if (this.query.checkInDate) {
        const startMoment = this.$moment(this.query.checkInDate);
        this.startStr = startMoment.format('YYYY-MM-DD');
        this.detailStartStr = startMoment.format('MM月DD日');
        this.startWeekDay = startMoment.day();
        if(this.evectionDetailType === '003' || (this.unlimitedJourney === 1 && this.evectionDetailType === '001' && this.allowChangeJourneyTime === 1)) {
          this.minDateFor003 = new Date(this.query.evectionStartDate);
        }
      }

      if (this.query.checkOutDate) {
        const endMoment = this.$moment(this.query.checkOutDate);
        this.endStr = endMoment.format('YYYY-MM-DD');
        this.detailEndStr = endMoment.format('MM月DD日');
        this.endWeekDay = endMoment.day();
        if(this.evectionDetailType === '003' || (this.unlimitedJourney === 1 && this.evectionDetailType === '001' && this.allowChangeJourneyTime === 1)) {
          this.maxDateFor003 = new Date(this.query.evectionEndDate);
        }
      }
      if (this.query.checkInDate && this.query.checkOutDate) {
        this.night = this.$moment(this.query.checkOutDate).diff(this.$moment(this.query.checkInDate), 'days');
      }
    },
    // 日历详情确定事件
    onSubmitDate() {
      this.showCalendarDetail = false;
      if (!this.hasOpenSelect) {
          return
      }
      this.hasOpenSelect = false;
      if(!this.isFirstLoad) {
        this.cityNameCopy = this.cityName;
        this.$emit('on-change', this.formatEmitValue());
      }
    },
    // 格式化 on-change 自定义事件参数
    formatEmitValue(item) {
      if ((item && item.filterType === 1) || (!item && this.query.filterType === 1)) {
        let filterType = item ? item.filterType : this.query.filterType;
        this.$emit('on-keyword-change', {
          filterType: filterType
        });
      }
      let pt = '';
      if (item && item.filterType === 1) {
        pt = item.location;
      } else if (!item) {
        pt = this.pt;
      } else {
        pt = '';
      }

      let value = {
        type: 'cityOrDate',
        checkInDate: this.startStr,
        checkOutDate: this.endStr,
        cityId: this.currentCityId,
        cityName: this.cityName,
        cityCn: this.cityCn,
        isPOIName: this.isPOIName,
        filterType: item? item.filterType : this.filterType,
        keyword: item? item.keyword : this.keyword,
        pt: pt,
        pt_from_type: '100'
      };

      return value;
    },
    // 日历确认事件
    onConfirm(date) {
      this.showCalendar = false;
      this.showCalendarFor003 = false;

      this.calendarValue = date;

      const startMoment = this.$moment(date[0]);
      this.startStr = startMoment.format('YYYY-MM-DD');
      this.detailStartStr = startMoment.format('MM月DD日');
      this.startWeekDay = startMoment.day();

      const endMoment = this.$moment(date[1]);
      this.endStr = endMoment.format('YYYY-MM-DD');
      this.detailEndStr = endMoment.format('MM月DD日');
      this.endWeekDay = endMoment.day();

      this.night = endMoment.diff(startMoment, 'days');
    },
    // 打开日历
    onOpenCalendar() {
      if (!this.timeCanEdit) {
        Toast({message: '不能修改出差单既定行程时间', className: 'can-not-change-tip'});
        return
      }
      this.hasOpenSelect = true;
      if (this.evectionDetailType === '003' || (this.unlimitedJourney === 1 && this.evectionDetailType === '001' && this.allowChangeJourneyTime === 1)) {
        this.minDateFor003 = new Date(this.query.evectionStartDate);
        this.maxDateFor003 = new Date(this.query.evectionEndDate);
        this.showCalendarFor003 = true;
      } else {
        this.showCalendar = true;
      }
    },
    // 打开日历详情
    onOpenDate() {
      // if (this.defaultDate.length) {
      //   const [start, end] = this.defaultDate;
      //   const startMoment = this.$moment(start);
      //   this.detailStartStr = startMoment.format('MM月DD日');
      //   this.startWeekDay = startMoment.day();
      //
      //   const endMoment = this.$moment(end);
      //   this.detailEndStr = endMoment.format('MM月DD日');
      //   this.endWeekDay = endMoment.day();
      //
      //   this.night = endMoment.diff(startMoment, 'days');
      // }
      if (!this.timeCanEdit) {
        Toast({message: '不能修改出差单既定行程', className: 'can-not-change-tip'});
        return
      }
      this.$refs.calendar.reset();
      this.showCalendarDetail = !this.showCalendarDetail;
      this.$nextTick(() => {
        if(this.evectionDetailType === '001') {
          this.initHotelStayDatePicker();
        }
      })
    },
    getContainer() {
      return this.$refs.popup;
    },
    // 日历详情关闭完成事件
    onClosedCalendarDetail() {
      this.showPopup = false;
      this.cityName = this.cityNameCopy;
    },
    // 打开日历详情事件
    onOpenCalendarDetail() {
      let _this = this;
      this.showPopup = true;
      this.$emit('update-evection-type');
      this.cityNameCopy = JSON.parse(JSON.stringify(this.cityName));
      setTimeout(() => {
        _this.$refs.popup.getElementsByClassName('van-overlay')[0].addEventListener('click',() => {
          _this.showPopup = false;
          _this.showCalendarDetail = false;
        })
      }, 500)
    },
    // 格式化日历显示内容
    formatter(day) {
      return day;
    },
    // 关闭日历事件
    onCloseCalendar() {},
    // 打开城市选择器
    onOpenCitySelect(str) {
      if (!this.cityCanEdit && this.unlimitedJourney === 0) {
        Toast({message: '不能修改出差单既定行程', className: 'can-not-change-tip'});
        return
      }
      this.hasOpenSelect = true;
      if (str === 'autoUpdate') {
        this.showPopup = false;
      }
      this.$refs.aHotelCitySelector.show();
    },
    //初始化酒店关键字选择组件
    initHotelKeywordSelector() {
      const _this = this;
      this.$refs['hotelKeywordSelector'].init({
        mode: '100',
        timeCanEdit: _this.timeCanEdit,
        get_params() {
          return {
            cityId: _this.cityId ? _this.cityId : '',
          };
        },
      })
    },
    // 搜索触发事件
    onSearch(item) {
        this.showPopup = false;
      if(!this.isFirstLoad) {
        this.filterType = item && item.filterType ? item.filterType : 0;
        this.pt = item && item.filterType === 1 ? item.location : '';
        this.$emit('on-change', this.formatEmitValue(item));
      }
    },
    // 搜索清除事件
    onClear() {
      if(!this.isFirstLoad) {
        this.$emit('on-change', this.formatEmitValue());
      }
    },
    //初始化酒店入住和离店时间组件
    initHotelStayDatePicker() {
      this.$refs['hotelStayDatePicker'].init({
        __this: this,
        mode: '100',
        applyJourneyId: this.applyJourneyId
      })
    },
    //清除keyword
    clearKeyword() {
      this.keyword = '';
    },
    setKeyword(keyword) {
      this.keyword = keyword;
    },
    initHotelCitySelector() {
      const __this = this;
      const aSelector = __this.$refs.aHotelCitySelector;
      aSelector.init({
        mock_data: {
          enable: false,
          wait_seconds: 0.5,
        },
        panel_select_entity: {
          width: `440`,
        },
        el_autocomplete: {
          placeholder: `请选择目的城市`,
        },
        event_handler: {
          change_event_handler(args) {
            __this.cityName = args.name;
            __this.currentCityId = args.id;
            __this.cityId = args.id;
            __this.cityCn = args.entity.cityCn ? args.entity.cityCn : args.name;
            __this.isPOIName = (__this.cityCn && __this.cityCn !== 'null' && __this.cityName !== __this.cityCn) ? true : false;

            if (args.type !== '5') {
              __this.keyword = args.key ? args.key : '';
            } else {
              __this.keyword = '';
            }
            __this.pt = '';
            if (args.entity.filterType === 1) {
              __this.pt = args.entity.location;
            }
            __this.filterType = args.entity.filterType ? 1 : 0;
            if(!__this.isFirstLoad && !__this.showPopup) {
              __this.$emit('on-change', __this.formatEmitValue());
            }
          },
        },
      });
    },
    setHotelCitySelectorVal() {
      const __this = this;
      __this.currentCityId = __this.cityId;
      const aSelector = __this.$refs.aHotelCitySelector;
      __this.isPOIName = (__this.cityCn && __this.cityCn !== 'null' && __this.cityName !== __this.cityCn) ? true : false;
      aSelector.set_value({
        "id": __this.cityId,
        "name": __this.cityName,
        "entity": {
          "cityId": __this.cityId,
          "cityCn": __this.cityCn,
          "cityPinyin": "",
          "cityPinyinSimp": "",
          "initial": "",
          "isHot": "",
          "cityName": null,
          "key": null
        },
      });
    },
    changeKeyword(val) {
      this.query.pt = '';
      this.query.filterType = val.filterType;
      if(val.filterType === 1) {
        this.query.pt = val.location;
      }
    },
    async initLocation() {
      await currentLocation.init();
    },
    locate() {
      if (!this.cityCanEdit) {
        Toast({message: '不能修改出差单既定行程', className: 'can-not-change-tip'});
        return;
      }
      this.$Toast({message: '定位中...'});
      currentLocation.get_current_location().then((location) => {
        this.hasOpenSelect = true;
        this.cityId = location.cityId;
        this.cityCn = location.cityName;
        this.cityName = location.POIName;
        this.isPOIName = true;
        this.setHotelCitySelectorVal();
        if (!location.locationStr) {
          // 权限问题
          requestPermission();
          this.isPOIName = false;
          this.cityName = location.cityName;
          this.$Toast({message: '获取当前位置失败，请稍后再试!'});
        } else {
          this.$Toast({message: '定位成功'});
        }
      })
    },
    back() {
      this.$router.replace({
        name: 'h5-home'
      });
    },
    searchPopupShow() {
      return this.showPopup;
    },
    hideSearchPopup() {
      this.showPopup = false;
    },
    hotelCitySelectorShow() {
      const aHotelCitySelector = this.$refs.aHotelCitySelector;
      const show_aHotelCitySelector = aHotelCitySelector.get_show();
      return show_aHotelCitySelector;
    },
    hideHotelCitySelector() {
      this.$refs.aHotelCitySelector.hide();
    },
    hotelStayDatePickerShow() {
      const hotelStayDatePicker = this.$refs.hotelStayDatePicker;
      const hotelStayDatePickerShow = hotelStayDatePicker ? hotelStayDatePicker.get_show() : false;
      return hotelStayDatePickerShow;
    },
    hideHotelStayDatePicker() {
      this.$refs.hotelStayDatePicker.hide();
    },
    calendarShow() {
      return this.showCalendar;
    },
    hideCalendar() {
      this.showCalendar = false;
    },
    calendarFor003Show() {
      return this.showCalendarFor003;
    },
    hideCalendarFor003() {
      this.showCalendarFor003 = false;
    },
    keywordPopupShow() {
      const hotelKeywordSelector = this.$refs.hotelKeywordSelector;
      const show_hotelKeywordSelector = hotelKeywordSelector.get_show();
      return show_hotelKeywordSelector;
    },
    hideKeywordPopup() {
      this.$refs.hotelKeywordSelector.hide();
    },

      // 关闭所有弹窗
      closeAllPopup() {
        this.showPopup = false;
      },

    //因公出行-修改了时间
    dateHasChange() {
      this.hasOpenSelect = true;
    },
      keyPopupShow() {
          this.closeAllPopup();
      },
  },
}
