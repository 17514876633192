import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info' // 获取个人信息
import consumer_insurance_getEInsuranceOrderUrl from '@/lib/data-service/haolv-default/consumer_insurance_getEInsuranceOrderUrl'
import consumer_air_ticket_orderDetail from "@/lib/data-service/haolv-default/consumer_air_ticket_orderDetail";
import consumer_air_ticket_flightRules from "@/lib/data-service/haolv-default/consumer_air_ticket_fligthRules";
import consumer_air_ticket_returnFligthRules from "@/lib/data-service/haolv-default/consumer_air_ticket_returnFligthRules";
import cancelOrder from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";
import ChangClause from '@/page/travel-book/flight/component/changClause/index.vue'
import ChangeClausePopup from '@/page/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import ApprovalProcess from "@/component/approval-process/1.0.0/index.vue"
import FlightDetailPopup from "@/component/flight-detail-popup/import_latest_version_module.js"
import NavBar from "@/component/nav-bar/import_latest_version_module";
import moment from "moment";
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import {Toast, Dialog, Notify} from "vant";
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import open from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window'
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import FlightUpLevelInfo from "@/component/flight-up-level-info/index.vue";

export default {
    data () {
        return {
            isShowPriceDetail: false,
            isShowFlightDetail: false,//是否显示航班详情
            isShowDepFlight: true,//是否显示去程航班详情
            isShowReFlight: false,//是否显示返程航班详情
            ruleShowType: '000',
            detail: {},
            userId: 0,//当前用户id
            ruleShow: false, // 退改签说明弹窗
            ruleLoading: false,
            ruleText: {}, // 单程退改的文本
            ruleTextList: [], // 往返退改的文本
            type: '', // 如果传review,则不显示改签和退票按钮
            productTag: 0,

            contactShow: true,
            orderInfoShow: true,
            priceInfoShow: true,
            isCanChange: false, //当前用户能否退改此行程

            recordShow: true,
            insuranceShow: true,

            isLoading: false,

            milliseconds: 0,
            paySetIntervalFun: '',

            showGDNanhangLine: false,
            showDXNanhangLine: false,
        }
    },
    components: {
        ChangClause,
        NavBar,
        ApprovalProcess,
        ChangeClausePopup,
        FlightDetailPopup,
        FlightUpLevelInfo,
    },
    created () {},
    mounted () {
        const __this = this;
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                _this.$refs['reserveDetailBottom'].style.paddingBottom = `${api.safeArea.bottom + 64}px`;
            }
        });
        // fixStatusBar({
        //     type: `000`,
        //     handler({default_handler, api}) {
        //         // document.querySelector('#search-header')
        //
        //         default_handler({
        //             element: __this.$refs.searchHeader.$el,
        //         });
        //     }
        // });
    },
    activated () {
        this.type = this.$route.query.type ? this.$route.query.type : '';
        this.getOrderDetail(true);
        keyback_processor.addEventListener(() => {
            if (this.ruleShow) {
                this.ruleShow = false;
                return
            }
            this.back()
        })
    },
    deactivated() {
        keyback_processor.removeEventListener();
        clearInterval(this.paySetIntervalFun);
        this.$showLoading.hide();
    },
    destroyed () {},
    watch: {},
    computed: {
        matchFlightPrice() {
            let result = 0;
            result = parseFloat(this.detail.totalPrice || 0) + parseFloat(parseFloat(this.detail.totalAirportTax) + parseFloat(this.detail.totalFuelTax) || 0);
            if (this.detail.productInfo) {
                result += parseFloat(this.detail.productInfo.markupPrice || 0);
            }
            return result;
        },
    },
    filters: {
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
        filterCabinRank(val) {
            if (parseInt(val) === 1) {
                return '头等舱'
            } else if (parseInt(val) === 2) {
                return '公务舱'
            } else if (parseInt(val) === 3) {
                return '经济舱'
            } else {
                return ''
            }
        },
        filterTime(val) {
            if (val) {
                // 转换为式分秒
                let h = parseInt(val / 60 / 60 % 24);
                h = h < 10 ? '0' + h : h;
                let m = parseInt(val / 60 % 60);
                m = m < 10 ? '0' + m : m;
                let s = parseInt(val % 60);
                s = s < 10 ? '0' + s : s;
                let text = `${m}分${s}秒`;
                if (h !== '00') {
                    text = h + '时' + text
                }
                return text
            } else {
                return '00分00秒'
            }
        },
        convertTime(time) {
            if(time === null || time === '') {
                return;
            }
            // 提取小时数和分钟数
            const hours = parseInt(time.match(/(\d+)小时/)[0]);
            let minutesList = time.match(/(\d+)分钟/);
            if (minutesList == null) {
                minutesList = time.match(/(\d+)分/);
            }
            if (minutesList == null) {
                minutesList = '';
            }
            let minutes = 0;
            if (minutesList.length > 1) {
                minutes = parseInt(minutesList[1]);
            }

            return `${hours > 0 ? `${hours}h` : ''}${minutes > 0 ? `${minutes}m` : ''}`;
        }
    },
    methods: {
        firstCopySuccess(e) {
            Toast('已复制');
        },
        clickApplyNo(){
            if (this.detail.applicationid) {
                if(this.detail.approveType === 2) {
                    this.$router.push({
                        name: 'h5-apply-approve-exceed', // admin-my-apply-manage-apply-approve-exceed   ?applyId=3550&type=approve
                        query: {
                            applyId: this.detail.applicationid,
                            applyNo: this.detail.applyNo,
                            approveStatus: '',
                            selectType: 1,
                            // type: 'applyDetail',
                            approveType: 2,
                            type: 'applyDetail'
                        }
                    })
                } else {
                    this.$router.push({
                        name: 'h5-approve-detail',
                        query: {
                            id: this.detail.applicationid,
                            approveType: this.detail.approveType,
                            type: 'detail',
                            applyNo: this.detail.applyNo
                        },
                    });
                }

            }
        },
        // 返回
        back() {
            if (this.$route.query.jumpType) {
                if (this.$route.query.jumpType == 'home') {
                    this.$router.push({
                        name: 'h5-home',
                    })
                }
                if (this.$route.query.jumpType == 'list') {
                    this.$router.push({
                        name: 'h5-flight-order-list',
                        query: {
                            type: '0'
                        }
                    })
                }
            } else {
                this.$router.go(-1)
            }
        },
        closeItem() {},
        // 获取订单详情
        async getOrderDetail(val) {
            let _this = this;
            let orderNo = this.$route.query.orderNo || '';
            // if (!val) {
            //     this.isLoading = true
            // }
            this.$showLoading.show();
            let [err, res] = await awaitWrap(consumer_air_ticket_orderDetail({ orderNo }));
            if (err) {
                // this.isLoading = false;
                this.$showLoading.hide();
                return
            }
            // this.isLoading = false;
            this.$showLoading.hide();
            let detail = res.datas;
            detail.airplanePassenger.forEach(value => {
                value.show = true;
            });
            this.detail = detail;
            this.ruleShowType = this.detail.flightType === '1' ? '000' : '001';
            this.milliseconds = detail.milliseconds || 0;
            if (this.milliseconds > 0) {
                this.paySetIntervalFun = setInterval(() => {
                    this.milliseconds--;
                    if (this.milliseconds <= 0) {
                        clearInterval(this.paySetIntervalFun);
                        this.getOrderDetail()
                    }
                }, 1000)
            }
            await this.checkPermissions();
            if (Number(this.detail.orderStatusDigital) === 11 || Number(this.detail.orderStatusDigital) === 13 || (Number(this.detail.orderStatusDigital) === 22 && this.type !== 'review' && this.isCanChange)){
                let setBox = setInterval(() => {
                    if (_this.$refs.btnBox) {
                        fixStatusBar({
                            type: `000`,
                            handler({default_handler, api}) {
                                _this.$refs.btnBox.$el.style.paddingBottom = (api.safeArea.bottom + 8) + 'px';
                            }
                        });
                        clearInterval(setBox)
                    }
                }, 500)
            }
            this.$nextTick(() => {
                /*this.$refs.approvalProcess.init({
                    status: detail.status,
                    statusText: detail.statusText,
                    approvalData: detail.recordList,
                    approvalReason: detail.approvalReason,
                    btnParams: {
                        isShow: false,
                        approvalParams: {}
                    }
                })*/
            })

            // 订单状态为出票成功，航班日期 ≤ 2024-12-31，国内单程，起飞机场或者到达机场为 北京大兴国际机场（PKX），实际承运航班航司为 CZ、OQ，舱位单人票面价（不含税）大于500元
            // 订单状态为出票成功，航班日期 ≤ 2024-12-31，国内单程，起飞机场或者到达机场为 CAN、ZUH、HUZ、SWA、ZHA、HSC、SZX，实际承运航班航司为 CZ、OQ，舱位单人票面价（不含税）大于500元
            if (this.detail.theFlightInformationList.length === 1 && this.detail.orderStatusDigital === 22 && parseInt(this.detail.totalRebatePrice) > 500) {
                this.detail.theFlightInformationList.forEach(value=>{
                    let deptTime = moment(value.depDate).valueOf();
                    let lastTime = moment('2024-12-31 23:59:59').valueOf();
                    if (deptTime < lastTime && (['CZ', 'OQ'].indexOf(value.airlineCode) > -1 || ['CZ', 'OQ'].indexOf(value.shareAirlineCode) > -1 ) && (value.depAirportCode === 'PKX' || value.arrAirportCode === 'PKX')){
                        this.showDXNanhangLine = true;
                    }
                    if (deptTime < lastTime && (['CZ', 'OQ'].indexOf(value.airlineCode) > -1 || ['CZ', 'OQ'].indexOf(value.shareAirlineCode) > -1 ) && (['CAN','ZUH','HUZ','SWA','ZHA','HSC','SZX'].indexOf(value.depAirportCode) > -1 || ['CAN','ZUH','HUZ','SWA','ZHA','HSC','SZX'].indexOf(value.arrAirportCode) > -1)){
                        this.showGDNanhangLine = true;
                    }
                })
            }
        },
        toGDLine() {
            window.open('https://m.csair.com/extra_book/productPresent/#/?code=fx?airport=DWQ');
        },
        toDXLine() {
            window.open('https://m.csair.com/extra_book/productPresent/#/?airport=pkx&utm_source=msg');
        },
        // 获取个人信息
        async getUserInfo() {
            let res = await get_user_info();
            this.userId = res.datas.id
        },
        //检查当前用户是否可退改此行程
        async checkPermissions() {
            await this.getUserInfo();
            if (this.detail.initialUserId === this.userId) {
                this.isCanChange = true
            }
        },
        // 展开
        showMore(val, index) {
            let item = val;
            item.show = !item.show;
            this.detail.airplanePassenger[index] = item
        },
        // 查看退改规则
        backRule() {
            this.ruleText = {};
            this.ruleTextList = [];
            this.ruleShow = true;
            let data = {};
            if (this.detail.flightType === '1') {
                this.productTag = this.detail.theFlightInformationList[0].productTag;
                let data = {
                    segmentNo: this.detail.theFlightInformationList[0].segmentNo
                };
                this.ruleLoading = true;
                consumer_air_ticket_flightRules(data).then(res => {
                    this.ruleLoading = false;
                    this.ruleText = res.datas.rsData
                }).catch(() => {
                    this.ruleLoading = false
                })
            } else {
                data = {
                    segmentNos: [
                        this.detail.theFlightInformationList[0].segmentNo,
                        this.detail.theFlightInformationList[1].segmentNo,
                    ]
                };
                this.ruleLoading = true;
                consumer_air_ticket_returnFligthRules(data).then(res => {
                    this.ruleLoading = false;
                    this.ruleTextList = res.datas.rsData
                }).catch(() => {
                    this.ruleLoading = false
                })
            }
        },
        // 关闭退改规则弹窗
        closeRuleShow() {
            this.ruleShow = false
        },
        // 去改签
        goChange() {
            this.$router.push({
                name: "flight-change",
                query: {
                    orderNo: this.$route.query.orderNo,
                    evectionNo: this.detail.evectionNo,
                },
            })
        },
        goRefund() {
            this.$router.push({
                name: "flight-refund",
                query: {
                    orderNo: this.$route.query.orderNo,
                    orderStatus: 1
                },
            })
        },
        showMoreContact() {
            this.contactShow = !this.contactShow
        },
        showMoreOrderInfo() {
            this.orderInfoShow = !this.orderInfoShow
        },

        goEvectionApplyDetail() {
            if (this.detail.temporaryEvectionStatus !== 0) {
                return
            }
            this.$router.push({
                name: 'h5-office-evection-details',
                query: {
                    id: this.detail.evectionNo,
                }
            })
            /*this.$router.push({
                name: 'h5-approve-detail',
                query: {
                    id: this.detail.applyId,
                    approveType: this.detail.approveType,
                    type: 'approve'
                }
            })*/
        },
        getPolicyDetail() {
            if(this.detail.applicationid === null || this.detail.applicationid === '') {
                return;
            }
            this.$router.push({
                name: 'h5-apply-approve-exceed', // admin-my-apply-manage-apply-approve-exceed   ?applyId=3550&type=approve
                query: {
                    applyId: this.detail.applicationid,
                    applyNo: this.detail.applyNo,
                    approveStatus: '',
                    selectType: 1,
                    // type: 'applyDetail',
                    approveType: 2,
                    type: 'applyDetail'
                }
            })
        },
        showRecordMore() {
            this.recordShow = !this.recordShow
        },
        getRecordStatus(val) {
            // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return 'wait'
            } else if (val === 1) {
                return 'wait'
            } else if (val === 2) {
                return 'pass'
            } else if (val === 3 || val === 4) {
                return 'fail'
            } else {
                return ''
            }
        },
        getRecordStatusText(val) {
            // 审批状态：0.未开始，1.待我审，2.审核通过，3.驳回，4.已失效
            if (val === 0) {
                return '待审核'
            } else if (val === 1) {
                return '待审核'
            } else if (val === 2) {
                return '通过'
            } else if (val === 3) {
                return '驳回'
            } else if (val === 4) {
                return '已失效'
            } else {
                return ''
            }
        },
        recordEnd(val) {
            if (!val) {
                return false
            }
            if (val.approveStatus === 2 || val.approveStatus === 3 || val.approveStatus === 4) {
                return true
            } else {
                return false
            }
        },
        goPay() {
            this.$router.push({
                name: 'h5-travel-payment',
                query: {
                    orderNo: this.detail.orderNo,
                    orderType: "1",
                    businessType: 1,
                    flightOrderList: this.detail.orderNo,
                }
            });
        },
        cancel() {
            Dialog.confirm({
                title: '提示',
                message: '确定要取消订单吗?',
            }).then(() => {
                    // on confirm
                let data = {
                    orderNo: this.detail.orderNo
                };
                cancelOrder(data).then(res => {
                    Notify({ type: 'success', message: '取消订单成功' });
                    this.$router.push({
                        name: "h5-flight-order-list",
                    });
                });
            }).catch(() => {
                    // on cancel
            });
        },
        showMoreInsuranceInfo() {
            this.insuranceShow = !this.insuranceShow
        },
        checkInsuranceDetail(val) {
            // Toast.loading({
            //     duration: 0, // 持续展示 toast
            //     forbidClick: true,
            // });
            this.$showLoading.show();
            const params = {
                insuranceOrderId: val.insuranceOrderId
            };
            consumer_insurance_getEInsuranceOrderUrl(params).then(res => {
                const url = res.datas.epolicyUrl;
                if (url) {
                    open({
                        url,
                        title: '保险详情'
                    })
                }
            }).finally(() => {
                // Toast.clear()
                this.$showLoading.hide();
            })
        },

        //显示价格详情弹框
        showPriceDetailDialog() {
            this.isShowPriceDetail = !this.isShowPriceDetail;
        },

        //显示行程详情
        showFlightDetailDialog() {
            this.isShowFlightDetail = !this.isShowFlightDetail;
        },

        //显示保险详情
        toInsuranceDetail() {
            this.$router.push({
                name: "flight-insurance",
                query: {
                    insuranceInfo: JSON.stringify(this.detail.insuranceInfo),
                },
            });
        },

        showDepFlight() {
            this.isShowDepFlight = !this.isShowDepFlight;
        },

        showReFlight() {
            this.isShowReFlight = !this.isShowReFlight;
        },
        showProductDetail() {
            console.log('1212');
            console.log(this.$refs.aFlightUpLevelInfo);
            this.$refs.aFlightUpLevelInfo.openUpLevelDetail();
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
