import _ from 'underscore';
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'

import aRouter from "@/router";

//000:指定页面没有同时实现两个自定义方法，走history模式
//100:指定页面同时实现了两个自定义方法，走自定义模式
const get_data = function () {

    const currentRoute = aRouter.currentRoute;
    if (!currentRoute) return {type: `000`};

    const matched = currentRoute.matched;
    const matched_length = matched.length;
    if (matched_length <= 0) return {type: `000`};

    const m = matched[matched_length - 1];
    const instances = m.instances;
    if (!instances) return {type: `000`};

    const vm = instances.default;
    if (!vm) return {type: `000`};

    const __base_layout_header_get_btn_go_back_show__ = vm.__base_layout_header_get_btn_go_back_show;
    if (!_.isFunction(__base_layout_header_get_btn_go_back_show__)) return {type: `000`};

    const __base_layout_header_btn_go_back_click_event_handler__ = vm.__base_layout_header_btn_go_back_click_event_handler;
    if (!_.isFunction(__base_layout_header_btn_go_back_click_event_handler__)) return {type: `000`};

    const __base_layout_header_get_btn_go_back_show = _.bind(__base_layout_header_get_btn_go_back_show__, vm);
    const __base_layout_header_btn_go_back_click_event_handler = _.bind(__base_layout_header_btn_go_back_click_event_handler__, vm);

    return {
        type: `100`,
        __base_layout_header_get_btn_go_back_show,
        __base_layout_header_btn_go_back_click_event_handler,
    };
};

export default {
    data() {
        return {
            enable_left_arrow: false,
            left_text: ``,
            right_text: ``,
        };
    },
    methods: {
        __nav_bar_click_left_event_handler() {
            const __this = this;
            const $route = __this.$route;
            const enable_left_arrow = __this.enable_left_arrow;
            if (!enable_left_arrow) return;

            const data = get_data();
            const type = data.type;
            const __base_layout_header_btn_go_back_click_event_handler = data.__base_layout_header_btn_go_back_click_event_handler;

            if (type === `000`) {
                __this.$router.back();
                return;
            }

            if (type === `100`) {
                __base_layout_header_btn_go_back_click_event_handler();
                return;
            }

        },

        __nav_bar_click_right_event_handler() {
            const __this = this;
            __this.$toast('按钮');
        },

        __init_enable_left_arrow() {
            const __this = this;

            //没有找到保证路由加载完成的事件，使用setTimeout替代
            setTimeout(function () {
                const data = get_data();
                const type = data.type;
                const __base_layout_header_get_btn_go_back_show = data.__base_layout_header_get_btn_go_back_show;
                if (type === `000`) {
                    __this.enable_left_arrow = global.history.length > 0;
                }

                if (type === `100`) {
                    __this.enable_left_arrow = __base_layout_header_get_btn_go_back_show();
                }
            }, 500)
        },
    },
    watch: {
        $route: {
            handler(pNewValue, pOldValue) {
                const __this = this;
                __this.__init_enable_left_arrow();
            },
            immediate: true,
        },
    },
    created() {
        const __this = this;
    },
    mounted() {
        const __this = this;

        fixStatusBar({
            mock_data: {
                // enable: true,
                enable: false,
            },
            handler({api}) {
                console.log(`fixStatusBar`, `api.safeArea.top=`, api.safeArea.top);
                // console.log(`fixStatusBar`, `api.safeArea.bottom=`, api.safeArea.bottom);
                const paddingTop = `${api.safeArea.top}px`;
                // const paddingBottom = `${api.safeArea.bottom}px`;

                const $store = __this.$store;
                const data_for_base_layout = $store.state.data_for_base_layout;
                const header = data_for_base_layout.header;
                const style = header.style;
                style.paddingTop = paddingTop;
            }
        });
    },
}
