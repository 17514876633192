import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/agreement',
            name: 'agreement',
            component: () => import(/* webpackChunkName: "page-agreement" */ `./index.vue`),
        }
    ],
};


