//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { revoke } from '@/lib/data-service/haolv-default/design';
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Toast} from 'vant';
export default {
  name: 'RevokeModal',
  components: {NavBar},
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false
    },
    processInfo: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      loading: false,
      formValue: {
        comments: ''
      },
    }
  },
  computed: {
    show: {
      get() {
        return this.visible
      },
      set(visible) {
        this.$emit('update:visible', visible)
      }
    }
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate().then(() => {
          const params = {
              ...this.processInfo,
              ...this.formValue,
          }
          this.loading = true
          revoke(params).then(() => {
              this.loading = false;
              Toast.success('操作成功');
              this.handleCancel();
              this.$emit('success')
          }).finally(() => {
              this.loading = false
          })
      }).catch(()=>{})
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetValidation();
      this.show = false;
        this.$emit("cancel");
    }
  }
}
