import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: "",
    component: () => import_latest_version_module(),
    children: [
      {
        path: "/h5/evection-order-detail",
        name: "h5-evection-order-detail",
        // component: () => import(/* webpackChunkName: "page-evection-evection-order-evection-order-detail" */ `./../../reserve-order/reserve-order-detail/index.vue`),
        component: () => import(`./3.0.1/index.vue`),
        meta: {
          h5_layout: {
            need_alive: true,
            need_head_nav: true,
            current_page_name: "出差单详情",
            need_footer_bar: false,
            secondary_menu: 2,
          },
        },
      },
    ],
  };
