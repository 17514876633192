import NavBar from "@/component/nav-bar/import_latest_version_module";
import moment from "moment";
export default {
    data() {
        return {
            popupShow: false,
            nowTime: '',
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _openedPopup() {},
        show() {
            this.nowTime = moment().format('YYYY年MM月DD日')
            this.popupShow = true;
        },
        hide() {
            this.popupShow = false;
        },
        isShow() {
            return this.popupShow;
        },
    }
}
