//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OrgPicker from "./../OrgPicker.vue";
import { assignee } from "@/lib/data-service/haolv-default/design";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {Toast} from 'vant';
export default {
  name: "AssigneeModal",
  components: {NavBar, OrgPicker,  },
  props: {
    // 是否显示
    visible: {
      type: Boolean,
      default: false,
    },
    processInfo: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      loading: false,
      formValue: {
        comments: "",
        transferUserInfo: null,
        imageList: [],
        fileList: [],
      },
      rules: {
        transferUserInfo: [
          { required: true, message: "请选择转办人", trigger: "change" },
        ],
      },
    };
  },
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(visible) {
        this.$emit("update:visible", visible);
      },
    },
    // 用户名展示
    transferUserInfoUserName() {
      return this.formValue.transferUserInfo?.name || "";
    },
  },
  methods: {
    // 确认操作
    handleConfirm() {
      this.$refs.formRef.validate().then(() => {
          const { imageList, fileList, ...restParams } = this.formValue;
          const params = {
              ...this.processInfo,
              ...restParams,
              attachments: [...imageList, ...fileList],
              phone: restParams.transferUserInfo.phone,
          };
          this.loading = true;
          assignee(params)
              .then(() => {
                  this.loading = false;
                  Toast.success("转办成功");
                  this.handleCancel();
                  this.$emit("success");
              })
              .finally(() => {
                  this.loading = false;
              });
      }).catch(()=>{});
    },
    // 取消操作
    handleCancel() {
      this.$refs.formRef.resetValidation();
      this.formValue = {
        comments: "",
        transferUserInfo: null,
        imageList: [],
        fileList: [],
      };
      this.show = false;
        this.$emit("cancel");
    },
    // 显示用户选择器
    handleShowOrgPicker() {
      this.$refs.orgPicker.show();
    },
    // 选择用户确认操作
    handleSelectedOrg(selected) {
      if (!selected.length) {
        this.formValue.transferUserInfo = null;
        return;
      }
      const [item] = selected;
      if (item.id === this.processInfo.currentUserInfo.id) {
          Toast.fail('转办人不能给自己');
        return;
      }
      this.formValue.transferUserInfo = item;
    },
  },
};
