import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/hotel-search',
      name: 'h5-hotel-search',
      component: () => import(/* webpackChunkName: "page-travel-book-hotel-hotel-search" */ `./1.2.7/index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '酒店搜索',
          need_head_nav: false,
          need_footer_bar: false,
          secondary_menu: 0,
          need_alive: true,
        },

	data_for_all_routes_processor: {
    		order_index: '012-001',
	},

},
    }
  ],
};
