/**
 * 1. 中顺洁柔（云浮）纸业有限公司  276
 * 2. 中顺洁柔纸业股份有限公司  217
 * 3. 中顺洁柔纸业股份有限公司唐山分公司  279
 * 4. 中顺洁柔（四川）纸业有限公司  278
 * 5. 中山市中顺商贸有限公司  282
 * 6. 浙江中顺纸业有限公司  281
 * 7. 成都中顺纸业有限公司  280
 * 8. 江门中顺纸业有限公司  277
 * 9. 中顺洁柔（湖北）纸业有限公司  275
 * **/
// 不显示是否超标标签的公司
const hidePolicyLabelCompanyIdList = [217,274,275,276,277,278,279,280, 283, 282, 281]; //
// 不显示用车入口的公司
const hideUseCarCompanyIdList = [217,274,275,276,277,278,279,280, 283, 282, 281];
// 不开放火车票的公司
const hideTrainOpenCompanyIdList = [276, 217, 279, 278, 282, 281, 280, 277, 275]; //
// 不显示是否超标的公司中要显示是否超标的人员
// 不显示用车入口的公司中要显示用车入口的人员
const showPolicyLabelUserId = [{
    companyId: 217,
    userId: [38711, 76270]
}];
const hideEvectionTypeChangeTabCompanyIdList = [302]; // 302 法巴农银 隐藏因私预订
const flightListSortByPriceCompanyIdList = [290]; // 290 广东志特新材料集团有限公司 机票排序由票价低开始
// 返回值： boolean true：显示， false: 不显示
function getCarUserShow(companyId, userId) {
    // 如果存在上述的公司id，就不显示
    let notHaveCompanyId = hideUseCarCompanyIdList.indexOf(companyId) === -1;
    let haveShowUserId = false;
    // 但是如果是上述公司的某一个用户，则显示
    showPolicyLabelUserId.forEach(value => {
        if (value.companyId === companyId && value.userId.indexOf(userId) > -1) {
            haveShowUserId = true
        }
    });

    return notHaveCompanyId || haveShowUserId
}

export {
    hidePolicyLabelCompanyIdList,
    hideUseCarCompanyIdList,
    hideTrainOpenCompanyIdList,
    showPolicyLabelUserId,
    hideEvectionTypeChangeTabCompanyIdList,
    flightListSortByPriceCompanyIdList,
    getCarUserShow
}
