import moment from "moment";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import Calendar from '@/page/travel-book/flight/component/calendar/1.0.0/index.vue'
import ChangClause from '@/page/travel-book/flight/component/changClause/1.0.0/index.vue'
import ChangeClausePopup from '@/page/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import { Toast } from 'vant'
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import DomesticTicketAirportSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/domestic-ticket-airport-selector/1.1.4";
import consumer_flight_search_stipulates from '@/lib/data-service/haolv-default/consumer_flight_search_stipulates'

import VirtualList from 'vue-virtual-scroll-list'
import FlightItem from '@/page/travel-book/flight/component/flightItem/1.0.5/index.vue'
import FlightListSkeleton from '@/component/skeleton/flight-list-skeleton/index.vue'

import consumer_flight_search_changeSearch from '@/lib/data-service/haolv-default/consumer_flight_search_changeSearch'
import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
export default {
    name: 'flightSearch',
    data () {
        return {
            fromStationCode: '', // 出发站id
            toStationCode: '', // 到达站id
            startFlightParams: {}, // 出发站信息
            arriveFlightParams: {}, //到达站信息
            startDate: '', // 出发时间
            endDate: '', //返程时间
            flightType: 1, // 航程类型
            cabinRank: '', // 仓位登记 0=不限 1=头等舱 2=公务舱 3=经济舱
            airLineCode: '', // 航司二字码
            psgType: 1, // psgType
            takeOffInterval: '', //
            flightNo: '', // 航班
            airlineName: '', // 航空公司名称

            segmentTypeText: '', // 去返程名称

            // evectionNo: '', // 出差单编号
            // evectionType: 1, // 出差类型
            redirectType: '', // 返回类型
            journeyNo: '', // 订单号

            flightList: [], // 机票列表
            oriFlightsList: [], // 原始机票列表
            filterFlightList: [], // 中间存储列表

            travelPopupShow: false,
            flightFromStationCode: '', // 搜搜条件-出发站
            flightToStationCode: '', // 搜索条件-到达站
            flightStartFlightParams: {}, // 搜索条件
            flightArriveFlightParams: {}, // 搜索条件
            flightStartDate: '', // 搜索条件-出发日期
            flightEndDate: '', // 搜索条件-返程日期

            ruleShow: false, // 退改签说明弹窗

            ruleText: {}, // 退改的文本
            luggage: '', // 行李文本
            canJourney: true, // 防连点
            isShowCalendar: false, // 时间选择弹窗

            params: {}, //

            loadingShow: true,

            flightItemComponent: FlightItem,

            active: 0, // 当前tab
            orderField: 'depTime', // 排序字段，depTime-起飞时间 arrTime-到达时间。初始数据排列默认按起飞时间和到达时间从早到晚排序, 后续其他排序前端自行处理
            orderType: '', // 排序类型，asc-从小到大/从早到晚/从低到高 desc-从大到小/从晚到早/从高到低
            priceType: '', // 价格排序，同上

            screenShow: false, // 筛选框展示

            onlyLineFly: [], // 只看直飞，
            setOnlyLineFly: [],
            hideShareFly: [], // 隐藏共享航班
            setHideShareFly: [],

            takeOffTime: [
                /*{ value: "", label: "全部" },*/
                { value: "00:00-05:59", label: "00:00-05:59" },
                { value: "06:00-11:59", label: "06:00-11:59" },
                { value: "12:00-17:59", label: "12:00-17:59" },
                { value: "18:00-23:59", label: "18:00-23:59" }
            ],
            takeOffTimeParams: '', // 起飞时间段
            setTakeOffTimeParams: '',
            endTakeOffTimeParams: '', // 降落时间段
            setEndTakeOffTimeParams: '',

            depAirportList: [],
            depAirportParams: '', // 出发城市机场搜索关键词
            setDepAirportParams: '',
            arrAirportList: [],
            arrAirportParams: '',
            setArrAirportParams: '',
            planeSizeList: [
                {value: '', label: '所有机型'},
                {value: 1, label: '大型机'},
                {value: 2, label: '中型机'},
                {value: 3, label: '其他型机'},
            ],
            planeSizeParams: '',
            setPlaneSizeParams: '',
            classTypeList: [
                //  舱位列表
                {
                    value: '',
                    label: "所有舱位"
                },
                {
                    value: '1,2',
                    label: "头等/商务舱"
                },
                /*{
                    value: 2,
                    label: "公务舱"
                },*/
                {
                    value: '3',
                    label: "经济舱"
                }
            ],
            cabinParams: '', // 舱位筛选
            setCabinParams: '',
            airlinesList: [],
            airlinesParams: '',
            setAirlinesParams: '',

            ruleLoading: false,
            luggageLoading: false,
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        }, // 是否展示
        changeForm: {
            type: Object,
            default: () => {}
        },
        changeType: {
            type: [Number, String],
            default: 1
        }, // 更改类型 1改期 2升舱
        changeEvectionType: {
            type: Number,
            default: 1
        },
        changeIndex: {
            type: Number,
            default: 0
        }, // 当前改签的航班的序号
        evectionNo: {
          type: String,
          default: ''
        },
        orderNo: {
            type: String,
            default: ''
        },
        newTravelUser: {
            type: Array,
            default: () => []
        },
        changeSupplierOrderNo: {
            type: String
        }
    },
    components: {
        NavBar,
        Calendar,
        ChangClause,
        DomesticTicketAirportSelector,
        'virtual-list': VirtualList,
        FlightListSkeleton,
        ChangeClausePopup
    },
    created () {
    },
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {
        /*startDate(newVal) {
            // this.search()
        },*/
        flightFromStationCode(newVal) {
            this.$nextTick(() => {
                this.$refs.startAirportSelector.get_selected_entity_promise().then(res => {
                    this.flightStartFlightParams = res
                })
            })
        },
        flightToStationCode(newVal) {
            this.$nextTick(() => {
                this.$refs.arriveAirportSelector.get_selected_entity_promise().then(res => {
                    this.flightArriveFlightParams = res
                })
            })
        },
        popupShow(newVal) {
            if (newVal) {
                console.log('c', this.changeForm)
                this.fromStationCode = this.changeForm.fromStationCode
                this.toStationCode = this.changeForm.toStationCode
                this.startFlightParams = this.changeForm.startFlightParams
                this.arriveFlightParams = this.changeForm.arriveFlightParams
                this.startDate = this.changeForm.startDate
                this.flightType = 1
                this.airlineName = this.changeForm.airlineName
                this.airLineCode = this.changeForm.airLineCode
                this.segmentTypeText = this.changeForm.segmentTypeText
                this.flightNo = this.changeForm.flightNo ? this.changeForm.flightNo : ''


                this.$nextTick(() => {
                    this.search()
                })
            } else {
                Toast.clear()
            }
        }
    },
    computed: {
        popupShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        }
    },
    filters: {
        filterFlightDate(val) {
            return val === '' ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterFlightDaY(val) {
            return val === '' ? '' : moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day()
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '公务舱'
            } else {
                return '经济舱'
            }
        },
        filterOrderType(val) {
            if (val === 'asc') {
                return '早-晚'
            } else if (val === 'desc') {
                return '晚-早'
            } else {
                return ''
            }
        },
        filterPriceType(val) {
            if (val === 'asc') {
                return '低-高'
            } else if (val === 'desc') {
                return '高-低'
            } else {
                return ''
            }
        },
    },
    methods: {
        // 判断机场字段的长短返回样式
        inputFontLong(val) {
            if (val && (val.length > 0 && val.length < 3)) {
                return ''
            } else {
                return 'small'
            }
        },
        // 关闭搜索下拉框
        closeTravelPopup() {
            this.travelPopupShow = false
        },
        // 返回
        back() {
            this.popupShow = false
        },
        // 打开搜索下拉框
        showSearchBox() {
            this.travelPopupShow = true
            console.log(this.startFlightParams)
        },
        // 打开完搜索下拉框后触发
        openSearchBox() {
            this.flightFromStationCode = this.fromStationCode
            this.flightToStationCode = this.toStationCode
            this.flightStartFlightParams = JSON.parse(JSON.stringify(this.startFlightParams))
            this.flightArriveFlightParams = JSON.parse(JSON.stringify(this.arriveFlightParams))
            console.log(this.flightStartFlightParams, this.startFlightParams)
            this.flightStartDate = this.startDate
        },
        // 选择时间
        showCalendar() {
            if (this.changeType !== 1) {
                return
            }
            this.isShowCalendar = true
        },
        // 确认选择时间
        onCalendarConfirm(val) {
            this.flightStartDate = moment(val).format('YYYY-MM-DD')
            this.isShowCalendar = false
        },
        // 打开出发站选择弹框
        openStartFlightPopup() {
            this.$refs.startAirportSelector.show()
        },
        // 打开到达站选择弹框
        openArriveFlightPopup() {
            this.$refs.arriveAirportSelector.show()
        },
        toSearch() {
            this.travelPopupShow = false
            this.fromStationCode = this.flightFromStationCode
            this.toStationCode = this.flightToStationCode
            this.startFlightParams = JSON.parse(JSON.stringify(this.flightStartFlightParams))
            this.arriveFlightParams = JSON.parse(JSON.stringify(this.flightArriveFlightParams))
            this.startDate = this.flightStartDate
            this.search()
        },
        clickDate(val) {
            this.search()
        },
        search() {
            if (this.fromStationCode === '') {
                Toast({
                    message: '请选择出发地',
                    position: 'bottom',
                });
                this.loadingShow = false
                return
            }
            if (this.toStationCode === '') {
                Toast({
                    message: '请选择目的地',
                    position: 'bottom',
                });
                this.loadingShow = false
                return
            }
            if (this.startDate === null || this.startDate === '') {
                Toast({
                    message: '请选择日期',
                    position: 'bottom',
                });
                this.loadingShow = false
                return
            }
            let params = {
                cabinRank: this.changeForm.cabinRank,
                depDate: this.startDate,
                flightNo: this.changeForm.flightNo,
                orderNo: this.orderNo,
                segmentNo: this.changeForm.segmentNo,
                userIds: this.newTravelUser,
            };
            let data = {
                companyCode: '',
                rqData: Object.assign({}, params),
                //sign: '',
                //timeStamp: ''
            }
            this.loadingShow = true
            consumer_flight_search_changeSearch(data).then(res => {
                if (!res.rsData) {
                    this.loadingShow = false
                    return
                }
                let flightList = res.rsData
                let airlinesCodeList = [] // 航司Code合集
                let airlinesList = [] // 航司合集
                let depAirportCodeList = [] // 起飞机场的code合集
                let depAirportList = [] // 起飞机场的合集
                let arrAirportCodeList = [] // 降落机场的code合集
                let arrAirportList = [] // 降落机场的合集
                flightList.forEach((value, index) => {
                    value.show = false
                    value.price = 0
                    value.discount = 0
                    value.cabin = ''
                    value.loading = false
                    if (index === 0) {
                        depAirportList.push({value: '', label: `${value.depCityName}所有机场`})
                        arrAirportList.push({value: '', label: `${value.arrCityName}所有机场`})
                        airlinesList.push({
                            value: '',
                            label: '所有航空公司',
                            logo: ''
                        })
                    }

                    if (airlinesCodeList.indexOf(value.airCompanyCode) === -1) {
                        airlinesCodeList.push(value.airCompanyCode)
                        airlinesList.push({
                            value: value.airCompanyCode,
                            label: value.airCompanyName,
                            logo: value.airCompanyLogoImg
                        })
                    }
                    if (depAirportCodeList.indexOf(value.depAirportCode) === -1) {
                        depAirportCodeList.push(value.depAirportCode)
                        depAirportList.push({
                            value: value.depAirportCode,
                            label: value.depAirportName
                        })
                    }
                    if (arrAirportCodeList.indexOf(value.arrAirportCode) === -1) {
                        arrAirportCodeList.push(value.arrAirportCode)
                        arrAirportList.push({
                            value: value.arrAirportCode,
                            label: value.arrAirportName
                        })
                    }
                })
                // this.flightList = flightList
                this.airlinesList = airlinesList
                this.depAirportList = depAirportList
                this.arrAirportList = arrAirportList
                this.oriFlightsList = flightList
                console.log(this.airlinesList, this.depAirportList, this.arrAirportList)
                this.trainFilter()
                // Toast.clear()
            }).catch(() => {
                this.loadingShow = false
                // clearInterval(this.percentSetInterval)
                this.flightList = []
                // Toast.clear()
            })
        },
        trainFilter() {
            let oriFlightsList = JSON.parse(JSON.stringify(this.oriFlightsList))
            // 是否只看直飞
            if (this.onlyLineFly.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.stopNum === 0) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            // 是否隐藏共享航班
            if (this.hideShareFly.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (!value.hasShare) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            if (this.airlinesParams !== '') {
                // this.airlinesParams.value
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.airCompanyCode === this.airlinesParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.cabinParams !== '') {
                let flightItemList = []
                let cabinParamsArr = this.cabinParams.value.split(',')
                oriFlightsList.forEach(value => {
                    let policys = []
                    value.productList.forEach(value1 => {
                        let product = []
                        value1.productList.forEach(value2 => {
                            if (cabinParamsArr.indexOf(value2.cabin[0].cabinRank.toString()) >= 0) {
                                product.push(value2)
                            }
                        })
                        value1.productList = product
                        if (product.length > 0) {
                            policys.push(value1)
                        }
                    })
                    value.productList = policys
                    if (policys.length > 0) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.takeOffTimeParams !== '') {
                let flightItemList = []
                let takeOffTimeArr = this.takeOffTimeParams.split('-')
                let leftTime = this.timeToMinute(takeOffTimeArr[0])
                let rightTime = this.timeToMinute(takeOffTimeArr[1])
                oriFlightsList.forEach(value => {
                    let depTime = this.timeToMinute(value.depTime)
                    if (depTime >= leftTime && depTime <= rightTime) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.planeSizeParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.planeSize === this.planeSizeParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.depAirportParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.depAirportCode === this.depAirportParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.arrAirportParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.arrAirportCode === this.arrAirportParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            this.filterFlightList = oriFlightsList
            this.sortList()
        },
        timeToMinute(val) {
            let timeVal = val.split(':')
            let hour = parseInt(timeVal[0]) * 60
            let minute = parseInt(timeVal[1])
            return hour + minute
        },
        sortList() {
            let flightList = JSON.parse(JSON.stringify(this.filterFlightList))

            let timeToMinute = function (val) {
                let timeVal = val.split(':')
                let hour = parseInt(timeVal[0]) * 60
                let minute = parseInt(timeVal[1])
                return hour + minute
            }
            // 按出发时间正序排列
            let depTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按出发时间反序排列
            let depTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按到达时间正序排列
            let arrTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按到达时间反序排列
            let arrTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按价格正序排列
            let priceCompare = function (x, y) {
                console.log(x, y)
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return -1;
                } else if (xPrice > yPrice) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按价格反序排序
            let priceReverseCompare = function (x, y) {
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return 1;
                } else if (xPrice > yPrice) {
                    return -1;
                } else {
                    return 0;
                }
            }

            if (this.orderField === 'depTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(depTimeCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(depTimeReverseCompare)
                }
            } else if (this.orderField === 'arrTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(arrTimeCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(arrTimeReverseCompare)
                }
            } else if (this.orderField === 'price') {
                if (this.priceType === 'asc') {
                    flightList.sort(priceCompare)
                } else if (this.priceType === 'desc') {
                    flightList.sort(priceReverseCompare)
                }
            }
            this.loadingShow = false
            flightList.forEach(value => {
                value.productList = [value.policys]
            })
            this.flightList = flightList
            console.log(this.flightList)
        },
        showMore(val, index) {
            val.show = !val.show
            this.flightList[index] = val
        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        // 返回退改规则
        backRule(val, child) {
            console.log(val, child)
            this.ruleShow = true
            this.ruleLoading = true
            this.ruleText = {}
            let params = {
                rqData: {
                    cabinCode: child.cabin[0].cabinCode,
                    flightKey: val.flightKey,
                    flightNo: val.flightNo,
                    policyId: child.policyId
                }
            }
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleText = res.datas.rsData
            }).finally(() => {
                this.ruleLoading = false
            })
        },
        // 关闭退改规则弹窗
        closeRuleShow() {
            this.ruleShow = false
        },
        submit(flight, policys) {
            console.log(flight, policys)
            if (!this.canJourney) {
                return
            }
            this.canJourney = false
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            })
            let item = { ...flight };
            let formData = {
                rqData: {
                    evectionNo: '',
                    journeyNo: '',
                    cabinRank: policys.cabin[0].cabinRank,
                    directFlight: 0,
                    flightType: this.flightType,
                    psgType: this.psgType,
                    segmentList: []
                }
            }
            let segment = {
                "airportTax": policys.priceDatas[0].airportTax,
                "billingFee": policys.priceDatas[0].billingFee,
                "cabinName": policys.cabin[0].cabinName,
                "cabinCode": policys.cabin[0].cabinCode,
                "cabinRank": policys.cabin[0].cabinRank,
                "chdCabinCode": policys.cabin[0].chdCabinCode,
                "comment": policys.comment,
                "crewType": policys.priceDatas[0].crewType,
                "discount": policys.cabin[0].discount,
                "fuelTax": policys.priceDatas[0].fuelTax,
                "policyId": policys.policyId,
                "price": policys.priceDatas[0].price,
                "priceTaxes": policys.priceDatas[0].priceTaxes,
                "productType": policys.productType,
                "productTag": policys['productTag'],
                "segmentType": 1,//去返程
                "settlement": policys.priceDatas[0].settlement,
                "settlementPriceTaxes": policys.priceDatas[0].settlementPriceTaxes,
                "vtWorkTime": policys.vtWorkTime,
                "workTime": policys.workTime,
                "flightProductType": policys.flightProductType
            }
            formData.rqData.segmentList.push(Object.assign(item, segment))
            this.params = formData
            console.log(this.params)
            this.setOrder()
        },
        setOrder() {
            this.canJourney = true
            let obj = {
                violationResults: this.params.rqData.overproofApplyList ? this.params.rqData.overproofApplyList : [],
                params: this.params
            }
            this.$emit('getSegment', obj, this.changeIndex)
            Toast.clear()
            this.popupShow = false
        },


        toChangeTimeSort() {
            this.priceType = ''
            this.orderField = 'depTime'
            let orderType = this.orderType
            if (orderType === '') {
                this.orderType = 'asc'
            } else if (orderType === 'asc') {
                this.orderType = 'desc'
            } else {
                this.orderType = ''
            }
            this.sortList()
        },
        toChangePriceSort() {
            this.orderType = ''
            this.orderField = 'price'
            let priceType = this.priceType
            if (priceType === '') {
                this.priceType = 'asc'
            } else if (priceType === 'asc') {
                this.priceType = 'desc'
            } else {
                this.priceType = ''
            }
            this.sortList()
        },

        toShowFilter() {
            this.setOnlyLineFly = JSON.parse(JSON.stringify(this.onlyLineFly))
            this.setHideShareFly = JSON.parse(JSON.stringify(this.hideShareFly))
            this.setTakeOffTimeParams = this.takeOffTimeParams
            this.setEndTakeOffTimeParams = this.endTakeOffTimeParams
            this.setDepAirportParams = this.depAirportParams
            this.setArrAirportParams = this.arrAirportParams
            this.setPlaneSizeParams = this.planeSizeParams
            this.setCabinParams = this.cabinParams
            this.setAirlinesParams = this.airlinesParams
            this.screenShow = true
        },

        toggleOnlyLineFly() {
            this.$refs.onlyLineFlyCheckbox.toggle()
        },
        toggleHideShareFly() {
            this.$refs.hideShareFlyCheckbox.toggle()
        },

        clearAllCommandToSearch() {
            this.setOnlyLineFly = []
            this.setHideShareFly = []
            this.setTakeOffTimeParams = ''
            this.setEndTakeOffTimeParams = ''
            this.setDepAirportParams = ''
            this.setArrAirportParams = ''
            this.setPlaneSizeParams = ''
            this.setCabinParams = ''
            this.setAirlinesParams = ''
            // this.trainFilter()
        },
        submitToFilter() {
            this.onlyLineFly = JSON.parse(JSON.stringify(this.setOnlyLineFly))
            this.hideShareFly = JSON.parse(JSON.stringify(this.setHideShareFly))
            this.takeOffTimeParams = this.setTakeOffTimeParams
            this.endTakeOffTimeParams = this.setEndTakeOffTimeParams
            this.depAirportParams = this.setDepAirportParams
            this.arrAirportParams = this.setArrAirportParams
            this.planeSizeParams = this.setPlaneSizeParams
            this.cabinParams = this.setCabinParams
            this.airlinesParams = this.setAirlinesParams
            this.screenShow = false
            this.trainFilter()
        },
        openedFlightSearch() {
            const _this = this
            fixStatusBar({
                type: `000`,
                handler({default_handler, api}) {
                    _this.$refs['navBar'].$el.style.paddingTop = `${api.safeArea.top}px`;
                    _this.$refs['tabBar'].$el.style.paddingBottom = `${api.safeArea.bottom}px`;

                }
            });
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
