import moment from "moment";
// 头部tab
import EvectionHeadTab from "@/page/evection/components/evection-head-tab/3.0.0/index.vue";
// 列表为空展示组件
import ListEmpty from "@/component/list-empty/3.0.0/index.vue";
// 出差单列表
import consumer_evection_evectionList from "@/lib/data-service/haolv-default/consumer_evection_evectionList";

export default {
  components: {
    EvectionHeadTab,
    ListEmpty,
  },
  data() {
    return {
      emptyType: '',
      form: {
        currentPage: 1,
        pageSize: 0,
        evectionType: 1,
      },

      list: [],

      loading: false,
      finished: true,
      refreshing: false,
    };
  },
  watch: {
    loading(val) {
      const Toast = this.$Toast;
      if (val) {
        Toast.loading({
          duration: 0, // 持续展示 toast
          forbidClick: true,
          message: "加载中...",
        });
      } else {
        Toast.clear();
      }
    },
  },
  filters: {
    format_month(val) {
      switch (moment(val).format("MM")) {
        case "01":
          return "一月";
          break;
        case "02":
          return "二月";
          break;
        case "03":
          return "三月";
          break;
        case "04":
          return "四月";
          break;
        case "05":
          return "五月";
          break;
        case "06":
          return "六月";
          break;
        case "07":
          return "七月";
          break;
        case "08":
          return "八月";
          break;
        case "09":
          return "九月";
          break;
        case "10":
          return "十月";
          break;
        case "11":
          return "十一月";
          break;
        case "12":
          return "十二月";
          break;
      }
    },
    format_week (val) {
      switch (moment(val).day()) {
        case 0:
          return '周日'
          break;
        case 1:
          return '周一'
          break;
        case 2:
          return '周二'
          break;
        case 3:
          return '周三'
          break;
        case 4:
          return '周四'
          break;
        case 5:
          return '周五'
          break;
        case 6:
          return '周六'
          break;
      }
    }
  },
  methods: {
    req_list() {
      const form = this.form;
      this.emptyType = '';
      consumer_evection_evectionList(form)
        .then((result) => {
          const datas = result.datas;
          const totalCount = datas.totalCount;
          if (totalCount > form.pageSize) {
            this.finished = false;
          } else {
            form.pageSize = totalCount;
            this.finished = true;
          }
          this.list = datas.list;
          this.refreshing = false;
          this.loading = false;
        })
        .catch((err) => {
          this.list = [];
          this.emptyType = 'network';
          this.finished = true;
          this.refreshing = false;
          this.loading = false;
        });
    },
    onLoad() {
      if (this.refreshing) return;
      this.loading = true;
      this.form.pageSize += 10;
      this.req_list();
    },
    onRefresh() {
      this.form.pageSize = 0;
      this.refreshing = false;
      this.onLoad();
    },
    // 去出差单详情
    to_evection_detail(evectionNo) {
      this.$router.push({
        name: "h5-evection-order-detail",
        query: {
          pageType: 2,
          evectionNo,
        },
      });
    },

    to_flight_detail (orderNo) {
      this.$router.push({
        name: "flight-order-detail",
        query: {
          orderNo
        },
      });
    },
    to_train_detail (orderNo) {
      this.$router.push({
        name: "h5-train-order-detail",
        query: {
          orderNo
        },
      });
    },
    to_hotel_detail (orderNo) {
      this.$router.push({
        name: 'h5-hotel-order-detail',
        query: {
          orderNo,
        },
      })
    },
  },
  created() {
    this.onLoad();
  },
};
