import NavBar from "@/component/nav-bar/import_latest_version_module";
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import StopData from '@/page/travel-book/flight/component/stopData/1.0.0/index.vue'
import StopDataPopup from '@/page/travel-book/flight/component/stopDataPopup/1.0.0/index.vue'
import ChangClause from '@/page/travel-book/flight/component/changClause/1.1.0/index.vue'
import ChangeClausePopup from '@/page/travel-book/flight/component/changeClausePopup/1.0.0/index.vue'
import TravelerListForPrivateReserve from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/traveler-list-for-private-reserve/1.0.1/index.vue'
import consumer_journey_planeTicketcalculatePrice from '@/lib/data-service/haolv-default/consumer_journey_planeTicketcalculatePrice'
import consumer_journey_saveOrderMsg from '@/lib/data-service/haolv-default/consumer_journey_saveOrderMsg'
import consumer_journey_personalCreateOrder from '@/lib/data-service/haolv-default/consumer_journey_personalCreateOrder'
import consumer_flight_order_flightsStop from '@/lib/common-service/consumer_flight_order_flightsStop'
import consumer_flight_search_stipulatesRule from '@/lib/common-service/consumer_flight_search_stipulatesRule'
import consumer_air_ticket_privateDetail from '@/lib/data-service/haolv-default/consumer_air_ticket_privateDetail'
import { awaitWrap } from "@/page/travel-book/flight/common/unit";
import get_user_info from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info'
import InsureChoice  from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/insure-choice/1.0.2/index.vue'
import keybackProcessor from "@/lib/common-service/apicloud/keyback_processor";
import moment from 'moment'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import consumer_flight_search_returnStipulatesRule from '@/lib/data-service/haolv-default/consumer_flight_search_returnStipulatesRule'
import consumer_flight_search_queryPlaneNameByCodes from '@/lib/data-service/haolv-default/consumer_flight_search_queryPlaneNameByCodes'
import consumer_flight_search_queryMeals from '@/lib/data-service/haolv-default/consumer_flight_search_queryMeals';
import {Dialog, Toast} from "vant";
import FlightUpLevelPackage from "@/component/flight-up-level-package/index.vue";

export default {
    data() {
        return {
            title: '填写订单',
            detailShow: false,

            backDialogShow: false,

            loading: false,
            evectionNo: '',
            orderNo: '',
            evectionType: '',

            userInfo: {},
            evectionInfo: {},
            evectionParams: {
                orderNo: '',
                linkman: '',
                linkmanPhone: '',
                linkmanSms: 0, // 联系人短信: 0:不发送;1:发送 默认0
                frequentIds: [],
                bookingSms: 0, // 出行人短信: 0:不发送;1:发送 默认0
            },
            evectionFormParams: {},
            priceResult: {},
            flightList: [],

            ruleShow: false,
            ruleLoading: false,
            ruleText: [], // 退改规则
            luggage: '', // 行李信息
            luggageParams: {}, // 行李对象
            productTag: 0,

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 5,

            searchTemp: '',
            intervalNum: 0,

            stopDataShow: false,
            stopData: [], // 经停信息
            stopDataLoading: false,

            insuranceIdArr: [],

            startCity: '',
            endCity: '',
            startDate: '',
            endDate: '',
            flightType: 1,
            flightDetailShow: false,

            tipsHide: false,
            hasAgreementPricePro: false, // 是否含有协议价产品
            dialogError3Visible: false,

            bookingEquityProductInfos: null, // 加享产品info

            tmcSnailValuations: [],
        }
    },
    components: {
        FlightUpLevelPackage,
        NavBar,
        OrangeNavBar,
        ChangClause,
        TravelerListForPrivateReserve,
        StopData,
        ChangeClausePopup,
        StopDataPopup,
        InsureChoice
    },
    created() {
    },
    mounted() {
        const _this = this
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                // _this.$refs['navBar'].$el.style.paddingTop = `${api.safeArea.top}px`;
                _this.$refs['reserveBottom'].style.paddingBottom = `${api.safeArea.bottom + 8}px`;
                _this.$refs['reserveDetailBottom'].style.paddingBottom = `${api.safeArea.bottom + 64}px`;
            }
        });
    },
    async activated() {
        this.intervalNum = 0
        this.orderNo = this.$route.query.orderNo
        this.evectionParams.orderNo = this.$route.query.orderNo
        this.evectionType = parseInt(this.$route.query.evectionType)
        // await this.getUserInfo() // 获取个人资料
        await this.getEvectionInfo()
        this.$refs['travelerListForReserve'].init({
            businessType: 1,
            startTime: this.evectionInfo.depDate,
            segmentList: this.evectionInfo,
            flightTmcSnailValuations: this.tmcSnailValuations,
        })
        this.$nextTick(() => {
            this.initInsure();
            this.$refs.aFlightUpLevelPackage.init({
                orderNo: this.orderNo,
            })
        })
        this.setSearchReact()

        keybackProcessor.addEventListener(() => {
            if (this.detailShow) {
                this.detailShow = false
                return
            }
            if (this.ruleShow) {
                this.ruleShow = false
                return;
            }
            if (this.stopDataShow) {
                this.stopDataShow = false
                return;
            }
            if (this.$refs.travelerListForReserve.getShow()) {
                this.$refs.travelerListForReserve.hidePopup()
                return;
            }
            if (this.backDialogShow) {
                this.backDialogShow = false
                return;
            }
            this.back()
        })
    },
    deactivated() {
        clearInterval(this.searchTemp)
        keybackProcessor.removeEventListener();
    },
    destroyed() {
        clearInterval(this.searchTemp)
    },
    watch: {},
    computed: {},
    filters: {
        filterDate(val) {
            return moment(val).format('YYYY年MM月DD日')
        },
        filterShortDate(val) {
            if (val == '') {
                return '';
            }
            return moment(val).format('MM-DD')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
    },
    methods: {
        back() {
            this.backDialogShow = true
            /*Dialog.confirm({
                title: '温馨提示',
                message: '您的订单尚未填写完成，确认离开当前页面',
                cancelButtonText: '离开',
                confirmButtonText: '继续预订',
                confirmButtonColor: '#ff7002',
                className: 'reserve-back-warning-dialog',
            }).then(() => {
            }).catch(() => {
                this.$router.go(-1)
            });*/
        },
        toBack() {
            this.$router.go(-1)
        },
        showDetail() {
            this.detailShow = !this.detailShow
        },
        async getUserInfo() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            })
            let [err, res] = await awaitWrap(get_user_info())
            Toast.clear()
            this.userInfo = res.datas
        },
        async getEvectionInfo() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            })
            this.loading = true
            let [err, res] = await awaitWrap(consumer_air_ticket_privateDetail({orderNo: this.orderNo}))
            Toast.clear()
            if (err) {
                this.loading = false
                return
            }
            this.loading = false
            this.evectionInfo = res.datas
            // 获取机票行程
            let startCity = '';
            let endCity = '';
            let startDate = '';
            let endDate = '';
            let flightType = 1;
            let flightTypeList = [];
            let flightQueryMealsList = [];
            let flightStopList = [];
            let flightRuleParams = {
                rqData: []
            };

            let tmcSnailValuations = [];
            this.evectionInfo.forEach((value, index) => {
                if (value.tmcSnailValuations) {
                    tmcSnailValuations = [...tmcSnailValuations, ...value.tmcSnailValuations];
                }
                if (value.policyType === 'QCYX') {
                    this.hasAgreementPricePro = true;
                }
                flightTypeList.push(value.planeType);
                flightQueryMealsList.push({
                    "flightNo":value.flightNo,
                    "deptTime":value.depTime,
                    "arrTime":value.arrTime,
                });
                if (value['stopNum'] > 0) {
                    flightStopList.push({
                        "rqData": {
                            "date": value['depDate'],
                            "flightNo": value['flightNo'],
                            'depCityCode': value['depCityCode'],
                            'arrCityCode': value['arrCityCode'],
                            'arrTime': value['arrTime'],
                            'deptTime': value['depTime'],
                            'depAirCode': value['depCityPortCode'],
                            'arrAirCode': value['arrCityPortCode'],
                        }
                    });
                }
                flightRuleParams.rqData.push({
                    cabinCode: value.cabin,
                    flightKey: value.flightKey,
                    flightNo: value.flightNo,
                    policyId: value.policyId
                })
                if (index === 0) {
                    startCity = value.depCityName;
                    endCity = value.arrCityName;
                    startDate = value.depDate;
                }
                if (index === 1) {
                    endDate = value.depDate;
                    flightType = 2;
                }

            });
            this.tmcSnailValuations = tmcSnailValuations;
            this.startCity = startCity;
            this.endCity = endCity;
            this.startDate = startDate;
            this.endDate = endDate;
            this.flightType = flightType;
            this._queryPlaneNameByCodes(flightTypeList);
            this._queryMeals(flightQueryMealsList);
            this._queryStopInfo(flightStopList);
            this.getRuleDetail(flightRuleParams);
        },
        _queryPlaneNameByCodes(flightTypeList) {
            consumer_flight_search_queryPlaneNameByCodes(flightTypeList).then(res=>{
                let resList = res.datas;
                this.evectionInfo.forEach(value => {
                    resList.forEach(value2 => {
                        if (value.planeType === value2.planeType) {
                            value.planeName = value2.planeName;

                        }
                    })
                })
            })
        },
        _queryMeals(flightQueryMealsList) {
            let apiList = [];
            if (flightQueryMealsList) {
                flightQueryMealsList.forEach(value=>{
                    apiList.push(consumer_flight_search_queryMeals(value));
                })
            }
            Promise.all(apiList).then(resList => {
                resList.forEach(value2 => {
                    let res = value2.datas;
                    if (res == null) {
                        return
                    }
                    this.evectionInfo.forEach(value => {
                        if (res.flightNo === value.flightNo) {
                            let hasMealsText = '';
                            if (res) {
                                if (res.hasMeals === 1) {
                                    hasMealsText = '有餐食';
                                } else {
                                    hasMealsText = '无餐食';
                                }
                            }
                            value.hasMeals = res ? res.hasMeals : '';
                            value.hasMealsText = hasMealsText;
                        }
                    })
                });
            })
        },
        _queryStopInfo(flightStopList) {
            if (flightStopList === undefined || flightStopList === null) {
                return;
            }
            let apiList = [];
            flightStopList.forEach(value => {
                apiList.push(consumer_flight_order_flightsStop(value));
            })

            Promise.all(apiList).then(resList => {
                resList.forEach(value2 => {
                    let res = value2.datas;
                    if (res == null) {
                        return
                    }
                    this.evectionInfo.forEach(value1 => {
                        if (res['flightNo'] === value1['flightNo'] &&
                            res['date'] === value1['depDate']) {
                            let _stopData = res['stopInfos'];
                            let _stopDataResult = [];
                            if (_stopData && _stopData.length > 0) {
                                _stopData.forEach((value, index) => {
                                    if (index !== 0 && index !== (_stopData.length - 1)) {
                                        const _depTimeArr = value['depTime'].split(':');
                                        const _arrTimeArr = value['arrTime'].split(':');
                                        const _depTime =
                                            parseInt(_depTimeArr[0]) * 60 + parseInt(_depTimeArr[1]);
                                        const _arrTime =
                                            parseInt(_arrTimeArr[0]) * 60 + parseInt(_arrTimeArr[1]);
                                        value['time'] = _depTime - _arrTime;
                                        _stopDataResult.push(value);
                                    }
                                });
                                value1['stopData'] = _stopDataResult;
                            }
                        }
                    });
                })
            })
        },
        getRuleDetail(params) {
            this.ruleText = [];
            consumer_flight_search_returnStipulatesRule(params).then(res=>{
                let ruleText = res.datas.rsData;
                this.flightList.forEach(value=>{
                    ruleText.forEach(value1 => {
                        if (value1.policyId === value.policyId) {
                            value1.depCityName = value.depCityName;
                            value1.arrCityName = value.arrCityName;
                        }
                    });
                })
                this.ruleText = ruleText;
            }).catch(()=>{
            })
        },
        // 获取订单所有价格
        async getAllPrice() {
            let productIds = [];
            if (this.bookingEquityProductInfos) {
                productIds.push(this.bookingEquityProductInfos.productId);
            }
            let params = {
                //evectionNo: this.evectionNo,
                orderNo: this.orderNo,
                headCounts: this.evectionParams.frequentIds.length,
                packageCodes: this.insuranceIdArr,
                productIds: productIds, //加享产品
            }
            let res = await consumer_journey_planeTicketcalculatePrice(params)
            let priceResult = res.datas
            let flightRebatePrice = 0
            let flightAirportTaxAndFuelTax = 0
            let flightSubtotal = 0
            if (priceResult.flightCalculates) {
                priceResult.flightCalculates.forEach(value => {
                    flightRebatePrice += parseFloat(value.rebatePrice)
                    flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax + parseFloat(value.airportTax) + parseFloat(value.fuelTax)
                    flightSubtotal += parseFloat(value.subtotal)
                })
            }
            priceResult.flightRebatePrice = flightRebatePrice
            priceResult.flightAirportTaxAndFuelTax = flightAirportTaxAndFuelTax
            priceResult.flightSubtotal = flightSubtotal
            this.priceResult = priceResult
        },
        // 展示退改规则弹窗
        showThisRuleList() {
            this.ruleShow = true
            this.ruleLoading = true
            this.ruleText = {};
            let params = {
                rqData: {
                    cabinCode: this.evectionInfo.cabin,
                    flightKey: this.evectionInfo.flightKey,
                    flightNo: this.evectionInfo.flightNo,
                    policyId: this.evectionInfo.policyId
                }
            }
            consumer_flight_search_stipulatesRule(params).then(res => {
                this.ruleText = res.datas.rsData
            }).finally(() => {
                this.ruleLoading = false
            })
        },
        // 关闭退改规则弹窗
        closeRuleShow() {
            this.ruleShow = false
        },
        // 保存信息
        async saveInfo() {
            let params = Object.assign({}, this.evectionParams)
            let [err, res] = await awaitWrap(consumer_journey_saveOrderMsg(params))
            if (err) {
                return false
            }
            return res
        },
        timeLongTips(time) {
            return new Promise((resolve, reject) => {
                if (time && time >= 20) {
                    Dialog.confirm({
                        title: '温馨提示',
                        message: '该订单支付后预计30分钟内出票，是否继续预订？',
                        confirmButtonText: '继续预订',
                        cancelButtonText: '取消',
                    })
                        .then(() => {
                            // on confirm
                            resolve(true);
                        })
                        .catch(() => {
                            // on cancel
                            resolve(false);
                        });
                } else {
                    resolve(true)
                }
            })
        },
        // 最终创建订单
        submitLowPrice() {
            //const lprList = ['YPL1','FPL1','BPL1','OPL4','OPL3','OPL5','OPL25','SPL1','OPL21','OPL15','OPL13','OPL14','OPL34','OPL1','SGL1','OPL26','OPL30'];
            return new Promise((resolve,reject)=> {
                let index = 0;
                let hasLowPrice = [];
                this.evectionInfo.forEach(value => {
                    if (value.productTag === 5) {
                        hasLowPrice.push(index);
                        index++;
                    }
                })
                if (hasLowPrice.length === 0) {
                    resolve(true);
                } else {
                    // 弹窗
                    let message = '';
                    this.ruleText.forEach((value, index) => {
                        if (hasLowPrice.indexOf(index) > -1) {
                            message += `本产品为低价精选产品，最晚于${value.timeLimitQueue}出票，报销凭证提供${value.tagProperty}。\n`;
                            if (value.certificateLimit) {
                                message += `证件限制:${value.certificateLimit}\n`;
                            }
                            if (value.ageLimit) {
                                message += `年龄限制:${value.ageLimit}\n`;
                            }
                            if (value.numberLimit) {
                                message += `人数限制:${value.numberLimit}\n`;
                            }
                        }
                    })

                    Dialog.confirm({
                        title: '温馨提示',
                        message: message,
                        confirmButtonText: '继续预定',
                        cancelButtonText: '重新选择价格',
                    }).then(() => {
                        // on confirm
                        resolve(true);
                    }).catch(() => {
                        // on cancel
                        reject(false);
                    });
                }
            })
        },
        async createOrder() {

            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "",
            });
            let travelerInfo = this.$refs['travelerListForReserve'].getTravelerInfo()
            this.evectionParams.linkman = travelerInfo.linkman
            this.evectionParams.linkmanPhone = travelerInfo.linkmanPhone
            this.evectionParams.bookingSms = travelerInfo.smsType
            this.evectionParams.linkmanSms = travelerInfo.linkmanSms
            this.evectionParams.frequentIds = travelerInfo.userIdList
            let hasChild = false;
            let hasDifferentAge = false;
            let hasDifferentAgeNameList = [];

            let hasUserCardErr = false;
            let userErrCardTips = '';
            let userCardErrName = [];
            let hasUserAgeErr = false;
            let userErrAgeTips = '';
            let userAgeErrName = [];
            let hasUserLengthErr = false;
            let userLengthErrTips = '';
            travelerInfo.userList.forEach(item=>{
                if (item.age < 13) {
                    hasChild = true;
                }
                if (item.age < 22 || item.age > 65) {
                    hasDifferentAge = true;
                    hasDifferentAgeNameList.push(item.frequentPassengerName || item.userName);
                }

                if (item.cardErrShow) {
                    hasUserCardErr = true;
                    userErrCardTips = item.cardErrTips;
                    userCardErrName.push(item.name);
                }
                if (item.ageErrShow) {
                    hasUserAgeErr = true;
                    userErrAgeTips = item.ageErrTips;
                    userAgeErrName.push(item.name);
                }
                this.tmcSnailValuations.forEach(value => {
                    if (value.passengerNum !== '1,9') {
                        let passengerNum = value.passengerNum.split(',');
                        const userLength = this.evectionParams.userList.length;
                        if (passengerNum[0] > userLength || passengerNum[1] < userLength) {
                            hasUserLengthErr = true;
                            userLengthErrTips = `该产品仅限${passengerNum[0]}-${passengerNum[1]}人预订，当前出行人数不符合条件`
                        }
                    }
                })

            })
            if (hasChild) {
                Toast.clear()
                Toast.fail('暂不支持在线预订儿童票');
                return;
            }
            if (this.hasAgreementPricePro && hasDifferentAge) {
                Toast.clear()
                this.errorText = `协议价仅限乘客年龄22至65周岁预订，乘客${hasDifferentAgeNameList.join('，')}年龄不符合要求，请选择其他非协议价预订`;
                this.dialogError3Visible = true;
                return;
            }
            // this.evectionParams.frequentIds = [1383] // 虚拟id
            if (this.evectionParams.linkman === '' || this.evectionParams.linkmanPhone === '') {
                Toast.clear()
                Toast.fail('请填写联系人')
                return
            }
            if (this.evectionParams.frequentIds.length === 0) {
                Toast.clear()
                Toast.fail('请添加乘机人')
                return
            }

            let errText = ``;
            if (hasUserLengthErr) {
                errText += `${userLengthErrTips}\/n`;
            }
            if (hasUserAgeErr) {
                errText += `${userErrAgeTips},当前出行人${userAgeErrName.join(',')}不符合要求\/n`;
            }
            if (hasUserCardErr) {
                errText += `${userErrCardTips},当前出行人${userCardErrName.join(',')}不符合要求\/n`;
            }
            if (errText) {
                Dialog({message: errText});
                return
            }
            Toast.clear()
            //无自营价格只能出飞常准携程运价，且该运价出票时限大于20分钟，点击创建订单时，弹出提示
            let timeTipsRes = await this.timeLongTips(this.$route.query.ticketLimitTime);
            if (!timeTipsRes) {
                return;
            }
            const [sErr, SRes] = await awaitWrap(this.submitLowPrice());
            if (sErr === false) {
                return
            }
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
                message: "",
            });
            clearInterval(this.searchTemp)
            let evectionParams = JSON.parse(JSON.stringify(this.evectionParams))
            evectionParams.enable_error_alert = false // 临时隐藏
            evectionParams.orderClient = 2
            let equityProductId = '';
            let equityProductPrice = '';
            if (this.bookingEquityProductInfos) {
                equityProductId = this.bookingEquityProductInfos.productId;
                equityProductPrice = this.bookingEquityProductInfos.farePat;
            }
            evectionParams.equityProductId = equityProductId
            evectionParams.equityProductPrice = equityProductPrice
            let [err, result] = await awaitWrap(consumer_journey_personalCreateOrder(evectionParams))
            if (err) {
                this.setSearchReact()
                // 200017 下单失败!机票订票失败:该航班变价，请重新搜索！
                Toast.clear()
                let orderResultList = err.data.datas ? err.data.datas.orderResultList : []
                let errorCode = err.data.code
                this.errorText = err.data.msg;
                if (errorCode === '200017' || errorCode === '1000000' || errorCode === '200029' || errorCode === '300052') {
                    this.dialogError3Visible = true
                    /*this.dialogErrorVisible = true
                    this.returnSecond = 5;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.returnToSearch()
                        }
                    }, 1000)*/
                } else {
                    this.dialogError2Visible = true
                }
                /*if (orderResultList) {
                    orderResultList.forEach(value => {
                        if (!value.isSuccess) {
                            let [...flightList] = this.flightList
                            flightList.forEach(value1 => {
                                if (value1.journeyNo === value.orderNo) {
                                    value1.showError = true
                                    value1.errorReason = value.desc
                                }
                            })
                            this.flightList = flightList
                        }
                    })
                }*/
                return
            }
            let orderResult = result.datas
            let canSubmit = true
            orderResult.orderResultList.forEach(value => {
                if (!value.isSuccess) {
                    canSubmit = false
                    /*let [...flightList] = this.flightList
                    flightList.forEach(value1 => {
                        if (value1.journeyNo === value.orderNo) {
                            value1.showError = true
                            value1.errorReason = value.desc
                        }
                    })
                    this.flightList = flightList*/
                }
            })
            if (canSubmit) {
                this.$refs.aInsureChoice.submit().then(res => {
                    Toast.clear()
                    this.$router.push({
                        name: 'flight-submit-order',
                        query: {
                            orderNo: this.orderNo
                        }
                    })
                }).catch(() => {
                    Toast.clear()
                });
            } else {
                this.setSearchReact()
                Toast.clear()
                Toast.fail('订票失败，请重新选择行程')
            }
        },
        returnCabin() {
            this.dialogError3Visible = false;
            this.$router.go(-1);
        },
        returnToSearch() {
            if (!this.dialogErrorVisible) {
                return
            }
            this.dialogErrorVisible = false;
            clearInterval(this.returnSetInterval);
            this.$router.go(-2)
            /*this.$router.replace({
                name: 'flight-search',
                query: {
                    fromStationCode: this.evectionInfo.depAirportCode,
                    fromStationName: this.evectionInfo.depCityName,
                    toStationCode: this.evectionInfo.arrAirportCode,
                    toStationName: this.evectionInfo.arrCityName,
                    startDate: this.evectionInfo.depDate,
                    endDate: this.evectionInfo.arrDate,
                    flightType: 1,
                    evectionType: 1
                }
            })*/
        },
        getTravelerInfo(param) {
            console.log('param', param)
            this.evectionParams.frequentIds = param.userIdList;
            this.getAllPrice()
        },
        setSearchReact() {
            this.searchTemp = setInterval(() => {
                this.intervalNum++
                if (this.intervalNum >= 30 * 60) {
                    console.log(`因私预订页倒计时${this.intervalNum}`);
                    clearInterval(this.searchTemp)
                    Toast('页面停留时间超过30分钟，请您重新搜索航班')
                    this.intervalNum = 0
                    setTimeout(() => {
                        this.$router.go(-1)
                    }, 1000)
                }
            }, 1000)
        },
        backStopDetail() {
            if (this.evectionInfo.stopNum > 0) {
                this.stopDataShow = true
                this.stopData = []
                this.stopDataLoading = true
                let params = {
                    "rqData": {
                        "date": this.evectionInfo.depDate,
                        "flightNo": this.evectionInfo.hasShare ? this.evectionInfo.share_flight_no : this.evectionInfo.flightNo,
                        'depCityCode': this.evectionInfo.depCityCode,
                        'arrCityCode': this.evectionInfo.arrCityCode,
                        'arrTime': this.evectionInfo.arrTime,
                        'deptTime': this.evectionInfo.depTime,
                        'depAirCode': this.evectionInfo.depAirportCode,
                        'arrAirCode': this.evectionInfo.arrAirportCode,
                    }
                }
                consumer_flight_order_flightsStop(params).then(res => {
                    this.stopData = res.datas.stopInfos
                }).finally(() => {
                    this.stopDataLoading = false
                })
            }
        },
        showShareDetail() {
            Dialog.alert({
                title: `实际承运-${this.evectionInfo.shareAirlineName}${this.evectionInfo.share_flight_no}`,
                message: '请您前往实际承运的航司值机柜台办理登记手续',
                confirmButtonColor: '#ff7002',
            }).then(() => {
                // on close
            });
        },
        // 关闭经停信息弹窗
        closeStopDataShow() {
            this.stopDataShow = false
        },
        initInsure() {
            this.$refs.aInsureChoice.init({
                mock_data:{
                    //是否使用随机数据--true/false
                    enable: false,
                },
                //业务类型--1：机票/2：火车票
                businessType: 1,
                //订单号
                orderNo: this.orderNo,
                evectionType: this.evectionType,
                flightType: this.flightType
            });
        },
        changeInsure(data) {
            this.insuranceIdArr = data.id_list
            this.getAllPrice()
        },
        showFlightDetail() {
            this.flightDetailShow = true;
        },
        showChangeReturnDetail() {
            this.ruleLoading = true;
            this.ruleText = [];
            this.ruleShow = true;
            let params = {
                rqData: []
            };
            this.evectionInfo.forEach(value1=>{
                const rqData = {
                    cabinCode: value1.cabin,
                    flightKey: value1.flightKey,
                    flightNo: value1.flightNo,
                    policyId: value1.policyId
                };
                params.rqData.push(rqData);
                this.productTag = value1.productTag;
            })
            consumer_flight_search_returnStipulatesRule(params).then(res=>{
                this.ruleLoading = false;
                let ruleText = res.datas.rsData;
                this.flightList.forEach(value=>{
                    ruleText.forEach(value1 => {
                        if (value1.policyId === value.policyId) {
                            value1.depCityName = value.depCityName;
                            value1.arrCityName = value.arrCityName;
                            value1.productTag = value.productTag;
                        }
                    });
                })
                this.ruleText = ruleText;
            }).catch(()=>{
                this.ruleLoading = false;
            })
        },
        showOrHideTips() {
            this.tipsHide = !this.tipsHide;
        },
        changeProduct(item) {
            this.bookingEquityProductInfos = item;
            // 去计算价格
            this.getAllPrice();
        },
        matchFlightPrice(item) {
            let result = 0;
            result = parseFloat(item.rebatePrice || 0) + parseFloat(parseFloat(item.airportTax) + parseFloat(item.fuelTax) || 0);
            // 加上加享产品
            if (this.priceResult.bookingEquityProductInfos) {
                this.priceResult.bookingEquityProductInfos.forEach(value=>{
                    result += parseFloat(value.markupPrice || 0);
                })
            }
            return result;
        },
        showProductDetail() {
            this.$refs.aFlightUpLevelPackage._showDetail(this.bookingEquityProductInfos);
        },
        _setUserError(item) {
            console.log(item);
            if (this.params.businessType !== 1) {
                item.cardErrShow = false;
                item.cardErrTips = '';
                item.ageErrShow = false;
                item.ageErrTips = '';
                item.showTips = ''
                return;
            }
            if (!this.params.flightTmcSnailValuations || this.params.flightTmcSnailValuations.length === 0) {
                item.cardErrShow = false;
                item.cardErrTips = '';
                item.ageErrShow = false;
                item.ageErrTips = '';
                item.showTips = ''
                return
            }
            let cardErrShow = false;
            let cardErrTips = '';
            let ageErrShow = false;
            let ageErrTips = '';
            let showTips = '该产品';
            let showNum = 0;
            this.params.flightTmcSnailValuations.forEach(value => {
                if (value.cardType === 'NI'){
                    // 仅支持身份证
                    if (item.certificateType !== 1) {
                        cardErrShow = true;
                        cardErrTips = '仅限身份证预订';
                        showTips += '仅限身份证预订';
                        showNum++;
                    }
                }
                if (value.cardType === 'PP') {
                    // 仅支持身份证
                    if (item.certificateType !== 2) {
                        cardErrShow = true;
                        cardErrTips = '仅限护照预订';
                        showTips += '仅限护照预订';
                        showNum++;
                    }
                }
                if (value.ageScope !== '0,100') {
                    let ageScope = value.ageScope.split(',');
                    if (ageScope[0] > item.age || ageScope[1] < item.age) {
                        ageErrShow = true;
                        if (showNum > 0) {
                            showTips += ', ';
                        }
                        ageErrTips = `仅限年龄在${ageScope[0]}岁至${ageScope[1]}岁之间`;
                        showTips += `仅限年龄在${ageScope[0]}岁至${ageScope[1]}岁之间`;
                    }
                }


            })
            item.cardErrShow = cardErrShow;
            item.cardErrTips = cardErrTips;
            item.ageErrShow = ageErrShow;
            item.ageErrTips = ageErrTips;
            item.showTips = showTips
        },
    }
}
