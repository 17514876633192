// consumer_car_order_carOrderDetails 我的订单-用车订单详情
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://yapi.yinzhitravel.com/project/52/interface/api/15132
const consumer_car_order_carOrderDetails = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/car/order/carOrderDetails',
        data: pParameter
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_car_order_carOrderDetails;