import moment from "moment";
// 列表为空展示组件
import ListEmpty from "@/component/list-empty/3.0.1/index.vue";
import Search from '@/page/personal-center/order/components/flightListSearch/1.0.0/index.vue'
// 订单列表
import consumer_car_order_carOrderList from "@/lib/data-service/haolv-default/consumer_car_order_carOrderList";
//根据订单号获取跳转订单详情链接接口
import consumer_car_link_getGaodeLinkByOrderNo
    from "@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkByOrderNo";
import open from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window";

export default {
    components: {
        ListEmpty,
        Search
    },
    data() {
        return {
            statusList: [
                {label: "我的订单", value: "0"},
                {label: "已完成", value: "1"},
                {label: "待支付", value: "2"},
                {label: "未完成", value: "3"},
                {label: "已取消", value: "4"},
            ],
            form: {
                currentPage: 1,
                pageSize: 0,
                orderNo: "",
            },
            orderType: "0",
            list: null,
            loading: false,
            finished: true,
            refreshing: false,
            emptyType: "",
        };
    },
    watch: {
        loading(val) {
            const Toast = this.$Toast;

            if (val) {
                Toast.loading({
                    duration: 0, // 持续展示 toast
                    forbidClick: true,
                    message: "加载中...",
                });
            } else {
                Toast.clear();
            }
        },
    },
    methods: {
        carLinkGetGaodeLinkByOrderNo(orderNo) {
            consumer_car_link_getGaodeLinkByOrderNo({orderNo}).then(res => {
                let url = res.datas.url;
                open({
                    url,
                    title: '用车订单详情',
                    btn_back_click_event_handler: () => {
                        this.search();
                    }
                })
            })
        },
        onSecretary(){},
        seeTripOrder(data) {
            this.$router.push({
                name: 'h5-car-order-details',
                query: {
                    orderNo: data,
                }
            })
        },
        req_list(data) {
            let post = {
                orderStatus: null,
                orderNo: null,
                currentPage: this.form.currentPage,
                pageSize: this.form.pageSize,
            };
            if (data) {
                if (data.orderStatus) {
                    post.orderStatus = data.orderStatus;
                }
                if (data.orderNo) {
                    post.orderNo = data.orderNo;
                }
            }

            consumer_car_order_carOrderList(post).then(res => {
                this.refreshing = false;
                this.loading = false;
                this.list = res.datas.carOrderList.list;
                if (res.datas.carOrderList.totalCount > this.form.pageSize) {
                    this.finished = false;
                } else {
                    this.form.pageSize = res.datas.carOrderList.totalCount;
                    this.finished = true;
                }
            }).catch((err) => {
                this.list = [];
                this.emptyType = "network";
                this.finished = true;
                this.refreshing = false;
                this.loading = false;
            });
        },

        onRefresh() {
            this.form.pageSize = 0;
            this.refreshing = false;
            this.onLoad();
        },
        onLoad() {
            if (this.refreshing) return;
            this.loading = true;
            // this.list = [];
            this.form.pageSize += 10;
            this.req_list({
                orderStatus: this.orderType,
                orderNo: this.form.orderNo
            });
        },

        search() {
            this.form.pageSize = 10;
            this.form.currentPage = 1;
            this.loading = true;
            this.req_list(
                {
                    orderStatus: this.orderType,
                    orderNo: this.form.orderNo
                }
            );
        },
        clickTab() {
            this.list = null;
            // 组装当前页面url
            let href = location.href.split('?')[0];
            let newUrl = href + '?type=' + this.orderType;
            history.replaceState(null, null, newUrl);
            this.search();
        },
        change_state(val) {
            this.form.orderNo = '';
            this.orderType = val + '';
            this.search();
        },
    },
    mounted() {

    },
    activated() {
        // if (this.$route.query.type) {
        //     this.orderType = this.$route.query.type;
        // }
        this.search();
    },
    filters: {}
};
