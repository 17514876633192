// consumer_appRegister app 注册
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-beta.haolvtrip.com/doc.html#/haolv-consumer/center-controller/appRegisterUsingPOST
const consumer_appRegister = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/appRegister',
        data: pParameter,
        check_token: false,
        enable_error_alert: false
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_appRegister;