import consumer_department_getDepartmentList from '@/lib/data-service/haolv-default/consumer_department_getDepartmentList'
import consumer_web_costCenter_allList from "@/lib/data-service/haolv-default/consumer_web_costCenter_allList";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import {awaitWrap, handlePromise} from '@/page/travel-book/flight/common/unit'
//import Q from 'q';
export default {
    name: 'costAttributionList',
    data () {
        return {
            // show: false,
            tabActive: 1,
            radio: '',

            cascadeList: [], // 多维数组部门列表
            allCascadeList: [], // 一位数组部门列表
            filterCascadeList: [], // 筛选后部门列表
            allCostList: [], // 成本列表
            filterCostList: [], // 筛选成本列表

            depSearchKey: '', // 部门搜索关键词
            costSearchKey: '', // 成本中心关键词
            //deferInitCompleted: Q.defer(),

            showDept: true, // 是否显示组织架构 boolean 默认true
        }
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        feeType: { // 费用归属类型：1.组织架构，2.成本中心
            type: Number,
            default: 1
        },
        feeName: {
            type: String,
            default: ''
        },
        feeId: {
            // type: Number,
            default: ''
        },
    },
    components: {
        NavBar,
        radioItem: () => import('./radioItem/index.vue')
    },
    created () {},
    mounted () {
        this._getCostCenterList();
    },
    activated () {},
    deactivated() {},
    destroyed () {},
    watch: {
        feeType(newVal) {
            this.tabActive = newVal
        },
        show(newVal) {
            if (newVal) {
                this.depSearchKey = ''; // 部门搜索关键词
                this.costSearchKey = ''; // 成本中心关键词
            }
        },
    },
    computed: {
        popupShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        },
        currentFeeType: {
            get () {
                return this.feeType
            },
            set (val) {
                this.$emit('update:feeType', val)
            }
        },
        currentFeeName: {
            get () {
                return this.feeName
            },
            set (val) {
                this.$emit('update:feeName', val)
            }
        },
        currentFeeId: {
            get () {
                return this.feeId
            },
            set (val) {
                this.$emit('update:feeId', val)
            }
        }
    },
    filters: {},
    methods: {
        _reactData() {
            this.cascadeList = []; // 多维数组部门列表
            this.allCascadeList = []; // 一位数组部门列表
            this.filterCascadeList = []; // 筛选后部门列表
            this.allCostList = []; // 成本列表
            this.filterCostList = []; // 筛选成本列表

            this.depSearchKey = ''; // 部门搜索关键词
            this.costSearchKey = ''; // 成本中心关键词
            //this.deferInitCompleted = Q.defer();
        },
        _getCostCenterList() {
            this._reactData();
            let apiList = [];
            apiList.push(consumer_web_costCenter_allList());
            apiList.push(consumer_department_getDepartmentList());
            Promise.all(handlePromise(apiList)).then(res => {
                if (res[0] && res[0].datas) {
                    let allCostList = res[0].datas;
                    allCostList.forEach(value => {
                        value.check = false;
                        value.label = value.centerName;
                        value.centerCode = value.centerCode;
                        value.value = value.id;
                        value.type = 2;
                    });
                    this.allCostList = JSON.parse(JSON.stringify(allCostList));
                    //this.allCostList = this.allCostList.concat(allCostList);
                }
                if (res[1] && res[1].datas) {
                    let cascadeList = res[1].datas.departmentListResults ? res[1].datas.departmentListResults : [];
                    let item = {
                        childrenList: null,
                        deptId: -1,
                        deptLevel: 1,
                        deptName: "各自所在部门",
                        deptStatus: 1,
                        parentId: 0,
                        strength: 1,
                        userId: null,
                        userName: "",
                    };
                    cascadeList.unshift(item);
                    this.cascadeList = this._initTreeData(cascadeList)
                }
                //this.deferInitCompleted.resolve()
            })
        },

        _initTreeData(list) {
            // 这里一定要转化，要不然他们的值监听不到变化
            let tempData = JSON.parse(JSON.stringify(list))
            let reduceDataFunc = (data, level) => {
                data.map((m, i) => {
                    m.isExpand = false; // 是否展开
                    m.check = false;
                    m.indeterminate = false;
                    m.level = level;
                    m.bLeft = level * 15;
                    m.label = m.deptName;
                    m.value = m.deptId;
                    m.type = 1;
                    m.children = m.childrenList;
                    delete m.childrenList;
                    this.allCascadeList.push(m);
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children, level + 1)
                    }
                })
            }
            reduceDataFunc(tempData, 1)
            return tempData
        },
        _handlerExpand(val) {
            val.isExpand = !val.isExpand
        },
        _toSelectRadio(val) {
            this.radio = val.value;
            this.currentFeeName = val.label;
            this.currentFeeId = val.value;
            this.currentFeeType = this.tabActive;
            this.popupShow = false
        },
        _getActive(val) {
            this.radio = val.value;
            this.currentFeeName = val.label;
            this.currentFeeId = val.value;
            this.currentFeeType = this.tabActive;
            this.popupShow = false
        },
        _searchDepKey(val) {
            let filterCascadeList = []
            if (val === '') {
                this.filterCascadeList = filterCascadeList;
                return
            }
            this.allCascadeList.forEach(value => {
                if (value.label.indexOf(val) > -1) {
                    filterCascadeList.push(value)
                }
            });
            this.filterCascadeList = filterCascadeList
        },
        _searchCostKey(val) {
            let filterCostList = []
            if (val === '') {
                this.filterCostList = filterCostList;
                return
            }
            this.allCostList.forEach(value => {
                if (value.label.indexOf(val) > -1) {
                    filterCostList.push(value)
                }
            });
            this.filterCostList = filterCostList
        },

        closePop() {
            this.popupShow = false
        },
        isShow() {
            return this.popupShow;
        },
        /*
        * params {
        *   showDept: // 是否显示组织架构 boolean 默认true
        * }*/
        setParams(params) {
            if (params.showDept !== undefined && params.showDept !== null) {
                this.showDept = params.showDept;
            }
            if (!this.showDept) {
                this.currentFeeType = 2;
                this.tabActive = 2;
            }
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
