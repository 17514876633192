import NavBar from "@/component/nav-bar/import_latest_version_module";
import consumer_hotel_search_getRoomPackageInfo from '@/lib/data-service/haolv-default/consumer_hotel_search_getRoomPackageInfo'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
export default {
    data() {
        return {
            active: 0,
            popupShow: false,

            evectionNo: '',
            checkInDate: '',
            checkOutDate: '',
            hotelId: '',
            cityId: '',
            applyId: '',

            hotelStaticInfo: {},
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
        /*this.active = 0;
        this.evectionNo = this.$route.query.evectionNo;
        this.checkInDate = this.$route.query.checkInDate;
        this.checkOutDate = this.$route.query.checkOutDate;
        this.hotelId = this.$route.query.hotelId;
        this.cityId = this.$route.query.cityId;
        this.applyId = this.$route.query.applyId;
        this.getHotelDetail();*/
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _openedPopup() {
            const __this = this;
            const scrollView = this.$refs['contWrapper'];
            scrollView.scrollTop = 0;

            setTimeout(()=>{
                fixStatusBar({
                    type: `000`,
                    handler({default_handler, api}) {
                        const bodyWidth = document.body.clientWidth;
                        let topElement = __this.$refs['hotelAboutNavbar'].$el;
                        let orrPaddingTop = topElement.style.paddingTop || 0;
                        let safeAreaPaddingTop = api.safeArea.top;
                        if (bodyWidth >= 414) {
                            orrPaddingTop = orrPaddingTop * 2
                            safeAreaPaddingTop = safeAreaPaddingTop * 2;
                        }
                        let currentPaddingTop = parseInt((orrPaddingTop || 0)/(bodyWidth/100));
                        const addPaddingTop = parseInt((safeAreaPaddingTop)/(bodyWidth/100));
                        topElement.style.paddingTop = (addPaddingTop + currentPaddingTop) + 'vw';
                        /*let bottomElement = __this.$refs['bottomBtnWrapper'];
                        let oriPaddingBottom = bottomElement.style.paddingBottom;
                        if (oriPaddingBottom >= 414) {
                            oriPaddingBottom = oriPaddingBottom * 2
                        }
                        let currentPaddingBottom = (oriPaddingBottom || 0)/(bodyWidth/100);
                        const addPaddingBottom = (api.safeArea.bottom)/(bodyWidth/100);
                        bottomElement.style.paddingBottom = (addPaddingBottom + currentPaddingBottom) + 'vw';*/
                    }
                });

                this.$refs.hTab.resize();
            }, 500)
        },
        init(params) {
            this.active = 0;
            this.hotelStaticInfo = params.hotelStaticInfo;
            this.show();
        },
        tabBeforeChange(name){
            if (name === 2) {
                return false
            } else {
                return true
            }
        },
        getHotelDetail() {
            let params = {
                evectionNo: this.evectionNo,
                checkInDate: this.checkInDate,
                checkOutDate: this.checkOutDate,
                hotelId: this.hotelId,
                cityId: this.cityId,
                applyId: this.applyId
            };
            this.$showLoading.show();
            consumer_hotel_search_getRoomPackageInfo(params).then(res=>{
                const data = res.datas;
                this.hotelStaticInfo = data.hotelStaticInfo;
            }).finally(()=>{
                this.$showLoading.hide();
            })
        },
        back() {
            this.popupShow = false;
        },
        show() {
            this.popupShow = true;
        },
        isShow() {
            return this.popupShow
        },
        returnIcon(val) {
            let typeName = val.typeName;
            let iconName = '';
            switch (typeName) {
                case '运动':
                    iconName = 'haolv-enterprise-app-iconsport';
                    break
                case '公共区':
                    iconName = 'haolv-enterprise-app-icontotal';
                    break
                case '停车场':
                    iconName = 'haolv-enterprise-app-iconpark';
                    break
                case '娱乐活动设施':
                    iconName = 'haolv-enterprise-app-iconentertainment';
                    break
                case '餐饮服务':
                    iconName = 'haolv-enterprise-app-iconfoot';
                    break
                case '交通服务':
                    iconName = 'haolv-enterprise-app-iconpark';
                    break
                case '前台服务':
                    iconName = 'haolv-enterprise-app-iconfront-servce';
                    break
                case '清洁服务':
                    iconName = 'haolv-enterprise-app-iconclean';
                    break
                case '无障碍设施服务':
                    iconName = 'haolv-enterprise-app-iconsport';
                    break
                case '商务服务':
                    iconName = 'haolv-enterprise-app-iconbox-edit';
                    break
                default:
                    iconName = 'haolv-enterprise-app-iconsport'
            }
            return iconName;
        },
        showOnePhone(val) {
            if (val) {
                let phoneArr = val.split(',');
                return phoneArr[0]
            } else {
                return ''
            }

        }
    }
}
