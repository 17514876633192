import extend from "extend";
import Q from 'q';
import _ from 'underscore';
import is_null_or_undefined from 'nllib/src/common/is_null_or_undefined';

let routes_level_one;

let order_index = 0;

const __get_all_route_list = function (pRoutes) {
    const list_001 = pRoutes;
    const list_002 = _.chain(list_001)
        .filter(function (m) {
            return m.name || m.path;
        })
        .map(function (m) {
            if (!m.meta) {
                m.meta = {};
            }
            const meta = m.meta;
            if (!meta.data_for_all_routes_processor) {
                meta.data_for_all_routes_processor = {};
            }
            const data_for_all_routes_processor = meta.data_for_all_routes_processor;
            data_for_all_routes_processor.default_order_index = order_index++;
            if (is_null_or_undefined(data_for_all_routes_processor.order_index)) {
                data_for_all_routes_processor.order_index = data_for_all_routes_processor.default_order_index;
            }
            return m;
        })
        .value()
    ;

    const list_003 = _.chain(list_001)
        .filter(function (m) {
            return _.isArray(m.children);
        })
        .map(function (m) {
            const children = _.chain(m.children)
                .map(function (m) {
                    const o = extend(true, {}, m);
                    return o;
                })
                .value()
            ;
            return children;
        })
        .flatten()
        .value()
    ;

    let list_004 = [];
    if (list_003.length > 0) {
        list_004 = __get_all_route_list(list_003);
    }

    let list = [];
    list = list.concat(list_002);
    list = list.concat(list_004);
    return list;

};

let __all_route_list;
const get_all_route_list = function (p_routes_level_one) {
    if (!__all_route_list) {
        __all_route_list = __get_all_route_list(p_routes_level_one);
        // console.log(`__all_route_list=`, __all_route_list);

        const route_name_join_order_index_list = _.chain(__all_route_list)
            .map(function (m) {
                const path = m.path;
                const name = m.name;
                const order_index = m.meta.data_for_all_routes_processor.order_index;
                const default_order_index = m.meta.data_for_all_routes_processor.default_order_index;
                return {
                    path,
                    name,
                    order_index,
                    default_order_index,
                };
            })
            .value()
        ;
        console.log(`route_name_join_order_index_list=`, route_name_join_order_index_list);
    }
    return __all_route_list;
};

const init = function (pParameter) {
    if (!pParameter) pParameter = {};
    const __routes_level_one = pParameter.routes_level_one;
    if (!__routes_level_one) {
        const msg = 'init parameter error, code=001';
        console.error(msg);
        console.trace();
        return Q.reject({msg: msg});
    }

    routes_level_one = __routes_level_one;
    get_all_route_list(routes_level_one);
};

export default {
    init,
    get_all_route_list,
}
