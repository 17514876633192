export default {
    name: 'flightItem',
    data () {
        return {}
    },
    props: {
        flight: {
            type: Object,
            default: () => {}
        }
    },
    components: {},
    created () {},
    mounted () {},
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {},
    computed: {},
    filters: {},
    methods: {
        ruleBack() {
            this.$emit('getRuleBack', this.flight)
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/