//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import EvectionStandardExceed from '@/component/evectionStandardExceed/import_latest_version_module'
  import cache_get from "nllib/src/cache/get";
  import moment from "moment";

  export default {
  props: {
    isShowStandard: {
      type: Boolean,
      default() {
        return true;
      }
    },
    evectionDetailType: {
      type: String,
      default() {
        return '101';
      }
    },
    row: {
      type: Object,
      default() {
        return {};
      },
    },
    keyId: {
      type: String,
      default() {
        return '';
      },
    },
    cardIdx: {
      type: Number,
      default() {
        return 0;
      },
    },
    nightDay: {
      type: Number,
      default() {
        return 1;
      },
    }
  },
  data() {
    return {
      show: false,
      isShowMoreBtn: false,
      isHideMoreBtn: false
    };
  },
  components: {
    EvectionStandardExceed
  },
    activated() {
      const outTime = cache_get({key: 'hotelDetailOutTime'});
      let minute = moment().diff(outTime, 'minute');
      let hotelDetailReactType = cache_get({key: 'hotelDetailReactType'});
      if (hotelDetailReactType !== '2' || minute > 5) {
        this.hideCard();
      }
    },
  methods: {
    onToggle(row, cardIdx) {
      const overflowEle = this.$refs['body-wrap'];

      if (this.show) {
        overflowEle.style.height = '0px';
        this.show = false;
        this.isShowMoreBtn = false;
        this.isHideMoreBtn = false;
      } else {
        const overflowChildEle = overflowEle.children[0];
        let height = overflowChildEle.offsetHeight;

        let item3H = height;
        this.isShowMoreBtn = false;
        if (row.ratePlanViewList.length > 3) {
          this.isShowMoreBtn = true;
          item3H = overflowEle.children[0].children[0].offsetHeight + overflowEle.children[0].children[1].offsetHeight + overflowEle.children[0].children[2].offsetHeight;
          height = item3H;
        }

        overflowEle.style.height = `${height}px`;
        this.show = true;
        setTimeout(() => {
          this.$emit('jump-to-top', row, cardIdx);
        }, 300)
      }
    },
    onMoreToggle(row, cardIdx) {
      const overflowEle = this.$refs['body-wrap'];
      const overflowChildEle = overflowEle.children[0];
      let height = overflowChildEle.offsetHeight;
      overflowEle.style.height = `${height}px`;
      this.isShowMoreBtn = false;
      this.isHideMoreBtn = true;
    },
    onHideToggle(row, cardIdx) {
      const overflowEle = this.$refs['body-wrap'];
      const overflowChildEle = overflowEle.children[0];
      let height = overflowChildEle.offsetHeight;
      let item3H = height;
      if (row.ratePlanViewList.length > 3) {
        this.isShowMoreBtn = true;
        item3H = overflowEle.children[0].children[0].offsetHeight + overflowEle.children[0].children[1].offsetHeight + overflowEle.children[0].children[2].offsetHeight;
        height = item3H;
      }
      overflowEle.style.height = `${height}px`;
      this.isShowMoreBtn = true;
      this.isHideMoreBtn = false;
    },
    updateHeight() {
      if (this.show) {
        const overflowEle = this.$refs['body-wrap'];
        const overflowChildEle = overflowEle.children[0];
        const height = overflowChildEle.offsetHeight;
        overflowEle.style.height = `${height}px`;
      }
    },
    getEvectionStandardExceedShow(){
      let evectionStandardExceedItem;
      let evectionStandardExceed = this.$refs.evectionStandardExceed;
      if (evectionStandardExceed) {
        evectionStandardExceedItem = evectionStandardExceed.find(item => {
          return item.getShow() === true;
        })
      }
      return evectionStandardExceedItem;
    },
    evectionStandardExceedHide(standardExceedItem) {
      standardExceedItem.closePopup();
    },
    hideCard() {
      const overflowEle = this.$refs['body-wrap'];
      overflowEle.style.height = '0px';
      this.show = false;
      this.isShowMoreBtn = false;
      this.isHideMoreBtn = false;
    }
  },

}
