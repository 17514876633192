import consumer_trains_order_getTrainsReturnOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsReturnOrderDetail'

export default {
    data() {
        return {
            dataNum: null,
            tipList: ['退票结果将在2小时内以短信方式通知您。退款以铁路部门实退金额为准，预计15个工作日内到账。', '已购买保险订单，若退票成功，我们将为您退保。', '若退票失败，请凭售票时有效证件在发车前到火车站退票窗口办理。'],
        }
    },
    computed: {

    },
    mounted(){
        this.init();
    },
    methods: {
        init(){
            let orderNo = this.$route.query.orderNo;
            consumer_trains_order_getTrainsReturnOrderDetail({
                orderNo
            }).then((res) => {
                this.dataNum = res.datas.refundAmount;
            })
        },
        // 查看退票申请单-New
        toOrderList() {
            let refundNo = this.$route.query.orderNo;
            this.$router.push({
                name: 'h5-train-refund-form-detail',
                query: {
                    refundNo,
                }
            });
        },

        // toOrderList() {
        //     let orderNo = this.$route.query.orderNo;
        //     this.$router.push({
        //         name: 'h5-train-order-list',
        //         query: {
        //             active: 2
        //         }
        //     });
        // },
    },
}
