import Q from 'q';
import _ from 'underscore';
import get_feature_list from './get_feature_list';

export default {
    components: {},
    data() {
        return {
            title: ``,
            feature_list: [],
        };
    },
    methods: {},
    created() {
        const __this = this;
        __this.title = __this.$route.meta.data_for_base_layout.header.title;
        __this.feature_list = get_feature_list();
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
