import NavBar from "@/component/nav-bar/import_latest_version_module";
import ReservePopupBox from "@/page/travel-book/flight/component/reservePopupBox/import_latest_version_module";
import consumer_web_policyConfig_getUsefulCarScene from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getUsefulCarScene'
import consumer_web_policyConfig_getLoginUserCarPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getLoginUserCarPolicy'
import consumer_car_link_getGaodeLinkByPolicyId from '@/lib/data-service/haolv-default/consumer_car_link_getGaodeLinkByPolicyId'
import consumer_car_order_checkCreditUsableAmount from '@/lib/data-service/haolv-default/consumer_car_order_checkCreditUsableAmount'
import consumer_evection_addOrUpdate from "@/lib/data-service/haolv-default/consumer_evection_addOrUpdate";
import consumer_payment_payReminder from '@/lib/data-service/haolv-default/consumer_payment_payReminder'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import { Toast, Dialog } from 'vant';
import open from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window";
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import consumer_web_company_checkCompanyUseCar
    from "@/lib/data-service/haolv-default/consumer_web_company_checkCompanyUseCar";
// import consumer_web_staff_judgeOrderFree from '@/lib/data-service/haolv-default/consumer_web_staff_judgeOrderFree';
import consumer_web_staff_judgeOrderFreeCar from '@/lib/data-service/haolv-default/consumer_web_staff_judgeOrderFreeCar';
import consumer_apply_carReasonApproval from '@/lib/data-service/haolv-default/consumer_apply_carReasonApproval'
/* 最新出差城市选择 */
import DomesticTicketAirportSelector from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/domestic-ticket-airport-selector-1000/1.0.0";
// 申请出差单
import consumer_apply_addEvection from "@/lib/data-service/haolv-default/consumer_apply_addEvection";
// 检测出行人的审批人，是否面审
import consumer_approveFlow_matching from "@/lib/data-service/haolv-default/consumer_approveFlow_matching";
import moment from "moment";
import store from '@/lib/store';
//在app中获取用户当前位置
import currentLocation from '@/lib/common-service/current_location_helper/index.js'
import requestPermission from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/requestPermission";
// 滑屏返回
/* 最新出差城市选择 */
import TravelApplicationCitySelector
  from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/travel-application-city-selector/1.0.3/index.vue";

export default {
    data() {
        return {
            recordList: [],   // 审批流
            approveFlowId: null,   // 审批流code
            carApplyList: [],
            evectionCityListIds: [], //已选中出差城市id列表
            carReasonText: '',
            carCity: [],
            carPolicyType: null,
            carPolicyTypeText: null,
            carApplyPopup: false,
            carApplyPopupTitle: '外勤用车',
            useTravel: 1, // 是否使用出差
            carControlRule: 0, // 用车管控 0：不用申请出差单 1：需要申请出差单
            carSceneResults: [],
            dailyRemainingAmount: '',
            monthRemainingAmount: '',
            carReason: 0,
            policyList: [],

            travelCurrent: null, // 当前出差,

            evectionRulePopupShow: false,

            paymentPage: 0, // 是否弹窗 0.不弹 1.弹
            errCode: '',
            errMsg: '',
            hasCarType: true,
            noHasCarTypeText: '',

            userCanApprove: false,
        }
    },
    components: {
        NavBar,
        ReservePopupBox,
        DomesticTicketAirportSelector,
        TravelApplicationCitySelector,
    },
    created() {
    },
    mounted() {
        // this.getPayRemider();
    },
    async activated() {
        await this.$store.state.userInfoDefer.promise;
        await this.$store.state.policyDefer.promise;
        this.travelCurrent = null;
        this.errMsg = '';
        this.errCode = '';
        this.hasCarType = true;
        this.noHasCarTypeText = '';
        this.getLoginUserCarPolicy();
        this.initLocation();//初始化定位组件
        await this.refundChangePolicyGet();
        this.getUsefulCarScene(); // 非必选出差单情况下其实可以不调这个接口
        this._getUserCanApprove();
        const __this = this;
        keyback_processor.addEventListener(function () {

            if (__this.evectionRulePopupShow) {
                __this.evectionRulePopupShow = false;
                return
            }

            const reservePopupBox = __this.$refs.reservePopupBox;
            if (reservePopupBox && reservePopupBox.getShow()) {
                reservePopupBox.closeEvectionlPopup();
                return
            }
            __this.back();
        });

        // 判断是否是搜索页返回，地址栏带参数
        if (this.$route.query.evectionNo) {
            const query = this.$route.query;
            const evectionNo = query.evectionNo;
            const journeyId = parseInt(query.journeyId) || 0;
            this.$refs['reservePopupBox'].setJourneyId({journeyId})
        }
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    destroyed() {
    },
    watch: {
        evectionCityListIds(val) {
            var evectionCityList = this.$refs.aTravelApplicationCitySelector.get_selected_entity_list();
            this.carCity = [];
            evectionCityList.forEach((item) => {
                this.carCity.push(item.name)
            });
        },
    },
    computed: {},
    filters: {

    },
    methods: {
        //初始化定位
        async initLocation() {
          await currentLocation.init();
          this.locate();
        },
        //获取定位
        locate() {
            let loading = this.$Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
            });
            currentLocation.get_current_location().then((location) => {
                loading.clear();
                location = {
                    cityId: "70008",
                    cityName: "中山",
                    locationStr: "",
                    uaType: "000",
                };
                if (!location.cityName && !location.cityName) {
                    requestPermission();
                    this.$Toast({message: '获取当前位置失败，请稍后再试!'});
                } else {
                    // this.$Toast({message: '定位成功'});
                    this.evectionCityListIds = [Number(`${location.cityId}`)];
                    setTimeout(() => {
                        this.carCity = [location.cityName];
                    }, 200)
                }
            }).finally(() => {
                loading.clear();
            });
        },
        //请求下级审批人
        async getApproveFlow() {
            let res = await consumer_approveFlow_matching({applyType: 1, staffIds: []});
            let datas = res.datas;
            let isFreeApprove = Number(datas.isFreeApprove);
            let isHasApprovalProcess = isFreeApprove === 0 && datas !== null && datas.nodeList !== null && datas.nodeList.length > 0;
            if (isHasApprovalProcess) {
                let approvalNodeList = datas.nodeList;
                this.approveFlowId = approvalNodeList[0].approveFlowId;
                let approvalUserId = approvalNodeList[0]['staffs'].length > 0
                    ? approvalNodeList[0]['staffs'][0]['userId']
                    : 0;
                approvalNodeList[0]['staffs'].forEach((index,item) => {
                    if (item.userId == approvalUserId) {
                        this.recordList = [
                            {
                                'approverUserId': item.userId,
                                'approverUserName': item.staffName,
                                'linkNum': 1,
                            }
                        ];
                    }
                })
            }
        },
        clickApplyCarTwo () {
            this.carApplyPopup = true;
            this.carApplyPopupTitle = this.carPolicyTypeText + '申请';
            this.getApproveFlow();
            this.$refs.aTravelApplicationCitySelector.init();
        },
        async clickApplyCar() {
            if (!this.carCity) {
                Toast('请选择用车城市');
                return;
            } else if (!this.carReasonText) {
                Toast('请输入用车事由');
                return;
            }
            await this.$store.state.userInfoDefer.promise;
            let data = {
                accessoryUrl: '',
                approveFlowId: this.approveFlowId,
                budgetAmount: null,
                budgetInsuranceCode: "",
                budgetInsuranceName: "",
                budgetItemType: "",
                budgetProCode: "",
                budgetProName: "",
                endDate: moment().format('YYYY-MM-DD'),
                explainDesc: '',
                feeAffiliationId: -1,
                feeAffiliationName: "各自所在部门",
                feeAffiliationType: 1,
                journeyList: [
                  {
                    businessType: 4,
                    carPolicyType: this.carPolicyType,
                    journeyIndex: 0,
                  }
                ],
                reason: this.carReasonText,
                recordList: this.recordList,   // 审批流程
                staffIdList: [store.state.userInfo.id],   // 出行人ID
                startDate: moment().format('YYYY-MM-DD'),
                toCityId: this.evectionCityListIds.join(","),
                toCityName: this.carCity,
            };
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
            });
            consumer_apply_addEvection(data).then((res) => {
                Toast(`提交申请成功，待审批通过后可用车`);
                setTimeout(() => {
                    this.carApplyPopup = false;
                },1000)
            }).catch((rej) => {
                Toast(`${rej.data.msg}`);
            });
        },
        async _getUserCanApprove() {
            let userParams = {
                'userIds': [],
            };
            userParams['userIds'].push(this.$store.state.userInfo['id']);
            let [userErr, userRes] =
                await  awaitWrap(consumer_web_staff_judgeOrderFreeCar(userParams)) ;
            if (userErr) {
                return;
            }
            this.userCanApprove = userRes['datas'];
        },
        dailyLimitCount(day,month){
            if (!month) {
                return day;
            }
            let numDay = null;
            let numMonth = null;
            if (day.indexOf('元')) {
                numDay = Number(day.substring(0,day.length-1));
                numMonth = Number(month.substring(0,month.length-1));
            } else {
                numDay = Number(day);
                numMonth = Number(month);
            }


            if (numDay > numMonth) {
                return month;
            } else {
                return day;
            }
        },
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get();
            this.carControlRule = res.datas.carControlRule;
        },
        /*changeTravelTabType(val) {
            if (val === 2) {
                this.toOrder()
            }
        },*/
        back() {
            this.$router.go(-1)
            // this.$router.replace({
            //     name: 'h5-home',
            // })
        },
        returnCarPolicyTypeClass(val) {
            // 用车场景 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
            let className = '';
            switch (val) {
                case 1:
                    className = 'policy_trave_vehicles haolv-enterprise-app-iconpolicy_trave_vehicles';
                    break;
                case 2:
                    className = 'policy_overtime_car haolv-enterprise-app-iconpolicy_overtime_car';
                    break;
                case 3:
                    className = 'policy_field_vehicles haolv-enterprise-app-iconpolicy_shuttle_service';
                    break;
                case 4:
                    className = 'policy_car_hailing haolv-enterprise-app-iconpolicy_car_hailing';
                    break;
                case 5:
                    className = 'policy_fixed_point haolv-enterprise-app-iconpolicy_fixed_point';
                    break;
                case 6:
                    className = 'policy_shuttle_service haolv-enterprise-app-iconpolicy_field_vehicles';
                    break;
                default:
                    className = ''
            }
            return className
        },
        openEvectionRulePopup() {
            let policyList = JSON.parse(JSON.stringify(this.policyList));
            policyList.forEach((value, index) => {
                if (index === 0) {
                    value.show = true;
                } else {
                    value.show = false;
                }
            })
            this.policyList = policyList;
            this.evectionRulePopupShow = true
        },
        getUsefulCarScene() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0,
            });
            consumer_web_policyConfig_getUsefulCarScene().then(res => {
                this.errCode = '';
                this.errMsg = '';
                this.carSceneResults = res.datas.carSceneResults;
                this.dailyRemainingAmount = res.datas.carSceneResults[0].dailyRemainingAmount;
                this.monthRemainingAmount = res.datas.carSceneResults[0].monthRemainingAmount;
                this.carReason = 0;
                for (let i = 0;i < res.datas.carSceneResults.length;i++) {
                    if (res.datas.carSceneResults[i].carReason == 1) {
                        this.carReason = 1;
                    }
                }
            }).catch((e) => {
                this.carSceneResults = [];
                this.errCode = e.data.code;
                if (this.errCode !== '100009' && this.errCode !== '100010') {
                    Dialog.alert({
                        title: '温馨提示',
                        message: e.data.msg,
                        confirmButtonText: '我知道了'
                    }).then(() => {
                        // on close
                    });
                } else {
                    this.errMsg = e.data.msg;
                }
            }).finally(() => {
                Toast.clear()
            })
        },
        getLoginUserCarPolicy() {
            consumer_web_policyConfig_getLoginUserCarPolicy().then(res => {
                let policyList = res.datas.policyList || [];
                policyList.forEach((value, index) => {
                    if (index === 0) {
                        value.show = true;
                    } else {
                        value.show = false;
                    }
                })
                this.policyList = policyList;
            })
        },
        returnUseCarTimeText(val) {
            // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
            const useCarType = [2, 5, 6];
            if (useCarType.indexOf(val) > -1) {
                return true
            } else {
                return false
            }
        },
        returnUseCarLocationType(val) {
            // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
            const useCarType = [2, 5];
            if (useCarType.indexOf(val) > -1) {
                return true
            } else {
                return false
            }
        },
        returnWorkLocation(val) {
            // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
            const useCarType = [2, 5];
            if (useCarType.indexOf(val) > -1) {
                return true
            } else {
                return false
            }
        },
        returnUsageScenarios(val) {
            // 用车差标类型 1：出差用车 2：加班用车 3：接送服务 4：代叫车 5：定点通勤 6：外勤用车
            const useCarType = [2, 3];
            if (useCarType.indexOf(val) > -1) {
                return true
            } else {
                return false
            }
        },
        clickCarCity() {
            this.$refs.aTravelApplicationCitySelector.show();
        },
        clickCar(value) {
            if (value.applyStatusText != '审核通过') {
                return;
            }
            this.travelCurrent = {
              id: value.evectionId,
              active: {
                staffList: value.staffList,
                id: value.journeyId,
              },
              endDate: value.endDate,
              evectionNo: '',
              evectionReason: value.carRaeson,
              startDate: value.depDate,
            };
            this.getCurrentEvection({
                active: {
                    areaType: 1,
                    businessType: 4,
                    carPolicyType: value.carPolicyType,
                    carPolicyText: value.carPolicyText,
                    departCityId: null,
                    departCityName: null,
                    departTime: value.depDate,
                    departTimeEnd: value.endDate,
                    evectionId: value.evectionId,
                    evectionNo: "",
                    firstDepartTime: null,
                    firstReturnTime: null,
                    id: value.journeyId,
                    journeyType: 0,
                    journeyTypeText: "普通",
                    returnTime: null,
                    returnTimeEnd: null,
                    singleReturnType: null,
                    singleReturnTypeText: null,
                    staffList: value.staffList,
                    toCityId: null,
                    toCityName: value.endCity,
                    tripDate: null,
                },
                endDate: '',
                startDate: '',
                evectionNo: '',
                evectionReason: value.carRaeson,
                feeAffiliation: "各自所在部门",
                feeAffiliationId: -1,
                feeAffiliationType: 1,
                id: value.evectionId,
                journeys: [],
                partnerEvectionId: null,
            });
        },
        beforeToOrder(val) {
            if (this.carReason == 1) {
                Toast.loading({
                    message: '',
                    forbidClick: true,
                    duration: 0,
                });
                consumer_apply_carReasonApproval({carPolicyType: val.carPolicyType}).then((res) => {
                    this.carReasonText = '';
                    this.carPolicyType = val.carPolicyType;
                    this.carPolicyTypeText = val.carPolicyTypeText;
                    if (res.datas && res.datas.length == []) {
                        this.carApplyPopup = true;
                        this.carApplyPopupTitle = val.carPolicyTypeText + '申请';
                        this.getApproveFlow();
                        this.$refs.aTravelApplicationCitySelector.init();
                    } else {
                        this.carApplyList = res.datas;
                        let switchBox = [];
                        for (let i = 0;i < this.carApplyList.length;i++) {
                            if (this.carApplyList[i].applyStatusText == '审核通过') {
                                switchBox.push(this.carApplyList[i]);
                            }
                        }
                        // if (switchBox.length == 1) {
                        //     this.clickCar(switchBox[0]);
                        // }
                        this.carApplyPopup = true;
                        this.carApplyPopupTitle = '选择用车事由';
                    }
                }).catch((rej) => {
                    Toast(`${rej.data.msg}`);
                }).finally(() => {
                    Toast.clear()
                });
                return;
            }

            // 免审情况
            console.log(this.userCanApprove, this.$store.state.policy.isFreeApprove)
            if (this.userCanApprove && this.$store.state.policy.isFreeApprove === 1) {
                if (this.carControlRule === 1 && this.travelCurrent !== null) {
                    this.createOrder(val.carPolicyType);
                } else {
                    this.toOrder(val);
                }
                return;
            }

            // 以下走正常流程
            if (this.carControlRule === 1 && this.travelCurrent !== null && this.travelCurrent.active.journeyType === 0 && val.carPolicyType !== this.travelCurrent.active.carPolicyType) {
                return;
            }
            if (this.carControlRule === 1 && this.travelCurrent === null) {
                Toast('请选择出差单');
                return;
            }
            if (this.carControlRule === 1) {
                this.createOrder(val.carPolicyType);
            } else {
                this.toOrder(val);
            }


        },
        async toOrder(val) {
            Toast.loading({
                message: '',
                forbidClick: true,
                duration: 0,
            });
            let [checkErr, checkResult] = await awaitWrap(consumer_web_company_checkCompanyUseCar());
            if (checkErr) {
                Toast.clear();
                return
            }
            const useCar = checkResult.datas.useCar;
            if (useCar !== 1) {
                Toast.clear();
                Dialog.alert({
                    title: '温馨提示',
                    message: '企业暂未开通用车功能',
                    confirmButtonText: '我知道了'
                }).then(() => {
                    // on close
                    this.back();
                });
                return
            }
            let params = {
                policyId: val ? val.policyId : '',
                carPolicyType: val.carPolicyType,
            };
            let [err, result] = await awaitWrap(this.checkCreditUsableAmount(val ? val.policyId : ''));
            if (err) {
                Toast.clear();
                return
            }
            consumer_car_link_getGaodeLinkByPolicyId(params).then(res => {
                let url = res.datas.url;
                // url = 'widget://html/demo/api-execScript-test/1.0.3/index-b.html';
                open({
                    url,
                    title: '用车首页',
                    parent_window_name: `main`,
                    wait_seconds_to_exec_script: 2,
                    /*btn_back_click_event_handler: () => {
                        console.log('btn_back_click_event_handler');
                        //console.log('api.winName=', api.winName);
                        //console.log(window.ning_test());
                        //console.log(window.checkCreditUsableAmount());
                    }*/
                })
            }).catch((e) => {
                console.log('非6个0', e);
                // 如果非6个0情况返回了高德url，则弹出可以继续预约用车的弹窗
                if (e.data.datas && e.data.datas.url) {
                    Dialog.confirm({
                        title: "温馨提示",
                        message: e.data.msg,
                        confirmButtonText: '去用车',
                        cancelButtonText: '取消用车',
                    }).then(()=>{
                        window.clearTravel = function () {
                            this.travelCurrent = null;
                        };
                        let url = e.data.datas.url;
                        // url = 'widget://html/demo/api-execScript-test/1.0.3/index-b.html';
                        open({
                            name: 'car',
                            url,
                            title: '用车首页',
                            parent_window_name: `main`,
                            wait_seconds_to_exec_script: 2,
                            enable_log: true,
                            btn_back_click_event_handler: () => {
                                console.log('btn_back_click_event_handler');console.log(window.clearTravel());
                            }
                        })
                    }).catch(()=>{

                    })
                } else {
                    Dialog.alert({
                        title: '温馨提示',
                        message: e.data.msg,
                        confirmButtonText: '我知道了'
                    }).then(() => {
                        // on close
                    });
                }
            }).finally(() => {
                Toast.clear()
            })
        },
        checkCreditUsableAmount(val) {
            console.log(`checkCreditUsableAmount`);
            return new Promise((resolve, reject) => {
                // 不传就是因私
                if (val) {
                    consumer_car_order_checkCreditUsableAmount({policyId: val}).then(res => {
                        let result = res.datas;
                        if (result.enoughAmount === 1) {
                            resolve(true)
                        } else {
                            Dialog.alert({
                                title: '温馨提示',
                                message: result.msg,
                            }).then(() => {
                                // on close
                            });
                            reject()
                        }
                    }, error => {
                        reject()
                    })
                } else {
                    resolve(true)
                }
            })

        },
        /*toCreateOrder() {
            if (this.paymentPage === 1) {
                // 弹窗
                Dialog.confirm({
                    title: '温馨提示',
                    message: '因公出行订单费用将由企业账户支付',
                    confirmButtonText: '继续预订',
                    cancelButtonText: '取消预订',
                }).then(() => {
                    // on confirm
                    this.createOrder();
                }).catch(() => {
                    // on cancel
                });
            } else {
                // 不弹
                this.createOrder();
            }
        },*/
        getCurrentEvection(newVal) {
            let hasCarType = false;
            if (newVal === null) {
                return
            }
            // 判断是不是没有职级
            if(this.errCode === '100009') {
                Dialog.alert({
                    title: '温馨提示',
                    message: this.errMsg,
                }).then(() => {
                    // on close
                });
                return;
            }

            // 再看有没有适合的用车类型
            if (newVal.active.journeyType === 0) {
                // 普通出差单情况下
                this.carSceneResults.forEach(value => {
                    if (value.carPolicyType === newVal.active.carPolicyType) {
                        hasCarType = true;
                    }
                });
                this.hasCarType = hasCarType;
                this.noHasCarTypeText = `未设置${newVal.active.carPolicyText}差标，联系管理员为您设置`;
            } else {
                // 不限行程的情况下
                if (this.carSceneResults.length === 0) {
                    hasCarType = false;
                    this.noHasCarTypeText = `未设置差标，联系管理员为您设置`;
                } else {
                    this.carSceneResults.forEach(value => {
                        if (value.carPolicyType === newVal.active.carPolicyType) {
                            hasCarType = true;
                        }
                    });
                    // this.hasCarType = hasCarType;
                    this.noHasCarTypeText = `未设置${newVal.active.carPolicyText}差标，联系管理员为您设置`;
                    if (!hasCarType) {
                        this.noHasCarTypeText = '请选择用车场景';
                    }
                }
                this.hasCarType = hasCarType;
            }

            if (!hasCarType) {
                Dialog.alert({
                    title: '温馨提示',
                    message: this.noHasCarTypeText,
                }).then(() => {
                    // on close
                });
            }
            // 如果是普通订单并且有出差权限
            if (hasCarType && newVal.active.journeyType === 0) {
                // 跳转
                // 创建出差单-》 获取高德h5地址
                this.createOrder(newVal.active.carPolicyType);
            }
            if (hasCarType && newVal.active.journeyType === 1) {
                // Toast('请选择用车场景');
            }
        },
        async createOrder(carPolicyType) {
            const __this = this;
            Toast.loading({
                message: '',
                forbidClick: true,
                duration: 0,
            });
            let [checkErr, checkResult] = await awaitWrap(consumer_web_company_checkCompanyUseCar());
            if (checkErr) {
                Toast.clear();
                return
            }
            const useCar = checkResult.datas.useCar;
            if (useCar !== 1) {
                Toast.clear();
                Dialog.alert({
                    title: '温馨提示',
                    message: '企业暂未开通用车功能',
                    confirmButtonText: '我知道了'
                }).then(() => {
                    // on close
                    this.back();
                });
                return
            }
            const staffList = this.travelCurrent.active.staffList;
            let staffIdList = [];
            staffList.forEach(value => {
                if (value.bookStatus === 1) {
                    staffIdList.push(value.userId)
                }
            });

            let params = {
                journeyId: this.travelCurrent ? this.travelCurrent.active.id : 0,
                applyId: this.travelCurrent ? this.travelCurrent.id : "",
                endDate: this.travelCurrent ? this.travelCurrent.endDate : "",
                evectionNo: this.travelCurrent ? this.travelCurrent.evectionNo : "",
                evectionType: this.useTravel === 1 ? 2 : 1, // 1是个人出差，2是出差单出差
                startDate: this.travelCurrent ? this.travelCurrent.startDate : "",
                travellerList: staffIdList,
                personType: 2,
            };
            Toast.loading({
                message: '',
                forbidClick: true,
                duration: 0,
            });
            let [err, res] = await awaitWrap(consumer_evection_addOrUpdate(params));
            if (err) {
                Toast.clear();
                return
            }
            const urlParams = {
                journeyId: this.travelCurrent ? this.travelCurrent.active.id : "",
                carPolicyType: carPolicyType,
            };
            consumer_car_link_getGaodeLinkByPolicyId(urlParams).then(res => {

                window.clearTravel = function () {
                    __this.travelCurrent = null;
                };
                let url = res.datas.url;
                // url = 'widget://html/demo/api-execScript-test/1.0.3/index-b.html';
                open({
                    name: 'car',
                    url,
                    title: '用车首页',
                    parent_window_name: `main`,
                    wait_seconds_to_exec_script: 2,
                    enable_log: true,
                    btn_back_click_event_handler: () => {
                        console.log('btn_back_click_event_handler');console.log(window.clearTravel());
                    }
                })
            }).catch((e) => {
                console.log('非6个0', e);
                // 如果非6个0情况返回了高德url，则弹出可以继续预约用车的弹窗
                if (e.data.datas && e.data.datas.url) {
                    Dialog.confirm({
                        title: "温馨提示",
                        message: e.data.msg,
                        confirmButtonText: '去用车',
                        cancelButtonText: '取消用车',
                    }).then(()=>{
                        window.clearTravel = function () {
                            __this.travelCurrent = null;
                        };
                        let url = e.data.datas.url;
                        // url = 'widget://html/demo/api-execScript-test/1.0.3/index-b.html';
                        open({
                            name: 'car',
                            url,
                            title: '用车首页',
                            parent_window_name: `main`,
                            wait_seconds_to_exec_script: 2,
                            enable_log: true,
                            btn_back_click_event_handler: () => {
                                console.log('btn_back_click_event_handler');console.log(window.clearTravel());
                            }
                        })
                    }).catch(()=>{

                    })
                } else {
                    Dialog.alert({
                        title: '温馨提示',
                        message: e.data.msg,
                        confirmButtonText: '我知道了'
                    }).then(() => {
                        // on close
                    });
                }

            }).finally(() => {
                Toast.clear()
            })
        },
        getPayRemider() {
            consumer_payment_payReminder().then(res=>{
                this.paymentPage = res.datas.paymentPage;
            })
        },
        goOrderList() {
            this.$router.push({
                name: 'h5-car-order-list',
                query: {
                    jumpType: 'back'
                }
            })
        },
        changePolicyShow(val) {
            val.show = !val.show;
        }
    }
}
