// consumer_department_getStaffsAndCertificateByUserIds
const __request = require(`./__request/__request_contentType_json`);
const to_boolean = require(`nllib/src/convert/to_boolean`);

const consumer_department_getStaffsAndCertificateByUserIds = (pParameter) => {
    if (!pParameter) pParameter = {};
    const companyId = pParameter.companyId || 0;
    const currentPage = pParameter.currentPage || 1;
    const isSelectCustomer = to_boolean(pParameter.isSelectCustomer, true);
    const key = pParameter.key || ``;
    const keyword = pParameter.keyword || ``;
    const orderBy = pParameter.orderBy || ``;
    const pageSize = pParameter.pageSize || 999;
    const userIds = pParameter.userIds || [];
    const parameter = {
        method: 'post',
        urlSuffix: '/consumer/department/getStaffsAndCertificateByUserIds',
        data: {
            companyId,
            currentPage,
            isSelectCustomer,
            key,
            keyword,
            orderBy,
            pageSize,
            userIds,
        },
    };
    return __request(parameter);
};
export default consumer_department_getStaffsAndCertificateByUserIds