// consumer_car_order_carOrderList 我的订单-用车订单列表
const __request = require(`./__request/__request_contentType_json`);

const consumer_car_order_carOrderList = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/car/order/carOrderList',
        data: pParameter
    };
    return new Promise(function (resolve, reject) {
        resolve(__request(p));
    })
};
module.exports = consumer_car_order_carOrderList;