// consumer_trains_order_orderBookingPolling
const __request = require(`./__request/__request_contentType_json`);
// 接口文档地址：
// http://api-alpha.haolvtrip.com/doc.html#/yinzhilv-tmc/%E7%81%AB%E8%BD%A6%E7%A5%A8-%E8%AE%A2%E5%8D%95%E6%93%8D%E4%BD%9C/orderBookingPollingUsingPOST
const consumer_trains_order_orderBookingPolling = (pParameter) => {
    if (!pParameter) pParameter = {};
    const p = {
        method: 'post',
        urlSuffix: '/consumer/trains/order/orderBookingPolling',
        data: pParameter,
        enable_error_alert: false // 临时隐藏
    };
    return new Promise(function(resolve, reject) {
        resolve(__request(p))
    })
};
module.exports = consumer_trains_order_orderBookingPolling;
