import NavBar from '@/component/nav-bar/import_latest_version_module'
import consumer_trains_passenger_addPassengerBusiness from '@/lib/data-service/haolv-default/consumer_trains_passenger_addPassengerBusiness'
import consumer_frequent_addOrUpdateFrequent from "@/lib/data-service/haolv-default/consumer_frequent_addOrUpdateFrequent";
import consumer_web_city_areaLinkage from "@/lib/data-service/haolv-default/consumer_web_city_areaLinkage";
import mock_data_helper from './../../lib/mock_data_helper'
import fixStatusBar from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar";
import extend from "extend";
import {Toast} from "vant";
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
export default {
    data() {
        return {
            show: false,
            maxNumber: 0,
            type: '001', // 001： 员工， 002：常旅客
            mock_data: {
                enable: false,
                test_case: '001'
            },
            list: [],
            result: [],

            typeData: '000',
            hideValue: '', //隐藏后的证件号码
            titleText: '',
            clickShow: false,
            // editShow: false,
            nameOff: true,
            choiceCardType: 1,
            clickShowTime: false,
            showPicker: false,
            clickShowCard: false,
            userData: {
                surnames: '', //姓拼音
                name: '', //名拼音
                birthday: '', //生日
                countryName: '', //国籍
                countryId: '', //国籍ID
                frequentPassengerName: '', //姓名
                frequentId: '', //姓名ID
                phone: '', //手机号码
                certificates: {
                    certificateType: 1,
                    certificateTypeText: '身份证',
                    certificateValue: ''
                }
            },
            userDataBox: null,
            countryDataAll: [],
            currentDate: new Date(),
            minDate: new Date(1920, 1, 1),
            maxDate: new Date(),
            cardTypeAll: [
                {
                    certificateType: 1,
                    certificateTypeText: '身份证',
                    certificateTypeBox: '',
                },
                {
                    certificateType: 2,
                    certificateTypeText: '护照',
                    certificateTypeBox: '',
                },
                {
                    certificateType: 5,
                    certificateTypeText: '台胞证',
                    certificateTypeBox: '',
                },
                {
                    certificateType: 4,
                    certificateTypeText: '回乡证',
                    certificateTypeBox: '',
                },
                {
                    certificateType: 6,
                    certificateTypeText: '军官证',
                    certificateTypeBox: '',
                },
                {
                    certificateType: 7,
                    certificateTypeText: '香港身份证',
                    certificateTypeBox: '',
                },
                {
                    certificateType: 11,
                    certificateTypeText: '澳門身份證',
                    certificateTypeBox: '',
                },
            ],
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
        consumer_web_city_areaLinkage({levelType: 1}).then(res => {
            this.countryDataAll = res.datas;
        })
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        _addBack() {
            this.show = false;
        },
        _toggle(index) {
            this.$refs.checkboxes[index].toggle();
        },
        async _submit() {
            if (this.result.length === 0) {
                Toast(`请选择${this.type === '001' ? '员工' : '常旅客' }`);
                return
            }
            Toast.loading({
                duration: 0, // 持续展示 toast
                forbidClick: true,
            });
            const params = {
                userIds: JSON.parse(JSON.stringify(this.result))
            };
            const addPassengerBusiness = this.mock_data.enable ? mock_data_helper.addPassengerBusiness(params) : consumer_trains_passenger_addPassengerBusiness(params);
            let [err, res] = await awaitWrap(addPassengerBusiness);
            if (err) {
                Toast.clear();
                return
            }
            Toast.clear();
            Toast.success('添加成功');
            this.$emit('result', {list: this.result});
            this._addBack()
        },
        _react() {
            this.result = []
        },
        _openedResult() {
            const _this = this;
            fixStatusBar({
                handler({default_handler, api}) {
                    const bodyWidth = document.body.clientWidth;
                    const topElement = _this.$refs['navBar'].$el;
                    let orrPaddingTop = topElement.style.paddingTop;
                    if (bodyWidth >= 414) {
                        orrPaddingTop = orrPaddingTop * 2
                    }
                    let currentPaddingTop = (orrPaddingTop || 0)/(bodyWidth/100);
                    const addPaddingTop = (api.safeArea.top)/(bodyWidth/100);
                    topElement.style.paddingTop = (addPaddingTop + currentPaddingTop) + 'vw';
                }
            })
        },
        async _edit(item, index) {
            console.log('edit', item)
            this.clickShow = true;
            this.nameOff = true;
            //回填数据
            //证件类型信息回填--证件号
            /*if (this.typeData != '000') {
                if (this.frequents[index].certificates.length != 7) {
                    let res = await consumer_frequent_getCertificatesByFrequentId({frequentId: this.frequents[index].frequentUserId})
                    this.frequents[index].certificates = res.datas.certificates;
                }
            }*/

            this.userDataBox = JSON.parse(JSON.stringify(item));
            if (this.userDataBox.certificateType == undefined) {
                this.choiceCardType = this.userDataBox.certificates[0].certificateType;
            } else {
                this.choiceCardType = this.userDataBox.certificateType;
            }

            let indexBox = 0;
            this.userDataBox.certificates.forEach((item, index) => {
                if (item.certificateType == this.choiceCardType) {
                    indexBox = index;
                }
            });


            this._fillData(this.userDataBox, indexBox);
        },
        //回填信息
        _fillData(data, index) {
            let indexBox = index == undefined ? 0 : index;

            if (data.birthday != null || data.birthday != undefined) {
                const list = data.birthday.split('-');
                if (list.length >= 3) {
                    this.currentDate = new Date(list[0], list[1] - 1, list[2]);
                } else {
                    this.currentDate = new Date();
                }
            } else {
                this.currentDate = new Date();
            }

            this.userData.surnames = data.surnames == undefined ? '' : data.surnames;
            this.userData.name = data.name == undefined ? '' : data.name;
            this.userData.birthday = data.birthday == undefined ? null : data.birthday;
            this.userData.countryName = data.countryName == undefined ? '' : data.countryName;
            this.userData.countryId = data.countryId == undefined ? '' : data.countryId;
            this.userData.frequentId = data.frequentUserId == undefined ? '' : data.frequentUserId;
            this.userData.frequentPassengerName = data.frequentPassengerName == undefined ? '' : data.frequentPassengerName;
            this.userData.phone = data.phone == undefined ? '' : data.phone;
            this.userData.certificates.certificateTypeText =
                data.certificates[indexBox].certificateTypeText == undefined
                    ? '' : data.certificates[indexBox].certificateTypeText;
            this.userData.certificates.certificateType =
                data.certificates[indexBox].certificateType == undefined ? '' : data.certificates[indexBox].certificateType;
            this.userData.certificates.certificateValue =
                data.certificates[indexBox].certificateValue == undefined ? '' : data.certificates[indexBox].certificateValue;

            //将证件号隐藏
            this.hideValue = this._getNumHide(this.userData.certificates.certificateType,this.userData.certificates.certificateValue);
        },
        _onConfirm(value, index) {
            this.showPicker = false;
            this.userData.countryName = value.name;
            this.userData.countryId = value.id;
        },
        //生日组件--确认按钮
        _clickConfirm() {
            this.userData.birthday = `${this.currentDate.getFullYear()}-${this.currentDate.getMonth() + 1}-${this.currentDate.getDate()}`;
            this.clickShowTime = false;
        },
        //生日组件--取消按钮
        _clickCancel() {
            this.clickShowTime = false;
        },
        //证件类型选择--返回title
        _cardTextBrackets(TypeText, TypeBox) {
            let num = null;
            if (TypeText == '身份证') {
                num = 1;
            }
            if (TypeText == '护照') {
                num = 2;
            }
            if (TypeText == '回乡证') {
                num = 4;
            }
            if (TypeText == '台胞证') {
                num = 5;
            }
            if (TypeText == '军官证') {
                num = 6;
            }
            if (TypeText == '香港身份证') {
                num = 7;
            }
            if (TypeText == '澳門身份證') {
                num = 11;
            }

            let text = '';
            if (this.nameOff) {
                if (TypeBox == '' || TypeBox == null) {
                    text = TypeText;
                } else {
                    text = `${TypeText}(${this._getNumHide(num,TypeBox)})`;
                }
            } else {
                text = TypeText;
            }

            return text
        },
        //证件类型选择--click
        _clickChoice(index) {
            //记录当前证件类型的选择--type
            this.choiceCardType = index;
            //关闭证件类型选择popup
            this.clickShowCard = false;
            //判断所选证件类型
            let testType = null;
            this.cardTypeAll.forEach((CItem, CIndex) => {
                if (index == CItem.certificateType) {
                    testType = CItem;
                }
            });
            //将证件类型选择的选项回填--text
            this.userData.certificates.certificateTypeText = testType.certificateTypeText;
            //将证件类型选择的选项回填--type
            this.userData.certificates.certificateType = testType.certificateType;

            //区分新增、编辑
            if (this.nameOff) {
                //编辑
                //判断选中的证件类型是否有信息
                if (testType.certificateTypeBox == '') {
                    //未保存数据--清空旧数据
                    this._resetData();
                    this.userData.certificates.certificateValue = '';
                } else {
                    //已保存数据--已保存数据回填
                    let indexBox = 0;
                    this.userDataBox.certificates.forEach((item, index) => {
                        if (this.choiceCardType == item.certificateType) {
                            indexBox = index;
                        }
                    });
                    this._fillData(this.userDataBox, indexBox);
                }
            } else {
                //新增
                //清空数据
                this._resetData();
                this.userData.certificates.certificateValue = '';
            }
        },
        //清空数据
        _resetData() {
            this.userData.surnames = '';
            this.userData.name = '';
            this.userData.birthday = '';
            this.userData.countryName = '';
            this.userData.frequentPassengerName = '';
            this.userData.phone = '';
        },
        _onFailed() {},
        //选择出生日期
        _clickBirthday() {
            this.clickShowTime = true;
        },
        _clickCountry() {
            this.showPicker = true;
        },
        //证件类型选择--显示证件类型选择
        _clickCard() {
            this.clickShowCard = true;
            //区分新增、编辑
            if (this.nameOff) {
                //编辑
                this.cardTypeAll.forEach((item, index) => {
                    item.certificateTypeBox = '';
                    this.userDataBox.certificates.forEach((UItem, UIndex) => {
                        if (item.certificateType == UItem.certificateType) {
                            item.certificateTypeBox = UItem.certificateValue;
                        }
                    })
                })
            } else {
                //新增
            }
        },
        //证件号码校验
        _rulesCertificates(val) {
            let data = this._getNumHide(this.userData.certificates.certificateType,this.userData.certificates.certificateValue);

            if (this.userData.certificates.certificateType == 1) {
                let patternOne = /^[1-9][0-9]/;
                let patternTwo = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
                let patternThree = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                let provs = {
                    11: "北京",
                    12: "天津",
                    13: "河北",
                    14: "山西",
                    15: "内蒙古",
                    21: "辽宁",
                    22: "吉林",
                    23: "黑龙江 ",
                    31: "上海",
                    32: "江苏",
                    33: "浙江",
                    34: "安徽",
                    35: "福建",
                    36: "江西",
                    37: "山东",
                    41: "河南",
                    42: "湖北 ",
                    43: "湖南",
                    44: "广东",
                    45: "广西",
                    46: "海南",
                    50: "重庆",
                    51: "四川",
                    52: "贵州",
                    53: "云南",
                    54: "西藏 ",
                    61: "陕西",
                    62: "甘肃",
                    63: "青海",
                    64: "宁夏",
                    65: "新疆",
                    71: "台湾",
                    81: "香港",
                    82: "澳门"
                };
                let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
                //身份证校验
                if (val.length !== 18) {
                    return false
                }
                if (!patternOne.test(val.substring(0, 2))) {
                    return false
                }
                if (provs[val.substring(0, 2)] == undefined) {
                    return false
                }
                if (!patternTwo.test(val.substring(6, 14))) {
                    return false
                } else {
                    let year = val.substring(6, 10);
                    let month = val.substring(10, 12);
                    let date = val.substring(12, 14);
                    let dateTime = new Date();
                    let date2 = new Date(year + "-" + month + "-" + date);

                    if (dateTime <= date2) {
                        return false
                    }
                    if (!(date2 && date2.getMonth() == (parseInt(month) - 1))) {
                        return false
                    }
                }
                if (!patternThree.test(val)) {
                    return false
                } else {
                    let sum = 0;
                    let code = val.substring(17);
                    for (let i = 0; i <= 16; i++) {
                        sum = sum + val[i] * factor[i];
                    }
                    if (parity[sum % 11] != code) {
                        return false
                    }
                }
            } else {

            }
            return true
        },
        _getNumHide(type, value) {
            if (type == 1) {
                //身份证
                let text = value.slice(0, 5) + '********' + value.slice(13);
                return text
            }
            if (type == 2) {
                //护照
                let text = value.slice(0, parseInt(value.length / 2) - 2) + '****' + value.slice(parseInt(value.length / 2) + 2);
                return text
            }
            if (type == 4) {
                //回乡证
                let text = value.slice(0, parseInt(value.length / 2) - 2) + '****' + value.slice(parseInt(value.length / 2) + 2);
                return text
            }
            if (type == 5) {
                //台胞证
                let text = value.slice(0, parseInt(value.length / 2) - 4) + '********' + value.slice(parseInt(value.length / 2) + 4);
                return text
            }
            if (type == 6) {
                //军官证
                let text = value.slice(0, parseInt(value.length / 2) - 1) + '***' + value.slice(parseInt(value.length / 2) + 2);
                return text
            }
            if (type == 7) {
                //香港身份证
                let text = value.slice(0, parseInt(value.length / 2) - 4) + '********' + value.slice(parseInt(value.length / 2) + 4);
                return text
            }
            if (type == 11) {
                //澳门身份证
                let text = value.slice(0, parseInt(value.length / 2) - 4) + '********' + value.slice(parseInt(value.length / 2) + 4);
                return text
            }
        },
        _phoneNumberForm(val) {
            let reg = /^1[3456789]\d{9}$/;
            return reg.test(val)
        },
        //保存按钮
        _keepClick() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            });
            this.$refs.form.validate().then(() => {
                let postDataBox = {
                    birthday: this.userData.birthday == '' ? null : this.userData.birthday,
                    certificateType: this.userData.certificates.certificateType == null ? '' : this.userData.certificates.certificateType,
                    certificateValue: this.hideValue == null ? '' : this.hideValue,
                    countryId: this.userData.countryId == '' ? null : this.userData.countryId,
                    name: this.userData.name == null ? '' : this.userData.name,
                    frequentPassengerName: this.userData.frequentPassengerName == null ? '' : this.userData.frequentPassengerName,
                    phone: this.userData.phone == null ? '' : this.userData.phone,
                    surnames: this.userData.surnames == null ? '' : this.userData.surnames,
                };
                let data = this._getNumHide(this.userData.certificates.certificateType,this.userData.certificates.certificateValue);
                if (data == postDataBox.certificateValue) {
                    postDataBox.certificateValue = this.userData.certificates.certificateValue;
                }
                if (postDataBox.countryId == '' || postDataBox.countryId == null) {
                    delete postDataBox.countryId
                }
                // nameOff-true-编辑旅客
                postDataBox.frequentUserId = this.userData.frequentId == null ? '' : this.userData.frequentId;
                consumer_frequent_addOrUpdateFrequent(postDataBox).then(res => {
                    if (res.code == '000000') {
                        Toast.success('编辑成功');
                    } else {
                        Toast.fail('编辑失败');
                    }
                    this.clickShow = false;
                    this.$emit('reactList')
                })
            }).catch(() => {
                this.$toast.fail('提交失败');
            });
        },

        init(params) {
            this._react();
            let initParams = {
                canChooseTravelerList: [], // 可选的名单
                maxNumber: 0,
                type: '001',
                mock_data: {
                    enable: false,
                    test_case: '001'
                },
            };
            if (!params) {
                params = {}
            }
            extend(initParams, params);
            extend(initParams.mock_data, params.mock_data);
            this.list = initParams.canChooseTravelerList;
            this.maxNumber = initParams.maxNumber;
            this.type = initParams.type;
            this.mock_data = initParams.mock_data;
        },
        showPopup(val) {
            this.result = [];
            this.show = true;
        },

        get_show() {
            return this.show
        },
        hide() {
            this._addBack()
        },
    }
}