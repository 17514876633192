import moment from "moment";
import consumer_apply_myApplyList from "@/lib/data-service/haolv-default/consumer_apply_myApplyList";
import consumer_apply_applyJourneyList from '@/lib/data-service/haolv-default/consumer_apply_applyJourneyList'
import NavBar from "@/component/nav-bar/import_latest_version_module";
let deferred = function () {
    let dfd = {};
    dfd.promise = new Promise((resolve, reject) => {
        dfd.resolve = resolve;
        dfd.reject = reject;
    })
    return dfd
}
export default {
    data () {
        return {
            loading: false,
            businessTravelList: [],
            currentPopupShow: false,
            paddingBottom: 0,
            haveTravel: false,
            listDeferred: deferred(),
            activeItem: null,
        }
    },
    props: {
        popupShow: {
            type: Boolean,
            default: false
        },
        // list: {
        //     type: Array,
        //     default: () => []
        // },
        journeyId: {
            type: [Number, String],
            default: 0,
        },
        // activeItem: {
        //     type: Object,
        //     default: null
        // },
        // 筛选显示到类型
        businessType: {
            type: Number,
            default: 0 // 1.机票，2.火车票，3.酒店
        }
    },
    components: {NavBar},
    created () {},
    mounted () {
        let __this = this
        this.getBusinessTravel()
    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {
        businessType(newVal, oldVal) {
            this.setHaveTravel()
        },
        journeyId: {
            immediate: false,
            handler(newVal, oldVal) {
                if (newVal === '' || newVal === 0) {
                    this.activeItem = null
                    this.$emit('getCurrent', this.activeItem)
                    return
                }
                this.callbackReady().then(() => {
                    let currentItem = null
                    this.businessTravelList.forEach(value => {
                        value.journeys.forEach(value1 => {
                            if (value1.id === newVal) {
                                currentItem = JSON.parse(JSON.stringify(value))
                                currentItem.active = value1
                                currentItem.active.evectionId = value.id
                            }
                        })
                    })
                    this.activeItem = currentItem
                    this.$emit('getCurrent', currentItem)
                })
            }
        }
    },
    computed: {
        currentJourneyId: {
            get () {
                return this.journeyId
            },
            set (val) {
                this.$emit('update:journeyId', val)
            }
        },
    },
    filters: {
        changeDepartTime(val) {
            let day = moment(val).format('YYYY年MM月DD日')
            return day
        },
        changeDepartDayAndTime(val) {
            let day = moment(val).format('YYYY-MM-DD')
            return day
        },
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "周日";
            } else if (day === 1) {
                return "周一";
            } else if (day === 2) {
                return "周二";
            } else if (day === 3) {
                return "周三";
            } else if (day === 4) {
                return "周四";
            } else if (day === 5) {
                return "周五";
            } else if (day === 6) {
                return "周六";
            } else {
                return "";
            }
        },
    },
    methods: {
        getShow() {
            return this.currentPopupShow
        },
        chooseTravel() {
            this.currentPopupShow = true
        },
        callbackReady() {
            return this.listDeferred.promise
        },
        getBusinessTravel() {
            this.loading = true
            let params = {
                pageSize: 1000,
                currentPage: 1,
                status: 2,
                approveType: 1,
            };
            consumer_apply_applyJourneyList(params).then((res) => {
                let businessTravelList = res.datas;
                // 前海人寿情况
                if (this.$store.state.evectionBackType === '001') {
                    businessTravelList.forEach(value=>{
                        if (value.journeys) {
                            value.journeys.forEach(value1 => {
                                if (value1.businessType === 1) {
                                    value1.departCityName = localStorage.getItem('fromStationName');
                                    value1.toCityName = localStorage.getItem('toStationName');
                                }
                            })
                        }
                    })
                }
                this.businessTravelList = businessTravelList;
                this.setHaveTravel()
                this.listDeferred.resolve()
            }).finally(() => {
                this.loading = false
            });
        },
        setHaveTravel() {
            let haveTravel = false
            if (this.businessTravelList.length === 0) {
                haveTravel = false
            }
            this.businessTravelList.forEach(value => {
                if (value.journeys) {
                    value.journeys.forEach(value1 => {
                        if (this.businessType === 0 || value1.businessType === this.businessType) {
                            haveTravel = true
                        }
                    })
                }
            })
            this.haveTravel = haveTravel
        },
        getCurrentItem(item, journey, index, jIndex) {
            //let current = JSON.parse(JSON.stringify(item))
            //current.active = journey
            //current.active.evectionId = item.id
            //this.currentActiveItem = current
            //this.$emit('getCurrent', current)
            this.currentJourneyId = journey.id
            this.currentPopupShow = false
        },
        canShowItem(item) {
            let isThisBusinessType = (this.businessType === 0 || item.businessType === this.businessType)
            return isThisBusinessType
        },
        closeEvectionlPopup() {
            this.currentPopupShow = false
        },
        returnStaffList(val) {
            if (!val) {
                return ''
            }
            let staffList = []
            val.staffList.forEach(value => {
                staffList.push(value.staffName)
            })
            return staffList.join('/')
        },
        clearCurrent() {
            this.currentJourneyId = ''
            //this.currentActiveItem = null
            //this.$emit('getCurrent', null)
        },
        //
        getCurrentInfo() {
            return this.activeItem
        },
        setCurrentInfo() {
            return new Promise((resolve, reject) => {
                if (this.journeyId === '' || this.journeyId === 0) {
                    this.activeItem = null
                    this.$emit('getCurrent', this.activeItem)
                    return reject()
                }
                this.callbackReady().then(() => {
                    let currentItem = null
                    this.businessTravelList.forEach(value => {
                        value.journeys.forEach(value1 => {
                            if (value1.id === this.journeyId) {
                                currentItem = JSON.parse(JSON.stringify(value))
                                currentItem.active = value1
                                currentItem.active.evectionId = value.id
                            }
                        })
                    })
                    this.activeItem = currentItem
                    this.$emit('getCurrent', currentItem)
                    resolve()
                }).catch(e=>{
                    reject()
                })
            })
        },
        setActiveItemName(activeItem) {
            if (!activeItem) {
                return ''
            }
            let name = ''
            name += `${activeItem.evectionReason}-`
            let businessName = ''
            if (activeItem.active.businessType === 1) {
                businessName = '机票'
            } else if (activeItem.active.businessType === 2) {
                businessName = '火车票'
            } else {
                businessName = '酒店'
            }
            name += `[${businessName}] `
            if (activeItem.active.businessType === 3) {
                name += `${activeItem.active.departCityName || activeItem.active.toCityName} ${activeItem.active.tripDate}`
            } else {
                name += `${activeItem.active.departCityName}-${activeItem.active.toCityName} ${activeItem.active.tripDate}`
            }
            return name
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
