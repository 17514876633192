import NavBar from "@/component/nav-bar/import_latest_version_module";
export default {
    name: 'MyEvectionRulePopup',
    data() {
        return {}
    },
    props: {
        show: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        myTravelPolicy: {
            type: Object,
            default: ()=> {}
        }
    },
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        evectionRulePopupShow: {
            get () {
                return this.show
            },
            set (val) {
                this.$emit('update:show', val)
            }
        },
        policyLoading: {
            get () {
                return this.loading
            },
            set (val) {
                this.$emit('update:loading', val)
            }
        }
    },
    filters: {},
    methods: {
        closeRulePopup() {
            console.log('11111111');
            this.evectionRulePopupShow = false
        },
    }
}
