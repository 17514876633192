import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
  path: '',
  component: () => import_latest_version_module(),
  children: [
    {
      path: '/h5/no-payment',
      name: 'h5-no-payment',
      component: () => import(/* webpackChunkName: "page-travel-book-payment-no-payment" */ `./index.vue`),
      meta: {
        h5_layout: {
          current_page_name: '待支付',
          need_head_nav: false,
          need_alive: true,
          need_footer_bar: false,
          secondary_menu: 0,
        },
      
	data_for_all_routes_processor: {
    		order_index: '014-002',
	},

},
    }
  ],
};
