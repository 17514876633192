import Q from "q";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import OrangeNavBar from "@/component/orange-nav-bar/import_latest_version_module";
import PaymentMethodSelector000 from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/payment-method-selector/1.2.1/payment-method-selector-000/index.vue';
import {Dialog, Toast} from "vant";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'
// 取消订单
import consumer_trains_order_applyForCancel from '@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel'
// 时刻表
import TrainTimeTable from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
// 滑屏返回
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";
import consumer_trains_order_getTrainsOrderDetail from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsOrderDetail'
import wangyiServerOnline from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper/index'
import moment from "moment";


export default {
    components: {
        PaymentMethodSelector000,
        NavBar,
        OrangeNavBar,
        TrainTimeTable
    },
    data() {
        return {
            businessType: 2, // 1机票，2火车票，3酒店
            evectionNo: '',
            orderNos: [],
            orderType: "",
            pageType: "",
            detail: {},
            flightOrderList:[],

            detailShow: false,
            priceInfo: {
                trainstrainsOrder:[],
                passengers:[],
            },
            payAmount: 0,
            outPayAmount: 0,

            milliseconds: -1, // 倒计时 -1时不显示 秒
            countdownFun: null,
        };
    },
    computed: {
        countdownTimeStr() {
            let milliseconds = this.milliseconds;
            if (milliseconds && milliseconds > 0) {
                // 转换为式分秒
                let h = parseInt(milliseconds / 60 / 60 % 24);
                h = h < 10 ? '0' + h : h;
                let m = parseInt(milliseconds / 60 % 60);
                m = m < 10 ? '0' + m : m;
                let s = parseInt(milliseconds % 60);
                s = s < 10 ? '0' + s : s;
                // 作为返回值返回
                return `${h}:${m}:${s}`
            } else {
                return '00:00:00'
            }
        }
    },
    mounted(){
        const __this = this;
        fixStatusBar({
            type: `000`,
            handler({default_handler, api}) {
                __this.$refs['reserveBottom'].style.paddingBottom = `${api.safeArea.bottom + 8}px`;
                __this.$refs['reserveDetailBottom'].style.paddingBottom = `${api.safeArea.bottom}px`;
            }
        });
    },
    activated() {
        const __this = this;
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.getUnReadMsgNum({
                callback:(result) => {
                    __this.readMsgNum = result;
                }
            });
            setTimeout(() => {
                wangyiServerOnline.onlyHide();
            },200)
        });

        this.init();
        this.$refs.aPaymentMethodSelector000.get_data().then(res=>{
            let milliseconds = res.milliseconds;
            if (milliseconds > 0) {
                this.milliseconds = milliseconds;
                // 倒计时开始
                this.startCountdown();
            } else if (milliseconds === 0) {
                this.milliseconds = milliseconds;
                // 弹出提示
                this.showTimeEndDialog();
            } else {
                this.milliseconds = -1;
            }
        });

        let _this = this;
        keyback_processor.addEventListener(function () {
            //订单明细
            try {
                let show_detailShow = _this.detailShow;
                if (show_detailShow) {
                    _this.detailShow = false;
                    return;
                }
            } catch (err) {

            }

            //时刻表
            try {
                const aTrainTimeTable = _this.$refs.aTrainTimeTable;
                let show_aTrainTimeTable = aTrainTimeTable.get_show();
                if (show_aTrainTimeTable) {
                    _this.$refs.aTrainTimeTable.hide();
                    return;
                }
            } catch (err) {

            }
            _this.close_current_page();
        });
    },
    deactivated() {
        wangyiServerOnline.config().then(() => {
            wangyiServerOnline.show();
            wangyiServerOnline.destructionGetUnReadMsgNum();
        });

        clearInterval(this.countdownFun);
        keyback_processor.removeEventListener();
    },
    deactivate() {
        this.$Toast.clear();
    },
    filters: {
        filterWeek(val) {
            if (val === "") {
                return "";
            }
            let day = moment(val).day();
            if (day === 0) {
                return "星期日";
            } else if (day === 1) {
                return "星期一";
            } else if (day === 2) {
                return "星期二";
            } else if (day === 3) {
                return "星期三";
            } else if (day === 4) {
                return "星期四";
            } else if (day === 5) {
                return "星期五";
            } else if (day === 6) {
                return "星期六";
            } else {
                return "";
            }
        },
    },
    watch: {},
    methods: {
        //取消订单
        toCancelOrder(orderNo) {
            let _this = this;
            Dialog.confirm({
                title: '确认要取消订单吗？',
                message: '一天仅有3次取消订单的机会（包括自动取消），超过3次后当日将无法使用12306账号预订。',
                confirmButtonText: '取消订单',
                cancelButtonText: '稍后再说',
            }).then(() => {
                this.$showLoading.show();
                consumer_trains_order_applyForCancel({orderNo: orderNo})
                    .then(res => {
                        _this.$showLoading.hide();
                        Dialog.alert({
                            title: '取消预订提示',
                            message: '你已成功取消预订',
                        }).then(() => {
                            this.outTimeToReturn()
                        })
                    }).catch(() => {
                    _this.$showLoading.hide();
                    Dialog.alert({
                        title: '取消预订提示',
                        message: '订单取消失败',
                    })
                })
            }).catch(() => {

            });
        },
        showDetail() {
            this.detailShow = !this.detailShow
        },
        clickSiteNum(){
            this.$refs['aTrainTimeTable'].show();
        },
        getAllPrice() {
            const params = {
                orderNo: this.orderNos[0]
            };
            consumer_trains_order_getTrainsOrderDetail(params).then(res => {
                let priceInfo = res.datas;
                this.priceInfo = priceInfo;
                this.$refs['aTrainTimeTable'].init({
                    //出发站三字码
                    fromStationCode: this.priceInfo.fromStationCode,
                    //到达站三字码
                    toStationCode: this.priceInfo.toStationCode,
                    //车次号
                    trainCode: this.priceInfo.trainNo,
                    //日期(2020-05-18)
                    trainDate: this.priceInfo.startDate,
                })
            })
        },
        init() {
            this.businessType = 2;
            this.evectionNo = this.$route.query.evectionNo ? this.$route.query.evectionNo : '';
            this.orderNos = this.$route.query.orderNo ? [this.$route.query.orderNo] : '';
            this.flightOrderList = this.$route.query.flightOrderList ? this.$route.query.flightOrderList : '';
            this.orderType = Number(this.$route.query.orderType) || 4;
            this.pageType = Number(this.$route.query.pageType) || 2;
            this.paymentInit();
            this.getAllPrice()
        },

        paymentInit() {
            const __this = this;
            let orderForPayVos = [];
            this.orderNos.forEach(value => {
                const params = {
                    orderNo: value,
                    businessContent: ''
                };
                orderForPayVos.push(params)
            });
            const init_000 = function() {
                __this.$refs.aPaymentMethodSelector000.init({
                    page_this: __this,
                    mock_data: {
                        enable: false,
                        // enable: true,

                        // wait_seconds: 2,
                        wait_seconds: 0.5,

                        //000:只有支付宝
                        //100:只有授信支付
                        //200:同时有授信支付和支付宝
                        // test_case: `000`,
                        // test_case: `100`,
                        test_case: `200`,
                    },
                    //在这里传入订单详情的相关参数
                    get_params() {
                        const p = {
                            "orderForPayVos": orderForPayVos,
                            "payType":  __this.pageType !== 3 ? 1 : 2
                        };
                        console.log(__this.pageType);
                        console.log(p);
                        return Q.resolve(p);
                    },

                    //支付操作前的事件处理方法，常用于验证是否能够进行支付操作
                    before_pay_event_handler() {
                        // alert(`不能够支付！原因：XXX`);
                        // return Q.reject();
                        return Q.resolve();


                        //__this.$Toast.clear();
                        //console.log(`before_pay_event_handler`);
                        //return Q.resolve();
                    },

                    //用户取消支付的事件处理方法
                    user_cancel_pay_event_handler() {
                        Toast.clear();
                        // __this.$router.push({
                        //     name: "h5-no-payment",
                        //     query: __this.$route.query,
                        // });
                    },

                    //支付成功事件处理方法
                    pay_successful_event_handler() {
                        Toast.clear();
                        __this.$router.replace({
                            name: "h5-payment-success",
                            query: {
                                businessType: __this.businessType,
                                orderType: __this.orderType,
                                pageType: __this.pageType,
                                orderNo: __this.orderNos.length > 0 ? __this.orderNos[0] : ''
                            }
                        });
                        console.log(`pay_successful_event_handler`);
                    },
                    //支付失败事件处理方法
                    pay_failed_event_handler() {
                        Toast.clear();
                        console.log(`pay_failed_event_handler`);
                    },
                    //获取支付方式接口，返回code=300052时，弹窗的确定按钮单击处理处理方法
                    dialog_code_300052_btn_ok_click_event_handler() {
                        Toast.clear();
                        console.log(`dialog_code_300052_btn_ok_click_event_handler`);
                        __this.$router.replace({
                            name: `h5-office-evection`,
                        });
                        return Q.resolve(true);
                        // return true;
                    },
                });

                __this.$refs.aPaymentMethodSelector000.get_data().then(data => {
                    __this.outPayAmount = 0;
                    __this.payAmount = data.payAmount;
                    if (data.paymentChannels && data.paymentChannels.length > 1) {
                        let hasCompanyPay = false;
                        data.paymentChannels.forEach(value => {
                            if (value.code === 24) {
                                hasCompanyPay = true
                            }
                        });
                        if (hasCompanyPay) {
                            data.paymentChannels.forEach(value => {
                                if (value.code !== 24) {
                                    __this.outPayAmount = value.payAmount
                                }
                            })
                        }
                    }
                })
            };

            init_000();
        },

        close_current_page() {
            if (this.milliseconds > 0 || this.milliseconds === -1) {
                // 倒计时没结束或不用倒计时
                Dialog.confirm({
                    title: '温馨提示',
                    message: '您的订单尚未完成支付，确认要离开？',
                    confirmButtonText: '确认离开',
                    cancelButtonText: '继续支付',
                    confirmButtonColor: '#FF7002',
                }).then(() => {
                    // 去火车票订单详情页
                    this.$router.replace({
                        name: 'h5-train-order-detail',
                        query: {
                            orderNo: this.orderNos[0],
                            jumpType: 'list'
                        }
                    });
                }).catch(() => {
                    // on cancel
                });
            } else {
                // 超时
                // 13920 APP端酒店/飞机/火车票 支付界面优化 禅道bug
                this.outTimeToReturn()
            }

        },
        outTimeToReturn() {
            // 13920 APP端酒店/飞机/火车票 支付界面优化 禅道bug
            this.$router.replace({
                // name: 'h5-train-order-list',
                name: 'h5-train-home',
            });
        },
        payment() {
            this.$router.replace({
              name: "h5-travel-payment",
                query: {
                    orderNo: this.orderNos[0],
                    evectionNo: this.evectionNo,
                    flightOrderList: this.flightOrderList,
                    orderType: this.orderType,
                    businessType: this.businessType,
                }
            });
        },

        // 倒计时
        startCountdown() {
            if (this.countdownFun) {
                clearInterval(this.countdownFun);
            }
            this.countdownFun = setInterval(()=>{
                this.milliseconds--;
                if (this.milliseconds === 0) {
                    clearInterval(this.countdownFun);
                    // 弹出提示
                    this.showTimeEndDialog();
                }
            }, 1000)
        },
        showTimeEndDialog() {
            Dialog.alert({
                title: '温馨提示',
                message: '支付超时，请重新下单',
                confirmButtonText: '我知道了'
            }).then(() => {
                // on close
                // 13920 APP端酒店/飞机/火车票 支付界面优化 禅道bug
                this.outTimeToReturn();
            });
        }
    },
};
