import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import mock_data_helper from '@/page/demo/sample-data-list/1.0.1/lib/mock_data_helper';

export default {
    data() {
        return {
            entity: {
                id: ``,
                number: ``,
            },
        };
    },
    methods: {
        //base_layout_header，是否显示返回按钮
        __base_layout_header_get_btn_go_back_show() {
            return true;
        },

        //base_layout_header，返回按钮单击事件方法
        __base_layout_header_btn_go_back_click_event_handler() {
            const __this = this;
            __this.$toast(`自定义返回按钮的单击事件！`);
            __this.$router.replace({
                name: 'demo-sample-data-list',
            });
        },
    },
    mounted() {
        const __this = this;
        const id = __this.$route.query.id;
        if (!id) {
            const msg = `mounted parameter error, code=001`;
            console.error(msg);
            console.trace();
            return Q.reject({msg: msg});
        }

        const entity = mock_data_helper.get_entity({
            id,
        });
        if (!entity) {
            const msg = `mounted parameter error, code=002`;
            console.error(msg);
            console.trace();
            return Q.reject({msg: msg});
        }
        __this.entity = entity;

    },
};
