export default {
    data() {
        return {}
    },
    props: {
        title: {
            type: String,
            default: ''
        },
        reactBackFun: {
            type: Boolean,
            default: false
        },
        backShow: {
            type: Boolean,
            default: false
        },
        useBackBtnColor: {
            type: Boolean,
            default: false,
        }, // 是否使用传入的颜色
        backBtnColor: {
            type: String,
            default: '#666666'
        },
        backBtnName: {
            type: String,
            default: 'arrow-left'
        },
        userIconSize: {
            type: Boolean,
            default: false,
        }, // 是否使用传入的size
        iconSize: {
            type: [String, Number],
            default: 20
        },
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        backIconBtnColor() {
            let color = ''
            // 先判断是否使用传入的颜色
            if (this.useBackBtnColor) {
                color = this.backBtnColor
                return color
            }
            if (this.backBtnName === 'cross') {
                color = '#666666'
            } else {
                color = '#333333'
            }
            return color
        },
        backIconSize() {
            let size = ''
            // 先判断是否使用传入的颜色
            if (this.userIconSize) {
                size = this.userIconSize
                return size
            }
            if (this.backBtnName === 'cross') {
                size = 14
            } else {
                size = 24
            }
            return size
        },
    },
    filters: {},
    methods: {
        back() {
            if (!this.reactBackFun) {
                this.$router.go(-1)
            }
            this.$emit('handleBack')
        },
    }
}