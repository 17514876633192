import moment from "moment";
import consumer_evection_orderStatusList from '@/lib/data-service/haolv-default/consumer_evection_orderStatusList'
import evectionList from '@/lib/data-service/haolv-default/consumer_evection_evectionListChange.js'

export default {
  name:'MyBusiness',
  props:[
    "testOff",
  ],
  data(){
    return {
      zoneOff:false,
      form:{
        //选项选中的渲染数据
        approveTypeNew:[0],
      },
      //选项选中的保存数据
      approveTypeOld:[0],
      //创建时间
      isShowFoundCalendar: false,
      flightFoundStartDate: "", // 往返时的出发时间
      flightFoundEndDate: "", // 往返时的到达时间
      //出行时间
      isShowSetOutCalendar: false,
      flightSetOutStartDate: "", // 往返时的出发时间
      flightSetOutEndDate: "", // 往返时的到达时间
      applyTypeList:[],
      // applyTypeList: [
      //   { label: "全部", value: "" },
      //   { label: "待审核", value: "1" },
      //   { label: "待出行", value: "2" },
      //   { label: "出行中", value: "3" },
      //   { label: "已结束", value: "4" },
      // ],
    }
  },
  mounted(){
    console.log(this.approveTypeOld);
    this.GetGetMyJourneyList();
  },
  watch:{
    //监听父组件传回来的值，判断单击确定还是取消
    testOff:{
      immediate: true,
      handler(value) {
        if(value == true){
          if (this.form.approveTypeNew.length ==0) {
            this.form.approveTypeNew=[0];
          }

          this.form.flightFoundStartDate = this.flightFoundStartDate == '' ? '' : this.flightFoundStartDate + ' 00:00:00';
          this.form.flightFoundEndDate = this.flightFoundEndDate == '' ? '' : this.flightFoundEndDate + ' 23:59:59';
          this.form.flightSetOutStartDate = this.flightSetOutStartDate == '' ? '' : this.flightSetOutStartDate + ' 00:00:00';
          this.form.flightSetOutEndDate = this.flightSetOutEndDate == '' ? '' : this.flightSetOutEndDate + ' 23:59:59';

          this.$emit('clickFormNew',this.form);
          this.approveTypeOld = this.form.approveTypeNew;

          evectionList(this.form).then(res => {
            console.log(res);
            this.$emit('postData',res);
          })

        }else if(value == false) {
          this.form.approveTypeNew = this.approveTypeOld;

          this.flightFoundStartDate = [];
          this.flightFoundEndDate = [];
          this.flightSetOutStartDate = [];
          this.flightSetOutEndDate = [];
          //重置组件中的日期
          if (this.$refs.FoundDate) {
            this.$refs.FoundDate.reset();
            this.$refs.SetOutDate.reset();
          }
        }
      }
    },
  },
  methods:{
    //获取订单状态按钮数据的接口
    async GetGetMyJourneyList(){
      let test = await consumer_evection_orderStatusList();
      this.applyTypeList = test.datas;
    },
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
      if (index == 0) {
        this.form.approveTypeNew = [0]
      } else if (index != 0) {
        if (this.form.approveTypeNew.includes(0)) {
          this.approveTypeOld = [0];
          this.form.approveTypeNew.splice(this.form.approveTypeNew.indexOf(0));
        }
      }
    },
    showFoundCalendar() {
      this.isShowFoundCalendar = true;
    },
    onCalendarRangeConfirmFound(data) {
      this.isShowFoundCalendar = false;
      this.flightFoundStartDate = moment(data[0]).format("YYYY-MM-DD");
      this.flightFoundEndDate = moment(data[1]).format("YYYY-MM-DD");
    },
    showSetOutCalendar() {
      this.isShowSetOutCalendar = true;
    },
    onCalendarRangeConfirmSetOut(data) {
      this.isShowSetOutCalendar = false;
      this.flightSetOutStartDate = moment(data[0]).format("YYYY-MM-DD");
      this.flightSetOutEndDate = moment(data[1]).format("YYYY-MM-DD");
    },
  }
}
