import moment from "moment";
import NavBar from "@/component/nav-bar/import_latest_version_module";
import Calendar from '@/page/travel-book/flight/component/calendar/1.0.1/index.vue'
import ChangClause from '@/page/travel-book/flight/component/changClause/1.0.0/index.vue'
import CheckTravel from '@/page/travel-book/flight/component/checkTravel/import_latest_version_module'
import {Toast, Dialog} from 'vant'
import {awaitWrap} from '@/page/travel-book/flight/common/unit'
import VirtualList from 'vue-virtual-scroll-list'
import DomesticTicketAirportSelector from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/domestic-ticket-airport-selector/1.1.0/index.vue';
import consumer_flight_order_budgetOrder from '@/lib/data-service/haolv-default/consumer_flight_order_budgetOrder'
import FlightItem from '@/page/travel-book/flight/component/flightItem'
import consumer_flight_search_flightInfo from '@/lib/data-service/haolv-default/consumer_flight_search_flightInfo'
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
import consumer_web_policyConfig_getAllowChangeCities from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getAllowChangeCities'
import FlightListSkeleton from '@/component/skeleton/flight-list-skeleton/index.vue'
import ChangeEvectionCity from '@/component/change-evection-city/1.0.0/index.vue'

import getEvectionAllType from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_evection_logic_judge_option/1.0.0/index'
import getJourneyOptionsCanBeModified from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'


import consumer_flight_search_stipulates from '@/lib/common-service/consumer_flight_search_stipulates'
import consumer_air_ticket_flightLuggage from '@/lib/common-service/consumer_air_ticket_flightLuggage'

export default {
    data() {
        return {
            fromStationCode: '', // 出发站id
            toStationCode: '', // 到达站id
            startFlightParams: {}, // 出发站信息
            arriveFlightParams: {}, //到达站信息
            startDate: '', // 出发时间
            endDate: '', //返程时间
            flightType: 1, // 航程类型
            cabinRank: '', // 仓位登记 0=不限 1=头等舱 2=公务舱 3=经济舱
            airLineCode: '', // 航司二字码
            psgType: 1, // psgType
            takeOffInterval: '', //
            flightNo: '', // 航班

            orderNo: '', //订单编号
            evectionNo: '', // 出差单编号
            evectionType: 1, // 出差类型
            redirectType: '', // 返回类型
            evectionDetailType: '', // '001':因公出差带出差单, '002': 因公出差不带出差单, '101':因私出差
            journeyNo: '', // 订单号
            workTravelType: '',
            journeyType: '',

            startRangeDate: '', // 开始范围
            endRangeDate: '', // 结束范围

            flightList: [], // 机票列表
            oriFlightsList: [], // 原始机票列表
            filterFlightList: [], // 中间存储列表

            travelPopupShow: false,
            flightFromStationCode: '', // 搜搜条件-出发站
            flightToStationCode: '', // 搜索条件-到达站
            flightStartFlightParams: {}, // 搜索条件
            flightArriveFlightParams: {}, // 搜索条件
            flightStartDate: '', // 搜索条件-出发日期
            flightEndDate: '', // 搜索条件-返程日期

            ruleShow: false, // 退改签说明弹窗

            ruleText: {}, // 退改的文本
            luggage: '', // 行李文本
            luggageParams: {}, // 行李对象
            canJourney: true, // 防连点
            isShowCalendar: false, // 时间选择弹窗

            params: {}, //

            loadingShow: true,
            percent: 0,
            percentSetInterval: '',

            cabinPopupShow: false, // 舱位弹出框
            // cabin: 0, // 最终选中的舱位
            setCabin: 0, // 弹窗选中的舱位
            cabinList: [
                {
                    value: 0,
                    label: "不限"
                },
                {
                    value: 1,
                    label: "头等舱"
                },
                {
                    value: 2,
                    label: "公务舱"
                },
                {
                    value: 3,
                    label: "经济舱"
                }
            ],
            flightItemComponent: FlightItem,

            active: 0, // 当前tab
            orderField: 'depTime', // 排序字段，depTime-起飞时间 arrTime-到达时间。初始数据排列默认按起飞时间和到达时间从早到晚排序, 后续其他排序前端自行处理
            orderType: '', // 排序类型，asc-从小到大/从早到晚/从低到高 desc-从大到小/从晚到早/从高到低
            priceType: '', // 价格排序，同上

            screenShow: false, // 筛选框展示

            onlyLineFly: [], // 只看直飞，
            setOnlyLineFly: [],
            hideShareFly: [], // 隐藏共享航班
            setHideShareFly: [],

            takeOffTime: [
                /*{ value: "", label: "全部" },*/
                {value: "00:00-05:59", label: "00:00-05:59"},
                {value: "06:00-11:59", label: "06:00-11:59"},
                {value: "12:00-17:59", label: "12:00-17:59"},
                {value: "18:00-23:59", label: "18:00-23:59"}
            ],
            takeOffTimeParams: '', // 起飞时间段
            setTakeOffTimeParams: '',
            endTakeOffTimeParams: '', // 降落时间段
            setEndTakeOffTimeParams: '',

            depAirportList: [],
            depAirportParams: '', // 出发城市机场搜索关键词
            setDepAirportParams: '',
            arrAirportList: [],
            arrAirportParams: '',
            setArrAirportParams: '',
            planeSizeList: [
                {value: '', label: '所有机型'},
                {value: 1, label: '大型机'},
                {value: 2, label: '中型机'},
                {value: 3, label: '其他型机'},
            ],
            planeSizeParams: '',
            setPlaneSizeParams: '',
            classTypeList: [
                //  舱位列表
                {
                    value: '',
                    label: "所有舱位"
                },
                {
                    value: '1,2',
                    label: "头等/商务舱"
                },
                /*{
                    value: 2,
                    label: "公务舱"
                },*/
                {
                    value: '3',
                    label: "经济舱"
                }
            ],
            cabinParams: '', // 舱位筛选
            setCabinParams: '',
            airlinesList: [],
            airlinesParams: '',
            setAirlinesParams: '',

            ruleLoading: false,
            luggageLoading: false,

            dialogErrorVisible: false,
            dialogError2Visible: false,
            errorText: '',
            returnSecond: 3,
            returnSetInterval: '',
            evectionRequired: 0,
            bookUpperBound: 6,
            unLimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            changeEvectionCity: 0, // 是否可选临近城市 0:否, 1:是

            searchTemp: '',
            intervalNum: 0,

            cityCanEdit: true,
            timeCanEdit: true,

            formSelectedIdList: [],
            toSelectedIdList: [],
            journeyDetail: {},
        }
    },
    components: {
        NavBar,
        Calendar,
        ChangClause,
        CheckTravel,
        DomesticTicketAirportSelector,
        'virtual-list': VirtualList,
        FlightListSkeleton,
        ChangeEvectionCity
        //FlightItem
    },
    created() {
        this.evectionRequired = parseInt(this.$route.query.evectionRequired)
        this.fromStationCode = this.$route.query.fromStationCode || 'CAN'
        this.toStationCode = this.$route.query.toStationCode || 'BJS'
        this.startFlightParams.name = this.$route.query.fromStationName || '广州'
        this.arriveFlightParams.name = this.$route.query.toStationName || '北京'
        this.startDate = this.$route.query.startDate ? this.$route.query.startDate : moment().locale('zh-cn').format('YYYY-MM-DD')
        this.endDate = this.$route.query.endDate
        this.flightType = parseInt(this.$route.query.flightType) || 1
        this.evectionNo = this.$route.query.evectionNo
        this.evectionType = parseInt(this.$route.query.evectionType)
        this.redirectType = this.$route.query.redirectType
        this.workTravelType = parseInt(this.$route.query.workTravelType)
        this.cabinParams = this.$route.query.cabin ? this.$route.query.cabin : ''
        this.startRangeDate = this.$route.query.startRangeDate // 开始范围
        this.endRangeDate = this.$route.query.endRangeDate // 结束范围
        this.journeyType = this.$route.query.journeyType ? this.$route.query.journeyType : '' // 0 普通单， 1 不限行程单
        if (this.redirectType === '002') {
            this.orderNo = this.$route.query.orderNo
        }
    },
    mounted() {
        const __this = this;
        this.$refs.arriveAirportSelector.init({
            input: {
                placeholder: '目的城市(如北京/beijing/bj)'
            }
        })
        let evectionParams = {
            vm: this
        }
        const evectionQuery = getEvectionAllType(evectionParams)
        this.evectionDetailType = evectionQuery.evectionDetailType
    },
    async activated() {
        this.intervalNum = 0
        this.canChangeFactor()
        await this.refundChangePolicyGet()
        this.search()
        this.setSearchReact()

        if (this.changeEvectionCity === 1 && parseInt(this.$route.query.journeyId)) {
            this.getAllowChangeCity()
        }
    },
    deactivated() {
        clearInterval(this.searchTemp)
        clearInterval(this.percentSetInterval)
    },
    destroyed() {
        clearInterval(this.percentSetInterval)
        clearInterval(this.searchTemp)
    },
    watch: {
        startDate(newVal) {
            // this.search()
        },
        flightFromStationCode(newVal) {
            this.$nextTick(() => {
                this.flightStartFlightParams = this.$refs.startAirportSelector.get_selected_entity()
            })
        },
        flightToStationCode(newVal) {
            this.$nextTick(() => {
                this.flightArriveFlightParams = this.$refs.arriveAirportSelector.get_selected_entity()
            })
        },
    },
    computed: {},
    filters: {
        filterFlightDate(val) {
            return val === '' ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterFlightDaY(val) {
            return val === '' ? '' : moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day()
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        filterCabinRank(val) {
            if (val === 1) {
                return '头等舱'
            } else if (val === 2) {
                return '公务舱'
            } else if (val === 3) {
                return '经济舱'
            } else {
                return '不限'
            }
        },
        filterPercent(val) {
            if (val < 16) {
                return '航班信息准备中...'
            } else if (val >= 16 && val < 36) {
                return '机票舱位准备中...'
            } else if (val >= 36 && val < 56) {
                return '机票坐席准备中...'
            } else if (val >= 56 && val < 80) {
                return '机票折扣准备中...'
            } else if (val >= 80 && val < 100) {
                return '低价机票准备中...'
            } else {
                return ''
            }
        },
        filterOrderType(val) {
            if (val === 'asc') {
                return '早-晚'
            } else if (val === 'desc') {
                return '晚-早'
            } else {
                return ''
            }
        },
        filterPriceType(val) {
            if (val === 'asc') {
                return '低-高'
            } else if (val === 'desc') {
                return '高-低'
            } else {
                return ''
            }
        },
    },
    methods: {
        async refundChangePolicyGet() {
            let res = await refund_change_policy_get()
            this.evectionRequired = res.datas.evectionRequired
            this.bookUpperBound = res.datas.bookUpperBound
            this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime
            this.unLimitedJourney = res.datas.unLimitedJourney;
            this.changeEvectionCity = res.datas.changeEvectionCity
        },
        getAllowChangeCity() {
            let _this = this
            consumer_web_policyConfig_getAllowChangeCities({journeyId: parseInt(this.$route.query.journeyId)}).then(res => {
                this.journeyDetail = res.datas
                console.log('journeyDetail', this.journeyDetail)
                if (this.journeyDetail.departCityChange === 1) {
                    this.formSelectedIdList = [this.fromStationCode]
                    this.$nextTick(() => {
                        this.$refs.formChangeEvectionCity.init({
                            dialog: {
                                title: `出发城市`,
                            },
                            get_data_source() {
                                return new Promise((resolve, reject) => {
                                    let list = _this.journeyDetail.departSelectCities
                                    resolve({all_entity_list: list})
                                })
                            },
                            btn_ok_click_event_handler(res) {
                                console.log('this.selectedIdList', res, _this.formSelectedIdList)
                                let list = res.selected_entity_list
                                _this.flightFromStationCode = list[0].cityCode
                                _this.flightStartFlightParams = {
                                    name: list[0].cityName,
                                    code: list[0].cityCode
                                }
                            },
                        });
                    })
                }
                if (this.journeyDetail.toCityChange === 1) {
                    this.toSelectedIdList = [this.toStationCode]
                    this.$nextTick(() => {
                        this.$refs.toChangeEvectionCity.init({
                            dialog: {
                                title: `到达城市`,
                            },
                            get_data_source() {
                                return new Promise((resolve, reject) => {
                                    let list = _this.journeyDetail.toSelectCities
                                    resolve({all_entity_list: list})
                                })
                            },
                            btn_ok_click_event_handler(res) {
                                console.log('this.selectedIdList', res, _this.toSelectedIdList)
                                let list = res.selected_entity_list
                                _this.flightToStationCode = list[0].cityCode
                                _this.flightArriveFlightParams = {
                                    name: list[0].cityName,
                                    code: list[0].cityCode
                                }
                            },
                        });
                    })
                }
            })
        },
        canChangeFactor() {
            const __this = this
            getJourneyOptionsCanBeModified({
                situation: '000',
                vm: __this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit
                this.timeCanEdit = res.timeCanEdit
            })
        },
        // 判断机场字段的长短返回样式
        inputFontLong(val) {
            if (val && (val.length > 0 && val.length < 3)) {
                return ''
            } else {
                return 'small'
            }
        },
        // 关闭搜索下拉框
        closeTravelPopup() {
            this.travelPopupShow = false
        },
        // 返回
        back() {
            // this.$router.go(-1)
            this.$router.replace({
                name: 'h5-flight-home',
            })
        },
        // 打开搜索下拉框
        showSearchBox() {
            this.travelPopupShow = true
        },
        // 打开完搜索下拉框后触发
        openSearchBox() {
            this.flightFromStationCode = this.fromStationCode
            this.flightToStationCode = this.toStationCode
            this.flightStartDate = this.startDate
        },
        // 选择时间
        showCalendar() {
            if (!this.timeCanEdit) {
                return
            }
            this.isShowCalendar = true
        },
        // 确认选择时间
        onCalendarConfirm(val) {
            this.flightStartDate = moment(val).format('YYYY-MM-DD')
            this.isShowCalendar = false
        },
        // 打开出发站选择弹框
        openStartFlightPopup() {
            if (!this.cityCanEdit && this.journeyDetail.departCityChange !== 1) {
                return
            }
            if (this.journeyDetail.departCityChange === 1) {
                this.$refs.formChangeEvectionCity.show()
            } else {
                this.$refs.startAirportSelector.show()
            }

        },
        // 打开到达站选择弹框
        openArriveFlightPopup() {
            if (!this.cityCanEdit && this.journeyDetail.toCityChange !== 1) {
                return
            }
            if (this.journeyDetail.toCityChange === 1) {
                this.$refs.toChangeEvectionCity.show()
            } else {
                this.$refs.arriveAirportSelector.show()
            }
        },
        toSearch() {
            this.travelPopupShow = false
            this.fromStationCode = this.flightFromStationCode
            this.toStationCode = this.flightToStationCode
            this.startFlightParams = JSON.parse(JSON.stringify(this.flightStartFlightParams))
            this.arriveFlightParams = JSON.parse(JSON.stringify(this.flightArriveFlightParams))
            this.startDate = this.flightStartDate
            this.search()
        },
        clickDate(val) {
            this.search()
        },
        search() {
            if (this.fromStationCode === '') {
                Toast({
                    message: '请选择出发地',
                    position: 'bottom',
                });
                this.loadingShow = false
                return
            }
            if (this.toStationCode === '') {
                Toast({
                    message: '请选择目的地',
                    position: 'bottom',
                });
                this.loadingShow = false
                return
            }
            if (this.startDate === null || this.startDate === '') {
                Toast({
                    message: '请选择日期',
                    position: 'bottom',
                });
                this.loadingShow = false
                return
            }
            let params = {
                // 航程类型
                flightType: this.flightType,
                needPnr: 0,
                // 出发城市三字码。
                depCityPortCode: this.fromStationCode,
                // 到达城市三字码。
                arrCityPortCode: this.toStationCode,
                // 出发日期
                depDate: this.startDate,
                // 仓位等级 0=不限 1=头等舱 2=公务舱 3=经济舱
                cabinRank: this.cabinRank,
                // 航空公司二字码
                airLineCode: '',
                // psgType
                psgType: this.psgType,
                //  是否直飞
                directFlight: 0,
                // 折扣
                discount: 0,
                orderField: "",
                takeOffInterval: '',
                flightNo: '',

                orderType: '',
                planeSize: '',
            };
            let data = {
                companyCode: '',
                rqData: Object.assign({}, params),
                sign: '',
                timeStamp: '',
                ignoreKeyAccountCompany: this.evectionType === 1 ? 1 : '',
                // applyId: this.$route.query.applyId ? parseInt(this.$route.query.applyId) : '',
                evectionNo: this.evectionNo,
            }
            /*Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            })*/
            //this.percent = 0
            this.loadingShow = true
            // 替换地址栏参数
            let urlParams = {
                fromStationCode: this.fromStationCode,
                fromStationName: this.startFlightParams.name,
                toStationCode: this.toStationCode,
                toStationName: this.arriveFlightParams.name,
                startDate: this.startDate,
                endDate: '',
                flightType: this.flightType,
                evectionNo: this.evectionNo,
                redirectType: "000",
                evectionType: this.evectionType, // 1是个人出差，2是出差单出差
                cabin: this.$route.query.cabin ? this.$route.query.cabin : '',
                evectionRequired: this.evectionRequired,
                workTravelType: this.workTravelType, // 因公出差的类型， 1是有选出差单，2是没有
                applyId: this.$route.query.applyId,
                startRangeDate: this.startRangeDate,
                endRangeDate: this.endRangeDate,
                journeyId: this.$route.query.journeyId ? parseInt(this.$route.query.journeyId) : 0,
                journeyType: this.$route.query.journeyType !== undefined ? parseInt(this.$route.query.journeyType) : '',
            }
            // 组装当前页面url
            let href = location.href.split('?')[0]
            let newUrl = href + '?'
            for(let i in urlParams) {
                newUrl += `${i}=${urlParams[i]}&`
            }
            history.replaceState(null, null, newUrl)

            /*this.percentSetInterval = setInterval(() => {
                if (this.percent < 95) {
                    this.percent++
                }
                if (this.percent === 100) {
                    this.loadingShow = false
                    clearInterval(this.percentSetInterval)
                }
            }, 100)*/
            consumer_flight_search_flightInfo(data).then(res => {
                // this.percent = 100
                // this.loadingShow = false
                // clearInterval(this.percentSetInterval)
                if (!res.rsData) {
                    this.loadingShow = false
                    return
                }
                let flightList = res.rsData
                let airlinesCodeList = [] // 航司Code合集
                let airlinesList = [] // 航司合集
                let depAirportCodeList = [] // 起飞机场的code合集
                let depAirportList = [] // 起飞机场的合集
                let arrAirportCodeList = [] // 降落机场的code合集
                let arrAirportList = [] // 降落机场的合集
                flightList.forEach((value, index) => {
                    value.show = 0
                    value.price = 0
                    value.discount = 0
                    value.productList = []
                    value.cabin = ''
                    value.loading = false
                    if (index === 0) {
                        depAirportList.push({value: '', label: `${value.depCityName}所有机场`})
                        arrAirportList.push({value: '', label: `${value.arrCityName}所有机场`})
                        airlinesList.push({
                            value: '',
                            label: '所有航空公司',
                            logo: ''
                        })
                    }

                    if (airlinesCodeList.indexOf(value.airCompanyCode) === -1) {
                        airlinesCodeList.push(value.airCompanyCode)
                        airlinesList.push({
                            value: value.airCompanyCode,
                            label: value.airCompanyName,
                            logo: value.airCompanyLogoImg
                        })
                    }
                    if (depAirportCodeList.indexOf(value.depAirportCode) === -1) {
                        depAirportCodeList.push(value.depAirportCode)
                        depAirportList.push({
                            value: value.depAirportCode,
                            label: value.depAirportName
                        })
                    }
                    if (arrAirportCodeList.indexOf(value.arrAirportCode) === -1) {
                        arrAirportCodeList.push(value.arrAirportCode)
                        arrAirportList.push({
                            value: value.arrAirportCode,
                            label: value.arrAirportName
                        })
                    }
                })
                // this.flightList = flightList
                this.airlinesList = airlinesList
                this.depAirportList = depAirportList
                this.arrAirportList = arrAirportList
                this.oriFlightsList = flightList
                this.trainFilter()
                // Toast.clear()
            }).catch(() => {
                this.loadingShow = false
                //clearInterval(this.percentSetInterval)
                this.flightList = []
                // Toast.clear()
            })
        },
        trainFilter() {
            let oriFlightsList = JSON.parse(JSON.stringify(this.oriFlightsList))
            // 是否只看直飞
            if (this.onlyLineFly.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.stopNum === 0) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            // 是否隐藏共享航班
            if (this.hideShareFly.length > 0) {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (!value.hasShare) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            if (this.airlinesParams !== '') {
                // this.airlinesParams.value
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.airCompanyCode === this.airlinesParams) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            if (this.cabinParams !== '') {
                let flightItemList = []
                let cabinParamsArr = this.cabinParams.split(',')
                oriFlightsList.forEach(value => {
                    let policys = []
                    if (value.productList.length > 0) {
                        value.productList.forEach(value1 => {
                            let product = []
                            value1.productList.forEach(value2 => {
                                if (cabinParamsArr.indexOf(value2.cabin[0].cabinRank.toString()) >= 0) {
                                    product.push(value2)
                                }
                            })
                            value1.productList = product
                            if (product.length > 0) {
                                policys.push(value1)
                            }
                        })
                        value.productList = policys
                        if (policys.length > 0) {
                            flightItemList.push(value)
                        }
                    } else {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.takeOffTimeParams !== '') {
                let flightItemList = []
                // let takeOffTimeArr = this.takeOffTimeParams.value.split('-')
                let takeOffTimeArr = this.takeOffTimeParams.split('-')
                let leftTime = this.timeToMinute(takeOffTimeArr[0])
                let rightTime = this.timeToMinute(takeOffTimeArr[1])
                oriFlightsList.forEach(value => {
                    let depTime = this.timeToMinute(value.depTime)
                    if (depTime >= leftTime && depTime <= rightTime) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.planeSizeParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.planeSize === this.planeSizeParams.value) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.depAirportParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.depAirportCode === this.depAirportParams) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }

            if (this.arrAirportParams !== '') {
                let flightItemList = []
                oriFlightsList.forEach(value => {
                    if (value.arrAirportCode === this.arrAirportParams) {
                        flightItemList.push(value)
                    }
                })
                oriFlightsList = flightItemList
            }
            this.filterFlightList = oriFlightsList
            this.sortList()
        },
        timeToMinute(val) {
            let timeVal = val.split(':')
            let hour = parseInt(timeVal[0]) * 60
            let minute = parseInt(timeVal[1])
            return hour + minute
        },
        sortList() {
            let flightList = JSON.parse(JSON.stringify(this.filterFlightList))

            let timeToMinute = function (val) {
                let timeVal = val.split(':')
                let hour = parseInt(timeVal[0]) * 60
                let minute = parseInt(timeVal[1])
                return hour + minute
            }
            // 按出发时间正序排列
            let depTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按出发时间反序排列
            let depTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.depTime)
                let yFromTime = timeToMinute(y.depTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按到达时间正序排列
            let arrTimeCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return -1;
                } else if (xFromTime > yFromTime) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按到达时间反序排列
            let arrTimeReverseCompare = function (x, y) {
                let xFromTime = timeToMinute(x.arrTime)
                let yFromTime = timeToMinute(y.arrTime)
                if (xFromTime < yFromTime) {
                    return 1;
                } else if (xFromTime > yFromTime) {
                    return -1;
                } else {
                    return 0;
                }
            }
            // 按价格正序排列
            let priceCompare = function (x, y) {
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return -1;
                } else if (xPrice > yPrice) {
                    return 1;
                } else {
                    return 0;
                }
            }
            // 按价格反序排序
            let priceReverseCompare = function (x, y) {
                let xPrice = x.basePrice
                let yPrice = y.basePrice
                if (xPrice < yPrice) {
                    return 1;
                } else if (xPrice > yPrice) {
                    return -1;
                } else {
                    return 0;
                }
            }

            if (this.orderField === 'depTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(depTimeCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(depTimeReverseCompare)
                }
            } else if (this.orderField === 'arrTime') {
                if (this.orderType === 'asc') {
                    flightList.sort(arrTimeCompare)
                } else if (this.orderType === 'desc') {
                    flightList.sort(arrTimeReverseCompare)
                }
            } else if (this.orderField === 'price') {
                if (this.priceType === 'asc') {
                    flightList.sort(priceCompare)
                } else if (this.priceType === 'desc') {
                    flightList.sort(priceReverseCompare)
                }
            }
            if (flightList.length === 0) {
                this.loadingShow = false
                this.flightList = flightList
                return
            }
            // 默认展开第一个
            /*let val = flightList[0]
            val.show++
            //this.flightList[index] = val
            val.loading = true
            let params = {
                "rqData": {
                    "arrAirportCode": val.arrAirportCode,
                    "businessExt": val.businessExt,
                    "deptAirportCode": val.depAirportCode,
                    "deptDate": val.depDate,
                    "deptTime": val.depTime,
                    "flightNo": val.flightNo,
                    "psgType": this.psgType,
                    "shareFlightNo": val.sharedFlightNo,
                },
                depCityPortCode: val.depCityCode,
                arrCityPortCode: val.arrCityCode,
                basePrice: val.basePrice,
                ignoreKeyAccountCompany: this.evectionType === 1 ? 1 : '',
                applyId: this.$route.query.applyId ? parseInt(this.$route.query.applyId) : '',
                evectionNo: this.evectionNo,
                accord: val.accord
            }
            consumer_flight_search_flightPrice(params).then(res => {
                let productList = []
                let data = res.rsData
                if (data.flightPolicyList && data.flightPolicyList.length > 0) {
                    if (this.cabinParams !== '') {
                        let flightPolicyList = []
                        let cabinParamsArr = this.cabinParams.split(',')
                        data.flightPolicyList.forEach(value => {
                            if (cabinParamsArr.indexOf(value.cabin[0].cabinRank.toString()) >= 0) {
                                flightPolicyList.push(value)
                            }
                        })
                        if (flightPolicyList.length > 0) {
                            productList.push(flightPolicyList)
                        }
                    } else {
                        productList.push(data.flightPolicyList)
                    }
                }
                if (data.policys && data.policys.length > 0) {
                    if (this.cabinParams !== '') {
                        let policys = []
                        let cabinParamsArr = this.cabinParams.split(',')
                        data.policys.forEach(value => {
                            if (cabinParamsArr.indexOf(value.cabin[0].cabinRank.toString()) >= 0) {
                                policys.push(value)
                            }
                        })
                        if (policys.length > 0) {
                            productList.push(policys)
                        }
                    } else {
                        productList.push(data.policys)
                    }
                }
                val.loading = false
                val.policys = [...data.flightPolicyList, ...data.policys]
                val.productList = productList
                flightList[0] = val
            }, error => {
                val.loading = false
                this.loadingShow = false
                this.flightList = flightList
                let errorCode = error.data.rsCode;
                this.errorText = error.data.rsMessage || error.data.msg;
                if (errorCode === '090002') {
                    this.show = true
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.$refs.tips.close()
                            this.search()
                            clearInterval(this.returnSetInterval)
                        }
                    }, 1000)
                } else {
                    Dialog.alert({
                        title: '温馨提示',
                        message: this.errorText,
                    }).then(() => {
                        this.search()
                    })
                }
            }).catch(() => {
                val.loading = false
                this.loadingShow = false
                this.flightList = flightList
            })*/
            this.loadingShow = false
            this.flightList = flightList
            /*
            let [error, res] = await awaitWrap(consumer_flight_search_flightPrice(params))
            if (error) {
                val.loading = false
                this.loadingShow = false
                this.flightList = flightList
                let errorCode = error.data.rsCode;
                this.errorText = error.data.rsMessage || error.data.msg;
                if (errorCode === '090002') {
                    this.show = true
                    this.returnSecond = 3;
                    this.returnSetInterval = setInterval(() => {
                        this.returnSecond--;
                        if (this.returnSecond <= 0) {
                            this.$refs.tips.close()
                            this.search()
                            clearInterval(this.returnSetInterval)
                        }
                    }, 1000)
                } else {
                    Dialog.alert({
                        title: '温馨提示',
                        message: this.errorText,
                    }).then(() => {
                        this.search()
                    })
                }
            }
            let productList = []
            let data = res.rsData
            if (data.flightPolicyList && data.flightPolicyList.length > 0) {
                if (this.cabinParams !== '') {
                    let flightPolicyList = []
                    let cabinParamsArr = this.cabinParams.split(',')
                    data.flightPolicyList.forEach(value => {
                        if (cabinParamsArr.indexOf(value.cabin[0].cabinRank.toString()) >= 0) {
                            flightPolicyList.push(value)
                        }
                    })
                    if (flightPolicyList.length > 0) {
                        productList.push(flightPolicyList)
                    }
                } else {
                    productList.push(data.flightPolicyList)
                }
            }
            if (data.policys && data.policys.length > 0) {
                if (this.cabinParams !== '') {
                    let policys = []
                    let cabinParamsArr = this.cabinParams.split(',')
                    data.policys.forEach(value => {
                        if (cabinParamsArr.indexOf(value.cabin[0].cabinRank.toString()) >= 0) {
                            policys.push(value)
                        }
                    })
                    if (policys.length > 0) {
                        productList.push(policys)
                    }
                } else {
                    productList.push(data.policys)
                }
            }
            val.loading = false
            val.policys = [...data.flightPolicyList, ...data.policys]
            val.productList = productList
            flightList[0] = val

            this.loadingShow = false
            this.flightList = flightList
             */
        },
        showMore(val, index) {
            val.show = !val.show
            this.flightList[index] = val
        },
        getPrice(val) {
            if (val) {
                return val.toFixed(2);
            } else {
                return 0;
            }
        },
        // 返回退改规则
        backRule(val, child) {
            this.ruleShow = true
            this.ruleLoading = true
            this.luggageLoading = true
            this.ruleText = {}
            this.luggage = ''
            this.luggageParams = {}
            let data = {
                rqData: {
                    arrCityPortCode: val.arrCityCode,
                    cabinCode: child.cabin[0].cabinCode,
                    depCityPortCode: val.depCityCode,
                    depDate: val.depDate,
                    flightNo: val.flightNo,
                    flightProductType: child.flightProductType,
                    flightType: this.flightType,
                    psgType: this.psgType,
                    policyId: child.policyId,
                    extData: child.extData,
                    businessExt: child.businessExt,
                }
            }
            consumer_flight_search_stipulates(data).then(res => {
                let refRule = res.datas.rsData.refRule;
                let chaRule = res.datas.rsData.chaRule;
                if (refRule && chaRule) {
                    refRule = refRule.rules
                    chaRule = chaRule.rules
                    refRule.map(item => (item.type = "refRule"));
                    chaRule.map(item => (item.type = "chaRule"));
                    refRule.sort((a, b) => {
                        return a.serial - b.serial
                    })
                    chaRule.sort((a, b) => {
                        return a.serial - b.serial
                    })
                    // this.ruleText = [refRule, chaRule];
                    this.ruleText = {refRule, chaRule}
                }
                // this.luggage = res.datas.rsData.luggage
            }).finally(() => {
                this.ruleLoading = false
            })

            consumer_air_ticket_flightLuggage(data).then(res => {
                if (res.datas && res.datas.rsData) {
                    let rsData = res.datas.rsData
                    this.luggageParams = {
                        flyFree: rsData.flyFree,
                        freeWithYou: rsData.freeWithYou,
                        rests: rsData.rests,
                    }
                }
            }).finally(() => {
                this.luggageLoading = false
            })
        },
        // 关闭退改规则弹窗
        closeRuleShow() {
            this.ruleShow = false
        },
        submit(flight, policys) {
            if (!this.canJourney) {
                return
            }
            this.canJourney = false
            Toast.loading({
                message: '',
                forbidClick: true,
                duration: 0
            })
            let item = {...flight};
            let formData = {
                journeyId: this.$route.query.journeyId ? parseInt(this.$route.query.journeyId) : 0,
                rqData: {
                    evectionNo: this.evectionNo,
                    journeyNo: this.orderNo,
                    cabinRank: policys.cabin[0].cabinRank,
                    directFlight: 0,
                    flightType: this.flightType,
                    psgType: this.psgType,
                    segmentList: [],
                    evectionType: this.evectionType
                }
            }
            let segment = {
                "airportTax": policys.priceDatas[0].airportTax,
                "billingFee": policys.priceDatas[0].billingFee,
                "cabinName": policys.cabin[0].cabinName,
                "cabinCode": policys.cabin[0].cabinCode,
                "cabinRank": policys.cabin[0].cabinRank,
                "chdCabinCode": policys.cabin[0].chdCabinCode,
                "comment": policys.comment,
                "crewType": policys.priceDatas[0].crewType,
                "discount": policys.cabin[0].discount,
                "fuelTax": policys.priceDatas[0].fuelTax,
                "policyId": policys.policyId,
                "price": policys.priceDatas[0].price,
                "priceTaxes": policys.priceDatas[0].priceTaxes,
                "productType": policys.productType,
                "segmentType": 1,//去返程
                "settlement": policys.priceDatas[0].settlement,
                "settlementPriceTaxes": policys.priceDatas[0].settlementPriceTaxes,
                "vtWorkTime": policys.vtWorkTime,
                "workTime": policys.workTime,
                "flightProductType": policys.flightProductType,
                businessExt: policys.businessExt,

                "airCompanyCode": item.airCompanyCode,
                "airCompanyName": item.airCompanyName,
                "arrAirportCode": item.arrAirportCode,
                "arrAirportName": item.arrAirportName,
                "arrCityCode": item.arrCityCode,
                "arrCityName": item.arrCityName,
                "arrDate": item.arrDate,
                "arrTerminal": item.arrTerminal,
                "arrTime": item.arrTime,
                "chaRule": item.chaRule,
                "depAirportCode": item.depAirportCode,
                "depAirportName": item.depAirportName,
                "depCityCode": item.depCityCode,
                "depCityName": item.depCityName,
                "depDate": item.depDate,
                "depTime": item.depTime,
                "depTerminal": item.depTerminal,
                "directFlight": item.directFlight,
                "duration": item.duration,
                "flightNo": item.flightNo,
                "planeType": item.planeType,
                "refRule": item.refRule,
                "stopNum": item.stopNum,
                hasShare: item.hasShare,
                sharedFlightNo: item.sharedFlightNo,
                sharedAirCompanyCode: item.sharedAirCompanyCode,
                isShareText: `实际乘坐航班：${item.sharedAirCompanyName}${item.sharedFlightNo}请按实际乘坐航班办理值机。`,

                policyType: policys.policyType,
                isShare: item.hasShare ? 1 : 0, // 是否共享航班 1-是 0-否"
            }
            formData.rqData.segmentList.push(Object.assign({}, segment))
            this.params = formData
            if (this.evectionType === 2) {
                // 出差单出差情况进行审核
                let name = `${flight.flightNo} ${policys.cabin[0].cabinName}`
                Toast.clear()

                this.$refs.checkTravelBox.check({
                    businessType: 1,
                    businessParams: {
                        evectionNo: this.evectionNo,
                        policyList: [policys],
                        deptDate: item.depDate,
                    }
                }).then(res => {
                    Toast.loading({
                        message: '',
                        forbidClick: true,
                        duration: 0
                    })
                    this.params.rqData.overproofApplyList = res.policies
                    consumer_flight_order_budgetOrder(this.params).then(res => {
                        Toast.clear()
                        this.canJourney = true
                        this.$router.push({
                            name: 'flight-reserve',
                            query: {
                                orderNo: res.datas.rsData.orderNo,
                                evectionNo: res.datas.rsData.evectionNo,
                                redirectType: '001',
                                evectionType: this.evectionType
                            }
                        })
                    }, error => {
                        Toast.clear()
                        this.canJourney = true
                        // 根据code返回弹窗信息
                        this.setErrorDialog(error.data)
                    }).catch(() => {
                        this.canJourney = true
                        Toast.clear()
                    })
                }).catch(error => {
                    this.canJourney = true
                })
            } else {
                this.setOrder()
            }
        },
        setOrder() {
            // 提交
            consumer_flight_order_budgetOrder(this.params).then(res => {
                Toast.clear()
                this.canJourney = true
                // Toast.success('预定成功')
                this.$router.push({
                    name: 'order-reserve',
                    query: {
                        evectionNo: res.datas.rsData.evectionNo,
                        redirectType: '001',
                        evectionType: this.evectionType
                    }
                })
            }, error => {
                // 根据code返回弹窗信息
                this.setErrorDialog(error.data)
            }).catch(() => {
                Toast.clear()
                this.canJourney = true
            })
        },
        setErrorDialog(error) {
            this.errorText = error.msg
            if (error.code === '200028') {
                this.dialogErrorVisible = true;
                this.returnSecond = 3;
                this.returnSetInterval = setInterval(() => {
                    this.returnSecond--;
                    if (this.returnSecond <= 0) {
                        this.search()
                        clearInterval(this.returnSetInterval)
                        this.dialogErrorVisible = false
                    }
                }, 1000)
            } else {
                this.dialogError2Visible = true
            }
        },
        showCabinPopup() {
            this.cabinPopupShow = true
            this.setCabin = this.cabinRank
        },
        cancelCabinToClose() {
            this.cabinPopupShow = false
            this.setCabin = this.cabinRank
        },
        submitCabinToClose() {
            this.cabinPopupShow = false
            this.cabinRank = this.setCabin
        },

        toChangeTimeSort() {
            this.priceType = ''
            this.orderField = 'depTime'
            let orderType = this.orderType
            if (orderType === '') {
                this.orderType = 'asc'
            } else if (orderType === 'asc') {
                this.orderType = 'desc'
            } else {
                this.orderType = ''
            }
            this.sortList()
        },
        toChangePriceSort() {
            this.orderType = ''
            this.orderField = 'price'
            let priceType = this.priceType
            if (priceType === '') {
                this.priceType = 'asc'
            } else if (priceType === 'asc') {
                this.priceType = 'desc'
            } else {
                this.priceType = ''
            }
            this.sortList()
        },
        toShowFilter() {
            this.setOnlyLineFly = JSON.parse(JSON.stringify(this.onlyLineFly))
            this.setHideShareFly = JSON.parse(JSON.stringify(this.hideShareFly))
            this.setTakeOffTimeParams = this.takeOffTimeParams
            this.setEndTakeOffTimeParams = this.endTakeOffTimeParams
            this.setDepAirportParams = this.depAirportParams
            this.setArrAirportParams = this.arrAirportParams
            this.setPlaneSizeParams = this.planeSizeParams
            this.setCabinParams = this.cabinParams
            this.setAirlinesParams = this.airlinesParams
            this.screenShow = true
        },
        toggleOnlyLineFly() {
            this.$refs.onlyLineFlyCheckbox.toggle()
        },
        toggleHideShareFly() {
            this.$refs.hideShareFlyCheckbox.toggle()
        },
        /*toggleDepAirport(index) {
            this.$refs.depAirportCheckbox[index].toggle()
        },
        toggleArrAirport() {
            this.$refs.arrAirportCheckbox[index].toggle()
        },*/
        clearAllCommandToSearch() {
            this.setOnlyLineFly = []
            this.setHideShareFly = []
            this.setTakeOffTimeParams = ''
            this.setEndTakeOffTimeParams = ''
            this.setDepAirportParams = ''
            this.setArrAirportParams = ''
            this.setPlaneSizeParams = ''
            this.setCabinParams = ''
            this.setAirlinesParams = ''
            // this.trainFilter()
        },
        submitToFilter() {
            this.onlyLineFly = JSON.parse(JSON.stringify(this.setOnlyLineFly))
            this.hideShareFly = JSON.parse(JSON.stringify(this.setHideShareFly))
            this.takeOffTimeParams = this.setTakeOffTimeParams
            this.endTakeOffTimeParams = this.setEndTakeOffTimeParams
            this.depAirportParams = this.setDepAirportParams
            this.arrAirportParams = this.setArrAirportParams
            this.planeSizeParams = this.setPlaneSizeParams
            this.cabinParams = this.setCabinParams
            this.airlinesParams = this.setAirlinesParams
            this.screenShow = false
            this.trainFilter()
        },
        formatterCalendar(day) {
            let type = ''
            const current = moment(day.date).format('YYYY-MM-DD')
            const currentDay = moment(current + ' 00:00:00').valueOf()
            const nowDate = moment().format('YYYY-MM-DD')
            const today = moment(nowDate + ' 00:00:00').valueOf()
            let isPassMinDate = true
            let isPassMaxDate = true
            let isPassToday = true
            isPassToday = currentDay >= today
            if (this.startRangeDate && this.startRangeDate !== '') {
                isPassMinDate = moment(this.startRangeDate + ' 00:00:00').valueOf() <= currentDay
            }
            if (this.endRangeDate && this.endRangeDate !== '') {
                isPassMaxDate = moment(this.endRangeDate + ' 00:00:00') >= currentDay
            }
            type = (isPassToday && isPassMinDate && isPassMaxDate) ? '' : 'disabled'
            if (moment(day.date).format('YYYY-MM-DD') === this.flightStartDate) {
                type = 'selected'
            }
            day.type = type
            return day
        },
        setSearchReact() {
            this.searchTemp = setInterval(() => {
                this.intervalNum++
                if (this.intervalNum >= 30 * 60) {
                    clearInterval(this.searchTemp)
                    Toast('页面停留时间超过30分钟，请您重新搜索航班')
                    this.search()
                    this.intervalNum = 0
                    this.setSearchReact()
                }
            }, 1000)
        },
    }
}
