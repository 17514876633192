import wangyiServerOnline from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/wangyiqiyu_helper";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
import slider_left_back from "@/lib/common-service/slider_left_back/index";// 滑屏返回
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
import app_loading from '@/lib/common-service/app-loading/index.js';

export default {
  data() {
    return {
      id: 1,
      flutterSwitch: false,
    }
  },
  activated() {
    app_loading.hide();
    this.flutterSwitch = window.getFlutter;
    this.keyback();
    wangyiServerOnline.config().then(() => {
      wangyiServerOnline.getUnReadMsgNum({
        callback:(result) => {
          this.readMsgNum = result;
        }
      });
      setTimeout(() => {
        wangyiServerOnline.onlyHide();
      },1)
    });

    fixStatusBar({
      type: `100`,
      selector: `.back-btn`,
    });

    let _this = this;
    fixStatusBar({
      type: `000`,
      handler({default_handler, api}) {
        _this.$refs['bookBtnWrapper'].style.paddingBottom = `${api.safeArea.bottom - 10}px`;
      }
    });

    if (window.getFlutter !== true) {
      try {
        slider_left_back(this.$refs.advertDetail, this);
      } catch (e) {

      }
    }

    this.init();
  },
  destroyed() {
    wangyiServerOnline.config().then(() => {
      wangyiServerOnline.show();
      wangyiServerOnline.destructionGetUnReadMsgNum();
    });
    keyback_processor.removeEventListener();
  },
  methods: {
    clickGuide() {
      if (window.getFlutter == true) {
        try {
          window.flutter_inappwebview
          //可以传递你所需要的任意类型数据，数组、对象等
            .callHandler("backHandler", {'type': 'browser','url': 'https://ca.rrx.cn/v/hqne5c?v=1'})
            .then((res)=>{
              console.log("res==flutter给html的数据", res);
            })
        } catch (e) {}
        return
      }


      const api = global.api;
      console.log(api);
      if (api) {
        const systemType = api.systemType;
        if (systemType === 'ios') {
          api.openApp({
            iosUrl: 'https://ca.rrx.cn/v/hqne5c?v=1'
          });

        } else {
          api.openApp({
            androidPkg: 'android.intent.action.VIEW',
            mimeType: 'text/html',
            uri: 'https://ca.rrx.cn/v/hqne5c?v=1'
          }, function(ret, err) {

          });
        }
      } else {
        window.open('https://ca.rrx.cn/v/hqne5c?v=1');
      }
    },
    init() {
      this.id = this.$route.query.id ? Number(this.$route.query.id) : 1;
    },
    onBack(){
      this.$router.go(-1);
    },
    onBook() {
      // new https://wxapi.csair.com/h5/gomini.html?schemeId=11412
      // old https://tsk.csair.com/tcsH5/#/?shareCode=CZgXzaWR4Ucd6ZdNKZYxmA==&entrance_channel=TCSH5
      if (this.id == 1) {
        if (window.getFlutter == true) {
          try {
            window.flutter_inappwebview
            //可以传递你所需要的任意类型数据，数组、对象等
              .callHandler("backHandler", {'type': 'browser','url': 'https://wxapi.csair.com/h5/gomini.html?schemeId=11412'})
              .then((res)=>{
                console.log("res==flutter给html的数据", res);
              })
          } catch (e) {}
          return
        }

        const api = global.api;
        if (api) {
          const systemType = api.systemType;
          if (systemType === 'ios') {
            api.openApp({
              iosUrl: 'https://wxapi.csair.com/h5/gomini.html?schemeId=11412'
            });
          } else {
            api.openApp({
              androidPkg: 'android.intent.action.VIEW',
              mimeType: 'text/html',
              uri: 'https://wxapi.csair.com/h5/gomini.html?schemeId=11412'
            }, function(ret, err) {

            });
          }
        } else {
          window.open('https://wxapi.csair.com/h5/gomini.html?schemeId=11412');
        }
      } else {
        this.$router.push({
          name: 'h5-advert-book',
          query: {
            id: this.id
          },
        });
      }
    },
    keyback() {
      const __this = this;

      keyback_processor.addEventListener(function () {
        __this.onBack()
      });
    }
  }
}
