import Q from 'q';
import _ from 'underscore';
import moment from 'moment';
import {Toast} from 'vant';

import api_jiguang_push from '@/lib/data-service/system/api_jiguang_push';

export default {
    components: {},
    data() {
        return {
            form: {
                content: ``,
                alias: ``,
            },
        };
    },
    methods: {
        __btn_submit_click() {
            const __this = this;
            const form = __this.form;
            const content = form.content;
            const alias = form.alias;

            Q.when()
                .then(function () {
                    return api_jiguang_push({
                        content,
                        alias,
                    });
                })
                .then(function (data) {
                    // console.log(JSON.stringify(data, null, 4));
                    Toast.success(`推送成功！`);
                })
                .catch(function (ex) {
                    Toast.success(`推送失败！`);
                    if (ex) {
                        const enable_log_error = !ex.disable_log_error;
                        if (enable_log_error) {
                            console.error(ex);
                            console.trace();
                            if (ex.stack) {
                                console.error(ex.stack);
                            }
                        }
                    }
                    return Q.reject(ex);
                })
            ;
        },
    },
    created() {
        // const __this = this;
    },
    mounted() {
        const __this = this;
        const now = moment();
        const now_format = now.format(`YYYY-MM-DD HH:mm:ss`);
        __this.form.content = `content[${now_format}]`;
        __this.form.alias = `init_userId`;
    },
    activated() {
        // const __this = this;
    },
    deactivated() {
        // const __this = this;
    },
}
