import moment from 'moment'
import DomesticTicketAirportSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-city-selector/1.2.1/index.vue';
import consumer_evection_addOrUpdate from '@/lib/data-service/haolv-default/consumer_evection_addOrUpdate' // 新增或修改出差单
//查询出差规则
import refund_change_policy_get from '@/lib/common-service/consumer_t-tp-refund-change-policy_get/index'
//选择出差单弹窗组件
import ReservePopupBox from "@/page/travel-book/flight/component/reservePopupBox/import_latest_version_module";
import ChooseTraveler from '@/component/choose-traveler/1.0.5/index.vue'
import CommonTravelerSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/common-traveler-selector/1.0.0/index.vue';
import {Dialog, Toast} from "vant";
// 滑屏返回
import slider_left_back from "@/lib/common-service/slider_left_back/index";
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar';
// 我的出差
import web_policyConfig_userTravelPolicy from "@/lib/data-service/haolv-default/web_policyConfig_userTravelPolicy";
import consumer_web_policyConfig_getUserTravelPolicy from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getUserTravelPolicy";
import consumer_journey_getEvectionDetailV2 from '@/lib/data-service/haolv-default/consumer_journey_getEvectionDetailV2'
// 用户信息
import getJourneyOptionsCanBeModified
    from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_journey_options_can_be_modified/index.js'
import consumer_user_historyCity from '@/lib/data-service/haolv-default/consumer_user_historyCity'
import consumer_trains_account_accountStatus
    from '@/lib/data-service/haolv-default/consumer_trains_account_accountStatus'
import NavBar from "@/component/nav-bar/import_latest_version_module";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
import {hideEvectionTypeChangeTabCompanyIdList,hideTrainOpenCompanyIdList} from '@/lib/ohter/index.js'
import get_user_info from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/haolv/get_user_info";
export default {
    data() {
        return {
            hideTrainOpenCompanyIdList: hideTrainOpenCompanyIdList,
            showTabBar: false,
            getEvectionDetailV2Switch: true,
            tabsText: 1,
            trainDialog: false,
            journeyType: null,
            evectionRequired: 0, // 出差单是否必填 0：否 1：是
            unLimitedJourney: 1, // 是否不限行程 0：否 1：是
            allowChangeJourneyTime: 1, // 是否允许修改行程时间 0：否 1：是
            businessRuleControl: 1, // 因公出差规则 1：出差单审批 2：预订审批
            timeCanEdit: null,
            cityCanEdit: null,
            userInfo: {}, // 用户信息
            selectedStartTrainId: '', // 出发站ID
            startTrainParams: {}, // 出发站信息
            selectedArriveTrainId: '', // 到达站ID
            arriveTrainParams: {}, // 到达站信息
            isShowCalendar: false, // 出行日期弹窗
            trainDate: '', // 出行日期
            popupShow: false, // 出行人弹窗
            activeUserIds: [], // 选中的员工id
            allUserItems: [], // 全部员工列表
            activeUserIndex: 0,
            activeChooseUserList: [], // 当前选择的出行人
            checked: false, // 是否只看高铁动车
            travelPopupShow: false, // 出差预订弹窗
            travelCurrent: null, // 当前出差
            canChooseUserList: [], // 出差单情况下可以选择的出行人
            activeCanChooseUserList: [], // 出差单情况下选中的出行人
            travelUserSelectPopupShow: false, // 出差单选择出行人弹窗

            minDate: new Date(),   //时间范围
            maxDate: new Date(new Date().getFullYear() + 10, 1, 1),   //时间范围

            useTravel: 1, // 是否使用出差

            showBiao: false,  //差标提示组件
            showBiaoData: {},  //差标提示信息
            companyData: null, //差标限制
            evectionDetail: null, // 出差信息

            policyLoading: false,
        }
    },
    components: {
        DomesticTicketAirportSelector,
        ReservePopupBox,
        ChooseTraveler,
        CommonTravelerSelector,
        NavBar
    },
    methods: {
        //获取用户信息
        getUserInfo() {
            get_user_info().then(res => {
                this.userInfo = res.datas;
                // if (this.hideTrainOpenCompanyIdList.indexOf(this.userInfo.companyId) === -1) {
                //
                // } else {
                //     Dialog.alert({
                //         title: '温馨提示',
                //         message: '企业暂未开通火车功能',
                //         confirmButtonText: '我知道了'
                //     }).then(() => {
                //         this.$router.go(-1);
                //     });
                // }
                if (hideEvectionTypeChangeTabCompanyIdList.indexOf(this.userInfo.companyId) > -1) {
                    this.showTabBar = false;
                } else  {
                    this.showTabBar = true;
                }
            })
        },
        clickTrainIndent(){
            this.$router.push({
                name: 'h5-train-order-list',
                query: {
                    jumpType: 'back'
                },
            })
        },
        toEditTrainAccount() {
            if (this.accountStatus === 2) {
                this.$router.push({
                    name: 'h5-train-account-check'
                })
            } else {
                this.$router.push({
                    name: 'h5-train-account-depute'
                })
            }
        },
        setDefault() {
            consumer_user_historyCity().then(res => {
                this.selectedStartTrainId = res.datas.trainHistoryInfo.fromStationCode;
                this.selectedArriveTrainId = res.datas.trainHistoryInfo.toStationCode;
            })
        },
        //调用方法判断当前状态下地点、时间是否可以改变
        canChangeFactorBox() {
            getJourneyOptionsCanBeModified({
                situation: '100',
                vm: this
            }).then(res => {
                this.cityCanEdit = res.cityCanEdit;
                this.timeCanEdit = res.timeCanEdit;
            })
        },
        //差标提示
        clickBiao() {
            this.showBiao = true;
            this.policyLoading = true;

            let dataBox = {
                applyJourneyId: this.travelCurrent && this.travelCurrent.journeys.length != 0 ? this.travelCurrent.journeys[0].id : '',
                businessType: 2,
                evectionNo: this.travelCurrent ? this.travelCurrent.evectionNo : '',
                userIds: this.activeChooseUserList,
            };
            consumer_web_policyConfig_getUserTravelPolicy(dataBox).then(res => {
                this.showBiaoData.trainsPolicy = res.datas.trainsMsg.trainsPolicy;
                this.showBiaoData.trainsPolicyText = res.datas.trainsMsg.trainsPolicyText;
                this.showBiaoData.cGSeatName = res.datas.trainsMsg.cGSeatName;
                this.showBiaoData.dSeatName = res.datas.trainsMsg.dSeatName;
                this.showBiaoData.seatName = res.datas.trainsMsg.seatName;
                this.showBiaoData.trainsControlRulesText = res.datas.trainsMsg.trainsControlRulesText;
                this.showBiaoData.travelerNames = res.datas.travelerNames;
            }).finally(()=>{
                this.policyLoading = false;
            });
            // if (this.activeChooseUserList && this.activeChooseUserList.length != 0) {
            // if (true) {
            //
            // } else {
            //     web_policyConfig_userTravelPolicy().then(res => {
            //         this.showBiaoData = res.datas;
            //     }).finally(()=>{
            //         this.policyLoading = false;
            //     });
            // }
        },
        // 判断或者字段的长短返回样式
        inputFontLong(val) {
            if (val && (val.length > 0 && val.length < 3)) {
                return '';
            } else {
                return '';
            }
        },
        formatterDate(day) {
            const num = day.date.getDay();
            if (num === 0 || num === 6) {
                day.bottomInfo = '休';
            }
            return day;
        },
        // 打开出发站选择弹窗
        openStartTrainPopup() {
            if (!this.getEvectionDetailV2Switch) return;
            // 因公出差的类型， 1是有选出差单，2是没有
            let workTravelType = this.travelCurrent != null ? 1 : 2;
            // 是否现在行程0限制
            let unlimitedJourney = this.unlimitedJourney;


            if (workTravelType == 1 && unlimitedJourney == 0) {
                Toast({
                    message: '不能修改出差单既定行程',
                    position: 'bottom'
                });
            } else {
                this.$refs.startAirportSelector.show();
            }
        },
        // 打开到达站选择弹窗
        openArriveTrainPopup() {
            if (!this.getEvectionDetailV2Switch) return;
            // 因公出差的类型， 1是有选出差单，2是没有
            let workTravelType = this.travelCurrent != null ? 1 : 2;
            // 是否现在行程0限制
            let unlimitedJourney = this.unlimitedJourney;


            if (workTravelType == 1 && unlimitedJourney == 0) {
                Toast({
                    message: '不能修改出差单既定行程',
                    position: 'bottom'
                });
            } else {
                this.$refs.arriveAirportSelector.show();
            }
        },
        checkAccount() {
            consumer_trains_account_accountStatus().then(res => {
                // 1-正常 2-需核验 3-账号/密码变更 4-您还未授权认证12306账号信息，请及时授权认证避免无法购买火车票
                const accountStatus = res.datas.accountStatus;
                this.accountStatus = accountStatus;
                if (this.accountStatus == 4) {
                    this.trainDialog = true
                }
            })
        },
        // 选择出行日期弹窗
        showCalendar() {
            this.$refs.ShowCalendarRef.reset(new Date(this.trainDate));
            if (!this.getEvectionDetailV2Switch) return;
            // 因公出差的类型， 1是有选出差单，2是没有
            let workTravelType = this.travelCurrent != null ? 1 : 2;
            if (this.travelCurrent != null) {
                this.minDate = new Date(this.travelCurrent.journeys[0].departTime);
                this.maxDate = new Date(this.travelCurrent.journeys[0].departTimeEnd);
            }

            if (this.checkEvectionRequired()) {
                this.isShowCalendar = true;
            }

            if (this.timeCanEdit) {
                this.isShowCalendar = true;
            } else {
                Toast({
                    message: '不能修改出差单既定行程时间',
                    position: 'bottom'
                });
            }
        },
        checkEvectionRequired() {
            let isOk = true;
            if (this.allowChangeJourneyTime == 0 && this.travelCurrent != null) {
                isOk = false;
                Toast({
                    message: '不能修改出差单既定行程时间',
                    position: 'bottom'
                });
                return isOk
            }
            return isOk
        },
        // 确定选择出行日期
        onCalendarConfirm(date) {
            this.isShowCalendar = false;
            this.trainDate = this.$moment(date).format('YYYY-MM-DD');
        },
        // 出差预订
        chooseTravel() {
            this.travelPopupShow = true
        },
        // 选中出差触发
        getCurrentEvection(value) {
            this.canChangeFactorBox();
            if (value == null) {
                this.activeChooseUserList = [];
                this.setDefault();
            } else if (value.journeys.length != 0) {
                this.journeyType = value.journeys[0].journeyType;
            }

            let testText = new Date().toLocaleDateString();
            let testTextBox = null;
            testText.split('/').forEach((item, index) => {
                if (item.length == 1) {
                    item = `0${item}`;
                }
                if (index == 0) {
                    testTextBox = `${item}`;
                } else {
                    testTextBox = `${testTextBox}-${item}`;
                }
            });

            let startDate = '';
            let momentStartDate = null;
            let today = null;
            // 选中出差单，回显日期、日历组件选中日期
            this.trainDate = this.$route.query.startDate ? this.$route.query.startDate : testTextBox;
            if (value) {
                startDate = value.active.departTime;
                momentStartDate = moment(startDate + ' 00:00:00').valueOf();
                today = moment().valueOf();
                if (value.active.journeyType === 1) {
                    // 如果是不限行程
                    if (momentStartDate < today) {
                        // 如果出发日期小于今天，就是已过期，则选今天
                        this.$refs.ShowCalendarRef.reset(new Date(moment().format('YYYY-MM-DD')));
                    } else {
                        this.$refs.ShowCalendarRef.reset(new Date(startDate));
                    }
                } else {
                    this.$refs.ShowCalendarRef.reset(new Date(value.active.tripDate));
                }
            } else {
                this.minDate = new Date();
                this.maxDate = new Date(new Date().getFullYear() + 10, 1, 1);
                this.$refs.ShowCalendarRef.reset(new Date(this.trainDate));
            }

            if (value != null) {
                if (value.journeys[0].journeyType == 1) {
                    if (momentStartDate < today) {
                        // 如果出发日期小于今天，就是已过期，则选今天
                        this.minDate = new Date(moment().format('YYYY-MM-DD'));
                    } else {
                        this.minDate = new Date(startDate);
                    }

                    this.maxDate = new Date(value.endDate);
                }
            }

            // 如果是搜索页返回的情况
            if (this.evectionDetail) {
                this.$nextTick(() => {
                    const params = {
                        travelUserList: this.evectionDetail.psgList
                    };
                    this.$refs['traveler'].setActiveUserList(params);
                    this.evectionDetail = null
                })

            }
        },
        getSelectedStaffIdList(selectedStaffIdList) {
            this.activeChooseUserList = selectedStaffIdList;
        },
        // 切换
        change_page(page) {
            if (page === 1) {
                this.$router.push({
                    name: "h5-flight-home",
                });
            } else if (page === 2) {
                this.$router.push({
                    name: "h5-hotel-home",
                });
            }
        },
        // 获取出差预设列表
        getBusinessTravel() {
            let params = {
                pageSize: 100,
                currentPage: 1,
                status: 2,
                approveType: 1,
            };
        },
        // 搜索
        async searchSubmit() {
            if (!this.selectedStartTrainId || !this.selectedArriveTrainId) {
                Toast({
                    message: '请选择出发和到达城市',
                    position: 'bottom'
                });
                return
            }
            if (!this.trainDate) {
                Toast({
                    message: '请选择出发日期',
                    position: 'bottom'
                });
                return
            }

            // 因公出差的类型， 1是有选出差单，2是没有
            let workTravelType = this.travelCurrent != null ? 1 : 2;
            if (workTravelType == 1 && this.activeChooseUserList.length == 0) {
                Toast({
                    message: '请选择出行人',
                    position: 'bottom'
                });
                return
            }

            if (this.useTravel === 2) {
                this.toSearchPage();
                return
            }

            let staffList = [];
            this.activeChooseUserList.forEach(value => {
                staffList.push(value)
            });
            let params = {
                applyId: this.travelCurrent ? this.travelCurrent.id : '',
                trainDate: this.travelCurrent ? this.travelCurrent.startDate : '',
                evectionNo: this.travelCurrent ? this.travelCurrent.evectionNo : '',
                evectionType: this.useTravel === 1 || this.businessRuleControl === 2 ? 2 : 1, // 1是个人出差，2是出差单出差
                travellerList: staffList,
                personType: 2,
                checked: this.checked,
            };
            let res = await consumer_evection_addOrUpdate(params);
            this.toSearchPage(res);
        },
        toSearchPage(res) {
            let maxDateText = this.travelCurrent == null ? '' : this.travelCurrent.endDate;
            let minDateText = this.travelCurrent == null ? '' : this.travelCurrent.startDate;


            if (this.useTravel == 2) {
                this.$router.push({
                    name: 'train-search',
                    query: {
                        fromStationCode: this.selectedStartTrainId, //初始站ID
                        fromStationName: this.startTrainParams.stationName, //初始站
                        toStationCode: this.selectedArriveTrainId, //终点站ID
                        toStationName: this.arriveTrainParams.stationName, //终点站
                        trainDate: this.trainDate, //日期
                        evectionType: this.useTravel === 1 ? 2 : 1, // 1是因私出差，2是因公出差
                        checked: this.checked,
                        allowChangeJourneyTime: this.allowChangeJourneyTime,
                        maxDateText: null,
                    },
                });
            } else {
                this.$router.push({
                    name: 'train-search',
                    query: {
                        fromStationCode: this.selectedStartTrainId, //初始站ID
                        fromStationName: this.startTrainParams.stationName, //初始站
                        toStationCode: this.selectedArriveTrainId, //终点站ID
                        toStationName: this.arriveTrainParams.stationName, //终点站
                        trainDate: this.trainDate, //日期
                        evectionNo: res ? res.datas : '',
                        redirectType: '000',
                        evectionType: this.useTravel === 1 ? 2 : 1, // 1是因私出差，2是因公出差
                        checked: this.checked,
                        evectionRequired: this.evectionRequired,   //出差单是否必填--1必填/0非必填
                        workTravelType: this.travelCurrent != null ? 1 : 2, // 因公出差的类型， 1是有选出差单，2是没有
                        applyId: this.travelCurrent ? this.travelCurrent.id : '',
                        maxDateText,
                        minDateText,
                        journeyId: this.travelCurrent ? this.travelCurrent.active.id : 0,
                        journeyType: this.journeyType,
                        activeChooseUserList: this.activeChooseUserList.join(),
                        allowChangeJourneyTime: this.allowChangeJourneyTime,
                        unlimitedJourney: this.unlimitedJourney,
                    },
                });
            }
        },
        // 交换出发地和目的地
        changeAdr() {
            if (this.checkEvectionRequired()) {
                let selectedStartTrainId = this.selectedStartTrainId;
                let selectedArriveTrainId = this.selectedArriveTrainId;
                this.selectedStartTrainId = selectedArriveTrainId;
                this.selectedArriveTrainId = selectedStartTrainId;
            }
        },
        // 切换出差类型时触发
        changeTravelType(name, title) {
            this.travelCurrent = null;
            this.useTravel = name;
        },
        __btn_go_back_click() {
            const __this = this;
            if(__this.$route.query.type === 'new') {
                __this.$router.go(-1)
            } else {
                __this.$router.replace({
                    name: 'h5-home',
                })
            }
        },
        refundChangePolicyGet() {
            refund_change_policy_get().then(res => {
                this.evectionRequired = res.datas.evectionRequired;
                this.allowChangeJourneyTime = res.datas.allowChangeJourneyTime;
                this.unlimitedJourney = res.datas.unlimitedJourney;
                this.businessRuleControl = res.datas.businessRuleControl;
            })
        }
    },
    mounted() {
        const __this = this;
        this.refundChangePolicyGet();
        this.canChangeFactorBox();
        this.trainDate = this.$moment().format('YYYY-MM-DD');

        fixStatusBar({
            type: `100`,
            selector: `.train-bg`,
        });
        fixStatusBar({
            type: `100`,
            selector: `.flight-home-title`,
        });

        this.checkAccount();

        if (this.$route.query.useTravel == 1 ||
            this.$route.query.useTravel == undefined ||
            this.$route.query.useTravel == null) {
            this.useTravel = 1;
        } else {
            this.useTravel = 2;
        }
        this.setDefault();
        this.$refs.startAirportSelector.init();
        this.$refs.arriveAirportSelector.init();
    },
    activated() {
        this.getUserInfo();//获取用户信息
        this.getBusinessTravel();
        slider_left_back(this.$refs.train_home, this);
        // 判断是否是搜索页返回，地址栏带参数
        if (this.$route.query.evectionNo) {
            this.getEvectionDetailV2Switch = false;
            const query = this.$route.query;
            const evectionNo = query.evectionNo;
            const journeyId = parseInt(query.journeyId);
            this.trainDate = query.startDate;
            consumer_journey_getEvectionDetailV2({evectionNo}).then(res => {
                this.getEvectionDetailV2Switch = true;
                this.evectionDetail = res.datas;
                this.$refs['reservePopupBox'].setJourneyId({journeyId})
            })
        }
        const __this = this;
        keyback_processor.addEventListener(function () {

            try {
                //出差单选择弹框
                const reservePopupBox = __this.$refs.reservePopupBox;
                const show_reservePopupBox = reservePopupBox._data.currentPopupShow;
                if (show_reservePopupBox) {
                    __this.$refs.reservePopupBox._data.currentPopupShow = false;
                    return
                }
            } catch (err) {

            }

            try {
                //差标弹框
                if (__this.showBiao) {
                    __this.showBiao = false;
                    return;
                }
            } catch (err) {

            }

            try {
                //出发地弹框
                const startAirportSelector = __this.$refs.startAirportSelector;
                const show_startAirportSelector = startAirportSelector.get_show();
                if (show_startAirportSelector) {
                    startAirportSelector.hide();
                    return;
                }
            } catch (err) {

            }


            try {
                //目的地弹框
                const arriveAirportSelector = __this.$refs.arriveAirportSelector;
                const show_arriveAirportSelector = arriveAirportSelector.get_show();
                if (show_arriveAirportSelector) {
                    arriveAirportSelector.hide();
                    return;
                }
            } catch (err) {

            }


            try {
                //日期弹框
                if (__this.isShowCalendar) {
                    __this.isShowCalendar = false;
                    return;
                }
            } catch (err) {

            }

            try {
                //选择出行人弹框
                const traveler = __this.$refs.traveler;
                const show_traveler = traveler.get_show();
                if (traveler.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.popup_selected_user_entity_list.show) {
                    traveler.$refs.aCommonTravelerSelector.$refs.aTravelerMultipleSelector.popup_selected_user_entity_list.show = false;
                    return;
                }
                if (show_traveler) {
                    traveler.hide();
                    return;
                }
            } catch (err) {

            }


            __this.__btn_go_back_click();
        })
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    watch: {
        selectedStartTrainId(newVal, oldVal) {
            this.$nextTick(() => {
                this.$refs.startAirportSelector.get_selected_entity_promise({
                    id: newVal,
                }).then((entity) => {
                    this.startTrainParams = entity;
                });
            })
            // 当是不限行程，且第一次选了出行城市之后
            if (newVal && !oldVal && this.travelCurrent && this.travelCurrent.active.journeyType === 1) {
                if (!this.selectedArriveTrainId) {
                    this.openArriveTrainPopup();
                } else if (!this.trainDate) {
                    this.showCalendar();
                }

            }
        },
        selectedArriveTrainId(newVal, oldVal) {
            this.$nextTick(() => {
                this.arriveTrainParams = this.$refs.arriveAirportSelector.get_selected_entity_promise({
                    id: newVal,
                })
                    .then((entity) => {
                        this.arriveTrainParams = entity;
                    })
                    .catch((rej) => {
                        console.log(rej);
                    })
            })
            // 当是不限行程，且第一次选了目的城市之后
            if (newVal && !oldVal && this.travelCurrent && this.travelCurrent.active.journeyType === 1 && !this.trainDate) {
                this.showCalendar();
            }
        },
        travelCurrent(newVal) {
            if (newVal == null) {
                this.activeChooseUserList = [];
            } else {
                let [...staffList] = newVal.journeys.length == 0 ? [] : newVal.journeys[0].staffList;
                let [...canStaffList] = newVal.journeys.length == 0 ? [] :newVal.journeys[0].staffList;

                staffList.forEach((value, index) => {
                    value.id = value.userId;
                    value.text = value.staffName;
                });
                canStaffList.forEach(value => {
                    value.id = value.userId;
                    value.text = value.staffName;
                });
                this.activeChooseUserList = staffList;
                this.canChooseUserList = canStaffList;

                // 更新城市和日期
                this.selectedStartTrainId = newVal.active.departCityId;
                this.selectedArriveTrainId = newVal.active.toCityId;
                this.trainDate = newVal.active.tripDate;
                if (newVal.active.journeyType == 1) {

                    /*var date1 = new Date(`${moment().format('YYYY-MM-DD')} 00:00:00`).valueOf();
                    var date2 = new Date(`2023-08-07 00:00:00`).valueOf();
                    if (date1 < date2) {
                        this.trainDate = moment().format('YYYY-MM-DD');
                    } else {
                        this.trainDate = newVal.active.departTime;
                    }*/
                    // 如果不限行程，则不赋值城市
                    this.selectedStartTrainId = '';
                    this.selectedArriveTrainId = '';
                    this.trainDate = '';

                    // 如果是不限行程，则弹出城市选择
                    // 触发选择出发城市的弹窗方法
                    this.openStartTrainPopup();
                }
            }
        }
    },
    filters: {
        filterTrainDate(val) {
            return val === '' || val === null ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterFlightDaY(val) {
            return val === '' || val === null ? '' : moment(val).format('YYYY年MM月DD日')
        },
        filterWeek(val) {
            if (!val) {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        }
    },
}
