// evectionListChange
// /consumer/evection/evectionListChange
//获取出差单列表数据接口

const __request = require(`./__request/__request_contentType_json`);
const consumer_evection_evectionList = (pParameter) => {


  let name = [];
  if (!pParameter) {
    pParameter = {};
  }

  const p = {
    method: 'post',
    urlSuffix: '/consumer/evection/evectionListChange',
    data: pParameter
  };

  return new Promise(function(resolve, reject) {
    resolve(__request(p))
  })
};
module.exports = consumer_evection_evectionList;
