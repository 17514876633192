import moment from 'moment'
import consumer_flight_search_lowestPrice from '@/lib/data-service/haolv-default/consumer_flight_search_lowestPrice'
export default {
    data () {
        return {
            show: false,
            weekList: [], // 日历列表
            events: [],
            minDate: this.returnDateType(),
            maxDate: this.returnDateType(moment().add(12, 'month').format('YYYY-MM-DD'))
        }
    },
    props: {
        saveWeek: String,
        minDateText: {
            type: String,
            default: ''
        },
        maxDateText: {
            type: String,
            default: ''
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    components: {},
    created () {},
    mounted () {
        this.getDayList()
        if (this.minDateText !== '') {
            this.minDate = this.returnDateType(this.minDateText)
        }
        if (this.maxDateText !== '') {
            this.maxDate = this.returnDateType(this.maxDateText)
        }

    },
    activated () {
    },
    deactivated() {},
    destroyed () {},
    watch: {
        saveWeek: {
            immediate: true, // immediate选项可以开启首次赋值监听
            handler(newVal, oldVal) {
                console.log(newVal, oldVal)
            }
        },
        currentWeek(newVal) {
            this.getDayList()
        },
        minDateText: {
            deep: true,
            handler(newVal) {
                this.minDate = this.returnDateType(newVal)
            }
        },
        maxDateText: {
            deep: true,
            handler(newVal) {
                console.log('max', newVal)
                this.maxDate = this.returnDateType(newVal)
            }
        }
    },
    computed: {
        currentWeek: {
            get () {
                return this.saveWeek
            },
            set (val) {
                this.$emit('update:saveWeek', val)
            }
        }
    },
    filters: {
        filterDate(val) {
            if (val) {
                return moment(val).format('MM-DD')
            } else {
                return ''
            }
        }
    },
    methods: {
        returnDateType(val) {
            console.log('guolv',val)
            let day = moment(val)
            return day.toDate()
        },
        // 获取7天日历
        getDayList() {
            let now = new Date().valueOf();
            let location = this.locationDay(2);
            console.log(now, location)
            if (location - now <= 0) {
                this._getWeek();
            } else {
                this._getWeek(location);
            }
            this.getPrice()
        },
        // 获取指定日期
        locationDay(val) {
            console.log(this.currentWeek, Date.parse(this.currentWeek))
            return Date.parse(this.currentWeek) - val * Math.floor(24 * 3600 * 1000);
        },
        // 获取一周时间
        _getWeek(val) {
            let arr = []
            val ? val : ''
            for (let i = 0; i < 7; i++) {
                let dateTime = moment(val).subtract(-i, "days").format("YYYY-MM-DD") //
                let week = moment(val).subtract(-i, "days")._d // 获取周几
                week = week.getDay()
                console.log(week)
                let weekName = ''
                switch (week) {
                    case 0: weekName = '星期天'
                        break
                    case 1: weekName = '星期一'
                        break
                    case 2: weekName = '星期二'
                        break
                    case 3: weekName = '星期三'
                        break
                    case 4: weekName = '星期四'
                        break
                    case 5: weekName = '星期五'
                        break
                    case 6: weekName = '星期六'
                        break
                    default: weekName = ''
                }
                let obj = {
                    week: weekName,
                    date: dateTime,
                    status: false
                };
                arr.push(obj)
            }
            arr.map((item, index) => {
                item.date === this.currentWeek ? (item.status = true) : ''
            });
            this.weekList = arr

        },
        // 获取每日最低价
        getPrice() {
            let fromStationCode = this.$parent.fromStationCode
            let toStationCode = this.$parent.toStationCode
            console.log('form', fromStationCode)
            if (!fromStationCode || !toStationCode) return;
            let endTime = new Date().valueOf() + 24 * 3600 * 1000 * 29;
            let data = {
                arrCode: fromStationCode,
                depCode: toStationCode,
                startDate: moment().format("YYYY-MM-DD"),
                endDate: moment(endTime).format("YYYY-MM-DD"),
                supplierType: 1
            };
            consumer_flight_search_lowestPrice(data).then(res => {
                console.log(res);
                this.events = res.result;
                this.setPrice();
            });
        },
        // 设置价格
        setPrice() {
            this.weekList.map(item => {
                this.events.map(day => {
                    if (item.date === day.depDate) {
                        item.price = day.ticketPrice;
                    }
                });
            });
        },
        // 点击出发
        setTime(val) {
            if (this.disabled) {
                return
            }
            this.weekList.map(item => {
                item.status = false
            });
            val.status = true
            this.currentWeek = val.date
            // this.$emit("update:saveWeek", val.date)
            this.$emit("clickDate", val.date)
        },
        showVanCalendar() {
            if (this.disabled) {
                return
            }
            this.show = true
        },
        onConfirm(date) {
            console.log('date', date)
            this.currentWeek = moment(date).format('YYYY-MM-DD')
            this.$emit("clickDate", date);
            this.show = false
        }
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/