import import_latest_version_module from '@/component/h5-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/h5/train-change-order-detail',
            name: 'h5-train-change-order-detail',
            component: () => import(/* webpackChunkName: "page-personal-center-order-train-train-change-order-detail" */ `./1.1.0/index.vue`),
            meta: {
                h5_layout: {
                    current_page_name: '火车票',
                    need_head_nav: false,
                    need_footer_bar: false,
                    secondary_menu: 0,
                    need_alive: true,
                }
            },
        }
    ],
};