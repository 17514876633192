import _ from 'underscore';
import fixStatusBar from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/fixStatusBar'

import get_tab_list from './get_tab_list';

export default {
    data() {
        return {
            active: 0,
            tab_list: [],
        };
    },
    methods: {
        __init_tab_list() {
            const __this = this;
            const tab_list = get_tab_list();
            __this.tab_list = tab_list;

            fixStatusBar({
                mock_data: {
                    // enable: true,
                    enable: false,
                },
                handler({api}) {
                    // console.log(`fixStatusBar`, `api.safeArea.top=`, api.safeArea.top);
                    console.log(`fixStatusBar`, `api.safeArea.bottom=`, api.safeArea.bottom);
                    // const paddingTop = `${api.safeArea.top}px`;
                    const paddingBottom = `${api.safeArea.bottom}px`;

                    const $store = __this.$store;
                    const data_for_base_layout = $store.state.data_for_base_layout;
                    const tab_bar = data_for_base_layout.tab_bar;
                    const style = tab_bar.style;
                    style.paddingBottom = paddingBottom;
                }
            });
        },

        __init_tab_list_active() {
            const __this = this;

            const tab_list = __this.tab_list;
            const route_name = __this.$route.name;
            const index = _.chain(tab_list)
                .findIndex(function (m) {
                    return m.to.name === route_name;
                })
                .value()
            ;
            if (index > -1) {
                __this.active = index;
            }
        },
    },
    watch: {
        $route(to, from) {
            const __this = this;
            __this.__init_tab_list_active();
        },
    },
    mounted() {
        const __this = this;
        __this.__init_tab_list();
        __this.__init_tab_list_active();
    },
}
