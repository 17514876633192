export default {
  props: {
    href: {
      type: String,
      required: true,
    }
  },
  data () {
    return {

    };
  },
}