// 彩色icon组件
import ColorIcon from "@/component/color-icon/index.vue";

export default {
    data() {
        return {
            orderTypeList: [
                {
                    img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/15/flight.png',
                    title: '机票订单',
                    orderListlink: 'h5-flight-order-list',
                    noLinkTip: ''
                },
                {
                    img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/15/hotel.png',
                    title: '酒店订单',
                    orderListlink: 'h5-hotel-order-list',
                    noLinkTip: ''
                },
                {
                    img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/15/train.png',
                    title: '火车票订单',
                    orderListlink: 'h5-train-order-list',
                    noLinkTip: ''
                },
                {
                    img: 'https://quancheng-static-bucket.oss-cn-shenzhen.aliyuncs.com/2022/07/15/car.png',
                    title: '用车订单',
                    orderListlink: 'h5-car-order-list',
                    noLinkTip: ''
                }
            ]
        }
    },
    components: {
        ColorIcon
    },
    methods: {
        toList(orderType) {
            if (orderType.orderListlink === '') {
                this.$Toast(orderType.noLinkTip);
            } else {
                this.$router.push({
                    name: orderType.orderListlink,
                })
            }
        }
    }
}
