import extend from 'extend';
import Q from 'q';
import _ from 'underscore';

import api_ready_helper from 'yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/api_ready_helper/index.js';

const fixTabBar = function (pParameter) {
    if (!pParameter) pParameter = {};

    const type = pParameter.type || `000`;
    const selector = pParameter.selector;
    const __element = pParameter.element;
    const __handler = pParameter.handler;

    let element;

    if (type === `000`) {
        if (!_.isFunction(__handler)) {
            const msg = `fixTabBar parameter error, code=001`;
            console.error(msg);
            console.trace();
            return Q.reject({msg: msg});
        }
    }

    if (type === `100`) {
        if (!selector) {
            const msg = `fixTabBar parameter error, code=101`;
            console.error(msg);
            console.trace();
            return Q.reject({msg: msg});
        }
        element = global.document.querySelector(selector);
        if (!element) {
            const msg = `fixTabBar parameter error, code=102`;
            console.error(msg);
            console.trace();
            return Q.reject({msg: msg});
        }
    }

    if (type === `200`) {
        element = __element;
        if (!element) {
            const msg = `fixTabBar parameter error, code=201`;
            console.error(msg);
            console.trace();
            return Q.reject({msg: msg});
        }
    }

    return Q.when()
        .then(function () {
            return api_ready_helper.get_api_ready_promise();
        })
        .then(function () {
            const api = global.api;

            const default_handler = function ({element}) {
                element.style.paddingBottom = api.safeArea.bottom + 'px';
            };

            if (type === `000`) {
                return __handler({
                    default_handler,
                });
            }

            if (type === `100`) {
                return default_handler({
                    element,
                });
            }

            if (type === `200`) {
                return default_handler({
                    element,
                });
            }
        })
        .catch(function (ex) {
            if (ex) {
                const enable_log_error = !ex.disable_log_error;
                if (enable_log_error) {
                    console.error(ex);
                    console.trace();
                    if (ex.stack) {
                        console.error(ex.stack);
                    }
                }
            }
            return Q.reject(ex);
        })
        ;
};

export default fixTabBar;
