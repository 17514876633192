import moment from "moment";
import DomesticTicketAirportSelector
    from 'yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-city-selector/1.1.2/index.vue';
import consumer_trains_product_checkTheRemainingTrainTickets
    from '@/lib/data-service/haolv-default/consumer_trains_product_checkTheRemainingTrainTickets'
import consumer_trains_common_queryRailwayTimetable
    from '@/lib/data-service/haolv-default/consumer_trains_common_queryRailwayTimetable'
import consumer_trains_order_addJourney from '@/lib/data-service/haolv-default/consumer_trains_order_addJourney'
import consumer_trains_order_scheduledToCheck from '@/lib/data-service/haolv-default/consumer_trains_order_scheduledToCheck'
import CheckTravel from '@/page/travel-book/flight/component/checkTravel/index.vue'
import {Toast} from "vant";

export default {
    data() {
        return {
            orderNo: '',
            evectionNo: '',
            evectionType: 1, // 1为个人出差， 2为出差单出差
            railwayTimeList: [], // 经停信息
            showTrainWayListPopup: false,
            trainParams: {},
            trainFromStationCode: '',  // 搜搜条件-出发站
            trainToStationCode: '', // 搜索条件-到达站
            trainStartTrainParams: {}, // 搜索条件
            trainArriveTrainParams: {}, // 搜索条件
            trainStartDate: '', // 搜索条件-出发日期
            travelPopupShow: false,
            isShowCalendar: false, // 出发日期弹窗
        }
    },
    components: {
        DomesticTicketAirportSelector,
        CheckTravel
    },
    created() {

    },
    mounted() {
        let _routeParams = this.routeParams;
        let _trainInfo = decodeURIComponent(_routeParams.trainInfo);
        this.trainParams = JSON.parse(_trainInfo);
        this.trainStartDate = _routeParams.trainDate;
        this.redirectType = this.$route.query.redirectType;
        this.evectionNo = this.$route.query.evectionNo ? this.$route.query.evectionNo : '';
        this.evectionType = parseInt(this.$route.query.evectionType);
        if (this.$route.query.orderNo && this.$route.query.orderNo != undefined) {
            this.orderNo = this.$route.query.orderNo;
        }
        this.getRailwayTime();
        this.initCheckTravelBox()
    },
    watch: {
        trainFromStationCode(newVal) {
            this.$nextTick(() => {
                this.trainStartTrainParams = this.$refs.startAirportSelector.get_selected_entity();
            });
        },
        trainToStationCode(newVal) {
            this.$nextTick(() => {
                this.trainArriveTrainParams = this.$refs.arriveAirportSelector.get_selected_entity();
            });
        },
    },
    computed: {
        routeParams() {
            return this.$route.query;
        },
    },
    filters: {
        filterTrainDaY(val) {
            return val === '' ? '' : moment(val).format('MM月DD日')
        },
        filterWeek(val) {
            if (val === '') {
                return ''
            }
            let day = moment(val).day();
            if (day === 0) {
                return '周日'
            } else if (day === 1) {
                return '周一'
            } else if (day === 2) {
                return '周二'
            } else if (day === 3) {
                return '周三'
            } else if (day === 4) {
                return '周四'
            } else if (day === 5) {
                return '周五'
            } else if (day === 6) {
                return '周六'
            } else {
                return ''
            }
        },
        formatterTrainDate(val) {
            return val == '' ? '' : moment(val).format('MM-DD');
        },
    },
    methods: {
        // 获取经停信息
        getRailwayTime() {
            consumer_trains_common_queryRailwayTimetable({
                fromStationCode: this.trainParams.fromStationCode,
                toStationCode: this.trainParams.toStationCode,
                trainCode: this.trainParams.trainCode,
                trainDate: this.trainStartDate,
            }).then((res) => {
                this.railwayTimeList = [].concat(res.results);
            });
        },
        showSearchBox() {
            this.travelPopupShow = true
        },
        openSearchBox() {
        },
        inputFontLong(val) {
            if (val && (val.length > 0 && val.length < 3)) {
                return ''
            } else {
                return 'small'
            }
        },
        // 打开出发站选择框
        openStartTrainPopup() {
            this.$refs.startAirportSelector.show();
        },
        // 打开到达站选择框
        openArriveTrainPopup() {
            this.$refs.arriveAirportSelector.show();
        },
        showCalendar() {
            this.isShowCalendar = true;
        },
        // 确认出发日期
        onCalendarConfirm(date) {
            this.trainStartDate = this.$moment(date).format('YYYY-MM-DD');
            this.isShowCalendar = false;
            this.onRefresh();
        },
        // 搜索
        toSearch() {
            this.travelPopupShow = false;
        },
        // 刷新
        onRefresh() {
            Toast.loading({
                message: '刷新中...',
                forbidClick: true,
                duration: 0
            });
            consumer_trains_product_checkTheRemainingTrainTickets({
                fromStationCode: this.trainParams.fromStationCode,
                toStationCode: this.trainParams.toStationCode,
                trainDate: this.$moment(this.trainStartDate).format('YYYY-MM-DD'),
            }).then((res) => {
                let _list = res.resultList;
                for (let i = 0; i < _list.length; i++) {
                    if (_list[i].trainCode == this.trainParams.trainCode) {
                        this.trainParams = _list[i];
                    }
                }
            }).finally(() => {
                Toast.clear();
            });
        },
        // 前一天
        onPrev() {
            let _date = new Date(this.trainStartDate);
            _date.setDate(_date.getDate() - 1);
            this.trainStartDate = this.$moment(_date).format('YYYY-MM-DD');
            this.onRefresh();
        },
        // 后一天
        onNext() {
            let _date = new Date(this.trainStartDate);
            _date.setDate(_date.getDate() + 1);
            this.trainStartDate = this.$moment(_date).format('YYYY-MM-DD');
            this.onRefresh();
        },
        // 预订
        onReserve(item, sindex) {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            this.trainParams.sindex = sindex;
            let _trainParams = this.trainParams;
            // _trainParams.sindex = sindex;

            if (this.evectionType === 2) {
                this.$refs.checkTravelBox.run()
            } else {
                this.setOrder(_trainParams)
            }
        },
        setOrder(_trainParams) {
            let buyTicketsType = 0;
            if (_trainParams.isSale == 1 && parseInt(_trainParams.trainSeatVoList[_trainParams.sindex].seatInventory) > 0) {
                buyTicketsType = 0;
            } else if (_trainParams.isSale == 1 && parseInt(_trainParams.trainSeatVoList[_trainParams.sindex]) <= 0) {
                buyTicketsType = 4;
            } else {
                buyTicketsType = 5;
            }
            let [...robTicketNote] = _trainParams.trainSeatVoList;
            let params = {
                acceptNoSeat: 1, // 是否接受无座: 1：接受 0：不接受
                fromStationCode: _trainParams.fromStationCode,
                fromStationName: _trainParams.fromStationName,
                fromTime: _trainParams.fromTime,
                seatCode: _trainParams.trainSeatVoList[_trainParams.sindex].seatCode,
                seatName: _trainParams.trainSeatVoList[_trainParams.sindex].seatName,
                seatPrice: _trainParams.trainSeatVoList[_trainParams.sindex].seatPrice,
                canChooseSeat: _trainParams.trainSeatVoList[_trainParams.sindex].canChooseSeat == true ? 1 : 2,
                numberOfSeats: _trainParams.trainSeatVoList[_trainParams.sindex].numberOfSeats,
                startDate: this.$moment(_trainParams.trainStartDate).format('YYYY-MM-DD'),
                toStationCode: _trainParams.toStationCode,
                toStationName: _trainParams.toStationName,
                trainCode: _trainParams.trainCode,
                arriveDate: this.$moment(_trainParams.arriveDate).format('YYYY-MM-DD'),
                buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
                toTime: _trainParams.toTime,
                trainNo: _trainParams.trainNo,
                evectionNo: this.evectionNo,
                overproofApplyList: [],
                robTicketNote: JSON.stringify(robTicketNote),
                orderNo: this.orderNo,
            };
            consumer_trains_order_addJourney(params).then((res) => {
                Toast.clear();
                this.$router.push({
                    name: 'order-reserve',
                    query: {
                        evectionNo: res.datas.evectionNo,
                        redirectType: '000',
                    }
                });
            });
        },
        // 启动超标检测组件
        initCheckTravelBox() {
            this.$refs.checkTravelBox.init({
                checkFun: () => {
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                        duration: 0
                    });
                    let _trainParams = this.trainParams;
                    // _trainParams.sindex = sindex;
                    let params = {
                        evectionNo: this.evectionNo,
                        seatCode: _trainParams.trainSeatVoList[_trainParams.sindex].seatCode,
                        seatName: _trainParams.trainSeatVoList[_trainParams.sindex].seatName,
                    };
                    return consumer_trains_order_scheduledToCheck(params).then(res => {
                        let violationResults = res.datas.violationResults;
                        Toast.clear();
                        return violationResults // 返回一个数组给组件
                        /* 数组每项包含以下内容
                        * businessType: 业务类型:1.火车票;2.机票;3.酒店 integer(int32)
                        * controlRules: 管控规则:1.允许预定,只做提示;2.选择原因,3.不允许预定,4不限 integer(int32)
                        * seatName: 差标政策 string
                        * standard: 差标类型 string
                        * userId: 违反用户id integer(int64)
                        * userRealName 违反用户名字 string
                        * violationOfReason 预订信息 string
                        * */
                    }).catch(() => {
                        loading.close();
                        return null
                    })
                },
                reserveSubmitFun: (warningList) => {
                    console.log(warningList);
                    /*if (!warningList) {
                        this.canJourney = true
                    }*/
                    Toast.loading({
                        message: '加载中...',
                        forbidClick: true,
                        duration: 0
                    });
                    let _trainParams = this.trainParams;
                    // _trainParams.sindex = sindex;
                    let buyTicketsType = 0;
                    if (_trainParams.isSale == 1 && parseInt(_trainParams.trainSeatVoList[_trainParams.sindex].seatInventory) > 0) {
                        buyTicketsType = 0;
                    } else if (_trainParams.isSale == 1 && parseInt(_trainParams.trainSeatVoList[_trainParams.sindex]) <= 0) {
                        buyTicketsType = 4;
                    } else {
                        buyTicketsType = 5;
                    }
                    let overproofApplyList = [];
                    warningList.forEach(value => {
                        let item = {
                            businessType: 2,
                            controlRule: value.controlRule,
                            reason: value.reason ? value.reason : '',
                            reserveContent: value.violationOfReason,
                            standard: value.standard,
                            standardContent: value.seatName,
                            userId: value.userId,
                            userName: value.userRealName
                        };
                        overproofApplyList.push(item)
                    });
                    let [...robTicketNote] = _trainParams.trainSeatVoList;
                    let params = {
                        acceptNoSeat: 1, // 是否接受无座: 1：接受 0：不接受
                        fromStationCode: _trainParams.fromStationCode,
                        fromStationName: _trainParams.fromStationName,
                        fromTime: _trainParams.fromTime,
                        seatCode: _trainParams.trainSeatVoList[_trainParams.sindex].seatCode,
                        seatName: _trainParams.trainSeatVoList[_trainParams.sindex].seatName,
                        seatPrice: _trainParams.trainSeatVoList[_trainParams.sindex].seatPrice,
                        canChooseSeat: _trainParams.trainSeatVoList[_trainParams.sindex].canChooseSeat == true ? 1 : 2,
                        numberOfSeats: _trainParams.trainSeatVoList[_trainParams.sindex].numberOfSeats,
                        startDate: this.$moment(_trainParams.trainStartDate).format('YYYY-MM-DD'),
                        toStationCode: _trainParams.toStationCode,
                        toStationName: _trainParams.toStationName,
                        trainCode: _trainParams.trainCode,
                        arriveDate: this.$moment(_trainParams.arriveDate).format('YYYY-MM-DD'),
                        buyTicketsType: buyTicketsType, // 购票方式 0:自取票 1：送票上门2：12306 购票 4：抢票 5：预约抢票
                        toTime: _trainParams.toTime,
                        trainNo: _trainParams.trainNo,
                        evectionNo: this.evectionNo,
                        overproofApplyList: overproofApplyList,
                        robTicketNote: JSON.stringify(robTicketNote),
                        orderNo: this.orderNo,
                    };
                    consumer_trains_order_addJourney(params).then((res) => {
                        Toast.clear();
                        this.$router.push({
                            name: 'order-reserve',
                            query: {
                                evectionNo: res.datas.evectionNo,
                                redirectType: '000',
                            }
                        });
                    }).catch(() => {
                        Toast.clear();
                    });
                }
            })
        },
    }
}
