import consumer_approveFlow_matchingByReserve from '@/lib/data-service/haolv-default/consumer_approveFlow_matchingByReserve'
export default {
    data() {
        return {
            nodeList: [],
        }
    },
    props: {
        businessRuleControl: {
            type: [Number, String],
            default: 1,
        }
    },
    components: {},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        init() {
            let nodeList = [];
            let hasNodeList = false;
            return new Promise((resolve, reject) => {
                consumer_approveFlow_matchingByReserve().then(res => {
                    nodeList = res.datas.nodeList;
                    if (nodeList && nodeList.length > 0) {
                        hasNodeList = true;
                        nodeList.forEach(value => {
                            value.text = '审批通过'
                        });
                        nodeList.unshift({
                            nodeName: '创建订单',
                            text: '提交审批'
                        });
                        nodeList.push({
                            nodeName: '预订完成',
                            text: ''
                        });
                        this.nodeList = nodeList
                    }

                    resolve(hasNodeList)
                }).catch(() => {
                    resolve(hasNodeList)
                })
            })
        },
    }
}
