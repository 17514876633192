import import_latest_version_module from '@/component/base-layout/import_latest_version_module';

export default {
    path: '',
    component: () => import_latest_version_module(),
    children: [
        {
            path: '/demo/sample-data-list',
            name: 'demo-sample-data-list',
            component: () => import(/* webpackChunkName: "page-demo-sample-data-list" */ `./1.0.1/index.vue`),
            meta: {
                data_for_base_layout: {
                    header: {
                        show: true,
                        title: `示例数据`,
                    },
                    tab_bar: {
                        show: true,
                    }
                },

                data_for_all_routes_processor: {
                    order_index: `020-000`,
                },
            },
        },
    ],
};

