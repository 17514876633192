import {Toast} from "vant";
import NavBar from "@/component/nav-bar/import_latest_version_module";
// 车次时刻表显示组件
import TrainTimeTable
    from "yinzhilv-js-sdk/frontend/common-project/component/haolv-app/train-time-table/1.0.0/index.vue";
import keyback_processor from "@/lib/common-service/apicloud/keyback_processor";// 滑屏返回
import consumer_trains_order_applyRefundTicketDeatil
    from '@/lib/data-service/haolv-default/consumer_trains_order_applyRefundTicketDeatil'
import consumer_trains_order_applyForRefund from '@/lib/data-service/haolv-default/consumer_trains_order_applyForRefund'
import consumer_trains_order_getTrainsChangeOrderDetail
    from '@/lib/data-service/haolv-default/consumer_trains_order_getTrainsChangeOrderDetail'

export default {
    data() {
        return {
            detailInfo: {
                trainInfo: {
                    startDate: ''
                },
                bookingInfo: {
                    evectionTypeText: ''
                },
                receiveOption: {
                    serviceFee: 0
                }
            },
            reason: '',
            otherReason: '',
            reasonList: ['出差行程变化', '天气因素', '定错车次', '其他'],
            isShowReasonPopup: false, // 退票原因弹窗
        }
    },
    components: {
        NavBar,
        TrainTimeTable
    },
    activated() {
        this.init();

        let __this = this;
        keyback_processor.addEventListener(function () {
            if(__this.isShowReasonPopup) {
                __this.isShowReasonPopup = false;
                return;
            }

            const trainTimeTable = __this.$refs['aTrainTimeTable'];
            if (trainTimeTable.get_show()) {
                trainTimeTable.hide();
                return
            }

            __this.goBack();
        })
    },
    deactivated() {
        keyback_processor.removeEventListener();
    },
    watch: {
        reason() {
            this.isShowReasonPopup = false;
        }
    },
    filters: {
        format_date(val) {
            if (val) {
                return moment(val).format("YYYY-MM-DD HH:mm:ss");
            } else {
                return val
            }
        },
    },
    methods: {
        //初始化
        init() {
            this.orderNo = this.$route.query.orderNo;
            this.changeNo = this.$route.query.changeNo;
            this.passengerSn = this.$route.query.passengerSn;
            if (this.orderNo) {
                this.getDetail()
            } else {
                this.getChangeDetail()
            }
        },

        // 获取订单详情
        getDetail() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });

            let dataNo = this.$route.query.orderNo;

            if (dataNo.slice(0, 1) === 'O') {
                consumer_trains_order_applyRefundTicketDeatil({
                    orderNo: dataNo,
                    sn: this.passengerSn
                }).then((res) => {
                    Toast.clear();
                    let detailInfo = res.datas;
                    detailInfo.passengers.forEach(value => {
                        value.checked = false;
                    });
                    this.detailInfo = detailInfo;

                    this.initTimeTable();
                }).catch(() => {
                    Toast.clear()
                });
            } else {
                consumer_trains_order_getTrainsChangeOrderDetail({
                    orderNo: dataNo
                }).then((res) => {
                    Toast.clear();
                    let detailInfo = res.datas;
                    detailInfo.passengers.forEach(value => {
                        value.checked = false;
                    });
                    this.detailInfo = detailInfo;

                    this.detailInfo.trainNo = this.detailInfo.changeTrainCode;
                    this.detailInfo.fromStationName = this.detailInfo.changeFromStationName;
                    this.detailInfo.toStationName = this.detailInfo.changeToStationName;
                    this.detailInfo.startDate = this.detailInfo.changeStartDate;
                    this.detailInfo.startTime = this.detailInfo.changeStartTime;

                    this.initTimeTable();
                }).catch(() => {
                    Toast.clear()
                });
            }
        },

        // 获取改签单详情
        getChangeDetail() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
                duration: 0
            });
            consumer_trains_order_getTrainsChangeOrderDetail({orderNo: this.changeNo}).then(res => {
                Toast.clear();
                let orderInfo = res.datas;
                let trainItemInfo = {
                    trainNo: orderInfo.changeTrainCode, // 车次
                    fromStation: orderInfo.changeFromStationName, // 出发站
                    fromStationName: orderInfo.changeFromStationName, // 出发站
                    toStation: orderInfo.changeToStationName, // 到达站
                    toStationName: orderInfo.changeToStationName, // 到达站
                    seatName: orderInfo.changeSeatName, // 坐席
                    startDate: orderInfo.changeStartDate, // 出发日期
                    startTime: orderInfo.changeStartTime, //出发时间
                    arriveTime: orderInfo.changeArriveTime, // 到达时间
                    ticket: orderInfo.electronicTicketNumber
                };
                orderInfo.orderTrainArr = [trainItemInfo];
                let passengersList = [];
                orderInfo.passengers.forEach(value => {
                    passengersList.push(value.passengerName)
                });
                orderInfo.passengersList = passengersList.join(',');
                this.detailInfo = Object.assign({}, orderInfo, trainItemInfo)

                this.initTimeTable();
            }).catch(() => {
                Toast.clear()
            })
        },

        //初始化时刻表组件
        initTimeTable() {
            //初始化时刻表组件
            this.$refs['aTrainTimeTable'].init({
                //出发站三字码
                fromStationCode: this.detailInfo.fromStationCode,
                //到达站三字码
                toStationCode: this.detailInfo.toStationCode,
                //车次号
                trainCode: this.detailInfo.trainNo,
                //日期(2020-05-18)
                trainDate: this.detailInfo.startDate,
            })
        },

        //显示时刻表
        showTrainTimeTable() {
            this.$refs['aTrainTimeTable'].show();
        },

        //选择退票原因
        onSelectReason(index) {
            this.$refs.reasonRadio[index].toggle();
        },

        // 确定退票
        onSubmit() {
            if (this.reason === '') {
                Toast('请勾选退票原因');
                return;
            }

            if (this.reason === '其他' && this.otherReason.trim() === '') {
                Toast('请填写退票原因');
                return;
            }

            let _params = {
                orderNo: this.orderNo,
                passengerSn: this.detailInfo.passengers[0].sn,
                reason: this.reason === '其他' ? this.otherReason : this.reason,
            };

            Toast.loading({
                message: '处理中...',
                forbidClick: true,
                loadingType: 'spinner',
                duration: 0,
            });


            consumer_trains_order_applyForRefund(_params).then((res) => {
                Toast.clear();
                this.$router.replace({
                    name: 'h5-train-refund-success',
                    query: {
                        refundNo: res.refundNo
                    }
                });
            }).catch(err => {
                Toast.clear();
            });
        },

        //跳转至火车票说明
        toTrainInstructions() {
            this.$router.push({
                name: 'h5-train-instructions',
                query: {
                    active: 2,
                    anchor: 3,
                }
            });
        },

        //返回
        goBack() {
            this.$router.go(-1);
        }
    }
}