

export default {

    data() {
        return {
            Off:null
        };
    },
    methods: {

    },
    created() {

    },
    mounted() {
        this.Off = this.$route.query.id;
    },
    activated() {

    },
    deactivated() {

    },
    watch: {

    }
}
