import NavBar from '@/component/nav-bar/import_latest_version_module.js'
import consumer_country_queryCountryTwoWord
    from "@/lib/data-service/haolv-default/consumer_country_queryCountryTwoWord";
import consumer_admin_flight_generate_certificateType
    from "@/lib/data-service/haolv-default/consumer_admin_flight_generate_certificateType";
// 添加外部客户
import consumer_web_externalCustomer_addExternalCustomer from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_addExternalCustomer.js';
// 修改外部客户
import consumer_web_externalCustomer_updateExternalCustomer from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_updateExternalCustomer.js';
// 外部客户详情
import consumer_web_externalCustomer_getCustomerDetail from '@/lib/data-service/haolv-default/consumer_web_externalCustomer_getCustomerDetail.js';
export default {
    data() {
        var validateRealID = () => {
            const idCard = this.form.certificates.find(item => {
                return item.certificateType === 1;
            })
            let val = idCard.certificateValue;
            if (val.length != 0) {
                let patternOne = /^[1-9][0-9]/;
                let patternTwo = /^(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)$/;
                let patternThree = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                let provs = {
                    11: "北京",
                    12: "天津",
                    13: "河北",
                    14: "山西",
                    15: "内蒙古",
                    21: "辽宁",
                    22: "吉林",
                    23: "黑龙江 ",
                    31: "上海",
                    32: "江苏",
                    33: "浙江",
                    34: "安徽",
                    35: "福建",
                    36: "江西",
                    37: "山东",
                    41: "河南",
                    42: "湖北 ",
                    43: "湖南",
                    44: "广东",
                    45: "广西",
                    46: "海南",
                    50: "重庆",
                    51: "四川",
                    52: "贵州",
                    53: "云南",
                    54: "西藏 ",
                    61: "陕西",
                    62: "甘肃",
                    63: "青海",
                    64: "宁夏",
                    65: "新疆",
                    71: "台湾",
                    81: "香港",
                    82: "澳门"
                };
                let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
                //身份证校验
                if (val.length !== 18) {
                    return false
                }
                if (!patternOne.test(val.substring(0, 2))) {
                    return false
                }
                if (provs[val.substring(0, 2)] == undefined) {
                    return false
                }
                if (!patternTwo.test(val.substring(6, 14))) {
                    return false
                } else {
                    let year = val.substring(6, 10);
                    let month = val.substring(10, 12);
                    let date = val.substring(12, 14);
                    let dateTime = new Date();
                    let date2 = new Date(year + "-" + month + "-" + date);

                    if (dateTime <= date2) {
                        return false
                    }
                    if (!(date2 && date2.getMonth() == (parseInt(month) - 1))) {
                        return false
                    }
                }
                if (!patternThree.test(val)) {
                    return false
                } else {
                    let sum = 0;
                    let code = val.substring(17);
                    for (let i = 0; i <= 16; i++) {
                        sum = sum + val[i] * factor[i];
                    }
                    if (parity[sum % 11] != code) {
                        return false
                    }
                }
                return true
            } else {
                return true
            }
            return true
        };
        return {
            title: '新增外部客户',
            id: '',
            userId: '',//用户id
            type: 'add', // add 新增 edit 编辑
            form: {
                customerName: '',
                familyName: '',
                givenName: '',
                englishName: '',
                englishSurnames: '',
                birthDate: '',
                email: '',
                phone: '',
                customerSex: '',
                customerSexText: '',
                customerType: '',
                certificates: [
                    {
                        certificateType: 1,
                        certificateName: '身份证',
                        certificateValue: '',
                        issCountryCode: 'CN',
                        issCountryName: '中国CN',
                        passengerExpireDate: '',
                        def: true,
                    }
                ],
                id: '',
                nationality: 'CN',
                countryName: '中国CN',
                remark: '',
            },
            rules: {
                phone: [
                    {required: true, message: "请输入手机号"},
                ],
                familyName: [
                    {required: true, message: "请输入姓"},
                ],
                givenName: [
                    {required: true, message: "请输入名"},
                ],
                englishSurnames: [
                    {required: true, message: "请输入英文姓"},
                ],
                englishName: [
                    {required: true, message: "请输入英文名"},
                ],
                customerSex: [
                    {required: true, message: "请选择性别"},
                ],
                birthDate: [
                    {required: true, message: "请选择出生日期"},
                ],
                countryName: [
                    {required: true, message: "请选择国籍"},
                ],
                idCardNum: [
                    {required: true, message: "请输入证件号码"},
                    {validator: validateRealID, message: "请输入正确证件号码"},
                ],
                certificate: [
                    {required: true, message: "请输入正确证件号码"},
                ],
                issCountryCode: [
                    {required: true, message: "请选择发证国家"},
                ],
                passengerExpireDate: [
                    {required: true, message: "请选择证件有效期"},
                ],
            },

            birthday: new Date(),//出生日期
            countryCode: 'CN',//国籍二字码
            countryName: '中国CN',//国籍名
            expireDate: new Date(),//有效期限

            isShowGenderPopup: false,//是否显示选择性别弹框
            isShowBirthdayPopup: false,//是否显示选择出生日期弹框
            isShowCountryPopup: false,//是否显示选择国籍弹框
            isShowCardTypePopup: false,//是否显示证件类型弹框
            isShowIssCountryPopup: false,//是否显示发证国弹框
            isShowExpireDatePopup: false,//是否显示证件有效期
            currCertificateIndex: 0,//当前编辑的证件序号
            newCardTypeId: '',//新证件id
            newCardTypeName: '',//新证件名称

            staffSexList: [
                {name: 1, text: "男"},
                {name: 2, text: "女"},
            ],//性别枚举list
            countryList: [],//国家枚举list
            cardTypeList: [],//证件类型枚举list
            minDate: this.$moment().add(-100, 'years')._d,//出生日期可选的最小日期
            maxDate: this.$moment()._d,//出生日期可选的最大日期
            minExpireDate: this.$moment().add(-1, 'day')._d,//证件有效期可选的最小日期
            maxExpireDate: this.$moment().add(+100, 'years')._d,//证件有效期可选的最小日期
        }
    },
    components: {
        NavBar
    },
    created() {
    },
    mounted() {
        // this.getCertificateType();
        this.queryCountryTwoWord();
    },
    async activated() {
        await this.init();
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        onShowGenderPopup() {
            this.isShowGenderPopup = true;
            this.$nextTick(() => {
                this.$refs.genderPicker.setIndexes([this.form.customerSex - 1])
            })
        },
        onShowBirthdatePopup() {
            this.isShowBirthdayPopup = true;
            this.birthday = new Date(this.form.birthDate)
        },
        onShowCountryPopup() {
            this.isShowCountryPopup = true;
            this.$nextTick(() => {
                let activeIndex = 0
                this.countryList.find((country, index) => {
                    if (this.form.countryName === country.cnName) {
                        activeIndex = index;
                        return country;
                    }
                })
                this.$refs.countryPicker.setIndexes([activeIndex])
            })
        },
        //当前获得焦点的元素，失去焦点，阻止移动端软键盘弹出
        focusFun() {
            document.activeElement.blur();
        },
        deleteCertificate(val, index) {
            this.form.certificates.splice(index, 1);
            this.cardTypeList.forEach(item => {
                if (item.code === val.certificateType) {
                    item.isSelected = false;
                }
            })
            if(!this.form.certificates || this.form.certificates.length === 0) {
                this.countryCode = 'CN'
                this.countryName = '中国CN'

                this.form.countryCode = 'CN'
                this.form.countryName = '中国'
            }
        },
        addCertificate() {
            if (this.form.certificates.length >= 3) {
                this.$toast('证件不能超过三个');
                return
            }
            this.newCardTypeId = '';
            this.newCardTypeName = '';
            // this.cardTypeList.forEach(value => )
            this.cardTypeList.forEach(value => {
                value.isSelected = false;
                this.form.certificates.forEach(value1 => {
                    if (value.code == value1.certificateType) {
                        value.isSelected = true;
                    }
                })
            })
            this.isShowCardTypePopup = true;
        },
        //显示发证国弹框
        onShowIssCountry(certificate, index) {
            if ([12].indexOf(certificate.certificateType) > -1) {
                return
            }
            this.isShowIssCountryPopup = true;
            this.currCertificateIndex = index;
            this.$nextTick(() => {
                if (certificate.certificateType === 1 || !certificate.issCountryCode) {
                    return;
                }
                const code = certificate.issCountryCode;
                let activeIndex = 0
                let item = {};
                for (let i = 0, l = this.countryList.length; i < l; i++) {
                    const active = this.countryList[i];
                    if (code === active.code) {
                        item = active;
                        activeIndex = i
                    }
                }
                this.$refs.issPicker.setIndexes([activeIndex])
            })
        },
        //显示选择证件有效期弹框
        onShowExpireDatePopup(certificate, index) {
            this.isShowExpireDatePopup = true;
            this.currCertificateIndex = index;
            if (certificate.passengerExpireDate) {
                this.expireDate = new Date(certificate.passengerExpireDate)
            } else {
                this.expireDate = new Date()
            }
        },
        //获取国籍枚举值
        async queryCountryTwoWord() {
            let res = await consumer_country_queryCountryTwoWord();
            this.countryList = res.datas
        },

        //获取证件枚举值
        async getCertificateType() {
            let res = await consumer_admin_flight_generate_certificateType();
            this.cardTypeList = res.datas;
        },
        //选择性别
        confirmGender(val) {
            this.isShowGenderPopup = false;
            this.form.customerSex = val.name;
            this.form.customerSexText = val.text;
        },

        //修改出生日期
        changeBirthday(val) {
            if (val) {
                this.form.birthDate = this.$moment(val).format("YYYY-MM-DD");
                this.birthday = new Date(this.form.birthDate)
            }
            this.isShowBirthdayPopup = false;
        },
        //格式化日期选择控件里的内容
        formatter(type, val) {
            if (type === 'year') {
                return `${val}年`;
            } else if (type === 'month') {
                return `${val}月`;
            } else if (type === 'day') {
                return `${val}日`;
            }
            return val;
        },
        //选择国籍
        onCountryConfirm(val) {
            console.log(val);
            this.countryCode = val.code;
            this.countryName = val.cnName + val.code;
            this.form.countryCode = val.code;
            this.form.nationality = val.code;
            this.form.countryName = val.cnName + val.code;
            this.isShowCountryPopup = false;
        },
        //选择证件类型
        onUseCardTypeConfirm(val) {
            let selectedItem = this.form.certificates.find(item => {
                return val.code === item.certificateType;
            });
            if (selectedItem) {
                return;
            }

            this.newCardTypeId = val.code;
            this.newCardTypeName = val.msg;
            let certificate = {
                certificateType: val.code,
                certificateName: val.msg,
                certificateValue: "",
                issCountryCode: 'CN',
                issCountryName: '中国CN',
                passengerExpireDate: '',
                def: false,
            }
            this.form.certificates.push(certificate)
            setTimeout(() => {
                this.isShowCardTypePopup = false;
            }, 300)
            setTimeout(() => {
                val.isSelected = true;
            }, 800)
        },
        //选择发证国
        onIssCountryConfirm(val) {
            let index = this.currCertificateIndex;
            this.form.certificates[index].issCountryCode = val.code;
            this.form.certificates[index].issCountryName = val.cnName + val.code;
            this.isShowIssCountryPopup = false;
        },
        //初始化
        async init() {
            this.id = this.$route.query.id;
            this.userId = this.$route.query.userId;
            this.type = this.$route.query.type;
            this.title = this.type === 'add' ? '新增外部客户' : '编辑外部客户';
            this.reactForm();
            await this.getCertificateType();
            if (this.type == 'add') {

            } else {
                await this.getDetail();
            }

        },
        reactForm() {
            this.form = {
                customerName: '',
                    familyName: '',
                    givenName: '',
                    englishName: '',
                    englishSurnames: '',
                    birthDate: '',
                    email: '',
                    phone: '',
                    customerSex: '',
                    customerSexText: '',
                    customerType: '',
                    certificates: [
                    {
                        certificateType: 1,
                        certificateName: '身份证',
                        certificateValue: '',
                        issCountryCode: 'CN',
                        issCountryName: '中国CN',
                        passengerExpireDate: '',
                        def: true,
                    }
                ],
                    id: '',
                    nationality: 'CN',
                    countryName: '中国CN',
                    remark: '',
            }
        },
        //选择证件有效期
        changeExpireDate(val) {
            let index = this.currCertificateIndex;
            this.form.certificates[index].passengerExpireDate = this.$moment(val).format("YYYY-MM-DD");
            this.isShowExpireDatePopup = false;
        },
        cardIdChange(ev, index) {
            const certificates = this.form.certificates[index];
            const value = ev.target.value;
            console.log(value);
            if (certificates.certificateType !== 1 || !value) return;
            // 使用正则表达式匹配身份证号码的倒数第二位数字
            const lastDigit = value.match(/\d(?=[^\d]*$)/)[0];
            // 判断奇偶性，并返回性别
            if (lastDigit % 2 === 0) {
                this.form.customerSex = 2;
                this.form.customerSexText = "女";
            } else {
                this.form.customerSex = 1;
                this.form.customerSexText = "男";
            }
            this.form.birthDate = value.slice(6, 10) + '-' + value.slice(10, 12) + '-' + value.slice(12, 14);

            console.log(this.form);
        },
        async getDetail() {
            console.log('2');
            this.$showLoading.show();
            let res = await consumer_web_externalCustomer_getCustomerDetail({id: this.id, userId: this.userId});
            console.log('3');
            this.$showLoading.hide();
            const detail = res.datas;
            const form = JSON.parse(JSON.stringify(this.form));
            form.familyName = detail.familyName || '';
            form.givenName = detail.givenName || '';
            form.customerName = detail.customerName;
            if (form.familyName === '' && form.givenName === '' && form.customerName !== '') {
                form.givenName = form.customerName;
            }
            form.englishSurnames = detail.englishSurnames || '';
            form.englishName = detail.englishName || '';
            if (form.englishSurnames === '' && form.englishName === '' && detail.creatorName !== '') {
                form.englishName = detail.creatorName;
            }
            form.birthDate = detail.birthDate;
            form.email = detail.email;
            form.phone = detail.phone;
            form.customerSex = detail.customerSex;
            if (detail.customerSex && detail.customerSex === 1) {
                form.customerSexText = '男';
            }
            if (detail.customerSex && detail.customerSex === 2) {
                form.customerSexText = '女';
            }
            form.certificates = detail.certificates;
            form.certificates.forEach((item) => {
                if (item.def == 1) {
                    item.def = true
                } else {
                    item.def = false;
                }
                item.certificateType = item.certificateType;
                item.issCountryCode = item.issCountryCode || 'CN'
                this.cardTypeList.forEach(value => {
                    if (item.certificateType == value.code) {
                        item.certificateName = value.msg;
                    }
                })
                if (item.passengerExpireDate) {
                    item.passengerExpireDate = this.$moment(item.passengerExpireDate).format('yyyy-MM-DD');
                }
            })
            form.id = detail.id;
            form.nationality = detail.nationality || 'CN';
            form.remark = detail.remark;
            this.form = form;
        },
        changeDefult(val, index) {
            console.log(val, index);
            let certificates =  this.form.certificates;
            certificates.forEach((value, index1) => {
                if (index1 == index) {
                    value.def = true;
                } else {
                    value.def = false;
                }
            });
            this.$set(this.form, 'certificates', certificates);
        },
        commit() {
            if (this.form.certificates.length === 0) {
                this.$Toast.fail('请添加至少一项证件信息');
                return;
            }
            this.$refs.form.validate().then((res) => {
                this.$showLoading.show();
                const userForm = JSON.parse(JSON.stringify(this.form));
                userForm.customerName = userForm.familyName + userForm.givenName;
                userForm.certificates.forEach(value=>{
                    if (value.def) {
                        value.def = 1;
                    } else {
                        value.def = 0;
                    }
                })
                // 提交
                let submitFun = this.type === 'add' ? consumer_web_externalCustomer_addExternalCustomer(userForm) : consumer_web_externalCustomer_updateExternalCustomer(userForm);
                submitFun.then(res=>{
                    this.$showLoading.hide();
                    this.$Toast.success(this.type === 'add' ? "添加成功" : "修改成功");
                    this.back();
                })
            })
        },
        back() {
            this.$router.go(-1);
        }
    }
}
