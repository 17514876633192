import consumer_flight_search_getEquityProductList from '@/lib/data-service/haolv-default/consumer_flight_search_getEquityProductList';
import {awaitWrap} from "@/page/travel-book/flight/common/unit";
import NavBar from "@/component/nav-bar/import_latest_version_module";
export default {
    data() {
        return {
            orderNo: '',
            productList: [],
            active: '',
            upLevelInfoDetailShow: false,
            readActiveDetail: {},
        }
    },
    props: {},
    components: {NavBar},
    created() {
    },
    mounted() {
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {},
    filters: {},
    methods: {
        /**
         * 初始化
         * 入参
         * params： obj
         * {
         *     orderNo: String, 必填，
         * }
         * */
        async init(params) {
            if (!params) {
                return;
            }
            this.orderNo = params.orderNo;
            await this._getProductList(this.orderNo);
        },
        async _getProductList(orderNo) {
            const params = {
                orderNo,

            }
            let [err, res] = await awaitWrap(consumer_flight_search_getEquityProductList(params));
            this.productList = res.datas;
        },
        _submit(item) {
            let active = null;
            if (item.productId === this.active) {
                //取消
                this.active = '';
            } else {
                this.active = item.productId;
                active = item;
            }
            // 外推
            this.$emit('change', active)
        },
        _showDetail(item) {
            this.readActiveDetail = item;
            this.upLevelInfoDetailShow = true;
        },
    }
}
