// approveType：1出差单，2超标单，3报销单，4改签单，5退订单
// type：'detail' 查看， 'approve' 审核
import moment from "moment";
// 彩色icon组件
import ColorIcon from "@/component/color-icon/index.vue";
// 出差申请单详情
import evection_detail from "@/lib/data-service/haolv-default/consumer_apply_detail.js";
import consumer_apply_workflowDetail from '@/lib/data-service/haolv-default/consumer_apply_workflowDetail';
// 审批出差单
import apply_aduitEvection from "@/lib/data-service/haolv-default/consumer_apply_aduitEvection.js";
// 超标单详情
import overproof_detail from "@/lib/data-service/haolv-default/consumer_overproof_detail.js";
// 审批超标单
import apply_aduitOverproof from "@/lib/data-service/haolv-default/consumer_apply_aduitOverproof.js";
// 改签单详情
import change_detail from "@/lib/data-service/haolv-default/consumer_journey_getApplyChange.js";
// 改签单审批
import apply_change from "@/lib/data-service/haolv-default/consumer_apply_aduitChange.js";
// 退订单详情  type 1酒店退订， 2机票退订， 3火车票退订
import apply_getApplyRefund from "@/lib/data-service/haolv-default/consumer_journey_getApplyRefund.js";
// 审批退订单
import apply_aduitRefund from "@/lib/data-service/haolv-default/consumer_apply_aduitRefund.js";
import store from '@/lib/store';
//  请求员工数据
import consumer_department_getDepartmentUsers from "@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers";
import consumer_approveFlow_getApproveNodes from '@/lib/data-service/haolv-default/consumer_approveFlow_getApproveNodes'
// 检测价格变价
import consumer_apply_fareAlert from '@/lib/data-service/haolv-default/consumer_apply_fareAlert'
import {approveFlow_matching, formatStartNodeShow, operablePermissions, getProcessInstanceInfo} from "@/lib/data-service/haolv-default/design";
// 选择出行人弹窗组件
import Transfer from "@/page/travel-book/flight/component/transfer/import_latest_version_module";
import {Toast} from "vant";
import NavBar from "@/component/nav-bar/import_latest_version_module";
// 新审批流操作
import AgreeModal from './components/process/AgreeModal.vue';
import AddMultiModal from './components/process/AddMultiModal.vue';
import DelegateModal from './components/process/DelegateModal.vue';
import AssigneeModal from './components/process/AssigneeModal.vue';
import ResolveModal from './components/process/ResolveModal.vue';
import RefuseModal from './components/process/RefuseModal.vue';
import RollbackModal from './components/process/RollbackModal.vue';
import RevokeModal from './components/process/RevokeModal.vue'
import DeleteMultiModal from './components/process/DeleteMultiModal.vue';
import ApprovalProgressForWorkflow from "@/component/approval-progress-for-workflow/index.vue";

//TODO:看到这个找lyn
//请求要先做好验证
import open from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/open_window";
import DownloadAttachment from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/download_attachment";
import PreviewAttachment from "yinzhilv-js-sdk/frontend/common-project/lib/common-service/apicloud/preview_attachment";

export default {
  components: {
    CollapseCard: () => import('./components/collapse-card/index.vue'),
    ColorIcon,
    Transfer,
    NavBar,
    AgreeModal,
    AddMultiModal,
    DelegateModal,
    AssigneeModal,
    ResolveModal,
    RefuseModal,
    RollbackModal,
    RevokeModal,
    DeleteMultiModal,
    ApprovalProgressForWorkflow
  },
  data() {
    return {
      budgetDetailSwitch: false,
      subsidyControlRule: 0,//预算管控 0：不开启 1：开启（只允许按出差单申请金额内预订行程） 默认：0
      intervalFun: null,
      loading: false,
      detail: null,
      failVisible: false,
      rejectReason: "",
      buttonOff: false,
      evectionFold: true,
      approveType: "",
      list: [{}, {}, {}, {}],
      userId: '',
      annexImages: {
        image: 'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/06/16/image.png?x-oss-process=style/haolv-default',
        word: 'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/06/16/word.png?x-oss-process=style/haolv-default',
        excel: 'https://haolv-enterprise-bucket.oss-cn-shenzhen.aliyuncs.com/2021/06/16/excel.png?x-oss-process=style/haolv-default',
      },
      travelIcons: {
        1: '#haolv-enterprise-app-iconflight_round_30', // 机票
        2: '#haolv-enterprise-app-icontrain_round_30', // 火车票
        3: '#haolv-enterprise-app-iconhotel_round_30', // 酒店
        4: '#haolv-enterprise-app-iconuseCar_round_30', // 酒店
      },
      travelTypeText: {
        1: '机票',
        2: '火车票',
        3: '酒店',
        4: '用车',
      },

      staffVisible: false,
      bookUpperBound: 1,//出行人数上限
      activeUserIds: [], //出行人弹窗已选中员工id
      staffAllList: [], //所有员工数据(一维数组)
      staffTreeList: [], //构造好的部门树形数据

      dialogShow: false,
      staffList: [],
      dialogChangeShow: false,
      staffRadio: 1,

      isFinish: true,

      priceChangeShow: false, // 变价弹窗开关
      changePriceInfo: {}, // 变价信息

      workflowList: [], // 新版审批流流程数组
      opaList: [], // 新版审批流操作按钮权限列表
      processInfo: {}, // 提交操作表单
      opaShow: false,
      modalConfig: {
        agreeVisible: false,
        commentVisible: false,
        delegateVisible: false,
        assigneeVisible: false,
        resolveVisible: false,
        refuseVisible: false,
        rollbackVisible: false,
        addMultiVisible: false,
        queryMultiUserVisible: false,
        revokeVisible: false,
        deleteMultiVisible: false
      },
    };
  },
  computed: {
    showMoreOpa() {
      let moreBtnList = [];
      let moreBtn = ['reject', 'frontJoin', 'backJoin', 'addAssignee', 'delAssignee'];
      this.opaList.forEach(value => {
        if (moreBtn.indexOf(value.key) > -1 && value.checked) {
          moreBtnList.push(value);
        }
      })
      return moreBtnList;
    },
    currentProcessInfo: {
      get () {
        return this.processInfo
      },
      set (val) {
        this.$emit('update:processInfo', val)
      }
    },
    evectionTime(){
      let startDate = this.$moment(this.detail.evection.startDate + ' 00:00:00');
      let endDate = this.$moment(this.detail.evection.endDate + ' 00:00:00').add(1,"days");

      return moment(endDate).diff(startDate, 'days')
    },
    currentPageName() {
      // 1出差单，2超标单，3报销单，4改签单，5退订单，7审批详情，8改签审批，9退订审批
      switch (Number(this.$route.query.approveType)) {
        case 1:
          return "出差单详情";
        case 2:
          return "超标单详情";
        case 3:
          return "报销单详情";
        case 4:
          return "改签单详情";
        case 5:
          return "退订单详情";
        case 8:
          return "改签审批";
        case 9:
          return "退订审批";
        default:
          return "审批详情";
      }
    },

    showAudit() {
      const recordList = this.detail.recordList;
      const userId = this.userId;
      if (this.$route.query.type === 'approve' && userId && recordList.length) {
        let approveStatus = '';
        recordList.forEach((item) => {
          if (item.approverUserId === userId) approveStatus = item.approveStatus;
        });

        return approveStatus === 1;
      } else {
        return false;
      }
    },
  },
  methods: {
    clickShowBudget() {
      this.$router.push({
        name: 'h5-approve-budget',
        query: {
          evectionNo: this.detail.evection.evectionNo ? this.detail.evection.evectionNo : '',
        }
      })
    },
    getDays (depDate,arrDate){
      // depDate = '2024-01-15';
      // arrDate = '2024-01-16';
      return moment(arrDate).diff(depDate, 'day')
    },
    intervalFunInterval(){
      if (this.intervalFun) {
        clearInterval(this.intervalFun);
      }
    },
    tripNum(numBox,index){
      let flightNum = 0;
      let trainNum = 0;
      let hotelNum = 0;

      if (this.detail.flightDetail == null) {
        flightNum = 0;
      } else {
        flightNum = this.detail.flightDetail.flightInfoList.length;
      }
      if (this.detail.trainDetail == null) {
        trainNum = 0;
      } else {
        trainNum = this.detail.trainDetail.trainInfoList.length;
      }
      if (this.detail.hotelDetail == null) {
        hotelNum = 0;
      } else {
        hotelNum = 1;
      }

      if (numBox == 1) {
        return index + 1
      } else if (numBox == 2) {
        return flightNum + index + 1
      }else if (numBox == 3) {
        return flightNum + trainNum + index + 1
      }
    },
    //机票--改签--详情
    toTypeServeFlightChange(data){
      this.intervalFunInterval();
      this.$router.push({
        name: 'flight-change-order-detail',
        query: {
          orderNo: data.chaOrderNo,
          psgNoList: data.psgNo,
          evectionType: this.detail.evection.evectionType
        }
      })
    },
    //机票--退票--详情
    toTypeServeFlightRespone(data){
      this.intervalFunInterval();
      this.$router.push({
        name: 'flight-refund-order-detail',
        query: {
          orderNo: data.refOrderNo,
          evectionType: data.evectionType === '出差申请' ? 2 : 1
        }
      })
    },
    //火车票--改签--详情
    toTypeServeTrainChange (data) {
      this.intervalFunInterval();
      this.$router.push({
        name: 'h5-train-change-order-detail',
        query: {
          orderNo: data.changeNo
        }
      })
    },
    //火车票--退票--详情
    toTypeServeTrainRefund(data){
      this.intervalFunInterval();
      this.$router.push({
        name: 'h5-train-refund-form-detail',
        query: {
          refundNo: data.refundNo
        }
      })
    },
    //酒店--退票--详情
    toTypeServeHotel (data){
      this.intervalFunInterval();
      this.$router.push({
        name: 'h5-hotel-refund-order-detail',
        query: {
          orderNo: data.refOrderNo,
          jumpType: 'go(-1)'
        }
      })
    },
    toTypeServe(val,data){
      if (val === 1) {
        this.intervalFunInterval();
        this.$router.push({
          name: 'flight-order-detail',
          query: {
            orderNo: data.orderNo
          }
        })
      }
      if (val === 2) {
        this.intervalFunInterval();
        this.$router.push({
          name: 'h5-train-order-detail',
          query: {
            orderNo: data.orderNo
          }
        })
      }
      if (val === 3) {
        this.intervalFunInterval();
        this.$router.push({
          name: 'h5-hotel-order-detail',
          query: {
            orderNo: data.orderNo,
            jumpType: 'go(-1)'
          }
        })
      }
      if (val.businessType === 4) {

      }
    },
    checkDownload(url){
      let pParameter = {
        url: url,
      };
      // DownloadAttachment.download_attachment(pParameter);
      // DownloadAttachment(pParameter);
      window.open(url);
    },
    // 请求详情
    req_detail() {
      const approveType = this.approveType;
      const id = this.$route.query.id;
      const applyNo = this.$route.query.applyNo || '';
      const selectType = parseInt(this.$route.query.selectType) || '';
      // let loadingDetail = this.$Toast.loading({
      //   duration: 0,
      //   message: "请求中...",
      //   forbidClick: true,
      // });
      this.$showLoading.show();

      let myRequest = (myMethod, params) => {

        myMethod(params)
          .then((res) => {
            // loadingDetail.clear();
            this.$showLoading.hide();
            let detail = res.datas;
            let off = 0;
            let isFinish = false;
            let recordList = [];
            if (approveType === 2) {
              if (detail.evectionDetailResponse.businessType === 1) {
                recordList = detail.tusApproveRecords
              } else if (detail.evectionDetailResponse.businessType === 2) {
                recordList = detail.tusApproveRecords
              } else if (detail.todHotelOrderInterimResponse != null) {
                recordList = detail.tusApproveRecords
              }
            } else {
              recordList = detail.recordList
            }
            if (recordList) {
              recordList.forEach((item,index) => {
                if (item.approveStatus == 2 || item.approveStatus == 3) {
                  off++;
                }
                if (item.approveStatus == 2 || item.approveStatus == 3) {
                  isFinish = true
                } else {
                  isFinish = false
                }
                this.isFinish = isFinish;
              });
              detail.recordList = recordList;
            }


            // 出差申请
            if (approveType === 1) {
              if (detail.evection.accessoryUrl) {
                detail.fileList = detail.evection.accessoryUrl
                  .split(",")
                  .map((item) => {
                    const imgArr = ["png", "jpg", "jpeg", "gif"];
                    const wordArr = ["doc", "docx"];
                    const pdfArr = ["pdf"];
                    const excelArr = ["xls", "xlsx"];

                    let obj = {};
                    const type = item.slice(item.lastIndexOf(".") + 1);
                    if (imgArr.includes(type)) {
                      obj.type = "image";
                    } else if (wordArr.includes(type)) {
                      obj.type = "word";
                    } else if (pdfArr.includes(type)) {
                      obj.type = "pdf";
                    } else if (excelArr.includes(type)) {
                      obj.type = "excel";
                    }

                    obj.url = item;
                    obj.name = item.slice(
                      item.lastIndexOf("/") + 1,
                      item.lastIndexOf(".")
                    );
                    return obj;
                  });
              } else {
              }


            }

            // 改签申请
            if (approveType === 4 || approveType === 8) {
              const type = detail.type;
              // 机票
              if (type === 4) {
                if (detail.reasonChange && detail.reasonChange.url) {
                  let urlName = detail.reasonChange.url.split('/')[detail.reasonChange.url.split('/').length - 1];
                  detail.reasonChange.urlName = `${urlName}`.length > 13 ? `${urlName.slice(0,12)}...` : `${urlName}`;
                }
                // 火车票
              } else if (type === 5) {
                if (detail.trainsOrderChangeDetailResponse.passengers) {

                } else {

                }
              }
            }

            // 退订申请
            if (approveType === 5 || approveType === 9) {
              const type = detail.type;
              // 酒店
              if (type === 1) {
                // 机票
              } else if (type === 2) {
                if (detail.reasonRefund && detail.reasonRefund.url) {
                  let urlName = detail.reasonRefund.url.split('/')[detail.reasonRefund.url.split('/').length - 1];
                  detail.reasonRefund.urlName = `${urlName}`.length > 13 ? `${urlName.slice(0,12)}...` : `${urlName}`;
                }
                // 火车票
              } else if (type === 3) {

              }
            }

            if (approveType === 7) {
              if (detail.flightDetail != null) {
                if (detail.flightDetail.milliseconds > 0) {
                  this.setPayIntervalFlight()
                }
              }
              if (detail.trainDetail != null) {
                if (detail.trainDetail.milliseconds > 0) {
                  this.setPayIntervalTrain()
                }
              }
              if (detail.hotelDetail != null) {
                if (detail.hotelDetail.milliseconds > 0) {
                  this.setPayIntervalHotel()
                }
              }
            }

            this.detail = detail;

            if (this.detail.isnotApproved) {
              this.buttonOff = true;
              // 设置底部安全高度
            } else {
              this.buttonOff = false;
            }

            if (this.$store.state.workflow === '1') {
              console.log('dd', this.detail)
              formatStartNodeShow({
                processInstanceId: this.$route.query.applyNo,
                userId: this.$store.state.userInfo.id,
                deptId: this.$store.state.userInfo.deptId,
                budgetAmount: this.detail.evection.budgetAmount || this.detail.budgetAmount || 0,
              }).then(res=>{
                let list = res.result;
                let result = [];
                list.forEach((value, index)=>{
                  if (value.showTimeStr && value.showTimeStr !== 'null') {
                    value.showTimeStr = moment(value.showTimeStr).format('yyyy-MM-DD HH:mm:ss');
                  } else {
                    value.showTimeStr = '';
                  }
                  result.push(value);
                })
                console.log(result);
                this.workflowList = result;
              })

              this.processInfo = {
                processInstanceId: applyNo,
                taskId: res.datas.approveApply.taskId,
                signFlag: null,
                currentUserInfo: {
                  id: this.$store.state.userInfo.id,
                  name: this.$store.state.userInfo.realName,
                },
              }
              // 只有审批中才能调
              console.log('6', res.datas.approveApply.status, res.datas.approveApply.status, this.$route.query.listApproveStatus);
              if ((res.datas.approveApply.status === 1 || res.datas.approveApply.status === 3) && this.$route.query.listApproveStatus == 1) {
                operablePermissions({
                  processInstanceId: applyNo,
                  userId: this.$store.state.userInfo.id,
                  taskId: res.datas.approveApply.taskId
                }).then((result) => {
                  this.opaList = result.result || [];
                })
              }
              getProcessInstanceInfo(applyNo, res.datas.approveApply.taskId).then(resultInfo=>{
                const formData = { ...resultInfo.result.formData };
                console.log('formData', formData);
                this.processInfo.formData = formData;
              })
            }
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
          });
      };

      const  approveStatus =  Number(this.$route.query.approveStatus || '0');
      if (approveType === 1 || approveType === 7) {
        myRequest(
            this.$store.state.workflow === '1' ? consumer_apply_workflowDetail : evection_detail,
            {
              id,
              applyNo,
              appType: this.$route.query.type === 'applyDetail' ? 1 : 2,
              approveStatus: approveStatus,
              selectType,
            });
      } else if (approveType === 2) {
        myRequest(
            overproof_detail,
            { applyId: id,
              applyNo,
              appType: this.$route.query.type === 'applyDetail' ? 1 : 2,
              approveStatus: approveStatus,
              selectType,
            });
      } else if (approveType === 3) {
      } else if (approveType === 4 || approveType === 8) {
        myRequest(
            change_detail,
            {
              id,
              applyNo,
              appType: this.$route.query.type === 'applyDetail' ? 1 : 2,
              approveStatus: approveStatus,
              selectType,
            });
      } else if (approveType === 5 || approveType === 9) {
        myRequest(
            apply_getApplyRefund,
            {
              id,
              applyNo,
              appType: this.$route.query.type === 'applyDetail' ? 1 : 2,
              approveStatus: approveStatus,
              selectType,
            });
      }
      /*if (approveType === 1 || approveType === 4 || approveType === 5 && this.subsidyControlRule == 1) {
        consumer_web_staff_getButtonMenu().then(res => {
          if (res.datas.menuList[0].url == 'getBudgetDetailButton') {
            this.budgetDetailSwitch = true;
          }
        })
      }*/
    },
    setPayIntervalFlight() {
      this.intervalFun = setInterval(() => {
        this.detail.flightDetail.milliseconds--;
        if (this.detail.flightDetail.milliseconds <= 0) {
          clearInterval(this.intervalFun);
          this.cancelOrder()
        }
      }, 1000)
    },
    setPayIntervalTrain() {
      this.intervalFun = setInterval(() => {
        this.detail.trainDetail.milliseconds--;
        if (this.detail.trainDetail.milliseconds <= 0) {
          clearInterval(this.intervalFun);
          this.cancelOrder()
        }
      }, 1000)
    },
    setPayIntervalHotel() {
      this.intervalFun = setInterval(() => {
        this.detail.hotelDetail.milliseconds--;
        if (this.detail.hotelDetail.milliseconds <= 0) {
          clearInterval(this.intervalFun);
          this.cancelOrder()
        }
      }, 1000)
    },
    //超时处理
    cancelOrder() {
      this.req_detail();
    },
    // 审批请求
    req_approve(params) {
      this.$showLoading.show();
      const approveType = Number(this.$route.query.approveType);
      let myRequest = (myMethod) => {
        myMethod(params)
          .then((res) => {
            if (params.approveStatus === 2) {
              // toast.clear();
              this.$showLoading.hide();
              this.$Toast.success("审批通过成功");
            } else if (params.approveStatus === 3) {
              this.failVisible = false;
              // toast.clear();
              this.$showLoading.hide();
              this.$Toast.success("审批拒绝成功");
            }
            const _this = this;
            setTimeout(() => {
              _this.intervalFunInterval();
              _this.$router.replace({
                name: "h5-my-approve-list",
                query:{
                  id: 1
                },
              });
            }, 2000);
          })
          .catch((err) => {
            // this.$Toast.clear();
            this.$showLoading.hide();
          });
      };

      if (approveType === 1) {
        myRequest(apply_aduitEvection);
      } else if (approveType === 2) {
        myRequest(apply_aduitOverproof);
      } else if (approveType === 3) {
      } else if (approveType === 4 || approveType === 8) {
        myRequest(apply_change);
      } else if (approveType === 5 || approveType === 9) {
        myRequest(apply_aduitRefund);
      } else if (approveType === 7) {
        myRequest(apply_aduitEvection);
      }
    },

    init() {
      this.loading = true;
      this.$showLoading.show();
      this.approveType = Number(this.$route.query.approveType);
      this.req_staffList();
      this.req_detail();
      if (this.$route.query.type === 'approve') {
        this.userId = this.$store.state.userInfo.id;
        /*consumer_user_info().then((res) => {
          this.userId = res.datas.id;
        })*/
      }
    },
    onClickLeft() {
      this.intervalFunInterval();
      this.$router.back();
    },
    // 折叠按钮
    change_fold(key) {
      this[key] = !this[key];
    },

    // 去订单详情
    to_order_detail(orderNo, businessType) {
      let query = { orderNo };
      if (businessType === 1) query.type = 'review';
      this.intervalFunInterval();
      this.$router.push({
        name: `${businessType === 1
          ? "flight-order-detail"
          : businessType === 2
            ? "h5-train-order-detail"
            : businessType === 3
              ? "h5-hotel-order-detail"
              : ""
          }`,
        query,
      });
    },
    // 去出差单详情
    to_evection_order_detail(id) {
      this.intervalFunInterval();
      this.$router.push({
        name: "h5-approve-detail",
        query: {
          id,
          approveType: 1,
          type: "detail",
        },
      });
    },

    // 审批通过前校验
    approvePassBefore() {
      if (this.detail.approveType !== 7 && this.detail.approveType !== 8 && this.detail.approveType !== 9) {
        this.approve_pass();
        return
      }
      let fareAlertDetailList = [];
      if (this.detail.flightDetail && this.detail.flightDetail.orderNo) {
        fareAlertDetailList.push({
          orderNo: this.detail.flightDetail.orderNo,
          businessType: 1
        })
      }
      // if (this.detail.hotelDetail && this.detail.hotelDetail.orderNo) {
      //   fareAlertDetailList.push({
      //     orderNo: this.detail.hotelDetail.orderNo,
      //     businessType: 3
      //   })
      // }
      // if (this.detail.trainDetail && this.detail.trainDetail.orderNo) {
      //   fareAlertDetailList.push({
      //     orderNo: this.detail.trainDetail.orderNo,
      //     businessType: 2
      //   })
      // }
      // Toast.loading({
      //   duration: 0, // 持续展示 toast
      //   message: '',
      //   forbidClick: true,
      //   loadingType: 'spinner',
      // });
      if (fareAlertDetailList == []) {
        this.approve_pass()
      } else {
        this.$showLoading.show();
        consumer_apply_fareAlert({fareAlertDetailList}).then(res => {
          if (res.datas.isShow === 1) {
            // 展示变价弹窗
            this.changePriceInfo = res.datas;
            this.priceChangeShow = true;
          } else {
            this.approve_pass()
          }
        }).finally(() => {
          // Toast.clear()
          this.$showLoading.hide();
        })
      }
    },
    // 审批通过
    approve_pass() {
      // const approveType = Number(this.$route.query.approveType);
      const detail = this.detail;
      // 是否有审批流程
      if (detail.isHasFlow) {
        // 有
        // 是否最后节点
        if (detail.isLastNode) {
          // 是
          this.getApplyList([])
        } else {
          // 不是
          let params = {
            applyNo: detail.approveApply.applyNo,
            approveFlowId: detail.approveApply.approveFlowId
          };
          consumer_approveFlow_getApproveNodes(params).then(res => {
            let staffList = res.datas.staffs;
            staffList.forEach(value => {
              value.id = value.userId;
              value.value = value.userId;
              value.name = value.staffName;
              value.text = value.staffName
            });
            this.staffList = staffList;
            // 判断是否有下一个审批人
            if (this.staffList.length === 1) {
              this.getApplyList(this.staffList)
            } else if (this.staffList.length > 1) {
              // 有
              this.dialogShow = true;
              this.$nextTick(() => {
                this.$refs.applyPicker.setIndexes = [0]
              })
            } else {
              // 没有
              this.staffVisible = true
            }
          })
        }
      } else {
        // 没有审批流
        this.dialogChangeShow = true
      }



      // 是否最后节点
      /*if (detail.isLastNode) {
        // 是
        this.getApplyList([])
      } else {
        // 不是最后的节点
        // 是否有审批流程
        if (detail.isHasFlow) {
          // 有
          let params = {
            applyNo: detail.approveApply.applyNo,
            approveFlowId: detail.approveApply.approveFlowId
          }
          consumer_approveFlow_getApproveNodes(params).then(res => {
            debugger
            let staffList = res.datas.staffs
            staffList.forEach(value => {
              value.id = value.userId
              value.value = value.userId
              value.name = value.staffName
              value.text = value.staffName
            })
            this.staffList = staffList
            // 判断是否有下一个审批人
            if (this.staffList.length > 0) {
              // 有
              this.dialogShow = true
            } else {
              // 没有
              this.staffVisible = true
            }
          })
        } else {
          // 否
          // 判断是否需要下个审批人
          this.dialogChangeShow = true
        }
      }*/
      /*let params = {
        approveStatus: 2,
        isOver: true,
        nextApproverId: "",
        nextApproverName: "",
      };
      if (approveType === 1) {
        params.applyNo = detail.approveApply.applyNo;
      } else if (approveType === 2) {
        params.applyNo = detail.applyNo;
        params.evectionNo = detail.evectionNo;
      } else if (approveType === 3) {
      } else if (approveType === 4) {
        params.applyNo = detail.approveApply.applyNo;
      } else if (approveType === 5) {
        params.applyNo = detail.approveApply.applyNo;
      }
      this.$Dialog
        .confirm({
          title: "审批通过确认",
          message: "请您确认通过该申请单！",
          confirmButtonText: '确认通过',
          confirmButtonColor: '#FF9C00',
        })
        .then(() => {
          this.req_approve(params);
        })
        .catch(() => { });*/
    },
    // 审批拒绝
    approve_fail() {
      this.failVisible = true;
    },

    close_fail() {
      this.failVisible = false;
      this.$refs.failForm.resetValidation("rejectReason");
    },
    preview_file(url){
      let pParameter = {
        url: url,
      };
      PreviewAttachment(pParameter);
    },
    download_file(url) {
      let pParameter = {
        url: url,
      };
      // DownloadAttachment.download_attachment(pParameter);
      // DownloadAttachment(pParameter);
      window.open(url);

    },

    // 提交拒绝
    commit_fail() {
      this.$refs.failForm
        .validate()
        .then((res) => {
          const approveType = Number(this.$route.query.approveType);
          const detail = this.detail;
          let params = { approveStatus: 3, rejectReason: this.rejectReason };
          if (approveType === 1) {
            params.applyNo = detail.approveApply.applyNo;
          } else if (approveType === 2) {
            params.applyNo = detail.applyNo;
          } else if (approveType === 3) {
          } else if (approveType === 4 || approveType === 8) {
            params.applyNo = detail.approveApply.applyNo;
          } else if (approveType === 5 || approveType === 9) {
            params.applyNo = detail.approveApply.applyNo;
          } else if (approveType === 7) {
            params.applyNo = detail.approveApply.applyNo;
          }

          this.req_approve(params);
        })
        .catch((err) => {
          console.error(err, "err");
        });
    },
    // 返回
    back() {
      this.intervalFunInterval();
      if(this.$route.query.form == 'flutter') {
        // 判断是否在flutter app里
        if (window.getFlutter == true) {
          // 如果在，则向flutter app发送信息
          try {
            window.flutter_inappwebview
                //可以传递你所需要的任意类型数据，数组、对象等
                .callHandler("backHandler", {'type': 'close'})
                .then((res)=>{
                  console.log("res==flutter给html的数据", res);
                })
          } catch (e) {}

        }

      } else {
        this.$router.go(-1);
      }
    },
    // 请求部门员工数据与格式化
    req_staffList() {
      consumer_department_getDepartmentUsers()
          .then((result) => {
            let oldList = [];
            const newList = [];

            format_staff_list(result.datas);
            //this.getApproveFlow();

            function format_staff_list(list) {
              for (const item of list) {
                const department = {text: item.deptName, children: []};
                if (item.deptList && item.deptList.length)
                  format_staff_list(item.deptList);
                department.children = item.staffList.map((staff) => {
                  return {text: staff.subStaffName, id: staff.subUserId};
                });
                oldList = [...oldList, ...item.staffList];
                newList.push(department);
              }
            }

            this.staffAllList = oldList.map((staff) => {
              // const {subUserId: id, subStaffName: text, deptId, deptName} = staff;
              const {subUserId: id, subStaffName: text} = staff;
              // return { id, text, deptId, deptName };
              return {id, text};
            });
            this.staffTreeList = newList;
            // this.staffExternalCustomerTree = [
            //   ...newList,
            //   ...this.staffExternalCustomerTree,
            // ];
            // console.log(this.staffExternalCustomerTree)
          })
          .catch((err) => {
          });
    },

    // 取消预选出行人
    cancelChooseUser() {
    },
    // 确认预选出行人
    submitChooseUser() {
      this.staffVisible = false;
      let approver = [];
      for (const staff of this.staffAllList) {
        if (this.activeUserIds.includes(staff.id)) {
          staff.name = staff.text;
          approver.push(staff);
        }
      }

      if ( approver.length > 0 ) {
        if (approver.length >= 2) {
          Toast("只能选择一个审批人");
          return
        }
        this.getApplyList(approver)
      }
    },

    getApplyList(val) {
      const _this = this;
      const approveType = Number(this.$route.query.approveType);
      // this.$Toast.loading({
      //   duration: 0,
      //   message: "请求中...",
      //   forbidClick: true,
      // });
      this.$showLoading.show();
      let isOver = false;
      if (this.detail.isHasFlow) {
        isOver = this.detail.isLastNode
      } else {
        if (this.staffRadio === 1) {
          isOver = true
        } else {
          isOver = false
        }
      }
      if (approveType === 4  || approveType === 8) {
        const params = {
          applyNo: this.detail.approveApply.applyNo,
          evectionNo: "",
          approveStatus: 2,
          isOver,
          nextApproverId: val.length ? val[0].id : "",
          nextApproverName: val.length ? val[0].name : "",
          rejectReason: "",
        };
        apply_change(params)
            .then((res) => {
              this.$showLoading.hide();
              this.$Toast.success("审批通过成功");
              setTimeout(() => {
                _this.intervalFunInterval();
                _this.$router.replace({
                  name: "h5-my-approve-list",
                  query: {
                    id: 2
                  }
                });
              }, 2000);
            })
            .catch((err) => {
              // this.$Toast.clear();
              this.$showLoading.hide();
            });
      } else if (approveType === 5 || approveType === 9) {
        const params = {
          applyNo: this.detail.approveApply.applyNo,
          evectionNo: "",
          approveStatus: 2,
          isOver,
          nextApproverId: val.length ? val[0].id : "",
          nextApproverName: val.length ? val[0].name : "",
          rejectReason: "",
        };

        apply_aduitRefund(params)
            .then((res) => {
              this.$showLoading.hide();
              this.$Toast.success("审批通过成功");
              setTimeout(() => {
                _this.intervalFunInterval();
                _this.$router.replace({
                  name: "h5-my-approve-list",
                  query: {
                    id: 2
                  }
                });
              }, 2000);
            })
            .catch((err) => {
              // this.$Toast.clear();
              this.$showLoading.hide();
            });
      } else if (approveType === 2) {
        const params = {
          applyNo: this.detail.evectionApplyId,
          evectionNo: this.detail.evectionNo,
            orderNo: this.detail.orderNo,
          approveStatus: 2,
          isOver,
          nextApproverId: val.length ? val[0].id : "",
          nextApproverName: val.length ? val[0].name : "",
          rejectReason: "",
        };

        apply_aduitOverproof(params)
            .then((res) => {
              this.$showLoading.hide();
              this.$Toast.success("审批通过成功");
              setTimeout(() => {
                _this.intervalFunInterval();
                _this.$router.replace({
                  name: "h5-my-approve-list",
                  query: {
                    id: 2
                  }
                });
              }, 2000);
            })
            .catch((err) => {
              // this.$Toast.clear();
              this.$showLoading.hide();
            });
      } else {
        let data = {
          applyNo: this.detail.approveApply.applyNo,
          approveStatus: 2,
          isOver,
          nextApproverId: val.length ? val[0].id : "",
          nextApproverName: val.length ? val[0].name : "",
        };
        apply_aduitEvection(data).then((res) => {
          console.log(res);
          this.$showLoading.hide();
          this.$Toast.success("审批通过成功");
          setTimeout(() => {
            _this.intervalFunInterval();
            _this.$router.replace({
              name: "h5-my-approve-list",
              query: {
                id: 2
              }
            });
          }, 2000);
        }).catch((err) => {
          // this.$Toast.clear();
          this.$showLoading.hide();
        });
      }
    },
    onConfirm(value) {
      this.dialogShow = false;
      this.getApplyList([value])
    },
    setShow() {
      this.dialogChangeShow = false;
      if (this.staffRadio === 1) {
        // 不需要下一个
        this.getApplyList([])
      } else {
        // 需要下一个
        this.staffVisible = true
      }
    },

    newPass(val) {
      this.opaShow = false;
      switch (val.key) {
        case 'pass':
          // 通过
          this.onAgree();
          break
        case 'refuse':
          // 拒绝
          this.onRefuse();
          break
        case 'reject':
          // 驳回
          this.onRollback();
          break
        case 'frontJoin':
          // 委派
          this.onDelegateTask();
          break
        case 'backJoin':
          // 转办
          this.onAssignee();
          break
        case 'addAssignee':
          // 加签
          this.onAddMulti();
          break
        case 'delAssignee':
          // 减签
          this.onDeleteMulti();
          break
      }
    },
    handleCommand() {
      this.opaShow = true;
    },
    onAgree() {
      this.modalConfig.agreeVisible = true;
    },
    onDelegateTask() {
      this.modalConfig.delegateVisible = true;
    },
    onRefuse() {
      this.modalConfig.refuseVisible = true
    },
    onAssignee() {
      this.modalConfig.assigneeVisible = true
    },
    onRollback() {
      this.modalConfig.rollbackVisible = true;
    },
    onAddMulti() {
      this.modalConfig.addMultiVisible = true;
    },
    onDeleteMulti() {
      this.modalConfig.deleteMultiVisible = true;
    },
    onResolveTask() {
      this.modalConfig.resolveVisible = true
    },
    _success() {
      console.log('33')
      this.back();
    },
    _canecl() {
      console.log('123')
      this.back();
    }
  },
  // beforeRouteEnter(to, form, next) {
  //   // 1出差单，2超标单，3报销单，4改签单，5退订单
  //   const approveType = Number(to.query.approveType);

  //   if (approveType === 1) {
  //     to.meta.h5_layout.current_page_name = "出差单详情";
  //   } else if (approveType === 2) {
  //     to.meta.h5_layout.current_page_name = "超标单详情";
  //   } else if (approveType === 3) {
  //     to.meta.h5_layout.current_page_name = "报销单详情";
  //   } else if (approveType === 4) {
  //     to.meta.h5_layout.current_page_name = "改签单详情";
  //   } else if (approveType === 5) {
  //     to.meta.h5_layout.current_page_name = "退订单详情";
  //   } else {
  //     to.meta.h5_layout.current_page_name = "审批详情";
  //   }

  //   next();
  // },
  async activated() {
    await this.$store.state.policyDefer.promise;
    this.subsidyControlRule = store.state.policy.subsidyControlRule;
    if (this.intervalFun != null) {
      clearInterval(this.intervalFun);
    }
    await this.$store.state.workflowDefer.promise;
    await this.$store.state.userInfoDefer.promise;
    this.init();
  },
  deactivated() {
    this.$showLoading.hide();
    // this.$Toast.clear();
  },
  filters: {
    filterIntervalTime(val) {
      if (val) {
        // 转换为式分秒
        let h = parseInt(val / 60 / 60 % 24);
        h = h < 10 ? '0' + h : h;
        let m = parseInt(val / 60 % 60);
        m = m < 10 ? '0' + m : m;
        let s = parseInt(val % 60);
        s = s < 10 ? '0' + s : s;
        let text = `${m}分${s}秒`;
        if (h !== '00') {
          text = h + '时' + text
        }
        return text
      } else {
        return '00分00秒'
      }
    },
    format_nodestatus(val) {
      switch (val) {
        case 0:
          return "未开始";
        case 1:
          return "待审批";
        case 2:
          return "通过";
        case 3:
          return "拒绝";
        case 4:
          return "失效";
        case 5:
          return '已发起';
      }
    },
    format_week(val) {
      if (val) {
        switch (moment(val).day()) {
          case 1:
            return "周一";
          case 2:
            return "周二";
          case 3:
            return "周三";
          case 4:
            return "周四";
          case 5:
            return "周五";
          case 6:
            return "周六";
          case 0:
            return "周日";
        }
      } else {
        return "--";
      }
    },
    format_monthDay(val) {
      return val ? moment(val).format("MM月DD日") : ""
    },
    format_spanTime(start, end) {
      const spanMinute = moment(end).diff(moment(start), "minute");

      return `${Math.floor(spanMinute / 60)}h${spanMinute % 60}m`;
    },
  },
  async mounted(){

  },
  watch: {
    loading(val) {
      // if (val) {
      //   this.$Toast.loading({
      //     duration: 0,
      //     message: "加载中...",
      //     forbidClick: true,
      //   });
      // } else {
      //   this.$Toast.clear();
      // }
    },
  },
};
