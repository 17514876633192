import moment from "moment";
import NavBar from "@/component/nav-bar/import_latest_version_module";
// 支付详情
import PaymentList from "../components/payment-list/index.vue";

// 出差单详情
import req_getEvectionDetail from "@/lib/data-service/haolv-default/consumer_journey_getEvectionDetail.js";
// 取消机票订单
import cancel_flight_order from "@/lib/data-service/haolv-default/consumer_air_ticket_cancelOrder";
// 取消火车票订单
import cancel_train_order from "@/lib/data-service/haolv-default/consumer_trains_order_applyForCancel";
// 取消酒店订单
import cancel_hotel_order from "@/lib/data-service/haolv-default/consumer_t-od-hotel-order-interim_cancelOrder";
// 出差单取消
import consumer_evection_cancelEvection from "@/lib/data-service/haolv-default/consumer_evection_cancelEvection.js";

export default {
  components: {
    PaymentList,
    NavBar
  },
  data() {
    return {
      detail: {},
    };
  },
  computed: {},
  methods: {
    req_detail(params) {
      this.$Toast.loading("加载中");
      req_getEvectionDetail(params)
        .then((res) => {
          if (this.pageType === 3) {
            if (this.orderType === 1) {
              const datas = res.datas;
              const flightDetail = datas.flightDetail;
              let detail = {
                reason: datas.reason,
                travellerList: flightDetail.orderList[0].passengerList.map(
                  (item) => {
                    return { staffName: item.psgName, certificateList: [{certificateType: item.cardType, certificateValue: item.cardNo }] };
                  }
                ),
                linkman: datas.linkman,
                linkmanPhone: datas.linkmanPhone,
                feeAffiliationName: datas.feeAffiliationName,
                businessContentVo: datas.businessContentVo,
                flightDetail: flightDetail,
                serviceFee: datas.serviceFee,
                sum: flightDetail.orderList[0].chaAmount,
              };
              this.detail = detail;
            } else if (this.orderType === 2) {
              const datas = res.datas.changeDetailResponse;
              let train = {
                trainNo: datas.changeToStationCode,
                seatName: datas.changeSeatName,
                fromStationName: datas.changeFromStationName,
                spanTime: "",
                startTime: datas.changeStartTime,
                startDate: datas.changeStartDate,
                arriveDate: datas.changeArriveDate,
                toStationName: datas.changeToStationName,
                arriveTime: datas.changeArriveTime,
                seatPrice: datas.changeSeatPrice,
              };
              let detail = {
                reason: res.datas.reason,
                travellerList: datas.passengers.map((item) => {
                  return {staffName: item.passengerName, certificateList: [{certificateType: item.passengerCardType, certificateValue: item.passengerCardNumber }]};
                }),
                linkman: res.datas.linkman,
                linkmanPhone: res.datas.linkmanPhone,
                feeAffiliationName: res.datas.feeAffiliationName,
                sum: datas.changeTotalAmount,
                businessContentVo: res.datas.businessContentVo,
                ticketBookingPageDetailsResponseList: [train],
              };
              this.detail = detail;
            }
          } else {
            this.detail = res.datas;
            const detail = this.detail;
          }

        })
        .catch((err) => {
          this.$Toast.clear();
        });
    },
    init() {
      const query = this.$route.query;

      this.orderType = Number(query.orderType) || "";
      this.pageType = Number(query.pageType) || 2;
      const pageType = this.pageType;
      this.loading = true;
      if (this.orderType) {
        if (pageType === 3) {
          this.req_detail({ orderNo: query.orderNo, pageType, personType: 2 });
        } else if (pageType === 2) {
          this.req_detail({ orderNo: query.orderNo, pageType, personType: 2 });
        }
      } else {
        this.req_detail({
          evectionNo: query.evectionNo,
          pageType,
          personType: 2,
        });
      }
    },

    close_current_page() {
      this.$router.replace({
        name: "h5-evection-order-list",
      });
    },
    payment() {
      this.$router.replace({
        name: "h5-evection-order-list",
      });
    },

    cancel_order() {
      this.$Dialog.confirm({
        title: '提示',
        message: '是否确认取消',
      })
        .then(() => {
          this.$Toast.loading({
            //duration: 0,
            message: "请求中...",
            forbidClick: true,
          });
          const query = this.$route.query;
          const orderType = Number(query.orderType);
          const orderNo = query.orderNo;
          if (orderType) {
            let myMethod = null;
            let routerName = '';
            if (orderType === 1) {
              myMethod = cancel_flight_order;
              routerName = 'flight-order-detail';
            };
            if (orderType === 2) {
              myMethod = cancel_train_order;
              routerName = 'h5-train-order-detail';
            };
            if (orderType === 3) {
              myMethod = cancel_hotel_order;
              routerName = 'h5-hotel-order-detail';
            };
            myMethod({orderNo}).then((res) => {
              this.$Toast.clear();
              this.$Toast.success('取消成功');

              this.$router.replace({
                name: routerName,
                query: {
                  orderNo
                }
              })
            }).catch((err) => {
              this.$Toast.clear();
            })
          } else {
            consumer_evection_cancelEvection({evectionNo: this.detail.evectionNo}).then((res) => {
              this.$Toast.clear();
              this.$Toast.success('取消成功');
              this.$router.replace({
                name: 'h5-flight-order-list',
              })
            }).catch((err) => {
              this.$Toast.clear();
            })
          }
        })
        .catch(() => {

        });
    },
    to_payment() {
      this.$router.push({
        name: "h5-travel-payment",
        query: this.$route.query,
      });
    },
  },
  activated() {
    this.init();
  },
  deactivated () {
    this.$Toast.clear();
  },
  filters: {},
  watch: {},
};
